import React, {useEffect, useState} from 'react';
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {errorMessages} from "../../../../Helpers/ErrorMessages";



export const useEntityLedgers = (component,isLedger,isEdit, actions) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState('idle');
    const [openDelete, setOpenDelete] = useState(false)
    const [entity_id, setEntityId] = useState('')



    useEffect(() => {
        setLoading('pending')
        axios.get(`${config.smsUrl}/accounting/entity_behavior/list`,{params:{entity_type:component}}).then(res => {
            const response = res.data;
            const dt = response ? response : {};
            const arr = dt.data ? dt.data : []
            setData(arr)
            setLoading('success')
        }).catch(err => {
            errorMessages(err, null, actions)
            setLoading('error')
        })
    }, [isLedger, isEdit])

    const handleOpenDelete = (id) =>{
        setOpenDelete(true)
        setEntityId(id)
    }

    const handleCloseDelete = () =>{
        setOpenDelete(false)
    }



    const isLoading = loading === 'pending'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'

    return {data, isLoading, isSuccess, isError, openDelete,handleCloseDelete,
    handleOpenDelete,entity_id, setOpenDelete, setData}
}