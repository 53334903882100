import React, {useEffect, useState} from "react"
import Form from "react-bootstrap/Form"
import Label from "../../Utils/FormInputs/Label"
import TextField from "../../Utils/FormInputs/TextField";
import {RightAlignedContainer} from "../../Utils/styledComponents";
import Textarea from "../../Utils/FormInputs/Textarea";
import {CustomSelect} from "../../Utils/FormInputs/SelectInput";
import InputGroup from "react-bootstrap/InputGroup";
import {dateConvert, getCurrentDate} from "../../Utils/ConvertDate";
import axios from "axios";
import {config} from "../../../Helpers/env";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {makeStyles} from "@material-ui/core/styles";
import {AccountingDrawer} from "../../Utils/AccountingUtils/AccountingDrawer";


const useStyles = makeStyles((theme) => ({
    drawerWidth:{
        width: 800,
        padding:'16px'
    }
}));


function ReverseDebtorsPayments({actions, openDialog, setOpenDialog, isSubmitted,setIsSubmitted,providerDetails}){
    const classes = useStyles()
    const [state, setState] = useState({payment_method:'', refund_date:dateConvert(),memo:''})
    const [refundAmount, setRefundAmount] = useState(0)
    const [submitted, setSubmitted] = useState(false)
    const [accounts, setAccounts] = useState([])
    const [refund_from, setRefundFrom] = useState('')


    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = _token.user_roles_id ? _token.user_roles_id : '';



    useEffect(() => {
        axios.get(`${config.smsUrl}/accounting/ledgers_under_category`).then(res => {
            const data = res.data;
            const dt = data ? data : {};
            const arr = dt.data ? dt.data : []
            const list = arr.map(item => ({
                value: item.id,
                label: item.head_name
            }))
            setAccounts(list)
        }).catch(err => {
            errorMessages(err, null, actions)
        })
    }, [])

    useEffect(()=>{
        if (!providerDetails?.visit_id){
            return
        }
        setRefundAmount(providerDetails.total_bill)
    }, [providerDetails?.visit_id])




    const handleCloseDialog = () =>{
        setOpenDialog(false);
    }

    const handleChange = (event) =>{
        const {name, value} = event.target;
        setState({...state, [name]:value})
    }


    const handleChangeRefundFrom = (val) =>{
        setRefundFrom(val)
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setSubmitted(true);
        if (refundAmount && refund_from && state.refund_date) {

            const params = {
                refund_date:state.refund_date,
                invoices_paid:providerDetails?.bills,
                refund_amount:+refundAmount,
                pay_type:2,
                received_by:user_roles_id,
                memo:state.memo,
            }

            setIsSubmitted('pending')
            axios.post(`${config.smsUrl}/cbilling/refund_bill`, params).then(() => {
                actions.snackbarActions.snackSuccess('Invoice reversed successfully')
                setIsSubmitted('resolved')
                setSubmitted(false)
                setOpenDialog(false)
                setRefundAmount(0)
                setRefundFrom('')
                setState({memo:'', refund_date: '', payment_method: ''})
            }).catch(err => {
                errorMessages(err, null, actions)
                setIsSubmitted('rejected')
            })
        }
    }


    const isPending = isSubmitted === 'pending';
    const year = new Date().getFullYear().toString()
    const current_year = year.split('').slice(-2).join('')
    return (
        <AccountingDrawer open={openDialog} title={`Refund ${providerDetails?.visit_id}/${current_year} | ${providerDetails.first_name} ${providerDetails.last_name}`} handleClose={handleCloseDialog} width={classes.drawerWidth}>
            <form onSubmit={handleSubmit}>
                <Form.Group>
                    <Label name='Refund Date' type/>
                    <TextField type='date' value={state.refund_date} name='refund_date' submitted={submitted} onChange={handleChange}/>
                    {submitted && !state.refund_date ? <div className='help-block'>Refund date is required</div>:null}
                </Form.Group>

                <Form.Group>
                    <Label name='Refund Amount'  htmlFor='refundAmount'/>
                    <InputGroup className="mb-3">
                        <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">{providerDetails?.currency_symbol}</InputGroup.Text>

                        <TextField name='refundAmount' type="text" value={refundAmount} readOnly/>
                    </InputGroup>
                </Form.Group>

                <Form.Group>
                    <Label name='Refund From' type htmlFor="refund_from"/>
                    <CustomSelect id='refund_from' submitted={submitted} onChange={handleChangeRefundFrom} value={refund_from} options={accounts}/>
                    {submitted && !refund_from ? <div className="help-block">Refund account is required</div>:null}
                </Form.Group>

                <Form.Group>
                    <Label name='Memo'/>
                    <Textarea rows='3' cols='3'  name="memo" onChange={handleChange} value={state.memo}/>
                </Form.Group>
                <RightAlignedContainer>
                    <button type='submit' disabled={isPending} className='btn btn-sm sms-btn'>{isPending ? 'Submitting....' : 'Submit'}</button>
                </RightAlignedContainer>
            </form>
        </AccountingDrawer>
    )
}

export {ReverseDebtorsPayments}