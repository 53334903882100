import {useState} from 'react';
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import {titleCase} from "../../../Users/addUser";


export const useSundries = (obj) => {
    const {actions, visit_id, setIsBlocking} = obj
    const initialState = [{product_name:'', product_id:'', isEdited:false,currency:'', currency_symbol:'',
        quantity:'', showDrug:false, isError:false, rate:0,prescription_detial_id:'', isRemoved:false, category_name:''}]
    const [sundries, setSundries] = useState(initialState);
    const [sundriesData, setSundriesData] = useState([])
    const [sundriesEdited, setSundriesEdited] = useState([])
    const [sundriesRemoved, setSundriesRemoved] = useState([])
    const [openSundries, setOpenSundries] = useState(false)
    const [cashItems, setCashItems] = useState([])
    const [product_id, setProductId] = useState('')
 
    const retrieveSundries = (event, idx) => {
        const arr = sundries.map((item, index) => {
            if (index === idx) {
                const formData = new FormData();
                formData.append('sandry_name', titleCase(event.target.value));
                if (event.target.value !== '') {
                    axios.post(`${config.smsUrl}/cdoctor/autocomplete_search_sandries`, formData).then(res => {
                        const resp = res.data;
                        const data = !resp ? [] : resp;
                        if (data[0] ===  "No Sandry Found") {
                            setSundriesData([{value: '', label: 'No Sandry Found'}])
                        } else {
                            setSundriesData(data);
                        }
                    }).catch(error => {
                        logoutErrorMessage(error, null, actions)
                    });
                    return {...item, product_name: event.target.value, showDrug: true, isError: false}
                }
                return {...item, product_name: event.target.value, showDrug: false, isError: false}
            }
            return item
        })
        setSundries(arr)
        setIsBlocking(true)
    };


    const clickSundries = (event, idx) => {
        let arr = sundries.map((item, index)=>{
            if (index === idx){
                return {...item, product_name:event.label, product_id:event.value, isError: false, showDrug: false}
            }
            return item
        })
        const formData = new FormData();
        formData.append('product_id', event.value);
        formData.append('location', 2); // for pharmacy
        formData.append('to_store', 'Pharmacy');
        if (event.value) {
            axios.post(`${config.smsUrl}/cdoctor/get_medicinedetails_byproduct_id`, formData).then(res => {
                const resp = res.data;
                const data = !resp ? {} : resp;
                const {quantity} = data
                axios.post(`${config.smsUrl}/cbilling/item_cost`, {visit_id:+visit_id, item_id:event.value, item_type:'product'}).then(res => {
                    const resObj = res.data;
                    const dataObj = !resObj.data ? {} : resObj.data;
                    arr = arr.map((item, index)=>{
                        if (index === idx){
                            return {...item, rate:  dataObj.cost ?? 0,status: quantity,showDrug: false,...dataObj,
                                currency: dataObj.currency_id, currency_symbol: dataObj.currency,product_name:event.label,
                                 product_id:event.value,}
                        }
                        return item
                    })
                    setSundries(arr)
                    if(dataObj.is_provider === 1 && dataObj.is_provided === 0){
                        setProductId(event.value)
                        setOpenSundries(true)
                    }
                }).catch(error => {
                    errorMessages(error, null, actions)
                });
            }).catch(error => {
                errorMessages(error, null, actions)
            });
        }
        setIsBlocking(true)
    }


    const handleChangeQuantity = (event, index) => {
        const {value} = event.target;
        const arr = sundries.map((item, idx) => {
            if (idx === index) {
                return {...item, quantity: value, isEdited:item.prescription_detial_id}
            }
            return item
        })
        const edited = arr.filter((item)=>item.isEdited)
        setSundriesEdited(edited)
        setSundries(arr)
        setIsBlocking(true)
    };

    const addSundriesToReceipt = () =>{
        const arr = sundries.filter(item=>item.product_id === product_id)
        setCashItems(arr)
        setOpenSundries(false)
    }

    const handleCloseSundries = () =>{
        const arr = sundries.filter(item=>item.product_id !== product_id)
        setSundries(arr)
        setOpenSundries(false)
    }

    const handleCancelSundries = () =>{
        setOpenSundries(false)
    }



    const handleAddSundry = () => {
        const item={product_name:'', product_id:'', quantity:'', showDrug:false, isError:false, rate:0}
        setSundries([...sundries, item])
    };

    const handleRemoveSundry = (index) => {
        const arr = sundries.filter((_, idx) => idx !== index)
        const removed = sundries.map((item, idx)=>{
            if(index === idx){
                return {...item, isRemoved:true}
            }
            return item
        })
        const deleted = removed.filter(item=>item.isRemoved)
        setSundriesRemoved(deleted)
        setSundries(arr);
        setIsBlocking(true)
    }

    const handleResetSundries = () =>{
        const arr = sundries.map(item=>({
            ...item, product_name:'', product_id:'', quantity:'',
            showDrug:false, isError:false, rate:0
        }))
        setSundries(arr)
    }

    const sundryProps = {sundries, sundriesData, retrieveSundries, clickSundries,sundriesEdited, initialState,
    sundriesRemoved,handleChangeQuantity, handleAddSundry, handleRemoveSundry,
        setSundries, setSundriesData, openSundries,addSundriesToReceipt,
    handleCancelSundries, handleCloseSundries, handleResetSundries}

    return {sundryProps, setSundries}
}
