import {css} from '@emotion/react';
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PageTitle from "../../Utils/smsTitle";
import Card from "@material-ui/core/Card";
import {useTheme} from "@material-ui/core/styles";
import {faUserMd} from "@fortawesome/free-solid-svg-icons/faUserMd";
import {titleCase} from "../../Users/addUser";
import { PrintTemplate } from '../../Utils/Templates/PrintTemplate'
import { usePrint } from '../../Utils/Templates/usePrint';
import * as colors from '../../../styles/colors';
import { formatDateTime } from "../../Utils/ConvertDate";
import { RightAlignedContainer } from '../../Utils/styledComponents';
import { table, detailsTable } from '../../../styles/tableStyles';
import { history } from './../../../Helpers/history';


const textWeight = css`
    font-weight:600;
`

const bottomTableCell = css`
    border-bottom:2px solid ${colors.text00};
`
const paragraph =  css`
    margin: 0 0 8px 0;
   
`
const titleParagraph = css`
    font-weight:600;
    display: inline-block;
    border-bottom: 1px solid ${colors.text00};
    margin: 0;
`

const splitNotes = (txt) =>{
    const nt = txt ? txt : '';
    return nt.split('\n').map(text=><p css={[paragraph]}>{text}</p>)
}

export function PrintableReferralNote({title, subTitle, text, referral}) {

    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    const {date_created, diagnosis,  reason, referal_number, referred_to,patient_number,
        treatment_given,address, dob, first_name, gender, last_name, phone_no} =referral;
    
    const handleOnAfterPrint = () => {
        history.push('/visitlist');
    }

    const patientDetails = (
        <tbody>
            <tr>
                <td><span>Date: </span> <span css={[textWeight]}>{formatDateTime(date_created)}</span></td>
            </tr>
            <tr>
            <td><span>Refer To: </span> <span><strong>{referred_to}</strong></span></td>
            <td colSpan={4}><span>Ref No.: </span> <span css={[textWeight]}>{referal_number}</span></td>
            
            </tr>
            
            <tr>
                <td><span>Patient Number: </span> <span css={[textWeight]}>{patient_number}</span></td>
                <td><span>Patient Name: </span> <span css={[textWeight]}>{`${!first_name ? "" : first_name} 
                                ${!last_name ? "" : last_name}`}
            </span>
        </td>
    
            </tr>
            <tr>
            <td><span>Age: </span> <span css={[textWeight]}>{dob}</span></td>
            <td><span>Gender:</span> <span css={[textWeight]}>{titleCase(gender)}</span></td>
            </tr>
            <tr>
            <td css={[bottomTableCell]}><span>Contact: </span> <span css={[textWeight]}>{phone_no}</span></td>
            <td css={[bottomTableCell]}><span>Address:</span> <span css={[textWeight]}>{address}</span></td>
            </tr>
        </tbody>
    )

    const itemDetails =(
        <>
            <table css={[table,detailsTable]}>
                <tbody>
                    <tr>
                        <td><p css={[titleParagraph]}>Reason</p></td>
                    </tr>
                    <tr>
                        <td>{reason ? splitNotes(reason): reason}</td>
                    </tr>
                    <tr>
                        <td><p css={[titleParagraph]}>Diagnosis</p></td>
                    </tr>
                    <tr>
                        <td> {diagnosis ? splitNotes(diagnosis): diagnosis}</td>
                    </tr>
                    <tr>
                        <td><p css={[titleParagraph]}>Treatment Given</p></td>
                    </tr>
                    <tr>
                        <td>{treatment_given ? splitNotes(treatment_given): treatment_given}</td>
                    </tr>
                </tbody>
            </table>
            <table className="table table-sm table-borderless">
                <tr>
                    <td/>
                    <td align="center"  className={palette.type === 'dark' && 'dark-theme-color'}>
                        <p>Signature &amp; Stamp </p></td>
                </tr>
                <tr>
                    <td className={palette.type === 'dark' && 'dark-theme-color'}/>
                    <td width="350px" align="right" className={palette.type === 'dark' && 'dark-theme-color'}><p
                        className={`doctor-stamp  mt-3 ${palette.type === 'dark' && 'dark-doctor-stamp'}`}
                        id="doctor-stamp"/></td>
                </tr>
            </table>
            <p className="referral-paragraph referral-bold-paragraph">{text}</p>
        </>
    )

    const {componentRef, handlePrint, getPageMargins, fontSize}  = usePrint(`Referral_Note_${patient_number}`, handleOnAfterPrint);
    return (
        <div>
            <PageTitle title={title}/>
            <SubHeader title="Doctor" subTitle={subTitle}>
                <FontAwesomeIcon icon={faUserMd}/>
            </SubHeader>
            <section className="general-referral-report">
                <RightAlignedContainer>
                    <button className="btn btn-sm sms-amber-btn mb-2" onClick={handlePrint}>Print Referral</button>
                </RightAlignedContainer>
                <Card>
                    <div ref={componentRef} style={{padding:10}}>
                        <style>
                            {getPageMargins()}
                            {fontSize()}
                        </style>
                        <div className="page-break" />
                        <PrintTemplate title='Referral Note' {...{patientDetails,itemDetails}}/>
                    </div>
                </Card>
            </section>
        </div>
    )
}

export default PrintableReferralNote;