import {useEffect, useRef, useState} from "react";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {useEditCompletedNotes} from "./useEditCompletedNotes";
import {useEditCompletedDiagnosis} from "./useEditCompletedDiagnosis";
import {useEditCompletedTriage} from "./useEditCompletedTriage";


export const useViewCompletedDetails = (actions) =>{
    const [patient, setPatient] = useState({});
    const [treatmentDetails, setTreatmentDetails] = useState([]);
    const [option, setOption] = useState([{label:'Triage', value:'triage'},{label:' Clinical Notes', value:'notes'},
        {label:'Diagnosis', value:'diagnosis'},{label:'Prescription', value:'prescription'}]);
    const [openDialog, setOpenDialog] = useState(false);
    const [patient_type, setPatientType] = useState('')
    const [ids, setIds] = useState({visit_id:'', patient_number:''})
    const [isPrint, setIsPrint] = useState(false)


    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;

    const {visit_id, patient_number} = ids


    const options = [{label:'Triage', value:'triage'},{label:' Clinical Notes', value:'notes'},
        {label:'Laboratory Result(s)', value:'lab'},{label:'Radiology Report(s)', value:'radiology'},
        {label:'Diagnosis', value:'diagnosis'},{label:'Prescription', value:'prescription'}]

    const notesObj = {actions, treatmentDetails,setTreatmentDetails, visit_id, patient_number}

    const { handleEdit, handleCancelEdit, handleChangeNotes,
        handleUpdateNotes, submitted,isPendingEdit, isResolved} = useEditCompletedNotes(notesObj)

    const {isResolvedDiagnosis, diagnosisProps} = useEditCompletedDiagnosis(notesObj)
    const {triageProps,isResolveTriage} = useEditCompletedTriage(notesObj)

    const componentRef = useRef()

    useEffect(() => {
        if(!patient_number){
            return;
        }
        const formData = new FormData();
        formData.append('patient_number', patient_number);
        axios.post(`${config.smsUrl}/cpatient/patient_update_form`, formData).then(res => {
            const data = res.data;
            const details = !data ? {} : data;
            setPatient(details)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, [patient_number]);


    useEffect(() => {
        if(!visit_id){
            return;
        }
        const formData = new FormData();
        formData.append('visit_id', visit_id);
        axios.post(`${config.smsUrl}/cdoctor/get_all_patient_treatment_details`, formData).then(res => {
            const data = res.data;
            const details = !data ? {} : data;
            const {lab_result,scan_result, diagnosis,prescription,other_medicine,exam_notes,triage,
                visit_detail} = details
            const presc = prescription ? prescription : [];
            const others = other_medicine ? other_medicine : [];
            const other_meds = others.filter(item=>Boolean(item));
            const medicationArr = [...presc, ...other_meds];
            const labArr = lab_result?.map(item=>({...item, open:true}))
            const scanArr = scan_result?.map(item=>({...item, open:true}))
           const arr = {
                    labResults: labArr,
                    radiologyResults: scanArr,
                    patientDiagnosis: diagnosis,
                    medication: medicationArr,
                    patientTriage: triage.map(item=>({
                        ...item, isEdit:false
                    })),
                    clinicalNotes: exam_notes.map(note=>({
                        ...note, openNote:true,
                        isEdit:note.done_by === user_roles_id,
                        display_input: false
                    })),
                   visit_type : parseInt(visit_detail['visit_type']),
                   visit_date: visit_detail['begin_datetime'],
                }

            setTreatmentDetails(arr)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, [visit_id, isResolved,isResolvedDiagnosis,isResolveTriage]);


    const handleOpenNote = (notes_id) => {
        const obj = {...treatmentDetails,  clinicalNotes: treatmentDetails?.clinicalNotes?.map((note, idx)=>{
                if (note.examination_notes_id === notes_id){
                    return {...note, openNote:!note.openNote}
                }
                return note
            })
        }
        setTreatmentDetails(obj)
    }

    const handleOpenResults = (visit_id,index,arr,item_name) =>{
        const openedArr = arr.map((child, idx)=>{
            if(idx === index){
                return {...child, open:!child.open}
            }else {
                return child
            }
        })
        const obj = {...treatmentDetails, [item_name]:openedArr}
        setTreatmentDetails(obj)
    }



    const handleChangeOption = (value) =>{
        setOption(value)
        setIsPrint(false)
    }



    const handleOpenDialog = (item) =>{
        const {visit_id:v_id, patient_number:p_number} = item;
        setIds({visit_id: v_id, patient_number: p_number, ...item})
        setPatientType(1)
        setOpenDialog(true)
    }

    const handleCloseDialog = () =>{
        setOpenDialog(false)
        setIsPrint(false)
    }


    const {first_name, last_name, Age, address, gender, phone_no} = patient

    const patientInfo = {patient_name:`${first_name} ${last_name}`, age:Age, gender,  address, phone: phone_no};



    const patientProps = {option, options, handleChangeOption, componentRef,
        openDialog, handleOpenDialog, handleCloseDialog,patientInfo,diagnosisProps,
        treatmentDetails, handleOpenResults, handleOpenNote, visit_id,patient_type,
    handleEdit, handleCancelEdit, handleChangeNotes, handleUpdateNotes, submitted,isPendingEdit,
    triageProps, isPrint, setIsPrint,setOpenDialog, ids}
    return {patientProps}
}