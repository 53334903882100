import { useEffect, useState } from "react";
import axios from "axios";
import { config } from "../../../Helpers/env";
import { errorMessages, logoutErrorMessage } from "../../../Helpers/ErrorMessages";
import { history } from "../../../Helpers/history";
import { dateTimeConvert } from "../../Utils/ConvertDate";
import { useMutation, useQuery } from "react-query";

function useScanRequests(actions, url) {
    const [activePage, setActivePage] = useState(1);
    // const [scanRequests, setScanRequests] = useState({});
    // const [total_count, setTotalCount] = useState(0)
    const [term, setTerm] = useState('');
    // const [status, setStatus] = useState('idle');
    const [requestStatus, setRequestStatus] = useState('idle')

    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;
    // const [scan_queue, setScanQueue] = useState(0);

    const scanRequestsQuery = useQuery(
        ["scanRequestsQuery", activePage, term],
        async () => {
            try {
                const formData = new FormData();

                formData.append("search", term);

                const response = await axios.post(`${config.smsUrl}/cradiology/${url}/${activePage}`, formData);

                const data = response?.data ?? {};

                const count = data?.total_count ?? 0;

                return { scanRequests: data, total_count:count };

            } catch (error) {

                logoutErrorMessage(error, null, actions);

            }
        },
        { retry: 1, refetchInterval: 10000 });

    const status = scanRequestsQuery.isLoading ? "pending" : scanRequestsQuery.isSuccess ? "success" : "error";

    let { scanRequests, total_count } = scanRequestsQuery.isSuccess ? scanRequestsQuery.data : { scanRequests: [], total_count: 0 };

    // const retrieveRequests = () => {
    //     const formData = new FormData();
    //     formData.append('search', term);
    //     setStatus('pending')
    //     axios.post(`${config.smsUrl}/cradiology/${url}/${activePage}`, formData).then((res) => {
    //         const data = res.data;
    //         const dt = !data ? {} : data;
    //         const count = !dt.total_count ? 0 : dt.total_count;
    //         // console.log(count)
    //         setScanRequests(dt)
    //         setTotalCount(count)
    //         setStatus('success')
    //     }).catch(err => {
    //         logoutErrorMessage(err, null, actions);
    //         setStatus('error')
    //     })
    // };
    //
    // useEffect(() => {
    //     retrieveRequests()
    //     const interval = setInterval(() => {
    //         retrieveRequests()
    //     }, 10000)
    //     return () => clearInterval(interval)
    // }, [activePage, term])

    // useEffect(() => {
    //     (async () => {
    //         try {
    //             const formData = new FormData();
    //             formData.append('search', '');
    //             axios.post(`${config.smsUrl}/cradiology/scan_queue/${0}`, formData).then(res => {
    //                 const data = res.data;
    //                 const dt = !data ? {} : data;
    //                 const count = !dt.total_count ? 0 : dt.total_count;
    //                 // console.log(count)
    //                 setScanQueue(count);
    //             }).catch(err => {
    //
    //             })
    //         } catch (error) {
    //             // errorMessages(error, null, actions)
    //         }
    //     })()
    // }, [])


    const scanQueueQuery = useQuery(
        ["scanQueueQuery"],
        async () => {
            try {
                const formData = new FormData();

                formData.append("search", "");

                const response = await axios.post(`${config.smsUrl}/cradiology/scan_queue/${0}`, formData);

                const data = response?.data ?? {};

                const count = data?.total_count ?? 0;

                return { count }
            } catch (error) {

                logoutErrorMessage(error, null, actions)

            }
        },
        { retry: 1 });

    const scan_queue = scanQueueQuery.isSuccess ? scanQueueQuery?.data?.count : 0;


    const handleChangeActivePage = (value) => {
        setActivePage(value)
    };

    const handler = (event) => {
        setTerm(event.target.value)
    }

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };


    const handleClickRow = (ids) => {
        const { service_id, visit_id, hospital_scan_id, scan_requests_detail_id,
            scan_request_id, hospital_scan_name, status, purpose, patient_number, activity_id } = ids
        const current_date = new Date();
        setRequestStatus('pending')
        const formData = new FormData()
        formData.append('visit_id', visit_id)
        formData.append('activity_id', activity_id)
        formData.append('scan_request_id', scan_request_id)
        formData.append('scan_state', 2)
        formData.append('user_roles_id', user_roles_id)
        axios.post(`${config.smsUrl}/cradiology/update_scan_activity`, formData).then(() => {
            setRequestStatus('resolved')
            history.push({
                pathname: `/scanupload/${service_id}/${visit_id}/${patient_number}/${hospital_scan_id}/${scan_requests_detail_id}/${scan_request_id}/${status}/${activity_id}/${dateTimeConvert(current_date)}`,
                state: { scanName: hospital_scan_name, purpose }
            })
        }).catch(err => {
            errorMessages(err, null, actions)
            setRequestStatus('rejected')
        })

    };

    const isLoading = status === 'pending';
    const isSuccess = status === 'success';
    const isRejected = status === 'error';

    const isPending = requestStatus === 'pending'

    return {
        activePage, term, scanRequests, total_count, handleChangeActivePage, handler, closeSnackbar, isLoading, isSuccess, isRejected,
        isPending, handleClickRow, scan_queue
    }
}
export { useScanRequests }
