import React from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import TextField from '../FormInputs/TextField';
import Label from '../FormInputs/Label';
import {ErrorMessage} from "../styledComponents";
import * as mq from "../../../styles/media-queries"
import styled from '@emotion/styled/macro'



const StyledForm  = styled.form({
    [mq.extraLarge]:{
        width:'80%'
    }
})

function AccountingDateFilter({from_date,to_date, handleChange, submitted,handleSubmit,isPending, children}) {
    return(
        <StyledForm onSubmit={handleSubmit}>
            <div className='row'>
                {children}
                <div className='col-lg-4'>
                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={2} lg={2} xl={2}>
                            <Label name='From'  type/>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                            <TextField type='date' name='from_date' value={from_date} onChange={handleChange}/>
                            {submitted && !from_date ? <ErrorMessage>This is a required field</ErrorMessage>: null}
                        </Col>
                    </Form.Group>
                </div>
                <div className='col-lg-4'>
                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={2} lg={2} xl={2}>
                            <Label name='To'  type/>
                        </Col>
                        <Col xs={10} sm={10} md={8} lg={8} xl={8}>
                            <TextField type='date'  name='to_date' value={to_date} onChange={handleChange}/>
                            {to_date < from_date ? <ErrorMessage>To date shouldn't be less than from date</ErrorMessage> :   submitted && !to_date ? <ErrorMessage>This is a required field</ErrorMessage>: null}
                        </Col>
                        <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                            <button type='submit' className='btn btn-sm sms-info-btn' disabled={isPending}>{isPending ? 'Searching...':'Search'}</button>
                        </Col>
                    </Form.Group>
                </div>
            </div>
        </StyledForm>
    ) 
}
export {AccountingDateFilter}
