import React, {useState} from 'react';
import Form from "react-bootstrap/Form";
import * as snackbarActions from "../../actions/snackbarActions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {history} from "../../Helpers/history";
import * as alertActions from "../../actions/alertActions";
import axios from "axios";
import { config } from "../../Helpers/env";


function PasswordReset(props){
    const [state,setState] = useState({
            new_password: '',
            re_password: '',
            submitted: false,
            submitting: false
        });
        history.listen((location, action) => {
            const {actions} = props;
            actions.alertActions.alertClear();
        });
    
    const {new_password, re_password, submitted, submitting} = state;
    const {alert} = props;
    const {type, message} = alert;

    const handleChange = event => {
        const {name, value} = event.target;
        setState({[name]: value})
    };

    const handleSetPassword = event => {
        event.preventDefault();
        const {actions} = props;
        const {new_password, re_password} = state;
        const {reset_token} = props.match.params;
        const formData = new FormData();
        formData.append('reset_token', reset_token);
        formData.append('new_password', new_password);
        formData.append('reset_password', re_password);
        setState({submitted: true})
        if (new_password && re_password) {
            setState({submitting: true})
            axios.post(`${config.smsUrl}/admin/reset`, formData).then(() => {
                actions.alertActions.alertSuccess('Password reset successfully')
                history.push('/login')
                setState({submitting: false})
            }).catch(() => {
                setState({submitting: false})
                alertActions.alertFailure('Password reset unsuccessful')
            })
            setState({new_password: '', submitted: false, re_password: ''});
        }

    };
       
        return (
            <div className="styled-reset">
                {message && <div className={` alert text-center ${type}`}>{message}</div>}
                <div className="card styled-reset-card p-4">
                    <div className="py-4">
                        <div className="text-center styled-reset-content mb-3">
                            <h4>Set Password</h4>
                        </div>
                        <form onSubmit={handleSetPassword} autoComplete="off">
                            <Form.Group>
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" placeholder="Password" value={new_password}
                                              name="new_password"
                                              onChange={handleChange}/>
                                {(submitted && !new_password) &&
                                <div className="help-block">Password is required</div>}
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Confirm Password</Form.Label>
                                <Form.Control type="password" placeholder="Confirm Password" value={re_password}
                                              name="re_password"
                                              onChange={handleChange}/>
                                {(submitted && !new_password) ?
                                    <div className="help-block">Confirm Password is required</div> :
                                    (new_password !== re_password) &&
                                    <div className="help-block">The two passwords don't match</div>}
                            </Form.Group>
                            <div className="text-center">
                                <button type="submit"  className="btn sms-btn px-5 mt-3" style={{width: "100%"}} >
                                    {submitting ? "Resetting....":"Reset Password"}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }



function mapStateToProps(state) {
    return {
        alert: state.alerts,
        snackbars: state.snackbar
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch),
            alertActions: bindActionCreators(alertActions, dispatch)
        }
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);
