import React, {useRef, useState, useEffect} from 'react';
import {bindActionCreators} from "redux";
import {connect} from 'react-redux';
import axios from 'axios';
import { Container } from '../../../Utils/styledComponents';
import { logoutErrorMessage } from '../../../../Helpers/ErrorMessages';
import { config } from "../../../../Helpers/env";
import * as snackbarActions from "../../../../actions/snackbarActions";
import PageTitle from "../../../Utils/smsTitle";
import { SubHeader } from '../../../../Containers/SubHeader';
import { ReusableTreatmentHistory, Spacer } from '../../../DoctorsModule/ReusableTreatmentHistory';
import ReusableAnteNatalTabs, { anteNatalRoutes } from './ReusableAnteNatalTabs';
import {usePrint} from "../../../Utils/Templates/usePrint";
import {ResultsHeading, PreviousTriage, PreviousClinicalNotes,
    PreviousDiagnosis, Heading, Border,SelectForm} from "../../../DoctorsModule/PatientPreviousVisits";

/**
 * Component that displays the Treatment details taken from an Ante Natal Visit
 * @param {*} param0 
 * @returns 
 */
const AnteNatalTreatmentDetails = ({actions, match}) => {

    const options =  [{label:'Triage', value:'triage'},{label:'Antenatal', value:'antenatal'},
        {label:'Laboratory Result(s)', value:'lab'},{label:'Radiology Report(s)', value:'radiology'},
        {label:'Diagnosis', value:'diagnosis'},{label:'Prescription', value:'prescription'}]
    const {patient_number, labrequest_id, scan_request_id, visit_id, patient_type,patient_admission_id, anc_id, anc_status} = match.params;
    const components = anteNatalRoutes(patient_number, visit_id, labrequest_id, scan_request_id, patient_type,patient_admission_id, anc_id, anc_status);

    const [visitDetails, setVisitDetails] = useState({labResults:[],radiologyResults:[], patientDiagnosis:[],
        medication:[], clinicalNotes:[], patientTriage:[], visit_date:'', visit_type:'',antenatal_data:{}})

    const [patient, setPatient] = useState({});
    const [option, setOption] = useState([{label:'Triage', value:'triage'},{label:'Antenatal', value:'antenatal'}])

    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const doctor_name = `${!_token.first_name ? "" : _token.first_name} ${!_token.last_name ? "" : _token.last_name}`;   

    useEffect(()=>{
        const formData = new FormData();
        formData.append('visit_id', visit_id);

        const ax1 = axios.post(`${config.smsUrl}/cdoctor/get_all_patient_treatment_details`, formData);
        const ax2 = axios.post(`${config.smsUrl}/antenatal/view_antenatal_details`, formData);
        Promise.all([ax1, ax2]).then(([res, res2]) => {
            const data = res.data;

            const details = !data ? {} : data;
            const visit_details = details.visit_detail ? details.visit_detail : {}
            const {lab_result,scan_result, diagnosis,prescription,other_medicine,exam_notes,triage} = details
            const presc = prescription ? prescription : [];
            const others = other_medicine ? other_medicine : [];
            const other_meds = others.filter(item=>Boolean(item));
            const medicationArr = [...presc, ...other_meds];
            const labArr = lab_result?.map(item=>({...item, open:true}))
            const scanArr = scan_result?.map(item=>({...item, open:true}));

            const data2 = res2.data;
            const antenatal_data = !data2 ? {} : data2;

            setVisitDetails({
                ...visitDetails,
                labResults: labArr,
                radiologyResults: scanArr,
                patientDiagnosis: diagnosis,
                medication: medicationArr,
                patientTriage: triage,
                clinicalNotes:  exam_notes.map(note=>({
                    ...note, openNote:true
                })),
                visit_date: visit_details.begin_datetime,
                visit_type: visit_details.patient_type,
                antenatal_data
            })
        }).catch(err => {
            logoutErrorMessage(err,null,actions)
        })
    },[])

    useEffect(() => {
        if(!patient_number){
            return;
        }
        const formData = new FormData();
        formData.append('patient_number', patient_number);
        axios.post(`${config.smsUrl}/cpatient/patient_update_form`, formData).then(res => {
            const data = res.data;
            const details = !data ? {} : data;
            setPatient(details)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, [patient_number]);

    const handleChangeOption = (value) =>{
        setOption(value)
    }

    const handleOpenNote = (v_id,notes_id) => {
        const obj = {...visitDetails,  clinicalNotes: visitDetails?.clinicalNotes?.map((note, idx)=>{
                if (note.examination_notes_id === notes_id){
                    return {...note, openNote:!note.openNote}
                }
                return note
            })
        }
        setVisitDetails(obj)
    }

    const handleOpenResults = (visit_id,index,arr,item_name) =>{
        const openedArr = arr.map((child, idx)=>{
            if(idx === index){
                return {...child, open:!child.open}
            }else {
                return child
            }
        })
        const obj = {...visitDetails, [item_name]:openedArr}
        setVisitDetails(obj)
    }

    const {first_name, last_name, Age, address, gender, phone_no} = patient;

    const patientInfo = {patient_name:`${first_name} ${last_name}`, age:Age, gender, phone:phone_no, address}

    const {patientDiagnosis, patientTriage, clinicalNotes} = visitDetails

    const triageComponent = (
        patientTriage?.length > 0 ?  <>
                <ResultsHeading>
                    <Heading>Triage</Heading>
                    <Border/>
                </ResultsHeading>
                <PreviousTriage {...{patientTriage}}/>
            </>: null
    )

    const clinicalNotesComponent = (
        clinicalNotes?.length > 0 ?
                <Spacer>
                    <ResultsHeading>
                        <Heading>Clinical Notes</Heading>
                        <Border/>
                    </ResultsHeading>
                    <PreviousClinicalNotes {...{clinicalNotes, visit_id, handleOpen:handleOpenNote}}/>
                </Spacer>:  null
    )

    const diagnosisComponent = (
        patientDiagnosis?.length > 0 ?
                <Spacer>
                    <PreviousDiagnosis {...{patientDiagnosis}} />
                </Spacer>
                : null
    )


    const {componentRef, handlePrint,  fontSize}  = usePrint('Medical History Report');
    return (
        <div className='journals' data-testid="ante-natal-treatment-details">
            <PageTitle title="Treatment Details"/>
            <SubHeader title="Ante Natal" subTitle="Treatment Details" doctor_name={doctor_name}>
               <div className="header-icon-margin-right-zero">
                   <img src="/images/antenatal-blue.png" className="icon-resize surgery-icon-tabs ante-natal-green"/></div>
            </SubHeader>
            <ReusableAnteNatalTabs components={components}>
                <Container>
                    <SelectForm {...{options, option, handleChangeOption,handlePrint}}/>
                    <div ref={componentRef}>
                        {fontSize}
                    <ReusableTreatmentHistory {...{patient_type, patient_number, patientInfo, treatmentDetails:visitDetails, option,
                    clinicalNotesComponent, triageComponent, diagnosisComponent, handleOpenResults}}/>
                    </div>
                </Container>
            </ReusableAnteNatalTabs>
        </div>
    )
}

function mapStateToProps(state) {
    const {snackbar} = state;
    return {snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(AnteNatalTreatmentDetails);