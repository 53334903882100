import {useEffect, useState} from "react";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {history} from "../../../Helpers/history";

export const useAddRole = (actions, role_id, historyState={}) => {
    const [rows, setRows] = useState([]);
    const [checked, setChecked] = useState(false);
    const [role_name, setName] = useState("");
    const [permission, setPermission] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [loading, setLoading] = useState('idle');
    const{rname} = historyState

    useEffect(() => {
        setLoading('loading')
        axios.post(`${config.smsUrl}/permission/retrieve_all_roles`).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const list = !dt.role_list ? [] : dt.role_list;
            const ls = list.map(item => ({
                check: false,
                open:true,
                module_name: item.module_name,
                params: item.params
            }))
            setRows(ls)
            setLoading('success')
        }).catch(err => {
            setLoading('error')
            logoutErrorMessage(err, null, actions)
        })
    }, []);

    useEffect(()=>{
        if(!role_id){
            return
        }
        axios.get(`${config.smsUrl}/permission/edit_role`,{params:{role_id}}).then(res=>{
            const data = res.data;
            const dt = !data ? [] : data;
            const ls = dt.map(item => {
                const isOpen = item.params.some(item=>item.create || item.delete || item.read || item.update)
                    return {
                        check: false,
                        open:isOpen,
                        module_name:item.module_name,
                        params:item.params
                    }
                })
            setPermission(ls);
        }).catch(err=>{
            logoutErrorMessage(err,null, actions)
        })
    },[role_id]);

    useEffect(()=>{
        setName(rname);
        setRows(permission)
    },[permission, rname]);

    const handleChangeRoleName = event => {
        setName(event.target.value);
    };

    const handleOpen = (idx) =>{
        const itemsArr = rows.map((item, index)=>{
            if(index === idx){
                return {...item,open:!item.open}
            }else{
                return item
            }
        })
        setRows(itemsArr)
    }

    const handleChangeCheckAll = (event, index) => {
        const {checked} = event.target;
        const arr = rows.map((item, idx) => {
            if (index === idx) {
                return {
                    ...item, check: checked,
                    params: item.params.map(it => {
                        return {...it, create: checked, read: checked, update: checked, delete: checked,checkedRow:checked}
                    })
                }
            }
            return item
        })
        setRows(arr)
    }

    const handleCheckRow = (event, index,  ind) => {
        const {checked} = event.target;
        const arr = rows.map((item, idx) => {
            if (index === idx) {
                return {
                    ...item,
                    params: item.params.map((it, i) => {
                        if (i === ind) {
                            return {...it, create: checked, read: checked, update: checked, delete: checked,checkedRow:checked}
                        }
                        return it
                    })
                }
            }
            return item
        })
        setRows(arr)
    };

    const handleChangeCheck = (event, index,  ind) => {
        const {checked, name} = event.target;
        const arr = rows.map((item, idx) => {
            if (index === idx) {
                return {
                    ...item,
                    params: item.params.map((it, i) => {
                        if (i === ind) {
                            return {...it, [name]: checked}
                        }
                        return it
                    })
                }
            }
            return item
        })
        setRows(arr)
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('role_id', role_id);
        formData.append('role_name', role_name);
        rows.forEach(row => {
            row.params.forEach(module => {
                formData.append('sub_module_id', module.sub_module_id ? module.sub_module_id : '');
                formData.append('create', module.create ?  +(module.create) : '');
                formData.append('read', module.read ? +(module.read) : '');
                formData.append('update',module.update ?  +(module.update):'');
                formData.append('delete',module.delete ?  +(module.delete) : '');
            })
        });
        const data = rows.map(row => {
            return (
                row.params.map(module => ({
                    sub_module_id: module.sub_module_id && module.sub_module_id,
                    create: +(module.create),
                    read: +(module.read),
                    update: +(module.update),
                    delete: +(module.delete)
                }))
            )
        });
        setSubmitted(true)
        const url = role_id ? 'update':'create'
        const dataSent = role_id ? formData : {role_name: role_name, params: data}
        if (role_name) {
            setIsSubmitted('pending');
            axios.post(`${config.smsUrl}/permission/${url}`, dataSent).then(() => {
                actions.snackbarActions.snackSuccess(`Role ${role_id ? 'updated':'added'} successfully`);
                setName('');
                setChecked(false);
                const arr = rows.map(item=>({...item, check:false,
                    params:item.params.map(row=>({
                        ...row,
                        checkedRow:false,
                        create:false,
                        read:false,
                        update:false,
                        delete:false
                    }))

                }))
                setRows(arr);
                setIsSubmitted('resolved');
                setTimeout(()=>{
                    history.push('/roles')
                }, 2000)
            }).catch(err => {
                errorMessages(err, null, actions);
                setIsSubmitted('rejected');
            })
        } else {
            window.scrollTo(0, 0)
        }
    };

    const handleReset = () => {
        setName('');
        setChecked(false);
        const arr = rows.map(item=>({check:false,
            params:item.params.map(row=>({
                create:false,
                read:false,
                update:false,
                delete:false
            }))

        }))
        setRows(arr);
        setSubmitted(false)
    };

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const isLoading = loading === 'loading'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'

    return {
        rows, checked, role_name, submitted, isSubmitted, loading, handleChangeRoleName, setName, setRows,
        handleChangeCheckAll, handleChangeCheck, handleSubmit, handleReset, closeSnackbar,
        handleOpen, handleCheckRow, isSuccess, isLoading, isError
    }
}