import {useEffect, useState} from "react";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {logoutErrorMessage} from "../../../Helpers/ErrorMessages";

export const useReceivedItems = (props) =>{
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('sl');
    const [loading, setLoading] = useState(false);
    const [receivedItems, setList] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [refresh, setRefresh] = useState(false);

    const {actions} = props;

    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const department_id = !_token.department_id ? "" : _token.department_id;

    const handleSearch = (value) => {
        setSearchValue(value)
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        setLoading(true);
        const formData = new FormData();
        formData.append('department_id', department_id)
        axios.post(`${config.smsUrl}/cinventory/get_dispatcheditems_by_department_id`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const cats = !dt.dispatch ? [] : dt.dispatch;
            setList(cats);
            setLoading(false);
        }).catch((err) => {
            logoutErrorMessage(err, null, actions);
            setLoading(false);
        })
    }, [refresh]);

    const handleRefresh = () =>{
        setRefresh(!refresh)
    }


    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    return { order, orderBy, loading, receivedItems, closeSnackbar, handleRequestSort,page, rowsPerPage,
        handleSearch, handleChangePage, handleChangeRowsPerPage,searchValue,userObj, token, _token,
        department_id, handleRefresh}
}