import { useState } from 'react';
import { titleCase } from './../../Users/addUser';
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import axios from "axios";
import { config } from "../../../Helpers/env";

export const useSurgeryEquipment =  (actions, match, _token) => {

    const initialEquipmentState = [{
        eqp_name : "", dosage : 0, quantity : 0, max_quantity:null, product_id : "", showEquipment : false, isError: false, rate:0, status: ""
    }]
    
    const [submitted, setSubmitted] = useState(false);
    const user_roles_id = !_token.user_roles_id ? 1 : _token.user_roles_id;

    // the equipment is stored as a list of objects
    const [equipment, setEquipment] = useState(initialEquipmentState);
    const {visit_id} = match.params;

    // event handler for adding a new row
    const handleAddRow = () => {
        const newRow = { eqp_name : "", dosage : 0, quantity : 0, product_id : "", max_quantity:null}
        const new_arr = [...equipment, newRow];
        setEquipment(new_arr);
    }

    // event handler for deleting the row
    const deleteRow = (index) => {
        const new_arr = equipment.filter((_, idx) => idx !== index);
        setEquipment(new_arr);
    }

    // for getting the available equipment from the database while the user is typing 
    // currently use the ones for the equipment
    const [isSubmitted, setIsSubmitted] = useState("idle");

    // setting of the equipment
    const [supplies, setSupplies] = useState([]);

    const getEquipment = (event, index) => {
        
        // for each item in the equipment, make an event listener such that when a person types
        // make an api call to see if what they are typing is present in the db
        const arr = equipment.map((item, item_index) => {
            // the function to act on each item
            // if index is the one currently being edited
            // if the user did not input an empty string
            
            if (index === item_index) {
                // because you are dealing with a form element
                const formData = new FormData();
                // get the value in the input box
                // is the title case to make sure that string can be compared whether
                // the user is using upper or lower case?
                item['eqp_name'] = event.target.value;
                formData.append('product_name', titleCase(event.target.value));
                if(submitted === true) { setSubmitted(false)}
                if (event.target.value !== '') {
                    axios.post(`${config.smsUrl}/cdoctor/autocomplete_search_medicine`, formData).then(
                        res => {
                            const resp = res.data;
                            const data = !resp ? [] : resp;

                            if (data[0] === "No Product Found") {
                                setSupplies([{value: '', label: 'No product found'}])
                            }  else {
                                setSupplies(data);
                            }

                        }
                    ).catch(error => {
                        logoutErrorMessage(error, null, actions);
                    });
                    return {...item, product_name: event.target.value, showEquipment: true, isError: false}
                } else {
                    return {eqp_name : "", dosage : 0, quantity : 0, product_id : "", max_quantity:null, product_name:'', showEquipment: false, isError: false}
                }
            }
            return item
        })

        setEquipment(arr);
        // add is submitted
        setIsSubmitted("idle");
    }

    // choose an equipment shown
    const clickEquipment = (event, idx) => {
        const arr = equipment.map((item, index) => {

            if (index === idx) {
                const formData = new FormData();
                formData.append('product_id', event.value);
                formData.append('location', 1); // 1 for store
                formData.append('to_store', 'Store');
                item.eqp_name = event.innerText;
                item.product_id = event.value;

                if (event.value) {
                    axios.post(`${config.smsUrl}/cdoctor/get_medicinedetails_byproduct_id`, formData).then(res => {
                        const resp = res.data;
                        const data = !resp ? {} : resp;
                        const {price, quantity} = data;

                        item.rate = price;
                        item.status = quantity;
                        item.max_quantity = parseInt(quantity);

                    }).catch(error => {
                        logoutErrorMessage(error, null, actions);
                    });
                    item.isError = false;
                } else {
                    item.isError = true;
                    item.eqp_name = '';
                }
                item.showEquipment = false;
                return item;
            }
            return item;
        })
        setEquipment(arr);
    }

    // event handler to sort out when the equipment changes
    const handleChangeItems = (event, index) => {
        // product bill detail id?
        const {name, value} = event.target;
        if(submitted === true) { setSubmitted(false)}
        const arr = equipment.map((item, idx) => {
            if (idx === index) {
                return {...item, [name]:value, isEdited: !!item.product_bill_detail_id}
            }
            return item;
        });
        setEquipment(arr);

    }

    const handleSubmitEquipment = (event) => {

        event.preventDefault();
        const arr = equipment.every(item => (item.product_id && item.max_quantity > 0 && item.quantity > 0))
        setSubmitted(true)
        if (arr){
            setIsSubmitted('pending')
            const params = {prescribed_by:user_roles_id,visit_id,bill_type:3,
            bill_details:equipment.map(item=>({
                dosage: item.dosage,
                product_id: item.product_id,
                rate: item.rate,
                quantity:+item.quantity,
                instructions:'',
                rt:''
            }))}
            axios.post(`${config.smsUrl}/cmedicationround/emergency_treatments`, {...params}).then(()=>{
                actions.snackbarActions.snackSuccess('Treatment recorded successfully')
                setIsSubmitted('resolved')
                setSubmitted(false);
                setEquipment(initialEquipmentState);

            }).catch(err=>{
                errorMessages(err, null,actions)
                setIsSubmitted('rejected')
                setSubmitted(false)
            })
        }
    };

    const isResolved = isSubmitted === 'resolved';

    return {
       equipment, handleAddRow, deleteRow, getEquipment, supplies, clickEquipment, handleChangeItems, handleSubmitEquipment,
        submitted, isSubmitted, isResolved
    }

}