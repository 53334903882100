import axios from "axios";
import { config } from "../../../Helpers/env";
import {useEffect, useState} from "react";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {history} from "../../../Helpers/history";

export const fetchEmployees = () => axios.get(`${config.smsUrl}/chumanresource/get_employees_without_roles`)
export const useAddUser = (props) => {
    const [state, setState] = useState({first_name: "", last_name: "", email: "", password: "", status: "1"});
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [employee_id, setEmployee] = useState("");
    const [employees, setEmployees] = useState([]);
    const [employee_email, setEmail] = useState('')
    const {actions} = props;


    useEffect(() => {
        fetchEmployees().then(res => {
            const data = res.data;
            const dt = !data ? [] : data;
            setEmployees(dt);
        }).catch((err) => {
            logoutErrorMessage(err, null, actions)
        })
    }, []);

    const handleChange = (event) => {
        const {name, value} = event.target;
        setState({...state, [name]: value})
    };

    const handleChangeEmployee = value => {
        setEmployee(value);
        const formData = new FormData();
        formData.append('employee_id', value.value);
        axios.post(`${config.smsUrl}/chumanresource/employee_update_form`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const {email} = dt;
            const em = email ? email : ''
            setEmail(em)
        }).catch(error => {
            logoutErrorMessage(error, null, actions)
        })
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const {password, status} = state;
        const formData = new FormData();
        formData.append('employee_id', employee_id.value);
        formData.append('user_name', employee_email);
        formData.append('password', password);
        formData.append('patient_id', 1);
        formData.append('security_code', 1);
        formData.append('status', status);
        setSubmitted(true);
        if (employee_id && employee_email && password && status && password.length >= 6) {
            setIsSubmitted('pending');
            axios.post(`${config.smsUrl}/cuser/insert_user`, formData).then(() => {
              
                actions.snackbarActions.snackSuccess('User added successfully');
                setIsSubmitted('resolved');
                history.push('/users')
            }).catch(err => {
                errorMessages(err, null, actions)
                setIsSubmitted('rejected');
            })
        }
    };

    const handleReset = () => {
        setState({first_name: "", last_name: "", email: "", password: "", status: ""})
    };

    const handleCloseSnackbar = () => {
        actions.snackbarActions.hideSnackbar();
    };

    return {state, submitted, isSubmitted, employee_id, employees, employee_email, handleChange,
        handleChangeEmployee, handleReset, handleSubmit, handleCloseSnackbar,setState,setEmployee,
        setEmail}
}