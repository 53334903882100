import React, { useEffect, useState } from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import * as snackbarActions from "../../../actions/snackbarActions"
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Label from "../../Utils/FormInputs/Label";
import { BackButton } from "../../Utils/Buttons/DataExportationButton";
import { Container } from "../../Utils/styledComponents"
import axios from "axios";
import { config } from "../../../Helpers/env";
import { errorMessages } from "../../../Helpers/ErrorMessages";
import {CustomTableCell, MuiPaper, StickyHeadTable} from "../../Utils/Tables/StickyHeaderTable";
import { LoadingGif } from "../../Utils/Loader";
import { formatDate } from "../../Utils/ConvertDate";
import { formatDigits } from "../../Utils/formatNumbers";
import PageTitle from "../../Utils/smsTitle";
import { SubHeader } from "../../../Containers/SubHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardList } from "@fortawesome/free-solid-svg-icons/faClipboardList";
import styled from "@emotion/styled/macro";
import * as colors from "../../../styles/colors";
import SelectInput from "../../Utils/FormInputs/SelectInput";
import { DateTimeFilter } from "../../Utils/ReusableComponents/DateTimeFilter";
import {history} from "../../../Helpers/history";
import {ReusableRouteTabs} from "../../Utils/Dialogs/ReusableTabs";
import {CustomTablePagination, EnhancedSearchTableToolbar} from "../../Utils/Tables/CustomTable";



const statusVariants = {
    'positive': {
        color: colors.deepGreen
    },
    'negative': {
        color: colors.deepOrange
    },
    'today': {
        color: colors.blue
    },
}

const rowVariants = {
    'positive': {
        // color: colors.base,
        background: colors.base
    },
    'negative': {
        td: {
            color: colors.base,
            fontWeight: 700
        },
        background: colors.deepRed
    },
    'today': {
        td: {
            color: colors.base,
        },

        background: colors.blue
    },
}


const Status = styled.span({
    display: 'inline-block'
}, ({ variant }) => statusVariants[variant])

const StyledRow = styled(TableRow)({
    verticalAlign: 'center'
}, ({ variant }) => rowVariants[variant])


const headData = [
    { id: 'sl', numeric: false, disablePadding: false, label: 'Sl' },
    { id: 'date', numeric: false, disablePadding: false, label: 'Date' },
    { id: 'supplier', numeric: false, disablePadding: false, label: 'Supplier Name' },
    { id: 'due_date', numeric: false, disablePadding: false, label: 'Due Date' },
    { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
    { id: 'amount', numeric: false, disablePadding: false, label: 'Amount' },
];

const AccountsPayables = ({ actions, snackbars, match: { params } }) => {
    const [data, setData] = React.useState({ debtors: [], loading: 'idle', total_count:0 });
    const [state, setState] = React.useState({ start_date: '', end_date: '' });
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [submitted, setSubmitted] = React.useState(false);
    const [isSubmitted, setIsSubmitted] = React.useState('idle');
    const [activePage, setActivePage] = React.useState(1);
    const [status, setStatus] = React.useState('all')
    const [term, setTerm] = React.useState('');

    const { debtors, loading, total_count } = data
    const { component } = params
    const isCustomer = component === 'customer'

    const { start_date, end_date } = state

    const isResolved = isSubmitted === 'resolved'



    useEffect(() => {
        setData({ ...data, loading: 'pending' })

        axios
            .get(
                `${config.smsUrl}/csupplier/supplier_payables`,
                { params: { status, page:activePage, search:term, start_date, end_date } })
            .then(res => {
                const response = res.data;
                const dt = response ? response : {};
                const data = dt.data ? dt.data : {}
                const arr = data.payables ? data.payables : []
                const count = arr.length
                const dataArr = arr.map(item => ({ ...item, id: item.id }))

                setData({ debtors: dataArr, loading: 'success', total_count: count })
            }).catch(err => {
                errorMessages(err, null, actions)
                setData({ ...data, loading: 'error' })

            })
    }, [isResolved, status, activePage, term, start_date, end_date])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setActivePage(newPage + 1)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        setActivePage(1)
    };

    const handleChange = (event) => {
        const { value, name } = event.target;
        setState({ ...state, [name]: value })
    }

    const handleChangeStatus = (event) => {
        setStatus(event.target.value)
    }

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar();
    };

    const handler = (event) => {
        setTerm(event.target.value)
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        setSubmitted(true)
        if (start_date && end_date) {
            setIsSubmitted('resolved')
        }
    }


    const isLoading = loading === 'pending'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'

    const isPending = isSubmitted === 'pending'

    const { openBar, type, message } = snackbars;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, debtors.length - page * rowsPerPage);

    const components = [
        {label: 'Unpaid Suppliers', path: '/creditors/report'},
        {label: 'Paid Suppliers', path: '/creditors/paidsuppliers'},
    ]

    const pagination = (
        <CustomTablePagination
            colSpan={3}
            activePage={activePage}
            page={page}
            rowsPerPage={rowsPerPage}
            count={total_count}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            isTotalCount
        />
    );

    const search_bar = (
        <EnhancedSearchTableToolbar
            term={term} handler={handler}
        />
    )
    return (
        <div>
            <PageTitle title="Supplier Payables" />

            <SubHeader title="Reports" subTitle="Supplier Payables">
                <FontAwesomeIcon icon={faClipboardList} />
            </SubHeader>

            <Container>
                {!isCustomer ?
                    <div className='mb-3'>
                        <BackButton to='/reports/generalreports' text='General Reports' />
                    </div>
                    : null
                }

                {!isCustomer ? <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/> :  null}

                <DateTimeFilter {...{ start_date, end_date, handleChange, submitted, handleSubmit, isPending, type: 'date', isFilter:false }} />
                <div className='row mb-0'>
                    <div className='col-lg-5'>
                        <Form.Group as={Row}>
                            <Label name='Filter by:' column sm={3} />
                            <Col sm={6}>
                                <SelectInput options={[{ value: 'all', text: 'All' }, { value: 'due_today', text: 'Due Today' },
                                { value: 'not_due', text: 'Not Due' }, { value: 'over_due', text: 'Over Due' }]} value={status} onChange={handleChangeStatus} />
                            </Col>
                        </Form.Group>
                    </div>
                </div>

                <MainSnackbar open={openBar} message={message} variant={type} handleCloseBar={closeSnackbar} />

                <MuiPaper>
                    {search_bar}
                    <StickyHeadTable {...{
                        width: '100%', page, rowsPerPage, handleChangePage, handleChangeRowsPerPage
                        , data: debtors, columns: headData.filter(item => Boolean(item)),
                        pagination, search_bar
                    }}>
                        {isLoading ?
                            <TableRow>
                                <TableCell
                                    align='center'
                                    {...{ colSpan: headData.length }}
                                >
                                    <LoadingGif />
                                </TableCell>
                            </TableRow>
                            : null
                        }

                        {isSuccess ? debtors.length > 0 ?
                                <>
                                    {debtors.slice(0, rowsPerPage).map((d, index) => {
                                        const payment_status = {
                                            'positive': `Payment due in ${d.days_left} days`,
                                            'negative': `Payment overdue by ${d.days_left} days`,
                                            'today': 'Payment due today'
                                        }
                                        const count = ((activePage - 1) * 10) + index + 1;
                                        return (
                                            <StyledRow
                                                // variant={d.status}
                                                tabIndex={-1}
                                                key={index}
                                            >
                                                <CustomTableCell>
                                                    {count}
                                                </CustomTableCell>
                                                <CustomTableCell
                                                    className='mui-table-cell'>
                                                    {d.date_added ? formatDate(d.date_added) : d.date_added}
                                                </CustomTableCell>

                                                <CustomTableCell>
                                                    <Link
                                                        style={{
                                                            // color: d.status === 'negative' ? '#fff' : '#000',
                                                            color: "#37a7b0",
                                                            textDecoration: 'underline'
                                                        }}
                                                        to={{ pathname: `/creditdetails/${d.id}` }}
                                                    >
                                                        {d.supplier_name}
                                                    </Link>
                                                </CustomTableCell>

                                                <CustomTableCell>
                                                    {d.expected_date ? formatDate(d.expected_date) : d.expected_date}
                                                </CustomTableCell>

                                                <CustomTableCell
                                                    style={{ color: d.status === "negative" ? "#FF0000" : "#000" }}
                                                >
                                                    {payment_status[d.status]}
                                                </CustomTableCell>

                                                <CustomTableCell>
                                                    {d.currency}
                                                    {d['Total Amount'] ? formatDigits(d['Total Amount']) : d['Total Amount']}
                                                </CustomTableCell>
                                            </StyledRow>
                                        );
                                    })}

                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 43 * emptyRows }}>
                                            <TableCell colSpan={headData.length} />
                                        </TableRow>
                                    )}

                                </>
                                :
                                <TableRow>
                                    <TableCell
                                        align='center'
                                        {...{ colSpan: headData.length }}
                                    >
                                        No records found
                                    </TableCell>
                                </TableRow>
                            : null
                        }

                        {isError ?
                            <TableRow>
                                <TableCell
                                    align='center'
                                    {...{ colSpan: headData.length }}
                                >
                                    The server did not return a valid response
                                </TableCell>
                            </TableRow>
                            : null
                        }
                    </StickyHeadTable>
                </MuiPaper>
            </Container>
        </div>
    )
};

function mapStateToProps(state) {
    return { snackbars: state.snackbar }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountsPayables);
