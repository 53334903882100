import {useEffect, useState} from "react";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {history} from "../../../Helpers/history";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";

const illnesses = [{disease:'Cardiac Disease',name:'cardiac_disease', cardiac_disease:false},
    {disease:'Kidney Disease', name:'kidney_disease', kidney_disease:false},
    {disease:'Hypertension',name:'hypertension', hypertension:false},
    {disease:'TB', name:'tb', tb:false},
    {disease:'Sickle Cell Disease',name:'sickle_cell_disease', sickle_cell_disease:false},
    {disease:'Epilepsy (seizures)', name:'epilepsy', epilepsy:false},
    {disease:'Diabetes', name:'diabetes', diabetes:false}]


export const useTriage = (actions, patient_number, visit_id, component) => {
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;

    const [triageList, setTriageList] = useState([]);
    const [triage, setTriage] = useState([])
    const [{weight_metric_id, weight_metric_name}, setWeight] = useState({weight_metric_id: 2, weight_metric_name: ''});
    const [{height_metric_id, height_metric_name}, setHeight] = useState({height_metric_id: 1, height_metric_name: ''});
    const [bmi, setBMI] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [allMetrics, setAllMetrics] = useState([])
    const [patient, setPatient] = useState({})
    const [otherMetric, setOtherMetric] = useState('');
    const [others, setOthers] = useState([]);
    const [optionalMetrics, setOptionalMetrics] = useState([]);
    const [state, setState] = useState({other_diseases: '',allergy:'', allergies:''});
    const [diseases, setDiseases] = useState(illnesses)


    useEffect(() => {
            axios.get(`${config.smsUrl}/cvisit/retrieve_all_metrics`).then(res => {
                const data = res.data;
                const dt = data ? data : {};
                    const metrics = dt.metrics ? dt.metrics : [];
                    setAllMetrics(metrics)
            }).catch(err => {
                logoutErrorMessage(err, null, actions)
            })

    }, [])


    useEffect(() => {
        const formData = new FormData();
        formData.append('patient_number', patient_number);
        axios.post(`${config.smsUrl}/cpatient/patient_update_form`, formData).then(response => {
            const data = response.data;
            const pt = !data ? {} : data;
            setPatient(pt)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })

    }, [])


    useEffect(() => {
        const filteredArr = allMetrics.filter(item => item.metric_id > 2);
        const tArr = filteredArr.slice(0, 1).map(item => ({
            ...item,
            metric_id: item.metric_id,
            metric_name: `${item.metric_name}(${item.uom ? item.uom : ''})`,
            metric_value: '', name: `metric_${item.metric_id}`
        }))
      
        setTriage(tArr)
        const tList = filteredArr.slice(1, 3).map(item => ({
            ...item,
            metric_id: item.metric_id,
            metric_name: `${item.metric_name}(${item.uom ? item.uom : ''})`,
            metric_value: '', name: `metric_${item.metric_id}`
        }))
        const other_metrics = filteredArr.slice(3).map(item => ({
            ...item,
            value: item.metric_id,
            label: `${item.metric_name}(${item.uom ? item.uom : ''})`,
            metric_value:''
        }))
        setOthers(other_metrics);
        setTriageList(tList)
    }, [allMetrics])

    const handleChange = (event, index) => {
        const triageCopy = [...triage];
        const {value} = event.target;
        triageCopy[index].metric_value = value;
        setTriage(triageCopy)
        setIsSubmitted('idle')
    };

    const handleChangeState = (event) => {
        const {name, value} = event.target
        setState({...state,[name]:value})
    };


    const handleChangeDisease = (event, index) =>{
        const {checked} = event.target
        const arr = diseases.map((item, idx)=>{
            if(index === idx){
                return {...item, [item.name]:checked}
            }
            return item
        })
        setDiseases(arr)
    }

    const handleChangeOthersMetrics = (value) => {
        setOtherMetric(value)
        const obj = {};
        obj['metric_value'] = '';
        obj['metric_id'] = value.value;
        obj['metric_name'] = value.label;
        const existingIds = optionalMetrics.map((id) => id.metric_id)
        if (!existingIds.includes(value.value)) {
            setOptionalMetrics([...optionalMetrics, obj])
        }
        setIsSubmitted('idle')

        const arr = others.map(item=>{
            if (item.value === value.value){
                return {...item, metric_value:value.value}
            }
            return item
        })
        setOthers(arr)

    }

    const handleChangeOptional = (event, metric_id) => {
        const arr = optionalMetrics.map(metric => {
            if (metric.metric_id === metric_id) {
                return {...metric, metric_value: event.target.value}
            }
            return metric
        })
        setOptionalMetrics(arr)
        setIsSubmitted('idle')
    }

    const removeMetric = (metric_id) =>{
        const arr = optionalMetrics.filter(item=>item.metric_id !== metric_id);
        setOptionalMetrics(arr)
    }

    const handleChangeVitals = (event, index) => {
        const triageCopy = [...triageList];
        const {value} = event.target;
        triageCopy[index].metric_value = value;
        setTriageList(triageCopy)
        setIsSubmitted('idle')
    };

    const calculateBMI = (weight, height) => {
        const h = height ? height : 0;
        if (h > 0) {
            setBMI(((+weight / Math.pow(+h, 2)) * 10000).toFixed(2))
        } else {
            setBMI(0)
        }

    }

    const handleChangeWeight = (event) => {
        const {value} = event.target;
        setWeight(currentState => ({...currentState, weight_metric_name: value}))
        calculateBMI(value, height_metric_name)
        setIsSubmitted('idle')
    }

    const handleChangeHeight = (event) => {
        const {value} = event.target;
        setHeight(currentState => ({...currentState, height_metric_name: value}))
        calculateBMI(weight_metric_name, value)
        setIsSubmitted('idle')
    }

    const handleSubmitTriage = (event) => {
        event.preventDefault();
        setSubmitted(true);

        const chronic_object = diseases.reduce(
            (obj, item) => Object.assign(obj, { [item.name]: item[item.name] }), {});

        const {allergies,other_diseases,allergy} = state

        const others_arr =  others.filter(metric => !metric.metric_value)
        const triage_arr = [...triageList,...triage,...optionalMetrics].filter(metric => metric.metric_value && metric.metric_id)
        const jsonData = {
            'patient_number':patient_number,
            'metric_type_id':1,
            'visit_id' :visit_id,
            'visit_state':3,
            'user_roles_id' : user_roles_id,
            activity_id:'',
            'chronic_illness': {...chronic_object, other_chronic_illness:other_diseases},
            allergies,
            allergy,
            triage_info : [
                ...triage_arr,...others_arr,
                {
                    'metric_id': height_metric_id,
                    'metric_value': height_metric_name
                },
                {
                    'metric_id': weight_metric_id,
                    'metric_value': weight_metric_name
                }

            ]
        }
        const arr = triageList.filter(metric=>!(metric.metric_id === 6 || metric.metric_id === 5)).every(metric=>metric.metric_value);
        const arr1 = triage.every(metric => metric.metric_value)
        if (patient_number && ((component === 'triage') || component !== 'triage')) {
            setIsSubmitted(true);
            axios.post(`${config.smsUrl}/cvisit/add_triage`, jsonData).then(res => {
                if (component === 'triage') {
                    history.push('/appointments/upcoming');
                } else {
                    history.push('/patientroundlist');
                }
                actions.snackbarActions.snackSuccess('Triage Information added successful');
                setIsSubmitted('resolved');
            }).catch((err) => {
                errorMessages(err, null, actions)
                setIsSubmitted('rejected');
            });
        }
    };
    const handleResetForm = () => {
        const triageCopy = triageList.map(triage => ({
            metric_value: "",
            metric_name: triage.metric_name,
            metric_id: triage.metric_id,
            name: triage.name
        }));
        setTriageList(triageCopy);
        setSubmitted(false)
    };

    const handleCloseSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    return {
        triageList, submitted, isSubmitted, handleChange, handleSubmitTriage, handleResetForm,
        handleCloseSnackbar, handleChangeWeight, handleChangeVitals, handleChangeHeight, bmi,
        weight_metric_name, height_metric_name, triage,removeMetric,
        optionalMetrics, handleChangeOptional,
        otherMetric, others, handleChangeOthersMetrics, patient,
        state, diseases, handleChangeDisease, handleChangeState
    }
}
