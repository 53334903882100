import React from "react";
import CustomTable, {useHiddenColumns} from "../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";


const MainRadiologyTable = ({activePage,total_count,all_scan_requests,title,
    handleChangeActivePage, term, radiologistList,handler,isLoading, isSuccess, isRejected,
                            }) => {
    const {
        headCells, all_hidden, handleAllHeadCells, handleHeadCells
    } = useHiddenColumns(radiologistList);
    return (
        <div className="mui-tables">
        <CustomTable title={title} handler={handler}
                      headData={headCells} data={all_scan_requests}
                     colSpan={headCells.length}  customPage activePage={activePage}
                     total_count={total_count} term={term}
                     handleChangeNextPage={handleChangeActivePage} records={10}
                     {...{all_hidden, handleAllHeadCells, handleHeadCells}}>
                <TableBody>
                    {isLoading ? <TableRow>
                        <TableCell colSpan={headCells.length} align="center"><img src="/images/smsloader.gif"
                                                                alt="loader"/></TableCell>
                    </TableRow>: null}
                    {isSuccess ? all_scan_requests.length > 0 ? all_scan_requests.slice(0, 10)
                        .map((request) => {
                            return (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={request.scan_requests_detail_id}
                                    className='mui-table-row' >
                                    {headCells.map((head)=>(
                                        <TableCell id={head.id}  key={head.id}
                                                   hidden={head.hidden}>{request[head.id]}</TableCell>
                                    ))}
                                </TableRow>
                            );
                        }):<TableRow>
                        <TableCell colSpan={headCells.length} align="center">No records found</TableCell>
                    </TableRow>:null}
                    {isRejected ? <TableRow>
                        <TableCell colSpan={headCells.length} align="center">The server did not return a valid response</TableCell>
                    </TableRow>:null}
                </TableBody>
        </CustomTable>
        </div>
    )
};
export default MainRadiologyTable;
