import React from "react";
import PageTitle from "../Utils/smsTitle";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CustomTable from "../Utils/Tables/CustomTable";
import FilterResults from "react-filter-search";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import { Container } from "../Utils/styledComponents";
import * as snackbarActions from "../../actions/snackbarActions";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import { faCoins } from "@fortawesome/free-solid-svg-icons/faCoins";
import CustomDialog from "../Utils/Dialogs/CustomDialog";
import DeleteDialog from "../Utils/Dialogs/DeleteDialog";
import { history } from "../../Helpers/history";
import { BackButton } from "../Utils/Buttons/DataExportationButton";
import { useConsumablesList } from "./CustomHooks/useConsumablesList";
import { ReusableRouteTabs } from "../Utils/Dialogs/ReusableTabs";
import { EditConsumable, ServiceConsumables } from "./ServiceConsumables";


const ConsumablesList = ({actions, snackbars, match}) => {
    const {service_id, consumable_status} = match.params;
    const {openBar, type, message} = snackbars;

    const {
        handleDeactivate, handleCloseDeactivate, openDeactivate, handleOpenDeactivate,
        handleCloseEditItem, openEditItem, handleOpenEditItem, handleChangeUnit,
        openNewItems, handleCloseNewItems, handleOpenNewItems, service,
        service_consumables, service_name, serviceConsumablesProps, 
        current_item, handleItemUpdate, handleChangeQuantity, handleAddConsumables,
        closeSnackbar, handleChangeRowsPerPage, handleChangePage, handleSearch,
        searchValue, page, rowsPerPage, isLoading, isSuccess, isError, uploadProps
    } = useConsumablesList(actions, service_id, consumable_status);

    const components = [
        {label: 'Active Items', path: `/service_consumables/${service_id}/${1}`},
        {label: 'In-Active Items', path: `/service_consumables/${service_id}/${0}`},
    ]

    const {service_type} = serviceConsumablesProps;

    const headData = service_type === 'Lab' ?
    [
        {id: 'sl', numeric: false, disablePadding: false, label: 'Sl'},
        {id: 'product_name', numeric: false, disablePadding: false, label: 'Product Name'},
        {id: 'consumable_quantity', numeric: false, disablePadding: false, label: 'Quantity'},
        {id: 'unit', numeric: false, disablePadding: false, label: 'Unit'},
        {id: 'actions', numeric: false, disablePadding: false, label: 'Action'},
    ]
    : 
    [
        {id: 'sl', numeric: false, disablePadding: false, label: 'Sl'},
        {id: 'product_name', numeric: false, disablePadding: false, label: 'Product Name'},
        {id: 'consumable_quantity', numeric: false, disablePadding: false, label: 'Quantity'},
        {id: 'actions', numeric: false, disablePadding: false, label: 'Action'},
    ];
    

    return (
        <div className='journals'>
            <PageTitle title="Invoice Providers"/>
            <SubHeader title="Billing" subTitle={`Consumables for Service: ${service_name}`}>
                <FontAwesomeIcon icon={faCoins}/>
            </SubHeader>

            {/*  Edit Item dialog box */}
            <CustomDialog open={openEditItem} handleClose={handleCloseEditItem} title="Edit Consumable" maxWidth="lg">
                <EditConsumable current_item={current_item} handleItemUpdate={handleItemUpdate} handleChangeQuantity={handleChangeQuantity} 
                uploadProps={uploadProps} service_type={service_type} handleChangeUnit={handleChangeUnit}/>
            </CustomDialog>

            {/*  Add Consumables dialog box */}
            <CustomDialog open={openNewItems} handleClose={handleCloseNewItems} title="Add Consumables" maxWidth="lg">
                <ServiceConsumables {...serviceConsumablesProps}/>
                <button className="btn sms-btn btn-sm" data-testid="add-consumables-dialog" onClick={handleAddConsumables}>Save Consumables</button>  
            </CustomDialog>


            {/*  Delete dialog box */}
            <DeleteDialog message={current_item.status === 0 ? `activate` : `delete`} openDialog={openDeactivate} handleClose={handleCloseDeactivate}
            text={"item"} title={current_item.status === 0 ? `Activate Consumable` : `Deactivate Consumable`} testId={"deactivate-activate-dialog"}>
                <button className='btn sms-btn-dismiss btn-sm' data-testid="deactivate-activate-confirm" onClick={handleDeactivate}>{current_item.status === 0 ? `Activate` : `Deactivate`}</button>
                <button className="btn sms-grey-btn btn-sm" data-testid="deny-deactivate-activate" onClick={handleCloseDeactivate}>No</button>    
            </DeleteDialog>

            <div style={{display:'flex', justifyContent:'space-between'}}>
                <div style={{paddingLeft: '44.46px', paddingTop:'15px'}}>
                    <BackButton to={"/servicelist/1"} text={'Services'}/>
                </div>
                <div className="text-right"  style={{padding: '10px 44.46px'}}>
                    <button onClick={()=>handleOpenNewItems(service)} className="btn sms-btn"><FontAwesomeIcon icon={faPlus}/> Add Consumables</button>
                </div>
            </div>

            <Container>
                <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/>
                <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>
                <CustomTable handleChangePage={handleChangePage}
                             handleChangeRowsPerPage={handleChangeRowsPerPage}
                             headData={headData} handler={handleSearch} page={page} rowsPerPage={rowsPerPage}
                             data={service_consumables} colSpan={headData.length}
                             id="searchValue" filter >
                    <FilterResults value={searchValue} data={service_consumables}
                        renderResults={results => (
                        <TableBody id="consumables-list">
                            {isLoading ? 
                            <TableRow>
                                <TableCell colSpan={headData.length} align="center"><img src="/images/smsloader.gif"
                                                                    alt="loader"/></TableCell>
                            </TableRow>:null}
                            {isSuccess ?
                                (service_consumables.length && service_consumables.length > 0) ?
                                results.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((item, index) => {
                                    const sl = (page  * rowsPerPage) +  index + 1;
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={index}
                                            className='mui-table-row'
                                            id="services"
                                        >
                                            <TableCell>{sl}</TableCell>
                                            <TableCell data-testid={"product-name"+index}>{item.product_name}</TableCell>
                                            <TableCell>{item.quantity}</TableCell>
                                            {service_type === 'Lab' ? 
                                            <TableCell>{item.unit}</TableCell>
                                            : null}
                                            <TableCell data-testid={"consumables-action-"+index}>
                                                {consumable_status === '1' ?
                                                    <button className="btn btn-sm sms-info-btn" onClick={()=>handleOpenEditItem(index, item)}
                                                        data-testid={"edit-consumable-"+index}
                                                        style={{marginRight: '10px', marginBottom: '5px'}}>Edit</button> : null}

                                                    <button className="btn btn-sm sms-btn-dismiss" onClick={()=>handleOpenDeactivate(index, item)}
                                                        data-testid={"deactivate-activate-"+index}
                                                        style={{marginRight: '10px', marginBottom: '5px'}}>
                                                            {consumable_status === '1' ? 'Deactivate' : 'Activate'}
                                                    </button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                }):
                                <TableRow>
                                    <TableCell colSpan={headData.length} align="center">No records found</TableCell>
                                </TableRow>:null}
                            {isError ? <TableRow>
                                <TableCell colSpan={headData.length} align="center">The server did not return a valid response</TableCell>
                            </TableRow>:null}
                        </TableBody>
                    )}/>
                </CustomTable>
            </Container>
        </div>
    )
}

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsumablesList);
