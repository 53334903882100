/** @jsxImportSource @emotion/react */
import React, {useEffect, useState} from 'react'
import {css} from '@emotion/react';
import {detailsTable, smallTable, table, textWeight} from "../../styles/tableStyles";
import {PrintTemplate} from "../Utils/Templates/PrintTemplate";
import styled from "@emotion/styled/macro";
import axios from "axios";
import {config} from "../../Helpers/env";


export const heading = css`
    display: inline-block;
    text-decoration: underline;
`



const widths= {
    '100':{
        width:'100%'
    },
    '80':{
        width:'80%'
    },
    '60':{
        width:'60%'
    },
    '40':{
        width:'40%'
    },
    '20':{
        width:'20%'
    },
    '10':{
        width:'10%'
    },
    '5':{
        width:'5%'
    }
}
const ResultSpan = styled.p({
    // display: 'inline-block',
    borderBottom: '2px dotted #807d7d',
    margin:'5px 0 0 0',
    // marginLeft:' 5px'
    lineHeight:2
},({width})=>widths[width])


const splitText = (txt = '') =>{
    const nt = txt ? txt : '';
    return nt.split('\n').map(text=><ResultSpan width='100'>{text}</ResultSpan>)
}


export function DentalServiceResults(props) {
    const {dental_services} = props
    return(
        <table css={[table,smallTable]}>
            {props.title ? props.title: null}
            <tbody>
            {dental_services?.map(item=>{
                return(
                    <tr key={item.service_id}>
                        <td><p style={{margin:'20px 0 0 0'}}>{item.service_name} {splitText(item.results)}</p></td>
                    </tr>
                )
            })}
            </tbody>
        </table>
    )


}

function DentalServiceReport(props) {
    const {patient_number} = props

    const [patient, setPatient] = useState({})


    useEffect (() => {
        const formData = new FormData();
        formData.append('patient_number', patient_number)
        axios.post(`${config.smsUrl}/cpatient/patient_update_form`, formData).then(response => {
            const patient = response.data;
            const patientDetails = !patient ? {} : patient;
            setPatient({
                ...patientDetails,
                patient_name: `${!patientDetails.first_name ? "" : patientDetails.first_name} ${!patientDetails.last_name ? "" : patientDetails.last_name}`,
                age: patientDetails.Age,
                gender: patientDetails.gender,
                address: patientDetails.address,
                phone: patientDetails.phone_no
            })

        }).catch(err => {
            // logoutErrorMessage(err, null, actions)
        } )

    },[patient_number])

    const patientDetails = (
        <tbody>
        <tr>
            <td><span>Patient No.: </span> <span css={[textWeight]}>{patient?.patient_number}
                    </span> </td>
            <td><span>Age:</span> <span css={[textWeight]}>{patient?.age}</span></td>

        </tr>

        <tr>

            <td><span>Patient Name: </span> <span css={[textWeight]}>{patient?.patient_name}</span></td>
            <td><span>Phone No.:</span> <span css={[textWeight]}>{patient?.phone}</span></td>
        </tr>
        <tr>
            <td><span>Gender: </span> <span css={[textWeight]}>{patient?.gender}</span></td>
            <td><span>Address:</span> <span css={[textWeight]}>{patient?.address}</span></td>
        </tr>
        </tbody>
    )

    return(
        <div>
            <PrintTemplate  {...{patientDetails,itemDetails:<DentalServiceResults {...{...props}}/>}}/>
        </div>
    )
}
export default DentalServiceReport