import {useState} from "react";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {errorMessages} from "../../../Helpers/ErrorMessages";



function useFileUploader(actions, obj) {
    const [selectedFile, setSelectedFile] = useState(null);
    const [openCsvDialog, setOpenCsvDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState('idle')
    const [item_type, setItemType] = useState(1)
    const {url, download_url, file_name, params} = obj

    const handleFileUpload = event => {
        setSelectedFile(event.target.files[0]);
    };

    const handleOpenCsvDialog = (type) => {
        setOpenCsvDialog(true)
        setItemType(type=== 'Service'? 1 : type === 'Product' ? 2 : type === 'Room'? 3: '')
    };
    const handleCloseCsvDialog = () => {
        setOpenCsvDialog(false)
    };

    const downloadTemplate = () =>{
        const otherParams = params ? params : {}
        axios.get(`${config.smsUrl}/${download_url}`, {params:{download:true, item_type: item_type, ...otherParams},responseType: 'blob'}).then(response=>{
            const data  = response.data;
            const url = URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href =  url;
            link.setAttribute('download', `${file_name}.csv`);
            document.body.appendChild(link);
            link.click();
        }).catch(err=>{
            errorMessages(err, null,actions)

        })
    }

    const fileUploader = (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('upload_csv_file', selectedFile ?? '');
        setSubmitted(true)
        if(selectedFile){
            setIsSubmitted('pending')
            axios.post(`${config.smsUrl}/${url}`, formData).then(() => {
                actions.snackbarActions.snackSuccess('File uploaded successfully')
                setIsSubmitted('resolved')
                setOpenCsvDialog(false)
            }).catch((error) => {
                errorMessages(error, null, actions)
                setIsSubmitted('rejected')
            })
        }

    };

    const isPending = isSubmitted === 'pending'
    const isResolved = isSubmitted === 'resolved'

    const uploadProps = {openCsvDialog, handleFileUpload, handleOpenCsvDialog, handleCloseCsvDialog,
        fileUploader, selectedFile, isPending,submitted, downloadTemplate}

    return {uploadProps, isResolved}
}
export {useFileUploader}