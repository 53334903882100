/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PageTitle from "../../Utils/smsTitle";
import Card from "@material-ui/core/Card";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../../actions/snackbarActions"
import {titleCase} from "../../Users/addUser";
import {faNotesMedical} from "@fortawesome/free-solid-svg-icons/faNotesMedical";
import {useDischargeNote} from "./hooks/useDischargeNote";
import {dateStringConvert} from "../../Utils/ConvertDate";
import {splitNotes} from "../../Radiology/RadiologyReport";
import { MiniCenteredDiv, RightAlignedContainer,Button } from "../../Utils/styledComponents";
import {ItemsTable, PrintTemplate} from "../../Utils/Templates/PrintTemplate";
import { usePrint } from "../../Utils/Templates/usePrint";
import {table, tableBordered, tableMargin, smallUpperCase, detailsTable} from '../../../styles/tableStyles';
import * as colors from "../../../styles/colors"
import {BackButton} from "../../Utils/Buttons/DataExportationButton";
import {DischargeNotesTemplate} from "../../MedicationandVisits/DischargeNoteTemplate";
import React from "react";

const notesText = css`
    display: inline-block;
  margin-bottom: 0.8rem;
`

const titleText = css`
  display: inline-block;
  border-bottom: 1.99px solid ${colors.text00};
`

/**
 * Component that displays the discharge details of an admitted patient
 * @param {*} props 
 * @returns 
 */
export function DischargeNote(props) {

    const {visit_id} = props.match.params;

    // const footer = (
    //    <>
    //     <table css={[table, tableMargin]}>
    //         <tr>
    //             <td>
    //                 <span>Discharged By</span></td>
    //             <td align="center">
    //                 <span>Signature &amp; Stamp </span></td>
    //         </tr>
    //         <tr>
    //             <td width="350px" ><span
    //                 className={`doctor-stamp  mt-3 `}
    //                 id="doctor-stamp"><strong>{discharging_office}</strong></span></td>
    //             <td width="350px" align="right" ><span
    //                 className={`doctor-stamp  mt-3`}
    //                 id="doctor-stamp"/></td>
    //         </tr>
    //     </table>
    //     <p className="referral-paragraph referral-bold-paragraph">Please come with this discharge note on your next  visit</p>
    //    </>
    // )
    const {handlePrint,componentRef} = usePrint('Discharge Summary')

    return (
        <div>
            <PageTitle title="Discharge Note"/>
            <SubHeader title="Visit" subTitle="Discharge Note">
                <FontAwesomeIcon icon={faNotesMedical}/>
            </SubHeader>
            <MiniCenteredDiv>
                <BackButton text='Admitted Patients' to='/patientroundlist'/>
                <RightAlignedContainer>
                    <button className="btn btn-sm sms-amber-btn small-btn mb-2" onClick={handlePrint}>Print</button>
                </RightAlignedContainer>
                <Card className='p-3'>
                    <div ref={componentRef}>
                        <DischargeNotesTemplate visit_id={visit_id} actions={props.actions}/>
                    </div>
                </Card>
            </MiniCenteredDiv>
        </div>
    )
}

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(DischargeNote);
