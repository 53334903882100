import {useState, useEffect} from "react";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";

export const useSampleTypesList = (actions) =>{
    const [searchValue, setSearchValue] = useState('');
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('sl');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [sample_type_id, setSampleId] = useState('');
    const [sampleTypes, setSampleTypes] = useState([]);
    const [loading, setLoading] = useState('idle');
    const [openDelete, setOpenDelete] = useState(false);
    const [sample_type_name, setTypeName] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [openDialog, setOpenDialog] = useState(false);
    const [sampleType, setType] = useState({});
    const [sample_id, setId] = useState('')


    const isPending = isSubmitted === 'pending';
    const isResolved = isSubmitted === 'resolved';
    const isRejected = isSubmitted === 'rejected';

    const isLoading = loading === 'pending'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'

    useEffect(() => {
        setLoading('pending');
        axios.get(`${config.smsUrl}/claboratory/get_all_sample_types`).then(res => {
            const data = res.data;
            const dt = !data ? [] : data;
            setSampleTypes(dt);
            setLoading('success')
        }).catch(err => {
            setLoading('error');
            logoutErrorMessage(err, null, actions);

        })

    }, [isResolved]);


    useEffect(() => {
        if(!sample_id){
            return
        }
        const formData = new FormData();
        formData.append('sample_type_id', sample_id);
        axios.post(`${config.smsUrl}/claboratory/sample_type_update_form`, formData).then((res) => {
            const data = res.data;
            const dt = !data ? {} : data;
            const dat = dt.data ? dt.data :{};
            setType(dat);
        }).catch(err => {
            logoutErrorMessage(err, null,actions)
        })
    }, [sample_id]);


    useEffect(() => {
        setTypeName(sampleType.type_name)
    }, [sampleType]);


    const handleChange = (event) => {
        const {value} = event.target;
        setTypeName(value)
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData();
        if(sample_id){
            formData.append('sample_type_id', sample_id);
        }
        formData.append('sample_type_name', sample_type_name);
        setSubmitted(true);
        if (sample_type_name) {
            setIsSubmitted('pending');
            const url = sample_id ? 'edit_sample_type' :'add_sample_type'
            axios.post(`${config.smsUrl}/claboratory/${url}`, formData).then(() => {
                setIsSubmitted('resolved');
                actions.snackbarActions.snackSuccess(`Sample type ${sample_id ? 'edited':'added'} successfully`);
                setOpenDialog(false)
                setTypeName('')
                setSubmitted(false)
            }).catch(err => {
                setIsSubmitted('rejected');
                errorMessages(err, null, actions)
            })
        }
    };


    const handleOpenDelete = (id) => {
        setSampleId(id);
        setOpenDelete(true)
    };

    const handleOpenDialog = (id) => {
        setId(id)
        setOpenDialog(true)
    };

    const handleCloseDialog = () => {
        setOpenDialog(false)
    };


    const handleDelete = () => {
        const formData = new FormData();
        formData.append('sample_type_id', sample_type_id);
        formData.append('sample_type_name', sample_type_name);
        axios.post(`${config.smsUrl}/claboratory/deactivate_sample_type`, formData).then(() => {
            const types = sampleType.filter(type => type.type_id !== sample_type_id);
            setSampleTypes(types);
            actions.snackbarActions.snackSuccess('Sample type deleted successfully');
            setOpenDelete(false)
        }).catch(err => {
            errorMessages(err, null, actions)
        })
    };


    const handleCloseDelete = () => {
        setOpenDelete(false)
    };

    const handleSearch = (searchValue) => {
        setSearchValue(searchValue)
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };
    return {searchValue, order, orderBy, page, rowsPerPage, handleSearch, handleRequestSort,
        handleChangePage, handleChangeRowsPerPage, closeSnackbar,sample_type_id,
        sampleTypes, openDelete, sample_type_name, submitted,isPending, isLoading, isRejected,
        isSubmitted, handleChange,  handleSubmit, handleOpenDelete,
        handleCloseDelete, handleDelete, isResolved, isSuccess, isError,
        openDialog, handleCloseDialog, handleOpenDialog, sample_id}
}