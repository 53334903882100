import React, {useState, useEffect} from "react";
import Form from "react-bootstrap/Form";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../actions/snackbarActions"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {titleCase} from "../Users/addUser";
import {SubHeader} from "../../Containers/SubHeader";
import {faXRay} from "@fortawesome/free-solid-svg-icons/faXRay";
import PageTitle from "../Utils/smsTitle";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import { config } from "../../Helpers/env";
import axios from 'axios';
import {history} from "../../Helpers/history";
import {errorMessages} from "../../Helpers/ErrorMessages";
import {useTheme} from "@material-ui/core/styles";
import PrintDialog from "../Utils/Dialogs/PrintDialog";
import {Container} from "../Utils/styledComponents";
import { PatientDetails } from "../Visit/NewService/SelectService";
import styled from "@emotion/styled/macro";
import * as mq from "../../styles/media-queries";
import { usePatientDetails } from "../DoctorsModule/ReferralNote/usePatientDetails";
import Textarea, {ResizableTextarea} from "../Utils/FormInputs/Textarea";
import TextField from "../Utils/FormInputs/TextField";
import { ErrorMessage } from "../Utils/styledComponents";
import {BackButton} from "../Utils/Buttons/DataExportationButton";



const StyledDiv = styled.div({
    [mq.medium]:{
      width:'1000px',
    },
    width:'1200px',
    margin:'20px auto'
});




const FileUpload = ({actions, snackbars, match: {params}, location: {state}}) => {
    const {visit_id,hospital_scan_id, scan_requests_detail_id, scan_request_id, patient_type,
        scan_result_id, current_date,patient_number,service_id, activity_id} = params;

    const obj = state ? state : {}
    const {scanName, purpose} = obj;

    const storage = JSON.parse(sessionStorage.getItem('radiology'))
    const item = storage ? storage : {}
    const rad_obj = item[scan_requests_detail_id] ? item[scan_requests_detail_id] : {}
    const rad_indication = rad_obj['indication'] ? rad_obj['indication'] : ''
    const findings = rad_obj['notes'] ? rad_obj['notes'] : ''
    const rad_conclusion = rad_obj['conclusion'] ? rad_obj['conclusion'] : ''
    const result_indication = () => rad_indication || purpose
    const result_findings = () => findings || ''
    const result_conclusion = () => rad_conclusion || ''

    const hospital_scan_name = !scanName ? "" : scanName;
    const [formState, setFormState] = useState({notes: result_findings(), indication: result_indication(), conclusion:result_conclusion()});
    const [submitted, setSubmitted] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [examination, setExamination] = useState({scan_name: hospital_scan_name, purpose: purpose})

    const [resultDetails, setResultDetails] = useState({});
    const [result_id, setResultId] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const {patient} = usePatientDetails(actions, patient_number, '')
    const {first_name, last_name, Age, gender} = patient;

    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;
    const technician = `${!_token.first_name ? "" : _token.first_name} ${!_token.last_name ? "" : _token.last_name}`;

    useEffect(() => {
        if (!scan_result_id) {
            return
        }
        const formData = new FormData();
        formData.append('scan_result_id', scan_result_id);
        axios.post(`${config.smsUrl}/cradiology/scanresult_update_form`, formData).then(res => {
            const data = res.data;
            const dt = data ?? {};
            const prev = dt.scan_result ? dt.scan_result : [];
            const single = prev[0] ? prev[0] : {};
            setResultDetails(single)
        }).catch(err => {
            errorMessages(err, null, actions)
        })
    }, [])

    useEffect(() => {
        if (!scan_result_id) {
            return
        }
        const {
            notes: resultNotes,
            indication: resultIndication,
            hospital_scan_name,
            purpose: scanPurpose,
            conclusion: resultConclusion
        } = resultDetails;
        setFormState({notes: resultNotes, indication: resultIndication, conclusion: resultConclusion})
        setExamination({scan_name: hospital_scan_name, purpose: scanPurpose})

    }, [resultDetails])


    const handleFormState = event => {
        const {name, value} = event.target;
        setFormState({...formState, [name]: value})
        const obj = {[scan_requests_detail_id]:{...rad_obj,[name]:value}}
        sessionStorage.setItem('radiology', JSON.stringify({...item,...obj}))
    };

    const handleFileUpload = (event) => {
        event.preventDefault();
        const {notes, conclusion, indication} = formState;
        const formData = new FormData();
        if (scan_result_id) {
            formData.append('scan_result_id', scan_result_id);
        }
        if (!scan_result_id) {
            formData.append('hospital_scan_id', hospital_scan_id);
            formData.append('scan_request_id', scan_request_id);
            formData.append('service_id', service_id);
        }
        formData.append('scan_requests_detail_id', scan_requests_detail_id);
        formData.append('user_roles_id', user_roles_id);
        formData.append('scan_request_id', scan_request_id);
        formData.append('activity_id', activity_id);
        formData.append('visit_id', visit_id);
        formData.append('scan_state', 3);
        formData.append('notes', notes ? notes : '');
        formData.append('patient_type', patient_type);
        formData.append('take_up_time', current_date ? current_date : '');
        formData.append('indication', indication ? indication : '');
        formData.append('conclusion', conclusion ? conclusion : '');
        const url = scan_result_id ? 'update_scan_result' : 'enter_scan_results'
        setSubmitted(true)
        if (notes) {
            setIsSubmitted('pending')
            axios.post(`${config.smsUrl}/cradiology/${url}`, formData).then(res => {
                const data = res.data;
                const dt = data ?? {};
                const id = dt.scan_result_id ? dt.scan_result_id : ''
                actions.snackbarActions.snackSuccess('Results added successfully')
                setIsSubmitted('resolved')
                setOpenDialog(true)
                setResultId(id)
            }).catch(err => {
                errorMessages(err, null, actions)
                setIsSubmitted('rejected')
            })
        }
    };


    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar();
    };

    const handleClick = () => {
        const route = scan_result_id ? scan_result_id : result_id
        history.push(`/viewresults/${visit_id}/${patient_number}/${scan_requests_detail_id}/${route}/${patient_type}`)
    }

    const handleCancel = () => {
        const route = scan_result_id ? '/scanresults' : '/scanradiology'
        history.push(route)
    }

    const {openBar, type, message} = snackbars;

    const {theme} = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    const {scan_name, purpose: scanPurpose} = examination;
    const {notes, conclusion, indication} = formState;
    const text = scan_result_id ? 'Scan Results' :'Scan Requests'
    const route = scan_result_id ? '/scanresults' : '/scanRadiology'
    return (
        <div className="journals">
            <PageTitle title="Record Results"/>
            <SubHeader title="Radiology" subTitle="Record Results">
                <FontAwesomeIcon icon={faXRay}/>
            </SubHeader>
            <PrintDialog handleCancel={handleCancel} openDialog={openDialog} handleClose={handleCancel}
                         handleClick={handleClick} message={`View ${scan_name} Report`}
                         text={`Would you like to view the ${scan_name} report`}/>
            <MainSnackbar handleCloseBar={closeSnackbar} open={openBar} variant={type} message={message}/>
            <Container>

                <StyledDiv>
                    <BackButton text={text} to={route}/>
                    <PatientDetails {...{patient_number,patient_name:`${first_name} ${last_name}`, age:Age, gender, visit_id}}/>
                    {/* <div className='row'>
                        <div className='col-lg-6'>
                            <Paragraph variant="p4">Examination : <strong>{titleCase(scan_name)}</strong></Paragraph>
                            <Paragraph variant="p4">Purpose:  <strong>{scanPurpose ? titleCase(scanPurpose) : scanPurpose}</strong></Paragraph>
                        </div>
                        <div className='col-lg-6'>
                            
                        </div>
                    </div> */}
                    <form autoComplete="off" onSubmit={handleFileUpload} className="mt-4">
                        {/*<div className="row">*/}
                        {/*    <div className="col-md-6">*/}
                        {/*        <Form.Group>*/}
                        {/*            <Form.Label>Examination</Form.Label>*/}
                        {/*            <Form.Control type="text" readOnly={true}*/}
                        {/*                        value={titleCase(scan_name)} id="scan_name"*/}
                        {/*                        className={`form__control ${palette.type === "dark" && 'form-control-dark'}`}/>*/}
                        {/*        </Form.Group>*/}
                        {/*    </div>*/}
                        {/*    <div className="col-md-6">*/}
                        {/*        <Form.Group>*/}
                        {/*            <Form.Label>Purpose</Form.Label>*/}
                        {/*            <Form.Control as="textarea" readOnly={true}*/}
                        {/*                        value={scanPurpose ? titleCase(scanPurpose) : scanPurpose} id="scan_name"*/}
                        {/*                        className={`form__control ${palette.type === "dark" && 'form-control-dark'}`}/>*/}
                        {/*        </Form.Group>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <Form.Group>
                            <Form.Label>Examination</Form.Label>
                            <Form.Control type="text" readOnly={true}
                                          value={titleCase(scan_name)} id="scan_name"
                                          className={`form__control ${palette.type === "dark" && 'form-control-dark'}`}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label htmlFor='indication'>Indication</Form.Label>
                            <TextField type="text"  value={indication ? indication : indication}
                                        onChange={handleFormState}
                                        id="indication" name='indication'
                                        className={`form__control ${palette.type === "dark" && 'form-control-dark'}`}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label htmlFor='notes'>Findings<span className="help-block ">*</span></Form.Label>
                            <ResizableTextarea submitted={submitted} rows="5" value={notes ? notes : ''}
                                        onChange={handleFormState}
                                        id="notes" name='notes' placeholder="Write findings...."
                                       />
                            {(submitted && !notes) && <ErrorMessage>Scan result details is required</ErrorMessage>}
                        </Form.Group>
                        <Form.Group>
                            <Form.Label htmlFor='conclusion'>Conclusion</Form.Label>
                            <ResizableTextarea as="textarea" rows="5" value={conclusion ? conclusion : conclusion}
                                        onChange={handleFormState} placeholder="Write conclusion..."
                                        id="conclusion" name='conclusion'
                                        />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Radiologist</Form.Label>
                            <Textarea type="text"
                                      value={titleCase(technician)} disabled={true} id="scan_name"
                                      />
                        </Form.Group>

                        <button type="submit" className="btn sms-btn" id="save-result"
                                    disabled={isSubmitted === 'pending' || isSubmitted === 'resolved'}>{isSubmitted === 'pending' ? "Saving..." : "Save Result"}</button>
                    </form>
                </StyledDiv>
            </Container>
        </div>
    )
}

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(FileUpload);
