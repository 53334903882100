import React from 'react'
import {StickyHeadTable, CustomTableCell, MuiPaper} from '../../Utils/Tables/StickyHeaderTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageTitle from '../../Utils/smsTitle';
import { SubHeader } from '../../../Containers/SubHeader';
import { dateConvert, formatDate } from '../../Utils/ConvertDate';
import axios from 'axios';
import { config } from "../../../Helpers/env"
import { errorMessages, logoutErrorMessage } from '../../../Helpers/ErrorMessages';
import * as snackbarActions from "../../../actions/snackbarActions";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import MainSnackbar from '../../Utils/Snackbar/SmsSnackbar';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { LoadingGif } from '../../Utils/Loader';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { formatDateTime, formatLocalTime } from '../../Utils/ConvertDate';
import { formatAmount } from '../../Utils/formatNumbers';
import { faMoneyBill } from '@fortawesome/free-solid-svg-icons/faMoneyBill';
import { BackButton } from '../../Utils/Buttons/DataExportationButton';
import {AccountingMiniHeader,LedgerParagraph} from "../../Utils/Menu/MiniSubHeader";
import { DateTimeFilter } from '../../Utils/ReusableComponents/DateTimeFilter';
import { Table } from '@material-ui/core';
import {RightAlignedContainer} from "../../Utils/styledComponents"
import styled from '@emotion/styled/macro';
import { exportPDF } from '../../Utils/downloadPDF';

const headData = [
    { id: 'date', label: 'Date',minWidth: 200 },
    { id: 'voucher_no', label: 'Voucher No.',minWidth: 200 },
    { id: 'type', label: 'Voucher Type',minWidth: 200 },
    {id: 'description',label: 'Memo',minWidth: 170},
    {id: 'amount',label: 'Amount',minWidth: 170},
    {id: 'transaction_type',label: 'Transaction Type',minWidth: 170},
  ]

  const TableContainer = styled.div({
      width:'350px'
  })

function ViewJournalTransactions({actions, snackbars, match:{params}}) {
    const {account, transaction_id, start_date:s_date, end_date:e_date} = params;
    const from_date = s_date ? s_date : dateConvert()
    const to_date = e_date ? e_date : dateConvert()
    const current_date = new Date()
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [state, setState] = React.useState({start_date:from_date, end_date:to_date})
    const [tranasactions, setTransactions] = React.useState([]);
    const [submitted, setSubmitted] = React.useState(false);
    const [isSubmitted, setIsSubmitted] = React.useState('idle');
    const [loading, setLoading] = React.useState('idle')
    const [balance, setBalance] = React.useState(0)
    const [category, setCategory] = React.useState(0)
    const {start_date, end_date} =state;



      const retrieveData = React.useCallback(()=>{
        const formData = new FormData();
        formData.append('ledger', transaction_id);
        formData.append('start_date', start_date);
        formData.append('end_date', end_date);
        setLoading('pending')
        axios.post(`${config.smsUrl}/accounting/specific_journal`, formData).then(response=>{
            const res = response.data ? response.data:{};
            const arr = res.data ? res.data : [];
            const bal = res.running_balance ? res.running_balance : 0
            const cat = res.category ? res.category : ""
            const list = arr.map(item=>({
                id:item.id,
                date:item.date_added,
                details:item.memo,
               ...item
            }))
            const total_debit = list.reduce((sum,item)=>{
                return  +sum + +item.debit;
            },0)
            const total_credit = list.reduce((sum,item)=>{
                return  +sum + +item.credit;
            },0)
            setTransactions(list)
            setBalance(bal)
            setCategory(cat)
            setLoading('success')
        }).catch(err=>{
            logoutErrorMessage(err, null,actions)
            setLoading('error')
        })

    },[])
    

    React.useEffect(()=>{
        retrieveData()
    },[retrieveData])
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    const handleChange = (event) =>{
        const {value, name} = event.target;
        setState({...state,[name]:value})
    }

    const handleSubmit = (event) =>{
        event.preventDefault()
        setSubmitted(true)
        const formData = new FormData();
        formData.append('ledger', transaction_id);
        formData.append('start_date', start_date);
        formData.append('end_date', end_date);
        if(start_date && end_date && end_date >= start_date){
            setIsSubmitted('pending')
            setLoading('pending')
            axios.post(`${config.smsUrl}/accounting/specific_journal`, formData).then(response=>{
                const res = response.data ? response.data:{};
                const arr = res.data ? res.data : [];
                const bal = res.running_balance ? res.running_balance : 0
                const list = arr.map(item=>({
                    id:item.id,
                    date:item.date_added,
                    details:item.memo,
                   ...item
                }))
                const total_debit = list.reduce((sum,item)=>{
                    return  +sum + +item.debit;
                },0)
                const total_credit = list.reduce((sum,item)=>{
                    return  +sum + +item.credit;
                },0)
                setTransactions(list)
                setBalance(bal)
                setLoading('success')
                setIsSubmitted('resolved')
            }).catch(err=>{
                errorMessages(err, null,actions)
                setLoading('error')
                setIsSubmitted('rejected')
            })
        }

    }

    const name = account.split('-')[1]

    const downloadPDF = () => {
        exportPDF(`${name} Journal(${formatLocalTime(current_date)})`, [['Date', "Voucher No.", "Voucher Type", "Memo", "Amount", "Transaction Type"]],
        tranasactions.filter(item=>Boolean(item)).map((item) => [formatDateTime(item.date), item.voucher_no, item.voucher_type, item.details, formatAmount(item.entry_amount),item.entry_category]),
        `${name} Journal(${formatLocalTime(current_date)}).pdf`,[["","","","Running Balance",  formatAmount(balance),""]]);
    }



    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const isLoading = loading === 'pending';
    const isSuccess = loading === 'success';
    const isError = loading === 'error';
    
    const isPending = isSubmitted === 'pending';
    const isResolved = isSubmitted === 'resolved';
    const isRejected = loading === 'rejected';
    const {openBar, type, message} = snackbars;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, tranasactions.length - page * rowsPerPage);

    const theme = useTheme();
    const isExtraLarge = useMediaQuery(theme.breakpoints.up('xl'));
    const isMedium = useMediaQuery(theme.breakpoints.up('md'));
    const isLarge = useMediaQuery(theme.breakpoints.up('lg'));

    const colSpan = isExtraLarge ? 12 : isMedium ? 6 : isLarge ? 10 : headData.length
   

    const pagination = (
        <Table>
            <TableRow>
                <TableCell colSpan={3}  align='right'><strong>Total: </strong></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
            </TableRow>
        </Table>
    )

    const reusableTable = (
        <MuiPaper>
            <StickyHeadTable {...{width:'100%',page,rowsPerPage,handleChangePage,handleChangeRowsPerPage
        ,data:tranasactions, columns:headData.filter(item=>Boolean(item))}}>
            {isLoading ? <TableRow><TableCell align='center' {...{colSpan}}><LoadingGif/></TableCell></TableRow>:null}
            {isSuccess ? tranasactions.length > 0 ? 
                <>
                    {tranasactions.map((row, index) => {
                        const total = row.debit - row.credit;
                    return (
                    <TableRow  tabIndex={-1} key={index}>
                        <CustomTableCell>{formatDateTime(row.date)}</CustomTableCell>
                        <CustomTableCell>{row.voucher_no}</CustomTableCell>
                        <CustomTableCell>{row.voucher_type}</CustomTableCell>
                        <CustomTableCell>{row.details}</CustomTableCell>
                        <CustomTableCell>{row.entry_amount  && row.entry_amount !== 0 ? row.entry_amount: null}</CustomTableCell>
                        <CustomTableCell>{row.entry_category}</CustomTableCell>
                    </TableRow>
                    );
            })}
                {emptyRows > 0 && (
                <TableRow style={{ height: 43 * emptyRows }}>
                <TableCell colSpan={headData.length} />
                </TableRow>
            )}
            </>:<TableRow><TableCell align='center'  {...{colSpan}}>No records found</TableCell></TableRow>:null}
            {isError ? <TableRow><TableCell align='center'  {...{colSpan}}>The server did not return a valid response</TableCell></TableRow>:null}
        </StickyHeadTable>
        </MuiPaper>
    )
    const title = s_date ? 'Profit & Loss':'Chart of Accounts';
    const route = s_date ? '/accounting/accreports/plstatement' : '/accounting/coa'
  return (
    <div className='journals'>
        <PageTitle title='Transactions'/>
        <SubHeader title="Reports" subTitle='Transactions'>
            <FontAwesomeIcon icon={faMoneyBill}/>
        </SubHeader>
        <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>
       
        <div className='mx-3 mt-2'>
            <BackButton to={route} text={title} data-testid="view-journal-transactions-back-button"/>
            <div className='mt-3'>
                <DateTimeFilter {...{start_date,end_date, handleChange, submitted,handleSubmit,isPending,type:'date'}}/>                
            </div>
            <RightAlignedContainer>
                {tranasactions.length > 0 ?  <button onClick={downloadPDF} className='btn btn-sm sms-info-btn mb-3'>Download PDF</button>:null}
            </RightAlignedContainer>

            <div className='row'>
                <div className='col-lg-6'>
                    <AccountingMiniHeader title={account}/>
                </div>
                <div className='col-lg-6'>
                    <RightAlignedContainer>
                        <LedgerParagraph variant='p1'>Running Balance: <strong>USD {balance} </strong> {category}</LedgerParagraph>

                    </RightAlignedContainer>
                </div>
            </div>
   
            {reusableTable}
        </div>
    </div>
  )
}
function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewJournalTransactions);
