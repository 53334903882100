import React from "react";
import { SubHeader } from "../../../Containers/SubHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomTable, { useHiddenColumns } from "../../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import * as snackbarActions from "../../../actions/snackbarActions"
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import PageTitle from "../../Utils/smsTitle";
import { faNotesMedical } from "@fortawesome/free-solid-svg-icons/faNotesMedical";
import { useVisitList } from "./hooks/useVisitList";
import { formatDateTime } from "../../Utils/ConvertDate";
import { MiniHeader } from "../../Utils/Menu/MiniSubHeader";
import { ReusableRouteTabs } from "../../Utils/Dialogs/ReusableTabs";
import { history } from "../../../Helpers/history";
import Triage from "../../Triage/Triage"
import CustomDialog from "../../Utils/Dialogs/CustomDialog";
import { Container } from "../../Utils/styledComponents";
import ReusableTabs, { TabPanel } from "../../Utils/Dialogs/ReusableTabs";
import { Link } from "react-router-dom";
import DeleteDialog from "../../Utils/Dialogs/DeleteDialog";
import Label from "../../Utils/FormInputs/Label";
import TextField from "../../Utils/FormInputs/TextField";
import SelectInput from "../../Utils/FormInputs/SelectInput";
import { useExcelReports } from "../../Utils/ReusableComponents/useExcelExport";
import Pagination from '@material-ui/lab/Pagination';
import moment from "moment";
import {getCurrentYear} from "../../Utils/ConvertDate";


const headData = [
    { id: 'count', numeric: false, disablePadding: false, label: 'Sl', hidden: false },
    { id: 'date', numeric: false, disablePadding: false, label: 'Date', hidden: false },
    { id: 'invoice', numeric: false, disablePadding: false, label: 'Invoice#', hidden: false },
    { id: 'patient_number', numeric: false, disablePadding: false, label: 'Patient No.', hidden: false },
    { id: 'patient_name', numeric: false, disablePadding: false, label: 'Patient Name', hidden: false },
    { id: 'phone_no', numeric: false, disablePadding: false, label: 'Phone', hidden: false },
    { id: 'provider_name', numeric: false, disablePadding: false, label: 'Provider Name', hidden: false },
    // { id: 'kin_phone_no', numeric: false, disablePadding: false, label: 'NOK Phone', hidden: false },
    { id: 'type', numeric: false, disablePadding: false, label: 'Visit Type', hidden: false },
    { id: 'item_status', numeric: false, disablePadding: false, label: 'Status', hidden: false },
    { id: 'action', numeric: false, disablePadding: true, label: 'Action', hidden: false },
];

const VisitList = ({ actions, snackbar }) => {
    const { /*searchValue, activePage, handleSearch, handleChangeNewPage,*/ closeSnackBar, openDialog,
        visitList, handleOpenDialog, handleCloseDialog,
        triageProps, ids, renderService, renderPrescription, cancelProps,
        isLoading, isSuccess, isError, tabValue, handleChangeTabValue,
        filters, handleChangeFilter, handleDownloadExcel,
        handleChangePage, handleChangeRowsPerPage, visitListData, title : dialogTitle
    } = useVisitList(actions)

    // const excelObj = {file_name:'Stock List', url:'cpharmacy/store_stock_excel', params:{ category_id:+activeStatus}}

    const { exportExcel } = useExcelReports(actions, {
        file_name: "Visit List",
        url1: "cvisit/visits",
        params: {
            start_date: moment(filters?.startDate).format("YYYY-MM-DD"),
            end_date: moment(filters?.endDate).format("YYYY-MM-DD"),
            visit_type: filters?.visitType,
            page: filters?.page,
            per_page: filters?.perPage,
            search: filters?.search,
        },
    })

    const { handleSubmitTriage, isPending: isPendingTriage } = triageProps;

    const { patient_number, patient_name, age, gender, screen } = ids;

    const { openBar, type, message } = snackbar;

    const { visits, total_count } = visitList;

    const components = [
        { label: 'New Visit', path: '/newvisit' },
        { label: 'Visit List', path: '/visitlist' },
        { label: 'Consultation List', path: '/consultationlist' },
    ];

    const isTriage = screen === 'triage';

    const isService = screen === 'service';

    const title = isTriage ? 'Take Vitals' : tabValue === 0 ? 'Add Service' : tabValue === 1 ? 'Add Prescription' : ''

    const { openCancel, handleCancel, handleOpenCancel, handleCloseCancel } = cancelProps;

    const visitsArr = visitListData?.items?.map((item, idx) => {
        const v_type = {
            "1": "New Visit",
            "2": "Follow-up"
        }

        const status = {
            0: (<span className="text-pending">Visit created</span>),
            1: (<span className='text-success'>Vitals taken</span>),
            2: (<span className='text-success'>Request added</span>),
            3: <span className='text-success'>Treatment ongoing</span>,
            4: <span className='text-error'>Visit Ended</span>
        }

        const action = {
            0: (
                <>
                    <button
                        onClick={() => handleOpenDialog(item, 'triage')}
                        className="btn btn-sm sms-info-btn mr-2 mb-2"
                    >
                        Triage
                    </button>

                    <button
                        onClick={() => handleOpenDialog(item, 'service')}
                        className="btn btn-sm sms-info-btn mr-2 mb-2"
                    >
                        Add request
                    </button>

                    <button
                        onClick={() => handleOpenCancel(item.visit_id, 'Cancel')}
                        className="btn btn-sm sms-gray-btn mb-2"
                    >
                        Cancel Visit
                    </button>
                </>

            ),

            1: (
                <>
                    <button
                        onClick={() => handleOpenDialog(item, 'service')}
                        className="btn btn-sm sms-info-btn mr-2 mb-2"
                    >
                        Add request
                    </button>

                    <button
                        onClick={() => handleOpenCancel(item.visit_id, 'End')}
                        className="btn btn-sm sms-gray-btn mb-2"
                    >
                        End Visit
                    </button>
                </>
            ),

            2: (
                <>
                    <button
                        onClick={() => handleOpenDialog(item, 'service')}
                        className="btn btn-sm sms-info-btn mr-2 mb-2"
                    >
                        Add request
                    </button>

                    <button
                        onClick={() => handleOpenCancel(item.visit_id, 'End')}
                        className="btn btn-sm sms-gray-btn mb-2"
                    >
                        End Visit
                    </button>
                </>
            ),

            3: (
                <>
                    <button
                        onClick={() => handleOpenDialog(item, 'service')}
                        className="btn btn-sm sms-info-btn mr-2 mb-2"
                    >
                        Add request
                    </button>

                    <button
                        onClick={() => handleOpenCancel(item.visit_id, 'End')}
                        className="btn btn-sm sms-gray-btn mb-2"
                    >
                        End Visit
                    </button>
                </>
            ),
        }

        const addRequestActionOnInsurance = {

            "4-1": (
                <button
                    onClick={() => handleOpenDialog(item, 'service')}
                    className="btn btn-sm sms-info-btn mr-2 mb-2"
                >
                    Add request
                </button>),
        }

        return {
            ...item,
            count: (filters?.perPage * (filters?.page - 1)) + idx + 1,
            date: formatDateTime(item.begin_datetime),
            type: v_type[item['visit_type']],
            item_status: status[item['status']],
            is_on_insurance: status[item['is_on_insurance']],
            patient_name: item["first_name"].concat(" ", item["last_name"]),
            invoice: item['is_on_insurance'] === 1 ? `${item.visit_id}/${getCurrentYear()}` : '',
            action: (
                <>
                    {
                        item.referal_status === 'TRUE' ?
                            <Link to={`/viewreferal/${item.visit_id}`} >
                                <button className="btn btn-sm sms-info-btn mr-1">
                                    View Referral
                                </button>
                            </Link>
                            :
                            null}

                    {action[item.status]}
                    {addRequestActionOnInsurance[`${item.status}-${item.is_on_insurance}`]}
                </>
            )
        }
    })

    const {
        headCells, all_hidden, handleAllHeadCells, handleHeadCells
    } = useHiddenColumns(headData);


    const tabs = ['Service Request', 'Prescription Request']

    return (
        <div>
            <PageTitle title="Visit List" />

            <SubHeader title="Visit list" subTitle="Visit List">
                <FontAwesomeIcon icon={faNotesMedical} />
            </SubHeader>

            <DeleteDialog
                message={`${dialogTitle.toLowerCase()}`}
                openDialog={openCancel}
                handleClose={handleCloseCancel}
                text="visit"
                title={`${dialogTitle} Visit`}
            >
                <button
                    className='btn sms-info-btn btn-sm'
                    onClick={handleCancel}
                >
                    {`${dialogTitle} Visit`}
                </button>

                <button
                    className="btn sms-grey-btn btn-sm"
                    onClick={handleCloseCancel}
                >
                    No
                </button>
            </DeleteDialog>


            <CustomDialog
                title={title}
                open={openDialog}
                handleClose={handleCloseDialog}
                maxWidth="lg"
                isContentOverflow={false}
            >
                {isTriage ?
                    <form onSubmit={handleSubmitTriage}>
                        <Triage {...{
                            ...triageProps,
                            patient_number,
                            patient: { patient_name, age, gender },
                            handleSubmitTriage
                        }}
                        />

                        <button
                            type="submit"
                            disabled={isPendingTriage}
                            className="btn btn-sm sms-btn"
                        >
                            {isPendingTriage ? 'Saving....' : 'Save Triage'}
                        </button>
                    </form>
                    :
                    null}

                {isService ?
                    <ReusableTabs
                        value={tabValue}
                        tabHeading={tabs}
                        handleChange={handleChangeTabValue}
                    >
                        <TabPanel value={tabValue} index={0}>
                            {renderService}
                        </TabPanel>

                        <TabPanel value={tabValue} index={1}>
                            {renderPrescription}
                        </TabPanel>
                    </ReusableTabs>
                    :
                    null}
            </CustomDialog>

            <Container>
                <MiniHeader title='Visit List' />

                <ReusableRouteTabs value={history.location.pathname} tabHeading={components} />

                <MainSnackbar variant={type} message={message} open={openBar} handleCloseBar={closeSnackBar} />

                {/*}<DateTimeFilter /*{...{ start_date, end_date, handleChange, submitted, handleSubmit, isPending: isPendingForm, type: 'date' }}  />*/}

                <div className="">
                    <form onSubmit={() => console.log("form works")} className="">

                        <div className="form-row">
                            <div className="form-group col">

                                <Label name="Visit Type" />
                                <SelectInput
                                    options={[
                                        { text: "Select...", value: "" },
                                        { text: "New Visit", value: "1" },
                                        { text: "Review", value: "2" },
                                    ]}
                                    required
                                    name="visitType"
                                    value={filters?.visitType}
                                    onChange={handleChangeFilter}
                                />
                            </div>

                            <div className="form-group col">
                                <Label name='Start Date' />

                                <TextField
                                    type='date'
                                    name='startDate'
                                    value={filters?.startDate}
                                    onChange={handleChangeFilter}
                                />
                            </div>

                            <div className="form-group col">
                                <Label name='End Date' />

                                <TextField
                                    type='date'
                                    name='endDate'
                                    value={filters?.endDate}
                                    onChange={handleChangeFilter}
                                />
                            </div>

                            <div className="form-group col d-flex h-auto justify-content-around align-items-end">
                                <button
                                    className="btn sms-info-btn"
                                    type="button"
                                    onClick={() => exportExcel(1)}
                                >
                                    <span>
                                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M7.5 1.05a.45.45 0 0 1 .45.45v6.914l2.232-2.232a.45.45 0 1 1 .636.636l-3 3a.45.45 0 0 1-.636 0l-3-3a.45.45 0 1 1 .636-.636L7.05 8.414V1.5a.45.45 0 0 1 .45-.45ZM2.5 10a.5.5 0 0 1 .5.5V12c0 .554.446 1 .996 1h7.005A.999.999 0 0 0 12 12v-1.5a.5.5 0 0 1 1 0V12a2 2 0 0 1-1.999 2H3.996A1.997 1.997 0 0 1 2 12v-1.5a.5.5 0 0 1 .5-.5Z"
                                                fill="#fff"
                                            />
                                        </svg>
                                    </span>
                                    &nbsp;&nbsp;Excel
                                </button>


                                {/*<button
                                    className="btn sms-info-btn"
                                    type="button"
                                    onClick={handleDownloadPDF}
                                >
                                    <span>
                                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M7.5 1.05a.45.45 0 0 1 .45.45v6.914l2.232-2.232a.45.45 0 1 1 .636.636l-3 3a.45.45 0 0 1-.636 0l-3-3a.45.45 0 1 1 .636-.636L7.05 8.414V1.5a.45.45 0 0 1 .45-.45ZM2.5 10a.5.5 0 0 1 .5.5V12c0 .554.446 1 .996 1h7.005A.999.999 0 0 0 12 12v-1.5a.5.5 0 0 1 1 0V12a2 2 0 0 1-1.999 2H3.996A1.997 1.997 0 0 1 2 12v-1.5a.5.5 0 0 1 .5-.5Z"
                                                fill="#fff"
                                            />
                                        </svg>
                                    </span>
                                    &nbsp;&nbsp;PDF
                                </button> */}
                            </div>
                        </div>
                    </form>
                </div>

                <CustomTable
                    headData={headCells}
                    handler={handleChangeFilter}
                    term={filters?.search}
                    data={visitsArr}
                    colSpan={headCells.length}
                    // customPage
                    customPageNoPagination
                    records={visitListData?.total ?? 0}
                    activePage={filters?.page}
                    handleChangeNextPage={handleChangePage}
                    total_count={visitListData?.total ?? 0}
                    searchInputName={"search"}
                    {...{ all_hidden, handleAllHeadCells, handleHeadCells }}
                >

                    <TableBody>
                        {isLoading ?
                            <TableRow>
                                <TableCell
                                    colSpan={headCells.length}
                                    align="center"
                                >
                                    <img src="/images/smsloader.gif" alt="loader" />

                                </TableCell>
                            </TableRow>
                            :
                            null}

                        {isSuccess ? visitsArr?.length > 0 ? visitsArr?.map(item => {

                            return (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={item.visit_id}
                                    className='mui-table-row'>

                                    {headCells.map((head) => (
                                        <TableCell key={head.id} hidden={head.hidden}>{item[head.id]}</TableCell>
                                    ))}
                                </TableRow>
                            );
                        })
                            :
                            <TableRow>
                                <TableCell
                                    colSpan={headCells.length}
                                    align="center"
                                >
                                    No records found
                                </TableCell>
                            </TableRow>
                            :
                            null}

                        {isError ?
                            <TableRow>
                                <TableCell
                                    colSpan={headCells.length}
                                    align="center"
                                >
                                    The server did not return a successful response
                                </TableCell>
                            </TableRow>
                            :
                            null}
                    </TableBody>
                </CustomTable>

                <div className="w-100 h-auto d-flex justify-content-end align-items-start">

                    <Pagination
                        count={visitListData?.pages ?? 0}
                        page={filters?.page}
                        onChange={handleChangePage}
                        hidePrevButton={filters?.page <= 1}
                        hideNextButton={filters?.page >= visitListData?.pages}
                    />

                </div>

                {/*<TablePagination
                        component="div"
                        // disabled={filters?.page <= 1}
                        count={visitListData ? visitListData?.total : 0}
                        page={filters?.page}
                        defaultPage={1}
                        onPageChange={handleChangePage}
                        rowsPerPage={visitListData ? filters?.perPage : 0}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    variant="outlined"
                        // hidePrevButton={true}
                        // hideNextButton={true}
                    // showFirstButton={filters?.page === 1 ? false : true}
                    // showLastButton={filters?.page === visitListData?.pages ? false : true}
                    />*/}

            </Container>
        </div>
    )
};

function mapStateToProps(state) {
    const { snackbar } = state;
    return { snackbar }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(VisitList);
