import React, {useEffect, useState} from "react";
import styled from "@emotion/styled/macro";
import * as colors from "../../../styles/colors"
import axios from "axios";
import {config} from "../../../Helpers/env";



const P = styled.p({
    margin:0,
    fontSize:'15px',
  })
  
  
const StyledUser = styled(P)({
    borderRight: `0.73800128698349px solid ${colors.muted001}`,
    ':last-of-type':{
      borderRight:0
    },
    padding:'0 7px'
  })

const StyledContainer = styled.div({
    display:'flex'
})

export const PatientDetails = ({patient_number,visit_id, patient_name, age, gender}) =>{
    const [visitDetails, setVisitDetails] = useState({})


    useEffect(()=>{
        if(!visit_id){
            return
        }
        axios.get(`${config.smsUrl}/cvisit/${visit_id}`).then(res=>{
            const data = res.data;
            const dt = !data ? {} : data;
            setVisitDetails(dt)
        }).catch(err=>{
        })
    },[visit_id]);

      return(
        <StyledContainer>
            <StyledUser>Patient No: <strong>{patient_number}</strong></StyledUser>
            <StyledUser>Patient Name: <strong>{patient_name}</strong></StyledUser>
            {age ? <StyledUser>Age: <strong>{age ? age : ''}</strong></StyledUser>:null}
            {gender ? <StyledUser>Gender: <strong>{gender ? gender : ''}</strong></StyledUser>:null}
            <StyledUser>Payment Mode: <strong>{visitDetails.is_on_insurance === 1 ? 'CREDIT' : 'CASH'}</strong></StyledUser>
            {visitDetails.is_on_insurance === 1 ? <StyledUser>Provider Name: <strong>{visitDetails?.insurance_provider_name}</strong></StyledUser>:null}
            {visitDetails.is_on_insurance === 1 ? <StyledUser>Account Name: <strong>{visitDetails?.account_name}</strong></StyledUser>:null}
        </StyledContainer>
      )
  }


