import React, {useEffect, useState} from "react";
import PageTitle from "../Utils/smsTitle";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faNotesMedical} from "@fortawesome/free-solid-svg-icons/faNotesMedical";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as snackbarActions from '../../actions/snackbarActions';
import TreatmentHistory from "./TreatmentHistory";
import ReusableTabs, {TabPanel} from "../Utils/Dialogs/ReusableTabs";
import PreviousInpatientPrescription from "./PreviousInpatientPrescription";
import axios from "axios";
import { config } from "../../Helpers/env";
import {logoutErrorMessage} from "../../Helpers/ErrorMessages";
import {BackButton} from "../Utils/Buttons/DataExportationButton";
import {Container} from "../Utils/styledComponents";
import {dateConvert} from "../Utils/ConvertDate";
import {PatientInformation} from "../DoctorsModule/PatientInfo/PatientInformation";
import RecordTreatment from "./RecordTreatment";
import CustomDialog from "../Utils/Dialogs/CustomDialog";

const InpatientTreatment = ({actions,match}) => {

    const tabHeading = ['Prescriptions', 'Previous Treatment'];
    const {patient_admission_id, visit_id, patient_number} = match.params;
    const [value, setValue] = useState(0);
    const [patient, setPatient] = useState({});
    const [openRecordDialog, setOpenRecordDialog] = useState(false);
    const [isRecordedTreatment, setIsRecordedTreatment] = useState(false);
    const [prescribed_drug, setPrescribedDrug] = useState({})

    const handleCloseRecordDialog = () => {
        setOpenRecordDialog(false);
    }

    const handleOpenRecordDialog = (drug_item) => {
        const new_item = {...drug_item, date: dateConvert()};
        setPrescribedDrug(new_item);
        setOpenRecordDialog(true);
    }

    const handleRecordTreatment = () => {
        setIsRecordedTreatment(!isRecordedTreatment)
    }

    useEffect(() => {
        const formD = new FormData();
        formD.append('patient_number', patient_number);
        axios.post(`${config.smsUrl}/cdoctor/get_patient_details`, formD).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            setPatient({
                patient_name: `${!dt.first_name ? "" : dt.first_name} ${!dt.last_name ? "" : dt.last_name}`,
                age: dt.Age,
                gender: dt.gender,
                address: dt.address,
                phone: dt.phone_no
            });
        }).catch(err => {
            logoutErrorMessage(err, null, actions);
        })
    }, [patient_number]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    
    return (
        <div className="journals">
            <PageTitle title="Inpatient Treatment"/>
            <SubHeader title="Inpatient Treatment" subTitle="Inpatient treatment">
                <FontAwesomeIcon icon={faNotesMedical}/>
            </SubHeader>
            <CustomDialog title={'Record Treatment'} open={openRecordDialog} handleClose={handleCloseRecordDialog} maxWidth="lg"  isContentOverflow={false}>
                <RecordTreatment match={{patient_admission_id,patient_number,visit_id}}  isRecordedTreatment={isRecordedTreatment} handleRecordTreatment={handleRecordTreatment}
                handleCloseRecordDialog={handleCloseRecordDialog} prescribed_drug={prescribed_drug} setPrescribedDrug={setPrescribedDrug}/>
            </CustomDialog>
            <Container>
                <div className='mb-3'>
                    <BackButton text='Admitted Patients' to='/patientroundlist' data-testid="inpatient-treatment-back-button"/>
                </div>
                <PatientInformation title='PATIENT DETAILS' style={{padding:'5px 0'}} {...{patient_number, visit_id}}/>
                <ReusableTabs value={value} handleChange={handleChange} tabHeading={tabHeading}>
                    <TabPanel value={value} index={0}>
                        <PreviousInpatientPrescription {...{visit_id, handleOpenRecordDialog,isRecordedTreatment, handleRecordTreatment}}/>
                    </TabPanel>
                    {/*<TabPanel value={value} index={1}>
                        <RecordTreatment match={{patient_admission_id,patient_number,visit_id}}/>
                    </TabPanel>*/}
                    <TabPanel value={value} index={1}>
                        <TreatmentHistory match={{patient_number,patient_admission_id,visit_id}}/>
                    </TabPanel>
                </ReusableTabs>
            </Container>
        </div>
    );
};

function mapStateToProps(state) {
    const {snackbar} = state;
    return {snackbar}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InpatientTreatment);
