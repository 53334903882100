/** @jsxImportSource @emotion/react */
import React, {useEffect, useState} from 'react'
import {textWeight, table, detailsTable, leftPadding} from "../../styles/tableStyles";
import axios from "axios";
import {config} from "../../Helpers/env";

function DentalPatientDetails({patient_number}) {
    const [patient, setPatient] = useState({})

    useEffect (() => {
        const formData = new FormData();
        formData.append('patient_number', patient_number)
        axios.post(`${config.smsUrl}/cpatient/patient_update_form`, formData).then(response => {
            const patient = response.data;
            const patientDetails = !patient ? {} : patient;
            setPatient({
                ...patientDetails,
                patient_name: `${!patientDetails.first_name ? "" : patientDetails.first_name} ${!patientDetails.last_name ? "" : patientDetails.last_name}`,
                age: patientDetails.Age,
                gender: patientDetails.gender,
                address: patientDetails.address,
                phone: patientDetails.phone_no
            })

        }).catch(err => {
            // logoutErrorMessage(err, null, actions)
        } )

    },[patient_number])

    return(
        <table css={[table, detailsTable]}>
            <tbody>
                <tr>
                    <td  style={{paddingLeft:0}}><span>Patient Name: </span> <span css={[textWeight]}>{patient?.patient_name}</span></td>
                    <td><span>Age:</span> <span css={[textWeight]}>{patient?.age}</span></td>
                    <td><span>Sex: </span> <span css={[textWeight]}>{patient?.gender}</span></td>
                    <td><span>Address:</span> <span css={[textWeight]}>{patient?.address}</span></td>
                </tr>
            </tbody>
        </table>
    )
}
export default DentalPatientDetails