import React from "react";
import "react-multi-carousel/lib/styles.css";
import {Table} from "@material-ui/core";
import {TableBody} from "@material-ui/core";
import {TableCell} from "@material-ui/core";
import {TableContainer} from "@material-ui/core";
import {TableFooter} from "@material-ui/core";
import {TablePagination} from "@material-ui/core";
import {TableRow} from "@material-ui/core";
import {TableHead} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useTopSellingProductsStyles = makeStyles({
    tableHeadText:{
      fontFamily: 'Inter',
      fontStyle: 'normal',
      padding: '8px',
      fontWeight: 500,
      fontSize: '14px',
      letterSpacing: '-0.3px',
      color: '#8a8a8a'
    },
    tableStyledRow : {
      backgroundColor: '#EFF0F6',
     border: 'none'

    },
    tableCellText: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 600,
      padding: '8px',
      fontSize: '12px',
      lineHeight: '120%',
      letterSpacing: '-0.4px',
      color: '#171717',
    },
    emptytext: {
      textAlign: 'center'
    },
    tableContainterStyle: {
      border:'none',
    }
})

export const TopSellingProducts = ({topSellsProps, filter_type}) => {

    const {sell_state, handleChangePage, handleChangeRowsPerPage} = topSellsProps;
    const {page, products} = sell_state;
    const class_styles = useTopSellingProductsStyles();

    return (

        <div>
          <div style={{display:'flex', justifyContent: 'space-between', marginBottom: '10px'}}>
            <div>
              <p className="dashboard-card-font-color">Top Selling Products</p>
              <div className="orange-short-border"></div>
            </div>
            <div style={{marginRight:'5px'}}>
              <p className="dropdown-filter">{filter_type}</p>                       
            </div>
          </div>
          <div>
            <TableContainer className="w-100" classes={{root:class_styles.tableContainterStyle}} >
              <Table aria-label="custom pagination table">
                <TableHead>
                  <TableRow classes={{root:class_styles.tableStyledRow}}>
                    <TableCell className={class_styles.tableHeadText}>Name</TableCell>
                    <TableCell className={class_styles.tableHeadText}>Quantity</TableCell>
                    <TableCell className={class_styles.tableHeadText}>Revenue</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {products.length > 0 ?
                  products.slice(page * 5, page * 5 + 5).map((product) => {
                    return (
                      <TableRow>
                        <TableCell className={class_styles.tableCellText}>
                          <p style={{margin:'4px', fontSize:'12px'}}>{product.product_name}</p>
                        </TableCell>
                        <TableCell className={class_styles.tableCellText}>
                          <p style={{margin:'4px', fontSize:'12px'}}>{product.total_quantity_sold.toLocaleString()}</p>
                        </TableCell>
                        <TableCell className={class_styles.tableCellText}>
                          <p style={{margin:'4px', fontSize:'12px'}}>{product.total_revenue_generated.toLocaleString()}</p>
                        </TableCell>
                      </TableRow>
                    );
                  })
                  :
                  <TableRow >
                    <TableCell colSpan={3} className={class_styles.emptytext}>
                    <p style={{margin:'6px'}}>No top selling products found</p>
                    </TableCell>
                  </TableRow>
                  }
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10]}
                      colSpan = {3}
                      count = {products.length}
                      page = {page}
                      rowsPerPage = {5}
                      onChangePage = {handleChangePage}
                      onChangeRowsPerPage = {handleChangeRowsPerPage}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </div>
        </div>

    );

}
