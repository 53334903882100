import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../actions/snackbarActions";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import MainVitals from "../Triage/MainVitals";
import PageTitle from "../Utils/smsTitle";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faNotesMedical} from "@fortawesome/free-solid-svg-icons/faNotesMedical";
import Card from '@material-ui/core/Card'
import {useTheme} from "@material-ui/core/styles";
import {useTriage} from "../Triage/hooks/useTriage";
import {titleCase} from "../Users/addUser";
import { BackButton } from "../Utils/Buttons/DataExportationButton";
import {FormLabel} from "../Utils/FormInputs/Label";
import {ResizableTextarea} from "../Utils/FormInputs/Textarea";
import {HistoryGroup,FormGroup,Radio, Paragraph} from "./Findings/PatientFindings";

const MedicationTriage = ({actions, snackbars, match}) => {
    const {patient_number, visit_id, patient_admission_id} = match.params
    const {
        triageList, submitted, isSubmitted, handleChange, handleSubmitTriage, handleResetForm,
        handleCloseSnackbar, handleChangeWeight, handleChangeVitals, handleChangeHeight, bmi,
        weight_metric_name, height_metric_name, triage,removeMetric,
        optionalMetrics, handleChangeOptional,
         otherMetric, others, handleChangeOthersMetrics, patient,
        state, diseases, handleChangeDisease, handleChangeState
    } = useTriage(actions, patient_number, visit_id);
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    const {openBar, type, message} = snackbars;
    const singlePatient = !patient ? {} : patient;
    const {first_name, last_name, Age} = singlePatient;
    const patient_name = `${!first_name ? "" : titleCase(first_name)} ${!last_name ? "" : last_name}`;
    const patient_age = !Age ? '' : Age;

    const {allergy, allergies,other_diseases} = state

    const diseaseComponent = (
        <HistoryGroup>
            <Paragraph><strong>Chronic Illnesses</strong></Paragraph>
            {diseases.map((item, index)=>(
                <Radio
                    key={index}
                    label={item.disease}
                    onChange={(e)=>handleChangeDisease(e, index)}
                    type="checkbox"
                    name={item.name}
                    checked={item[item.name]}
                />
            ))}
            <FormGroup>
                <FormLabel  name='Others(specify)' toolTip='Write other previous illnesses'/>
                <ResizableTextarea rows='0'  name='other_diseases' value={other_diseases} onChange={handleChangeState} placeholder='Write other illnesses.....'/>
            </FormGroup>
        </HistoryGroup>
    )

    const allergyComponent = (
        <HistoryGroup>
            <Paragraph>Allergies</Paragraph>
            <Radio
                inline
                checked={allergy === 'Yes'}
                label="Yes"
                value="Yes"
                name="allergy"
                onChange={handleChangeState}
                type="radio"
            />
            <Radio
                inline
                checked={allergy === 'No'}
                label="No"
                value="No"
                name="allergy"
                onChange={handleChangeState}
                type="radio"
            />
            {allergy === 'Yes' ?  <ResizableTextarea   rows='0'  value={allergies} name='allergies'
                                                       onChange={handleChangeState} placeholder='Write other allergies.....'/>:null}
        </HistoryGroup>
    )
    return (
        <div>
            <PageTitle title="Take Vitals"/>
            <SubHeader title="Take Vitals" subTitle="Take vitals">
                <FontAwesomeIcon icon={faNotesMedical}/>
            </SubHeader>
            <div className="patient-section">
                <BackButton text='Admitted Patients' to='/patientroundlist'/>
                <Card className="p-3 general-card">
                    <h6 className={`visit-heading ${palette.type === "dark" && "dark-visit-heading"}`}>Take Vitals</h6>
                    <MainSnackbar handleCloseBar={handleCloseSnackbar} open={openBar} message={message} variant={type}/>
                    <MainVitals {...{ handleSubmitTriage, triageList, handleChange, submitted, removeMetric,
                                    handleResetForm, isSubmitted, handleChangeWeight, handleChangeHeight,
                                    bmi, weight:weight_metric_name, height:height_metric_name,  handleChangeVitals, triage,
                                    inpatient:true, optionalMetrics, handleChangeOptional, otherMetric, others,
                                    handleChangeOthersMetrics}} chronic_illness={<>
                        {diseaseComponent}
                        {allergyComponent}
                    </>}>
                        <table className='table table-sm table-borderless'>
                            <tr>
                                <td>Admission No.: <strong>{patient_admission_id}</strong></td>
                                <td>Patient Name: <strong>{patient_name}</strong></td>
                                <td>Age: <strong>{patient_age}</strong></td>
                            </tr>
                        </table>
                    </MainVitals>
                </Card>
            </div>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MedicationTriage);
