import React, {useState} from "react";
import PageTitle from "../Utils/smsTitle";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CustomTable from "../Utils/Tables/CustomTable";
import FilterResults from "react-filter-search";
import TableBody from "@material-ui/core/TableBody";
import {getComparator, stableSort} from "../Utils/Tables/sortingFunctions";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import *  as snackbarActions from "../../actions/snackbarActions";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import {FullScreenDialog} from "../Utils/Dialogs/FullScreenDialog"
import { RightAlignedContainer } from "../Utils/styledComponents";
import { faWheelchair } from "@fortawesome/free-solid-svg-icons/faWheelchair";
import { useEmergencyStock } from "./hooks/useEmergencyStock";
import { RequisitionForm } from "../Inventory/stock/AddRequisition";
import PrintDialog from "../Utils/Dialogs/PrintDialog";
import { Container } from "../Utils/styledComponents";
import {useReceiveDispatchLabStock} from "../Lab/stock/hooks/useReceiveDispatchLabStock";


const headData = [
    {id: 'sl', numeric: false, disablePadding: false, label: 'Sl'},
    {id: 'product', numeric: false, disablePadding: false, label: 'Product Name'},
    {id: 'unit', numeric: false, disablePadding: false, label: 'Unit'},
    {id: 'stock', numeric: false, disablePadding: false, label: 'Stock'},
];


const EmergencyStock = ({actions, snackbars}) => {
    const [openReceive, setOpenReceive] = useState(false);


    const handleOpenReceiveDialog = () =>{
        setOpenReceive(true)
    }

    const handleCloseReceiveDialog = () =>{
        setOpenReceive(false)
    }

    const {receiveStorePharmacy,isResolvedDispatch} = useReceiveDispatchLabStock({actions, setOpenDialog:setOpenReceive,to_store:'Patient Emergency'})

    const {searchValue, order, orderBy, loading, stock, total_count, activePage,
        handleCancel,handleClick, closeSnackbar, handleSearch, handleChangePage,
        handleRequestSort, openDialog,handleOpenEmergencyDialog, handleCloseDialog,requisitionProps,
        handleCloseEmergencyDialog,openEmmergency, activeStatus, changeActiveStatus} = useEmergencyStock(actions,isResolvedDispatch)
    const products = !stock ? [] : stock;
    const {openBar, type, message} = snackbars;
    return (
        <div data-testid="emergency" className='journals'>
            <PageTitle title="Stock"/>
            <SubHeader title="Patient Emergency" subTitle="Stock List">
                <FontAwesomeIcon icon={faWheelchair}/>
            </SubHeader>
            <FullScreenDialog open={openReceive} handleClose={handleCloseReceiveDialog} title="Receive Stock" maxWidth="lg">
                {receiveStorePharmacy}
            </FullScreenDialog>
            <FullScreenDialog open={openEmmergency} handleClose={handleCloseEmergencyDialog} title="Make Requisition">
                <RequisitionForm {...{...requisitionProps, department:'Emergency'}}/>
            </FullScreenDialog>
            <PrintDialog openDialog={openDialog} handleClose={handleCloseDialog} message="Print Requisition Note"
                         text="Would you like to print this requisition note" handleClick={handleClick} handleCancel={handleCancel}/>
            <Container>
                <div>
                    <RightAlignedContainer>
                        <button type="button" onClick={handleOpenEmergencyDialog} className="btn sms-btn mb-1 small-btn  mr-3">Make Requisition</button>
                        <button className='btn btn-sm sms-info-btn small-btn mb-1' id="receive-store-stock"
                                onClick={handleOpenReceiveDialog}>Receive stock</button>
                    </RightAlignedContainer>
                </div>
                <div className='inpatient-billing-header'>
                    <a className={`billing-span btn btn-sm ${activeStatus === 1 ? 'active-billing-span':''}`} 
                    onClick={()=>changeActiveStatus(1)}>Products</a>
                    <a className={`billing-span btn btn-sm ${activeStatus === 3 ? 'active-billing-span':''}`} 
                    onClick={()=>changeActiveStatus(3)}>Sundries</a>
                </div>
                <div>
                    <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>
                    <CustomTable title="Stock" handleChangeNextPage={handleChangePage} term={searchValue} records={10}
                                headData={headData} handler={handleSearch} total_count={total_count}
                                activePage={activePage}
                                handleRequestSort={handleRequestSort} data={products} colSpan={5} order={order}
                                orderBy={orderBy} customPage>
                        {loading ? <TableBody><TableRow>
                            <TableCell colSpan={headData.length} align="center"><img src="/images/smsloader.gif"
                                                                    alt="loader"/></TableCell>
                        </TableRow></TableBody> : products.length > 0 ?
                            <FilterResults value={searchValue} data={products}
                                        renderResults={results => (
                                            <TableBody>
                                                {stableSort(results, getComparator(order, orderBy))
                                                    .slice(0, 10)
                                                    .map((item, index) => {
                                                        const count = ((activePage - 1) * 10) + index + 1;
                                                        return (
                                                            <TableRow
                                                                hover
                                                                role="checkbox"
                                                                tabIndex={-1}
                                                                key={index}
                                                                className='mui-table-row'>
                                                                 <TableCell>{count}</TableCell>
                                                                <TableCell>{item.product_name}{item.country_of_origin? ` - ${item.country_of_origin}`: null}</TableCell>
                                                                <TableCell>{item.name}</TableCell>
                                                                <TableCell>{item.stock}</TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                            </TableBody>
                                        )}/> : <TableBody><TableRow>
                                <TableCell colSpan={headData.length} align="center">No records found</TableCell>
                            </TableRow></TableBody>}
                    </CustomTable>
                </div>
            </Container>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(EmergencyStock);
