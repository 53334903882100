/** @jsxImportSource @emotion/react */
import { css} from '@emotion/react'
import {table,textWeight, thBorderBottom, thBorderTop, detailsTable} from '../../styles/tableStyles'
import { RightAlignedContainer } from '../Utils/styledComponents.js';
import { usePrint } from '../Utils/Templates/usePrint.js';
import { LoadingGif } from '../Utils/Loader.js';
import { PrintTemplate } from '../Utils/Templates/PrintTemplate';
import { formatDateTime } from '../Utils/ConvertDate';
import { history } from '../../Helpers/history';

const headData = ["Drug Name",  "Dosage","Route","Quantity", "Intake Time",  "Comments"];

function EmergencyDetails({isPending, isResolved, isRejected, handleOnAfterPrint, emergencyObj={}}) {

    const {emergency_treatment, emergency_triage, patient_info} = emergencyObj;
    const patient = patient_info ? patient_info : {};
    const {patient_number, first_name, last_name, phone_no, gender, age, address, begin_datetime} = patient;
    const triages = emergency_triage ? emergency_triage : [];
    const treatments = emergency_treatment ? emergency_treatment : [];
    const {componentRef, handlePrint, a4Styles}  = usePrint(`EmergencyDetails_${patient_number}`, handleOnAfterPrint);
    const a4Paper = a4Styles();

    const patientDetails = (
        <tbody>
        <tr>
            <td>Date : <strong>{begin_datetime ? formatDateTime(begin_datetime):null}</strong></td>
        </tr>
        <tr>
            <td>Patient Number: <span css={[textWeight]}>{patient_number ? patient_number : ''}</span></td>
            <td>Gender: <span css={[textWeight]}>{gender ? gender : ''}</span></td>
            <td>Phone No.: <span css={[textWeight]}>{phone_no ? phone_no : ''}</span></td>
        </tr>
        <tr>
            <td>Patient Name: <span css={[textWeight]}>{`${first_name ? first_name : ''} ${last_name ? last_name : ''}`}</span> </td>
            <td>Age: <span css={[textWeight]}>{age ? age : ''}</span></td>
            <td>Address: <span css={[textWeight]}>{address ? address : ''}</span></td> 
        </tr>
        </tbody>
    )

    const triage = (
        <table css={[table, thBorderBottom,thBorderTop,detailsTable]}>
            <thead>
                <tr><th colSpan={2}>TRIAGE</th></tr>
            </thead>
            <tbody>
                {triages.filter(item=>item.metric_value !== '0').map((item, index)=>(
                    <tr key={index}>
                        <td>{`${item.metric_name}(${item.uom})`} : <strong>{item.metric_value}</strong></td>
                        {/* <td>{`${item.metric_name}(${item.metric_unit}) : ${item.metric_value}`}</td> */}
                    </tr>
                ))}
            </tbody>
        </table>
    )

    const medication = (
        <>
            <table css={[table, thBorderBottom,thBorderTop,detailsTable]}>
            <thead>
                <tr><th>Medication</th></tr>
            </thead>
            </table>
            <table css={[table,detailsTable]}>
            <thead>
                <tr>
                {headData.map((item, index)=>(
                    <th key={index}>{item}</th>
                ))}
                </tr>
            </thead>
            <tbody>
                {treatments.map((item,index)=>(
                    <tr key={index}>
                        <td>{item.product_name}</td>
                        <td>{item.dosage}</td>
                        <td>{item.route}</td>
                        <td>{item.quantity}</td>
                        <td>{item.intake_time}</td>
                        <td>{item.instructions}</td>
                    </tr>
                ))}
            </tbody>
            </table>
        </>
    )

    const ItemDetails = () =>{
        return (
            <>
            {triages.length > 0 ?  triage:null}
            {treatments.length > 0 ? medication:null}
            </>
        )
    }
    return (
        <div>
            <RightAlignedContainer>
                <button className='btn btn-sm sms-info-btn'  onClick={handlePrint}>Print</button>
            </RightAlignedContainer>
            <div ref={componentRef} className={`${a4Paper.emergencyListView} emergency-details`}>
                {isPending ? <div className='text-center'><LoadingGif/></div>:null}
                {isResolved ? <PrintTemplate title='Emergency Details' {...{patientDetails, itemDetails:<ItemDetails/>}} />:null}
                {isRejected ? <div className='text-center'>The server did not return a valid response</div>:null}
            </div>
        </div>
    
    )
}

export default EmergencyDetails