import React, {useState, useEffect, useRef} from "react";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TableRow from "@material-ui/core/TableRow";
import { RightAlignedContainer, Button } from "../../Utils/styledComponents";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import *  as snackbarActions from "../../../actions/snackbarActions";
import {Link} from "react-router-dom";
import {BackButton, DataExportButton} from "../../Utils/Buttons/DataExportationButton";
import Label from "../../Utils/FormInputs/Label";
import SelectInput from "../../Utils/FormInputs/SelectInput";
import { faClipboardList } from "@fortawesome/free-solid-svg-icons/faClipboardList";
import { Container } from "../../Utils/styledComponents";
import { BorderedCell, ReportsTable } from "../../Utils/Tables/ReportsTable";
import axios from "axios";
import { usePrint } from "../../Utils/Templates/usePrint";
import { config } from "../../../Helpers/env";
import {logoutErrorMessage} from "../../../Helpers/ErrorMessages"; 
import { DateTimeFilter } from "../../Utils/ReusableComponents/DateTimeFilter";
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { history } from "../../../Helpers/history";
import { dateConvert } from "../../Utils/ConvertDate";
import { HospitalAddress } from "../../Utils/HospitalAddress";
import {MedicalReportFilter} from "../ReportFilter";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {LoadingGif} from "../../Utils/Loader";
import {useExcelReports, usePdfDownload} from "../../Utils/ReusableComponents/useExcelExport";

const create_rows = (row_titles) => {
    let table_array = [];

    for (var x = 0; x < row_titles.length; x++) {
        let new_item = {
            row_title : row_titles[x],
            one_to_twenty_eight_days : {
                male: 0, female : 0
            },
            one_month_to_one_year :  {
                male: 0, female : 0
            },
            two_to_four_years :  {
                male: 0, female : 0
            },
            five_to_twelve_years :  {
                male: 0, female : 0
            },
            thirteen_to_nineteen_years :  {
                male: 0, female : 0
            },
            twenty_to_thirty_nine_years :  {
                male: 0, female : 0
            },
            forty_to_fifty_nine :  {
                male: 0, female : 0
            },
            sixty_and_above_years :  {
                male: 0, female : 0
            },
            total : 0
        }
        table_array.push(new_item)
    }
    return table_array;
}

/**
 * Report for LANE GENERAL HOSPITAL, THE MOH
 * @param {*} props 
 * @returns 
 */
const ProviderServicesReport = (props) => {

    const {actions, snackbars} = props;
    const [submitted, setSubmitted] = useState(false);
    const [providerOptions, setProviderOptions] = useState([]);
    const [insurance_provider, setInsuranceProvider] = useState('');
    const [state, setState] = useState({start_date:dateConvert(), end_date:dateConvert(), patient_type:1})
    const {start_date, end_date, patient_type} = state;
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [payment, setPayment] = useState({value:1,label:'Cash'})
    const [patientType, setPatientType] = useState({value:1,label:'Out-patient'})
    const [loading, setLoading] = useState('idle')
    const isPending = isSubmitted === 'pending';

    const handleProviderChange = (event) =>{
        const {value} = event.target;
        setInsuranceProvider(value);
    }   

    // get providers
    useEffect(() => {
        axios.get(`${config.smsUrl}/cbilling/insurance_providers/list`).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const arr = !dt.data ? [] : dt.data;
            const provs = arr.map(item=>({
                ...item, 
                value:item.provider_id, 
                text:item.provider_name}));
            // if (provs.length > 0) {
            //     // if there is at least one provider, show that report
            //     setInsuranceProvider(provs[0].value);
            // }
            setProviderOptions(provs);
        }).catch(err => {
         logoutErrorMessage(err,null,actions)
        })
    }, []);

    const provider_services_age_groups = [
        {age:'0 - 28 days'},{age:'1 month - 1Yr'},{age:'2 - 4Yrs'},
        {age:'5 - 12Yrs'},{age:'13 - 19Yrs'},{age:'20 - 39Yrs'},
        {age:'40 - 59Yrs'},{age:'60Yrs & Above'},
    ]

    // functions for the date time filter
    const handleChange = (event) =>{
        const {name, value} = event.target;
        setState({...state,[name]:value})
    }



    // function to make the api call to retrieve the moh reports
    const retrieveMOHReports = () => {
        const formData = new FormData();
        formData.append('start_end', start_date);
        formData.append('end_date', end_date);
        formData.append('provider_id',insurance_provider);
        formData.append('patient_type', patientType?.value ? patientType?.value :'');
        formData.append('mode_of_payment', payment?.value ? payment?.value :'');
        setIsSubmitted('pending')
        setLoading('loading')
        axios.post(`${config.smsUrl}/creport/moh_reports`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const new_report = report_details.map((item) => {
                // get the key from the data being returned
                const key_value = titles_dict[item.row_title];
                if (key_value) {
                    const get_key = Object.keys(dt[titles_dict[item.row_title]])[0];
                    const get_values = dt[key_value][get_key];
                    let total = 0;
                    for (const [key, value] of Object.entries(get_values)) {
                        const male = value.male??0;
                        const female = value.female??0;
                        total += male + female;
                    }
                    return {...item, total, ...get_values};
                }
                return item;
            })
            setSubmitted(false);
            setReportDetails(new_report);
            setIsSubmitted('resolved')
            setLoading('success')
        }).catch(err => {
            logoutErrorMessage(err,null,actions)
            setIsSubmitted('rejected')
            setLoading('error')
        })
    }

    const handleSubmit = (event) =>{
        event.preventDefault();
        setSubmitted(true);
        if(start_date && end_date){
            retrieveMOHReports();
        }
    }

    // get the report details
    useEffect(() => {
        retrieveMOHReports();
    }, [payment, patientType, insurance_provider]);

    const titles_dict = {
        'No. Of Admissions':'patient_admissions', 
        'No. Of O.P.D Patients': 'opd_patient', 
        'No. Of Referral In' : 'inward_referrals', 
        'No. Of Referral Out' : 'outward_referrals',
        'No. Discharged Patients':'discharged_patient', 
        'No. Of OBS & Gyn' : '', 
        'No. Of F.P Patients' : 'patients_followed_up', 
        'No. Of Patients Followed Up' : 'patients_followed_up',
        'No. Of Patents with C.Ds' : '', 
        'No. Of Patients On Drs APP' : "", 
        'No. of Death' : 'patient_death'
    }

    const new_Arr = create_rows(Object.keys(titles_dict));

    const [report_details, setReportDetails] = useState(new_Arr);

    const handleOnAfterPrint = () => {
        history.push('/reports/moh_reports');
    }

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const {componentRef, handlePrint,  fontSize}  = usePrint(`MOH_Report`, handleOnAfterPrint);

    const {openBar, type, message} =snackbars;

    const commonParams = {start_date , end_date,patient_type:patientType?.value ? patientType?.value:'',
        provider_id:insurance_provider}

    const fileName = `${patientType?.label}-${start_date} to ${end_date} MOH`

    const excelObj = {file_name:fileName,params:{...commonParams, export_type:2}, url:'creport/moh_reports_exports'}

    const pdfObj = {file_name:fileName,params:{...commonParams, export_type:1}, url:'creport/moh_reports_exports'}


    const {exportExcel, isLoading:isLoadingExcel} = useExcelReports(actions,excelObj)
    const {exportPdf, isLoading:isLoadingPdf} = usePdfDownload(actions,pdfObj)

    const isLoading = loading === 'loading'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'
    return (
        <div data-testid="provider-services-list" className='journals'>
            <PageTitle title="Provider Services Reports"/>
            <SubHeader title="Reports" subTitle="Services Offered Per Insurance Provider">
                <FontAwesomeIcon icon={faClipboardList}/>
            </SubHeader>
            <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>

            <Container>
                <div style={{display:'flex', justifyContent:'space-between'}}>
                    <BackButton text={'General Reports'} to={'/reports/generalreports'}/>
                    <DataExportButton exportCsv={exportExcel} isLoading={isLoadingExcel || isLoadingPdf} downLoadPdf={exportPdf}/>
                </div>
                
                {/** filter things */}
                <DateTimeFilter {...{start_date,end_date, handleChange, submitted,handleSubmit,isPending, type:'date'}}>
                    <div className='col-lg-4'>
                        <Form.Group as={Row}>
                            <Label name='Filter by Provider:' sm={5} column />
                            <Col sm={7}>
                                <SelectInput  value={insurance_provider} name="insurance_provider" onChange={handleProviderChange} options={providerOptions}
                                defaultOption="Select..."/>
                            </Col>
                        </Form.Group>
                    </div>
                    <div className='col-lg-4'>
                        <Form.Group as={Row}>
                            <Label name="Patient Type" column sm="5"/>
                            <Col sm="7">
                            <SelectInput value={patient_type} onChange={handleChange} name="patient_type"
                                options={[{value:'1', text:'Outpatient'},{value:'2', text:'Inpatient'}]}/>
                            </Col>
                        </Form.Group>
                    </div>
                </DateTimeFilter>

                {/*<MedicalReportFilter {...{payment, setPayment, patientType, setPatientType,handleSubmit,*/}
                {/*    start_date, end_date, handleChange,isPending, submitted, actions, file_name:'MOH Report',*/}
                {/*    excelUrl:'download_excel', pdfUrl:'download_pdf'}}/>*/}


                {/** Actual table */}
                <div ref={componentRef}>
                    <style>
                        {/* {getPageMargins()} */}
                        {fontSize()}
                    </style>

                    <table className='table table-sm table-borderless prescription-table receipt-print'>
                        <thead>
                            <tr>
                                <td>
                                    <HospitalAddress/>
                                    <div className="page-header-space"></div>
                                </td>
                            </tr>
                        </thead>
                    </table>

                    <ReportsTable {...{headTitle:'Category', provider_services_age_groups, gender_abbr:true}}>
                        {isLoading ? <TableRow>
                            <BorderedCell align='center' colSpan={18}><LoadingGif/></BorderedCell>
                        </TableRow>: null}
                    {
                      isSuccess ? report_details.length > 0 ?   report_details.map((item, index) => {
                            return (
                                <TableRow>
                                    <BorderedCell>
                                        <strong>{item.row_title}</strong>
                                    </BorderedCell>

                                    <BorderedCell>{item.one_to_twenty_eight_days['male']??0}</BorderedCell>
                                    <BorderedCell>{item.one_to_twenty_eight_days['female']??0}</BorderedCell>

                                    <BorderedCell>{item.one_month_to_one_year['male']??0}</BorderedCell>
                                    <BorderedCell>{item.one_month_to_one_year['female']??0}</BorderedCell>
                                    
                                    <BorderedCell>{item.two_to_four_years['male']??0}</BorderedCell>
                                    <BorderedCell>{item.two_to_four_years['female']??0}</BorderedCell>
                                    
                                    <BorderedCell>{item.five_to_twelve_years['male']??0}</BorderedCell>
                                    <BorderedCell>{item.five_to_twelve_years['female']??0}</BorderedCell>
                                    
                                    <BorderedCell>{item.thirteen_to_nineteen_years['male']??0}</BorderedCell>
                                    <BorderedCell>{item.thirteen_to_nineteen_years['female']??0}</BorderedCell>
                                    
                                    <BorderedCell>{item.twenty_to_thirty_nine_years['male']??0}</BorderedCell>
                                    <BorderedCell>{item.twenty_to_thirty_nine_years['female']??0}</BorderedCell>
                                    
                                    <BorderedCell>{item.forty_to_fifty_nine['male']??0}</BorderedCell>
                                    <BorderedCell>{item.forty_to_fifty_nine['female']??0}</BorderedCell>
                
                                    <BorderedCell>{item.sixty_and_above_years['male']??0}</BorderedCell>
                                    <BorderedCell>{item.sixty_and_above_years['female']??0}</BorderedCell>

                                    <BorderedCell>{item.total}</BorderedCell>
                                </TableRow>
                            )
                        }): <TableRow>
                          <BorderedCell align='center' colSpan={18}>No records found</BorderedCell>
                      </TableRow>: null
                    }
                        {isError ? <TableRow>
                            <BorderedCell align='center' colSpan={18}>The server did not return a valid response</BorderedCell>
                        </TableRow>: null}
                    </ReportsTable>
                </div>
            </Container>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(ProviderServicesReport);
