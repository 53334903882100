/** @jsxImportSource @emotion/react */
import { css} from '@emotion/react'
import { history } from '../../../Helpers/history';
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCoins} from "@fortawesome/free-solid-svg-icons/faCoins";
import PageTitle from "../../Utils/smsTitle";
import { Button, MiniCenteredDiv, RightAlignedContainer } from '../../Utils/styledComponents'
import { PrintTemplate, ItemsTable } from '../../Utils/Templates/PrintTemplate'
import { usePrint } from '../../Utils/Templates/usePrint';
import {useBillingReceipt} from "./hooks/useBillingReceipt";
import { formatDateTime } from '../../Utils/ConvertDate';
import Card from '@material-ui/core/Card'
import { BackButton } from '../../Utils/Buttons/DataExportationButton';
import styled from "@emotion/styled/macro";
import {CurrencyToggle, FxRate} from "../../Utils/CurrencyToggle";
import React from "react";
import {useVersionsContext} from "../../../Context/versions-context";


const textWeight = css`
    font-weight:600;
`



const StyledContainer = styled(RightAlignedContainer)({
    marginBottom:'5px'
})

function BillingReceipt(props) {
    const {receipt, services, state,  total_amount, cashier,selectedCurrency,
        currencies, currency, handleChangeExchangeRate, handleChangeCurrency, currencyExchangeRate} = useBillingReceipt(props)
    const { receipt_no, date_created} = state;
    const {currency_total, usd_total,paid_amount,due_amount,
        currency_paid_amount,currency_due_amount} = total_amount

    const isBaseCurrency = currency && currency.split('-')[1] === 'true'
    const currency_symbol = currency && currency.split('-')[0]

    const {subscription} = useVersionsContext()
    const version = subscription?.version
    const isPremium = version === 'Premium'



    const handleOnAfterPrint = () => {
        history.push('/paymentList');
    }

    const {componentRef, handlePrint,fontSize}  = usePrint(`BillingReceipt${receipt_no}`,handleOnAfterPrint)

    const headData = [{key:'name',value:'Item'}, {key:'quantity',value:'Quantity'},
        {key:'rate',value:`Rate(${currency_symbol})`}, {key:'amount',value:`Amount(${currency_symbol})`}]
  const patientDetails = (
      <tbody>
          <tr>
              <td><span>Date: </span> <span css={[textWeight]}>{formatDateTime(date_created)}</span></td>
              <td><span>Patient Name: </span> <span css={[textWeight]}>{`${!receipt.patient_firstname ? "" : receipt.patient_firstname} 
                                ${!receipt.patient_lastname ? "" : receipt.patient_lastname}`}
                </span> </td>


          </tr>
          <tr>
              <td><span>Cashier: </span> <span><strong>{cashier}</strong></span></td>
              <td><span>Contact: </span> <span css={[textWeight]}>{receipt.phone_no}</span></td>
          </tr>
          <tr>

              <td><span>Patient Number: </span> <span css={[textWeight]}>{receipt.patient_number}</span></td>
              <td><span>Address:</span> <span css={[textWeight]}>{receipt.address}</span></td>
          </tr>
      </tbody>
  )
  const itemDetails = (
    <ItemsTable {...{headData}}>
      <tbody>
      {services.map((item) => {
            return (
                <tr key={item.bill_id}>
                    {/* <td>{item.invoice_no}</td> */}
                    <td><span>{item.item_name}</span></td>
                    <td><span>{item.quantity}</span></td>
                    <td><span>{isBaseCurrency ?  item.usd_rate.toFixed(3) :   (item.currency_rate.toFixed(3)) }</span></td>
                    {/* <td><span>{isBaseCurrency ?  item.usd_rate :   (item.currency_rate) }</span></td> */}
                     <td><span>{isBaseCurrency ? item.usd_total.toFixed(3) :  (item.currency_total.toFixed(3))}</span></td>
                    {/* <td><span>{isBaseCurrency ? item.usd_total :  (item.currency_total)}</span></td>  */}
                </tr>
            )
        })}
          <tr>
              <td colSpan={headData.length -1}  align='right'><span css={[textWeight]}>Total Amount({currency_symbol})</span></td>
              <td ><span css={[textWeight]}>{isBaseCurrency ? Number(usd_total).toFixed(3) :  Number(currency_total).toFixed(3)}</span></td>
          </tr>
          <tr>
              <td colSpan={headData.length -1}  align='right' ><span css={[textWeight]}>Amount Received({currency_symbol})</span></td>
              <td><span css={[textWeight]}>{isBaseCurrency ? Number(paid_amount).toFixed(3) :  Number(currency_paid_amount).toFixed(3)}</span></td>
          </tr>
          <tr>
              <td colSpan={headData.length -1}  align='right'  ><span css={[textWeight]}>Balance({currency_symbol})</span></td>
              <td><span css={[textWeight]}>{isBaseCurrency ? Number(due_amount).toFixed(3) :  Number(currency_due_amount).toFixed(3)}</span></td>
          </tr>
      </tbody>
    </ItemsTable>
  )
  return (
      <div>
        <PageTitle title="Billing Receipt"/>
        <SubHeader title="Billing" subTitle="Billing receipt">
            <FontAwesomeIcon icon={faCoins}/>
        </SubHeader>
        <MiniCenteredDiv>
            <BackButton to='/paymentList' text='Billing List' data-testid="billing-receipt-back-button"/>
            <StyledContainer>
            
              <Button variant='orange' onClick={handlePrint}>Print</Button>
            </StyledContainer>
            <Card>
                {isPremium ? <div style={{padding:'8px 60px', borderBottom:'1px solid rgba(224, 224, 224, 1)'}}>
                    <RightAlignedContainer>
                        <CurrencyToggle options={currencies} value={currency} handleChangeCurrency={handleChangeCurrency} />
                        { !isBaseCurrency ? <FxRate currency={selectedCurrency?.currency_symbol} value={currencyExchangeRate}  handleChangeRate={handleChangeExchangeRate} nonBaseCurrency={currency_symbol}/>: null}
                    </RightAlignedContainer>
                </div>: null}
                <div ref={componentRef} style={{padding:'60px'}} className="requisition-receipt">
                    <style>
                        {fontSize()}
                    </style>
                    <PrintTemplate title='RECEIPT' {...{patientDetails,itemDetails}}/>
                </div>
            </Card>
        </MiniCenteredDiv>
      </div>
  )
}

export default BillingReceipt