import React, {useEffect, useState} from "react";
import axios from "axios";

import { errorMessages } from "../../../Helpers/ErrorMessages";
import { config } from "../../../Helpers/env";
import { useSnackbar } from "../../../Context/snackbar-context";




export const useAddSubscriber = (actions) =>{
    const {handleSuccess, handleError} = useSnackbar()
    const [name, setName] = useState("");
    const [status, setStatus] = useState(0)
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [openDialog, setOpenDialog] = useState(false);
    

    const handleOpenDialog = () =>{
        setOpenDialog(true)
    }

    const handleCloseDialog = () =>{
        setOpenDialog(false)
    }

    

    const handleSubmit = (event) =>{
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const fieldValues = Object.fromEntries(formData.entries())
        formData.append("status", 0)
      

        setSubmitted(true);
        if(fieldValues['name']){
            setIsSubmitted('pending');
            
            axios.post(`${config.smsUrl}/cuser/add_subscriber`, formData).then(()=>{
                handleSuccess('Subscriber added successfully')
                setIsSubmitted('resolved');
                setSubmitted(false)
                setName("");
                setOpenDialog(false)
                
            }).catch(err=>{
                setIsSubmitted('rejected');
                handleError('An error occured on the server')
                // errorMessages(err,null, actions)
            })
        }
    };

    const isPending = isSubmitted === 'pending'
    const isResolved = isSubmitted === 'resolved'

    const open =  openDialog
    const handleClose =  handleCloseDialog

    const title = 'Add Subscriber'

   return {
      isResolved,
       open,
       handleOpenDialog,
       name,
       title,
       handleSubmit,
       submitted,
       isPending, handleClose,
      
   }



}