import * as types from "../../actions/actionTypes"


const initialState = {products:[],product:{},categories:[],category:{}, loading:'idle'};


export default function (state= initialState,action) {
    switch (action.type) {
        case types.ADD_PRODUCT_REQUEST:
            return {loading: 'pending'};
        case types.ADD_PRODUCT_SUCCESS:
            return {loading: 'resolved'};
        case types.ADD_PRODUCT_ERROR:
            return {loading: 'rejected'};
        case types.EDIT_PRODUCT_REQUEST:
            return {loading: 'pending'};
        case types.EDIT_PRODUCT_SUCCESS:
            return {loading: 'resolved'};
        case types.EDIT_PRODUCT_ERROR:
            return {loading:'rejected'};
        case types.ADD_PRODUCT_CATEGORY_REQUEST:
            return {loading: 'pending'};
        case types.ADD_PRODUCT_CATEGORY_SUCCESS:
            return {loading: 'resolved'};
        case types.ADD_PRODUCT_CATEGORY_ERROR:
            return {loading: 'rejected'};
        case types.EDIT_PRODUCT_CATEGORY_REQUEST:
            return {loading: 'pending'};
        case types.EDIT_PRODUCT_CATEGORY_SUCCESS:
            return {loading: 'resolved'};
        case types.EDIT_PRODUCT_CATEGORY_ERROR:
            return {loading: 'rejected'};
        case types.PRODUCT_LIST_REQUEST:
            return {...state, loading:'pending'};
        case types.PRODUCT_LIST_SUCCESS:
            return {...state, products: action.products, loading: 'resolved'};
        case types.PRODUCT_LIST_ERROR:
            return {...state, loading: 'rejected'};
        case types.PRODUCT_UPDATE_FORM:
            return {...state,product: action.product};
        case types.DELETE_PRODUCT:
            return {...state,products: state.products.filter(product=>product.product_id !== action.product_id)};
        case types.CATEGORY_LIST_REQUEST:
            return {...state,loading:'pending'};
        case types.CATEGORY_LIST_SUCCESS:
            return {...state,categories: action.categories,loading: 'resolved'};
        case types.CATEGORY_LIST_ERROR:
            return {...state,loading: 'rejected'};
        case types.CATEGORY_UPDATE_FORM:
            return {...state, category: action.category};
        case types.DELETE_CATEGORY:
            return {...state, categories: state.categories.filter(category=>category.category_id !== action.category_id)};
        default:
            return state;

    }

}
