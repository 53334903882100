import React from "react";
import PageTitle from "../Utils/smsTitle";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CustomTable from "../Utils/Tables/CustomTable";
import FilterResults from "react-filter-search";
import TableBody from "@material-ui/core/TableBody";
import {getComparator, stableSort} from "../Utils/Tables/sortingFunctions";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {Link} from "react-router-dom";
import {faCog} from "@fortawesome/free-solid-svg-icons/faCog";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../actions/snackbarActions";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import DeleteDialog from "../Utils/Dialogs/DeleteDialog";
import {useTheme} from "@material-ui/core/styles";
import {useRoleList} from "./CustomHooks/useRoleList";
import { Container } from "../Utils/styledComponents";

const headData = [{id: 'sl', numeric: false, disablePadding: false, label: 'SL'},
    {id: 'roleName', numeric: false, disablePadding: false, label: 'Role Name'},
    {id: 'status', numeric: false, disablePadding: false, label: 'Status'},
    {id: 'actions', numeric: false, disablePadding: true, label: 'Action'},
];
export const RolesList = (props) =>{
    const {searchValue,  order, orderBy, page, rowsPerPage,  roles, loading,
        openDelete, handleOpenDelete, handleCloseDelete, handleDelete, handleCloseSnackbar,
        handleSearch,  handleRequestSort, handleChangePage,
        handleChangeRowsPerPage} = useRoleList(props)
    const {openBar,type,message} = props;
    const data = !roles ? [] :roles;
    const perm = JSON.parse(sessionStorage.getItem('permission'));
    const permission = !perm ? {} : perm;
    const user_roles = !permission.roles ? {} : permission.roles;
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    const status = 1;
    return(
        <div data-testid="role-list" className='journals'>
            <PageTitle title="Roles"/>
            <SubHeader title="Settings" subTitle="Roles">
                <FontAwesomeIcon icon={faCog}/>
            </SubHeader>
            <Container>
            <div className="text-right mb-3">
                <Link to='/addrole'>
                    <button className='btn btn-sm sms-info-btn'>New Role</button>
                </Link>
            </div>
            <div className="mui-tables">
                <MainSnackbar handleCloseBar={handleCloseSnackbar} message={message} open={openBar} variant={type}/>
                <DeleteDialog message={status === 1 ?  'deactivate':'activate'} openDialog={openDelete} handleClose={handleCloseDelete}
                                    text="category" title={`${status === 1 ? 'Deactivate':'Activate'} Category`}>
                        <button className='btn sms-info-btn btn-sm' onClick={handleDelete}>{status === 1 ? 'Deactivate':'Activate'}</button>
                        <button className="btn sms-grey-btn btn-sm" onClick={handleCloseDelete}>No</button>    
                </DeleteDialog>
                <CustomTable title="Roles" handleChangePage={handleChangePage} handleChangeRowsPerPage={handleChangeRowsPerPage}
                             headData={headData} handler={handleSearch} page={page} rowsPerPage={rowsPerPage} filter
                             handleRequestSort={handleRequestSort} data={data} colSpan={5} order={order} orderBy={orderBy} id="searchValue">
                    {loading ? <TableBody><TableRow>
                        <TableCell colSpan="8" align="center"><img src="/images/smsloader.gif"
                                                                   alt="loader"/></TableCell>
                    </TableRow></TableBody> : data.length > 0 ?
                    <FilterResults value={searchValue} data={data}
                                   renderResults={results => (
                                       <TableBody>
                                           {stableSort(results, getComparator(order, orderBy))
                                               .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                               .map((item,index) => {
                                                   const count = (page * rowsPerPage) + index + 1;
                                                   return (
                                                       <TableRow
                                                           hover
                                                           role="checkbox"
                                                           tabIndex={-1}
                                                           key={index}
                                                           className='mui-table-row'
                                                           id="services"
                                                       >
                                                           <TableCell>{count}</TableCell>
                                                           <TableCell>{item.role_name}</TableCell>
                                                           <TableCell>Active</TableCell>
                                                           <TableCell>
                                                               {user_roles.update ? <Link
                                                                   to={{pathname: `/editrole/${item.role_id}`,state:{rname:item.role_name}}}>
                                                                   <button
                                                                       className="btn btn-sm sms-info-btn mr-1">Edit
                                                                   </button>
                                                               </Link>:<div/>}
                                                               {user_roles.delete ? <button
                                                                   onClick={() => handleOpenDelete(item.role_id)}
                                                                   className="btn btn-sm sms-btn-dismiss">Delete
                                                               </button>:<div/>}
                                                          </TableCell>
                                                       </TableRow>
                                                   );
                                               })}
                                       </TableBody>
                                   )}/>: <TableBody><TableRow>
                            <TableCell colSpan="4" align="center">No records found</TableCell>
                        </TableRow></TableBody>}
                </CustomTable>
            </div>
            </Container>
        </div>
    )
};
function mapStateToProps(state) {
    return{snackbars:state.snackbar}

}

function mapDispatchToProps(dispatch) {
    return {actions:{snackbarActions:bindActionCreators(snackbarActions, dispatch)}}
}
export default connect(mapStateToProps, mapDispatchToProps)(RolesList);
