import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Label from "../Utils/FormInputs/Label";
import {CustomSelect} from "../Utils/FormInputs/SelectInput";
import TextField from "../Utils/FormInputs/TextField";
import { ErrorMessage } from "../Utils/styledComponents";
import {ResizableTextarea} from "../Utils/FormInputs/Textarea";
import InputGroup from "react-bootstrap/InputGroup";


const  BedTransfer=({state, submitted, isSubmitted, handleChange, handleResetForm, handleSubmitForm, title, text,
                                ward, changeWard, options, room, changeRoom, rooms, beds, bed_number, changeBed, p_number,
                                handleChangePatientNumbers, showNumber, patientNumbers, handleClickPatientNumber,currencyDetails,
    editTransfer ,to_bed, to_ward, to_room, handleChangeToWard, handleChangeToRoom, handleChangeToBed,patient_name=''}) =>{

    const toRooms = to_ward.value ? rooms : [];
    const toBeds = to_room.value ? beds : [];
    const st = !state ? {} : state;
    const {transfer_date,  description} = st;
    const { cost, currency_symbol} = currencyDetails

    return (
        <form autoComplete="off" onSubmit={handleSubmitForm}>
            <div className="row">
                <div className="col-md-6">
                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                            <Label name="Admission No."  type/>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={7} xl={7}>

                            <TextField type="text"
                                           {...{submitted}}
                                          name="p_number"
                                          value={p_number ? p_number.toUpperCase() : p_number} data-testid="patient-number" disabled={editTransfer}
                                          onChange={handleChangePatientNumbers} id="patient_id"/>
                            {(submitted && !p_number) &&
                            <ErrorMessage>Admission No. is required</ErrorMessage>}
                            {showNumber && <Form.Control as="select" multiple id="patient_number_select"
                                                         className="drug-ul visit-ul">
                                {patientNumbers.map((number, index) => (
                                    <option className="drug-li" key={index}
                                            onClick={handleClickPatientNumber}
                                            value={number.label} id="numbers">{number.label}</option>
                                ))}
                            </Form.Control>}
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                            <Label  name="Patient Name" type/>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                        <TextField value={patient_name} readOnly/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                            <Label  name="From: Ward" type/>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                            <CustomSelect  {...{submitted}} options={options} onChange={changeWard} value={ward}
                                          dataTest="ward"/>
                            {(submitted && !ward) &&
                            <ErrorMessage>Ward is required</ErrorMessage>}
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                            <Label  name="From: Room" type/>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                            <CustomSelect  {...{submitted}} options={rooms} onChange={changeRoom} value={room}
                                          dataTest="room"/>
                            {(submitted && !room) &&
                            <ErrorMessage>Room Name is required</ErrorMessage>}
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                            <Label  name="From: Bed" type/>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={7} xl={7}>


                            <CustomSelect  {...{submitted}} options={beds} onChange={changeBed} value={bed_number}
                                          dataTest="room"/>
                            {(submitted && !room) &&
                            <ErrorMessage>Bed Number is required</ErrorMessage>}
                        </Col>
                    </Form.Group>

                </div>
                <div className="col-md-6">
                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                            <Label  name="Transfer Date"/>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                            <TextField disabled={true}
                                       value={transfer_date}
                                       name="transfer_date" type={editTransfer ? 'text':"date"}
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                            <Label  name="To: Ward" type/>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={7} xl={7}>

                            <CustomSelect  {...{submitted}} options={options} onChange={handleChangeToWard} value={to_ward}
                                          dataTest="ward"/>
                            {(submitted && !ward) &&
                            <ErrorMessage>Ward is required</ErrorMessage>}
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                            <Label  name="To: Room" type/>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                            <CustomSelect  {...{submitted}} options={toRooms} onChange={handleChangeToRoom} value={to_room}
                                          dataTest="room"/>
                            {(submitted && !room) &&
                            <ErrorMessage>Room Name is required</ErrorMessage>}
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                            <Label  name="To: Room Cost" type/>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                            <InputGroup>
                                <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">{currency_symbol}</InputGroup.Text>
                                <TextField  type="number" value={cost} style={{pointerEvents:'none'}}/>
                            </InputGroup>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                            <Label  name="To: Bed" type/>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                            <CustomSelect  {...{submitted}} options={toBeds} onChange={handleChangeToBed} value={to_bed}
                                          dataTest="to_bed"/>
                            {(submitted && !to_bed) ?
                            <ErrorMessage>Bed Number is required</ErrorMessage>:
                                ((bed_number.value && to_bed.value) && bed_number.value === to_bed.value) ?  <ErrorMessage>To:Bed cannot be the same as From:Bed</ErrorMessage> :null}
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                            <Label  name="Reason" />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                            <ResizableTextarea value={description} rows={2}
                                               onChange={handleChange} name="description" 
                            />
                        </Col>
                    </Form.Group>

                </div>
            </div>

            <div className="text-right">
                {!editTransfer && <button onClick={handleResetForm} type="button" data-testid="reset-bed-transfer"
                        className="btn btn-sm sms-grey-btn px-4 mr-2" id="reset_service">Reset
                </button>}
                <button type="submit" id="submit_service" className="btn btn-sm sms-btn px-4"
                        disabled={isSubmitted === 'pending'} data-testid="add-bed-transfer">
                    {isSubmitted === 'pending' ? "Saving..." : "Save"}</button>
            </div>
        </form>

    )
}


export default BedTransfer;
