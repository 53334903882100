import {useEffect, useState} from "react";
import {dateConvert} from "../../../Utils/ConvertDate";
import axios from "axios";
import {config} from "../../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import {history} from "../../../../Helpers/history"

export const useEditDispatch = (actions, match) => {
    const initialState = [{
        product_name: '', unit: '', quantity_requested: '', batches: [], batch_id: '', isBatch: false,
        quantity: '', product_id: '', showProduct: false, available_quantity: ''
    }]
    const [products, setProducts] = useState(initialState);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [state, setState] = useState({
        date_issued: dateConvert(), endorsed_by: '', received_by: '',
        supplied_by: '', remarks: '', date_requested: '', requesting_department: '', from_department: '',
        to_department: '', requisition_id: '', issued_by: ''
    })
    const [requisition, setRequisition] = useState({});
    const [dept, setDept] = useState({form_department_id: '', to_department_id: ''});
    const [openDialog, setDialog] = useState(false);
    const [d_id, setDispatch] = useState('')
    const [submitted, setSubmitted] = useState(false);

    const {dispatch_id, status} = match.params;
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    // const issued_by = `${!_token.first_name ? "" : _token.first_name} ${!_token.last_name ? "" : _token.last_name}`;
    const user_roles_id = !_token.user_roles_id ? 1 : _token.user_roles_id;


    useEffect(() => {
        const formData = new FormData();
        formData.append('dispatch_id', dispatch_id);
        const url = status ? 'pharmacy_dispatch_update_form' : 'dispatch_update_form'
        axios.post(`${config.smsUrl}/cinventory/${url}`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            setRequisition(dt)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, [])

    useEffect(() => {
        const {product, dispatch_info} = requisition;
        const dt = !dispatch_info ? {} : dispatch_info;
        const {
            date_created, recieved_by_name, requested_by_name,
            from_department_name, from_store, to_department, requisition_id, requisition_date, to_department_name
        } = dt;
        setState({
            date_issued: date_created, endorsed_by: '', received_by: recieved_by_name,
            supplied_by: '', remarks: '', date_requested: requisition_date,
            requesting_department: '', from_department: from_department_name,
            to_department: to_department_name, requisition_id,
            requested_by: requested_by_name, issued_by: recieved_by_name
        })
        setDept({form_department_id: from_store, to_department_id: to_department})
        const prdts = !product ? [] : product;
        const prods = prdts.map(item => {
            if (status) {
                return ({
                    product_name: item.product_name,
                    unit: item.unit_name,
                    quantity_requested: item.quantity_requested,
                    quantity: item.quantity,
                    product_id: item.product_id,
                    available_quantity: item.available_qty < 0 ? 0 : item.available_qty
                })
            } else {
                return ({
                    product_name: item.product_name,
                    unit: item.unit_name,
                    quantity_requested: item.quantity_requested,
                    quantity: item.quantity,
                    product_id: item.product_id,
                    batches: item.batches ? item.batches : [],
                    batch_id: item.batch_id ? item.batch_id : '',
                    available_quantity: item.total_product.total_product < 0 ? 0 : item.total_product.total_product
                })
            }

        })
        setProducts(prods)
    }, [requisition])


    const handleOpenDialog = (_id) => {
        setDialog(true);
        setDispatch(_id);
    }

    const handleCloseDialog = () => {
        setDialog(false)
    }


    const handleChangeItems = (event, index) => {
        const {value} = event.target;
        const productCopy = [...products];
        const arr = productCopy.map((item, idx) => {
            if (idx === index) {
                if (status) {
                    return {...item, quantity: value}
                } else {
                    if (item.batch_id) {
                        return {...item, quantity: value, isBatch: false}
                    } else {
                        return {...item, quantity: '', isBatch: true}
                    }
                }
            }
            return item
        })
        setProducts(arr)

    };

    const handleChangeBatch = (event, idx) => {
        let allRows = [...products];
        allRows[idx]['batch_id'] = event.target.value;
        const formData = new FormData();
        formData.append('batch_id', event.target.value)
        formData.append('product_id', allRows[idx]['product_id'])
        axios.post(`${config.smsUrl}/cinventory/retrieve_product_batch_id`, formData).then(response => {
            const res = response.data;
            const data = res ? res : {};
            const t_product = data.total_product ? data.total_product : 0;
            allRows[idx]['available_quantity'] = t_product < 0 ? 0 : t_product;
            allRows[idx]['isBatch'] = false
            setProducts(allRows)
        }).catch(error => {
            errorMessages(error, null, actions)
        });
    };


    const handleAddRow = () => {
        setProducts([...products, ...initialState])
    };

    const removeRow = (index) => {
        let allRows = [...products];
        if (allRows.length > 1) {
            allRows.splice(index, 1);
        }
        setProducts(allRows);
    };


    const handleSubmit = (event) => {
        event.preventDefault();
        const {requisition_id, remarks} = state;
        const {form_department_id, to_department_id} = dept;
        const formData = new FormData();
        formData.append('dispatch_id', dispatch_id);
        formData.append('requisition_id', requisition_id);
        formData.append('user_roles_id', user_roles_id);
        formData.append('create_by', user_roles_id);
        formData.append('recieved_by', user_roles_id);
        formData.append('remarks', remarks);
        formData.append('store_id', form_department_id);
        formData.append('to', to_department_id);
        products.filter(drug => drug.product_id !== '' && drug.quantity !== '' &&
            (!status && drug.batch_id !== '')).forEach(drug => {
            formData.append('product_id', drug.product_id);
            formData.append('quantity', drug.quantity);
            !status && formData.append('batch_id', drug.batch_id);
        });
        setSubmitted(true)
        const pharmacy = products.every(drug => drug.product_id && drug.quantity <= drug.available_quantity && drug.quantity);
        const stores = products.every(drug => drug.product_id  && drug.quantity <= drug.available_quantity && drug.batch_id && drug.quantity );
        const val = status ? pharmacy : stores;
        const url = status ? 'pharm_dispatch_update' : 'update_dispatch';
        if (val) {
            setIsSubmitted('pending');
            axios.post(`${config.smsUrl}/cinventory/${url}`, formData).then(res => {
                const data = res.data;
                const dt = !data ? {} : data;
                const _id = !dt.dispatch_id ? "" : dt.dispatch_id;
                handleOpenDialog(_id)
                setIsSubmitted('resolved');
                actions.snackbarActions.snackSuccess('Product dispatch updated successfully');
            }).catch(err => {
                setIsSubmitted('rejected');
                errorMessages(err, null, actions);

            })
        }
    };

    const handleClick = () => {
        history.push(status ? `/dispatchnote/${dispatch_id}/${status}`:`/dispatchnote/${dispatch_id}`)
    }

    const handleCancel = () => {
        history.push(status ? '/dispatchlist/1' : '/dispatchlist')
    }
    const closeSnackBar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    return {
        products, isSubmitted, state, handleClick, handleCancel, d_id,
        handleChangeItems, handleAddRow, removeRow, dispatch_id, handleChangeBatch, submitted,
        handleSubmit, closeSnackBar, openDialog, handleOpenDialog, handleCloseDialog, requisition, status
    }
}