import React from "react";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import *  as snackbarActions from "../../../actions/snackbarActions";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {faBookMedical} from "@fortawesome/free-solid-svg-icons/faBookMedical";
import {MainSearchComponent} from "../MainSearch";
import Card from '@material-ui/core/Card'
import {Legend, Line} from "recharts";
import {CustomSelect} from "../../Utils/FormInputs/SelectInput";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SmsChips from "../../Utils/Buttons/SmsChip";
import TextField from "../../Utils/FormInputs/TextField";
import {useProductTrends} from "./CustomHooks/useProductTrends";
import SmsLineChart from "../../Main/Charts/LineChart";


const ProductTrends = (props) => {
    const {closeSnackbar, search_type, handleSubmit, chips, product, showProduct, products,
        sortBy, handleChangeSortBy, autoSearchProduct, selectProduct,
        handleDeleteChip, text, textType, year, date, handleChangeSearch,
        handleChangeDate, handleChangeYear, handleIncrementYear, statistics, isSubmitted, submitted,
    } = useProductTrends(props)
    const {product_name} = product
    const {start_date, end_date} = date;
    const {openBar, type, message} = props.snackbars;
    const label = sortBy.label === 'Amount' ? "Amount" : 'Quantity';
    return (
        <div data-testid="product-trends">
            <PageTitle title="Inventory Reports"/>
            <SubHeader title="Reports" subTitle="Product Trends">
                <FontAwesomeIcon icon={faBookMedical}/>
            </SubHeader>
            <div className="product-trends">
                <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>
                <MainSearchComponent text={text} type={textType} changeDate={handleChangeDate}
                                     handleSubmit={handleSubmit} isSubmitted={isSubmitted}
                                     changePeriod={handleChangeSearch} end_date={end_date}
                                     handleChangeValue={handleChangeYear} submitted={submitted}
                                     search_type={search_type} handleChangeYear={handleIncrementYear}
                                     start_date={start_date} year={year}/>
                <Card className="p-3 general-card mb-2">
                    <div className="row mx-0">
                        <div className="col-md-8">
                            <div className="row">
                                <div className="col-md-6">
                                    <Form.Group as={Row}>
                                        <Form.Label style={{marginTop: '.5rem'}} sm={4}>Sort By:</Form.Label>
                                        <Col sm={8}>
                                            <CustomSelect options={[{value: 1, label: 'Amount'},
                                                {value: 2, label: 'Quantity'}]} value={sortBy}
                                            onChange={handleChangeSortBy}/>
                                        </Col>
                                    </Form.Group>
                                </div>
                                <div className="col-md-6">
                                    <Form.Group as={Row}>
                                        <Form.Label style={{marginTop: '.5rem'}} sm={4}>Search Product:</Form.Label>
                                        <Col sm={8}>
                                            <TextField value={product_name} onChange={autoSearchProduct} type="text" />
                                            {showProduct &&
                                            <Form.Control as="select" className="drug-ul prescription-ul" multiple>
                                                {products.map((val, idx) => (
                                                    <option key={idx} value={val.value} className="drug-li "
                                                            onClick={() => selectProduct({value:val.value,label:val.label})}>{val.label}</option>
                                                ))}
                                            </Form.Control>}
                                        </Col>
                                    </Form.Group>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <SmsChips chips={chips}/>
                        </div>
                    </div>
                    {statistics.length > 0 ?
                        <SmsLineChart data={statistics}
                                      label={{value: label, angle: -90, position: 'insideLeft'}}
                                      height={350}
                                      xlabel={{value: 'Dates', position: 'bottom', offset: 0}}>
                            <Legend onClick={handleDeleteChip} verticalAlign="top" height={36} align="right"
                                    iconType="plainline"/>
                            {chips.length > 0 &&
                                chips.map((item) => {
                                    return (
                                        <Line key={`line_${item.value}`} type="monotone"
                                              dataKey={`value_${item.value}`} name={item.label}
                                              stroke={item.color} activeDot={{r: 5}}/>
                                    )
                                }) }

                        </SmsLineChart> :
                        <div className="text-center">Please search for a product</div>
                    }
                </Card>
            </div>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductTrends);
