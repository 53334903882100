import {useEffect, useState} from "react";
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import {titleCase} from "../../../Users/addUser";
import {dateStringConvert} from "../../../Utils/ConvertDate";
import {groupProductId, groupServiceId} from "../../utils/SanitizeArr";
import {useCurrency} from "../../../../Context/currency-context";


export const useInPatientBillingReceipt = (props, is_invoice=false) => {
    const {currency:selectedCurrency} = useCurrency(props)
    const [receipt, setReceipt] = useState({});
    const [state, setState] = useState({total_bill: 0,currency_total_bill:0, total_advance: 0, currency_paid_amount:0,
        due_amount: 0, currency_due_amount:0, paid_amount: 0, balance: 0});
    const [bills, setBills] = useState({})
    const [patient, setPatient] = useState({
        admission_id: '', patient_number: '', patient_name: '', bill_date: '',
        gender: '', dob: '', transaction_id: '', admission_date: '', discharge_date: '', address: '',
        phone_no:'',provider_name:'', account_name:''
    })
    const [items, setItems] = useState([])
    const [exchangeRate, setExchangeRate] = useState(0)
    const [currencyExchangeRate, setCurrencyExchangeRate] = useState(0)
    const [currency, setCurrency] = useState('')
    const [currencies, setCurrencies] = useState([])
    const [recordedCurrency, setRecordedCurrency] = useState({})

    const {visit_id, receipt_number} = props.match.params;
    const {actions} = props;

    const myDate = new Date();
    const now = myDate.toDateString();

    useEffect(()=>{
        if (recordedCurrency.currency_symbol){
            return
        }
        setCurrency(`${selectedCurrency?.currency_symbol}-${selectedCurrency?.is_base_currency}`)
    },[recordedCurrency.currency_symbol])

    // get currencies
    useEffect(() => {
        axios.get(`${config.smsUrl}/accounting/currencies`).then(res => {
            const data = res.data;
            const all_data = !data ? {}: data;
            const arr = all_data.data ?? [];
            const list = arr.map(item=>({...item,value:`${item.currency_symbol}-${item.is_base_currency}`, label:`${item.currency_symbol} ${item.currency_name}`}))
            setCurrencies(list)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, []);

    const currenciesFetched = currencies.length > 0


    useEffect(() => {
        if (currencies.length === 0){
            return
        }
        const numbers = receipt_number.split('-');
        const inpatient_url = is_invoice ? 'get_invoice_by_visit_id' : 'view_bill_receipt';
        const inpatient_dict = is_invoice ? {visit_id:+visit_id,invoices:numbers} : {visit_id:+visit_id,receipt_numbers:numbers};
        axios.post(`${config.smsUrl}/cbilling/${inpatient_url}`, inpatient_dict).then(res => {
            const data = res.data;
            const all_data = !data ? {} : data;
            setReceipt(all_data)
        }).catch(e => {
            logoutErrorMessage(e, null, actions)
        })
    }, [currenciesFetched]);

    useEffect(() => {

        if (is_invoice) {

            const {patient_info, invoice} = receipt;
            const invoices = invoice ?? [];
            let invoicesArr = [];

            invoices.forEach(item=>{
                invoicesArr = [...invoicesArr,...item]
            });

            const obj = invoicesArr[0] ? invoicesArr[0] :{};
            const currency_id = obj.currency_id ? obj.currency_id : 0
            const exchange_rate = obj.currency_rate ? obj.currency_rate : 0;
            const isCurrency = currencies.filter(item=>item.id === currency_id).reduce((prev, curr)=>({...prev, ...curr}),{})
            const symbol = isCurrency.currency_symbol ? isCurrency.currency_symbol : selectedCurrency?.currency_symbol
            const is_base = !(isCurrency.is_base_currency == null || undefined) ? isCurrency.is_base_currency : selectedCurrency?.is_base_currency

            const ex_rate = Math.pow(exchange_rate, -1)
            setCurrency(`${symbol}-${is_base}`)
            setRecordedCurrency(isCurrency)
            setPatient({...patient_info, ...obj});
            setExchangeRate(exchange_rate)
            setCurrencyExchangeRate(ex_rate)
            setBills({invoices:invoicesArr, balance_amount:0, amount_paid:0 });
            getLists(invoicesArr, 0,0, exchange_rate);

        } else {

            const {patient_info, bill} = receipt;
            const p_info = !patient_info ? {} : patient_info;
            const obj = bill ?? {};
            const invoices = obj.invoices ?? [];
            const bal =  obj.balance ?? 0;
    
            const paid_amount = obj.total_paid ?? 0;
            let invoicesArr = [];
            invoices.forEach(item=>{
                invoicesArr = [...invoicesArr,...item]
            })
            const ex_obj = invoicesArr[0] ? invoicesArr[0] : {};
            const currency_id = ex_obj.currency_id ? ex_obj.currency_id : 0
            const isCurrency = currencies.filter(item=>item.id === currency_id).reduce((prev, curr)=>({...prev, ...curr}),{})

            const exchange_rate = ex_obj.currency_rate ? ex_obj.currency_rate : 0;
            const symbol = isCurrency.currency_symbol ? isCurrency.currency_symbol : selectedCurrency?.currency_symbol
            const is_base = !(isCurrency.is_base_currency == null || undefined) ? isCurrency.is_base_currency : selectedCurrency?.is_base_currency
            const ex_rate = Math.pow(exchange_rate, -1)
    
            const {admission_date, discharge_date, address, dob, patient_firstname, transaction_id,provider_name, account_name,
                gender, patient_lastname, patient_number, patient_admission_id, date_created, phone_no} = p_info;

            setPatient({
                admission_id: patient_admission_id, patient_number, bill_date: date_created,
                patient_name: `${!patient_firstname ? "" : patient_firstname} ${!patient_lastname ? "" : patient_lastname}`,
                gender: titleCase(gender), dob: dob, transaction_id, admission_date, discharge_date, address: address,
                phone_no,provider_name, account_name
            });


            console.log('is Currency',isCurrency)
            console.log('currency_id',currency_id)
            setExchangeRate(exchange_rate)
            setCurrencyExchangeRate(ex_rate)
            setCurrency(`${symbol}-${is_base}`)
            setRecordedCurrency(obj)
            setBills({invoices:invoicesArr, balance_amount:bal, amount_paid:paid_amount });
            getLists(invoicesArr, bal,paid_amount, exchange_rate);

        }

    }, [receipt])


    const totalCurrency = (arr=[]) =>{
        return arr.reduce((a, item) => {
            return (+a + item.amount)
        }, 0)
    }

    const totalNonCurrency = (arr=[]) =>{
        return arr.reduce((a, item) => {
            return (+a + item.currency_total)
        }, 0)
    }

    const calculateTotal = (beds, services, products, paidAmt,balance, exchange_rate) => {


        const t_advance = !paidAmt ? 0 : paidAmt;
        const g_total = totalCurrency(services) + totalCurrency(beds) + totalCurrency(products);
        const g_currency_total = totalNonCurrency(services) + totalNonCurrency(beds) + totalNonCurrency(products);
        const bal = balance ?? 0;

        const currency_paid_amt = exchange_rate ?  t_advance /exchange_rate : t_advance
        const currency_balance = exchange_rate ?  bal / exchange_rate : bal

        setState({
            total_bill: g_total, total_advance: t_advance, due_amount: bal,
            paid_amount: t_advance, balance: bal, currency_total_bill: g_currency_total,
            currency_due_amount: currency_balance, currency_paid_amount: currency_paid_amt
        });
    };
    const getLists = (invoices, bal,paid_amount,ex_rate) => {
        const exchange_rate = ex_rate ? ex_rate : 0
        const servs = groupServiceId(invoices.filter(service=>service.service_id)).map(item => ({
            ...item,
            service_name: item.service_name,
            rate: item.rate.toFixed(3),
            currency_rate: exchange_rate ?  item.rate / exchange_rate : item.rate,
            quantity: item.quantity,
            amount: item.rate * (!item.quantity ? 0 : item.quantity),
            currency_total: (exchange_rate ?  item.rate / exchange_rate : item.rate) *  (!item.quantity ? 0 : item.quantity),
            date: item.date_created,
            pay_status:item.pay_status
        }))
        const prods =  groupProductId(invoices.filter(prdt=>prdt.product_id)).map(item => ({
            ...item,
            product_name: item.product_name,
            rate: item.rate.toFixed(3),
            currency_rate: exchange_rate ?  item.rate / exchange_rate : item.rate,
            quantity: !item.quantity ? 0 : item.quantity,
            amount: item.rate * (!item.quantity ? 0 : item.quantity),
            currency_total: (exchange_rate ?  item.rate / exchange_rate : item.rate) *  (!item.quantity ? 0 : item.quantity),
            date: item.date_created,
            pay_status:item.pay_status,
        }))




        const bds = invoices.filter(item=>item.bed_assignment_id).map(bed => ({
            ...bed,
            assign_date: bed.assign_date,
            end_date: (!bed.end_date ? now : dateStringConvert(bed.end_date)),
            room_name: bed.room_name,
            ward_name: bed.ward_name,
            days_occupied: bed.quantity,
            bed_number: bed.bed_number,
            rate: bed.rate.toFixed(3),
            currency_rate: exchange_rate ?  bed.rate / exchange_rate : bed.rate,
            amount: bed.rate * +bed.quantity,
            currency_total: (exchange_rate ?  bed.rate / exchange_rate : bed.rate) *  (!bed.quantity ? 0 : bed.quantity),
            pay_status:bed.pay_status,
        }))

        const allData = [...servs,...prods]


        const serviceMedArr = allData.map(item=>{
            return {
                ...item,
                bill_id: item.bill_id,
                name: item.service_id ? item.service_name : item.product_id ? item.product_name : '',
                currency_total:item.currency_total.toFixed(3),
                amount: item.amount.toFixed(3),
                currency_rate:item.currency_rate.toFixed(3)
            }
        })


        const bedArr = bds.map(item=>{
            return {
                ...item,
                bill_id: item.bill_id,
                name: `${item.ward_name} -> ${item.room_name} -> ${item.bed_number}`,
                quantity: `${item.quantity} day${item.quantity > 1 ? 's': ''}`,
                currency_total:item.currency_total.toFixed(3),
                amount: item.amount.toFixed(3),
                currency_rate:item.currency_rate.toFixed(3)

            }
        })
        const arr = [{name:'Services and Medication', id:'services_and_medication', open:true, values:serviceMedArr},
            {name:'Bed Payment', id:'bed_payment',open:true, values:bedArr}]
        setItems(arr)
        calculateTotal(bds, servs, prods, paid_amount,bal, exchange_rate)
    }

    //Change currency
    const handleChangeCurrency = (e) =>{
        setCurrency(e.target.value)
        // setExchangeRate(0)
        // setCurrencyExchangeRate(0)
        const {invoices, balance_amount, amount_paid} = bills

        getLists(invoices,balance_amount, amount_paid, +exchangeRate)
    }

    //Change exchange rate
    const handleChangeExchangeRate = (event) =>{
        const currency_ex_rate = 1 / +event.target.value
        setCurrencyExchangeRate(event.target.value)
        setExchangeRate(currency_ex_rate)
        const {invoices, balance_amount, amount_paid} = bills
        getLists(invoices,balance_amount, amount_paid, +currency_ex_rate)

    }

    // const handleOpenItem = (id) =>{
    //     const openedArr = items.map(item=>{
    //         if(item.id === id){
    //             return {...item, open:!item.open}
    //         }
    //         return item
    //     })
    //     setItems(openedArr)
    // }
    //
    // const closeSnackbar = () => {
    //     actions.snackbarActions.hideSnackbar()
    // };

    return {state,  patient, items,exchangeRate, currencies,
        currency, handleChangeExchangeRate, handleChangeCurrency,
        currencyExchangeRate, selectedCurrency}
}