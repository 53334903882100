import {useEffect, useState} from "react";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import { useAddEmployee } from "./useAddEmployee";
import {useFileUploader} from "../../PatientRecords/CustomHooks/useFileUploader";

export const useEmployeeList = (props) => {
    const [searchValue, setSearchValue] = useState('');
    const [openMenu, setOpenMenu] = useState(null);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('sl');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [employee, setEmployeeId] = useState({employee_id: '', status: 1,user_role_status:1, user_status:1});
    const [openDelete, setDelete] = useState(false);
    const [employeeList, setEmployeeList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const [employee_count, setCount] = useState(0);
    const [st, setStatus] = useState('')
    const {actions} = props;
    const {addEmployeeProps, isResolved} = useAddEmployee(actions)
    const obj = {url:'chumanresource/uploadCsv_employees', download_url:'chumanresource/employee_csv', file_name:'Employees'}

    const {uploadProps, isResolved:isResolvedUpload} = useFileUploader(actions, obj)

    useEffect(() => {
        setLoading(true);
        const formData = new FormData();
        formData.append('search', searchValue);
        axios.post(`${config.smsUrl}/chumanresource/employee_list/${activePage}`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const empList = !dt.employee_list ? [] : dt.employee_list;
            const count = !dt.total_count ? 0 : dt.total_count;
            setLoading(false);
            setCount(count);
            setEmployeeList(empList)
        }).catch(e => {
            logoutErrorMessage(e, null, actions);
            setLoading(false)
        })
    }, [activePage, searchValue, st, isResolved,isResolvedUpload]);

    const handleSearch = (event) => {
        setSearchValue(event.target.value);
    };


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangeNewPage = (page) => {
        setActivePage(page);
    };

    const handleOpenDelete = (item) => {
        setDelete(true)
        const {employee_id, status, user_role_status, user_status} = item
        setEmployeeId({employee_id, status, user_role_status, user_status});
    };

    const handleCloseDelete = () => {
        setDelete(false)
        // setEmployeeId(_id);
    };

    const handleDelete = () => {
        const formData = new FormData();
        const {employee_id, status, user_role_status,user_status} = employee
        formData.append('employee_id', employee_id);
        formData.append('status', status === 1 ? 0 : 1);
        if (user_role_status !== null){
            formData.append('user_role_status', user_role_status === 1 ? 0 : 1);
        }
        if (user_status !== null){
            formData.append('user_status', user_status === 1 ? 0 : 1);
        }
        axios.post(`${config.smsUrl}/chumanresource/deactivate_employee`, formData).then(() => {
            actions.snackbarActions.snackSuccess(`Employee ${status === 1 ? 'deactivate' : 'activated'} successfully`);
            setStatus(prev => prev + status)
            setDelete(false);
        }).catch(e => {
            errorMessages(e, null, actions);
            setDelete(false)
        });
        setOpenMenu(false)
    };

    const closeSnackBar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    return {
        searchValue, openMenu, order, orderBy, page, rowsPerPage, openDelete,
        employeeList, loading, activePage, employee_count, handleSearch,uploadProps,
         handleRequestSort, handleChangeNewPage, handleOpenDelete,employee,
        handleCloseDelete, handleDelete, closeSnackBar, setEmployeeId, setEmployeeList, addEmployeeProps
    }
}