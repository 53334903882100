import React from "react"
import PageTitle from "../Utils/smsTitle";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTooth} from "@fortawesome/free-solid-svg-icons/faTooth";
import ReusableDoctorTabs from "../Utils/Menu/ReusableDoctorTabs";
import {BackButton} from "../Utils/Buttons/DataExportationButton";
import {Container} from "../Utils/styledComponents";



function DentalDashboard({match, title, children}) {
    const {patient_number, visit_id} = match.params

    const components =  [
        {
            text: 'Medical history',
            path: `/dental-medical-history/${patient_number}/${visit_id}`
        },
        {
            text: 'Examination',
            path: `/dental-examination/${patient_number}/${visit_id}`
        },
        {
            text: 'Dental services',
            path: `/dental-request/${patient_number}/${visit_id}`
        },
        {
            text: 'Prescription',
            path: `/dental-prescription/${patient_number}/${visit_id}`
        }
    ]

    return (
        <div className='journals'>
            <PageTitle title={` Dental | ${title}`}/>
            <SubHeader  title='Dental' subTitle={title}>
                <FontAwesomeIcon icon={faTooth}/>
            </SubHeader>
            <div className="general-ledger-header mb-0">
                <BackButton text='Dental list' to='/dental-list'/>
            </div>
            <ReusableDoctorTabs components={components} patient_type={null}>
                <Container>
                    {children}
                </Container>
            </ReusableDoctorTabs>
        </div>
    );
}


export default DentalDashboard;
