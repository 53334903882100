import React from 'react';
import Card from "@material-ui/core/Card";
import Form from "react-bootstrap/Form";
import PageTitle from "../Utils/smsTitle";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMortarPestle} from "@fortawesome/free-solid-svg-icons/faMortarPestle";
import {useTheme} from "@material-ui/core/styles";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../actions/snackbarActions"
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import {useConfirmItems} from "./CustomHooks/useConfirmItems";


const ConfirmItems = ({snackbar, actions,match}) => {
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    const {products, submitted, handleChangeCheck, handleSubmit, closeSnackbar} = useConfirmItems(actions, match)
    const {openBar, message, type} = snackbar;
    return (
        <div data-testid="confirm-items">
            <PageTitle title="Confirm Items"/>
            <SubHeader title="Pharmacy" subTitle="Confirm receipt of items">
                <FontAwesomeIcon icon={faMortarPestle}/>
            </SubHeader>
            <div className="billing-details">
                <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>
                <Card className="p-3">
                    <form autoComplete="off" onSubmit={handleSubmit}>
                    <h6 className={`visit-heading ${palette.type === "dark" && "dark-visit-heading"}`}>Confirm Receipt of Items</h6>
                    <div className="table-responsive">
                        <table className="table table-bordered table-sm profile-table strike-table">
                            <thead>
                            <tr>
                                <td width="80">Confirm</td>
                                <td width="300">Product</td>
                                <td width="200">Quantity Requested</td>
                                <td width="200">Quantity Supplied</td>
                                <td width="200">Balance</td>
                                <td width="300">Remarks</td>
                            </tr>
                            </thead>
                            <tbody>
                            {products.map((item, index) => {
                                return (
                                    <tr key={index} className={item.check && "strike-out"}>
                                        <td align="center"><Form.Check type="checkbox" checked={item.check} name="check"
                                                                       onChange={e => handleChangeCheck(e, index)}
                                                                       style={{transform: "scale(1.3)"}}/></td>
                                        <td>{item.product_name}</td>
                                        <td>{item.quantity_requested}</td>
                                        <td>{item.quantity_supplied}</td>
                                        <td>{item.balance}</td>
                                        <td>{item.remarks}</td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                    <div className="text-right mr-5">
                    <button type="submit" disabled={!!submitted} className="btn btn-sm sms-btn px-4"  id="submit_category">
                        {submitted ? "Saving...":"Save"}</button>
                    </div>
                    </form>
                </Card>
            </div>
        </div>
    )
}

function mapStateToProps(state){
    const {snackbar} = state;
    return {snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions:{snackbarActions:bindActionCreators(snackbarActions,dispatch)}}
}
export default connect(mapStateToProps, mapDispatchToProps)(ConfirmItems);