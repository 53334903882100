import React from "react";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserMd} from "@fortawesome/free-solid-svg-icons/faUserMd";
import SimpleMenu from "../Utils/Menu/SimpleMenu";
import CustomTable, {CustomTablePagination, useHiddenColumns} from "../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import MenuItem from "@material-ui/core/MenuItem";
import * as doctorActions from "../../actions/DoctorsActions/doctorActions"
import * as snackbarActions from "../../actions/snackbarActions"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PageTitle from "../Utils/smsTitle";
import {Link} from "react-router-dom";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import {formatDateTime} from "../Utils/ConvertDate";
import {useDoctorWaitingList} from "./CustomHooks/useDoctorWaitingList";
import {history} from "../../Helpers/history";
import {ReusableRouteTabs} from "../Utils/Dialogs/ReusableTabs";
import { PatientDetails } from "../Visit/NewService/SelectService";
import ReusableDialog from "../Utils/Dialogs/ReusableDialog";
import Form from 'react-bootstrap/Form';
import Label from "../Utils/FormInputs/Label";
import { CustomSelect } from "../Utils/FormInputs/SelectInput";
import {ErrorMessage, RightAlignedContainer} from "../Utils/styledComponents";
import DeleteDialog from "../Utils/Dialogs/DeleteDialog";
import styled from "@emotion/styled/macro";
import * as colors from "../../styles/colors"
import {FullScreenDialog} from "../Utils/Dialogs/FullScreenDialog";
import {ReusableTreatmentHistory, Spacer} from "./ReusableTreatmentHistory";
import { usePrint } from '../Utils/Templates/usePrint';
import {useRetrievePatientCount} from "./hooks/useRetrievePatientCount";
import {
    ResultsHeading, PreviousTriage,
    PreviousDiagnosis, Heading, Border, SelectForm, UpdateCompletedDiagnosis
} from "./PatientPreviousVisits";
import {NotesRow, PreviousNotesTable} from "./Notes/PreviousNotesTable";
import {SavedNotesContainer} from "./Notes/PatientNotesContainer";
import {splitNotes} from "../Radiology/RadiologyReport";
import {SaveNotesForm} from "../MedicationandVisits/Findings/PatientFindings";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TextField from "../Utils/FormInputs/TextField";
import DentalVisitDetails from "../Dental/DentalVisitDetails";


const Status = styled.span({
    borderRadius:'15px',
    display:'inline-block',
    padding:'3px 10px',
    fontSize:'11px'
})

const PendingStatus = styled(Status)({
    background:colors.lightOrange,
    color:colors.pending
})

export const Progress = styled(Status)({
    background:colors.progress,
    color: colors.progressText
})

const FinishedStatus = styled(Status)({
    background:colors.lightGreen,
    color:colors.green
})


const headData = [{id: 'count', numeric: false, disablePadding: false, label: 'Sl.',hidden:false},
    {id: 'date', numeric: false, disablePadding: false, label: 'Date',hidden:false},
    {id: 'patient_number', numeric: false, disablePadding: false, label: 'Patient No.',hidden:false},
    {id: 'patient_name', numeric: false, disablePadding: false, label: 'Patient Name',hidden:false},
    {id: 'phone_no', numeric: false, disablePadding: false, label: 'Phone ',hidden:false},
    {id: 'kin_phone_no', numeric: false, disablePadding: false, label: 'NOK Phone',hidden:false},
    {id: 'doctor_name', numeric: false, disablePadding: false, label: 'Doctor Name',hidden:false},
    {id: 'visit_department', numeric: false, disablePadding: false, label: 'Visit Department',hidden:false},
    {id: 'status', numeric: false, disablePadding: false, label: 'Status',hidden:false},
    {id: 'action', numeric: false, disablePadding: true, label: 'Action',hidden:false},
];



export const DoctorDateFilter = ({state, handleChange}) =>{
    return (
        <div className='row'>
            <div className='col-lg-6 col-sm-12 col-md-12'>
                <Row>
                    <Col xs={12} sm={2} md={2} lg={4} xl={3}>
                        <Label name='Start Date' type/>
                    </Col>

                    <Col xs={12} sm={6} md={6} lg={7} xl={7}>
                        <TextField type='date' name='start_date' value={state.start_date} onChange={handleChange}/>
                    </Col>
                </Row>
            </div>
            <div className='col-lg-6 col-sm-12 col-md-12'>
                <Row>
                    <Col xs={12} sm={2} md={2} lg={4} xl={3}>
                        <Label name='End Date' type/>
                    </Col>

                    <Col xs={12} sm={6} md={6} lg={7} xl={7}>
                        <TextField type='date' name='end_date' value={state.end_date} onChange={handleChange}/>
                        {state.end_date < state.start_date ? <ErrorMessage>End date shouldn't be less than start date</ErrorMessage> : null}
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export const PatientWaitingList = (props) => {
    const user = JSON.parse(sessionStorage.getItem('user'));
    const perm = JSON.parse(sessionStorage.getItem('permission'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];

    const department_name = !_token.department_name ? "" : _token.department_name;

    const {actions} = props

    const {
        searchValue, openMenu,
        activePage, handleSearch, handleCloseMenu, handleChangeNextPage,
         closeSnackbar, waitingList, total_count, isLoading, isSuccess, isRejected,
        openAssign, doctor_name, doctors, handleChangeDoctor, isSubmitted, submitted,
        handleCloseAssign, ids, assignDoctor, department, isEnding,
        departments, handleChangeDepartment, openEnd,
        handleCloseEndVisit, handleEndVisit, patientProps, state, handleChange,
        page, rowsPerPage, handleChangePage, handleChangeRowsPerPage
    } = useDoctorWaitingList(props, _token)
    const {openDialog, handleCloseDialog, setOpenDialog,ids:visitInfo,
        option, options, handleChangeOption} = patientProps
    const {total_patients} = useRetrievePatientCount(actions)

    const components = [{label: 'Waiting List', path: '/waitinglist', badge: true, badge_count: total_patients},
        {label: 'Patients Seen', path: '/patientsSeen'}];
    const permission = !perm ? {} : perm;
    const waiting_list = !permission.waiting_list ? {} : permission.waiting_list;
    const doctor_list = !waitingList ? [] : waitingList;
    const {openBar, type, message} = props.snackbars;
    const {patient_number, patient_name} = ids;


    const {componentRef, handlePrint, fontSize} = usePrint('Medical History Report');


    const {
        treatmentDetails, handleOpenNote, isPrint, setIsPrint,
        handleEdit, handleCancelEdit, handleChangeNotes, handleUpdateNotes,
        submitted: submittedNotes, isPendingEdit, diagnosisProps, triageProps
    } = patientProps
    const {isEditDiagnosis, handleEditDiagnosis} = diagnosisProps

    const handlePrintDetails = () =>{
        handlePrint()
        setIsPrint(true)
    }
    

    const {patientDiagnosis, patientTriage, clinicalNotes} = treatmentDetails



    const notesHeadData = [{name:'Date'},{name:'Written By'}]

    const triageComponent = (
        patientTriage?.length > 0 ?  <>
                <ResultsHeading>
                    <Heading>Triage</Heading>
                    <Border/>
                </ResultsHeading>
                <PreviousTriage {...{patientTriage, isEditTriage: !isPrint, ...triageProps}} />
            </>: null
    )

    const clinicalNotesComponent = (
        clinicalNotes?.length > 0 ?
                <Spacer>
                    <ResultsHeading>
                        <Heading>Clinical Notes</Heading>
                        <Border/>
                    </ResultsHeading>
                    <PreviousNotesTable headData={notesHeadData}>
                        {clinicalNotes.map(item=>{

                            const examNotes = {
                                physical_examination:item.physical_examination, past_medical_history:item.past_medical_history, past_obstetric_history:item.past_obstetric_history,
                                family_history:item.family_history, notes:item.notes, complaints:item.complaints
                            }

                            const updateForm = (
                                <>
                                    <SaveNotesForm submitted={submittedNotes} handleSubmit={(e)=>handleUpdateNotes(e, item)}
                                                   handleChangeState={(e)=>handleChangeNotes(e, item.examination_notes_id)}
                                                   examNotes={examNotes} isEdit>
                                        <button type="submit" disabled={isPendingEdit}
                                                className="btn sms-btn small-btn btn-sm mt-2 mr-3">{isPendingEdit ? "Saving...." : "Update"}</button>
                                        <button type='button' onClick={()=>handleCancelEdit(item.examination_notes_id)} className="btn sms-gray-btn small-btn  btn-sm mt-2">Cancel</button>

                                    </SaveNotesForm>
                                </>

                            )
                            const viewNotes = (
                                <div className='row mb-0'>
                                    <div className='col-lg-6'>
                                        {item.complaints ? <>
                                            <Label name='Presenting Complaints'/>
                                            <SavedNotesContainer data-testid="complaints">
                                                {splitNotes(item.complaints)}
                                            </SavedNotesContainer>
                                        </>: null}
                                        {item.past_medical_history ? <>
                                            <Label name='Past medical history'/>
                                            <SavedNotesContainer data-testid="medical_history">
                                                {splitNotes(item.past_medical_history)}
                                            </SavedNotesContainer>
                                        </>: null}
                                        {item.physical_examination ?
                                            <>
                                                <Label name='Physical examination'/>
                                                <SavedNotesContainer data-testid="p_examination">
                                                    {splitNotes(item.physical_examination)}
                                                </SavedNotesContainer>
                                            </>: null}


                                    </div>
                                    <div className='col-lg-6'>
                                        {item.past_obstetric_history ? <>
                                            <Label name='Past obstetric history'/>
                                            <SavedNotesContainer data-testid="obs_history">
                                                {splitNotes(item.past_obstetric_history)}
                                            </SavedNotesContainer>
                                        </>: null}
                                        {item.family_history ? <>
                                            <Label name='Family history'/>
                                            <SavedNotesContainer data-testid="f_history">
                                                {splitNotes(item.family_history)}
                                            </SavedNotesContainer>
                                        </>: null}
                                        {item.notes ? <>
                                            <Label name='Notes'/>
                                            <SavedNotesContainer data-testid="clinical_notes">
                                                {splitNotes(item.notes)}
                                            </SavedNotesContainer>
                                        </>: null}

                                    </div>
                                </div>
                            )
                            return(
                                <NotesRow open={item.openNote} date={item.start_time ? formatDateTime(item.start_time):''} handleOpen={()=>handleOpenNote(item.examination_notes_id)}
                                          person={`${item.doctor_firstname} ${item.doctor_lastname}`} colSpan={notesHeadData.length}>
                                    {!item.displayInput ?
                                        <>
                                            {viewNotes}
                                            {item.isEdit ? <button className='btn-sm btn small-btn sms-info-btn print_button' onClick={()=>handleEdit(item.examination_notes_id)}>Edit notes</button> : null}
                                        </>
                                        :null}
                                    {item.displayInput ? updateForm : null}
                                </NotesRow>
                            )})}
                    </PreviousNotesTable>

                </Spacer>:  null
    )
    const editDiagnosisButton = (
        <RightAlignedContainer>
            <button type="button" onClick={handleEditDiagnosis} className='btn btn-sm small-btn sms-info-btn px-3 mb-2'>Edit</button>
        </RightAlignedContainer>

    )

    const diagnosisComponent = (
        patientDiagnosis?.length > 0 ?
                <Spacer>
                    {isEditDiagnosis ? <>
                            <ResultsHeading>
                                <Heading>Diagnosis</Heading>
                                <Border/>
                            </ResultsHeading>
                            <UpdateCompletedDiagnosis {...diagnosisProps}/>
                        </> :
                        <PreviousDiagnosis {...{patientDiagnosis}} editDiagnosis={!isPrint ?  editDiagnosisButton : null} />}

                </Spacer>
                : null
    )

    const {
        headCells, all_hidden, handleAllHeadCells, handleHeadCells
    } = useHiddenColumns(headData);

    const pagination = (
        <CustomTablePagination
            colSpan={3}
            activePage={activePage}
            page={page}
            rowsPerPage={rowsPerPage}
            count={total_count}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            isTotalCount
        />
    );

    const isDental = department_name.includes('Dental')
    return (
        <div data-testid="doctor-waiting-list" className='journals'>
            <PageTitle title="Doctor Waiting List"/>
            <SubHeader title="Doctor" subTitle="Waiting List">
                <FontAwesomeIcon icon={faUserMd}/>
            </SubHeader>


            <DeleteDialog message="end" openDialog={openEnd} handleClose={handleCloseEndVisit}
                          text="visit" title='End Visit'>
                <button className=' btn sms-info-btn btn-sm' id="end_visit" disabled={isEnding} onClick={()=>handleEndVisit('end')}>End Visit</button>
                <button className="btn sms-grey-btn btn-sm" onClick={handleCloseEndVisit}>No</button>
            </DeleteDialog>
            {isDental ?
                <DentalVisitDetails {...{actions, visitInfo, openPrint:openDialog, setOpenPrint:setOpenDialog}}/>:
                <FullScreenDialog open={openDialog} title='Visit Details' handleClose={handleCloseDialog}>
                    <SelectForm {...{options, option, handleChangeOption, handlePrint:handlePrintDetails}}/>
                    <div ref={componentRef}>
                        {fontSize}
                        <ReusableTreatmentHistory {...{...patientProps, clinicalNotesComponent, triageComponent,diagnosisComponent}}/>
                    </div>
                </FullScreenDialog>
            }

            <ReusableDialog message='Reassign Doctor' openDialog={openAssign} handleClose={handleCloseAssign}
            isSubmitted={isSubmitted} handleSubmit={assignDoctor} width='sm'>
                <PatientDetails {...{patient_number,patient_name}}/>
                    <div className="my-2">
                        <Form.Group>
                            <Label name="Department" htmlFor='department' type/>
                            <CustomSelect onChange={handleChangeDepartment} id='department' value={department} options={departments}/>
                            {(submitted && !department) && <ErrorMessage>This is a required field</ErrorMessage>}
                        </Form.Group>
                        <Form.Group>
                            <Label name="Doctor" htmlFor='doctor' type/>
                            <CustomSelect onChange={handleChangeDoctor} id='doctor' value={doctor_name} options={doctors}/>
                            {(submitted && !doctor_name) && <ErrorMessage>This is a required field</ErrorMessage>}
                        </Form.Group>
                    </div>
            </ReusableDialog>
            <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/>
            <div className="mui-tables">
                <DoctorDateFilter {...{state, handleChange}}/>
                <MainSnackbar variant={type} handleCloseBar={closeSnackbar} open={openBar} message={message}/>
                <CustomTable title="Waiting List" customPage handleChangeNextPage={handleChangeNextPage}
                             headData={headCells} handler={handleSearch} records={10} total_count={total_count}
                             activePage={activePage} data={doctor_list} colSpan={5}  term={searchValue}
                             handleHeadCells={handleHeadCells} all_hidden={all_hidden}
                             handleAllHeadCells={handleAllHeadCells} pagination={pagination}>

                    <TableBody>
                        {isLoading ? <TableRow>
                            <TableCell colSpan={headCells.length} align="center"><img src="/images/smsloader.gif"
                                                                                      alt="loader"/></TableCell>
                        </TableRow>:null}
                        {isSuccess ?  doctor_list.length > 0 ? doctor_list.slice(0, rowsPerPage)
                            .map((item, index) => {

                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={item.patient_number}
                                        className='mui-table-row '
                                    >
                                        {headCells.map((head)=>(
                                            <TableCell  key={head.id} hidden={head.hidden}>{item[head.id]}</TableCell>
                                        ))}
                                    </TableRow>
                                );
                            }):<TableRow>
                            <TableCell colSpan={headCells.length} align="center">No records found</TableCell>
                        </TableRow>:null}
                        {isRejected ? <TableRow>
                            <TableCell colSpan={headCells.length} align="center">The server did not return a valid response</TableCell>
                        </TableRow> : null }
                    </TableBody>
                </CustomTable>
                <SimpleMenu anchorEl={openMenu} handleClose={handleCloseMenu}>
                    {waiting_list.update &&
                    <Link to="/doctordashboard" style={{textDecoration: "none", color: "#474747"}}>
                        <MenuItem>Take Up</MenuItem></Link>}
                </SimpleMenu>
            </div>
        </div>
    )
};

function mapStateToProps(state) {
    return {doctors: state.doctors, snackbars: state.snackbar}

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            doctorActions: bindActionCreators(doctorActions, dispatch),
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientWaitingList);
