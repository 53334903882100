import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import TableContainer from "@material-ui/core/TableContainer";
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import Form from "react-bootstrap/Form";
import Pagination from "react-js-pagination";
import { titleCase } from "../../Users/addUser";
import Badge from '@material-ui/core/Badge';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Switch from '@material-ui/core/Switch';
import { withStyles } from "@material-ui/core/styles";

/**
 * Hooks to handle the hiding of columns
 * @param {*} head_data
 * @param version
 * @returns
 */
export const useHiddenColumns = (head_data, version='Premium') => {
    const [headCells, setHeadCells] = useState(head_data);

    

    useEffect(() => {
        if (version !== "Premium"){
            const filteredHeadData =head_data.filter(item => item.id !== 'provider_name');
            setHeadCells(filteredHeadData);
        }else{
            setHeadCells(head_data);
        }
    }, [head_data]);

    const [all_hidden, setAllHidden] = useState(false);

    const handleChangeHeadCellsArray = (new_arr) => {
        setHeadCells(new_arr);
    }

    const handleHeadCells = (idx) => {
        let new_arr = [...headCells];
        let it = new_arr[idx];
        let hd = it['hidden'];
        new_arr[idx] = {
            ...it,
            hidden: !hd
        }
        setHeadCells(new_arr);
    }

    const handleAllHeadCells = (hide_show) => {
        const new_arr = headCells.map((item, indx) => {
            return {
                ...item,
                // i did this to ensure that at least the first column is still shown
                // incase all the others are hidden
                hidden: indx !== 0 ? (hide_show === 'hide') : false
            }
        });
        setHeadCells(new_arr);
        if (hide_show === 'hide') { setAllHidden(true); } else { setAllHidden(false) };
    }

    return {
        headCells, all_hidden, handleAllHeadCells, handleHeadCells, handleChangeHeadCellsArray
    }
}

// toggle component for hiding and showing columns
const MpekeSwitch = withStyles((theme) => ({
    root: {
        width: 28,
        height: 16,
        padding: 0,
        display: 'flex',
        marginRight: '9px'
    },
    switchBase: {
        padding: 2,
        color: 'white',
        '&$checked': {
            transform: 'translateX(12px)',
            color: theme.palette.common.white,
            '& + $track': {
                opacity: 1,
                backgroundColor: '#3e9e8e',
                borderColor: '#3e9e8e',
            },
        },
    },
    thumb: {
        width: 12,
        height: 12,
        boxShadow: 'none',
    },
    track: {
        border: `1px solid #404346`,
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: '#404346',
    },
    checked: {},
}))(Switch);

function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort, headCells, all_hidden, handleAllHeadCells, handleHeadCells } = props;

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    const [showcolumns, setShowColumns] = useState(null);
    const handleShowColumnsOpen = (event, idx) => {
        setShowColumns(event.currentTarget);
        handleHideColumnsClose();
    }

    const handleShowColumnsClose = () => {
        setShowColumns(null);
    }

    const handleHiddenChange = (event, idx) => {
        handleHeadCells(idx);
    }

    const handleHideAll = () => {
        handleAllHeadCells('hide');
    }

    const handleShowAll = () => {
        handleAllHeadCells('show')
    }

    const [hide_columns_menu, setHideColumnsMenu] = useState(null);
    const [current_hd, setCurrentHd] = useState('');

    const handleHideColumnsOpen = (event, idx) => {
        setHideColumnsMenu(event.currentTarget);
        setCurrentHd(idx);
    };

    const handleHideColumnsClose = () => {
        setHideColumnsMenu(null);
        setCurrentHd('');
    }

    const handleHideColumn = () => {
        handleHeadCells(current_hd);
        handleHideColumnsClose();
    }

    const isShowColumnsOpen = Boolean(showcolumns);
    const showColumnsId = "show-columns";
    const renderShowColumnsMenu = (
        <Menu
            anchorEl={showcolumns}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={showColumnsId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isShowColumnsOpen}
            onClose={handleShowColumnsClose}
            classes={{
                paper: classes.popup
            }}
        >
            {headCells.map((headCell, idx) => {
                return (
                    idx === 0 ? null
                        :
                        <MenuItem style={{ fontSize: '10px' }} key={headCell.id}
                            onClick={(e) => handleHiddenChange(e, idx)}
                        >
                            <MpekeSwitch
                                checked={!headCell.hidden}
                                name="checkedA"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                            />
                            {headCell.label}
                        </MenuItem>
                )
            })
            }
            <MenuItem>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <button onClick={handleHideAll} className="btn btn-sm sms-btn">
                        Hide All
                    </button>

                    <button onClick={handleShowAll} className="btn btn-sm sms-info-btn">
                        Show All
                    </button>
                </div>
            </MenuItem>
        </Menu>
    );

    const isHideColumnsOpen = Boolean(hide_columns_menu);
    const hidecolumnsId = "hide-columns-menu";
    const renderHideColumnsMenu = (
        <Menu
            anchorEl={hide_columns_menu}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={hidecolumnsId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isHideColumnsOpen}
            onClose={handleHideColumnsClose}
            classes={{
                paper: classes.popup
            }}
        >
            <MenuItem style={{ color: "#616771" }} className="mui-menu-item" onClick={(e) => handleHideColumn()}>
                Hide
            </MenuItem>
            <MenuItem style={{ color: "#616771" }} className="mui-menu-item" onClick={(e) => handleShowColumnsOpen(e)}
                aria-controls={showColumnsId}
            >
                Show Columns
            </MenuItem>
        </Menu>
    );

    return (
        <TableHead >
            <TableRow>
                {headCells.map((headCell, idx) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        style={{
                            paddingRight: '0px'
                        }}
                        sortDirection={orderBy === headCell.id ? order : false}
                        hidden={headCell.hidden ? headCell.hidden : false}
                    >
                        {headCell.label}
                        {/*<TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}                        
                        </TableSortLabel> */}

                        {handleHeadCells ?
                            idx !== 0 || (all_hidden === true) ?
                                <IconButton
                                    aria-controls={hidecolumnsId}
                                    onClick={(e) => handleHideColumnsOpen(e, idx)}
                                    style={{ fontSize: '15px', padding: '2px' }}
                                >
                                    <MoreVertIcon fontSize='inherit' />
                                </IconButton>
                                : null : null
                        }
                        {renderHideColumnsMenu}
                        {renderShowColumnsMenu}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
};

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(5),
    },
}));

export function TablePaginationActions(props) {
    const classes = useStyles1();
    
    const theme = useTheme();

    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>

            <IconButton 
                onClick={handleBackButtonClick}
                disabled={page === 0} 
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        paddingTop: theme.spacing(2),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
    barRoot: {
        flexGrow: 1,
        // maxWidth: 752,
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(2),
        // marginBottom:theme.spacing()
    },
    demo: {
        // backgroundColor: theme.palette.type === "light" && theme.palette.background.paper,
        textAlign: 'center',
    },
    barTitle: {
        margin: theme.spacing(4, 0, 2),
    },
    ulItem: {
        display: "flex",
        flexDirection: "row",
        // borderBottom: theme.palette.type === "dark" ? "2px solid 1px solid rgba(255, 255, 255, 0.12)" :
        //     "1px solid rgba(0,0,0,.125)",
        padding: 0,
        marginBottom: theme.spacing(),
        // maxWidth: "800px",
        // margin: "0 auto",
        width: "25%",
        // alignItems: 'center',
        // justifyContent: 'center',
    },
    liItem: {
        // width: "30%",
        borderTop: theme.palette.type === "dark" ? "1px solid rgba(255, 255, 255, 0.12)" : "1px solid rgba(0,0,0,.125)",
        borderRight: theme.palette.type === "dark" ? "1px solid rgba(255, 255, 255, 0.12)" : "1px solid rgba(0,0,0,.125)",
        borderLeft: theme.palette.type === "dark" ? "1px solid rgba(255, 255, 255, 0.12)" : "1px solid rgba(0,0,0,.125)",
        borderBottom: theme.palette.type === "dark" ? "2px solid 1px solid rgba(255, 255, 255, 0.12)" :
            "1px solid rgba(0,0,0,.125)",
        padding: 0,
        // color:'#02b08a',
        textAlign: "center",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        '&:first-child': {
            borderTopLeftRadius: "6px",
            borderBottomLeftRadius: "6px",
        },
        '&:last-child': {
            borderTopRightRadius: "6px",
            borderBottomRightRadius: "6px",
        },
        cursor: "pointer"
    },
    liIcon: {
        fontSize: "1.3rem"
    },
    activeItem: {
        // width: "30%",
        background: "-webkit-gradient(linear, left top, left bottom, from(#027a5f), to(#02b08a))",
        color: '#fff',
        // borderLeft: "5px solid #02b08a",
        borderBottom: 0,
        padding: 0,
        textAlign: "center",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        // borderRadius:'6px',
        '&:first-child': {
            borderTopLeftRadius: "6px",
            borderBottomLeftRadius: "6px",
        },
        '&:last-child': {
            borderTopRightRadius: "6px",
            borderBottomRightRadius: "6px",
        },
        cursor: "pointer"
    },
    spacer: {
        flex: '1 1 100%'
    }

}));

export const EnhancedSearchTableToolbar = ({ title, handler, id, term, badge, count, searchPlaceHolder, searchInputName }) => {
    const classes = useToolbarStyles();
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;

    return (
        <Toolbar className={clsx(classes.root)}>
            {badge ? <Badge badgeContent={count} max={100} color="secondary">
                <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                    {title}
                </Typography>
            </Badge> : <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                {title}
            </Typography>}
            <div className={classes.spacer} />
            <div className="col-md-4 col-lg-3  col-sm-12 " >
                <Form.Control 
                    type="text"
                    value={term} 
                    data-testid={id} 
                    onChange={handler}
                    id='searchValue'
                    className={`form__control ${palette.type === "dark" && 'form-control-dark'}`}
                    placeholder={searchPlaceHolder ? searchPlaceHolder : "Search"} 
                    style={{ float: 'right' }}
                    name={searchInputName}
                />
            </div>
        </Toolbar>
    );
};

export const EnhancedTableToolbar = ({ title, handler, badge, count, searchPlaceHolder }) => {
    const classes = useToolbarStyles();
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;

    const handleChange = (event) => {
        handler(event.target.value);
    };
    return (
        <Toolbar className={clsx(classes.root)}>
            {badge ? <Badge badgeContent={count} max={100} color="secondary">
                <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                    {title}
                </Typography>
            </Badge> : <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                {title}
            </Typography>}
            <div className=" col-md-4 col-lg-4 col-sm-12" >
                <Form.Control type="text" autoComplete='off'
                    className={`form__control ${palette.type === "dark" && 'form-control-dark'}`}
                    placeholder={searchPlaceHolder ? searchPlaceHolder : "Search "} style={{ float: 'right' }}
                    id='searchValue' onChange={handleChange} />
            </div>
        </Toolbar>
    );
};


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),

    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    head1: {
        zIndex: 3,
        position: 'sticky',
        top: '0px',
    },
    rotatedContent1: {
        transform: 'rotate(270deg)',
    },
    popup: {
        '&.MuiMenu-paper': {
            boxShadow: '2px 2px 2px 2px rgb(142 163 166 / 12%)'
        }
    }
}));

export const ReusableCustomPagination = ({ data, activePage, records, total_count, handleChangeNextPage }) => (
    <div className="row">
        <div className="col-md-6" />
        <div className="col-md-6">
            <div className="text-right">
                {data.length > 0 && <Pagination
                    prevPageText='Prev'
                    nextPageText='Next'
                    firstPageText='First'
                    lastPageText='Last'
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={activePage}
                    itemsCountPerPage={records}
                    totalItemsCount={total_count}
                    pageRangeDisplayed={5}
                    onChange={handleChangeNextPage}
                />}
            </div>
        </div>
    </div>
)

export const MuiPagination = ({ colSpan, data, page, handleChangePage, handleChangeRowsPerPage, total_count, isTotal,
    rowsPerPage, rows = [10, 25, { label: 'All', value: -1 }] }) => {
    return (
        <TablePagination
            rowsPerPageOptions={rows}
            colSpan={colSpan}
            count={isTotal ? total_count : data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
        />
    )
}

export default function CustomTable({ title, badge, order, orderBy, page, rowsPerPage, handleRequestSort, handleChangePage,
    handleChangeRowsPerPage, children, handler, colSpan, id, customPage, noToolbar, customPageNoPagination,
    activePage, records, total_count, handleChangeNextPage, term, filter, searchPlaceHolder,
    outBill, handleShowUnPaid, showUnpaid, fewRows, handleHeadCells,
    handleAllHeadCells, all_hidden, searchInputName,
    data = [], headData = [],pagination=null
}) {
    const classes = useStyles();


    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                {!noToolbar ? filter ? <EnhancedTableToolbar title={title} handler={handler} id={id} term={term} filter={filter} badge={badge} count={total_count} searchPlaceHolder={searchPlaceHolder} /> :
                    <EnhancedSearchTableToolbar 
                        term={term} 
                        handler={handler} 
                        id={id} 
                        title={title} 
                        outBill={outBill}
                        handleShowUnPaid={handleShowUnPaid}
                        showUnpaid={showUnpaid} 
                        badge={badge} 
                        count={total_count} 
                        searchPlaceHolder={searchPlaceHolder}
                        searchInputName={searchInputName}
                    /> 
                    : null}

                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size='medium'
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            headCells={headData}
                            handleHeadCells={handleHeadCells}
                            handleAllHeadCells={handleAllHeadCells}
                            all_hidden={all_hidden}
                        />
                        {children}
                    </Table>
                    {pagination ? pagination :  (customPage ?
                        <ReusableCustomPagination {...{ data, activePage, records, total_count, handleChangeNextPage }} /> :
                        customPageNoPagination ?
                            <></> :  // to cater for the no pagination feature in the MOH reports for diagnosis notes
                            <MuiPagination {...{
                                colSpan, data, page, handleChangePage, handleChangeRowsPerPage,
                                rowsPerPage, rows: fewRows ? [5, 10, 15, 20, 50, 100] : [10, 25, { label: 'All', value: -1 }]
                            }} />)

                    }
                </TableContainer>
            </Paper>
        </div>
    );
}

export const CustomTablePagination = ({ page, rowsPerPage, handleChangePage, isRows,
    handleChangeRowsPerPage, colSpan, fewRows, isTotalCount, count = 0, data = [] }) => {
    return (
        <TablePagination
            rowsPerPageOptions={isRows ? [] : (fewRows ? [5, 10, 15, 20, 50, 100] : [10, 25, 50, 100, 200, 300, 500])}
            colSpan={colSpan}
            count={isTotalCount ? count : data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            // SelectProps={{
            //     inputProps: {'aria-label': 'rows per page'},
            //     native: true,
            // }}
            onChangePage={handleChangePage}
            // onPageChange={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
        />
    )
}
