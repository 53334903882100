import React from "react";
import CustomTable, {useHiddenColumns} from "../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import {getComparator, stableSort} from "../Utils/Tables/sortingFunctions";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../actions/snackbarActions"
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-regular-svg-icons/faEye";
import {useLabReport} from "./CustomHooks/useLabReport";
import {formatDateTime } from "../Utils/ConvertDate";
import PageTitle from "../Utils/smsTitle";
import {SubHeader} from "../../Containers/SubHeader";
import {faVials} from "@fortawesome/free-solid-svg-icons/faVials";
import {ReusableRouteTabs} from "../Utils/Dialogs/ReusableTabs";
import {history} from "../../Helpers/history";
import { DateTimeFilter } from "../Utils/ReusableComponents/DateTimeFilter";


const headData = [{id: 'count', numeric: false, disablePadding: false, label: 'SL', hidden:false},
    {id: 'date', numeric: false, disablePadding: false, label: 'Date', hidden:false},
    {id: 'patient_number', numeric: false, disablePadding: false, label: 'Patient No.', hidden:false},
    {id: 'patient_name', numeric: false, disablePadding: false, label: 'Patient Name', hidden:false},
    {id: 'phone_no', numeric: false, disablePadding: false, label: 'Phone', hidden:false},
    {id: 'kin_phone_no', numeric: false, disablePadding: false, label: 'NOK Phone', hidden:false},
    {id: 'doctor_name', numeric: false, disablePadding: false, label: 'Doctor', hidden:false},
    {id: 'department_name', numeric: false, disablePadding: false, label: 'Clinic', hidden:false},
    {id: 'action', numeric: false, disablePadding: true, label: 'Action', hidden:false},
];

export const LabReports = ({actions, snackbars}) => {
    const url = {tableUrl:'get_all_finished_lab_requests', exportUrl:'get_all_finished_lab_requests'};
    const {searchValue, order, orderBy,  activePage, total_count, reports:completedResults,
        handleSearch, handleRequestSort, handleChangeNextPage, closeSnackbar,
         isLoading,isSuccess, isError, state, submitted, isPending, handleChange, handleSubmit} = useLabReport(actions, url)
    const {openBar, type, message} = snackbars;
    const components = [{label: 'Pending Lab Requests', path: "/labrequests"},
        {label: 'Pending Lab Results', path:  "/pendingsamples"}, {label: 'Lab Results', path: "/labresultlist"},
        {label: 'Lab Reports', path: "/labreports"}]
    const resultReportList = !completedResults.data ? [] : completedResults.data;
    const reports = resultReportList.map((item, index)=>{
        const count = ((activePage - 1) * 10) + index + 1;
        return {
            ...item,
            count,
            date:item.time_requested ? formatDateTime(item.time_requested) : "",
            patient_name:`${!item.patient_firstname ? "" : item.patient_firstname} ${!item.patient_lastname ? "" : item.patient_lastname}`,
            doctor_name:`${item.doctor_firstname} ${item.doctor_lastname}`,
            phone_no:item.patient_Phone_number,
            kin_phone_no:item.patient_Kin_Phone_number,
            action: (
                <Link to={{pathname: `/generallabreport/${item.patient_number}/${item.labrequest_id}`}}><span
                    className="mr-1 mb-1 icon-button">
                    <FontAwesomeIcon icon={faEye}/></span></Link>
            )
        }
    })

    const {
        headCells, all_hidden, handleAllHeadCells, handleHeadCells
    } = useHiddenColumns(headData);
    const {start_date, end_date} = state
    return (
        <div className='journals'>
            <PageTitle title='Lab Reports'/>
            <SubHeader title="Laboratory" subTitle='Lab Reports'>
                <FontAwesomeIcon icon={faVials}/>
            </SubHeader>
            <MainSnackbar variant={type} handleCloseBar={closeSnackbar} open={openBar} message={message}/>
            <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/>
            <div className='mui-tables'>
                <DateTimeFilter {...{start_date,end_date, handleChange, submitted,handleSubmit,isPending, isFilter:false}}/>
                <CustomTable title="Lab Reports" headData={headCells} handler={handleSearch} customPage
                             activePage={activePage} {...{all_hidden, handleAllHeadCells, handleHeadCells}}
                             records={10} total_count={total_count} handleChangeNextPage={handleChangeNextPage}
                             handleRequestSort={handleRequestSort} data={reports} colSpan={headCells.length} order={order}
                             orderBy={orderBy} term={searchValue}>
                        <TableBody>
                            {isLoading ? <TableRow>
                        <TableCell colSpan={headCells.length} align="center"><img src="/images/smsloader.gif"
                                                                   alt="loader"/></TableCell>
                    </TableRow>: null}
                        {isSuccess ? reports.length > 0 ?  stableSort(reports, getComparator(order, orderBy))
                                .slice(0, 10)
                                .map((item, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={index}
                                            className='mui-table-row'>
                                            {headCells.map((head)=>(
                                                <TableCell id={head.id}  key={head.id}
                                                           hidden={head.hidden}>{item[head.id]}</TableCell>
                                            ))}
                                        </TableRow>
                                    );
                                }): <TableRow>
                                <TableCell colSpan={headCells.length} align="center">No records found</TableCell>
                            </TableRow>:null}
                            {isError ? <TableRow>
                                <TableCell colSpan={headCells.length} align="center">The server did not return a valid response</TableCell>
                            </TableRow>:null}
                        </TableBody>
                </CustomTable>
            </div>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(LabReports);
