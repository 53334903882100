import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {faUserMd} from "@fortawesome/free-solid-svg-icons/faUserMd";
import ReusableDoctorTabs, {doctorRoutes} from "../../Utils/Menu/ReusableDoctorTabs";
import {dateConvert} from "../../Utils/ConvertDate";
import {PatientInformation} from "../PatientInfo/PatientInformation";
import {usePatientDetails} from "../ReferralNote/usePatientDetails";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../../actions/snackbarActions";
import {connect} from "react-redux";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {useViewPreviousRequests} from "./hooks/useViewPreviousRequests";
import {CenteredContainer} from "../../Utils/styledComponents";
import {LoadingGif} from "../../Utils/Loader";
import {CollapsibleList, DataCell, ModuleTable} from "../../Utils/Lists/CollapsibleList";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {FinishedStatus, PendingStatus, StyledIcon, P, Container} from "../doctorStyles";
import TableRow from "@material-ui/core/TableRow";
import CustomDialog from "../../Utils/Dialogs/CustomDialog";
import {StyledContainer} from "../Prescription/NewPrescription";
import {NewRadiologyTable} from "./NewRadiologyRequest";
import DoctorRadiologyResults from "./RadiologyResults";

const headData = [{item:'Test Name',toolTip:'Make imaging request here', isRequired:true},
    {item:'Indication', isRequired:true},{item:'Status', isRequired:true}]


const RadiologyTab = ({actions, snackbars, match:{params}}) => {
    const {patient_number, visit_id, labrequest_id,activity_id,
        scan_request_id, patient_type,patient_admission_id} = params;
    const [patient, setPatient] = useState({patient_name: "", age: "", gender: "", address: "", phone: ""});
    const {patient: patientDetails} = usePatientDetails(actions, patient_number, '')


    useEffect(() => {
        setPatient({
            patient_name: `${!patientDetails.first_name ? "" : patientDetails.first_name} ${!patientDetails.last_name ? "" : patientDetails.last_name}`,
            age: patientDetails.Age,
            gender: patientDetails.gender,
            address: patientDetails.address,
            phone: patientDetails.phone_no
        });
    }, [patientDetails]);



    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };


    const arr = doctorRoutes(patient_number, visit_id, patient_type,patient_admission_id)
    const {patient_name, age, gender, address, phone} = patient;
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const doctor_name = `${!_token.first_name ? "" : _token.first_name} ${!_token.last_name ? "" : _token.last_name}`;
    const {openBar, type, message} = snackbars;
    const {scanRequests, isLoading, isSuccess, isError,addProps, editProps,
        openReport, handleOpenReport, handleCloseReport, handleOpen,
        radiologyResults,  retrieveResults, scanResults, activeChip} = useViewPreviousRequests(actions,params,_token )
    const {handleOpenEdit, openEdit, handleCloseEdit, handleSubmitScanRequest, isPending:isPendingEdit} = editProps
    const {handleOpenDialog,openDialog,handleCloseDialog, handleSubmitScanRequest:handleSubmit, isPending} = addProps

    return (
        <div className='journals'>
            <PageTitle title="Radiology Request"/>
            <SubHeader title="Doctor" subTitle="Radiology Request" doctor_name={doctor_name}>
                <FontAwesomeIcon icon={faUserMd}/>
            </SubHeader>

            <CustomDialog open={openDialog} handleClose={handleCloseDialog} title='Add  Request' maxWidth='lg'>
                <form onSubmit={handleSubmit}>
                    <NewRadiologyTable {...addProps}/>
                    <StyledContainer>
                        <button type='submit' className='btn sms-btn btn-sm' disabled={isPending}>{isPending ? 'Saving...' :'Save  Request'}</button>
                    </StyledContainer>
                </form>
            </CustomDialog>

            <CustomDialog open={openReport} handleClose={handleCloseReport} title='Radiology Report' maxWidth='lg'>
                <DoctorRadiologyResults {...{ radiologyResults,  retrieveResults, scanResults, activeChip}}/>
            </CustomDialog>

            <CustomDialog open={openEdit} handleClose={handleCloseEdit} title='Edit  Request' maxWidth='lg'>
                <form onSubmit={handleSubmitScanRequest}>
                    <NewRadiologyTable {...{...editProps, isEdit:true}}/>
                    <StyledContainer>
                        <button type='submit' className='btn sms-btn btn-sm' disabled={isPendingEdit}>{isPendingEdit ? 'Saving...' :'Save  Request'}</button>
                    </StyledContainer>
                </form>
            </CustomDialog>
            <MainSnackbar open={openBar} handleCloseBar={closeSnackbar} variant={type} message={message}/>
            <ReusableDoctorTabs components={arr} patient_type={+patient_type}>
                <PatientInformation phone={phone} gender={gender} age={age} patient_name={patient_name}
                                    address={address} patient_number={patient_number}
                                    date={dateConvert()}/>
                <Container>
                    <button onClick={handleOpenDialog}  type='button' className='btn btn-sm sms-info-btn mb-2'>Add Radiology Request</button>
                    {isLoading ? <CenteredContainer>
                        <LoadingGif/>
                    </CenteredContainer> : null}
                    {isSuccess ? scanRequests.length > 0 ? scanRequests.map((item)=>{
                        // const isAddedOut = item.scans.some(dt=>dt.item_status !== 'Done')
                        // const isAddedIn = item.scans.some(dt=>dt.item_status !== 'Done')
                        // const isAdded = patient_type === '1' ? isAddedOut : isAddedIn
                        const isDone = item.scans.some(dt=>dt.item_status === 'Done')
                        const payStatus =  item.scans.every(dt=>dt.pay_status === 0)
                        return(
                            <CollapsibleList key={item.id} item={item} handleOpen={()=>handleOpen(item.id)} button={
                                <>
                                    {payStatus ? <Tooltip title='Edit Request'>
                                        <IconButton onClick={()=>handleOpenEdit(item.id)}>
                                            <StyledIcon/>
                                        </IconButton>
                                    </Tooltip> : null}
                                    {isDone ?
                                        <button onClick={()=>handleOpenReport(item.id)} className='btn btn-sm sms-info-btn btn-sm'>View Radiology Report</button>
                                       : null}
                                </>
                            }
                                // user={ <ItemText  primary={`Requested By: ${item.doctor_name}`} />}
                            >
                                <ModuleTable headData={headData}>
                                    {item.scans.map((row, index) => {
                                        const isInPatientPending = row.item_status === 'Pending';
                                        const isOutPatientPending = row.item_status === 'Pending' || row.item_status === null;
                                        const isOutPatientFinished = row.item_status === 'Done';
                                        const isInPatientFinished = row.item_status === 'Done';
                                        const isPending = patient_type === '1' ? isOutPatientPending : isInPatientPending
                                        const isFinished =  patient_type === '1' ? isOutPatientFinished : isInPatientFinished
                                        return(
                                            <TableRow
                                                key={index}
                                            >
                                                <DataCell>
                                                    {row.hospital_scan_name}
                                                </DataCell>

                                                <DataCell>{row.purpose}</DataCell>
                                                <DataCell>{isPending ? <PendingStatus>{row.item_status ? row.item_status : 'Pending'}</PendingStatus>:isFinished ? <FinishedStatus>{row.item_status}</FinishedStatus>: null}</DataCell>
                                            </TableRow>
                                        )})}
                                </ModuleTable>
                            </CollapsibleList>
                        )
                    }) : <CenteredContainer>
                        <P>No radiology requests submitted for patient, click the add radiology request button to submit a request</P>
                    </CenteredContainer>:null}
                    {isError ? <CenteredContainer>
                        <P>An error occurred on the server</P>
                    </CenteredContainer>:null}
                </Container>
            </ReusableDoctorTabs>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbar
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(RadiologyTab);