import {useEffect, useState} from "react";
import axios from "axios";
import {config} from "../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";

const useRetrieveVisits = (actions, isResolved, url) => {
    const [searchValue, setSearchValue] = useState('');
    const [data, setData] = useState({});
    const [status, setStatus] = useState('idle')
    const [activePage, setActivePage] = useState(1);
    const [openCancel, setOpenCancel] = useState(false)
    const [cancel_visit_id, setCancelId] = useState('')
    const [isCanceled, setIsCanceled] = useState('idle')

    const isCancelResolved = isCanceled === 'resolved'


    useEffect(() => {
        const formData = new FormData();
        formData.append('search', searchValue);
        setStatus('pending')
        axios.post(`${config.smsUrl}/${url}/${activePage}`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            setStatus('success')
            setData(dt)
        }).catch(e => {
            logoutErrorMessage(e, null, actions);
            setStatus('error')
        })
    }, [activePage, searchValue, isResolved, isCancelResolved]);


    const handleOpenCancel = (id) => {
        setCancelId(id)
        setOpenCancel(true)

    };

    const handleCloseCancel = () => {
        setOpenCancel(false)
    };

    const handleCancel = () =>{
        setIsCanceled('pending')
        axios.post(`${config.smsUrl}/cvisit/delete_visits_without_requests`,{visit_id: cancel_visit_id}).then(() => {
            actions.snackbarActions.snackSuccess(`Visit canceled successfully`);
            setIsCanceled('resolved')
            setOpenCancel(false)
        }).catch(err => {
            errorMessages(err, null, actions)
            setIsCanceled('rejected')
        })
    }


    const handleSearch = (event) => {
        setSearchValue(event.target.value);
    };

    const handleChangeNewPage = (page) => {
        setActivePage(page);
    };

    const isLoading = status === 'pending';
    const isSuccess = status === 'success';
    const isError = status === 'error'

    const cancelProps = {openCancel, handleCancel, handleOpenCancel, handleCloseCancel}

    return {searchValue, data,  activePage,isError, isLoading, isSuccess,
    handleSearch,handleChangeNewPage, cancelProps}
}

export {useRetrieveVisits}