export const printFunctionality = (printDiv, styles='') =>{
    let prtContent = printDiv;
    let WinPrint = window.open('');
    let htmlToPrint = styles;
    htmlToPrint+=prtContent.innerHTML
    window.document.write(htmlToPrint);
    WinPrint.document.close();
    WinPrint.focus();
    setTimeout(()=>{
        window.print();
        WinPrint.close();
    }, 250)
}



export const printStyles = () =>{
    return '' +
        '<style>' +
        'table {' +
        'width: 100%;'+
        'border-collapse:collapse;' +
        '}' +
        'table th, table td {' +
        'padding:.65rem;' +
        'font-size:18px;' +
        '}' +
        'p {' +
            'margin:0'+
        '}'+
        '</style>';
}