import {useState, useEffect} from "react";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";


export const useLabTypeList = (actions) =>{
    const [searchValue, setSearchValue] = useState('');
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('sl');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [hospital_test_type_id, setTestTypeId] = useState('');
    const [typeList, setTypes] = useState([]);
    const [loading, setLoading] = useState('idle');
    const [openDelete, setOpenDelete] = useState(false);
    const [hospital_test_type, setHospitalType] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [openDialog, setOpenDialog] = useState(false);
    const [labType, setType] = useState({});
    const [test_type_id, setTypeId] = useState('')


    const isPending = isSubmitted === 'pending';
    const isResolved = isSubmitted === 'resolved';
    const isRejected = isSubmitted === 'rejected';

    const isLoading = loading === 'pending'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'

    useEffect(() => {
        setLoading('pending');
        axios.get(`${config.smsUrl}/claboratory/lab_type_list`).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const all_type = !dt.lab_list ? [] : dt.lab_list;
            setTypes(all_type);
            setLoading('success')
        }).catch(err => {
            setLoading('error');
            logoutErrorMessage(err, null, actions);

        })

    }, [isResolved]);


    useEffect(() => {
        if(!test_type_id){
            return
        }
        const formData = new FormData();
        formData.append('hospital_test_type_id', test_type_id);
        axios.post(`${config.smsUrl}/claboratory/lab_type_update_form`, formData).then((res) => {
            const data = res.data;
            const dt = !data ? {} : data;
            setType(dt);
        }).catch(err => {
            logoutErrorMessage(err, null,actions)
        })
    }, [test_type_id]);


    useEffect(() => {
        setHospitalType(labType.hospital_test_type)
    }, [labType]);


    const handleChange = (event) => {
        const {value} = event.target;
        setHospitalType(value)
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData();
        if(test_type_id){
            formData.append('hospital_test_type_id', test_type_id);
        }
        formData.append('hospital_test_type', hospital_test_type);
        setSubmitted(true);
        if (hospital_test_type) {
            setIsSubmitted('pending');
            const url = test_type_id ? 'edit_lab_type' :'add_lab_type'
            axios.post(`${config.smsUrl}/claboratory/${url}`, formData).then(() => {
                setIsSubmitted('resolved');
                actions.snackbarActions.snackSuccess(`Lab type ${test_type_id ? 'edited':'added'} successfully`);
                setOpenDialog(false)
                setHospitalType('')
                setSubmitted(false)
            }).catch(err => {
                setIsSubmitted('rejected');
                errorMessages(err, null, actions)
            })
        }
    };



    const handleOpenDelete = (id) => {
        setTestTypeId(id);
        setOpenDelete(true)
    };

    const handleOpenDialog = (id) => {
        setTypeId(id)
        setOpenDialog(true)
    };

    const handleCloseDialog = () => {
        setOpenDialog(false)
    };


    const handleDelete = () => {
        const formData = new FormData();
        formData.append('hospital_test_type_id', hospital_test_type_id);
        axios.post(`${config.smsUrl}/claboratory/delete_lab_type`, formData).then(() => {
            const types = typeList.filter(type => type.hospital_test_type_id !== hospital_test_type_id);
            setTypes(types);
            actions.snackbarActions.snackSuccess('Lab Type deleted successfully');
            setOpenDelete(false)
        }).catch(err => {
            errorMessages(err, null, actions)
        })
    };


    const handleCloseDelete = () => {
        setOpenDelete(false)
    };

    const handleSearch = (searchValue) => {
        setSearchValue(searchValue)
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };


    return {searchValue, order, orderBy, page, rowsPerPage, handleSearch, handleRequestSort,
        handleChangePage, handleChangeRowsPerPage, closeSnackbar,hospital_test_type_id,
         typeList, openDelete, hospital_test_type, submitted,isPending, isLoading, isRejected,
        isSubmitted, handleChange,  handleSubmit, handleOpenDelete,
        handleCloseDelete, handleDelete, isResolved, isSuccess, isError,
        openDialog, handleCloseDialog, handleOpenDialog, test_type_id}
}