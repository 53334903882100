import React, { useState, useEffect } from 'react';
import PageTitle from "../Utils/smsTitle";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {makeStyles} from "@material-ui/core/styles";
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
import {Link} from "react-router-dom";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import StarBorderOutlinedIcon from '@material-ui/icons/StarBorderOutlined';
import DomainOutlinedIcon from '@material-ui/icons/DomainOutlined';
import {faClipboardList} from "@fortawesome/free-solid-svg-icons/faClipboardList";
import ReceiptOutlinedIcon from "@material-ui/icons/ReceiptOutlined";
import SupervisorAccountOutlinedIcon from '@material-ui/icons/SupervisorAccountOutlined';
import styled from "@emotion/styled/macro";
import {history} from "../../Helpers/history"; 
import axios from 'axios';
import {config} from '../../Helpers/env';
import PrintDialog from '../Utils/Dialogs/PrintDialog';
import DialogSlide from '../Utils/Dialogs/TransitionDialog';
import MainSnackbar from '../Utils/Snackbar/SmsSnackbar';
import AccountingPeriod from '../Accounting/Setup/AccountingPeriod';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../actions/snackbarActions";
import { useVersionsContext } from '../../Context/versions-context';

const ReportsContainer = styled.div({
    padding:'40px 80px'
})

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '1500px',
        margin: '10px auto',
        width: '90%'
    },
    list: {
        paddingTop: 0
    },
    listItem: {
        borderBottom: '1px dashed #e5dddd',
        padding: '5px',
        cursor: 'pointer',
        '&:hover': {
            background: 'rgba(14, 118, 186, .08)'
        }
    },
    primary: {
        fontSize: '0.875rem',
        color: '#0e76ba'
    },
    secondary: {
        fontSize: '0.85em'
    },
    section: {
        maxWidth: '350px',
        marginBottom: '35px'
    },
    h6: {
        // borderBottom: '2px solid #027a5f',
        fontSize: '1.15em',
        color: '#4e4848'
    },
    icon: {
        // color: '#464545',
        width: '.8em'
    },
    span: {
        marginRight: '2px'
    },
    listItemIcon: {
        minWidth: '26px'
    },
    listIcon: {
        width: '.75em'
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));


const useGeneralReports = ({actions}) => {
    const [isPopup, setIsPopup] = useState(false);

    const [current_child, setCurrentChild] = useState({});
    const [accountingDialog, setAccDialog] = useState(false);
    const [accountingPeriodDialog, setAccPeriodDialog] = useState(false);

    const {subscription} = useVersionsContext()
    const version = subscription?.version 

    const handleOpenAccPeriodDialog = () => {
        setAccDialog(false);
        setIsPopup(true);
        setAccPeriodDialog(true);
    }

    const handleCloseAccPeriodDialog = () => {
        setAccPeriodDialog(false);
        setIsPopup(false);
        setCurrentChild({});
    }

    const handleCloseAccDialog = () => {
        setAccDialog(false);
    }

    const handleCheckAccStatus = (report_child) => {
        setCurrentChild(report_child);
        axios.get(`${config.smsUrl}/accounting/settings/status`).then(res=>{
            const data = res.data;
            const dt = data ?? {};
            const st = dt.message ?? '';
            
            if (st === false) {
                // first set up the accounting period
                setAccDialog(true);
            } else {
                history.push(`${report_child.url}`);
            }
        }).catch(err=>console.log(err))
    }

    const handleRouteChange = () => {
        history.push(`${current_child.url}`);
    }

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    return {
        closeSnackbar, isPopup, handleCheckAccStatus, accountingDialog, accountingPeriodDialog,
        handleCloseAccPeriodDialog, handleCloseAccDialog, handleOpenAccPeriodDialog, handleRouteChange,version
    }
}


const GeneralReports = ({actions, snackbars}) => {
    const classes = useStyles();
    const {openBar, type, message} = snackbars;

    const {
        closeSnackbar, isPopup, handleCheckAccStatus, accountingDialog, accountingPeriodDialog,
        handleCloseAccPeriodDialog, handleCloseAccDialog, handleOpenAccPeriodDialog, handleRouteChange,version
    } = useGeneralReports({actions});

    

    const list = [
        {
            id: 5,
            title: 'Medical Reports',
            icon: <DomainOutlinedIcon classes={{root: classes.icon}}/>,
            children: [
                {
                    name: 'Number of Services', url: 'numberofservices', id: '530',tier: ["Basic", "Standard", "Premium"]
                },
                {
                    name: 'Services performed by age group', url: 'servicesbyagegroup', id: '531',tier: ["Basic", "Standard", "Premium"]
                },

                // {
                //     name: 'Consultation', url: 'consultationreport', id: '500'
                // },
                // {
                //     name: 'Laboratory', url: 'tests/lab', id: '501'
                // },
                // {
                //     name: 'Radiology', url: 'tests/scan', id: '502'
                // },
                // {
                //     name: 'Dental', url: 'dentalreport', id: '504'
                // },
                {name: 'MOH Reports', url: 'moh_reports', id: '503',tier:["Premium"]},
                {name: 'Surgery Report', url: 'surgery_reports', id:'605',tier:["Premium"] },
                {name: 'Ward Report', url: 'ward_reports', id:'606', tier:["Premium", "Standard"] },
                // not sure of the id
                //  {name: 'Utilization of Special Services Reports', url: 'special_services_reports'}, //id
                {name: 'Death Reports', url: 'deathreports', id: '604', tier: ["Premium"]},
                {name: 'Diagnoses', url:'diagnoses', id:'609', tier:["Premium"]},
                {name: 'Chronic Diseases Report', url:'chronicdiseases', id:'610', tier:["Premium"]},
                {name: 'Referral Reports', url: 'referralreports', id: '607', tier:["Basic", "Standard", "Premium"]},
            ],
            tier: ["Basic", "Standard", "Premium"]
        },
        {
            id: 4,
            title: 'Purchases & Inventory',
            icon: <LocalMallOutlinedIcon classes={{root: classes.icon}}/>,
            children: [
                {name: 'Fast Moving Stock Report', url: 'fastmovingproducts', id: '402'},
                {name: 'Purchases', url: '/accounting/purchasesreport', id: '401',tier: ["Basic", "Standard", "Premium"]},
                // {name: 'Purchases by Product', url: 'purchasesbyitem', id: '402'},
                // {name: 'Stock Status', url: 'stockstatus', id: '403'},
                {name: 'Expired Products', url: 'expiredproducts', id: '404',tier: ["Basic", "Standard", "Premium"]},
                {name: 'Out of Stock', url: 'outofstock', id: '405', tier:["Basic", "Standard", "Premium"]},
                {name: 'Stock Summary', url: '/stocksummary', id: '403', tier:["Premium"]},
                // {name: 'Stock Batch-wise', url: '/stockbatchwise', id: '406'},
            ], 
            tier: ["Basic", "Standard", "Premium"]
        },
        {
            id: 2,
            title: 'Financial Reports',
            icon: <ShoppingCartOutlinedIcon classes={{root: classes.icon}}/>,
            children: [
                {name: 'Sales Reports', url: 'revenuebyprocedure', id: '201',tier: ["Basic", "Standard", "Premium"]},
                // {name: 'Sales by Procedure ', url: 'revenuebyprocedure', id: '202'},
                // {name: 'Sales by Cashier', url: 'revenuebycashier', id: '203'},
                // {name: 'Sales by Payment Mode', url: 'revenuebypaymentmode', id: '204'},
                {name: 'Collections Reports', url: 'totalrevenuegenerated', id: '205',tier: ["Basic", "Standard", "Premium"]},
                {name: 'Insurance Summary Reports', url: 'insurancesummaryreport', id: '206', tier: ["Premium"]},
                {name: 'Revenue per Department', url: 'revenuebydepartment', id: '207',tier: ["Basic", "Standard", "Premium"]},
                
            ],
            tier: ["Basic", "Standard", "Premium"]
        },
        {
        id: 1,
        title: 'Accountant',
        icon: <AccountBalanceWalletOutlinedIcon classes={{root: classes.icon}}/>,
        children: [

            {name: 'Profit & Loss Statement', url: '/accounting/accreports/plstatement', id: '103',tier : ["Premium"]},
            {name: 'Balance Sheet', url: '/accounting/accreports/balancesheet', id: '102',tier : ["Premium"]},
            // {name: 'Cash Flow Statement', url: 'cashflow', id: '104'},
            // {name: 'Journals', url: '/accounting/journals/report', id: '05'},
            {name: 'Trial Balance', url: '/accounting/trialbalance', id: '106',tier : ["Premium"]},
            {name: 'General Ledger', url: '/accounting/generalledger', id: '101',tier : ["Premium"]},
        ],
        tier: ["Premium"]
    },
        {
            id: 6,
            title: 'Payables',
            icon: <ReceiptOutlinedIcon classes={{root: classes.icon}}/>,
            children: [
                {name: 'Supplier Payables', url: '/creditors/report', id: '601',tier : ["Premium"]},
                // {name: 'Payables Summary', url: '/creditorssummary', id: '602'},
                // {name: 'Payments Made', url: '/creditorssummary', id: '201'},

            ],
            tier: ["Premium"]
        },

        {
            id: 7,
            title: 'Receivables',
            icon: <SupervisorAccountOutlinedIcon classes={{root: classes.icon}}/>,
            children: [
                {name: 'Customer Balances', url: '/customerreceivables', id: '701',tier : ["Premium"]},
                {name: 'Ageing Summary', url: '/debtorsagingsummary', id: '702',tier : ["Premium"]}

            ],
            tier : ["Premium"]
        }
    ]

    return (
        <div className="journals">
            <PageTitle title='General Reports'/>
            <SubHeader title="Reports" subTitle='General Reports'>
                <FontAwesomeIcon icon={faClipboardList}/>
            </SubHeader>

            <MainSnackbar variant={type} handleCloseBar={closeSnackbar} open={openBar} message={message}/>

            <PrintDialog handleCancel={handleCloseAccDialog} openDialog={accountingDialog} handleClose={handleCloseAccDialog}
            handleClick={handleOpenAccPeriodDialog} message={"Missing Accounting Period"}
            text={"You have not setup the accounting period. Proceed to setup?"}/>

            <DialogSlide openAccPeriod={accountingPeriodDialog} handleClose={handleCloseAccPeriodDialog} title={"Choose Accounting Period"}>
                <AccountingPeriod isPopup={isPopup} handleRouteChange={handleRouteChange}/>
            </DialogSlide>

            <ReportsContainer>
                <div className="row">
                    {list.map(item =>  (
                        
                        (item.tier?.includes(version)) && 
                        <div key={item.id} className="col-lg-4 col-md-6 col-sm-12">
                            
                            <div className={classes.section}>
                                <h6 className={classes.h6}><span className={classes.span}>
                                {item.icon}</span>{item.title}</h6>
                                {item.title === 'Financial Reports' ?
                                    item.children.map(it => (
                                        
                                        <List classes={{root: classes.list}} key={it.id}>
                                            
                                            <button
                                                style={{
                                                    background:'none',
                                                    width: '100%',
                                                    border: 'none'
                                                }}
                                                onClick={() => handleCheckAccStatus(it)}
                                            >
                                                {(it.tier?.includes(version)) &&
                                                
                                                <ListItem classes={{root: classes.listItem}}>
                                                    <ListItemIcon classes={{root: classes.listItemIcon}}>
                                                        <StarBorderOutlinedIcon classes={{root: classes.listIcon}}/>
                                                    </ListItemIcon>
                                                    <ListItemText primary={it.name} classes={{primary: classes.primary}}/>
                                                </ListItem>}
                                            </button>
                                            {Array.isArray(it.children) ?
                                            it.children.map(child => (
                                                <List key={child.id} component="div" disablePadding>
                                                    <Link to={child.url} style={{textDecoration: 'none'}}>
                                                        <ListItem button classes={{root: classes.listItem}}
                                                                className={classes.nested}>
                                                            <ListItemIcon classes={{root: classes.listItemIcon}}>
                                                                <StarBorderOutlinedIcon
                                                                    classes={{root: classes.listIcon}}/>
                                                            </ListItemIcon>
                                                            <ListItemText primary={child.name}
                                                                        classes={{primary: classes.primary}}/>
                                                        </ListItem>
                                                    </Link>
                                                </List>
                                            ))
                                            : null}
                                        </List>
                                    ))
                                :
                                    item.children.map(it => (
                                        (it.tier?.includes(version)) &&
                                        <List classes={{root: classes.list}} key={it.id}>
                                         
                                            <Link to={it.url} style={{textDecoration: 'none'}}>
                                                <ListItem classes={{root: classes.listItem}}>
                                                    <ListItemIcon classes={{root: classes.listItemIcon}}>
                                                        <StarBorderOutlinedIcon classes={{root: classes.listIcon}}/>
                                                    </ListItemIcon>
                                                    <ListItemText primary={it.name} classes={{primary: classes.primary}}/>
                                                </ListItem>
                                            </Link>
                                            {Array.isArray(it.children) ?
                                                it.children.map(child => (
                                                    <List key={child.id} component="div" disablePadding>
                                                        <Link to={child.url} style={{textDecoration: 'none'}}>
                                                            <ListItem button classes={{root: classes.listItem}}
                                                                    className={classes.nested}>
                                                                <ListItemIcon classes={{root: classes.listItemIcon}}>
                                                                    <StarBorderOutlinedIcon
                                                                        classes={{root: classes.listIcon}}/>
                                                                </ListItemIcon>
                                                                <ListItemText primary={child.name}
                                                                            classes={{primary: classes.primary}}/>
                                                            </ListItem>
                                                        </Link>
                                                    </List>
                                                ))
                                                : null}
                                        </List>
                                    ))
                                }
                            </div>
                        </div>
                    ))}
                </div>
            </ReportsContainer>
        </div>
    )
}

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralReports);