import React from "react";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CustomTable, {useHiddenColumns} from "../../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import * as snackbarActions from "../../../actions/snackbarActions"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import PageTitle from "../../Utils/smsTitle";
import {faNotesMedical} from "@fortawesome/free-solid-svg-icons/faNotesMedical";
import { dateStringConvert } from "../../Utils/ConvertDate";
import {MiniHeader} from "../../Utils/Menu/MiniSubHeader";
import {ReusableRouteTabs} from "../../Utils/Dialogs/ReusableTabs";
import {history} from "../../../Helpers/history";
import {Container} from "../../Utils/styledComponents";
import { useConsultationList } from "./hooks/useConsultationList";
import ReusableDialog from "../../Utils/Dialogs/ReusableDialog";
import { CustomSelect } from "../../Utils/FormInputs/SelectInput";
import Label from "../../Utils/FormInputs/Label";
import Form from "react-bootstrap/Form";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { PatientDetails } from "../NewService/SelectService";


const headData = [{id: 'sl', numeric: false, disablePadding: false, label: 'SL', hidden:false},
    {id: 'date', numeric: false, disablePadding: false, label: 'Date',hidden:false},
    {id: 'patientNo', numeric: false, disablePadding: false, label: 'Patient No.',hidden:false},
    {id: 'patientName', numeric: false, disablePadding: false, label: 'Patient Name',hidden:false},
    {id: 'phone', numeric: false, disablePadding: false, label: 'Phone',hidden:false},
    {id: 'kin_phone_no', numeric: false, disablePadding: false, label: 'NOK Phone',hidden:false},
    {id: 'doctor', numeric: false, disablePadding: false, label: 'Doctor',hidden:false},
    {id: 'status', numeric: false, disablePadding: false, label: 'Status',hidden:false},
    {id: 'actions', numeric: false, disablePadding: true, label: 'Action',hidden:false},
];

const ConsultationList = ({actions, snackbar}) => {
    const   {searchValue, activePage, handleSearch, handleChangeNewPage, closeSnackBar, 
        visitList, submitted, isSubmitted,  handleChangeDoctor,
        assignDoctor, doctor_name, doctors, department, departments,handleChangeDepartment,
        ids,openAssign,handleOpenAssign, handleCloseAssign,isLoading, isSuccess, isError
    } = useConsultationList(actions)
    const { patient_number, patient_name, age, gender} = ids
    const {openBar, type, message} = snackbar;
    const {visits,total_count} = visitList;
    const components = [{label: 'New Visit', path: '/newvisit'},
    {label: 'Visit List', path: '/visitlist'}, {label: 'Consultation List', path: '/consultationlist'}]

    const departs = !departments ? [] : departments;

    const {
        headCells, all_hidden, handleAllHeadCells, handleHeadCells
    } = useHiddenColumns(headData);

    return (
        <div>
            <PageTitle title="Consultation List"/>
            <SubHeader title="Consultation list" subTitle="Consultation List">
                <FontAwesomeIcon icon={faNotesMedical}/>
            </SubHeader>
            <ReusableDialog message='Reassign Doctor' openDialog={openAssign} handleClose={handleCloseAssign}
            isSubmitted={isSubmitted} handleSubmit={assignDoctor} width='md'>
                    <PatientDetails {...{patient_number,patient_name, age,gender}}/>
                    <div className="mt-3">
                        <Form.Group as={Row}>
                            <Label name="Department" htlmFor='department' sm={2} column />
                            <Col sm={6}>
                                <CustomSelect  value={department} id='department' options={departs} onChange={handleChangeDepartment}/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Label name="Doctor" htmlfor='doctor_name' sm={2} column type/>
                            <Col sm={6}>
                                <CustomSelect onChange={handleChangeDoctor} id='doctor_name' value={doctor_name} options={doctors}/>
                                {(submitted && !doctor_name) && <div className="help-block">Doctor is required</div>}
                            </Col>
                        </Form.Group>
                    </div>
            </ReusableDialog>
            <Container>
                <MiniHeader title='Consultation List'/>
                <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/>
                <MainSnackbar variant={type} message={message} open={openBar} handleCloseBar={closeSnackBar}/>
                <CustomTable  headData={headCells} handler={handleSearch} term={searchValue}
                             data={visits} colSpan={headCells.length} customPage records={10} activePage={activePage}
                             handleChangeNextPage={handleChangeNewPage} total_count={total_count}
                              {...{ all_hidden, handleAllHeadCells, handleHeadCells}}>
                   
                        <TableBody>
                        {isLoading? <TableRow>
                        <TableCell colSpan={headCells.length} align="center"><img src="/images/smsloader.gif"
                                                                   alt="loader"/></TableCell>
                    </TableRow> :null}
                            {isSuccess ?  visits.length > 0 ? visits.slice(0, 10).map(item => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={item.visit_id}
                                            className='mui-table-row'>
                                            <TableCell>{item.count}</TableCell>
                                            <TableCell hidden={headCells[1].hidden}>{dateStringConvert(item.date)}</TableCell>
                                            <TableCell hidden={headCells[2].hidden}>{item.patient_number}</TableCell>
                                            <TableCell hidden={headCells[3].hidden}>{item.patient_name}</TableCell>
                                            <TableCell hidden={headCells[4].hidden}>{item.phone_no}</TableCell>
                                            <TableCell hidden={headCells[5].hidden}>{item.kin_phone_no}</TableCell>
                                            <TableCell hidden={headCells[6].hidden}>{item.doctor_name}</TableCell>
                                            <TableCell hidden={headCells[6].hidden}>
                                                {item.status === 0 ? <span className="text-pending">Visit created</span>:
                                                item.status === 1 ? <span className='text-success'>Vitals taken</span>: item.status === 2 ? 
                                                <span className='text-success'>Request added</span>:item.status === 3 ? 
                                                 <span className='text-success'>Treatment ongoing</span>:null}
                                            </TableCell>
                                            <TableCell>
                                                 {item.status !== 3 ? <button onClick={()=>handleOpenAssign(item)}
                                                        className="btn btn-sm sms-info-btn mr-1">Reassign</button>:null}
                                             
                                            </TableCell>
                                        </TableRow>
                                    );
                                }): <TableRow>
                                <TableCell colSpan={headCells.length} align="center">No records found</TableCell>
                            </TableRow>:null}
                            { isError ?   <TableRow>
                            <TableCell colSpan={headCells.length} align="center">The server did not return a successful response</TableCell>
                        </TableRow> :null }
                        </TableBody>
                </CustomTable>
            </Container>
        </div>
    )
};

function mapStateToProps(state) {
    const {snackbar} = state;
    return {snackbar}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ConsultationList);
