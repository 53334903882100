import React, {useState} from "react";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import *  as snackbarActions from "../../../actions/snackbarActions";
import { DateTimeFilter } from "../../Utils/ReusableComponents/DateTimeFilter";
import { ReportsTable, BorderedCell,BoldBorderedCell } from "../../Utils/Tables/ReportsTable";
import  TableRow  from "@material-ui/core/TableRow";
import { faClipboardList } from "@fortawesome/free-solid-svg-icons/faClipboardList";
import { LoadingGif } from "../../Utils/Loader";
import { useDiagnosesReport } from "./CustomHooks/useDiagnosesReport";
import {convertFemales, convertMales} from "../MedicalReports/ServicesPerformedByAgeGroupTable";
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ReusableTabs from "../../Utils/Dialogs/ReusableTabs";
import Label from "../../Utils/FormInputs/Label";
import SelectInput from "../../Utils/FormInputs/SelectInput";
import CustomTable from "../../Utils/Tables/CustomTable";
import { TableBody, TableCell } from "@material-ui/core";
import { formatDateTime } from "../../Utils/ConvertDate";
import {BackButton, DataExportButton} from "../../Utils/Buttons/DataExportationButton";
import { TabPanel } from "../../Utils/Dialogs/ReusableTabs";
import {MedicalReportFilter} from "../ReportFilter";
import {Container, RightAlignedContainer} from "../../Utils/styledComponents";
import {useExcelReports, usePdfDownload} from "../../Utils/ReusableComponents/useExcelExport";

const headData = [
    {id: 'date', numeric: false, disablePadding: false, label: 'Date'},
    {id: 'p_number', numeric: false, disablePadding: false, label: 'Patient Number'},
    {id: 'gender', numeric:false, disablePadding:false, label:'Gender'},
    {id: 'age', numeric:false, disablePadding:false, label:'Age'},
    {id: 'diagnosis', numeric:false, disablePadding: false, label:'Diagnosis Notes'}
    ]

export const filterData = (arr) =>{
    return arr.map(item=>{
        const a28days = item['a0to28days'] ? item['a0to28days'] : {};
        const a1month = item['a1monthto1yr'] ? item['a1monthto1yr'] : {};
        const a2yrs = item['a2to4yrs'] ? item['a2to4yrs'] : {};
        const a5yrs = item['a5to12yrs'] ? item['a5to12yrs'] : {};
        const a13yrs = item['a13to19yrs'] ? item['a13to19yrs'] : {};
        const a20yrs = item['a20to39yrs'] ? item['a20to39yrs'] : {};
        const a40yrs = item['a40to59yrs'] ? item['a40to59yrs'] : {};
        const a60yrs = item['a60yrsandabove'] ? item['a60yrsandabove'] : {};
        const total = convertMales(a28days) + convertFemales(a28days) + convertMales(a1month) + convertFemales(a1month) +
            convertFemales(a2yrs) + convertMales(a2yrs) + convertMales(a5yrs) + convertFemales(a5yrs) +
            convertMales(a13yrs) + convertFemales(a13yrs) + convertMales(a20yrs) + convertFemales(a20yrs) +
            convertMales(a40yrs) + convertFemales(a40yrs) + convertMales(a60yrs) + convertFemales(a60yrs);
        return{
            ...item,
            a28days,
            a1month,
            a2yrs,
            a5yrs,
            a13yrs,
            a20yrs,
            a40yrs,
            a60yrs,
            total
        }
    })

}

const DiagnosesReport = ({actions}) => {
    const  {diagnosis, isSuccess, isError, state, submitted, without_icd_notes,
        handleChange, handleSubmit, isPending, handleChangeNewPage, isLoading,
        payment, patientType, setPatientType, setPayment
    } = useDiagnosesReport(actions);

    const {start_date, end_date, patient_type, chosen_patient_type} = state;
    const arr = filterData(diagnosis);

    const sum = arr.reduce((a,item)=>{
        return a + item.total
    }, 0)

    const title = {
        1:'Out-patient',
        2:'In-patient'
    }

    const tabs = ['With ICD','Without ICD'];
    const [tabValue, setTabValue] = useState(0);
    
    const handleChangeTabValue = (event,val) => {
        setTabValue(val);
    }

    const url = tabValue === 0 ? 'creport/diagnosis_reports_exports' : 'creport/notes_diagnoses_exports'

    const commonParams = {start_date , end_date,patient_type:patientType?.value ? patientType?.value:'',
        }

    const fileName = `${patientType?.label}-${start_date} to ${end_date} ${tabs[tabValue]}`

    const excelObj = {file_name:fileName,params:{...commonParams, export_type:2}, url}

    const pdfObj = {file_name:fileName,params:{...commonParams, export_type:1}, url}


    const {exportExcel, isLoading:isLoadingExcel} = useExcelReports(actions,excelObj)
    const {exportPdf, isLoading:isLoadingPdf} = usePdfDownload(actions,pdfObj)

  
    return (
        <div className='journals'>
            <PageTitle title='Outpatient Diagnosis'/>
            <SubHeader title="Reports" subTitle='Diagnosis'>
                <FontAwesomeIcon icon={faClipboardList}/>
            </SubHeader>
            <Container>
                <div style={{margin: '10px'}}>
                    <BackButton text='General Reports' to='/reports/generalreports'/>
                </div>
                <div className="mx-2 mt-2">
                    <DateTimeFilter {...{
                        start_date,
                        end_date,
                        handleChange,
                        submitted,
                        handleSubmit,
                        isPending,
                        type: 'date'
                    }}>
                        <div className="col-lg-4">
                            <Form.Group as={Row}>
                                <Label name="Patient Type" column sm="4"/>
                                <Col sm="7">
                                    <SelectInput value={chosen_patient_type} onChange={handleChange}
                                                 name="chosen_patient_type"
                                                 options={[{value: '1', text: 'Outpatient'}, {
                                                     value: '2',
                                                     text: 'Inpatient'
                                                 }]}/>
                                </Col>
                            </Form.Group>
                        </div>
                    </DateTimeFilter>

                    <RightAlignedContainer>
                        <div className="mb-2">
                            <DataExportButton exportCsv={exportExcel} isLoading={isLoadingExcel || isLoadingPdf}
                                              downLoadPdf={exportPdf}/>
                        </div>
                    </RightAlignedContainer>

                    {/*<MedicalReportFilter {...{payment, setPayment, patientType, setPatientType,handleSubmit,*/}
                    {/*    start_date, end_date, handleChange,isPending, submitted, actions, file_name:'Diagnosis',*/}
                    {/*    excelUrl:'download_excel', pdfUrl:'download_pdf'}}/>*/}

                    <ReusableTabs value={tabValue} tabHeading={tabs} handleChange={handleChangeTabValue}>

                        <TabPanel value={tabValue} index={0}>
                            <div>
                                <h6 className="mt-4"><strong>{`${title[patientType['value']]} Diagnoses`}</strong></h6>
                                <ReportsTable {...{headTitle: 'Diagnosis'}}>
                                    {isLoading ? <LoadingGif/> : null}
                                    {isSuccess ? diagnosis.length > 0 ?
                                            <>
                                                {arr.filter(item => item['Diagnosis'] && item['Diagnosis_code']).map((item, index) => {
                                                    return (
                                                        <TableRow key={index}>
                                                            <BorderedCell>{item['Diagnosis']}</BorderedCell>
                                                            <BoldBorderedCell>{item.a28days['M'] ? item.a28days['M'] : ''}</BoldBorderedCell>
                                                            <BoldBorderedCell>{item.a28days['F'] ? item.a28days['F'] : ''}</BoldBorderedCell>
                                                            <BoldBorderedCell>{item.a1month['M'] ? item.a1month['M'] : ''}</BoldBorderedCell>
                                                            <BoldBorderedCell>{item.a1month['F'] ? item.a1month['F'] : ''}</BoldBorderedCell>
                                                            <BoldBorderedCell>{item.a2yrs['M'] ? item.a2yrs['M'] : ''}</BoldBorderedCell>
                                                            <BoldBorderedCell>{item.a2yrs['F'] ? item.a2yrs['F'] : ''}</BoldBorderedCell>
                                                            <BoldBorderedCell>{item.a5yrs['M'] ? item.a5yrs['M'] : ''}</BoldBorderedCell>
                                                            <BoldBorderedCell>{item.a5yrs['F'] ? item.a5yrs['F'] : ''}</BoldBorderedCell>
                                                            <BoldBorderedCell>{item.a13yrs['M'] ? item.a13yrs['M'] : ''}</BoldBorderedCell>
                                                            <BoldBorderedCell>{item.a13yrs['F'] ? item.a13yrs['F'] : ''}</BoldBorderedCell>
                                                            <BoldBorderedCell>{item.a20yrs['M'] ? item.a20yrs['M'] : ''}</BoldBorderedCell>
                                                            <BoldBorderedCell>{item.a20yrs['F'] ? item.a20yrs['F'] : ''}</BoldBorderedCell>
                                                            <BoldBorderedCell>{item.a40yrs['M'] ? item.a40yrs['M'] : ''}</BoldBorderedCell>
                                                            <BoldBorderedCell>{item.a40yrs['F'] ? item.a40yrs['F'] : ''}</BoldBorderedCell>
                                                            <BoldBorderedCell>{item.a60yrs['M'] ? item.a60yrs['M'] : ''}</BoldBorderedCell>
                                                            <BoldBorderedCell>{item.a60yrs['F'] ? item.a60yrs['F'] : ''}</BoldBorderedCell>
                                                            <BoldBorderedCell>{item.total}</BoldBorderedCell>
                                                        </TableRow>
                                                    )
                                                })}
                                                <TableRow>
                                                    <BorderedCell colSpan={17}
                                                                  align="right"><strong>Total</strong></BorderedCell>
                                                    <BorderedCell><strong>{sum}</strong></BorderedCell>
                                                </TableRow>
                                            </>
                                            : <TableRow><BorderedCell colSpan={18} align="center">No records
                                                found</BorderedCell></TableRow>
                                        : null}
                                    {isError ?
                                        <TableRow><BorderedCell colSpan={18} align="center">The server did not return a
                                            valid response</BorderedCell></TableRow> : null}
                                </ReportsTable>
                            </div>
                        </TabPanel>

                        <TabPanel value={tabValue} index={1}>
                            <CustomTable headData={headData} data={without_icd_notes} customPageNoPagination
                                         colSpan={headData.length} title={`${title[patientType['value']]} Diagnoses`}
                                         handleChangeNextPage={handleChangeNewPage}>
                                <TableBody>
                                    {isLoading ? <LoadingGif/> : null}
                                    {isSuccess ?
                                        without_icd_notes.length > 0 ?
                                            without_icd_notes.map((item, idx) => {
                                                return (
                                                    <TableRow hover role="checkbox" key={item.visit_id} tabIndex={-1}
                                                              className="mui-table-row">
                                                        <TableCell><span>{formatDateTime(item.begin_datetime)}</span></TableCell>
                                                        <TableCell>{item.patient_number}</TableCell>
                                                        <TableCell>{item.gender}</TableCell>
                                                        <TableCell>{item.age}</TableCell>
                                                        <TableCell>{item.notes}</TableCell>
                                                    </TableRow>
                                                )
                                            }) :
                                            <TableRow><TableCell colSpan={5} align="center">No records found</TableCell></TableRow>
                                        : null
                                    }
                                    {isError ?
                                        <TableRow><TableCell colSpan={12} align="center">The server did not return a
                                            valid response</TableCell></TableRow> : null}
                                </TableBody>
                            </CustomTable>
                        </TabPanel>
                    </ReusableTabs>
                </div>
            </Container>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(DiagnosesReport);
