import React, {useEffect, useState} from "react";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CustomTable from "../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import {getComparator, stableSort} from "../Utils/Tables/sortingFunctions";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import * as snackbarActions from "../../actions/snackbarActions"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import PageTitle from "../Utils/smsTitle";
import DeleteDialog from "../Utils/Dialogs/DeleteDialog";
import {faBed} from "@fortawesome/free-solid-svg-icons/faBed";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import CustomDialog from "../Utils/Dialogs/CustomDialog";
import AddWardForm from "./AddWardForm";
import {useAddWard} from "./CustomHooks/UseAddWard";
import {titleCase} from "../Users/addUser";
import axios from "axios";
import {config} from "../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../Helpers/ErrorMessages";
import {LoadingGif} from "../Utils/Loader";
import {BlockNavigationComponent} from "../Utils/ReusableComponents/BlockNavigationComponent";
import {history} from "../../Helpers/history";


const headData = [{id: 'sl', numeric: false, disablePadding: false, label: 'SL'},
    {id: 'wardName', numeric: false, disablePadding: false, label: 'Ward Name'},
    {id: 'description', numeric: false, disablePadding: false, label: 'Description'},
    {id: 'rooms', numeric: false, disablePadding: false, label: 'Rooms'},
    {id: 'bed', numeric: false, disablePadding: false, label: 'Bed Capacity'},
    // {id: 'status', numeric: false, disablePadding: false, label: 'status'},
    {id: 'actions', numeric: false, disablePadding: true, label: 'Action'},
];

export const WardList = ({actions, snackbar}) => {
    const [searchValue, setSearchValue] = useState('');
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('sl');
    const [ward_id, setWardId] = useState('');
    const [openDelete, setDelete] = useState(false);
    const [wardList, setWardList] = useState([]);
    const [loading, setLoading] = useState('idle');
    const [activePage, setActivePage] = useState(1);
    const [ward_count, setCount] = useState(0);
    const {wardProps, isResolved} = useAddWard(actions)



    useEffect(() => {
        setLoading('loading');
        const formData = new FormData();
        const search = searchValue ? titleCase(searchValue) :searchValue
        formData.append('search', search);
        axios.post(`${config.smsUrl}/cbedmanager/ward_list/${activePage}`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const list = !dt.ward_list ? [] : dt.ward_list;
            const count = !dt.total_count ? 0 : dt.total_count;
            setLoading('success');
            setCount(count);
            setWardList(list)
        }).catch(e => {
            logoutErrorMessage(e, null, actions);
            setLoading('error')
        })
    }, [activePage, searchValue, isResolved, isResolved]);


    const handleSearch = (event) => {
        setSearchValue(event.target.value);

    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangeNewPage = (page) => {
        setActivePage(page);
    };


    const handleOpenDelete = (id) => {
        setDelete(true);
        setWardId(id)
    };

    const handleCloseDelete = () => {
        setDelete(false)
    };

    const handleDelete = () => {
        const formData = new FormData();
        formData.append('ward_id', ward_id);
        axios.post(`${config.smsUrl}/cbedmanager/delete_ward`, formData).then(() => {
            const wds = wardList.filter(wd => wd.ward_id !== ward_id);
            setWardList(wds);
            setDelete(false);
            actions.snackbarActions.snackSuccess('Ward deleted successfully');
        }).catch(e => {
            errorMessages(e, null, actions);
            setDelete(false)
        });
    };

    const closeSnackBar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const isLoading = loading === 'loading'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'

    const {openBar, type, message} = snackbar;
    const perm = JSON.parse(sessionStorage.getItem('permission'));
    const permission = !perm ? {} : perm;
    const add_ward = !permission.add_ward ? {} : permission.add_ward;
    const ward_list = !permission.ward_list ? {} : permission.ward_list;
    const wards = !wardList ? [] : wardList;

    const {open, openEditDialog, handleOpenEditDialog, handleCloseEditDialog, handleOpenDialogForm, handleCloseDialogForm,blockingProps} = wardProps



    const {isBlocking, isBlockDialog, setIsBlockDialog, handleReset, setIsBlocking} = blockingProps
    return (
        <div data-testid="ward-list" className='journals'>
            <PageTitle title="Ward List"/>
            <SubHeader title="Bed Management" subTitle="Ward list">
                <FontAwesomeIcon icon={faBed}/>
            </SubHeader>
            <BlockNavigationComponent
                when={isBlocking}
                shouldBlockNavigation={()=>{
                    return isBlocking;
                }}
                navigate={path=>history.push(path)}
                dialogClose={isBlockDialog}
                setIsBlockDialog={setIsBlockDialog}
                clearForm={handleReset}
            />
            <div className='general-ledger-header'>
                <div className="text-right">
                    {(add_ward.create || add_ward.read || add_ward.update || add_ward.delete)&&
                    <button className="btn sms-btn btn-sm" onClick={handleOpenDialogForm} ><FontAwesomeIcon icon={faPlus}/> Add Ward</button>
                    }
                  <CustomDialog open={open} handleClose={handleCloseDialogForm} title="Add Ward" maxWidth="md">
                     <AddWardForm {...{...wardProps, setIsBlocking}}/>
                   </CustomDialog>
                </div>

                <CustomDialog open={openEditDialog} maxWidth="md"
                handleClose={handleCloseEditDialog} title="Edit Ward">
                   <AddWardForm {...wardProps}/>
               </CustomDialog>
            </div>
            <div className="mui-tables">
            <DeleteDialog message="delete" openDialog={openDelete} handleClose={handleCloseDelete}
                              text="ward" title='Ward'>
                <button className=' btn sms-btn-dismiss btn-sm' onClick={handleDelete}>Delete</button>
                <button className="btn sms-grey-btn btn-sm" onClick={handleCloseDelete}>No</button>    
            </DeleteDialog>
                <MainSnackbar variant={type} message={message} open={openBar} handleCloseBar={closeSnackBar}/>
                <CustomTable title="Ward List" headData={headData} handler={handleSearch} term={searchValue}
                             handleRequestSort={handleRequestSort} data={wards} colSpan={5} order={order}
                             orderBy={orderBy} customPage records={10} activePage={activePage}
                             handleChangeNextPage={handleChangeNewPage} total_count={ward_count}>

                        <TableBody>
                            {isLoading  ? <TableRow>
                                    <TableCell colSpan={headData.length} align="center"><LoadingGif/></TableCell>
                                </TableRow> : null}
                            {isSuccess ? wards.length > 0 ?  stableSort(wards, getComparator(order, orderBy))
                                .slice(0, 10)
                                .map((item, index) => {
                                    const count = ((activePage - 1) * 10) + index + 1;
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={item.ward_id}
                                            className='mui-table-row'>
                                            <TableCell>{count}</TableCell>
                                            <TableCell>{item.name}</TableCell>
                                            <TableCell>{item.description}</TableCell>
                                            <TableCell>{item.number_of_rooms}</TableCell>
                                            <TableCell>{item.bed_capacity}</TableCell>
                                            {/*<TableCell>{item.status}</TableCell>*/}
                                            <TableCell>
                                                {ward_list.update ? 
                                                    <button className="btn btn-sm sms-info-btn mr-1" onClick={() => handleOpenEditDialog(item.ward_id)}>Edit</button>
                                                :<div/>}
                                                {/*{ward_list.delete && <button onClick={() => handleOpenDelete(item.ward_id)}*/}
                                                {/*        className="btn btn-sm sms-btn-dismiss">Delete*/}
                                                {/*</button>}*/}
                                            </TableCell>
                                        </TableRow>
                                    );
                                }): <TableRow>
                                <TableCell colSpan={headData.length} align="center">No records found</TableCell>
                            </TableRow>: null}
                            {isError ? <TableRow>
                                <TableCell colSpan={headData.length} align="center">The server did not a valid response</TableCell>
                            </TableRow>: null}
                        </TableBody>

                </CustomTable>
            </div>
        </div>
    )
};

function mapStateToProps(state) {
    const {beds, snackbar} = state;
    return {beds, snackbar}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WardList);
