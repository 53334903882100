import * as types from "../actions/actionTypes"

const initialState ={
  openBar:false
};

export default function snackbarReducer(state = initialState, action){
  switch (action.type) {
    case types.SNACKBAR_SUCCESS:
      return{...state, openBar:true, type:"success", message:action.message};
    case types.SNACKBAR_ERROR:
      return {...state, openBar:true, type: "error", message: action.message};
    case types.SNACKBAR_INFO:
      return {...state, openBar:true, type: "info", message: action.message};
    case types.SNACKBAR_WARNING:
      return {...state, openBar:true, type: "warning", message: action.message};
    case types.HIDE_SNACKBAR:
      return {...state, openBar: false};
    default:
      return  state;

  }
}
