import React, {useEffect, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Select from "@material-ui/core/Select";
import MenuItem from '@material-ui/core/MenuItem';
import InputBase from '@material-ui/core/InputBase';
import { ErrorMessage } from '../styledComponents';
import { titleCase } from '../../Users/addUser';
import Popper from "@material-ui/core/Popper";
import * as colors from "../../../styles/colors"

const styledBy = (property, mapping) => (props) => mapping[props[property]];

// Styles for the AutoComplete component
const styles = {
  root: {
    backgroundColor: 'white',   
    '& .MuiInputBase-root' : {
      fontSize: '12px',
      borderRadius: `10px`,
      padding: '10px',
      height: '34px',
      border: styledBy('color', {
        default: 'none',
        error: '1px solid red',
      }),
    },
    '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input' : {
      padding:'0px'
    },
  },
};

export const AutoCompleteTextField = withStyles(styles)(({ classes, color, ...other }) => (
  <TextField className={classes.root} {...other} />
));

const popper_styles = (theme) => ({
  popper: {
    maxWidth: "max-content",
    border: '1px solid rgba(27,31,35,.15)',
    boxShadow: '0 3px 12px rgba(27,31,35,.15)',
    borderRadius: 10,
    zIndex: theme.zIndex.modal,
    fontSize: 12,
    background:'white',
    top: '0px',
    marginTop: 5,
    marginBottom: 5,
  }
});

export const PopperMy = function (props) {
  return <Popper {...props} style={popper_styles.popper} placement="bottom-start" />;
};

// Styles for the Select component
const input_styles = {
  input: {
    backgroundColor: 'white',
    border: styledBy('color', {
      default: '1px solid #ced4da',
      error: '1px solid red',
      placeholder: '1px solid #ced4da',
    }),
    color: styledBy('color', {
      default: 'black',
      placeholder: 'gray',
    }),
    fontSize: '12px',
    borderRadius: `10px`,
    padding: '10px',
    height: '34px',
    width: '100%',
    '&:focus': {
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
};

const SelectInput = withStyles(input_styles)(({ classes, color, ...other }) => (
  <InputBase className={classes.input} {...other} />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    fontSize: '12px',
    '&:focus': {
      backgroundColor: '#d3dbf2',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

// styles for the menu dropdown
export const useStyles = makeStyles((theme) => ({
  root: {
   // display: 'flex',
   // flexWrap: 'wrap',
   // zIndex: theme.zIndex.modal,]
 },
  paper: {
    // boxShadow: 'none',
    color: '#586069',
    fontSize: 12,
    background: 'white',
    boxShadow: '0 3px 12px rgba(27,31,35,.15)',
  },
  option: {
    minHeight: 'auto',
   // alignItems: 'flex-start',
   // overflowWrap:'anywhere',
   // wordWrap: 'break-word',
   // hyphens: 'auto',
  
    padding: 8,
    '&[data-focus="true"]': {
     backgroundColor: '#d3dbf2'
    },
  },
  popper: {
    border: '1px solid rgba(27,31,35,.15)',
    boxShadow: '0 3px 12px rgba(27,31,35,.15)',
    borderRadius: 10,
    zIndex: theme.zIndex.modal,
    fontSize: 12,
    background:'white',
    top: '0px',
    marginTop: 5,
    marginBottom: 5,
  },
  selectMenu: {
    marginTop:'4px',
    height:'200px',
    overflow:'scroll'
  },
    errorField:{
      '& .MuiInputBase-root':{
          border:`1px solid ${colors.red60}`
      }
    }
}));

export const inValidateValue = (med_value, autocomplete_value, value_text) => {

   const isValueMed = (!med_value) ? true : typeof med_value === 'string' ? false :
      value_text ? !med_value[`${value_text}`] : !med_value.value ;

    const isAutoCompleteValue = (!autocomplete_value) ? true : typeof med_value === 'string' ? false :
    value_text ? !autocomplete_value[`${value_text}`] : !autocomplete_value.value;
    
    // if both are empty
    return isValueMed && isAutoCompleteValue ;
}

// uncontrolled  -> the options, the change everytime, so they can be undefined or not
// also the component is handled by the DOM, not react, hence the inputvalue element
export const AutoCompleteUnControlled = ({ options, idx, reset, handleChange, handleProductData, submitted, placeholder="", name="", sub_idx,
  isExpiryLimit = false,renderOption=(option)=>option.label,
  medicine_value={}, label_text="", value_text="", id="", errorMsg="", valid_error_check, errorMsg_Valid, testId="",
                                             item=null, ...rest}) => {
  
  const [value, setValue] = useState(medicine_value);
  const [inputValue, setInputValue] = useState('');
  const classes = useStyles();



  useEffect(()=>{
      if(!reset){
          return
      }

      setValue('')
      setInputValue('')
  },[reset])


  return (

    <div>

        <Autocomplete
          fullWidth
          id={id}
          value={value || null}
          inputValue={inputValue}
          onChange={(e, newValue) => {
              if (newValue === null) {
                setValue('');
              } else {
                setValue(newValue);
              }
              if (!handleProductData) {
                handleChange(newValue);
              } else {
                let obj = {
                    ...newValue,
                  innerText: !newValue ? '' : newValue.label ? newValue.label : label_text ? newValue[`${label_text}`] : "",
                  value: !newValue ? '' : newValue.value ? newValue.value : value_text ? newValue[`${value_text}`] : ""
                }
                if (isExpiryLimit === true) {
                  obj = { 
                    ...obj, 
                    'expiry_limit' : !newValue ? '' : newValue.expiry_limit ?  newValue.expiry_limit : ''
                  }
                };
                if(idx !== null && (sub_idx === null || sub_idx === undefined))  {
                  handleProductData(obj, idx, item);
               } else if(idx !== null && sub_idx !== null)  {
                  handleProductData(obj, idx, sub_idx);
                } else {
                handleProductData();
               }
              }
          }}

          getOptionSelected={(option, value) => {
              if (!value) { return true; }
              const get_value = value_text === '' ? value.value : value[`${value_text}`];
              return value_text === '' ? (option.value === get_value) : (option[`${value_text}`] === get_value)
          }} 

          getOptionLabel={(option) => {
            if (typeof(option) === 'string') {
              // not a dictionary
              return option;
            }
              return option ? option.label ? 
                option.label : label_text ?
                option[`${label_text}`] !== null ?
                option[`${label_text}`]  : "" : "" : ""; 
          }}

          onInputChange={(e, newInputValue, reason) => {
              setInputValue(newInputValue);
              if (e.type === 'change'){
                  if (idx !== null) {
                      handleChange(newInputValue,idx, item, (newInputValue === ''));
                  } else {
                      handleChange(newInputValue);
                  }
              }

          }} 
          
          options={options}
          renderOption={renderOption}
          classes={{
            option: classes.option,
            paper: classes.paper,
           // popper: classes.popper
          }}

          PopperComponent={PopperMy}

          renderInput={(params) => 
          <AutoCompleteTextField  {...params} variant="outlined" name={name} data-testid={testId}
            color={(medicine_value === null && !submitted) ? 'default' : 
            (submitted && (inValidateValue(medicine_value, value, value_text))) ? 'error' : "default"} 
            {...rest}
            placeholder={placeholder}/>}

          /> 
        {(medicine_value === null && !submitted) ? null : 
          (submitted && (inValidateValue(medicine_value, value, value_text)))  ?
          <ErrorMessage>{errorMsg}</ErrorMessage>
        : null}
        {valid_error_check ?
          <ErrorMessage>{errorMsg_Valid}</ErrorMessage>
          : null
        }
    </div>
  );
}

// uncontrolled  -> the options, the change everytime, so they can be undefined or not
// also the component is handled by the DOM, not react, hence the inputvalue element
// THIS IS FOR MEDICINE TO CATER FOR GENERIC NAMES
export const AutoCompleteProducts = ({ options, idx,reset, handleChange, handleProductData, submitted, placeholder="", name="", sub_idx,
  medicine_value={}, id="", errorMsg="", valid_error_check, errorMsg_Valid, testId="", ...rest}) => {
  
  const [value, setValue] = useState(medicine_value);
  const [inputValue, setInputValue] = useState('');
  const classes = useStyles();

    useEffect(()=>{
        if(!reset){
            return
        }
        setValue('')
        setInputValue('')
    },[reset])

  return (
    <div>

        <Autocomplete
          fullWidth
          id={id}
          data-testid={testId}
          value={value || null}
          inputValue={inputValue}

          onChange={(e, newValue) => {
              if (newValue === null) {
                setValue('');
              } else {
                setValue(newValue);
              }
              if (!handleProductData) {
                handleChange(newValue);
              } else {
                if(idx !== null && sub_idx === null)  {
                  handleProductData({
                    innerText: !newValue ? '' : newValue.label ? newValue.label : "",
                    value: !newValue ? '' : newValue.value ? newValue.value : "",
                    generic_name: !newValue ? '' : newValue.generic_name ? newValue.generic_name : "",
                    ...newValue
                  }, idx);
               } else if(idx !== null && sub_idx !== null)  {
                  handleProductData({
                    innerText: !newValue ? '' : newValue.label ? newValue.label : "",
                    value: !newValue ? '' : newValue.value ? newValue.value : "",
                    generic_name: !newValue ? '' : newValue.generic_name ? newValue.generic_name : "",
                    ...newValue
                  }, idx, sub_idx);
                } else {
                handleProductData({
                  innerText: !newValue ? '' : newValue.label ? newValue.label : "",
                  value: !newValue ? '' : newValue.value ? newValue.value : "",
                  generic_name: !newValue ? '' : newValue.generic_name ? newValue.generic_name : "",
                    ...newValue
                });
               }
              }
          }}

          filterOptions = {(options, state) => {
            // commented this out because it was not showing all the values the api returns

            //const state_value = state.inputValue;
            //let newOptions = [];
                       
            /*options.forEach((element) => {
              const get_option_value =  element.label ?? "";
              const isValuesCorrect = get_option_value?.toLowerCase().includes(state_value?.toLowerCase())
              // || get_option_value.includes(state_value);

              const hasGenericName = element['generic_name'] ?? "";
              const isGenericOption = hasGenericName?.toLowerCase().includes(state_value?.toLowerCase())
              // || hasGenericName?.includes(state_value);

              if (isValuesCorrect || isGenericOption) {
                newOptions.push(element);
              }
                
            }); */

            return options;
          }} 

          getOptionSelected={(option, value) => {
              return value.value  ===  option.value; 
          }} 

          getOptionLabel={(option) => {
            if (typeof(option) === 'string') {
              // not a dictionary
              return option;
            }
            return option ? option.label ? option.label : "" : ""; 
          }}

          onInputChange={(e, newInputValue) => {
            setInputValue(newInputValue);

              if (e?.type && e?.type === 'change'){
                  if (idx !== null) {
                      handleChange(newInputValue, idx, (newInputValue === ''));
                  } else {
                      handleChange(newInputValue);
                  }
              }
          }} 
          
          options={options}
          classes={{
            option: classes.option,
            paper: classes.paper,
           // popper: classes.popper
          }}

         PopperComponent={PopperMy}

          renderInput={(params) => {
              return (
                  <AutoCompleteTextField  {...params} variant="outlined" name={name}
                      // data-testid={testId}
                      color={(medicine_value === null && !submitted) ? 'default' :
                      (submitted && (inValidateValue(medicine_value, value, ''))) ? 'error' : "default"}
                      {...rest}
                      placeholder={placeholder}/>
              )

         }}
          
          /> 

        {(medicine_value === null && !submitted) ? null : 
          (submitted && (inValidateValue(medicine_value, value, '')))  ?
          <ErrorMessage>{errorMsg}</ErrorMessage>
        : null}
        {valid_error_check ?
          <ErrorMessage>{errorMsg_Valid}</ErrorMessage>
          : null
        }
    </div>
  );
}
 
// controlled -> the options are set and they can't change randomly
export const AutoCompleteControlled = ({ options, idx,reset, handleChange, handleProductData, testId, submitted,isDisabled, placeholder="", name="",
  medicine_value={}, label_text="", value_text="", id="", errorMsg=""}) => {

  const [value, setValue] = useState('');
  const classes = useStyles();

  useEffect(()=>{
      if(!reset){
          return
      }
      setValue('')
  },[reset])

    React.useEffect(()=>{
        if(!medicine_value){
            return
        }
        setValue(medicine_value)
    }, [medicine_value])

  return (
    <div>
        <Autocomplete
        fullWidth
          id={id}
          data-testid={testId}
        disabled={isDisabled}
          value={ value ? value : medicine_value ? medicine_value : null }
          onChange={(event, newValue) => {
              if (newValue === null) {
                setValue('');
              } else {
                setValue(newValue);
              }
              if (!handleProductData) {
                handleChange(newValue, id);
              } else {
                if(idx !== null)  {
                  handleProductData({
                    innerText: !newValue ? '' : newValue.label ? newValue.label : label_text ? newValue[`${label_text}`] : "",
                    value: !newValue ? '' : newValue.value ? newValue.value : value_text ? newValue[`${value_text}`] : "",
                }, idx);
               } else {
                handleProductData({
                  innerText: !newValue ? '' : newValue.label ? newValue.label : label_text ? newValue[`${label_text}`] : "",
                  value: !newValue ? '' : newValue.value ? newValue.value : value_text ? newValue[`${value_text}`] : "",
              });
               }
              }
          }}

          getOptionSelected={(option, value) => {
            return value_text === '' ? (option.value === value.value) : (option[`${value_text}`] === value[`${value_text}`])}} 

          getOptionLabel={(option) => {
            if (!option) {return "";}
            if (typeof(option) === 'string') {
              return option;
            }
            if (label_text) {
              if (option[`${label_text}`]) {
                return option[`${label_text}`];
              } else {
                return "";
              }
            }
            return option ? option.label ? option.label : "" : "";
          }}


          options={options}
          classes={{
            option: classes.option,
            paper: classes.paper,
          //  popper: classes.popper
          }}

          PopperComponent={PopperMy}

          renderInput={(params) => 
          <AutoCompleteTextField  {...params} variant="outlined" name={name} 
            color={(medicine_value === null && !submitted) ? 'default' : 
            (submitted && (inValidateValue(medicine_value, value, value_text))) ? 'error' : "default"} 
            placeholder={placeholder}/>}
          /> 

         {(medicine_value === null && !submitted) ? null : 
          (submitted && (inValidateValue(medicine_value, value, value_text)))  ?
          <ErrorMessage>{errorMsg}</ErrorMessage>
        : null}
    </div>
  );
}

export const SelectItem = ({ value, options, idx, onChange, submitted, id="", errorMsg="", name="", label_text="", value_text="", defaultOption="",placeholder=""}) => {
  const classes = useStyles();

  return (
    <div>
        <Select
          id={id}
          displayEmpty
          value={typeof value === 'string' ? value :  value.value ? value.value : value_text ? value[`${value_text}`] : ""}
          onChange={(idx !== null) ? (e) => onChange(e, idx) : (e) => onChange(e)}
          input={
            <SelectInput name={name} 
            color={(!submitted && value === '' && !defaultOption) ? 'placeholder' :
            (submitted && value === '') ? 'error' : "default"} />}
          MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
            },
            classes:{
              paper: classes.selectMenu,
            }
          }}
        >
          {placeholder !==  '' ?
            <StyledMenuItem disabled value="">
              {placeholder}
            </StyledMenuItem>
            : null
          }
          {defaultOption !==  '' ?
            <StyledMenuItem value="">
              {defaultOption}
            </StyledMenuItem>
            : null
          }
           {options.length > 0 ? 
                options.map((item, index) => (
                    <StyledMenuItem key={index} 
                        value={item.value ? `${item.value}` : value_text ? item[`${value_text}`] : item}
                        data-key={item.value ? `${item.value}` : value_text ? item[`${value_text}`] : item}>
                          {item.text ? item.text : label_text ? item[`${label_text}`] : item}
                    </StyledMenuItem>
            )) :
            <StyledMenuItem value={null}>None...</StyledMenuItem>
            }
        </Select>
         {submitted && value === '' ?
            <ErrorMessage>{errorMsg}</ErrorMessage>
          : null}
    </div>
  );
}