import React, { useEffect, useState } from "react";
import PageTitle from "../../Utils/smsTitle";
import { SubHeader } from "../../../Containers/SubHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyBill } from "@fortawesome/free-solid-svg-icons/faMoneyBill";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as snackbarActions from "../../../actions/snackbarActions";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import { ReusableNewCOA } from "./NewCOAccount";
import { Container, RightAlignedContainer } from "../../Utils/styledComponents";
import { NoTransaction } from "./ReusableJournalTable";
import COATable from "./COATable";
import { usePrint } from "../../Utils/Templates/usePrint";
import { LoadingGif } from "../../Utils/Loader";
import axios from "axios";
import { config } from "../../../Helpers/env";
import { errorMessages } from "../../../Helpers/ErrorMessages";
import { ReusableRouteTabs } from "../../Utils/Dialogs/ReusableTabs";
import { history } from "../../../Helpers/history";

const ChartOfAccounts = ({ actions, snackbar }) => {
  const [allAccounts, setAllAccounts] = useState([]);
  const [loading, setLoading] = useState("idle");
  const [isSubmitted, setIsSubmitted] = useState("idle");
  const [openDialog, setOpenDialog] = useState(false);

  const isResolved = isSubmitted === "resolved";

  const formatData = (data = {}) => {
    let arr = [];
    for (let item in data) {
      const obj = {
        head_name: item,
        head_level: -1,
        children: data[item],
      };
      arr.push(obj);
    }
    return arr;
  };

  useEffect(() => {
    setLoading("loading");
    axios
      .get(`${config.smsUrl}/accounting/chart/accounts`)
      .then((res) => {
        const data = res.data;
        const dt = data ? data : {};
        const arr = dt.data ? dt.data : {};
        const dataArr = formatData(arr);
        setAllAccounts(dataArr);
        setLoading("success");
      })
      .catch((err) => {
        errorMessages(err, null, actions);
        setLoading("error");
      });
  }, [isResolved]);

  const closeSnackbar = () => {
    actions.snackbarActions.hideSnackbar();
  };

  const isLoading = loading === "loading";
  const isSuccess = loading === "success";
  const isError = loading === "error";

  const { openBar, type, message } = snackbar;
  const perm = JSON.parse(sessionStorage.getItem("permission"));
  const permission = !perm ? {} : perm;
  const chart_of_accounts = !permission.chart_of_accounts
    ? {}
    : permission.chart_of_accounts;

  const { componentRef } = usePrint("ChartOfAccounts");
  const components = [
    { label: "Account List", path: "/accounting/accounts/list" },
    { label: "Chart of Accounts", path: "/accounting/coa" },
  ];

  return (
    <div className="journals">
      <PageTitle title="Chart of Accounts" />
      <SubHeader title="Accounting" subTitle="Chart of Accounts">
        <FontAwesomeIcon icon={faMoneyBill} />
      </SubHeader>
      <MainSnackbar
        message={message}
        open={openBar}
        handleCloseBar={closeSnackbar}
        variant={type}
      />
      <ReusableNewCOA
        {...{ actions, isSubmitted, openDialog, setIsSubmitted, setOpenDialog }}
      />
      <Container>
        <ReusableRouteTabs
          value={history.location.pathname}
          tabHeading={components}
        />
        <RightAlignedContainer>
          {chart_of_accounts.create ? (
            <div className="text-right">
              <button
                type="button"
                className="btn btn-sm sms-info-btn mb-2"
                data-testid="new-account-coa"
                onClick={() => setOpenDialog(true)}
              >
                New Account
              </button>
            </div>
          ) : null}
        </RightAlignedContainer>
        <div ref={componentRef}>
          {isLoading ? (
            <div className="text-center mt-5">
              <LoadingGif />
            </div>
          ) : null}
          {isSuccess ? (
            allAccounts.length > 0 ? (
              <COATable {...{ data: allAccounts }} />
            ) : (
              <NoTransaction text="accounts" />
            )
          ) : null}
          {isError ? (
            <div className="mt-3 text-center">
              The server did not return a valid response
            </div>
          ) : null}
        </div>
      </Container>
    </div>
  );
};

function mapStateToProps(state) {
  const { snackbar } = state;
  return { snackbar };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: { snackbarActions: bindActionCreators(snackbarActions, dispatch) },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChartOfAccounts);
