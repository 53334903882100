export function removeTrailingZeros(number) {
    // Convert number to string
    let strNum = number.toString();

    // Split the number into integer and decimal parts
    let [integerPart, decimalPart] = strNum.split('.');

    // Remove trailing zeros from decimal part
    if (decimalPart) {
        decimalPart = decimalPart.replace(/0+$/, '');
    }

    // Join integer and decimal parts with decimal point
    let result = integerPart;
    if (decimalPart) {
        result += '.' + decimalPart;
    }
    return parseFloat(result);
}
