import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../actions/snackbarActions"
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import MainRadiologyTable from "./MainRadiologyTable";
import PageTitle from "../Utils/smsTitle";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXRay} from "@fortawesome/free-solid-svg-icons/faXRay";
import {ReusableRouteTabs} from "../Utils/Dialogs/ReusableTabs";
import {history} from "../../Helpers/history";
import { useScanRequests } from "./CustomHooks/useScanRequests";
import {formatDateTime} from "../Utils/ConvertDate";
import {Link} from "react-router-dom";
import {faEye} from "@fortawesome/free-regular-svg-icons/faEye";
import {faEdit} from "@fortawesome/free-regular-svg-icons/faEdit";


export const radiologistList = [
    {id: 'count', numeric: false, disablePadding: false, label: '#', hidden:false},
    {id: 'date', numeric: false, disablePadding: false, label: 'Date', hidden:false},
    {id: 'patient_name', numeric: false, disablePadding: false, label: 'Patient Name', hidden:false},
    {id: 'phone_no', numeric: false, disablePadding: false, label: 'Phone', hidden:false},
    {id: 'kin_phone_no', numeric: false, disablePadding: false, label: 'NOK Phone', hidden:false},
    {id: 'hospital_scan_name', numeric: false, disablePadding: false, label: 'Scan Name', hidden:false},
    {id: 'doctor_name', numeric: false, disablePadding: false, label: 'Doctor', hidden:false},
    {id: 'radiologist_name', numeric: false, disablePadding: false, label: 'Radiologist', hidden:false},
    {id: 'action', numeric: false, disablePadding: false, label: 'Action', hidden:false}
];

const ScanResultsList =({actions, snackbars}) =>  {
    const {activePage, term, scanRequests, total_count,handleChangeActivePage,
        handler, closeSnackbar, isLoading, isSuccess, isRejected} = useScanRequests(actions,'retrieve_all_scan_results')
    const requests = !scanRequests.scan_result_list ? [] :scanRequests.scan_result_list;
    const all_scan_requests = requests.map((request, index)=>{
        const count = ((activePage - 1) * 10) + index + 1;
        return{
            ...request,
            count,
            date:request.date_created ? formatDateTime(request.date_created) : "",
            patient_name:`${request.patient_firstname} ${request.patient_lastname}`,
            doctor_name:`${request.doctor_firstname} ${request.doctor_lastname}`,
            action:(
                <>
                    <Link to={{pathname: `/viewresults/${request.visit_id}/${request.patient_number}/${request.scan_requests_detail_id}/${request.scan_result_id}/${request.patient_type}`}}><span
                        className="mr-2 mb-2 icon-button d-inline-block">
                        <FontAwesomeIcon icon={faEye}/></span></Link>
                    <Link to={{pathname: `/editscanresults/${request.visit_id}/${request.patient_number}/${request.scan_requests_detail_id}/${request.scan_result_id}/${request.patient_type}`}}><span
                        className="icon-button mr-2 mb-2 d-inline-block"><FontAwesomeIcon
                        icon={faEdit}/></span></Link>
                </>
            )
        }
    })

    const {openBar, type, message} = snackbars;
    const components = [{label: 'Requests', path: '/scanRadiology'}, {label: 'Results', path: "/scanresults"}];
    return (
        <div className='journals'>
            <PageTitle title='Scan Results' />
            <SubHeader title="Radiology" subTitle='Scan Results'>
                <FontAwesomeIcon icon={faXRay}/>
            </SubHeader>
            <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/>
            <MainSnackbar message={message} variant={type} handleCloseBar={closeSnackbar} open={openBar}/>
            <MainRadiologyTable title="Scan Results" onClick={() => {
            }} {...{activePage,total_count,all_scan_requests,title:'Requests',
            handleChangeActivePage, term, radiologistList,handler,isLoading, isSuccess, isRejected}}/>
        </div>
    );
    
}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbar
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScanResultsList);


