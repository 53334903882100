import React, {useEffect, useState} from 'react';
import ReceiptTemplate from '../Utils/ReceiptTemplate.js';
import CustomDialog from "../Utils/Dialogs/CustomDialog";
import {usePrint} from "../Utils/Templates/usePrint";
import axios from "axios";
import {config} from "../../Helpers/env";
import {errorMessages} from "../../Helpers/ErrorMessages";

const GetTriageDetails = (triage) => {

    const get_metrics = triage ? triage : [];

    if (get_metrics.length > 0) {

        const metrics_titles = get_metrics[0].data.map((item) => {
            const new_dict = {
                metric_id: item.metric_id,
                metric_name: item.metric_name,
                metric_type: item.metric_type,
                uom: item.uom
            };
            return new_dict;
        })

        const percentage_metrics = parseInt(80 / metrics_titles.length);
        return {metrics_titles, percentage_metrics};
    } else {
        return {metrics_titles: [], percentage_metrics: 0}
    }
}

export const PrintSurgeryReport = ({actions, surgeryItem, openDialog, setOpenDialog}) => {
    const [surgeryDetails, setSurgeryDetails] = useState({})

    useEffect(() => {
        if (!surgeryItem) {
            return
        }
        const doctor_view = true;
        const formD = new FormData();
        formD.append("surgery_id", surgeryItem.id);
        formD.append("visit_id", surgeryItem.visit_id);
        formD.append("doctor_view", doctor_view.toString());

        const formData = new FormData();
        formData.append('patient_number', surgeryItem.patient_number);

        const formData3 = new FormData();
        formData3.append('visit_id', surgeryItem.visit_id)

        const req1 = axios.post(`${config.smsUrl}/surgery/view_surgery_details`, formD);
        const req2 = axios.post(`${config.smsUrl}/cpatient/patient_update_form`, formData);
        const req3 = axios.post(`${config.smsUrl}/surgery/view_surgery_materials`, formData3);

        Promise.all([req1, req2, req3]).then(([res1, res2, res3]) => {

            const resp = res1.data;
            const data2 = res2.data;
            const data3 = res3.data;
            const details2 = !data2 ? {} : data2;

            const response = resp ? resp : {};
            const diagnosis = response.diagnosis ? response.diagnosis : [];
            const triage = response.triage ? response.triage : [];

            const surgery_data = response.surgery_data ? response.surgery_data : {};

            const materials = data3 ?? {};

            setSurgeryDetails({...surgery_data, ...details2, ...materials, diagnosis, triage, 'screen': 'preview'})
        }).catch(errs => {
            errorMessages(errs, null, actions);
        })

    }, [surgeryItem])

    const handleOnAfterPrint = () => {
        setOpenDialog(false)
    }


    const {
        Age,
        gender,
        operation_date,
        patient_name,
        actual_start_time,
        actual_end_time,
        patient_number,
        address,
        phone_no,
        patient_no,
        operation_class,
        lead_anaestheist,
        lead_surgeon,
        scrub_nurse,
        circulating_nurse,
        triage,
        diagnosis,
        surgery_name,
        anaesthesia_type,
        anaesthesiologist,
        preoperative_diagnosis,
        preoperative_instructions,
        postoperative_instructions,
        summary_report,
        other_anaestheists,
        other_sugeons,
        materials_used
    } = surgeryDetails;

    const {metrics_titles, percentage_metrics} = GetTriageDetails(triage,handleOnAfterPrint);

    const patientDetails = (
        <div>
            <tbody>
            <tr>
                <td style={{width: '40%'}}><p className='surgery-report-text'><strong>Patient
                    Number: </strong>{patient_number ? patient_number : patient_no ? patient_no : ''}</p></td>
                <td style={{width: '60%'}}><p className='surgery-report-text'><strong>Patient
                    Name: </strong>{patient_name}</p></td>
            </tr>
            <tr>
                <td style={{width: '40%'}}><p className='surgery-report-text'><strong>Gender: </strong>{gender}</p></td>
                <td style={{width: '60%'}}><p className="suregery-report-text"><strong>Address: </strong>{address}</p>
                </td>
            </tr>
            <tr>
                <td style={{width: '40%'}}><p className='surgery-report-text'><strong>Age: </strong>{Age}</p></td>
                <td style={{width: '60%'}}><p className='surgery-report-text'><strong>Phone: </strong>{phone_no}</p>
                </td>
            </tr>
            </tbody>
        </div>
    )

    const operationDetails = (
        <div>
            <tbody>
            <tr>
                <td style={{width: '30%'}}><p className='surgery-report-text'><strong>Operation
                    Date: </strong>{operation_date}</p></td>
                <td style={{width: '30%'}}><p className='surgery-report-text'><strong>Start
                    Time: </strong>{actual_start_time}</p></td>
                <td style={{width: '30%'}}><p className='surgery-report-text'><strong>Type of
                    Surgery: </strong>{surgery_name}</p></td>
            </tr>
            <tr>
                <td style={{width: '30%'}}><p className='surgery-report-text'><strong>Operation
                    Class: </strong>{operation_class}</p></td>
                <td style={{width: '30%'}}><p className='surgery-report-text'><strong>End
                    Time: </strong>{actual_end_time}</p></td>
                <td style={{width: '30%'}}><p className='surgery-report-text'><strong>Anaesthesia
                    Type: </strong>{anaesthesia_type}</p></td>
            </tr>
            <tr>
                <td style={{width: '30%'}}><p className='surgery-report-text'><strong>Lead
                    Surgeon: </strong>{lead_surgeon}</p></td>
                <td style={{width: '30%'}}><p className='surgery-report-text'><strong>Lead
                    Anaesthesiologist: </strong>{lead_anaestheist}</p></td>
                <td style={{width: '30%'}}><p className='surgery-report-text'>
                    <strong>Anaesthesiologist: </strong>{anaesthesiologist}</p></td>
            </tr>
            <tr>
                <td style={{width: '30%'}}><p className='surgery-report-text'><strong>Scrub
                    Nurse: </strong>{scrub_nurse}</p></td>
                <td style={{width: '30%'}}><p className='surgery-report-text'><strong>Circulating
                    Nurse: </strong>{circulating_nurse}</p></td>
            </tr>
            </tbody>
            <tbody>
            <tr>
                <td colSpan={3}><p className='surgery-report-text'><strong>Other Surgeons: </strong>{other_sugeons}</p>
                </td>
            </tr>
            <tr>
                <td colSpan={3}><p className='surgery-report-text'><strong>Other
                    Anaestheists: </strong>{other_anaestheists}</p></td>
            </tr>
            </tbody>
        </div>
    )

    const getMetricValues = (data_arr, value) => {
        const dets = data_arr.find(item => item.metric_name === value);
        return (
            <td key={dets.patientmsr_id} style={{textAlign: 'center', width: `${percentage_metrics}%`}}>
                <p>
                    {dets.metric_value}
                </p>
            </td>
        )
    }

    const doctorsDetails = (
        preoperative_diagnosis || preoperative_instructions || postoperative_instructions || summary_report ?
            <div>
                <h6 className=' surgery-print-titles'>DOCTOR'S NOTES</h6>
                {preoperative_diagnosis &&
                    <div>
                        <p className='surgery-report-text'><strong>Preoperative Diagnosis</strong></p>
                        <p className='surgery-report-text'>{preoperative_diagnosis}</p>
                    </div>
                }

                {preoperative_instructions &&
                    <div>
                        <p className='surgery-report-text'><strong>Preoperative Instruction(s)</strong></p>
                        <p className='surgery-report-text'>{preoperative_instructions}</p>
                    </div>
                }

                {postoperative_instructions &&
                    <div>
                        <p className='surgery-report-text'><strong>PostOperative Instruction(s)</strong></p>
                        <p className='surgery-report-text'>{postoperative_instructions}</p>
                    </div>
                }

                {summary_report &&
                    <div>
                        <p className='surgery-report-text'><strong>Summary Report</strong></p>
                        <p className='surgery-report-text'>{summary_report}</p>
                    </div>
                }
            </div>
            : null
    )

    const {componentRef, handlePrint} = usePrint(`SurgeryReport`);

    return (
        <>
            <CustomDialog title={'Preview Surgery Report'} open={openDialog} handleClose={() => setOpenDialog(false)}
                          maxWidth="md"
                          isPrint={true} printSurgery={handlePrint} isContentOverflow={false}>
                <div className="surgery-report" ref={componentRef}>
                    <ReceiptTemplate>
                        <h6 className=' surgery-print-titles'>PERSONAL DETAILS</h6>
                        {patientDetails}

                        <h6 className=' surgery-print-titles'>OPERATION DETAILS</h6>
                        {operationDetails}

                        {doctorsDetails}

                        {triage && triage.length > 0 ?
                            <div>
                                <h6 className=' surgery-print-titles'>TRIAGE DETAILS</h6>
                                <table className="table table-bordered profile-table mt-1">
                                    <thead>
                                    <tr>
                                        <td rowSpan={2} style={{textAlign: 'center'}}><strong>Date</strong></td>
                                        <td colSpan={metrics_titles.length} style={{textAlign: 'center'}}>
                                            <strong>Metrics</strong></td>
                                    </tr>
                                    <tr>
                                        {metrics_titles.map((it) => (
                                            <td key={it.metric_id} style={{textAlign: 'center'}}>
                                                <p><strong>{`${it.metric_name} ${it.uom ? `(${it.uom})` : ''}`}</strong>
                                                </p>
                                            </td>
                                        ))}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {triage.map((element, index) => (
                                        <tr key={index}>
                                            <td style={{width: '20%'}}>
                                                <p>
                                                    {element.metric_date}
                                                </p>
                                            </td>
                                            {metrics_titles.map((it, idx) => (
                                                getMetricValues(element.data, it.metric_name)
                                            ))}

                                        </tr>

                                    ))}
                                    </tbody>
                                </table>
                            </div> :
                            null}

                        {diagnosis && diagnosis.length > 0 &&
                            <div>
                                <h6 className=' surgery-print-titles'>DIAGNOSIS </h6>
                                <table className="table table-bordered profile-table mt-1">
                                    <tr>
                                        <td><strong><p>Disease</p></strong></td>
                                        <td><strong><p>ICD code</p></strong></td>
                                    </tr>
                                    {diagnosis.filter(item => !(item.diagnosis === null && item.diagnosis_code === null)).map(item => (
                                        <tr key={item.diagnosis_id}>
                                            <td><p>{item.diagnosis}</p></td>
                                            <td><p>{item.diagnosis_code}</p></td>
                                        </tr>
                                    ))}
                                </table>
                            </div>
                        }

                        {materials_used && materials_used.length > 0 ?
                            <div>
                                <h6 className=' surgery-print-titles'>SURGERY EQUIPMENT USED </h6>
                                <table
                                    className="table table-bordered  prescription-table profile-table bill-receipt-table">
                                    <thead>
                                    <tr>
                                        <td><strong><p>Drug</p></strong></td>
                                        <td><strong><p>Dosage</p></strong></td>
                                        <td><strong><p>Quantity</p></strong></td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {materials_used.filter(item => item.patient_medication_detail_id).map((item,) => (
                                        <tr key={item.patient_medication_detail_id}>
                                            <td>
                                                <p>{item.product_name}</p>
                                            </td>
                                            <td>
                                                <p>{item.dosage}</p>
                                            </td>
                                            <td>
                                                <p>{item.quantity}</p>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                            : null}
                    </ReceiptTemplate>
                </div>

            </CustomDialog>

        </>
    )
}

