import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Label from "../Utils/FormInputs/Label";
import {CustomSelect} from "../Utils/FormInputs/SelectInput";
import { ErrorMessage } from "../Utils/styledComponents";
import {CustomCheckInput, CustomInput, CustomResizableTextarea} from "../Inventory/CustomInput";


export function AddBedForm({state, submitted, isPending, handleSubmit, ward,
                               handleChangeWard, wards, room, handleChangeRoom, rooms,isResolved}) {


    const {bed_number, description, status} = state;

    return (
            <form autoComplete='off' onSubmit={handleSubmit}>
                <Form.Group as={Row}>
                    <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                        <Label name="Ward" type/>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                        <CustomSelect id="bed-ward" submitted={submitted} options={wards} onChange={handleChangeWard} value={ward} dataTest="ward"/>
                        {(submitted && !ward) &&
                            <ErrorMessage>Ward is required</ErrorMessage>}
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                        <Label  name="Room Name" type/>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                        <CustomSelect id="bed-room" submitted={submitted} options={rooms} onChange={handleChangeRoom} value={room} dataTest="room"/>
                        {(submitted && !room) &&
                            <ErrorMessage>Room Name is required</ErrorMessage>}
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                        <Label  name="Bed Number" type htmlFor="bed_number"/>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                        <CustomInput name='bed_number' inputValue={bed_number} submitted={submitted} isRequired type='text' reset={isResolved}/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                        <Label  name="Description" htmlFor="description"/>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                        <CustomResizableTextarea name='description' rows={2} reset={isResolved} inputValue={description} />

                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                        <Label  name="Status" type/>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                        <CustomCheckInput inputValue={status} isRequired name='status' type='radio' inline label='Active' value="1" id='1'/>
                        <CustomCheckInput inputValue={status} isRequired name='status' type='radio' inline label='Inactive' value="0" id='1'/>
                    </Col>
                </Form.Group>
                <button type="submit" data-testid="add-bed" className="btn btn-sm sms-btn px-4"
                        disabled={isPending}>
                    {isPending ? "Saving..." : "Save"}</button>
            </form>
       
    )
};


export default AddBedForm;
