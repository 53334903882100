import {useEffect, useState} from "react";
import { convertPhone } from "../../Utils/convertPhone";
import axios from "axios";
import { config } from "../../../Helpers/env";
import { errorMessages, logoutErrorMessage } from "../../../Helpers/ErrorMessages";


export const useAddEmployee = (actions) => {

    const [state, setState] = useState({
        employee_id: "", first_name: "",
        last_name: "", email: "", gender: "", address: "", mobile_no: "", kin_first_name: "",
        kin_telephone: "", kin_relationship: "", kin_last_name: "", kin_address: "", biography: "",
        specialisation: "", education: "",  status: "1", nin: "", dob: ""
    });
    const [employeeContact, setEmployeeContact] = useState()
    const [kinContact, setKinContact] = useState('')
    const [department_id, setDepartment] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [image, setImage] = useState(null);
    const [ninImage, setNinImage] = useState(null);
    const [blood_group,setBloodGroup] = useState('')
    const [openDialog, setOpenDialog] = useState(false)
    const [employee_id, setEmployeeId] = useState('')
    const [employee, setEmployee] = useState({});
    const [openEdit, setOpenEdit] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState('idle')
    const [departs, setDeparts] = useState([])


    useEffect(() => {
        if(!employee){
            return
        }
        setState({
            employee_id: employee.employee_id ? employee.employee_id : '',
            first_name: employee.first_name ? employee.first_name : '',
            last_name: employee.last_name ? employee.last_name : '',
            email: employee.email ? employee.email : '',
            gender: employee.gender ? employee.gender : '',
            address: employee.address ? employee.address : '',
            mobile_no: employee.phone_no ? employee.phone_no : '',
            biography: employee.biography ? employee.biography : '',
            specialisation: employee.specialisation ? employee.specialisation : '',
            education: employee.education ? employee.education : '',
            status: employee.status ? employee.status : '',
            dob: employee.dob ? employee.dob : '',
            nin: employee.id_nin ? employee.id_nin : '',
            kin_first_name: employee.kin_firstname ? employee.kin_firstname : '',
            kin_telephone: employee.kin_contact ? employee.kin_contact : '',
            kin_relationship: employee.relationship ? employee.relationship : '',
            kin_last_name: employee.kin_lastname ? employee.kin_lastname : '',
            kin_address: employee.kin_address ? employee.kin_address : '',
        });
        setEmployeeContact(employee.phone_no ? employee.phone_no : '')
        setKinContact(employee.kin_contact ? employee.kin_contact : '')
        setDepartment({value: employee.department_id, label: employee.department_name})
        setBloodGroup({
            value: employee.blood_type ? employee.blood_type : '',
            label: employee.blood_type ? employee.blood_type : ''
        })
    }, [employee]);


    useEffect(() => {
        axios.get(`${config.smsUrl}/cvisit/retrieve_department`).then(response => {
            const data = response.data;
            const dt = !data ? {} : data;
            const departs = !dt.departments ? [] : dt.departments;
            setDeparts(departs)
        }).catch(error => {
            errorMessages(error, actions, null)
        });
    }, []);



    
    const handleChangeDepartment = (value) => {
        setDepartment(value)
    };

    const handleChange = (event) => {
        const {name, value} = event.target;
        setState({...state, [name]: value})
    };

    const handleChangePicture = (event) => {
        setImage(event.target.files[0])
    };

    const handleChangeNinPicture = (event) => {
        setNinImage(event.target.files[0])
    };

    const handleChangeBloodGroup = (value) =>{
        setBloodGroup(value)
    }

    const handleSubmitForm = (event) => {
        event.preventDefault();
        setSubmitted(true);
        const formData = new FormData(event.currentTarget);
        const fieldValues = Object.fromEntries(formData.entries())
        if(employee_id){
            formData.append("employee_id", employee_id);
        }
        formData.append("phone_no", employeeContact ?  convertPhone(employeeContact):'');
        formData.append("blood_type", blood_group?.value ? blood_group?.value:'');
        formData.append("department_id", department_id?.value ? department_id?.value:'');
        formData.append("national_id_pic", ninImage);
        formData.append('image_path', image);
        formData.append("phone_no1", kinContact ? convertPhone(kinContact):'');
        if (department_id  && fieldValues['first_name'] && fieldValues['last_name'] && employeeContact &&
            fieldValues['gender'] && fieldValues['email'] && fieldValues['address'] && department_id?.value) {
            setIsSubmitted('pending')
            const url = employee_id ? 'edit_employee' : 'add_employee'
            axios.post(`${config.smsUrl}/chumanresource/${url}`, formData).then(() => {
                actions.snackbarActions.snackSuccess('Employee added successfully');
                setSubmitted(false)
                setIsSubmitted('resolved')
                setOpenDialog(false)
                setOpenEdit(false)
                setEmployeeId('')
                setState({
                    employee_id: "", first_name: "",
                    last_name: "", email: "", gender: "", address: "", mobile_no: "", kin_first_name: "",
                    kin_telephone: "", kin_relationship: "", kin_last_name: "", kin_address: "", biography: "",
                    specialist: "", education: "", blood_group: "", status: "1", nin: "", dob: ""
                });
                setNinImage(null);
                setImage(null);
                setSubmitted(false);
                setBloodGroup(null)
            }).catch(err => {
                errorMessages(err, null, actions, )
                setIsSubmitted('rejected')
            })
        }
    };

    const handleOpenDialog = () => {
        setOpenDialog(true)
    }

    const handleCloseDialog = () => {
        setOpenDialog(false)
    }

    const handleOpenEditDialog =(id)=>{
        setOpenEdit(true)
        setEmployeeId(id)
        const formData = new FormData();
        formData.append('employee_id', id);
        axios.post(`${config.smsUrl}/chumanresource/employee_update_form`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            setEmployee(dt)
        }).catch(error => {
            logoutErrorMessage(error, null, actions)
        })
    }

    const handleCloseEditDialog =() => {
        setOpenEdit(false)
        setState({
            employee_id: "", first_name: "",
            last_name: "", email: "", gender: "", address: "", mobile_no: "", kin_first_name: "",
            kin_telephone: "", kin_relationship: "", kin_last_name: "", kin_address: "", biography: "",
            specialisation: "", education: "", blood_group: "", status: "1", nin: "", dob: ""
        });
        setNinImage(null);
        setImage(null);
        setSubmitted(false);
        setBloodGroup(null)
        setDepartment(null)
    }

    const isResolved = isSubmitted === 'resolved'
    const isPending = isSubmitted === 'pending'


    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar();
    };



    const addEmployeeProps = {state, department_id, submitted, image, ninImage, handleChangeDepartment, handleChange,
        handleChangePicture, handleChangeNinPicture, handleSubmitForm,  closeSnackbar,
        setState, setDepartment, blood_group, handleChangeBloodGroup,isPending,
        employeeContact,kinContact,setKinContact,setEmployeeContact, departs,isSubmitted, isResolved,
        openDialog, handleOpenDialog, handleCloseDialog,openEdit, handleCloseEditDialog, handleOpenEditDialog}

    return { addEmployeeProps, isResolved }


}