import {useEffect, useState} from "react";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {useAssignRole} from "./useAssignRole";

export const useUserRolesList = (actions) =>{
    const [searchValue, setSearchValue] = useState('');
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('sl');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [{user_role_id, status}, setID] = useState({user_role_id:'', status:''});
    const [userRoles, setUserRoles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openDelete, setDelete] = useState(false);
    const { role_name, submitted, isSubmitted, roles,
        handleChangeRoleName, handleSubmit,
        state, employee_email,employees,handleChange,
        handleChangeEmployee, employee_id,openDialog,handleOpenDialog,handleCloseDialog} = useAssignRole(actions)


    const isResolved = isSubmitted==='resolved'
    useEffect(() => {
        setLoading(true);
        axios.post(`${config.smsUrl}/permission/user_roles_list`).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const list = !dt.user_roles_list ? [] :dt.user_roles_list;
            setUserRoles(list);
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        })
    }, [status, isResolved]);

    const handleOpenDelete = (_id, st) => {
        setDelete(true);
        setID({user_role_id: _id, status:st})
    };

    const handleCloseDelete = () => {
        setDelete(false)
    };

    const handleDelete = (_id,st) => {
        const formData = new FormData();
        formData.append('user_roles_id', _id);
        formData.append('status', st);
        axios.post(`${config.smsUrl}/permission/deactivate_user_role`, formData).then(() => {
            const message = st === 2 ? "User deactivated successfully" : 'User activated successfully'
            actions.snackbarActions.snackSuccess(message);
           setID(currentState=>({...currentState, status: st}))
        }).catch(err => {
            errorMessages(err, null, actions)
        });
        setDelete(false)
    };



    const handleCloseSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };


    const handleSearch = (searchValue) => {
        setSearchValue(searchValue)
    };


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return {searchValue, order, orderBy, page, rowsPerPage, user_role_id, userRoles, loading, openDelete,
        handleOpenDelete, handleCloseDelete, handleDelete, handleCloseSnackbar, handleSearch,
        handleRequestSort, handleChangePage, handleChangeRowsPerPage, role_name, submitted, isSubmitted, roles,
        handleChangeRoleName, handleSubmit,status,
        state, employee_email,employees,handleChange,
        handleChangeEmployee, employee_id,openDialog,handleOpenDialog,handleCloseDialog}
}