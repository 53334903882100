import React, { Fragment, useEffect, useState } from "react";
import * as snackbarActions from "../../../actions/snackbarActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {
  ErrorMessage,
  MiniCenteredDiv,
  RightAlignedContainer,
} from "../../Utils/styledComponents";
import PageTitle from "../../Utils/smsTitle";
import { SubHeader } from "../../../Containers/SubHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardList } from "@fortawesome/free-solid-svg-icons/faClipboardList";
import { BackButton } from "../../Utils/Buttons/DataExportationButton";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Label from "../../Utils/FormInputs/Label";
import TextField from "../../Utils/FormInputs/TextField";
import Row from "react-bootstrap/Row";
import { Steppers } from "../../Utils/Buttons/Steppers";
import axios from "axios";
import { config } from "../../../Helpers/env";
import { errorMessages } from "../../../Helpers/ErrorMessages";
import { AutoCompleteControlled } from "../../Utils/FormInputs/AutoCompleteInput";
import { useAddSupplier } from "../../Inventory/purchase/hooks/useAddSupplier";
import CustomDialog from "../../Utils/Dialogs/CustomDialog";
import { SupplierForm } from "../../Inventory/purchase/AddEditSupplier";
import {
  AssetAccountingDetails,
  AssetDetails,
  AssetDetailsTemplate,
} from "./AssetDetailsTemplate";
import styled from "@emotion/styled/macro";
import InputGroup from "react-bootstrap/InputGroup";
import { history } from "../../../Helpers/history";
import { ReusableNewCOA } from "../ChartofAccounts/NewCOAccount";
import { BlockNavigationComponent } from "../../Utils/ReusableComponents/BlockNavigationComponent";
import { useCurrency } from "../../../Context/currency-context";

const NewItemDetails = ({ state, handleChangeState, submitted }) => {
  const {
    asset_name,
    serial_number,
    model_number,
    product_condition,
    warranty,
    manufacturer,
  } = state;
  return (
    <Fragment>
      <Form.Group>
        <Label name="Asset name" type htmlFor="fixed_asset_name" />
        <TextField
          type="text"
          id="fixed_asset_name"
          value={asset_name}
          submitted={submitted}
          name="asset_name"
          onChange={handleChangeState}
        />
        {submitted && !asset_name ? (
          <ErrorMessage>Asset name is required</ErrorMessage>
        ) : null}
      </Form.Group>
      <Row>
        <Col>
          <Form.Group>
            <Label name="Serial number" htmlFor="serial_number" />
            <TextField
              type="text"
              id="serial_number"
              value={serial_number}
              name="serial_number"
              onChange={handleChangeState}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Label name="Model number" htmlFor="model_number" />
            <TextField
              type="text"
              id="model_number"
              value={model_number}
              name="model_number"
              onChange={handleChangeState}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Label name="Product condition" htmlFor="product_condition" />
            <TextField
              type="text"
              id="product_condition"
              value={product_condition}
              name="product_condition"
              onChange={handleChangeState}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Label name="Warranty" htmlFor="warranty" />
            <TextField
              type="text"
              id="warranty"
              value={warranty}
              name="warranty"
              onChange={handleChangeState}
            />
          </Form.Group>
        </Col>
      </Row>
      <Form.Group>
        <Label name="Manufacturer" htmlFor="manufacturer" />
        <TextField
          type="text"
          id="manufacturer"
          value={manufacturer}
          name="manufacturer"
          onChange={handleChangeState}
        />
      </Form.Group>
    </Fragment>
  );
};
const DepreciationDetails = ({
  suppliers,
  assetAccounts,
  paymentAccounts,
  state,
  expenseAccounts,
  assetValue,
  submitted,
  handleChangeState,
  handleChangeAutoCompleteValue,
}) => {
  const {
    date,
    isCash,
    asset_cost,
    depreciation_rate,
    invoice_no,
    salvage_value,
  } = state;
  const {
    assetAccount,
    paymentAccount,
    supplier,
    depreciationExpense,
    depreciationAccount,
  } = assetValue;
  const { currency: selectedCurrency } = useCurrency({
    suppliers,
    assetAccounts,
    paymentAccounts,
    state,
    expenseAccounts,
    assetValue,
    submitted,
    handleChangeState,
    handleChangeAutoCompleteValue,
  });
  return (
    <Fragment>
      <Form.Group>
        <Label name="Purchase date" type htmlFor="fixed_asset_purchase_date" />
        <TextField
          type="date"
          id="fixed_asset_purchase_date"
          submitted={submitted}
          value={date}
          name="date"
          onChange={handleChangeState}
        />
        {submitted && !date ? <ErrorMessage></ErrorMessage> : null}
      </Form.Group>
      <Row>
        <Col>
          <Form.Group>
            <Label name="Supplier" type htmlFor="supplier" />
            <AutoCompleteControlled
              options={suppliers}
              handleChange={handleChangeAutoCompleteValue}
              submitted={submitted}
              medicine_value={supplier}
              placeholder="Select supplier..."
              id="supplier"
              errorMsg="Payment account is required"
              value_text="value"
              label_text="label"
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Label name="Asset cost" type htmlFor="fixed_asset_cost" />
            <InputGroup>
              <InputGroup.Text
                style={{ borderRadius: "0.7rem 0 0 0.7rem" }}
                id="basic-addon1"
              >
                {selectedCurrency.currency_symbol}
              </InputGroup.Text>
              <TextField
                type="number"
                id="fixed_asset_cost"
                submitted={submitted}
                value={asset_cost}
                name="asset_cost"
                onChange={handleChangeState}
              />
            </InputGroup>
            {submitted && !asset_cost ? (
              <ErrorMessage>Asset cost is required</ErrorMessage>
            ) : null}
          </Form.Group>
        </Col>
      </Row>
      <Form.Group>
        <Label name="Invoice number" type htmlFor="invoice_number" />
        <TextField
          type="text"
          id="invoice_number"
          submitted={submitted}
          value={invoice_no}
          name="invoice_no"
          onChange={handleChangeState}
        />
      </Form.Group>
      <Form.Group>
        <Label name="Asset Account" type htmlFor="assetAccount" />
        <AutoCompleteControlled
          options={assetAccounts}
          handleChange={handleChangeAutoCompleteValue}
          submitted={submitted}
          medicine_value={assetAccount}
          placeholder="Select asset account..."
          id="assetAccount"
          errorMsg="Asset account is required"
          value_text="value"
          label_text="label"
        />
      </Form.Group>

      <Form.Group>
        <Label name="Salvage Value" htmlFor="salvage_value" type />
        <TextField
          type="text"
          id="salvage_value"
          value={salvage_value}
          name="salvage_value"
          onChange={handleChangeState}
          submitted={submitted}
        />
        {submitted && !salvage_value ? (
          <ErrorMessage>Salvage value is required</ErrorMessage>
        ) : null}
      </Form.Group>

      <Form.Group>
        <Label
          name="Depreciation rate(%)"
          type
          htmlFor="fixed_asset_depreciation_rate"
        />
        <TextField
          type="number"
          id="fixed_asset_depreciation_rate"
          submitted={submitted}
          value={depreciation_rate}
          name="depreciation_rate"
          onChange={handleChangeState}
          min="0"
          max="100"
        />
        {submitted && !depreciation_rate ? (
          <ErrorMessage>Depreciation rate is required</ErrorMessage>
        ) : null}
      </Form.Group>
      <Row>
        <Col>
          <Form.Group>
            <Label
              name="Accumulated depreciation ledger "
              type
              htmlFor="depreciationAccount"
            />
            <AutoCompleteControlled
              options={assetAccounts}
              handleChange={handleChangeAutoCompleteValue}
              submitted={submitted}
              medicine_value={depreciationAccount}
              placeholder="Select accumulated depreciation ledger..."
              id="depreciationAccount"
              errorMsg=" Accumulated depreciation ledger is required"
              value_text="value"
              label_text="label"
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Label
              name="Depreciation expense ledger "
              type
              htmlFor="depreciationExpense"
            />
            <AutoCompleteControlled
              options={expenseAccounts}
              handleChange={handleChangeAutoCompleteValue}
              submitted={submitted}
              medicine_value={depreciationExpense}
              placeholder="Select depreciation expense ledger..."
              id="depreciationExpense"
              errorMsg="Depreciation expense ledger is required"
              value_text="value"
              label_text="label"
            />
          </Form.Group>
        </Col>
      </Row>
      <Form.Group>
        <Form.Check
          type="checkbox"
          label="Is cash"
          checked={isCash}
          name="isCash"
          onChange={handleChangeState}
        />
      </Form.Group>
      {isCash ? (
        <Form.Group>
          <Label name="Payment Account" type htmlFor="paymentAccount" />
          <AutoCompleteControlled
            options={paymentAccounts}
            handleChange={handleChangeAutoCompleteValue}
            submitted={submitted}
            medicine_value={paymentAccount}
            placeholder="Select payment account..."
            id="paymentAccount"
            errorMsg="Payment account is required"
            value_text="value"
            label_text="label"
          />
        </Form.Group>
      ) : null}
    </Fragment>
  );
};

const steps = ["Record asset details", "Record accounting details"];

const Paragraph = styled.p({
  marginLeft: 0,
});

const NewFixedAsset = ({ actions, snackbars }) => {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const userObj = !user ? {} : user;
  const token = !userObj.token ? [] : userObj.token;
  const _token = !token[1] ? {} : token[1];
  const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;

  const [activeStep, setActiveStep] = React.useState(0);
  const [state, setState] = React.useState({
    date: "",
    asset_cost: 0,
    depreciation_rate: 0,
    asset_name: "",
    isCash: false,
    serial_number: "",
    model_number: "",
    product_condition: "",
    warranty: "",
    manufacturer: "",
    invoice_no: "",
    salvage_value: "",
  });

  const [assetValue, setAssetValue] = React.useState({
    assetAccount: null,
    paymentAccount: null,
    supplier: null,
    depreciationExpense: null,
    depreciationAccount: null,
  });

  const [submitted, setSubmitted] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState("idle");

  const [suppliers, setSuppliers] = useState([]);
  const [assetAccounts, setAssetAccounts] = useState([]);
  const [paymentAccounts, setPaymentAccounts] = useState([]);
  const [expenseAccounts, setExpenseAccounts] = useState([]);
  const [openAssetDetails, setOpenAssetDetails] = useState(false);
  const [isBlocking, setIsBlocking] = useState(false);
  const [openLedger, setOpenLedger] = useState(false);
  const [isSubmittedLedger, setIsSubmittedLedger] = useState("idle");

  const { openAddSupplier, setOpenAddSupplier, isResolved, supplierProps } =
    useAddSupplier(actions);

  const isResolvedLedger = isSubmittedLedger === "resolved";

  useEffect(() => {
    axios
      .get(`${config.smsUrl}/csupplier/retrieve_all_suppliers`)
      .then(({ data }) => {
        let suppliers_arr = data.total_manufacturers ?? [];
        const supps = suppliers_arr.map((supp) => ({
          value: supp.supplier_id,
          label: supp.supplier_name,
          ...supp,
        }));
        setSuppliers(supps);
      })
      .catch((err) => {
        errorMessages(err, null, actions);
      });
  }, [isResolved]);

  useEffect(() => {
    axios
      .get(`${config.smsUrl}/accounting/fixed_asset_accounts`)
      .then(({ data }) => {
        let asset_types_arr = data.data ?? [];
        const types_arr = asset_types_arr.map((i) => ({
          label: i.head_name,
          value: i.id,
        }));
        setAssetAccounts(types_arr);
      })
      .catch((err) => {
        errorMessages(err, null, actions);
      });
  }, [isResolvedLedger]);

  useEffect(() => {
    axios
      .get(`${config.smsUrl}/accounting/ledgers_under_category`)
      .then(({ data }) => {
        let asset_types_arr = data.data ?? [];
        const types_arr = asset_types_arr.map((i) => ({
          label: i.head_name,
          value: i.id,
        }));
        setPaymentAccounts(types_arr);
      })
      .catch((err) => {
        errorMessages(err, null, actions);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${config.smsUrl}/accounting/chart/expense_ledgers`)
      .then(({ data }) => {
        let accounts = data.data ?? [];
        const accounts_arr = accounts.map((i) => ({
          label: i.head_name,
          value: i.id,
        }));
        setExpenseAccounts(accounts_arr);
      })
      .catch((err) => {
        errorMessages(err, null, actions);
      });
  }, [isResolvedLedger]);

  const handleOpenSupplierDialog = () => {
    setOpenAddSupplier(true);
  };
  const handleCloseSupplierDialog = () => {
    setOpenAddSupplier(false);
  };

  const handleOpenAssetDetails = (event) => {
    const { asset_name, date, asset_cost, depreciation_rate } = state;
    const { supplier, assetAccount, depreciationAccount, depreciationExpense } =
      assetValue;
    event.preventDefault();
    setSubmitted(true);
    if (
      asset_name &&
      asset_cost &&
      date &&
      depreciation_rate &&
      supplier &&
      assetAccount &&
      depreciationAccount &&
      depreciationExpense
    ) {
      setOpenAssetDetails(true);
    }
  };
  const handleCloseAssetDetails = () => {
    setOpenAssetDetails(false);
  };

  const handleChangeState = (event) => {
    const { name, value, checked, type } = event.target;
    const val = type === "checkbox" ? checked : value;
    setState({ ...state, [name]: val });
    setIsBlocking(true);
  };

  const handleChangeAutoCompleteValue = (value, id) => {
    setAssetValue({ ...assetValue, [id]: value });
    setIsBlocking(true);
  };

  const handleNext = () => {
    const { asset_name } = state;
    setSubmitted(true);
    if (asset_name) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSubmitted(false);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSaveAsset = (event) => {
    const {
      asset_name,
      date,
      asset_cost,
      depreciation_rate,
      warranty,
      serial_number,
      model_number,
      product_condition,
      manufacturer,
      isCash,
      invoice_no,
      salvage_value,
    } = state;
    const {
      supplier,
      assetAccount,
      depreciationAccount,
      depreciationExpense,
      paymentAccount,
    } = assetValue;
    event.preventDefault();
    setSubmitted(true);
    setIsBlocking(false);
    if (
      asset_name &&
      asset_cost &&
      date &&
      depreciation_rate &&
      supplier &&
      assetAccount &&
      depreciationAccount &&
      depreciationExpense &&
      salvage_value
    ) {
      setIsSubmitted("pending");
      const jsonData = {
        asset_name,
        asset_cost: +asset_cost,
        date_purchased: date,
        depreciation_rate: +depreciation_rate
          ? +depreciation_rate / 100
          : +depreciation_rate,
        supplier_id: supplier?.value,
        serial_no: serial_number,
        warranty_period: warranty,
        model_number,
        manufacturer,
        product_condition,
        currency_id: 1,
        asset_account: assetAccount?.value,
        depreciation_expense_account: depreciationExpense?.value,
        accumulated_depreciation_account: depreciationAccount?.value,
        is_cash: isCash,
        cash_legder: isCash ? paymentAccount?.value : "",
        currency_rate: 1,
        currency_cost_value: 0,
        current_value: 0,
        added_by: user_roles_id,
        invoice_no,
        salvage_value,
      };
      axios
        .post(`${config.smsUrl}/cinventory/fixed_asset/add`, jsonData)
        .then(() => {
          actions.snackbarActions.snackSuccess("Asset created successfully");
          setOpenAssetDetails(false);
          setIsSubmitted("resolved");
          history.push("/accounting/fixedassets");
        })
        .catch((err) => {
          errorMessages(err, null, actions);
          setIsSubmitted("rejected");
        });
    }
  };

  const closeSnackbar = () => {
    actions.snackbarActions.hideSnackbar();
  };

  const { openBar, type, message } = snackbars;

  const { isPending } = supplierProps;

  const isPendingAddAsset = isSubmitted === "pending";

  const saveButtons = (
    <>
      <button
        type="button"
        onClick={handleSaveAsset}
        disabled={isPendingAddAsset}
        className="btn btn-sm sms-info-btn mr-4"
      >
        {isPendingAddAsset ? "Saving..." : "Save"}
      </button>
      <button
        type="button"
        onClick={handleCloseAssetDetails}
        className="btn btn-sm sms-gray-btn"
      >
        Cancel
      </button>
    </>
  );

  return (
    <div>
      <PageTitle title="New Fixed Asset" />
      <SubHeader title="Accounting" subTitle="New Fixed Asset">
        <FontAwesomeIcon icon={faClipboardList} />
      </SubHeader>
      <BlockNavigationComponent
        when={isBlocking}
        shouldBlockNavigation={() => {
          return isBlocking;
        }}
        navigate={(path) => history.push(path)}
      />
      <MiniCenteredDiv>
        <MainSnackbar
          open={openBar}
          message={message}
          variant={type}
          handleCloseBar={closeSnackbar}
        />
        <div className="mb-3">
          <BackButton to="/accounting/fixedassets" text="Asset List" />
        </div>
        <CustomDialog
          open={openAddSupplier}
          title="Add Supplier"
          handleClose={handleCloseSupplierDialog}
          maxWidth="md"
        >
          <SupplierForm {...supplierProps}>
            <button
              type="submit"
              disabled={isPending}
              className="btn btn-sm sms-btn"
            >
              {isPending ? "Saving..." : "Add Supplier"}
            </button>
          </SupplierForm>
        </CustomDialog>

        <ReusableNewCOA
          {...{
            actions,
            isSubmitted: isSubmittedLedger,
            openDialog: openLedger,
            setIsSubmitted: setIsSubmittedLedger,
            setOpenDialog: setOpenLedger,
            isExpense: true,
          }}
        />
        {/*<CustomDialog open={openAssetDialog} title="Add Asset Account" handleClose={handleCloseDialog} maxWidth="sm">*/}
        {/*   <FixedAssetAccountForm {...assetProps}/>*/}
        {/*</CustomDialog>*/}

        <CustomDialog
          open={openAssetDetails}
          title="Asset Details"
          handleClose={handleCloseAssetDetails}
          maxWidth="md"
          buttons={saveButtons}
        >
          <Paragraph>
            Please confirm that the data entered is correct before saving
          </Paragraph>
          <AssetDetailsTemplate>
            <AssetDetails {...{ state }} />
            <AssetAccountingDetails {...{ state, assetValue }} />
          </AssetDetailsTemplate>
        </CustomDialog>

        <Steppers {...{ activeStep, steps }} />
        <form onSubmit={handleOpenAssetDetails} autoComplete="off">
          {activeStep === 0 ? (
            <NewItemDetails
              {...{
                assetValue,
                suppliers,
                assetAccounts,
                state,
                handleChangeState,
                handleChangeAutoCompleteValue,
                submitted,
              }}
            />
          ) : null}
          {activeStep === 1 ? (
            <>
              <RightAlignedContainer>
                <div className="mb-3">
                  <button
                    type="button"
                    onClick={handleOpenSupplierDialog}
                    className="btn btn-sm sms-info-btn mr-4"
                  >
                    Quick add supplier
                  </button>
                  <button
                    type="button"
                    onClick={() => setOpenLedger(true)}
                    className="btn btn-sm sms-info-btn"
                  >
                    Quick add account
                  </button>
                </div>
              </RightAlignedContainer>
              <DepreciationDetails
                {...{
                  assetValue,
                  suppliers,
                  assetAccounts,
                  paymentAccounts,
                  state,
                  handleChangeState,
                  handleChangeAutoCompleteValue,
                  submitted,
                  expenseAccounts,
                }}
              />
            </>
          ) : null}

          <RightAlignedContainer>
            <div className="mt-4">
              {activeStep === 0 ? (
                <button
                  type="button"
                  onClick={handleNext}
                  className="btn btn-sm sms-info-btn"
                >
                  Record accounting details
                </button>
              ) : null}

              {activeStep === 1 ? (
                <>
                  <button
                    type="button"
                    onClick={handleBack}
                    className="btn btn-sm sms-gray-btn mr-4"
                  >
                    Back
                  </button>
                  <button type="submit" className="btn btn-sm sms-info-btn">
                    Save
                  </button>
                </>
              ) : null}
            </div>
          </RightAlignedContainer>
        </form>
      </MiniCenteredDiv>
    </div>
  );
};

function mapStateToProps(state) {
  return { snackbars: state.snackbar };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      snackbarActions: bindActionCreators(snackbarActions, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewFixedAsset);
