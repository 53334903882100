import React from "react";
import PageTitle from "../Utils/smsTitle";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserFriends} from "@fortawesome/free-solid-svg-icons/faUserFriends";
import { BackButton } from "../Utils/Buttons/DataExportationButton";
import Card from "@material-ui/core/Card";
import {useTheme} from "@material-ui/core/styles";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../actions/snackbarActions";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import {useEmployeeProfile} from "./CustomHooks/useEmployeeProfile";
import CustomDialog from "../Utils/Dialogs/CustomDialog";
import EmployeeForm from "./EmployeeForm";
import {Link} from "react-router-dom"

const EmployeeCard = ({title, children}) => {
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    return (
        <div className={`card emp-more-info-card ${palette.type === "dark" && "dark-emp-more-info-card"}`}>
            <h6 style={{fontSize: 15}}><strong>{title}</strong></h6>
            {children}
        </div>
    )
};
const EmployeeProfile = (props) => {
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    const {openBar, type, message} = props.snackbars;
    const {employee, employee_id, closeSnackBar, addEmployeeProps} = useEmployeeProfile(props)
    const {openEdit, handleOpenEditDialog, handleCloseEditDialog} = addEmployeeProps
    return (
        <div data-testid="employee-profile">
            <PageTitle title="Employee Profile"/>
            <SubHeader title="HRM" subTitle="Employee Profile">
                <FontAwesomeIcon icon={faUserFriends}/>
            </SubHeader>
            <CustomDialog title="Edit Employee" open={openEdit} handleClose={handleCloseEditDialog} maxWidth="lg"  isContentOverflow={false}>
                <EmployeeForm {...addEmployeeProps} />
            </CustomDialog>
            <div className="style-employee-details">
                <BackButton to='/employeelist' text='Employee List' data-testid="employee-profile-back-button"/>
                <MainSnackbar variant={type} message={message} open={openBar} handleCloseBar={closeSnackBar}/>
                <div className="row">
                    <div className="col-md-4">
                        <Card>
                            <div className="styled-employee-card 0 p-2">
                              <div className="row">

                                <div className="col-lg-6 col-md-6 col-sm-6">
                                            {/*<Link to='/employeelist'>*/}
                                            {/*<button */}
                                            {/*className="btn btn-sm sms-grey-btn emp-edit-btn mb-2">BACK</button>*/}
                                            {/*</Link>*/}
                                </div>
                                  <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className='text-lg-right text-md-right text-sm-left mb-2'>
                                        <button onClick={()=> handleOpenEditDialog(employee_id)}
                                            className="btn btn-sm sms-grey-btn emp-edit-btn">EDIT</button>
                                        </div>

                                    
                                  </div>
                              </div>
                                <div className="employee-image">
                                    <img src="/images/profile.png" alt="user"/>
                                </div>
                                <div className="emp-profile-info text-center">
                                    <p>Name: <strong>{`${!employee.first_name ? "" : employee.first_name}
                                     ${!employee.last_name ? "" : employee.last_name}`}</strong></p>
                                    <p>Gender: <strong>{employee.gender}</strong></p>
                                    <p style={{fontSize: 15, textDecoration: "underline"}}>Contact Information</p>
                                    <p><strong>{employee.phone_no}</strong></p>
                                    <p><strong>{employee.email}</strong></p>
                                    <p style={{fontSize: 15, textDecoration: "underline"}}>Address Information</p>
                                    <p><strong>{employee.address}</strong></p>
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className="col-md-8">
                        <div
                            className={`emp-other-details  p-1 ${palette.type === "dark" && "dark-emp-other-details"}`}>
                            <div className="row emp-other-info-row">
                                <div className="col-lg-6 mb-2">
                                    <EmployeeCard title="More Information">
                                        <p>Specialization: {employee.specialisation}</p>
                                        <p>Department: {employee.department_name}</p>
                                        <p>Date of Birth: {employee.dob}</p>
                                        <p>Blood Group: {employee.blood_type}</p>
                                    </EmployeeCard>
                                </div>
                                <div className="col-lg-6 mb-2">
                                    <EmployeeCard title="Next of Kin">
                                        <p>Name: {`${employee.kin_firstname ? employee.kin_firstname : ''} ${employee.kin_lastname ? employee.kin_lastname : ''}`}</p>
                                        <p>Contact: {employee.kin_contact}</p>
                                        <p>Address: {employee.kin_address}</p>
                                        <p>Relationship: {employee.relationship}</p>
                                    </EmployeeCard>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

};

function mapStateToProps(state) {
    return {employees: state.employees, snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeProfile);
