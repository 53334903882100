import React, {useEffect, useState} from "react";
import Label from "../../Utils/FormInputs/Label";
import Form from "react-bootstrap/Form";
import axios from "axios";
import {config} from "../../../Helpers/env";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {CustomInput} from "../../Inventory/CustomInput";
import CustomDialog from "../../Utils/Dialogs/CustomDialog";
import {CustomSelect} from "../../Utils/FormInputs/SelectInput";


function NewExpenseItem({actions, openExpense, setOpenExpense,isSubmitted, setIsSubmitted }){
    const [submitted, setSubmitted] = useState(false);
    const [expenseAccount, setExpenseAccount] = useState({value:'', label:''})
    const [accounts, setAccounts] = useState([])


    useEffect(() => {
        (async () => {
            try {
                const res = await axios.get(`${config.smsUrl}/accounting/chart/expense_ledgers`)
                let _data = res.data
                let dt = _data.data ?? [];
                let arr = dt.map(i => ({label: i.head_name, value: i.id, parent_id:i.parent_id}))
                setAccounts(arr)
            } catch (e) {
                // errorMessages(e, null, actions)
            }
        })();
    }, [])
    const submitNewExpense = (event) =>{
        event.preventDefault();
        const formData = new FormData(event.currentTarget)
        formData.append('account', expenseAccount?.value)
        const fieldValues = Object.fromEntries(formData.entries())
        if (fieldValues['expense_type_name']) {
            setIsSubmitted('pending')
            axios.post(`${config.smsUrl}/accounting/expenses_types/add`, formData).then(() => {
                actions.snackbarActions.snackSuccess('Expense account added successfully')
                setOpenExpense(false)
                setIsSubmitted('resolved')
                setSubmitted(false)
            }).catch(err => {
                errorMessages(err, null, actions);
                setIsSubmitted('rejected')
            })
        }

    }

    const isPending = isSubmitted === 'pending'
    return (
        <CustomDialog open={openExpense} handleClose={()=>setOpenExpense(false)} title='New Expense Account' maxWidth='sm'>
            <form onSubmit={submitNewExpense} autoComplete='off'>
                <Form.Group>
                    <Label type name='Account'/>
                    <CustomSelect submitted={submitted} value={expenseAccount} onChange={value=>setExpenseAccount(value)} options={accounts}
                                  defaultValue={{value:"",label:"Select expense account..."}}/>
                </Form.Group>
                <Form.Group>
                    <Label type name='Expense Item'/>
                    <CustomInput submitted={submitted} type='text' name='expense_type_name'/>
                </Form.Group>
                <button type='submit' disabled={isPending}
                        className='btn btn-sm sms-btn'>{isPending ? 'Submitting...' : 'Submit'}</button>
            </form>
        </CustomDialog>

    )
}

export {NewExpenseItem}