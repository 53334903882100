import { useState, useEffect } from "react";
import { errorMessages } from "../../../Helpers/ErrorMessages";
import axios from "axios";
import { config } from "../../../Helpers/env";
import { history } from "../../../Helpers/history";
import moment from "moment";
import { useAddAdmissionSurgery } from "./useAddAdmissionSurgery";
import {useAddAdmission} from "../../Visit/NewAdmission/hooks/useAddAdmission";

const convertToLabelValueArray = (str) => {
    const new_arr = str.split(",")
    return new_arr.map(a => {return {'label' : a, 'value':a}});
}

 export const validateOpDateStartTime = (date, time) => {
    if (date && time) {
        var d1 = new Date();
        var d2 = new Date(date + " " + time);
        return (d2 >= d1);
    } else if (time) {
        var d1 = new Date();
        const d = moment().format('L');
        const dd = moment(d +' '+ time).format();
        var d2 = new Date(dd);
        return (d2 >= d1);
    }
    return false;
}

export const checkDuration = (hr, mins) => {
    if (hr || mins) {
        if ((hr === "0" || hr === "00") && (mins === "0" || mins === "00")) {
            return false;
        } 
        if (Number(mins) > 59) {
            return false;
        } 

        return true; 
    } return false;
}

const checkTimes = (op_date, end_time, start_time) => {
    if (start_time && end_time) {
        return true;
    } return false;
}

export const useGetSurgeryDetails = (actions, pat_type='') => {

        // handle for getting the patient details
        const [p_number, setPatientNumber] = useState('');

        const [patient_type, setPatientType] = useState(pat_type ? pat_type : 0);

        const [surgery_id, setSurgeryID] = useState(null);

        const [service_id, setServiceID] = useState(null);
        
        const [surgery_department, setSurgeryDepartment] = useState("");

        const [submitted, setSubmitted] = useState(false)

        const [surgery_time , setSurgeryTime] = useState({operation_date:"", estimated_start_time:"", duration_hr:0, duration_mins:0,
        actual_end_time:"", actual_start_time:""}); 

        const [surgery_bill_details, setSurgeryBillDetails] = useState({});
        //Initial state for users conducting surgery
        const [surgeon, setSurgeon] = useState({ lead_surgeon:"", lead_anaestheist:"", anaesthesiologist : "", scrub_nurse:"", circulating_nurse:""});
        
        //Initial state for list of users in the system
        const [surgeons, setSurgeons] = useState([])
    
        const [other_anaestheists, setOtherAnaestheists] = useState([])
    
        const [other_surgeons, setOtherSurgeons] = useState([]);

        //Initial state for dropdown when searching user
        const [isOpen, setIsOpen] = useState({})
    
        // the submission
        const [isSubmitted, setIsSubmitted] = useState('idle');
    
        const [inputDoc, setInputDoc] = useState({input_visit_anaestheist:"", input_visit_surgeon:""});

        // check for visiting doctor
        const [isVisitingDoctor, setVisitingDoctor] = useState({});

        const [canReschedule, setCanReschedule] = useState(false);

        const [surgery_notes, setSurgeryNotes] = useState('');

        const [matchAdmitProps, setMatchAdmitProps] = useState({patient_number:"", visit_id:"", labrequest_id:'', scan_request_id:'', patient_type:'', patient_admission_id:''});

        // added parameter for the admission notes
        const {admissionProps} = useAddAdmission(actions, p_number, true, matchAdmitProps);
        const [openAdmission, setAdmissionDialog] = useState(false);
    
        const handleChangeInputSurgeons = (event, param) => {
            if (param === "other_surgeons") {
                if (event.key === "Enter") {
                    
                    if (event.target.value !== "") {
                        const new_val = event.target.value;
                        //setInputDoc({...inputDoc, ['input_visit_surgeon']:new_val});
                        const newD = [{label:new_val, value:new_val}];
                        setOtherSurgeons([...other_surgeons, ...newD])
                    }
    
                    setVisitingDoctor(prevState => ({...prevState, 'other_surgeons': false}));
                    setInputDoc({...inputDoc,'input_visit_surgeon': ""});
                } else {
                    setInputDoc({...inputDoc,'input_visit_surgeon': event.target.value});
                }
    
            } else if (param === "other_anaestheists") {
    
                if (event.key === "Enter") {
                    
                    if (event.target.value !== "") {
                        const new_val = event.target.value;
                        //setInputDoc({...inputDoc,['input_visit_anaestheist']: new_val});
                        const newD = [{label:new_val, value:new_val}];
                        setOtherAnaestheists([...other_anaestheists, ...newD]);
                    } 
    
                    setVisitingDoctor(prevState => ({...prevState, "other_anaestheists": false}));
                    setInputDoc({...inputDoc,'input_visit_anaestheist': ""});
                } else {
                    setInputDoc({...inputDoc,'input_visit_anaestheist': event.target.value});
                }
            } else {
                setSurgeon({...surgeon,[param]: event.target.value})
            }
        }
    
        // auto search function for users
        const handleChangeSurgeon = (event, param) => {
            const {value, name} = event.target;
            setSurgeon({...surgeon,[name]:value})
            const formData = new FormData();
            if (value !== "") {
                formData.append("first_name", value.toUpperCase());
                axios.post(`${config.smsUrl}/cuser/auto_search_users`, formData)
                .then(res => {
                    const details = res.data;
                    const data = !details ? [] : details;
                    if (data[0] === "No User Found") {
                        setSurgeons([{value: "", label: "Other"}])
                        setIsOpen(prevState => ({...prevState, [param]: true}));
                    } else {
                        setSurgeons(data);
                        setIsOpen(prevState => ({...prevState, [param]: true}));
                    }
                }).catch(error => {
                    errorMessages(error, null, actions)
                });
            } else {
                setIsOpen(prevState => ({...prevState, [param]: false}));
            }
            setIsSubmitted("idle");
        }
    
        //select user
        const handleSelectSurgeon = (value, param) =>{
            if (value === "Other") {
                // convert to the textfield
                setVisitingDoctor(prevState => ({...prevState, [param]: true}));
                setIsOpen(prevState => ({...prevState, [param]: false}));
            } else {
                setSurgeon({...surgeon,[param]:value})
                setIsOpen(prevState => ({...prevState, [param]: false}));
            }
        }

        // handler for the change of surgery values in the state
        const onChangeSurgeryTime = (event) => {
            setSurgeryTime({...surgery_time, [event.target.name]: event.target.value});
        }
    
        // functions for operation classes
        const operationClasses = [
            { label: 'Minor', value: "minor" },
            { label: 'Major', value: "major" }
        ];
    
        const [operation_class, setOpClass] = useState([]);
    
        const handleOpClassChange = (value) => {
            setOpClass(value);
        }
    
        // functions for anaesthesia types
        const anaesthesiaTypes = [
            { label: 'Local', value: "local" },
            { label: 'Spinal', value: "spinal" },
            { label: 'General', value: "general" },
            { label: 'Epidural', value: "epidural" }
        ];
    
        const [anaesthesia_type, setAnesType] = useState([]);
    
        const handleAnesTypeChange = (value) => {
            setAnesType(value);
        }
    
        // functions for surgery types
        const [surgery_name, setSurgeryName] = useState([]);
    
        const [surgeryTypes, setSurgeryTypes] = useState([]);

        useEffect(() => {
            const formData = new FormData();
                formData.append('service_type', 'Surgery');
                axios.post(`${config.smsUrl}/cvisit/retrieve_services_by_service_type`, formData)
                    .then(res => {
                        const details = res.data;
                        const data = !details ? [] : details;
                        const arr = data.map(item => ({
                            value: `${item.service_id}-${item.cost}`,
                            label: item.service_name
                        }))
                        setSurgeryTypes(arr)
                    }).catch(error => {
                    errorMessages(error, null, actions)
                });
        }, []);
    
        const handleSurgeryTypeChange = (value) => {
            setSurgeryName(value);
        }
    
        // handles for the notes
        const [general_notes, setGeneralNotes] = useState({'preoperative_diagnosis': "", 'preoperative_instructions' : "", //'planned_procedures' : "", 
        'post_operative_instructions':"", 'summary_report':""});
        
        const handleNotesState = (event) => {
            setGeneralNotes({...general_notes, [event.target.name]: event.target.value});
        }
    
        // handle the submission of scheduling a surgery 
        const [surgeryConfirmation, setConfirmation] = useState(false);
    
        const isUpdated = isSubmitted === 'resolved';
    
        const handleUpdateSurgery = (event) => {
            event.preventDefault();
    
            const {lead_surgeon, lead_anaestheist, anaesthesiologist, scrub_nurse, circulating_nurse} = surgeon
    
            const anaesT = (anaesthesia_type['value']  === undefined) ? "" : anaesthesia_type['value'];
    
            const params = {
                surgery_id, lead_surgeon, anaesthesiologist, lead_anaestheist, scrub_nurse, circulating_nurse,
                preoperative_instructions : general_notes.preoperative_instructions,
                preoperative_diagnosis : general_notes.preoperative_diagnosis,
                other_anaestheists : other_anaestheists.map(a => a.label).join(","),
                other_surgeons : other_surgeons.map(a => a.label).join(","), anaesthesia_type : anaesT
            }
    
            if (p_number) {
    
                setIsSubmitted('pending');

                // THIS NEEDS TO CHANGE TO UPDATE SURGERY DETAILS
                axios.post(`${config.smsUrl}/surgery/update_surgery_details`, {...params}).then((res) => {
                
                    actions.snackbarActions.snackSuccess('Surgery updated');
    
                    setIsSubmitted('resolved');
                   // setSubmittedSurgery(false);
    
                    setConfirmation(false);
                    // reset the screen for the scheduled surgery
    
                }).catch((err) => {
                    setIsSubmitted('rejected');
                    setConfirmation(false);
                   // errorMessages(err, null, actions);
                });
            } else {
                setIsSubmitted('rejected');
                setConfirmation(false);
            }
        }
    
        // TO SOLVE AS WELL
        const handleCompleteSurgery = (event) => {

            setSubmitted(true);
            if (p_number && checkTimes(surgery_time.operation_date, surgery_time.actual_start_time, surgery_time.actual_end_time) ) {

                const params = {
                    surgery_id,
                    "actual_end_time": surgery_time.actual_end_time ? surgery_time.actual_end_time : "",
                    "actual_start_time": surgery_time.actual_start_time ? surgery_time.actual_start_time : "",
                    "summary_report":general_notes.summary_report,
                }
        
                setIsSubmitted('pending');
                // new_surgery
                axios.post(`${config.smsUrl}/surgery/end_surgery`, {...params}).then((res) => {
    
                    actions.snackbarActions.snackSuccess('Surgery completed');
        
                    setIsSubmitted('resolved');
                    // setSubmittedSurgery(false);
        
                    setConfirmation(false);
                    history.push(`/surgerymodule/pastsurgeries`)
                    // reset the screen for the scheduled surgery
        
                }).catch((err) => {
                    setIsSubmitted('rejected');
                    setConfirmation(false);
                    errorMessages(err, null, actions);
                });
            }  
        }

        const handleRescheduleSurgery = (event) => {

            event.preventDefault();

            if (p_number && checkDuration(surgery_time.duration_hr, surgery_time.duration_mins) && validateOpDateStartTime(surgery_time.operation_date, surgery_time.estimated_start_time)) {
                
                const estimated_duration = `${surgery_time.duration_hr}:${surgery_time.duration_mins}`
                const params = {
                    patient_number: p_number, surgery_id, estimated_start_time: surgery_time.estimated_start_time, estimated_duration, operation_date: surgery_time.operation_date,
                }
                setIsSubmitted('pending');

                axios.post(`${config.smsUrl}/surgery/reschedule_surgery`, {...params}).then((res) => {
                
                    actions.snackbarActions.snackSuccess('Surgery updated');
                    setIsSubmitted('resolved');
                   // setSubmittedSurgery(false);
    
                    setConfirmation(false);
                    setCanReschedule(true);
                    
                }).catch((err) => {
                    setIsSubmitted('rejected');
                    setConfirmation(false);
                    errorMessages(err, null, actions);
                });
            } else {
                setIsSubmitted('rejected');
                setConfirmation(false);
            }
        }
    
        const openConfirmationDialog = (event) => {
            // first validate the form
            setSubmitted(true);
            event.preventDefault();
            if (p_number && validateOpDateStartTime(surgery_time.operation_date, surgery_time.estimated_start_time) &&
                (surgery_time.duration_hr || surgery_time.duration_mins) && operation_class['value'] && surgery_name['value']) {
                    setConfirmation(true);
            }
        } 
    
        const handleCloseConfirm = () => {
            setConfirmation(false);
            setSubmitted(false);
        };

        const handleOtherAnaestheists = (data) => {
            if (data && data.some(e => e.label === 'Other')) {
                setVisitingDoctor({...isVisitingDoctor, 'other_anaestheists': true});
            } else {
                setOtherAnaestheists(data);
                setVisitingDoctor({...isVisitingDoctor, 'other_anaestheists': false});
            }
        }
    
        const handleOtherSurgeons = (data) => {
            if (data && data.some(e => e.label === 'Other')) {
               setVisitingDoctor({...isVisitingDoctor, 'other_surgeons': true});
            } else {
                setOtherSurgeons(data);
                setVisitingDoctor({...isVisitingDoctor, 'other_surgeons': false});
            }
    
        }
    
        const handleChangeDoctors = (data) => {
            const value = data;
            const formData = new FormData();
            if (value !== "") {
                formData.append("first_name", value.toUpperCase());
                axios.post(`${config.smsUrl}/cuser/auto_search_users`, formData)
                .then(res => {
                    const details = res.data;
                    const data = !details ? [] : details;
                    if (data[0] === "No User Found") {
                        setSurgeons([{value: value, label: "Other"}])
                    } else {
                        setSurgeons(data);
                    }
                }).catch(error => {
                    errorMessages(error, null, actions)
                });
            } else {
                setSurgeons([{value: "", label: "Other"}])
            }
            setIsSubmitted("idle");
        }

        const assignSurgeryDetails = (item) => {

            const duration_value = item.estimated_duration ?  item.estimated_duration.split(":") : [];

            setPatientType(item.patient_type ? item.patient_type : pat_type ? pat_type : 0);

            const hr = (duration_value && (duration_value.length > 1 )) ? (duration_value[0]) : 0;
            const mins = (duration_value && (duration_value.length > 1 ))  ? (duration_value[1]) : 0;

            const initialState = item.screen === 'updateP' ? 
            {operation_date:item.operation_date ? item.operation_date : "", 
            estimated_start_time:item.estimated_start_time ? item.estimated_start_time : "0:0:0", 
            duration_hr: hr, duration_mins: mins, original_op_date:item.operation_date ? item.operation_date : "", 
            original_start_time:item.estimated_start_time ? item.estimated_start_time : "0:0:0", 
            original_duration_hr: hr, original_duration_mins: mins
            }
            : 
            {operation_date:item.operation_date ? item.operation_date : "", 
            estimated_start_time:item.estimated_start_time ? item.estimated_start_time : "0:0:0", 
            duration_hr: hr, duration_mins: mins}

            setSurgeryID(item.id ? item.id : item.surgery_id);

            setSurgeryTime(()=>initialState);

            const p_no = item.patient_no ? item.patient_no : item.patient_number ? item.patient_number : '';
            setPatientNumber(()=>p_no)

            setSurgeon({ lead_surgeon:item.lead_surgeon ? item.lead_surgeon : '', lead_anaestheist:item.lead_anaestheist ? item.lead_anaestheist : '', 
            anaesthesiologist : item.anaesthesiologist ? item.anaesthesiologist : '', scrub_nurse:item.scrub_nurse ? item.scrub_nurse : '', 
            circulating_nurse:item.circulating_nurse ? item.circulating_nurse : ''})

            setOtherAnaestheists(item.other_anaestheists ? convertToLabelValueArray(item.other_anaestheists) : []);

            setOtherSurgeons(item.other_surgeons ? convertToLabelValueArray(item.other_surgeons) : []);

            setOpClass(item.operation_class? {'label': item.operation_class, 'value': item.operation_class}: []);

            setAnesType(item.anaesthesia_type ? {'label': item.anaesthesia_type, 'value': item.anaesthesia_type} : []);

            setSurgeryName(item.service_name ? {'label' : item.service_name, 'value' : item.service_name}: [])
        
            setGeneralNotes({'preoperative_diagnosis': item.preoperative_diagnosis ? item.preoperative_diagnosis : '', 
            'preoperative_instructions' : item.preoperative_instructions ? item.preoperative_instructions : '', 
            //'planned_procedures' : item.planned_procedures ? item.planned_procedures : '', 
            'post_operative_instructions':item.post_operative_instructions ? item.post_operative_instructions : '', 
            'summary_report':item.summary_report ? item.summary_report : ''});

            const matches = {patient_number:p_no, visit_id:item.visit_id ? item.visit_id : '', 
            labrequest_id:'', scan_request_id:'', patient_type:item.patient_type ? item.patient_type : '', patient_admission_id:''}
            setMatchAdmitProps(()=>matches);

            const pre_inst = (item.preoperative_instructions ? item.preoperative_instructions : '');
            const pre_diag = (item.preoperative_diagnosis ? item.preoperative_diagnosis : '');
            const surgery_notes_set = pre_inst && pre_diag ? pre_inst + '\n' + pre_diag : pre_inst && !pre_diag ? pre_inst + '\n' : !pre_inst && pre_diag ? pre_diag : '';
            setSurgeryNotes(()=>surgery_notes_set);
        }

        const clearSurgeryDetails = () => {
            setPatientType(0);
            setSurgeryID(0);
            setSurgeryTime({operation_date:"", estimated_start_time:"", duration_hr:0, duration_mins:0,
            actual_end_time:"", actual_start_time:""});
            setPatientNumber('');
            setSurgeon({ lead_surgeon:"", lead_anaestheist:"", anaesthesiologist : "", scrub_nurse:"", circulating_nurse:""});
            setOtherAnaestheists([]);
            setOtherSurgeons([]);
            setOpClass([]);
            setAnesType([]);
            setSurgeryName([]);
            setGeneralNotes({'preoperative_diagnosis': "", 'preoperative_instructions' : "", //'planned_procedures' : "", 
            'post_operative_instructions':"", 'summary_report':""});
            setMatchAdmitProps({patient_number:'', visit_id:'', 
            labrequest_id:'', scan_request_id:'', patient_type:'', patient_admission_id:''});
            setSurgeryNotes('');
        }

    const handleCloseAdmission = () => {
        setAdmissionDialog(false);
    }

        return {
            assignSurgeryDetails, clearSurgeryDetails, patient_type,
            surgery_time,  onChangeSurgeryTime, handleRescheduleSurgery,
            general_notes, handleNotesState, setConfirmation, handleCompleteSurgery,
            operation_class, operationClasses, handleOpClassChange, 
            anaesthesia_type, anaesthesiaTypes, handleAnesTypeChange, isUpdated,
            p_number, handleUpdateSurgery, surgery_id, matchAdmitProps,
            surgery_name, handleSurgeryTypeChange, surgeryTypes, surgery_notes,
            handleCloseConfirm, openConfirmationDialog, surgeryConfirmation,
            surgeon,surgeons, isOpen, handleChangeSurgeon, handleSelectSurgeon, 
            other_anaestheists, other_surgeons, isVisitingDoctor, inputDoc, submitted,
            handleOtherSurgeons, handleOtherAnaestheists, handleChangeDoctors, handleChangeInputSurgeons,
            canReschedule, setCanReschedule, admissionProps:{...admissionProps, openAdmission,handleCloseAdmission, setAdmissionDialog}
        };

}