import {useEffect, useRef, useState} from "react";
import axios from "axios";
import {config} from "../../../../Helpers/env";
import {logoutErrorMessage} from "../../../../Helpers/ErrorMessages";

export const useViewPurchaseOrder = (match, actions) =>{
    const [purchaseOrder, setPurchaseOrder] = useState({});
    const componentRef = useRef();
    const {local_purchase_order_id} = match.params;

    useEffect(() => {
        const formData = new FormData();
        formData.append('local_purchase_order_id', local_purchase_order_id);
        axios.post(`${config.smsUrl}/cinventory/retrieve_purchase_order`, formData).then(res => {
            const data = res.data;
            const all_data = !data ? {} : data;
            setPurchaseOrder(all_data);
        }).catch(e => {
            logoutErrorMessage(e, null, actions)
        })
    }, []);

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    return { purchaseOrder,  componentRef, closeSnackbar}
}