import React, {useEffect, useState} from "react";
import axios from "axios";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {config} from "../../../Helpers/env";
import {ErrorMessage, RightAlignedContainer} from "../../Utils/styledComponents";
import {ConfirmationDialog} from "../../Utils/ReusableComponents/ConfirmationDialog";
import {BlockNavigationComponent} from "../../Utils/ReusableComponents/BlockNavigationComponent";
import {history} from "../../../Helpers/history";
import {DataCell, ModuleTable} from "../../Utils/Lists/CollapsibleList";
import TableRow from "@material-ui/core/TableRow";
import {CustomSelect} from "../../Utils/FormInputs/SelectInput";
import TextField from "../../Utils/FormInputs/TextField";
import InputGroup from "react-bootstrap/InputGroup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus} from "@fortawesome/free-solid-svg-icons/faMinus";
import {StyledCell} from "../doctorStyles";
import CustomDialog from "../../Utils/Dialogs/CustomDialog";

const headData = [{item:'Service', toolTip:'Enter service name', isRequired:true},
    {item:'Quantity', toolTip:'Enter service quantity', isRequired:true},
    {item:'Cost',  toolTip:'Enter cost'}, {item:'Department'},
    // {item:'Doctor Name'},
    {item:'Action'}]
function NewAntenatalService({actions, openDialog, setOpenDialog, visit_id, isSubmitted, setIsSubmitted}) {
    const initialState = [{currency:'', currency_symbol:'',cost:'',
         service: {value:'', label:'Select service...'}, department_name: '', department_id: '', doctor_name: {value:'', label:'Select doctor...'}, quantity:1
    }]

    const [services, setServices] = useState([])
    const [addedServices, setAddedServices] = useState(initialState)
    const [submitted, setSubmitted] = useState(false);
    const [doctors, setDoctors] = useState([])
    const [openMultiService, setOpenMultiService] = useState(false)
    const [multi_service_id, setMultiServiceId] = useState('')
    const [isBlockDialog, setIsBlockDialog] = useState(false)
    const [isBlocking, setIsBlocking] = useState(false)

    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;



    useEffect(() => {
        const formData = new FormData();
        formData.append('service_type', 'Ante Natal Care');
        axios.post(`${config.smsUrl}/cvisit/retrieve_services_by_service_type`, formData)
            .then(res => {
                const details = res.data;
                const data = !details ? [] : details;
                const arr = data.map(item => ({
                    value: item.service_id,
                    label: item.service_name
                }))
                setServices(arr)
            }).catch(error => {
            errorMessages(error, null, actions)
        });
    }, []);


    const handleRetrieveDoctors = (id) =>{
        const formData = new FormData();
        formData.append('department_id', id);
        axios.post(`${config.smsUrl}/cvisit/getdoctorsbydepartment_id`, formData)
            .then(response => {
                const res = response.data;
                const data = !res ? {} : res;
                const docs = !data.doctors ? [] : data.doctors;
                setDoctors(docs)
            })
            .catch(error => {
                logoutErrorMessage(error, null, actions)
            });
    }


    const handleChangeService = (value, idx)=>{
        let arr = addedServices.map((item, index)=>{
            if (index === idx){
                return {...item, service:value}
            }
            return item
        })

        const formData = new FormData();
        formData.append('service_id', value?.value);
        const endPoints = [
            {
                url:`${config.smsUrl}/cbilling/retrieve_department_by_service_id`,
                data: formData
            },
            {
                url:`${config.smsUrl}/cbilling/item_cost`,
                data: {visit_id :+visit_id, item_id: +value?.value, item_type:'service'}
            }
        ]
        Promise.all(endPoints.map((endpoint) => axios.post(endpoint.url, endpoint.data)))
            .then(([{data: departmentData}, {data: currencyData}] )=> {
                const data = !departmentData ? {} : departmentData;
                const {department_id, department_name} = data;
                if (department_id) {
                    handleRetrieveDoctors(department_id)
                }

                const visitObj = currencyData.data ? currencyData.data : {}

                arr = arr.map((item, index)=>{
                    if (index === idx){
                        return {...item,currency:visitObj.currency_id,
                            currency_symbol:visitObj.currency, cost:visitObj.cost, department_id, department_name,
                            ...visitObj}
                    }
                    return item
                })

                setAddedServices(arr)

                if(visitObj.is_provider === 1 && visitObj.is_provided === 0){
                    setMultiServiceId(value?.value)
                    setOpenMultiService(true)
                }
            })
        setIsBlocking(true)
    }

    const addMultiServiceToReceipt = () =>{
        setOpenMultiService(false)
    }

    const handleCloseMultiService = () =>{
        const arr = addedServices.filter(item=>item.service !== multi_service_id)
        setAddedServices(arr)
        setOpenMultiService(false)
    }

    const handleCancelMultiService = () =>{
        setOpenMultiService(false)
    }

    const handleChangeQuantity = (e, idx)=> {
        let arr = addedServices.map((item, index) => {
            if (index === idx) {
                return {...item, quantity: e.target.value}
            }
            return item
        })
        setAddedServices(arr)
    }

    const handleChangeDoctor = (value, idx) =>{
        let arr = addedServices.map((item, index)=>{
            if (index === idx){
                return {...item, doctor_name:value}
            }
            return item
        })
        setAddedServices(arr)
        setIsBlocking(true)
    }



    const handleAddRow =() => {
        setAddedServices([...addedServices, ...initialState])
        setIsBlocking(true)
    }

    const handleRemoveRow =(index)=>{
        if (addedServices.length > 1) {
            const arr = addedServices.filter((_, idx) => idx !== index)
            setAddedServices(arr);
        }
        setIsBlocking(true)
    }


    const handleSubmitAntenatalService = (event) => {
        event.preventDefault();
        const data = addedServices.map(item => ({
            service_id: +item?.service?.value,
            quantity: +item.quantity,
            rate: +item.cost,
            is_provided: item.is_provided,
            is_provider: item.is_provider,
            currency_id:item.currency_id,
            department_id:item.department_id,
            assigned_to:item?.doctor_name?.value
        }))

        const isArr = addedServices.every(proc => proc.service && proc.quantity);


        // required parameters
        const  params = {
            visit_id: +visit_id, result_type: 3,bill_type:2,
            user_roles_id, patient_type: 1,
            bill_details:  data
        }

        setSubmitted(true);
        setIsBlocking(false)
        if (isArr) {
            setIsSubmitted('pending');
            axios.post(`${config.smsUrl}/antenatal/create_anc_service`, {...params}).then(() => {
                setIsSubmitted('resolved');
                actions.snackbarActions.snackSuccess(`Antenatal service(s) submitted successfully`);
                handleResetForm()
            }).catch((err) => {
                errorMessages(err, null, actions)
                setSubmitted(false);
                setIsSubmitted('rejected');
            });
        }

    };

    const handleResetForm = () =>{
        setOpenDialog(false)
        const arr = addedServices.map(item=>({
            ...item,
            currency:'', currency_symbol:'',cost:'',
            service: '', department_name: '', department_id: '', doctor_name: '',
            quantity:1
        }))
        setAddedServices(arr)
        setIsBlockDialog(false)
        setIsBlocking(false)
        setSubmitted(false);

    }

    const handleCloseMultiServiceDialog = () => {
        if(isBlocking){
            setIsBlockDialog(true)
        }else{
            handleResetForm()
        }
    };

    const isPending = isSubmitted === 'pending'

    const docs = doctors ? doctors : [];

    return  (
            <div>
                <BlockNavigationComponent
                    when={isBlocking}
                    shouldBlockNavigation={()=>{
                        return isBlocking;
                    }}
                    navigate={path=>history.push(path)}
                    dialogClose={isBlockDialog}
                    setIsBlockDialog={setIsBlockDialog}
                    clearForm={handleResetForm}
                />
                <ConfirmationDialog open={openMultiService} handleClose={handleCancelMultiService}
                                    title='Make cash payment'
                                    confirmItems={addMultiServiceToReceipt}
                                    removeItems={handleCloseMultiService} service='antenatal service'/>
              <form autoComplete="off" onSubmit={handleSubmitAntenatalService}>
                  <ModuleTable headData={headData}>
                      {addedServices.length > 0  && addedServices?.map((procedure, index) => (
                          <TableRow
                              key={index}
                          >
                              <DataCell variant='250'>
                                  <CustomSelect id='service_name' onChange={(val)=>handleChangeService(val, index)}
                                                value={procedure.service} options={services}
                                                data-test="service_name"/>
                                  {(submitted && !procedure.service) &&
                                      <ErrorMessage>Service is required</ErrorMessage>}
                              </DataCell>
                              <DataCell variant='200'>
                                  <TextField submitted={submitted}  id='service_quantity'
                                             value={procedure.quantity} name='quantity'
                                             data-test="service_quantity" onChange={e=>handleChangeQuantity(e, index)}/>
                                  {(submitted && procedure.quantity) &&
                                      <ErrorMessage>Quantity is required</ErrorMessage>}
                              </DataCell>
                              <DataCell variant='150'>
                                  <InputGroup>
                                      <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">{procedure.currency_symbol}</InputGroup.Text>
                                      <TextField  type="number" value={procedure.cost} style={{pointerEvents:'none'}}/>
                                  </InputGroup>
                              </DataCell>
                              <DataCell variant='200'>
                                  <TextField submitted={submitted}  id='visit_department'
                                             value={procedure.department_name} name='department_name'
                                             data-test="department_name" disabled={true}/>
                                  {(submitted && procedure.department_name) &&
                                      <ErrorMessage>Department is required</ErrorMessage>}
                              </DataCell>
                              {/*<DataCell variant='200'>*/}
                              {/*    <CustomSelect id='visit_doctor' onChange={(e)=>handleChangeDoctor(e, index)} name='doctor_name'*/}
                              {/*                  value={procedure.doctor_name} options={docs.map(doctor => ({*/}
                              {/*        value: doctor.user_roles_id,*/}
                              {/*        label: `${doctor.first_name} ${doctor.last_name}`*/}
                              {/*    }))}*/}
                              {/*                  data-test="doctor_name"/>*/}
                              {/*    {(submitted && procedure.doctor_name) &&*/}
                              {/*        <ErrorMessage>Doctor is required</ErrorMessage>}*/}
                              {/*</DataCell>*/}
                              <DataCell>
                                  <button onClick={() => handleRemoveRow(index)} type="button"
                                          className="btn btn-sm sms-btn-dismiss" data-testid="remove-row">
                                      <FontAwesomeIcon icon={faMinus}/></button>
                              </DataCell>

                          </TableRow>
                      ))}
                      <TableRow>
                          <StyledCell colSpan={headData.length}>
                              <button type="button" onClick={handleAddRow} className="btn btn-sm sms-gray-btn"
                                      id="add-row">Add another line</button>
                          </StyledCell>
                      </TableRow>
                  </ModuleTable>
                  <RightAlignedContainer>
                      <button type="submit" id='submit_form'
                              className="sms-btn btn btn-sm px-3 mt-3"  disabled={isPending}>{isPending ? 'Saving...' : 'Save Service'}</button>
                  </RightAlignedContainer>
              </form>

            </div>
        )


}

export default  NewAntenatalService


