import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { config } from '../../../../Helpers/env'
// import {errorMessages} from "../../../Helpers/ErrorMessages";


export const useExpenseItems = (actions) => {
    const [expensesItems, setExpensesItems] = useState([]);
    const [loading, setLoading] = useState('idle');


    useEffect(() => {
        (async () => {
            setLoading('pending')
            try {
                const res = await axios.get(`${config.smsUrl}/accounting/expense_types`)
                let _data = res.data
                let dt = _data.data ?? [];
                setExpensesItems(dt)
                setLoading('success')
            } catch (e) {
                // errorMessages(e, null, actions)
                setLoading('error')
            }
        })();
    }, [])



    const closeSnackbar = () =>{
        actions.snackbarActions.hideSnackbar()
    }



    const isLoading = loading === 'pending'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'



    return {expensesItems,  isLoading, isSuccess, isError, closeSnackbar}
}
