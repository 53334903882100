/** @jsxImportSource @emotion/react */   
import {css} from '@emotion/react';
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCoins} from "@fortawesome/free-solid-svg-icons/faCoins";
import PageTitle from "../../Utils/smsTitle";
import { Button,  MiniCenteredDiv, RightAlignedContainer } from '../../Utils/styledComponents'
import { PrintTemplate, ItemsTable } from '../../Utils/Templates/PrintTemplate'
import { usePrint } from '../../Utils/Templates/usePrint';
import * as colors from '../../../styles/colors';
import {formatDate} from '../../Utils/ConvertDate';
import { formatDigits, formatSsp} from '../../Utils/formatNumbers';
import Card from '@material-ui/core/Card'
import { useViewInvoice } from './hooks/useViewInvoice';
import { LoadingGif } from '../../Utils/Loader';
import {coloredInvoice, textWeight, thUppercase} from "../../../styles/tableStyles";
import Form from "react-bootstrap/Form";
import {CurrencyToggle, FxRate} from "../../Utils/CurrencyToggle";
import React from "react";
import { BackButton } from '../../Utils/Buttons/DataExportationButton';

const titleText = css`
    margin: 0;
    color: ${colors.darkBlue001};
    font-size: 15px;
`


const borderless = css`
   border-bottom: 0;
`

const tableMargin = css`
    margin-bottom: 0;
`

function InvoiceReceipt({ match}) {
    const  {receipt, items, total_amount, isPending, isResolved, isRejected, isCurrency,
        exchangeRate, currencies, currency, handleChangeExchangeRate, handleChangeCurrency} = useViewInvoice(match)

    const {currency_total, usd_total} = total_amount

    const isBaseCurrency = currency === 'USD'
    const currency_symbol = !isBaseCurrency && exchangeRate ? currency : 'USD'

    const {componentRef, handlePrint, fontSize}  = usePrint(`InvoiceReceipt_${receipt.patient_number}`);

    const headData = [{key:'name',value:'Item'}, {key:'quantity',value:'Quantity'},
        {key:'rate',value:`Rate(${currency_symbol})`}, {key:'amount',value:`Amount(${currency_symbol})`}]
    const patientDetails = (
        <tbody>
            <tr>
                <td css={[textWeight, thUppercase]} ><p css={[titleText]}>Invoice To:</p></td>
                <td css={[textWeight, thUppercase]}><p css={[titleText]}>Invoice Details:</p></td>
            </tr>
            <tr>
                <td ><span>Name: </span> <span css={[textWeight]}>{receipt.billed_to ? receipt.billed_to : ''}</span></td>
                <td ><span>Date: </span> <span css={[textWeight]}>{receipt.date_created ?  formatDate(receipt.date_created ):''}</span></td>
            </tr>
            <tr>
                <td ><span>Card No.:</span> <span css={[textWeight]}>{receipt.card_number ? receipt.card_number : ''}</span></td>
                <td ><span>Patient Name: </span> <span css={[textWeight]}>{`${!receipt.patient_firstname ? "" : receipt.patient_firstname} 
                                    ${!receipt.patient_lastname ? "" : receipt.patient_lastname}`}
                </span> </td>
            </tr>
            <tr>
                <td><span>Account Holder: </span> <span css={[textWeight]}>{receipt.account_name}</span></td>
                <td><span>Contact: </span> <span css={[textWeight]}>{receipt.phone_no}</span></td>
            </tr>
        </tbody>
    )

    const itemDetails = (
        <ItemsTable {...{headData, coloredTableHead:coloredInvoice}}>
        <tbody>
        {items.map((item) => {
                return (
                    <tr key={item.bill_id}>
                        <td><span>{item.item_name}</span></td>
                        <td><span>{item.quantity}</span></td>
                        <td><span>{isBaseCurrency ? item.usd_rate :   item.currency_rate }</span></td>
                        <td><span>{isBaseCurrency ? item.usd_total: item.currency_total }</span></td>
                    </tr>
                )
            })}

            <tr>
                <td colSpan={headData.length - 1} align='right'><span css={[textWeight]}>Total Bill({currency_symbol})</span></td>
                <td><span css={[textWeight]}>{isBaseCurrency ? usd_total :  currency_total}</span></td>
            </tr>
        </tbody>
        </ItemsTable>
    )

    return (
        <div>
        <PageTitle title="Invoice"/>
        <SubHeader title="Invoice" subTitle="Invoice">
            <FontAwesomeIcon icon={faCoins}/>
        </SubHeader>
        {isPending ? <LoadingGif/>:null}
        {isResolved ?
            <MiniCenteredDiv>
                 <BackButton to='/paymentList' text='Billing List' data-testid="billing-receipt-back-button"/>
                <RightAlignedContainer>
                <Button variant='orange' onClick={handlePrint}>Print</Button>
                </RightAlignedContainer>
                <Card className='mt-2'>
                    <div style={{padding:'8px 20px', borderBottom:'1px solid rgba(224, 224, 224, 1)'}}>
                        <RightAlignedContainer>
                            <CurrencyToggle options={currencies} value={currency} handleChangeCurrency={handleChangeCurrency} />
                            { currency !== 'USD' ? <FxRate currency={currency !== 'USD' && currency} value={exchangeRate}  handleChangeRate={handleChangeExchangeRate} />: null}
                        </RightAlignedContainer>
                    </div>
                    <div ref={componentRef}  style={{padding:'20px'}} className="requisition-receipt">
                        <style>
                            {fontSize()}
                        </style>
                        <PrintTemplate title='INVOICE' {...{patientDetails,itemDetails, bioBordered:borderless, bioMargin:tableMargin}}/>
                    </div>
                </Card>
            </MiniCenteredDiv> : null}
            {isRejected ? <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                <p>The server did not return a valid response</p>
            </div>:null}
        </div>
    )
}

export default InvoiceReceipt