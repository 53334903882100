/** @jsxImportSource @emotion/react */
import {css, jsx} from '@emotion/react';
import React, {useEffect, useState} from "react";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCoins} from "@fortawesome/free-solid-svg-icons/faCoins";
import PageTitle from "../../Utils/smsTitle";
import Card from "@material-ui/core/Card";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../../actions/snackbarActions"
import { usePrint } from '../../Utils/Templates/usePrint';
import { Button, MiniCenteredDiv, RightAlignedContainer } from '../../Utils/styledComponents';
import { PrintTemplate, ItemsTable } from '../../Utils/Templates/PrintTemplate';
import { formatDateTime } from '../../Utils/ConvertDate';
import { BackButton } from '../../Utils/Buttons/DataExportationButton';
import { history } from '../../../Helpers/history';
import {CurrencyToggle, FxRate} from "../../Utils/CurrencyToggle";
import {groupProductId, groupServiceId} from "../utils/SanitizeArr";
import {useCurrency} from "../../../Context/currency-context";
import {useVersionsContext} from "../../../Context/versions-context";


const textWeight = css`
    font-weight:600;
`



export function ViewAllReceipts({actions, snackbars, match}) {
    const {currency:selectedCurrency} = useCurrency({actions, snackbars, match})
    const {visit_id} = match.params
    const [state, setState] = useState({patient_name: '', patient_number: '', address:'', phone_no:'', age:'', gender:''});
    const [totals, setTotals] = useState({paid_amount: 0, due_amount_total: 0,currency_id:'',
        total_bill_amount:0, currency_due_amount:0,currency_paid_amount:0, currency_total_amount:0})
    const [billDetails, setBillDetails] = useState([])
    const [bills, setBills] = useState([])
    const [info, setInfo] = useState({date_created:'', cashier:''})
    const [exchangeRate, setExchangeRate] = useState(0)
    const [currencyExchangeRate, setCurrencyExchangeRate] = useState(0)
    const [currency, setCurrency] = useState('')
    const [currencies, setCurrencies] = useState([])
    const [recordedCurrency, setRecordedCurrency] = useState({})

    const {subscription} = useVersionsContext()
    const version = subscription?.version
    const isPremium = version === 'Premium'

    useEffect(()=>{
        if (recordedCurrency.currency_symbol){
            return
        }
        setCurrency(`${selectedCurrency?.currency_symbol}-${selectedCurrency?.is_base_currency}`)
    },[recordedCurrency.currency_symbol])

    // get currencies
    useEffect(() => {
        axios.get(`${config.smsUrl}/accounting/currencies`).then(res => {
            const data = res.data;
            const all_data = !data ? {}: data;
            const arr = all_data.data ?? [];
            const list = arr.map(item=>({value:`${item.currency_symbol}-${item.is_base_currency}`, label:`${item.currency_symbol} ${item.currency_name}`}))
            setCurrencies(list)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, []);


    useEffect(() => {
        const formData = new FormData();
        formData.append('visit_id', visit_id);
        axios.post(`${config.smsUrl}/cbilling/view_receipt`, formData)
            .then(res => {
                const details = res.data;
                const data = !details ? {} : details;
                const rt = data.bill ?? [];
                const invoices = rt[0] ?? {};
                let obj = invoices.invoice ? invoices.invoice : {};
                const exchange_rate = obj.currency_rate ? obj.currency_rate : 0;

                const symbol = obj.currency_symbol ? obj.currency_symbol : selectedCurrency?.currency_symbol
                const is_base = !(obj.is_base_currency == null || undefined)  ? obj.is_base_currency : selectedCurrency.is_base_currency

                const ex_rate = Math.pow(exchange_rate, -1)
                const p_receipt = data.patient_info ?? {};
                const item = p_receipt ?? {};
                const receipt = rt[rt.length-1] ?  rt[rt.length-1]  : {};
                const {receipts} = receipt;
                const [receiptObj] = receipts
                const date = receiptObj.date ? receiptObj.date : '';
                const cashier = `${!receiptObj.first_name ? "" : receiptObj.first_name} ${!receiptObj.last_name ? "" : receiptObj.last_name}`
                const { patient_number, address, phone_no,patient_firstname,patient_lastname, gender, age} = item
                const patient_name = `${!patient_firstname ? "" : patient_firstname} ${!patient_lastname ? "" : patient_lastname}`;



                setState({...state, patient_name, patient_number, address, phone_no, gender, age})
                setBillDetails(rt)
                setInfo({date_created:date, cashier})
                setCurrency(`${symbol}-${is_base}`)
                setExchangeRate(exchange_rate)
                setCurrencyExchangeRate(ex_rate)
                setRecordedCurrency(obj)
            }).catch(error => {
            logoutErrorMessage(error, null, actions)
        });
    }, []);


    const totalCurrency = (arr=[]) =>{
        return arr.reduce((a, item) => {
            return (+a + item.usd_total)
        }, 0)
    }

    const totalNonCurrency = (arr=[]) =>{
        return arr.reduce((a, item) => {
            return (+a + item.currency_total)
        }, 0)
    }

    const calculateTotalAmount = (arr,exchange_rate) => {
        const obj = arr[0] ? arr[0] : {};
        // const exchange_rate = obj.exchange_rate ? obj.exchange_rate : 0;
        const t_amount = totalCurrency(arr)

        const p_total = arr.reduce((sum, item) => {
            return +item.total_paid + +sum
        }, 0)

        const currency_total = totalNonCurrency(arr)
        const currency_paid = exchange_rate ?  p_total / exchange_rate : p_total
        const currency_due = currency_total - currency_paid

        const due_amt = t_amount - p_total
        setTotals({...totals,paid_amount: p_total, due_amount_total:due_amt,  total_bill_amount: t_amount,
            currency_total_amount: currency_total, currency_paid_amount: currency_paid, currency_due_amount: currency_due,
            currency_id: obj.currency_id})
    }

    const calculateTotal = (arr) => {
        return arr.reduce((sum, item) => {
            return +item.amount_paid + +sum
        }, 0)
    }

    const convertLists = (bill_details, ex_rate) =>{
        const arr =bill_details.map(item=>{
            const {invoice,receipts} = item;
            const qty = invoice.quantity ? invoice.quantity : 0;
            const rate = invoice.rate ? invoice.rate : 0
            const total = rate * qty
            return ({...invoice,
                item_name: invoice.service_id ? invoice.service_name : invoice.product_id ? invoice.product_name : '',
                other_currency_rate: ex_rate ?  invoice.rate / ex_rate : invoice.rate,
                usd_rate:invoice.rate,
                usd_total : total,
                currency_total:(ex_rate ?  invoice.rate / ex_rate : invoice.rate) *  (!invoice.quantity ? 0 : invoice.quantity),
                // total_mount:invoice.quantity * invoice.rate,
                total_paid:calculateTotal(receipts),
                // exchange_rate:invoice.currency_rate,
            })
        })
        const productArr = groupProductId(arr.filter(item=>item?.product_id))
        const serviceArr = groupServiceId(arr.filter(item=>item?.service_id))
        setBills( [...serviceArr, ...productArr])
        calculateTotalAmount(arr,ex_rate)
    }


    useEffect(() => {
        const obj = billDetails[0] ? billDetails[0] : {};
        const inv = obj['invoice'] ? obj['invoice'] : {}
        const exchange_rate = inv.currency_rate ? inv.currency_rate : 0;
        const ex_rate = exchange_rate > 0 ?  Math.pow(exchange_rate, -1) : 0
        setExchangeRate(exchange_rate)
        setCurrencyExchangeRate(ex_rate)
        convertLists(billDetails, exchange_rate)
    }, [billDetails]);



    //Change currency
    const handleChangeCurrency = (e) =>{
        setCurrency(e.target.value)
        setExchangeRate(0)
    }

    //Change exchange rate
    const handleChangeExchangeRate = (event) =>{
        const currency_ex_rate =  +event.target.value > 0  ?   1 / +event.target.value : 0
        setCurrencyExchangeRate(event.target.value)
        setExchangeRate(currency_ex_rate)
        convertLists(billDetails, currency_ex_rate)
    }

    const handleOnAfterPrint = () => {
        history.push('/patientbillinglist');
    }



    const {patient_name, patient_number, phone_no} = state;
    const {paid_amount, total_bill_amount, currency_total_amount, currency_paid_amount, currency_id} = totals;
    const {cashier, date_created} = info;
    const {componentRef, handlePrint, getPageMargins, fontSize}  = usePrint(`AllReceipts_${patient_number}`,
        handleOnAfterPrint);


    const isBaseCurrency = currency && currency.split('-')[1] === 'true'
    const currency_symbol = currency && currency.split('-')[0]

    const headData = [{key:'name',value:'Item'}, {key:'quantity',value:'Quantity'},
        {key:'rate',value:`Rate(${currency_symbol})`}, {key:'amount',value:`Amount(${currency_symbol})`}]

    const patientDetails = (
        <tbody>
            <tr>
                <td><span>Date: </span> <span css={[textWeight]}>{formatDateTime(date_created)}</span></td>
                <td><span>Cashier: </span> <span><strong>{cashier}</strong></span></td>
            </tr>
            <tr>
            <td><span>Patient Number: </span> <span css={[textWeight]}>{patient_number}</span></td>
            <td><span>Patient Contact: </span> <span css={[textWeight]}>{phone_no}</span></td>
            </tr>
            <tr>    
                <td><span>Patient Name: </span> <span css={[textWeight]}>{patient_name}
                </span> </td>
                <td></td>
            </tr>
        </tbody>
    )

    const itemDetails = (
        <ItemsTable {...{headData}}>
            <tbody>
            {bills.map((item) => {
                return (
                    <tr key={item.bill_id}>
                        {/* <td>{item.invoice_no}</td> */}
                        <td><span>{item.item_name}</span></td>
                        <td><span>{item.quantity}</span></td>
                        <td><span>{!isBaseCurrency ?  item.other_currency_rate.toFixed(3) : item.usd_rate.toFixed(3)}</span></td>
                        <td><span>{!isBaseCurrency ? item.currency_total.toFixed(3) : item.usd_total.toFixed(3)}</span></td>
                    </tr>
                )
            })}

                <tr>
                    <td colSpan={headData.length - 1} align='right'><span css={[textWeight]} >Total Amount({currency_symbol})</span></td>
                    <td ><span css={[textWeight]}>{!isBaseCurrency ? currency_total_amount.toFixed(3) :  total_bill_amount.toFixed(3)}</span></td>
                </tr>
                <tr>
                    <td colSpan={headData.length - 1} align='right'><span ><strong>Amount Received({currency_symbol})</strong></span></td>
                    <td ><span css={[textWeight]}>{!isBaseCurrency ? currency_total_amount.toFixed(3) :  total_bill_amount.toFixed(3)}</span></td>
                </tr>

            </tbody>
        </ItemsTable>
      )
      
    return (
        <div data-testid="billing-receipt">
            <PageTitle title="Receipts"/>
            <SubHeader title="Billing" subTitle="Receipts">
                <FontAwesomeIcon icon={faCoins}/>
            </SubHeader>
            <MiniCenteredDiv>
                <BackButton to='/paymentList' text='Billing List' data-testid="view-all-receipts-back-button"/>
                <RightAlignedContainer>
                <Button variant='orange' onClick={handlePrint}>Print</Button>
                </RightAlignedContainer>
                <Card className='mt-2'>
                    {isPremium ? <div style={{padding:'8px 30px', borderBottom:'1px solid rgba(224, 224, 224, 1)'}}>
                        <RightAlignedContainer>
                            <CurrencyToggle options={currencies} value={currency} handleChangeCurrency={handleChangeCurrency} />
                            { !isBaseCurrency ? <FxRate currency={selectedCurrency?.currency_symbol} value={currencyExchangeRate}  handleChangeRate={handleChangeExchangeRate} nonBaseCurrency={currency_symbol}/>: null}
                        </RightAlignedContainer>
                    </div>: null}
                    <div  ref={componentRef} className="requisition-receipt" style={{padding:'0 30px'}}>
                        <style>
                            {getPageMargins()}
                            {fontSize()}
                        </style>
                        <PrintTemplate title='RECEIPT' {...{patientDetails, itemDetails}}/>
                    </div>
                </Card>
            </MiniCenteredDiv>
        </div>
    )

}

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewAllReceipts);
