import CustomDialog from "../Dialogs/CustomDialog";
import React from "react";
import {RightAlignedContainer} from "../styledComponents";

function ConfirmationDialog({open, handleClose, title, service, confirmItems, removeItems}) {
    return(
        <CustomDialog open={open} handleClose={handleClose} title={title}>
            <p style={{marginLeft:0, marginRight:0}}>This {service} is not on your insurance cover, Are you willing to pay for it ?</p>
            <RightAlignedContainer>
                <button onClick={confirmItems}  className='btn sms-btn btn-sm mr-3'>Yes</button>
                <button  onClick={removeItems} className='btn sms-gray-btn btn-sm'>No</button>
            </RightAlignedContainer>
        </CustomDialog>
    )
}

export {ConfirmationDialog}