import React from 'react';
import {HospitalAddress} from "./HospitalAddress";
import {useTheme} from "@material-ui/core/styles";


const ReceiptTemplate = ({name,footer,children, message, noApprove}) =>{
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    
    return (
        <>
            
            <table className='table table-sm table-borderless prescription-table'>
                <thead>
                <tr>
                    <td>
                    <HospitalAddress/>
                    <div className="page-header-space"></div>
                    </td>
                </tr>
                </thead>
                <tbody>
                {children}
                </tbody>
                <tfoot>
                    <tr>
                        <td>
                        <div className="page-footer-space"></div>
                        </td>
                    </tr>
                    {/*<tr>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>*/}
                </tfoot>
            </table>

            <table className="table table-borderless table-sm footer-table">

                {footer && <tr>
                    <td align="left" className={palette.type === 'dark' && 'dark-theme-color'}><p>{message}</p></td>
                    {!noApprove && <td align="center" className={palette.type === 'dark' && 'dark-theme-color'}>
                        <p>Approved By </p></td>}
                </tr>}
                {footer && <tr>
                    <td className={palette.type === 'dark' && 'dark-theme-color'} ><p
                        className={`doctor-stamp-left mt-3 ${!name ? 'mt-3' : ''}  ${palette.type === 'dark' && 'dark-doctor-stamp'}`}
                        >{name}</p></td>
                    {!noApprove && <td align="right" className={palette.type === 'dark' && 'dark-theme-color'}><p
                        className={`doctor-stamp-right mt-3 ${palette.type === 'dark' && 'dark-doctor-stamp'}`}
                        /></td>}
                </tr>}
                <tr/>
                {/*<tr>*/}
                {/*    <td/>*/}
                {/*    <td align="left" className="text-small">Powered by Mpeke HMIS Marascientific&copy;</td>*/}
                {/*</tr>*/}
            </table>

            {/*<table className="table table-borderless table-sm mpeke-footer">
                <tfoot>
                    <tr>
                        <td align="left" className="text-small">Powered by Mpeke HMIS Marascientific&copy;</td>
                    </tr>
                </tfoot>
            </table>*/}

            <div className="table table-borderless table-sm mpeke-footer">
                <text  className="text-small">Powered by Mpeke HMIS Marascientific&copy;</text>
            </div>

        </>

    )
}
export default ReceiptTemplate;