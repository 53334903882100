import axios from "axios";
import * as types from "../actionTypes";
import { config } from "../../Helpers/env"
import {history} from "../../Helpers/history";

const error = (err, dispatch) => {
    const response = !err.response ? {} : err.response;
    const message = !response.data ? "" : response.data;
    const msg = !message.error ? "" : message.error;
    const msg1 = !message.message ? '' : message.message;
    dispatch({type: types.LOGIN_FAILURE});
    dispatch({type: types.ALERT_ERROR, message: msg ? msg : msg1})
}
const getPermissions = (dispatch)  => {
    axios.get(`${config.smsUrl}/admin/get_user_permissions`).then(res => {
        const data = res.data;
        const dt = data ? data : {};
        sessionStorage.setItem('permission', JSON.stringify(dt));
    }).catch(err => {
        error(err, dispatch)
    })
}
export const userLogin = (formData) => dispatch => {
    dispatch({type: types.LOGIN_REQUEST});
    return axios.post(`${config.smsUrl}/admin/login`, formData).then(res => {
        const data = res.data;
        const dt = data ? data : {};
        sessionStorage.setItem('user', JSON.stringify(dt));
        dispatch({type: types.LOGIN_SUCCESS, loggedUser: dt});
        dispatch({type: types.ALERT_SUCCESS, message: "User login successful"});
        getPermissions(dispatch)
        setTimeout(() => {
        history.push('/')
        }, 1000)

    }).catch((err) => {
        error(err, dispatch)
    })
};
