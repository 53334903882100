import React from "react";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CustomTable from "../../Utils/Tables/CustomTable";
import FilterResults from "react-filter-search";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {Link} from "react-router-dom";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import *  as snackbarActions from "../../../actions/snackbarActions";
import {faShippingFast} from "@fortawesome/free-solid-svg-icons/faShippingFast";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {usePurchaseOrders} from "./hooks/usePurchaseOrderList";
import {dateStringConvert} from "../../Utils/ConvertDate";
import {DataExportButton} from "../../Utils/Buttons/DataExportationButton";
import SelectInput from "../../Utils/FormInputs/SelectInput";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import AddIcon from '@material-ui/icons/Add';
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons/faPencilAlt";
import {useReusablePagination} from "../stock/hooks/useReusablePagination";
import { Container } from "../../DoctorsModule/doctorStyles";

const useStyles = makeStyles((theme) => ({
    icon: {
        width: '.95rem',
    },
    approved: {
        color: "green"
    },
    canceled: {
        color: '#D9512C'
    },
    pending: {
        color: "#F29339"
    },
    purchased: {
        color: "#1d5dd3"
    },
}));

const headData = [
    {id: 'sl', numeric: false, disablePadding: false, label: 'SL'},
    {id: 'purchaseDate', numeric: false, disablePadding: false, label: 'Date'},
    {id: 'orderNo', numeric: false, disablePadding: false, label: 'Purchase Order#'},
    {id: 'name', numeric: false, disablePadding: false, label: 'Supplier Name'},
    {id: 'tAmount', numeric: false, disablePadding: false, label: 'Total Amount'},
    {id: 'status', numeric: false, disablePadding: false, label: 'Status'},
    {id: 'action', numeric: false, disablePadding: false, label: 'Action'}
];

const PurchaseOrderList = ({actions, snackbars}) => {
    const {page, rowsPerPage, handleChangeRowsPerPage, handleChangePage} = useReusablePagination()
    const {
        totalAmount, purchaseOrders, loading, handleCloseBar, value, handleChangeValue,
        filteredList, searchValue, handleSearch, csvRef, downloadPdf, downloadCsv
    } = usePurchaseOrders(actions)
    const orders = !purchaseOrders ? [] : purchaseOrders;
    const {openBar, type, message} = snackbars;
    const perm = JSON.parse(sessionStorage.getItem('permission'));
    const permission = !perm ? {} : perm;
    const purchase_orders = !permission.purchase_orders ? {} : permission.purchase_orders;
    const headers = [{label: 'Order Date', key: ' create_date'}, {
        label: 'Order NO',
        key: '  local_purchase_order_id'
    },
        {label: 'Supplier', key: 'supplier_name'}, {label: 'Total Amount', key: '   grand_total'}]
    const classes = useStyles();
    return (
        <div className='journals' data-testid="purchase-order-list">
            <PageTitle title="Purchase Orders"/>
            <SubHeader title="Inventory" subTitle="Purchase orders">
                <FontAwesomeIcon icon={faShippingFast}/>
            </SubHeader>
            <Container>
                <div className="mb-3">
                    <Grid container spacing={0}>
                        <Grid item xs={6} md={8}>
                            <SelectInput value={value} onChange={handleChangeValue} defaultOption='All Purchase Orders'
                                        options={[{value: 0, text: 'Pending Approval'}, {value: 1, text: 'Approved'},
                                            {value: 2, text: 'Canceled'}]} style={{width: '25%'}}/>
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <Grid container spacing={2}>
                                <Grid item xs={6} md={4}>
                                    <div className="text-right">
                                        <Link to='/addpurchaseorder'>
                                            <button className='btn btn-sm sms-btn-outline py-1'><AddIcon
                                                classes={{root: classes.icon}}/> New
                                            </button>
                                        </Link>
                                    </div>
                                </Grid>
                                <Grid item xs={6} md={4}>
                                    <DataExportButton data={purchaseOrders} title='Purchase Order List' csvRef={csvRef}
                                                    exportCsv={downloadCsv} downLoadPdf={downloadPdf} headers={headers}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <div className="mui-tables">
                    {/*<DeleteDialog message="Purchase Order" openDialog={openDelete} handleClose={handleCloseDelete}*/}
                    {/*              text="purchase order" handleDelete={handleDelete}/>*/}
                    <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={handleCloseBar}/>
                    <CustomTable title="Purchase orders" page={page} rowsPerPage={rowsPerPage}
                                handleChangePage={handleChangePage}
                                headData={headData} handler={handleSearch} data={orders} colSpan={7}
                                handleChangeRowsPerPage={handleChangeRowsPerPage} filter>
                        {loading ? <TableBody><TableRow>
                            <TableCell colSpan="7" align="center"><img src="/images/smsloader.gif"
                                                                    alt="loader"/></TableCell>
                        </TableRow></TableBody> : orders.length > 0 ?
                            <FilterResults value={searchValue} data={orders}
                                        renderResults={results => (
                                            <TableBody>
                                                {filteredList(results).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    .map((d) => {
                                                        return (
                                                            <TableRow
                                                                hover
                                                                role="checkbox"
                                                                tabIndex={-1}
                                                                key={d.local_purchase_order_id}
                                                                className='mui-table-row'>
                                                                <TableCell component="th" scope="row" padding="default"
                                                                            className='mui-table-cell'>
                                                                    {d.sl}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {d.create_date ? dateStringConvert(d.create_date) : ''}
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Link
                                                                        to={{pathname: `/purchaseorderapproval/${d.local_purchase_order_id}/${d.status}`}}>
                                                                        {d.purchase_order_number}</Link>
                                                                </TableCell>
                                                                <TableCell>
                                                                    {d.supplier_name}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {d.grand_total}
                                                                </TableCell>
                                                                <TableCell
                                                                    className={d.status === 0 ? classes.pending : d.status === 1 ? classes.approved :
                                                                        d.status === 2 ? classes.canceled : d.status === 3 ? classes.purchased : ''}>
                                                                    {d.status === 0 ? 'PENDING APPROVAL' : d.status === 1 ? 'APPROVED' :
                                                                        d.status === 2 ? 'CANCELED' : d.status === 3 ? 'FULFILLED' : ''}
                                                                </TableCell>
                                                                <TableCell id="mainbuttons">
                                                                    {/*{(d.status === 0 || d.status === 2) ? */}
                                                                        <Link
                                                                        to={{pathname: `/editpurchaseorder/${d.local_purchase_order_id}/${d.status}`}}>
                                                                        <button
                                                                            className="btn pharmacy-info-btn btn-sm mr-1 ">
                                                                            <FontAwesomeIcon
                                                                                icon={faPencilAlt}/>
                                                                        </button>
                                                                    </Link>
                                                                    {/*: null}*/}
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                                <TableRow>
                                                    <TableCell colSpan={4} className="text-right">
                                                        Total:
                                                    </TableCell>
                                                    <TableCell>{totalAmount}</TableCell>
                                                    <TableCell/>
                                                    <TableCell/>
                                                </TableRow>
                                            </TableBody>
                                        )}/> : <TableBody><TableRow>
                                <TableCell colSpan="7" align="center">No records found</TableCell>
                            </TableRow></TableBody>}
                    </CustomTable>
                </div>
            </Container>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderList);
