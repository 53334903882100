import React, {useEffect, useState} from 'react';
import axios from "axios";
import { config } from "../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {history} from "../../../Helpers/history";


export const useOutPatientTriage = (actions, metric_id, table) =>{

    const [allMetrics, setAllMetrics] = useState([])
    const [{metric_name, uom}, setNewMetric] = useState({metric_name: '', uom: ''});
    const [submitMetric, setSubmitMetric] = useState(false);
    const [isMetric, setIsMetric] = useState(false);
    const [isMetricSubmit, setIsMetricSubmitted] = useState('idle');
    const [openDialog, setDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState('');
    const [metric, setMetric] = useState({});
    const [metricDialog, setMetricDialog] = useState(false);
    const [isSaveTriage, setSaveTriage] = useState(false);

    useEffect(() => {
        if (isSaveTriage === true) {
            !status && setLoading(true)
            const url = table ? 'get_metrics' : 'retrieve_all_metrics'
            axios.get(`${config.smsUrl}/cvisit/${url}`).then(res => {
                const data = res.data;
                const dt = data ? data : {};
                if (table) {
                    const mets = dt.data ? dt.data : [];
                    setAllMetrics(mets)
                } else {
                    const metrics = dt.metrics ? dt.metrics : [];
                    setAllMetrics(metrics)
                }
    
                setLoading(false)
            }).catch(err => {
                setLoading(false)
                logoutErrorMessage(err, null, actions)
            })
        }
    }, [isMetric, status, isSaveTriage])

    useEffect(() => {
        if (metric_id) {
            const formData = new FormData();
            formData.append('metric_id', metric_id)
            axios.post(`${config.smsUrl}/cvisit/metrics_update_form`, formData).then(res => {
                const data = res.data;
                const dt = data ? data : {};
                setMetric(dt)
            }).catch(err => {
                logoutErrorMessage(err, null, actions)
            })
        }
    }, [])

    useEffect(() => {
        const {metric_name, uom} = metric
        setNewMetric({metric_name, uom})
    }, [metric])

    const handleOpenDialog = () => {
        setDialog(true)
    }
    const handleCloseDialog = () => {
        setDialog(false)
    }

    const handleOpenMetricDialog = () => {
        setMetricDialog(true)
    }
    const handleCloseMetricDialog = () => {
        setMetricDialog(false)
    }

    const handleChangeMetric = (event) => {
        const {value, name} = event.target;
        setNewMetric(prevState => ({...prevState, [name]: value}))
    }

    const handleDelete = (_id, st) => {
        const formData = new FormData();
        formData.append('metric_id', _id);
        formData.append('status', st);
        axios.post(`${config.smsUrl}/cvisit/deactivate_metric`, formData).then(() => {
            const message = st === 0 ? "Metric deactivated successfully" : 'Metric activated successfully'
            actions.snackbarActions.snackSuccess(message);
            setStatus(prevState => prevState + st)
        }).catch(err => {
            errorMessages(err, null, actions)
        });
    };

    const handleSubmitMetric = (event) => {
        event.preventDefault()
        const formData = new FormData();
        metric_id && formData.append('metric_id', metric_id);
        formData.append('metric_name', metric_name);
        formData.append('uom', uom);
        setSubmitMetric(true);
        if (metric_name && uom) {
            setIsMetricSubmitted('pending');
            const url = metric_id ? 'edit_new_metrics' : 'new_triage_metrics'
            axios.post(`${config.smsUrl}/cvisit/${url}`, formData).then(res => {
                const data = res.data;
                const dt = data ? data : {};
                const metric = dt.data ? dt.data : {};
                setAllMetrics([...allMetrics, metric])
                setIsMetric(true)
                setIsMetricSubmitted('resolved')
                actions.snackbarActions.snackSuccess(`Metric ${metric_id ? 'updated' : 'added'} successful`);
                setDialog(false)
                if (metric_id) {
                    history.push('/triagemetrics')
                }
                setMetricDialog(false)
            }).catch(err => {
                errorMessages(err, null, actions)
                setDialog(false)
                setIsMetricSubmitted('rejected')
            })
        }
    }

    return {allMetrics, metric_name, uom, submitMetric, isMetric,metricDialog,
        handleOpenMetricDialog,handleCloseMetricDialog, setAllMetrics, setSaveTriage,
        isMetricSubmit, openDialog, handleOpenDialog, handleCloseDialog,
        handleChangeMetric, handleSubmitMetric, loading, handleDelete}
}
