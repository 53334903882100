import React, {useState} from "react";
import PageTitle from "../Utils/smsTitle";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../actions/snackbarActions";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import {faUserMd} from "@fortawesome/free-solid-svg-icons/faUserMd";
import {useDoctorSchedule} from "./CustomHooks/useDoctorSchedule";
import moment from "moment";
import {Calendar, Views, momentLocalizer} from 'react-big-calendar'
import "react-big-calendar/lib/css/react-big-calendar.css";
import ReusablePopOver from "../Utils/Dialogs/ReusablePopOver";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";

export const useCalenderStyles = makeStyles((theme) => ({
    typography: {
        padding: theme.spacing(0,2),
    },
    span:{
        padding:theme.spacing(0, 2),
        marginBottom:'20px',
        display:'inline-block'
    }
}));
export const formatDate = (date) => moment(date).format('dddd, MMMM Do YYYY');
export const formatTime = (time) => moment(time).format('LT')

export const DoctorSchedule = ({actions, snackbars}) => {
    const classes = useCalenderStyles();
    const localizer = momentLocalizer(moment)
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const {schedules,  closeSnackbar, loading} = useDoctorSchedule(actions, _token)
    const [anchorEl, setAnchorEl] = useState(null);
    const [{event,date,resource, description}, setDetails] = useState({event:'', date:'', resource:'', description:''})
    const handleClick = (e,event) =>{
        const {title, resource, start, end} = e;
        setAnchorEl(event.currentTarget);
        setDetails({event:title, resource:resource.split('-')[0],description:resource.split('-')[1],date: `${formatDate(start)}, ${formatTime(start)} - ${formatTime(end)}`})
    }
    const handleClose = () =>{
        setAnchorEl(null)
    }

    const {openBar, type, message} = snackbars;
    const doctor_name = `${!_token.first_name ? "" : _token.first_name} ${!_token.last_name ? "" : _token.last_name}`;
    return (
        <div>
            <PageTitle title="Doctor Schedule"/>
            <SubHeader title="Doctor" subTitle={`Dr.${doctor_name}'s schedule`}>
                <FontAwesomeIcon icon={faUserMd}/>
            </SubHeader>
            <div className="mui-tables">
                <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>
                <ReusablePopOver handleClose={handleClose} anchorEl={anchorEl}>
                    <Typography variant="h5" className={classes.typography}>{event}</Typography>
                    <Typography variant="span" className={classes.span}>{date}</Typography>
                    {description ? <Typography className={classes.typography}>{description}</Typography>: ''}
                    <Typography className={classes.typography}>{`Dr. ${resource}`}</Typography>
                </ReusablePopOver>
                {loading === 'pending' ? <div className='text-center mt-5'><img src="/images/listPreloader.gif"
                                                   alt="loader" style={{width: '10%'}}/></div> : loading === 'resolved' && schedules.length > 0 ?
                    <div className='px-3'>
                        <Calendar selectable
                                  popup
                                  localizer={localizer}
                                  events={schedules}
                                  defaultView={Views.MONTH}
                                  views={['month', 'week', 'agenda']}
                                  style={{height: "80vh"}}
                                  onSelectEvent={(event, e) => handleClick(event,e)}
                                  // components={{
                                  //     toolbar: RBCToolbar
                                  // }}
                        />
                    </div> : <div className='text-center mt-5'>No events scheduled</div>}
            </div>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(DoctorSchedule);
