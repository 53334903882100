import React, {useEffect, useState} from 'react';
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import CustomTable from "../../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {titleCase} from "../../Users/addUser";
import {Link} from "react-router-dom";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../../actions/snackbarActions";
import * as billingActions from "../../../actions/BillingActions/BillingActions";
import {connect} from "react-redux";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {useTheme} from '@material-ui/core';
import PageTitle from '../../Utils/smsTitle';
import {SubHeader} from '../../../Containers/SubHeader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCoins} from '@fortawesome/free-solid-svg-icons/faCoins';
import {history} from "../../../Helpers/history";
import {ReusableRouteTabs} from "../../Utils/Dialogs/ReusableTabs";
import {dateConvert, dateStringConvert} from "../../Utils/ConvertDate";
import {DateFilter} from "../utils/DateFilter";

const billingHeadData = [{id: 'no', numeric: false, disablePadding: false, label: 'SL'},
    {id: 'patientNumber', numeric: false, disablePadding: false, label: 'Date'},
    {id: 'patientName', numeric: false, disablePadding: false, label: 'Provider Name'},
    {id: 'patientType', numeric: false, disablePadding: false, label: 'Provider Type'},
    {id: 'actions', numeric: false, disablePadding: false, label: 'Action'}
];

const Creditors = (props) => {
    const {actions} = props;
    const theme = useTheme();
    const {palette} = theme;
    const {openBar, type, message} = props.snackbars;
    const [term, setTerm] = useState('')
    const [activePage, setActivePage] = useState(1)
    const [billingList, setBillingList] = useState([])
    const [totalCount, settotalCount] = useState(0)
    const [loading, setLoading] = useState(false)
    const [listDisplay, setListDisplay] = useState(1)
    const [date, setDate] = useState({start_date:dateConvert(), end_date:dateConvert()})
    const {start_date, end_date} = date


    useEffect(() => {
        const formData = new FormData();
        const search = term ? titleCase(term) : ''
        formData.append('search', search);
        formData.append('status', 0);
        formData.append('start_date', start_date);
        formData.append('end_date', end_date);
        setLoading(true)
        const url = listDisplay === 1 ? 'credit_customer' : 'product_credit_customer'
        axios.post(`${config.smsUrl}/cpatient/${url}/${activePage}`, formData).then(res => {
            const data = res.data;
            const bills = !data ? {} : data;
            const all_bills = !bills.credit_list ? [] : bills.credit_list;
            const t_count = !bills.total_count ? 0 : bills.total_count;
            setBillingList(all_bills)
            settotalCount(t_count)
            setLoading(false)
        }).catch(err => {
            logoutErrorMessage(err, null, actions);
            setLoading(false)
        })
    }, [activePage, term, listDisplay, start_date, end_date])

    const closeSnackBar = () => {
        const {actions} = props;
        actions.snackbarActions.hideSnackbar();
    };


    const handler = (event) => {
        setTerm(event.target.value)
    }

    const handleChangeActivePage = (val) => {
        setActivePage(val)
    };

    const handleChangeDate = (event) =>{
        const {name, value} = event.target;
        setDate({...date,[name]:value})
    }

    const components = [{label: 'Out-patient', path: '/paymentList'},
        {label: 'In-patient', path: '/patientbillinglist'},
        {label: 'Invoiced Bills', path: '/invoicedbills'},
        {label: 'Debtors', path: '/debtors'},
      
    ]

    return (
        <div className='journals'>
            <PageTitle title="Debtors"/>
            <SubHeader title="Billing" subTitle="Debtors">
                <FontAwesomeIcon icon={faCoins}/>
            </SubHeader>
            <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/>
            <div className="mui-tables">
                <DateFilter {...{start_date, end_date,  handleChangeDate}}/>
                <MainSnackbar handleCloseBar={closeSnackBar} message={message} open={openBar} variant={type}/>
                <CustomTable title="Debtors" colSpan={7}
                             data={billingList}
                             headData={billingHeadData}
                             handler={handler} activePage={activePage}
                             handleChangeNextPage={handleChangeActivePage} total_count={totalCount}
                             records={10} customPage term={term}>
                    {loading ? <TableBody><TableRow>
                        <TableCell colSpan="9" align="center"><img src="/images/smsloader.gif"
                                                                   alt="loader"/></TableCell>
                    </TableRow></TableBody> : billingList.length > 0 ?
                        <TableBody>
                            {billingList.slice(0, 10)
                                .map((bill, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    const count = ((activePage - 1) * 10) + index + 1;
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={bill.patient_number}
                                            className='mui-table-row'>
                                            <TableCell component="th" id={labelId} scope="row"
                                                       padding="default"
                                                       className='mui-table-cell'>
                                                <span>{count}</span>
                                            </TableCell>
                                            <TableCell>
                                                <span>{dateStringConvert(bill.date_added)}</span>
                                            </TableCell>
                                            <TableCell>
                                                <span>{titleCase(bill.provider_name)}</span>
                                            </TableCell>
                                            <TableCell>
                                                <span>{bill.provider_type}</span>
                                            </TableCell>

                                            <TableCell>
                                                <Link to={{pathname: `/debtorsledger/${bill.provider_id}/${bill.pay_status}`
                                                }} style={{
                                                    textDecoration: "none",
                                                    color: palette.type === "dark" ? "#fff" : "#3d3d3d"
                                                }}>
                                                    <button className="btn btn-sm sms-info-btn mr-2 mb-2"  style={{marginBottom:'4px'}}>View</button>
                                                </Link>
                                                <Link to={{pathname: `/cleardebt/${bill.provider_id}/${bill.pay_status}`}} style={{
                                                    textDecoration: "none",
                                                    color: palette.type === "dark" ? "#fff" : "#3d3d3d"
                                                }}>
                                                    <button className="btn sms-amber-btn btn-sm mr-2 mb-2"  style={{marginBottom:'4px'}}>Pay</button>
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody> : <TableBody><TableRow>

                            <TableCell colSpan="9" align="center">No records found</TableCell>
                        </TableRow></TableBody>}
                </CustomTable>

            </div>
        </div>
    )
}

function mapStateToProps(state) {
    const {snackbar, billing} = state
    return {
        snackbars: snackbar, billing
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch),
            billingActions: bindActionCreators(billingActions, dispatch),
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Creditors)