import React from "react";
import {connect} from "react-redux";
import * as patientActions from "../../actions/PatientActions/patientActions";
import {bindActionCreators} from "redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as snackbarActions from "../../actions/snackbarActions";
import {SubHeader} from "../../Containers/SubHeader";
import {faWheelchair} from "@fortawesome/free-solid-svg-icons/faWheelchair";
import Card from "@material-ui/core/Card";
import PageTitle from "../Utils/smsTitle";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import {useRegisterPatient} from "./CustomHooks/useRegisterPatient";
import { PatientForm } from "./PatientForm";
import {RightAlignedContainer} from "../Utils/styledComponents";
import { BackButton } from "../Utils/Buttons/DataExportationButton";


const RegisterPatient = ({actions, snackbars, match}) => {
    const {patient_number} = match.params;
    const {isSubmitted, closeSnackBar, handleReset, patientProps,handleSavePatient,
    } = useRegisterPatient(actions, patient_number);
    const {openBar, type, message} = snackbars;
    const isRegister = patient_number ? false : true;
    return (
        <div>
            <PageTitle title={patient_number ? 'Update Patient Profile' :"Patient Registration"}/>
            <SubHeader title="Patient" subTitle={patient_number ? 'Update Patient Profile' :"Patient Registration"}>
                <FontAwesomeIcon icon={faWheelchair}/>
            </SubHeader>
            {patient_number ? 
                <div style={{margin: '10px'}}>
                    <BackButton text='Patient Profile' to={`/patientProfile/${patient_number}`}/>
                </div>
            : null}
            <div className="patient-section">
                <MainSnackbar handleCloseBar={closeSnackBar} open={openBar} variant={type} message={message}/>
                <Card className="p-3 general-card">
                    <PatientForm {...{...patientProps,handleSavePatient, isRegister}}>
                        <RightAlignedContainer>
                            <button
                                id="resetBtn"
                                type="button"
                                className="btn btn-sm sms-grey-btn px-4 mr-2"
                                onClick={handleReset}
                            >
                                Reset
                            </button>
                            <button
                                id="submitBtn"
                                type="submit"
                                disabled={isSubmitted === 'pending' || isSubmitted === 'resolved'}
                                className="btn btn-sm sms-btn px-4"
                            >
                                {isSubmitted === 'pending' ? "Saving..." : "Save"}
                            </button>
                        </RightAlignedContainer>
                    </PatientForm>
                </Card>

            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {patients: state.patients, snackbars: state.snackbar};
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            patientAction: bindActionCreators(patientActions, dispatch),
            snackbarActions: bindActionCreators(snackbarActions, dispatch),
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPatient)

