import {useEffect, useRef, useState} from "react";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {exportPDF} from "../../Utils/downloadPDF";
import { useReceiveStock } from "./useReceiveStock";
import { useStockAdjustment } from "../../Inventory/stock/hooks/useStockAdjustment";
import { useAddRequisition } from "../../Inventory/stock/hooks/useAddRequisition";

export const usePharmacyStock = (actions, isResolvedDispatch) =>{
    const [searchValue, setSearchValue] = useState('');
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('sl');
    const [loading, setLoading] = useState('idle');
    const [stock, setList] = useState([]);
    const [total_count, setCount] = useState(0);
    const [activePage, setActivePage] = useState(1)
    const [refresh, setRefresh] = useState(false);
    const [allRecords, setAllRecords] = useState([])
    const csvRef = useRef();
    const {openDialog,setOpenDialog, isResolved,stockProps,setInvoice} = useReceiveStock(actions)
    const {adjustmentProps, isResolved:isResolvedAdjustment, isCsvResolved} = useStockAdjustment(actions)
    const [isSetup, setIsSetup] = useState('')
    const [activeStatus,  setActiveStatus] = useState(1)

    useEffect(()=>{
        setActivePage(1)
    },[activeStatus])

    useEffect(() => {
        setLoading('pending');
        const formData = new FormData();
        formData.append('category_id', activeStatus)
        formData.append('search', searchValue)
        formData.append('page', activePage)
        formData.append('to_store', "Pharmacy")
        axios.post(`${config.smsUrl}/cpharmacy/pharmacy_stock`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const st = !dt.stock ? [] : dt.stock;
            const count = !dt.total_count ? 0 : dt.total_count;
            setCount(count)
            setList(st);
            setLoading('success');
        }).catch((err) => {
            logoutErrorMessage(err, null, actions);
            setLoading('error');
        })
    }, [activePage,searchValue, isResolved, isResolvedAdjustment, activeStatus,isResolvedDispatch,isCsvResolved]);

    useEffect(() => {
        axios.get(`${config.smsUrl}/cpharmacy/check_initial_setup`).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const message = dt.message ? dt.message : ''
            setIsSetup(message)
        }).catch((err) => {
            logoutErrorMessage(err, null, actions);
        })
    }, []);

    const retrieveAllRecords = () => {
        return axios.post(`${config.smsUrl}/cpharmacy/pharma_stock`)
    }

    const changeActiveStatus= (status) => {
        setActiveStatus(status)
    }

    const handleRefresh = () =>{
        setRefresh(!refresh)
    }

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const handleSearch = (event) => {
        setSearchValue(event.target.value)
    };

    const handleChangePage = (page) =>{
        setActivePage(page)
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleOpenDialog = () =>{
        setOpenDialog(true)
    }

    const handleCloseDialog = () =>{
        setOpenDialog(false)
        setInvoice('')
    }

    const downloadPdf = () => {
        const formData = new FormData();
        formData.append('search', searchValue)
        retrieveAllRecords().then(res => {
            const data = res.data;
            const items = !data ? [] : data;
            exportPDF("Pharmacy Stock List", [["SL",  "Product", "Sell Price", "In Quantity",
                    "Quantity Sold","Stock"]],
                items.map((item, index) => [index += 1, item.product_name, item.price,
                    item.totaldispatchedrecieved, item.totalsold, item.stock
                ]),
                "Pharmacy Stock List .pdf");
            setAllRecords(items);
        }).catch((err) => {
            logoutErrorMessage(err, null, actions);
        })

    }

    const downloadCsv = () => {
        retrieveAllRecords().then(res => {
            const data = res.data;
            const items = !data ? [] : data;
            setAllRecords(items);
            csvRef.current.link.click()
        }).catch((err) => {
            logoutErrorMessage(err, null, actions);
        })
    }

    const {
        requisitionProps, openEmmergency:openRequsition, setOpenEmmergency, handleCloseDialog:handleReqCloseDialog,
        openDialog:requisitionDialog, handleCancel:handleReqCancel, handleClick:handleReqClick, 
    } = useAddRequisition(actions, 'pharmacy');

    const handleOpenRequisitionDialog = () =>{
        setOpenEmmergency(true)
    }

    const handleCloseRequisitionDialog = () =>{
        setOpenEmmergency(false)
    }

    const isLoading = loading === 'pending';
    const isSuccess = loading === 'success';
    const isError = loading === 'error';
    const setupRequired = isSetup === "Initial Setup Required";

    return {searchValue, order, orderBy, isLoading,isSuccess,isError, 
        stock, total_count, activePage,setupRequired,allRecords,csvRef,
        downloadCsv,downloadPdf, closeSnackbar, handleSearch, handleChangePage,
        handleRequestSort, handleRefresh, openDialog,handleOpenDialog, handleCloseDialog,
        stockProps,adjustmentProps,  changeActiveStatus, activeStatus,

        requisitionProps, openRequsition, setOpenEmmergency, requisitionDialog,
        handleReqCancel, handleReqClick, handleReqCloseDialog,
        handleCloseRequisitionDialog, handleOpenRequisitionDialog
    }
}