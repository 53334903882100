import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import {useFileUploader} from "../../../PatientRecords/CustomHooks/useFileUploader";
import CustomDialog from "../../../Utils/Dialogs/CustomDialog";
import { CustomInput } from "../../../Inventory/CustomInput";
import Label from "../../../Utils/FormInputs/Label";
import Form from "react-bootstrap/esm/Form";
import {history} from "../../../../Helpers/history";

const useInvoiceProviders = (actions, isCreateInvoice=false) =>{
    const providerTypes = [{value:'Insurance Company', text:'Insurance Company'},
    {value:'Organisation', text:'Organisation'},
    {value:'Individuals', text:'Individuals'}];
    const [searchValue, setSearchValue] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [state, setState] = useState({provider_name:'', provider_type:'', discount: ''});
    const [providers, setProviders] = useState([]);
    const [loading, setLoading] = useState('idle');
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [openDialog, setOpenDialog] = useState(false);
    const [openDialogScheme, setOpenDialogScheme] = useState(false);
    const [contact, setContact] = useState('');
    const [debitLedger, setDebitLedger] = useState('')
    const [creditLedger, setCreditLedger] = useState('')
    const [accounts, setAccounts] = useState([]);
    const [providerOptions, setProviderOptions] = useState([]);
    const [isEditProvider, setEditProvider] = useState(false);
    const [isBlocking, setIsBlocking] = useState(false)
    const [isBlockDialog, setIsBlockDialog] = useState(false)
    const [scheme_id, setSchemeId] = useState('')
    const [openScheme, setOpenScheme] = useState(false)
    const [scheme_name, setName] = useState("");
    const [scheme,setScheme] = useState({})
    const [selectedOptions, setSelectedOptions] = useState([]); 
    const [options, setOptions] = useState([]); 
    const [submittedScheme, setSubmittedScheme] = useState(false);
    const [isSubmittedScheme, setIsSubmittedScheme] = useState('idle');
    const [openEdit, setOpenEdit] = useState(false)
    const [insurance_id, setInsuranceId] = useState('')
    // const [insuranceDetails, setInsuranceDetails] = useState({})
    const [selectedChecks, setSelectedChecks] = useState([])
    const [checkList, setCheckList] = useState([])

    const obj = {url:'cbilling/insurance_providers/upload_bulk', download_url:'cbilling/provider_csv', file_name:'Providers'}
    const {uploadProps, isResolved:isResolvedUpload} = useFileUploader(actions, obj)
    const formRef = useRef()

    const isPending = isSubmitted === 'pending';
    const isResolved = isSubmitted === 'resolved';
    const isRejected = isSubmitted === 'rejected';

    const isPendingScheme = isSubmittedScheme === 'pending';
    const isResolvedScheme = isSubmittedScheme === 'resolved';
    const isRejectedScheme = isSubmittedScheme === 'rejected';


    const isLoading = loading === 'pending';
    const isSuccess = loading === 'success';
    const isError = loading === 'error';

    const [currency, setCurrency] = useState('');
    const [currencies, setCurrencies] = useState([]); 



    useEffect(() => {
        if(!isCreateInvoice){
            return
        }
        axios.get(`${config.smsUrl}/cbilling/insurance_providers/list`).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const arr = !dt.data ? [] : dt.data;
            setProviderOptions(arr);
        }).catch(err => {
         logoutErrorMessage(err,null,actions)
        })
    }, [isCreateInvoice, isResolved]);
    
    useEffect(() => {
        if(!scheme){
            return
        }
        setName(scheme.name)
    }, [scheme]);

    useEffect(() => {
        if(isCreateInvoice){
            return
        }
        setLoading('pending');
        axios.get(`${config.smsUrl}/cbilling/insurance_providers`).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const arr = !dt.data ? [] : dt.data;
            const insuranceProviders = arr.map(item=>{
                const isInsurance = item.provider_type === 'Insurance Company'
                return {
                ...item,
                action:(
                    <>
                        <button className="btn btn-sm sms-info-btn mr-2" style={{marginRight: '10px', marginBottom:'5px'}}
                                onClick={()=>handleOpenEditInsurance(item)}>Update</button>
                        {isInsurance ? <button onClick={()=>history.push(`/items_provider_allows/${item.provider_id}/${item.provider_name}/1/${item.currency_id}-${item.currency_symbol}`)}
                                               className="btn btn-sm sms-btn mr-2" style={{marginRight: '10px', marginBottom:'5px'}}>Items List</button>: null}
                        <button onClick={()=>history.push(`/customer-transactions/${item.provider_id}/${item.provider_name}`)}
                                className="btn btn-sm sms-info-btn" style={{marginBottom:'5px'}}>View Transactions</button>
                    </>
                )
            }})
            setProviders(insuranceProviders);
            setLoading('success')
        }).catch(err => {
         logoutErrorMessage(err,null,actions)
            setLoading('error')
        })
    }, [isCreateInvoice, isResolved, isResolvedUpload]);

    // get currencies
    useEffect(() => {
        axios.get(`${config.smsUrl}/accounting/currencies`).then(res => {
            const data = res.data;
            const all_data = !data ? {}: data;
            const arr = all_data.data ?? [];
            const list = arr.map(item=>({value:`${item.id}-${item.is_base_currency}-${item.currency_symbol}`, text:`${item.currency_symbol} ${item.currency_name}`}))
            setCurrencies(list)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, []);

    //get scheme options
    useEffect(() => {
        axios.get(`${config.smsUrl}/insurance-scheme/`).then(res => {
            const data = res.data;
            const arr = data.items ? data.items:[]
            const list = arr.map(item => ({
                value: `${item.id}`,
                label: item.name,    
              }));
            setOptions(list)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, [isResolvedScheme]);

    //get checks
    useEffect(() => {
        axios.get(`${config.smsUrl}/insurance-claim-approval-checks/`).then(res => {
            const data = res.data;
            const arr = data.items ? data.items:[]
            const list = arr.filter(item=>!item.any_provider).map(item => ({
                value: `${item.id}`,
                label: item.name,
            }));
            setCheckList(list)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, []);

    const handleChangeInsuranceChecks = (value) =>{
        setSelectedChecks(value)
    }

    // useEffect(() => {
    //     if(!insurance_id){
    //         return
    //     }
    //     axios.get(`${config.smsUrl}/cbilling/insurance_providers/${insurance_id}`).then(res => {
    //         const data = res.data;
    //         const dt = !data ? {} : data;
    //         setInsuranceDetails(dt);
    //     }).catch(err => {
    //         logoutErrorMessage(err,null,actions)
    //     })
    // }, [insurance_id]);
    //
    // useEffect(()=>{
    //     if (!(insuranceDetails && insurance_id)){
    //         return
    //     }
    //     setState({
    //         provider_name: insuranceDetails?.InsuranceProviders?.provider_name,
    //         provider_type: insuranceDetails?.InsuranceProviders?.provider_type,
    //         discount: insuranceDetails?.InsuranceProviders?.discount
    //     }
    //     )
    //     setContact(insuranceDetails?.InsuranceProviders?.contact)
    //     const schemes = insuranceDetails?.schemes?.map(item=>({
    //         value:item.id,
    //         label:item.name
    //     }))
    //
    //     const checks = insuranceDetails?.checks?.map(item=>({
    //         value:item.id,
    //         label:item.name
    //     }))
    //     setSelectedOptions(schemes)
    //     setSelectedChecks(checks)
    //
    // },[insuranceDetails, insurance_id])

    const handleOpenEditInsurance = (item) =>{
        setInsuranceId(item.provider_id)
        setCurrency(`${item?.currency_id}-${item?.is_base_currency}-${item?.currency_symbol}`)
        setOpenEdit(true)
        axios.get(`${config.smsUrl}/cbilling/insurance_providers/${item.provider_id}`).then(res => {
            const data = res.data;
            const insuranceDetails = !data ? {} : data;
            setState({
                    provider_name: insuranceDetails?.InsuranceProviders?.provider_name,
                    provider_type: insuranceDetails?.InsuranceProviders?.provider_type,
                    discount: insuranceDetails?.InsuranceProviders?.discount
                }
            )
            setContact(insuranceDetails?.InsuranceProviders?.contact)
            const schemes = insuranceDetails?.schemes?.map(item=>({
                value:item.id,
                label:item.name
            }))

            const checks = insuranceDetails?.checks?.map(item=>({
                value:item.id,
                label:item.name
            }))
            setSelectedOptions(schemes)
            setSelectedChecks(checks)
            // setInsuranceDetails(dt);
        }).catch(err => {
            logoutErrorMessage(err,null,actions)
        })
    }

    const handleCloseEditInsurance = () =>{
        setOpenEdit(false)
    }

    const handleChangeDebitLedger = (value) =>{
        setDebitLedger(value)
        setIsBlocking(true)
    }

    const handleChangeCreditLedger = (value) =>{
        setCreditLedger(value)
        setIsBlocking(true)
    }

    const handleSearch = (searchValue) => {
        setSearchValue(searchValue)
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeCurrency = (event) => {
        setCurrency(event.target.value)
        setIsBlocking(true)
    }

    const handleChange = (event) =>{
        let {value, name} = event.target;
        if(name === 'discount'){
            value = value.replace(/\D/g, '') // making sure it's a number

            if(value > 100){
                return
            }
        }

        setState({...state,[name]:value});
    }

    const handleChangeOption = (value) =>{
        setSelectedOptions(value)
        
    }

    const handleOpenDialog = () =>{
        setOpenDialog(true)
    }

    const handleOpenDialogScheme = () =>{
        setOpenDialogScheme(true)
    }

    const handleCloseDialogScheme = () =>{
        setOpenDialogScheme(false)
    }

    const handleCloseDialog = () =>{
        if(isBlocking){
            setIsBlockDialog(true)
        }else{
            setOpenDialog(false)
            setIsBlockDialog(false)
            setIsBlocking(false)
        }
    }


    const handleOpenEdit = (_id,event) =>{
        setSchemeId(_id)
        setOpenScheme(true)
        axios.get(`${config.smsUrl}/insurance-scheme/`, {scheme_id: scheme_id}).then((res) => {
            const data = res.data;
            const list = data.items?  data.items: []
            const schemeToEdit = list.find((scheme) => scheme.id === _id);
              setScheme(schemeToEdit);

        }).catch(err => {
            logoutErrorMessage(err, null,actions)
        })
      
    }
  
    const handleCloseEdit = () =>{
        setOpenScheme(false)
        setSchemeId('')
        setName('')
    }

    const handleSubmitScheme = (event) =>{
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const fieldValues = Object.fromEntries(formData.entries())
        const requestMethod = scheme_id ? axios.put : axios.post
        const params={
               name: fieldValues['name']
        }
        setSubmittedScheme(true);
            setIsSubmittedScheme('pending');
            if( fieldValues['name']){
                requestMethod(`${config.smsUrl}/insurance-scheme/${scheme_id}`, params).then(()=>{
                    actions.snackbarActions.snackSuccess(`Scheme ${scheme_id ? 'updated': 'added'} successfully`)
                    setIsSubmittedScheme('resolved');
                setSubmittedScheme(false)
                setName("");
                setOpenDialogScheme(false)
                setOpenScheme(false)
            }).catch(err=>{
                setIsSubmittedScheme('rejected');
                errorMessages(err,null, actions)
            })
        }
        
    };

    const addProvider = (formData) =>{
        return axios.post(`${config.smsUrl}/cbilling/insurance_providers/add`, formData)
    }

    const updateProvider = (formData) =>{
        return axios.put(`${config.smsUrl}/cbilling/insurance_providers/${insurance_id}`, formData)
    }

    const handleSubmit = (event) =>{
        event.preventDefault();
        const {provider_name, provider_type, discount} = state;
        setSubmitted(true)
        const credit = creditLedger ? creditLedger : {};
        const credit_account = credit.value ? credit.value : '';
        const currency_id = parseInt(currency.split('-')[0]);
        const debit = debitLedger ? debitLedger : {};
        const debit_account = debit.value ? debit.value : '';
        const isInsurance = provider_type === 'Insurance Company'
        
        setIsBlocking(false)
        const isRequired = (isInsurance && selectedOptions?.length > 0) || !isInsurance
        if(provider_name && provider_type && currency_id && isRequired){
            setIsSubmitted('pending')
            const formData = new FormData(formRef.current);
            if (insurance_id){
                formData.append('insurance_id', insurance_id);
            }
            formData.append('contact', contact ? contact : '');
            formData.append('provider_name', provider_name);
            if (isInsurance){
                selectedOptions?.forEach((option) => {
                    formData.append('scheme_id', option?.value);
                });
                 selectedChecks?.forEach((option) => {
                    formData.append('claim_check_id', option?.value);
                })
            }else{
                formData.append('scheme_id', '');
                formData.append('claim_check_id', '');
            }
            formData.append('provider_category', provider_type);
            formData.append('credit_account', credit_account);
            formData.append('debit_account', debit_account);
            formData.append('currency_id', currency_id);
            formData.append('discount', discount);
            const postData = insurance_id ? updateProvider(formData) : addProvider(formData)
            postData.then(()=>{
                actions.snackbarActions.snackSuccess('Provider added successfully')
                setIsSubmitted('resolved')
                setOpenDialog(false)
                setState({provider_name:'', provider_type:'', discount: ''})
                setName('')
                setSubmitted(false)
                setCurrency('')
                setContact(undefined)
                setOpenEdit(false)
            }).catch(err=>{
                errorMessages(err, null, actions)
                setIsSubmitted('rejected')
            })
        }
    
    }

    const handleResetForm = () =>{
        setOpenDialog(false)
        setIsBlockDialog(false)
        setIsBlocking(false)
        setState({provider_name:'', provider_type:'', discount: 'null'})
        setSubmitted(false)
        setCurrency('')
        setContact(undefined)
    }

    const handleUpdateProvider = () => {
        // api call to save the provider
        setState({provider_name:'', provider_type:'', discount: ''});
        setContact('');
        handleCloseDialog();
    }

    const handleEditProvider = (item) => {
        setState(item);
        setContact(item.contact);
        setEditProvider(true);
        handleOpenDialog();
    }

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };



    const open = scheme_id ? openScheme : openDialogScheme
    const handleClose = scheme_id ? handleCloseEdit : handleCloseDialogScheme

    const title = scheme_id ? 'Edit Scheme' : 'Add Scheme'

    const providerProps = {
        state, handleChange, handleSubmit, contact, setContact, isPending, isRejected, isResolved, handleChangeCurrency,
        providerTypes, submitted,  creditLedger, debitLedger, accounts, currencies, currency,handleChangeOption,options,selectedOptions,
        handleChangeCreditLedger, handleChangeDebitLedger, handleUpdateProvider, isEditProvider,handleOpenEdit,isResolvedScheme,
        isBlocking,isBlockDialog,setOpenDialog,setIsBlockDialog,handleResetForm,openDialogScheme,handleOpenDialogScheme,
        openEdit, handleOpenEditInsurance, handleCloseEditInsurance,
        checkList, selectedChecks, handleChangeInsuranceChecks,
         renderAddScheme: (
            <CustomDialog open={open} handleClose={handleClose} title={title}>
                <form onSubmit={handleSubmitScheme} autoComplete='off'>
                    <Form.Group>
                        <Label type name='Scheme' htmlFor="name"/>
                        <CustomInput name='name' id="name" isRequired submitted={submittedScheme} inputValue={scheme_name} type='text' placeholder='Enter scheme name' reset={isResolved}/>
                    </Form.Group>
                    <button type='submit' id="save_scheme"  className='btn btn-sm sms-btn mr-4'>{isPendingScheme ? 'Saving...' :'Save'}</button>
                </form>
            </CustomDialog>
        )
    }

    return {providerProps,providers,searchValue, page, rowsPerPage,isLoading, isEditProvider,
        isSuccess, isError,openDialog,providerOptions,handleSearch, handleChangePage, handleEditProvider,
        handleChangeRowsPerPage, closeSnackbar, handleOpenDialog, handleCloseDialog, uploadProps, setPage}
}

export {useInvoiceProviders}