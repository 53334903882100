import React, {useEffect, useState} from "react";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCoins} from "@fortawesome/free-solid-svg-icons/faCoins";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PageTitle from "../../Utils/smsTitle";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../../actions/snackbarActions"
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import PrintDialog from "../../Utils/Dialogs/PrintDialog";
import TextField from "../../Utils/FormInputs/TextField";
import {MiniHeader} from "../../Utils/Menu/MiniSubHeader"
import Label from "../../Utils/FormInputs/Label"
import {Container} from "../../Utils/styledComponents"
import { BackButton } from "../../Utils/Buttons/DataExportationButton";
import { history } from "../../../Helpers/history";
import Alert from "@material-ui/lab/Alert";
import InputGroup from "react-bootstrap/InputGroup";
import {useCurrency} from "../../../Context/currency-context";
import {dateConvert} from "../../Utils/ConvertDate";
import {groupProductId, groupServiceId} from "../utils/SanitizeArr";
import axios from "axios";
import {config} from "../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";


const Table = ({children, title, headData=[]}) =>(
    <table className="table table-bordered table-sm billing-invoice-table  strike-table">
        <thead>
        <tr>
            <td align="center" colSpan={headData.length}>{title}</td>
        </tr>
        <tr>
            {headData.map((item, index) => (<td key={index}>{item}</td>))}
        </tr>
        </thead>
        <tbody>
            {children}
        </tbody>

    </table>
)


function RefundBill({actions, match, snackbars}) {
    const {currency:selectedCurrency} = useCurrency({actions, snackbars, match})

    const {visit_id} = match.params;
    const [state, setState] = useState({notes: '', patient_name: '', patient_number: '', address: ''});
    const [services, setServices] = useState([]);
    const [products, setProducts] = useState([]);
    const [billDetails, setBillDetails] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [date, setDate] = useState(dateConvert());
    const [openPrint, setOpenPrint] = useState(false);
    const [totalAmounts, setTotalAmounts] = useState({
        paid_amount: 0, due_amount_total:0,  total_bill_amount: 0,
        currency_total_amount: 0,
        currency_paid_amount: 0, currency_due_amount: 0,total_refund:0,
    });
    const [submitted, setSubmitted] = useState(false);
    const [currency, setCurrency] = useState('')
    const [exchangeRate, setExchangeRate] = useState(0)
    const [currencyExchangeRate, setCurrencyExchangeRate] = useState(0)

    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;



    const calculateTotal = (arr) => {
        return arr.reduce((sum, item) => {
            return +item.amount_paid + +sum
        }, 0)
    }

    const totalCurrency = (arr=[]) =>{
        return arr.reduce((a, item) => {
            return (+a + item.usd_total)
        }, 0)
    }

    const reduceAmounts = (arr = []) =>{
        return arr.reduce((a, item) => {
            return a + item.amount
        }, 0)
    }

    const totalNonCurrency = (arr=[]) =>{
        return arr.reduce((a, item) => {
            return (+a + item.currency_total)
        }, 0)
    }

    const calculateTotalAmount = (arr,exchange_rate) => {
        const obj = arr[0] ? arr[0] : {};
        // const exchange_rate = obj.exchange_rate ? obj.exchange_rate : 0;
        const t_amount = totalCurrency(arr)

        const p_total = arr.reduce((sum, item) => {
            return +item.total_paid + +sum
        }, 0)

        const currency_total = totalNonCurrency(arr)
        const currency_paid = exchange_rate ?  p_total / exchange_rate : p_total
        const currency_due = currency_total - currency_paid

        const total_refund = reduceAmounts(arr.map(item=>({...item, amount:item.refund_amount})))


        const due_amt = t_amount - p_total
        setTotalAmounts({...totalAmounts,paid_amount: p_total, due_amount_total:due_amt,  total_bill_amount: t_amount,
            currency_total_amount: currency_total, currency_paid_amount: currency_paid, currency_due_amount: currency_due,
            currency_id: obj.currency_id,total_refund})
    }

    const convertLists = (bill_details, ex_rate) =>{
        const arr = bill_details.map(item=>{
            const {invoice,receipts} = item;
            const qty = invoice.quantity ? invoice.quantity : 0;
            const rate = invoice.rate ? invoice.rate : 0
            const total = rate * qty
            const sub_total = invoice.is_base_currency === false ?
                (ex_rate ?  invoice.rate / ex_rate : invoice.rate) *  (!invoice.quantity ? 0 : invoice.quantity):
                total
            return ({...invoice,
                item_name: invoice.service_id ? invoice.service_name : invoice.product_id ? invoice.product_name : '',
                other_currency_rate: ex_rate ?  invoice.rate / ex_rate : invoice.rate,
                usd_rate:invoice.rate,
                usd_total : total,
                currency_total:(ex_rate ?  invoice.rate / ex_rate : invoice.rate) *  (!invoice.quantity ? 0 : invoice.quantity),
                subTotal:sub_total,
                total_paid:calculateTotal(receipts),
                status: invoice.pay_status,
                refund_amount:0,
                isRefund:false,
                // exchange_rate:invoice.currency_rate,
            })
        })

        const productsArr = groupProductId(arr.filter(item=>item?.product_id))
        const serviceArr = groupServiceId(arr.filter(item=>item?.service_id)).map(item=>{
            const qty = item.quantity ? item.quantity : 0;
            const rate = item.rate ? item.rate : 0
            const total = rate * qty
            const sub_total = item.is_base_currency === false ?
                (ex_rate ?  item.rate / ex_rate : item.rate) *  (!item.quantity ? 0 : item.quantity):
                total
            return {
            ...item,
            subTotal:sub_total
        }})
        setProducts(productsArr)
        setServices(serviceArr)
        calculateTotalAmount(arr,ex_rate)
    }

    useEffect(() => {
        if (!visit_id){
            return
        }
        const formData = new FormData();
        formData.append('visit_id', visit_id);
        axios.post(`${config.smsUrl}/cbilling/view_receipt`, formData)
            .then(res => {
                const details = res.data;
                const data = !details ? {} : details;
                const rt = data.bill ?? [];
                const invoices = rt[0] ?? {};
                let obj = invoices.invoice ? invoices.invoice : {};
                const exchange_rate = obj.currency_rate ? obj.currency_rate : 0;

                const symbol = obj.currency_symbol ? obj.currency_symbol : obj.currency_symbol
                const is_base = !(obj.is_base_currency == null || undefined)  ? obj.is_base_currency : obj.is_base_currency
                const currency_name = obj.currency_name ? obj.currency_name : obj.currency_name


                const ex_rate = Math.pow(exchange_rate, -1)
                const p_receipt = data.patient_info ?? {};
                const item = p_receipt ?? {};
                const { patient_number, address, phone_no,patient_firstname,patient_lastname, gender, age} = item
                const patient_name = `${!patient_firstname ? "" : patient_firstname} ${!patient_lastname ? "" : patient_lastname}`;



                setState({...state, patient_name, patient_number, address, phone_no, gender, age})
                setBillDetails(rt)
                setCurrency(`${symbol}-${is_base}-${currency_name}`)
                setExchangeRate(exchange_rate)
                setCurrencyExchangeRate(ex_rate)
            }).catch(error => {
            logoutErrorMessage(error, null, actions)
        });
    }, [visit_id]);

    useEffect(() => {
        if (!billDetails){
            return
        }
        const obj = billDetails[0] ? billDetails[0] : {};
        const inv = obj['invoice'] ? obj['invoice'] : {}
        const exchange_rate = inv.currency_rate ? inv.currency_rate : 0;
        const ex_rate = exchange_rate > 0 ?  Math.pow(exchange_rate, -1) : 0
        setExchangeRate(exchange_rate)
        setCurrencyExchangeRate(ex_rate)
        convertLists(billDetails, exchange_rate)
    }, [billDetails]);

    const handleRefundService = (id) =>(event)=>{
        const {checked} = event.target;
        const arr = services.map(item=>{
            if(item.service_id === id){
                return {...item, isRefund:checked, refund_amount: checked ? item.subTotal : 0}
            }
            return item
        })
        calculateTotalAmount(arr, exchangeRate)
        setServices(arr)
    }

    const handleRefundProduct = (id) =>(event)=>{
        const {checked} = event.target;
        const arr = products.map(item=>{
            if(item.product_id === id){
                return {...item, isRefund:checked, refund_amount: checked ? item.subTotal : 0}
            }
            return item
        })
        calculateTotalAmount(arr, exchangeRate)
        setProducts(arr)
    }

    const handleChangeDate = (event) => {
        setDate(event.target.value)
    };




    const handleSubmitBill = (event) => {
        event.preventDefault();
        const {total_refund, currency_total_refund} = totalAmounts;
        const {patient_number} = state
        setSubmitted(true);
        const serviceArr = services.filter(item=>item.isRefund).map(({isRefund, refund_amount, ...item})=>({...item, refund_amount: item.subTotal}))
        const productArr = products.filter(item=>item.isRefund).map(({isRefund,refund_amount, ...item})=>({...item, refund_amount: item.subTotal}))
        const remainingServices = services.filter(item=>!item.isRefund).map(({isRefund, ...item})=>({...item}))
        const remainingProds = products.filter(item=>!item.isRefund).map(({isRefund, ...item})=>({...item}))

        const invoicesRefunded = [...serviceArr, ...productArr];
        const remainingInvs =  [...remainingServices, ...remainingProds]
        const servs = services.some(item=>item.isRefund)
        const prdts = products.some(item=>item.isRefund)

        const isBase = currency.split('-')[1]

        const isNotBase = isBase === 'false'

        const currency_id = currency.split('-')[0]

        const isNotBaseData = isNotBase ?  {currency_total:+currency_total_refund} : {}
        if(servs || prdts){
            setIsSubmitted('pending')
            axios.post(`${config.smsUrl}/cbilling/make_refund`, {
                visit_id: +visit_id,
                refunded_by: user_roles_id,
                invoices_refunded: invoicesRefunded,
                invoices_remaining: remainingInvs,
                refund_amount: +total_refund,
                amount_refunded: +total_refund,
                is_base_currency:isBase,
                currency_id: +currency_id,
                ...isNotBaseData

            }).then((res) => {
                actions.snackbarActions.snackSuccess(`Bill refunded successfully`);
                setIsSubmitted('resolved')
                history.push(`/refundreceipt/${visit_id}/1/${patient_number}`);
            }).catch(err => {
                errorMessages(err, null, actions)
                setIsSubmitted('rejected')
            })

        }

    };

    const handleOpenReceipt = (e) => {
        e.preventDefault();
        const servs = services.some(item=>item.isRefund)
        const prdts = products.some(item=>item.isRefund)
        if (servs || prdts) {
            setOpenPrint(true);
            setSubmitted(false);
        } else {
            setSubmitted(true);
        }

    }

    const handleCloseReceipt = () => {
        setOpenPrint(false);
    };



    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };
    const { paid_amount, due_amount_total,  total_bill_amount,total_refund,
        currency_total_amount, currency_paid_amount, currency_due_amount,} = totalAmounts
    const {patient_number, patient_name} = state
    const {openBar, type, message} = snackbars;

    const isBase = currency.split('-')[1]
    const currency_symbol = currency.split('-')[0]
    const currency_name = currency.split('-')[2]
    const isNotBase = isBase === 'false'

    const isRefundService = services.length > 0 && services.some(item=>item.isRefund)
    const isRefundProduct = products.length > 0 && products.some(item=>item.isRefund)


    const servicesHeadData = ["Item", "Quantity",isNotBase ?  `Rate(${currency_symbol})` :  null, "Rate(USD)",
        isNotBase ? `Amount(${currency_symbol})`:null, "Amount(USD)", "Refund"]

    const productsHeadData = ["Medicine Name", "Dosage", "Frequency", "No. of Days",
        "Stock","Prescribed Quantity", isNotBase ?  `Rate(${currency_symbol})` :  null, "Rate(USD)",
        isNotBase ? `Amount(${currency_symbol})`:null, "Amount(USD)","Refund"]

    return (
        <div data-testid="add-bill-component" className='journals'>
            <PageTitle title='Refund Bill'/>
            <SubHeader title="Billing" subTitle='Refund Bill'>
                <FontAwesomeIcon icon={faCoins}/>
            </SubHeader>
           
                <PrintDialog handleCancel={()=>{history.push('/paymentList')}} openDialog={openPrint} handleClose={handleCloseReceipt}
                             handleClick={handleSubmitBill} message="Confirm Payment"
                             text="Would you like to confirm this payment"/>
                <MainSnackbar variant={type} handleCloseBar={closeSnackbar} open={openBar} message={message}/>
                <Container>
                     <BackButton to='/paymentList' text='Billing List' data-testid="refund-bill-back-button"/>
                    <MiniHeader  title='Refund Bill'/>
                    <form onSubmit={handleOpenReceipt} autoComplete="off" style={{marginTop:'1.5rem'}}>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                        <Label name="Patient No."/>
                                    </Col>
                                    <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                                        <TextField type="text" value={patient_number} readOnly/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                        <Label name="Patient Name"/>
                                    </Col>
                                    <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                                        <TextField type="text" value={patient_name} readOnly/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                        <Label name="Currency"  type/>
                                    </Col>

                                    <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                                        <TextField value={`${currency_symbol} ${currency_name}`} disabled/>

                                    </Col>
                                </Form.Group>
                                {isNotBase ?
                                    <Form.Group as={Row} >
                                        <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                            <Label  name="Exchange Rate"  type/>
                                        </Col>

                                        <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                                            <InputGroup>
                                                <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">{currency_symbol}</InputGroup.Text>
                                                <TextField submitted={submitted} type="number" value={currencyExchangeRate}  readOnly/>
                                            </InputGroup>
                                            <span style={{marginTop:'5px', display:'inline-block', fontWeight:700, color:'blue'}}>(1 {selectedCurrency.currency_symbol}  = {currencyExchangeRate} {currency_symbol})</span>
                                        </Col>
                                    </Form.Group> : null}
                            </div>
                            <div className='col-lg-6'>
                                <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                        <Label name="Date" />
                                    </Col>

                                    <Col xs={12} sm={12} md={10} lg={10} xl={isNotBase ?  "9" : "7"}>
                                        <TextField type="date" value={date} onChange={handleChangeDate} readOnly/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                        <Label name= 'Total Bill' />
                                    </Col>
                                    <Col  xs={12} sm={12} md={10} lg={10} xl={isNotBase ?  "9" : "7"}>
                                        {isNotBase ?  <Row>
                                           <Col sm='6'>
                                               <InputGroup>
                                                   <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">{selectedCurrency.currency_symbol}</InputGroup.Text>
                                                   <TextField type="text" value={total_bill_amount} readOnly/>
                                               </InputGroup>
                                           </Col>
                                            <Col sm='6'>
                                                <InputGroup>
                                                    <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">{currency_symbol}</InputGroup.Text>
                                                    <TextField type="text" value={currency_total_amount} readOnly/>
                                                </InputGroup>
                                            </Col>
                                        </Row> : <InputGroup>
                                            <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">{currency_symbol}</InputGroup.Text>
                                            <TextField type="text" value={total_bill_amount} readOnly/>
                                        </InputGroup>}
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                        <Label name= 'Amount Paid' />
                                    </Col>

                                    <Col  xs={12} sm={12} md={10} lg={10} xl={isNotBase ?  "9" : "7"}>
                                        {isNotBase ? <Row>
                                            <Col sm='6'>
                                                <InputGroup>
                                                    <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">{selectedCurrency.currency_symbol}</InputGroup.Text>
                                                    <TextField type="text" value={paid_amount} readOnly/>
                                                </InputGroup>
                                            </Col>
                                            <Col sm='6'>
                                                <InputGroup>
                                                    <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">{currency_symbol}</InputGroup.Text>
                                                    <TextField type="text" value={currency_paid_amount} readOnly/>
                                                </InputGroup>
                                            </Col>
                                        </Row>:   <InputGroup>
                                            <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">{currency_symbol}</InputGroup.Text>
                                            <TextField type="text" value={paid_amount} readOnly/>
                                        </InputGroup>}
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                        <Label name= 'Total Refund'/>
                                    </Col>
                                    <Col  xs={12} sm={12} md={10} lg={10} xl={isNotBase ?  "9" : "7"}>
                                        <InputGroup>
                                            <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">{currency_symbol}</InputGroup.Text>
                                            <TextField type="text" value={total_refund} readOnly/>
                                        </InputGroup>
                                        {/*{isNotBase ? <Row>*/}
                                        {/*    <Col sm='6'>*/}
                                        {/*        <InputGroup>*/}
                                        {/*            <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">{selectedCurrency.currency_symbol}</InputGroup.Text>*/}
                                        {/*            <TextField type="text" value={total_refund} readOnly/>*/}
                                        {/*        </InputGroup>*/}
                                        {/*    </Col>*/}
                                        {/*    <Col sm='6'>*/}
                                        {/*        <InputGroup>*/}
                                        {/*            <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">{total_refund}</InputGroup.Text>*/}
                                        {/*            <TextField type="text" value={total_refund} readOnly/>*/}
                                        {/*        </InputGroup>*/}
                                        {/*    </Col>*/}
                                        {/*</Row>:  <InputGroup>*/}
                                        {/*    <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">{currency_symbol}</InputGroup.Text>*/}
                                        {/*    <TextField type="text" value={total_refund} readOnly/>*/}
                                        {/*</InputGroup>}*/}
                                    </Col>
                                </Form.Group>
                            </div>
                        </div>
                        <div className="other-billing-details mb-3">
                            <div className="service-details">
                                {submitted && !(isRefundService || isRefundProduct) ?
                                    <div className='mb-3'>
                                        <Alert severity='error'>Please select at least one item to refund before saving</Alert>
                                    </div>
                                    : null}
                                {services.length > 0 ?
                                    <Table headData={servicesHeadData.filter(item=>Boolean(item))} title='Services'>
                                        {services.map((item,idx) => (
                                            <tr key={item.service_id}>
                                                <td>{item.service_name}</td>
                                                <td>{item.quantity}</td>
                                                { isNotBase ?  <td>{item.other_currency_rate}</td>: null}
                                                <td>{item.usd_rate}</td>
                                                { isNotBase ?  <td>
                                                    {item.currency_total}
                                                </td>: null}
                                                <td >{item.usd_total}</td>
                                                <td id='refund'>
                                                    <Form.Check type='checkbox' checked={item.isRefund} onChange={handleRefundService(item.service_id)}/>
                                                </td>
                                            </tr>
                                        ))}
                                    </Table> : null}
                                {products.length > 0 ? <Table headData={productsHeadData.filter(item=>Boolean(item))} title='Products'>
                                {products.map((item, idx) => (<tr key={item.product_id}>
                                    <td>
                                        {item.product_name}
                                    </td>
                                    <td align="center">
                                        {item.dosage}
                                    </td>

                                    <td align="center">
                                        {item.frequency}
                                    </td>
                                    <td align="center">
                                        {item.number_of_days}
                                    </td>
                                    <td align="center">
                                        {item?.stock?.depart_stock_val}
                                    </td>
                                    <td>
                                        {item.quantity}
                                    </td>
                                    { isNotBase ?  <td>{item.currency_rate}</td>: null}

                                    <td align="center">
                                        {item.rate}
                                    </td>

                                    { isNotBase ?  <td>
                                        {item.currency_total}
                                    </td>: null}

                                    <td align="center">
                                        {item.usd_total}
                                    </td>
                                    <td  id='refund'>
                                        <Form.Check type='checkbox' checked={item.isRefund} onChange={handleRefundProduct(item.product_id)}/>
                                    </td>
                                </tr>))}
                                </Table> : null}
                            </div>
                        </div>
                        <button id='save-refund' type="submit" className="btn btn-sm sms-info-btn"
                            disabled={isSubmitted === 'pending'}>
                                {isSubmitted === 'pending' ? "Saving..." : "Save"}</button>    
                    </form>

            </Container>
        </div>
    )
}

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(RefundBill);
