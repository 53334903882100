import {useEffect, useState} from "react";
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import {dateConvert} from "../../../Utils/ConvertDate";

export const useInPatientBillingList = (actions) =>{
    const [searchValue, setSearchValue] = useState('');
    const [openMenu, setOpenMenu] = useState(null);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('sl');
    const [activePage, setActivePage] = useState(1);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [billingList, setBillingList] = useState([]);
    const [total_count, setCount] = useState(0);
    const [loading, setLoading] = useState('idle');
    const [refresh, setRefresh] = useState(false);
    const [bill_id, setBillId] = useState({visit_id:'', patient_admission_id:'',patient_number:''});
    const [activeStatus, setActiveStatus] = useState(1)
    const [admissionStatus, setAdmissionStatus] = useState(1)
    const [payStatus, setPayStatus] = useState(0)
    const [date, setDate] = useState({start_date:dateConvert(), end_date:dateConvert()})
    const {start_date, end_date} = date


    useEffect(() => {
        const formData = new FormData();
        formData.append('search', searchValue);
        formData.append('admission_status', admissionStatus);
        formData.append('pay_status', payStatus);
        formData.append('patient_type', 2);
        formData.append('start_date', start_date);
        formData.append('end_date', end_date);
        formData.append('per_page', rowsPerPage);
        setLoading('pending');
        axios.post(`${config.smsUrl}/cbilling/bill_list/${activePage}`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const billing_list = !dt.bill_list ? [] : dt.bill_list;
            const count = !dt.total_count ? 0 : dt.total_count;
            setBillingList(billing_list);
            setCount(count);
            setLoading('success')
        }).catch(err=>{
            setLoading('error');
            logoutErrorMessage(err, null, actions)
        })
    }, [activePage,  searchValue, refresh, activeStatus,
        admissionStatus, payStatus, start_date, end_date, rowsPerPage]);

    const handleChangeStatus = (status) =>{
        const {active_status, admission_status, pay_status} = status;
        setActiveStatus(active_status)
        setAdmissionStatus(admission_status)
        setPayStatus(pay_status)

    }

    const handleRefresh = () =>{
        setRefresh(!refresh)
    }

    const handleSearch = (event) => {
        setSearchValue(event.target.value);
        setActivePage(1)
        setPage(0)
    };


    const handleCloseMenu = () => {
        setOpenMenu(null)
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    const handleChangeNextPage = (value) => {
        setActivePage(value)
    };

    const handleOpenMenu = (event, visit_id, patient_admission_id,patient_number) => {
        setOpenMenu(event.currentTarget);
        setBillId({visit_id, patient_admission_id, patient_number});
    };

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar();
    };

    const handleChangeDate = (event) =>{
        const {name, value} = event.target;
        setDate({...date,[name]:value})
        setActivePage(1)
        setPage(0)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setActivePage(newPage+1)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        setActivePage(1);
    };

    const isLoading = loading === 'pending';
    const isSuccess = loading === 'success';
    const isError = loading === 'error';

    return {searchValue, openMenu, order, orderBy, activePage, billingList, total_count,
        loading, bill_id, handleSearch, handleCloseMenu, handleRequestSort, handleChangeNextPage,
        handleOpenMenu, closeSnackbar, handleRefresh, activeStatus, handleChangeStatus,
        isError, isLoading, isSuccess, date, handleChangeDate, rowsPerPage, page,
    handleChangePage, handleChangeRowsPerPage}
}