import axios from "axios";
import {config} from "../../../Helpers/env";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {dateConvert} from "../ConvertDate";
import {useState} from "react";

function useExcelReports(actions, obj={}) {
    const [loading, setLoading] = useState('idle')
    const {url, file_name, params} = obj
    const current_date = dateConvert()
    const exportExcel = () => {
        const paramsObj = params ? params :{}
        setLoading('loading')
        axios.get(`${config.smsUrl}/${url}`, {params:{download:true, ...paramsObj},responseType: 'blob'}).then(response=>{
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${file_name} Report_${current_date}.xlsx`);
            document.body.appendChild(link);
            link.click();
            setLoading('success')
        }).catch(err=>{
            setLoading('error')
            if (err?.response?.status === 406){
                const errorObj ={response:{data:{message:'There is no data to download'}, status:406}}
                errorMessages(errorObj, null,actions)
                return
            }
            errorMessages(err, null,actions)

        })
    }
    const isLoading = loading === 'loading'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'
    return {exportExcel,  isSuccess, isError, isLoading}
}


function usePdfDownload(actions, obj={}) {
    const [loading, setLoading] = useState('idle')
    const {url, file_name, params} = obj
    const current_date = dateConvert()
    const exportPdf = () => {
        const paramsObj = params ? params :{}
        setLoading('loading')
        axios.get(`${config.smsUrl}/${url}`, {params:{download:true, ...paramsObj},responseType: 'blob'}).then(response=>{
            console.log(response.data)
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${file_name} Report_${current_date}.pdf`);
            document.body.appendChild(link);
            link.click();
            setLoading('success')
        }).catch(err=>{
            setLoading('error')
            if (err?.response?.status === 406){
                const errorObj ={response:{data:{message:'There is no data to download'}, status:406}}
                errorMessages(errorObj, null,actions)
                return
            }
            errorMessages(err, null,actions)


        })
    }
    const isLoading = loading === 'loading'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'
    return {exportPdf, isSuccess, isError, isLoading}
}
export {useExcelReports,usePdfDownload}
