import React, {useEffect, useState} from "react";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CustomTable from "../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import {getComparator, stableSort} from "../Utils/Tables/sortingFunctions";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import * as snackbarActions from "../../actions/snackbarActions"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import PageTitle from "../Utils/smsTitle";
import DeleteDialog from "../Utils/Dialogs/DeleteDialog";
import {faBed} from "@fortawesome/free-solid-svg-icons/faBed";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import AddRoomForm from "./AddRoomForm";
import CustomDialog from "../Utils/Dialogs/CustomDialog";
import {useAddRoom} from "./CustomHooks/useAddRoom";
import {titleCase} from "../Users/addUser";
import axios from "axios";
import {config} from "../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../Helpers/ErrorMessages";
import {LoadingGif} from "../Utils/Loader";
import {BlockNavigationComponent} from "../Utils/ReusableComponents/BlockNavigationComponent";
import {history} from "../../Helpers/history";


const headData = [{id: 'sl', numeric: false, disablePadding: false, label: 'SL'},
    {id: 'ward', numeric: false, disablePadding: false, label: 'Ward'},
    {id: 'roomName', numeric: false, disablePadding: false, label: 'Room Name'},
    {id: 'description', numeric: false, disablePadding: false, label: 'Description'},
    {id: 'bed', numeric: false, disablePadding: false, label: 'Bed Capacity'},
    {id: 'charge', numeric: false, disablePadding: false, label: 'Charge'},
    // {id: 'status', numeric: false, disablePadding: false, label: 'status'},
    {id: 'actions', numeric: false, disablePadding: true, label: 'Action'},
];

export const RoomList = ({actions, snackbar}) => {
    const [searchValue, setSearchValue] = useState('');
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('sl');
    const [room_id, setRoomId] = useState('');
    const [openDelete, setDelete] = useState(false);
    const [roomList, setRoomList] = useState([]);
    const [loading, setLoading] = useState('idle');
    const [activePage, setActivePage] = useState(1);
    const [room_count, setRoomCount] = useState(0);

    const {isResolved, roomProps} = useAddRoom(actions)

    useEffect(() => {
        setLoading('loading');
        const formData = new FormData();
        const search = searchValue ? titleCase(searchValue) :searchValue
        formData.append('search', search);
        axios.post(`${config.smsUrl}/cbedmanager/room_list/${activePage}`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const list = !dt.room_list ? [] : dt.room_list;
            const count = !dt.total_count ? 0 : dt.total_count;
            setLoading('success');
            setRoomCount(count);
            setRoomList(list)
        }).catch(err => {
            logoutErrorMessage(err, null, actions);
            setLoading('error')
        })
    }, [activePage, searchValue, isResolved, isResolved]);


    const handleSearch = (event) => {
        setSearchValue(event.target.value);
    };


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangeNewPage = (page) => {
        setActivePage(page);
    };

    //
    // const handleOpenDelete = (id) => {
    //     setDelete(true);
    //     setRoomId(id)
    // };

    const handleCloseDelete = () => {
        setDelete(false)
    };

    const handleDelete = () => {
        const formData = new FormData();
        formData.append('room_id', room_id);
        axios.post(`${config.smsUrl}/cbedmanager/delete_room`, formData).then(() => {
            const wds = roomList.filter(wd => wd.room_id !== room_id);
            setRoomList(wds);
            setDelete(false);
            actions.snackbarActions.snackSuccess('Room deleted successfully');
        }).catch(e => {
            errorMessages(e, null, actions);
            setDelete(false)
        });
    };

    const closeSnackBar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const isLoading = loading === 'loading'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'
    const {openBar, type, message} = snackbar;
    const perm = JSON.parse(sessionStorage.getItem('permission'));
    const permission = !perm ? {} : perm;
    const add_room = !permission.add_room ? {} : permission.add_room;
    const room_list = !permission.room_list ? {} : permission.room_list;
    const rooms = !roomList ? [] : roomList;

    const {handleOpenEditDialog, openEditDialog, open, handleCloseEditDialog, handleOpenDialogForm, handleCloseDialogForm, blockingProps} = roomProps



    const {isBlocking, isBlockDialog, setIsBlockDialog, handleReset, setIsBlocking} = blockingProps
    return (
        <div data-testid="room-list" className='journals'>
            <PageTitle title="Room List"/>
            <SubHeader title="Bed Management" subTitle="Room list">
                <FontAwesomeIcon icon={faBed}/>
            </SubHeader>
            <BlockNavigationComponent
                when={isBlocking}
                shouldBlockNavigation={()=>{
                    return isBlocking;
                }}
                navigate={path=>history.push(path)}
                dialogClose={isBlockDialog}
                setIsBlockDialog={setIsBlockDialog}
                clearForm={handleReset}
            />
            <div className='general-ledger-header'>
                <div className="text-right">
                    {(add_room.create || add_room.read || add_room.update || add_room.delete)&&
                        <button className="btn sms-btn btn-sm" onClick={handleOpenDialogForm}><FontAwesomeIcon icon={faPlus}/> Add Room</button>
                    }
                </div>
                <CustomDialog title="Add room" open={open} handleClose={handleCloseDialogForm} maxWidth="md"> 
                     <AddRoomForm {...{...roomProps, setIsBlocking}}/>
                 </CustomDialog>
            </div>
                    <CustomDialog open={openEditDialog} maxWidth="md"
                    handleClose={handleCloseEditDialog} title="Edit room">
                        <AddRoomForm {...roomProps}/>
                     </CustomDialog>
            <div className="mui-tables">
            <DeleteDialog message="delete" openDialog={openDelete} handleClose={handleCloseDelete}
                              text="room" title='Delete Room'>
                <button className=' btn sms-btn-dismiss btn-sm' onClick={handleDelete}>Delete</button>
                <button className="btn sms-grey-btn btn-sm" onClick={handleCloseDelete}>No</button>    
            </DeleteDialog>
                <MainSnackbar variant={type} message={message} open={openBar} handleCloseBar={closeSnackBar}/>
                <CustomTable title="Room List" headData={headData} handler={handleSearch} term={searchValue}
                             handleRequestSort={handleRequestSort} data={rooms} colSpan={5} order={order}
                             orderBy={orderBy} customPage records={10} activePage={activePage}
                             handleChangeNextPage={handleChangeNewPage} total_count={room_count}>
                        <TableBody>
                            {isLoading ? <TableRow>
                                    <TableCell colSpan={headData.length} align="center"><LoadingGif/></TableCell>
                                </TableRow>: null}
                            {isSuccess ? rooms.length > 0 ?  stableSort(rooms, getComparator(order, orderBy))
                                .slice(0, 10)
                                .map((item, index) => {
                                    const cnt  = ((activePage - 1) * 10) + index + 1;
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={item.room_id}
                                            className='mui-table-row'>
                                            <TableCell>{cnt}</TableCell>
                                            <TableCell>{item.ward}</TableCell>
                                            <TableCell>{item.name}</TableCell>
                                            <TableCell>{item.descriprion}</TableCell>
                                            <TableCell>{item.bed_capacity}</TableCell>
                                            <TableCell>{item.charge}</TableCell>
                                            {/*<TableCell>{item.status}</TableCell>*/}
                                            <TableCell>
                                                {room_list.update ? 
                                                    <button className="btn btn-sm sms-info-btn mr-1" onClick={()=> handleOpenEditDialog(item.room_id)}>Edit</button>
                                            :<div/>}
                                                {/*{room_list.delete &&<button onClick={() => handleOpenDelete(item.room_id)}*/}
                                                {/*        className="btn btn-sm sms-btn-dismiss">Delete*/}
                                                {/*</button>}*/}
                                            </TableCell>
                                        </TableRow>
                                    );
                                }):<TableRow>
                                <TableCell colSpan={headData.length} align="center">No records found</TableCell>
                            </TableRow>: null}
                            {isError ? <TableRow>
                                <TableCell colSpan={headData.length} align="center">The server did not return a valid response</TableCell>
                            </TableRow>: null}
                        </TableBody>

                </CustomTable>
            </div>
        </div>
    )
};

function mapStateToProps(state) {
    const {snackbar} = state;
    return {snackbar}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoomList);
