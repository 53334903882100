import React from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Label from "./Label";
import TextField from "./TextField";
import Textarea from "./Textarea";
import SelectInput from "./SelectInput";
import PhoneInput, { isPossiblePhoneNumber, isValidPhoneNumber,
} from "react-phone-number-input";
import {  withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import { ErrorMessage } from "../styledComponents";

// compulsory colSm, optional colXs and colXl
// compulsory labelSm, optional : labelXl
// Added the optional parameters to cater for responsiveness : see the Admission part in the Docotor's module
export default function FormGroup({
                                      labelSm,
                                      labelXl,
                                      colSm,
                                      colXl,
                                      colXs,
                                      labelName,
                                      labelType,
                                      type,
                                      value,
                                      name,
                                      onChange,
                                      dataTest,
                                      submitted,
                                      ...rest
                                  }) {

    const labelArea = (
        !labelXl ? 
            <>
                <Label column sm={labelSm} name={labelName} type={labelType}/>
            </>
        :   <>
                <Label column sm={labelSm} xl={labelXl} name={labelName} type={labelType}/>
            </>
    )

    const textFieldToAdd = (
        <>
            <TextField submitted={submitted} type={type} value={value} name={name} onChange={onChange} data-testid={dataTest} {...rest}/>
            {labelType && ((submitted && !value) && <ErrorMessage>{labelName} is required</ErrorMessage>)}
        </>
    )

    return (
        <Form.Group as={Row}>
            {labelArea}
            {!colXs && !colXl ? 
                <Col sm={colSm}>
                    {textFieldToAdd}
                </Col>
            : colXs && !colXl ?
                <Col sm={colSm} xs={colXs}>
                    {textFieldToAdd}
                </Col>
            : !colXs && colXl ?
                <Col sm={colSm} xl={colXl}>
                    {textFieldToAdd}
                </Col>
            :   <Col sm={colSm} xs={colXs} xl={colXl}>
                    {textFieldToAdd}
                </Col>
            }
        </Form.Group>
    )

}

export function FormTextArea({labelSm, labelXl, colSm, colXs, colXl,labelName, labelType, value, name, onChange, dataTest, submitted,...props}) {
    const textAreaToAdd = (
        <>
            <Textarea submitted={submitted} onChange={onChange} name={name} value={value} data-test={dataTest} {...props}/>
            {labelType && ((submitted && !value) && <ErrorMessage>{labelName} is required</ErrorMessage>)}
        </>
    )

    const labelArea = (
        !labelXl ? 
            <>
                <Label column sm={labelSm} name={labelName} type={labelType}/>
            </>
        :   <>
                <Label column sm={labelSm} xl={labelXl} name={labelName} type={labelType}/>
            </>
    )
    
    return (
        <Form.Group as={Row}>
            {labelArea}
            {!colXs && !colXl ? 
                <Col sm={colSm}>
                    {textAreaToAdd}
                </Col>
            : colXs && !colXl ?
                <Col sm={colSm} xs={colXs}>
                    {textAreaToAdd}
                </Col>
            : !colXs && colXl ?
                <Col sm={colSm} xl={colXl}>
                    {textAreaToAdd}
                </Col>
            :   <Col sm={colSm} xs={colXs} xl={colXl}>
                    {textAreaToAdd}
                </Col>
            }
        </Form.Group>
    )
}

export function FormSelectGroup({
                                    labelSm,
                                    colSm,
                                    labelName,
                                    labelType,
                                    options,
                                    defaultValue,
                                    value,
                                    name,
                                    onChange,
                                    dataTest,
                                    submitted
                                }) {
    return (
        <Form.Group as={Row}>
            <Label column sm={labelSm} name={labelName} type={labelType}/>
            <Col sm={colSm}>
                <SelectInput submitted={submitted} onChange={onChange} value={value} name={name} options={options}
                             defaultOption={defaultValue} data-test={dataTest}/>
                
            </Col>
        </Form.Group>
    )

}

export const ReusablePhoneInput = ({contact, setContact, submitted, required, id, ...props}) => {
    return (
        <>
            <PhoneInput  id={id} value={contact} onChange={setContact} defaultCountry='SS' {...props}/>
            {(required && (submitted && !contact)) ? <ErrorMessage>Contact is required</ErrorMessage> : null}
                {/*// contact && !isValidPhoneNumber(contact) &&*/}
                {/*// <ErrorMessage>Invalid phone number</ErrorMessage>}*/}
        </>
    )
}
export const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);
