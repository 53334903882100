import React from 'react'
import {useAccountingStyles} from "../Expenses/hooks/useAccountingStyles";
import {CustomSelect} from "../../Utils/FormInputs/SelectInput";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Label from "../../Utils/FormInputs/Label";

export const SortSelect = ({category,categories,handleChangeCategory, placeholder}) =>{
    const classes = useAccountingStyles();
    return(
        <div className={classes.customSelect}>
            <Form.Group as={Row} style={{marginBottom:'.4rem'}}>
                <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                    <Label name='Sort by:' />
                </Col>
                <Col xs={12} sm={12} md={12} lg={5} xl={5}>
                    <CustomSelect value={category} options={categories} onChange={handleChangeCategory} placeholder={placeholder}/>
                </Col>
            </Form.Group>
        </div>
        )

}
