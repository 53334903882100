import React, {forwardRef} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  paper: {
      margin: '22px',
      borderRadius: '15px'
  },
}));

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogSlide({title='', handleClose, children, openAccPeriod, maxWidth='lg'}) {
  const classes = useStyles();
  return (
      <Dialog
        open={openAccPeriod}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth={maxWidth}
        classes={{
          paper: classes.paper
        }}
      >
        <div style={{display:'flex', justifyContent:'space-between'}}>
          <DialogTitle>{title}</DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}><CloseIcon/></Button>
          </DialogActions>
        </div>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
           {children}
          </DialogContentText>
        </DialogContent>
      </Dialog>
  );
}