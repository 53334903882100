import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { config } from "../../../Helpers/env";
import { errorMessages, logoutErrorMessage } from "../../../Helpers/ErrorMessages";
import { history } from "../../../Helpers/history";
import { useMutation, useQuery } from "react-query";
import {dateConvert} from "../../Utils/ConvertDate";

export const useLabReport = (actions, componentUrl, dep) => {
    const [searchValue, setSearchValue] = useState('');
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('sl');
    // const [loading, setLoading] = useState('idle');
    const [activePage, setActivePage] = useState(1);
    // const [total_count, setTotalCount] = useState(0);
    // const [reports, setReports] = useState({});
    const [allRecords, setAllRecords] = useState([]);
    const [state, setState] = useState({ start_date: dateConvert(), end_date: dateConvert() })
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const csvRef = useRef();
    const { tableUrl, exportUrl } = componentUrl;
    const { start_date, end_date } = state;


    // const retrieveLabData = () => {
    //     setLoading('pending');
    //     const formData = new FormData();
    //     formData.append('start_date', start_date);
    //     formData.append('end_date', end_date);
    //     formData.append('start', activePage);
    //     formData.append('search', searchValue);
    //     axios.post(`${config.smsUrl}/claboratory/${tableUrl}`, formData).then(res => {
    //         const data = res.data;
    //         const dt = !data ? {} : data;
    //         const count = !dt.iTotalDisplayRecords ? 0 : dt.iTotalDisplayRecords;
    //         setReports(dt);
    //         setTotalCount(count);
    //         setLoading('success')
    //     }).catch((err) => {
    //         logoutErrorMessage(err, null, actions);
    //         setLoading('error')
    //     })
    // }

    const date = start_date && end_date;

    const labDataQuery = useQuery(
        ["labDataQuery", activePage, searchValue, dep, start_date, end_date],
        async () => {
            try {
                const formData = new FormData();

                formData.append('start_date', start_date);
                formData.append('end_date', end_date);
                formData.append('start', activePage);
                formData.append('search', searchValue);

                const response = await axios.post(`${config.smsUrl}/claboratory/${tableUrl}`, formData);

                const data = response?.data ?? {};

                const count = data?.iTotalDisplayRecords ?? 0;

                return { reports: data, total_count: count }

            } catch (error) {
                logoutErrorMessage(error, null, actions);
            }
        },
        { retry: 1, refetchInterval: 15000 });

    const loading = labDataQuery.isLoading ? "loading" : labDataQuery.isSuccess ? "success" : "error";

    let { reports, total_count } = labDataQuery.isSuccess ? labDataQuery.data : { reports: [], total_count: 0 }


    // useEffect(() => {
    //     if (date) {
    //         return;
    //     }
    //     retrieveLabData()
    //     const interval = setInterval(() => {
    //         retrieveLabData()
    //     }, 15000)
    //
    //
    //     return () => clearInterval(interval)
    //
    // }, [activePage, searchValue, dep, date]);

    const retrieveAllRecords = () => {
        return axios.post(`${config.smsUrl}/claboratory/${exportUrl}`)
    }

    const downloadCsv = () => {
        retrieveAllRecords().then(res => {
            const data = res.data;
            const dt = !data ? [] : data;
            setAllRecords(dt);
            csvRef.current.link.click()
        }).catch((err) => {
            errorMessages(err, null, actions);
        })
    }

    const handleSearch = (event) => {
        setSearchValue(event.target.value);
    };


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangeNextPage = (value) => {
        setActivePage(value)
    };

    const handleClickRow = (labrequest_id, current_date) => {
        history.push(`/collectsample/${labrequest_id}/${current_date}`)
    };

    const handleClickSample = (visit_id, sample_id, labrequests_detail_id, patient_type) => {
        history.push(`/captureresults/${visit_id}/${sample_id}/${labrequests_detail_id}/${patient_type}`)
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setState({ ...state, [name]: value })
    }

    const labMutation = useMutation(
        async () => {
            const formData = new FormData();

            formData.append('start_date', start_date);
            formData.append('end_date', end_date);
            formData.append('start', activePage);
            formData.append('search', searchValue);

            setSubmitted(true);

            setIsSubmitted("pending");

            return await axios.post(`${config.smsUrl}/claboratory/${tableUrl}`, formData);
        },
        {
            onError: (error) => {
                setSubmitted(false);

                setIsSubmitted('rejected')

                logoutErrorMessage(error, null, actions)
            },
            onSuccess: (response) => {

                setIsSubmitted("resolved");

                const data = response?.data ?? {};

                const total_count = data?.iTotalRecords ?? 0;

                const reports = data;

                return { reports, total_count }

            }
        })

    const handleSubmit = (event) => {
        event.preventDefault();

        if (start_date && end_date) {
            labMutation.mutate();
        }

        reports = labMutation.isSuccess ? labMutation.data.reports : [];

        total_count = labMutation.isSuccess ? labMutation.data.total_count : 0;

    }

    // const handleSubmit = (event) => {
    //     event.preventDefault();
    //
    //     setSubmitted(true);
    //     if (start_date && end_date) {
    //         setLoading('pending');
    //         setIsSubmitted('pending')
    //         const formData = new FormData();
    //         formData.append('start_date', start_date);
    //         formData.append('end_date', end_date);
    //         formData.append('start', activePage);
    //         formData.append('search', searchValue);
    //         axios.post(`${config.smsUrl}/claboratory/${tableUrl}`, formData).then(res => {
    //             const data = res.data;
    //             const dt = !data ? {} : data;
    //             const count = !dt.iTotalRecords ? 0 : dt.iTotalRecords;
    //             setReports(dt);
    //             setTotalCount(count);
    //             setLoading('success')
    //             setIsSubmitted('resolved')
    //         }).catch((err) => {
    //             logoutErrorMessage(err, null, actions);
    //             setLoading('error')
    //             setIsSubmitted('rejected')
    //         })
    //     }
    // }

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const isLoading = loading === 'pending';
    const isSuccess = loading === 'success';
    const isError = loading === 'error';

    const isPending = isSubmitted === 'pending';
    const isResolved = isSubmitted === 'resolved';
    const isRejected = isSubmitted === 'rejected';

    return {
        searchValue, order, orderBy, loading, activePage, total_count, reports, handleClickSample,
        handleSearch, handleRequestSort, handleChangeNextPage, closeSnackbar, handleClickRow,
        retrieveAllRecords, csvRef, downloadCsv, allRecords, isLoading, isSuccess, isError, state, submitted,
        handleChange, handleSubmit, isPending, isRejected, isResolved
    }
}
