import React from "react";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import CustomTable, {CustomTablePagination, useHiddenColumns} from "../../Utils/Tables/CustomTable";
import {titleCase} from "../../Users/addUser";
import { formatDateTime} from "../../Utils/ConvertDate";
import {useTheme} from "@material-ui/core/styles";
import {Link} from "react-router-dom";

const headData = [{id: 'count', numeric: false, disablePadding: false, label: 'SL', hidden:false},
    {id: 'date', numeric: false, disablePadding: false, label: 'Bill Date', hidden:false},
    {id: 'patient_number', numeric: false, disablePadding: false, label: 'Patient No.', hidden:false},
    {id: 'patient_name', numeric: false, disablePadding: false, label: 'Patient Name', hidden:false},
    {id: 'phone_no', numeric: false, disablePadding: false, label: 'Phone', hidden:false},
    {id: 'kin_phone_no', numeric: false, disablePadding: false, label: 'NOK Phone', hidden:false},
    {id: 'action', numeric: false, disablePadding: false, label: 'Action', hidden:false}
];

const RefundedBills = ({isLoading, isSuccess, isError,billingList=[], activePage,handleChangeActivePage,
handler,total_count, term,rowsPerPage, page, handleChangeRowsPerPage, handleChangePage}) => {

    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;

    const all_bills = billingList.map((bill, index)=>{
        const count = ((activePage - 1) * 10) + index + 1;
        return {
            ...bill,
            count,
            date:formatDateTime(bill.begin_datetime),
            patient_name: titleCase(`${!bill.patient_firstname ? "" : bill.patient_firstname} 
             ${!bill.patient_lastname ? "" : bill.patient_lastname}`),
            action: (
                <Link to={{pathname: `/refundreceipt/${bill.visit_id}/${activePage}/${bill.patient_number}`}}
                      style={{
                          textDecoration: "none",
                          color: palette.type === "dark" ? "#fff" : "#3d3d3d",
                          marginRight:'20px'
                      }}>
                    <button className="btn btn-sm sms-info-btn mr-1">View</button>
                </Link>
            )
        }
    })

    const {
        headCells, all_hidden, handleAllHeadCells, handleHeadCells
    } = useHiddenColumns(headData);


    const pagination = (
        <CustomTablePagination
            colSpan={3}
            activePage={activePage}
            page={page}
            rowsPerPage={rowsPerPage}
            count={total_count}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            isTotalCount
        />
    );

    return (
        <div className="journals">
            <CustomTable colSpan={headCells.length} data={all_bills}
                            headData={headCells} {...{all_hidden, handleAllHeadCells, handleHeadCells, pagination}}
                            activePage={activePage} handler={handler}
                            handleChangeNextPage={handleChangeActivePage} total_count={total_count}
                            records={10} customPage term={term}>
                <TableBody>
                    {isLoading ? <TableRow>
                        <TableCell colSpan={headCells.length} align="center"><img src="/images/smsloader.gif"
                                                            alt="loader"/></TableCell>
                        </TableRow> : null}
                    {isSuccess ?  all_bills.length > 0 ? all_bills.slice(0, 10)
                        .map((bill) => {
                            return (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={bill.bill_id}
                                    className='mui-table-row'>
                                    {headCells.map((head)=>(
                                        <TableCell id={head.id}  key={head.id}
                                                   hidden={head.hidden}>{bill[head.id]}</TableCell>
                                    ))}
                                </TableRow>
                            );
                        }): <TableRow>
                        <TableCell colSpan={headCells.length} align="center">No records found</TableCell>
                    </TableRow>:null}
                    {isError ? <TableRow>
                        <TableCell colSpan={headCells.length} align="center">The server did not return a valid response</TableCell>
                    </TableRow>:null}
                </TableBody>
            </CustomTable>
        </div>

    );

}

export default RefundedBills;
