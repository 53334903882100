import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Card } from '@material-ui/core';
import React from 'react'
import { SubHeader } from '../../Containers/SubHeader'
import PageTitle from '../Utils/smsTitle'
import Info from '@material-ui/icons/Info'
import { BackButton } from '../Utils/Buttons/DataExportationButton';
import {DischargeNotesTemplate} from "./DischargeNoteTemplate";
import {usePrint} from "../Utils/Templates/usePrint";
import {RightAlignedContainer} from "../Utils/styledComponents";


const DischargedPatientDetails = (props) => {
    const { visit_id} = props.match.params;
    const {handlePrint,componentRef} = usePrint('Discharge Summary')

    return (
      <div>
          <PageTitle title="Patient Details"/>
          <SubHeader title="Patient details" subTitle="Patient admission details">
            <FontAwesomeIcon icon={Info}/>
          </SubHeader>
          <section className="general-lab-report">
              <BackButton to='/dischargelist' text='Discharged Patients' data-testid="discharged-patient-details-back-button"/>
              <RightAlignedContainer>
                  <button className="btn btn-sm sms-amber-btn small-btn mb-2" onClick={handlePrint}>Print</button>
              </RightAlignedContainer>
              <Card className='p-3'>
                  <div ref={componentRef}>
                      <DischargeNotesTemplate visit_id={visit_id} actions={props.actions}/>
                  </div>
              </Card>
          </section>
      </div>
    )
}

export default DischargedPatientDetails