import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Label from "../../../Utils/FormInputs/Label";
import { AutoCompleteControlled } from "../../../Utils/FormInputs/AutoCompleteInput";
import axios from "axios";
import { config } from "../../../../Helpers/env";
import { errorMessages, logoutErrorMessage } from "../../../../Helpers/ErrorMessages";
import { useNewSingleProcedure } from "./useNewSingleProcedure";
import { useNewRadiologyRequest } from "./useNewRadiologyRequest";
import { useNewSelfLabRequest } from "./useNewLabRequest";
import { useNewMultipleProcedures } from "./useNewMultipleProcedures";
import { useVersionsContext } from "../../../../Context/versions-context";





export const useNewServiceRequest = (params, isMultiple = false) => {
    const { actions } = params
    const [isBlocking, setIsBlocking] = useState(false)
    const [service_type, setServiceType] = useState('');
    const [services, setServices] = useState([]);
    const [department, setDepartment] = useState('');
    const [departments, setDepartments] = useState([])
    const {subscription} = useVersionsContext()
    const version = subscription?.version


    const serviceTypes = [{ value: 'Lab', label: 'Lab' },
    { value: 'Procedure', label: 'Procedure' },
    { value: 'Radiology', label: 'Radiology' },
    
    ]

    const serviceTypesPremium = [{ value: 'Lab', label: 'Lab' },
    { value: 'Procedure', label: 'Procedure' },
    { value: 'Radiology', label: 'Radiology' },
    { value: 'Dental', label: 'Dental' },
    { value: 'Ante Natal Care', label: 'Ante Natal Care' },
    ]

    

    useEffect(() => {
        axios.get(`${config.smsUrl}/cvisit/retrieve_department`).then(res => {
            const data = res.data;
            const dt = !data ? [] : data;
            const departs = !dt.departments ? [] : dt.departments;
            const arr = departs.map(department => ({
                value: department.department_id,
                label: department.department_name
            }))
            setDepartments(arr)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, [])


    const handleChangeServiceType = (value) => {
        if (!value) {
            setServiceType('');
            setDepartment('');
            // setService('');
            return;
        }
        setServiceType(value);
        if (value.value === "Lab") {
            setDepartment({ value: 7, label: 'Labs' })
        } else if (value.value === "Radiology") {
            setDepartment({ value: 8, label: 'Scans and Radiology' })
        } else {
            //if (value.value === "Procedure") {
            const formData = new FormData();
            formData.append('service_type', value.value);
            axios.post(`${config.smsUrl}/cvisit/retrieve_services_by_service_type`, formData)
                .then(res => {
                    const details = res.data;
                    const data = !details ? [] : details;
                    const arr = data.map(item => ({
                        value: `${item.service_id}-${item.cost}`,
                        label: item.service_name
                    }))
                    setServices(arr)
                }).catch(error => {
                    errorMessages(error, null, actions)
                });
            setDepartment('');
        }
        setIsBlocking(true)

    };


    const propsObj = { service_type, setServiceType, department_name: department, isBlocking, setIsBlocking, departs: departments, services, ...params }

    const { renderSingleProcedure, handleSubmitService, submitted, isResolved: isResolvedProcedure, handleCloseSingleService } = useNewSingleProcedure(propsObj)
    const { renderMultiProcedure, handleSubmitMultipleService, isResolved: isResolvedMultiProcedure, handleCloseMultiServiceDialog } = useNewMultipleProcedures(propsObj)
    const { renderScanRequest, handleSubmitScanRequest, isResolved: isResolvedRadiology, handleCloseRadiologyDialog } = useNewRadiologyRequest(propsObj)
    const { renderLabRequest, handleSubmitLabRequest, isResolved: isResolvedLab, handleCloseLabDialog } = useNewSelfLabRequest(propsObj)


    const renderChildComponents = {
        "Ante Natal Care": renderSingleProcedure,
        "Procedure": isMultiple ? renderMultiProcedure : renderSingleProcedure,
        "Lab": renderLabRequest,
        "Radiology": renderScanRequest,
        "Dental": renderSingleProcedure,
        undefined: isMultiple ? null : renderSingleProcedure,
    }

    const handleSubmit = {
        "Ante Natal Care": handleSubmitService,
        "Procedure": isMultiple ? handleSubmitMultipleService : handleSubmitService,
        "Lab": handleSubmitLabRequest,
        "Radiology": handleSubmitScanRequest,
        "Dental": handleSubmitService,
        undefined: handleSubmitService
    }

    const handleCloseServiceDialog = {
        "Ante Natal Care": handleCloseSingleService,
        "Procedure": isMultiple ? handleCloseMultiServiceDialog : handleCloseSingleService,
        "Lab": handleCloseLabDialog,
        "Radiology": handleCloseRadiologyDialog,
        "Dental": handleCloseSingleService,
        undefined: handleCloseSingleService
    }

    const isResolvedService = {
        "Ante Natal Care": isResolvedProcedure,
        "Procedure": isMultiple ? isResolvedMultiProcedure : isResolvedProcedure,
        "Lab": isResolvedLab,
        "Radiology": isResolvedRadiology,
        "Dental": isResolvedProcedure,
    }


    const isServiceResolved = isResolvedService[service_type?.value] ? isResolvedService[service_type?.value] : false

    return {
        isResolvedService: isServiceResolved,
        handleCloseServiceDialog: handleCloseServiceDialog[service_type?.value] ? handleCloseServiceDialog[service_type?.value] : () => { },
        renderService: (
            <div className="mt-3">
                <form onSubmit={handleSubmit[service_type?.value]}>
                    <div className="mt-3">
                        <div className="row">
                            <div className="col-lg-6">
                                <Form.Group as={Row}>
                                    <Label column sm={3} name="Service Type" type />
                                    <Col sm={8}>
                                        {version === "Basic" ? <AutoCompleteControlled reset={isServiceResolved} options={serviceTypes} handleChange={handleChangeServiceType} submitted={submitted}
                                            medicine_value={service_type} placeholder="Select service type..." dataTest="service_type"
                                            errorMsg='Service Type is required' value_text='value' label_text='label' testId="service_type" />: <AutoCompleteControlled reset={isServiceResolved} options={serviceTypesPremium} handleChange={handleChangeServiceType} submitted={submitted}
                                            medicine_value={service_type} placeholder="Select service type..." dataTest="service_type"
                                            errorMsg='Service Type is required' value_text='value' label_text='label' testId="service_type" />}
                                    </Col>
                                </Form.Group>
                            </div>
                            <div className="col-lg-6" />
                        </div>
                    </div>
                    {renderChildComponents[service_type?.value]}
                </form>

            </div>
        )
    }
};

