import React from "react";
import axios from "axios";
import { config } from "../Helpers/env";

const CurrencyContext = React.createContext()
CurrencyContext.displayName = 'CurrencyContext'


function CurrencyProvider(props) {
   const [currency, setCurrency] = React.useState({})
    const [addedCurrencies, setAddedCurrencies] = React.useState([])

    // get currencies
    React.useEffect(() => {
        axios
            .get(`${config.smsUrl}/accounting/currencies`)
            .then((res) => {
                const data = res.data;
                const all_data = !data ? {} : data;
                const arr = all_data.data ?? [];
                const list = arr.filter(item=>item.is_base_currency).reduce((prev, curr)=> ({...prev, ...curr}) ,{});
                setAddedCurrencies(arr)
                setCurrency(list);
            })
            .catch((err) => {
               console.log(err)
            });
    }, []);

    return <CurrencyContext.Provider value={{currency, setCurrency, addedCurrencies}}  {...props}/>
}

function useCurrency() {
    const context = React.useContext(CurrencyContext)
    if (context === undefined) {
        throw new Error(`useCurrency must be used within a CompanyProvider`)
    }
    return context
}

export {CurrencyProvider, useCurrency}

