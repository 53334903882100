import React from "react";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PageTitle from "../../Utils/smsTitle";
import Card from "@material-ui/core/Card";
import ReactToPrint from "react-to-print";
import {useTheme} from "@material-ui/core/styles";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../../actions/snackbarActions"
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {faShippingFast} from "@fortawesome/free-solid-svg-icons/faShippingFast";
import {useViewRequisitionNote} from "./hooks/useViewRequisitionNote";
import {titleCase} from "../../Users/addUser";
import {dateStringConvert, formatDateTime} from "../../Utils/ConvertDate";
import ReceiptTemplate from "../../Utils/ReceiptTemplate";
import {BackButton} from "../../Utils/Buttons/DataExportationButton";


export function ViewRequisition({match, actions, snackbars}) {

    const {requisition, closeSnackbar, componentRef} = useViewRequisitionNote(match, actions)

    const {component} = match.params
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    const {openBar, type, message} = snackbars;
    const {personal_data, requisition_info} = requisition;
    const data = !personal_data ? {} : personal_data;
    const details = !requisition_info ? [] : requisition_info;
    const {date_created, email, from_department, phone_no, requested_by} = data;

    const url = {
        'lab':'/lab-stock-list',
        'pharmacy':'/pharmacystock',
        'patient_emergency':'/emergencystock',
        'store':'/requisitionlist/1'
    }

    const isStore = component === 'store'
    return (
        <div data-testid="view-requisition">
            <PageTitle title="Requisition Receipt"/>
            <SubHeader title="Inventory" subTitle="Requisition receipt">
                <FontAwesomeIcon icon={faShippingFast}/>
            </SubHeader>
            <section className="general-lab-report">
                <MainSnackbar variant={type} handleCloseBar={closeSnackbar} open={openBar} message={message}/>
                <div className='row'>
                    <div className='col-lg-6'>
                        <BackButton text={isStore ? 'Requisition list':'Stock'} to={url[component]}/>
                    </div>
                    <div className='col-lg-6'>
                        <div className="text-right">
                            <ReactToPrint content={() => componentRef.current}
                                          trigger={() => <button className="btn sms-amber-btn mb-2">Print</button>}/>
                        </div>
                    </div>

                </div>

                <Card className="requisition-receipt" ref={componentRef} id="prescription-print">
                    {/*<div className="lab-report-heading">*/}
                    {/*    <HospitalAddress/>*/}
                    <ReceiptTemplate>
                        <div
                            className={`title-background text-center ${palette.type === 'dark' && 'dark-title-background'}`}
                            id="title-background">
                            <h6>Requisition Receipt</h6>
                        </div>
                        {/*</div>*/}
                        <table className="table table-sm table-borderless prescription-table">
                            <tr>
                                <td className={palette.type === 'dark' && 'dark-theme-color'}><p>
                                    Date Requested: <strong>{date_created ? formatDateTime(date_created) : ""}</strong></p></td>
                                <td className={palette.type === 'dark' && 'dark-theme-color'}><p>Requested
                                    From: <strong>{from_department}</strong></p></td>
                            </tr>
                            <tr>
                                <td className={palette.type === 'dark' && 'dark-theme-color'}><p>Requested
                                    By: <strong>{requested_by ? titleCase(requested_by) : ""}</strong></p>
                                </td>
                                <td className={palette.type === 'dark' && 'dark-theme-color'}>
                                    <p>Contact &amp; Email: <strong>{phone_no}<br/>{email ? email : ""}</strong></p></td>
                                {/*<td/>*/}
                            </tr>
                            {/*<tr>*/}
                            {/*    <td className={palette.type === 'dark' && 'dark-theme-color'}/>*/}
                            {/*        /!*<p>Remarks: {receipt.address}</p></td>*!/*/}
                            {/*    <td  className={palette.type === 'dark' && 'dark-theme-color'}><p>Contact &amp; Email: {phone_no}<br/>{email ? email : ""}</p></td>*/}

                            {/*</tr>*/}
                        </table>
                        <div
                            className={`bill-patient-details mb-3 ${palette.type === "dark" && "dark-bill-patient-details"}`}
                            id="patient-lab-report"/>
                        <div className="table-responsive">
                            <table className="table table-bordered table-sm profile-table bill-receipt-table">
                                <thead>
                                <tr>
                                    <td rowSpan={2} style={{verticalAlign: 'middle', margin: 0}}><p>No.</p></td>
                                    <td rowSpan={2} width="200" align="center" style={{verticalAlign: 'middle'}}>
                                        <p>Particulars</p></td>
                                    <td rowSpan={2} width="80" align="center" style={{verticalAlign: 'middle'}}>
                                        <p>Unit</p></td>
                                    <td colSpan={2} align="center"><p>Qty last taken</p></td>
                                  
                                    <td rowSpan={2} align="center" style={{verticalAlign: 'middle'}}><p>Balance at
                                        hand</p></td>
                                    <td rowSpan={2} width="150" align="center" style={{verticalAlign: 'middle'}}>
                                        <p>Quantity Requested</p></td>
                                    {/*<td rowSpan={2} width="200" align="center" style={{verticalAlign:'middle'}}><p>Remarks</p></td>*/}
                                </tr>
                                <tr>
                                    <td align="center" width="80"><p>Qty</p></td>
                                    <td width="150" align="center"><p>Date</p></td>
                                    {/*<td><p>Total Amount</p></td>*/}
                                </tr>
                                </thead>
                                <tbody>
                                {details.map((item, index) => {
                                    const count = index += 1;
                                    return (
                                        <tr key={index}>
                                            <td><p>{count}</p></td>
                                            <td><p>{item.product_name}</p></td>
                                            <td><p>{item.name}</p></td>
                                            <td><p>{item.previously_dispatched_detail}</p></td>
                                            <td>
                                                <p>{item.previous_dispatch_date ? dateStringConvert(item.previous_dispatch_date) : ""}</p>
                                            </td>
                                          
                                            <td><p>{item.available_qty}</p></td>
                                            <td align="center"><p>{item.quantity}</p></td>
                                            {/*<td><p>{item.remarks}</p></td>*/}
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                    </ReceiptTemplate>
                </Card>
            </section>
        </div>
    )

}

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewRequisition);
