import React from "react";
import axios from "axios";
import {config} from "../../../Helpers/env";

export function useDentalDiagnosis(formRef) {
    const [addedDiseases, setAddedDiseases] = React.useState([])
    const [diseases, setDiseases]  = React.useState([])
    const [disease, setDisease] = React.useState('')
    const [removedDiagnosis, setRemovedDiagnosis] = React.useState([])



    const handleAddNew = (idx) =>{
        const formData = new FormData(formRef.current)
        const diagnosis = formData.get(`new_disease_${idx}`)
        const arr = addedDiseases.map((diseaseItem, index)=>{
            if (idx === index){
                return {...diseaseItem, isAddedDiagnosis:true, diagnosis, code:''}
            }
            return diseaseItem
        })
        setAddedDiseases(arr)
    }

    const autoSearchDisease = (event) => {
        setDisease(event)
        if (event !== '') {
            const formData = new FormData();
            formData.append('diagnosis', event);
            axios.post(`${config.smsUrl}/cdoctor/auto_search_codes`, formData).then(res => {
                const data = res.data;
                const dt = !data ? [] : data;
                if (dt.length > 0) {
                    const arr = dt.map(item => ({
                        value: item.id,
                        label: `${item.code ? item.code : ''} ${item.diagnosis}`,
                        code:item.code
                    }))
                    setDiseases(arr);
                } else {
                    const missingDisease = (
                        <p style={{margin:'0.65rem 0'}}>No results found for <strong>{`"${event}"`}</strong>
                            <span data-testid='missing-disease' style={{display:'inline-block', textDecoration:'underline', color:'#3871B7', marginLeft:'5px'}}>Create {event}</span></p>
                    )
                    const obj = {value:"new_disease", label:missingDisease, missing_disease:disease}
                    setDiseases([obj]);
                }
            }).catch(error => {
                // logoutErrorMessage(error, null, actions)
            });
        }
    };

    const getDiseaseDetails = (event) => {
        const formData = new FormData();
        formData.append('diagnosis',  event.value);
        if (event.value !=="new_disease") {
            axios.post(`${config.smsUrl}/cdoctor/retrieve_icd_info`, formData).then(res => {
                const resp = res.data;
                const dt = resp ? resp : {};
                const obj = {diagnosis:event.innerText, code:event.code, id:event.value, diagnosis_id:"", isAdded:true}

                setAddedDiseases([obj, ...addedDiseases])
            }).catch(error => {
                // logoutErrorMessage(error, null, actions)
            });
        }else {
            const obj = {isNew:event.value === "new_disease",diagnosis:event.innerText, code:"",
                missing_disease:event.missing_disease, id:"", diagnosis_id: "", isAdded:true}
            setAddedDiseases([obj, ...addedDiseases])
        }

    };




    const handleRemove = (index) =>{
        const arr = addedDiseases.filter((_, idx)=>index !== idx)
        const removed = addedDiseases.filter((_, idx)=>index === idx).filter(item=>item.diagnosis_id)
        setAddedDiseases(arr)
        setRemovedDiagnosis([...removedDiagnosis,...removed])
    }

    const diagnosisProps = {addedDiseases, disease, diseases, handleAddNew,
        autoSearchDisease, getDiseaseDetails, handleRemove, setAddedDiseases, removedDiagnosis}

    return {diagnosisProps}
}
