import React from 'react';
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMoneyBill} from "@fortawesome/free-solid-svg-icons/faMoneyBill";
import {makeStyles} from "@material-ui/core/styles";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../../actions/snackbarActions";
import {connect} from "react-redux";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {ReusableRouteTabs} from "../../Utils/Dialogs/ReusableTabs";
import { useSetUpSuppliers } from './hooks/useSetupSuppliers';
import  {Container} from "../../Utils/styledComponents"
import { history } from '../../../Helpers/history';
import { AccountSetUpForm } from './AccountSetUpForm';
import SetupLedgerList from './SetupLedgerList';
import CustomDialog from '../../Utils/Dialogs/CustomDialog';
import DeleteDialog from '../../Utils/Dialogs/DeleteDialog';
import { EditSetupForm } from './AccountSetUpForm';


const useStyles = makeStyles((theme) => ({
    margin: {
        margin: 0,
    },
    inputBase: {
        fontSize: '0.9em',
        padding: '2px'
    },

    col: {
        maxWidth: 600,
    },
    td: {
        width: '20%'
    },
    btn: {
        width: '4%'
    },
    icon: {
        fontSize: '.8rem'
    },
    totalSection:{
        borderRadius:'10px',
        backgroundColor:'#fbfafa'
    }
}));
const SetUpSuppliers = ({actions, snackbars,match:{params}}) => {
    const classes = useStyles();
    const components = [
        {label: 'Accounting Period', path: '/accounting/setup'},
        {label: 'Suppliers', path: '/accounting/setupledger/suppliers'},
        {label: 'Customers', path: '/accounting/setupledger/customers'},
        {label: 'Products', path: '/accounting/setupproducts'},
        {label: 'Services', path: '/accounting/setupservices'},
    ]
    const {component} = params;
    const title = {
        'suppliers':'Supplier',
        'customers':'Customer',
    }
    const headData = [
        { id: 'service_name', label: title[component], minWidth: 200 },
        { id: 'debit_ledger', label: 'Debit Ledger', minWidth: 200 },
        { id: 'credit_ledger', label: 'Credit Ledger', minWidth: 200 },
        { id: 'action', label: 'Action', minWidth: 200 },
    ]
    
    const tableData = [{name:title[component], isRequired:true},{name:'Debit', isRequired:true},{name:'Credit', isRequired:true},
    {name:'Action', isAction:true}]
    const {closeSnackbar,supplierProps,  ledgerData, editProps, isSetUp, handleChangeSetup} = useSetUpSuppliers(actions, component)
    const {openDelete, handleCloseDelete,  handleDelete, openEdit, handleCloseEdit}= ledgerData
    const {accounts} = supplierProps
    const {openBar, type, message} = snackbars;
    const text = component ?  title[component].toLowerCase() : ''
    return (
        <div className='journals'>
            <PageTitle title={`Setup ${title[component]}s`}/>
            <SubHeader title="Accounting" subTitle={`Setup ${title[component]}s`}>
                <FontAwesomeIcon icon={faMoneyBill}/>
            </SubHeader>
            <MainSnackbar message={message} open={openBar} handleCloseBar={closeSnackbar} variant={type}/>
            <CustomDialog open={openEdit} handleClose={handleCloseEdit} title={`Edit ${title[component]}`}>
                <EditSetupForm {...{...editProps, accounts,item_name:title[component]}}/>
            </CustomDialog>
            <DeleteDialog message='delete' openDialog={openDelete} handleClose={handleCloseDelete}
            text={text} title={`Delete ${title[component]}`}>
                <button className='btn sms-btn-dismiss btn-sm' onClick={handleDelete}>Delete</button>
                <button className="btn sms-grey-btn btn-sm" onClick={handleCloseDelete}>No</button>    
            </DeleteDialog>
            <Container>
                <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/>
                <div className='general-ledger-header inpatient-billing-header mb-3'>
                    <a className={`billing-span btn btn-sm ${isSetUp === 0 ? 'active-billing-span':''}`} onClick={()=>handleChangeSetup(0)}>Setup</a>
                    <a className={`billing-span btn btn-sm ${isSetUp === 1 ? 'active-billing-span':''}`} onClick={()=>handleChangeSetup(1)}>{`${title[component]} Ledgers`}</a>
                </div>
                {isSetUp === 0 ? <AccountSetUpForm {...{...supplierProps, headData:tableData, component:title[component]}}/>:null}
                {isSetUp === 1 ? <SetupLedgerList {...{...ledgerData, headData}}/>:null}
            </Container>
        </div>
        
    )
}

function mapStateToProps(state) {
    const {snackbar} = state;
    return {snackbars:snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(SetUpSuppliers);