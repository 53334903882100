import React from "react";
import Form from "react-bootstrap/Form";
import Label from "../../Utils/FormInputs/Label";
import {ErrorMessage, RightAlignedContainer} from "../../Utils/styledComponents";
import TextField from "../../Utils/FormInputs/TextField";
import {useReturnProduct} from "./hooks/useReturnProduct";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../../actions/snackbarActions";
import {connect} from "react-redux";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faShippingFast} from "@fortawesome/free-solid-svg-icons/faShippingFast";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {Container} from "../../Utils/styledComponents"
import {dateConvert} from "../../Utils/ConvertDate";
import  {ResizableTextarea} from "../../Utils/FormInputs/Textarea";
import PrintDialog from "../../Utils/Dialogs/PrintDialog";
import {BackButton} from "../../Utils/Buttons/DataExportationButton";
import {CollapsibleList, DataCell, ItemText, ModuleTable} from "../../Utils/Lists/CollapsibleList";
import TableRow from "@material-ui/core/TableRow";
import {formatDigits} from "../../Utils/formatNumbers";
import { AutoCompleteControlled } from "../../Utils/FormInputs/AutoCompleteInput";
import {CustomSelect} from "../../Utils/FormInputs/SelectInput";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {StyledRemoveIcon} from "../../DoctorsModule/doctorStyles";



const headData = [{item:'Product',toolTip:'Product name', isRequired:true},
    {item:'Batch', isRequired:true},{item:'Quantity Purchased', isRequired:true},
    {item:'Stock', isRequired:true},{item:'Return Quantity', isRequired:true},
    {item:'Unit Cost', isRequired:true},{item:'Discount(%)', isRequired:true},
    {item:'Total', isRequired:true},{item:'Action'}]


function ReturnProducts({actions, snackbars}) {
    const {
        supplier, suppliers,  rows,details, openDialog, submitted, totals,
        isPending, handleOpen, handleChangeSupplier,  handleChangeDetails,handleRemove,
        handleSubmit, handleCancel, handleChangeDiscount, handleChangeQuantity, handleCloseDialog,
        handleOpenReceipt, closeSnackbar,invoice, handleChangeInvoice,data
    } = useReturnProduct(actions)
    const {openBar, type, message} = snackbars;
    const {total_discount, grand_total_price} = totals
    const date = dateConvert()
    return (
        <div className='journals'>
            <PageTitle title='Return Products'/>
            <SubHeader title="Inventory" subTitle='Return Products'>
                <FontAwesomeIcon icon={faShippingFast}/>
            </SubHeader>
            <PrintDialog openDialog={openDialog} message='View Report' handleClose={handleCloseDialog} handleCancel={handleCancel}
            text='Would you like to view the return report' preview='Cancel' next='View' handleClick={handleOpenReceipt}/>
            <MainSnackbar message={message} open={openBar} handleCloseBar={closeSnackbar} variant={type}/>
            <Container>
                <div className='mb-4'>
                    <BackButton to='/returns/supplier' text='Return List'/>
                </div>

                    <div className="row">
                        <div className="col-md-4">
                            <Form.Group>
                                <Label name="Date"/>
                                <TextField type='date' value={date} readOnly/>
                            </Form.Group>
                            <Form.Group>
                                <Label name="Supplier" type/>
                                {/**
                                <CustomSelect submitted={submitted} onChange={handleChangeSupplier} value={supplier}
                                              options={suppliers}
                                              placeholder="Select supplier..."/>
                                {(submitted && !supplier) ?
                                    <ErrorMessage> Supplier is required </ErrorMessage> : ''}
                                 */}
                                <AutoCompleteControlled options={suppliers} submitted={submitted} handleChange={handleChangeSupplier}
                                    errorMsg="Supplier is required<" medicine_value={supplier} placeholder="Select Supplier..."
                                        value_text='value' label_text='label' />
                            </Form.Group>

                            <Form.Group>
                                <Label name="Invoice" type/>
                                <CustomSelect submitted={submitted} onChange={handleChangeInvoice} value={invoice}
                                              options={data.map(item=>({value:item.invoice_no, label:item.invoice_no}))}
                                              placeholder="Select invoice..." isDisabled={!supplier}/>
                                {(submitted && !supplier) ?
                                    <ErrorMessage> Supplier is required </ErrorMessage> : ''}

                            </Form.Group>

                        </div>
                        <div className="col-md-5">
                            <Form.Group>
                                <Label name="Total Discount"/>
                                <TextField name="total_discount" value={total_discount}
                                                   readOnly/>

                            </Form.Group>
                            <Form.Group>
                                <Label name="Net Return"/>
                                <TextField name="grand_total_price" value={formatDigits(grand_total_price)}
                                                   readOnly/>

                            </Form.Group>
                            <Form.Group>
                                <Label name="Details"/>
                                <ResizableTextarea name="details" value={details} rows='3'
                                                   onChange={handleChangeDetails}/>

                            </Form.Group>
                        </div>
                    </div>
                    {rows.map((item)=>{
                        return(
                            <CollapsibleList key={item.id} name={`Invoice no.: ${item.invoice_no}`} open={item.open} handleOpen={()=>handleOpen(item.id)}
                                             user={ <ItemText  primary={item.date} />} >
                                <form onSubmit={e=>handleSubmit(e, item)} autoComplete="off">
                                <ModuleTable headData={headData}>
                                    {item.products.map((row) => {
                                        return(
                                            <TableRow
                                                key={row.product_purchase_details_id}
                                            >
                                                <DataCell>
                                                    {row.product_name}
                                                </DataCell>
                                                <DataCell>
                                                    {row.batch_id}
                                                </DataCell>
                                                <DataCell align='center'>
                                                    {row.purchase_quantity}
                                                </DataCell>

                                                <DataCell>
                                                    {row.available_quantity}
                                                </DataCell>
                                                <DataCell>
                                                    <TextField type="number" value={row.product_quantity}
                                                               onChange={(e) => handleChangeQuantity(e, item.id, row.product_purchase_details_id)}/>
                                                    {submitted && row.product_purchase_id === item.id && !row.product_quantity ?
                                                        <ErrorMessage>Quantity is required</ErrorMessage> :
                                                        (row.product_quantity && row.product_quantity > row.purchase_quantity) ?
                                                            <ErrorMessage>You can only return up
                                                                to {row.purchase_quantity}</ErrorMessage> : null}
                                                </DataCell>
                                                <DataCell>
                                                    {row.rate}
                                                </DataCell>
                                                <DataCell>
                                                    <TextField type="number" value={row.discount}
                                                               name="discount"
                                                               onChange={(e) => (handleChangeDiscount(e, item.id, row.product_purchase_details_id))}/>

                                                </DataCell>
                                                <DataCell>
                                                    {row.total}
                                                </DataCell>
                                                <DataCell>
                                                    <Tooltip title='Remove'>
                                                        <IconButton type='button' onClick={() => handleRemove(item.id, row.product_purchase_details_id)}>
                                                            <StyledRemoveIcon/>
                                                        </IconButton>
                                                    </Tooltip>
                                                </DataCell>
                                            </TableRow>
                                        )})}
                                    {/*<TableRow>*/}
                                    {/*    <DataCell colSpan={headData.length}>*/}
                                    {/*        <div className="mt-4">*/}
                                    {/*            <Form.Check type="radio" name='radio'*/}
                                    {/*                        value={+(1)}*/}
                                    {/*                        defaultChecked onChange={handleChangeState}*/}
                                    {/*                        label="Return to Supplier"/>*/}
                                    {/*            <Form.Check type="radio" name='radio'*/}
                                    {/*                        value={+(2)} onChange={handleChangeState}*/}
                                    {/*                        label="Wastage"/>*/}
                                    {/*            <Form.Check type="radio" name='radio'*/}
                                    {/*                        value={+(3)} onChange={handleChangeState}*/}
                                    {/*                        label="Damage"/>*/}
                                    {/*        </div>*/}
                                    {/*    </DataCell>*/}
                                    {/*</TableRow>*/}

                                </ModuleTable>
                                    <RightAlignedContainer>
                                        <button type='submit' disabled={isPending} className='btn btn-sm sms-btn m-3'>{isPending ? 'Submitting...' : 'Submit'}</button>
                                    </RightAlignedContainer>
                                </form>
                            </CollapsibleList>
                        )
                    })}


            </Container>
        </div>
    )
}

function mapStateToProps(state) {
    const {snackbar} = state;
    return {snackbars: snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(ReturnProducts);