import * as types from "../../actions/actionTypes";


const initialState = {
    total_count: 0,
    patient: {},
    patients: [],
    previousVisits: [],
    openVisit: false,
    patient_number: 0,
    visitDetails: {}
};
export default function patientsReducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_PATIENT_PREVIOUS_VISITS:
            return {...state, previousVisits: action.previousVisits};
        case types.GET_PREVIOUS_VISIT_DETAILS:
            return {...state, visitDetails: action.visitDetails};
        case types.PATIENT_COUNT: {
            return {...state, total_count: action.total_count}
        }
        case types.LOAD_PATIENTS_SUCCESS:
            return {...state, patient: action.patient};
        case types.REGISTER_PATIENT_REQUEST:
            return {loading: true};
        case types.REGISTER_PATIENT_SUCCESS:
            return {loading: false};
        case types.REGISTER_PATIENT_ERROR:
            return {loading: false};
        case types.RETRIEVE_PATIENTS_REQUEST:
            return {loading: true};
        case types.OPEN_VISIT:
            return {...state, openVisit: true};
        case types.CLOSE_VISIT:
            return {...state, openVisit: false};
        case types.GET_PATIENT_ID:
            return {...state, patient_number: action.patient_number};
        case types.RETRIEVE_PATIENTS_SUCCESS:
            return {...state, patients: action.patients, loading: false};
        case types.RETRIEVE_PATIENTS_ERROR:
            return {loading: false};
        case types.DELETE_PATIENT:
            return {
                ...state,
                patients: state.patients.filter(patient => patient.patient_number !== action.patient_number)
            };
        case types.EDIT_PATIENT_REQUEST:
            return {loading: true};
        case types.EDIT_PATIENT_SUCCESS:
            return {loading: false};
        case types.EDIT_PATIENT_ERROR:
            return {loading: false};
        default:
            return state;
    }
}
