import {useEffect, useState} from "react";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";

export const useRoleList = (props) =>{
    const [searchValue, setSearchValue] = useState('');
    const [openMenu,setOpenMenu] = useState(null);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('sl');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [role_id,setRoleId] = useState('');
    const [roles,setRoles] = useState([]);
    const [loading,setLoading] = useState(false);
    const [openDelete,setDelete] = useState(false);
    const {actions} = props;

    useEffect(()=>{
        setLoading(true);
        axios.get(`${config.smsUrl}/permission/role_list`).then(res=>{
            const data = res.data;
            const dt = !data ? {} : data;
            const list = !dt.user_list ? [] : dt.user_list;
            setRoles(list);
            setLoading(false);
        }).catch(err=>{
            logoutErrorMessage(err,null, actions);
            setLoading(false);
        })
    }, []);

    const handleOpenDelete = (id) =>{
        setDelete(true)
        setRoleId(id)
    };

    const handleCloseDelete = () =>{
        setDelete(false)
    };

    const handleDelete = () =>{
        const formData = new FormData();
        formData.append('role_id', role_id);
        axios.post(`${config.smsUrl}/permission/role_delete`, formData).then(()=>{
            const allRoles = roles.filter(user=>user.role_id !== role_id);
            setRoles(allRoles);
            actions.snackbarActions.snackSuccess('Role deleted successfully');
            setOpenMenu(null)
        }).catch(err=>{
            errorMessages(err, null, actions)
            setOpenMenu(null)
        });
        setDelete(false)
    };

    const handleCloseSnackbar = () =>{
        actions.snackbarActions.hideSnackbar()
    };


    const handleSearch = (searchValue) =>{
        setSearchValue(searchValue)
    };

    const handleOpenMenu = (event,r) =>{
        setOpenMenu(event.currentTarget);
        setRoleId(r)
    };

    const handleCloseMenu = () =>{
        setOpenMenu(null)
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return {searchValue, openMenu, order, orderBy, page, rowsPerPage, roles, loading,
        openDelete, handleOpenDelete, handleCloseDelete, handleDelete, handleCloseSnackbar,
        handleSearch, handleOpenMenu, handleCloseMenu, handleRequestSort, handleChangePage,
        handleChangeRowsPerPage}
}