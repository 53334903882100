import React, {useEffect, useState} from "react";
import {useTheme} from "@material-ui/core/styles";
import {titleCase} from "../../Users/addUser";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {logoutErrorMessage} from "../../../Helpers/ErrorMessages";

const splitDiagnosisNotes = (txt) => {
    const nt = txt ? txt : '';
    return nt.split('\n').map(text => <p style={{margin: 0}}>{text}</p>)
}


const PreviousDiagnosis = ({actions, match, isResolved=''}) => {
    const {visit_id} = match;
    const [codes, setCodes] = useState([]);
    const [previousDiagnosis, setPreviousDiagnosis] = useState([]);
    const [notes, setNotes] = useState([]);
    useEffect(() => {
        const formData = new FormData();
        formData.append('visit_id', visit_id)
        axios.post(`${config.smsUrl}/cdoctor/get_previous_diagnosis`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const items = dt.diagnosis ? dt.diagnosis : []
            setPreviousDiagnosis(items);
        }).catch(error => {
            logoutErrorMessage(error, null, actions)
        });
    }, [isResolved])

    useEffect(() => {
        const arr = previousDiagnosis.map(({diagnosis_code, diagnosis, notes}) => ({
            code: diagnosis_code, diagnosis, notes
        }))
        const prevNotes = arr.map(item => item.notes);
        const newArr = [...new Set(prevNotes)];
        const notesArr = newArr.filter(item=>Boolean(item))
        setNotes(notesArr);
        setCodes(arr)
    }, [previousDiagnosis]);
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    return (
        <div className="patient-triage mt-3">
            {codes.length > 0 ? <div className="table-responsive">
                <table
                    className={`table table-sm table-bordered billing-invoice-table procedure-table ${palette.type === 'dark' &&
                    'dark-table dark-table-bordered'}`}>
                    <thead>
                    <td className={palette.type === 'dark' && 'dark-theme-color'}>Disease</td>
                    <td className={palette.type === 'dark' && 'dark-theme-color'}>ICD code</td>
                    </thead>
                    <tbody>
                    {codes.map((test) => (
                        <tr key={test.code}>
                            <td>{titleCase(test.diagnosis)}</td>
                            <td className={palette.type === 'dark' && 'dark-theme-color'}>{test.code}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>:null}
            {notes.length > 0 ? <div>
                <p style={{margin: 0, textDecoration: 'underline'}}>Diagnosis Notes:</p>
                <div className='diagnosis-notes' >
                    {notes.map((item, index) => (
                    <div key={index}>{splitDiagnosisNotes(item)}</div>
                ))}
                </div>
            </div>: null}
        </div>
    )
};


export default PreviousDiagnosis;
