import {useState, useEffect} from "react";
import {history} from "../../../../Helpers/history";
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import { titleCase } from "../../../Users/addUser";

export const useRegisterDeath = (actions) =>{
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const [p_number, setPatientNumber] = useState('');
    const [patient_no, setPatientNo] = useState( '')
    const [patient_name, setPatientName] = useState('');
    const [patientNumbers, setPatientNumbers] = useState([]);
    const [showNumber, setShowNumber] = useState(false);
    const [patientDetails, setPatient] = useState({patient_name: '', dob: '', age: '', residence: '', gender: '', patient_number: ''});
    const [state, setState] = useState({
        death_date: '', death_time: '', district: '', immediate: '', underlying: '', omorbid_illness: '', witnessed_by: '', recieved_by: '',  country: '', occupation: '',
        municipality: '', patient_type: 0, witness_tel: '', recievedby_tel: '', tribe: '', bed_no: 0, recievedby_rel: '',  witness_rel: '', religion: '', ward: ''
    });
    const [nationality, setNationality] = useState('');
    const [ward, setWard] = useState('')
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [openDialog, setDialog] = useState(false);
    const [patient_death_id, setPatientId] = useState('')
    const [visit_id, setVisitId] = useState('')
    const [wards, setWards] = useState([])
    const [search_parameter, setSearchParameter] = useState('patient_no')
    const reported_by = `${!_token.first_name ? "" : _token.first_name} ${!_token.last_name ? "" : _token.last_name}`;
    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;

    const handleCloseDialog = () => {
        setDialog(false)
        history.push('reports/deathreports')
    };

    const viewReport = () => {
        const {patient_type} = state;
        history.push(`/viewdeathreport/${patient_death_id}/${patient_type}`)
    }

    const handleChange = (event) => {
        const {name, value} = event.target;
        setState({...state, [name]: value})
    }

    const handleChaneNationality = (value) => {
        setNationality(value)
    }

    const handleChangeWard = (value) => {
        setWard(value)
    }

    const retrievePreviousVisits = (p_number) => {
        const formData = new FormData();
        formData.append('patient_number', p_number);
        axios.post(`${config.smsUrl}/cpatient/get_patient_previous_visits`, formData).then((res) => {
            const data = res.data;
            const visit = !data ? {} : data;
            const visits = !visit.patient_visits ? [] : visit.patient_visits;
            const v = !visits[visits.length - 1] ? {}:visits[visits.length- 1] ;
            const id = v.visit_id ? v.visit_id : '';
            setVisitId(id);
        }).catch(err => {
            logoutErrorMessage(err, null, actions);
        })
    }

    const handleSearchPatientNumber = (event) => {
        setPatientNumber(event.target.value);
        const formData = new FormData();
        if (event.target.value !== '') {
            const patient_no = search_parameter === 'patient_no' ? event.target.value.toUpperCase() : ''
            const patient_name = search_parameter === 'patient_name' ? titleCase(event.target.value) : ''
            const phone_no = search_parameter === 'phone_no' ? event.target.value : ''
            formData.append('patient_number', patient_no);
            formData.append('name', patient_name);
            //formData.append('last_name', '');
            formData.append('phone_no', phone_no);
            axios.post(`${config.smsUrl}/cvisit/search_patient_number`, formData)
                .then(res => {
                    const details = res.data;
                    const data = !details ? [] : details;
                    if (data[0] === "No Patient Found") {
                        setPatientNumbers([{value: '', label: 'No patient found'}])
                    } else {
                        const arr = data.map(item => ({
                            value: item.patient_number,
                            label: `(${item.patient_number}) ${item.first_name} ${item.last_name} - ${item.phone_no}`
                        }))
                        setPatientNumbers(arr)
                    }
                }).catch(error => {
                logoutErrorMessage(error, null, actions);
            });
            setShowNumber(true);
        } else {
            setShowNumber(false);
        }

    };

    const handleClickPatientNumber = (val) => {
        setPatientNumber(val.label)
        const formData = new FormData();
        formData.append('patient_number', val.value);
        axios.post(`${config.smsUrl}/cpatient/patient_update_form`, formData)
            .then(res => {
                const patient = res.data;
                const pt = !patient ? {} : patient;
                const {first_name, last_name, dob, Age, gender, address, patient_number} = pt
                setPatient({
                    patient_name: `${first_name ? first_name : ''} ${last_name ? last_name : ''}`,
                    age: Age ? Age : '', dob:dob ? dob : '', gender:gender ? gender : '',
                    residence: address ? address : '', patient_number: patient_number ?? ''
                })
                retrievePreviousVisits(patient_number)
            }).catch(error => {
            logoutErrorMessage(error, null, actions);
        })
        setShowNumber(false)
    };

    useEffect(() => {
      axios.get(`${config.smsUrl}/cbedmanager/getAllWards`).then(res => {
        const data = res.data
        const dt = data.wards ? data.wards : []
        setWards(dt)
      }).catch( err=> {
        logoutErrorMessage(err, null, actions)
      })
    
     
    }, [])

    const handleSubmit = (event) => {
        event.preventDefault()
        const {municipality, death_date, death_time, district, occupation, patient_type, tribe, underlying, immediate, witnessed_by, witness_rel, witness_tel, recieved_by, recievedby_rel, recievedby_tel, omorbid_illness, religion, bed_no} = state;
        const {patient_number} = patientDetails
       
        const payload = {patient_number: patient_number,
            date_of_death: death_date,
            time_of_death: death_time,
            district_of_origin: district,
            municipality: municipality,
            nationality: nationality.value,
            underying_cause_of_death: underlying,
            immediate_cause_of_death: immediate,

            tribe: tribe,
            patient_type: +patient_type,
            religion: religion,
            omorbid_illness: omorbid_illness,
            received_by: recieved_by,
            received_by_relationship: recievedby_rel,
            received_by_phone_no: recievedby_tel,
            witnessed_by: witnessed_by,
            witnessed_by_relationship: witness_rel,
            witnessed_by_phone_no: witness_tel,
            ward_id: ward.value,
            bed_number: bed_no,
            user_roles_id: user_roles_id,
            visit_id: visit_id,
            country: nationality.value,
            occupation: occupation,

        
        }
        setSubmitted(true)
        console.log("ISS TRUE? ", (patient_type))
        console.log("Death date: ", death_date);
        console.log('Time: ', death_time);
        console.log("IMmeedicaite:: ", immediate) 
        console.log("Nationality : " , nationality)
        console.log("VIsit id: ",  visit_id)
        if (patient_type && death_date && death_time  && immediate && nationality && visit_id) {
            setIsSubmitted(true);
            axios.post(`${config.smsUrl}/creport/registerDeath`, payload).then(res => {
                const data = res.data;
                const val = !data ? [] : data;
                const value = val[1] ? val[1] : {};
                const _id = value.patient_death_id ? value.patient_death_id : ''
                setDialog(true);
                setPatientId(_id)
                setIsSubmitted(false);
                actions.snackbarActions.snackSuccess('Death registered successfully')
            }).catch(err => {
                setIsSubmitted(false);
                errorMessages(err, null, actions)
            })
        }
    }
    const handleReset = () => {
        setPatientName('');
        setState({
            death_date: '', death_time: '', district: '', cause_of_death: '',
            municipality: '', patient_type: ''
        });
        setPatient({patient_name: '', dob: '', age: '', residence: '', gender: ''});
        setNationality('')
        setSubmitted(false)
    }


    const handleChangeSearchParam = (event) =>{
        setSearchParameter(event.target.value)
        setPatientNumber('')
        setShowNumber(false);
    }
    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    }

    return {patient_name, patientNumbers, showNumber, patientDetails, nationality, submitted, p_number,
        isSubmitted, openDialog, patient_death_id, visit_id, reported_by, handleCloseDialog, viewReport,
        handleChange, handleChaneNationality, retrievePreviousVisits, handleSearchPatientNumber,
        handleClickPatientNumber, handleSubmit, handleReset, closeSnackbar, state,userObj,wards,
        token, _token, user_roles_id,setState, setNationality, handleChangeWard, ward, search_parameter, handleChangeSearchParam}
}