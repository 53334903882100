import React from "react";
import {PatientInformation} from "../PatientInfo/PatientInformation";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../../actions/snackbarActions"
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserMd} from "@fortawesome/free-solid-svg-icons/faUserMd";
import ReusableDoctorTabs, {doctorRoutes} from "../../Utils/Menu/ReusableDoctorTabs";
import {dateConvert} from "../../Utils/ConvertDate";
import {useTheme} from "@material-ui/core/styles";
import { useSurgery } from './hooks/useSurgery';
import { RightAlignedContainer} from "../../Utils/styledComponents";
import PrintDialog from "../../Utils/Dialogs/PrintDialog";
import ScheduleSurgery from "../../SurgeryModule/ScheduleSurgery";
import {DoctorsDetails} from '../../SurgeryModule/DoctorsDetails';


/**
 * Component to schedule surgery for patient both in and out
 * What to do after API calls :
 * @param params
 * @returns 
 */
const Surgery = ({actions, snackbars, match}) => {
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const {patient_number, visit_id, labrequest_id, scan_request_id, patient_type, patient_admission_id} = match.params
    const doctor_name = `${!_token.first_name ? "" : _token.first_name} ${!_token.last_name ? "" : _token.last_name}`;

    // lifting state for the different tabs
    const {
        state, patient, onChange, general_notes, handleNotesState,
        operation_class, operationClasses, handleOpClassChange,
        anaesthesia_type, anaesthesiaTypes, handleAnesTypeChange,
        handleSubmitSurgery, patientDetails,
        submitted, surgeon,surgeons, isOpen, handleChangeSurgeon, handleSelectSurgeon,
        surgery_name, handleSurgeryTypeChange, surgeryTypes, 
        handleCloseConfirm, openConfirmationDialog, surgeryConfirmation,
        handleChange, inputDoc,
        other_anaestheists, other_surgeons,isVisitingDoctor,
        handleOtherAnaestheists, handleOtherSurgeons,handleChangeDoctors,
    } = useSurgery(actions, patient_number, visit_id, patient_type);


    const { operation_date, estimated_start_time, duration_hr, duration_mins } = state;

    // deconstruct the patient object
    const {patient_name, age, gender, address, phone} = patient;
    // construct the route for Surgery
    const arr = doctorRoutes(patient_number, visit_id,  patient_type, patient_admission_id);
    
    // handle changing the theme from light to dark
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    // show the pop up messages
    const {openBar, type, message} = snackbars;

    return (
        <div className="journals">

            <PageTitle title="Surgery"/>

            <SubHeader title="Doctor" subTitle="Schedule Surgery" doctor_name={doctor_name}>
                <FontAwesomeIcon icon={faUserMd}/>
            </SubHeader>

            <ReusableDoctorTabs components={arr} patient_type={+patient_type}>

                <PatientInformation {...{patient_number, visit_id}}/>

                {surgeryConfirmation ?
                    <PrintDialog handleCancel={handleCloseConfirm} openDialog={surgeryConfirmation} handleClose={handleCloseConfirm}
                    handleClick={handleSubmitSurgery} message="Confirm Surgery"
                    text="Would you like to confirm this scheduled surgery?"/> : null
                }

                <form autoComplete="off">

                <ScheduleSurgery
                    patientDetails={patientDetails} 
                    doctorsModule={true} 
                    submitted={submitted} handleSubmitSurgery={handleSubmitSurgery}
                    operation_date={operation_date}  onChange={onChange}
                    operation_class={operation_class} operationClasses={operationClasses} handleOpClassChange={handleOpClassChange}    
                    surgery_name={surgery_name} surgeryTypes={surgeryTypes} handleSurgeryTypeChange={handleSurgeryTypeChange} 
                    estimated_start_time = {estimated_start_time} duration_hr={duration_hr} duration_mins={duration_mins}
                />

                <DoctorsDetails
                    other_anaestheists={other_anaestheists}
                    other_surgeons ={other_surgeons} 
                    anaesthesia_type={anaesthesia_type} anaesthesiaTypes={anaesthesiaTypes} handleAnesTypeChange={handleAnesTypeChange}
                    general_notes={general_notes}
                    handleNotesState={handleNotesState} 
                    doctorsModule={true} 
                    {...{surgeon, surgeons, isOpen, handleChangeSurgeon, handleChange, handleSelectSurgeon, handleOtherAnaestheists, handleOtherSurgeons, handleChangeDoctors, isVisitingDoctor, inputDoc}}
                />

                <RightAlignedContainer>
                    <button type="submit" className="btn btn-sm sms-btn"
                    onClick={openConfirmationDialog}
                    >Save surgery</button>
                </RightAlignedContainer>
                </form>

            </ReusableDoctorTabs>

        </div>

    )
};

function mapStateToProps(state) {
    return {
        snackbars: state.snackbar
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Surgery);
