import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormGroup from "../Utils/FormInputs/FormGroup";
import Label from "../Utils/FormInputs/Label";
import {titleCase} from "../Users/addUser";
import {useTheme} from "@material-ui/core/styles";
import {formatDateTime} from "../Utils/ConvertDate";
import {CustomSelect} from "../Utils/FormInputs/SelectInput";
import TextField from "../Utils/FormInputs/TextField";
import { ErrorMessage } from "../Utils/styledComponents";
import {ResizableTextarea} from "../Utils/FormInputs/Textarea";


const EditLabResults = ({resultState:state, patient, parameters,  submittedResult:submitted,q_type,
     technician, handleChangeResult, handleChangeResultState:handleChange, handleSubmitResult,isFetching,
     result, handleChangeQualitative
}) => {
    const {patient_number, patient_name, dob, gender,result_type,
        sample_no, sample_description, lab_test, drawn_date, drawn_by} = patient;
    const {date, report,observations} = state;
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    const reactive = [{value: 3, label: 'Reactive'}, {value: 2, label: 'Non-reactive'}];
    const blood_g = [
        {label: "A+", value: "A+"},
        {label: "A-", value: "A-"},
        {label: "B+", value: "B+"},
        {label: "B-", value: "B-"},
        {label: "O+", value: "O+"},
        {label: "O-", value: "O-"},
        {label: "AB+", value: "AB+"},
        {label: "AB-", value: "AB-"},
    ]
    const positive = [{value: 1, label: 'Positive'}, {value: 0, label: 'Negative'}];
    return (
        <form autoComplete="off" onSubmit={handleSubmitResult}>
            <div className="lab-patient-details mb-3">
                <div className="row col-md-12">
                    <div className="col-md-4">
                        <table className="table-sm table-borderless table prescription-table">
                            <tr>
                                <td><p>Patient ID: <span className="ml-2"><strong>{patient_number}</strong></span></p></td>
                                
                            </tr>
                            <tr>
                                <td><p>Patient Name: <span className="ml-2"><strong>{patient_name}</strong></span></p></td>
                                
                            </tr>
                            <tr>
                                <td><p>Age: <span className="ml-2"><strong>{dob}</strong></span></p></td>
                                
                            </tr>
                            <tr>
                                <td><p>Gender: <span className="ml-2"><strong>{gender ? titleCase(gender):''}</strong></span></p></td>
                                
                            </tr>
                        </table>

                    </div>
                    <div className="col-md-4">
                        <table className="table-sm table-borderless table prescription-table">
                            <tr>
                                <td><p>Sample No: <span className="ml-2"><strong>{sample_no}</strong></span></p></td>
                                
                            </tr>
                            <tr>
                                <td><p>Sample Description: <span className="ml-2"><strong>{sample_description}</strong></span></p></td>
                                
                            </tr>
                            <tr>
                                <td><p>Lab Test: <span className="ml-2"><strong>{lab_test}</strong></span></p></td>
                                
                            </tr>
                        </table>
                    </div>
                    <div className="col-md-4">
                        <table className="table-sm table-borderless table prescription-table">
                            <tr>
                                <td><p>Drawn Date: <span className="ml-2"><strong>{drawn_date ? formatDateTime(drawn_date):''}</strong></span></p></td>
                                
                            </tr>
                            <tr>
                                <td><p>Drawn By: <span className="ml-2"><strong>{drawn_by}</strong></span></p></td>
                                
                            </tr>
                            <tr>
                                <td><p>Result Type: <span className="ml-2"><strong>{result_type === 1 ? 'Qualitative' : result_type === 2 ?  'Quantitative':'Notes'}</strong></span></p></td>
                            
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
                <div className="row">
                    <div className="col-md-6">
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={3} xl={2}>
                                <Label name="Technician" htmlFor='technician' />
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                                <TextField type="text" disabled={true} id='technician' value={titleCase(technician)}/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={3} xl={2}>
                                <Label name="Comments" htmlFor='comments'/>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                                <ResizableTextarea rows={2} placeholder="Write comments..."  onChange={handleChange} id="comments" name="report" value={report}/>
                            </Col>
                        </Form.Group>
                    </div>
                    <div className="col-md-6">
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={3} xl={2}>
                                <Label name="Date & Time"  />
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                                <TextField onChange={handleChange} name="date"
                                           value={date} type="datetime-local"/>
                            </Col>
                        </Form.Group>
                        {result_type === 1 ? <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={3} xl={2}>
                                <Label name="Result" htmlFor='result' />
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                                <CustomSelect submitted={submitted} dataTest="result_type"  id='result' value={result}
                                                onChange={handleChangeQualitative}
                                                options={q_type === '+ve/-ve' ? positive :
                                                    q_type === 'blood_group' ? blood_g : q_type === 'reactive/non-reactive' ?
                                                        reactive : []}/>
                                                        {(submitted && !result) && <ErrorMessage>Result is required</ErrorMessage>}
                            </Col>
                        </Form.Group> : result_type === 3 &&
                        <>
                            <Form.Group as={Row}>
                                <Col xs={12} sm={12} md={12} lg={3} xl={2}>
                                    <Label name="Observations" htmlFor='observations'/>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                                    <ResizableTextarea  onChange={handleChange} name="observations"
                                                        value={observations} labelName="Observations"
                                                         submitted={submitted} id="observations"/>
                                    {(submitted && !observations) && <ErrorMessage>Observation(s) is required</ErrorMessage>}
                                </Col>
                            </Form.Group>
                        </>}
                    </div>
                </div>
                {result_type === 2 && <table className="table table-sm table-borderless">
                    <tr style={{borderTop: "1px solid #909090"}}>
                        <td width="70" className={palette.type === 'dark' && 'dark-theme-color'}><strong>Parameter</strong></td>
                        <td width="30" align="center" className={palette.type === 'dark' && 'dark-theme-color'}><strong>Result<span className="help-block">*</span></strong></td>
                        <td width="30" align="center" className={palette.type === 'dark' && 'dark-theme-color'}><strong>Normal Range</strong></td>
                        <td width="80" align="center" className={palette.type === 'dark' && 'dark-theme-color'}><strong>Unit</strong></td>
                       
                    </tr>
                    {parameters.map((item,index)=>(
                        <tr key={index} style={{borderTop: "1px solid #909090",}}>
                            <td width="30"> {item.parameter_name}
                            </td>
                            <td width="30" align="center"><TextField submitted={submitted} type="text"  value={item.result} onChange={e=>handleChangeResult(e, index)}
                                                        className={`form__control ${palette.type === "dark" && 'form-control-dark'}`}/>
                                                    {(submitted && !item.result) && <ErrorMessage>Result is required</ErrorMessage>}
                            </td>
                            <td className={palette.type === 'dark' && 'dark-theme-color'} align="center">{(item.min_range && item.max_range) ?
                                `${item.min_range} - ${item.max_range}`: 'NA'} </td>
                            <td align="center" className={palette.type === 'dark' && 'dark-theme-color'}>{item.unit}</td>
                        </tr>
                    ))}

            
                </table>}
                <button type="submit" id="submit" disabled={isFetching}
                        className="btn btn-sm sms-btn px-4">{isFetching ? "Saving...":"Save"}
                </button>
        </form>
    )
};



export default EditLabResults;
