import React from "react";
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as snackbarActions from "../../actions/snackbarActions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import {faKey} from "@fortawesome/free-solid-svg-icons/faKey";
import Card from "@material-ui/core/Card";
import {useTheme} from "@material-ui/core/styles";
import {useChangePassword} from "./CustomHooks/useChangePassword";


export const ChangePassword = ({actions, snackbars}) => {

    const theme = useTheme();
    // const them = !theme ? {} : theme;
    // const palette = !them.palette ? {} : them.palette;
    const {state, submitted, submitting, handleChange, handleSubmit,
        handleCloseSnackbar,passwordForm} = useChangePassword(actions)

    const {openBar, type, message,} = snackbars;
    return (
        <div>
            <SubHeader title="Change Password" subTitle="Change user password">
                <FontAwesomeIcon icon={faKey}/>
            </SubHeader>
            <div className="add-service-card">
                <Card className=" p-3">
                    <h6 className={`visit-heading ${theme.palette.type === "dark" && "dark-visit-heading"}`}>Change
                        Password</h6>
                    <MainSnackbar message={message} open={openBar} handleCloseBar={handleCloseSnackbar} variant={type}/>
                    <Form ref={passwordForm} onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-md-6">
                                <Form.Group as={Row}>
                                    <Form.Label column sm="5" style={{textAlign:'right'}}>Email<span className="help-block">*</span></Form.Label>
                                    <Col sm="7">
                                        <Form.Control type="email" name="user_name" value={state.user_name}
                                                      onChange={handleChange}
                                                      className={`form__control ${theme.palette.type === "dark" && 'form-control-dark'}`} id="email"/>
                                        {(submitted && !state.user_name) &&
                                        <div className="help-block">Please enter your email address</div>}
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label column sm="5" style={{textAlign:'right'}}>Old Password<span
                                        className="help-block">*</span></Form.Label>
                                    <Col sm="7">
                                        <Form.Control type="password" name="old_password" value={state.old_password}
                                                      onChange={handleChange}
                                                      className={`form__control ${theme.palette.type === "dark" && 'form-control-dark'}`} id="old_password"/>
                                        {(submitted && !state.old_password) &&
                                        <div className="help-block">Please enter your old password</div>}
                                    </Col>
                                </Form.Group>

                            </div>
                            <div className="col-md-6">
                                <Form.Group as={Row}>
                                    <Form.Label column sm="5" style={{textAlign:'right'}}>New Password<span
                                        className="help-block">*</span></Form.Label>
                                    <Col sm="7">
                                        <Form.Control type="password" name="new_password" value={state.new_password}
                                                      onChange={handleChange}
                                                      className={`form__control ${theme.palette.type === "dark" && 'form-control-dark'}`} id="new_password"/>
                                        {(submitted && !state.new_password) &&
                                        <div className="help-block">Please enter your new password</div>}
                                    </Col>
                                </Form.Group>


                                <Form.Group as={Row}>
                                    <Form.Label column sm="5" style={{textAlign:'right'}}>Re-Type Password<span
                                        className="help-block">*</span></Form.Label>
                                    <Col sm="7">
                                        <Form.Control type="password" name="repassword" value={state.repassword}
                                                      onChange={handleChange}
                                                      className={`form__control ${theme.palette.type === "dark" && 'form-control-dark'}`} id="re_password"/>
                                        {(submitted && !state.repassword) ?
                                            <div className="help-block">Please re-type the password</div> :
                                            (state.new_password !== state.repassword) &&
                                            <div className="help-block">The two passwords don't match</div>}
                                    </Col>
                                </Form.Group>
                            </div>
                        </div>
                        <div className="text-center">
                            <button type="submit" disabled={!!submitting}
                                    className="btn sms-btn px-4">{submitting ? "Submitting...." : "Submit"}</button>
                        </div>
                    </Form>
                </Card>
            </div>
        </div>

    )
};

function mapStateToProps(state) {
    return {
        snackbars: state.snackbar
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);


