import React, {useEffect, useState} from "react";
import {formatDateTime} from "../Utils/ConvertDate";
import PrintDialog from "../Utils/Dialogs/PrintDialog";
import {titleCase} from "../Users/addUser";
import {CustomSelect} from "../Utils/FormInputs/SelectInput";
import Textarea from  "../Utils/FormInputs/Textarea"
import { ErrorMessage } from "../Utils/styledComponents";
import Col from "react-bootstrap/Col";
import Label from "../Utils/FormInputs/Label";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import TextField from "../Utils/FormInputs/TextField";
import axios from "axios";
import {config} from "../../Helpers/env";
import {textWeight} from "../../styles/tableStyles";


const CollectSample = ({
    sampleState, patient,  openSampleNo, sample_no,
    sampleTypes, technician, handleChangeSampleState, closeSampleNo, handleChangeSampleTypes,
    handleSubmitSample,  labTests,handleChangeSampleDescription, 
     isFetching, submittedSample,visit_id, handleChangeAddToInvoice
}) => {

    const {drawn_date} = sampleState;
    const {patient_id, patient_name, age, gender,  requested_by,  time_requested} = patient;

    const [visitDetails, setVisitDetails] = useState({})

    useEffect(()=>{
        if(!visit_id){
            return
        }
        axios.get(`${config.smsUrl}/cvisit/${visit_id}`).then(res=>{
            const data = res.data;
            const dt = !data ? {} : data;
            setVisitDetails(dt)
        }).catch(err=>{
        })
    },[visit_id]);

    const sample_numbers = (
        <table className="table table-sm table-bordered billing-invoice-table">
            <thead>
                <tr>
                    <td><strong>Test</strong></td>
                    <td><strong>Sample No.</strong></td>
                </tr>
            </thead>
            <tbody>
                {sample_no.map(item=>(
                    <tr key={item.sample_no}>
                        <td>{item.hospital_test_name}</td>
                        <td>{item.sample_no}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
    return (
        <div>
            <PrintDialog handleClose={closeSampleNo} openDialog={openSampleNo} text={sample_numbers} message="Sample No(s)."
                         sample/>
            <form autoComplete="off" onSubmit={handleSubmitSample}>
                <div className="lab-patient-details mb-3">
                    <table className="table table-borderless table-sm prescription-table">
                        <tr>
                            <td><p>Patient ID: <span className="ml-2"><strong>{patient_id}</strong></span></p></td>
                            <td><p>Request by:<span
                                className="ml-2"><strong>{requested_by ? titleCase(requested_by) : ''}</strong></span>
                            </p></td>
                        </tr>
                        <tr>
                            <td><p>Patient Name: <span
                                className="ml-2"><strong>{titleCase(!patient_name ? "" : patient_name)}</strong></span>
                            </p></td>
                            <td><p>Date Requested:<span
                                className="ml-2"><strong>{time_requested ? formatDateTime(time_requested) : ''}</strong></span>
                            </p></td>
                        </tr>
                        <tr>
                            <td><p>Age: <span className="ml-2"><strong>{!age ? "" : age}</strong></span></p></td>
                            <td><p>Gender: <span className="ml-2"><strong>
                                {titleCase(!gender ? "" : gender)}</strong></span></p></td>
                        </tr>
                        <tr>
                            <td><span>Payment Mode: </span> <span
                            ><strong>{visitDetails.is_on_insurance === 1 ? 'CREDIT' : visitDetails.is_on_insurance === 0 ? 'CASH' : ''}</strong></span>
                            </td>
                            {visitDetails.is_on_insurance === 1 ?
                                <td><span>Provider Name: </span> <span><strong>{visitDetails?.insurance_provider_name}</strong></span></td> : <td/>}
                        </tr>
                        <tr>
                            {visitDetails.is_on_insurance === 1 ?
                                <td><span>Account Name: </span> <span><strong>{visitDetails?.account_name}</strong></span></td> : <td/>}
                            <td/>
                        </tr>
                    </table>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={3} xl={2}>
                                <Label name="Date & Time"/>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                                <TextField onChange={handleChangeSampleState} name="drawn_date"
                                           value={drawn_date}
                                           type="datetime-local"/>
                            </Col>
                        </Form.Group>

                    </div>
                </div>
            
                <table className="table table-sm table-bordered billing-invoice-table procedure-table">
                    <thead>
                    <tr>
                        <th><strong>Lab Test</strong></th>
                        <th><strong>Lab</strong></th>
                        <th><strong>Status</strong></th>
                        <th><strong>Sample Type</strong></th>
                        <th><strong>Sample Description</strong></th>
                        {visitDetails.is_on_insurance === 1 ? <th><strong>Add to Invoice</strong></th> : null}
                    </tr>
                    </thead>
                    <tbody>
                    {labTests.map((item, index)=>(
                            <tr key={item.labrequests_detail_id}>
                                <td>{item.hospital_test_name}</td>
                                <td>{item.hospital_test_type}</td>
                                <td>{item.is_external === true ? 'External Test' : item.is_external === false ? 'Internal Test' : ''}</td>
                                <td>
                                    <CustomSelect submitted={submittedSample} value={item.sample_type}
                                                  onChange={(value) => handleChangeSampleTypes(value, item.labrequests_detail_id)}
                                                  options={sampleTypes.map(item => ({
                                                      value: item.type_id,
                                                      label: item.type_name
                                                  }))} id={`sample_${index}`}/>
                                    {(submittedSample && !item.sample_type) &&
                                        <ErrorMessage>Sample type is required</ErrorMessage>}
                                </td>
                                <td>
                                    <Textarea name="sample_description" value={item.sample_description}
                                              onChange={(e) => handleChangeSampleDescription(e, item.labrequests_detail_id)}/>
                                </td>
                                {visitDetails.is_on_insurance === 1 ? <td><input type="checkbox" name="add_to_invoice"
                                           onChange={(e) => handleChangeAddToInvoice(e, item.labrequests_detail_id)}
                                           />
                                </td> : null}
                            </tr>
                        ))}
                    </tbody>
                </table>
                <button type="submit" id="submit" disabled={isFetching}
                        className="btn btn-sm sms-btn px-4">{isFetching ? "Saving..." : "Save"}</button>
            </form>
        </div>
    )
};

export default CollectSample;
