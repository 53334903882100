import React from "react";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CustomTable from "../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import * as snackbarActions from "../../actions/snackbarActions"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PageTitle from "../Utils/smsTitle";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import {formatDateTime} from "../Utils/ConvertDate";
import {history} from "../../Helpers/history";
import {ReusableRouteTabs} from "../Utils/Dialogs/ReusableTabs";
import { useEmergencyList } from "./hooks/useEmergencyList";
import { faWheelchair } from "@fortawesome/free-solid-svg-icons/faWheelchair";
import { Container } from "../Utils/styledComponents";
import { FullScreenDialog } from "../Utils/Dialogs/FullScreenDialog";
import EmergencyDetails from "./EmergencyDetails";
import CustomDialog from "../Utils/Dialogs/CustomDialog";
import { AttachPatient } from "./AttachPatient";
import { PatientContainer } from "./RecordPatient";
import { PatientForm } from "../PatientRecords/PatientForm";
import { RightAlignedContainer } from "../Utils/styledComponents";
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';

const headData = [
    {id: 'sl', numeric: false, disablePadding: false, label: 'Sl'},
    {id: 'date', numeric: false, disablePadding: false, label: 'Date'},
    {id: 'patientNo', numeric: false, disablePadding: false, label: 'Patient NO'},
    {id: 'patientName', numeric: false, disablePadding: false, label: 'Patient Name'},
    {id: 'age', numeric: false, disablePadding: false, label: 'Age'},
    {id: 'gender', numeric: false, disablePadding: false, label: 'Gender'},
    {id: 'actions', numeric: false, disablePadding: true, label: 'Action'},
];

export const EmergencyList = ({actions, snackbars}) => {

    const {searchValue,activePage, emergency, emergencyDetails, openDialog, isLoading,isSuccess,
        isError, isPending, isRejected, isResolved, handleChangeNextPage, handleCloseDialog, 
        handleOpenDialog, handleSearch, closeSnackbar,savePatientProps,attachPatientProps,isSubmitted,
        handleSubmitPatient, openAttach,handleOpenAttach, handleCloseAttach,isSavingPatient} = useEmergencyList(actions)
    const {emergencies,total_count} = emergency
    const {openDialog:openPatient, handleOpenDialog:handleOpenPatient, handleCloseDialog:handleClosePatient} = savePatientProps

    const components =[  
        {label:'Record Vitals', path:'/EmergencyPatient'},
        {label:'Emergency list', path:'/emergencylist'}
    ]
    const {openBar, type, message} = snackbars;
    const {emergencyObj} = emergencyDetails;
    const handleOnAfterPrint = () => {
        handleCloseDialog();
        history.push('/emergencylist');
    }

    return (
        <div data-testid="doctor-waiting-list" className='journals'>
            <PageTitle title="Emergency  List"/>
            <SubHeader title="Patient Emergency" subTitle="Emergency List">
                <FontAwesomeIcon icon={faWheelchair}/>
            </SubHeader>
            <CustomDialog open={openDialog} handleClose={handleCloseDialog} title="Emergency Details" maxWidth="lg">
                <EmergencyDetails {...{isPending, isResolved, isRejected, emergencyObj}}/>
            </CustomDialog>
            <CustomDialog open={openPatient} handleClose={handleClosePatient} title="Register Patient" maxWidth='lg'>
                <PatientForm {...savePatientProps}>
                    <button type='submit' className='btn sms-btn px-3'>Save</button>
                </PatientForm>
            </CustomDialog>
            <CustomDialog open={openAttach} handleClose={handleCloseAttach} title='Attach Patient Details' maxWidth="md">
                <PatientContainer>
                    <RightAlignedContainer>
                        <button onClick={handleOpenPatient} className='btn btn-sm sms-info-btn'><FontAwesomeIcon icon={faPlus}/> Quick Add Patient</button>
                    </RightAlignedContainer>
                    <form autoComplete='off'>
                        <AttachPatient {...attachPatientProps}>
                        <button type="submit" disabled={isSavingPatient}
                            onClick={(e)=>handleSubmitPatient(e)}
                            className="btn btn-sm sms-btn px-4">{isSavingPatient ? 'Saving...': 'Save patient'}</button>   
                        </AttachPatient>
                    </form>
                </PatientContainer>
            </CustomDialog>
            <Container>
                <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/>
         
                <MainSnackbar variant={type} handleCloseBar={closeSnackbar} open={openBar} message={message}/>
                <CustomTable  customPage handleChangeNextPage={handleChangeNextPage}
                            headData={headData} handler={handleSearch} records={10} total_count={total_count}
                            activePage={activePage}
                            data={emergencies} colSpan={headData.length} 
                            term={searchValue}>
                    
                        <TableBody>
                            {isLoading ? <TableRow>
                                <TableCell colSpan={headData.length} align="center"><img src="/images/smsloader.gif"
                                                                        alt="loader"/></TableCell>
                            </TableRow>:null}
                            {isSuccess ?  emergencies.length > 0 ? emergencies.slice(0,10)
                                .map((item, index) => {
                                    const count = ((activePage - 1) * 10) + index + 1;
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={item.visit_id}
                                            className='mui-table-row'
                                        >
                                            <TableCell>{count}</TableCell>
                                            <TableCell>{item.begin_datetime ? formatDateTime(item.begin_datetime) : item.begin_datetime}</TableCell>
                                            <TableCell>{item.patient_number}</TableCell>
                                            <TableCell>{`${item.first_name} ${item.last_name}`}</TableCell>
                                            <TableCell>{item.age}</TableCell>
                                            <TableCell>{item.gender}</TableCell>
                                            <TableCell>
                                            {item.patient_number === 'P0000001E' ? <button onClick={()=>handleOpenAttach(item.visit_id)}
                                                        className="btn btn-sm sms-info-btn mr-1 mb-1">Add Patient</button>:null}
                                                <button onClick={()=>handleOpenDialog(item)}
                                                        className="btn btn-sm sms-info-btn mr-1 mb-1">View</button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                }):<TableRow>
                                <TableCell colSpan={headData.length} align="center">No records found</TableCell>
                            </TableRow>:null}
                            {isError ? <TableRow>
                                <TableCell colSpan={headData.length} align="center">The server did not return a valid response</TableCell>
                            </TableRow> : null }
                        </TableBody>
                </CustomTable>
            
            </Container>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmergencyList);
