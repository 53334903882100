import React from "react";
import PageTitle from "../Utils/smsTitle";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CustomTable from "../Utils/Tables/CustomTable";
import FilterResults from "react-filter-search";
import TableBody from "@material-ui/core/TableBody";
import {getComparator, stableSort} from "../Utils/Tables/sortingFunctions";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {Link} from "react-router-dom";
import {faCog} from "@fortawesome/free-solid-svg-icons/faCog";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../actions/snackbarActions";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import DeleteDialog from "../Utils/Dialogs/DeleteDialog";
import {useTheme} from "@material-ui/core/styles";
import {useUsersList} from "./CustomHooks/useUsersList";

const headData = [{id: 'sl', numeric: false, disablePadding: false, label: 'SL'},
    {id: 'employee', numeric: false, disablePadding: false, label: 'Employee Name'},
    // {id: 'lastName', numeric: false, disablePadding: false, label: 'Last Name'},
    {id: 'email', numeric: false, disablePadding: false, label: 'Email'},
    {id: 'status', numeric: false, disablePadding: false, label: 'Status'},
    {id: 'actions', numeric: false, disablePadding: true, label: ''},
];

const UsersList = (props) =>{
    const {searchValue,  order, orderBy, page, rowsPerPage,  users, loading,
        openDelete, handleOpenDelete, handleCloseDelete, handleDelete, handleCloseSnackbar,
        handleSearch, handleRequestSort, handleChangePage,
        handleChangeRowsPerPage} = useUsersList(props)
    const {type,message,openBar} = props.snackbars;
    const perm = JSON.parse(sessionStorage.getItem('permission'));
    const permission = !perm ? {} : perm;
    const users_list = !permission.users ? {} : permission.users;

    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    return(
        <div data-testid="users-list">
            <PageTitle title="Users"/>
            <SubHeader title="Settings" subTitle="Users">
                <FontAwesomeIcon icon={faCog}/>
            </SubHeader>
            <div className="mui-tables">
                <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={handleCloseSnackbar}/>
                <DeleteDialog openDialog={openDelete} message="User" handleClose={handleCloseDelete}
                              handleDelete={handleDelete} text="user"/>
                <CustomTable title="Users" handleChangePage={handleChangePage} handleChangeRowsPerPage={handleChangeRowsPerPage}
                             headData={headData} handler={handleSearch} page={page} rowsPerPage={rowsPerPage} filter
                             handleRequestSort={handleRequestSort} data={users} colSpan={5} order={order} orderBy={orderBy} id="searchValue">
                    {loading ? <TableBody><TableRow>
                        <TableCell colSpan="8" align="center"><img src="/images/smsloader.gif"
                                                                   alt="loader"/></TableCell>
                    </TableRow></TableBody> : users.length > 0 ?
                    <FilterResults value={searchValue} data={users}
                                   renderResults={results => (
                                       <TableBody>
                                           {stableSort(results, getComparator(order, orderBy))
                                               .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                               .map((item) => {
                                                   return (
                                                       <TableRow
                                                           hover
                                                           role="checkbox"
                                                           tabIndex={-1}
                                                           key={item.employee_id}
                                                           className='mui-table-row'
                                                           id="services"
                                                       >
                                                           <TableCell>{item.sl}</TableCell>
                                                           <TableCell>{`${item.first_name} ${item.last_name}`}</TableCell>
                                                           <TableCell>{item.user_name}</TableCell>
                                                           <TableCell>{item.status === 1 ? "Active" : "Inactive"}</TableCell>
                                                           <TableCell>
                                                               {users_list.update ? <Link
                                                                   to={{pathname: `/edituser/${item.user_id}`}}>
                                                                   <button
                                                                       className="btn btn-sm sms-info-btn mr-1">Edit
                                                                   </button>
                                                               </Link>:<div/>}
                                                               {users_list.delete ? <button
                                                                   onClick={() => handleOpenDelete(item.user_id)}
                                                                   className="btn btn-sm sms-btn-dismiss">Delete
                                                               </button>:<div/>}
                                                           </TableCell>
                                                       </TableRow>
                                                   );
                                               })}
                                       </TableBody>
                                   )}/>: <TableBody><TableRow>
                            <TableCell colSpan="8" align="center">No records found</TableCell>
                        </TableRow></TableBody>}
                </CustomTable>
            </div>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars:state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions:{snackbarActions:bindActionCreators(snackbarActions,dispatch)}}
}
export default connect(mapStateToProps, mapDispatchToProps)(UsersList);
