 import * as types from "./actionTypes";


export function snackSuccess(message) {
  return {
    type: types.SNACKBAR_SUCCESS, message
  }
}

export function snackError(message) {
  return {
    type: types.SNACKBAR_ERROR, message
  }
}

export function snackInfo(message) {
  return {
    type: types.SNACKBAR_INFO, message
  }
}

export function snackWarning(message) {
  return {
    type: types.SNACKBAR_WARNING, message
  }
}

export function hideSnackbar() {
  return {
    type: types.HIDE_SNACKBAR
  }
}
