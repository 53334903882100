import {combineReducers} from "redux";
import alerts from "./alertReducer";
import patients from "./PatientsModuleReducer/patientReducer";
import departments from "./DoctorsModuleReducer/departmentReducer";
import doctors from "./DoctorsModuleReducer/doctorsReducer";
// import appointments from "./DoctorsModuleReducer/appointmentReducer";
import billing from "./BillingsModuleReducer/BillingsReducer";
// import diagnoses from "./DoctorsModuleReducer/diagnosisReducer";
// import measurements from "./DoctorsModuleReducer/measurementReducer";
import snackbar from "./snackbarReducer";
import scans from "./RadiologyReducer/scanReducer"
import employees from "./employeeReducer"
import medicines from "./PharmacyReducer/PharmacyReducer"
import prescriptions from "./PrescriptionReducer/prescriptionReducer";
import loggedUser from "./UsersModuleReducer/UserLoginReducer";
import beds from "./BedManagementReducer";
import steppers from './steppersReducer'

const rootReducer = combineReducers({alerts,  patients, departments,doctors,
    prescriptions,  snackbar, billing, scans, employees, medicines, loggedUser,beds, steppers
});
export default rootReducer;
