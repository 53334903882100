import {useEffect, useState} from "react";
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {logoutErrorMessage} from "../../../../Helpers/ErrorMessages";

export const usePrescriptionReport = (actions,prescription_id) =>{
    const [prescription, setPrescription] = useState({});
    const [drugs, setDrugs] = useState([]);
    const [others, setOthers] = useState([]);
    useEffect(() => {
        const formData = new FormData();
        formData.append('prescription_id', prescription_id);
        axios.post(`${config.smsUrl}/cdoctor/get_prescription_details_by_visit_id`, formData).then(res => {
            const data = res.data;
            const resp = !data ? {} : data;
            const info = !resp.patient_info ? {} : resp.patient_info;
            const presc = !resp.prescription ? [] : resp.prescription;
            const other_med = !resp.other_medicine ? [] : resp.other_medicine;
            setDrugs(presc.filter(Boolean));
            setPrescription(info);
            setOthers(other_med.filter(Boolean))
        }).catch(err => {
            logoutErrorMessage(err,null, actions)
        })
    }, []);

    const closeSnackbar = () =>{
        actions.snackbarActions.hideSnackbar()
    };

    return {prescription, drugs, closeSnackbar, others}
}