import React, { useState } from "react";
import PageTitle from "../Utils/smsTitle";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faNotesMedical} from "@fortawesome/free-solid-svg-icons/faNotesMedical";
import CustomTable from "../Utils/Tables/CustomTable";
import FilterResults from "react-filter-search";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {Link} from "react-router-dom";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../actions/snackbarActions";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import {useServiceList} from "./CustomHooks/useServiceList";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {Container, RightAlignedContainer} from "../Utils/styledComponents";
import {CsvDialog} from "../Utils/Dialogs/CsvDialog";
import { history } from "../../Helpers/history";
import ServiceConsumables from "./ServiceConsumables";
import CustomDialog from "../Utils/Dialogs/CustomDialog";
import { ReusableRouteTabs } from "../Utils/Dialogs/ReusableTabs";

const headData = [
    {id: 'sl', numeric: false, disablePadding: false, label: 'Sl'},
    {id: 'serviceName', numeric: false, disablePadding: false, label: 'Service Name'},
    {id: 'department', numeric: false, disablePadding: false, label: 'Department'},
    {id: 'rate', numeric: false, disablePadding: false, label: 'Rate'},
    {id: 'description', numeric: false, disablePadding: false, label: 'Description'},
    {id: 'actions', numeric: false, disablePadding: true, label: 'Action'},
];

export const ServiceList = (props) => {
    const {searchValue,  page, rowsPerPage, services, loading,  handleDelete,
        handleSearch, handleChangePage, handleChangeRowsPerPage, closeSnackbar, uploadProps,
        serviceConsumablesProps, handleAddConsumables, handleOpenNewItems, handleCloseNewItems, openNewItems, currencySymbol
    } = useServiceList(props)

    const {openBar, type, message} = props.snackbars;
    const perm = JSON.parse(sessionStorage.getItem('permission'));
    const permission = !perm ? {} : perm;
    const service_list = !permission.service_list ? {} : permission.service_list;
    const {handleOpenCsvDialog} = uploadProps;
    const components = [
        {label: 'Active Services', path: `/servicelist/1`},
        {label: 'In-active Services', path: `/servicelist/0`},
    ]
    return (
        <div data-testid="service-list" className='journals'>
            <PageTitle title="Service List"/>
            <SubHeader title="Services" subTitle="Service List">
                <FontAwesomeIcon icon={faNotesMedical}/>
            </SubHeader>
            <CsvDialog {...{...uploadProps, file:"/csv/labtest.csv", title:'Upload Services'}}/>

            {/*  Add Consumables dialog box */}
            <CustomDialog open={openNewItems} handleClose={handleCloseNewItems} title="Add Consumables" maxWidth="lg">
                <ServiceConsumables {...serviceConsumablesProps}/>
                <button className="btn sms-btn btn-sm" data-testid="add-consumables-dialog" onClick={handleAddConsumables}>Save consumables</button>  
            </CustomDialog>

            <Container>
                <RightAlignedContainer>
                    <div className='mb-1'>
                    <Link to="/addservice">
                        <button className="btn sms-btn btn-sm mr-3" data-testid="add-service-button"><FontAwesomeIcon icon={faPlus}/> Add Service</button>
                    </Link>
                        <button onClick={handleOpenCsvDialog} id="upload-services" className="btn sms-info-btn btn-sm">Upload Services
                        </button>
                    </div>
                </RightAlignedContainer>


                <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/>
                
                <div className="mui-tables mt-3">
                <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>
                <CustomTable title="Service List" handleChangePage={handleChangePage}
                             handleChangeRowsPerPage={handleChangeRowsPerPage}
                             headData={headData} handler={handleSearch} page={page} rowsPerPage={rowsPerPage}
                              data={services} colSpan={5}
                              id="searchValue" filter >
                    {loading ? <TableBody><TableRow>
                            <TableCell colSpan="6" align="center"><img src="/images/smsloader.gif"
                                                                       alt="loader"/></TableCell>
                        </TableRow></TableBody> : services.length > 0 ?
                        <FilterResults value={searchValue} data={services}
                                       renderResults={results => (
                                           <TableBody id="table-service-list">
                                               {results.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                   .map((item, idx) => {
                                                       const count = (page * rowsPerPage) + idx + 1;
                                                       return (
                                                           <TableRow
                                                               hover
                                                               role="checkbox"
                                                               tabIndex={-1}
                                                               key={item.service_id}
                                                               className='mui-table-row'
                                                               id="services"
                                                           >
                                                               <TableCell>{count}</TableCell>
                                                               <TableCell data-testid={"service-name-"+idx}>{item.service_name}</TableCell>
                                                               <TableCell>{item.department_name}</TableCell>
                                                               <TableCell>{currencySymbol} {item.cost}</TableCell>
                                                               <TableCell>{item.service_type}</TableCell>
                                                               <TableCell data-testid={"service-list-action-"+idx}>

                                                               {(service_list.update && item.status === 1) ? <Link 
                                                                   to={{pathname: `/editservice/${item.service_id}`}}>
                                                                   <button className="btn btn-sm sms-amber-btn" style={{marginRight: '10px', marginBottom:'5px'}}
                                                                   data-testid={"edit-button-"+idx}
                                                                   >
                                                                       Edit</button>
                                                               </Link>:null}

                                                               {item.service_type === 'Lab' ? item.status === 1 ? item.has_consumables ? 
                                                                   <button onClick={()=>history.push(`/service_consumables/${item.service_id}/${1}`)}  data-testid={"consumables-list-"+idx}
                                                                   className="btn btn-sm sms-btn" style={{marginRight: '10px', marginBottom:'5px'}}>Consumables List</button>
                                                                   :
                                                                    <button onClick={()=>handleOpenNewItems(item)} data-testid={"add-consumables-"+idx}
                                                                    className="btn sms-btn" style={{marginRight: '10px', marginBottom:'5px'}}>Add Consumables</button>
                                                                : null : null}

                                                               {service_list.delete ? <button style={{marginRight: '10px', marginBottom:'5px'}}
                                                                   onClick={() => handleDelete(item.service_id, item.status === 1 ? 0 : 1)} data-testid={"deactivate-activate-"+idx}
                                                                   className="btn btn-sm sms-info-btn">{item.status === 1 ? 'Deactivate':'Activate'}
                                                               </button>:null}
                                                    
                                                               </TableCell>
                                                           </TableRow>
                                                       );
                                                   })}
                                           </TableBody>
                                       )}/>:<TableBody><TableRow>
                            <TableCell colSpan="6" align="center">No records found</TableCell>
                        </TableRow></TableBody>}
                </CustomTable>
                </div>
            </Container>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceList);
