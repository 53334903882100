/** @jsxImportSource @emotion/react */
import React, {useEffect, useState} from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import * as snackbarActions from "../../actions/snackbarActions"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import {Container, RightAlignedContainer} from "../Utils/styledComponents"
import axios from "axios";
import { config } from "../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../Helpers/ErrorMessages";
import {CustomTableCell, MuiPaper, StickyHeadTable} from "../Utils/Tables/StickyHeaderTable";
import {LoadingGif} from "../Utils/Loader";
import PageTitle from "../Utils/smsTitle";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CustomTablePagination, EnhancedSearchTableToolbar, useHiddenColumns} from "../Utils/Tables/CustomTable";
import {faTooth} from "@fortawesome/free-solid-svg-icons/faTooth";
import {dateConvert, formatDateTime} from "../Utils/ConvertDate";
import {Link} from "react-router-dom";
import {history} from "../../Helpers/history";
import DeleteDialog from "../Utils/Dialogs/DeleteDialog";
import {DoctorDateFilter, Progress} from "../DoctorsModule/WaitingList";
import {FinishedStatus, PendingStatus} from "../DoctorsModule/doctorStyles";
import {usePrint} from "../Utils/Templates/usePrint";
import CustomDialog from "../Utils/Dialogs/CustomDialog";
import DentalExaminationReport from "./DentalExaminationReport";
import {DentalServiceResults, heading} from "./DentalServiceReport";
import {textWeight} from "../../styles/tableStyles";
import DentalVisitDetails from "./DentalVisitDetails";



const headData = [{id: 'count', numeric: false, disablePadding: false, label: 'Sl.',hidden:false},
    {id: 'date', numeric: false, disablePadding: false, label: 'Date',hidden:false},
    {id: 'patient_number', numeric: false, disablePadding: false, label: 'Patient No.',hidden:false},
    {id: 'patient_name', numeric: false, disablePadding: false, label: 'Patient Name',hidden:false},
    {id: 'phone_no', numeric: false, disablePadding: false, label: 'Phone ',hidden:false},
    {id: 'kin_phone_no', numeric: false, disablePadding: false, label: 'NOK Phone',hidden:false},
    {id: 'doctor_name', numeric: false, disablePadding: false, label: 'Doctor Name',hidden:false},
    {id: 'visit_department', numeric: false, disablePadding: false, label: 'Visit Department',hidden:false},
    {id: 'status', numeric: false, disablePadding: false, label: 'Status',hidden:false},
    {id: 'action', numeric: false, disablePadding: true, label: 'Action',hidden:false},
];

const DentalList = ({actions, snackbars}) => {
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const department_id = !_token.department_id ? "" : _token.department_id;
    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;

    const [data, setData] = React.useState({patients:[], loading:'idle', total_count:0});
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const {patients, loading, total_count} = data
    const [activePage, setActivePage] = React.useState(1);
    const [term, setTerm] = React.useState('');
    const [openEndVisit, setOpenEndVisit] = React.useState(false)
    const [visit_id, setVisitId] = React.useState('')
    const [isEnd, setIsEnd] = React.useState('idle')
    const [openPrint, setOpenPrint] = useState(false);
    const [state, setState] = useState({start_date:dateConvert(), end_date:dateConvert()})
    const [visitInfo, setVisitInfo] = React.useState({})


    const isEnded = isEnd === 'resolved'

    useEffect(() => {
        const formData = new FormData()
        formData.append('search', term);
        formData.append('department_id', department_id);
        formData.append('start_date', state.start_date);
        formData.append('end_date', state.end_date);
        formData.append('per_page', rowsPerPage);
        setData({...data, loading: 'pending'})
        axios.post(`${config.smsUrl}/cdoctor/doctor_waiting_list/${activePage}`,formData ).then(res => {
            const response = res.data;
            const obj = response ? response : {};
            const patient_list = obj.waiting_list ? obj.waiting_list : [];
            const count = obj.total_count ? obj.total_count : 0

            const list = patient_list.map((item, index) => ({
                ...item,
                count: ((activePage - 1) * 10) + index + 1,
                date: item.begin_datetime,
                patient_number: item.patient_number,
                patient_name: `${!item.first_name ? "" : item.first_name} ${!item.last_name ? "" : item.last_name}`,
                status: item.status,
                visit_id: item.visit_id
            }));
            setData({patients: list, total_count:count, loading: 'success'})
        }).catch(err => {
            errorMessages(err, null, actions);
            setData({...data, loading: 'error'});
        })
    }, [activePage, term, isEnded, state.start_date, state.end_date, rowsPerPage])

    const handleChange = (event) =>{
        const {value, name} = event.target
        setState({...state, [name]:value})
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setActivePage(newPage+1);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        setActivePage(1);
    };

    const handleClickPatient = (row) => {
        const formData = new FormData();
        formData.append('visit_id', row.visit_id ? row.visit_id : '');
        formData.append('user_roles_id', user_roles_id);
        axios.post(`${config.smsUrl}/cvisit/Update_visit_takeup_status`, formData).then(() => {
            history.push(`/dental-medical-history/${row.patient_number}/${row.visit_id}`)
        }).catch((err) => {
            logoutErrorMessage(err, null, actions)
        });
    };

    const handleOpenEndVisit = (item) => {
        setOpenEndVisit(true)
        setVisitId(item.visit_id);
    };

    const handleCloseEndVisit = () => {
        setOpenEndVisit(false)
    };

    const handleEndVisit = (action, v_id="") => {
        const id = visit_id ? visit_id : v_id
        const formData = new FormData();
        formData.append('visit_id', id);
        formData.append('user_roles_id', user_roles_id);
        formData.append('department_id', department_id);
        formData.append('action', action);
        if (id) {
            setIsEnd('pending')
            const isEnded = action === 'end'
            axios.post(`${config.smsUrl}/cvisit/complete_visit`, formData).then(() => {
                actions.snackbarActions.snackSuccess(`Visit ${isEnded ? 'ended' : 'reverted'} successfully`);
                setOpenEndVisit(false)
                setIsEnd('resolved')
            }).catch(e => {
                errorMessages(e, null, actions);
                setIsEnd('rejected')
            });
        }
    };

    const handleOpenPrint = (item) => {
        setVisitInfo(item)
        setOpenPrint(true);
    };

    const handleClosePrint = () => {
        setOpenPrint(false);
    };

    const closeSnackbar = () =>{
        actions.snackbarActions.hideSnackbar();
    };


    const isLoading = loading === 'pending'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'


    const {openBar, type, message} = snackbars;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, patients.length - page * rowsPerPage);

    const handler = (event) => {
        setTerm(event.target.value)
    }


    const pagination = (
        <CustomTablePagination
            colSpan={3}
            activePage={activePage}
            page={page}
            rowsPerPage={rowsPerPage}
            count={total_count}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            isTotalCount
        />
    );

    const search_bar = (
        <EnhancedSearchTableToolbar
            term={term} handler={handler}
        />
    )

    const {componentRef, handlePrint,  fontSize}  = usePrint('Medical History Report');

    const isEnding = isEnd === 'ending'


    const dentalPatients = patients.map((item, index)=>{
        const count = ((activePage - 1) * 10) + index + 1;
        const status = {
            2:(<PendingStatus>Pending</PendingStatus>),
            3:(<Progress>Treatment ongoing</Progress>),
            4:(<FinishedStatus>Complete</FinishedStatus>)
        }
        return {
            ...item,
            count,
            date:item.date ? formatDateTime(item.date) : '',
            patient_name:`${!item.first_name ? "" : item.first_name} ${!item.last_name ? "" : item.last_name}`,
            doctor_name:`${item.doctor_first_name} ${item.doctor_last_name}`,
            status: status[item.status],
            action:(
                item.status === 4 ?
                    <>
                        <button className='btn btn-sm mr-3 sms-gray-btn mb-sm-3 mb-2 small-btn'
                                onClick={() => handleEndVisit('revert', item.visit_id)}>Revert Visit
                        </button>
                        <button className='btn btn-sm small-btn sms-info-btn mb-2 ' onClick={() => handleOpenPrint(item)}>View
                            Details
                        </button>
                    </>
                    :
                    <>
                        <button type='button' onClick={() => handleClickPatient(item)}
                                className={`btn btn-sm mb-2 small-btn mr-2 ${item.status === 2 ?
                                        'sms-info-btn' : 'sms-amber-btn'}`}>{item.status === 2 ? "Take up" : "Continue"}</button>
                            {item.status !== 2 ?
                                <button onClick={()=>handleOpenEndVisit(item)}
                                        className="btn btn-sm small-btn sms-info-btn mr-3 mb-2 ">End Visit</button> :  null}
                        </>

            )
        }
    })

    return (
        <div>
            <PageTitle title="Dental list"/>
            <SubHeader title="Dental" subTitle="Dental list">
                <FontAwesomeIcon icon={faTooth}/>
            </SubHeader>
            <DentalVisitDetails {...{actions, visitInfo, openPrint, setOpenPrint}}/>
            <DeleteDialog message="end" openDialog={openEndVisit} handleClose={handleCloseEndVisit}
                          text="visit" title='End Visit'>
                <button className=' btn sms-info-btn btn-sm' id="end_visit" disabled={isEnding} onClick={()=>handleEndVisit('end')}>End Visit</button>
                <button className="btn sms-grey-btn btn-sm" onClick={handleCloseEndVisit}>No</button>
            </DeleteDialog>
            <Container>
                <MainSnackbar open={openBar} message={message} variant={type} handleCloseBar={closeSnackbar}/>
                <DoctorDateFilter {...{state, handleChange}}/>
                <MuiPaper>
                    {search_bar}
                    <StickyHeadTable {...{width:'100%',data:dentalPatients, columns:headData.filter(row=>Boolean(row)), pagination, search_bar}}>
                        {isLoading ? <TableRow><TableCell align='center' {...{colSpan:headData.length}}><LoadingGif/></TableCell></TableRow>:null}
                        {isSuccess ? dentalPatients.length > 0 ?
                            <>
                                {dentalPatients.slice(0, rowsPerPage).map((row, index) => {
                                    return (
                                        <TableRow  tabIndex={-1} key={index}>
                                            {headData.map((head)=>(
                                                <CustomTableCell id={head.id}  key={head.id}
                                                           hidden={head.hidden}>{row[head.id]}</CustomTableCell>
                                            ))}
                                        </TableRow>
                                    );
                                })}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 43 * emptyRows }}>
                                        <TableCell colSpan={headData.length} />
                                    </TableRow>
                                )}
                            </>:<TableRow><TableCell align='center'  {...{colSpan:headData.length}}>No records found</TableCell></TableRow>:null}
                        {isError ? <TableRow><TableCell align='center'  {...{colSpan:headData.length}}>The server did not return a valid response</TableCell></TableRow>:null}
                    </StickyHeadTable>
                </MuiPaper>

            </Container>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DentalList);
