import Form from "react-bootstrap/Form";
import Label, { ToolTip } from "../../../../Utils/FormInputs/Label";
import Row from 'react-bootstrap/Row';
import {useTheme} from "@material-ui/core/styles";
import Col from 'react-bootstrap/Col';
import TextField from '../../../../Utils/FormInputs/TextField';
import { FormLabel } from "../../../../Utils/FormInputs/Label";
import { CustomSelect } from "../../../../Utils/FormInputs/SelectInput";
import PrintDialog from "../../../../Utils/Dialogs/PrintDialog";
import { dateConvert } from '../../../../Utils/ConvertDate';
import { PreviousObsHistoryTable } from "./AnteNatalTables";
import { useState } from "react";

/**
 * Component renders the first screen in the ante natal patient card
 */
const AnteNatalFirstScreen = ({first_screen_props, handleStep}) => {

    const {
        clientCategories, client_category, handleClientCategoryChange,
        anc_visit, anc_visit_options, handleANCVisitChange,
        anc1_timing, anc1timings, handleANC1Timing,
        expected_date_delivery, handleExpectedDate,
        gestational_age, handleGestationalAgeChange, 
        lnmp, handleLMNPChange,
        inr_no, handleINRNoChange,
        muac, handleMUACChange,
        gravida, handleGravidaChange, length_of_menses,
        parity, handleParityChange, handleMenstrualLength, mens_amounts, amount_menstrual, handleAmountMenstrual,
        prev_obstetric_history, handleObsChange,
        handleMenstrualHistory, menstrual_history, 
        medical_illness, handleOtherMedicals, other_medicals, handleMedicalIllness, sgbv_specifics_options,
        family_social_history, handleFamilySocialHistory, handleCloseSkip, openSkip,
        surgery_meds_texts, surgery_meds_checks, handleSurgeryMedsChecks, handleSurgeryMedsTexts,
        sgbv_risk, handleSGBVRisk, sgbv_sepcific, handleSGBVSpecific, handleOBSGYN, obs_gyn
    } = first_screen_props;

    const {sgbv_yes, sgbv_no, sgbv_others, sgbv_husband_health} = sgbv_risk;
    const {cardiac_disease, kidney_disease, hypertension, tb, asthma, sti,
        sickle_cell_disease, epilepsy, diabetes, other_medical_illness} = medical_illness;
    const {dc, ectopic_preg, caesarean_section, vacuur_extraction, retained_placenta, uterus_operation, cervical_circlage, pph} = obs_gyn;
    const {family_plan, why_disconnected, why_never_used} = menstrual_history;
    const {diabetes_family, hypertension_family, sickle_cell_disease_family, epilepsy_family, twins, others_family, smoking_alcohol} = family_social_history;
    const {blood_transfusion_why_text, other_meds_text} = surgery_meds_texts;
    const {surgery_operations, blood_transfusion_check, arvs, other_meds_check, fractures} = surgery_meds_checks;
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;

    return (

        <div data-testid="ante-natal-patient-form-first-screen">
            
            <PrintDialog handleCancel={handleCloseSkip} openDialog={openSkip}
                            handleClose={handleCloseSkip}
                            handleClick={handleStep(2)} message="Skip to Third Screen"
                            text="Would you like to skip to the Ante Natal Progress Examination Table?"/>

            <h5 className="antenatal-form">{`Profile & Support Information`}</h5>
            <hr class="rounded"/>

            <Row>
                <Col md={5}>
                    <Form.Group as={Row} className="surgery-schedule-flex">
                        <Col xs={6} sm={6} lg={5} md={6} className="align-cols">
                            <Label name="ANC Visit"/>
                            <CustomSelect options={anc_visit_options}  value={anc_visit} onChange={handleANCVisitChange}/>
                        </Col>
                        <Col xs={6} sm={6} lg={5} md={6} className="align-cols">
                            <Label name="Current ANC Visit Date"/>
                            <TextField autocomplete="off" type="date" disabled={true} value={dateConvert()}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="surgery-schedule-flex">
                        <Col xs={6} sm={6} lg={5} md={6} className="align-cols">
                            <Label name="ANC 1 Timing"/>
                            <CustomSelect options={anc1timings} value={anc1_timing} onChange={handleANC1Timing}/>
                        </Col>

                        <Col xs={6} sm={6} lg={5} md={6} className="align-cols">
                            <Label name="Client Category"/>
                            <CustomSelect options={clientCategories} onChange={handleClientCategoryChange} value={client_category}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="surgery-schedule-flex">
                        <Col xs={6} sm={6} lg={5} md={6} className="align-cols">
                            <Label name="Last Normal Menstruation Period"/>
                            <TextField autocomplete="off" type="date" value={lnmp} onChange={handleLMNPChange}/>
                        </Col>
                        
                        <Col xs={6} sm={6} lg={5} md={6} className="align-cols">
                            <Label name="Expected Date of Delivery"/>
                            <TextField autocomplete="off" type="date" value={expected_date_delivery} onChange={handleExpectedDate}/>
                        </Col>
                    </Form.Group>
                </Col>

                <Col md={2}>
                    <div style={{borderLeft: '3px solid #027a5f',  borderRadius:'1px', height: '100%', position: 'relative', left: '50%', marginLeft: '-3px', top: '0' }}></div>
                </Col>

                <Col md={5}>
                    <Form.Group as={Row} className="surgery-schedule-flex">
                        <Col xs={6} sm={6} lg={5} md={6} className="align-cols">
                            <Label name="Gestational Age (In days)"/>
                            <TextField autocomplete="off" value={gestational_age} disabled={true}/>
                        </Col>

                        <Col xs={6} sm={6} lg={5} md={6} className="align-cols">
                            <FormLabel name="INR No." toolTip={"Integrated Nutrition Register Number"}/>
                            <TextField autocomplete="off" value={inr_no} onChange={handleINRNoChange}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="surgery-schedule-flex">                
                        <Col xs={6} sm={6} lg={5} md={6} className="align-cols">
                            <FormLabel name="MUAC (Cm + Color Code)" toolTip={"Mid Upper Arm Circumference"}/>
                            <TextField autocomplete="off" type="number" value={muac} onChange={handleMUACChange} min='0'/>
                        </Col>

                        <Col xs={6} sm={6} lg={5} md={6} className="align-cols">
                            <FormLabel  name={"Parity"} toolTip={"Number of pregnancies carried beyond 7 months (exclude the current pregnancy)."}/>
                            <TextField autocomplete="off" type="number" value={parity} onChange={handleParityChange} min='0'/>
                        </Col>

                    </Form.Group>

                    <Form.Group as={Row} className="surgery-schedule-flex">

                        <Col xs={6} sm={6} lg={5} md={6} className="align-cols">
                            <FormLabel  name={"Gravida"} toolTip={"Number of this pregnancy in sequence"}/>
                            <TextField autocomplete="off" type="number" value={gravida} onChange={handleGravidaChange} min='0'/>
                        </Col>
                    </Form.Group>
                </Col>
            </Row>

            {/**Adding the section for the  previous obstetrics history*/}
            {gravida > 1 ? 
                <PreviousObsHistoryTable prev_obstetric_history={prev_obstetric_history} handleObsChange={handleObsChange}/>
            :
            null}

            <h5 className="antenatal-form">{`PREVIOUS ILLNESS:`}</h5>
            <hr class="rounded"/>
            <Row>
                <Col md={5}>
                
                    <h6>Medical</h6>
                    <div>
                    <Row className="surgery-schedule-flex">
                        <Col>
                            <Form.Check type="checkbox" label="Cardiac Disease" checked={cardiac_disease} name="cardiac_disease" onChange={handleMedicalIllness}/>
                        </Col>
                        <Col>
                            <Form.Check type="checkbox" label="Sickle Cell Disease" checked={sickle_cell_disease} name="sickle_cell_disease" onChange={handleMedicalIllness}/>
                        </Col>
                    </Row>
                    <Row className="surgery-schedule-flex">
                        <Col>
                            <Form.Check type="checkbox" label="Kidney Disease" checked={kidney_disease} name="kidney_disease" onChange={handleMedicalIllness} />
                        </Col>
                        <Col>
                            <Form.Check type="checkbox" label="Epilepsy (seizures)"  checked={epilepsy} name="epilepsy" onChange={handleMedicalIllness}/>
                        </Col>
                    </Row>
                    <Row className="surgery-schedule-flex">
                        <Col>
                            <Form.Check type="checkbox" label="Hypertension"  checked={hypertension} name="hypertension" onChange={handleMedicalIllness}/>
                        </Col>
                        <Col>
                            <Form.Check type="checkbox" label="Diabetes"  checked={diabetes} name="diabetes" onChange={handleMedicalIllness}/>
                        </Col>
                    </Row>
                    <Row className="surgery-schedule-flex">
                        <Col>
                            <Form.Check type="checkbox" label="TB"  checked={tb} name="tb" onChange={handleMedicalIllness}/>
                        </Col>
                        <Col>
                            <Form.Check type="checkbox" label="Others (Specify)"  checked={other_medical_illness} name="other_medical_illness" onChange={handleMedicalIllness}/>
                        </Col>
                    </Row>
                    <Row className="surgery-schedule-flex">
                        <Col>
                            <Form.Check type="checkbox" label="Asthma"  checked={asthma} name="asthma" onChange={handleMedicalIllness}/>
                            <Form.Check type="checkbox" label="STI"  checked={sti} name="sti" onChange={handleMedicalIllness}/>
                        </Col>
                        <Col>
                            <TextField autocomplete="off" value={other_medicals} onChange={handleOtherMedicals}/>
                        </Col>
                    </Row>

                    </div>

                    <p/>
                    <h6>Surgery Medications</h6>
                    <Row className="surgery-schedule-flex">
                        <Col>
                            <Form.Check type="checkbox" label="Operations" checked={surgery_operations} name="surgery_operations" onChange={handleSurgeryMedsChecks}/>
                        </Col>
                        <Col>
                            <Form.Check type="checkbox" label="ARVs" checked={arvs} name="arvs" onChange={handleSurgeryMedsChecks}/>
                        </Col>
                    </Row>
                    <Row className="surgery-schedule-flex">
                        <Col>
                            <Form.Check type="checkbox" label="Blood Transfusion: why?" checked={blood_transfusion_check} name="blood_transfusion_check" onChange={handleSurgeryMedsChecks}/>
                        </Col>
                        <Col>
                            <Form.Check type="checkbox" label="Others specify:" checked={other_meds_check} name="other_meds_check" onChange={handleSurgeryMedsChecks}/>
                        </Col>
                    </Row>
                    <Row className="surgery-schedule-flex">
                        <Col>
                            <TextField autocomplete="off" name="blood_transfusion_why_text" value={blood_transfusion_why_text} onChange={handleSurgeryMedsTexts}/>
                        </Col>
                        <Col>
                            <TextField autocomplete="off" name="other_meds_text" value={other_meds_text} onChange={handleSurgeryMedsTexts}/>
                        </Col>
                    </Row>
                    <Row className="surgery-schedule-flex">
                        <Col>
                            <Form.Check type="checkbox" label="Fracture of pelvis, spine and femur" checked={fractures} name="fractures" onChange={handleSurgeryMedsChecks}/>
                        </Col>
                    </Row>


                </Col>

                <Col md={2}>
                    <div style={{borderLeft: '3px solid #027a5f',  borderRadius:'1px', height: '100%', position: 'relative', left: '50%', marginLeft: '-3px', top: '0' }}></div>
                </Col>

                <Col md={5}>

                    <h6>Risk of SGBV <ToolTip toolTip={"Sexual and Gender-Based Violence"}/></h6>
                    <div>
                    <Row className="surgery-schedule-flex">
                        <Col>
                            <Form.Check type="checkbox" label="Yes" checked={sgbv_yes} name="sgbv_yes" onChange={handleSGBVRisk}/>
                        </Col>
                        <Col>
                            <Form.Check type="checkbox" label="No" checked={sgbv_no} name="sgbv_no" onChange={handleSGBVRisk}/>
                        </Col>
                    </Row>
                    <Row className="surgery-schedule-flex">
                        {sgbv_yes === true ? 
                            <>
                                <Col sm={5}>
                                <Label name="If Yes, specify Physical/Psycho-social"/>
                                </Col>
                                <Col sm={4}>
                                <CustomSelect options={sgbv_specifics_options} value={sgbv_sepcific} onChange={handleSGBVSpecific}/>
                                {/*!sgbv_yes ? <p>Please tick yes for Risk of SGBV</p> : null*/}
                                </Col>  
                            </> 
                        : null}
                    </Row> 
                    <Row className="surgery-schedule-flex">
                        <Col>
                        <Label name="Other SGBV (Specify)"/>
                        <TextField autocomplete="off" name={"sgbv_others"} value={sgbv_others} onChange={handleSGBVRisk}/>
                        </Col>
                    </Row>                    
                    <Row className="surgery-schedule-flex">
                        <Col>
                        <Label name="Health of the Husband/partner:"/>
                        <TextField autocomplete="off" name={"sgbv_husband_health"} value={sgbv_husband_health} onChange={handleSGBVRisk}/>
                        </Col>
                    </Row> 
                    </div>

                    <h6>OBS/GYN</h6>
                    <div>
                    <Row className="surgery-schedule-flex">
                        <Col>
                            <Form.Check type="checkbox" label="D & C" checked={dc} name="dc" onChange={handleOBSGYN} />
                        </Col>
                    </Row>
                    <Row className="surgery-schedule-flex">
                        <Col>
                            <Form.Check type="checkbox" label="Ectopic Pregnancy"  checked={ectopic_preg} name="ectopic_preg" onChange={handleOBSGYN}/>
                        </Col>
                    </Row>
                    <Row className="surgery-schedule-flex">
                        <Col>
                            <Form.Check type="checkbox" label="Caesarean Section"  checked={caesarean_section} name="caesarean_section" onChange={handleOBSGYN}/>
                        </Col>
                    </Row>
                    <Row className="surgery-schedule-flex">
                        <Col>
                            <Form.Check type="checkbox" label="Vacuur Extraction, Forceps"  checked={vacuur_extraction} name="vacuur_extraction" onChange={handleOBSGYN}/>
                        </Col>
                    </Row>
                    <Row className="surgery-schedule-flex">
                        <Col>
                            <Form.Check type="checkbox" label="Retained Placenta"  checked={retained_placenta} name="retained_placenta" onChange={handleOBSGYN}/>
                        </Col>
                    </Row>
                    <Row className="surgery-schedule-flex">
                        <Col>
                            <Form.Check type="checkbox" label="PPH"  checked={uterus_operation} name="uterus_operation" onChange={handleOBSGYN}/>
                        </Col>
                    </Row>
                    <Row className="surgery-schedule-flex">
                        <Col>
                            <Form.Check type="checkbox" label="Operations on the uterus"  checked={pph} name="pph" onChange={handleOBSGYN}/>
                        </Col>
                    </Row>
                    <Row className="surgery-schedule-flex">
                        <Col>
                            <Form.Check type="checkbox" label="Cervical circlage (Shirodkar, Mc Donald)"  checked={cervical_circlage} name="cervical_circlage" onChange={handleOBSGYN}/>
                        </Col>
                    </Row>
                    </div>

                </Col>
            </Row>


            <h5 className="antenatal-form">{`PATIENT HISTORIES:`}</h5>
            <hr class="rounded"/>
            <Row>
                <Col md={5}>

                    <Row className="surgery-schedule-flex">
                        <Col>
                        <h6>
                            Family History
                        </h6>                        
                        </Col>
                        
                        <Col>
                        <h6>
                            Social History
                        </h6>                        
                        </Col>
                    </Row>
                    <Row className="surgery-schedule-flex">
                        <Col>
                            <Form.Check type="checkbox" label="Diabetes"  checked={diabetes_family} name="diabetes_family" onChange={handleFamilySocialHistory}/>
                        </Col>
                        <Col>
                            <Form.Check type="checkbox" label="Smoking / Alcohol"   checked={smoking_alcohol} name="smoking_alcohol" onChange={handleFamilySocialHistory}/>
                        </Col>
                    </Row>
                    <Row className="surgery-schedule-flex">
                        <Col>
                            <Form.Check type="checkbox" label="Hypertension"   checked={hypertension_family} name="hypertension_family" onChange={handleFamilySocialHistory}/>
                        </Col>
                    </Row>
                    <Row className="surgery-schedule-flex">
                        <Col>
                            <Form.Check type="checkbox" label="Sickle Cell Disease"   checked={sickle_cell_disease_family} name="sickle_cell_disease_family" onChange={handleFamilySocialHistory}/>
                        </Col>
                    </Row>
                    <Row className="surgery-schedule-flex">
                        <Col>
                            <Form.Check type="checkbox" label="Epilepsy"   checked={epilepsy_family} name="epilepsy_family" onChange={handleFamilySocialHistory}/>
                        </Col>
                    </Row>
                    <Row className="surgery-schedule-flex">
                        <Col>
                            <Form.Check type="checkbox" label="Twins"   checked={twins} name="twins" onChange={handleFamilySocialHistory}/>
                        </Col>
                    </Row>
                    <Row className="surgery-schedule-flex">
                        <Col>
                            <Form.Check type="checkbox" label="Others"   checked={others_family} name="others_family" onChange={handleFamilySocialHistory}/>
                        </Col>
                    </Row>

                </Col>

                <Col md={2}>
                    <div style={{borderLeft: '3px solid #027a5f',  borderRadius:'1px', height: '100%', position: 'relative', left: '50%', marginLeft: '-3px', top: '0' }}></div>
                </Col>

                <Col md={5}>

                    <Row className="surgery-schedule-flex">
                        <Col>
                            <h6>Menstrual And Contraceptive History</h6>
                        </Col>
                        <Col>
                            <Label name="Family Planning methods ever used:"/>
                            <TextField autocomplete="off" name="family_plan"  value={family_plan} onChange={handleMenstrualHistory}/>
                        </Col>
                    </Row>

                    <Row className="surgery-schedule-flex">
                        <Col>
                            <Label name="Length of menses (no. of days of bleeding)"/>
                            <TextField autocomplete="off" type="number" min="0" value={length_of_menses} onChange={handleMenstrualLength}/>
                        </Col>
                        <Col>
                            <Label name="When and why was it disconnected?"/>
                            <TextField autocomplete="off" name="why_disconnected" value={why_disconnected} onChange={handleMenstrualHistory} />
                        </Col>
                    </Row> 

                    <Row className="surgery-schedule-flex">
                        <Col>
                            <Label name="Amount:"/>
                            <CustomSelect name="amount_menstrual" options={mens_amounts}  value={amount_menstrual} onChange={handleAmountMenstrual}/>
                        </Col>
                        <Col>
                            <Label name="Never used, why?"/>
                            <TextField name="why_never_used" value={why_never_used} onChange={handleMenstrualHistory}/>
                        </Col>
                    </Row> 

                </Col>
            </Row>

        </div>
    )
}


export default AnteNatalFirstScreen;