import React from "react";
import {useTheme} from "@material-ui/core/styles";
import Form from "react-bootstrap/Form";
import Col from 'react-bootstrap/Col';
import { FormLabel } from "../Utils/FormInputs/Label";
import { RightAlignedContainer} from "../Utils/styledComponents";
import Label from "../Utils/FormInputs/Label";
import Row from 'react-bootstrap/Row';

export const PostSurgeryDetails = ({onChange, surgery_time, general_notes, handleNotesState, handleCompleteSurgery, submitted, handleAdmitPatient, checkSubmissionAdmit, patient_type}) => {

    const { summary_report } = general_notes;
    
    const { 
        actual_start_time, actual_end_time
    } = surgery_time;

    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;

    return (
        <div>
            <div className="mt-2">
                <Form.Group as={Row} className="surgery-schedule-flex">
                    <Col xs={4} sm={2} xl={2}>
                        <Label name="Actual Start Time" className="surgery-details" type/>
                        <Form.Control type="time" rows="4"  name="actual_start_time"
                                defaultValue={actual_start_time}
                                onChange={onChange} autoComplete="off"
                                className={`form__control ${palette.type === "dark" && 'form-control-dark'}`}/>
                        {(submitted && !actual_start_time) &&
                        <div className="help-block">Actual Start time is required</div>}  
                    </Col>

                    <Col xs={8} sm={2} xl={2}>
                        <Label name="Actual End Time" className="surgery-details" type/>
                        <Form.Control type="time" rows="4"  name="actual_end_time"
                                defaultValue={actual_end_time}
                                onChange={onChange} autoComplete="off"
                                className={`form__control ${palette.type === "dark" && 'form-control-dark'}`}/>
                        {(submitted && !actual_end_time) &&
                        <div className="help-block">Actual End time is required</div>}                               
                    </Col>

                    <Col xs={8} sm={2} xl={2}>
                        {((checkSubmissionAdmit === false) &&  (patient_type !== '2')) ? <button onClick={handleAdmitPatient} className="btn btn-sm sms-btn mr-1 center-button" style={{marginTop:'34px', marginBottom:'6px'}}>Admit Patient</button>:null}
                    </Col>

                </Form.Group>
                <Col sm={12}>

                    {/*<Form.Group>
                        <FormLabel className="pl-1" name='Postoperative Instruction(s)' isRequired  toolTip='Capture Postoperative Instruction(s) notes here'/>
                        <Form.Control as="textarea" rows="6" cols="4"  name="post_operative_instructions"
                                    value={post_operative_instructions}
                                    id="postoperativeInstructions"
                                    onChange={handleNotesState}
                                    className={`form__control ${palette.type === "dark" && 'form-control-dark'}`}/>
                    </Form.Group>*/}

                    <Form.Group>
                        <FormLabel className="pl-1" name='Summary Report' toolTip='Capture Surgery Report here'/>
                        <Form.Control as="textarea" rows="6"  name="summary_report"
                                    value={summary_report} autoComplete="off"
                                    id="summary_report"
                                    onChange={handleNotesState}
                                    className={`form__control ${palette.type === "dark" && 'form-control-dark'}`}/>
                    </Form.Group>
                </Col>
            </div>
            <RightAlignedContainer>
                <button type="submit" className="btn btn-sm sms-hl-btn" onClick={handleCompleteSurgery} style={{marginRight:'20px'}}>Complete Surgery</button>
            </RightAlignedContainer>
        </div>
    )
};
