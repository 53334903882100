import React, { useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Select from "react-select";
import { ErrorMessage } from "../styledComponents";
import { InputBase, MenuItem, TextField } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';

const styledBy = (property, mapping) => (props) => mapping[props[property]];

const styles = {
  root: {
    backgroundColor: 'white',
    '& .MuiInputBase-root': {
      fontSize: '14px',
      borderRadius: `10px`,
      padding: '10px',
      height: '34px',
      border: styledBy('color', {
        default: 'none',
        error: '1px solid red',
      }),
    },
    '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
      padding: '0px'
    }
  },
};


const SelectInput = ({ name, value, onChange, options, testId, defaultOption, id, submitted = false, errorMsg = "", ...props }) => {
  const theme = useTheme();
  const them = !theme ? {} : theme;
  const palette = !them.palette ? {} : them.palette;
  const isSubmitted = submitted && !value;
  return (
    <div>
      <Form.Control style={{ border: isSubmitted ? '1px solid red' : '' }} as="select" name={name}
        value={value} onChange={onChange} id={id}
        data-testid={testId}
        className={` form__control ${palette.type === "dark" && 'form-control-dark'}`} {...props}>
        {defaultOption && <option value="">{defaultOption}</option>}
        {options?.map(option => (
          <option key={option.value} value={option.value}>{option.text}</option>
        ))}
      </Form.Control>
      {isSubmitted ? <ErrorMessage>{errorMsg ? errorMsg : "This is a required field"}</ErrorMessage> : null}
    </div>
  )
};
export default SelectInput;

export const ReusabelSelect = ({ name, value, onChange, options, defaultOption, submitted = false, ...props }) => {
  const theme = useTheme();
  const them = !theme ? {} : theme;
  const palette = !them.palette ? {} : them.palette;
  const isSubmitted = submitted && !value;
  return (
    <div>
      <Form.Control style={{ border: isSubmitted ? '1px solid red' : '' }} as="select" name={name} value={value} onChange={onChange} className={` form__control ${palette.type === "dark" && 'form-control-dark'}`} {...props}>
        {defaultOption && <option value="">{defaultOption}</option>}
        {options.map(option => (
          <option key={option.value} value={option.value}>{option.label}</option>
        ))}
      </Form.Control>
      {isSubmitted ? <ErrorMessage>This is a required field</ErrorMessage> : null}
    </div>
  )
}


export const CustomSelect = ({ onChange, value, name, data_testid, id = "react-select", width = '', submitted = false, options = [], defaultValue = { label: "Select...", value: "" }, ...rest }) => {
  const theme = useTheme();
  const them = !theme ? {} : theme;
  const palette = !them.palette ? {} : them.palette;
  const isSubmitted = submitted && !value?.value;
  const ref = useRef()
  const customStyles = {
    control: (provided, { isDisabled }) => ({
      ...provided,
      backgroundColor: palette.type === 'dark' ? ' #424242' : isDisabled ? '#e9ecef' : 'white',
      borderRadius: '.7rem',
      color: palette.type === 'dark' && '#fff',
      // border: palette.type === 'dark' && '1px solid  rgba(255, 255, 255, 0.12)',
      fontSize: '14px',
      width: `${width}px`,
      border: isSubmitted ? '1px solid red' : ''
    }),
    option: (provided) => {
      return {
        ...provided,
        backgroundColor: '#ffff',
        color: '#181818',
        cursor: 'pointer',
        overflowY: 'auto',
        ':hover': {
          ...provided[':provided'],
          backgroundColor: 'rgba(20,1,125,0.1)',
        },
        ':active': {
          ...provided[':active'],
          backgroundColor: '#ffff',
          color: '#0b0b0b'
        },
        fontSize: '14px'

      };
    },
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    singleValue: (styles) => ({
      ...styles,
      color: palette.type === 'dark' && '#fff',
    })
  };
  return (
    <Select onChange={onChange} styles={customStyles} selectProps={{ ariaLabel: "admission-relation" }}
      value={value} classNamePrefix={id} menuPortalTarget={document.body} isClearable
      options={options} data-test={data_testid} name={name} defaultValue={defaultValue} {...rest} />
  )
};

// Styles for the Select component
const input_styles = {
  input: {
    backgroundColor: 'white',
    border: styledBy('color', {
      default: '1px solid #ced4da',
      error: '1px solid red',
      placeholder: '1px solid #ced4da',
    }),
    color: styledBy('color', {
      default: 'black',
      placeholder: 'gray',
    }),
    fontSize: '14px',
    borderRadius: `10px`,
    padding: '10px',
    height: '34px',
    width: '100%',
    '&:focus': {
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
};

const CustomSelectInput = withStyles(input_styles)(({ classes, color, ...other }) => (
  <InputBase className={classes.input} {...other} />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    fontSize: '12px',
    '&:focus': {
      backgroundColor: '#d3dbf2',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

// styles for the menu dropdown
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  paper: {
    boxShadow: 'none',
    color: '#586069',
    fontSize: 13,
    background: 'white',
  },
  option: {
    minHeight: 'auto',
    alignItems: 'flex-start',
    overflowWrap: 'anywhere',
    wordWrap: 'break-word',
    hyphens: 'auto',
    padding: 8,
    '&[data-focus="true"]': {
      backgroundColor: '#d3dbf2'
    },
  },
  popper: {
    border: '1px solid rgba(27,31,35,.15)',
    boxShadow: '0 3px 12px rgba(27,31,35,.15)',
    borderRadius: 10,
    zIndex: 1,
    fontSize: 13,
    background: 'white',
    top: '0px',
    marginTop: 5,
    marginBottom: 5
  },
  selectMenu: {
    marginTop: '4px',
  },
}));

const inValidateValue = (med_value, autocomplete_value, value_text) => {

  const isValueMed = (!med_value) ? true : typeof med_value === 'string' ? false :
    value_text ? !med_value[`${value_text}`] : !med_value.value;

  const isAutoCompleteValue = (!autocomplete_value) ? true : typeof med_value === 'string' ? false :
    value_text ? !autocomplete_value[`${value_text}`] : !autocomplete_value.value;

  // if both are empty
  return isValueMed && isAutoCompleteValue;
}



const AutoCompleteTextField = withStyles(styles)(({ classes, color, ...other }) => (
  <TextField className={classes.root} {...other} />
));

export const AutoCompleteUnControlled = ({ options, idx, handleChange, handleProductData, submitted, placeholder = "", name = "",
  medicine_value = {}, label_text = "", value_text = "", id = "", errorMsg = "" }) => {

  const [value, setValue] = useState(medicine_value);
  const [inputValue, setInputValue] = useState('');
  const classes = useStyles();

  return (

    <div >

      <Autocomplete

        id={id}
        value={value || null}
        inputValue={inputValue}

        onChange={(event, newValue) => {
          if (newValue === null) {
            setValue('');
          } else {
            setValue(newValue);
          }
          if (!handleProductData) {
            handleChange(newValue);
          } else {
            if (idx !== null) {
              handleProductData({
                innerText: !newValue ? '' : newValue.label ? newValue.label : label_text ? newValue[`${label_text}`] : "",
                value: !newValue ? '' : newValue.value ? newValue.value : value_text ? newValue[`${value_text}`] : "",
              }, idx);
            } else {
              handleProductData({
                innerText: !newValue ? '' : newValue.label ? newValue.label : label_text ? newValue[`${label_text}`] : "",
                value: !newValue ? '' : newValue.value ? newValue.value : value_text ? newValue[`${value_text}`] : "",
              });
            }
          }
        }}

        getOptionSelected={(option, value) => {
          return value_text === '' ? (option.value === value.value) : (option[`${value_text}`] === value[`${value_text}`])
        }}

        getOptionLabel={(option) => {
          if (typeof (option) === 'string') {
            // not a dictionary
            return option;
          }
          return option ? option.label ?
            option.label : label_text ?
              option[`${label_text}`] !== null ?
                option[`${label_text}`] : "" : "" : "";
        }}

        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
          if (idx !== null) {
            handleChange(newInputValue, idx, (newInputValue === ''));
          } else {
            handleChange(newInputValue);
          }
        }}

        options={options}
        classes={{
          option: classes.option,
          paper: classes.paper,
          popper: classes.popper
        }}

        renderInput={(params) =>
          <AutoCompleteTextField  {...params} variant="outlined" name={name}
            color={(medicine_value === null && !submitted) ? 'default' :
              (submitted && (inValidateValue(medicine_value, value, value_text))) ? 'error' : "default"}
            placeholder={placeholder} />}
      />
      {(medicine_value === null && !submitted) ? null :
        (submitted && (inValidateValue(medicine_value, value, value_text))) ?
          <div className="invalid-text">{errorMsg}</div>
          : null}
    </div>
  );
}

