import * as types from "../actionTypes";
import axios from "axios";
import { config } from "../../Helpers/env";
import {history} from "../../Helpers/history";
import "react-toastify/dist/ReactToastify.css";
import {snackSuccess} from "../snackbarActions";
import {errorMessages, logoutErrorMessage} from "../../Helpers/ErrorMessages";

export function loadPatientSuccess(patient) {
    return {
        type: types.LOAD_PATIENTS_SUCCESS,
        patient
    };
}

export const deletePatient = (formData) => (dispatch) => {
    return axios.post(`${config.smsUrl}/cpatient/delete_patient`, formData).then(() => {
        const patient_number = formData.get("patient_number");
        dispatch({type: types.DELETE_PATIENT, patient_number});
        dispatch(snackSuccess("Patient deleted successfully"))
    }).catch(err => {
        errorMessages(err, dispatch, null)
    })
};

export const patientPreviousVisits = (formData) => (dispatch) => {
    return axios.post(`${config.smsUrl}/cpatient/get_patient_previous_visits`, formData).then((res) => {
        const data = res.data;
        const visit = !data ? {} : data;
        const visits = !visit.patient_visits ? [] : visit.patient_visits;
        dispatch({type: types.GET_PATIENT_PREVIOUS_VISITS, previousVisits: visits});
    }).catch(err => {
        logoutErrorMessage(err, dispatch, null);
    })
};

export const getVisitDetails = (formData) => (dispatch) => {
    return axios.post(`${config.smsUrl}/cpatient/all_patient_visit_info`, formData).then((res) => {
        const data = res.data;
        const visit = !data ? {} : data;
        dispatch({type: types.GET_PREVIOUS_VISIT_DETAILS, visitDetails: visit});
    }).catch(err => {
        logoutErrorMessage(err, dispatch, null);
    })
};

export const retrieveAllPatients = (page, formData) => (dispatch) => {
    dispatch({type: types.RETRIEVE_PATIENTS_REQUEST});
    return axios.post(`${config.smsUrl}/cpatient/patient_list/${page}`, formData).then(res => {
        const data = res.data;
        const dt = !data ? {} : data;
        const patients = !dt.patient_list ? [] : dt.patient_list;
        const total_count = !dt.total_count ? 0 : dt.total_count;
        dispatch({type: types.PATIENT_COUNT, total_count});
        dispatch({type: types.RETRIEVE_PATIENTS_SUCCESS, patients})
    }).catch((err) => {
        dispatch({type: types.RETRIEVE_PATIENTS_ERROR});
        logoutErrorMessage(err, dispatch, null)
    })
};

export function closeVisit() {
    return {
        type: types.CLOSE_VISIT
    }
}

export const registerNewPatient = (formData) => (dispatch) => {
    dispatch({type: types.REGISTER_PATIENT_REQUEST});
    return axios.post(`${config.smsUrl}/cpatient/add_patient`, formData).then((res) => {
        const data = res.data;
        const info = !data ? {} : data;
        const patient_number = !info.patient_number ? "" : info.patient_number;
        dispatch({type: types.REGISTER_PATIENT_SUCCESS});
        dispatch({type: types.OPEN_VISIT, openVisit: true});
        dispatch(snackSuccess('Patient registered successfully'));
        dispatch({type: types.GET_PATIENT_ID, patient_number});
    }).catch(err => {
        dispatch({type: types.OPEN_VISIT, openVisit: false});
        dispatch({type: types.REGISTER_PATIENT_ERROR});
        errorMessages(err, dispatch, null);
    })

};

export function updatePatient(formData) {
    return function (dispatch) {
        dispatch({type: types.EDIT_PATIENT_REQUEST});
        return axios.post(config.smsUrl + "/cpatient/edit_patient", formData).then(() => {
            dispatch({type: types.EDIT_PATIENT_SUCCESS});
            dispatch(snackSuccess('Patient updated successfully'));
            history.push("/patientDetails");
        }).catch(err => {
            dispatch({type: types.EDIT_PATIENT_ERROR});
            errorMessages(err, dispatch, null)
        })
    }
}

export function getPatientsDetail(formData) {
    return function (dispatch) {
        return axios.post(config.smsUrl + "/cpatient/patient_update_form", formData).then(response => {
                const patient = response.data;
                const pt = !patient ? {} : patient;
                dispatch(loadPatientSuccess(pt));
            }).catch(err => {
                logoutErrorMessage(err, dispatch, null)
            })
    };
}


