import React from "react";
import {DataCell, ModuleTable} from "../../Utils/Lists/CollapsibleList";
import TableRow from "@material-ui/core/TableRow";
import {titleCase} from "../../Users/addUser";
import { AutoCompleteUnControlled } from "../../Utils/FormInputs/AutoCompleteInput";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {StyledRemoveIcon, StyledCell} from "../doctorStyles";
import InputGroup from "react-bootstrap/InputGroup";
import TextField from "../../Utils/FormInputs/TextField";


const headData = [{item:'Lab test', toolTip:'Make Lab test request here', isRequired:true},{item:'Lab', isRequired:true},
    {item:'Cost'}, {item:'Status'}, {item:'Action', isRequired:false}]


const NewLabTable = ({ labTests, retrieveTests, tests, getTestDetails, handleAddRow, removeRow,
                         submitted,isEdit=false,isOutPatient=false}) =>{
    return (
        <ModuleTable headData={headData}>
            {labTests.map((test, index) => (
                <TableRow
                    key={test.uuid}
                >
                    <DataCell variant='200'>
                        { isEdit && test.labrequests_detail_id ? test.test_name :
                            <>
                            <AutoCompleteUnControlled options={tests} submitted={submitted} idx={index} handleChange={retrieveTests} label_text="label"
                            medicine_value={{label:test.test_name, value: test.test_id}} id="test_name" value_text="value"
                            valid_error_check={test.isError} errorMsg_Valid="Please a select a valid lab test" testId={`test_name-${index}`}
                            handleProductData={getTestDetails} placeholder="Type Lab Test" errorMsg="Lab test is required"/>
                            </> }
                    </DataCell>
                    <DataCell>
                        {titleCase(test.hospital_test_type)}
                    </DataCell>
                    <DataCell variant='150'>
                        <InputGroup>
                            <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">{test.currency_symbol}</InputGroup.Text>
                            <TextField  type="number" value={test.cost} style={{pointerEvents:'none'}}/>
                        </InputGroup>
                    </DataCell>
                    <DataCell>
                        {test.is_external=== true ? 'External Test': test.is_external=== false ?  'Internal Test' : ''}
                    </DataCell>
                    <DataCell>
                        <Tooltip title='Remove'>
                            <IconButton type='button' onClick={() => removeRow(test.uuid)}>
                                <StyledRemoveIcon/>
                            </IconButton>
                        </Tooltip>
                    </DataCell>
                </TableRow>
            ))}
            {!isOutPatient ? <TableRow>
                <StyledCell colSpan={headData.length}>
                    <button type="button" onClick={handleAddRow} className="btn btn-sm sms-gray-btn"
                            id="add-row">Add another line
                    </button>
                </StyledCell>
            </TableRow> : null}

        </ModuleTable>
    )
}

export {NewLabTable}



