import { ErrorMessage, RightAlignedContainer } from "../../Utils/styledComponents";
import TextField from "../../Utils/FormInputs/TextField";
import { Tooltip } from "@material-ui/core";
import { StyledRemoveIcon } from "../../DoctorsModule/doctorStyles";
import { CustomSelect } from "../../Utils/FormInputs/SelectInput";
import { IconButton } from "@material-ui/core";
import Label from "../../Utils/FormInputs/Label";
import Form from "react-bootstrap/Form";
import { CsvDialog } from "../../Utils/Dialogs/CsvDialog";
import {ServiceAutoComplete} from "../utils/ServiceAutocomplete";


// component to show the form to update an item
export const EditItem = ({current_item, handleItemUpdate, handleChangeItem, submitted, item_type}) => {
    const isProduct = current_item?.product_id
    return (
        <>
            <table className="table table-bordered billing-invoice-table procedure-table">
            <thead>
            <tr>
                <th><strong>Item Name</strong></th>
                <th><strong>Item Type</strong></th>
                <th><strong>Cost</strong></th>
                {isProduct ? <th><strong>Pack Unit</strong></th> :  null}
                <th><strong>Currency</strong></th>
            </tr>
        </thead>

                <tbody>
                    <tr key={current_item.index}>
                        <td>{current_item.item_name}</td>
                        <td>{current_item.item_type}</td>
                        <td>
                            <>
                                <TextField submitted={submitted} type="number" value={current_item.cost}
                                    onChange={(e) => handleChangeItem(e, "cost")}/>
                                {submitted && !current_item.cost ? <ErrorMessage>This is a required field</ErrorMessage>:null}
                            </>
                        </td>



                        {isProduct ? <td>
                            <TextField onChange={(e)=>handleChangeItem(e, 'pack_unit')} value={current_item.pack_unit} type='text'
                                    submitted={submitted} name='pack_unit' placeholder={`Pack Unit`}/>
                            {(submitted && !current_item.pack_unit) &&
                                <ErrorMessage>Pack unit is required</ErrorMessage>}
                        </td>: null}
                        <td>
                            <TextField type='text' value={current_item.currency_symbol} readOnly/>
                        </td>
                        

                    </tr>
                </tbody>
            </table>
            <button type="submit" className="btn btn-sm sms-btn px-4" id="submit-btn" onClick={handleItemUpdate}>Update</button>
        </>
    )
}

// toggle component to show the tables or not
const ToggleHMIS = ({label_text, default_checked, onChange, id}) => {
    return (
        <div className="switch-admission-prescription">
            <Label name={label_text} className="label-admission-prescription"/>
            <text className="label-admission-prescription">No</text>
            <Form.Check type="switch" id={id} defaultChecked={default_checked} onChange={onChange} className="toggle-admission-prescription"/>
            <text className="label-admission-prescription">Yes</text>
        </div>
    )
}

// component to display the table items for the items
const TableItems = ({item_type, new_items,
    submitted, handleChangeItem,isResolved,
    getItems, setItemDetails,currency_symbol,
    handleAddRow, handleRemoveRow, uploadProps,resetField,
    handleOpenCsvDialog, isProduct=false,room_options=[],}) => (

<>
<CsvDialog {...{...uploadProps, title:'Upload Items'}}/>
   <RightAlignedContainer><button onClick={()=>handleOpenCsvDialog(item_type)} id="upload-providers" className="btn sms-info-btn btn-sm mb-2">Upload Items</button></RightAlignedContainer>
    <table className='table table-sm table-bordered billing-invoice-table procedure-table'>
        <thead>
            <tr>
                <th><strong>{`${item_type}`} Name</strong></th>
                <th><strong>Cost</strong></th>
                {isProduct ?  <th><strong>Pack Unit</strong></th> : null}
                <th><strong>Currency</strong></th>
                <th><strong>Action</strong></th>
            </tr>
        </thead>
        <tbody>
            {new_items.map((item, index)=>{

                const delete_id = {
                    'Room':item.room_id,
                    'Service':item.service_id,
                    'Product':item.product_id
                }



                return(
                    <tr key={index}>
                        <td>
                            {item_type === 'Room' ? 
                                <>
                                    <CustomSelect value={{label: item.name, value: item.room_id}} 
                                            onChange={(e)=>handleChangeItem(e, index, item, 'name')} 
                                            options={room_options} id={`item-room-${index}`}/>
                                    {(submitted && !item.room_id) &&
                                        <ErrorMessage>Room name is required</ErrorMessage>}
                                </>
                            :
                            item_type === 'Service' ? 
                                <>
                                    <ServiceAutoComplete value={{label: item.service_name , value: item.service_id, ...item}} options={item.service_options} submitted={submitted}
                                    inputValue={item.service_name} errorMsg='Please enter a valid service name'  placeholder="Type service name"  id={`service-name-${index}`}
                                    handleSearch={(e, newInputValue, reason)=>{
                                        if (e?.type && e?.type === 'change') {
                                            getItems(newInputValue, index, item, (newInputValue === ''))
                                        }
                                    }}
                                    getData={(e, newValue)=>setItemDetails(newValue, index, item)} testId={`service-name-${index}`}/>
                                {(submitted && !item.service_id) &&
                                    <ErrorMessage>Service name is required</ErrorMessage>}
                            </>:
                            item_type === 'Product' ? 
                            <>
                                <ServiceAutoComplete value={{label: item.product_name , value: item.product_id, ...item}} options={item.product_options} submitted={submitted}
                                                     inputValue={item.product_name} errorMsg='Please enter a valid product name'  placeholder="Type product name"  id={`product-name-${index}`}
                                                     handleSearch={(e, newInputValue, reason)=>{
                                                         if (e?.type && e?.type === 'change') {
                                                             getItems(newInputValue, index, item, (newInputValue === ''))
                                                         }
                                                     }}
                                                     getData={(e, newValue)=>setItemDetails(newValue, index, item)} testId={`product-name-${index}`}/>

                            {(submitted && !item.product_id) &&
                                <ErrorMessage>Product name is required</ErrorMessage>}
                            </>: null
                            }
                        </td>
                        <td>
                            <TextField onChange={(e)=>handleChangeItem(e, index, item, 'cost')} value={item.cost} type='number'
                                    submitted={submitted} name='price' placeholder={`Cost`} id={`${item_type}-cost-${index}`}/>
                            {(submitted && !item.cost) &&
                                <ErrorMessage>Cost is required</ErrorMessage>}
                        </td>


                        {isProduct ? <td>
                            <TextField id={`pack_unit-${index}`} onChange={(e)=>handleChangeItem(e, index, item, 'pack_unit')} value={item.pack_unit} type='number'
                                    submitted={submitted} name='pack_unit' placeholder={`Pack Unit`} />
                            {(submitted && !item.pack_unit) &&
                                <ErrorMessage>Pack unit is required</ErrorMessage>}
                        </td> : null}
                        <td>
                            <TextField type='text' readOnly value={currency_symbol !== 'null' ? currency_symbol : ''}/>
                        </td>

                        <td>
                            <Tooltip title='Remove'>
                                <IconButton onClick={()=>handleRemoveRow(delete_id[item_type])}>
                                    <StyledRemoveIcon/>
                                </IconButton>
                            </Tooltip>
                        </td>
                    </tr>
                )
            })}
            <tr>
                <td colSpan={3}>
                    <button type='button' onClick={handleAddRow} id={`add_${item_type}`} className='btn btn-sm sms-gray-btn'>Add another line</button>
                </td>
            </tr>
        </tbody>
    </table></>
)

export const AddItem = ({
    provider_products, provider_rooms, provider_services,
    handleToggleServices, handleToggleRooms, handleToggleProducts,
    handleAddServicesRow, handleRemoveServicesRow, handleChangeService, getServiceOptions, setServiceDetails,
    handleAddRoomRow, handleRemoveRoomRow, handleChangeRoom, room_options,
    handleAddProductRow, handleRemoveProductRow, handleChangeProduct,  getProductOptions, setProductDetails,
    submitted, handleAddItems, uploadProps, currency_symbol, resetField
    }) => {
    
    const {isAddRooms, new_rooms} = provider_rooms;
    const {isAddServices, new_services} = provider_services;
    const {isAddProducts, new_products} = provider_products;
    const {handleOpenCsvDialog} = uploadProps
    return (
        <>
            {/* Table for adding Services */}
            <ToggleHMIS label_text={"Add Services"} default_checked={isAddServices} onChange={handleToggleServices} id={'add-services'}/> 
            {isAddServices ?
                <TableItems new_items={new_services} submitted={submitted} item_type={'Service'} currency_symbol={currency_symbol}
                getItems={getServiceOptions} setItemDetails={setServiceDetails} handleOpenCsvDialog={handleOpenCsvDialog} uploadProps={uploadProps}
                handleAddRow={handleAddServicesRow} handleRemoveRow={handleRemoveServicesRow} handleChangeItem={handleChangeService} {...{resetField}} />
            : null}

            {/* Table for adding Rooms */}
            <ToggleHMIS label_text={"Add Rooms"} default_checked={isAddRooms} onChange={handleToggleRooms} id={'add-rooms'}/> 
            {isAddRooms ?
                <TableItems new_items={new_rooms} submitted={submitted} item_type={'Room'}  handleOpenCsvDialog={handleOpenCsvDialog} uploadProps={uploadProps}
                room_options={room_options} currency_symbol={currency_symbol}
                
                handleAddRow={handleAddRoomRow} handleRemoveRow={handleRemoveRoomRow} handleChangeItem={handleChangeRoom}/>
            : null}

            {/* Table for adding Products */}
            <ToggleHMIS label_text={"Add Products"} default_checked={isAddProducts} onChange={handleToggleProducts} id={'add-products'}/>
            {isAddProducts ?
                <TableItems new_items={new_products} submitted={submitted} item_type={'Product'}  handleOpenCsvDialog={handleOpenCsvDialog} uploadProps={uploadProps}
                getItems={getProductOptions} setItemDetails={setProductDetails} isProduct={true} currency_symbol={currency_symbol}
                handleAddRow={handleAddProductRow} handleRemoveRow={handleRemoveProductRow} handleChangeItem={handleChangeProduct}/>
            : null}

            <button type="submit" className="btn btn-sm sms-btn px-4" id="submit-btn" onClick={handleAddItems}>Save</button>
        </>
    )
}
