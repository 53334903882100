import React from "react";
import CustomTable from "../Utils/Tables/CustomTable";
import FilterResults from "react-filter-search";
import TableBody from "@material-ui/core/TableBody";
import {getComparator, stableSort} from "../Utils/Tables/sortingFunctions";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../actions/snackbarActions";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import DeleteDialog from "../Utils/Dialogs/DeleteDialog";
import { Container, ErrorMessage, RightAlignedContainer } from "../Utils/styledComponents";
import { ReusableRouteTabs } from "../Utils/Dialogs/ReusableTabs";
import { history } from "../../Helpers/history";
import PageTitle from "../Utils/smsTitle";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faVials} from "@fortawesome/free-solid-svg-icons/faVials";
import CustomDialog from "../Utils/Dialogs/CustomDialog";
import Form from 'react-bootstrap/Form'
import Label from "../Utils/FormInputs/Label";
import TextField from "../Utils/FormInputs/TextField";
import { useSampleTypesList } from "./CustomHooks/useSampleTypes";


const headData = [{id: 'sl', numeric: false, disablePadding: false, label: 'SL'},
    {id: 'sampleType', numeric: false, disablePadding: true, label: 'Sample Type'},
    {id: 'actions', numeric: false, disablePadding: true, label: 'Action'},
];

const SampleTypes = ({actions, snackbars}) => {
    const {searchValue, order, orderBy, page, rowsPerPage, handleSearch, handleRequestSort,
        handleChangePage, handleChangeRowsPerPage, closeSnackbar,
        sampleTypes, openDelete, sample_type_name, submitted,isPending, isLoading, 
        handleChange,  handleSubmit, handleOpenDelete,
        handleCloseDelete, handleDelete,  isSuccess, isError,
        openDialog, handleCloseDialog, handleOpenDialog, sample_id}= useSampleTypesList(actions)
    const {openBar, type, message} = snackbars;
    const components = [{label: 'Lab Tests', path: '/labsettings'},
    {label: 'Lab Types', path: '/labtype'},
    {label: 'Sample Types', path: '/addsample'},]
    return (
        <div>
            <PageTitle title="Laboratory Settings"/>
            <SubHeader title="Laboratory" subTitle="Laboratory settings">
                <FontAwesomeIcon icon={faVials}/>
            </SubHeader>
            <CustomDialog open={openDialog} handleClose={handleCloseDialog} title={`${sample_id ? 'Edit':'Add'} Sample Type`}>
                <form autoComplete="off" onSubmit={handleSubmit}>
                    <Form.Group>
                        <Label name='Sample Type' htmlFor='sample_type' type/>
                        <TextField value={sample_type_name} onChange={handleChange} id='sample_type'/>
                        {submitted && !sample_type_name ? <ErrorMessage>This is a required field</ErrorMessage>:null}
                    </Form.Group>
                    <button type="submit" disabled={isPending} className="btn btn-sm sms-info-btn px-3">{isPending ? 'Saving...' :'Save'}</button>
                </form>
            </CustomDialog>
            <Container>
                <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/>
                <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>
                <DeleteDialog message="Delete " openDialog={openDelete} handleClose={handleCloseDelete}
                              text="sample type" title='Delete Sample Type'>
                    <button className='btn sms-btn-dismiss btn-sm' onClick={handleDelete}>Delete</button>
                    <button className="btn sms-grey-btn btn-sm" onClick={handleCloseDelete}>No</button>
                </DeleteDialog>
                <RightAlignedContainer>
                    <button onClick={()=>handleOpenDialog('')} className="btn btn-sm sms-info-btn mb-1">Add Sample Type</button>
                </RightAlignedContainer>
                <CustomTable  title="Sample Types" handleChangePage={handleChangePage}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                            headData={headData} handler={handleSearch} page={page} rowsPerPage={rowsPerPage}
                            handleRequestSort={handleRequestSort} data={sampleTypes} colSpan={headData.length} order={order}
                            orderBy={orderBy} id="searchValue" filter>
                        <FilterResults value={searchValue} data={sampleTypes}
                            renderResults={results => (
                            <TableBody>
                                {isLoading ? <TableRow>
                                    <TableCell colSpan={headData.length} align="center"><img src="/images/smsloader.gif"
                                                                            alt="loader"/></TableCell>
                                </TableRow>:null}
                                {isSuccess ? sampleTypes.length > 0 ? stableSort(results, getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((item, index) => {
                                        const count = (page * rowsPerPage) + index + 1;
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={item.type_id}
                                                className='mui-table-row'
                                                id="unit"
                                            >
                                                <TableCell>{count}</TableCell>
                                                <TableCell >{item.type_name}</TableCell>
                                                <TableCell>
                                                    <button onClick={()=>handleOpenDialog(item.type_id)} className="btn btn-sm sms-info-btn mr-1">Edit</button>
                                                    <button onClick={()=>handleOpenDelete(item.type_id)} className="btn btn-sm sms-btn-dismiss">Delete</button>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    }):<TableRow>
                                    <TableCell colSpan={headData.length} align="center">No records found</TableCell>
                                </TableRow>:null}
                                {isError ? <TableRow>
                                    <TableCell colSpan={headData.length} align="center">The server did not return a valid response</TableCell>
                                </TableRow> : null}
                            </TableBody>
                        )}/>
                </CustomTable>
            </Container>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(SampleTypes);
