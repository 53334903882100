import {useEffect, useState} from "react";
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import {usePatientDetails} from "../../ReferralNote/usePatientDetails";

export const useDoctorTriage = (actions, ids) => {
    const { visit_id, patient_number, patient_type} = ids
    const [state, setState] = useState({patient_name: "", age: "", gender: "", address: "", phone: ""});
    const [triage, setTriage] = useState([]);
    const {patient: patientDetails} = usePatientDetails(actions, patient_number, '')

    useEffect(() => {
        if (patient_type !== '1'){
            return;
        }
        const formData = new FormData();
        formData.append('visit_id', visit_id);
        axios.post(`${config.smsUrl}/cdoctor/get_triage_details`, formData).then(res => {
            const data = res.data;
            const details = !data ? [] : data;
            const dt = details[0] ? details[0] : {};
            const items = dt.data ? dt.data : [];
            let obj = {};
            details.forEach(({metric_name, metric_value}) => {
                obj[metric_name] = metric_value
            })
            const weight = obj['Weight'] ? +(obj['Weight']) : 0;
            const height = obj['Height'] ? +(obj['Height']) : 0;
            const b = weight > 0 && height > 0 ? (weight / Math.pow(height, 2)) * 10000 : 0;

            let bj = {metric_name:'BMI', metric_value:b.toFixed(2), uom:'', metric_id:''}
            setTriage([...items, bj])
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, []);

    useEffect(() => {
        setState({
            patient_name: `${!patientDetails.first_name ? "" : patientDetails.first_name} ${!patientDetails.last_name ? "" : patientDetails.last_name}`,
            age: patientDetails.Age,
            gender: patientDetails.gender,
            address: patientDetails.address,
            phone: patientDetails.phone_no
        })
    }, [patientDetails]);



    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    return { state, triage, closeSnackbar}
}