/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PageTitle from "../../Utils/smsTitle";
import { Button, MiniCenteredDiv, RightAlignedContainer } from '../../Utils/styledComponents'
import { PrintTemplate, ItemsTable } from '../../Utils/Templates/PrintTemplate'
import { usePrint } from '../../Utils/Templates/usePrint';
import * as colors from '../../../styles/colors';
import {dateStringConvert} from '../../Utils/ConvertDate';
import {formatDigits, formatSsp} from '../../Utils/formatNumbers';
import Card from '@material-ui/core/Card'
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../../actions/snackbarActions";
import { faShippingFast } from '@fortawesome/free-solid-svg-icons/faShippingFast';
import React, {useEffect, useState} from "react";
import Form from "react-bootstrap/Form"
import axios from "axios";
import {config} from "../../../Helpers/env";
import {logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import { history } from '../../../Helpers/history';
import { BackButton } from '../../Utils/Buttons/DataExportationButton';
import {useCurrency} from "../../../Context/currency-context";


const textWeight = css`
    font-weight:600;
`

const tableCell = css`
    border-top:2px solid ${colors.text00};
`


export const ViewPurchaseTemplate = ({state,currency_symbol, products=[]}) =>{
    const { purchase_date,invoice_no, grand_total,
        supplier_name, currency_grand_total,isCurrency,details} = state;

    const headData = [{key:'name',value:'Product Name'},{key:'generic_name',value:'Generic Name'},  {key:'quantity',value:'Quantity'},
        {key:'rate',value:`Unit Cost(${currency_symbol})`}, {key:'total_cost',value:`Total Cost(${currency_symbol})`}]

    const patientDetails = (
        <tbody>
        <tr>
            <td colSpan={4}><span>Date: </span> <span css={[textWeight]}>{dateStringConvert(purchase_date)}</span></td>

        </tr>
        <tr>
            <td><span>Invoice#: </span> <span><strong>{invoice_no}</strong></span></td>

        </tr>
        <tr>
            <td><span>Supplier: </span> <span css={[textWeight]}>{supplier_name}</span></td>
        </tr>
        <tr>
            <td><span>Purchase details: </span> <span css={[textWeight]}>{details}</span></td>
        </tr>
        </tbody>
    )

    const itemDetails = (
        <ItemsTable {...{headData}}>
            <tbody>
            {products.map((item, index) => (
                <tr key={index} id="drugs-row">
                    <td><span>{item.product_name}</span></td>
                    <td><span>{item.generic_name}</span></td>
                    <td><span>{item.quantity}</span></td>
                    <td><span>{isCurrency ?  formatSsp(item.currency_rate) : formatDigits(item.usd_rate)}</span></td>
                    <td><span>{isCurrency ? formatSsp(item.currency_total) : formatDigits(item.usd_total)}</span></td>
                </tr>))}

            <tr>
                <td align='right' colSpan={headData.length -1}><span >Total Amount</span></td>
                <td css={[tableCell]}><span css={[textWeight]}>{isCurrency ? formatSsp(currency_grand_total) :  formatDigits(grand_total)}</span></td>
            </tr>

            </tbody>
        </ItemsTable>
    )
    return(
        <PrintTemplate title='PURCHASE DETAILS' {...{patientDetails,itemDetails}}/>
    )
}

function ViewPurchase({actions, match}) {
    const {purchase_id, component} = match.params;
    const [state, setState] = useState({supplier_name:'',purchase_date:'',invoice_no:'', grand_total:0, currency_grand_total:0, currency_id:'',details:''})
    const [products, setProducts] = useState([])
    const [isCurrency, setIsCurrency] = useState(false)
    const [loadingPurchase, setLoadingPurchase] = useState('idle')
    const [currency, setCurrency] = useState('')

    const [recordedCurrency, setRecordedCurrency] = useState({})

    const {currency:selectedCurrency} = useCurrency({actions, match})

    useEffect(()=>{
        if (recordedCurrency.currency_symbol){
            return
        }
        setCurrency(`${selectedCurrency?.currency_symbol}-${selectedCurrency?.is_base_currency}`)
    },[recordedCurrency.currency_symbol])


    useEffect(() =>{
        if (!purchase_id){
            return
        }
        const formData = new FormData();
        formData.append('product_purchase_id', purchase_id)
        setLoadingPurchase('loading')
        axios.post(`${config.smsUrl}/cinventory/purchase_update_form`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const prods = dt.purchase_info ?? [];
            const obj = prods[0] ? prods[0] : {};
            setState({supplier_name: dt.supplier_name, purchase_date: dt.purchase_date, invoice_no: dt.chalan_no,details:dt.payment_detials,
            grand_total: obj.grand_total,currency_grand_total: obj.grand_currency_total, currency_id: obj.currency_id})

            const arr = prods.map(item=>({
                ...item,
                currency_rate: item.rate / item.currency_rate  ,
                usd_rate: item.rate,
                usd_total : item.total_amount,
                currency_total:(item.rate / item.currency_rate) * item.quantity

            }))
            setRecordedCurrency(dt.currency_symbol)
            setCurrency(`${dt.currency_symbol}-${dt.is_base_currency}`)
            setProducts(arr)
            setLoadingPurchase('success')

        }).catch((err) => {
            logoutErrorMessage(err, null, actions)
            setLoadingPurchase('error')
        })
    },[])

    const handleChangeCurrency = (event) =>{
        setIsCurrency(event.target.checked)
    }

    const isLoadingPurchase = loadingPurchase === 'loading'
    const isSuccessPurchase = loadingPurchase === 'success'
    const isErrorPurchase = loadingPurchase === 'error'



    const {invoice_no,  currency_id} = state;
    const handleOnAfterPrint = () => {
        history.push('/purchaselist');
      }

  const {componentRef, handlePrint,  fontSize}  = usePrint(`ViewPurchase_${invoice_no}`, handleOnAfterPrint);


const isCanceled = component === 'canceled'

const isBaseCurrency = currency && currency.split('-')[1] === 'true'
const currency_symbol = currency && currency.split('-')[0]

  return (
      <div>
        <PageTitle title='Purchase Details'/>
        <SubHeader title="Inventory" subTitle='Purchase Details'>
            <FontAwesomeIcon icon={faShippingFast}/>
        </SubHeader>
        <MiniCenteredDiv>
            <BackButton to={isCanceled ? '/canceled-purchases':'/purchaselist'} text={isCanceled ? 'canceled purchases':'purchase list'} data-testid="purchase-details-back-button"/>

            {/*<BackButton to='/purchaselist' text='Purchase List' data-testid="purchase-details-back-button"/>*/}
            <RightAlignedContainer>
              <Button variant='orange' onClick={handlePrint}>Print</Button>
            </RightAlignedContainer>
            <Card className='mt-2'>
                {!isBaseCurrency ?  <div style={{padding:'8px 32px', borderBottom:'1px solid rgba(224, 224, 224, 1)'}}>
                    <RightAlignedContainer>
                        <Form.Check
                            type="switch"
                            id="custom-switch"
                            label="View in SSP(South Sudan Pounds)"
                            checked={isCurrency}
                            onChange={handleChangeCurrency}
                        />
                    </RightAlignedContainer>
                </div>: null}
                <div style={{padding:'32px'}}>
                    <div ref={componentRef} className="requisition-receipt">
                        <style>
                            {/* {getPageMargins()} */}
                            {fontSize()}
                        </style>
                        <ViewPurchaseTemplate {...{state:{...state, isCurrency:isBaseCurrency}, products,
                            isErrorPurchase, isLoadingPurchase, isSuccessPurchase,currency_symbol}}/>
                    </div>
                </div>
            </Card>
        </MiniCenteredDiv>
    </div>
  )
}

function mapStateToProps(state){
  const {snackbar} = state;
  return {snackbar}
}

function mapDispatchToProps(dispatch) {
  return {actions:{snackbarActions:bindActionCreators(snackbarActions, dispatch)}}
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewPurchase);