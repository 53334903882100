/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";
import React, { useEffect, useState } from "react";
import { SubHeader } from "../../../Containers/SubHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins } from "@fortawesome/free-solid-svg-icons/faCoins";
import PageTitle from "../../Utils/smsTitle";
import {
  Button,
  ErrorMessage,
  Container,
  RightAlignedContainer,
  MiniCenteredDiv,
} from "../../Utils/styledComponents";
import { PrintTemplate, ItemsTable } from "../../Utils/Templates/PrintTemplate";
import { usePrint } from "../../Utils/Templates/usePrint";
import * as colors from "../../../styles/colors";
import { dateStrokeConvert, formatDate } from "../../Utils/ConvertDate";
import Card from "@material-ui/core/Card";
import { LoadingGif } from "../../Utils/Loader";
import {
  coloredInvoice,
  textWeight,
  thUppercase,
  biggerFont,
  mediumFont,
  printTable,
} from "../../../styles/tableStyles";
import { BackButton } from "../../Utils/Buttons/DataExportationButton";
import axios from "axios";
import { config } from "../../../Helpers/env";
import { CurrencyToggle, FxRate } from "../../Utils/CurrencyToggle";
import {
  errorMessages,
  logoutErrorMessage,
} from "../../../Helpers/ErrorMessages";
import { bindActionCreators } from "redux";
import * as snackbarActions from "../../../actions/snackbarActions";
import { connect } from "react-redux";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import CustomDialog from "../../Utils/Dialogs/CustomDialog";
import { ResizableTextarea } from "../../Utils/FormInputs/Textarea";
import Form from "react-bootstrap/Form";
import Label from "../../Utils/FormInputs/Label";
import { history } from "../../../Helpers/history";
import { FinishedStatus } from "../../DoctorsModule/doctorStyles";
import {
  Border,
  Heading,
  ResultsHeading,
} from "../../DoctorsModule/PatientPreviousVisits";
import Alert from "@material-ui/lab/Alert";
import { groupProductId, groupServiceId } from "../utils/SanitizeArr";
import { formatDigits, formatSsp } from "../../Utils/formatNumbers";
import { removeTrailingZeros } from "../utils/removeDecimals";
import { useCurrency } from "../../../Context/currency-context";
import { useVersionsContext } from "../../../Context/versions-context";
import TextField from "../../Utils/FormInputs/TextField";
import SplitInvoice from "./SplitInvoice";
import { Link } from "react-router-dom";

const titleText = css`
  margin: 0;
  color: ${colors.darkBlue001};
  font-size: 15px;
`;

const heading = css`
  font-size: 20px;
  margin: 0;
`;

const borderless = css`
  border-bottom: 0;
`;

const tableMargin = css`
  margin-bottom: 0;
`;

const card = css`
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  background: #ffffff;
`;

const checksDiv = css`
  border-right: 1px solid #eee;
  //background-color: #fbfbfb;
  padding: 10px 20px 70px;
  word-wrap: break-word;
  min-height: 100%;
`;
const invoiceSpan = css`
  color: #3f51b5;
  display: inline-block;
  text-decoration: underline;
  font-weight: 700;
  margin-right: 14px;
`;

const removeNumbers = (text = "") => {
  return text.replace(/[0-9]/g, "");
};

const getVisitYear = (date) => {
  const year = new Date(date).getFullYear().toString();
  return year.split("").slice(-2).join("");
};

function BilledInvoicesReceipt({ actions, snackbar, match }) {
  const { currency: selectedCurrency } = useCurrency({
    actions,
    snackbar,
    match,
  });
  const { visit_id, provider_id, claim_status, patient_type, provider_type } =
    match.params;
  const [receipt, setReceipt] = useState({});
  const [items, setItems] = useState([]);
  const [total_amount, setTotalAmount] = useState({
    total_base_amount: 0,
    total_non_base_amount: 0,
  });
  const [co_payment, setCoPayment] = useState({
    discount: 0,
    discount_amount: 0,
    grand_total_bill: 0,
  });
  const [status, setStatus] = useState("idle");
  const [exchangeRate, setExchangeRate] = useState(0);
  const [currencyExchangeRate, setCurrencyExchangeRate] = useState(0);
  const [currency, setCurrency] = useState("");
  const [currencies, setCurrencies] = useState([]);
  const [invoiceItems, setInvoiceItems] = useState([]);
  const [openCancel, setOpenCancel] = useState(false);
  const [openApprove, setOpenApprove] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState("idle");
  const [approvalChecks, setApprovalChecks] = useState([]);
  const [serviceTypes, setServiceTypes] = useState([]);
  const [loadChecks, setLoadChecks] = useState("idle");
  const [prevCurrency, setPrevCurrency] = useState(null);
  const [total_bill, setTotalBill] = useState(0);
  const [checkAll, setCheckAll] = useState(false);
  const [recordedCurrency, setRecordedCurrency] = useState({});
  const [coPaymentValue, setCoPaymentValue] = useState("");
  const [openCoPayment, setOpenCoPayment] = useState(false);
  const [submittedCopayment, setSubmittedCopayment] = useState(false);
  const [isSubmittedCoPayment, setIsSubmittedCopayment] = useState("idle");
  const [openSplit, setOpenSplit] = useState(false);
  const [isSplitSubmitted, setIsSplitSubmitted] = useState("idle");
  const [originalInvoice, setOriginalInvoice] = useState({});
  const [splitInvoices, setSplitInvoices] = useState([]);

  const { subscription } = useVersionsContext();
  const version = subscription?.version;
  const isPremium = version === "Premium";

  const isResolved = isSubmittedCoPayment === "resolved";
  const isSplitResolved = isSplitSubmitted === "resolved";

  useEffect(() => {
    if (recordedCurrency.currency_symbol) {
      return;
    }
    setCurrency(
      `${selectedCurrency?.currency_symbol}-${selectedCurrency?.is_base_currency}`
    );
  }, [recordedCurrency.currency_symbol]);

  // get currencies
  useEffect(() => {
    axios
      .get(`${config.smsUrl}/accounting/currencies`)
      .then((res) => {
        const data = res.data;
        const all_data = !data ? {} : data;
        const arr = all_data.data ?? [];
        const list = arr.map((item) => ({
          value: `${item.currency_symbol}-${item.is_base_currency}`,
          label: `${item.currency_symbol} ${item.currency_name}`,
        }));
        setCurrencies(list);
      })
      .catch((err) => {
        // logoutErrorMessage(err, null, actions)
      });
  }, []);

  const reduceAmounts = (arr = []) => {
    return arr.reduce((a, item) => {
      return a + item.amount;
    }, 0);
  };

  const convertList = (receiptsArr, ex_rate, total_bill, isBase) => {
    isBase = isBase ? isBase : isBaseCurrency;
    console.warn("isBaseCurrency ", isBaseCurrency);
    console.warn("isBase ", isBase);
    console.warn("ex_rate ", ex_rate);

    // isBase = true;
    // console.warn("currency ", currency);
    const productArr = groupProductId(
      receiptsArr.filter((item) => item.product_id)
    );
    const serviceArr = groupServiceId(
      receiptsArr.filter((item) => item.service_id)
    );
    const bedArr = receiptsArr.filter((item) => item.bed_assignment_id);
    const arr = [...serviceArr, ...productArr, ...bedArr].map((item) => {
      const rate = isBase
        ? item.rate
          ? item.rate
          : 0
        : item.currency_id == 2
        ? item.rate
        : item.rate * (1 / ex_rate);
      console.log("rate  ", rate);
      return {
        ...item,
        name: item.service_id
          ? item.service_name
          : item.product_id
          ? item.product_name
          : item.bed_assignment_id
          ? `${item.ward_name} -> ${removeNumbers(item.room_name)}`
          : "",
        quantity: item.bed_assignment_id
          ? `${item.quantity} day${item.quantity > 1 ? "s" : ""}`
          : item.quantity,
        usd_rate: !isBase ? (ex_rate ? rate * ex_rate : rate) : rate,
        other_currency_rate: !isBase ? rate : ex_rate ? rate / ex_rate : rate,
        usd_total: !isBase
          ? ex_rate
            ? item.amount * ex_rate
            : item.amount
          : item.amount,
        currency_total: !isBase
          ? item.currency_id == 2
            ? item.amount
            : item.amount * (1 / ex_rate)
          : item.amount,
      };
    });
    const base_total_amount = reduceAmounts(
      arr.map((item) => ({ amount: item.usd_total }))
    );
    const non_base_total_amount = reduceAmounts(
      arr.map((item) => ({ amount: item.currency_total }))
    );
    const convertedArr = arr.map((item) => ({
      ...item,
      other_currency_rate: removeTrailingZeros(
        item.other_currency_rate.toFixed(3)
      ),
      currency_total: removeTrailingZeros(item.currency_total.toFixed(3)),
    }));

    const base_amount = total_bill ? total_bill : base_total_amount;
    const non_base_amount = total_bill ? total_bill : non_base_total_amount;
    setItems(convertedArr);
    setTotalAmount({
      total_base_amount: base_amount,
      total_non_base_amount: removeTrailingZeros(non_base_amount.toFixed(3)),
    });
  };

  useEffect(() => {
    setStatus("pending");
    const formData = new FormData();
    formData.append("visit_id", visit_id);
    formData.append("provider_id", provider_id);
    axios
      .post(`${config.smsUrl}/cbilling/view_visit_invoices`, formData)
      .then((res) => {
        const data = res.data;
        const all_data = !data ? {} : data;
        const info = !all_data.patient_info ? {} : all_data.patient_info;
        const invoices = all_data.bill ?? [];
        const is_base_obj = all_data.base_currency ?? {};
        const visit_obj = all_data.visit ? all_data.visit : {};

        const obj = invoices[0] ? invoices[0] : {};
        const exchange_rate = obj.currency_rate ? obj.currency_rate : 0;
        const ex_rate = exchange_rate > 0 ? Math.pow(exchange_rate, -1) : 0;
        const symbol = is_base_obj.currency_symbol
          ? is_base_obj.currency_symbol
          : selectedCurrency?.currency_symbol;
        const is_base = !(is_base_obj.is_base_currency == null || undefined)
          ? is_base_obj.is_base_currency
          : selectedCurrency?.is_base_currency;
        obj["date_created"] = data["visit"]["begin_datetime"];

        setCoPayment({
          discount: all_data?.discount,
          discount_amount: all_data?.discount_amount,
          grand_total_bill: all_data?.grand_total_bill,
        });
        setReceipt({ ...info, ...obj });
        setExchangeRate(exchange_rate);
        setCurrencyExchangeRate(ex_rate);
        convertList(invoices, exchange_rate, all_data.total_bill, is_base);
        setInvoiceItems(invoices);
        setStatus("resolved");
        setCurrency(`${symbol}-${is_base}`);
        setPrevCurrency(`${symbol}-${is_base}`);
        setTotalBill(all_data.total_bill);
        const service_types = invoices
          .filter((item) => item.service_id)
          .map((item) => item.service_type);
        setServiceTypes(service_types);
        setOriginalInvoice(visit_obj?.parent);
        setSplitInvoices(visit_obj?.children);
      })
      .catch((e) => {
        setStatus("rejected");
      });
  }, [isResolved, isSplitResolved]);

  // get checked invoice checks
  useEffect(() => {
    if (claim_status !== "1") {
      return;
    }
    setLoadChecks("loading");
    axios
      .get(`${config.smsUrl}/insurance-claim-approval-checks/visit/${visit_id}`)
      .then((res) => {
        const data = res.data;
        const all_data = !data ? [] : data;
        const checks = all_data.map((item) => ({
          ...item["checks"],
          claim_check: item?.checks?.id,
          visit_claim_id: item?.visit?.id,
          is_checked: item?.visit?.is_checked,
        }));
        setApprovalChecks(checks);
        setLoadChecks("success");
      })
      .catch((err) => {
        logoutErrorMessage(err, null, actions);
        setLoadChecks("error");
      });
  }, [claim_status]);

  // get invoice checks
  useEffect(() => {
    if (!patient_type || serviceTypes.length === 0) {
      return;
    }
    if (claim_status === "0") {
      setLoadChecks("loading");
      axios
        .post(`${config.smsUrl}/insurance-claim-approval-checks/find-checks`, {
          service_types: serviceTypes,
          patient_types: [+patient_type],
          insurance_provider_id: provider_id,
          any_patient_type: false,
          any_service_type: true,
          any_provider: true,
        })
        .then((res) => {
          const data = res.data;
          const all_data = !data ? [] : data;
          const checks = all_data.map((item) => ({
            ...item,
            claim_check: item.id,
            is_checked: false,
          }));
          setApprovalChecks(checks);
          setLoadChecks("success");
        })
        .catch((err) => {
          logoutErrorMessage(err, null, actions);
          setLoadChecks("error");
        });
    }
  }, [serviceTypes, patient_type, claim_status]);

  //Change currency
  const handleChangeCurrency = (e) => {
    setCurrency(e.target.value);
    // setExchangeRate(0)
    const amount = prevCurrency === e.target.value ? total_bill : null;
    convertList(invoiceItems, exchangeRate, amount);
  };

  const handleChangeCoPayment = (event) => {
    setCoPaymentValue(event.target.value);
  };

  const handleSaveCoPayment = (event) => {
    event.preventDefault();
    setIsSubmittedCopayment("pending");
    setSubmittedCopayment(true);
    if (coPaymentValue) {
      axios
        .get(`${config.smsUrl}/cbilling/apply-co-payment/invoice/${visit_id}`, {
          params: { co_payment: +coPaymentValue },
        })
        .then(() => {
          actions.snackbarActions.snackSuccess("Co-payment saved successfully");
          setOpenCoPayment(false);
          setIsSubmittedCopayment("resolved");
        })
        .catch((err) => {
          errorMessages(err, null, actions);
          setIsSubmittedCopayment("rejected");
        });
    }
  };

  //Change exchange rate
  const handleChangeExchangeRate = (event) => {
    const ex_rate = +event.target.value > 0 ? 1 / +event.target.value : 0;
    setCurrencyExchangeRate(event.target.value);
    setExchangeRate(ex_rate);
    convertList(invoiceItems, ex_rate);
  };

  const checksComplete = approvalChecks.every((item) => item.check);

  const handleOpenApprove = () => {
    if (checksComplete) {
      setOpenApprove(true);
      setSubmitted(false);
    } else {
      setSubmitted(true);
    }
  };

  const handleCloseApprove = () => {
    setOpenApprove(false);
  };

  const handleOpenCancel = () => {
    setOpenCancel(true);
  };

  const handleCloseCancel = () => {
    setOpenCancel(false);
  };

  const handleChangeReason = (event) => {
    setCancelReason(event.target.value);
  };

  const handleCheckAll = (event) => {
    setCheckAll(event.target.checked);
    const arr = approvalChecks.map((item) => ({
      ...item,
      is_checked: event.target.checked,
    }));
    setApprovalChecks(arr);
  };

  const handleChangeCheck = (event, id) => {
    const { name, checked } = event.target;
    const arr = approvalChecks.map((item) => {
      if (item.claim_check === id) {
        return { ...item, [name]: checked };
      }
      return item;
    });
    setApprovalChecks(arr);
  };

  const handleApproveClaim = () => {
    const isComplete = approvalChecks.every((item) => item.is_checked);
    axios
      .post(`${config.smsUrl}/cbilling/save-bill-claim-checks`, {
        visit_id: +visit_id,
        provider_id: +provider_id,
        claim_status: isComplete ? 2 : 1,
        reason: "",
        claim_checks: approvalChecks.map(
          ({ claim_check, is_checked, ...item }) => ({
            claim_check,
            is_checked,
          })
        ),
      })
      .then(() => {
        actions.snackbarActions.snackSuccess(
          "Claim check(s) saved successfully"
        );
        setOpenApprove(false);
        history.push("/invoicedbills");
      })
      .catch((err) => {
        errorMessages(err, null, actions);
      });
  };

  const handleCancelClaim = (event) => {
    event.preventDefault();
    setSubmitted(true);
    if (cancelReason) {
      setIsSubmitted("pending");
      axios
        .post(`${config.smsUrl}/cbilling/update_insurance_claim_status`, {
          visit_id,
          provider_id,
          reason: cancelReason,
          claim_status: 1,
        })
        .then(() => {
          actions.snackbarActions.snackSuccess("Claim canceled successfully");
          setSubmitted(false);
          setIsSubmitted("resolved");
          setOpenCancel(false);
          history.push("/invoicedbills");
        })
        .catch((err) => {
          errorMessages(err, null, actions);
          setIsSubmitted("rejected");
        });
    }
  };

  const handleCloseSnackbar = () => {
    actions.snackbarActions.hideSnackbar();
  };

  const isPending = status === "pending";
  const isSuccessful = status === "resolved";
  const isRejected = status === "rejected";

  const isSubmitting = isSubmitted === "pending";

  const { total_non_base_amount, total_base_amount } = total_amount;

  const isBaseCurrency = currency && currency.split("-")[1] === "true";
  const currency_symbol = currency && currency.split("-")[0];

  const isInsurance = provider_type === "Insurance Company";

  const columnData = isInsurance
    ? [
        { key: "quantity", value: "Quantity" },
        { key: "name", value: "Description" },
      ]
    : [
        { key: "name", value: "Item" },
        { key: "quantity", value: "Quantity" },
      ];

  const headData = [
    ...columnData,
    { key: "rate", value: `Rate(${currency_symbol})` },
    { key: "amount", value: `Amount(${currency_symbol})` },
  ];

  const { componentRef, handlePrint, fontSize } = usePrint(
    `InvoiceReceipt${receipt.patient_number}`
  );
  const { openBar, type, message } = snackbar;

  const isSubmittingCopayment = isSubmittedCoPayment === "pending";

  const isLoading = loadChecks === "loading";
  const isSuccess = loadChecks === "success";
  const isError = loadChecks === "error";

  const year = new Date().getFullYear().toString();
  const current_year = year.split("").slice(-2).join("");

  const patientDetails = (
    <tbody>
      {/*<tr>*/}
      {/*    <td css={[textWeight, thUppercase]} ><p css={[titleText]}>Invoice To:</p></td>*/}
      {/*    <td css={[textWeight, thUppercase]}><p css={[titleText]}>Invoice Details:</p></td>*/}
      {/*</tr>*/}
      <tr>
        <td css={[thUppercase]}>
          <span className="span-heading">Invoice No.: </span>{" "}
          <span
            css={[textWeight]}
          >{`${receipt?.visit_id}/${current_year}`}</span>
        </td>
        <td css={[thUppercase]}>
          <span className="span-heading">Date: </span>{" "}
          <span css={[textWeight]}>
            {receipt.date_created
              ? dateStrokeConvert(receipt.date_created)
              : ""}
          </span>
        </td>
      </tr>
      <tr>
        <td css={[thUppercase]}>
          <span className="span-heading">Bill To: </span>{" "}
          <span css={[textWeight]}>
            {receipt.billed_to ? receipt.billed_to : ""}
          </span>
        </td>
        <td css={[thUppercase]}>
          <span className="span-heading">Patient Name: </span>{" "}
          <span css={[textWeight]}>
            {`${!receipt.patient_firstname ? "" : receipt.patient_firstname} 
                                ${
                                  !receipt.patient_lastname
                                    ? ""
                                    : receipt.patient_lastname
                                }`}
          </span>
        </td>
      </tr>
      {/*<tr>*/}
      {/*    <td><span className='span-heading'>Member Number:</span> <span css={[textWeight]}>{receipt.card_number}</span></td>*/}
      {/*    /!*<td><span className='span-heading'>Contact: </span> <span css={[textWeight]}>{receipt.phone_no}</span></td>*!/*/}
      {/*</tr>*/}
    </tbody>
  );
  const itemDetails = (
    <ItemsTable
      {...{
        headData,
        coloredTableHead: coloredInvoice,
        tableHeading: printTable,
        capitalize: thUppercase,
      }}
    >
      <tbody>
        {items.map((item) => {
          return (
            <tr key={item.bill_id}>
              <td className="table-items">
                <span>{isInsurance ? item.quantity : item.name}</span>
              </td>
              <td className="table-items">
                <span>{isInsurance ? item.name : item.quantity}</span>
              </td>
              <td className="table-items">
                <span>
                  {!isBaseCurrency
                    ? formatSsp(item.other_currency_rate)
                    : formatDigits(item.usd_rate.toFixed(3))}
                </span>
              </td>
              <td className="table-items">
                <span>
                  {!isBaseCurrency
                    ? formatSsp(item.currency_total)
                    : formatDigits(item.usd_total.toFixed(3))}
                </span>
              </td>
            </tr>
          );
        })}

        <tr>
          <td colSpan={headData.length - 1} align="right">
            <span css={[textWeight]}>TOTAL</span>
          </td>
          <td>
            <span css={[textWeight]}>
              {" "}
              {co_payment.discount <= 0 ? currency_symbol : null}{" "}
              {isBaseCurrency
                ? formatDigits(total_base_amount.toFixed(3))
                : formatSsp(total_non_base_amount)}
            </span>
          </td>
        </tr>
        {co_payment.discount > 0 ? (
          <>
            <tr>
              <td colSpan={headData.length - 1} align="right">
                <span css={[textWeight]}>
                  CO-PAYMENT ({`${co_payment.discount}%`})
                </span>
              </td>
              <td>
                <span css={[textWeight]}>{co_payment.discount_amount}</span>
              </td>
            </tr>
            <tr>
              <td colSpan={headData.length - 1} align="right">
                <span css={[textWeight]}>GRAND TOTAL</span>
              </td>
              <td>
                <span css={[textWeight]}>
                  {" "}
                  {currency_symbol}{" "}
                  {isBaseCurrency
                    ? formatDigits(co_payment.grand_total_bill.toFixed(3))
                    : formatSsp(co_payment.grand_total_bill)}
                </span>
              </td>
            </tr>
          </>
        ) : null}
      </tbody>
    </ItemsTable>
  );

  const approveButtons = (
    <>
      <button
        type="button"
        onClick={handleApproveClaim}
        className="btn btn-sm sms-info-btn mr-2"
      >
        Continue
      </button>
      <button
        type="button"
        onClick={handleCloseApprove}
        className="btn btn-sm sms-gray-btn"
      >
        Cancel
      </button>
    </>
  );

  const printSections = (
    <>
      <BackButton
        to="/invoicedbills"
        text="Invoiced Bills"
        data-testid="billed-invoices-receipt-back-button"
      />
      <RightAlignedContainer>
        <Button variant="orange" onClick={handlePrint}>
          Print
        </Button>
      </RightAlignedContainer>
    </>
  );

  const splitComponent =
    splitInvoices.length > 0 || originalInvoice?.visit_id ? (
      <div
        style={{
          borderBottom: "1px solid rgba(224, 224, 224, 1)",
          padding: "8px 20px",
        }}
      >
        {originalInvoice?.visit_id ? (
          <span style={{ display: "inline-block", marginRight: "16px" }}>
            Original invoice:
            <span css={[invoiceSpan]}>
              <Link
                to={{
                  pathname: `/parentinvoice/${originalInvoice.visit_id}/${patient_type}/${provider_id}/${claim_status}/${provider_type}`,
                }}
              >
                {`${originalInvoice.visit_id}/${getVisitYear(
                  originalInvoice.begin_datetime
                )}`}
              </Link>
            </span>
          </span>
        ) : null}
        {splitInvoices.length > 0 ? (
          <span>
            Split invoice(s):{" "}
            {splitInvoices.map((item) => (
              <span key={item.visit_id} css={[invoiceSpan]}>
                <Link
                  to={{
                    pathname: `/childinvoice/${item.visit_id}/${patient_type}/${provider_id}/${claim_status}/${provider_type}/${visit_id}`,
                  }}
                >
                  {`${item.visit_id}/${getVisitYear(item.begin_datetime)}`}
                </Link>
              </span>
            ))}
          </span>
        ) : null}
      </div>
    ) : null;
  return (
    <div>
      <PageTitle title="Invoice" />
      <SubHeader title="Invoice" subTitle="Invoice">
        <FontAwesomeIcon icon={faCoins} />
      </SubHeader>
      <CustomDialog
        title="Complete claim"
        buttons={approveButtons}
        open={openApprove}
        handleClose={handleCloseApprove}
      >
        Before you go on, please make sure the insurance claim is complete,
        because you can't change it later.
      </CustomDialog>

      <CustomDialog
        title="Add Co-payment"
        open={openCoPayment}
        handleClose={() => {
          setOpenCoPayment(false);
        }}
      >
        <form autoComplete="off" onSubmit={handleSaveCoPayment}>
          <Form.Group>
            <Label name="Co-payment(%)" type />
            <TextField
              value={coPaymentValue}
              onChange={handleChangeCoPayment}
              type="number"
              submitted={submittedCopayment}
            />
            {submittedCopayment && !coPaymentValue ? (
              <ErrorMessage>This field is required</ErrorMessage>
            ) : null}
          </Form.Group>
          <RightAlignedContainer>
            <button
              type="submit"
              className="btn btn-sm sms-info-btn small-btn px-3"
              disabled={isSubmittingCopayment}
            >
              {isSubmittingCopayment ? "Saving..." : "Save"}
            </button>
          </RightAlignedContainer>
        </form>
      </CustomDialog>

      <CustomDialog
        open={openSplit}
        handleClose={() => {
          setOpenSplit(false);
        }}
        title="Split Invoice"
        maxWidth="lg"
      >
        <SplitInvoice
          {...{
            actions,
            snackbar,
            match,
            setOpenSplit,
            isSubmitted: isSplitSubmitted,
            setIsSubmitted: setIsSplitSubmitted,
          }}
        />
      </CustomDialog>

      <CustomDialog
        title="Incomplete claim"
        open={openCancel}
        handleClose={handleCloseCancel}
      >
        <form onSubmit={handleCancelClaim} autoComplete="off">
          <Form.Group>
            <Label name="Reason" type />
            <ResizableTextarea
              submitted={submitted}
              value={cancelReason}
              rows={2}
              onChange={handleChangeReason}
              placeholder="Write reason..."
            />
            {submitted && !cancelReason ? (
              <ErrorMessage>Reason is required</ErrorMessage>
            ) : null}
            {/*<RightAlignedContainer>*/}
            <button type="submit" className="btn btn-sm sms-btn mt-2">
              {isSubmitting ? "Saving..." : "Save"}
            </button>
            {/*</RightAlignedContainer>*/}
          </Form.Group>
        </form>
      </CustomDialog>
      <MainSnackbar
        variant={type}
        message={message}
        open={openBar}
        handleCloseBar={handleCloseSnackbar}
      />
      {isPending ? <LoadingGif /> : null}
      {isSuccessful ? (
        (claim_status === "0" || claim_status === "1") && isInsurance ? (
          <Container>
            {printSections}
            <div css={[card]} className="mt-2">
              <div className="row">
                <div className="col-lg-4 col-sm-5 pr-0">
                  <div css={[checksDiv]}>
                    <ResultsHeading>
                      <Heading>Invoice approval checks</Heading>
                      <Border />
                    </ResultsHeading>
                    {isLoading ? <LoadingGif /> : null}
                    {isSuccess ? (
                      approvalChecks.length > 0 ? (
                        <>
                          <div className="my-3">
                            {submitted && !checksComplete ? (
                              <Alert
                                severity="error"
                                style={{
                                  paddingTop: 0,
                                  paddingBottom: 0,
                                  marginBottom: "8px",
                                }}
                              >
                                Please review all checks and mark them before
                                continuing
                              </Alert>
                            ) : null}
                            <RightAlignedContainer>
                              <Form.Check
                                checked={checkAll}
                                style={{ color: "#3f51b5", fontWeight: 700 }}
                                className="form__label mb-sm-2"
                                onChange={handleCheckAll}
                                label="Select all checks"
                              />
                            </RightAlignedContainer>
                            {approvalChecks.length > 0
                              ? approvalChecks.map((item, index) => {
                                  return (
                                    <Form.Check
                                      key={item.claim_check}
                                      type="checkbox"
                                      data-testid={`approval_check-${index}`}
                                      label={item.name}
                                      checked={item.is_checked}
                                      className="form__label mb-sm-2"
                                      name="is_checked"
                                      onChange={(e) =>
                                        handleChangeCheck(e, item.id)
                                      }
                                    />
                                  );
                                })
                              : null}
                          </div>
                          <button
                            type="button"
                            onClick={handleApproveClaim}
                            className="btn btn-sm sms-info-btn mr-2 px-3"
                          >
                            Save
                          </button>
                        </>
                      ) : (
                        <p style={{ marginTop: "30px" }}>
                          No checks found for this insurance provider
                        </p>
                      )
                    ) : null}
                    {isError ? (
                      <p style={{ marginTop: "30px" }}>
                        There was an error loading checks
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-8 col-sm-7 pl-0">
                  <div
                    style={{
                      borderBottom: "1px solid rgba(224, 224, 224, 1)",
                      padding: "8px 20px 0 20px",
                    }}
                  >
                    <div className="row p-0">
                      <div
                        className="col-lg-6"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <button
                          onClick={() => {
                            setOpenCoPayment(true);
                          }}
                          className="btn btn-sm sms-info-btn small-btn mr-3"
                        >
                          Add co-payment
                        </button>
                        <button
                          onClick={() => {
                            setOpenSplit(true);
                          }}
                          className="btn btn-sm sms-btn small-btn"
                        >
                          Split invoice
                        </button>
                      </div>
                      <div className="col-lg-6">
                        <RightAlignedContainer>
                          <CurrencyToggle
                            options={currencies}
                            value={currency}
                            handleChangeCurrency={handleChangeCurrency}
                          />
                          {!isBaseCurrency ? (
                            <FxRate
                              currency={selectedCurrency?.currency_symbol}
                              value={currencyExchangeRate}
                              handleChangeRate={handleChangeExchangeRate}
                              nonBaseCurrency={currency_symbol}
                            />
                          ) : null}
                        </RightAlignedContainer>
                      </div>
                    </div>
                  </div>
                  {splitComponent}

                  <div
                    ref={componentRef}
                    style={{ padding: "20px" }}
                    className="requisition-receipt"
                  >
                    <style>{fontSize()}</style>
                    <PrintTemplate
                      tableHeading={printTable}
                      title="INVOICE"
                      {...{
                        patientDetails,
                        itemDetails,
                        bioBordered: borderless,
                        bioMargin: tableMargin,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Container>
        ) : (
          <MiniCenteredDiv>
            {printSections}
            <Card className="mt-2">
              {isInsurance ? (
                <div
                  style={{
                    borderBottom: "1px solid rgba(224, 224, 224, 1)",
                    padding: "8px 20px 0 20px",
                  }}
                >
                  <div className="row p-0">
                    <div
                      className="col-lg-6"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <button
                        onClick={() => {
                          setOpenSplit(true);
                        }}
                        className="btn btn-sm sms-btn small-btn"
                      >
                        Split invoice
                      </button>
                    </div>
                    <div className="col-lg-6">
                      <RightAlignedContainer>
                        <FinishedStatus style={{ marginBottom: "5px" }}>
                          Claim complete
                        </FinishedStatus>
                      </RightAlignedContainer>
                    </div>
                  </div>
                </div>
              ) : null}
              {isPremium ? (
                <div
                  style={{
                    borderBottom: "1px solid rgba(224, 224, 224, 1)",
                    padding: "8px 20px",
                  }}
                >
                  <RightAlignedContainer>
                    <CurrencyToggle
                      options={currencies}
                      value={currency}
                      handleChangeCurrency={handleChangeCurrency}
                    />
                    {!isBaseCurrency ? (
                      <FxRate
                        currency={selectedCurrency?.currency_symbol}
                        value={currencyExchangeRate}
                        handleChangeRate={handleChangeExchangeRate}
                        nonBaseCurrency={currency_symbol}
                      />
                    ) : null}
                  </RightAlignedContainer>
                </div>
              ) : null}
              {isInsurance ? splitComponent : null}
              <div
                ref={componentRef}
                style={{ padding: "20px" }}
                className="requisition-receipt"
              >
                <style>{fontSize()}</style>
                <PrintTemplate
                  tableHeading={printTable}
                  title="INVOICE"
                  {...{
                    patientDetails,
                    itemDetails,
                    bioBordered: borderless,
                    bioMargin: tableMargin,
                  }}
                />
              </div>
            </Card>
          </MiniCenteredDiv>
        )
      ) : null}
      {isRejected ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p>The server did not return a valid response</p>
        </div>
      ) : null}
    </div>
  );
}

function mapStateToProps(state) {
  const { snackbar } = state;
  return { snackbar };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      snackbarActions: bindActionCreators(snackbarActions, dispatch),
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BilledInvoicesReceipt);
