import {useEffect, useState} from "react";
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {logoutErrorMessage} from "../../../../Helpers/ErrorMessages";

export const useInternalPrintableNote = (props) =>{
    const [referral, setReferral] = useState({});
    const {referral_id} = props.match.params;
    const {actions} = props;

    useEffect(() => {
        const formData = new FormData();
        formData.append('patient_referal_id', referral_id);
        axios.post(`${config.smsUrl}/cmedicationround/referral_update_form`, formData).then(res => {
            const data = res.data;
            const all_data = !data ? {} : data;
            setReferral(all_data)
        }).catch(e => {
            logoutErrorMessage(e, null, actions)
        })
    }, []);

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

return {referral, closeSnackbar}
}