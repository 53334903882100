
import { useEffect,  useState } from 'react';
import { errorMessages } from '../../../Helpers/ErrorMessages';
import axios from "axios";
import { config } from "../../../Helpers/env";
import { useGetSurgeryDetails } from './useGetSurgeryDetails';
import { useVisitList } from '../../Visit/NewVisit/hooks/useVisitList';
import { useObservationChart } from '../../MedicationandVisits/CustomHooks/useObservationChart';

const useCompleteSurgery = (actions, patient_number, visit_id, patient_surgery_id, patient_t) => {

    const [patient, setPatient] = useState({});

    const {
        assignSurgeryDetails, patient_type,
        surgery_time,  onChangeSurgeryTime,
        admissionProps, general_notes, handleNotesState,
        operation_class, operationClasses, handleOpClassChange,
        anaesthesia_type, anaesthesiaTypes, handleAnesTypeChange,
        p_number, handleUpdateSurgery, isUpdated,
        surgery_name, handleSurgeryTypeChange, surgeryTypes, 
        handleCloseConfirm, openConfirmationDialog, surgeryConfirmation,
        setConfirmation, surgeon,surgeons, isOpen, handleChangeSurgeon, handleSelectSurgeon,
        other_anaestheists, other_surgeons, isVisitingDoctor, inputDoc,
        handleOtherSurgeons, handleOtherAnaestheists, handleChangeDoctors, handleChangeInputSurgeons,
        submitted, handleCompleteSurgery
    } = useGetSurgeryDetails(actions, patient_t);

    const { handleSubmitTriage, triageProps,  handlePatientDetails, setSubmitTriage, isTriage, handleEditTriage} = useVisitList(actions)

    const {tabular, handleChangeTabular, tableData, graphData, closeSnackbar,
        expanded,handleChange} = useObservationChart(actions, visit_id, isTriage);

    // get all treatment details for that visit
    useEffect(() => {
        const formData1 = new FormData();
        formData1.append('visit_id', visit_id);
        formData1.append('surgery_id', patient_surgery_id)
        
        const formData = new FormData();
        formData.append('patient_number', patient_number);

        const req1 = axios.post(`${config.smsUrl}/surgery/view_surgery_details`, formData1);
        const req2 = axios.post(`${config.smsUrl}/cpatient/patient_update_form`, formData)        

        Promise.all([req1, req2]).then(([res1, res2]) => {
            const data1 = res1.data;
            const data2 = res2.data;
            const details2 = !data2 ? {} : data2;
            setPatient(details2);

            const response = data1 ? data1 : {};
            const diagnosis = response.diagnosis ? response.diagnosis : [];
            const triage = response.triage ? response.triage : [];
            const surgery_data = response.surgery_data ? response.surgery_data : {};

            assignSurgeryDetails({...surgery_data, diagnosis, triage});
            handlePatientDetails({...surgery_data, ...details2, triage})
            
        }).catch(errs => {
            errorMessages(errs, null, actions)
        })
        

    }, [isUpdated]);

    const [openTriageDialog, setOpenTriageDialog] = useState(false);
    const [openDiagnosisDialog, setOpenDiagnosisDialog] = useState(false);

    const handleCloseDialog = (diag) => {
        if (diag === 'Diagnosis') {
            setOpenDiagnosisDialog(false);
        } else if (diag === 'Triage') {
            setOpenTriageDialog(false);
        }
    };

    const handleOpenDialog = (diag) => {
        if (diag === 'Diagnosis') {
            setOpenDiagnosisDialog(true);
        } else if (diag === 'Triage') {
            setOpenTriageDialog(true);
        }
    };

    return {
        patient, openTriageDialog, openDiagnosisDialog, handleCloseDialog, handleOpenDialog,
        surgery_time,  onChangeSurgeryTime, patient_type,
        general_notes, handleNotesState,
        operation_class, operationClasses, handleOpClassChange,
        anaesthesia_type, anaesthesiaTypes, handleAnesTypeChange,
        p_number, handleUpdateSurgery,
        surgery_name, handleSurgeryTypeChange, surgeryTypes, 
        handleCloseConfirm, openConfirmationDialog, surgeryConfirmation,
        setConfirmation, surgeon,surgeons, isOpen, handleChangeSurgeon, handleSelectSurgeon,
        other_anaestheists, other_surgeons, isVisitingDoctor, inputDoc,
        handleOtherSurgeons, handleOtherAnaestheists, handleChangeDoctors, handleChangeInputSurgeons,
        submitted, handleCompleteSurgery, setSubmitTriage,
        handleSubmitTriage, triageProps, tabular, handleChangeTabular, tableData, graphData, closeSnackbar,
        expanded, handleChange, admissionProps, handleEditTriage
    }
}

export default useCompleteSurgery;

