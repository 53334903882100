import TableRow from "@material-ui/core/TableRow";
import {StyledRemoveIcon} from "../../DoctorsModule/doctorStyles";
import React, {useEffect} from "react";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {CustomSelect} from "../../Utils/FormInputs/SelectInput";
import {ErrorMessage, RightAlignedContainer} from "../../Utils/styledComponents";
import axios from "axios";
import {config} from "../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import Form from "react-bootstrap/Form"
import Label from "../../Utils/FormInputs/Label";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TextField from "../../Utils/FormInputs/TextField";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";


export const NewApprovalChecks = (props) =>{
    const {actions, isSubmitted, setIsSubmitted,openDialog, setOpenDialog,approvalCheckId} = props
    const [rows, setRows] = React.useState([])
    const [checkState, setCheckState] = React.useState({check:'', isAnyService:false,
        isAnyPatient:false, isGeneral:false})
    const [typesState, setTypesState] = React.useState({patient_type:[],service_type:[]})
    const [submitted, setSubmitted] = React.useState(false)


    const user = JSON.parse(sessionStorage.getItem("user"));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;

    useEffect(() => {
        if(!approvalCheckId){
            return
        }
        axios.get(`${config.smsUrl}/insurance-claim-approval-checks/${approvalCheckId}`).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const patient_type = dt?.patient_types?.map(item=>({
                value:item,
                label:item === 1 ? 'Out-patient':'In-patient'
            }))

            const service_type = dt?.service_types?.map(item=>({
                value:item,
                label:item
            }))
            setCheckState({check: dt.name, isAnyPatient: dt.any_patient_type, isAnyService: dt.any_service_type,
            isGeneral: dt.any_provider})
            setTypesState({patient_type, service_type})

        }).catch(err => {
            logoutErrorMessage(err,null,actions)
        })
    }, [approvalCheckId]);


    const isPending = isSubmitted === 'pending'


    const handleChangeCheck = (event) =>{
        const {name, value, checked, type} = event.target
        const val = type === 'checkbox' ? checked : value
        setCheckState({...checkState, [name]:val})
        if (name === 'isAnyService'){
            setTypesState({...typesState, service_type: []})
        }
        if (name === 'isAnyPatient'){
            setTypesState({...typesState, patient_type: []})
        }
    }

    const handleChangeSelect = ({value, id}) =>{
        setTypesState({...typesState,[id]:value})
    }


    const isRequiredService = (checkState.isAnyService && typesState?.service_type?.length === 0) ||
        (!checkState.isAnyService && typesState?.service_type?.length > 0)

    const isRequiredPatient = (checkState.isAnyPatient && typesState?.patient_type?.length === 0) ||
        (!checkState.isAnyPatient && typesState?.patient_type?.length > 0)

    const handleAddRow = () =>{
        const obj ={...checkState, ...typesState}
        if (checkState.check && isRequiredService && isRequiredPatient){
            setRows([...rows, obj])
            setCheckState({check:'', isAnyService:false,
                isAnyPatient:false, isGeneral:false})
            setTypesState({patient_type:[],service_type:[]})
            setSubmitted(false)
        }else {
            setSubmitted(true)
        }

    }

    const handleRemoveRow = (index) =>{
        const arr = rows.filter((_, idx) => idx !== index)
        setRows(arr)
    }

    const addChecks = () =>{
        const data = rows.map((item)=>({
            // user_roles_id,
            any_patient_type:item.isAnyPatient,
            any_service_type: item.isAnyService,
            any_provider:item.isGeneral,
            name:item.check,
            patient_types:item?.patient_type?.map(type=>type.value),
            service_types:item.service_type.map(service=>service.value)
        }))
        return axios.post(`${config.smsUrl}/insurance-claim-approval-checks/many`, {data})

    }

    const editChecks = () =>{
        const patient_types = typesState?.patient_type?.map(type=>type.value)
        const service_types = typesState.service_type.map(service=>service.value)

        const params = {
            any_patient_type:checkState.isAnyPatient,
            any_service_type: checkState.isAnyService,
            any_provider:checkState.isGeneral,
            name:checkState.check,
            patient_types,
            service_types
        }
        return axios.put(`${config.smsUrl}/insurance-claim-approval-checks/${approvalCheckId}`, params)
    }

    const handleSubmit = (event) =>{
        event.preventDefault()
        const requiredData = rows.every(item=>item.check && item.patient_type)
        const save = approvalCheckId ? editChecks() : addChecks()
        if (requiredData){
            setIsSubmitted('pending')
            save.then(() => {
                actions.snackbarActions.snackSuccess(`Check(s) ${approvalCheckId ? 'updated':'added'} successfully`);
                setIsSubmitted('resolved');
                setSubmitted(false);
                const removed = rows.map(item=>({
                    ...item,
                    check:'',
                    service_type:[],
                    patient_type:[]
                }))
                setRows(removed)
                setOpenDialog(false)
            }).catch(err => {
                setIsSubmitted('rejected');
                errorMessages(err, null, actions);
            })
        }

    }


    const serviceTypes = [
        {label: 'Procedure', value: 'Procedure'},
        {label: 'Lab', value: 'Lab'},
        {label: 'Radiology', value: 'Radiology'},
        {value:'Dental', label:'Dental'},
        {value:'Surgery', label:'Surgery'},
        {label: 'Ante Natal Care', value: 'Ante Natal Care'}
    ]

    const patientTypes = [
        {label: 'Out-patient', value: 1},
        {label: 'In-patient', value: 2}
    ]
    return (
        <form onSubmit={handleSubmit} autoComplete='off'>
                    <Form.Group>
                        <Label name='Check' type htmlFor="check"/>
                        <TextField type='text'  value={checkState.check} submitted={submitted}
                                   onChange={handleChangeCheck} name="check" id="check"/>
                        {submitted && !checkState.check ? <ErrorMessage>Approval check is required</ErrorMessage>:null}
                    </Form.Group>
                    <Form.Group>
                        <Label name='Service type' type htmlFor="service_type"/>
                        <Row>
                            <Col lg='3'>
                                <Form.Check type='checkbox' label='Any service' checked={checkState.isAnyService}
                                            className="form__label mb-sm-2"  name='isAnyService'
                                            onChange={handleChangeCheck} id="service_type"/>
                            </Col>
                            <Col lg='9'>
                                <CustomSelect id="service_type" onChange={value=>handleChangeSelect({value, id:'service_type'})}
                                               value={typesState.service_type} options={serviceTypes} isMulti isDisabled={checkState.isAnyService}/>
                            </Col>
                        </Row>
                        {submitted && !isRequiredService? <ErrorMessage>Service type is required</ErrorMessage>: null}
                    </Form.Group>
                    <Form.Group>
                        <Label name='Patient type' type htmlFor="patient_type"/>

                        <Row>
                            <Col lg='3'>
                                <Form.Check type='checkbox' label='Any patient' checked={checkState.isAnyPatient}
                                            className="form__label mb-sm-2" name='isAnyPatient'
                                            onChange={handleChangeCheck} id="patient_type"/>
                            </Col>
                            <Col lg='9'>
                                <CustomSelect id="patient_type" onChange={(value)=>handleChangeSelect({value, id:'patient_type'})}
                                              value={typesState.patient_type} options={patientTypes} isMulti isDisabled={checkState.isAnyPatient}/>

                            </Col>
                        </Row>
                        {submitted && !isRequiredPatient? <ErrorMessage>Patient type is required</ErrorMessage>: null}

                    </Form.Group>
                    <Form.Group>
                        <Form.Check label='Is general to all providers' checked={checkState.isGeneral} name='isGeneral'
                                    className="form__label" onChange={handleChangeCheck} id="any_provider"/>
                    </Form.Group>
            {!approvalCheckId ? <button type="button" onClick={handleAddRow} className="btn btn-sm sms-gray-btn my-3"
                            id="add-row">Add another check
                    </button>: null}

                    {rows.length > 0 ? <TableContainer>
                        <Table>
                            <TableBody>
                                {rows?.map((item, index) => {
                                    const p_type = {
                                        1:'Out-patient',
                                        2:'In-patient'
                                    }
                                    const type = item.service_type.length > 0 ? item.service_type.map(item=>item.value).join(', ') : ''
                                    const service_type = item.isAnyService ? 'Any service' : type

                                    const patient = item.patient_type.length > 0 ? item.patient_type.map(item=>p_type[item?.value]).join(', ') : ''
                                    const patient_type = item.isAnyPatient ? 'Any patient' : patient

                                    const is_general = item.isGeneral ? 'Yes' : 'No'
                                    return(
                                        <TableRow
                                            key={index}
                                            style={{borderTop:index === 0 ? '1px solid rgba(224, 224, 224, 1)' : ''}}
                                        >
                                            <TableCell>
                                                Check: <strong>{item.check}</strong><br/>
                                                Service type : <strong>{service_type}</strong>
                                            </TableCell>
                                            <TableCell>
                                                Patient type : <strong>{patient_type}</strong><br/>
                                                General to all providers : <strong>{is_general}</strong>
                                            </TableCell>
                                            <TableCell>
                                                <Tooltip title='Remove'>
                                                    <IconButton onClick={() => handleRemoveRow(index)}>
                                                        <StyledRemoveIcon/>
                                                    </IconButton>
                                                </Tooltip>

                                            </TableCell>
                                        </TableRow>
                                    )})}
                            </TableBody>
                        </Table>
                    </TableContainer>: null}
                    {rows.length > 0 || approvalCheckId ?  <RightAlignedContainer>
                        <button type='submit' className='btn btn-sm sms-btn px-3 mt-3'
                                disabled={isPending}>{isPending ? 'Saving...':'Save'}</button>
                    </RightAlignedContainer>: null}

                </form>
    )
}