import React, {useEffect, useState} from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import * as snackbarActions from "../../../actions/snackbarActions"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {BackButton} from "../../Utils/Buttons/DataExportationButton";
import {Container} from "../../Utils/styledComponents"
import axios from "axios";
import { config } from "../../../Helpers/env";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {CustomTableCell, MuiPaper, StickyHeadTable} from "../../Utils/Tables/StickyHeaderTable";
import {LoadingGif} from "../../Utils/Loader";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClipboardList} from "@fortawesome/free-solid-svg-icons/faClipboardList";
import { CustomTablePagination, EnhancedSearchTableToolbar } from "../../Utils/Tables/CustomTable";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import  Row from "react-bootstrap/Row"
import Grid from "@material-ui/core/Grid";
import Label from "../../Utils/FormInputs/Label";
import TextField from "../../Utils/FormInputs/TextField";
import {dateConvert} from "../../Utils/ConvertDate";
import {ReusableRouteTabs} from "../../Utils/Dialogs/ReusableTabs";
import {history} from "../../../Helpers/history";
import {ReverseDebtorsPayments} from "./ReverseDebtorsPayment";
import {formatDigits} from "../../Utils/formatNumbers";
import {Link} from "react-router-dom";



const headData = [
    {id: 'count', numeric: false, disablePadding: false, label: 'No.'},
    // {id: 'date', numeric: false, disablePadding: false, label: 'Payment Date'},
    // {id: 'invoice_no', numeric: false, disablePadding: false, label: 'Receipt#'},
    {id: 'provider_name', numeric: false, disablePadding: false, label: 'Invoice For'},
    {id: 'paid_amount', numeric: false, disablePadding: false, label: 'Amount Paid'},
    {id: 'action', numeric: false, disablePadding: false, label: 'Action'},

];

const PaidAccountReceivables = ({actions, snackbars, match:{params}}) => {
    const [data, setData] = React.useState({debtors:[], loading:'idle', total_count:0});
    const [state, setState] = React.useState({start_date:dateConvert(), end_date:dateConvert()});
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const [submitted, setSubmitted] = React.useState(false);
    const {debtors, loading, total_count} = data
    const {component} = params
    const isCustomer = component === 'customer';
    const [activePage, setActivePage] = React.useState(1);
    const [term, setTerm] = React.useState('');
    const {start_date, end_date} = state

    const [openDialog, setOpenDialog] = React.useState(false)
    const [isSubmitted, setIsSubmitted] = React.useState('idle')
    const [paymentDetails, setPaymentDetails] = React.useState({})



    const isResolved = isSubmitted === 'resolved'


    const convertArr = (arr=[]) =>{
      return  arr.map(item=>({
            ...item,
          paid_amount:`${item.currency_symbol} ${formatDigits(item.paid_amount)}`,
            action:(
                <button type='button' className=' btn btn-sm sms-info-btn small-button' onClick={()=>{
                    history.push(`/invoicereportdetails/${item.customer_id}/${item.provider_name}/paid`)
                }}>
                   Reverse
                </button>
            )
        }))
    }



    useEffect(() => {
        setData({...data, loading: 'pending'})
        axios.get(`${config.smsUrl}/cbilling/paid_debtors`, {
            params: {
                page:activePage,
                per_page:rowsPerPage,
                search:term,
                // start_date,
                // end_date
            }}).then(res => {
            const response = res.data;
            const arr = response ? response : [];
            const debt_arr = arr.length > 0 ? arr[0].provider_paid : [];
            const count = arr.length > 0 ? arr[0].total_count : 0;
            setData({debtors: convertArr(debt_arr), loading: 'success', total_count:count})
        }).catch(err => {
            errorMessages(err, null, actions);
            setData({...data, loading: 'error'});
        })
    }, [isResolved, activePage, term,rowsPerPage])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setActivePage(newPage+1);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        setActivePage(1);
    };

    const handleChange = (event) =>{
        const {value, name} = event.target;
        setState({...state,[name]:value})
    }

    const closeSnackbar = () =>{
        actions.snackbarActions.hideSnackbar();
    };




    const isLoading = loading === 'pending'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'

    const isPending = isSubmitted === 'pending'

    const {openBar, type, message} = snackbars;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, debtors.length - page * rowsPerPage);

    const handler = (event) => {
        setTerm(event.target.value)
    }

    const pagination = (
        <CustomTablePagination
            colSpan={3}
            activePage={activePage}
            page={page}
            rowsPerPage={rowsPerPage}
            count={total_count}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            isTotalCount
        />
    );

    const search_bar = (
        <EnhancedSearchTableToolbar
            term={term} handler={handler}
        />
    )

    const components = [
        {label: 'Unpaid Invoices', path: '/customerreceivables'},
        {label: 'Paid Invoices', path: '/paidcustomerreceivables'},
    ]

    return (
        <div>
            <PageTitle title="Customer Receivables"/>
            <SubHeader title="Reports" subTitle="Customer Receivables">
                <FontAwesomeIcon icon={faClipboardList}/>
            </SubHeader>
            <Container>
                {!isCustomer ?
                    <div className='mb-3'>
                        <BackButton to='/reports/generalreports' text='General Reports'/>
                    </div>:null}

                {!isCustomer ? <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/>: null}
                <Grid container spacing={0}>
                        <Grid item xs={6} md={4}>
                        </Grid>
                    </Grid>
                <MainSnackbar open={openBar} message={message} variant={type} handleCloseBar={closeSnackbar}/>
                <ReverseDebtorsPayments {...{openDialog, setOpenDialog, actions, isSubmitted, setIsSubmitted, providerDetails: paymentDetails}}/>
                <MuiPaper>
                    {search_bar}
                    <StickyHeadTable {...{width:'100%',data:debtors, columns:headData.filter(item=>Boolean(item)), pagination, search_bar}}>
                        {isLoading ? <TableRow><TableCell align='center' {...{colSpan:headData.length}}><LoadingGif/></TableCell></TableRow>:null}
                        {isSuccess ? debtors.length > 0 ?
                            <>
                                {debtors.slice(0, rowsPerPage).map((row, index) => {
                                    const count = ((activePage - 1) * rowsPerPage) + index + 1;
                                    return (
                                        <TableRow  tabIndex={-1} key={index}>
                                            {headData.map((col)=>(
                                                col.id === 'count' ?  <CustomTableCell>{count}</CustomTableCell>:
                                                    <CustomTableCell>{row[col.id]}</CustomTableCell>
                                            ))}
                                        </TableRow>
                                    );
                                })}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 5 * emptyRows }}>
                                        <TableCell colSpan={headData.length} />
                                    </TableRow>
                                )}
                            </>:<TableRow><TableCell align='center'  {...{colSpan:headData.length}}>No records found</TableCell></TableRow>:null}
                        {isError ? <TableRow><TableCell align='center'  {...{colSpan:headData.length}}>The server did not return a valid response</TableCell></TableRow>:null}
                    </StickyHeadTable>
                </MuiPaper>

            </Container>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaidAccountReceivables);
