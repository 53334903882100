import React from 'react';
import CashCollections from "./CashCollections";
import {ReusableRouteTabs} from "../../Utils/Dialogs/ReusableTabs";
import {history} from "../../../Helpers/history";
import { BackButton } from '../../Utils/Buttons/DataExportationButton';

const  RevenuePerCashier = () =>{
    const headData = [
        {id: 'sl', numeric: false, disablePadding: false, label: 'Sl'},
        {
            id: 'name',
            numeric: false,
            disablePadding: false,
            label: 'Cashier'
        },
        {id: 'out', numeric: false, disablePadding: false, label: 'Out Patient'},
        {id: 'in', numeric: false, disablePadding: false, label: 'In Patient'},
        {id: 'amount', numeric: false, disablePadding: false, label: 'Amount'},
    ];
    const components = [
        {label: 'Sales by Service', path: '/reports/revenuebyprocedure'},
        {label: 'Sales by Product', path: '/accounting/productsales'},
    ]
    return (
        <CashCollections headData={headData} title='Sales by Cashier' component='cashier'
                          urls={{revenue_url:'cashier_collections',data_url:'cashier_collection_time_periods'}}>
             <BackButton to='/reports/generalreports' text='General Reports'/>
            <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/> 
        </CashCollections>
    )
}
export default RevenuePerCashier;