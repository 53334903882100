import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../actions/snackbarActions";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import {formatDateTime} from "../Utils/ConvertDate";
import PageTitle from "../Utils/smsTitle";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserMd} from "@fortawesome/free-solid-svg-icons/faUserMd";
import CustomTable from "../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {useLabScanResults} from "./Lab/hooks/useDoctorLabResults";
import {Link} from "react-router-dom";
import {ReusableRouteTabs} from "../Utils/Dialogs/ReusableTabs";
import {history} from "../../Helpers/history";
import {useRetrievePatientCount} from "./hooks/useRetrievePatientCount";

const headData = [{id: 'sl', numeric: false, disablePadding: false, label: 'SL'},
    {id: 'date', numeric: false, disablePadding: false, label: 'Date'},
    {id: 'patientName', numeric: false, disablePadding: false, label: 'Patient No'},
    {id: 'patientName', numeric: false, disablePadding: false, label: 'Patient Name'},
    {id: 'phone', numeric: false, disablePadding: false, label: 'Phone'},
    {id: 'kin_phone_no', numeric: false, disablePadding: false, label: 'NOK Phone'},
    {id: 'actions', numeric: false, disablePadding: true, label: 'Action'},
];

export const DoctorLabResultList = ({actions, snackbars}) => {
    const {searchValue, pendingRequests, total_count, activePage,
          handleSearch, closeSnackbar, handleChangeActivePage,
          handleRefresh, isLoading, isSuccess, isRejected} = useLabScanResults(actions,1);
    const requests = !pendingRequests ? [] : pendingRequests;
    const {openBar, type, message} = snackbars;
    const {total_patients} = useRetrievePatientCount(actions)

    const components = [{label: 'Waiting List', path: '/waitinglist', badge:true, badge_count:total_patients}, {
        label: 'Lab/Radiology Results',
        path: '/pendingdoctorrequests'
    },{label: 'Patients Seen', path: '/patientsSeen'}];

    return (
        <div data-testid = "doctor-lab-result-list" className='journals'>
            <PageTitle title='Lab/Radiology Results'/>
            <SubHeader title="Doctor" subTitle='Lab/Radiology Results'>
                <FontAwesomeIcon icon={faUserMd}/>
            </SubHeader>
            <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/>
            <MainSnackbar message={message} open={openBar} handleCloseBar={closeSnackbar} variant={type}/>
            <div className="mui-tables">
                <div className="text-right">
                    <button onClick={handleRefresh} className="btn btn-sm sms-info-btn mb-1">Refresh</button>
                </div>
                <CustomTable title='Lab/Radiology Results' term={searchValue}
                             headData={headData} handler={handleSearch}
                              data={requests} colSpan={headData.length}  total_count={total_count} activePage={activePage} records={10}
                             handleChangeNextPage={handleChangeActivePage} customPage>
                        <TableBody>
                            {isLoading ? <TableRow>
                                <TableCell colSpan={headData.length} align="center"><img src="/images/smsloader.gif"
                                                                        alt="loader"/></TableCell>
                            </TableRow>:null}
                            {isSuccess ? requests.length > 0 ? requests.slice(0, 10)
                                .map((item, index) => {
                                    const count = ((activePage - 1) * 10) + index + 1;
                                    const url = item.labrequest_id ? 'doctorlab' : item.scan_request_id ? 'doctorradiology':
                                        (item.labrequest_id && item.scan_request_id) ? 'doctorlab':'previousvisits'
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={index}
                                            className='mui-table-row'
                                        >
                                            <TableCell>{count}</TableCell>
                                            <TableCell>{item.time_requested ? formatDateTime(item.time_requested):''}</TableCell>
                                            <TableCell>{item.patient_number}</TableCell>
                                            <TableCell>{`${!item.first_name ? "" : item.first_name} ${!item.last_name ? "" : item.last_name}`}</TableCell>
                                            <TableCell>{item.phone_no}</TableCell>
                                            <TableCell>{item.kin_phone_no}</TableCell>
                                            <TableCell>
                                                <Link
                                                    to={{pathname:`/${url}/${item.patient_number}/${item.visit_id}/${item.labrequest_id}/${item.scan_request_id}/1/${null}`}}>
                                                <button className="btn btn-sm sms-info-btn">View Results</button>
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                    );
                                }):<TableRow>
                                <TableCell colSpan={headData.length} align="center">No records found</TableCell>
                            </TableRow>:null}
                            {isRejected ? <TableRow>
                                <TableCell colSpan={headData.length} align="center">The server did not return a valid response</TableCell>
                            </TableRow> : null}
                        </TableBody>
                </CustomTable>
            </div>
        </div>
    )
};

function mapStateToProps(state) {
    return {
        snackbars: state.snackbar
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(DoctorLabResultList);
