import React, { version } from "react";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CustomTable, {CustomTablePagination, useHiddenColumns} from "../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import * as snackbarActions from "../../actions/snackbarActions"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import PageTitle from "../Utils/smsTitle";
import {faNotesMedical} from "@fortawesome/free-solid-svg-icons/faNotesMedical";
import {formatDateTime, todayTime} from "../Utils/ConvertDate";
import {history} from "../../Helpers/history";
import { ReusableRouteTabs } from "../Utils/Dialogs/ReusableTabs";
import CustomDialog from "../Utils/Dialogs/CustomDialog";
import {Container} from "../Utils/styledComponents";
import { usePreviousVisits } from "./CustomHooks/usePreviousVisits";
import { ViewPatientVisitHistory } from "../DoctorsModule/PatientPreviousVisits";
import { PatientVisitFeedback } from "./PatientVisitFeedback";
import AddSchedule from "../ShiftsandSchedules/AddSchedule";
import ScheduleFollowUp from "../ShiftsandSchedules/ScheduleFollowUp";
import { useAddSchedule } from "../ShiftsandSchedules/CustomHooks/useAddSchedule";
import { useEditSchedule } from "../ShiftsandSchedules/CustomHooks/useEditSchedule";
import { AccountingDateFilter } from "../Utils/AccountingUtils/AccountingDateFilter";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Label from "../Utils/FormInputs/Label";
import SelectInput, {CustomSelect} from "../Utils/FormInputs/SelectInput";
import {useExcelReports, usePdfDownload} from "../Utils/ReusableComponents/useExcelExport";
import {DataExportButton} from "../Utils/Buttons/DataExportationButton";
import Form from "react-bootstrap/Form";
import { useVersionsContext } from "../../Context/versions-context";


// component for the patient visit schedule
const PatientVisitAddSchedule = ({first_name, last_name, actions, _token, phone_no}) => {

    const { addScheduleProps
    } = useAddSchedule(actions, _token, `${first_name} ${last_name}`, phone_no);

    return (
        <ScheduleFollowUp  { ...addScheduleProps }/>
    )
}

const PatientVisitEditSchedule = ({first_name, last_name, actions, _token}) => {

    const { editScheduleProps
    } = useEditSchedule(actions);

    return (
        <AddSchedule  { ...editScheduleProps }/>
    )
}

const headData = [
    {id: 'count', numeric: false, disablePadding: false, label: 'Sl', hidden:false},
    {id: 'date', numeric: false, disablePadding: false, label: 'Date',hidden:false},
    {id: 'patient_number', numeric: false, disablePadding: false, label: 'Patient No.',hidden:false},
    {id: 'patient_name', numeric: false, disablePadding: false, label: 'Patient Name',hidden:false},
    {id: 'phone_no', numeric: false, disablePadding: false, label: 'Phone Number',hidden:false},
    {id: 'age', numeric: false, disablePadding: false, label: 'Age',hidden:false},
    {id: 'kin_phone_no', numeric: false, disablePadding: false, label: 'Kin Contact',hidden:false},
    {id: 'patient_type', numeric: false, disablePadding: false, label: 'Patient Type',hidden:false},
    {id: 'department_name', numeric: false, disablePadding: false, label: 'Department',hidden:false},
    {id: 'provider_name', numeric: false, disablePadding: false, label: 'Provider Name',hidden:false},
    {id: 'action', numeric: false, disablePadding: true, label: 'Action',hidden:false},
];

const PreviousVisits = ({actions, snackbar, match}) => {
    const {feedback_status} = match.params;
    const {searchValue, activePage, handleSearch, handleChangeNewPage, closeSnackBar, openDialog,handleChange,feedback,
        visitList,  handleOpenDialog, handleCloseDialog, patientDetails, submitted, handleDateFilterSubmit,
        isLoading, isSuccess, isError, _token, feedbackProps, date, handleChangeDate,
        provider, providers, handleChangeProvider, paymentType, paymentTypes, handleChangePaymentType,
        page, rowsPerPage,handleChangePage, handleChangeRowsPerPage, showProviderFilter
    }= usePreviousVisits(actions, feedback_status);

    const {subscription} = useVersionsContext()
     const version = subscription?.version

    const {patient_number, screen, visit_id, first_name, last_name, phone_no} = patientDetails
    const {openBar, type, message} = snackbar;
    const {visits,total_count} = visitList;
    const {from_date, to_date} = date;

    // for the dialogs
    const isViewHistory = screen === 'view_history';
    const isAddFeedback = screen === 'add_feedback';
    const isViewFeedback = screen === 'view_feedback';
    const isScheduleFollowUp = screen === 'schedule_follow_up';
    const isEditFollowUp = screen === 'edit_follow_up';

    const title = isViewHistory ? 'Patient Visit History' 
                    : isAddFeedback ? 'Add Patient Visit Feedback' 
                    : isViewFeedback ? 'View Patient Visit Feedback'
                    : isScheduleFollowUp ? 'Schedule Follow Up'
                    : isEditFollowUp ? 'Edit Schedule Follow Up' : '';

    const components = [
        {label: 'With Feedback', path: `/previous_visits/${true}`},
        {label: 'Without Feedback', path: `/previous_visits/${false}`},
    ]

    const exportUrl = {
        'true':'cvisit/previous_visit_list/excel_report',
        'false':'cvisit/previous_visit_list/excel_report'
    }

    const exportTitle = {
        'true':'With Feedback',
        'false':'Without Feedback'
    }


    const {
        headCells, all_hidden, handleAllHeadCells, handleHeadCells
    } = useHiddenColumns(headData);

    const pagination = (
        <CustomTablePagination
            colSpan={3}
            activePage={activePage}
            page={page}
            rowsPerPage={rowsPerPage}
            count={total_count}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            isTotalCount
        />
    );


    const params = {
        'start_date': from_date,
        'end_date': to_date,
        'feedback_status': feedback_status,
        download:true
    }

    const current_date = todayTime()

    const {exportExcel, isLoading:isLoadingExcel} = useExcelReports(actions,{file_name:`PreviousVisits_${ (feedback_status === true) ? 'With':'Without'} Feedback-${current_date}`,
        url:`cvisit/previous_visit_list/excel_report`, params:{...params,download_type:1}})
    const {exportPdf, isLoading:isLoadingPdf} = usePdfDownload(actions,{file_name:`PreviousVisits_${ (feedback_status === true) ? 'With':'Without'} Feedback-${current_date}`,
        url:`cvisit/previous_visit_list/excel_report`, params:{...params,download_type:2}})


    const all_visits = visits.map((item, index) => {
        const buttons = {
            true: (
                <>
                    <button onClick={() => handleOpenDialog(item, 'view_feedback')}
                            style={{marginBottom: '5px'}}
                            className="btn btn-sm sms-btn mr-1">View Feedback
                    </button>
                    <button onClick={() => handleOpenDialog(item, 'schedule_follow_up')}
                            style={{marginBottom: '5px'}}
                            className="btn btn-sm sms-info-btn mr-1">Schedule Follow
                        Up
                    </button>
                </>

            ),
            false: (
                <button onClick={() => handleOpenDialog(item, 'add_feedback')}
                        style={{marginBottom: '5px'}}
                        className="btn btn-sm sms-info-btn mr-1">Add Feedback</button>
            )
        }

        return {
            ...item,
            count: (activePage - 1) * rowsPerPage + (index + 1),
            date: item.begin_datetime ? formatDateTime(item.begin_datetime) : item.begin_datetime,
            patient_name:`${item.first_name} ${item.last_name}`,
            patient_type:(
                item.patient_type === 1 ? 'Out Patient' : item.patient_type === 2 ? 'In Patient' : 'Emergency Patient'
            ),
            action: (
                <>
                    <button onClick={() => handleOpenDialog(item, 'view_history')}
                            style={{marginBottom: '5px'}}
                            className="btn btn-sm sms-amber-btn mr-1">Visit History
                    </button>
                    {buttons[item.feedback_status]}
                </>

            )
        }
    })


    return (
        <div>
            <PageTitle title="Previous Visits"/>
            <SubHeader title="Previous Visits" subTitle="Visit List">
                <FontAwesomeIcon icon={faNotesMedical}/>
            </SubHeader>

            <CustomDialog title={title} open={openDialog} handleClose={handleCloseDialog} maxWidth="md"  isContentOverflow={false}>
                {isViewHistory ?
                    <>
                        <ViewPatientVisitHistory {...{actions, patient_number, visit_id}}/>
                    </>
                : null}

                {isViewFeedback ?
                    <>
                        <PatientVisitFeedback {...feedbackProps}/>
                    </>
                : null}

                {isAddFeedback ? 
                    <>
                        <PatientVisitFeedback {...feedbackProps}/>
                    </>
                : null}

                {isScheduleFollowUp ?
                    <>
                        <PatientVisitAddSchedule {...{first_name, last_name, actions, _token, phone_no}}/>
                    </>
                : null}

                {isEditFollowUp ?
                    <>
                        <PatientVisitEditSchedule {...{first_name, last_name, actions, _token}}/>
                    </>
                : null}
            </CustomDialog>

            <Container>
                <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <AccountingDateFilter {...{
                        from_date, to_date, handleChange: handleChangeDate,
                        submitted, handleSubmit: handleDateFilterSubmit, isPending: isLoading
                    }} />
                    <DataExportButton exportCsv={exportExcel} isLoading={isLoadingExcel || isLoadingPdf}
                                          downLoadPdf={exportPdf}/>
                </div>

                <div className='row'>
                    {feedback_status === 'true' ?
                        <div className="col-lg-4 pl-0">
                            <Form.Group as={Row}>
                                <Col xs={12} sm={2} md={2} lg={3} xl={2}>
                                    <Label name="Feedback Status" style={{paddingRight: '10px'}}/>
                                </Col>
                                <Col xs={12} sm={8} md={8} lg={8} xl={8}>
                                    <SelectInput value={feedback} onChange={handleChange} name="feedback"
                                                 options={[{value: '0', text: 'All'}, {
                                                     value: '1',
                                                     text: 'Positive'
                                                 }, {value: '2', text: 'Negative'}]}/>
                                </Col>
                            </Form.Group>
                        </div>
                        : null}


                    <div className="col-lg-4 pl-0">
                        <Form.Group as={Row}>
                            <Col sm={2} lg={2}>
                                <Label name='Payment Type'/>
                            </Col>
                            <Col sm={8} lg={8}>
                                <CustomSelect value={paymentType} options={paymentTypes}
                                              onChange={handleChangePaymentType}
                                              isClearable/>
                            </Col>
                        </Form.Group>
                    </div>

                    {showProviderFilter && version === "Premium"   ?
                        <div className="col-lg-4">
                            <Form.Group as={Row}>
                                <Col sm={2} lg={2}>
                                    <Label name='Provider'/>
                                </Col>
                                <Col sm={8} lg={8}>
                                    <CustomSelect value={provider} options={providers}
                                                  onChange={handleChangeProvider}
                                                  isClearable/>
                                </Col>
                            </Form.Group>
                        </div>
                        : null}

                </div>

                <MainSnackbar variant={type} message={message} open={openBar} handleCloseBar={closeSnackBar}/>
                <CustomTable headData={headCells} handler={handleSearch} term={searchValue}
                             data={all_visits} colSpan={headCells.length} customPage records={10}
                             activePage={activePage}
                             handleChangeNextPage={handleChangeNewPage} total_count={total_count}
                             handleHeadCells={handleHeadCells} all_hidden={all_hidden}
                             handleAllHeadCells={handleAllHeadCells} pagination={pagination}>
                    <TableBody>
                        {isLoading ? <TableRow>
                        <TableCell colSpan={headCells.length} align="center"><img src="/images/smsloader.gif"
                                                                                      alt="loader"/></TableCell>
                        </TableRow>:null}
                        {isSuccess ?  all_visits.length > 0 ? all_visits.slice(0, rowsPerPage)
                            .map((item, index) => {
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={item.patient_number}
                                        className='mui-table-row '
                                    >
                                        {headCells.map((head)=>(
                                            <TableCell  key={head.id} hidden={head.hidden}>{item[head.id]}</TableCell>
                                        ))}
                                    </TableRow>
                                );
                            }):<TableRow>
                            <TableCell colSpan={headCells.length} align="center">No records found</TableCell>
                        </TableRow>:null}
                        {isError ? <TableRow>
                            <TableCell colSpan={headCells.length} align="center">The server did not return a valid response</TableCell>
                        </TableRow> : null }
                    </TableBody>
                </CustomTable>
            </Container>
        </div>
    )
};

function mapStateToProps(state) {
    const {snackbar} = state;
    return {snackbar}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(PreviousVisits);
