import {useEffect, useState} from "react";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {history} from "../../../Helpers/history";
import {useAssignRole} from "./useAssignRole";

export const useEditUserRole = (props) =>{
    const [user_name, setUserName] = useState("");
    const [role_name, setRoleName] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [roles, setRoles] = useState([]);
    const [users, setUsers] = useState([]);
    const [userRole,setUserRole] = useState({});
    const {user_role_id} = props.match.params;
    const {actions} = props;

    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const create_by = _token.user_roles_id ? _token.user_roles_id : '';
    const { setEmployee,setEmail, employee_id,employee_email} = useAssignRole(props)


    useEffect(()=>{
        axios.get(`${config.smsUrl}/cuser/manage_user`).then(res => {
            const data = res.data;
            const dt = data ? data : {};
            const user = dt.user_list ? dt.user_list : []
            setUsers(user)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })

        axios.get(`${config.smsUrl}/permission/role_list`).then(res => {
            const data = res.data;
            const dt = data ? data : [];
            const role = dt.user_list ? dt.user_list : []
            setRoles(role)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    },[]);

    useEffect(() => {
        const formData = new FormData();
        formData.append('user_roles_id',user_role_id);
        axios.post(`${config.smsUrl}/permission/user_role_update_form`,formData).then(res=>{
            const data = res.data;
            const dt = data ? data : {};
            const details = dt.usr_roles ? dt.usr_roles : {}
            setUserRole(details)
        }).catch(err=>{
            logoutErrorMessage(err, null, actions)
        });
    },[user_role_id]);

    useEffect(()=>{
        const {first_name, last_name, user_id,role_id,role_name, email} = userRole;
        setEmployee({value:user_id ? user_id : '',
            label:`${first_name ? first_name : ''} ${last_name ? last_name : ''}`});
        setRoleName({value:role_id ? role_id : '', label:role_name ? role_name : ''});
        setEmail(email)
    },[userRole]);

    const handleChangeUsername = (value) => {
        setUserName(value)
    };

    const handleChangeRoleName = (value) =>{
        setRoleName(value)
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('user_roles_id', user_role_id);
        formData.append('role_id', role_name.value);
        formData.append('created_by', create_by);
        setSubmitted(true);
        if (role_name) {
            setIsSubmitted('pending');
            axios.post(`${config.smsUrl}/permission/update_user_role`, formData).then(() => {
                actions.snackbarActions.snackSuccess('User role updated Successfully');
                setIsSubmitted('resolved')
                history.push('/userroles')
            }).catch(err => {
                errorMessages(err, null, actions)
                setIsSubmitted('rejected');
            })
        }
    };

    const handleCloseSnackbar = () => {
        actions.snackbarActions.hideSnackbar();
    };

    return {user_name, role_name, submitted, isSubmitted, roles, users, handleChangeUsername, handleChangeRoleName,
        handleCloseSnackbar, userRole, handleSubmit, employee_id,employee_email}
}