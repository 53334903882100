/** @jsxImportSource @emotion/react */
import {jsx, css} from '@emotion/react'
import React, {useEffect, useState} from 'react'
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PageTitle from "../../Utils/smsTitle";
import { Button, MiniCenteredDiv, RightAlignedContainer } from '../../Utils/styledComponents'
import { PrintTemplate, ItemsTable } from '../../Utils/Templates/PrintTemplate'
import { usePrint } from '../../Utils/Templates/usePrint';
import * as colors from '../../../styles/colors';
import {formatDate} from '../../Utils/ConvertDate';
import {formatDigits} from '../../Utils/formatNumbers';
import Card from '@material-ui/core/Card'
import { BackButton } from '../../Utils/Buttons/DataExportationButton';
import axios from "axios";
import {config} from "../../../Helpers/env";
import {faShippingFast} from "@fortawesome/free-solid-svg-icons/faShippingFast";



const textWeight = css`
    font-weight:600;
`

const tableCell = css`
    border-top:1px solid ${colors.text00};
`

function ReturnReport({match:{params}}) {
    const headData = [{key:'name',value:'Product'},{key:'batch_id',value:'Batch#'}, {key:'quantity',value:'Quantity'}, {key:'rate',value:'Rate'},
        {key:'discount',value:'Discount'},
        {key:'amount',value:'Amount'}]
    const {return_id} = params;
    const [data, setData] = useState([])
    const [total_amount, setTotalAmount] = useState(0)

    useEffect(() => {
        if(!return_id){
            return
        }
        axios.get(`${config.smsUrl}/cinventory/return_details/${return_id}`).then(res => {
            const data = res.data;
            const dt = data ? data : [];
            const sum = dt.map(item=>{
                const qty = item.return_quantity ? item.return_quantity : 0;
                const rate = item.rate ? item.rate : 0
                return {
                    total: qty * rate
                }
            }).reduce((a, item)=> a +  item.total,0)
            setData(dt)
            setTotalAmount(sum)
        }).catch(() => {
        })
    }, [return_id])


    const {componentRef, handlePrint,  fontSize}  = usePrint(`Return Report`)
    const productData = data ? data : []
    const details = productData[0] ? productData[0] : {};
    const return_type = details.return_type === 1 ? 'Return to supplier': details.return_type === 2 ? 'Wastages':
        details.return_type === 2 ? 'Damages' : ''
    const patientDetails = (
        <tbody>
        <tr>
            <td><span>Date: </span> <span css={[textWeight]}>{details.return_date ?  formatDate(details.return_date):''}</span></td>
            <td><span>Invoice#: </span><span><strong>{details.chalan_no ? details.chalan_no : ''}</strong></span></td>
        </tr>
        <tr>
            <td><span>Return Type: </span> <span><strong>{return_type}</strong></span></td>
            <td><span>Supplier: </span> <span><strong>{details.supplier_name ? details.supplier_name : ''}</strong></span></td>
        </tr>
        </tbody>
    )
    const itemDetails = (
        <ItemsTable {...{headData}}>
            <tbody>
            {productData.map((item) => {
                const qty = item.return_quantity ? item.return_quantity : 0;
                const rate = item.rate ? item.rate : 0
                return (
                    <tr key={item.product_id}>
                        <td><span>{item.product_name}</span></td>
                        <td><span>{item.batch_id}</span></td>
                        <td><span>{item.return_quantity}</span></td>
                        <td><span>{formatDigits(item.rate)}</span></td>
                        <td><span>{item.discount}</span></td>
                        <td><span>{formatDigits(qty * rate)}</span></td>
                    </tr>
                )
            })}

            <tr>
                <td  css={[tableCell]}/>
                <td  css={[tableCell]}/>
                <td  css={[tableCell]}/>
                <td  css={[tableCell]}/>
                <td  css={[tableCell]}><span >Total Amount</span></td>
                <td css={[tableCell]}><span css={[textWeight]}>{formatDigits(total_amount)}</span></td>
            </tr>
            {/*<tr>*/}
            {/*    <td/>*/}
            {/*    <td/>*/}
            {/*    <td><span >Total Discount</span></td>*/}
            {/*    <td><span css={[textWeight]}>{formatAmount(0)}</span></td>*/}
            {/*</tr>*/}
            {/*<tr>*/}
            {/*    <td/>*/}
            {/*    <td/>*/}
            {/*    <td><span >Grand Total</span></td>*/}
            {/*    <td><span css={[textWeight]}>{formatAmount(0)}</span></td>*/}
            {/*</tr>*/}
            </tbody>
        </ItemsTable>
    )
    return (
        <div>
            <PageTitle title="Return Report"/>
            <SubHeader title="Billing" subTitle="Return Report">
                <FontAwesomeIcon icon={faShippingFast}/>
            </SubHeader>
            <MiniCenteredDiv>
                <BackButton to='/returns/supplier' text='Return List'/>
                <RightAlignedContainer>
                    <Button variant='orange' onClick={handlePrint}>Print</Button>
                </RightAlignedContainer>
                <Card className='mt-2'>
                    <div ref={componentRef} style={{padding:'60px'}} className="requisition-receipt">
                        <style>
                            {fontSize()}
                        </style>
                        <PrintTemplate title='Return Report' {...{patientDetails,itemDetails}}/>
                    </div>
                </Card>
            </MiniCenteredDiv>
        </div>
    )
}

export default ReturnReport