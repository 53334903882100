import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import { config } from "../../../../Helpers/env";
import {useCommonState} from "../../CustomHooks/useReusableFunctions";


export const useProductSalesReport = (actions, form) => {
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [category, setCategory] = useState('');
    const [data, setData] = useState({});
    const [count, setCount] = useState(0)
    const csvRef = useRef();
    const {closeSnackbar, search_type, text, textType, year, date, handleChangeSearch,
        handleChangeDate, handleChangeYear, handleIncrementYear, inputType,
        setSearchType, activePage, handleChangeActivePage,
        handleCustomSearch, customSearch
    } = useCommonState(actions)
    const {url, id} = form;
    const {start_date, end_date} = date;
    useEffect(() => {
        setLoading(true);
        axios.post(`${config.smsUrl}/creport/${url}`, null, {params: {
            start_date: start_date,
            end_date: end_date,
            date_type: 3,
            page:activePage,
            search: customSearch,
            [id]: category.value ? category.value : '',
        }}).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const count = !dt.total_count ? 0 : dt.total_count;
            setData(dt)
            setCount(count)
            setLoading(false);
        }).catch((err) => {
            logoutErrorMessage(err, null, actions);
            setLoading(false);
        })
    }, [activePage, customSearch, category]);


    const handleSubmit = (event) => {
        event.preventDefault();
        setSubmitted(true);
        if (start_date && end_date) {
            setIsSubmitted('pending')
            setLoading(true)
            axios.post(`${config.smsUrl}/creport/${url}`, null, {params:{
                start_date: start_date,
                end_date: end_date,
                date_type: 3,
                page:activePage,
                [id]: category.value ? category.value : '',
            }}).then(res => {
                const data = res.data;
                const dt = !data ? {} : data;
                const count = !dt.total_count ? 0 : dt.total_count;
                setData(dt)
                setCount(count)
                setLoading(false);
                setIsSubmitted('resolved')
            }).catch(err => {
                setIsSubmitted('rejected')
                setLoading(false)
                errorMessages(err, null, actions);
            })
        }
    }

    const handleChangeCategory = (value) => {
        setCategory(value);
    };

    const downloadCsv = () => {
        csvRef.current.link.click()
    }

    return {closeSnackbar, search_type, handleSubmit, data, count, customSearch, handleChangeActivePage,
        text, textType, year, date, handleChangeSearch, handleCustomSearch,category,activePage,
        handleChangeDate, handleChangeYear, handleIncrementYear, isSubmitted, submitted,
        loading, setSearchType, inputType, csvRef, downloadCsv,  handleChangeCategory
    }
}