import React from "react";
import {titleCase} from "../Users/addUser";
import TextField from "../Utils/FormInputs/TextField";
import Label from "../Utils/FormInputs/Label";
import  {CustomSelect} from "../Utils/FormInputs/SelectInput";
import {useTheme} from "@material-ui/core/styles";
import Form from 'react-bootstrap/Form';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ErrorMessage } from "../Utils/styledComponents";
import {ReusablePhoneInput} from "../Utils/FormInputs/FormGroup"
import {ResizableTextarea} from "../Utils/FormInputs/Textarea";
import InputGroup from 'react-bootstrap/InputGroup';
import { validateChosenDOBDate } from "../Utils/ConvertDate";
import { CustomInput, CustomResizableTextarea} from "../Inventory/CustomInput";


function PatientForm({state, contact, setContact, blood_type, submitted,  formRef,isSubmitted,
    handleChange, handleChangeBloodGroup, handleSavePatient,isAddProvider,providerOptions,handleSelect,handleDOBAgeChange, age_type,
    k_contact,setKinContact,  kinRelation,patientRelation, isDisableAge, handleChangeAgeType, handleProviderToggle,insuranceProvider,
    handleChangeKinRelation, handleChangePatientRelation, children, isRegister=false,providerState,providers,handleChangeProvider,
    otherProviderDetails,isResolved,setIsBlocking,
    chronic_illness, handleMedicalIllness,handleOtherMedicals,other_medicals,handleHasAllergies,allergies,handleChangeAllergies,hasAllergies,
    ...rest
} ) {
    const {first_name, first_name1, last_name, last_name1, age,
        gender, dob, address, address1, email, id_nin} = state;
    // const {providerState,providers,handleChangeProvider} = useRegisterPatient(actions)
    const { account_name, card_number} = providerState
    // const provider = account_name.split('-')[1];
    
    // const { medical_illness, handleMedicalIllness } = first_screen_props;
    const {cardiac_disease, kidney_disease, hypertension, tb,
        sickle_cell_disease, epilepsy, diabetes, other_medical_illness} = chronic_illness;
    
    const isInsurance = otherProviderDetails?.provider_type === 'Insurance Company'
    const relation = patientRelation ?? {};
    const theme = useTheme();
    const them  = theme ? theme : {};
    const palette = them.palette ? them.palette : {};
   
  return (
    <form ref={formRef} autoComplete="off" onSubmit={handleSavePatient}>
        <h6
            className={`visit-heading ${
                palette.type === "dark" && "dark-visit-heading"
            }`}
        >
            {" "}
            <strong>Patient Information</strong>
        </h6>
        <div className="row" id="_patient-info">
            <div className="col-md-6">
                <Form.Group
                    as={Row}
                    // id="patient_first_name"
                >
                    <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                        <Label  name="First Name" htmlFor='patient_first_name' type/>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                    <CustomInput name='first_name' setIsBlocking={setIsBlocking} submitted={submitted} placeholder='Enter patient first name'
                                     type='text' reset={isResolved} inputValue={first_name} isRequired id='patient_first_name'/>
                    </Col>
                </Form.Group>
                <Form.Group
                    as={Row}
                    // id="patient_last_name"
                >
                    <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                        <Label  name="Last Name" htmlFor='patient_last_name' type/>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                    <CustomInput name='last_name' setIsBlocking={setIsBlocking} submitted={submitted} placeholder='Enter patient last name'
                                     type='text' reset={isResolved} inputValue={last_name} isRequired id='patient_last_name'/>
                    </Col>
                </Form.Group>
                <Form.Group
                    as={Row}
                    // id="patient_nin"
                >
                    <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                        <Label  name="NIN/Passport No." htmlFor='patient_nin'/>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                    <CustomInput name='id_nin' setIsBlocking={setIsBlocking}  placeholder='Enter NIN/passport number'
                                     type='text' reset={isResolved} inputValue={id_nin}  id='patient_nin'/>
                    </Col>
                </Form.Group>
                <Form.Group
                    as={Row}
                    // id="patient_email"
                    className="FormField"
                >
                    <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                        <Label  name="Email" htmlFor="patient_email"/>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                    <CustomInput name='email' setIsBlocking={setIsBlocking}  placeholder='Enter patient email'
                                     type='email' reset={isResolved} inputValue={email}  id='patient_email'/>
                    </Col>
                </Form.Group>
                <Form.Group
                    as={Row}
                   // id="formHorizontalName"
                    className="FormField"
                    // id="patient_phone"
                >
                    <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                        <Label  name="Phone No" htmlFor='patient_phone' type/>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                        <ReusablePhoneInput contact={contact} setContact={setContact} id='patient_phone' required submitted={submitted}/>
                    </Col>
                </Form.Group>
            </div>
            <div className="col-md-6">

                <>
                    {submitted && !(dob || age) ?  <div className='text-center mb-2'>
                        <ErrorMessage>*Enter date of birth or age</ErrorMessage>
                    </div> : null}

                    <Form.Group
                        as={Row}
                        // id="formHorizontalName"
                        className="FormField"
                    >
                        <Col xs={12} sm={12} md={12} lg={5} xl={5}>
                            <Label column htmlFor='patient_dob'  name="Date of Birth"/>
                            <TextField
                                type="date"
                                name="dob"
                                value={dob}
                                onChange={handleChange}
                                id="patient_dob"
                                disabled={!isDisableAge}
                            />
                            {(validateChosenDOBDate(dob) || (submitted && validateChosenDOBDate(dob))) ? <ErrorMessage>Birth date shouldn't be greater than today</ErrorMessage>
                            : null}
                        </Col>

                        <Col  xs={12} sm={12} md={12} lg={2} xl={2}>
                            <div className="switch-register-patient">
                                <Form.Check type="switch" id="customswitch" defaultChecked={!isDisableAge} onClick={handleDOBAgeChange} className="toggle-admission-prescription"/>
                            </div>
                        </Col>

                        <Col  xs={12} sm={12} md={12} lg={5} xl={5}>    
                            <Label column  name="Age" htmlFor='patient_age'/>
                            <InputGroup className="mb-3">
                                <TextField
                                    min="0"
                                    type="number"
                                    name="age"
                                    value={age}
                                    onChange={handleChange}
                                    id="patient_age"
                                    disabled={isDisableAge}
                                />
                                {/*<CustomInput name='age' setIsBlocking={setIsBlocking}*/}
                                {/*             type='number' disabled={isDisableAge} reset={isResolved} inputValue={age}  id='patient_age'/>*/}
                                <Form.Control
                                as="select"
                                disabled={isDisableAge}
                                className='form__text_input'
                                style={{height: 'auto'}}
                                onChange={handleChangeAgeType}
                                value={age_type}
                                >
                                <option value="years">Years</option>
                                <option value="months">Months</option>
                                <option value="weeks">Weeks</option>
                                <option value="days">Days</option>
                                </Form.Control>
                            </InputGroup>
                        </Col>
                    </Form.Group>
                </>

                <Form.Group
                    as={Row}
                    // id="patient_gender"
                >
                    <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                        <Label name="Gender" type htmlFor="gender"/>
                    </Col>

                    <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                        <Form.Check
                            inline
                            type="radio"
                            name="gender"
                            className="form__label"
                            label="Female"
                            value="female"
                            checked={gender === "female"}
                            onChange={handleChange}
                            id="female"
                        />

                        <Form.Check
                            inline
                            type="radio"
                            name="gender"
                            className="form__label"
                            label="Male"
                            value="male"
                            checked={gender === "male"}
                            onChange={handleChange}
                            id="male"
                        />
                        {submitted && !gender && (
                            <ErrorMessage>Gender is required</ErrorMessage>
                        )}
                    </Col>
                </Form.Group>
                <Form.Group
                    as={Row}
                    // id="patient_bloodgroup"
                    className="FormField"
                >
                    <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                        <Label  name="Blood Group" htmlFor="patient_bloodgroup"/>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={8} xl={8}>

                        <CustomSelect
                            onChange={handleChangeBloodGroup}
                            value={blood_type}
                            id="patient_bloodgroup"
                            options={[
                                {label: "A+", value: "A+"},
                                {label: "A-", value: "A-"},
                                {label: "B+", value: "B+"},
                                {label: "B-", value: "B-"},
                                {label: "O+", value: "O+"},
                                {label: "O-", value: "O-"},
                                {label: "AB+", value: "AB+"},
                                {label: "AB-", value: "AB-"},
                            ]}
                        />
                    </Col>
                </Form.Group>
                <Form.Group
                    as={Row}
                    // id="patient_address"
                    className="FormField"
                >
                    <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                        <Label  name="Address" htmlFor='patient_address'/>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                    <CustomResizableTextarea name='address' setIsBlocking={setIsBlocking} rows={1} inputValue={address}
                                                 reset={isResolved} id='patient_address'/>
                    </Col>
                </Form.Group>
            </div>
        </div>
        <h6
            className={`visit-heading ${
                palette.type === "dark" && "dark-visit-heading"
            }`}
        >
            <strong>Next of Kin Information</strong>
        </h6>
        <div className="row" id="_patient-kin">
            <div className="col-md-6">
                <Form.Group
                    as={Row}
                    // id="kin_first_name"
                >
                    <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                        <Label  name="First Name" htmlFor='kin_first_name' />
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                    <CustomInput name='kin_first_name' setIsBlocking={setIsBlocking} type='text'  reset={isResolved}
                                     inputValue={first_name1}  id='kin_first_name'/>
                    </Col>
                </Form.Group>
                <Form.Group
                    as={Row}
                    //id="formHorizontalName"
                    id="k_telephone"

                >
                    <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                        <Label name="Telephone" htmlFor='kin_telephone'/>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={8} xl={8}>

                    <ReusablePhoneInput id="kin_telephone" contact={k_contact} setContact={setKinContact}/>
                    </Col>
                </Form.Group>
                <Form.Group
                    as={Row}
                    // id="kinrelationship"
                >
                    <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                        <Label  name="Relationship" htmlFor="kinrelationship" />
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={8} xl={8}>

                        <CustomSelect   onChange={handleChangePatientRelation} value={relation}
                                    options={[{value:'Mother',label:'Mother'},{value:'Father',label:'Father'},
                                        {value:'Wife',label:'Wife'},{value:'Husband',label:'Husband'},
                                        {value:'Sister',label:'Sister'},{value:'Brother',label:'Brother'},
                                        {value:'Spouse',label:'Spouse'},{value:'Guardian',label:'Guardian'},
                                        {value:'Son',label:'Son'}, {value:'Daughter',label:'Daughter'}, {value:'Friend',label:'Friend'},
                                        {value:'Other',label:'Other'}]} id='kin-relationship'/>

                    </Col>
                </Form.Group>
            </div>
            <div className="col-md-6">
                <Form.Group
                    as={Row}
                    // id="kin_last_name"
                >
                    <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                        <Label  name="Last Name" htmlFor='kin_last_name'/>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                    <CustomInput name='kin_last_name' setIsBlocking={setIsBlocking} type='text'  reset={isResolved}
                                     inputValue={last_name1}  id='kin_last_name'/>
                    </Col>
                </Form.Group>

                <Form.Group
                    as={Row}
                    // id="kin_address"
                >
                    <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                        <Label  name="Address" htmlFor='kin_address'/>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={8} xl={8}>

                    <CustomResizableTextarea name='kin_address' setIsBlocking={setIsBlocking} rows={1} inputValue={address1}
                                                 reset={isResolved} id='kin_address'/>
                    </Col>
                </Form.Group>

                {relation.value === 'Other' ? <Form.Group
                    as={Row}
                    // id="kin_other_relationship"
                >
                    <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                        <Label  name="Other(Relationship)" htmlFor="kin_other_relationship"/>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                        <TextField
                            type="text"
                            value={titleCase(kinRelation)}
                            onChange={handleChangeKinRelation}
                            id="kin_other_relationship"
                        />
                    </Col>
                </Form.Group>:null}
            </div>
        </div>

        {/*<h6*/}
        {/*    className={`visit-heading ${*/}
        {/*        palette.type === "dark" && "dark-visit-heading"*/}
        {/*    }`}*/}
        {/*>*/}
        {/*    <strong>Chronic diseases and allergy information</strong>*/}
        {/*</h6>*/}

   
    {/*<h6>Chronical Diseases</h6>*/}
    {/* <Row>*/}
    {/*     <Col  xs={12} sm={12} md={12} lg={6} xl={6}>*/}
    {/*        <div>*/}

    {/*            <Col>*/}
    {/*                <Form.Check type="checkbox" label="Cardiac Disease" checked={cardiac_disease} name="cardiac_disease" onChange={handleMedicalIllness}/>*/}
    {/*            </Col>*/}
    {/*            <Col>*/}
    {/*                <Form.Check type="checkbox" label="Sickle Cell Disease" checked={sickle_cell_disease} name="sickle_cell_disease" onChange={handleMedicalIllness}/>*/}
    {/*            </Col>*/}
    {/*            <Col>*/}
    {/*                <Form.Check type="checkbox" label="Kidney Disease" checked={kidney_disease} name="kidney_disease" onChange={handleMedicalIllness} />*/}
    {/*            </Col>*/}
    {/*            <Col>*/}
    {/*                <Form.Check type="checkbox" label="Epilepsy (seizures)"  checked={epilepsy} name="epilepsy" onChange={handleMedicalIllness}/>*/}
    {/*            </Col>*/}
    {/*            <Col>*/}
    {/*                <Form.Check type="checkbox" label="Hypertension"  checked={hypertension} name="hypertension" onChange={handleMedicalIllness}/>*/}
    {/*            </Col>*/}
    {/*            <Col>*/}
    {/*                <Form.Check type="checkbox" label="Diabetes"  checked={diabetes} name="diabetes" onChange={handleMedicalIllness}/>*/}
    {/*            </Col>*/}
    {/*            <Col>*/}
    {/*                <Form.Check type="checkbox" label="TB"  checked={tb} name="tb" onChange={handleMedicalIllness}/>*/}
    {/*            </Col>*/}
    {/*            <Col>*/}
    {/*                <Label name="Others(Specify)" className="label-admission-prescription"/>*/}
    {/*            </Col>*/}

    {/*        </div>*/}

    {/*         <Col>*/}
    {/*             <CustomResizableTextarea name='chronic_illness' setIsBlocking={setIsBlocking} rows={2} inputValue={chronic_illness.other_chronic_illness}*/}
    {/*                                      reset={isResolved} id='chronic_illness'/>*/}
    {/*             /!*<ResizableTextarea rows={2} value={chronic_illness.other_chronic_illness} onChange={handleOtherMedicals}/>*!/*/}
    {/*         </Col>*/}
    {/*     </Col>*/}

    {/* </Row>*/}
    {/* <p/>    */}
    {/*<h6>Allergies</h6>*/}
    {/*<Label name="Do you have any allergies?" className="label-admission-prescription"/>*/}
    {/*<Col xs={12} sm={12} md={12} lg={8} xl={8}>*/}
    {/*        <Form.Check*/}
    {/*            inline*/}
    {/*            type="radio"*/}
    {/*            name="allergy"*/}
    {/*            className="form__label"*/}
    {/*            label="Yes"*/}
    {/*            value="yes"*/}
    {/*            checked={ hasAllergies === true }*/}
    {/*            onChange={handleHasAllergies}*/}
    {/*        />*/}
    {/*        <Form.Check*/}
    {/*            inline*/}
    {/*            type="radio"*/}
    {/*            name="allergy"*/}
    {/*            className="form__label"*/}
    {/*            label="No"*/}
    {/*            value="no"*/}
    {/*            checked={ hasAllergies === false}*/}
    {/*            onChange={handleHasAllergies}*/}
    {/*        />*/}
    {/*    {*/}
    {/*        hasAllergies?*/}

    {/*                <CustomResizableTextarea name='allergies' setIsBlocking={setIsBlocking} rows={2} inputValue={allergies}*/}
    {/*                                         reset={isResolved} id='allergies'/>*/}

    {/*           :null}*/}
    {/*    </Col>*/}
        <h6
            className={`visit-heading ${
                palette.type === "dark" && "dark-visit-heading"
            }`}
        >
            <strong>Provider Information</strong>
        </h6>


        <Form.Group  className="switch-admission-prescription">

        <Label name="Do you want to add a provider?" className="label-admission-prescription"/>

        <text className="label-admission-prescription">No</text>
        <Form.Check type="switch" id="custom-switch"
                    defaultChecked={isAddProvider} checked={isAddProvider} onChange={handleProviderToggle}
                    className="toggle-admission-prescription toggle-patient-insurance" {...rest}/>
        <text className="label-admission-prescription">Yes</text>
        </Form.Group >
        <div className="row">
            <div className='col-lg-6'>
                {isAddProvider ?
                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                            <Label name="Provider"  htmlFor='patient_insurance_provider' type/>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                            <Form.Control as="select" id="patient_insurance_provider"  value={insuranceProvider} name='insuranceProvider'
                                className={` form__control insurance_select ${palette.type === "dark" && 'form-control-dark'}`}
                                onChange={handleSelect}>
                                    <option value={insuranceProvider} >{insuranceProvider !== '' ? insuranceProvider:' Select....'}</option>
                                    {providers.map((option,index) => (
                                    <option key={index} value={index} id='patient_insurance_provider'{...rest}>
                                        {option.provider_name}
                                    </option>
                                    ))}

                            </Form.Control>

                        </Col>
                    </Form.Group>
                : null}
                {insuranceProvider ?
                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                             <Label name="Name of Employee"  type htmlFor='account_name'/>
                        </Col>
                        
                        <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                            <CustomInput name='account_name' setIsBlocking={setIsBlocking} type='text' isRequired submitted={submitted} reset={isResolved}
                                         inputValue={account_name}  id='account_name'/>
                        </Col>
                    </Form.Group>
                    :null}
                {isInsurance ?
                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                             <Label name="Membership No." type htmlFor='card_number'/>
                        </Col>
                        
                        <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                            <CustomInput name='card_number' setIsBlocking={setIsBlocking} type='text' isRequired submitted={submitted} reset={isResolved}
                                         inputValue={card_number}  id='card_number'/>
                        </Col>
                    </Form.Group> : null}
            </div>
        </div>
        {children}
    </form>
  )
}
export {PatientForm}
