import React from "react";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CustomTable from "../../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import {getComparator, stableSort} from "../../Utils/Tables/sortingFunctions";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import *  as snackbarActions from "../../../actions/snackbarActions";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {faClipboardList} from "@fortawesome/free-solid-svg-icons/faClipboardList";
import {useReusableInventory} from "./CustomHooks/useReusableInventory";
import {convertMonth} from "../../Utils/ConvertDate";
import { exportPDF } from "../../Utils/downloadPDF";
import {BackButton, DataExportButton} from "../../Utils/Buttons/DataExportationButton";
import { formatDate } from "../../Utils/ConvertDate";
import {formatDigits} from "../../Utils/formatNumbers";
import {RightAlignedContainer} from "../../Utils/styledComponents";
import {useExcelReports, usePdfDownload} from "../../Utils/ReusableComponents/useExcelExport";


const headData = [
    {id: 'sn', numeric: false, disablePadding: false, label: 'SN.'},
    {id: 'drug', numeric: false, disablePadding: false, label: 'Product'},
    {id: 'batch_id', numeric: false, disablePadding: false, label: 'Batch#'},
    {id: 'expiryDate', numeric: false, disablePadding: false, label: 'Expiry Date'},
    {id: 'stock', numeric: false, disablePadding: false, label: 'Stock'},
    {id: 'amount', numeric: false, disablePadding: false, label: 'Amount'},
];

const ExpiredProducts = ({actions,snackbars}) => {
    const {data, search_type, year, date,csvRef,downloadCsv,
        searchValue, order, orderBy, loading, total_count,
        handleChangeActivePage, handleCustomSearch, activePage, closeSnackbar,   handleRequestSort,
    } = useReusableInventory(actions, 'expired_drugs')
    const {openBar, type, message} = snackbars;
    const products = data.expired ?? [];
 


    const {exportExcel, isLoading:isLoadingExcel} = useExcelReports(actions,{file_name:`Expired Products`,
        url:`creport/expired_pdf_excel`, params:{download_type:1}})
    const {exportPdf, isLoading:isLoadingPdf} = usePdfDownload(actions,{file_name:`Expired Products`,
        url:`creport/expired_pdf_excel`,params:{download_type:2}})
    return (
        <div className='journals' data-testid="number-of-lab-tests">
            <PageTitle title="Expired Drugs"/>
            <SubHeader title="Reports" subTitle="Expired Drugs">
                <FontAwesomeIcon icon={faClipboardList}/>
            </SubHeader>
            
            <div style={{display:'flex', justifyContent:'space-between', flexWrap:'wrap'}}>
                <div style={{margin: '10px'}}>
                    <BackButton text={'General Reports'} to={'/reports/generalreports'}/>
                </div>
                <div style={{margin: '10px'}}>
                    <DataExportButton downLoadPdf={exportPdf} isLoading={isLoadingExcel || isLoadingPdf} exportCsv={exportExcel}/>
                </div>
            </div>
            <div className="mui-tables mt-2">
                {/*<RightAlignedContainer>*/}
                {/*    <div className='mb-2'>*/}
                {/*        <DataExportButton title='Purchase Report' downLoadPdf={exportPdf}*/}
                {/*                          exportCsv={exportExcel} isLoading={isLoadingExcel || isLoadingPdf}/>*/}
                {/*    </div>*/}

                {/*</RightAlignedContainer>*/}
                <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>
                <CustomTable title="" handleChangeNextPage={handleChangeActivePage} term={searchValue}
                             records={10}
                             headData={headData} handler={handleCustomSearch} total_count={total_count}
                             activePage={activePage}
                             handleRequestSort={handleRequestSort} data={products} colSpan={5} order={order}
                             orderBy={orderBy} customPage>
                    {loading ? <TableBody><TableRow>
                        <TableCell colSpan="8" align="center"><img src="/images/smsloader.gif"
                                                                   alt="loader"/></TableCell>
                    </TableRow></TableBody> : products.length > 0 ?

                        <TableBody>
                            {stableSort(products, getComparator(order, orderBy))
                                .slice(0, 10)
                                .map((item, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={index}
                                            className='mui-table-row'>
                                            <TableCell>{item.sl}</TableCell>
                                            <TableCell>{item.product_name}</TableCell>
                                            <TableCell>{item.batch_id}</TableCell>
                                            <TableCell>{formatDate(item.expiry_date)}</TableCell>
                                            <TableCell>{item.stock_quantity}</TableCell>
                                            <TableCell>{item.amount ? formatDigits(item.amount): item.amount}</TableCell>
                                           
                                        </TableRow>
                                    );
                                })}
                        </TableBody> : <TableBody><TableRow>
                            <TableCell colSpan="8" align="center">No records found</TableCell>
                        </TableRow></TableBody>}
                </CustomTable>

            </div>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpiredProducts);
