import React from "react";
import Form from "react-bootstrap/Form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus} from "@fortawesome/free-solid-svg-icons/faMinus";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as departmentActions from "../../../actions/DoctorsActions/departmentActions";
import * as snackbarActions from "../../../actions/snackbarActions";
import Label from "../../Utils/FormInputs/Label";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {useTheme} from "@material-ui/core/styles";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import Card from "@material-ui/core/Card";
import {faShippingFast} from "@fortawesome/free-solid-svg-icons/faShippingFast";
import {dateStrokeConvert} from "../../Utils/ConvertDate";
import {useEditRequisition} from "./hooks/useEditRequisition";
import PrintDialog from "../../Utils/Dialogs/PrintDialog";
import TextField from "../../Utils/FormInputs/TextField";
import { ErrorMessage } from  "../../Utils/styledComponents";
import { Container } from "../../Utils/styledComponents";
import { AutoCompleteUnControlled } from "../../Utils/FormInputs/AutoCompleteInput";
import { BackButton } from "../../Utils/Buttons/DataExportationButton";
import { LoadingGif } from "../../Utils/Loader";


const EditRequisition = ({actions, snackbars, match}) => {

    const {
        products, state, issued_by,
        openDialog, handleCloseDialog, handleCancel, handleClick, handleChangeItems, removeRow,
        handleSubmit, closeSnackBar, submitted, isPending,
        handleOnClick, current_batches, retrieveBatches, getBatchDetails,
        isLoading, isSuccess, isError
    } = useEditRequisition(actions, match)

    const {date_requested, date_issued, to_department, requested_by} = state;

    const {openBar, type, message} = snackbars;
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;

    const headData = [{name:'Product', isRequired:true},{name:'Batch#', isRequired:true},{name:'Available Stock'},{name:'Requested Quantity'},
    {name:'Quantity', isRequired:true},{name:'Action'}]

    return (
        <div data-testid="edit-requisition">
            <PageTitle title="Inventory"/>
            <SubHeader title="Inventory" subTitle="Add Dispatch">
                <FontAwesomeIcon icon={faShippingFast}/>
            </SubHeader>
            <PrintDialog openDialog={openDialog} handleClose={handleCloseDialog} message="Print Dispatch Note"
                         text="Would you like to print this dispatch note" handleClick={handleClick}
                         handleCancel={handleCancel}/>
            
            <MainSnackbar message={message} variant={type} handleCloseBar={closeSnackBar} open={openBar}/>
            <Container>
                <BackButton text={'Requisition list'} to={'/requisitionlist/1'} />
                <Card className="p-3 general-card">
                    <form onSubmit={handleSubmit} autoComplete="off">
                        <div className="row">
                            <div className="col-lg-6">
                                <Form.Group as={Row}>
                                    <Label name="Date Issued"  sm="4" column/>
                                    <Col sm="6">
                                        <TextField type="date" value={date_issued} disabled={true} name="date"/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Label name="From"  sm="4" column/>
                                    <Col sm="6">
                                        <TextField type="text" name="from" value="Stores" disabled={true}/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Label name="To"  sm="4" column/>
                                    <Col sm="6">
                                        <Form.Control type="text" value={to_department} disabled={true}
                                        className={`form__control ${palette.type === "dark" && 'form-control-dark'}`}
                                        name="received_by"/>
                                    </Col>
                                </Form.Group>
                            </div>

                            <div className='col-lg-6'>
                                <Form.Group as={Row}>
                                    <Label name="Date Requested" sm="4" column/>
                                    <Col sm="6">
                                        <Form.Control type="text"
                                            value={date_requested ? dateStrokeConvert(date_requested) : ''}
                                            disabled={true}
                                            className={`form__control ${palette.type === "dark" && 'form-control-dark'}`}
                                            name="date_issued"/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Label name="Requested By" sm="4" column/>
                                    <Col sm="6">
                                        <Form.Control type="text" value={requested_by} disabled={true}
                                        className={`form__control ${palette.type === "dark" && 'form-control-dark'}`}
                                        name="received_by"/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Label name="Received By" sm="4" column/>
                                    <Col sm="6">
                                        <Form.Control type="text" value={issued_by} disabled={true}
                                        className={`form__control ${palette.type === "dark" && 'form-control-dark'}`}
                                        name="received_by"/>
                                    </Col>
                                </Form.Group>
                            </div>
                        </div>

                        <div className="table-responsive">
                            <table
                                className={`table table-sm table-bordered ${palette.type === 'dark' && 'dark-table dark-table-bordered'}`}>
                                <thead>
                                    {headData.map((item, index)=>(
                                        <th className={palette.type === 'dark' && 'dark-theme-color'} key={index}>{item.name}{item.isRequired ? <span className="help-block">*</span>: null}</th>
                                    ))}
                                   
                                </thead>
                                <tbody>
                                {isLoading ? <tr>
                                    <td colSpan={headData.length} align="center"><LoadingGif/></td>
                                </tr>: null}
                                {isSuccess ? products.length > 0 ?  products.map((item, index) => (
                                    <tr key={index} data-testid="drugs-row">
                                        <td data-testid={`product_name-${index}`}>
                                            {item.product_name}
                                        </td>
                                        <td>
                                            <AutoCompleteUnControlled
                                                options={current_batches}
                                                submitted={submitted}
                                                idx={index}
                                                handleChange={retrieveBatches} 
                                                label_text="label"
                                                onFocus={()=>handleOnClick(index)}
                                                medicine_value={{label:item.batch_id, value: item.batch_id}} 
                                                id="batch_ids"
                                                testId={`batch_id-${index}`}
                                                value_text="value"
                                                errorMsg_Valid = "Please enter a valid batch id"
                                                handleProductData={getBatchDetails} 
                                                placeholder="Type Batch ID" 
                                                errorMsg="Please select a batch ID"
                                            />
                                        </td>
                                        <td>{item.available_quantity}</td>
                                        <td>{item.quantity_requested}</td>
                                        <td>
                                            <TextField submitted={submitted} value={item.quantity} name="quantity"
                                                       onChange={(e) => handleChangeItems(e,index)} type="number"
                                                       data-testid={`quantity-${index}`} placeholder="Input quantity"/>
                                            {(submitted && !item.quantity) ? <ErrorMessage>Quantity is required</ErrorMessage>: null}
                                            {item.quantity > item.available_quantity ? <ErrorMessage>You can only dispatch up to {item.available_quantity} items</ErrorMessage>: null}
                                        </td>
                                        <td>
                                            <button onClick={() => removeRow(index)} type="button"
                                                    className="btn btn-sm sms-btn-dismiss" data-testid={`remove-row-${index}`}>
                                                <FontAwesomeIcon icon={faMinus}/>
                                            </button>
                                        </td>
                                    </tr>
                                )):<tr>
                                    <td colSpan={headData.length} align="center">No records found</td>
                                </tr>:null}
                                {isError ? <tr>
                                        <td colSpan={headData.length} align="center">The server did not return a valid response</td>
                                    </tr>: null}
                                </tbody>
                            </table>
                        </div>
                        <button type="submit" disabled={isPending}
                            id="saveBtn"
                                className="btn sms-btn btn-sm ">{isPending ? "Saving..." : "Save"}</button>
                    </form>
                </Card>
            </Container>
        </div>
    )
}


function mapStateToProps(state) {
    return {
        snackbars: state.snackbar,
        departs: state.departments
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch),
            departmentActions: bindActionCreators(departmentActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditRequisition);
