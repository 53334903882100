import React from 'react';
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form"
import {CSVLink} from "react-csv";
import Row from 'react-bootstrap/Row'
import PurchaseDialog from "../purchase/PurchaseDialog";
import TextField from "../../Utils/FormInputs/TextField";
import  {ResizableTextarea} from '../../Utils/FormInputs/Textarea';
import styled from '@emotion/styled/macro';
import * as mq from '../../../styles/media-queries'
import {RightAlignedContainer} from "../../Utils/styledComponents"
import { ErrorMessage } from '../../Utils/styledComponents';
import Label from '../../Utils/FormInputs/Label';
import SelectInput from '../../Utils/FormInputs/SelectInput';
import { AutoCompleteUnControlled } from '../../Utils/FormInputs/AutoCompleteInput';
import { LoadingGif } from '../../Utils/Loader';
import { CollapsibleList, DataCell, ModuleTable } from '../../Utils/Lists/CollapsibleList';
import { IconButton, TableCell, TableRow, Tooltip } from '@material-ui/core';
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import {AutoCompleteProducts} from "../../Utils/FormInputs/AutoCompleteInput";
import { dateStringConvert } from '../../Utils/ConvertDate';

const headData = [{item:'Batch Number', align:'left',  isRequired:true},{item:'Expiry Date',align: 'center'},
    {item:'Stock', align:'right'},{item:'Physical Stock', align:'center', width:'120'},{item:'Unit Cost', align: 'center'}, {item:'Action', align:'right'}];

const StyledDiv = styled.div({
    [mq.medium]:{
        width:'600px'
    }
})



export const StyledRemoveIcon = styled(RemoveCircleIcon)({
    width:'0.9em',
    height:'0.9em',
    fill:'#c4495c'
})

export const StyledCell= styled(TableCell)({
    padding:'8px'
})

export const StyledInput = styled(TextField)({
    display:'inline',
    borderRadius:'0.7rem'
}, ({width}) => ({width:width}))


const AddInitialStock = ({rows, products,  submitted,  handleChangeQuantity, headData,isPending,
     handleRemoveSpecificRow,handleRetrieveDrugs,handleClickDrug,handleChangeItems,handleAddRow,
                             handleSubmit}) => {

    return (
        <form onSubmit={handleSubmit} autoComplete="off">
            <ModuleTable headData={headData}>
                {rows.map((item, idx) => (
                    <TableRow id="addr1" key={item.uuid}>
                        <StyledCell>
                            <AutoCompleteProducts options={products} submitted={submitted} idx={idx} handleChange={handleRetrieveDrugs} label_text="label"
                                medicine_value={{label:item.product_name, value:item.product_id}} id="drug_name" value_text="value"
                                valid_error_check={item.isError} errorMsg_Valid="Please enter a valid product"
                                handleProductData={handleClickDrug} placeholder="Type Product Name" errorMsg="Enter Product Name"/>
                        </StyledCell>
                        <StyledCell>
                            <TextField type="text"
                                       name="batch_id"
                                       value={item.batch_id}
                                       onChange={(e) => handleChangeItems(e, idx)}
                                       placeholder="Input batch id"
                                       submitted={submitted}
                            />
                            {(submitted && !item.batch_id) ?
                                <ErrorMessage>Please enter the batch id</ErrorMessage> :null}
                        </StyledCell>
                        <StyledCell>
                            <TextField type="date" submitted={submitted}
                                       name="expiry_date" onChange={(e) => handleChangeItems(e, idx)}
                                       value={item.expiry_date}/>
                            {(submitted && !item.expiry_date) &&
                                <ErrorMessage>Expiry date is required</ErrorMessage>}
                        </StyledCell>
                        <StyledCell>
                            <TextField type="number"
                                       name="quantity"
                                       value={item.quantity}
                                       onChange={(e) => handleChangeQuantity(e, idx)}
                                       placeholder="Input quantity"
                                       submitted={submitted}
                            />
                            {(submitted && !item.quantity) ?
                                <ErrorMessage>Please enter the quantity</ErrorMessage> :null}
                        </StyledCell>
                        <StyledCell>
                            <button type="button" className="btn sms-btn-dismiss btn-sm"
                                    onClick={()=>handleRemoveSpecificRow(item.uuid)}
                            >
                                Delete
                            </button>
                        </StyledCell>
                    </TableRow>
                ))}
                <TableRow>
                    <StyledCell colSpan={headData.length}>
                        <button onClick={handleAddRow} type='button' className='btn btn-sm sms-gray-btn'>Add another product</button>
                    </StyledCell>
                </TableRow>
            </ModuleTable>
            <RightAlignedContainer>
                <button type='submit' disabled={isPending} className='btn btn-sm sms-btn mt-3' >{isPending ? 'Submitting....': 'Submit'}</button>
            </RightAlignedContainer>
        </form>
    )
}



const StockAdjustment = ({ purchase_date, submitted,
    allStock,  adjusted_by, handleInput, handleOpenStockDialog,
    state, handleSubmit,   isPending, handleRetrieveDrugs,  product, handleClickDrug,
    handleChange, isLoading, isSuccess,handleRemoveProduct, products,
    handleOpen,retrieveExpiry, handleChangeItem, handleRemoveBatch, isError, reasons, adjustmentUpload  }) => {

    const {reason, description,positive_reason} = state;

    const productLength = products.some(item=>item.batches.length > 0);

    const isPositive = reason.includes('Positive') || reason.includes('Negative')
    const isPositiveAdjustment = reason.toLowerCase().includes('positive')

    const positiveReasons = [{value:'Monthly stock take', text:'Monthly stock take'}]
    return (
    
        <div>
            {adjustmentUpload}
            <RightAlignedContainer>
                <button className="btn btn-sm mb-3 sms-btn mx-3" onClick={handleOpenStockDialog}>Upload Stock CSV</button>
            </RightAlignedContainer>

            <form onSubmit={handleSubmit} autoComplete="off">
                <StyledDiv>
              
                    <Form.Group as={Row}>
                        <Label column sm="3" name='Date' htmlFor='date'/>
                        <Col sm="8">
                            <TextField name="purchase_date" value={purchase_date} type="date" id='date'
                                onChange={handleInput}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Label column sm="3" name=' Adjusted by' htmlFor='adjustedBy'/>
                        <Col sm="8">
                            <TextField value={adjusted_by} type="text" readOnly id='adjustedBy'/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Label type name='Product' column sm='3' htmlFor='product'/>
                        <Col sm='8'>
                            <AutoCompleteUnControlled options={allStock} handleChange={handleRetrieveDrugs} label_text="label" isExpiryLimit={true}
                            medicine_value={{label:product.product_name, value: product.product_id}} testId="product_name" id="product_name" value_text="value"
                            handleProductData={handleClickDrug} placeholder="Type Product Name" errorMsg="Enter Product Name"/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Label column sm="3" name='Reason' htmlFor='reason' type/>
                        <Col sm="8">
                            <SelectInput defaultOption="Select reason..." value={reason} options={reasons} submitted={submitted}
                                         type="text" name="reason" id='reason' onChange={handleChange} errorMsg="Reason is required"/>
                            {/*submitted && !reason ? <ErrorMessage>Reason is required</ErrorMessage>:null*/}
                        </Col>
                    </Form.Group>

                    {isPositive ? <Form.Group as={Row}>
                        <Label column sm="3" name={reason} htmlFor='positive_reason'/>
                        <Col sm="8">
                            <ResizableTextarea value={positive_reason} placeholder={`Write ${reason?.toLowerCase()}...`} name="positive_reason" id='positive_reason'
                                               onChange={handleChange} rows={2} />
                            {/*<SelectInput testId='positive_reason' defaultOption="Select reason..." value={positive_reason} options={positiveReasons}*/}
                            {/*              name="positive_reason" id='positive_reason' onChange={handleChange}/>*/}
                        </Col>
                    </Form.Group>: null}

                    <Form.Group as={Row}>
                        <Label column sm="3" name='Description' htmlFor='description'/>
                        <Col sm="8">
                            <ResizableTextarea value={description} type="text" name="description" id='description'
                                      onChange={handleChange} rows={3}/>
                        </Col>
                    </Form.Group>
                    
                </StyledDiv>
                {isLoading ? <div className='text-center'><LoadingGif/></div> : null}
                    { isSuccess ? productLength ?  products && products.map((item, index)=>{
                        const batches = item.batches ? item.batches : [];
                        return(
                            batches.length > 0 ? 
                            <CollapsibleList key={index} open={item.open} name={item.product_name} handleOpen={()=>handleOpen(index)} button={
                                <>
                                    <Tooltip title='Delete'>
                                        <IconButton onClick={()=>handleRemoveProduct(index)}>
                                            <StyledRemoveIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </>
                            } >
                                    <ModuleTable headData={headData}>
                                        {batches.map((row, idx) => {
                                            const daysLeft = Math.floor((Date.parse(row.expiry_date) - Date.now()) / (24 * 60 * 60 * 1000));
                                            const isPositiveQuantity = isPositiveAdjustment && parseInt(row.physical_stock)  < row.stock
                                            const isNegativeQuantity = !isPositiveAdjustment && parseInt(row.physical_stock) > row.stock
                                            return(
                                                <TableRow
                                                    key={idx}
                                                >
                                                    <DataCell align='left'>
                                                        {
                                                         row.isBatch ? row.batch_id:
                                                             <>
                                                                 <StyledInput submitted={submitted} onBlur={()=>retrieveExpiry(batches,row.batch_id, index, idx)} onChange={e=>handleChangeItem(e, index, idx)} value={row.batch_id} name='batch_id' type='text' width='50%'/>
                                                                 {(submitted && !row.batch_id) &&
                                                                     <div className="invalid-text">Batch id is required</div>}
                                                             </>

                                                        }
                                                    </DataCell>
                                                    <DataCell align='left' width='200'>
                                                        {
                                                            row.isBatch ? dateStringConvert(row.expiry_date):
                                                                <>
                                                                    <StyledInput submitted={submitted} disabled={row.disableExpiry}  onChange={e=>handleChangeItem(e, index, idx)} value={row.expiry_date} type='date' name='expiry_date' width='100%'/>
                                                                    {(submitted && !row.expiry_date) ?
                                                                        <div className="invalid-text">Expiry date is required</div>:
                                                                    item.expiry_limit > daysLeft ?  <div className="invalid-text">This batch is within the product's expiry limit</div>:null}
                                                                </>

                                                        }

                                                    </DataCell>
                                                    <TableCell align='left'>{row.stock}</TableCell>
                                                    <DataCell align='left' width='15%'>
                                                        <StyledInput submitted={submitted && !row.physical_stock} onChange={e=>handleChangeItem(e, index, idx)} value={row.physical_stock}  name='physical_stock' type='number' min="0"/>
                                                        {(submitted &&  !row.physical_stock ) && <ErrorMessage>Physical stock is required</ErrorMessage>}
                                                        {reason && row.physical_stock && isPositiveQuantity ? <ErrorMessage>Physical stock should be more than  stock for a positive adjustment</ErrorMessage>: null}
                                                        {reason && row.physical_stock && isNegativeQuantity ? <ErrorMessage>Physical stock should be less than  stock for a negative adjustment</ErrorMessage>: null}
                                                    </DataCell>
                                                    <DataCell align='left'>
                                                        {
                                                            row.isBatch ?    row.unit_cost:
                                                                <>
                                                                    <StyledInput submitted={submitted} onChange={e=>handleChangeItem(e, index, idx)} value={row.unit_cost} name='unit_cost' type='number' width='40%' min="0"/>
                                                                    {(submitted && !row.unit_cost) &&
                                                                        <div className="invalid-text">Unit cost is required</div>}
                                                                </>

                                                        }

                                                    </DataCell>
                                                    <DataCell align='left'>
                                                        <Tooltip title='Delete'>
                                                            <IconButton onClick={() => handleRemoveBatch(index, idx)}>
                                                                <StyledRemoveIcon/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </DataCell>
                                                </TableRow>
                                            )})}
                                        {/*<TableRow>*/}
                                        {/*    <TableCell colSpan={headData.length}>*/}
                                        {/*        <button type='button' onClick={()=>handleAddBatch(index)} className='btn btn-sm pharmacy-grey-btn'>Add another row</button>*/}
                                        {/*    </TableCell>*/}
                                        {/*</TableRow>*/}
                                    </ModuleTable> 
                            </CollapsibleList>: null
                        )
                    }):  <p  className='text-center mt-2'>There are no batches for this product, please purchase product first</p>:null}
                    {isError ? <div className='text-center'>The server did not return a valid response</div> : null}
                    {productLength ?
                            <div className='text-right'>
                                <button type='submit' disabled={isPending} className='btn btn-sm sms-info-btn' onClick={handleSubmit} >{isPending ? 'Submitting....': 'Submit'}</button>
                            </div> : null
                    }
            </form>
        </div>
    );

}

export  {StockAdjustment,AddInitialStock};