import React, {useState, useEffect, useRef} from 'react';
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCoins} from "@fortawesome/free-solid-svg-icons/faCoins";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import ReactToPrint from "react-to-print";
import Card from "@material-ui/core/Card";
import ReceiptTemplate from "../../Utils/ReceiptTemplate";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {useTheme} from "@material-ui/core/styles";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../../actions/snackbarActions";
import {connect} from "react-redux";
import { dateStringConvert} from "../../Utils/ConvertDate";
import { BackButton } from '../../Utils/Buttons/DataExportationButton';

const CreditorDetails = (props) => {
    const [receipt, setReceipt] = useState({});
    const [services, setServices] = useState([]);
    const [total, setTotal] = useState({total_amount: 0, paid_amount: 0, due_amount: 0});
    const componentRef = useRef();
    const {provider_id, status, receipt_number} = props.match.params;
    const {actions} = props;
    const [cleared_date, setClearedDate] = useState("");

    useEffect(() => {
        const formData = new FormData();
        const numbers = receipt_number.split('-')
        formData.append('provider_id', provider_id);
        numbers.forEach(item=>{
            formData.append('receipt_numbers', item)
        })
        axios.post(`${config.smsUrl}/cpatient/get_debt_payment_details`, formData).then(res => {
            const data = res.data;
            const all_data = !data ? {} : data;
            const info = !all_data.patient_info ? [] : all_data.patient_info;
            const p_info = info[0] ? info[0] : {};
            const arr = !all_data.deposit ? {} : all_data.deposit;
            const invoices = arr.invoices ?? [];
            setClearedDate(arr.date ?? new Date());
            let invoicesArr = [];
            invoices.forEach(item => {
                invoicesArr = [...invoicesArr, ...item]
            })
            const {outstanding_balance, total_paid} = arr;
            const t_amount = invoicesArr.reduce((a, item) => {
                const qty = item.quantity ? item.quantity : 0;
                const rate = item.rate ? item.rate : 0
                return +a + (qty * rate);
            }, 0)
            setServices(invoicesArr);
            setTotal({total_amount: t_amount, paid_amount: total_paid, due_amount: outstanding_balance});
            setReceipt(p_info);
        }).catch(e => {
            logoutErrorMessage(e, null, actions)
        })
    }, []);

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    const {openBar, type, message} = props.snackbars;



    const { due_amount, paid_amount} = total;
    const bedArr = services.filter(item=>item.bed_assignment_id)
    const servicesArr = services.filter(item=>!item.bed_assignment_id)
    const billed_to = services.map(item =>{

        return  item.billed_to
    })

    return (
        <div>
            <PageTitle title="Creditor Details"/>
            <SubHeader title="Billing" subTitle="Creditor Billing Details">
                <FontAwesomeIcon icon={faCoins}/>
            </SubHeader>
        
            <section className="billing-details">
                <MainSnackbar variant={type} handleCloseBar={closeSnackbar} open={openBar} message={message}/>
                <div className="text-right">
                    <ReactToPrint content={() => componentRef.current}
                                  trigger={() => <button className="btn sms-amber-btn mb-2">Print</button>}/>
                </div>
                <div className='mb-3'>
                    <BackButton to='/debtors' text='Debtors List' data-testid="creditor-details-back-button"/>
                </div>
              
                <Card className="p-2 billing-receipt" ref={componentRef} id="prescription-print">
                    <ReceiptTemplate>
                        <table className="table table-sm table-borderless prescription-table">
                            <tr>
                                <td className={palette.type === 'dark' && 'dark-theme-color'}><p>Debt Payment for: <strong>{billed_to[0]}</strong></p></td>
                                    <td className={palette.type === 'dark' && 'dark-theme-color'}><p>
                                    Date Cleared: <strong>{dateStringConvert(cleared_date)}</strong></p></td>
                            </tr>
                        </table>
                        <div
                            className={`bill-patient-details mb-3 ${palette.type === "dark" && "dark-bill-patient-details"}`}
                            id="patient-lab-report"/>
                        <table className="table table-borderless">
                            <tr>
                                <td colSpan="2">
                                    <div className="table-responsive">
                                        {servicesArr.length > 0 ? <table
                                            className="table table-bordered table-sm profile-table bill-receipt-table">
                                            <thead>
                                            <tr>
                                                <td><p><strong>SNo.</strong></p></td>
                                                <td><p><strong>Item(s) Cleared</strong></p></td>
                                                <td><p><strong>Qty</strong></p></td>
                                                <td><p><strong>Price</strong></p></td>
                                                <td><p><strong>Total Amount</strong></p></td>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {servicesArr.map((item, index) => {
                                                const qty = item.quantity ? item.quantity : 0;
                                                const rate = item.rate ? item.rate : 0
                                                return (
                                                    <tr key={item.bill_id}>
                                                        <td><p>{item.invoice_no}</p></td>
                                                        <td>
                                                            <p>{item.service_id ? item.service_name : item.product_id ? item.product_name : ''}</p>
                                                        </td>
                                                        <td><p>{item.quantity}</p></td>
                                                        <td><p>{item.rate}</p></td>
                                                        <td><p>{qty * rate}</p></td>
                                                    </tr>
                                                )
                                            })}

                                            </tbody>
                                        </table>:null}
                                        {bedArr.length > 0 && <div className="table-responsive">
                                            <table
                                                className="table table-bordered table-sm profile-table bill-receipt-table">
                                                <thead>
                                                <tr>
                                                    <td colSpan="9" align="center"><p><strong>Bed Payment</strong></p></td>
                                                </tr>
                                                <tr>
                                                    <td><p><strong>Ward</strong></p></td>
                                                    <td><p><strong>Room</strong></p></td>
                                                    <td><p><strong>Bed NO.</strong></p></td>
                                                    <td><p><strong>Assign Date</strong></p></td>
                                                    <td><p><strong>End Date</strong></p></td>
                                                    <td><p><strong>Total Days</strong></p></td>
                                                    <td><p><strong>Rate</strong></p></td>
                                                    <td><p><strong>Amount</strong></p></td>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {bedArr.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td><p>{item.ward_name}</p></td>
                                                            <td><p>{item.room_name}</p></td>
                                                            <td><p>{item.bed_number}</p></td>
                                                            <td><p>{dateStringConvert(item.assign_date)}</p></td>
                                                            <td>
                                                                <p>{item.end_date ? dateStringConvert(item.end_date) : ''}</p>
                                                            </td>
                                                            <td><p>{item.quantity}</p></td>
                                                            <td><p>{item.rate}</p></td>
                                                            <td><p>{item.rate * item.quantity}</p></td>
                                                        </tr>
                                                    )
                                                })}
                                                </tbody>
                                            </table>
                                        </div>}
                                        <table className='table table-sm table-borderless'>
                                            <tbody>

                                                <tr>
                                                    <td colSpan={4} align='right'><p><strong>Amount Paid :</strong></p></td>
                                                    <td><p style={{marginLeft:'1rem'}}><strong>{paid_amount}</strong></p></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={4} align='right'><p><strong>Outstanding Balance :</strong></p></td>
                                                    <td ><p style={{marginLeft:'1rem'}}><strong>{due_amount}</strong></p></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </td>

                            </tr>
                        </table>
                    </ReceiptTemplate>
                </Card>
            </section>
        </div>
    )
}

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(CreditorDetails);
