import React from "react";
import {DataCell, ModuleTable} from "../../Utils/Lists/CollapsibleList";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {StyledCell, StyledRemoveIcon} from "../doctorStyles";
import { AutoCompleteUnControlled } from "../../Utils/FormInputs/AutoCompleteInput";
import Textarea from "../../Utils/FormInputs/Textarea";
import InputGroup from "react-bootstrap/InputGroup";
import TextField from "../../Utils/FormInputs/TextField";

const headData = [{item:'Test Name',toolTip:'Make imaging request here', isRequired:true},
    {item:'Purpose',toolTip:'Enter purpose of the investigation', isRequired:true},
    {item:'Cost', isRequired:false}, {item:'Action', isRequired:false}]


const NewRadiologyTable = ({scans, getScanTests,scanTests, retrieveScanDetails, handleAddRow, removeRow,
                         handleChangePurpose, submitted,   isEdit=false, isOutPatient=false}) =>{
    const all_scans = scanTests ? scanTests :[]

    return (
        <ModuleTable headData={headData}>
            {scans.map((scan, index) => (
                <TableRow
                    key={scan.uuid}
                >
                    <DataCell variant='200'>
                        {isEdit && scan.scan_requests_detail_id ? scan.scan_name:
                            <>
                                <AutoCompleteUnControlled options={all_scans} submitted={submitted} idx={index} handleChange={getScanTests} label_text="hospital_scan_name"
                                    medicine_value={{label:scan.scan_name, value: scan.hospital_scan_id}} id="scan_name" value_text="hospital_scan_id"
                                    valid_error_check={scan.isError} errorMsg_Valid="Please enter a valid name" testId={`scan_name-${index}`}
                                    handleProductData={retrieveScanDetails} placeholder="Type Test name" errorMsg="Test name is required<"/>
                        </>}
                    </DataCell>
                    <DataCell variant='150'>
                        <Textarea as="textarea" id={`purpose-${index}`}
                                  value={scan.purpose}
                                  onChange={e => handleChangePurpose(e, index)}
                                  name="purpose"/>
                    </DataCell>
                    <DataCell variant='150'>
                        <InputGroup>
                            <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">{scan.currency_symbol}</InputGroup.Text>
                            <TextField  type="number" value={scan.cost} style={{pointerEvents:'none'}}/>
                        </InputGroup>
                    </DataCell>
                    <DataCell>
                        <Tooltip title='Remove'>
                            <IconButton type='button' onClick={() => removeRow(scan.uuid)}>
                                <StyledRemoveIcon/>
                            </IconButton>
                        </Tooltip>
                    </DataCell>
                </TableRow>
            ))}
            {!isOutPatient ? <TableRow>
                <StyledCell colSpan={headData.length}>
                    <button type="button" onClick={handleAddRow} className="btn btn-sm sms-gray-btn"
                            id="add-row">Add another line
                    </button>
                </StyledCell>
            </TableRow>:null}

        </ModuleTable>
    )
}

export {NewRadiologyTable}


