import React, {useEffect, useState} from "react";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CustomTable, {useHiddenColumns} from "../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import {getComparator, stableSort} from "../Utils/Tables/sortingFunctions";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import * as snackbarActions from "../../actions/snackbarActions"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import PageTitle from "../Utils/smsTitle";
import {faBed} from "@fortawesome/free-solid-svg-icons/faBed";
import {formatDateTime} from "../Utils/ConvertDate";
import {Container} from "../Utils/styledComponents";
import { ReusableRouteTabs } from "../Utils/Dialogs/ReusableTabs";
import { history } from "../../Helpers/history";
import CustomDialog from "../Utils/Dialogs/CustomDialog";
import {titleCase} from "../Users/addUser";
import axios from "axios";
import {config} from "../../Helpers/env";
import {logoutErrorMessage} from "../../Helpers/ErrorMessages";
import {EditBedAssignment} from "./EditBedAssignment";


const headData = [{id: 'count', numeric: false, disablePadding: false, label: 'SL', hidden:false},
    {id: 'patient_admission_id', numeric: false, disablePadding: false, label: 'Admission Id',hidden:false},
    {id: 'admitted_by', numeric: false, disablePadding: false, label: 'Admitted By',hidden:false},
    {id: 'provider_name', numeric: false, disablePadding: false, label: 'Provider Name',hidden:false},
    {id: 'patient_number', numeric: false, disablePadding: false, label: 'Patient No.',hidden:false},
    {id: 'patient_name', numeric: false, disablePadding: false, label: 'Patient Name',hidden:false},
    {id: 'dob', numeric: false, disablePadding: false, label: 'D.O.B',hidden:false},
    {id: 'phone_no', numeric: false, disablePadding: false, label: 'Phone',hidden:false},
    {id: 'kin_phone', numeric: false, disablePadding: false, label: 'NOK Phone',hidden:false},
    {id: 'assign_date', numeric: false, disablePadding: false, label: 'Assign Date',hidden:false},
    {id: 'ward_name', numeric: false, disablePadding: false, label: 'Ward',hidden:false},
    {id: 'room_name', numeric: false, disablePadding: false, label: 'Room',hidden:false},
    {id: 'bed_number', numeric: false, disablePadding: false, label: 'Bed NO',hidden:false},
    {id: 'action', numeric: false, disablePadding: true, label: 'Action',hidden:false},
];

const PreviousBedAssignments = ({actions,snackbars}) => {


    const [searchValue, setSearchValue] = useState('');
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('date');
    const [data, setData] = useState({});
    const [loading, setLoading] = useState('idle');
    const [activePage, setActivePage] = useState(1);
    const [admissionDetails, setAdmissionDetails] = useState({});
    const [openDialog, setOpenDialog] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState('idle')


    const isResolved = isSubmitted === 'resolved'

    useEffect(() => {
        setLoading('pending');
        const formData = new FormData();
        const search = searchValue ? titleCase(searchValue) :searchValue
        formData.append('search', search);
        formData.append('status', 0);
        axios.post(`${config.smsUrl}/cbedmanager/bed_assign_list/${activePage}`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            setData(dt)
            setLoading('success');
        }).catch(e => {
            logoutErrorMessage(e, null, actions);
            setLoading('error')
        })
    }, [activePage,  searchValue,isResolved]);

    const handleSearch = (event) => {
        setSearchValue(event.target.value);

    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangeNewPage = (page) => {
        setActivePage(page);
    };

    const handleOpenDialog = (item) =>{
        setOpenDialog(true)
       setAdmissionDetails({...item})
    }

    const handleCloseDialog = () => {
        setOpenDialog(false)
    };



    const closeSnackBar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const isLoading = loading === 'pending';
    const isSuccess = loading === 'success';
    const isError = loading === 'error';


    const {openBar, type, message} = snackbars;

    const patients = !data.bed_assign ? [] : data.bed_assign;
    const count = !data.total_count ? 0 : data.total_count;

    const {
        headCells, all_hidden, handleAllHeadCells, handleHeadCells
    } = useHiddenColumns(headData);

    const admissions = patients.map((item, index)=>{
        const count = ((activePage - 1) * 10) + index + 1;
        return {
            ...item,
            count,
            date:item.admission_date ? formatDateTime(item.admission_date) : '',
            patient_name:`${!item.first_name ? "" : item.first_name} ${!item.last_name ? "" : item.last_name}`,
            admitted_by: `${!item.admitted_by_first_name ? "" : item.admitted_by_first_name} ${!item.admitted_by_last_name ? "" : item.admitted_by_last_name}`,
            assign_date:formatDateTime(item.assign_date),
            action:(
                <button onClick={()=>handleOpenDialog(item)} className="btn btn-sm sms-info-btn mr-1 mb-1">Make corrections</button>

            )
        }
    })

    const components = [{label: 'Admissions without beds', path: '/unassignedbeds'},
        {label: 'Admissions with beds', path: '/bedassignlist'},
        {label: 'Previous bed assignments', path: '/previousbedassignlist'}]

    return (
        <div data-testid="bed-assign-list" className='journals'>
            <PageTitle title="Previous bed assignments"/>
            <SubHeader title="Ward Manager" subTitle="Previous bed assigments">
                <FontAwesomeIcon icon={faBed}/>
            </SubHeader>


            <CustomDialog open={openDialog} handleClose={handleCloseDialog} title="Make Corrections" maxWidth='lg'>
                <EditBedAssignment  {...{setOpenDialog,openDialog, isSubmitted, setIsSubmitted,actions, admissionDetails}}/>
            </CustomDialog>
            <MainSnackbar variant={type} message={message} open={openBar} handleCloseBar={closeSnackBar}/>
            <Container>
                <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/>
                <CustomTable  headData={headCells} handler={handleSearch} term={searchValue}
                              handleRequestSort={handleRequestSort} data={patients} colSpan={headCells.length} order={order}
                              orderBy={orderBy} customPage records={10} activePage={activePage}
                              handleChangeNextPage={handleChangeNewPage} total_count={count}
                              {...{all_hidden, handleAllHeadCells, handleHeadCells}}>
                    <TableBody>
                        {isLoading ? <TableRow>
                            <TableCell colSpan={headCells.length} align="center"><img src="/images/smsloader.gif"
                                                                                      alt="loader"/></TableCell>
                        </TableRow>:null}
                        {isSuccess ? admissions.length > 0 ?  stableSort(admissions, getComparator(order, orderBy))
                            .slice(0, 10)
                            .map((item) => {
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={item.bed_assignment_id}
                                        className='mui-table-row'>
                                        {headCells.map((head)=>(
                                            <TableCell id={head.id}  key={head.id}
                                                       hidden={head.hidden}>{item[head.id]}</TableCell>
                                        ))}
                                    </TableRow>
                                );
                            }):<TableRow>
                            <TableCell colSpan={headCells.length} align="center">No records found</TableCell>
                        </TableRow>:null}
                        {isError ? <TableRow>
                            <TableCell colSpan={headCells.length} align="center">The server did not return a valid response</TableCell>
                        </TableRow>:null}
                    </TableBody>
                </CustomTable>
            </Container>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviousBedAssignments);
