/** @jsxImportSource @emotion/react */
import React, {useRef} from 'react'
import Form from "react-bootstrap/Form";
import Label from "../Utils/FormInputs/Label";
import {CustomInput, CustomResizableTextarea} from "../Inventory/CustomInput";
import styled from "@emotion/styled";
import axios from "axios";
import {config} from "../../Helpers/env";
import {AutoCompleteProducts} from "../Utils/FormInputs/AutoCompleteInput";
import {smallTable, table} from "../../styles/tableStyles";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {StyledRemoveIcon} from "../DoctorsModule/doctorStyles";
import Alert from "@material-ui/lab/Alert";
import {ConfirmationDialog} from "../Utils/ReusableComponents/ConfirmationDialog";


const SubHeading = styled.p({
    fontWeight:600,
    fontSize:'13px',
    margin:'10px 0',
    textDecoration:'underline',
    lineHeight:1
})


const Item  = styled.div({
    border:'1px solid #dddbdb',
    padding:'8px',
    borderRadius:'8px',
    marginTop:'8px'
})
const Span = styled.span({
    display:'inline-block',
    marginRight:'8px',
    padding:'0.25rem 0'
})
const colors = {
    'red':{
        color:'#c4495c'
    },
    'green':{
        color:'#4caf50'
    }
}
const ColoredSpan = styled(Span)({

},({variant})=>colors[variant])

export function DentalDiagnosis(props) {
    const {addedDiseases, disease, diseases, handleAddNew,
        autoSearchDisease, getDiseaseDetails, handleRemove} = props

    return (
        <div>
            <Form.Group>
                <Label name='Diagnosis'/>
                <AutoCompleteProducts  options={diseases} handleChange={autoSearchDisease}
                                       medicine_value={disease}  placeholder="Search for a disease..."
                                       dataTest="disease" testId='disease_name' handleProductData={getDiseaseDetails}
                                       errorMsg='Disease is required' id='disease' value_text='value' label_text='label' />
                {addedDiseases.length > 0 ?  addedDiseases.filter(item=>item.diagnosis).map((item, index)=>{
                    return (
                        <Item key={index}>
                            <div className="table-responsive">
                                <table css={[table]}>
                                    <tbody>
                                    <tr>
                                        {item.isNew && !item.isAddedDiagnosis ?
                                            <td>
                                                <Label name='Disease'/>
                                                <CustomResizableTextarea rows={2} placeholder="Write diagnosis..."
                                                                     name={`new_disease_${index}`} inputValue={item.missing_disease}/>
                                            </td> :<td>
                                            {item.diagnosis}
                                        </td>}
                                        <td>
                                            <Tooltip title='Remove'>
                                                <IconButton type='button' onClick={()=>handleRemove(index)}>
                                                    <StyledRemoveIcon/>
                                                </IconButton>
                                            </Tooltip>
                                        </td>
                                    </tr>
                                    {item.isNew && !item.isAddedDiagnosis ? <tr>
                                        <td  align='right'>
                                            <button type='button' onClick={()=>handleAddNew(index)} className='btn sms-info-btn small-btn mt-2'>Add</button>
                                        </td>
                                    </tr>: null}
                                    </tbody>
                                </table>
                            </div>
                        </Item>
                    )
                }): null}
            </Form.Group>
        </div>
    )
}

function DentalDiagnosisMedication(props) {
    const {prescribedDrugs, product, products, alert, confirmation, autoSearchComplete,
        clickDrug, handleAddItem, handleRemove, diagnosis,treatment_plan,
        addItemsToReceipt, handleCloseConfirmation, handleCancelConfirmation,handleCloseAlert,
        stockAlert, handleCloseStockAlert,submitted} = props
    const {open, isExpired, message} = alert
    const {openAlert, alertMessage, isStockOut} =  stockAlert

    return (
        <div>
            {open && isExpired ? <Alert severity="error" style={{paddingTop:0, paddingBottom:0, marginBottom:'8px'}} onClose={handleCloseAlert}>
                <strong>{message} is expired!</strong> Please choose another drug
            </Alert>: null}

            {openAlert && isStockOut ? <Alert severity="error" style={{paddingTop:0, paddingBottom:0, marginBottom:'8px'}} onClose={handleCloseStockAlert}>
                <strong>{alertMessage} is out of stock!</strong>
            </Alert>: null}

            {openAlert && isStockOut && isExpired ? <Alert severity="error" style={{paddingTop:0, paddingBottom:0, marginBottom:'8px'}}
                                                           onClose={handleCloseStockAlert}>
                <strong>{message} is expired and out of stock!</strong>
            </Alert>: null}


            <ConfirmationDialog open={confirmation} handleClose={handleCancelConfirmation}
                                title='Make cash payment'
                                confirmItems={addItemsToReceipt}
                                removeItems={handleCloseConfirmation} service='product'/>
            {diagnosis}
            <Form.Group>
                <Label name='Treatment plan management' htmlFor="treatment_plan"/>
                <CustomResizableTextarea rows={2} placeholder="Write treatment plan..."
                                         name='treatment_plan' id='treatment_plan' inputValue={treatment_plan}/>
            </Form.Group>
            {/*<Form.Group>*/}
            {/*    <SubHeading>Medication</SubHeading>*/}
            {/*    <AutoCompleteProducts  options={products} handleChange={autoSearchComplete}*/}
            {/*                           medicine_value={product}  placeholder="Select for a drug..."*/}
            {/*                           dataTest="product" testId='product_name' handleProductData={clickDrug}*/}
            {/*                           errorMsg='Product is required' id='product' value_text='value' label_text='label' />*/}
            {/*    {prescribedDrugs.length > 0 ?  prescribedDrugs.filter(item=>!item.isStockOut).map((item, index)=>{*/}
            {/*        return (*/}
            {/*            <Item key={item.product_id}>*/}
            {/*                <div className="table-responsive">*/}
            {/*                    <table css={[table, smallTable]}>*/}
            {/*                        <tbody>*/}
            {/*                        <tr>*/}
            {/*                            <td colSpan={3}>*/}
            {/*                                {item.product_name}<br/>*/}
            {/*                                {item.stock ? <ColoredSpan variant={item.stock > 0 ? 'green':'red'}>Stock: <strong>{item.stock}</strong></ColoredSpan>: null}*/}
            {/*                                {item.rate ? <><Span>Cost: <strong>{item.rate}</strong></Span><br/></>: null}*/}
            {/*                                {item.isAdded ? <>*/}
            {/*                                    {item.quantity_to_bill ? <Span>Quantity to bill: <strong>{item.quantity_to_bill}</strong></Span>: null}*/}
            {/*                                    {item.quantity_prescribed ? <Span>Quantity prescribed: <strong>{item.quantity_prescribed}</strong></Span>: null}*/}
            {/*                                    {item.dosage ? <Span>Dosage: <strong>{item.dosage}</strong></Span> : null}*/}
            {/*                                    {item.frequency ? <Span>Frequency: <strong>{item.frequency}</strong></Span>: null}*/}
            {/*                                    {item.number_of_days ? <Span>Duration: <strong>{item.number_of_days}</strong></Span>: null}*/}
            {/*                                    {item.key_information ? <Span>Instructions: <strong>{item.key_information}</strong></Span>: null}*/}
            {/*                                </>: null}*/}
            {/*                            </td>*/}
            {/*                            {item.isAdded ? <td>*/}
            {/*                                <Tooltip title='Remove'>*/}
            {/*                                    <IconButton type='button' onClick={()=>handleRemove(item)}>*/}
            {/*                                        <StyledRemoveIcon/>*/}
            {/*                                    </IconButton>*/}
            {/*                                </Tooltip>*/}
            {/*                            </td> :  null}*/}
            {/*                        </tr>*/}
            {/*                        /!*{item.isRequired ? <tr>*!/*/}
            {/*                        /!*    <td colSpan={5}>*!/*/}
            {/*                        /!*        <Alert severity="error" style={{paddingTop:0, paddingBottom:0, marginBottom:'8px'}}*!/*/}
            {/*                        /!*               onClose={handleCloseAlert}>*!/*/}
            {/*                        /!*            Quantity prescribed is required*!/*/}
            {/*                        /!*        </Alert>*!/*/}
            {/*                        /!*    </td>*!/*/}
            {/*                        /!*   </tr>: null}*!/*/}
            {/*                        {!item.isAdded || item.isApi ?*/}
            {/*                            <>*/}
            {/*                                <tr>*/}
            {/*                                <td>*/}
            {/*                                        <Label name='Qty to bill' htmlFor={`quantity_to_bill_${item['product_id']}`}/>*/}
            {/*                                        <CustomInput name={`quantity_to_bill_${item['product_id']}`} id={`quantity_to_bill_${item['product_id']}`}*/}
            {/*                                                     type='number' inputValue={item.quantity_to_bill} submitted={item.isRequired} disabled={!(item.is_provided && item.pack_unit)}/>*/}
            {/*                                    </td>*/}
            {/*                                    <td>*/}
            {/*                                        <Label name='Qty prescribed' htmlFor={`quantity_prescribed_${item['product_id']}`}/>*/}
            {/*                                        <CustomInput name={`quantity_prescribed_${item['product_id']}`} id={`quantity_prescribed_${item['product_id']}`}*/}
            {/*                                                     type='number' inputValue={item.previous_quantity} submitted={item.isRequired}/>*/}
            {/*                                    </td>*/}
            {/*                                    <td>*/}
            {/*                                        <Label name='Dose' htmlFor={`dosage_${item['product_id']}`}/>*/}
            {/*                                        <CustomInput name={`dosage_${item['product_id']}`} id={`dosage_${item['product_id']}`} type='text'*/}
            {/*                                                     inputValue={item.dosage}/>*/}
            {/*                                    </td>*/}
            {/*                                   */}
            {/*                                    <td rowSpan={5}>*/}
            {/*                                        <Tooltip title='Remove'>*/}
            {/*                                            <IconButton type='button' onClick={()=>handleRemove(item)}>*/}
            {/*                                                <StyledRemoveIcon/>*/}
            {/*                                            </IconButton>*/}
            {/*                                        </Tooltip>*/}
            {/*                                    </td>*/}
            {/*                                </tr>*/}
            {/*                                <tr>*/}
            {/*                                <td>*/}
            {/*                                        <Label name='Frequency' htmlFor={`frequency_${item['product_id']}`}/>*/}
            {/*                                        <CustomInput name={`frequency_${item['product_id']}`} id={`frequency_${item['product_id']}`} type='text'*/}
            {/*                                                     inputValue={item.frequency}/>*/}
            {/*                                    </td>*/}
            {/*                                    <td>*/}
            {/*                                        <Label name='Duration' htmlFor={`number_of_days_${item['product_id']}`}/>*/}
            {/*                                        <CustomInput name={`number_of_days_${item['product_id']}`} id={`number_of_days_${item['product_id']}`} type='text' inputValue={item.number_of_days}/>*/}
            {/*                                    </td>*/}
            {/*                                    <td colSpan={2}>*/}
            {/*                                        <Label name='Instructions' htmlFor={`key_information_${item['product_id']}`}/>*/}
            {/*                                        <CustomResizableTextarea rows={1} name={`key_information_${item['product_id']}`}*/}
            {/*                                             id={`key_information_${item['product_id']}`}  inputValue={item.key_information}/>*/}
            {/*                                    </td>*/}
            {/*                                </tr>*/}
            {/*                                {!item.isApi ? <tr>*/}
            {/*                                    <td colSpan={3} align='right'>*/}
            {/*                                        <button type='button' onClick={()=>handleAddItem(item)} className='btn sms-info-btn small-btn'>Add</button>*/}
            {/*                                    </td>*/}
            {/*                                </tr>: null}*/}
            {/*                            </>:null}*/}
            {/*                        </tbody>*/}
            {/*                    </table>*/}
            {/*                </div>*/}
            {/*            </Item>*/}
            {/*        )*/}
            {/*    }): null}*/}
            {/*</Form.Group>*/}
        </div>
    )
}
export default DentalDiagnosisMedication