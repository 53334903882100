import React from "react";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CustomTable from "../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import {getComparator, stableSort} from "../Utils/Tables/sortingFunctions";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {Link} from "react-router-dom";
import * as snackbarActions from "../../actions/snackbarActions"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import PageTitle from "../Utils/smsTitle";
import DeleteDialog from "../Utils/Dialogs/DeleteDialog";
import {faBed} from "@fortawesome/free-solid-svg-icons/faBed";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {useBedTransferList} from "./CustomHooks/useBedTransferList";
import {dateStringConvert} from "../Utils/ConvertDate";
import BedTransfer from './BedTransfer'
import CustomDialog from '../Utils/Dialogs/CustomDialog';
import {ConfirmationDialog} from "../Utils/ReusableComponents/ConfirmationDialog";

const headData = [{id: 'sl', numeric: false, disablePadding: false, label: 'SL'},
    {id: 'date', numeric: false, disablePadding: false, label: 'Transfer Date'},
    {id: 'patientNo', numeric: false, disablePadding: false, label: 'Admission ID'},
    {id: 'patientName', numeric: false, disablePadding: false, label: 'Patient Name'},
    {id: 'ward', numeric: false, disablePadding: false, label: 'Ward'},
    {id: 'roomName', numeric: false, disablePadding: false, label: 'Room'},
    {id: 'bed', numeric: false, disablePadding: false, label: 'Bed Number'},
    {id: 'patient', numeric: false, disablePadding: false, label: 'Reason'},
    {id: 'actions', numeric: false, disablePadding: true, label: 'Action'},
];

export const BedTransferList = (props) => {

    const {
        searchValue, order, orderBy, openDelete, transferList, loading, activePage, count,
        handleSearch, handleRequestSort, handleChangeNewPage, handleCloseDelete,
        handleDelete, closeSnackBar,  transferProps} = useBedTransferList(props)

    const  {openConfirmation,  handleCloseConfirmation,handleBillRoom,
        admission_no, showNumber, patientNumbers, state, submitted, rooms, wards,
        bedList, ward_name, room_name, bed_name, to_bed, to_ward, to_room,
        handleChangeRoom, handleChangeWard, handleChangeBed, currencyDetails,
        handleChangeToWard, handleChangeToRoom, changeToBed, handleChange, handleChangePatientNumbers,
        handleClickPatientNumber, handleSubmit, handleReset,
        isSubmitted,patient_name, open, handleCloseDialogForm,handleOpenDialogForm,
        } = transferProps
    const {openBar, type, message} = props.snackbars;
    const perm = JSON.parse(sessionStorage.getItem('permission'));
    const permission = !perm ? {} : perm;
    const bed_transfer = !permission.bed_transfer ? {} : permission.bed_transfer;
    const bed_transfer_list = !permission.bed_transfer_list ? {} : permission.bed_transfer_list;
    const patients = !transferList ? [] : transferList;

    return (
        <div data-testid="bed-transfer-list" className='journals'>
            <PageTitle title="Bed Transfer List"/>
            <SubHeader title="Bed Management" subTitle="Bed Transfer list">
                <FontAwesomeIcon icon={faBed}/>
            </SubHeader>
            <ConfirmationDialog open={openConfirmation} handleClose={handleCloseConfirmation}
                                title='Make cash payment'
                                confirmItems={handleBillRoom}
                                removeItems={handleCloseConfirmation} service='room'/>
            <div className='general-ledger-header'>
                <div className="text-right">
                    {(bed_transfer.create || bed_transfer.update || bed_transfer.read || bed_transfer.delete) &&
                    
                        <button className="btn sms-btn btn-sm" onClick={()=>handleOpenDialogForm('')}><FontAwesomeIcon icon={faPlus}/> Bed Transfer</button>
                    }
                    <CustomDialog open={open} handleClose={handleCloseDialogForm} title="Bed Transfer" maxWidth='md'>
                    <BedTransfer state={state} beds={bedList} handleChange={handleChange} submitted={submitted} isSubmitted={isSubmitted}
                         options={wards.map(wd=>({value:wd.ward_id, label:wd.name}))} patientNumbers={patientNumbers} handleClickPatientNumber={handleClickPatientNumber}
                         p_number={admission_no} handleChangePatientNumbers={handleChangePatientNumbers} showNumber={showNumber}
                         changeBed={handleChangeBed} changeRoom={handleChangeRoom} rooms={rooms.map(rm=>({value:rm.room_id, label:rm.name}))} changeWard={handleChangeWard}
                         handleSubmitForm={handleSubmit} bed_number={bed_name} handleResetForm={handleReset} text="Bed transfer" currencyDetails={currencyDetails}
                         title="Bed Transfer" room={room_name} ward={ward_name} to_bed={to_bed} to_ward={to_ward} to_room={to_room}
                         handleChangeToWard={handleChangeToWard} handleChangeToRoom={handleChangeToRoom}
                         handleChangeToBed={changeToBed} patient_name={patient_name}/>
        
                    </CustomDialog>
                  
    
                </div>
            </div>
            <div className="mui-tables">
            <DeleteDialog message="delete" openDialog={openDelete} handleClose={handleCloseDelete}
                              text="bed transfer" title='Delete Bed Transfer'>
                <button className=' btn sms-btn-dismiss btn-sm' onClick={handleDelete}>Delete</button>
                <button className="btn sms-grey-btn btn-sm" onClick={handleCloseDelete}>No</button>    
            </DeleteDialog>
                <MainSnackbar variant={type} message={message} open={openBar} handleCloseBar={closeSnackBar}/>
                <CustomTable title="Bed Transfer List" headData={headData} handler={handleSearch} term={searchValue}
                             handleRequestSort={handleRequestSort} data={patients} colSpan={5} order={order}
                             orderBy={orderBy} customPage records={10} activePage={activePage}
                             handleChangeNextPage={handleChangeNewPage} total_count={count}>
                    {loading ? <TableBody><TableRow>
                        <TableCell colSpan="9" align="center"><img src="/images/smsloader.gif"
                                                                   alt="loader"/></TableCell>
                    </TableRow></TableBody> : patients.length > 0 ?
                        <TableBody>
                            {stableSort(patients, getComparator(order, orderBy))
                                .slice(0, 10)
                                .map((item, index) => {
                                    const cnt = ((activePage - 1) * 10) + index + 1;
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={item.bed_transfer_id}
                                            className='mui-table-row'>
                                            <TableCell>{cnt}</TableCell>
                                            <TableCell>{item.date_transfered ? dateStringConvert(item.date_transfered) : ''}</TableCell>
                                            <TableCell>{item.patient_admission_id}</TableCell>
                                            <TableCell>{`${item.first_name ? item.first_name : ''} ${item.last_name ? item.last_name : ''}`}</TableCell>
                                            <TableCell>{item.ward_name}</TableCell>
                                            <TableCell>{item.room_name}</TableCell>
                                            <TableCell>{item.to_bed_number}</TableCell>
                                            <TableCell>{item.reason}</TableCell>
                                            <TableCell>
                                                {bed_transfer_list.read ?
                                                    <Link to={{pathname: `/viewbedtransfer/${item.bed_transfer_id}`}}>
                                                        <button className="btn btn-sm sms-info-btn mr-1">View</button>
                                                    </Link> : <div/>}
                                                {/*{bed_transfer_list.update ?*/}
                                                {/*    <Link to={{pathname: `/editbedtransfer/${item.bed_transfer_id}`}}>*/}
                                                {/*        <button className="btn btn-sm sms-info-btn mr-1">Edit</button>*/}
                                                {/*    </Link> : <div/>}*/}
                                                {/*{bed_transfer_list.delete ? <button onClick={() => handleOpenDelete(item.bed_transfer_id)}*/}
                                                {/*        className="btn btn-sm sms-btn-dismiss">Delete*/}
                                                {/*</button>:<div/>}*/}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                        : <TableBody><TableRow>
                            <TableCell colSpan="9" align="center">No records found</TableCell>
                        </TableRow></TableBody>}
                </CustomTable>
            </div>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BedTransferList);
