import {useEffect, useRef, useState} from "react";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";


export const useEditCompletedNotes = (itemObj) =>{
    const {actions, treatmentDetails,setTreatmentDetails, visit_id} = itemObj
    const [submitted, setSubmitted] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState('idle')

    const handleEdit = (_id) =>{
        const arr = treatmentDetails?.clinicalNotes?.map(item=>{
            if(item.examination_notes_id === _id){
                return {...item, isEdit:false, displayInput:true}
            }
            return item
        })
        const obj = {...treatmentDetails, clinicalNotes:arr}
        setTreatmentDetails(obj)
    }


    const handleChangeNotes = (event, _id) =>{
        const {name, value} = event.target
        const arr = treatmentDetails?.clinicalNotes?.map(item=>{
            if(item.examination_notes_id === _id){
                return {...item, [name]:value}
            }
            return item
        })
        const obj = {...treatmentDetails, clinicalNotes:arr}
        setTreatmentDetails(obj)
    }

    const handleCancelEdit = (_id) =>{
        const arr = treatmentDetails?.clinicalNotes?.map(item=>{
            if(item.examination_notes_id === _id){
                return {...item, isEdit:true, displayInput:false}
            }
            return item
        })
        const obj = {...treatmentDetails, clinicalNotes:arr}
        setTreatmentDetails(obj)
    }



    const handleUpdateNotes = (event, item) =>{
        event.preventDefault();
        const {notes, examination_notes_id, done_by,physical_examination, past_medical_history, past_obstetric_history,
            family_history,complaints} = item;
        // const id_key = patient_type === '1' ? 'examination_notes_id' : 'round_id'
        setSubmitted(true)
        const jsonData = {examination_notes_id, notes, user_roles_id:done_by, visit_id,
            physical_examination, past_medical_history, past_obstetric_history,
            family_history,complaints, chronic_illness:{},allergies:''}
        if(notes && physical_examination && complaints){
            // const url = patient_type === '1' ? 'cdoctor/update_exam_notes' : 'cmedicationround/update_patient_round'
            setIsSubmitted('pending');
            axios.post(`${config.smsUrl}/cdoctor/update_exam_notes`,jsonData).then(()=>{
                setIsSubmitted('resolved');
                actions.snackbarActions.snackSuccess('Findings edited successfully');
                const arr = treatmentDetails?.clinicalNotes?.map(row=>{
                    if(row.examination_notes_id === examination_notes_id) {
                        return {...item, isEdit:true, open:true}
                    }
                    return item
                })
                const obj = {...treatmentDetails, clinicalNotes:arr}
                setTreatmentDetails(obj)
                setSubmitted(false)
            }).catch(err=>{
                setIsSubmitted('rejected');
                errorMessages(err,null, actions)
            })
        }

    };



    const isPendingEdit = isSubmitted === 'pending'
    const isResolved = isSubmitted === 'resolved'
    return { handleEdit, handleCancelEdit, handleChangeNotes, handleUpdateNotes, submitted,isPendingEdit, isResolved}
}