import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import {DialogActions, DialogContent, DialogTitle} from "../SmsDialog";
import CloseIcon from '@material-ui/icons/Close';
import {CircleButton} from "../styledComponents";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';





export const StyledCloseIcon = styled(CloseIcon)({
  width:'0.8em',
  height:'0.8em'
})



export const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0 }} {...other}>
      {children}
      {onClose ? (
        <CircleButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <StyledCloseIcon/>
        </CircleButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({
      paper: {
          margin: '22px',
          overflow:'initial',

      },
        paperOverflow:{
            margin: '22px',
            overflow:'auto',
            // padding:'20px',
            height: 'inherit'
        },
      content:{
          overflow:'initial',
          [theme.breakpoints.down("sm")]: {
            overflow:'auto',
        },
         
      },
        contentOverflow:{
            overflow:'initial',
        },
      scrollPaper:{
        alignItems:'flex-start'
      },
}));

function CustomDialog({open, handleClose,title, children,maxWidth="sm",isPaperStyle=false,isPrint=false,printSurgery=null,
                          isContentOverflow=true, buttons=null}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const classes = useStyles();
  return (
      <Dialog
        // fullScreen={fullScreen}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth={maxWidth}
        fullWidth={true}
        classes={{
          scrollPaper:classes.scrollPaper, 
          paper: isPaperStyle ?  classes.paperOverflow : classes.paper
        }}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose} isPrint={isPrint} printSurgery={printSurgery}>
          {title}
        </DialogTitle>
        <DialogContent
            // className={isContentOverflow ? classes.content : classes.contentOverflow}
        >
            {children}
        </DialogContent>
          <DialogActions>
              {buttons}
          </DialogActions>
      </Dialog>
    
  );
}
export default CustomDialog;



