import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus} from "@fortawesome/free-solid-svg-icons/faMinus";
import TextField from "../../Utils/FormInputs/TextField";
import { ErrorMessage } from "../../Utils/styledComponents";
import { CustomSelect, ReusabelSelect } from "../../Utils/FormInputs/SelectInput";
import InputGroup from "react-bootstrap/InputGroup";
import {DataCell, ModuleTable} from "../../Utils/Lists/CollapsibleList";
import TableRow from "@material-ui/core/TableRow";
import {StyledCell} from "../../DoctorsModule/doctorStyles";

const headData = [{item:'Service', toolTip:'Enter service name', isRequired:true},
    {item:'Quantity', toolTip:'Enter service quantity', isRequired:true},
    {item:'Cost',  toolTip:'Enter cost'}, {item:'Department'},{item:'Doctor Name'},{item:'Action'}]

export const ProcedureTable = ({procedures, services, submitted, handleAddProcedureRow, handleRemoveProcedureRow,
                                   docs,  handleChangeProcedureService,handleChangeProcedureQuantity,
                                   handleChangeDoctorMultipleProcedures}) => {

    return (
        <div className="table-responsive">
            <ModuleTable headData={headData}>
                {procedures && procedures?.map((procedure, index) => (
                    <TableRow
                        key={procedure.uuid}
                    >
                        <DataCell variant='250'>
                                <ReusabelSelect submitted={submitted}  onChange={(e)=>handleChangeProcedureService(e,index)} id='service'
                                                value={procedure.service} options={services} name='service' defaultOption={'Select'}
                                                data-test="service"/>
                                {(submitted && !procedure.service) &&
                                    <ErrorMessage>Service is required</ErrorMessage>}
                        </DataCell>
                        <DataCell variant='200'>
                            <TextField submitted={submitted}  id='service_quantity'
                                       value={procedure.quantity} name='quantity'
                                       data-test="service_quantity" onChange={e=>handleChangeProcedureQuantity(e, index)}/>
                            {(submitted && procedure.quantity) &&
                                <ErrorMessage>Quantity is required</ErrorMessage>}
                        </DataCell>
                        <DataCell variant='150'>
                            <InputGroup>
                                <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">{procedure.currency_symbol}</InputGroup.Text>
                                <TextField  type="number" value={procedure.cost} style={{pointerEvents:'none'}}/>
                            </InputGroup>
                        </DataCell>
                        <DataCell variant='200'>
                            <TextField submitted={submitted}  id='visit_department'
                                       value={procedure.department_name} name='department_name'
                                       data-test="department_name" disabled={true}/>
                            {(submitted && procedure.department_name) &&
                                <ErrorMessage>Department is required</ErrorMessage>}
                        </DataCell>
                        <DataCell variant='200'>
                            <CustomSelect id='visit_doctor' onChange={(e)=>handleChangeDoctorMultipleProcedures(e, index)} name='doctor_name'
                                          value={procedure.doctor_name} options={docs.map(doctor => ({
                                value: doctor.user_roles_id,
                                label: `${doctor.first_name} ${doctor.last_name}`
                            }))}
                                          data-test="doctor_name"/>
                            {(submitted && procedure.doctor_name) &&
                                <ErrorMessage>Doctor is required</ErrorMessage>}
                        </DataCell>
                        <DataCell>
                            <button onClick={() => handleRemoveProcedureRow(index)} type="button"
                                    className="btn btn-sm sms-btn-dismiss" data-testid="remove-row">
                                <FontAwesomeIcon icon={faMinus}/></button>
                        </DataCell>

                    </TableRow>
                ))}
                <TableRow>
                    <StyledCell colSpan={headData.length}>
                        <button type="button" onClick={handleAddProcedureRow} className="btn btn-sm sms-gray-btn"
                                id="add-row">Add another line</button>
                    </StyledCell>
                </TableRow>
            </ModuleTable>
        </div>
    )
}
