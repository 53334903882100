import React from "react";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCoins} from "@fortawesome/free-solid-svg-icons/faCoins";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PageTitle from "../../Utils/smsTitle";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../../actions/snackbarActions"
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import PrintDialog from "../../Utils/Dialogs/PrintDialog";
import {ReusableBillTable, ProductsTable} from "../utils/ReusableTable";
import TextField from "../../Utils/FormInputs/TextField";
import Textarea from "../../Utils/FormInputs/Textarea"
import {MiniHeader} from "../../Utils/Menu/MiniSubHeader"
import Label from "../../Utils/FormInputs/Label"
import SelectInput from '../../Utils/FormInputs/SelectInput';
import {Container, ErrorMessage, RightAlignedContainer} from "../../Utils/styledComponents"
import { formatDate, formatDateTime } from "../../Utils/ConvertDate";
import { useInPatientInvoice } from "./hooks/useInpatientInvoice";
import CustomDialog from "../../Utils/Dialogs/CustomDialog";
import { NewProvider } from "../customers/NewProvider";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { BackButton } from "../../Utils/Buttons/DataExportationButton";
import InputGroup from "react-bootstrap/InputGroup";
import {BlockNavigationComponent} from "../../Utils/ReusableComponents/BlockNavigationComponent";
import {history} from "../../../Helpers/history";



function InpatientBillingInvoice(props) {
    const {
        billState,
        providers,
        state,
        bedBill,
        services,
        products,
        isSubmitted,
        openPrint,
        submitted,
        handleDeleteServices,
        handleSubmitBill,
        handleCloseReceipt,
        handleClickReceipt,
        closeSnackbar,
        handleChangeProductRate,
        handleChangeServiceRate,
        openProvider,
        handleChange,
        providerProps,
        handleCloseDialog,
        handleOpenDialog,
        handleChangeProductQuantity,
        handleDeleteProduct,
        currencies, currency, handleChangeExchangeRate, handleChangeServiceCurrencyRate,
        exchangeRate,  currencyTotal, handleChangeProductCurrencyRate,
        handleChangeCurrency, totals,isBlocking
    } = useInPatientInvoice(props)
    const {openBar, type, message} = props.snackbars;
    const {patient_number, first_name, last_name, address,  admission_date} = state;
    const {invoice_date,  memo, insurance_provider, account_name, card_number,policy_holder} = billState

    const {status} = props.match.params;
    const title =  {'1':'Create Receipt', '2':'Create Invoice'
}
    const provider = insurance_provider.split('-')[1];
    const isInsurance = provider === 'Insurance Company'

    const {total_amount} = totals

    const isBase = currency.split('-')[1]
    const currency_symbol = currency.split('-')[2]
    const isNotBase = isBase === 'false'


    const unpaid = ["Item", "Quantity",isNotBase ?  `Rate(${currency_symbol})` :  null, "Rate(USD)",
        isNotBase ? `Amount(${currency_symbol})`:null, "Amount(USD)", "Action"]

    const productHeadData = ["Medicine Name", "Dosage", "Frequency", "No. of Days", "Quantity Administered",
        isNotBase ?  `Rate(${currency_symbol})` :  null, "Rate(USD)",
        isNotBase ? `Amount(${currency_symbol})`:null, "Amount(USD)","Action"]

    const bedHeadData = ["Ward", "Room", "Bed No.", "Assign Date",
        "End Date","Days",isNotBase ?  `Rate(${currency_symbol})` :  null,"Rate(USD)",
        "Amount(USD)",isNotBase ? `Amount(${currency_symbol})`:null,]
    
    return (
        <div data-testid="add-bill-component" className='journals'>
            <PageTitle title={title[status]}/>
            <SubHeader title="Billing" subTitle={title[status]}>
                <FontAwesomeIcon icon={faCoins}/>
            </SubHeader>
            <BlockNavigationComponent
                when={isBlocking}
                shouldBlockNavigation={()=>{
                    return isBlocking;
                }}
                navigate={path=>history.push(path)}
            />
                <PrintDialog handleCancel={handleCloseReceipt} openDialog={openPrint} handleClose={handleCloseReceipt}
                             handleClick={handleClickReceipt} message="View Invoice"
                             text="Would you like to confirm this payment?"/>
                             <CustomDialog open={openProvider} handleClose={handleCloseDialog} title="New Provider">
                                    <NewProvider {...providerProps}/>
                                   </CustomDialog>
                <MainSnackbar variant={type} handleCloseBar={closeSnackbar} open={openBar} message={message}/>
                <Container>
                    <div className='mb-3'>
                        <BackButton to='/patientbillinglist' text='Billing List' data-testid="inpatient-billing-invoice-back-button"/>
                    </div>
                <RightAlignedContainer>
            <button onClick={handleOpenDialog} className="btn sms-btn"><FontAwesomeIcon icon={faPlus}/> Quick Add Provider</button>
            </RightAlignedContainer>
                    <MiniHeader  title={title[status]}/>
                <form onSubmit={handleSubmitBill} autoComplete="off" style={{marginTop:'1.5rem'}}>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <Form.Group as={Row}>
                                <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                    <Label name="Admission Date" htmlFor='admission_date'/>
                                </Col>
                                <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                                    <TextField type="text" id='admission_date' value={formatDate(admission_date)} readOnly/>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                    <Label name="Patient No." htmlFor='patient_no' />
                                </Col>
                                <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                                    <TextField type="text" id='patient_no' value={patient_number} readOnly/>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                    <Label name="Patient Name" htmlFor='patient_name'/>
                                </Col>
                                <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                                    <TextField type="text" id='patient_name' value={`${!first_name ? "" : first_name} ${!last_name ? "" : last_name}`} readOnly/>
                                </Col>
                            </Form.Group>
                            
                            <Form.Group as={Row}>
                                <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                    <Label name="Address" htmlFor='address'/>
                                </Col>
                                <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                                    <Textarea value={address} id='address' readOnly/>
                                </Col>
                            </Form.Group>
                           
                            <Form.Group as={Row}>
                                <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                    <Label name="Invoice For" type  htmlFor='insurance_provider'/>
                                </Col>
                                <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                                    <SelectInput name="insurance_provider" id='insurance_provider' submitted={submitted} value={insurance_provider} onChange={handleChange}
                                                 options={providers}
                                                 defaultOption="Select..."/>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                    <Label name="Currency"  type htmlFor="currency"/>
                                </Col>
                                <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                                    <SelectInput submitted={submitted} value={currency}
                                                 onChange={handleChangeCurrency} options={currencies}
                                                 defaultOption="Select..." id="currency"/>

                                </Col>
                            </Form.Group>
                            {isNotBase ?
                                <Form.Group as={Row} >
                                    <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                        <Label  name="Exchange Rate"  type/>
                                    </Col>

                                    <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                                        <InputGroup className="">
                                            <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">USD</InputGroup.Text>
                                            <TextField submitted={submitted} type="number" value={exchangeRate} onChange={handleChangeExchangeRate}/>
                                        </InputGroup>
                                        <span style={{marginTop:'5px', display:'inline-block', fontWeight:700, color:'blue'}}>(1 {currency.split('-')[2]} = {exchangeRate} USD)</span>
                                    </Col>
                                </Form.Group> : null}
                        </div>
                        <div className='col-lg-6'>
                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                            <Label name="Invoice Date"  htmlFor='inovice_date' type/>
                        </Col>
                        <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                            <TextField submitted={submitted} type="date" id='invoice_date' value={invoice_date} name="invoice_date" onChange={handleChange}/>
                            {submitted && !invoice_date ? <ErrorMessage>This is a required field</ErrorMessage>:null}
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                            <Label name="Memo" htmlFor='memo'/>
                        </Col>
                        <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                            <Textarea value={memo} name="memo" id='memo' onChange={handleChange}/>
                        </Col>
                    </Form.Group>
                    {isInsurance ?  
                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                            <Label name="Policy Holder" htmlFor='policy_holder'  type/>
                        </Col>
                        <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                            <TextField submitted={submitted} value={policy_holder} id='policy_holder' name="policy_holder" onChange={handleChange}/>
                            {submitted && !policy_holder ? <ErrorMessage>This is a required field</ErrorMessage>:null}
                        </Col>
                    </Form.Group>:
                    insurance_provider ? <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                            <Label name="Name of Employee" htmlFor='account_name'  type/>
                        </Col>
                        <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                            <TextField submitted={submitted} value={account_name} id='account_name' name="account_name" onChange={handleChange}/>
                            {submitted && !account_name ? <ErrorMessage>This is a required field</ErrorMessage>:null}
                        </Col>
                    </Form.Group>:null}

                    {isInsurance ?
                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                            <Label name="Membership No." htmlFor='card_number'  type/>
                        </Col>
                        <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                            <TextField submitted={submitted} type='text' id='card_number' value={card_number} name="card_number" onChange={handleChange}/>
                            {submitted && !card_number ? <ErrorMessage>This is a required field</ErrorMessage>:null}
                        </Col>
                    </Form.Group>
                    : null }

                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                            <Label name="Total Bill(USD)"/>
                        </Col>
                        <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                            <TextField name='Total_Bill' type="text" value={total_amount} readOnly/>
                        </Col>
                    </Form.Group>
                    {isNotBase ?
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                <Label name={`Total Bill(${currency_symbol})`}/>
                            </Col>
                            <Col  xs={12} sm={12} md={10} lg={10} xl={7} >
                                <TextField type="text" value={currencyTotal} readOnly/>
                            </Col>
                        </Form.Group>: null}
                </div>
                    </div>
                        <div className="other-billing-details mb-3">
                                    <div className="service-details">
                                        {services.length > 0 ?
                                            <ReusableBillTable services={services.map(item=>({...item,subTotal:item.amount}))} isInPatient={true}
                                                               handleDelete={handleDeleteServices}
                                                               headData={ unpaid.filter(item=>Boolean(item))}
                                                               title="Service(s)"
                                                               isCurrency={isNotBase}
                                                               handleChangeRate={handleChangeServiceRate}
                                                               handleChangeCurrencyRate={handleChangeServiceCurrencyRate}
                                                               >
                                            </ReusableBillTable> : null}
                                        {products.length > 0 ? <ProductsTable products={products} submitted={submitted} headData={productHeadData.filter(item=>Boolean(item))}
                                                               handleChangeProductRate={handleChangeProductRate} isInPatient={true}
                                                               handleChangeProductQuantity={handleChangeProductQuantity}
                                                               handleDeleteProduct={handleDeleteProduct}
                                                              isCurrency={isNotBase}
                                                              handleChangeProductCurrencyRate={handleChangeProductCurrencyRate}
                                                              >
                                                              </ProductsTable> : null}
                                                                            {bedBill.length > 0 &&
                                    <table className="table table-bordered table-sm billing-invoice-table">
                                        <thead>
                                            <tr>
                                                <td colSpan={8} align="center">Bed Payment</td>
                                            </tr>
                                            <tr>
                                                {bedHeadData.filter(item=>Boolean(item)).map((item, index) => (<td key={index}>{item}</td>))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {bedBill.map((bed, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{bed.ward_name}</td>
                                                    <td>{bed.room_name}</td>
                                                    <td>{bed.bed_number}</td>
                                                    <td>{formatDateTime(bed.assign_date)}</td>
                                                    <td>{formatDateTime(bed.discharge_date)}</td>
                                                    <td>{bed.quantity}</td>
                                                    {isNotBase ? <td>{bed.currency_rate}</td>:null}
                                                    <td>{bed.rate}</td>
                                                    {isNotBase ? <td>{bed.currency_total}</td>: null}
                                                    <td>{bed.amount}</td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>

                                    </table>}
                                    </div>

                        </div>
                        <button type="submit" className="btn btn-sm sms-info-btn"
                            disabled={isSubmitted === 'pending'}>{isSubmitted === 'pending' ? "Saving..." : "Save"}</button>
                    
                   
                </form>

            </Container>
        </div>
    )
}

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(InpatientBillingInvoice);
