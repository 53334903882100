import React, {useEffect, useState} from "react";
import axios from "axios";
import {config} from "../../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import CustomDialog from "../../../Utils/Dialogs/CustomDialog";
import Form from "react-bootstrap/Form";
import Label from "../../../Utils/FormInputs/Label";
import {CustomInput} from "../../CustomInput";


export const useAddMedicineType = (actions) =>{
    const [type_name, setName] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [openDialog, setOpenDialog] = useState(false);
    const [type, setType] = useState({});
    const [type_id, setTypeId] = useState('')
    const [openEdit, setOpenEdit] = useState(false)



    useEffect(() => {
        if(!type_id){
            setName('')
            return
        }
        setName(type.type_name)
    }, [type]);



    const handleOpenDialog = () =>{
        setOpenDialog(true)
    }

    const handleCloseDialog = () =>{
        setOpenDialog(false)
    }

    const handleOpenEdit = (_id) =>{
        setOpenEdit(true)
        setTypeId(_id)
        const formData = new FormData();
        formData.append('product_type_id', _id);
        axios.post(`${config.smsUrl}/cpharmacy/type_update_form`, formData).then((res) => {
            const data = res.data;
            const dt = !data ? {} : data;
            setType(dt);
        }).catch(err => {
            logoutErrorMessage(err, null,actions)
        })
    }

    const handleCloseEdit = () =>{
        setOpenEdit(false)
        setTypeId('')
        setName('')
    }

    const handleSubmit = (event) =>{
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const fieldValues = Object.fromEntries(formData.entries())
        if (type_id){
            formData.append('product_type_id', type_id);
        }
        setSubmitted(true);
        if(fieldValues['type_name']){
            setIsSubmitted('pending');
            const url = type_id ? 'type_update' : 'add_type'
            axios.post(`${config.smsUrl}/cpharmacy/${url}`, formData).then(()=>{
                actions.snackbarActions.snackSuccess('Medicine type added successfully')
                setIsSubmitted('resolved');
                setSubmitted(false)
                setName("");
                setOpenDialog(false)
                setOpenEdit(false)
            }).catch(err=>{
                setIsSubmitted('rejected');
                errorMessages(err,null, actions)
            })
        }
    };

    const isPending = isSubmitted === 'pending'
    const isResolved = isSubmitted === 'resolved'

    const open = type_id ? openEdit : openDialog
    const handleClose = type_id ? handleCloseEdit : handleCloseDialog

    const title = type_id ? 'Edit Medicine Type' : 'Add Medicine Type'

    return {
        isResolved,
        open,
        handleOpenEdit,
        handleOpenDialog,
        renderAddMedicineType: (
            <CustomDialog open={open} handleClose={handleClose} title={title}>
                <form onSubmit={handleSubmit} autoComplete='off'>
                    <Form.Group>
                        <Label type name='Name' htmlFor='type_name'/>
                        <CustomInput name='type_name' isRequired submitted={submitted} inputValue={type_name} type='text' placeholder='Enter medicine type' reset={isResolved}/>
                    </Form.Group>
                    <button type='submit'  className='btn btn-sm sms-btn mr-4'>{isPending ? 'Saving...' :'Save'}</button>
                </form>
            </CustomDialog>
        )

    }



}