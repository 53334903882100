import React from "react";
import styled from "@emotion/styled/macro";
import * as colors from "../../../styles/colors"
import AddIcon from '@material-ui/icons/Add';


const NotesContainer = styled.div({
    border:`1px solid ${colors.gray001}`,
    overflow:'hidden',
    borderRadius:'4px',
    marginTop:'20px',
    // marginLeft:'20px',
    // marginRight:'20px',
    padding:'12px',
    background:colors.base,
    position:'relative'
})

const Heading = styled.h4({
    fontSize:'16px',
    fontWeight:700,
    marginBottom:0
})

const Border = styled.div({
    border:`2px solid ${colors.indigo}`,
    width:'60px'
})

export const Button = styled.button({
    color:colors.lightBlue,
    background:colors.base,
    padding :'4px 10px',
    border :`0.85px solid ${colors.lightBlue}`,
    borderRadius:'8px'
})

export const StyledAdd = styled(AddIcon)({
    width: "0.65em",
    height: "0.65em"
})


const SavedNotesContainer = styled.div({
    border:`1px solid rgba(224, 224, 224, 1)`,
    overflow:'hidden',
    borderRadius:'8px',
    padding:'8px',
    position:'relative',
    marginBottom:'1rem'
})




const PatientNotesContainer = ({children,heading="Clinical Notes", button=null}) => {
    return (
        <NotesContainer>
            <div className="row mb-3">
                <div className="col-lg-6">
                    <Heading>{heading}</Heading>
                    <Border/>
                </div>
                <div className="col-lg-6">
                    {button}
                </div>
            </div>
            {children}
        </NotesContainer>
    )
};

export {PatientNotesContainer, SavedNotesContainer};
