/** @jsxImportSource @emotion/react */
import React from "react";
import {useTheme} from "@material-ui/core/styles";
import {useCompany} from "../../Context/company-context";
import {tableBordered, table, detailsTable, printTable, smallTable} from "../../styles/tableStyles";


export const HospitalAddress = (props) => {
    const {state} = useCompany(props);
    const { companyName, companyEmail, mobile, address, website, logo } = state;
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    return (
            <table css={[table, smallTable]}>
                {/* <tr>
                    <td/>
                    <td align="center"  className={palette.type === 'dark' && 'dark-theme-color'}><img style={{marginTop:20,width:'80%'}}  src={image} alt="smartmedlogo"/></td>
                </tr> */}
                <tr>
                   <td  align="center" className="table-items"><img   src={logo ? logo : '/images/smartmedlogo.svg'} alt="smartmedlogo" style={{  width:'120px', marginBottom:'-5px'}}/></td>
                </tr>
                <tr>
                    <td align="center" className="table-items"><span><strong>{companyName}</strong></span></td>
                </tr>

                <tr>
                   <td align="center" className="table-items"><span><strong>{mobile}</strong></span></td>
                </tr>
                <tr>
                    <td align="center" className="table-items"><span><strong>{address}</strong></span></td>
                </tr>
                {companyEmail ? <tr>
                    <td align="center" className="table-items"v><span><strong>{companyEmail}</strong></span></td>
                </tr>:null}
                <tr>
                    {website ? <td align="center" className="table-items"><span><strong>Website:</strong> <a href={website}
                                                                                                                target="__blank">{website}</a></span></td>:<td/>}
                </tr>

            </table>
    )
};
