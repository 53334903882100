import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import * as loginActions from "../../actions/UserActions/UserLoginActions";
import * as alertActions from "../../actions/alertActions";
import {bindActionCreators} from "redux";
import Form from "react-bootstrap/Form";
import PageTitle from "../Utils/smsTitle";
import {Link} from "react-router-dom"
import {config} from "../../Helpers/env"
import { makeStyles } from "@material-ui/styles";
import TextField from "../Utils/FormInputs/TextField";
import { ErrorMessage } from "../Utils/styledComponents";
import * as types from "../../actions/actionTypes";
import axios from "axios";
import {history} from "../../Helpers/history";
import {errorMessages} from "../../Helpers/ErrorMessages";
import actions from "redux-form/lib/actions";
import { useVersionsContext } from "../../Context/versions-context";

const useStyles =  makeStyles(()=>({
    root: {
        position: "fixed",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        display: "flex"
    },
    loader: {
        margin: "auto",
        maxHeight: "100%"
    }
}));

const errorMessage = (err) => {
    const response = !err.response ? {} : err.response;
    const message = !response.data ? "" : response.data;
    const msg = !message.error ? "" : message.error;
    const msg1 = !message.message ? '' : message.message;
    return msg ? msg : msg1
}
function Login(props){
    const [ state, setState ] = useState({
        username: "",
        password: "",
    })
    const [submitted, setSubmitted] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState('idle')
    const [alerts, setAlerts] = useState({type:'', message:''})
    const {username, password} = state;
    const {type, message} = alerts;
    const {subscription} = useVersionsContext(props)
    

    const {actions} = props;


    useEffect(()=>{
        sessionStorage.clear()
    },[])

    
   const handleChange = e => {
        const {name, value} = e.target;
        setState({...state,[name]: value});
    };

    const getPermissions = ()  => {
        axios.get(`${config.smsUrl}/admin/get_user_permissions`).then(res => {
            const data = res.data;
            const dt = data ? data : {};
            sessionStorage.setItem('permission', JSON.stringify(dt));
        }).catch(err => {
            const error = errorMessage(err)
            setAlerts({type: 'alert-danger', message: error})
        })
    }


    const handleSubmit = e => {
        e.preventDefault();
        setSubmitted(true)

        const formData = new FormData();
        formData.append('user_name', username);
        formData.append('password', password);
        if (username && password) {
            setIsSubmitted('pending')
            // actions.loginActions.userLogin(formData);

            axios.post(`${config.smsUrl}/admin/login`, formData).then(res => {
                const data = res.data;
                const dt = data ? data : {};
                sessionStorage.setItem('user', JSON.stringify(dt));
                setIsSubmitted('success')
                setAlerts({type: 'alert-success', message: 'User login successful'})
                setIsSubmitted('resolved')
                getPermissions()
                setTimeout(() => {
                    history.push('/')
                }, 1000)

            }).catch((err) => {
                const error = errorMessage(err)
                setAlerts({type: 'alert-danger', message: error})
                setIsSubmitted('error')
            })
        }
    }


    const isPending = isSubmitted === 'pending'
    const classes = useStyles()
        return (
            <div className={`${classes.root} responsive-login`}>
                <PageTitle title="Login"/>
                <div className="responsive-div"
                     style={{marginTop: 90}}
                >
                    {message && <div className={`text-center alert mb-2 ${type}`}>{message}</div>}
                    <div className="card border-0 card-login">
                        <div className="row col-md-12 mb-0">
                            <div className="col-md-7 text-center border-right col-sm-2 responsive-side-div"
                                 style={{backgroundColor: "rgba(10,66,191,0.04)"}}
                            >
                                <div>
                                <h4 style={{
                                    color: "rgb(21 126 63)", marginTop: 20,
                                    fontWeight: 500,
                                    textShadow: "2px 4px 3px rgba(174, 174, 174, 0.3)",
                                    fontFamily: "Roboto",
                                    fontSize:20
                                }}>
                                    MPEKE<span style={{color: "rgb(52 88 167)"}}> HMIS</span>
                                </h4>
                                <h4 style={{
                                    color: "rgb(21 126 63)", marginTop: 10,
                                    fontWeight: 500,
                                    textShadow: "2px 4px 3px rgba(174, 174, 174, 0.3)",
                                    fontFamily: "Roboto",
                                    fontSize:18
                                }}>{subscription?.version}</h4>
                                </div>
                                <img src="./images/surgeon.png"
                                     style={{width: "70%", marginTop: "5%", background: "rgb(241, 247, 245)"}}
                                />
                                <p
                                    className="text-center"
                                    style={{color: "#389081",  fontSize: 15}}
                                >
                                    {" "}
                                    Unlock the Power of Smart Health{" "}
                                </p>
                                <p className="text-center mt-3" style={{fontSize: ".8em", color: "#585858"}}>
                                    &copy; Mara Scientific | Mpeke HMIS
                                </p>
                            </div>
                            <div className="col-md-5">
                                <div className="responsive-div-right">
                                    <Form onSubmit={handleSubmit}>
                                        {/*<h4 className="text-center sms-text">SMARTMED</h4>*/}
                                        <h5 className="text-center login-text">LOGIN</h5>
                                        <Form.Group
                                            className={"FormField" + (submitted && !username ? " has-error" : "")}
                                        >
                                            <TextField
                                                type="text"
                                                id="username"
                                                name="username"
                                                placeholder="Enter your email"
                                                value={username}
                                                onChange={handleChange}
                                                submitted={submitted}
                                            />
                                            {submitted && !username && (
                                                <ErrorMessage>Username is required</ErrorMessage>
                                            )}
                                        </Form.Group>
                                        <Form.Group
                                            className={"FormField" + (submitted && !password ? " has-error" : "")}
                                        >
                                            <TextField
                                                type="password"
                                                id="password"
                                                placeholder="Enter your password"
                                                name="password"
                                                value={password}
                                                onChange={handleChange}
                                                submitted={submitted}
                                            />
                                            {submitted && !password && (
                                                <ErrorMessage>Password is required</ErrorMessage>
                                            )}
                                        </Form.Group>
                                        <Form.Group className="FormField">
                                            <button type="submit" id="login" disabled={isPending}
                                                    className="btn  py-2 sms-btn px-4"  style={{width: "100%"}}>
                                                {isPending ? "Signing in..." : "Sign In"}
                                            </button>
                                        </Form.Group>
                                    </Form>
                                    <Link to="/forgotpassword" style={{fontSize:15}}>Forgot password?</Link>
                                    <div className="text-right mt-5" style={{fontSize:15}}>
                                        Version: {config.version}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }


function mapStateToProps(state) {
    return {user: state.loggedUser, alerts: state.alerts};
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            loginActions: bindActionCreators(loginActions, dispatch),
            alertActions: bindActionCreators(alertActions, dispatch)
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
