import {useState, useEffect} from "react";
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {errorMessages} from "../../../../Helpers/ErrorMessages";
import {history} from "../../../../Helpers/history";
import {useReusablePrescription} from "./useReusablePrescription";
import {useSundries} from "./useSundries";
import {v4 as uuidv4} from "uuid";


export const useAddPrescription = (actions, match) => {
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = !_token.user_roles_id ? 1 : _token.user_roles_id;
    const {patient_number, visit_id, labrequest_id, scan_request_id, patient_type,patient_admission_id} = match;
    const [isBlocking, setIsBlocking] = useState(false)
    const [isBlockDialog, setIsBlockDialog] = useState(false)

    const obj = {actions, visit_id,setIsBlocking}


    const [presc_id, setPrescId] = useState("");
    const [printDialog, setOpenPrintDialog] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const {drugProps} = useReusablePrescription(obj);
    const {submitted, prescription, others,  setPrescription, setOthers, setSubmitted, other,
        isSubmitted,  setIsSubmitted, setOther, initialOthers, initialPrescState, isDrugClicked, alert, setAlert} = drugProps;
    const [isSundries, setIsSundries] = useState(false);
    const {sundryProps} = useSundries(obj);
    const {sundries, setSundries, initialState, handleResetSundries} = sundryProps;

    

    useEffect(() => {
        setIsSubmitted('idle');
    }, [isDrugClicked]);


    const handleClosePrintDialog = () => {
        setOpenPrintDialog(false)
    };



    const handleChangeItems = (event, index) => {
        const {name, value} = event.target;
        const arr = prescription.map((item, idx) => {
            if (idx === index) {
                return {...item, [name]: value, isEdited: !!item.product_bill_detail_id}
            }
            return item
        })
        setPrescription(arr)
        setIsSubmitted('idle')
        setIsBlocking(true)
    };

    const handleChangeOtherMedicine = (event, index) => {
        const {value, name} = event.target
        const arr = others.map((item, idx) => {
            if (idx === index) {
                return {...item, [name]: value}
            }
            return item
        })
        setOthers(arr)
        setIsSubmitted('idle')
        setIsBlocking(true)
    }

    const handleAddOther = () => {
        setOthers([...others, ...initialOthers])
    }

    const handleRemoveOther = (index) => {
        const arr = others.filter((_, idx) => idx !== index)
        setOthers(arr)
        setIsSubmitted('idle')
    }

    const handleAddRow = () => {
        const filtered_Prescription = prescription.filter(item=>!item.isExpired)
        setPrescription([...filtered_Prescription, ...initialPrescState])
        setAlert({...alert, open: false})
    };

    const removeRow = (item_uuid) => {
        const arr = prescription.filter((e) => e.uuid !== item_uuid)
        setPrescription(arr);
        setAlert({...alert, open: false})
    }

    const handleToggleSundries = (e) => {
        setIsSundries(e.target.checked)
        setIsBlocking(true)

    }

    const handleOpenDialog = () => {
        setOpenDialog(true)
    };

    const handleResetForm = () =>{
        setOpenDialog(false)
        setIsBlockDialog(false)
        setIsBlocking(false)
        const resetProducts = prescription.map(item=>({
            ...item, drug_name: '', dosage: '', disabled: false,generic_name:'', stock:'',
            number_of_days: 0, key_information: '', rate: 0, date: '', invoice_no: '',
            product_id: '', showDrug: false,  frequency: '', isError: false,
            prescription_detial_id: '', bill_id: '', isEdited: false, status:0,currency:'',
            currency_symbol:'', uuid : ''
        }))
        setPrescription(resetProducts)

        const resetOthers = others.map(item=>({
            ...item, other_medicine: '', dosage: '', disabled: false, quantity:0,
            number_of_days: 0, key_information: '', frequency: '', rate: 0
        }))
        setIsSundries(false)

        setOther(false)

        setOthers(resetOthers)

        handleResetSundries()
    }

    const handleCloseDialog = () => {
        if(isBlocking){
            setIsBlockDialog(true)
        }else{
            handleResetForm()
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setSubmitted(true);
        const arr = prescription.every(item => ((item.product_id && item.quantity && item.quantity > 0 && item.quantity <= item.stock && !item.isExpired) || (other && (!item.product_id && item.quantity && item.other_medicine))))
        const isSundry = sundries.every(item => item.product_name) ;

        const sunArr = isSundries ? (arr && isSundry) : arr;
        const onlySunArr = (isSundries && !arr) ? isSundry : false;
        setIsBlocking(false)
        if (sunArr || onlySunArr) {
            setIsSubmitted('pending');
            const params = {
                bill_type: 3,
                products: prescription.filter(item=>Boolean(item)).filter(drug => drug.product_id && !drug.isExpired).map(presc => ({
                    dosage: presc.dosage,
                    number_of_days: +presc.number_of_days,
                    key_information: presc.key_information,
                    rate: presc.rate,
                    product_id: presc.product_id,
                    frequency: presc.frequency,
                    quantity_prescribed:parseInt(presc.quantity),
                    is_provided: presc.is_provided,
                    is_provider: presc.is_provider,
                    currency_id:presc.currency_id,
                    quantity_billable:presc.quantity_billable,
                    quantity_to_bill: +presc.quantity_to_bill

                })),
                sundries: sundries.filter(item=>Boolean(item)).filter(item=>item.product_id).map(item=>({
                    bill_type:3,
                    ...item
                }))
            }
            // const url = patient_type === '1' ? 'save_prescription' : 'save_inpatient_prescription'
            axios.post(`${config.smsUrl}/cdoctor/save_prescription`, {
                visit_id, user_roles_id,
                other_products: others.filter(item=>Boolean(item)).filter(drug => drug.other_medicine || drug.dosage || drug.price || drug.instructions ||
                    drug.frequency || drug.number_of_days), ...params
            }).then(res => {
                const data = res.data;
                const dt = !data ? {} : data;
                const presc_id = !dt.prescription_id ? "" : dt.prescription_id;
                setPrescId(presc_id);
                actions.snackbarActions.snackSuccess(`Prescription added successfully`);
                setIsSubmitted('resolved');
                setOpenDialog(false);
                setSubmitted(false);
                setOther(false)
                setOthers(initialOthers)

                const clearedArr  = prescription.map(item=>({
                    ...item,   drug_name: '', dosage: '', disabled: false,generic_name:'', stock:'',
                    number_of_days: 0, key_information: '', rate: 0, date: '', invoice_no: '',
                    product_id: '', showDrug: false,  frequency: '', isError: false,
                    prescription_detial_id: '', bill_id: '', isEdited: false, status:0,currency:'',
                    currency_symbol:'', uuid : uuidv4(), isExpired:false
                }))
                setPrescription(clearedArr)
                setSundries(initialState)
                setIsSundries(false)
            }).catch(err => {
                setIsSubmitted('rejected');
                errorMessages(err, null, actions);

            })
        }

 

    };

    const isPending = isSubmitted === 'pending';
    const isResolved = isSubmitted === 'resolved';
    const isRejected  = isSubmitted === 'rejected';

    const handleClick = () => {
        history.push(`/prescriptiondetails/${patient_number}/${visit_id}/${labrequest_id}/${scan_request_id}/${patient_type}/${patient_admission_id}/${presc_id}`)
    };

    const addProps = {submitted, prescription,  others, handleChangeOtherMedicine,
        handleAddOther, handleRemoveOther, isSubmitted,  printDialog,sundryProps,
        handleClosePrintDialog, handleChangeItems, handleAddRow, removeRow,
        handleSubmit, handleClick, other, ...drugProps, isPending, isResolved, isRejected,
        openDialog, handleOpenDialog, handleCloseDialog, isSundries, handleToggleSundries,
        isBlocking,isBlockDialog,setOpenDialog,setIsBlockDialog,handleResetForm}

    return {addProps}
}