import React from 'react';
import Menu from '@material-ui/core/Menu';

export default function SimpleMenu({anchorEl,children,handleClose}) {

  return (
    <div>
      <Menu
        id="customized-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {children}
      </Menu>
    </div>
  );
}
