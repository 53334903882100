import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../actions/snackbarActions"
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import MainRadiologyTable from "./MainRadiologyTable";
import {history} from "../../Helpers/history";
import {ReusableRouteTabs} from "../Utils/Dialogs/ReusableTabs";
import PageTitle from "../Utils/smsTitle";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXRay} from "@fortawesome/free-solid-svg-icons/faXRay";
import {formatDateTime} from "../Utils/ConvertDate";
import { useScanRequests } from "./CustomHooks/useScanRequests";
import { count } from "d3-array";


export const radiologistList = [
    {id: 'count', numeric: false, disablePadding: false, label: '#', hidden:false},
    {id: 'date', numeric: false, disablePadding: false, label: 'Date', hidden:false},
    {id: 'patient_name', numeric: false, disablePadding: false, label: 'Patient Name', hidden:false},
    {id: 'phone_no', numeric: false, disablePadding: false, label: 'Phone', hidden:false},
    {id: 'kin_phone_no', numeric: false, disablePadding: false, label: 'NOK Phone', hidden:false},
    {id: 'hospital_scan_name', numeric: false, disablePadding: false, label: 'Scan Name', hidden:false},
    {id: 'doctor_name', numeric: false, disablePadding: false, label: 'Doctor', hidden:false},
    {id: 'action', numeric: false, disablePadding: false, label: 'Action', hidden:false}
];

const ScanRadiology =  ({actions, snackbars}) => {
    const {activePage, term, scanRequests, total_count,handleChangeActivePage, scan_queue,
         handler, closeSnackbar, isLoading, isSuccess, isRejected, isPending, handleClickRow} = useScanRequests(actions,'retrieve_all_scan_requests')
    const requests = !scanRequests.scan_request_list ? [] :scanRequests.scan_request_list;
    const {openBar, type, message} = snackbars;
    const components = [{label: 'Requests', path: '/scanRadiology', badge:true, badge_count:total_count}, {label: 'Results', path: "/scanresults"}];

    const all_scan_requests = requests.map((request, index)=>{
        const count = ((activePage - 1) * 10) + index + 1;
        return{
            ...request,
            count,
            date:request.time_requested ? formatDateTime(request.time_requested) : "",
            patient_name:`${request.patient_firstname} ${request.patient_lastname}`,
            doctor_name:`${request.doctor_firstname} ${request.doctor_lastname}`,
            action:(
                <button disabled={isPending} onClick={() => handleClickRow(request)} className="btn btn-sm sms-info-btn">Record Results</button>

            )
        }
    })
    return (
        <div className='journals'>
            <PageTitle title='Scan Requests' />
            <SubHeader title="Radiology" subTitle='Scan Requests'>
                <FontAwesomeIcon icon={faXRay}/>
            </SubHeader>
            <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/>
            <MainSnackbar message={message} variant={type} handleCloseBar={closeSnackbar} open={openBar}/>
            <MainRadiologyTable title="Scan Requests"
                {...{activePage,total_count,all_scan_requests,title:'Requests',
                    handleChangeActivePage, term, radiologistList,handler,isLoading, isSuccess, isRejected}}
            />
        </div>
    );
    
}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbar
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScanRadiology);


