import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import TextField from '../FormInputs/TextField';
import Label from '../FormInputs/Label';
import {ErrorMessage} from "../styledComponents";
import * as mq from "../../../styles/media-queries";
import styled from '@emotion/styled/macro';

const StyledForm  = styled.form({
    [mq.extraLarge]:{
        width:'70%'
    }
})

function DateTimeFilter({start_date,end_date, handleChange, submitted,handleSubmit,isPending, children, isFilter=true}) {
    return(
        <StyledForm onSubmit={handleSubmit} data-testid="date-time-filter">
            <div className='row'>
                {children}
                <div className='col-lg-6 col-sm-12 col-md-12'>
                    {/*<Form.Group as={Row}>*/}
                        <Row>
                        <Col  xs={12} sm={2} md={2} lg={4} xl={3}>
                            <Label name='From' type htmlFor="start_date"/>
                        </Col>

                        <Col  xs={12} sm={6} md={6} lg={6} xl={6}>
                            <TextField type='date' name='start_date' id="start_date" value={start_date} onChange={handleChange}/>
                            {submitted && !start_date ? <ErrorMessage>This is a required field</ErrorMessage>: null}
                        </Col>
                        </Row>
                    {/*</Form.Group>*/}
                </div>
                <div className='col-lg-6 col-sm-12 col-md-12'>
                    {/*<Form.Group as={Row}>*/}
                        <Row>
                            <Col  xs={12} sm={2} md={2} lg={4} xl={3}>
                                <Label name='To'  type htmlFor="end_date"/>
                            </Col>

                            <Col   xs={12} sm={6} md={6} lg={6} xl={6}>
                                <TextField type='date'  name='end_date' id="end_date" value={end_date} onChange={handleChange}/>
                                {end_date < start_date ? <ErrorMessage>End date shouldn't be less than start date</ErrorMessage> :   submitted && !end_date ? <ErrorMessage>This is a required field</ErrorMessage>: null}
                            </Col>
                            {isFilter ? <Col sm={2}>
                                <button type='submit' name="date-time-submit-button"
                                style={{marginTop:'0.3rem'}} className='btn btn-sm sms-info-btn' disabled={isPending}>{isPending ? 'Filtering...':'Filter'}</button>
                            </Col>: null}
                        </Row>

                    {/*</Form.Group>*/}
                </div>
            </div>
        </StyledForm>
    ) 
}
export {DateTimeFilter}
