import {useEffect, useState} from "react";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {history} from "../../../Helpers/history";

export const fetchEmployees = () => axios.get(`${config.smsUrl}/chumanresource/get_all_employees`)
export const useEditUser = (props) => {
    const [state, setState] = useState({first_name: "", last_name: "", email: "", password: "", status: "1"});
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [user, setUser] = useState({});
    const [employee_id, setEmployee] = useState(null);
    const [employees, setEmployees] = useState([]);
    const {actions} = props;
    const {user_id} = props.match.params;

    useEffect(() => {
        fetchEmployees().then(res => {
            const data = res.data;
            const dt = !data ? [] : data;
            setEmployees(dt);
        }).catch((err) => {
            errorMessages(err, null, actions)
        })
    }, []);


    const handleChangeEmployee = value => {
        setEmployee(value);
    };

    const handleChange = (event) => {
        const {name, value} = event.target;
        setState({...state, [name]: value})
    };

    useEffect(() => {
        const formData = new FormData();
        formData.append('user_id', user_id);
        axios.post(`${config.smsUrl}/cuser/user_update_form`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            setUser(dt)
        }).catch(err => {
            errorMessages(err, null, actions)
        })
    }, []);

    useEffect(() => {
        const emp = !user ? {} : user;
        const first_name = !emp.first_name ? "" : emp.first_name;
        const last_name = !emp.last_name ? "" : emp.last_name;
        const user_name = !emp.user_name ? "" : emp.user_name;
        const password = !emp.password ? "" : emp.password;
        setState({
            first_name: first_name, last_name: last_name,
            email: user_name, status: emp.status, password
        });
        setEmployee({value: emp.employee_id, label: `${first_name} ${last_name}`})

    }, [user]);

    const handleSubmit = (event) => {
        event.preventDefault();
        const {email, password, status} = state;
        const formData = new FormData();
        formData.append('user_id', user_id);
        formData.append('employee_id', employee_id.value);
        formData.append('user_name', email);
        formData.append('status', status);
        setSubmitted(true);
        if (employee_id && email && status) {
            setIsSubmitted(true);
            axios.post(`${config.smsUrl}/cuser/user_update`, formData).then(() => {
                setIsSubmitted(false);
                actions.snackbarActions.snackSuccess('User Updated successfully');
                history.push('/users')
            }).catch(err => {
                setIsSubmitted(false);
                errorMessages(err, null, actions)
            })
        }
    };

    const handleCloseSnackbar = () => {
        actions.snackbarActions.hideSnackbar();
    };

    return {state, submitted, isSubmitted, user, employee_id, employees, handleChangeEmployee,
        handleChange, handleSubmit, handleCloseSnackbar, setState, setEmployee
    }
}