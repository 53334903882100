import React from "react";
import AddEditDeathRegistration from "./AddEditDeathRegistration";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../../actions/snackbarActions";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import countries from "../../../Helpers/countries.json"
import {useEditDeath} from "./CustomHooks/useEditDeath";


const EditDeath = ({actions, snackbar, match}) => {
    const  {p_number, patientNumbers, showNumber, patientDetails, state, nationality, submitted, isSubmitted, deathDetails, updateDeath,
         reported_by, handleChange, handleChaneNationality, handleSearchPatientNumber, patient_name, wards, ward, handleChangeWard,
        handleClickPatientNumber, handleSubmit,  closeSnackbar, handleReset} = useEditDeath(actions, match)
    const {openBar, type, message} = snackbar;
    return (
        <div data-testid="edit-death">
          
            <MainSnackbar handleCloseBar={closeSnackbar} message={message} open={openBar} variant={type}/>
            <AddEditDeathRegistration state={state} handleChange={handleChange} handleReset={handleReset}
                                      handleChangePatientNumbers={handleSearchPatientNumber} reported_by={reported_by}
                                      handleClickPatientNumber={handleClickPatientNumber} handleSubmit={handleSubmit}
                                      isSubmitted={isSubmitted} p_name={patient_name} patient={patientDetails}
                                      patientNumbers={patientNumbers} registerDeath showNumber={showNumber} updateDeath={updateDeath}
                                      submitted={submitted} subTitle="Update Death" title="Update Death"
                                      countries={countries.map(country => ({label: country.text, value: country.text}))}
                                      handleChangeCountry={handleChaneNationality} nationality={nationality} wards={wards} ward={ward} handleChangeWard={handleChangeWard}/>
        </div>
    )
}

function mapStateToProps(state) {
    const {snackbar} = state;
    return {snackbar}

}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(EditDeath);