import React, { useEffect, useState } from "react";
import TextField from "../../Utils/FormInputs/TextField";
import {
  ErrorMessage,
  RightAlignedContainer,
} from "../../Utils/styledComponents";
import SelectInput, { CustomSelect } from "../../Utils/FormInputs/SelectInput";
import Form from "react-bootstrap/Form";
import Label from "../../Utils/FormInputs/Label";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import { DataCell } from "../../Utils/Lists/CollapsibleList";
import { ResizableTextarea } from "../../Utils/FormInputs/Textarea";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {
  errorMessages,
  logoutErrorMessage,
} from "../../../Helpers/ErrorMessages";
import { BlockNavigationComponent } from "../../Utils/ReusableComponents/BlockNavigationComponent";
import { history } from "../../../Helpers/history";
import CustomDialog from "../../Utils/Dialogs/CustomDialog";
import { ReusableNewCOA } from "../ChartofAccounts/NewCOAccount";
import { useCurrency } from "../../../Context/currency-context";
import { useVersionsContext } from "../../../Context/versions-context";

function NewExpense({
  actions,
  openDialog,
  setOpenDialog,
  isSubmitted,
  setIsSubmitted,
  expense_id,
}) {
  const { currency: selectedCurrency } = useCurrency({
    actions,
    openDialog,
    setOpenDialog,
    isSubmitted,
    setIsSubmitted,
  });

  const [openExpense, setOpenExpense] = useState(false);
  const [expenses, setExpenses] = useState([
    { expense_item: "", memo: "", amount: "", usd_amount: 0 },
  ]);
  const [expenseItems, setExpenseItems] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [isSubmittedItem, setIsSubmittedItem] = useState("idle");
  const [currencies, setCurrencies] = useState([]);
  const [currency, setCurrency] = useState("");
  const [exchangeRate, setExchangeRate] = useState(0);
  const [currencyExchangeRate, setCurrencyExchangeRate] = useState(0);
  const [isBlocking, setIsBlocking] = useState(false);
  const [isBlockDialog, setIsBlockDialog] = useState(false);
  const [state, setState] = useState({
    expense_date: "",
    remarks: "",
    voucher_no: "",
  });
  const [paymentAccounts, setPaymentAccounts] = useState([]);
  const [paymentAccount, setPaymentAccount] = useState({
    value: "",
    label: "",
  });

  const { subscription } = useVersionsContext();
  const version = subscription?.version;
  const isPremium = version === "Premium";
  const [totalAmount, setTotalAmount] = useState(0);

  //   handleResetForm();
  useEffect(() => {
    if (isPremium) {
      return;
    }
    setCurrency(
      `${selectedCurrency.id}-${selectedCurrency.is_base_currency}-${selectedCurrency.currency_symbol}`
    );
  }, [version]);

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(
          `${config.smsUrl}/accounting/payment_accounts`
        );
        let _data = res.data;
        let dt = _data.data ?? [];
        let credits = dt.map((i) => ({ label: i.head_name, value: i.id }));
        setPaymentAccounts(credits);
      } catch (e) {
        // errorMessages(e, null, actions)
      }
    })();
  }, []);

  const isResolvedItem = isSubmittedItem === "resolved";

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(
          `${config.smsUrl}/accounting/chart/expense_ledgers`
        );
        let _data = res.data;
        let dt = _data.data ?? [];
        let exp_types = dt.map((i) => ({
          label: i.head_name,
          value: i.id,
          parent_id: i.parent_id,
        }));
        setExpenseItems(exp_types);
      } catch (e) {
        // errorMessages(e, null, actions)
      }
    })();
  }, [isResolvedItem]);

  // get currencies
  useEffect(() => {
    axios
      .get(`${config.smsUrl}/accounting/currencies`)
      .then((res) => {
        const data = res.data;
        const all_data = !data ? {} : data;
        const arr = all_data.data ?? [];
        const list = arr.map((item) => ({
          value: `${item.id}-${item.is_base_currency}-${item.currency_symbol}`,
          text: `${item.currency_symbol} ${item.currency_name}`,
          currency_id: item.id,
        }));
        setCurrencies(list);
      })
      .catch((err) => {
        logoutErrorMessage(err, null, actions);
      });
  }, []);

  useEffect(() => {
    (async () => {
      if (expense_id) {
        on_data_for_editing_Expense(expense_id);
      }
    })();
  }, [expense_id]);

  const on_data_for_editing_Expense = (expense_id) => {
    const formData = new FormData();
    formData.append("expense_id", expense_id);
    axios
      .post(`${config.smsUrl}/accounting/view_expenses_details`, formData)
      .then((res) => {
        const data = res.data;
        const all_data = !data ? {} : data;
        const arr = !all_data.data ? {} : all_data.data;
        console.log(arr);
        setState({
          expense_date: arr[0].expense_date,
          remarks: arr[0].remarks,
          voucher_no: arr[0].voucher_no,
        });

        const isCurrency = currencies.filter((item) => {
          if (item.currency_id == arr[0].currency_id) {
            return item;
          }
          return;
        });

        const payaccount = paymentAccounts.filter((item) => {
          if (item.value == arr[0].payment_account) {
            return item;
          }
          return;
        });

        setPaymentAccount(payaccount[0]);

        setCurrency(isCurrency[0].value);
        const currency_rate = arr[0]?.currency_rate ? arr[0]?.currency_rate : 0;
        const ex_rate = currency_rate > 0 ? Math.pow(currency_rate, -1) : 0;
        setExchangeRate(currency_rate);
        setCurrencyExchangeRate(ex_rate);

        const exps = arr.map((item) => ({
          expense_item: expenseItems.filter((exp) => {
            if (exp.value == item.expense_account) {
              console.log(exp);
              return exp;
            }
            return;
          })[0],
          receipt_no: item.memo,
          amount: item.amount_in_currency,
          memo: item.memo,
          usd_amount: item.amount_in_base_currency,
        }));

        setExpenses(exps);
        calculate_Amount(exps);
      })
      .catch((e) => {
        // setStatus("rejected");
        errorMessages(e, null, actions);
      });
  };

  const calculateAmount = (rate) => {
    const sumArr = expenses.map((item) => ({
      ...item,
      usd_amount: +item.amount * +rate,
    }));
    setExpenses(sumArr);
  };
  const calculate_Amount = (exps) => {
    let sum = 0;
    for (var i = 0; i < exps.length; i++) {
      sum += exps[i].amount;
    }
    setTotalAmount(sum);
  };

  const handleChangeState = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
    setIsBlocking(true);
  };

  const handleChangePaymentAccount = (value) => {
    setPaymentAccount(value);
    setIsBlocking(true);
  };

  //Change currency
  const handleChangeCurrency = (event) => {
    setCurrency(event.target.value);
    setIsBlocking(true);
  };

  //Change exchange rate
  const handleChangeExchangeRate = (event) => {
    const ex_rate = +event.target.value > 0 ? 1 / +event.target.value : 0;
    setExchangeRate(ex_rate);
    setCurrencyExchangeRate(event.target.value);
    calculateAmount(ex_rate);
    setIsBlocking(true);
    calculate_Amount(expenses);
  };

  const handleChangeExpenseItem = (value, index) => {
    const arr = expenses.map((item, idx) => {
      if (index === idx) {
        return { ...item, expense_item: value };
      }
      return item;
    });
    setExpenses(arr);
    setIsBlocking(true);
  };

  const handleChangeExpense = (event, index) => {
    const { value, name } = event.target;
    const arr = expenses.map((item, idx) => {
      if (index === idx) {
        return { ...item, [name]: value };
      }
      return item;
    });
    setExpenses(arr);
    setIsBlocking(true);
  };

  const handleChangeAmount = (event, index) => {
    const { value } = event.target;
    const arr = expenses.map((item, idx) => {
      if (index === idx) {
        return { ...item, amount: +value, usd_amount: +value * exchangeRate };
      }
      return item;
    });
    setExpenses(arr);
    setIsBlocking(true);
    calculate_Amount(arr);
  };

  const handleAddExpense = () => {
    const obj = { expense_item: "", receipt_no: "", amount: "" };
    setExpenses([...expenses, obj]);
  };

  const handleRemoveExpense = (index) => {
    const arr = expenses.filter((_, idx) => index !== idx);
    setExpenses(arr);
    calculate_Amount(expenses);
  };

  const handleCloseExpense = () => {
    if (isBlocking) {
      setIsBlockDialog(true);
    } else {
      setOpenDialog(false);
      setIsBlockDialog(false);
      setIsBlocking(false);
    }
  };

  const handleSubmitExpense = (e) => {
    e.preventDefault();
    const isBase = currency.split("-")[1];

    const isNotBase = isBase === "false";

    const currency_id = currency.split("-")[0];
    const data = {
      expense_date: state.expense_date,
      expense_id: expense_id,
      payment_account: paymentAccount?.value,
      remarks: state.remarks,
      voucher_no: state.voucher_no,
      currency_id: +currency_id,
      currency_rate: +exchangeRate,
      expenses: expenses.map((item) => {
        const exp = item.expense_item ? item.expense_item : {};
        const value = exp.value ? exp.value : "";
        const isNotBaseData = isNotBase
          ? {
              amount_in_currency: +item.amount,
              amount_in_base_currency: +item.usd_amount,
              // , is_base_currency:isBase
            }
          : {
              amount_in_currency: +item.amount,
              amount_in_base_currency: +item.amount,
            };
        return {
          expense_account: value,
          memo: item.memo,
          related_category: exp?.parent_id ?? "",
          ...isNotBaseData,
        };
      }),
    };
    const arr = expenses.every((item) => item.expense_item && item.amount);
    setSubmitted(true);
    if (arr && state.expense_date && paymentAccount.value) {
      setIsSubmitted("pending");
      axios
        .post(`${config.smsUrl}/accounting/expenses/add`, data)
        .then(() => {
          actions.snackbarActions.snackSuccess("Expense recorded successfully");
          setIsSubmitted("resolved");
          const exps = expenses.map((item) => ({
            expense_item: "",
            receipt_no: "",
            amount: "",
            ...item,
          }));
          setExpenses(exps);
          setCurrency("");
          setExchangeRate("");
          setOpenDialog(false);
          setIsBlocking(false);
          setIsBlockDialog(false);
        })
        .catch((err) => {
          errorMessages(err, null, actions);
          setIsSubmitted("rejected");
        });
    }
  };

  const handleResetForm = () => {
    setOpenDialog(false);
    setIsBlockDialog(false);
    setIsBlocking(false);
    const exps = expenses.map((item) => ({
      ...item,
      expense_item: "",
      receipt_no: "",
      amount: "",
    }));
    setExpenses(exps);
    setCurrency("");
    setExchangeRate("");
  };

  const isPending = isSubmitted === "pending";

  const isBase = currency.split("-")[1];
  const currency_symbol = currency.split("-")[2];
  const isNotBase = isBase === "false";
  const isBaseCurrency = isBase === "true";

  return (
    <div>
      <BlockNavigationComponent
        when={isBlocking}
        shouldBlockNavigation={() => {
          return isBlocking;
        }}
        navigate={(path) => history.push(path)}
        dialogClose={isBlockDialog}
        setIsBlockDialog={setIsBlockDialog}
        clearForm={handleResetForm}
      />
      <ReusableNewCOA
        {...{
          actions,
          isSubmitted: isSubmittedItem,
          openDialog: openExpense,
          setIsSubmitted: setIsSubmittedItem,
          setOpenDialog: setOpenExpense,
        }}
      />
      <CustomDialog
        open={openDialog}
        handleClose={handleCloseExpense}
        title="Record Expense"
        maxWidth="lg"
      >
        <RightAlignedContainer>
          <div className="mb-2">
            <button
              className="btn btn-sm sms-info-btn"
              onClick={() => setOpenExpense(true)}
            >
              Quick Add Expense Account
            </button>
          </div>
        </RightAlignedContainer>
        <form onSubmit={handleSubmitExpense} autoComplete="off">
          <div className="row">
            <div className="col-lg-6">
              <Form.Group as={Col}>
                <Label name="Date" htmlFor="expense_date" type />
                <TextField
                  type="date"
                  value={state.expense_date}
                  name="expense_date"
                  id="expense_date"
                  submitted={submitted}
                  onChange={handleChangeState}
                />
                {submitted && !state.expense_date ? (
                  <ErrorMessage>Date is required</ErrorMessage>
                ) : null}
              </Form.Group>
              {isPremium ? (
                <Form.Group as={Col}>
                  <Label name="Currency" type htmlFor="expense_currency" />
                  <SelectInput
                    submitted={submitted}
                    value={currency}
                    onChange={handleChangeCurrency}
                    options={currencies}
                    defaultOption="Select..."
                    id="expense_currency"
                  />
                </Form.Group>
              ) : null}
              {isNotBase ? (
                <Form.Group as={Col}>
                  <Label name="Exchange Rate" htmlFor="exchange_rate" />
                  <InputGroup className="">
                    <InputGroup.Text
                      style={{ borderRadius: "0.7rem 0 0 0.7rem" }}
                      id="basic-addon1"
                    >
                      {currency_symbol}
                    </InputGroup.Text>
                    <TextField
                      submitted={submitted}
                      type="number"
                      id="exchange_rate"
                      value={currencyExchangeRate}
                      onChange={handleChangeExchangeRate}
                    />
                  </InputGroup>
                  <span
                    style={{
                      marginTop: "5px",
                      display: "inline-block",
                      fontWeight: 700,
                      color: "blue",
                    }}
                  >
                    (1 {selectedCurrency.currency_symbol} ={" "}
                    {currencyExchangeRate} {currency_symbol})
                  </span>
                </Form.Group>
              ) : null}
              <Form.Group as={Col}>
                <Label name="Voucher No" htmlFor="voucher_no" type />
                <ResizableTextarea
                  rows="1"
                  type="text"
                  value={state.voucher_no}
                  name="voucher_no"
                  id="voucher_no"
                  onChange={handleChangeState}
                />
                {submitted && !state.voucher_no ? (
                  <ErrorMessage>Voucher Number is required</ErrorMessage>
                ) : null}
              </Form.Group>
            </div>
            <div className="col-lg-6">
              <Form.Group as={Col}>
                <Label name="Payment Account" type />
                <CustomSelect
                  value={paymentAccount}
                  options={paymentAccounts}
                  id="payment_account"
                  onChange={handleChangePaymentAccount}
                  submitted={submitted}
                />

                {submitted && !paymentAccount.value ? (
                  <ErrorMessage>Payment account is required</ErrorMessage>
                ) : null}
              </Form.Group>
              <Form.Group as={Col}>
                <Label name="Remarks" htmlFor="remarks" />
                <ResizableTextarea
                  rows="2"
                  value={state.remarks}
                  name="remarks"
                  id="remarks"
                  onChange={handleChangeState}
                />
              </Form.Group>

              <Form.Group as={Col}>
                <Label name="Total Amount" htmlFor="totalAmount" type />
                <ResizableTextarea
                  rows="1"
                  type="text"
                  value={totalAmount}
                  name="totalAmount"
                  id="totalAmount"
                  readOnly
                  //   onChange={handleChangeState}
                />
              </Form.Group>
            </div>
          </div>
          <table className="table table-bordered mt-3">
            <tr>
              <td>
                Account<span className="help-block">*</span>
              </td>
              <td>Memo</td>
              <td>
                Amount{currency_symbol ? `(${currency_symbol}) ` : ""}
                <span className="help-block">*</span>
              </td>
              {isNotBase ? (
                <td>Amount {currency_symbol ? `(${currency_symbol})` : ""}</td>
              ) : null}
              <td>Action</td>
            </tr>
            <tbody>
              {expenses.map((item, index) => (
                <tr key={index}>
                  <DataCell variant="300">
                    <CustomSelect
                      value={item.expense_item}
                      options={expenseItems}
                      id={`expense_item_${index}`}
                      onChange={(value) =>
                        handleChangeExpenseItem(value, index)
                      }
                    />

                    {submitted && !item.expense_item ? (
                      <ErrorMessage>Expense item is required</ErrorMessage>
                    ) : null}
                  </DataCell>
                  <DataCell variant="200">
                    <TextField
                      {...{ submitted }}
                      value={item.memo}
                      type="text"
                      name="memo"
                      onChange={(e) => handleChangeExpense(e, index)}
                      placeholder="Enter memo."
                      id={`expense_memo_${index}`}
                    />
                    {/*{(submitted && !item.receipt_no) ? <ErrorMessage>Receipt No. is required</ErrorMessage>:null}*/}
                  </DataCell>
                  <DataCell variant="200">
                    <TextField
                      {...{ submitted }}
                      value={item.amount}
                      type="number"
                      name="amount"
                      id={`expense_amount_${index}`}
                      onChange={(e) => handleChangeAmount(e, index)}
                      placeholder="Input amount"
                    />
                    {submitted && !item.amount ? (
                      <ErrorMessage>Amount is required</ErrorMessage>
                    ) : null}
                  </DataCell>
                  {isNotBase ? (
                    <DataCell variant="200">
                      <TextField
                        value={
                          item.usd_amount
                            ? item.usd_amount.toFixed(3)
                            : item.usd_amount
                        }
                        type="number"
                        name="usd_amount"
                        readOnly
                      />
                    </DataCell>
                  ) : null}
                  <DataCell>
                    <button
                      type="button"
                      onClick={() => handleRemoveExpense(index)}
                      className="btn btn-sm sms-btn-dismiss"
                    >
                      Remove
                    </button>
                  </DataCell>
                </tr>
              ))}
              <tr>
                <DataCell colSpan={4}>
                  <button
                    type="button"
                    id="add_expense"
                    onClick={handleAddExpense}
                    className="btn btn-sm sms-gray-btn"
                  >
                    Add another line
                  </button>
                </DataCell>
              </tr>
            </tbody>
          </table>
          <button
            type="submit"
            disabled={isPending}
            className="btn btn-sm sms-btn"
          >
            {isPending ? "Submitting..." : "Submit"}
          </button>
        </form>
      </CustomDialog>
    </div>
  );
}

export { NewExpense };
