import React from 'react';
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Label from "../../Utils/FormInputs/Label";
import ReactCountryFlag from "react-country-flag"
import Col from "react-bootstrap/Col";
import { AutoCompleteControlled } from '../../Utils/FormInputs/AutoCompleteInput';
import { Select, MenuItem } from "@material-ui/core";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import {CustomInput, CustomResizableTextarea} from "../CustomInput";

const styledBy = (property, mapping) => (props) => mapping[props[property]];


const StyledMenuItem = withStyles((theme) => ({
    root: {
      fontSize: '12px',
      '&:focus': {
        backgroundColor: '#d3dbf2',
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.common.white,
        },
      },
    },
  }))(MenuItem);


  // Styles for the Select component
const input_styles = {
    input: {
      backgroundColor: 'white',
      border: styledBy('color', {
        default: '1px solid #ced4da',
        error: '1px solid red',
        placeholder: '1px solid #ced4da',
      }),
      color: styledBy('color', {
        default: 'black',
        placeholder: 'gray',
      }),
      fontSize: '12px',
      borderRadius: `10px`,
      padding: '10px',
      height: '34px',
      width: '100%',
      '&:focus': {
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  };
  
  const SelectInput = withStyles(input_styles)(({ classes, color, ...other }) => (
    <InputBase className={classes.input} {...other} />
  ));

// styles for the menu dropdown
const useStyles = makeStyles((theme) => ({
    root: {
     // display: 'flex',
     // flexWrap: 'wrap',
     // zIndex: theme.zIndex.modal,
   },
    paper: {
      boxShadow: 'none',
      color: '#586069',
      fontSize: 12,
      background: 'white',
    },
    option: {
      minHeight: 'auto',
      alignItems: 'flex-start',
     // overflowWrap:'anywhere',
     // wordWrap: 'break-word',
      hyphens: 'auto',
      padding: 8,
      '&[data-focus="true"]': {
       backgroundColor: '#d3dbf2'
      },
    },
    popper: {
      border: '1px solid rgba(27,31,35,.15)',
      boxShadow: '0 3px 12px rgba(27,31,35,.15)',
      borderRadius: 10,
      zIndex: theme.zIndex.modal,
      fontSize: 12,
      background:'white',
      top: '0px',
      marginTop: 5,
      marginBottom: 5
    },
    selectMenu: {
      marginTop:'10px',
    },
  }));

export const AddProductForm = ({handleSubmit, productTypes, handleChangeType, product_type, units, handleChangeUnit,unit_name,
    state, handleChange, submitted, categories, category_name, handleChangeCategory,handleChangeLevel,
    isPending, countryArr,formRef, isResolved, setIsBlocking,
    }) =>{
    const {country_of_origin,reorder_level} = state;

// console.log(state)

    const classes = useStyles();
    return(
        <form autoComplete="off" ref={formRef} onSubmit={handleSubmit}>
        <div className="row">
            <div className="col-md-6">
                <Form.Group as={Row}>
                    <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                        <Label name="Category"  type/>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                        <AutoCompleteControlled options={categories} handleChange={handleChangeCategory}   
                                                submitted={submitted} testId="category_name"
                                                medicine_value={category_name}  placeholder="Please select category"
                                                errorMsg='Please select category'  value_text='value' label_text='label' />
                    </Col>
                </Form.Group>

                {category_name.label === 'Medicine' &&
                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                            <Label name="Generic Name"  htmlFor='generic_name'/>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                            <CustomInput name='generic_name' setIsBlocking={setIsBlocking} inputValue={state['generic_name']} id="generic_name" type='text' placeholder='Enter generic name' reset={isResolved}/>

                        </Col>
                    </Form.Group>
                }
                <Form.Group id='product_type' as={Row}>
                    <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                        <Label name="Product Type"/>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                        <AutoCompleteControlled reset={isResolved} options={productTypes} handleChange={handleChangeType}  
                                                 testId="product_type"
                                                medicine_value={product_type}  placeholder="Select product type..."
                                                errorMsg='Product Type is required'  value_text='value' label_text='label'  />
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                        <Label name="Product Location"   htmlFor='product_location'/>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                        <CustomInput name='product_location' setIsBlocking={setIsBlocking} inputValue={state['product_location']}
                                     type='text' placeholder='Enter product location'  reset={isResolved}/>

                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                        <Label name="Price" htmlFor='price'/>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                        <CustomInput name='price' setIsBlocking={setIsBlocking} inputValue={state['price']}  isRequired submitted={submitted} type='number' placeholder="Input price"  reset={isResolved}/>

                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                        <Label name="Description"  htmlFor='product_detail'/>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                        <CustomResizableTextarea name='product_detail' setIsBlocking={setIsBlocking}
                                                 inputValue={state['product_detail']} reset={isResolved}
                                                 rows={2} placeholder="Write description...."/>

                    </Col>
                </Form.Group>

            </div>
            <div className="col-md-6">
                <Form.Group as={Row}>
                    <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                        <Label name="Product Name" type htmlFor='product_name'/>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                        <CustomInput name='product_name' setIsBlocking={setIsBlocking} inputValue={state['product_name']} isRequired
                                     submitted={submitted} type='text' placeholder="Enter product name"  reset={isResolved}/>
                    </Col>
                </Form.Group>

                {category_name.label === 'Medicine' &&
                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                            <Label name="Strength" htmlFor='strength'/>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                            <CustomInput name='strength' setIsBlocking={setIsBlocking}
                                         inputValue={state['strength']}  type='text'
                                         placeholder="Enter medicine strength" reset={isResolved}/>

                        </Col>
                    </Form.Group>}
                <Form.Group  as={Row}>
                    <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                        <Label name="Unit" type/>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                        <AutoCompleteControlled reset={isResolved} options={units} handleChange={handleChangeUnit}  submitted={submitted}
                                                medicine_value={unit_name}  placeholder="Select unit..." testId='product_unit'
                                                errorMsg='Unit is required'  value_text='value' label_text='label' />
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                        <Label name="Box Size" htmlFor='box_size'/>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                        <CustomInput name='box_size' setIsBlocking={setIsBlocking}
                                     inputValue={state['box_size']} type='text' placeholder="Enter box size"
                                     reset={isResolved}/>
                    </Col>
                </Form.Group>

                <Form.Group id='country_of_origin' as={Row}>
                    <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                        <Label name="Country of Origin" htmlFor="country_of_origin"/>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                        <Select
                            value={country_of_origin}
                            onChange={handleChange}
                            name="country_of_origin"
                            input={
                                <SelectInput color={'default'} />}
                        
                                MenuProps={{
                                    getContentAnchorEl: null,
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    classes:{
                                      paper: classes.selectMenu,
                                    },
                                    MenuListProps:{
                                        // onMouseEnter: handleHover,
                                        // onMouseLeave: (event)=> handleCloseHover(item),
                                         style: {  
                                             margin:'0px',
                                          height:'200px',
                                            padding:'2px',
                                            borderRadius:'20px',

                                        }
                                         
                                    }
                                }}

                                
                        >
                   
                            {!!countryArr?.length &&
                            countryArr.map(({ label, value }) => (
                                <StyledMenuItem key={value} value={value}>
                                   
          
          <ReactCountryFlag countryCode={value} svg  style={{marginRight:'10px'}}/>
        
                                {label}
                                </StyledMenuItem>
                            ))}
                        </Select>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                            <Label name="Reorder Level"  htmlFor='reorder_level'/>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                            <CustomInput name='reorder_level' inputValue={reorder_level} data-testid="reorder_level" type='number' placeholder='Enter reorder level' reset={isResolved}/>

                        </Col>
                    </Form.Group>
            </div>
        </div>
        <button type="submit" disabled={isPending}
                className="btn sms-btn btn-sm px-3">{isPending === 'pending' ? "Saving..." : "Save"}</button>
    </form>
    )

}


