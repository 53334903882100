import React from "react";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CustomTable from "../../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import {getComparator, stableSort} from "../../Utils/Tables/sortingFunctions";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import *  as snackbarActions from "../../../actions/snackbarActions";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {MainSearchComponent} from "../MainSearch";
import {faClipboardList} from "@fortawesome/free-solid-svg-icons/faClipboardList";
import {useReusableInventory} from "./CustomHooks/useReusableInventory";
import {convertMonth} from "../../Utils/ConvertDate";
import { exportPDF } from "../../Utils/downloadPDF";
import {DataExportButton} from "../../Utils/Buttons/DataExportationButton";

const headData = [
    {id: 'sn', numeric: false, disablePadding: false, label: 'SN.'},
    {id: 'name', numeric: false, disablePadding: false, label: 'Product'},
    {id: 'unit', numeric: false, disablePadding: false, label: 'Unit'},
    {id: 'quantity', numeric: false, disablePadding: false, label: 'Quantity Consumed(units)'},
    {id: 'days', numeric: false, disablePadding: false, label: 'Days out of Stock'},
    {id: 'stock', numeric: false, disablePadding: false, label: 'Stock on Hand'},
    {id: 'expired', numeric: false, disablePadding: false, label: 'Quantity Expired'}
];


const StockStatusReport = ({actions, snackbars}) => {
    const {data, search_type, text, textType, year, date,csvRef,downloadCsv,
        isSubmitted, submitted, searchValue, order, orderBy, loading, total_count,
        handleChangeActivePage, handleCustomSearch, activePage, closeSnackbar,   handleRequestSort,
        handleChangeSearch, handleChangeDate, handleChangeYear, handleIncrementYear, handleSubmit,inputType
    } = useReusableInventory(actions, 'stock_status')
    const {start_date, end_date} = date;
    const {openBar, type, message} = snackbars;
    const stock = data.data ?? [];

    const downloadPdf = () => {
        const {end_date, start_date} = date
        const time = search_type === '0' ? start_date : search_type === '1' ? convertMonth(start_date) :
            search_type === '2' ? year : `${start_date} to ${end_date}`;
        exportPDF(`Stock Status (${time})`, [['SN', "Product", "Unit", "Quantity Consumed",
                "Days out of Stock", "Stock on Hand","Quantity Expired"]],
            stock.map((item, index) => [index++, item.product_name, item.unit_name, item.quantity, item.days,
            item.stock_on_hand, item.quantity_expired]),
            `Stock Status (${time}).pdf`);
    }

    const headers = [{label: 'Product', key: 'product_name'}, {label: 'Unit', key: 'unit_name'},
        {label: 'Quantity Consumed', key: 'quantity'},{label: 'Days out of Stock', key: 'days'},
        {label: 'Stock on Hand', key: 'stock_on_hand'},{label: 'Quantity Expired', key: 'quantity_expired'}]
    return (
        <div className='journals'>
            <PageTitle title="Stock Status"/>
            <SubHeader title="Reports" subTitle="Stock Status">
                <FontAwesomeIcon icon={faClipboardList}/>
            </SubHeader>
            <div className='general-ledger-header'>
                <MainSearchComponent text={text} type={textType} changeDate={handleChangeDate}
                                     handleSubmit={handleSubmit} isSubmitted={isSubmitted}
                                     changePeriod={handleChangeSearch} end_date={end_date}
                                     handleChangeValue={handleChangeYear} submitted={submitted}
                                     search_type={search_type} handleChangeYear={handleIncrementYear}
                                     start_date={start_date} year={year} inputType={inputType}>
                    <DataExportButton headers={headers} title='Stock Status'
                                      csvRef={csvRef} data={stock} downLoadPdf={downloadPdf}
                                      exportCsv={downloadCsv}/>
                </MainSearchComponent>
            </div>
            <div className="mui-tables mt-2">
                <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>
                <CustomTable title="" handleChangeNextPage={handleChangeActivePage} term={searchValue}
                             records={10}
                             headData={headData} handler={handleCustomSearch} total_count={total_count}
                             activePage={activePage}
                             handleRequestSort={handleRequestSort} data={stock} colSpan={5} order={order}
                             orderBy={orderBy} customPage>
                    {loading ? <TableBody><TableRow>
                        <TableCell colSpan="8" align="center"><img src="/images/smsloader.gif"
                                                                   alt="loader"/></TableCell>
                    </TableRow></TableBody> : stock.length > 0 ?
                        <TableBody>
                            {stableSort(stock, getComparator(order, orderBy))
                                .slice(0, 10)
                                .map((item, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={index}
                                            className='mui-table-row'>
                                            <TableCell>{item.product_name}</TableCell>
                                            <TableCell>{item.quantity}</TableCell>
                                            <TableCell>{item.sale_price}</TableCell>
                                            <TableCell>{item.purchase_price}</TableCell>
                                            <TableCell>{item.sale_quantity}</TableCell>
                                            <TableCell>{item.purchase_quantity}</TableCell>
                                            <TableCell>{item.total_amount}</TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody> : <TableBody><TableRow>
                            <TableCell colSpan="8" align="center">No records found</TableCell>
                        </TableRow></TableBody>}
                </CustomTable>

            </div>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(StockStatusReport);
