import React from "react";
import {useTheme} from "@material-ui/core/styles";
import Form from "react-bootstrap/Form";
import Label from "../Utils/FormInputs/Label";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from "react-bootstrap/InputGroup";
import {CustomSelect} from "../Utils/FormInputs/SelectInput";
import moment from "moment";

const validateOpDate = (date) => {
    if (date) {
        var d1 = new Date();
        d1.setHours(0,0,0,0)
        var d2 = new Date(date);
        return (d2 > d1);
    } 
    return false;
}

const validateOpDateStartTime = (date='', time='') => {
    if (date && time) {
        const d1 = new Date();
        const d2 = new Date(date + " " + time);
        return (d2 >= d1);
    } else if (time) {
        const d1 = new Date();
        const d = moment().format('L');
        const dd = moment(d +' '+ time).format();
        const d2 = new Date(dd);
        return (d2 >= d1);
    }
    return false;
}

const checkDuration = (hr, mins) => {
    if (hr || mins) {
        if ((hr === "0" || hr === "00") && (mins === "0" || mins === "00")) {
            return false;
        } return true; 
    } return false;
}

export const PatientDetailsSurgery = ({p_number, patientDetails}) => {
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    const {first_name, last_name, gender, address} = patientDetails;

    return (
        <div className={`surgery-components  ${palette.type === 'dark' && 'dark-patient-information'} `}>
            <div className="mt-2">  
                <table className="table-surgery-patient" style={{width:"100%", marginBottom:"15px"}}>
                    <thead>
                        <tr>
                            <td align='center'>Patient Number</td>
                            <td align='center'>Patient Name</td>
                            <td align='center'>Age</td>
                            <td align='center'>Gender</td>
                            <td align='center'>Phone</td>
                            <td align='center'>Address</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{p_number}</td>
                            <td>{`${first_name} ${last_name}`}</td>
                            <td>{patientDetails.Age}</td>
                            <td>{gender}</td>
                            <td>{patientDetails.phone_no}</td>
                            <td>{address}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )

    
}

/**
 * Component to capture the patients details and schedule the surgery
 * @param params
 */
const ScheduleSurgery = ({
    p_number, patientDetails, patientNumbers, submitted, showNumber, handleChangePatientNumbers, handleClickPatientNumber,
    operation_date, estimated_start_time, duration_hr, duration_mins, operation_class, operationClasses, handleOpClassChange, onChange,
    surgery_name, handleSurgeryTypeChange, surgeryTypes, doctorsModule=false, completeSurgery=false
}) => {

    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    const {first_name, last_name,age, gender, address, phone} = patientDetails;

    return (
        <div className={`surgery-components  ${palette.type === 'dark' && 'dark-patient-information'} `}>
            <div className="mt-2">
               {!doctorsModule ?  
                    <> 
                        <table className="table-surgery-patient" style={{width:"100%", marginBottom:"15px"}}>
                            <thead>
                                <tr>
                                    <td align='center'>Patient Number</td>
                                    <td align='center'>Patient Name</td>
                                    <td align='center'>Age</td>
                                    <td align='center'>Gender</td>
                                    <td align='center'>Phone</td>
                                    <td align='center'>Address</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {completeSurgery ? 
                                            <>
                                            <td>{p_number}</td>
                                            </>
                                        :
                                        <>
                                        <Form.Control type="text" autoComplete="off" className={`form__control ${theme.palette.type === "dark" && 'form-control-dark'}  surgery-details`}
                                            name="p_number" value={p_number} onChange={handleChangePatientNumbers}/>
                                        {   // check if it present
                                            (submitted && !p_number) && <div className="help-block">Patient ID is required</div>
                                        }
                                        {   // dropdown for the patients
                                            showNumber && 
                                            <Form.Control as="select" multiple id="patient_number_select" className="drug-ul visit-ul">
                                                {patientNumbers.map((number, index) => (
                                                    <option className="drug-li" key={index} onClick={handleClickPatientNumber} value={number.value} id="numbers">
                                                        {number.label}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        }                    
                                        </>                    
                                        }

                                    </td>
                                    <td>{`${first_name} ${last_name}`}</td>
                                    {completeSurgery ? <><td>{patientDetails.Age}</td></> : <><td>{age}</td></> }
                                    <td>{gender}</td>
                                    {completeSurgery ? <><td>{patientDetails.phone_no}</td></> : <><td>{phone}</td></> }
                                    <td>{address}</td>
                                </tr>
                            </tbody>
                        </table>
                    </>
               : null} 

                <h6 className='patient-surgery-details'>Surgery Details</h6>
                <Form.Group as={Row} className="surgery-schedule-flex">
                    <Col xs={12} sm={6} md={6} lg={4} xl={4}>
                        <Label name="Surgery to be performed" className="surgery-details" type/>
                        <CustomSelect id='surgery_name' options={surgeryTypes} onChange={handleSurgeryTypeChange} value={surgery_name} autoComplete="off" /> 
                        {(submitted && surgery_name.length === 0) ? <div id='surgery_name_required'  className="help-block">Surgery name is required</div> : null}                      
                    </Col>

                    <Col xs={12} sm={6} md={6} lg={4} xl={4}>
                        <Label name="Operation Class" className="surgery-details" type/>
                        <CustomSelect id="operation_class" options={operationClasses} onChange={handleOpClassChange} value={operation_class} autoComplete="off"/>
                        {(submitted && (!operation_class['value'])) &&
                        <div id="operation_class_required" className="help-block">Operation Class is required</div>}  
                    </Col>

                    <Col xs={12} sm={6} md={6} lg={4} xl={4}>
                        <Label name="Operation Date" className="surgery-details" type/>
                        <Form.Control type="date" rows="6"  name="operation_date" value={operation_date} onChange={onChange} 
                        className={`form__control ${palette.type === "dark" && 'form-control-dark'}`} autoComplete="off"/>
                        {(submitted && !operation_date) && <div id="operation_date_required" className="help-block">Operation date is required</div>}
                        {(submitted && operation_date && !validateOpDate(operation_date)) ? 
                        <div className="help-block">The earliest operation date is today</div> : null}
                    </Col>

                    <Col xs={12} sm={6} md={6} lg={4} xl={4}>
                        <Label name="Estimated Start Time" className="surgery-details" type/>
                        <Form.Control type="time" rows="4"  name="estimated_start_time" autoComplete="off"
                                defaultValue={estimated_start_time}
                                onChange={onChange}
                                className={`form__control ${palette.type === "dark" && 'form-control-dark'}`}/>
                        {(submitted && !estimated_start_time) &&
                        <div id="start_time_required" className="help-block">Start time is required</div>}  
                        {(submitted && estimated_start_time && (!validateOpDateStartTime(operation_date, estimated_start_time))) ? <div className="help-block">Start time should be later than current time</div> : null}
                    </Col>

                    <Col xs={12} sm={6} md={6} lg={4} xl={4}>
                        <Label name="Estimated Duration" className="surgery-details" type/>
                        <InputGroup className="mb-2">
                            <Form.Control type="number" value={duration_hr} name="duration_hr" onChange={onChange} min={0} className="left-border-radius" autoComplete="off"/>
                            <InputGroup.Text size="sm" className="zero-border-radius-txt">Hrs</InputGroup.Text>
                            <Form.Control type="number" value={duration_mins} name="duration_mins" onChange={onChange} min={0} className="zero-border-radius" autoComplete="off"/>
                            <InputGroup.Text size="sm" className="right-border-radius-txt">Mins</InputGroup.Text>
                        </InputGroup>
                        {(submitted && !(checkDuration(duration_hr, duration_mins))) ? <div id="duration_required" className="help-block">Duration is required</div> : null}                              
                    </Col>
                </Form.Group>
            </div>
        </div>
    )
};

export default ScheduleSurgery;