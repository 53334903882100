import React from 'react';
import {CustomSelect} from "../../Utils/FormInputs/SelectInput";
import {ErrorMessage} from "../../Utils/styledComponents"
import Form from 'react-bootstrap/Form';
import TextField from '../../Utils/FormInputs/TextField';


function ProductLedgerForm({rows,  isAllProducts,
    accounts, submitted,
    handleChangeCreditAccount, handleChangeDebitAccount, handleSubmit,
    isPending,  handleAddRow, handleRemoveRow,
    retrieveProducts, clickProduct,products,}) {
  return (
        <form onSubmit={handleSubmit} autoComplete='off'>
            {/* <table  className='table table-borderless table-sm'>
                <tbody>
                    <tr>
                        <td width='220px' style={{verticalAlign:'middle'}}>
                            <Form.Check value={isAllProducts} onChange={handleChange} label={`Same accounts for all products`}/>
                        </td>
                        <td width='100px' valign='middle' style={{verticalAlign:'middle'}}> Debit Account</td>
                        <td width='300px'>
                            <CustomSelect value={debitAccount} options={accounts} isDisabled={!isAllProducts}
                                            onChange={handleChangeAllDebitAccount}/>
                        </td>
                        <td width='20px'/>
                        <td width='100px' style={{verticalAlign:'middle'}}>Credit Account</td>
                        
                        <td width='300px'>
                            <CustomSelect value={creditAccount} options={accounts} isDisabled={!isAllProducts}
                                            onChange={handleChangeAllCreditAccount}/>
                        </td>
                    </tr>
                </tbody>
            </table> */}
            <div className='table-responsive'>
                <table className="table table-bordered">
                    <thead>
                    <tr>
                        <td width='400px'>Product<span className="help-block">*</span></td>
                        {/* <td>Memo</td> */}
                        <td>Debit<span className="help-block">*</span></td>
                        <td>Credit<span className="help-block">*</span></td>
                        <td width='100px'>Action</td>
                    </tr>
                    </thead>
                 
                    <tbody>
                    {rows.map((item, index) => (
                        <tr key={index}>
                            <td>
                                <TextField  {...{submitted}} value={item.name} type="text" name="name" disabled={isAllProducts}

                                            onChange={(e) => retrieveProducts(e, index)} placeholder="Enter product name"/>
                                {(submitted && !item.name) ? <ErrorMessage>Product name is required</ErrorMessage>:
                                    item.isError && <ErrorMessage>Please enter a valid product</ErrorMessage>}
                                {item.isOpen &&
                                    <Form.Control as="select" className="drug-ul prescription-ul" multiple>
                                        {products.map((val, idx) => (
                                            <option key={idx} value={val.value} className="drug-li "
                                                    onClick={() => clickProduct({value:val.value,innerText:val.label}, index)}>{val.label}</option>
                                        ))}
                                    </Form.Control>}
                            </td>
                            <td>
                                <CustomSelect value={item.debit_account} options={accounts} isDisabled={isAllProducts}
                                              onChange={value => handleChangeDebitAccount(value, index)} submitted={submitted}/>
                                            {(submitted && !item.debit_account) ? <ErrorMessage>Debit account is required</ErrorMessage>:null}
                            </td>
                            <td>
                                <CustomSelect value={item.credit_account} options={accounts} isDisabled={isAllProducts}
                                              onChange={value => handleChangeCreditAccount(value, index)} submitted={submitted}/>
                                            {(submitted && !item.credit_account) ? <ErrorMessage>Credit account is required</ErrorMessage>:null}
                            </td>
                            <td>
                                <button type='button' onClick={()=>handleRemoveRow(index)} className='btn btn-sm sms-btn-dismiss'>Remove</button>
                            </td>
                        </tr>
                    ))}
                    <tr>
                        <td colSpan={4}> <button type='button' onClick={handleAddRow} className='btn btn-sm sms-gray-btn'>Add another line</button></td>

                    </tr>
                    </tbody>
                </table>
            </div>

            <button type='submit' disabled={isPending}  className='btn btn-sm sms-btn'>{isPending ? 'Submitting...':'Submit'}</button>
        </form>
  )
}

export default ProductLedgerForm