import {useEffect, useState} from "react";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {convertPhone} from "../../Utils/convertPhone";
import moment from "moment";

export const useAddSchedule = (actions, _token, patient_name='', patient_phone_no='') => {
    const [state, setState] = useState({
        status: '1',
        start_period: '',
        end_period: '',
        patient_time: '',
        slot_name: patient_name,
        doctor_name: '',
        description:'',
        patient_phone_no:patient_phone_no
    });
    const [staff_name, setStaffName] = useState('');
    const [employees, setEmployees] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [phone, setPhone] = useState(undefined)
    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;
    const [openAddDialog, setOpenAddDialog] = useState(false)

    useEffect(() => {
        axios.get(`${config.smsUrl}/chumanresource/get_all_employees`).then(res => {
            const data = res.data;
            const dt = !data ? [] : data;
            const arr = dt.map(employee => ({
                label:
                    `${employee.first_name} ${employee.last_name}`, value: employee.employee_id
            }))
            setEmployees([...arr, {value: 'other', label: 'Other'}]);
        }).catch((err) => {
            logoutErrorMessage(err, null, actions)
        })
    }, []);


    const handleChange = event => {
        const {name, value} = event.target;
        setState({...state, [name]: value})
    };

    const handleChangeStaff = (value) => {
        setStaffName(value);
        if (value.value !== 'other') {
            const formData = new FormData();
            formData.append('employee_id', value.value);
            axios.post(`${config.smsUrl}/chumanresource/employee_update_form`, formData).then(res => {
                const data = res.data;
                const dt = !data ? {} : data;
                const {phone_no} = dt;
                setPhone(phone_no ?? phone_no)
            }).catch(error => {
                errorMessages(error, null, actions)
            })
        }else {
            setPhone(undefined)
        }

    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setSubmitted(true);
        const {patient_time, start_period, end_period, slot_name, doctor_name, description} = state;
        const date = new Date();
        const current_date = moment(date).format().split('T')[0];
        const formData = new FormData();
        const phone_no = phone ? convertPhone(phone) : phone;
        
        formData.append('slot_name', patient_phone_no ? `${slot_name} - ${patient_phone_no}` : slot_name);
        formData.append('name', staff_name.value === 'other' ? doctor_name : staff_name.label);
        formData.append('user_roles_id', user_roles_id);
        formData.append('phone', phone_no ? phone_no : phone);
        formData.append('slot_date', patient_time);
        formData.append('start_time', start_period);
        formData.append('end_time', end_period);
        formData.append('description', description);
        if (start_period && end_period && staff_name && slot_name && phone && patient_time && patient_time >= current_date && end_period >= start_period) {
            setIsSubmitted('pending');
            axios.post(`${config.smsUrl}/cshiftschedule/add_schedule`, formData).then(() => {
                actions.snackbarActions.snackSuccess('Schedule added successfully');
                setOpenAddDialog(false);
                setIsSubmitted('resolved');
            }).catch(err => {
            
                errorMessages(err, null, actions)
               setIsSubmitted('rejected');
            })
        }

    };

    const handleReset = () => {
        setState({patient_time: '', status: '1', start_period: '', end_period: ''});
        setStaffName(null);
        setPhone(undefined);
    };

    const handleCloseBar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const handleOpenAddDialog=()=>{
        setOpenAddDialog(true)
    }

    const handleCloseAddDialog=()=>{
        setOpenAddDialog(false)
    }

    const isPending = isSubmitted === 'pending'
    const isResolved = isSubmitted === 'resolved'
    const isRejected = isSubmitted === 'rejected'

     const addScheduleProps = { state, staff_name, employees, submitted, isSubmitted, handleChange, handleChangeStaff,
        handleSubmit, handleReset, handleCloseBar, setState, phone, setPhone, openAddDialog, handleOpenAddDialog, handleCloseAddDialog, isPending, isRejected, isResolved}
    return {
        addScheduleProps
    }

}
