/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";
import React, { useEffect, useState } from "react";
import { SubHeader } from "../../../Containers/SubHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PageTitle from "../../Utils/smsTitle";
import {
  Button,
  RightAlignedContainer,
  MiniCenteredDiv,
} from "../../Utils/styledComponents";
import { PrintTemplate, ItemsTable } from "../../Utils/Templates/PrintTemplate";
import { usePrint } from "../../Utils/Templates/usePrint";
import * as colors from "../../../styles/colors";
import {
  dateStrokeConvert,
  formatDate,
  getCurrentYear,
} from "../../Utils/ConvertDate";
import Card from "@material-ui/core/Card";
import { LoadingGif } from "../../Utils/Loader";
import {
  coloredInvoice,
  textWeight,
  thUppercase,
  printTable,
} from "../../../styles/tableStyles";
import { BackButton } from "../../Utils/Buttons/DataExportationButton";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {
  errorMessages,
  logoutErrorMessage,
} from "../../../Helpers/ErrorMessages";
import { bindActionCreators } from "redux";
import * as snackbarActions from "../../../actions/snackbarActions";
import { connect } from "react-redux";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import { faMoneyBill } from "@fortawesome/free-solid-svg-icons/faMoneyBill";
import { CurrencyToggle, FxRate } from "../../Utils/CurrencyToggle";
import { useCurrency } from "../../../Context/currency-context";
import DeleteDialog from "../../Utils/Dialogs/DeleteDialog";
import { useVersionsContext } from "../../../Context/versions-context";

const borderless = css`
  border-bottom: 0;
`;

const tableMargin = css`
  margin-bottom: 0;
`;

function ExpenseReceiptDetails({ actions, snackbar, match }) {
  const { currency: selectedCurrency } = useCurrency({
    actions,
    snackbar,
    match,
  });
  const { expense_id, delete_expense } = match.params;
  const [receipt, setReceipt] = useState({});
  const [total, setTotal] = useState({ usd_total: 0, ssp_total: 0 });
  const [items, setItems] = useState([]);
  const [status, setStatus] = useState("idle");
  const [exchangeRate, setExchangeRate] = useState(0);
  const [currencyExchangeRate, setCurrencyExchangeRate] = useState(0);
  const [currency, setCurrency] = useState("");
  const [currencies, setCurrencies] = useState([]);
  const [isdeleteexpense, setIsDeleteExpense] = useState(
    delete_expense == "true" ? true : false
  );

  const { subscription } = useVersionsContext();
  const version = subscription?.version;
  const isPremium = version === "Premium";

  const handleclose = () => {
    setIsDeleteExpense(false);
  };
  const handledeleteexpense = () => {
    setIsDeleteExpense(false);

    const formData = new FormData();
    formData.append("expense_id", expense_id);
    axios
      .post(`${config.smsUrl}/accounting/cancel_expense`, formData)
      .then((res) => {
        actions.snackbarActions.snackSuccess("Expense deleted successfully..");
        setIsDeleteExpense(false);
      })
      .catch((e) => {
        setIsDeleteExpense(false);
        errorMessages(e, null, actions);
      });
  };

  // get currencies
  useEffect(() => {
    axios
      .get(`${config.smsUrl}/accounting/currencies`)
      .then((res) => {
        const data = res.data;
        const all_data = !data ? {} : data;
        const arr = all_data.data ?? [];
        const list = arr.map((item) => ({
          value: `${item.currency_symbol}-${item.is_base_currency}-${item.id}`,
          label: `${item.currency_symbol} ${item.currency_name}`,
        }));
        setCurrencies(list);
      })
      .catch((err) => {
        logoutErrorMessage(err, null, actions);
      });
  }, []);

  const displayList = (arr, ex_rate) => {
    const expenseItems = arr.map((item) => ({
      ...item,
      amount_in_currency: ex_rate
        ? item.amount_in_base_currency / ex_rate
        : item.amount_in_currency,
    }));

    const total_amount_usd = expenseItems.reduce(
      (item, acc) => +item + acc.amount_in_base_currency,
      0
    );
    const total_amount_ssp = expenseItems.reduce(
      (item, acc) => +item + acc.amount_in_currency,
      0
    );
    setItems(expenseItems);
    setTotal({ usd_total: total_amount_usd, ssp_total: total_amount_ssp });
  };

  const currenciesFetched = currencies.length > 0;

  useEffect(() => {
    if (currencies.length === 0) {
      return;
    }
    setStatus("pending");
    const formData = new FormData();
    formData.append("expense_id", expense_id);
    axios
      .post(`${config.smsUrl}/accounting/view_expenses_details`, formData)
      .then((res) => {
        const data = res.data;
        const all_data = !data ? {} : data;
        const arr = !all_data.data ? {} : all_data.data;
        const currency_id = arr[0]?.currency_id ?? "";
        const currency_rate = arr[0]?.currency_rate ? arr[0]?.currency_rate : 0;
        const ex_rate = currency_rate > 0 ? Math.pow(currency_rate, -1) : 0;
        const isCurrency = currencies
          .filter((item) => item?.value?.split("-")[2] === `${currency_id}`)
          .reduce((prev, curr) => ({ ...prev, ...curr }), {});
        const symbol = isCurrency?.value
          ? isCurrency.value.split("-")[0]
          : selectedCurrency.currency_symbol;
        setCurrency(isCurrency?.value);
        setReceipt({ ...arr[0], currency_symbol: symbol });
        setExchangeRate(currency_rate);
        setCurrencyExchangeRate(ex_rate);
        displayList(arr);
        setStatus("resolved");
      })
      .catch((e) => {
        setStatus("rejected");
        errorMessages(e, null, actions);
      });
  }, [currenciesFetched]);

  const handleChangeCurrency = (e) => {
    setCurrency(e.target.value);
    // setExchangeRate(0)
  };

  //Change exchange rate
  const handleChangeExchangeRate = (event) => {
    const ex_rate = +event.target.value > 0 ? 1 / +event.target.value : 0;
    setExchangeRate(ex_rate);
    setCurrencyExchangeRate(event.target.value);
    displayList(items, ex_rate);
  };

  const closeSnackbar = () => {
    actions.snackbarActions.hideSnackbar();
  };

  const isPending = status === "pending";
  const isResolved = status === "resolved";
  const isRejected = status === "rejected";

  const currency_symbol = currency
    ? currency.split("-")[0]
    : selectedCurrency.currency_symbol;

  const headData = [
    { key: "expense", value: "Expense" },
    { key: "memo", value: "Memo" },
    { key: "amount", value: `Amount(${currency_symbol})` },
  ];

  const { componentRef, handlePrint, fontSize } = usePrint(
    `InvoiceReceipt${receipt.patient_number}`
  );
  const { openBar, type, message } = snackbar;

  const isBaseCurrency = currency && currency.split("-")[1] === "true";

  const expenseDetails = (
    <tbody>
      <tr>
        <DeleteDialog
          message="delete"
          openDialog={isdeleteexpense}
          handleClose={handleclose}
          text="delete"
          title="Delete Expense"
        >
          <button
            className="btn sms-info-btn btn-sm mr-3"
            onClick={handledeleteexpense}
          >
            Continue
          </button>
          <button className="btn sms-grey-btn btn-sm" onClick={handleclose}>
            No
          </button>
        </DeleteDialog>
        <td css={[thUppercase]}>
          <span className="span-heading">Date: </span>{" "}
          <span css={[textWeight]}>
            {receipt.expense_date
              ? dateStrokeConvert(receipt.expense_date)
              : ""}
          </span>
        </td>
        <td css={[thUppercase]}>
          <span className="span-heading">Voucher#: </span>{" "}
          <span css={[textWeight]}>
            {/* {receipt.id ? `${receipt.id}/${getCurrentYear()}` : ""} */}
            {receipt.voucher_no}
          </span>
        </td>
      </tr>
      <tr>
        <td css={[thUppercase]}>
          <span className="span-heading">Payment Account: </span>{" "}
          <span css={[textWeight]}>
            {receipt.billed_to ? receipt.billed_to : ""}
          </span>
        </td>
        <td css={[thUppercase]}>
          <span className="span-heading"> Total Amount: </span>{" "}
          <span css={[textWeight]}>
            {" "}
            {receipt?.currency_symbol}{" "}
            {isBaseCurrency
              ? total.usd_total.toFixed(3)
              : total.ssp_total.toFixed(3)}
          </span>
        </td>
      </tr>

      <tr>
        <td css={[thUppercase]}>
          <span className="span-heading">Remarks: </span>{" "}
          <span css={[textWeight]}>
            {receipt.remarks ? receipt.remarks : ""}
          </span>
        </td>
        <td css={[thUppercase]} />
      </tr>
    </tbody>
  );
  const itemDetails = (
    <ItemsTable
      {...{
        headData,
        coloredTableHead: coloredInvoice,
        tableHeading: printTable,
        capitalize: thUppercase,
      }}
    >
      <tbody>
        {items.map((item) => {
          return (
            <tr key={item.id}>
              <td className="table-items">
                <span>{item.expense_name}</span>
              </td>
              <td className="table-items">
                <span>{item.memo}</span>
              </td>
              <td className="table-items">
                <span>
                  {isBaseCurrency
                    ? item.amount_in_base_currency.toFixed(3)
                    : item.amount_in_currency.toFixed(3)}
                </span>
              </td>
            </tr>
          );
        })}
      </tbody>
    </ItemsTable>
  );

  const printSections = (
    <>
      <BackButton
        to="/accounting/expenses"
        text="Expenses"
        data-testid="expense-receipt-back-button"
      />
      <RightAlignedContainer>
        <Button variant="orange" onClick={handlePrint}>
          Print
        </Button>
      </RightAlignedContainer>
    </>
  );
  return (
    <div>
      <PageTitle title="Expenses" />
      <SubHeader title="Accounting" subTitle="Expenses">
        <FontAwesomeIcon icon={faMoneyBill} />
      </SubHeader>

      <MainSnackbar
        variant={type}
        message={message}
        open={openBar}
        handleCloseBar={closeSnackbar}
      />
      {isPending ? <LoadingGif /> : null}
      {isResolved ? (
        <MiniCenteredDiv>
          {printSections}
          <Card className="mt-2">
            {isPremium ? (
              <div
                style={{
                  padding: "8px 60px",
                  borderBottom: "1px solid rgba(224, 224, 224, 1)",
                }}
              >
                <RightAlignedContainer>
                  <CurrencyToggle
                    options={currencies}
                    value={currency}
                    handleChangeCurrency={handleChangeCurrency}
                  />
                  {!isBaseCurrency ? (
                    <FxRate
                      currency={selectedCurrency.currency_symbol}
                      value={currencyExchangeRate}
                      handleChangeRate={handleChangeExchangeRate}
                      nonBaseCurrency={currency_symbol}
                    />
                  ) : null}
                </RightAlignedContainer>
              </div>
            ) : null}
            <div
              ref={componentRef}
              style={{ padding: "20px" }}
              className="requisition-receipt"
            >
              <style>{fontSize()}</style>
              <PrintTemplate
                tableHeading={printTable}
                title="Expense Voucher"
                {...{
                  patientDetails: expenseDetails,
                  itemDetails,
                  bioBordered: borderless,
                  bioMargin: tableMargin,
                }}
              />
            </div>
          </Card>
        </MiniCenteredDiv>
      ) : null}
      {isRejected ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p>The server did not return a valid response</p>
        </div>
      ) : null}
    </div>
  );
}

function mapStateToProps(state) {
  const { snackbar } = state;
  return { snackbar };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      snackbarActions: bindActionCreators(snackbarActions, dispatch),
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExpenseReceiptDetails);
