import React, {useRef, useState} from 'react';
import axios from 'axios';
import { config } from '../../../Helpers/env'
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {formatPhoneNumber} from 'react-phone-number-input'


export const useAddCompany = (actions) => {
    const [state, setState] = useState({name: '', email: '', logo: '', address: '', website: ''});
    const [logo, setLogo] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [contact, setContact] = useState();
    const [error, setError] = useState(null)
    const ref = useRef()
    const fileRef = useRef()

    const handleChange = (event) => {
        const {value, name} = event.target;
        setState({...state, [name]: value})
    }

    const handleChangeLogo = (event) => {
        let {files} = event.target;
        if (files[0].name.substr(files[0].name.length - 4) !== ".csv" ||
            files[0].name.substr(files[0].name.length - 5) !== ".xlsx" ||
            files[0].name.substr(files[0].name.length - 4) !== ".doc" ||
            files[0].name.substr(files[0].name.length - 4) !== ".txt") {
            setLogo(files[0])
            setError(null)
        } else {
            event.target.value = null
            setError('Please select an image')
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const {name, email, address} = state;
        const formData = new FormData(ref.current);
        formData.append('logo', logo);
        formData.append('contact', formatPhoneNumber(contact));
        setSubmitted(true)
        // setError('Please select a logo')
        if (name && email && address && contact && logo) {
            setIsSubmitted('pending')
            axios.post(`${config.smsUrl}/admin/add_hospital_info`, formData).then(res => {
                actions.snackbarActions.snackSuccess('Company added successfully')
                setIsSubmitted('resolved')
            }).catch(err => {
                setIsSubmitted('rejected')
                errorMessages(err, null, actions)
            })
        }
    }

    const handleCloseSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    }

    return {
        state, logo, submitted, isSubmitted, ref, handleChange, error,
        handleChangeLogo, handleSubmit, handleCloseSnackbar, fileRef, contact, setContact
    }
}