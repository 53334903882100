import React, {useEffect, useState} from "react";
import CustomTable from "../../Utils/Tables/CustomTable";
import FilterResults from "react-filter-search";
import TableBody from "@material-ui/core/TableBody";
import {getComparator, stableSort} from "../../Utils/Tables/sortingFunctions";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../../actions/snackbarActions";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import DeleteDialog from "../../Utils/Dialogs/DeleteDialog";
import {useAddUnit} from "./hooks/useAddUnit";
import {useFileUploader} from "../../PatientRecords/CustomHooks/useFileUploader";
import axios from "axios";
import {config} from "../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {CsvDialog} from "../../Utils/Dialogs/CsvDialog";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMortarPestle} from "@fortawesome/free-solid-svg-icons/faMortarPestle";
import {Container} from "reactstrap";
import {RightAlignedContainer} from "../../Utils/styledComponents";
import {LoadingGif} from "../../Utils/Loader";

const headData = [{id: 'sl', numeric: false, disablePadding: false, label: 'SL'},
    {id: 'unitName', numeric: false, disablePadding: false, label: 'Unit Name'},
    {id: 'actions', numeric: false, disablePadding: false, label: 'Action'},
];

export const UnitList = ({actions, snackbars}) => {
    const [searchValue, setSearchValue] = useState('');
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('sl');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [item, setItem] = useState({unit_id:'', status:''});
    const [isDelete, setIsDelete] = useState('idle')
    const [unitList, setUnits] = useState([]);
    const [loading, setLoading] = useState('idle');
    const [openDelete, setOpenDelete] = useState(false);
    const { renderAddUnit, isResolved, handleOpenEdit, handleOpenDialog} = useAddUnit(actions)

    const obj = {url:'cpharmacy/uploadCsv_units', download_url:'cpharmacy/unit_csv', file_name:'Units'}
    const {uploadProps, isResolved:isResolvedUpload} = useFileUploader(actions, obj)

    const isDeleted = isDelete === 'deleted'
    useEffect(() => {
        setLoading('loading');
        axios.get(`${config.smsUrl}/cpharmacy/unit_list`).then(res => {
            const data = res.data;
            const dt = !data ? {}:data;
            const all_units = !dt.unit ? [] : dt.unit;
            setUnits(all_units);
            setLoading('success')
        }).catch(err => {
            logoutErrorMessage(err,null,actions);
            setLoading('error')
        })

    }, [isResolved,isDeleted,isResolvedUpload]);


    const handleOpenDelete = (unit) => {
        const {unit_id, status} = unit
        setItem({unit_id, status});
        setOpenDelete(true)
    };
    console.log(item)
    const handleCloseDelete = () => {
        setOpenDelete(false)
    };

    const handleDelete = () => {
        const {unit_id, status} = item;
        console.log(unit_id)
        const formData = new FormData();
        const isActive = status === 1 ? 0 : status === 0 ? 1 : ''
        formData.append('unit_id', unit_id);
        formData.append('status', isActive);
        setIsDelete('pending')
        axios.post(`${config.smsUrl}/cpharmacy/unit_delete`, formData).then(() => {
            const message = status === 1 ? 'deactivated' : status === 0 ? 'activated' : ''
            actions.snackbarActions.snackSuccess(`Unit ${message} successfully`);
            setIsDelete('deleted')
            setOpenDelete(false)
        }).catch(err => {
            errorMessages(err, null, actions)
            setIsDelete('rejected')
        })
    };

    const handleSearch = (value) => {
        setSearchValue(value)
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const isLoading = loading === 'loading'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'

    const {handleOpenCsvDialog} = uploadProps
    const {openBar, type, message} = snackbars
    const {status} = item;
    return (
        <div data-testid="unit-list">
            <PageTitle title="Unit"/>
            <SubHeader title="Pharmacy" subTitle="Unit">
                <FontAwesomeIcon icon={faMortarPestle}/>
            </SubHeader>
            <CsvDialog {...{...uploadProps, file:"/csv/unit_data.csv", title:'Upload Units'}}/>
            <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>
            <DeleteDialog message={status === 1 ?  'deactivate':'activate'} openDialog={openDelete} handleClose={handleCloseDelete}
                                text="unit" title={`${status === 1 ? 'Deactivate': item.status === 0 ? 'Activate':'Deactivate'} Unit`}>
                    <button className={`btn btn-sm ${status === 1 ? 'sms-gray-btn' : 'sms-info-btn'}`}
                    onClick={handleDelete}>{status === 1 ? 'Deactivate': item.status === 0 ? 'Activate':'Deactivate'}</button>
                    <button className="btn sms-grey-btn btn-sm" onClick={handleCloseDelete}>No</button>
            </DeleteDialog>
            {renderAddUnit}
            <Container>
                <div className='general-ledger-header'>
                    <RightAlignedContainer>
                        <button className='btn btn-sm sms-info-btn mr-4' onClick={()=>handleOpenDialog('')}>New Unit</button>
                        <button onClick={handleOpenCsvDialog} id="upload-units" className="btn sms-btn btn-sm">Upload Units
                        </button>
                    </RightAlignedContainer>
                </div>
                <CustomTable  title="Unit List" handleChangePage={handleChangePage}
                              handleChangeRowsPerPage={handleChangeRowsPerPage} filter
                              headData={headData} handler={handleSearch} page={page} rowsPerPage={rowsPerPage}
                              handleRequestSort={handleRequestSort} data={unitList} colSpan={5} order={order}
                              orderBy={orderBy} id="searchValue">
                        <FilterResults value={searchValue} data={unitList}
                           renderResults={results => (
                               <TableBody>
                                   {isLoading ? <TableRow>
                                       <TableCell colSpan={headData.length} align="center"><LoadingGif/></TableCell>
                                   </TableRow>: null}
                                   {isSuccess ? unitList.length > 0 ?  stableSort(results, getComparator(order, orderBy))
                                       .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                       .map((item, index) => {
                                           const count = (page * rowsPerPage) + index + 1;
                                           return (
                                               <TableRow
                                                   hover
                                                   role="checkbox"
                                                   tabIndex={-1}
                                                   key={item.unit_id}
                                                   className='mui-table-row'
                                                   id="unit"
                                               >
                                                   <TableCell>{count}</TableCell>
                                                   <TableCell >{item.name}</TableCell>
                                                   <TableCell>
                                                       <button onClick={()=>handleOpenEdit(item.unit_id)} className="btn btn-sm sms-btn mr-1">Edit</button>
                                                       <button onClick={() => handleOpenDelete(item)}
                                                               className={`btn btn-sm ${item.status === 1 ? 'sms-gray-btn' : 'sms-info-btn'}`}>
                                                           {item.status === 1 ? 'Deactivate':item.status === 0 ?  'Activate':'Deactivate'}
                                                       </button>
                                                   </TableCell>
                                               </TableRow>
                                           );
                                       }) : <TableRow>
                                       <TableCell colSpan={headData.length} align="center">No records found</TableCell>
                                   </TableRow>: null}
                                   {isError ? <TableRow>
                                       <TableCell colSpan={headData.length} align="center">The server did not return a valid response</TableCell>
                                   </TableRow>: null}
                               </TableBody>
                        )}/>
                </CustomTable>
            </Container>

        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(UnitList);
