import {useEffect, useState} from "react";
import {dateTimeConvert} from "../../Utils/ConvertDate";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {history} from "../../../Helpers/history";
import { useLabReport } from "./useLabReport";
import { useLabResultsReport } from "./useLabResultsReport";

export const useEditResults = (actions) => {
    const [resultState, setResultState] = useState({date: dateTimeConvert(), report: '', observations: '',approvedBy:''});
    const [patient, setPatient] = useState({
        patient_number: '', patient_name: '', dob: '', gender: '', bill_no: '', result_type: '',
        sample_no: '', sample_description: '', lab_test: '', drawn_date: '', drawn_by: '',patient_type:''
    });
    const [parameters, setParameters] = useState([{
        hospital_detail_id: '',
        parameter_name: '',
        result: '',
        unit: '',
        min_range: '',
        max_range: ''
    }]);
    const [tests, setTests] = useState([]);
    const [submittedResult, setSubmittedResult] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [updateDetails, setUpdateDetails] = useState({});
    const [paramDetails, setParamDetails] = useState([]);
    const [result, setResult] = useState('')
    const [q_type, setQtype] = useState('')
    const [ids, setIds] = useState({sample_id:'', labrequests_detail_id:'', test_results_id:'',visit_id:'', status:''})
    const [openDialog, setOpenDialog] = useState(false)
    const [openResultDialog, setOpenResultDialog] = useState(false)
    const [isBlocking, setIsBlocking] = useState(false)
    const [isBlockDialog, setIsBlockDialog] = useState(false)
    const [openApproval, setOpenApproval] = useState(false)
    const [confirmApproval, setConfirmApproval] = useState(false)
    const [confirmCancel, setConfirmCancel] = useState(false)
    const [isApproved, setIsApproved] = useState('idle')

    const isFetching = isSubmitted === 'pending';
    const isFetchedSuccess = isSubmitted === 'resolved';
    const isResolved = isApproved === 'resolved';
    const isFetchedError = isSubmitted === 'rejected';
    const {sample_id, labrequests_detail_id, test_results_id, visit_id} = ids;

    const isResultsResolved = isFetchedSuccess || isResolved

    const url = {tableUrl:'retrieve_all_lab_results', exportUrl:'retrieve_all_lab_results'};

    const {searchValue, order, orderBy,  activePage, total_count, reports,
        handleSearch, handleRequestSort, handleChangeNextPage, closeSnackbar,
         retrieveAllRecords, csvRef, downloadCsv, allRecords, isError, isLoading, isSuccess, state,
          submitted, isPending, handleChange, handleSubmit} = useLabReport(actions, url, isResultsResolved)
    const {resultDetails, params,retrieveLabResults} = useLabResultsReport(actions,test_results_id)



    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const technician = `${!_token.first_name ? "" : _token.first_name} ${!_token.last_name ? "" : _token.last_name}`;
    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;

    useEffect(() => {
        if(!(test_results_id && sample_id)){
            return
        }
        const formData = new FormData();
        formData.append('test_results_id', test_results_id);
        formData.append('sample_id', sample_id);
        axios.post(`${config.smsUrl}/claboratory/lab_results_update_form`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const pt = !dt.patient_info ? {} : dt.patient_info;
            const param = !dt.result_info ? [] : dt.result_info;
            setUpdateDetails(pt);
            setParamDetails(param)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, [test_results_id, sample_id]);

    useEffect(() => {
        const {result_type} = updateDetails;
        setPatient({
            patient_number: updateDetails.patient_number,
            patient_name: `${!updateDetails.patient_firstname ? "" : updateDetails.patient_firstname} 
            ${!updateDetails.patient_lastname ? "" : updateDetails.patient_lastname}`,
            dob: updateDetails.age,
            gender: updateDetails.gender,
            bill_no: updateDetails.transaction_id,
            result_type: updateDetails.result_type ? updateDetails.result_type : '',
            sample_no: updateDetails.sample_id,
            sample_description: updateDetails.sample_description,
            lab_test: updateDetails.hospital_test_name,
            drawn_date: updateDetails.time_taken,
            drawn_by: `${!updateDetails.tech_firstname ? "" : updateDetails.tech_firstname} 
            ${!updateDetails.tech_lastname ? "" : updateDetails.tech_lastname}`,
            patient_type:updateDetails.patient_type
        });
    
        const all_params = paramDetails.map(item => ({
            ...item,
            hospital_detail_id: item.hospital_test_detail_id,
            parameter_name: item.sub_test_name,
            unit: item.unit,
            min_range: item.critical_low,
            max_range: item.critical_high,
            result: item.test_value
        }));



        const param = paramDetails ? paramDetails : []
        const para = param[0] ? param[0] : {};
        const labNotes =  param[param.length - 1] ?  param[param.length - 1] : {}
        const val = para.sub_test_name ? para.sub_test_name : '';
        const result_data = para.test_value ? para.test_value : '';
        const notes = para.notes ? para.notes : ''

        if (result_type === 3) {

            setResultState({...resultState, observations: result_data})
        }
        const r = result === '0' ? 'Negative' : result === '1' ? 'Positive' :
            result === '3' ? 'Reactive' : result === '2' ? 'Non-reactive' : ''
        if (result_type === 1) {
            setResult({value: result_data, label: val === 'blood_group' ? result_data : r})
        }
        setResultState({...resultState,report: notes,observations: result_data})
        setParameters(all_params)
        setQtype(val)
    }, [updateDetails, paramDetails]);



    const handleChangeResult = (event, idx) => {
        const paramsCopy = [...parameters];
        if (paramsCopy[idx]) {
            paramsCopy[idx].result = event.target.value;
        }
        setParameters(paramsCopy)
        setIsBlocking(true)
    };

    const handleChangeResultState = (event) => {
        const {value, name} = event.target;
        setResultState({...resultState, [name]: value})
        setIsBlocking(true)
    };

    const handleChangeQualitative = (val) => {
        setResult(val)
        setIsBlocking(true)
    }

    const handleOpenDialog = (item) =>{
        const {sample_id, labrequests_detail_id, test_results_id,visit_id, status} = item;
        setIds({sample_id, labrequests_detail_id, test_results_id,visit_id, status })
        setOpenDialog(true)
    }

    const handleCloseDialog = () =>{
        if(isBlocking){
            setIsBlockDialog(true)
        }else{
            setIsBlockDialog(false)
            setIsBlocking(false)
            setOpenDialog(false)
        }

    }

    const handleOpenResultDialog = (item) =>{
        const {sample_id, labrequests_detail_id, test_results_id,visit_id, status} = item;
        setIds({sample_id, labrequests_detail_id, test_results_id,visit_id, status})
        setOpenResultDialog(true)
    }

    const handleCloseResultDialog = () =>{
        setOpenResultDialog(false)
    }

    const handleOpenApproveDialog = (item) =>{
        const {sample_id, labrequests_detail_id, test_results_id,visit_id, status} = item;
        setIds({sample_id, labrequests_detail_id, test_results_id,visit_id, status})
        setOpenApproval(true)
    }

    const handleCloseApproveDialog = () =>{
        setOpenApproval(false)
    }

    const handleConfirmApproval = () =>{
        setConfirmApproval(true)
    }

    const handleCloseConfirmApproval = () =>{
        setConfirmApproval(false)
    }

    const handleConfirmCancel = () =>{
        setConfirmCancel(true)
    }

    const handleCloseConfirmCancel = () =>{
        setConfirmCancel(false)
    }

    const handleApproval = (status) =>{
        const formData = new FormData();
        formData.append('labrequests_detail_id', labrequests_detail_id);
        formData.append('status', status);
        formData.append('user_roles_id', user_roles_id);
        setIsApproved('pending')
        axios.post(`${config.smsUrl}/claboratory/update_lab_result_status`, formData).then(() => {
            const message = status === 1 ? 'approved' : 'canceled'
            actions.snackbarActions.snackSuccess(`Lab results ${message} successfully`);
            setOpenApproval(false)
            if (status === 1){
                setConfirmApproval(false)
            }else{
                setConfirmCancel(false)
            }
            setIsApproved('resolved');
        }).catch(err => {
            errorMessages(err, null, actions)
            setIsApproved('rejected');
        })
    }

    const handleSubmitResult = (event) => {
        event.preventDefault();
        const {result_type, patient_type} = patient
        const {observations, report} = resultState
        const formData = new FormData();
        const obj = parameters[0] ? parameters[0] : {};
        formData.append('visit_id', visit_id);
        formData.append('test_results_id', test_results_id);
        formData.append('labrequests_detail_id', labrequests_detail_id);
        formData.append('user_roles_id', user_roles_id);
        formData.append('notes', report);
        formData.append('patient_type', patient_type);
        formData.append('is_external', obj.is_external);
        if (result_type === 1 || result_type === 3) {
            formData.append('test_value', result_type === 1 ? result.value : result_type === 3 ? observations : '')
        }
        parameters.filter(test=>test.hospital_detail_id).forEach(item => {
            result_type === 2 && formData.append('test_value', item.result);
            formData.append('hospital_test_detail_id', item.hospital_detail_id);
        });
        setSubmittedResult(true);
        setIsBlocking(false)
        const arr = parameters.every(test=>test.hospital_detail_id && test.result)
        if(arr){
            setIsSubmitted('pending')
            axios.post(`${config.smsUrl}/claboratory/edit_lab_results`, formData).then(() => {
                actions.snackbarActions.snackSuccess('Lab results edited successfully');
                setOpenDialog(false)
                setIsSubmitted('resolved');
                retrieveLabResults()
                setOpenApproval(false)
            }).catch(err => {
                errorMessages(err, null, actions)
                setIsSubmitted('rejected');
            })
        }
  
    };

    const handleEdit = () =>{
        setOpenResultDialog(false)
        setOpenDialog(ids)
    }

    const resultProps =  {resultState, patient, parameters, tests, submittedResult, updateDetails,q_type,isSubmitted,
        paramDetails, technician, handleChangeResult, handleChangeResultState, handleSubmitResult,isFetching,
        closeSnackbar, sample_id, labrequests_detail_id, test_results_id, result, handleChangeQualitative
    }

    const handleOnAfterPrint = () => {
        history.push('/labresultlist');
        handleCloseResultDialog();
    }

    const handleResetForm = () =>{
        setIsBlockDialog(false)
        setIsBlocking(false)
        setOpenDialog(false)
    }

    const printOutProps = {resultDetails, params, handleEdit, handleOnAfterPrint}


    return {resultProps, openDialog, handleOpenDialog, handleCloseDialog,searchValue, order, orderBy,  activePage, total_count, reports,
        handleSearch, handleRequestSort, handleChangeNextPage, closeSnackbar,handleCloseResultDialog,
         retrieveAllRecords, csvRef, downloadCsv, allRecords, isError, isLoading, isSuccess, state,
          submitted, isPending, handleChange, handleSubmit, printOutProps, openResultDialog, handleOpenResultDialog,
        isBlockDialog, isBlocking, setIsBlockDialog,  handleResetForm,
    openApproval, confirmApproval, confirmCancel, handleConfirmApproval,
        handleApproval, handleCloseApproveDialog,
    handleCloseConfirmCancel, handleCloseConfirmApproval,
        handleConfirmCancel,handleOpenApproveDialog, ids}
}