/** @jsxImportSource @emotion/react */
import {jsx, css} from '@emotion/react'
import React, {useEffect, useState} from 'react'
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCoins} from "@fortawesome/free-solid-svg-icons/faCoins";
import PageTitle from "../../Utils/smsTitle";
import {MiniCenteredDiv, RightAlignedContainer} from '../../Utils/styledComponents'
import { PrintTemplate, ItemsTable } from '../../Utils/Templates/PrintTemplate'
import * as colors from '../../../styles/colors';
import {formatDate} from '../../Utils/ConvertDate';
import {formatDigits} from '../../Utils/formatNumbers';
import Card from '@material-ui/core/Card'
import { LoadingGif } from '../../Utils/Loader';
import {coloredInvoice, textWeight, thUppercase} from "../../../styles/tableStyles";
import {BackButton} from "../../Utils/Buttons/DataExportationButton";
import axios from "axios";
import { config } from "../../../Helpers/env";
import Form from 'react-bootstrap/Form'
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../../actions/snackbarActions";
import {connect} from "react-redux";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {history} from "../../../Helpers/history";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import Alert from '@material-ui/lab/Alert';
import {titleCase} from "../../Users/addUser";
import {FinishedStatus} from "../../DoctorsModule/doctorStyles";


const titleText = css`
    margin: 0;
    color: ${colors.darkBlue001};
    font-size: 15px;
    
`


const borderless = css`
   border-bottom: 0;
`

const tableMargin = css`
    margin-bottom: 0;
`

const headData = [{key:'name',value:'Item'}, {key:'quantity',value:'Quantity'}, {key:'rate',value:'Rate'}, {key:'amount',value:'Amount'},
    {key:'reverse',value:'Reverse'}]

function ReverseInpatientBill({actions, snackbars, match}) {
    const [receipt, setReceipt] = useState({});
    const [items, setItems] = useState([]);
    const [total_amount, setTotalAmount] = useState(0)
    const [status, setStatus] = useState('idle')
    const {visit_id, patient_admission_id,pay_status} = match.params;
    const [submitted, setSubmitted] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState('idle')

    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;

    useEffect(() => {
        setStatus('loading')
        const formData = new FormData();
        formData.append('visit_id', visit_id);
        formData.append('patient_admission_id', patient_admission_id);
        formData.append('pay_status', pay_status);
        formData.append('invoice_number', '');
        axios.post(`${config.smsUrl}/cbilling/get_inpatient_bill`, formData).then(res => {
            const data = res.data;
            const all_data = !data ? {} : data;
            const patient_info = !all_data.patient_info ? [] : all_data.patient_info;
            const info = !patient_info[0] ? {} : patient_info[0];
            const invoices = all_data.admission_bill ?? [];
            const obj = invoices[0] ? invoices[0] :{};
            const t_amount = invoices.reduce((a, item) => {
                const qty = item.quantity ? item.quantity : 0;
                const rate = item.rate ? item.rate : 0
                return +a + ( qty * rate );
            },0)
            setReceipt({...info, ...obj});
            setTotalAmount(t_amount)
            const invArr = invoices.map(item=>({
                ...item, isReverse:false
            }))
            setItems(invArr);
            setStatus('success')
        }).catch(e => {
            setStatus('error')
        })
    }, []);

    const handleReverse = (id) =>(event)=>{
        const arr = items.map(item=>{
            if(item.bill_id === id){
                return {...item, isReverse:event.target.checked}
            }
            return item
        })
        setItems(arr)
    }

    const handleSubmit = (event) =>{
        event.preventDefault()
        const arr = items.length > 0 && items.some(item=>item.isReverse)
        const data = items.filter(item=>item.isReverse).map(({isReverse, ...item})=>({...item}))
        setSubmitted(true)
        if(arr){
            setIsSubmitted('pending')
            axios.post(`${config.smsUrl}/cbilling/bill/reversal`,{bill:data, created_by:user_roles_id}).then(()=>{
                actions.snackbarActions.snackSuccess('Items reversed successfully')
                history.push('/patientbillinglist')
                setIsSubmitted('resolved')
                const clearedArr = items.map(item=>({
                    ...item, isReverse:false
                }))
                setItems(clearedArr)
            }).catch(err=>{
                errorMessages(err, null, actions)
                setIsSubmitted('rejected')
            })
        }
    }


    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };


    const isPending = isSubmitted === 'pending'

    const isReversed = items.length > 0 && items.some(item=>item.isReverse)



    const isLoading = status === 'loading'
    const isSuccess = status === 'success'
    const isError = status === 'error'

    const {openBar, type, message} = snackbars;

    const patient_name = `${receipt.p_first_name ? titleCase(receipt.p_first_name) : ''} ${receipt.p_last_name ? titleCase(receipt.p_last_name) : ''}`;


    const patientDetails = (
        <tbody>
        <tr>
            <td><span>Admission Date: </span> <span css={[textWeight]}>{receipt?.admission_date ? formatDate(receipt?.admission_date):null}</span> </td>
            <td><span>Patient Name: </span> <span css={[textWeight]}>{patient_name}</span> </td>

        </tr>
        <tr>
            <td><span>Patient Number: </span> <span css={[textWeight]}>{receipt?.patient_number}</span></td>
            <td><span>Contact: </span> <span css={[textWeight]}>{receipt?.phone_no}</span></td>
        </tr>
        <tr>
            <td><span>Admission No: </span> <span css={[textWeight]}>{patient_admission_id}</span></td>
            <td><span>Address:</span> <span css={[textWeight]}>{receipt?.address}</span></td>
        </tr>
        </tbody>
    )
    const itemDetails = (
        <>
            {submitted && !isReversed ?
                <div className='mb-3'>
                    <Alert severity='error'>Please select at least one item to reverse before submitting</Alert>
                </div>
                : null}
            <ItemsTable {...{headData, coloredTableHead:coloredInvoice}}>
                <tbody>
                {items.map((item) => {
                    const qty = item.quantity ? item.quantity : 0;
                    const rate = item.rate ? item.rate : 0
                    return (
                        <tr key={item.bill_id}>
                            <td><span>{item.service_id ? item.service_name : item.product_id ? item.product_name :
                                item.bed_assignment_id ? `${item.ward_name} -> ${item.room_name} -> ${item.bed_number}` : ''}</span></td>
                            <td><span>{item.bed_assignment_id ? `${item.quantity} day${item.quantity > 1 ? 's': ''}` : item.quantity}</span></td>
                            <td><span>{formatDigits(item.rate)}</span></td>
                            <td><span>{formatDigits(qty * rate)}</span></td>
                            <td align='center'>
                                {item.pay_status === 0 ?
                                    <Form.Check type='checkbox' checked={item.isReverse} onChange={handleReverse(item.bill_id)}/>:
                                    <FinishedStatus>Paid</FinishedStatus>}
                            </td>
                        </tr>
                    )
                })}

                <tr>
                    <td  colSpan={headData.length -2} align='right'><span css={[textWeight]}>Total Bill</span></td>
                    <td colSpan={2}><span css={[textWeight]}>{formatDigits(total_amount)}</span></td>
                </tr>
                </tbody>
            </ItemsTable>
        </>

    )
    return (
        <div>
            <PageTitle title="Reverse Bill"/>
            <SubHeader title="Reverse bill" subTitle="Reverse bill">
                <FontAwesomeIcon icon={faCoins}/>
            </SubHeader>
            <MainSnackbar variant={type} handleCloseBar={closeSnackbar} open={openBar} message={message}/>
            {isLoading ? <LoadingGif/>:null}
            {isSuccess ?
                <MiniCenteredDiv>
                    <BackButton to='/patientbillinglist' text='Pending Clearance' data-testid="billed-invoices-receipt-back-button"/>
                    <Card className='mt-2 p-3' >
                        <form onSubmit={handleSubmit}>
                            <PrintTemplate title='Inpatient Bill' {...{patientDetails,itemDetails, bioBordered:borderless, bioMargin:tableMargin}}/>
                            <RightAlignedContainer>
                                <button className='btn btn-sm sms-info-btn' disabled={isPending}>{isPending ? 'Submitting...':'Submit'}</button>
                            </RightAlignedContainer>
                        </form>

                    </Card>
                </MiniCenteredDiv> : null}
            {isError ? <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                <p>The server did not return a valid response</p>
            </div>:null}
        </div>
    )
}

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(ReverseInpatientBill)

