import { useState, useEffect } from 'react';
import { errorMessages, logoutErrorMessage } from '../../../../Helpers/ErrorMessages';
import axios from "axios";
import { config } from "../../../../Helpers/env";
import { useSurgeryVisit } from './../../../Visit/Surgery/hooks/useSurgeryVisit';

export const useSurgery = (actions, patient_number, visit_id, patient_type) => {

    // create objects for the patient details
    const {
        state, patientDetails, onChange, operation_class, operationClasses, handleOpClassChange,
        anaesthesia_type, anaesthesiaTypes, handleAnesTypeChange,
        p_number, handleCancelSurgery, submitted, setSubmitted,
        surgery_name, handleSurgeryTypeChange, surgeryTypes, 
        handleCloseConfirm, openConfirmationDialog, surgeryConfirmation, setConfirmation, isOpen, 
        handleChangeSurgeon, handleSelectSurgeon, handleChange, inputDoc, 
        surgeon, surgeons, general_notes,handleNotesState,
        other_anaestheists, other_surgeons,isVisitingDoctor, clearSurgeryDoctorDetails,
        handleOtherAnaestheists, handleOtherSurgeons,handleChangeDoctors
    } = useSurgeryVisit(actions, patient_number);

    const [patient, setPatient] = useState({patient_name: "", age: "", gender: "", address: "", phone: ""});

    // handles the changes
    useEffect(() => {
        setPatient({
            patient_name: `${!patientDetails.first_name ? "" : patientDetails.first_name} ${!patientDetails.last_name ? "" : patientDetails.last_name}`,
            age: patientDetails.age,
            gender: patientDetails.gender,
            address: patientDetails.address,
            phone: patientDetails.phone_no
        });
    }, [patientDetails]);

    //const [submittedSurgery, setSubmittedSurgery] = useState(false);
    //const [isSubmitted, setIsSubmitted] = useState('pending');

    const handleSubmitSurgery = (event) => {
        event.preventDefault();

        const {lead_surgeon, lead_anaestheist, anaesthesiologist, scrub_nurse, circulating_nurse} = surgeon
        const estimated_duration = `${state.duration_hr}:${state.duration_mins}`
        const anaesT = (anaesthesia_type['value']  === undefined) ? "" : anaesthesia_type['value'];

        const surgery_service = surgery_name['value'].split('-');
        const service_id = parseInt(surgery_service[0]);

        const params = {
            visit_id,
            patient_number: p_number, visit_type:5, user_roles_id:1, patient_type,  bill_type:2,
            estimated_start_time: state.estimated_start_time, estimated_duration, operation_date: state.operation_date,
            operation_class : operation_class['value'],
            lead_surgeon : lead_surgeon, anaesthesiologist : anaesthesiologist, lead_anaestheist : lead_anaestheist,
            scrub_nurse : scrub_nurse, circulating_nurse: circulating_nurse, anaesthesia_type : anaesT, 
            preoperative_instructions : general_notes.preoperative_instructions,
            preoperative_diagnosis : general_notes.preoperative_diagnosis,
          //  planned_procedures : general_notes.planned_procedures,
            post_operative_instructions : general_notes.post_operative_instructions,
            report : general_notes.report,
            other_anaestheists : other_anaestheists.map(a => a.label).join(","),
            other_surgeons : other_surgeons.map(a => a.label).join(","),
            bill_details : {
                service_id,
                quantity: 1,
                rate: parseInt(surgery_service[1]),
            }
        }

        // to get a new visit_id
        const {first_name, last_name} = patientDetails;

        if (first_name && last_name) {

            //setIsSubmitted('pending');
            axios.post(`${config.smsUrl}/surgery/create_surgery_visit`, {...params}).then((res) => {

                // save the new visit
                /*const data = res.data ? res.data : {};
                const dt = data ? data : {};
                const v_id = dt.data ? dt.data : {};
                const id = v_id.visit_id ? v_id.visit_id:'';*/
            
                actions.snackbarActions.snackSuccess('Surgery scheduled');

                //setIsSubmitted('resolved');
                //setSubmittedSurgery(false);
                setSubmitted(false);
                setConfirmation(false);
                clearSurgeryDoctorDetails();

            }).catch((err) => {
                //setIsSubmitted('rejected');
                setSubmitted(false);
                clearSurgeryDoctorDetails();
                setConfirmation(false);
                logoutErrorMessage(err, null, actions);
            });
        } else {
            //setIsSubmitted('rejected');
            setSubmitted(false);
            setConfirmation(false);
        }
    }

    return {
        state, patient, onChange, general_notes, handleNotesState,
        operation_class, operationClasses, handleOpClassChange,
        anaesthesia_type, anaesthesiaTypes, handleAnesTypeChange,
        patientDetails,  handleSubmitSurgery,
        patient_number, submitted, 
        surgery_name, handleSurgeryTypeChange, surgeryTypes, 
        handleCloseConfirm, openConfirmationDialog, surgeryConfirmation,
        handleCancelSurgery, isOpen,
        handleChangeSurgeon, handleSelectSurgeon, handleChange, inputDoc,
        surgeon, surgeons,
        other_anaestheists, other_surgeons,isVisitingDoctor,
        handleOtherAnaestheists, handleOtherSurgeons,handleChangeDoctors,
    };
}