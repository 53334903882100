import React, {useEffect, useState} from 'react';
import axios from "axios";
import { config } from "../../../Helpers/env";
import {logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import moment from "moment";



export const useDoctorSchedule = (actions,_token) =>{
    const [schedules, setSchedules] = useState([]);
    const [loading, setLoading] = useState('idle');
    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;
    const phone_no = !_token.phone_no ? "" : _token.phone_no;


    useEffect(() => {
        setLoading('pending');
        const formData = new FormData();
        formData.append('user_roles_id', user_roles_id);
        formData.append('phone', phone_no);
        axios.post(`${config.smsUrl}/cshiftschedule/get_doctor_schedule_by_id`,formData).then(res => {
            const data = res.data;
            const dt = !data ? [] : data;
            const arr = dt.map(item=>{
                const date = item.slot_date.split('T')[0];
                return {
                    id:item.schedule_id,
                    start:moment(`${date}T${item.start_time}`).toDate(),
                    end:moment(`${date}T${item.end_time}`).toDate(),
                    title:item.slot_name,
                    resource:`${item.schedule_owner}-${item.description ? item.description : ''}`
                }})
            setSchedules(arr);
            setLoading('resolved')
        }).catch(err => {
            setLoading('rejected');
            logoutErrorMessage(err, null, actions)
        })
        return () =>{

        }
    }, []);


    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };



    return {schedules, loading, closeSnackbar}
}