import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../actions/snackbarActions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {SubHeader} from "../../Containers/SubHeader";
import {faWheelchair} from "@fortawesome/free-solid-svg-icons/faWheelchair";
import CustomTable, {
    CustomTablePagination,
    useHiddenColumns
} from "../Utils/Tables/CustomTable";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz"
import SimpleMenu from "../Utils/Menu/SimpleMenu";
import MenuItem from '@material-ui/core/MenuItem';
import {Link} from "react-router-dom";
import PageTitle from "../Utils/smsTitle";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import {history} from "../../Helpers/history";
import { useTheme } from "@material-ui/core/styles";
import CustomDialog from "../Utils/Dialogs/CustomDialog";
import { PatientForm } from "./PatientForm";
import { RightAlignedContainer, PatientContainer, Container } from "../Utils/styledComponents";
import PrintDialog from "../Utils/Dialogs/PrintDialog";
import {CsvDialog} from "../Utils/Dialogs/CsvDialog";
import {BlockNavigationComponent} from "../Utils/ReusableComponents/BlockNavigationComponent";
import {useRegisterPatient} from "./CustomHooks/useRegisterPatient";
import {useFileUploader} from "./CustomHooks/useFileUploader";
import axios from "axios";
import {config} from "../../Helpers/env";
import {logoutErrorMessage} from "../../Helpers/ErrorMessages";
import { useVersionsContext } from "../../Context/versions-context";

const headData = [
    {id: 'count', numeric: false, disablePadding: false, label: 'Sl', hidden:false},
    {id: 'patient_number', numeric: false, disablePadding: false, label: 'Patient No.', hidden:false},
    {id: 'patient_name', numeric: false, disablePadding: false, label: 'Patient Name', hidden:false},
    {id: 'phone_no', numeric: false, disablePadding: false, label: 'Phone', hidden:false},
    {id: 'kin_phone_no', numeric: false, disablePadding: false, label: 'NOK phone', hidden:false},
    {id: 'address', numeric: false, disablePadding: false, label: 'Address', hidden:false},
    {id: 'gender', numeric: false, disablePadding: false, label: 'Gender', hidden:false},
    {id: 'age', numeric: false, disablePadding: false, label: 'Age', hidden:false},
    {id: 'blood_type', numeric: false, disablePadding: false, label: 'Blood Group', hidden:false},
    {id: 'provider_name', numeric: false, disablePadding: false, label: 'Provider', hidden:false},
    {id: 'action', numeric: false, disablePadding: false, label: 'Action', hidden:false},
];

const PatientDetails  = ({actions, snackbars, match:{params}}) => {

    const [data, setData] = useState({loading:'idle', patients:[], total_count:0});
    const [activePage, setActivePage] = useState(1)
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [patient_id, setPatientId] = useState(null)
    const [actualPatientID, setActualPatientID] = useState(0);
    const [term, setTerm] = useState('')
    const [openMenu, setOpenMenu] = useState(null);
    const [isRegister, setIsRegister] = useState(false);

    const {patientProps,openDialog,
        handleOpenDialog, handleCloseDialog, isResolved} = useRegisterPatient(actions);

    const obj = {url:'cpatient/uploadCsv_patients', download_url:'cpatient/patient_csv', file_name:'Patients'}
    const {uploadProps, isResolved:isResolvedUpload} = useFileUploader(actions, obj)

    const {setState, setContact, setKinContact, setBloodType, setNumber,
        setPatient, setPatientNumber, setKinRelation, setPatientRelation} = patientProps;

    useEffect(() => {
        setData({...data,loading:'loading'})
        axios.get(`${config.smsUrl}/cpatient/patient_list/${activePage}`,  {
        params:{per_page:rowsPerPage,search:term}
        }).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const arr = !dt.patient_list ? [] : dt.patient_list;
            const count = !dt.total_count ? 0 : dt.total_count;
            setData({loading:'success', patients:arr, total_count:count})
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
            setData({...data,loading:'error'})
        })

    }, [term, activePage, isResolved, isResolvedUpload, rowsPerPage])



    const handler = (event) => {
        setTerm(event.target.value)
        setActivePage(1)
        setPage(0)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setActivePage(newPage+1)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        setActivePage(1);
    };


    const handleChangeActivePage = (page) => {
        setActivePage(page)
    };

    const handleOpenSimpleMenu = (event, id, actualID) => {
        setOpenMenu(event.currentTarget)
        setPatientId(id)
        setActualPatientID(actualID)
    };

    const handleCloseSimpleMenu = () => {
        setOpenMenu(null);
    };

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const handleClick = (patient_id) => {
        history.push(`/patientProfile/${patient_id}`)
    };

    const handleCloseSimpleOpenDialog = (p_id) => {
        setOpenMenu(null);
        setIsRegister(false);
        handleOpenDialog(p_id);
    }

    const handleRegisterPatientButton = () => {
        handleOpenDialog('');
        setIsRegister(true);
        setState({
            first_name: '', last_name: '', gender: '', dob: '', email: '', id_nin: '',
            address: '', first_name1: '', last_name1: '', address1: '', relationship: '', age:'',
            patient_number :''
        });
        setContact();
        setKinContact();
        setBloodType(null);
        setPatientNumber('');
        setPatient({});
        setKinRelation('');
        setNumber('')
        setPatientRelation(null)

    }

    const {patients, loading, total_count} = data;
    const all_patients = !patients ? [] : patients;
    const {openBar, type, message} = snackbars;
    const perm = JSON.parse(sessionStorage.getItem('permission'));
    const permission = !perm ? {} : perm;
    const patient_list = !permission.patient_list ? {} : permission.patient_list;
    const {theme} = useTheme();
    const them = theme ? theme : {};
    const palette = them.palette ? them.palette : {};
    const {component} = params;
    const isReception = component === 'reception';
    const {subscription} = useVersionsContext()
    const version = subscription?.version


    const pts = all_patients.map((patient, index) => ({
        ...patient,
        count: ((activePage - 1) * rowsPerPage) + (index + 1),
        patient_number: patient.patient_number,
        patient_name: `${patient.first_name} ${patient.last_name}`,
        phone_no: patient.phone_no,
        address: patient.address,
        gender: patient.gender,
        age: patient.age,
        blood_type: patient.blood_type ? (patient.blood_type).toUpperCase() : '',
        provider_name: patient.provider_name,
        action:(
            isReception ?  <MoreHorizIcon
                        onClick={e => handleOpenSimpleMenu(e, patient?.patient_number, patient?.patient_id)}
                        data-test="open-menu"/>:
                    <button onClick={()=>history.push(`/patient-history/${patient.patient_number}`)}
                            type="button" className="btn btn-sm sms-info-btn">View History</button>
        )
    }))
    const isLoading = loading === 'loading'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'

    const {isSubmitting,p_number, openVisit, handleClickVisit, handleCloseVisit} = patientProps;

    const {handleOpenCsvDialog} = uploadProps
    const {isBlocking, isBlockDialog, setIsBlockDialog,  handleResetForm} = patientProps
   
    
    const {
        headCells, all_hidden, handleAllHeadCells, handleHeadCells
    } = useHiddenColumns(headData);

    const pagination = (
        <CustomTablePagination
            colSpan={3}
            activePage={activePage}
            page={page}
            rowsPerPage={rowsPerPage}
            count={total_count}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            isTotalCount
        />
    );

    return (
        <div id="patient-list">
            <PageTitle title="Patient List"/>
            <SubHeader title="Patient" subTitle="Patient List">
                <FontAwesomeIcon icon={faWheelchair}/>
            </SubHeader>
            <div className="mui-tables">
                <PrintDialog
                    handleClick={handleClickVisit}
                    handleClose={handleCloseVisit}
                    openDialog={openVisit}
                    message="Create Visit"
                    text={`Would you like to create a visit for this patient ${p_number}`}
                    handleCancel={handleCloseVisit}
                />

                <BlockNavigationComponent
                    when={isBlocking}
                    shouldBlockNavigation={()=>{
                        return isBlocking;
                    }}
                    navigate={path=>history.push(path)}
                    dialogClose={isBlockDialog}
                    setIsBlockDialog={setIsBlockDialog}
                    clearForm={handleResetForm}
                />

                <CustomDialog open={openDialog} handleClose={handleCloseDialog} title={(isRegister === false) ? 'Update Patient Details' : 'Register Patient'}
                              maxWidth="lg" isContentOverflow={false} isPaperStyle={true}>
                    <PatientContainer>
                        <PatientForm {...{...patientProps, isRegister}}>
                            <RightAlignedContainer>
                            <button type="submit" disabled={isSubmitting} 
                            className="btn btn-sm sms-btn">{(isSubmitting && (isRegister === true)) ? 'Registering...' :
                            (isSubmitting && (isRegister === false)) ? 'Updating...' :
                            ((isRegister === false) && !isSubmitting) ? 'Update Patient' : 'Register Patient'}</button>
                            </RightAlignedContainer>
                        </PatientForm>
                    </PatientContainer>
                </CustomDialog>

                <CsvDialog {...{...uploadProps, file:"/csv/patients_edited.csv", title:'Upload Patients'}}/>
                <MainSnackbar message={message} open={openBar} handleCloseBar={closeSnackbar} variant={type}/>

                <Container>
                {isReception ? <RightAlignedContainer>
                        <div className="mb-2">
                            <button onClick={handleRegisterPatientButton} id="register-patient" className="btn sms-info-btn btn-sm mr-4">Register Patient</button>
                            <button onClick={handleOpenCsvDialog} id="upload-patients" className="btn sms-info-btn btn-sm">Upload Patients
                            </button>
                        </div>
                    </RightAlignedContainer>: null}
                    <CustomTable headData={headCells} title="Patients" data={pts} 
                        term={term} handler={handler} colSpan={headCells.length} customPage records={10} total_count={total_count}
                        handleChangeNextPage={handleChangeActivePage} activePage={activePage} searchPlaceHolder="Search Patient Name or Phone Number"
                        handleHeadCells={handleHeadCells} all_hidden={all_hidden} handleAllHeadCells={handleAllHeadCells}
                        pagination={pagination}
                    >
                        <TableBody>
                            {isLoading ? <TableRow>
                                <TableCell colSpan={headCells.length} align="center"><img src="/images/smsloader.gif"
                                                                                          alt="loader"/></TableCell>
                            </TableRow>:null}
                            {isSuccess ?  pts.length > 0 ? pts.slice(0, rowsPerPage)
                                .map((item, index) => {

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={item.patient_number}
                                            className='mui-table-row '
                                        >
                                            {headCells.map((head)=>(
                                                <TableCell  key={head.id} hidden={head.hidden}>{item[head.id]}</TableCell>
                                            ))}
                                        </TableRow>
                                    );
                                }):<TableRow>
                                <TableCell colSpan={headCells.length} align="center">No records found</TableCell>
                            </TableRow>:null}
                            {isError ? <TableRow>
                                <TableCell colSpan={headCells.length} align="center">The server did not return a valid response</TableCell>
                            </TableRow> : null }
                        </TableBody>
                    </CustomTable>
                </Container> 

                <SimpleMenu anchorEl={openMenu} handleClose={handleCloseSimpleMenu}>
                    <Link to={{pathname: `/newvisit/${patient_id}/${actualPatientID}`}} style={{
                        textDecoration: "none",
                        color: palette.type === "dark" ? "#fff" : "#3d3d3d"
                    }}><MenuItem className="mui-menu-item">New Visit</MenuItem></Link>
                    {/*    <Link to={{pathname: `/admissionform/${patient_id}`}} style={{*/}
                    {/*    textDecoration: "none",*/}
                    {/*    color: palette.type === "dark" ? "#fff" : "#3d3d3d"*/}
                    {/*}}><MenuItem className="mui-menu-item">New Admission</MenuItem></Link>*/}
                    {version == "Premium" &&
                    <Link id='new_surgery' to={{pathname: `/surgeryvisit/${patient_id}`}} style={{
                        textDecoration: "none",
                        color: palette.type === "dark" ? "#fff" : "#3d3d3d"
                    }}><MenuItem className="mui-menu-item">New Surgery</MenuItem></Link>}
                    {patient_list.create ? <Link to={{pathname: `/patientProfile/${patient_id}`}}
                                                    style={{
                                                        textDecoration: "none",
                                                        color: palette.type === "dark" ? "#fff" : "#3d3d3d"
                                                    }}><MenuItem className="mui-menu-item">View
                        Profile</MenuItem></Link>:null}
                    {patient_list.update ? <MenuItem onClick={()=>handleCloseSimpleOpenDialog(patient_id)} className="mui-menu-item">Edit
                        Profile</MenuItem>:null}
                </SimpleMenu>
            </div>
        </div>
    );
    }


function mapStateToProps(state) {
    return {
        snackbars: state.snackbar
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
          
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientDetails);
