import {useEffect, useState} from "react";
import axios from "axios";
import {config} from "../../../Helpers/env";

export function useViewPaymentDetails(visit_id) {

    const [visitDetails, setVisitDetails] = useState({})


    useEffect(()=>{
        if(!visit_id){
            return
        }
        axios.get(`${config.smsUrl}/cvisit/${visit_id}`).then(res=>{
            const data = res.data;
            const dt = !data ? {} : data;
            setVisitDetails(dt)
        }).catch(err=>{
        })
    },[visit_id]);

    return {visitDetails}
}