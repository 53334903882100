import {useEffect, useState} from "react";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {history} from "../../../Helpers/history";

export const useEditParameters = (actions) => {
    const [hospital_test_name, setHospitalTestName] = useState('');
    const [hospital_test_type, setTestType] = useState('');
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [tests, setTests] = useState([{parameter_name: '', reference_range:'', //critical_low: '', critical_high: '',
        unit: '', code: '', hospital_test_detail_id: '', isEdited:false}]);
    const [parametersRemoved, setParametersRemoved] = useState([])
    const [parametersEdited, setParametersEdited] = useState([])
    const [parameters, setParameters] = useState([]);
    const [hospitalTypes, setHospitalTypes] = useState([]);
    const [test, setTest] = useState({});
    const [hospital_test_id, setId] = useState(null)
    const [hospital_test_code, setHospitalCode] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [result_type, setResultType] = useState('');
    const [positive, setPositive] = useState('');
    const [hospital_test_detail_id, setHospId] = useState('')
    const [openEdit, setOpenEdit] = useState(false)
    const [prevResultType, setPrevResultType] = useState('')


    useEffect(() => {
        axios.get(`${config.smsUrl}/claboratory/lab_type_list`).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const all_type = !dt.lab_list ? [] : dt.lab_list;
            setHospitalTypes(all_type);
        }).catch(err => {
            logoutErrorMessage(err, null, actions);

        })

    }, []);

    const handleOpenEdit = (id) =>{
        setId(id)
        setOpenEdit(true)
        const formData = new FormData();
        formData.append('hospital_test_id', id);
        axios.post(`${config.smsUrl}/claboratory/parameter_update_form`, formData).then((res) => {
            const data = res.data;
            const dt = !data ? {} : data;
            const para = !dt.para ? [] : dt.para;
            const details = !dt.test ? {} : dt.test;
            setParameters(para);
            setTest(details);
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }


    useEffect(() => {
        setHospitalTestName(test.hospital_test_name);
        setTestType({value: test.hospital_test_type_id, label: test.hospital_test_type});
        setHospitalCode(test.hospital_test_code ? test.hospital_test_code : '');
        setResultType(test.result_type ? {
            value: test.result_type, label: test.result_type === 1 ?
                'Qualitative' : test.result_type === 2 ? 'Quantitative' : test.result_type === 3 ? 'Notes' : ''
        } : '');
        setPrevResultType(test.result_type)
        const params = parameters.map(param => ({
            code: param.sub_test_code !=='null' ?  param.sub_test_code : '',
            unit: param.unit !=='null' ?  param.unit : '',
            parameter_name: param.sub_test_name !=='null' ?  param.sub_test_name : '',
            reference_range: param.reference_range !=='null' ?  param.reference_range : '',
            hospital_test_detail_id: param.hospital_test_detail_id,
            isEdited:false,
            disabled: false
        }));
        const param = parameters ? parameters : []
        const para = param[0] ? param[0] : {};
        const val = para.sub_test_name ? para.sub_test_name : '';
        const label = val === 'blood_group' ? 'Blood Group' :
            val === '+ve/-ve' ? 'Positive/Negative' :
                val === 'reactive/non-reactive' ? 'Reactive/Non-reactive' : '';

        setPositive({value: val, label})
        setHospId(para.hospital_test_detail_id ?? '')
        setTests(params)
    }, [parameters, test]);

    const handleChangePositive = (value) => {
        setPositive(value)
    }


    const handleAddRow = () => {
        const allRows = [...tests];
        const row = {
            parameter_name: '',
            reference_range:'',
            //critical_low: '',
            //critical_high: '',
            hospital_test_detail_id: '',
            unit: '',
            code: '',
        };
        allRows.push(row);
        setTests(allRows)
    };


    const handleChangeCode = (event) => {
        setHospitalCode(event.target.value)
    };

    const removeRow = (index) => {
        const arr = tests.filter((_,idx)=>idx !== index)
        const removed = tests.filter((_,idx)=>idx === index).map(item=>({
            sub_test_code:item.code,
            sub_test_name:item.parameter_name,
            ...item
        }))
        setTests(arr);
        setParametersRemoved([...parametersRemoved,...removed])
    };

    const handleChange = (event, idx) => {
        const {name, value} = event.target;
        const qualitative_edited = prevResultType === result_type.value
        const arr = tests.map((item, index)=>{
            if(index === idx){
                return {...item, [name]:value, isEdited:qualitative_edited ?  true:false}
            }
            return item;
        })
        const edited = arr.filter(item=>item.isEdited).map(item=>({
            sub_test_code:item.code,
            sub_test_name:item.parameter_name,
            ...item
        }))
        setTests(arr)
        setParametersEdited(edited)
    };

    const handleChangeHospitalType = (value) => {
        setTestType(value)
    };

    const handleChangeResultType = (value) => {
        setResultType(value)
    }



    const handleCloseEdit = () =>{
        setOpenEdit(false)
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const items = tests.map(item=>({
            ...item,reference_range: +item.reference_range // critical_high: +item.critical_high, critical_low: +item.critical_low
        }))
        //const arr = items.every(item=>item.critical_low && item.critical_high && item.critical_high > item.critical_low)
        const arr = items.every(item=>item.parameter_name)

        const isQualitative = (result_type.value === 1 && positive.value) || (result_type.value === 2 && arr)

        const isNotes = result_type.value === 3 && !isQualitative
        setSubmitted(true)
        const quantitative_parameters = tests.filter(item=>item.isEdited === false).map(item=>({
            sub_test_code:item.code, sub_test_name:item.parameter_name, hospital_test_detail_id:item.hospital_test_detail_id,
            reference_range:item.reference_range, unit:item.unit ? item.unit : ''
        }))
        const qualitative_edited = prevResultType === result_type.value
        if ((isQualitative || isNotes) && hospital_test_type && hospital_test_code) {
            setIsSubmitted('pending');
            
            const isQuantitative = {parameters_edited:parametersEdited, parameters_removed:parametersRemoved,
                parameters_added:quantitative_parameters}

            const isQualitative = {sub_test_name:positive.value,hospital_test_detail_id,
                parameters_added: !qualitative_edited ? [{sub_test_name:positive.value,hospital_test_detail_id}] : [],
                parameters_edited:qualitative_edited ? [{sub_test_name:positive.value,hospital_test_detail_id}]: [],parameters_removed:[]}
            
            const isNotes = {sub_test_name:positive.value,hospital_test_detail_id,
                parameters_edited:[],parameters_removed:[], parameters_added:[]}

            const params = result_type.value === 2 ? isQuantitative : result_type.value === 1  ? isQualitative : isNotes;
            axios.post(`${config.smsUrl}/claboratory/edit_lab_test_parameters`, {hospital_test_id,
                hospital_test_type_id:hospital_test_type.value,hospital_test_name,hospital_test_code,
            result_type:`${result_type?.value}`,hospital_test_description:'', ...params}).then(() => {
                actions.snackbarActions.snackSuccess('Lab test parameters update successfully');
                setIsSubmitted('resolved');
                setSubmitted(false)
                setOpenEdit(false)
            }).catch(e => {
                setIsSubmitted('rejected');
                errorMessages(e, null, actions)
                setSubmitted(false)
            })
        }

    };

    const closeSnackbar = () => {
       
        actions.snackbarActions.hideSnackbar()
    };
    const isPending = isSubmitted === 'pending';
    const isResolved = isSubmitted === 'resolved';
    const isRejected = isSubmitted === 'rejected';
    const editProps = {
        hospital_test_name, hospital_test_type, isSubmitted, tests, parameters,
        hospitalTypes, test, hospital_test_id, hospital_test_code, handleAddRow,
        handleChangeCode, removeRow, handleChange, handleChangeHospitalType, handleSubmit,
        closeSnackbar, submitted, result_type, setTests,isPending,isRejected,
        handleChangeResultType, positive, handleChangePositive, isEditable:true
    }
    return {editProps, openEdit, handleOpenEdit, handleCloseEdit,  isResolved}
}