import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../../actions/snackbarActions"
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import ReusableTabs, {TabPanel} from "../../Utils/Dialogs/ReusableTabs";
//import {useTheme} from "@material-ui/core/styles";
import { useSurgeryVisit } from './hooks/useSurgeryVisit';
import ScheduleSurgery from "../../SurgeryModule/ScheduleSurgery";
import {DoctorsDetails} from "../../SurgeryModule/DoctorsDetails";
import { RightAlignedContainer} from "../../Utils/styledComponents";
import PrintDialog from "../../Utils/Dialogs/PrintDialog";
import CurrentSurgeries from "../../SurgeryModule/CurrentSurgeries";

/**
 * Function to handle Surgery visits for emergency, maternity and patients referred from other
 * hopsitals for surgery
 * @param actions, snackbars, match 
 * @returns 
 */
const SurgeryVisit = ({actions, snackbars, match}) => {

    const {patient_number} = match.params;

    const {
        patientDetails, onChange, handleNotesState,
        operation_class, operationClasses, handleOpClassChange,
        anaesthesia_type, anaesthesiaTypes, handleAnesTypeChange,  
        surgery_name, handleSurgeryTypeChange, surgeryTypes,
        p_number, patientNumbers, handleChangePatientNumbers, handleClickPatientNumber,
        handleSubmitSurgery,
        submitted, showNumber, isOpen, 
        handleChangeSurgeon, handleSelectSurgeon, handleChange, inputDoc,
        handleCloseConfirm, openConfirmationDialog, surgeryConfirmation,     
        state, surgeon, surgeons, general_notes,
        other_anaestheists, other_surgeons,isVisitingDoctor,
        handleOtherAnaestheists, handleOtherSurgeons,handleChangeDoctors,
        tabs, tabValue, handleChangeTabValue
    } = useSurgeryVisit(actions, patient_number);

    const { operation_date, estimated_start_time, duration_hr, duration_mins} = state;

    /*const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    const {openBar, type, message} = snackbars;*/
    
    return (
        <div className="journals">

                <PageTitle title="Surgery Visit"/>
                <SubHeader title="Doctor" subTitle="Surgery">
                    <img src="/images/surgery-21.png" className="icon-resize surgery-icon-tabs" alt="Surgery"/>
                </SubHeader>

                {surgeryConfirmation ?
                    <PrintDialog handleCancel={handleCloseConfirm} openDialog={surgeryConfirmation} handleClose={handleCloseConfirm}
                    handleClick={handleSubmitSurgery} message="Confirm Surgery"
                    text="Would you like to confirm this scheduled surgery?"/> : null
                }

                <ReusableTabs value={tabValue} tabHeading={tabs} handleChange={handleChangeTabValue}>
                        
                    <TabPanel value={tabValue} index={0}>
                        <ScheduleSurgery
                            p_number={p_number} patientDetails={patientDetails} patientNumbers={patientNumbers}
                            handleChangePatientNumbers={handleChangePatientNumbers} handleClickPatientNumber={handleClickPatientNumber}
                            submitted={submitted} showNumber={showNumber}
                            operation_date={operation_date} estimated_start_time = {estimated_start_time} duration_hr={duration_hr} duration_mins={duration_mins} onChange={onChange}
                            operation_class={operation_class} operationClasses={operationClasses} handleOpClassChange={handleOpClassChange}    
                            surgery_name={surgery_name} surgeryTypes={surgeryTypes} handleSurgeryTypeChange={handleSurgeryTypeChange}                     
                        />
                        <DoctorsDetails
                            other_anaestheists={other_anaestheists} other_surgeons ={other_surgeons}
                            anaesthesia_type={anaesthesia_type} anaesthesiaTypes={anaesthesiaTypes} handleAnesTypeChange={handleAnesTypeChange}
                            general_notes={general_notes} handleNotesState={handleNotesState} visitModule={true}
                            {...{surgeon, surgeons, isOpen, handleChangeSurgeon, handleChange, handleSelectSurgeon, handleOtherAnaestheists, handleOtherSurgeons, handleChangeDoctors, isVisitingDoctor, inputDoc}}
                        />
                        <RightAlignedContainer>
                            <button type="submit" className="btn btn-sm sms-btn" onClick={openConfirmationDialog}>Save surgery</button>
                        </RightAlignedContainer>                                
                    </TabPanel>

                    <TabPanel value={tabValue} index={1}>
                        <CurrentSurgeries actions={actions} snackbar={snackbars} surgeryVisit={true} surgeriesByStatus={0}/>
                    </TabPanel>

                </ReusableTabs>

        </div>
        )
};

function mapStateToProps(state) {
    return {
        snackbars: state.snackbar
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SurgeryVisit);
