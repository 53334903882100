import React, {useEffect,  useState} from "react";
import {CustomTablePagination, EnhancedSearchTableToolbar} from "../../Utils/Tables/CustomTable";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import *  as snackbarActions from "../../../actions/snackbarActions";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {Container} from "../../Utils/styledComponents";
import axios from "axios";
import {config} from "../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {dateConvert} from "../../Utils/ConvertDate";
import {LoadingGif} from "../../Utils/Loader";
import {CustomTableCell, MuiPaper, StickyHeadTable} from "../../Utils/Tables/StickyHeaderTable";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClipboardList} from "@fortawesome/free-solid-svg-icons/faClipboardList";
import {ReportDateFilter} from "../ReportDateFilter";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Label from "../../Utils/FormInputs/Label";
import {CustomSelect} from "../../Utils/FormInputs/SelectInput";

const headData = [
    {id: 'count', numeric: false, disablePadding: false, label: 'Sl'},
    {id: 'product_name', numeric: false, disablePadding: false, label: 'Product name'},
    {id: 'total_quantity_in', numeric: false, disablePadding: false, label: 'Quantity In'},
    {id: 'total_quantity_out', numeric: false, disablePadding: false, label: 'Quantity Out'},
    {id: 'total_quantity_remaining', numeric: false, disablePadding: false, label: 'Available stock'},
    {id: 'usage_percentage', numeric: false, disablePadding: false, label: 'Percentage Sold'},
    {id: 'department_name', numeric: false, disablePadding: false, label: 'Department'},
    // {id: 'total_usd', numeric: false, disablePadding: false, label: 'Total(USD)'},
    // {id: 'total_ssp', numeric: false, disablePadding: false, label: 'Total(SSP)'}
];


const FastMovingStockReport = ({actions, snackbars}) => {
    const departments=[
        {value:'Store', label:'Store'},
        {value:'Pharmacy', label:'Pharmacy'},
        {value:'Laboratory', label:'Laboratory'},
    ]
    const [date, setDate] = useState({start_date: dateConvert(), end_date: dateConvert()});
    const [searchValue, setSearchValue] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isSubmitted, setIsSubmitted] = useState('idle');;
    const [data, setData] = useState({stockList:[], loading:'idle', total:0});
    const [submitted, setSubmitted] = useState(false);
    const [activePage, setActivePage] = useState(1)
    const [department, setDepartment] = useState({value:'Store', label:'Store'})


    const {start_date, end_date} = date;

    const {loading, total, stockList} = data

    const isResolved = isSubmitted === 'resolved'


    useEffect(() => {
        setActivePage(1)
        setPage(0)
    }, [department, isResolved]);


    useEffect(() => {
        setData({...data, loading: 'pending'})
        axios.get(`${config.smsUrl}/creport/fast_moving_products`, {
            params: {
                start_date: start_date,
                end_date: end_date,
                department:department?.value,
                page:activePage,
                search:searchValue
            }
        }).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const arr = dt.data ? dt.data : []
            const total_count = dt.count ? dt.count : 0

            const stock = arr.map(item=>({
                ...item,
                department_name:department?.value,
                usage_percentage:`${item?.percentage_sold?.toFixed(1)} %`
            }))
            setData({...data, loading: 'success', stockList: stock, total: total_count})
        }).catch((err) => {
            setData({...data, loading: 'error'})
            logoutErrorMessage(err, null, actions);
        })
    }, [department, searchValue, activePage]);

    const handleSubmit = (event) => {
        event.preventDefault();
        const {start_date, end_date} = date;
        setSubmitted(true)
        if (start_date && end_date) {
            setIsSubmitted('pending')
            setData({...data, loading: 'pending'})
            axios.get(`${config.smsUrl}/creport/fast_moving_products`, {
                params: {
                    start_date: start_date,
                    end_date: end_date,
                    department:department?.value,
                    page:activePage,
                    search:searchValue
                }
            }).then(res => {
                const data = res.data;
                const dt = !data ? {} : data;
                const arr = dt.data ? dt.data : []
                const total_count = dt.count ? dt.count : 0
                const stock = arr.map(item=>({
                    ...item,
                    department_name:department?.value,
                    usage_percentage:`${item?.percentage_sold?.toFixed(1)} %`
                }))
                setData({...data, loading: 'success', stockList: stock, total: total_count})
                setIsSubmitted('resolved')
            }).catch(err => {
                setIsSubmitted('rejected')
                errorMessages(err, null, actions);
                setData({...data, loading: 'error'})
            })
        }
    }

    const handleChangeDate = (event) => {
        const {value, name} = event.target;
        setDate({...date, [name]: value})
    }


    const handleSearch = (event) => {
        setSearchValue(event.target.value)
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setActivePage(newPage+1)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        setActivePage(1);
    };

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };



    const {openBar, type, message} =snackbars;

    const isLoading = loading === 'pending'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'

    const isPending = isSubmitted === 'pending'

    const pagination = (
        <CustomTablePagination
            colSpan={3}
            activePage={activePage}
            page={page}
            rowsPerPage={rowsPerPage}
            count={total}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            isTotalCount
        />
    );

    const search_bar = (
        <EnhancedSearchTableToolbar
            term={searchValue} handler={handleSearch}
        />
    )
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, stockList.length -  rowsPerPage);

    return (
        <div data-testid={`fast-moving-stock-report`}>
            <PageTitle title='Fast moving stock report'/>
            <SubHeader title="Reports" subTitle='Fast moving stock report'>
                <FontAwesomeIcon icon={faClipboardList}/>
            </SubHeader>
            <Container>
                <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>
                <ReportDateFilter {...{handleSubmit, start_date, end_date, handleChange:handleChangeDate,isPending,
                    submitted,actions, file_name:'Revenue per department',
                    excelUrl:'creport/fast_moving_products_exports', excelParams:{export_type:2, department:department?.value, search:searchValue, page:activePage},
                    pdfUrl:'creport/fast_moving_products_exports', pdfParams:{export_type:1,department:department?.value, search:searchValue, page:activePage}
                }}>
                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={12} lg={7} xl={7} style={{paddingLeft: 0}}>
                            <Form.Group as={Row}>
                                <Col xs={12} sm={12} md={2} lg={2} xl={2} style={{paddingLeft: 0}}>
                                    <Label name='Department'/>
                                </Col>
                                <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                                    <CustomSelect value={department} onChange={(value) => setDepartment(value)}
                                                  options={departments}/>
                                </Col>
                            </Form.Group>
                        </Col>
                    </Form.Group>
                </ReportDateFilter>

                <MuiPaper>
                    <StickyHeadTable {...{
                        width: '100%',
                        data: stockList,
                        columns: headData.filter(item => Boolean(item)),
                        pagination,
                        search_bar
                    }}>
                        {isLoading ? <TableRow><TableCell
                            align='center' {...{colSpan: headData.length}}><LoadingGif/></TableCell></TableRow> : null}
                        {isSuccess ? stockList.length > 0 ?
                            <>
                                {stockList.slice(0, 10).map((row, index) => {
                                    const count = ((activePage - 1) * 10) + index + 1;
                                    return (
                                        <TableRow tabIndex={-1} key={index}>
                                            {headData.map(col => (
                                                col.id === 'count' ? <CustomTableCell>{count}</CustomTableCell> :
                                                    <CustomTableCell>{row[col.id]}</CustomTableCell>
                                            ))}
                                        </TableRow>
                                    );
                                })}
                                {(stockList.length < 10 && emptyRows > 0) && (
                                    <TableRow style={{height: 5 * emptyRows}}>
                                        <TableCell colSpan={headData.length}/>
                                    </TableRow>
                                )}
                            </> : <TableRow><TableCell align='center'  {...{colSpan: headData.length}}>No records
                                found</TableCell></TableRow> : null}
                        {isError ?
                            <TableRow><TableCell align='center'  {...{colSpan: headData.length}}>The server did not
                                return a valid response</TableCell></TableRow> : null}
                    </StickyHeadTable>
                </MuiPaper>
            </Container>

        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(FastMovingStockReport);
