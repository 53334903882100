/** @jsxImportSource @emotion/react */
import React, {useRef} from 'react'
import Form from "react-bootstrap/Form";
import Label from "../Utils/FormInputs/Label";
import styled from "@emotion/styled";
import axios from "axios";
import {config} from "../../Helpers/env";
import {AutoCompleteProducts} from "../Utils/FormInputs/AutoCompleteInput";
import {smallTable, table} from "../../styles/tableStyles";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {StyledRemoveIcon} from "../DoctorsModule/doctorStyles";
import {RightAlignedContainer} from "../Utils/styledComponents";
import {ConfirmationDialog} from "../Utils/ReusableComponents/ConfirmationDialog";
import {errorMessages} from "../../Helpers/ErrorMessages";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../actions/snackbarActions";
import {connect} from "react-redux";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";

const Heading = styled.h6({
    margin:'0 0 12px 0',
    fontWeight:600,
    fontSize:'14px'
})

const SubHeading = styled.p({
    fontWeight:600,
    fontSize:'13px',
    margin:'10px 0',
    textDecoration:'underline',
    lineHeight:1
})

const ProductContainer = styled.div({
    padding:'5px'
})

const Item  = styled.div({
    border:'1px solid #dddbdb',
    padding:'8px',
    borderRadius:'8px',
    marginTop:'8px'
})
const Span = styled.span({
    display:'inline-block',
    marginRight:'8px',
    padding:'0.25rem 0'
})
const colors = {
    'red':{
        color:'#c4495c'
    },
    'green':{
        color:'#4caf50'
    }
}

function AddServiceRequestForm(props) {
    const {visit_id, isSubmitted, setIsSubmitted, actions, snackbars,setOpenServiceDialog} = props

    const [addedServices, setAddedServices] = React.useState([])
    const [services, setServices]  = React.useState([])
    const [service, setService] = React.useState('')
    const [openConfirmation, setOpenConfirmation] = React.useState(false)
    const [procedure_id, setProcedureId] = React.useState('')
    const [submitted, setSubmitted] = React.useState(false)
    const [status, setStatus] = React.useState('idle')

    const formRef = useRef()

    const isSelected = status === 'selected'

    const autoSearchComplete = (event) =>{
        const formData = new FormData();
        formData.append('service_name', event);
        setService(event)
        if (event !== '') {
            axios.post(`${config.smsUrl}/cdental/search_dental_services`, formData).then(res => {
                const resp = res.data;
                const data = !resp ? [] : resp;
                if (data[0] === "No Product Found") {
                    setServices([{value: '', label: 'No service found'}])
                } else {
                    const arr = data.map(item => ({
                        value: item.procedure_id,
                        label: item.label,
                        service_id:item.value
                    }))
                    setServices(arr);
                }
            }).catch(error => {
                // logoutErrorMessage(error, null, actions)
            });
        }
    }

    const getServiceDetails = (event) => {
        const formData = new FormData();
        formData.append('procedure_id', event.value);
        setService(event.innerText)
        if (event.value) {
            axios.post(`${config.smsUrl}/cdoctor/get_service_id_by_procedure_id`, formData).then(res => {
                const resp = res.data;
                const data = !resp ? {} : resp;
                const {service_id:_id} = data;
                axios.post(`${config.smsUrl}/cbilling/item_cost`, {visit_id: +visit_id, item_id:+_id, item_type:'service'}).then(res => {
                    const resObj = res.data;
                    const dataObj = !resObj.data ? {} : resObj.data;
                    const obj = { rate:  dataObj.cost ?? 0,...dataObj,
                        currency: dataObj.currency_id, currency_symbol: dataObj.currency,
                        service_name:event.innerText,procedure_id:event.value, service_id:event.service_id}
                    const arr = [obj,...addedServices, ]
                    setAddedServices(arr)
                    if(dataObj.is_provider === 1 && dataObj.is_provided === 0){
                        setProcedureId(data.procedure_id)
                        setOpenConfirmation(true)
                    }
                    setStatus('selected')
                }).catch(error => {
                    // errorMessages(error, null, actions)
                });
            }).catch(error => {
                // errorMessages(error, null, actions)
            });
        }
    }

    const handleRemove = (index) =>{
        const arr = addedServices.filter((_, idx)=>index !== idx)
        setAddedServices(arr)
    }


    const addItemsToReceipt = () =>{
        const arr = addedServices.filter(item=>item.procedure_id === procedure_id)
        setAddedServices(arr)
        setOpenConfirmation(false)
    }

    const handleCloseConfirmation = () =>{
        const arr = addedServices.filter(item=>item.procedure_id !== procedure_id)
        setAddedServices(arr)
        setOpenConfirmation(false)
    }

    const handleCancelConfirmation = () =>{
        setOpenConfirmation(false)
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const arr = addedServices.every(prod => prod.service_id && prod.procedure_id)
        setSubmitted(true)
        if (arr) {
            setIsSubmitted('pending');
            const params = {
                visit_id, result_type: 1,bill_type:2,
                bill_details: addedServices.filter(prod => prod.service_id && prod.procedure_id).map(({procedure_id, service_id, qty,rate,...item}) => ({
                    procedure_id,
                    service_id,
                    quantity:1,
                    rate,
                    is_provided: item.is_provided,
                    is_provider: item.is_provider,
                    currency_id:item.currency_id
                }))
            }

            axios.post(`${config.smsUrl}/cdoctor/add_procedure`, {...params}).then(() => {
                actions.snackbarActions.snackSuccess('Procedure added successfully');
                setIsSubmitted('resolved');
                setSubmitted(false)
                setAddedServices([])
                setOpenServiceDialog(false)
                setService({value:'', label:''})
            }).catch(err => {
                errorMessages(err, null, actions)
                setIsSubmitted('rejected');
            })
        }
    };

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const isPending = isSubmitted === 'pending'

    const {openBar, type, message} = snackbars;

    return (
        <div>
            <ConfirmationDialog open={openConfirmation} handleClose={handleCancelConfirmation}
                                title='Make cash payment'
                                confirmItems={addItemsToReceipt}
                                removeItems={handleCloseConfirmation} service='procedure'/>
            <MainSnackbar open={openBar} handleCloseBar={closeSnackbar} variant={type} message={message}/>
            <form ref={formRef} onSubmit={handleSubmit}>
                <Form.Group>
                    <Label name='Select service'/>
                    <AutoCompleteProducts  options={services} handleChange={autoSearchComplete} reset={isSelected}
                                           medicine_value={service}  placeholder="Search for a service..."
                                           dataTest="service" testId='service_name' handleProductData={getServiceDetails}
                                           errorMsg='Service is required' id='service' value_text='value' label_text='label' />
                    {addedServices.length > 0 ?  addedServices.map((item, index)=>{
                        return (
                            <Item key={index}>
                                <div className="table-responsive">
                                    <table css={[table, smallTable]}>
                                        <tbody>
                                        <tr>
                                            <td>
                                                {item.service_name}
                                            </td>
                                            <td>
                                                <Tooltip title='Remove'>
                                                    <IconButton type='button' onClick={()=>handleRemove(index)}>
                                                        <StyledRemoveIcon/>
                                                    </IconButton>
                                                </Tooltip>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </Item>
                        )
                    }): null}
                </Form.Group>
                <RightAlignedContainer>
                    <button type='submit' className='btn btn-sm small-btn sms-info-btn px-3'>{isPending ? 'Saving...':'Save'}</button>
                </RightAlignedContainer>
            </form>
        </div>

    )
}

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddServiceRequestForm);