import React, {useEffect, useRef, useState} from 'react';
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBookMedical} from "@fortawesome/free-solid-svg-icons/faBookMedical";
import ReactToPrint from "react-to-print";
import Card from "@material-ui/core/Card";
import {HospitalAddress} from "../../Utils/HospitalAddress";
import * as snackbarActions from "../../../actions/snackbarActions";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import axios from "axios";
import { config } from "../../../Helpers/env"
import {logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {extractTime, extractDate, formatTime} from "../../Utils/ConvertDate";
import { BackButton } from '../../Utils/Buttons/DataExportationButton';


const ViewDeathReport = ({match, actions, snackbar}) => {
    const [details, setDetails] = useState({});
    const componentRef = useRef()
    const {patient_death_id, patient_type} = match.params;

    useEffect(() => {
        const formData = new FormData();
        formData.append('patient_death_id', patient_death_id);
        const url = patient_type === '2' ? `${config.smsUrl}/creport/viewDetails` : `${config.smsUrl}/creport/viewoutDetails`
        axios.post(url, formData).then(res => {
            const data = res.data;
            const dt = data ? data : {};
            setDetails(dt)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, [patient_death_id])
   

    const handleCloseSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    }
    const {type, message, openBar} = snackbar;
    const { date_of_death, district_of_origin, 
         nationality, patient_firstname, patient_lastname,
        time_of_death,gender,bed_number, ward_name,age, admission_date, underying_cause_of_death, immediate_cause_of_death, religion, tribe, witnessed_by, witnessed_by_relationship, witnessed_by_phone_no, received_by, received_by_relationship, received_by_phone_no, occupation, omorbid_illness } = details
    const deathTime = `${date_of_death}T${time_of_death}`
    return (
        <div data-testid="death-report">
            <PageTitle title="Mortality Form"/>
            <SubHeader title="Reports" subTitle="Mortality form">
                <FontAwesomeIcon icon={faBookMedical}/>
            </SubHeader>
            <MainSnackbar message={message} open={openBar} handleCloseBar={handleCloseSnackbar} variant={type}/>
            <section className="general-lab-report">
                <div>
                <div style={{margin: '10px'}}>
                    <BackButton text={'Death Reports'} to={'/reports/deathreports'}/>
                </div>
                <div className="text-right">
                    <ReactToPrint content={() => componentRef.current}
                                  trigger={() => <button className="btn sms-amber-btn mb-2">Print</button>}/>
                </div>
                </div>

                <Card className="p-2 lab-report" ref={componentRef} id="prescription-print">
                    <HospitalAddress/>
                    <table className="table table-borderless table-sm">
                        <tr>
                            <td colSpan="2" align="center"><p className="underline">MORTALITY FORM</p></td>
                        </tr>
                    </table>
                    <table className="table table-borderless table-sm">
                        <tr>
                            <td colSpan="2"><p className="underline">PATIENT IDENTIFICATION</p></td>
                        </tr>
                        <tr>
                            <td><p>Name:<span className='dotted-span' style={{width: '60%'}}>
                                {`${patient_firstname ? patient_firstname : ''} ${patient_lastname ? patient_lastname : ''}`}
                            </span></p></td>
                            <td><p>Age:<span className='dotted-span' style={{width: '60%'}}>{age}</span></p></td>
                        </tr>
                        <tr>
                        <td><p>Gender:<span className='dotted-span' style={{width: '60%'}}>{gender}</span></p></td>
                        <td><p>Occupation:<span className='dotted-span' style={{width: '60%'}}/>{occupation}</p></td>
                           
                        </tr>
                      
                        <tr>
                            <td><p>Country:<span className='dotted-span' style={{width: '60%'}}>{nationality}</span></p></td>
                            <td><p>District:<span className='dotted-span'
                                                  style={{width: '60%'}}>{district_of_origin}</span></p></td>
                        </tr>
                        <tr>
                            <td><p>Religion:<span className='dotted-span' style={{width: '60%'}}>{religion}</span></p></td>
                            <td><p>Tribe:<span className='dotted-span' style={{width: '60%'}}>{tribe}</span></p></td>
                        </tr>
                        <tr>
                          
                            <td><p>Nationality:<span className='dotted-span' style={{width: '60%'}}>{nationality}</span>
                            </p></td>
                        </tr>
                        {patient_type === '2' && <tr>
                            <td><p>Admission Date:<span className='dotted-span' style={{width: '60%'}}>{extractDate(admission_date)}</span></p></td>
                            <td><p>Admission Time:<span className='dotted-span' style={{width: '60%'}}>{extractTime(admission_date)}</span></p></td>
                        </tr>}
                        <tr>
                            <td><p>Date of Death:<span className='dotted-span'
                                                       style={{width: '60%'}}>{date_of_death}</span></p></td>
                            <td><p>Time:<span className='dotted-span' style={{width: '60%'}}>{formatTime(deathTime)}</span></p>
                            </td>
                        </tr>
                    </table>
                    
                    <table className="table table-borderless table-sm">
                        <tr>
                            <td colSpan="2"><p className="underline">CAUSES OF DEATH</p></td>
                        </tr>
                        <tr>
                            <td colSpan="2"><p>Immediate:<span className='dotted-span'
                                                               style={{width: '89%'}}>{immediate_cause_of_death}</span></p></td>
                        </tr>
                        <tr>
                            <td colSpan="2"><p>Underlying:<span className='dotted-span' style={{width: '89%'}}>{underying_cause_of_death} </span></p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2"><p>Comorbid Illness:<span className='dotted-span' style={{width: '85%'}}>
                            {omorbid_illness}</span></p></td>
                        </tr>
                        {patient_type === '2' && <tr>
                            <td><p>WARD:<span className='dotted-span' style={{width: '78%'}}>{ward_name}</span></p></td>
                            <td><p>BED NO:<span className='dotted-span' style={{width: '83%'}}>{bed_number}</span></p></td>
                        </tr>}
                    </table>
                    <table className="table table-borderless table-sm">
                        <tr>
                            <td rowSpan="2" width={110}><p>Received By:</p></td>
                            <td width={250}><p>Name:<span className='dotted-span' style={{width: '60%'}}>
                                {received_by}
                            </span></p></td>
                            <td><p>Tel:<span className='dotted-span' style={{width: '70%'}}>{received_by_phone_no}</span></p></td>
                            <td><p>R/ship:<span className='dotted-span' style={{width: '60%'}}>{received_by_relationship}</span></p></td>
                        </tr>
                        <tr>
                            <td colSpan="3"><p>Signature:<span className='dotted-span' style={{width: '88%'}}/></p></td>
                        </tr>
                        <tr>
                            <td rowSpan="2" width={110}><p>Witnessed By:</p></td>
                            <td width={300}><p>Name:<span className='dotted-span' style={{width: '60%'}}>{witnessed_by}
                                </span></p></td>
                            <td><p>Tel:<span className='dotted-span' style={{width: '70%'}}>{witnessed_by_phone_no}</span></p></td>
                            <td><p>R/ship:<span className='dotted-span' style={{width: '60%'}}>{witnessed_by_relationship}</span></p></td>
                        </tr>
                        <tr>
                            <td colSpan="3"><p>Signature:<span className='dotted-span' style={{width: '88%'}}/></p></td>
                        </tr>
                    </table>
                </Card>
            </section>
        </div>
    )

};

function mapStateToProps(state) {
    const {snackbar} = state;
    return {snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewDeathReport);