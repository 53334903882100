import React, { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import CustomDialog from "../Dialogs/CustomDialog";



const BlockNavigationComponent = ({when, navigate, shouldBlockNavigation,dialogClose=false, setIsBlockDialog=()=>{}, clearForm=()=>{}}) => {

    const [modalVisible, setModalVisible] = useState(false);
    const [lastLocation, setLastLocation] = useState(null);
    const [confirmedNavigation, setConfirmedNavigation] = useState(false);



    useEffect(() => {
        window.onbeforeunload = when ? () => true : null;
        if (dialogClose){
            setModalVisible(true)
        }
        return () => {
            window.onbeforeunload = null;
        };
    }, [when, dialogClose]);

    const closeModal = () => {
        setModalVisible(false);
        setIsBlockDialog(false)
    };

    const handleBlockedNavigation = (nextLocation) => {
        if ((!confirmedNavigation && shouldBlockNavigation(nextLocation)) || (!confirmedNavigation && dialogClose)) {
            setModalVisible(true);
            setLastLocation(nextLocation);
            return false;
        }
        return true;
    };

    const handleConfirmNavigationClick = () => {
        setModalVisible(false);
        setConfirmedNavigation(true);
        clearForm()
    };

    useEffect(() => {
        if (confirmedNavigation && lastLocation) {
            // Navigate to the previous blocked location with your navigate function
            navigate(lastLocation.pathname);
        }

    }, [confirmedNavigation, lastLocation]);

    const buttons = (
        <>
            <button className='btn btn-sm sms-gray-btn mr-3' onClick={closeModal}>Cancel</button>
            <button className='btn btn-sm sms-info-btn' onClick={handleConfirmNavigationClick}>Yes</button>
        </>
    )

    return (
        <>
            <Prompt when={when} message={handleBlockedNavigation} />
            <CustomDialog open={modalVisible} handleClose={closeModal} title='Close without saving?' buttons={buttons}>
                <p style={{margin:0}}>You have unsaved changes. If you leave this page, you won't be able to recover the changes. Are you sure you want to leave this page?</p>
            </CustomDialog>
        </>
    );
};
export {BlockNavigationComponent};