import React from "react";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CustomTable from "../../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import {getComparator, stableSort} from "../../Utils/Tables/sortingFunctions";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {Link} from "react-router-dom";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import *  as snackbarActions from "../../../actions/snackbarActions";
import DeleteDialog from "../../Utils/Dialogs/DeleteDialog";
import {faShippingFast} from "@fortawesome/free-solid-svg-icons/faShippingFast";
import {faEye} from "@fortawesome/free-solid-svg-icons/faEye";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {useRequisitionList} from "./hooks/useRequisitionList";
import {dateStringConvert} from "../../Utils/ConvertDate";
import { Container } from "../../Utils/styledComponents";

const headData = [{id: 'sl', numeric: false, disablePadding: false, label: 'SL'},
    {id: 'date', numeric: false, disablePadding: false, label: 'Date Requested'},
    {id: 'from', numeric: false, disablePadding: false, label: 'From'},
    {id: 'requestedBy', numeric: false, disablePadding: false, label: 'Requested By'},
    {id: 'actions', numeric: false, disablePadding: true, label: 'Action'},
];


const RequisitionList = ({actions, snackbars, match}) => {

    const {
        searchValue, order, orderBy, loading, requisitionList, openDelete, activePage, allRecords,
        total_count,  handleCloseDelete, handleDelete, closeSnackbar, handleSearch,
        handleRequestSort, handleChangeActivePage, status
    } = useRequisitionList(actions, match)
    const requisitions = !requisitionList ? [] : requisitionList;
    const {openBar, type, message} = snackbars;
    const perm = JSON.parse(sessionStorage.getItem('permission'));
    const permission = !perm ? {} : perm;
    const requisition_list = !permission.requisition_list ? {} : permission.requisition_list;
    const requisition_pharmacy = !permission.requisition_pharmacy ? {} : permission.requisition_pharmacy;
    return (
        <div data-testid="requisition-list" className='journals'>
            <PageTitle title="Requisition List"/>
            <SubHeader title="Inventory" subTitle="Requisition List">
                <FontAwesomeIcon icon={faShippingFast}/>
            </SubHeader>
            <Container>
            <div className="mui-tables">
            <DeleteDialog message={'delete'} openDialog={openDelete} handleClose={handleCloseDelete}
                                    text="requisition" title={`Delete Requisition`}>
                        <button className='btn sms-info-btn btn-sm' onClick={handleDelete}>Delete</button>
                        <button className="btn sms-grey-btn btn-sm" onClick={handleCloseDelete}>No</button>    
                </DeleteDialog>
                <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>
                <CustomTable title="Requisition List" activePage={activePage}
                             handleChangeNextPage={handleChangeActivePage}
                             headData={headData} handler={handleSearch} total_count={total_count} records={10}
                             customPage
                             handleRequestSort={handleRequestSort} data={requisitions} colSpan={5} order={order}
                             orderBy={orderBy} term={searchValue}>
                    {loading ? <TableBody><TableRow>
                        <TableCell colSpan="5" align="center"><img src="/images/smsloader.gif"
                                                                   alt="loader"/></TableCell>
                    </TableRow></TableBody> : requisitions.length > 0 ?
                        <TableBody>
                            {stableSort(requisitions, getComparator(order, orderBy))
                                .slice(0, 10)
                                .map((item, index) => {
                                    const count = ((activePage - 1) * 10) + index + 1;
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={index}
                                            className='mui-table-row'>
                                            <TableCell>{count}</TableCell>
                                            <TableCell>{dateStringConvert(item.date_created)}</TableCell>
                                            <TableCell>{item.department_name}</TableCell>
                                            <TableCell>{`${!item.first_name ? "" : item.first_name} ${!item.last_name ? "" : item.last_name}`}</TableCell>
                                            <TableCell>
                                                <Link
                                                    to={{pathname: `/viewrequisition/store/${item.requisition_id}`}}>
                                                    <button className="btn btn-sm sms-btn mr-1">
                                                        <FontAwesomeIcon icon={faEye}/></button>
                                                </Link>
                                                {status && requisition_pharmacy.update ? 
                                                <Link to={{pathname: `/editrequisition/${item.requisition_id}/${status}`}}>
                                                        <button className="btn btn-sm sms-info-btn mr-1">
                                                            Dispatch
                                                        </button>
                                                    </Link>
                                                    : (requisition_list.update ? <Link
                                                        to={{pathname: `/editrequisition/${item.requisition_id}`}}>
                                                        <button className="btn btn-sm sms-info-btn mr-1">
                                                            Dispatch
                                                        </button>
                                                    </Link> 
                                                    : 
                                                <div/>)}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                        : <TableBody><TableRow>
                            <TableCell colSpan="5" align="center">No records found</TableCell>
                        </TableRow></TableBody>}
                </CustomTable>
            </div>
            </Container>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(RequisitionList);
