import React from "react";
import PageTitle from "../Utils/smsTitle";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CustomTable from "../Utils/Tables/CustomTable";
import FilterResults from "react-filter-search";
import TableBody from "@material-ui/core/TableBody";
import {getComparator, stableSort} from "../Utils/Tables/sortingFunctions";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {Link} from "react-router-dom";
import {faCog} from "@fortawesome/free-solid-svg-icons/faCog";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../actions/snackbarActions"
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import DeleteDialog from "../Utils/Dialogs/DeleteDialog";
import {useUserRolesList} from "./CustomHooks/useUserRoleList";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import { Container } from "../DoctorsModule/doctorStyles";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Label from "../Utils/FormInputs/Label";
import Col from "react-bootstrap/Col";
import {CustomSelect} from "../Utils/FormInputs/SelectInput";
import TextField from "../Utils/FormInputs/TextField";
import { ErrorMessage } from "../Utils/styledComponents";
import CustomDialog from "../Utils/Dialogs/CustomDialog";

export const useStyles = makeStyles({
    selected:{
        '&$selected, &$selected:hover':{
            backgroundColor:'#d7d3d3'
        }
    }
})

const headData = [{id: 'sl', numeric: false, disablePadding: false, label: 'SL'},
    {id: 'userName', numeric: false, disablePadding: false, label: 'User Name'},
    {id: 'email', numeric: false, disablePadding: false, label: 'Email'},
    {id: 'role', numeric: false, disablePadding: false, label: 'Role'},
    {id: 'actions', numeric: false, disablePadding: true, label: 'Action'},
];
export const UserRoles = ({actions, snackbars}) => {
    const {searchValue, order, orderBy, page, rowsPerPage, userRoles, loading, openDelete,
         handleCloseDelete, handleDelete, handleCloseSnackbar, handleSearch,
        handleRequestSort, handleChangePage, handleChangeRowsPerPage,
        role_name, submitted, isSubmitted, roles,
        handleChangeRoleName, handleSubmit,status,
        state, employee_email,employees,handleChange,
        handleChangeEmployee, employee_id,openDialog,handleOpenDialog,handleCloseDialog
    } = useUserRolesList(actions);
    const {openBar, message, type} = snackbars;
    const rols = !roles ? [] : roles;
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    const classes = useStyles()
    return (
        <div data-testid="assigned-role-list" className='journals'>
            <PageTitle title="Assigned Roles"/>
            <SubHeader title="Settings" subTitle="Assigned Roles">
                <FontAwesomeIcon icon={faCog}/>
            </SubHeader>
            <div className='general-ledger-header'>
                <div className="text-right">
                    <button className='btn btn-sm sms-info-btn' onClick={handleOpenDialog}>Assign Role</button>
                </div>
            </div>
            <CustomDialog open={openDialog} handleClose={handleCloseDialog}
                            maxWidth='xs' title='Assign Role'>
                <form onSubmit={handleSubmit} autoComplete="off">
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                                <Label  name="Employee" type/>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={7} xl={7}>

                                <CustomSelect {...{submitted}} value={employee_id} onChange={handleChangeEmployee}
                                              options={employees.map(employee => ({
                                                  label: `${employee.first_name} ${employee.last_name}`,
                                                  value: employee.employee_id
                                              }))} id='employee_name' dataTest="employee_id"/>
                                {(submitted && !employee_id) &&
                                    <ErrorMessage>Employee name is required</ErrorMessage>}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                                <Label  name="Role" type/>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={7} xl={7}>

                                <CustomSelect {...{submitted}} value={role_name} onChange={handleChangeRoleName} options={rols.map(role => ({
                                    value: role.role_id,
                                    label: role.role_name
                                }))} dataTest="role_name" id='role_name'/>
                                {(submitted && !role_name) && <ErrorMessage>Role name is required</ErrorMessage>}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                                <Label  name="Email" type/>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                                <TextField id='_user_email' submitted={submitted}
                                           value={employee_email}
                                           name="email" type="email"  disabled={true}/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                                <Label name="Password" type/>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                                <TextField {...{submitted}} value={state.password} type="password"
                                           id='adduser_password'
                                           onChange={handleChange} name="password"
                                           className={`form__control ${palette.type === "dark" && 'form-control-dark'}`}/>
                                {(submitted && !state.password) ? <ErrorMessage>Password is required</ErrorMessage> :
                                    (submitted && state.password.length < 6) &&
                                    <ErrorMessage>Password should be at least 6 characters or more than </ErrorMessage>}
                            </Col>
                        </Form.Group>

                        <div className="text-right mr-5">
                            <button type="submit" disabled={isSubmitted === 'pending'} id='submit-role' className="btn btn-sm sms-btn px-4">
                                {isSubmitted === 'pending' ? "Saving..." : "Save"}</button>
                        </div>
                </form>
            </CustomDialog>
            <DeleteDialog message={status === 1 ?  'deactivate':'activate'} openDialog={openDelete} handleClose={handleCloseDelete}
                                    text="user" title={`${status === 1 ? 'Deactivate':'Activate'} User`}>
                    <button className='btn sms-info-btn btn-sm' onClick={handleDelete}>{status === 1 ? 'Deactivate':'Activate'}</button>
                    <button className="btn sms-grey-btn btn-sm" onClick={handleCloseDelete}>No</button>    
            </DeleteDialog>
            <Container>
                {/*<div className="text-right">*/}
                {/*    <button className='btn btn-sm sms-info-btn' onClick={handleOpenDialog}>Assign Role</button>*/}
                {/*</div>*/}
                <div className="mui-tables mt-3">
                <MainSnackbar handleCloseBar={handleCloseSnackbar} message={message} open={openBar} variant={type}/>
                <CustomTable handleChangePage={handleChangePage} handleChangeRowsPerPage={handleChangeRowsPerPage}
                             headData={headData} handler={handleSearch} page={page} rowsPerPage={rowsPerPage}
                             handleRequestSort={handleRequestSort} data={userRoles} colSpan={5} order={order}
                             orderBy={orderBy} id="searchValue" filter>
                    {loading ? <TableBody><TableRow>
                        <TableCell colSpan="7" align="center"><img src="/images/smsloader.gif"
                                                                   alt="loader"/></TableCell>
                    </TableRow></TableBody> : userRoles.length > 0 ?
                        <FilterResults value={searchValue} data={userRoles}
                                       renderResults={results => (
                                           <TableBody>
                                               {stableSort(results, getComparator(order, orderBy))
                                                   .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                   .map((item, index) => {
                                                       const count = (page * rowsPerPage) + index + 1;
                                                       return (
                                                           <TableRow
                                                               hover={item.status === 1}
                                                               tabIndex={-1}
                                                               key={item.user_roles_id}
                                                               className='mui-table-row'
                                                               classes={{selected:classes.selected}}
                                                               selected={item.status === 2}
                                                           >
                                                               <TableCell>{count}</TableCell>
                                                               <TableCell>{`${item.first_name} ${item.last_name}`}</TableCell>
                                                               <TableCell>{item.email}</TableCell>
                                                               <TableCell>{item.role_name}</TableCell>
                                                               <TableCell>
                                                                   {item.status === 1 && <Link to={{pathname: `/edituserrole/${item.user_roles_id}`}}>
                                                                       <button className="btn btn-sm sms-info-btn mr-1 py-1 mb-1">Edit</button>
                                                                   </Link>}

                                                                   <button onClick={() =>handleDelete(item.user_roles_id,item.status === 1 ? 2 : 1)}
                                                                                               className={`btn btn-sm py-1 mb-1 mr-1 ${item.status===1 ? 'sms-grey-btn':'sms-info-btn'}`}>
                                                                       {item.status === 1 ? 'Deactivate':'Activate'}
                                                                   </button>
                                                                   {item.status === 1 && <Link to={{pathname: `/changeuserpassword/${item.user_id}`}}>
                                                                       <button className="btn btn-sm sms-btn mr-1 py-1 mb-1">Change Password</button>
                                                                   </Link>}
                                                               </TableCell>
                                                           </TableRow>
                                                       );
                                                   })}
                                           </TableBody>
                                       )}/> : <TableBody><TableRow>
                            <TableCell colSpan="7" align="center">No records found</TableCell>
                        </TableRow></TableBody>}
                </CustomTable>
            </div>
            </Container>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(UserRoles);
