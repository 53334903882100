import React, {useEffect,  useState} from "react";
import {CustomTablePagination, EnhancedSearchTableToolbar} from "../../Utils/Tables/CustomTable";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import *  as snackbarActions from "../../../actions/snackbarActions";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {MedicalReportFilter} from "../ReportFilter";
import {Container} from "../../Utils/styledComponents";
import axios from "axios";
import {config} from "../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {dateConvert, formatDate} from "../../Utils/ConvertDate";
import {LoadingGif} from "../../Utils/Loader";
import {CustomTableCell, MuiPaper, StickyHeadTable} from "../../Utils/Tables/StickyHeaderTable";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClipboardList} from "@fortawesome/free-solid-svg-icons/faClipboardList";
import {BackButton} from "../../Utils/Buttons/DataExportationButton";

const headData = [
    {id: 'count', numeric: false, disablePadding: false, label: 'Sl'},
    {id: 'date', numeric: false, disablePadding: false, label: 'Visit date'},
    {id: 'patient_number', numeric: false, disablePadding: false, label: 'Patient number'},
    {id: 'patient_name', numeric: false, disablePadding: false, label: 'Patient name'},
    {id: 'diagnosis', numeric: false, disablePadding: false, label: 'Diagnosis'}
];


const ChronicDiseasesReport = ({actions, snackbars}) => {
    const [date, setDate] = useState({start_date: dateConvert(), end_date: dateConvert()});
    const [searchValue, setSearchValue] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isSubmitted, setIsSubmitted] = useState('idle');;
    const [data, setData] = useState({diseaseList:[], loading:'idle', total:0});
    const [submitted, setSubmitted] = useState(false);
    const [payment, setPayment] = useState({value:'', label:'All'})
    const [patientType, setPatientType] = useState({value:'', label:'All'})
    const [activePage, setActivePage] = useState(1)
    const [providers, setProviders] = useState([])
    const [provider, setProvider] = useState({value:'', label:''})


    const {start_date, end_date} = date;

    const {loading, total, diseaseList} = data

    const isResolved =  isSubmitted === 'resolved'


    useEffect(() => {
        if (payment.label === 'All' || payment.label === 'Cash'){
            return
        }
        axios.get(`${config.smsUrl}/cbilling/insurance_providers/list`).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const arr = !dt.data ? [] : dt.data;
            const list = arr.filter(item=>item.provider_type === payment.label).map(item=>({
                value:item.provider_id,
                label:item.provider_name
            }))
            setProviders(list);
        }).catch(err => {
            logoutErrorMessage(err,null,actions)
        })

    }, [payment.label]);

    const isCash = payment.label === 'All' || payment.label === 'Cash'


    const chronicObj = {
        'cardiac_disease':'Cardiac Disease',
        'kidney_disease':'Kidney Disease',
        'hypertension':'Hypertension',
        'tb':'Tb',
        'sickle_cell_disease':'Sickle Cell Disease',
        'epilepsy':'Epilepsy (seizures)',
        'diabetes':'Diabetes'
    }

    const filterDisease = (obj={}) =>{
        let item=[];
        for(let disease in obj){
            if (obj[disease] === true){
                item = [...item,chronicObj[disease]]
            }
        }
        return item.join(', ')
    }

    useEffect(() => {
        setPage(0)
        setActivePage(1)
    }, [payment, patientType, isResolved]);

    useEffect(() => {
        setData({...data, loading: 'pending'})
        const formData = new FormData()
        formData.append('start_date', start_date)
        formData.append('end_date', end_date)
        formData.append('patient_type', patientType?.value ? patientType?.value : '')
        formData.append('pay_type',payment?.value ? payment?.value : '')
        formData.append('search', searchValue)
        formData.append('provider_id', provider?.value)
        axios.post(`${config.smsUrl}/creport/Chronic_disease_report/${activePage}`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const arr = dt.chronic_disease ? dt.chronic_disease : []

            const diseases = arr.map(item=>({
                ...item,
                date:item.begin_datetime ? formatDate(item.begin_datetime):item.begin_datetime,
                diagnosis: filterDisease(item.chronic_illness),
                patient_name:`${item.first_name} ${item.last_name}`
            }))

            const total_count = dt.total_count ? dt.total_count : 0
            setData({...data, loading: 'success', diseaseList: diseases, total: total_count})
        }).catch((err) => {
            setData({...data, loading: 'error'})
            logoutErrorMessage(err, null, actions);
        })
    }, [isCash,provider, patientType, activePage, searchValue]);

    const handleSubmit = (event) => {
        event.preventDefault();
        setSubmitted(true)
        if (start_date && end_date) {
            setIsSubmitted('pending')
            setData({...data, loading: 'pending'})
            const formData = new FormData()
            formData.append('start_date', start_date)
            formData.append('end_date', end_date)
            formData.append('patient_type', patientType?.value ? patientType?.value : '')
            formData.append('pay_type',payment?.value ? payment?.value : '')
            formData.append('search', searchValue)
            formData.append('provider_id', provider?.value)
            axios.post(`${config.smsUrl}/creport/Chronic_disease_report/${activePage}`, formData).then(res => {
                const data = res.data;
                const dt = !data ? {} : data;
                const arr = dt.chronic_disease ? dt.chronic_disease : []
                const total_count = dt.total_count ? dt.total_count : 0

                const diseases = arr.map(item=>({
                    ...item,
                    date:item.begin_datetime ? formatDate(item.begin_datetime):item.begin_datetime,
                    diagnosis: filterDisease(item.chronic_illness),
                    patient_name:`${item.first_name} ${item.last_name}`
                }))
                setData({...data, loading: 'success', diseaseList: diseases, total: total_count})
                setIsSubmitted('resolved')
            }).catch(err => {
                setIsSubmitted('rejected')
                errorMessages(err, null, actions);
                setData({...data, loading: 'error'})
            })
        }
    }

    const handleChangeDate = (event) => {
        const {value, name} = event.target;
        setDate({...date, [name]: value})
    }


    const handleSearch = (value) => {
        setSearchValue(value)
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setActivePage(newPage+1)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        setActivePage(1);
    };

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };



    const {openBar, type, message} =snackbars;

    const isLoading = loading === 'pending'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'

    const isPending = isSubmitted === 'pending'

    const pagination = (
        <CustomTablePagination
            colSpan={3}
            activePage={activePage}
            page={page}
            rowsPerPage={rowsPerPage}
            count={total}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            isTotalCount
        />
    );

    const search_bar = (
        <EnhancedSearchTableToolbar
            term={searchValue} handler={handleSearch}
        />
    )
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, diseaseList.length - page * rowsPerPage);

    return (
        <div data-testid={`chronic-disease-report`}>
            <PageTitle title='Chronic disease'/>
            <SubHeader title="Reports" subTitle='Chronic diseases'>
                <FontAwesomeIcon icon={faClipboardList}/>
            </SubHeader>
            <Container>
                <div className='mb-3'>
                    <BackButton text={'General Reports'} to={'/reports/generalreports'}
                                data-testid="chronic-diseases-back-button"/>
                </div>
                <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>
                <MedicalReportFilter {...{
                    payment,
                    setPayment,
                    patientType,
                    setPatientType,
                    handleSubmit,
                    start_date,
                    end_date,
                    handleChange: handleChangeDate,
                    isPending,
                    submitted,
                    actions,
                    file_name: 'Chronic diseases',
                    excelUrl: 'creport/Chronic_disease_report_pdf',
                    excelParams: {export_type: 2},
                    pdfUrl: 'creport/Chronic_disease_report_pdf',
                    pdfParams: {export_type: 1},
                    provider,
                    providers,
                    setProvider
                }}/>

                <MuiPaper>
                    {search_bar}
                    <StickyHeadTable {...{
                        width: '100%',
                        data: diseaseList,
                        columns: headData.filter(item => Boolean(item)),
                        pagination
                    }}>
                        {isLoading ? <TableRow><TableCell
                            align='center' {...{colSpan: headData.length}}><LoadingGif/></TableCell></TableRow> : null}
                        {isSuccess ? diseaseList.length > 0 ?
                            <>
                                {diseaseList.slice(0, 10).map((row, index) => {
                                    const count = ((activePage - 1) * 10) + index + 1;
                                    return (
                                        <TableRow tabIndex={-1} key={index}>
                                            {headData.map(col => (
                                                col.id === 'count' ? <CustomTableCell>{count}</CustomTableCell> :
                                                    <CustomTableCell>{row[col.id]}</CustomTableCell>
                                            ))}
                                        </TableRow>
                                    );
                                })}
                                {emptyRows > 0 && (
                                    <TableRow style={{height: 20 * emptyRows}}>
                                        <TableCell colSpan={headData.length}/>
                                    </TableRow>
                                )}
                            </> : <TableRow><TableCell align='center'  {...{colSpan: headData.length}}>No records
                                found</TableCell></TableRow> : null}
                        {isError ?
                            <TableRow><TableCell align='center'  {...{colSpan: headData.length}}>The server did not
                                return a valid response</TableCell></TableRow> : null}
                    </StickyHeadTable>
                </MuiPaper>
            </Container>

        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(ChronicDiseasesReport);
