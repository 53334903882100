import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as snackbarActions from '../../actions/snackbarActions';
import {usePreviousPrescription} from "../DoctorsModule/Prescription/hooks/usePreviousPrescription";
import {CenteredContainer} from "../Utils/styledComponents";
import {LoadingGif} from "../Utils/Loader";
import {CollapsibleList, DataCell, ItemText, ModuleTable} from "../Utils/Lists/CollapsibleList";
import TableRow from "@material-ui/core/TableRow";
import styled from "@emotion/styled/macro";
import {ExpiredStatus, FinishedStatus, OtherMedicine, PendingStatus, Processing} from "../DoctorsModule/doctorStyles";
import {useViewPaymentDetails} from "./CustomHooks/useViewPaymentDetails";

const P = styled.p({
    marginTop:'20px ',
    fontWeight:700
})

const Container = styled.div({
    padding: "20px"
})


const headData = [{item:'Drug Name', toolTip:'Enter drug name', isRequired:true},
    {item:'Quantity Prescribed',  toolTip:'Enter prescribed quantity'},
    {item:'Quantity Dispensed',  toolTip:'Enter dispensed quantity'},
    {item:'Quantity to bill',  toolTip:'Enter quantity too bill'},
    {item:'Quantity Administered',  toolTip:'Enter administered quantity'},
    {item:'Dosage',  toolTip:'Enter dosage'},
    {item:'Frequency',  toolTip:'Enter frequency'},
    {item:'No. of Days',  toolTip:'Enter number of days'},
    {item:'Instructions',  toolTip:'Enter instructions'},{item:'Status',  toolTip:'status'},
    {item:'Action',  toolTip:'record treatment of prescription'}];
const sundriesHeadData = [{item:'Sundry Name',}, {item: 'status'}]

const PreviousInpatientPrescription = ({actions, visit_id, handleOpenRecordDialog, isRecordedTreatment, handleRecordTreatment}) => {
    const match = {patient_number:'', visit_id, labrequest_id:'', scan_request_id:'', patient_type:'',patient_admission_id:''}
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const {allPrescriptions,  isError, isSuccess, isLoading, handleOpen} = usePreviousPrescription(actions,match,_token, isRecordedTreatment, handleRecordTreatment)
    const {visitDetails} = useViewPaymentDetails(visit_id)

    const headData = [{item:'Drug Name', toolTip:'Enter drug name', isRequired:true},
        {item:'Quantity Prescribed',  toolTip:'Enter prescribed quantity'},
        {item:'Quantity Dispensed',  toolTip:'Enter dispensed quantity'},
        visitDetails?.is_on_insurance === 1 ?  {item:'Quantity to bill',  toolTip:'Enter quantity too bill'} : null,
        {item:'Quantity Administered',  toolTip:'Enter administered quantity'},
        {item:'Dosage',  toolTip:'Enter dosage'},
        {item:'Frequency',  toolTip:'Enter frequency'},
        {item:'No. of Days',  toolTip:'Enter number of days'},
        {item:'Instructions',  toolTip:'Enter instructions'},{item:'Status',  toolTip:'status'},
        {item:'Action',  toolTip:'record treatment of prescription'}];
    return (
        <Container>
            {isLoading ? <CenteredContainer>
                <LoadingGif/>
            </CenteredContainer> : null}
            {isSuccess ? allPrescriptions.length > 0 ? allPrescriptions.map((item)=>{
                const sundry = item.drugs.filter(item => item.category_name === 'Sundries')
                const presc = item.drugs.filter(item => (item.category_name === 'Medicine')||(!item.category_name))

                return(
                    <CollapsibleList name={item.date} key={item.id} open={item.open} item={item} handleOpen={()=>handleOpen(item.id)}
                                    user={ <ItemText  primary={`Prescribed By: ${item.doctor_name}`} />}>
                        {presc.length > 0 ?
                            <ModuleTable headData={headData.filter(item=>Boolean(item))}>
                                {presc.map((row, index) => {
                                    const isFinished =   row.status === 4
                                    const isOther = row.status === null || row.status === undefined;
                                    const inPatientStatus = {
                                        0:<PendingStatus>Pending</PendingStatus>,
                                        1:<Processing>Pending</Processing>,
                                        3:<PendingStatus>Pending</PendingStatus>,
                                        2:<FinishedStatus>Dispensed(out-patient)</FinishedStatus>,
                                        4:<FinishedStatus>Dispensed</FinishedStatus>,
                                        7:<ExpiredStatus>Stopped</ExpiredStatus>
                                    }
                                    return(
                                        <TableRow
                                            key={index}
                                        >
                                            <DataCell>
                                                <span>{row.product_name}{isOther ? <OtherMedicine>Other</OtherMedicine> : null}</span>
                                            </DataCell>
                                            <DataCell>{row.quantity_prescribed}</DataCell>
                                            <DataCell>{row.quantity_given ? row.quantity_given : 0}</DataCell>
                                            {visitDetails?.is_on_insurance === 1 ?  <DataCell>{row.quantity_to_bill_insurance ? row.quantity_to_bill_insurance : 0}</DataCell>: null}
                                            <DataCell>{row.quantity_administered ? row.quantity_administered : 0}</DataCell>
                                            <DataCell>{row.dosage}</DataCell>
                                            <DataCell>{row.frequency}</DataCell>
                                            <DataCell>{row.number_of_days}</DataCell>
                                            <DataCell>{row.key_information}</DataCell>
                                            <DataCell>{inPatientStatus[row.status]}</DataCell>
                                            <DataCell>
                                                {isFinished && (row.quantity_administered !== row.quantity_given) ?
                                                <button type="button" className="btn btn-sm sms-info-btn" onClick={()=>handleOpenRecordDialog(row)}>
                                                    Record Treatment
                                                </button> : null}
                                            </DataCell>
                                        </TableRow>

                                    )})}

                            </ModuleTable> : null }
                        {sundry.length > 0 ?
                            <ModuleTable headData={headData.filter(item=>Boolean(item))}>
                                {sundry.map((row, index) => {
                                    const isFinished =   row.status === 4
                                    const isOther = row.status === null || row.status === undefined;
                                    const inPatientStatus = {
                                        0:<PendingStatus>Pending</PendingStatus>,
                                        1:<Processing>Pending</Processing>,
                                        3:<PendingStatus>Pending</PendingStatus>,
                                        2:<FinishedStatus>Dispensed(out-patient)</FinishedStatus>,
                                        4:<FinishedStatus>Dispensed</FinishedStatus>,
                                        7:<ExpiredStatus>Stopped</ExpiredStatus>
                                    }
                                    return(
                                        <TableRow
                                            key={index}
                                        >
                                            <DataCell>
                                                <span>{row.product_name}{isOther ? <OtherMedicine>Other</OtherMedicine> : null}</span>
                                            </DataCell>
                                            <DataCell>{row.quantity_prescribed}</DataCell>
                                            <DataCell>{row.quantity_given ? row.quantity_given : 0}</DataCell>
                                            {visitDetails?.is_on_insurance === 1 ?  <DataCell>{row.quantity_to_bill_insurance ? row.quantity_to_bill_insurance : 0}</DataCell>: null}
                                            <DataCell>{row.quantity_administered ? row.quantity_administered : 0}</DataCell>
                                            <DataCell>{row.dosage}</DataCell>
                                            <DataCell>{row.frequency}</DataCell>
                                            <DataCell>{row.number_of_days}</DataCell>
                                            <DataCell>{row.key_information}</DataCell>
                                            <DataCell>{inPatientStatus[row.status]}</DataCell>
                                            <DataCell>
                                                {isFinished && (row.quantity_administered !== row.quantity_given) ?
                                                    <button type="button" className="btn btn-sm sms-info-btn" onClick={()=>handleOpenRecordDialog(row)}>
                                                        Record Treatment
                                                    </button> : null}
                                            </DataCell>
                                            {/*<DataCell>*/}
                                            {/*    <span>{row.product_name}{isOther ? <OtherMedicine>Other</OtherMedicine> : null}</span>*/}
                                            {/*</DataCell>*/}
                                            {/*<DataCell>{inPatientStatus[row.status]}</DataCell>*/}
                                        </TableRow>
                                    )})}
                            </ModuleTable>: null }
                    </CollapsibleList>
                )
            }) : <CenteredContainer>
                <P>No drugs prescribed for patient, click the add prescription button to prescribe drugs for patient</P>
            </CenteredContainer>:null}
            {isError ? <CenteredContainer>
                <P>An error occurred on the server</P>
            </CenteredContainer>:null}
        </Container>
    )
      
}
function mapStateToProps(state) {
    const {snackbar} = state;
    return {snackbar}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviousInpatientPrescription)