import {useEffect, useState} from "react";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {history} from "../../../Helpers/history";
import {fetchEmployees} from "./useAddUser";

export const useAssignRole = (actions) => {
    const [role_name, setRoleName] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [roles, setRoles] = useState([]);
    const [state, setState] = useState({first_name: "", last_name: "", email: "", password: "", status: "1"});
    const [employee_id, setEmployee] = useState("");
    const [employees, setEmployees] = useState([]);
    const [employee_email, setEmail] = useState('');
    const [openDialog,setOpenDialog] = useState(false);
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = _token.user_roles_id ? _token.user_roles_id :'';


    useEffect(() => {
        fetchEmployees().then(res => {
            const data = res.data;
            const dt = !data ? [] : data;
            setEmployees(dt);
        }).catch((err) => {
            logoutErrorMessage(err, null, actions)
        })
    }, []);

    const handleChange = (event) => {
        const {name, value} = event.target;
        setState({...state, [name]: value})
    };

    const handleChangeEmployee = value => {
        setEmployee(value);
        const formData = new FormData();
        formData.append('employee_id', value.value);
        axios.post(`${config.smsUrl}/chumanresource/employee_update_form`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const {email} = dt;
            const em = email ? email : ''
            setEmail(em)
        }).catch(error => {
            errorMessages(error, null, actions)
        })
    };


    useEffect(() => {
        axios.get(`${config.smsUrl}/permission/role_list`).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const list = !dt.user_list ? [] : dt.user_list
            setRoles(list)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, []);


    const handleChangeRoleName = (value) => {
        setRoleName(value)
    };
    const handleOpenDialog = () =>{
        setOpenDialog(true)
    }

    const handleCloseDialog = () =>{
        setOpenDialog(false)
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData();
        const {password} = state;
        formData.append('user_roles_id', user_roles_id);
        formData.append('user_type', role_name.value);
        formData.append('employee_id', employee_id.value);
        formData.append('user_name', employee_email);
        formData.append('password', password);
        setSubmitted(true);
        if (employee_id && employee_email && role_name && password && password.length >= 6) {
            setIsSubmitted('pending');
            axios.post(`${config.smsUrl}/permission/usercreate`, formData).then((res) => {
                if (res.data === "Role Already Exists") {
                    actions.snackbarActions.snackError("Role already assigned to this user")
                } else {
                    actions.snackbarActions.snackSuccess('Role assigned Successfully');
                    history.push('/userroles')
                }
                setIsSubmitted('resolved')
                setState({first_name: "", last_name: "", email: "", password: "", status: "1"})
                setRoleName("")
                setEmail("")
                setEmployee("")
                setSubmitted(false)
                setOpenDialog(false)
            }).catch(err => {
                setIsSubmitted('pending');
                errorMessages(err, null, actions)
                setIsSubmitted('rejected');
            })
        }
    };

    const handleReset = () => {
        setRoleName("");
    };

    const handleCloseSnackbar = () => {
        actions.snackbarActions.hideSnackbar();
    };

    return { role_name, submitted, isSubmitted, roles, state,handleChange, handleChangeEmployee,
        handleChangeRoleName, handleSubmit, handleReset, handleCloseSnackbar, setRoleName, employees,
        employee_id,employee_email, setEmployee,setEmail,openDialog,handleOpenDialog,handleCloseDialog}
}