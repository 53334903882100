import React, {useEffect,  useState} from "react";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as snackbarActions from "../../actions/snackbarActions";
import {SubHeader} from "../../Containers/SubHeader";
import PageTitle from "../Utils/smsTitle";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import CustomTable, {useHiddenColumns} from "../Utils/Tables/CustomTable";
import {faPrescriptionBottle} from "@fortawesome/free-solid-svg-icons/faPrescriptionBottle";
import * as prescriptionActions from "../../actions/PrescriptionActions/PrescriptionActions"
import {withTheme} from "@material-ui/core/styles";
import {dateConvert, formatDateTime} from "../Utils/ConvertDate";
import {Container} from "../Utils/styledComponents";
import {ReusableRouteTabs} from "../Utils/Dialogs/ReusableTabs";
import { history } from "../../Helpers/history";
import FillPrescription from "./FillPrescription";
import { DateTimeFilter } from "../Utils/ReusableComponents/DateTimeFilter";
import { ReturnPrescription } from "./ReturnPrescription";
import {titleCase} from "../Users/addUser";
import axios from "axios";
import {config} from "../../Helpers/env";
import {logoutErrorMessage} from "../../Helpers/ErrorMessages";
import {useDebounce} from "../Lab/useDebounce";
import { useVersionsContext } from "../../Context/versions-context";

const headData = [
    {id: 'count', numeric: false, disablePadding: false, label: 'SL', hidden:false},
    {id: 'date', numeric: false, disablePadding: false, label: 'Date', hidden:false},
    {id: 'patient_number', numeric: false, disablePadding: false, label: 'Patient No.',hidden:false},
    {id: 'patient_name', numeric: false, disablePadding: false, label: 'Patient Name',hidden:false},
    {id: 'phone', numeric: false, disablePadding: false, label: 'Phone',hidden:false},
    {id: 'kin_phone_no', numeric: false, disablePadding: false, label: 'NOK Phone',hidden:false},
    {id: 'doctor_name', numeric: false, disablePadding: false, label: 'Doctor Name',hidden:false},
    {id: 'action', numeric: false, disablePadding: false, label: 'Action',hidden:false}
];



export const prescriptionComponents = [{label: 'Out-Patient Prescriptions', path: '/medicalAnalysis/out-patient',  tier: ["Basic", "Standard", "Premium"]},
    {label: 'In-Patient Prescriptions', path: '/medicalAnalysis/in-patient', tier: ["Standard", "Premium"]},
    {label: 'Previous Prescriptions', path: '/medicalAnalysis/previous',  tier: ["Basic", "Standard", "Premium"]},
    // {label: 'Dispense Consumables', path: '/dispenseConsumables'}
]
/**
 *
 * @param actions
 * @param snackbars
 * @param match
 * @returns {Element}
 * @constructor
 */

const PrescriptionList = ({actions, snackbars, match}) => {
    const {openBar, type, message} = snackbars;
    const {component} = match.params;
    const [term, setTerm] = useState('');
    const [activePage, setActivePage] = useState(1);
    const [prescriptions, setPrescriptions] = useState([]);
    const [status, setStatus] = useState('idle')
    const [total_count, setTotalCount] = useState(0)
    const [state, setState] = useState({start_date:dateConvert(), end_date:dateConvert()})
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle')
    const [prescriptionIds, setPrescriptionIds] = useState({})
    const [openDialog, setOpenDialog] = useState(false)
    const [openReturnDialog, setOpenReturnDialog] = useState(false)
    const [debouncedInputValue] = useDebounce(term, 500)
    const {subscription} = useVersionsContext()
    const version = subscription?.version
    
    const isResolved = isSubmitted === 'resolved'



    const {start_date, end_date} = state;

    const url = {
        'out-patient':1,
        'in-patient':2,
    }
    const route = {
        'out-patient':`prescription_list/${activePage}`,
        'in-patient':`prescription_list/${activePage}`,
        'previous':'previous_filled_prescription_list'
    }

    /**
     * Reset state when the component changes
     */

    useEffect(()=>{
        setActivePage(1)
        setState({start_date: dateConvert(), end_date: dateConvert()})
        setPrescriptions([])
        setTotalCount(0)
    },[component])


    /**
     * Retrieve data for a give component when it mounts and when any of the dependencies change
     */
    useEffect(() =>{
        const formData = new FormData();

        if(component !== 'previous'){
            formData.append('patient_type', url[component]);
        }
        formData.append('start_date', start_date);
        formData.append('end_date', end_date);
        formData.append('start', activePage);
        formData.append('search', debouncedInputValue);
        setStatus('pending')
        axios.post(`${config.smsUrl}/cpharmacy/${route[component]}`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            let prescription
            let count;
            if(component === 'previous'){
                const dataArr = !dt.data ? [] : dt.data;
                prescription = dataArr.map(item=>({
                    ...item, patient_name:`${item.patient_first_name ? item.patient_first_name : ''} ${item.patient_last_name ? item.patient_last_name : ''}`,
                    doctor_name: `${item.doctor_first_name ? item.doctor_first_name : ''} ${item.doctor_last_name ? item.doctor_last_name : ''}`
                }))
                count = !dt.iTotalRecords ? 0 : dt.iTotalRecords;
            }else{
                const dataArr = !dt.presc_list ? [] : dt.presc_list;
                prescription = dataArr.map(item=>({
                    ...item, patient_name:`${item.patient_firstname ? item.patient_firstname : ''} ${item.patient_lastname ? item.patient_lastname : ''}`,
                    doctor_name: `${item.doctor_firstname ? item.doctor_firstname : ''} ${item.doctor_lastname ? item.doctor_lastname : ''}`
                }))
                count = !dt.total_count ? 0 : dt.total_count;
            }

            setPrescriptions(prescription)
            setTotalCount(count)
            setStatus('success')
        }).catch(err => {
            logoutErrorMessage(err,null,actions)
            setStatus('rejected')
        })
    },[debouncedInputValue, activePage, isResolved, component])


    /**
     * Onchange handler for searching the table
     * @param event
     */
    const handler = (event) => {
        setTerm(event.target.value)
    };

    /**
     * Changes the page number
     * @param value
     */
    const handleChangeActivePage = (value) => {
        setActivePage(value)
    };

    /**
     * Closes the snackbar
     */
    const closeSnackBar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    /**
     * Onchange for the start and end date
     * @param event
     */
    const handleChange = (event) =>{
        const {name, value} = event.target;
        setState({...state,[name]:value})
    }

    /**
     * Filters the table based on the date filter -  start and end date
     * @param event
     */
    const handleSubmit = (event) =>{
        event.preventDefault();
        setSubmitted(true);
        if(start_date && end_date){
            setStatus('pending');
            setIsSubmitted('pending')
            const formData = new FormData();
            if(component !== 'previous'){
                formData.append('patient_type', url[component]);
            }
            formData.append('start_date', start_date);
            formData.append('end_date', end_date);
            formData.append('start', activePage);
            formData.append('search', term);
            axios.post(`${config.smsUrl}/cpharmacy/${route[component]}`, formData).then(res => {
                const data = res.data;
                const dt = !data ? {} : data;
                let prescription
                let count;
                if(component === 'previous'){
                    const dataArr = !dt.data ? [] : dt.data;
                    prescription = dataArr.map(item=>({
                        ...item, patient_name:`${item.patient_first_name ? item.patient_first_name : ''} ${item.patient_last_name ? item.patient_last_name : ''}`,
                        doctor_name: `${item.doctor_first_name ? item.doctor_first_name : ''} ${item.doctor_last_name ? item.doctor_last_name : ''}`
                    }))
                    count = !dt.iTotalRecords ? 0 : dt.iTotalRecords;
                }else{
                    const dataArr = !dt.presc_list ? [] : dt.presc_list;
                    prescription = dataArr.map(item=>({
                        ...item, patient_name:`${item.patient_firstname ? item.patient_firstname : ''} ${item.patient_lastname ? item.patient_lastname : ''}`,
                        doctor_name: `${item.doctor_firstname ? item.doctor_firstname : ''} ${item.doctor_lastname ? item.doctor_lastname : ''}`
                    }))
                    count = !dt.total_count ? 0 : dt.total_count;
                }

                setPrescriptions(prescription)
                setTotalCount(count)
                setStatus('success')
                setIsSubmitted('complete')
            }).catch((err) => {
                logoutErrorMessage(err, null, actions);
                setStatus('error')
                setIsSubmitted('error')
            })
        }
    }


    const isLoading =  status === 'pending';
    const isSuccess = status === 'success';
    const isRejected = status === 'error';

    const isPendingForm =  isSubmitted === 'pending';



    const buttonText = {
        'out-patient':'Dispense',
        'in-patient':'Dispense',
        'previous':'View'
    }


    /**
     * Transform the data to be mapped in the table
     * @type {(*&{date: string, phone: *, kin_phone_no: *, count: number, action})[]}
     */
    const unfilledPrescription = prescriptions.map((unfilled, index)=>{
        const action = {
            4: (
                    <button type="button" onClick={() =>{
                        setOpenDialog(true)
                        setPrescriptionIds(unfilled)
                    }} className="btn btn-sm sms-info-btn mr-1 mb-2">View</button>

            ),
            8:( <button type="button" onClick={() =>{
                setOpenReturnDialog(true)
                setPrescriptionIds(unfilled)
            }} className="btn btn-sm sms-amber-btn mr-1 mb-2">Receive Remaining Stock</button>)
        }
        const count = ((activePage - 1) * 10) + index + 1;
        return {
            ...unfilled,
            count,
            date:formatDateTime(unfilled.date_created),
            phone:unfilled.patient_Phone_number,
            kin_phone_no:unfilled.patient_kin_Phone_number,
            action: (
                <>
                    {unfilled.status !== 4 ? <button type="button" onClick={() => {
                        setOpenDialog(true)
                        setPrescriptionIds(unfilled)
                    }} className={`btn btn-sm mb-2 ${buttonText[component] === 'View' ?
                        'sms-info-btn' : 'sms-btn'}  mr-1`}>{buttonText[component]}</button>:null}
                    {action[unfilled.status]}
                </>

            )

        }
    })


    const title = {
        'out-patient':'Out-Patient Prescriptions',
        'in-patient':'In-Patient Prescriptions',
        'previous':'Previous Prescriptions'
    }


    const {
        headCells, all_hidden, handleAllHeadCells, handleHeadCells
    } = useHiddenColumns(headData);
    return (
        <div id="prescription-list" className='journals'>
            <PageTitle title={title[component]}/>
            <SubHeader title="Pharmacy" subTitle={title[component]}>
                <FontAwesomeIcon icon={faPrescriptionBottle}/>
            </SubHeader>

            <FillPrescription {...{component,actions, openDialog, setOpenDialog, prescriptionIds,setPrescriptionIds,
                isSubmitted, setIsSubmitted}}/>

            <ReturnPrescription {...{actions, openDialog:openReturnDialog, setOpenDialog:setOpenReturnDialog, prescriptionIds,setPrescriptionIds,
                isSubmitted, setIsSubmitted}}/>

            <MainSnackbar handleCloseBar={closeSnackBar} open={openBar} message={message} variant={type}/>
            <Container>
                <ReusableRouteTabs value={history.location.pathname} tabHeading={prescriptionComponents} version={version}/>
                <DateTimeFilter {...{start_date,end_date, handleChange, submitted,handleSubmit,isPending:isPendingForm, type:'date'}}/>
                <CustomTable headData={headCells} title='' data={unfilledPrescription}
                             customPage handleChangeNextPage={handleChangeActivePage}
                             records={10} total_count={total_count} activePage={activePage} term={term}
                             id='search-prescription' handler={handler} colSpan={headCells.length}
                             {...{all_hidden, handleAllHeadCells, handleHeadCells}}
                >
                        <TableBody>
                            {isLoading ? <TableRow>
                                <TableCell colSpan={headCells.length} align="center"><img src="/images/smsloader.gif"
                                                                        alt="loader"/></TableCell>
                            </TableRow>:null}
                            {isSuccess ? unfilledPrescription.length > 0 ? unfilledPrescription.slice(0, 10)
                                .map((unfilled, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={index}
                                            className='mui-table-row'>
                                            {headCells.map((head)=>(
                                                <TableCell id={head.id}  key={head.id}
                                                           hidden={head.hidden}>{unfilled[head.id]}</TableCell>
                                            ))}
                                        </TableRow>
                                    );
                                }):<TableRow>
                                <TableCell colSpan={headCells.length} align="center">No records found</TableCell>
                            </TableRow>:null}
                            {isRejected ? <TableRow>
                                <TableCell colSpan={headCells.length} align="center">The server didnot return a valid response</TableCell>
                            </TableRow>:null}
                        </TableBody> 
                </CustomTable>
            </Container>
        </div>
    );

}

function mapStateToProps(state) {
    return {prescriptions: state.prescriptions, snackbars: state.snackbar};
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            prescriptionActions: bindActionCreators(prescriptionActions, dispatch),
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    };
}

export default withTheme(connect(mapStateToProps, mapDispatchToProps)(PrescriptionList));
