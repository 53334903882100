import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as snackbarActions from "../../actions/snackbarActions";
import {SubHeader} from "../../Containers/SubHeader";
import {faHome} from "@fortawesome/free-solid-svg-icons/faHome";
import PageTitle from "../Utils/smsTitle";
import Card from "@material-ui/core/Card";
import "react-multi-carousel/lib/styles.css";
import {CollectionVisitCards, Inventory, InPatientStatistics, MortalityRate} from "./DashboardCards";
import {useTheme} from "@material-ui/core/styles";
import { useMainPage } from "./hooks/useMainPage";
import PatientStatistics from "./PatientStatistics";
import DoctorsOnDuty from "./DoctorsOnDuty";
import { TopSellingProducts } from './TopSellingProducts';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Form from 'react-bootstrap/Form';
import SalesAnalytics from "./SalesAnalytics";
import {makeStyles} from "@material-ui/core/styles";
import TimeToService from './TimeToService';
import React, {useState, version} from 'react';
import SettingsIcon from '@material-ui/icons/Settings';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MoreIcon from '@material-ui/icons/MoreVert';
import Tooltip from '@material-ui/core/Tooltip';
import List from "@material-ui/core/List";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ListItem from "@material-ui/core/ListItem";
import FilterListIcon from '@material-ui/icons/FilterList';
import {Link} from "react-router-dom";
import Collapse from '@material-ui/core/Collapse';
import { useVersionsContext } from "../../Context/versions-context";
import Alert from "@material-ui/lab/Alert";


// styles for the elements in the Dashboard
const useDashboardStyles = makeStyles((theme) => ({
    title:{
        '&$selected, &$selected:hover':{
            backgroundColor:'#d7d3d3'
        }
    }, 
    card_style: {
        borderRadius: '25px',
        border: 0,
        padding: '0 30px',
        boxShadow: 'none'
    },
    card_style_doctors: {
      borderRadius: '25px',
      border: 0,
      padding: '0 30px',
      boxShadow: 'none',
      maxHeight:'675px',
      overflowY: 'clip'
    },
    sectionDesktop: {
      display: 'none',
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
          display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
          display: 'none',
      },
    },
    iconButtonColor: {
      color: '#3B4CB8 !important',
    },
    iconWhiteColor: {
      color: 'white !important',
      '&:hover':{
        color:'#3B4CB8'
          }
    },
    buttonStyle : {
      background: '#D2D7F7',
      borderRadius: '18px',
      color: '#3B509E',
      fontWeight: 600,
      boxShadow:'none',
      height:'40px'
    },
    outsideIconButton: {
      margin:'10px',
      background: '#3B509E',
      padding: '5px',
      '&:hover':{
        background:'#D2D7F7'
      }
    },
}));

export const CustomizedTick = (props) => {
  const { x, y, payload } = props;
  const txt = Intl.NumberFormat('en', {notation: "compact"}).format(payload.value);
  return (
      <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} fill="#666">
        <tspan textAnchor="middle" x="-20" y={'-15'}>
          {txt}
        </tspan>
      </text>
    </g>
  );
}

export const CustomTooltip = ({ active, payload, label }) => {

  const fillerStyle = {
    backgroundColor: '#fcfcfd',
    border: 'none',
    fontSize:'6px',
    padding:'10px',
    borderRadius: '20px',
    textAlign: 'left',
    boxShadow: '0 5px 5px 0 rgba(0, 0, 0, 0.1)'
  }

  const labelStyle = {
    fontFamily: 'Visby CF',
    fontStyle: 'normal',
    fontWeight: '700',
    color: '#172647',
    fontSize: '11px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    margin: '2px'
  }

  const valueStyle = {
    fontFamily: 'Visby CF',
    fontStyle: 'normal',
    fontWeight: '700',
    color: '#355AA8',
    fontSize: '10px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    margin: '2px'
  }

  const orangeShortBorder = {
    width: '34px',
    borderBottom: '2px solid #F76B1D',
    marginLeft:'2px'
  }

  if (active && payload && payload.length) {
    return (
      <div style={fillerStyle}>
        <p style={labelStyle}>{`${label}`}</p>
        <div style={orangeShortBorder}></div>
        <p style={valueStyle}>{`${payload[0].name} : ${new Intl.NumberFormat("en", {style:'decimal'}).format(payload[0].value)}`}</p>
        <p style={valueStyle}>{`${payload[1].name} : ${new Intl.NumberFormat("en", {style:'decimal'}).format(payload[1].value)}`}</p>
        {payload[2] ? 
          <p style={valueStyle}>{`${payload[2].name} : ${new Intl.NumberFormat("en", {style:'decimal'}).format(payload[2].value)}`}</p>
        : null}
      </div>
    );
  }
  return null;
};

/**
 * Component that renders the Main Dashboard
 * @param {*} props 
 * @returns 
 */
const MainPage = (props) => {
    const classes = useDashboardStyles();

    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;


    const {  gender_data, age_groups, topSellsProps, time_to_service, earnings, filter_type, isInventoryPermission,
      inventory, in_patient_statistics, mortality_rate, sales_analytics,
      patient_visits, handleOverallFilterChange, isEarningsPermission, isRoles, isAssignRole
    } = useMainPage({props});

    const {subscription} = useVersionsContext()
    version = subscription?.version

    const cardList = CollectionVisitCards(earnings, patient_visits, version);
    const {actions} = props;

    const today_date = new Date();
    const time_now  = new Intl.DateTimeFormat('default', { hour12: true,
        hour: 'numeric',
        minute: 'numeric'
    }).format(today_date);

    // hooks, functions for the pop-up menus
    const [anchorEl, setAnchorEl] = useState(null);
    const [settingsEl, setSettingsEl] = useState(null);

    const isMenuOpen = Boolean(anchorEl);
    const isSettingsMenu = Boolean(settingsEl);
  
    const handleProfileMenuOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleSettingsMenu = (event) => {
      setSettingsEl(event.currentTarget);
    };
  
    const handleMenuClose = () => {
      setAnchorEl(null);
    };

    const handleSettingsClose = () => {
      setSettingsEl(null);
    }

    // component for the dashboard dropdown
   const dashboardDropDowns = (
      <Form.Control as='select' className="no-border-dropdown dashboard-dropdown" onChange={(e)=>handleOverallFilterChange(e.target.value)}>
        <option value={'Today'}>Today</option>
        <option value={'This Week'}>This Week</option>
        <option value={'This Month'}>This Month</option>
      </Form.Control>
    ) 


    const [isSmallSettingsOpen, setSmallSettingsOpen] = useState(false);
    const [isSmallFilterOpen, setSmallFilterOpen] = useState(false);

    const handleSmallFilterOpen = () => {
      setSmallFilterOpen(!isSmallFilterOpen);
      if (isSmallSettingsOpen) { setSmallSettingsOpen(false); }
    }

    const handleSmallSettingsOpen = () =>{
      setSmallSettingsOpen(!isSmallSettingsOpen);
      if (isSmallFilterOpen) { setSmallFilterOpen(false); }
    }

    const handleSmallFilterChange = (toChange) => {
      handleOverallFilterChange(toChange);
      handleSmallFilterOpen();
      setAnchorEl(null);
    }
    // pop up menu when a user clicks on the small icons for buttons
    const menuId = 'refresh-settings-menu';
    const renderMenu = (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        id={menuId}
        keepMounted
        transformOrigin={{vertical: 'top', horizontal: 'right'}}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleSmallFilterOpen}>
          <IconButton aria-label="docs" color="inherit" style={{padding:'2px'}}>
            <FilterListIcon colorInterpolationFilters="inherit" classes={{root: classes.iconButtonColor}}/>
          </IconButton>
          <p style={{margin:'2px'}}>{filter_type}</p>
          {isSmallFilterOpen ? <ExpandMore classes={{root: classes.iconButtonColor}}/> :
            <ChevronLeftIcon classes={{root: classes.iconButtonColor}}/>}
        </MenuItem>

        <Collapse in={isSmallFilterOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
              <ListItem button onClick={(e)=>handleSmallFilterChange('Today')}>
                <p style={{margin:'2px'}}>Today</p>
              </ListItem>
              <ListItem button onClick={(e)=>handleSmallFilterChange('This Week')}>
                <p style={{margin:'2px'}}>This Week</p>
              </ListItem>          
            <ListItem button onClick={(e)=>handleSmallFilterChange('This Month')}>
              <p style={{margin:'2px'}}>This Month</p>
            </ListItem>        
          </List>
        </Collapse>

        <MenuItem onClick={handleSmallSettingsOpen}>
          <IconButton aria-label="docs" color="inherit" style={{padding:'2px'}}>
            <SettingsIcon colorInterpolationFilters="inherit" classes={{root: classes.iconButtonColor}}/>
          </IconButton>
          <p style={{margin:'2px'}}>Settings</p>
          {isSmallSettingsOpen ? <ExpandMore classes={{root: classes.iconButtonColor}}/> :
            <ChevronLeftIcon classes={{root: classes.iconButtonColor}}/>}
        </MenuItem>

        <Collapse in={isSmallSettingsOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {isRoles ? 
            <Link to="/roles">
              <ListItem>
                <p style={{margin:'2px'}}>Roles</p>
              </ListItem>
            </Link>        
          : null}
          {isAssignRole ? 
            <Link to="/userroles">
              <ListItem>
                <p style={{margin:'2px'}}>User Roles</p>
              </ListItem>          
            </Link>
          : null}
          <Link to="/support">
            <ListItem>
              <p style={{margin:'2px'}}>Support</p>
            </ListItem>        
          </Link>
          </List>
        </Collapse>
      </Menu>
    );

    // pop-up menu for the settings
    const settingsID = 'settings-pop-up-menu';
    const renderSettingsMenu = (
      <Menu
        anchorEl={settingsEl}
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        id={settingsID}
        keepMounted
        transformOrigin={{vertical: 'top', horizontal: 'right'}}
        open={isSettingsMenu}
        onClose={handleSettingsClose}
      >
        {isRoles ? 
          <Link to="/roles">
            <MenuItem>
              <p style={{margin:'2px'}}>Roles</p>
            </MenuItem>
          </Link>        
         : null}
         {isAssignRole ? 
          <Link to="/userroles">
            <MenuItem>
              <p style={{margin:'2px'}}>User Roles</p>
            </MenuItem>          
          </Link>
         : null}
        <Link to="/support">
          <MenuItem>
            <p style={{margin:'2px'}}>Support</p>
          </MenuItem>        
        </Link>
      </Menu>
    );
    
    const settingsButton = (
      <div>
        <div className={classes.sectionDesktop}>
          {dashboardDropDowns}
          <Tooltip title='Settings'>
            <IconButton classes={{root: classes.outsideIconButton}} 
              aria-label="show more"
              aria-controls={settingsID}
              aria-haspopup="true"
              onClick={handleSettingsMenu}>
              <SettingsIcon colorInterpolationFilters="inherit" classes={{root: classes.iconWhiteColor}}/>
            </IconButton>
          </Tooltip>
        </div>
        <div className={classes.sectionMobile}>
          <IconButton
            aria-label="show more"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            color="inherit"
          >
            <MoreIcon classes={{root: classes.iconButtonColor}}/>
          </IconButton>
        </div>
      </div>
    )

    // the title card style and those with buttons
    const title_report_card = (card_title, report_link, report_name, margin_right='30px') => {
      return (
        <div style={{display:'flex', justifyContent: 'space-between'}}>
          <div>
            <p className="dashboard-card-font-color">{card_title}</p>
            <div className="orange-short-border"></div>
          </div>
          {report_name ? 
          <div style={{marginRight:`${margin_right}`}}>
            <Link to={{pathname:`${report_link}`}}>
            <button className="sms-dashboard-btn">{report_name}</button>
            </Link>
          </div>          
          :
          null}
        </div>
      )
    }

    //dashboard with earnings components only
    const earnings_dashboard_ui = (
      <div className="dashboard-layout">
        {/**First Row */}
        <div className="row" >
            {/* The cards for the total, cash and credit earnings, and patient visits */}
            { cardList.map((item, index) => (
              item.tier?.includes(version) ?
                <div key={index} className={`col-lg-3 col-md-6 col-sm-12 dashboard-col`}>
                    <Card className="p-3 mb-4 metrics__card" classes={{root: classes.card_style}}>
                        <div style={{display:'flex', justifyContent: 'space-between'}}>
                            <div>
                                <p className="dashboard-card-font-color">{`${item.title}`}</p>
                                <div className="orange-short-border"></div>                                        
                            </div>
                            <div>
                                <p  className="dropdown-filter">{filter_type}</p>                       
                            </div>
                        </div>
                        <div>{item.bodyValues()}</div>
                    </Card>
                </div>
                :null
            ))}
        </div>

        {/** Second And Third Row */}
        <div className="row">

            <div className="col-lg-6 col-md-12 col-sm-12">
              <div>
                <Card className="p-3 mb-4 metrics__card" classes={{root: classes.card_style}}>
                  <SalesAnalytics sales_analytics={sales_analytics}
                        title_report_card={title_report_card('Sales Analytics', '/reports/revenuebyprocedure', 'Sales Reports', '10px')}/>
                </Card>
              </div>

              <Card className="p-3 mb-4 metrics__card" classes={{root: classes.card_style}}>
                <PatientStatistics title_report_card={title_report_card('Patient Statistics', '/reports/moh_reports',
                version == "Premium" ? "MOH Reports": '',
                '10px')}
                gender_data={gender_data} age_groups={age_groups}/>
              </Card>

            </div>

            <div className="col-lg-6 col-md-3 col-sm-12">

            {version == "Premium" || version == "Standard" && <Card className="p-3 mb-4 metrics__card" classes={{root: classes.card_style}}>
                <InPatientStatistics in_patient_statistics={in_patient_statistics}/>
              </Card>}

              {version == "Premium" || version == "Standard" && 
              <Card className="p-3 mb-4 metrics__card" classes={{root: classes.card_style}}>
                <MortalityRate filter_type={filter_type} mortality_rate={mortality_rate}/>
              </Card>  }                    
            </div>

           {["Premium", "Standard"].includes(version) &&
            <div className="col-lg-6 col-md-3 col-sm-12" >
            <Card className="p-3 mb-4 metrics__card" classes={{root: classes.card_style_doctors}}>
              <DoctorsOnDuty actions={actions}/>
            </Card>
          </div>}

        </div>

        {/** Fourth Row */}
        <div className="row">
            <div className="col-md-6">
              
              <Card className="p-3 mb-3 metrics__card" classes={{root: classes.card_style}}>
              <TimeToService time_to_service={time_to_service}/>
              </Card>
            </div>
                
        </div>
      </div>
    )

    //dashboard with inventory components only
    const inventory_dashboard_ui = (
      <div className="dashboard-layout">

        <div className="row">

            <div className="col-md-6">
              <div>
                <Card className="p-3 mb-4 metrics__card" classes={{root: classes.card_style}}>
                <PatientStatistics title_report_card={title_report_card('Patient Statistics', '/reports/moh_reports',
                version == "Premium" ? "MOH Reports": '',
                '10px')}
                gender_data={gender_data} age_groups={age_groups}/>
              </Card>
              </div>

              <Card className="p-3 mb-4 metrics__card" classes={{root: classes.card_style}}>
              <TopSellingProducts topSellsProps={topSellsProps} filter_type={filter_type}/>
              </Card>
            </div>

            <div className="col-md-3">
              <Card className="p-3 mb-4 metrics__card" classes={{root: classes.card_style}}>
              <Inventory inventory={inventory}
                title_report_card={title_report_card('Inventory', '/productlist','Stock List', '5px')}/>
              </Card>

              {version == "Premium" || version == "Standard" && <Card className="p-3 mb-4 metrics__card" classes={{root: classes.card_style}}>
                <InPatientStatistics in_patient_statistics={in_patient_statistics}/>
              </Card>}

              {version == "Premium" || version == "Standard" && 
              <Card className="p-3 mb-4 metrics__card" classes={{root: classes.card_style}}>
                <MortalityRate filter_type={filter_type} mortality_rate={mortality_rate}/>
              </Card>  }                    
            </div>

            <div className="col-md-3" >   
            {<Card className="p-3 mb-4 metrics__card" classes={{root: classes.card_style}}>
                <div style={{display:'flex', justifyContent: 'space-between'}}>
                      <div>
                          <p className="dashboard-card-font-color">{`${cardList[3].title}`}</p>
                          <div className="orange-short-border"></div>                                        
                      </div>
                      <div md={4} style={{padding:'0px'}}>
                        <p   className="dropdown-filter">{filter_type}</p>                       
                      </div>
                  </div>
                  <div>{cardList[3].bodyValues()}</div>
              </Card>}

              {["Premium", "Standard"].includes(version) &&<Card className="p-3 mb-4 metrics__card" classes={{root: classes.card_style_doctors}}>
                <DoctorsOnDuty actions={actions}/>
              </Card>}
            </div>

        </div>
      </div>
    )
    

    // all access dashboard
    const all_access_dashboard = (
      <div className="dashboard-layout">
        {/**First Row */}
        <div className="row" >
            {/* The cards for the total, cash and credit earnings, and patient visits */}
            {cardList.map((item, index) => (
              item.tier?.includes(version) ?
                <div key={index} className={`col-lg-3 col-md-6 col-sm-12dashboard-col`}>
                    <Card className="p-3 mb-4 metrics__card" classes={{root: classes.card_style}}>
                        <div style={{display:'flex', justifyContent: 'space-between'}}>
                            <div>
                                <p className="dashboard-card-font-color">{`${item.title}`}</p>
                                <div className="orange-short-border"></div>                                        
                            </div>
                            <div>
                                <p  className="dropdown-filter">{filter_type}</p>                       
                            </div>
                        </div>
                        <div>{item.bodyValues()}</div>
                    </Card>
                </div>
              :null
            ))}
        </div>

        {/** Second And Third Row */}
        <div className="row">

            <div className="col-lg-6 col-md-6 col-sm-12">
              <div>
                <Card className="p-3 mb-4 metrics__card" classes={{root: classes.card_style}}>
                  <SalesAnalytics sales_analytics={sales_analytics}
                        title_report_card={title_report_card('Sales Analytics', '/reports/revenuebyprocedure', 
                        'Sales Reports', 
                        '10px')}/>
                </Card>
              </div>

              <Card className="p-3 mb-4 metrics__card" classes={{root: classes.card_style}}>
                <PatientStatistics title_report_card={title_report_card('Patient Statistics', '/reports/moh_reports',
                version == "Premium" ? "MOH Reports": '',
                '10px')}
                gender_data={gender_data} age_groups={age_groups}/>
              </Card>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-12">
              <Card className="p-3 mb-4 metrics__card" classes={{root: classes.card_style}}>
                <Inventory inventory={inventory}
                title_report_card={title_report_card('Inventory', '/productlist','Stock List', '5px')}/>
              </Card>

              {version == "Premium" || version == "Standard" && <Card className="p-3 mb-4 metrics__card" classes={{root: classes.card_style}}>
                <InPatientStatistics in_patient_statistics={in_patient_statistics}/>
              </Card>}
              {version == "Premium" || version == "Standard" && 
              <Card className="p-3 mb-4 metrics__card" classes={{root: classes.card_style}}>
                <MortalityRate filter_type={filter_type} mortality_rate={mortality_rate}/>
              </Card>  }                 
            </div>

            {["Premium", "Standard"].includes(version) &&<div className="col-lg-3 col-md-6 col-sm-12" >
              <Card className="p-3 mb-4 metrics__card" classes={{root: classes.card_style_doctors}}>
                <DoctorsOnDuty actions={actions}/>
              </Card>
            </div>}

        </div>

        {/** Fourth Row */}
        <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              
              <Card className="p-3 mb-3 metrics__card" classes={{root: classes.card_style}}>
                <TopSellingProducts topSellsProps={topSellsProps} filter_type={filter_type}/>
              </Card>
            </div>
                
            <div className="col-lg-6 col-md-6 col-sm-12">
            <Card className="p-3 mb-4 metrics__card" classes={{root: classes.card_style}}>
                <TimeToService time_to_service={time_to_service}/>
              </Card>
            </div>
        </div>
      </div>
    )

    // all normal dashboard
    const normal_dashboard = (
      <div className="dashboard-layout">
        <div className="row">

            <div className="col-lg-6 col-md-6 col-sm-12">
            <Card className="p-3 mb-4 metrics__card" classes={{root: classes.card_style}}>
                <PatientStatistics title_report_card={title_report_card('Patient Statistics', '/reports/moh_reports',
                version == "Premium" ? "MOH Reports": '',
                '10px')}
                gender_data={gender_data} age_groups={age_groups}/>
              </Card>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-12">

            {version == "Premium" || version == "Standard" && <Card className="p-3 mb-4 metrics__card" classes={{root: classes.card_style}}>
                <InPatientStatistics in_patient_statistics={in_patient_statistics}/>
              </Card>}

              {version == "Premium" || version == "Standard" && 
              <Card className="p-3 mb-4 metrics__card" classes={{root: classes.card_style}}>
                <MortalityRate filter_type={filter_type} mortality_rate={mortality_rate}/>
              </Card>  }                    
            </div>

            <div className="col-lg-3 col-md-6 col-sm-12" >
            <Card className="p-3 mb-4 metrics__card" classes={{root: classes.card_style}}>
                <div style={{display:'flex', justifyContent: 'space-between'}}>
                      <div>
                          <p className="dashboard-card-font-color">{`${cardList[3].title}`}</p>
                          <div className="orange-short-border"></div>                                        
                      </div>
                      <div md={4} style={{padding:'0px'}}>
                        <p   className="dropdown-filter">{filter_type}</p>                       
                      </div>
                  </div>
                  <div>{cardList[3].bodyValues()}</div>
              </Card>
              {["Premium", "Standard"].includes(version) &&<Card className="p-3 mb-4 metrics__card" classes={{root: classes.card_style_doctors}}>
                <DoctorsOnDuty actions={actions}/>
              </Card>}
            </div>

        </div>

      </div>
    )
    const versions = ["Basic", "Standard", "Premiumn"]
   
  
    return (
        <div data-testid="main-page" style={{backgroundColor: '#EEEEEE'}}>
            <PageTitle title="Dashboard"/>
            <SubHeader classTitle={'dashboard-title-style'} classSubTitle={'dashboard-subtitle-style'} title="Dashboard" 
                subTitle={`Welcome to Mpeke HMIS | ${today_date.toDateString()} : ${time_now}`} settingsButton={settingsButton} >
                <FontAwesomeIcon icon={faHome}/>
            </SubHeader>

            {version == "Premium" || version == "Basic" || version == "Standard" ?
            (isEarningsPermission && !isInventoryPermission) ? earnings_dashboard_ui :
            (isInventoryPermission && !isEarningsPermission) ? inventory_dashboard_ui :
            (!isEarningsPermission && !isInventoryPermission) ? normal_dashboard :
            all_access_dashboard:

              <Alert severity="error" style={{paddingTop:0, paddingBottom:0, marginBottom:'8px'}}>
              <span style={{display:'inline-block', marginRight:4}} >Looks like you don't have any running subscription, please contact Mara Scientific to renew your seetup for you a subscription. Thanks for choosing Mpeke HMIS</span>
              
          </Alert>}
              
            

            {renderMenu}
            {renderSettingsMenu}
        </div>
    )

}


function mapStateToProps(state) {
    const {doctors, user} = state;
    return {doctors, user, snackbars: state.snackbar};
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch),
        },
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(MainPage);