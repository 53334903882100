import React from 'react';
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMoneyBill} from "@fortawesome/free-solid-svg-icons/faMoneyBill";

import {makeStyles} from "@material-ui/core/styles";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../../actions/snackbarActions";
import {connect} from "react-redux";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {ReusableRouteTabs} from "../../Utils/Dialogs/ReusableTabs";
import {Container} from "../../Utils/styledComponents"
import { history } from '../../../Helpers/history';
import { useSetupServices } from './hooks/useSetupServices';
import {AccountSetUpForm} from './AccountSetUpForm';
import SetupLedgerList from './SetupLedgerList';
import DeleteDialog from '../../Utils/Dialogs/DeleteDialog';
import CustomDialog from '../../Utils/Dialogs/CustomDialog';
import { EditSetupForm } from './AccountSetUpForm';

const headData = [
    { id: 'service_name', label: 'Service',minWidth: 200 },
    { id: 'debit_ledger', label: 'Debit Ledger',minWidth: 200 },
    { id: 'credit_ledger', label: 'Credit Ledger',minWidth: 200 },
    { id: 'action', label: 'Action',minWidth: 200 },
]

const tableData = [{name:'Service', isRequired:true},{name:'Debit', isRequired:true},{name:'Credit', isRequired:true},
{name:'Action', isAction:true}]
const SetupServices = ({actions, snackbars}) => {
    const components = [
        {label: 'Accounting Period', path: '/accounting/setup'},
        {label: 'Suppliers', path: '/accounting/setupledger/suppliers'},
        {label: 'Customers', path: '/accounting/setupledger/customers'},
        {label: 'Products', path: '/accounting/setupproducts'},
        {label: 'Services', path: '/accounting/setupservices'},
    ]
    const {serviceProps,ledgerData, closeSnackbar, isSetUp,handleChangeSetup, editProps} = useSetupServices(actions)
    const {openDelete, handleCloseDelete,  handleDelete, openEdit, handleCloseEdit}= ledgerData
    const {accounts} = serviceProps

    const {openBar, type, message} = snackbars;
    return (
        <div className='journals'>
            <PageTitle title='Setup Services'/>
            <SubHeader title="Accounting" subTitle='Setup Services'>
                <FontAwesomeIcon icon={faMoneyBill}/>
            </SubHeader>
            <CustomDialog open={openEdit} handleClose={handleCloseEdit} title='Edit Service'>
                <EditSetupForm {...{...editProps, accounts,item_name:'Service'}}/>
            </CustomDialog>
            <DeleteDialog message='delete' openDialog={openDelete} handleClose={handleCloseDelete}
            text="service" title='Delete Service'>
                <button className='btn sms-btn-dismiss btn-sm' onClick={handleDelete}>Delete</button>
                <button className="btn sms-grey-btn btn-sm" onClick={handleCloseDelete}>No</button>    
            </DeleteDialog>
            <MainSnackbar message={message} open={openBar} handleCloseBar={closeSnackbar} variant={type}/>
            <Container>
                <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/>
                <div className='general-ledger-header inpatient-billing-header mb-3'>
                    <a className={`billing-span btn btn-sm ${isSetUp === 0 ? 'active-billing-span':''}`} onClick={()=>handleChangeSetup(0)}>Setup</a>
                    <a className={`billing-span btn btn-sm ${isSetUp === 1 ? 'active-billing-span':''}`} onClick={()=>handleChangeSetup(1)}>Service Ledgers</a>
                </div>
                {isSetUp === 0 ?  <AccountSetUpForm {...{...serviceProps, headData:tableData, component:'services'}}/>:null}
                {isSetUp === 1 ? <SetupLedgerList {...{...ledgerData, headData}}/>:null}
            </Container>
        </div>
        
    )
}

function mapStateToProps(state) {
    const {snackbar} = state;
    return {snackbars:snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(SetupServices);