import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Label from "../../Utils/FormInputs/Label";
import { CustomSelect } from "../../Utils/FormInputs/SelectInput";
import TextField from "../../Utils/FormInputs/TextField";
import { ErrorMessage } from "../../Utils/styledComponents";
import axios from "axios";
import { config } from "../../../Helpers/env";
import { errorMessages } from "../../../Helpers/ErrorMessages";
import { AccountingDrawer } from "../../Utils/AccountingUtils/AccountingDrawer";
import { BlockNavigationComponent } from "../../Utils/ReusableComponents/BlockNavigationComponent";
import { history } from "../../../Helpers/history";
import { dateConvert } from "../../Utils/ConvertDate";
import { useVersionsContext } from "../../../Context/versions-context";
import { useCurrency } from "../../../Context/currency-context";

export const ReusableNewCOA = ({
  actions,
  isSubmitted,
  setIsSubmitted,
  openDialog,
  setOpenDialog,
  isExpense = false,
  account_id = "",
}) => {
  const [accountType, setAccountType] = useState("");
  const [state, setState] = useState({
    account_name: "",
    account_code: "",
    description: "",
    isSubAccount: false,
    opening_balance: 0,
    balance_date: dateConvert(),
    isTransactionAccount: false,
  });
  const [parentAccount, setParentAccount] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [defaultAccounts, setDefaultAccount] = useState([]);
  const [subAccounts, setSubAccounts] = useState([]);
  const [entryType, setEntryType] = useState("");
  const [isBlocking, setIsBlocking] = useState(false);
  const [isBlockDialog, setIsBlockDialog] = useState(false);
  const [currencies, setCurrencies] = useState([]);
  const [currency, setCurrency] = useState("");

  const { subscription } = useVersionsContext();
  const version = subscription?.version;
  const isPremium = version === "Premium";

  const { currency: selectedCurrency } = useCurrency({ actions });

  useEffect(() => {
    if (isPremium) {
      return;
    }
    setCurrency(selectedCurrency?.id);
  }, [version]);

  useEffect(() => {
    axios.get(`${config.smsUrl}/accounting/chart/accounting_categories`).then(
      (res) => {
        const data = res.data;
        const dt = data ?? {};
        const arr = dt.data ?? [];
        const items = arr.map((item) => ({
          value: `${item.id}-${item.head_code}`,
          label: `${item.head_code} - ${item.head_name}`,
        }));
        setDefaultAccount(items);
      },
      (err) => console.log(err)
    );
  }, []);

  // get currencies
  useEffect(() => {
    axios
      .get(`${config.smsUrl}/accounting/currencies`)
      .then((res) => {
        const data = res.data;
        const all_data = !data ? {} : data;
        const arr = all_data.data ?? [];
        const list = arr.map((item) => ({
          value: `${item.id}`,
          label: `${item.currency_symbol} ${item.currency_name}`,
        }));
        setCurrencies(list);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // get currencies
  useEffect(() => {
    if (account_id) {
      onload_Data_to_edit();
    }
  }, [account_id]);

  const retrieveSubAccount = (id) => {
    const formData = new FormData();
    formData.append("account_category", id);
    axios
      .post(`${config.smsUrl}/accounting/chart/parent_accounts`, formData)
      .then(
        (res) => {
          const data = res.data;
          const dt = data ?? {};
          const arr = dt.data ?? [];
          const items = arr.map((item) => ({
            value: item.head_code,
            label: `${item.head_code} - ${item.head_name}`,
          }));
          setSubAccounts(items);
        },
        (err) => console.log(err)
      );
  };

  const handleChangeAccountType = (value) => {
    setAccountType(value);
    const val = value ? value : {};
    const item = val.value ? val.value : "";
    const id = item.split("-")[0];
    retrieveSubAccount(id);
    setIsBlocking(true);
  };

  const handleChangeState = (event) => {
    const { value, checked, type, name } = event.target;
    const val = type === "checkbox" ? checked : value;
    setState({ ...state, [name]: val });
    setIsBlocking(true);
  };

  const handleChangeParentAccount = (value) => {
    setParentAccount(value);
    setIsBlocking(true);
  };

  const handleChangeEntryType = (value) => {
    setEntryType(value);
    setIsBlocking(true);
  };

  const handleCloseDialog = () => {
    if (isBlocking) {
      setIsBlockDialog(true);
    } else {
      setOpenDialog(false);
      setIsBlockDialog(false);
      setIsBlocking(false);
    }
  };

  const onload_Data_to_edit = () => {
    axios
      .get(`${config.smsUrl}/accounting/find/account?account_id=${account_id}`)
      .then(
        (res) => {
          const data = res.data;
          const dt = data ?? {};
          const obj = dt.data[0] ?? [];
          console.log(" helloe  ,,,,  ", obj);
          console.log(" data  ", data);

          // const items = arr.map((item) => ({
          //   value: item.head_code,
          //   label: `${item.head_code} - ${item.head_name}`,
          // }));
          if (obj) {
            const value = {
              value: `${obj.parent_id_final}-${obj.parent_code_final}`,
              label: `${obj.parent_code_final} - ${obj.parent_name_final}`,
            };
            const parent_value = {
              value: obj.parent_head_code,
              label: `${obj.parent_head_code} - ${obj.parent_name}`,
            };

            handleChangeAccountType(value);
            handleChangeParentAccount(parent_value);
            setEntryType("");
            setCurrency(currencies[0]);
            setState({
              account_name: obj.head_name,
              account_code: obj.head_code,
              description: "",
              isSubAccount: false,
              opening_balance: 0,
              balance_date: balance_date ? balance_date : "",
              isTransactionAccount: false,
            });
          }
        },
        (err) => console.log(err)
      );
  };

  //Change currency
  const handleChangeCurrency = (value) => {
    setCurrency(value);
  };

  const clearForm = () => {
    setAccountType("");
    setParentAccount("");
    setEntryType("");
    setCurrency("");
    setState({
      account_name: "",
      account_code: "",
      description: "",
      isSubAccount: false,
      opening_balance: 0,
      balance_date: balance_date ? balance_date : "",
      isTransactionAccount: false,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const {
      account_name,
      opening_balance,
      balance_date,
      isTransactionAccount,
    } = state;
    const isTransaction = isTransactionAccount ? 1 : 0;
    setSubmitted(true);
    const formData = new FormData();
    const val = accountType ? accountType : {};
    const item = val.value ? val.value : "";
    const category_id = item.split("-")[1] ? item.split("-")[1] : "";
    const entry_type = entryType ? entryType : {};
    const entry_value = entry_type.value ? entry_type.value : "";
    const currency_type = currency ? currency : {};
    const currency_value = currency_type.value ? currency_type.value : "";
    formData.append("account_category", category_id);
    formData.append("account_name", account_name);

    formData.append(
      "parent_account_code",
      parentAccount.value ? parentAccount.value : ""
    );
    formData.append("opening_balance", opening_balance ? opening_balance : 0);
    formData.append("entry_type", entry_value);
    formData.append("as_of", balance_date);
    formData.append("is_transaction", isTransaction);
    formData.append("currency", currency_value);
    formData.append("account_id", account_id);
    setIsBlocking(false);
    const isTransact =
      (entry_value && balance_date && currency_value) ||
      (!entry_value && balance_date && currency_value);
    if (
      accountType &&
      account_name &&
      currency_value &&
      ((isTransactionAccount && isTransact) || !isTransactionAccount)
    ) {
      setIsSubmitted("pending");
      // const url = isSubAccount && parentAccount.value ? 'sub_accounts/add' : 'parent_accounts/add'
      axios
        .post(`${config.smsUrl}/accounting/chart/sub_accounts/add`, formData)
        .then(() => {
          actions.snackbarActions.snackSuccess("Account created successfully");
          setIsSubmitted("resolved");
          clearForm();
          setSubmitted(false);
          setOpenDialog(false);
        })
        .catch((err) => {
          errorMessages(err, null, actions);
          setIsSubmitted("rejected");
        });
    }
  };

  const handleResetForm = () => {
    setOpenDialog(false);
    setIsBlockDialog(false);
    setIsBlocking(false);
    setEntryType("");
    setAccountType("");
    setParentAccount("");
    setState({
      account_name: "",
      account_code: "",
      description: "",
      isSubAccount: false,
      opening_balance: 0,
    });
    setSubmitted(false);
  };

  const entryTypes = [
    { value: "DEBIT", label: "DEBIT" },
    { value: "CREDIT", label: "CREDIT" },
  ];

  const isPending = isSubmitted === "pending";
  const {
    account_name,
    opening_balance,
    isSubAccount,
    balance_date,
    isTransactionAccount,
  } = state;
  return (
    <div>
      <BlockNavigationComponent
        when={isBlocking}
        shouldBlockNavigation={() => {
          return isBlocking;
        }}
        navigate={(path) => history.push(path)}
        dialogClose={isBlockDialog}
        setIsBlockDialog={setIsBlockDialog}
        clearForm={handleResetForm}
      />
      <AccountingDrawer
        open={openDialog}
        title="Create Account"
        handleClose={handleCloseDialog}
      >
        <form
          onSubmit={handleSubmit}
          autoComplete="off"
          className="general-card"
          name="new-acc-form"
        >
          <Form.Group as={Col}>
            <Label name="Account type" htmlFor="account_type" type />
            {/* <Col sm={8}> */}
            <CustomSelect
              value={accountType}
              options={defaultAccounts}
              dataTest={"account-type"}
              name="account-type"
              onChange={handleChangeAccountType}
              id="account_type"
            />
            {submitted && !accountType && (
              <ErrorMessage>Account type is required</ErrorMessage>
            )}
            {/* </Col> */}
          </Form.Group>
          <Form.Group as={Col}>
            <Label name="Account name" htmlFor="account_name" type />
            {/* <Col sm={8}> */}
            <TextField
              onChange={handleChangeState}
              value={account_name}
              id="account_name"
              name="account_name"
              type="text"
              data-testid="account-name"
            />
            {submitted && !account_name && (
              <ErrorMessage>Account name is required</ErrorMessage>
            )}
            {/* </Col> */}
          </Form.Group>

          {isPremium ? (
            <Form.Group as={Col}>
              <Label name="Currency Type" type />
              {/* <Col sm={8}> */}
              <CustomSelect
                value={currency}
                onChange={handleChangeCurrency}
                name="currency"
                dataTest={"currency"}
                id="currency_type"
                options={currencies}
              />
              {submitted && !currency && (
                <ErrorMessage>This field is required</ErrorMessage>
              )}
              {/* </Col> */}
            </Form.Group>
          ) : null}

          {/* {(accountType && subAccounts.length > 0) ?  */}
          {/* <Form.Group as={Col}> */}
          {/* <Col sm={4}/> */}
          {/* <Col sm={8}> */}
          {/* <Form.Check type='checkbox' label='Is sub-account' name='isSubAccount'
                            value={isSubAccount}
                            onChange={handleChangeState}/> */}
          {/* </Col> */}
          {/* </Form.Group>  */}
          {/* // : null} */}

          {/* {isSubAccount ?
            <> */}
          <Form.Group as={Col}>
            <Label name="Parent account" htmlFor="parent_account" type />
            {/* <Col sm={8}> */}
            <CustomSelect
              value={parentAccount}
              onChange={handleChangeParentAccount}
              dataTest={"parent-account"}
              options={subAccounts}
              isDisabled={!accountType}
              id="parent_account"
            />
            {submitted && isSubAccount && !parentAccount && (
              <ErrorMessage>This field is required</ErrorMessage>
            )}
            {/* </Col> */}
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Check
              type="checkbox"
              label="Is transaction account"
              name="isTransactionAccount"
              value={isTransactionAccount}
              onChange={handleChangeState}
              id="is_transaction_account"
            />
          </Form.Group>

          {isTransactionAccount ? (
            <div className="row">
              <div className="col-lg-6">
                <Form.Group as={Col}>
                  <Label
                    name="Opening balance"
                    htmlFor="opening_balance"
                    type
                  />
                  {/* <Col sm={8}> */}
                  <TextField
                    onChange={handleChangeState}
                    value={opening_balance}
                    name="opening_balance"
                    type="number"
                    id="opening_balance"
                    disabled={!isTransactionAccount}
                  />
                  {/* {(submitted && isTransactionAccount && !opening_balance) &&
                                <ErrorMessage>This field  is required</ErrorMessage>} */}
                  {/* </Col> */}
                </Form.Group>
              </div>
              <div className="col-lg-6">
                <Form.Group as={Col}>
                  <Label name="As of" htmlFor="balance_date" type />
                  {/* <Col sm={8}> */}
                  <TextField
                    onChange={handleChangeState}
                    value={balance_date}
                    name="balance_date"
                    type="date"
                    id="balance-date"
                    disabled={!isTransactionAccount}
                  />
                  {submitted && isTransactionAccount && !balance_date && (
                    <ErrorMessage>This field is required</ErrorMessage>
                  )}
                  {/* </Col> */}
                </Form.Group>
              </div>
            </div>
          ) : null}

          {isTransactionAccount && !isExpense ? (
            <Form.Group as={Col}>
              <Label name="Entry type" type />
              {/* <Col sm={8}> */}
              <CustomSelect
                value={entryType}
                onChange={handleChangeEntryType}
                id={"entry_type"}
                options={entryTypes}
                isDisabled={!isTransactionAccount}
              />
              {submitted && isTransactionAccount && !entryType && (
                <ErrorMessage>This field is required</ErrorMessage>
              )}
              {/* </Col> */}
            </Form.Group>
          ) : null}
          {/* </> : null} */}

          <div style={{ marginLeft: "15px" }}>
            <button
              type="submit"
              className="btn btn-sm sms-info-btn"
              data-testid="submit-new-account-coa"
              disabled={isPending}
            >
              {isPending ? "Submitting..." : "Submit"}
            </button>
          </div>
        </form>
      </AccountingDrawer>
    </div>
  );
};
