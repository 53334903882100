import {useEffect, useState} from "react";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {titleCase} from "../../Users/addUser";
import {useBedTransfer} from "./useBedTransfer";
import {useAddAssign} from "./useAddAssign";

export const useBedAssignList = (actions, url, status="") =>{
    const [searchValue, setSearchValue] = useState('');
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('date');
    const [data, setData] = useState({});
    const [loading, setLoading] = useState('idle');
    const [activePage, setActivePage] = useState(1);
    const [ids, setIds] = useState({patient_admission_id:'', visit_id:'', bed_assignment_id:'', patient_name:'', bill_id:''});
    const {transferProps, isResolved:isResolvedTransfer} = useBedTransfer(actions)

    const {assignProps, openDialog, setOpenDialog, isResolved} = useAddAssign(actions, ids)



    useEffect(() => {
        setLoading('pending');
        const formData = new FormData();
        const search = searchValue ? titleCase(searchValue) :searchValue
        formData.append('search', search);
        formData.append('status', status);
        axios.post(`${config.smsUrl}/${url}/${activePage}`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            setData(dt)
            setLoading('success');
        }).catch(e => {
            logoutErrorMessage(e, null, actions);
            setLoading('error')
        })
    }, [activePage,  searchValue,isResolvedTransfer]);

    const handleSearch = (event) => {
        setSearchValue(event.target.value);

    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangeNewPage = (page) => {
        setActivePage(page);
    };

    const handleOpenDialog = (item) =>{
        setOpenDialog(true)
        const {patient_admission_id, visit_id,bed_assignment_id, patient_name, bill_id} = item;
        setIds({patient_admission_id, visit_id,bed_assignment_id,patient_name, bill_id})
    }

    const handleCloseDialog = () => {
        setOpenDialog(false)
    };



    const closeSnackBar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const isLoading = loading === 'pending';
    const isSuccess = loading === 'success';
    const isError = loading === 'error';

    return {searchValue, order, orderBy,  data,  activePage,handleSearch,
        handleRequestSort, handleChangeNewPage,openDialog, handleOpenDialog, handleCloseDialog,
        closeSnackBar,  isLoading, isSuccess, isError, transferProps, assignProps}
}