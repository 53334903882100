import {useEffect, useState} from "react";
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import { dateConvert } from "../../../Utils/ConvertDate";

export const useProductLog = (actions,product_id) => {
    const transactions = [{value:'ADDED', text:'ADDED'},{value:'ADJUSTED I', text:'ADJUSTED(INCREMENT)'},
    {value:'ADJUSTED D', text:'ADJUSTED(DECREMENT)'}, {value:'DISPATCHED', text:'DISPATCHED'},
    {value:'REQUISITIONED', text:'REQUISITIONED'},{value:'SOLD', text:'SOLD'},
    {value:'ADMINISTERED', text:'ADMINISTERED'},  {value:'DISPENSED TO WARD', text:'DISPENSED TO WARD'}]
    const [searchValue, setSearchValue] = useState('');
    const [data, setData] = useState({loading:'idle', total_count:0, productTransactions:[]});
    const [activePage, setActivePage] = useState(1);
    const [state, setState] = useState({start_date:dateConvert(), end_date:dateConvert(), transaction_type:''})
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [last_id, setLastId] = useState(0)
    const {start_date, end_date, transaction_type} = state;
    const {loading} = data;


    useEffect(() => {
        setData({...data, loading:'pending'})
        const formData = new FormData();
        formData.append('product_id', product_id)
        formData.append('actions', transaction_type)
        formData.append('start_date', start_date)
        formData.append('end_date', end_date)
        axios.post(`${config.smsUrl}/cinventory/product_log_list`,formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const arr = dt.data ? dt.data :{};
            const products = arr.product_log ? arr.product_log : []
            setData({productTransactions:products, loading:'success'})
        }).catch((err) => {
            logoutErrorMessage(err, null, actions);
            setData({...data, loading:'error'})
        })

    }, []);


    const handleSearch = (event) => {
        setSearchValue(event.target.value);
    };


    const handleChangeNextPage = (value) => {
        setActivePage(value)
    };


    const handleChange = (event) =>{
        const {name, value} = event.target;
        setState({...state,[name]:value})
    }

    const handleSubmit = (event) =>{
        event.preventDefault();
        setSubmitted(true);
        if(start_date && end_date){
            setData({...data, loading:'pending'})
            setIsSubmitted('pending')
            const formData = new FormData();
            formData.append('product_id', product_id)
            formData.append('actions', transaction_type)
            formData.append('start_date', start_date)
            formData.append('end_date', end_date)
            axios.post(`${config.smsUrl}/cinventory/product_log_list`, formData).then(res => {
                const data = res.data;
                const dt = !data ? {} : data;
                const arr = dt.data ? dt.data :{};
                const products = arr.product_log ? arr.product_log : []
                setData({productTransactions:products, loading:'success'})
                setIsSubmitted('resolved')
            }).catch((err) => {
                errorMessages(err, null, actions);
                setIsSubmitted('rejected')
                setData({...data, loading:'error'})
                
            })
        }
    }

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const isLoading = loading === 'pending';
    const isSuccess = loading === 'success';
    const isError = loading === 'error';
    
    const isPending = isSubmitted === 'pending';
    const isResolved = isSubmitted === 'resolved';
    const isRejected = isSubmitted === 'rejected';
    return {
        searchValue, activePage, data,handleSearch,  handleChangeNextPage, closeSnackbar, 
        isLoading, isSuccess, isError, state,submitted,
        handleChange, handleSubmit, isPending, isRejected, isResolved, transactions
    }
}
