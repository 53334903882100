import * as types from "../actionTypes";
import axios from "axios";
import { config } from "../../Helpers/env";
import moment from "moment";
import * as d3 from "d3";
import {snackError, snackInfo, snackSuccess} from "../snackbarActions";
import {errorMessages, logoutErrorMessage} from "../../Helpers/ErrorMessages";

// export const parseDate = d3.timeParse("%Y-%m-%d");
//
// export function saveBillReport(bill_report) {
//   return {
//     type: types.BILL_REPORT,
//     bill_report
//   }
// }
//
// export function getAllBills(patientBills) {
//   return {
//     type: types.GET_ALL_BILLS,
//     patientBills
//   }
// }
//
// export function getAllCustomerVisits(customers) {
//   return {
//     type: types.GET_CUSTOMER_VISITS,
//     customers
//   }
// }
//
// export function getPrescDrugs(presc_drugs) {
//   return {
//     type: types.GET_ALL_PRESC_DRUGS,
//     presc_drugs
//   }
// }
//
// export function saveThisMonthsSales(this_months_sales) {
//   return {
//     type: types.THIS_MONTHS_SALES,
//     this_months_sales
//   }
// }
//
// export function saveDrugsInRange(drugs_in_range) {
//   return {
//     type: types.DRUGS_IN_RANGE,
//     drugs_in_range
//   }
// }
//
// export function saveDrugsByDate(drugs_by_date) {
//   return {
//     type: types.DRUGS_BY_DATE,
//     drugs_by_date
//   }
// }
//
// export function saveMonthlyVisits(monthly_visits) {
//   return {
//     type: types.MONTHLY_VISITS,
//     monthly_visits
//   }
// }
//
// export function saveMonthlyVisitPercentage(monthly_visit_percentage) {
//   return {
//     type: types.MONTHLY_VISIT_PERCENTAGE,
//     monthly_visit_percentage
//   }
// }
//
// export function updateDrugs(drug_sales) {
//   return {
//     type: types.UPDATE_DRUGS_BY_DATE,
//     drug_sales
//   }
// }

export const retrieveBillingList = (page, formData) => (dispatch) =>{
  dispatch({type:types.BILL_LIST_REQUEST});
  return axios.post(`${config.smsUrl}/cbilling/bill_list/${page}`, formData).then(res=>{
    const data = res.data;
    const bills = !data ? {} : data;
    const all_bills = !bills.bill_list ? [] : bills.bill_list;
    const total_count = !bills.total_count ? 0 : bills.total_count;
    dispatch({type:types.BILL_TOTAL_COUNT,total_count});
    dispatch({type:types.BILL_LIST_SUCCESS,billingList:all_bills})
  }).catch(err=>{
    dispatch({type:types.BILL_LIST_ERROR});
    logoutErrorMessage(err,dispatch,null)
  })
};


export const deleteBill = (bill_id) =>(dispatch) =>{
  return axios.get(`${config.smsUrl}/Cbilling/delete_bill`,{params:{bill_id}}).then(()=>{
    dispatch({type:types.DELETE_BILL,bill_id});
    dispatch(snackSuccess('Bill Deleted Successfully'))
  }).catch(err=>{
    errorMessages(err, dispatch, null)
  })
};

// export function getCustomerVisits(start_date, end_date) {
//   return function (dispatch) {
//     return axios.get(config.smsUrl + "/billing/top_payers", {
//       params: {
//         start_date,
//         end_date
//       }
//     }).then(response => {
//       const visits = response.data;
//       visits.sort((a, b) => {
//         return b - a
//       });
//       dispatch(getAllCustomerVisits(visits));
//     })
//       .catch(error => {
//        errorMessages(error,dispatch,null)
//       })
//   }
// }
//
// export function updateDrugSales(start_date, end_date) {
//   return function (dispatch) {
//     return axios.get(config.smsUrl + "/billing/getDrugSalesByDate", {
//       params: {
//         start_date,
//         end_date
//       }
//     })
//       .then(response => {
//         const drugs_by_date = response.data;
//         console.log(drugs_by_date);
//         if (Object.keys(drugs_by_date).length > 0) {
//           const obj1 = Object.keys(drugs_by_date).map((drug) => {
//             return {
//               drug,
//               price: drugs_by_date[drug]
//             };
//           });
//           console.log(obj1);
//
//           const obj = {};
//           obj["drugs"] = drugs_by_date;
//           obj["duration"] = {
//             start_date,
//             end_date
//           };
//           obj["readable_drugs"] = obj1;
//           dispatch(updateDrugs(obj));
//           console.log(obj);
//         } else {
//           dispatch(snackInfo("No stats available in that date range"));
//         }
//       }).catch(error=>{
//         errorMessages(error,dispatch,null)
//       })
//   }
// }
//
//
// export function getBillReport(start_date, end_date, means = "D") {
//   return function (dispatch) {
//     return axios.get(config.smsUrl + '/billing/getDrugSalesInDateRange', {
//       params: {
//         start_date,
//         end_date,
//         means
//       }
//     })
//       .then(response => {
//         // let arr = processData(response.data.presc_drugs);
//         // let monthlySales = getMonthlySales(arr); //returns only date and total
//         let monthlySales = processSalesData(response.data);
//         console.log(monthlySales);
//         dispatch(saveBillReport(monthlySales));
//         if (monthlySales.length !== 0) {
//           dispatch(saveThisMonthsSales(monthlySales)); //require date
//           dispatch(getDrugsSold(monthlySales));
//           dispatch(getVisits((monthlySales)));
//           dispatch(getDrugsByDate(monthlySales));
//         }
//         // get this month sales        // get drugs sold between two days        // visits per week
//       }).catch(error=>{
//         errorMessages(error,dispatch,null)
//       })
//   }
// }
//
// export function processSalesData(data) {
//   const arr = [];
//   Object.keys(data).map(sale => {
//     arr.push({date: new Date(sale), total: data[sale]})
//   });
//   arr.sort((a, b) => {
//     return new Date(b.date) - new Date(a.date)
//   });
//   return arr;
// }
//
// export function processData(prescribed_drugs) {
//   const groups = prescribed_drugs.reduce((acc, obj) => {
//     const date = obj.issue_date !== null && obj.issue_date.split('T')[0];
//     if (!acc[date]) {
//       acc[date] = [];
//     }
//     obj.issue_date !== null && acc[date].push(obj);
//     return acc;
//   }, {});
//
//
//   const groupArrays = Object.keys(groups).map((date) => {
//     return {
//       date,
//       prescribed_drugs: groups[date]
//     };
//   });
//
//   groupArrays.sort((a, b) => {
//     return new Date(b.date) - new Date(a.date)
//   });
//   return groupArrays;
// }
//
// export function getAllPrescDrugs() {
//   return function (dispatch) {
//     return axios.get(config.smsUrl + "/pharmacists/getAllPrescDrugs").then(response => {
//       const prescribed_drugs = response.data.presc_drugs;
//       dispatch(getPrescDrugs(processData(prescribed_drugs)));
//       console.log(prescribed_drugs);
//     }).catch(error=>{
//       errorMessages(error,dispatch,null)
//     })
//
//   }
// }
//
// function getMonthlySales(data) {
//   const arr = [];
//   const now = new Date();
//   data.forEach(eachDayBill => {
//     const billDate = eachDayBill.date;
//     const prescribed_drugs = eachDayBill.prescribed_drugs;
//     const dayTotal = d3.sum(prescribed_drugs, function (d) {
//       return d.cost;
//     });
//     if (new Date(billDate).getMonth() === now.getMonth()) {
//       arr.push({date: parseDate(billDate), total: +dayTotal})
//     }
//   });
//   return arr;
// }
//
// export function updateBillReport(start_date, end_date, means) {
//   return function (dispatch) {
//     return axios.get(config.smsUrl + "/billing/getDrugSalesInDateRange", {
//       params: {
//         start_date,
//         end_date,
//         means
//       }
//     })
//       .then(response => {
//         const data = response.data;
//         console.log(data);
//         if (Object.keys(data).length === 0) {
//           dispatch(snackInfo("No stats available in that date range"));
//         } else {
//           const updatedSales = updateMonthSales(response.data);
//           dispatch(saveThisMonthsSales(updatedSales));
//         }
//       }).catch(error=>{
//         errorMessages(error,dispatch,null)
//       })
//   }
// }
//
// function updateMonthSales(data) {
//   const arr = [];
//   Object.keys(data).map(res => {
//     arr.push({date: new Date(res), total: +data[res]})
//   });
//   arr.sort((a, b) => {
//     return new Date(b.date) - new Date(a.date)
//   });
//   return arr;
// }
//
// export function toJson(date) {
//   return moment(date).format();
// }
//
// export function toDateString(date) {
//   return moment(date).format("dddd, Do MMMM");
// }
//
// export function getDrugsSold(start_date, end_date,  means = "D") {
//   return function (dispatch) {
//     // const start_date = toJson(monthlySales[2].date ? monthlySales[2].date : monthlySales[0].date);
//     // const end_date = toJson(monthlySales[1].date ? monthlySales[1].date : monthlySales[0].date);
//     // console.log(start_date);
//     // console.log(end_date);
//     // if (monthlySales.length > 0) {
//       return axios.get(config.smsUrl + "/billing/getDrugSalesInDateRange", {
//         params: {
//           start_date: start_date,
//           end_date: end_date,
//           means
//         }
//       })
//         .then(response => {
//           const drugs_in_range = response.data;
//           console.log(drugs_in_range);
//           if (Object.keys(drugs_in_range).length > 0) {
//             dispatch(saveDrugsInRange(getPercentageDrugs(drugs_in_range)));
//           }
//         }).catch(error=>{
//           errorMessages(error,dispatch,null)
//         })
//     // }
//   }
// }
//
// export function getLastWeek() {
//   const today = new Date();
//   return new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
// }
//
// export function getLastMonth() {
//   const today = new Date();
//   return new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
// }
//
// export function getCurrentDate() {
//   const now = new Date();
//   return new Date(now.getFullYear(), now.getMonth(), now.getDate());
// }
//
// export function getMonday(d) {
//   d = new Date(d);
//   const day = d.getDay(),
//     diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
//   return new Date(d.setDate(diff));
// }
//
// export function getDrugsByDate(monthlySales) {
//   return function (dispatch) {
//     const start_date = toJson(getMonday(getLastWeek(new Date()))); //start date is older
//     const end_date = toJson(getMonday(new Date()));
//     return axios.get(config.smsUrl + "/billing/getDrugSalesByDate", {
//       params: {
//         start_date,
//         end_date
//       }
//     })
//       .then(response => {
//         const drugs_by_date = response.data;
//         console.log(drugs_by_date);
//         if (Object.keys(drugs_by_date).length > 0) {
//           const obj1 = Object.keys(drugs_by_date).map((drug) => {
//             return {
//               drug,
//               price: drugs_by_date[drug]
//             };
//           });
//           console.log(obj1);
//
//           const obj = {};
//           obj["drugs"] = drugs_by_date;
//           obj["duration"] = {
//             start_date,
//             end_date
//           };
//           obj["readable_drugs"] = obj1;
//           dispatch(saveDrugsByDate(obj));
//           console.log(obj);
//         }
//       }).catch(error=>{
//         errorMessages(error,dispatch,null)
//       })
//   }
// }
//
// export function getPercentageDrugs(drugs_in_range) {
//   if (Object.keys(drugs_in_range).length !== 0) {
//     let lastDay = 0;
//     if (Object.keys(drugs_in_range)[1]) {
//       lastDay = +drugs_in_range[Object.keys(drugs_in_range)[1]];
//     }
//     const firstDay = +drugs_in_range[Object.keys(drugs_in_range)[0]];
//     console.log(drugs_in_range);
//     console.log(Object.keys(drugs_in_range)[0]);
//     drugs_in_range["percentage"] = (((lastDay - firstDay) / firstDay) * 100).toFixed(2);
//     drugs_in_range["difference"] = +(lastDay - firstDay);
//     return drugs_in_range;
//   }
// }
//
// export function getConsultationBill(consultation_bill) {
//   return {
//     type: types.GET_CONSULTATION_BILL,
//     consultation_bill
//   }
// }
//
// export function getProcedureBill(procedure_bill) {
//   return {
//     type: types.GET_PROCEDURE_BILL, procedure_bill
//   }
// }
//
// export function getLabBill(lab_bill) {
//   return {
//     type: types.GET_LAB_BILL, lab_bill
//   }
// }
//
// export function getScanBill(scan_bill) {
//   return {
//     type: types.GET_SCAN_BILL,
//     scan_bill
//   }
// }
//
//
// export function getVisits(monthlySales, means = "M") {
//   return function (dispatch) {
//     if (monthlySales[0].date) {
//       const date = new Date(), year = date.getFullYear(), m = date.getMonth();
//       const latestMonth = monthlySales[0].date.getMonth();
//       let start_date;
//       if (m === 0) {
//         start_date = new Date(year, 11, 1);
//       } else if (m === 1) {
//         start_date = new Date(year, 12, 1);
//       } else {
//         start_date = new Date(year, m - 1, 1);
//       }
//       const end_date = new Date(year, m + 1, 1);
//       return axios.get(config.smsUrl + "/billing/getAppsInDateRange", {
//         params: {
//           start_date: start_date,
//           end_date: end_date,
//           means
//         }
//       })
//         .then(response => {
//           const monthly_visits = response.data;
//           console.log(monthly_visits);
//           if (Object.keys(monthly_visits).length > 0) {
//             dispatch(saveMonthlyVisits(getPercentageVisits(monthly_visits)));
//           }
//         }).catch(error=>{
//           errorMessages(error,dispatch,null)
//         })
//     }
//   }
// }
//
// export function getPercentageVisits(monthly_visits) {
//   if (Object.keys(monthly_visits).length !== 0) {
//     const lastMonth = +monthly_visits[Object.keys(monthly_visits)[0]];
//     let firstMonth = 0;
//     let percentage = 100;
//     if (monthly_visits[Object.keys(monthly_visits)[1]]) {
//       firstMonth = +monthly_visits[Object.keys(monthly_visits)[1]];
//       percentage = Math.round(((lastMonth - firstMonth) / firstMonth) * 100);
//     }
//     if (percentage.toString() === "Infinity") {
//       percentage = 0;
//     }
//     monthly_visits["percentage"] = percentage;
//     monthly_visits["difference"] = +(lastMonth - firstMonth);
//     return monthly_visits;
//   }
// }
//
// export function getAllPatientBills(appointment_id) {
//   return function (dispatch) {
//     return axios.get(config.smsUrl + "/receptionists/appointmentHasBills", {params: {appt_id: appointment_id}}).then(response => {
//       axios.get(config.smsUrl + "/billing/get_appointment_bills", {params: {appointment_id}}).then(res => {
//         const patient_bills = res.data.bills;
//         if (patient_bills.length > 0) {
//           sortBills(patient_bills);
//           const appointment_bill_id = patient_bills[0].appt_bill_id;
//           const payment_status = patient_bills[0].payment_status;
//           axios.get(config.smsUrl + "/billing/get_appointment_bill", {params: {appointment_bill_id}}).then(response => {
//             const apptbills = response.data;
//             apptbills["bill_id"] = appointment_bill_id;
//             apptbills["payment_status"] = payment_status;
//             console.log(apptbills);
//             dispatch(getAllBills([apptbills]));
//           }).catch(error=>{
//             errorMessages(error,dispatch,null)
//           })
//         }
//       }).catch(error=>{
//         errorMessages(error,dispatch,null)
//       })
//     }).catch(error=>{
//       errorMessages(error,dispatch,null)
//     })
//   }
// }
//
// function sortBills(arr){
//   arr.sort((a, b) => {
//     return b.payment_status - a.payment_status
//   });
//   console.log(arr)
//   return arr;
// }
//
// export function updateBillStatus(appt_bill_id, payment_status = 1) {
//   return function (dispatch) {
//     return axios.post(config.smsUrl + "/billing/UpdateBillStatus", null, {
//       params: {
//         appt_bill_id,
//         payment_status
//       }
//     })
//       .then(response => {
//         const appointment = response.data;
//         dispatch(reloadAppointmentBills(appointment.appt_bill.appointment_id, appt_bill_id));
//       }).catch(error=>{
//         errorMessages(error,dispatch,null)
//       })
//   }
// }
//
// export function reloadAppointmentBills(appointment_id, appointment_bill_id) {
//   return function (dispatch) {
//     return axios.get(config.smsUrl + "/receptionists/appointmentHasBills", {params: {appt_id: appointment_id}})
//       .then(response => {
//         axios.get(config.smsUrl + "/billing/get_appointment_bills", {params: {appointment_id}}).then(res => {
//           const patient_bills = res.data.bills;
//           if (patient_bills.length > 0) {
//             sortBills(patient_bills);
//             const payment_status = patient_bills[0].payment_status;
//             axios.get(config.smsUrl + "/billing/get_appointment_bill", {params: {appointment_bill_id}}).then(response => {
//               const apptbills = response.data;
//               apptbills["bill_id"] = appointment_bill_id;
//               apptbills["payment_status"] = payment_status;
//               dispatch(getAllBills([apptbills]));
//             }).catch(error=>{
//               errorMessages(error,dispatch,null)
//             })
//           }
//         }).catch(error=>{
//           errorMessages(error,dispatch,null)
//         })
//       }).catch(error=>{
//         errorMessages(error,dispatch,null)
//       })
//
//   }
// }
