//Service actions
export  const CHANGE_SERVICE_CURRENCY_RATE = "CHANGE_SERVICE_CURRENCY_RATE"
export const CHANGE_SERVICE_RATE = "CHANGE_SERVICE_RATE";
export const DELETE_SERVICE = "DELETE_SERVICE";
export const PAY_SERVICE = 'PAY_SERVICE';
export const CHECK_ALL_SERVICES = 'CHECK_ALL_SERVICES';
export const CALCULATE_SERVICE_AMOUNT = "CALCULATE_SERVICE_AMOUNT";
export const RETRIEVE_SERVICES = "RETRIEVE_SERVICES";

//product actions
export  const CHANGE_PRODUCT_CURRENCY_RATE = "CHANGE_PRODUCT_CURRENCY_RATE"
export const CHANGE_PRODUCT_RATE = "CHANGE_PRODUCT_RATE";
export const CHANGE_PRODUCT_QUANTITY = "CHANGE_PRODUCT_QUANTITY";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const PAY_PRODUCT = 'PAY_PRODUCT';
export const CHECK_ALL_PRODUCTS = 'CHECK_ALL_PRODUCTS';
export const CALCULATE_PRODUCT_AMOUNT = "CALCULATE_PRODUCT_AMOUNT";
export const RETRIEVE_PRODUCTS = "RETRIEVE_PRODUCTS";

// service and product actions
export const RETRIEVE_PRODUCTS_AND_SERVICES = "RETRIEVE_PRODUCTS_AND_SERVICES";

//bed actions
export const PAY_BED = 'PAY_BED';
export const CHECK_ALL_BEDS = 'CHECK_ALL_BEDS';
export const CALCULATE_BED_AMOUNT = "CALCULATE_BED_AMOUNT";
