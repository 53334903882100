import React from "react";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserMd} from "@fortawesome/free-solid-svg-icons/faUserMd";
import CustomTable, {CustomTablePagination, useHiddenColumns} from "../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import * as snackbarActions from "../../actions/snackbarActions"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PageTitle from "../Utils/smsTitle";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import {formatDateTime} from "../Utils/ConvertDate";
import {history} from "../../Helpers/history";
import {ReusableRouteTabs} from "../Utils/Dialogs/ReusableTabs";
import { useSeenPatients } from "./CustomHooks/usePatientsSeen";
import { DateTimeFilter } from "../Utils/ReusableComponents/DateTimeFilter";
import {useRetrievePatientCount} from "./hooks/useRetrievePatientCount";
import {usePrint} from "../Utils/Templates/usePrint";
import {
    Border,
    Heading,
    PreviousDiagnosis,
    PreviousTriage,
    ResultsHeading, SelectForm,
    UpdateCompletedDiagnosis
} from "./PatientPreviousVisits";
import {ReusableTreatmentHistory, Spacer} from "./ReusableTreatmentHistory";
import {NotesRow, PreviousNotesTable} from "./Notes/PreviousNotesTable";
import {SaveNotesForm} from "../MedicationandVisits/Findings/PatientFindings";
import Label from "../Utils/FormInputs/Label";
import {SavedNotesContainer} from "./Notes/PatientNotesContainer";
import {splitNotes} from "../Radiology/RadiologyReport";
import {FullScreenDialog} from "../Utils/Dialogs/FullScreenDialog";
import DeleteDialog from "../Utils/Dialogs/DeleteDialog";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {CustomSelect} from "../Utils/FormInputs/SelectInput";
import { useVersionsContext } from "../../Context/versions-context";


const headData = [
    {id: 'count', numeric: false, disablePadding: false, label: 'Sl', hidden:false},
    {id: 'date', numeric: false, disablePadding: false, label: 'Date', hidden:false},
    {id: 'patient_number', numeric: false, disablePadding: false, label: 'Patient No.', hidden:false},
    {id: 'patient_name', numeric: false, disablePadding: false, label: 'Patient Name', hidden:false},
    {id: 'phone_no', numeric: false, disablePadding: false, label: 'Phone', hidden:false},
    {id: 'kin_phone_no', numeric: false, disablePadding: false, label: 'NOK Phone', hidden:false},
    {id: 'provider_name', numeric: false, disablePadding: false, label: 'Provider Name', hidden:false},
    {id: 'assigned_to', numeric: false, disablePadding: true, label: 'Assigned to'},
    {id: 'action', numeric: false, disablePadding: true, label: 'Action'},
];

 const PatientsSeen = ({actions,snackbars}) => {
     const {total_patients} = useRetrievePatientCount(actions)

     const components = [{label: 'Waiting List', path: '/waitinglist', badge:true, badge_count:total_patients},
     //     {
     //     label: 'Lab/Radiology Results',
     //     path: '/pendingdoctorrequests'
     // },
         {label: 'Patients Seen', path: '/patientsSeen'}];

    const {searchValue, activePage, handleSearch,  handleChangeNextPage,handleSubmit,
        closeSnackbar,  isLoading, isSuccess, submitted,state, data,handleChange,
       isPending, isError, patientProps,isRejected,
        openEnd,handleOpenEndVisit,
        handleCloseEndVisit, handleEndVisit, isEnding,providers,
        page, rowsPerPage, handleChangePage, handleChangeRowsPerPage,
    provider, handleChangeProvider}= useSeenPatients(actions)
    const {total_count, patients} = data;
    const {openBar, type, message} = snackbars;
    const {start_date, end_date} = state

     const {openDialog, handleOpenDialog, handleCloseDialog, option, options, handleChangeOption } = patientProps



     const {componentRef, handlePrint,  fontSize}  = usePrint('Medical History Report');

     const {subscription} = useVersionsContext()
     const version = subscription?.version


     const {treatmentDetails, handleOpenNote,  setIsPrint,
          handleCancelEdit, handleChangeNotes, handleUpdateNotes,
         submitted:submittedNotes,isPendingEdit, diagnosisProps, triageProps} = patientProps
     const {isEditDiagnosis} = diagnosisProps

     const handlePrintDetails = () =>{
         handlePrint()
         setIsPrint(true)
     }


     const {patientDiagnosis, patientTriage, clinicalNotes} = treatmentDetails



     const notesHeadData = [{name:'Date'},{name:'Written By'}]

     const triageComponent = (
         patientTriage?.length > 0 ?  <>
             <ResultsHeading>
                 <Heading>Triage</Heading>
                 <Border/>
             </ResultsHeading>
             <PreviousTriage {...{patientTriage, isEditTriage: false, ...triageProps}} />
         </>: null
     )

     const clinicalNotesComponent = (
         clinicalNotes?.length > 0 ?
             <Spacer>
                 <ResultsHeading>
                     <Heading>Clinical Notes</Heading>
                     <Border/>
                 </ResultsHeading>
                 <PreviousNotesTable headData={notesHeadData}>
                     {clinicalNotes.map(item=>{

                         const examNotes = {
                             physical_examination:item.physical_examination, past_medical_history:item.past_medical_history, past_obstetric_history:item.past_obstetric_history,
                             family_history:item.family_history, notes:item.notes, complaints:item.complaints
                         }

                         const updateForm = (
                             <>
                                 <SaveNotesForm submitted={submittedNotes} handleSubmit={(e)=>handleUpdateNotes(e, item)}
                                                handleChangeState={(e)=>handleChangeNotes(e, item.examination_notes_id)}
                                                examNotes={examNotes} isEdit>
                                     <button type="submit" disabled={isPendingEdit}
                                             className="btn sms-btn small-btn btn-sm mt-2 mr-3">{isPendingEdit ? "Saving...." : "Update"}</button>
                                     <button type='button' onClick={()=>handleCancelEdit(item.examination_notes_id)} className="btn sms-gray-btn small-btn  btn-sm mt-2">Cancel</button>

                                 </SaveNotesForm>
                             </>

                         )
                         const viewNotes = (
                             <div className='row mb-0'>
                                 <div className='col-lg-6'>
                                     {item.physical_examination ?
                                         <>
                                             <Label name='Physical examination'/>
                                             <SavedNotesContainer data-testid="p_examination">
                                                 {splitNotes(item.physical_examination)}
                                             </SavedNotesContainer>
                                         </>: null}
                                     {item.past_medical_history ? <>
                                         <Label name='Past medical history'/>
                                         <SavedNotesContainer data-testid="medical_history">
                                             {splitNotes(item.past_medical_history)}
                                         </SavedNotesContainer>
                                     </>: null}
                                     {item.notes ? <>
                                         <Label name='Notes'/>
                                         <SavedNotesContainer data-testid="clinical_notes">
                                             {splitNotes(item.notes)}
                                         </SavedNotesContainer>
                                     </>: null}
                                     {item.complaints ? <>
                                         <Label name='Presenting Complaints'/>
                                         <SavedNotesContainer data-testid="complaints">
                                             {splitNotes(item.complaints)}
                                         </SavedNotesContainer>
                                     </>: null}

                                 </div>
                                 <div className='col-lg-6'>
                                     {item.family_history ? <>
                                         <Label name='Family history'/>
                                         <SavedNotesContainer data-testid="f_history">
                                             {splitNotes(item.family_history)}
                                         </SavedNotesContainer>
                                     </>: null}
                                     {item.past_obstetric_history ? <>
                                         <Label name='Past obstetric history'/>
                                         <SavedNotesContainer data-testid="obs_history">
                                             {splitNotes(item.past_obstetric_history)}
                                         </SavedNotesContainer>
                                     </>: null}

                                 </div>
                             </div>
                         )
                         return(
                             <NotesRow open={item.openNote} date={item.start_time ? formatDateTime(item.start_time):''} handleOpen={()=>handleOpenNote(item.examination_notes_id)}
                                       person={`${item.doctor_firstname} ${item.doctor_lastname}`} colSpan={notesHeadData.length}>
                                 {!item.displayInput ?
                                     <>
                                         {viewNotes}
                                         {/*{item.isEdit && !isPrint ? <button className='btn-sm btn small-btn sms-info-btn' onClick={()=>handleEdit(item.examination_notes_id)}>Edit notes</button> : null}*/}
                                     </>
                                     :null}
                                 {item.displayInput ? updateForm : null}
                             </NotesRow>
                         )})}
                 </PreviousNotesTable>

             </Spacer>:  null
     )
     // const editDiagnosisButton = (
     //     <RightAlignedContainer>
     //         <button type="button" onClick={handleEditDiagnosis} className='btn btn-sm small-btn sms-info-btn px-3 mb-2'>Edit</button>
     //     </RightAlignedContainer>
     //
     // )

     const diagnosisComponent = (
         patientDiagnosis?.length > 0 ?
             <Spacer>
                 {isEditDiagnosis ? <>
                         <ResultsHeading>
                             <Heading>Diagnosis</Heading>
                             <Border/>
                         </ResultsHeading>
                         <UpdateCompletedDiagnosis {...diagnosisProps}/>
                     </> :
                     <PreviousDiagnosis {...{patientDiagnosis}}
                                        // editDiagnosis={!isPrint ?  editDiagnosisButton : null}
                     />}

             </Spacer>
             : null
     )

     const doctor_list = patients.map((item, index)=>{
         const count = ((activePage - 1) * rowsPerPage) + index + 1;
         const buttons = {
             3:(
                 <>
                     <button className='btn btn-sm sms-info-btn mr-3 mb-1 ' onClick={()=>handleOpenDialog(item)}>View Details</button>
                     <button onClick={()=>handleOpenEndVisit(item)}
                             className="btn btn-sm sms-gray-btn mb-1 ">End Visit</button>
                 </>
             ),
             4: (
                 <button className='btn btn-sm sms-info-btn mr-3 mb-1 ' onClick={()=>handleOpenDialog(item)}>View Details</button>
             )
         }
         return {
            ...item,
            count,
            date: item.begin_datetime ? formatDateTime(item.begin_datetime) : item.begin_datetime,
            patient_name:`${item.first_name} ${item.last_name}`,
            assigned_to:`${item.assigned_to_first_name} ${item.assigned_to_last_name}`,
            action:buttons[item.visit_status]
         }
     })

     const {
         headCells, all_hidden, handleAllHeadCells, handleHeadCells
     } = useHiddenColumns(headData);

     const pagination = (
         <CustomTablePagination
             colSpan={3}
             activePage={activePage}
             page={page}
             rowsPerPage={rowsPerPage}
             count={total_count}
             handleChangePage={handleChangePage}
             handleChangeRowsPerPage={handleChangeRowsPerPage}
             isTotalCount
         />
     );

    return (
        <div data-testid="Patients Seen" className='journals'>
            <PageTitle title="Patients Seent"/>
            <SubHeader title="Doctor" subTitle="Patients Seen">
                <FontAwesomeIcon icon={faUserMd}/>
            </SubHeader>

            <DeleteDialog message="end" openDialog={openEnd} handleClose={handleCloseEndVisit}
                          text="visit" title='End Visit'>
                <button className=' btn sms-info-btn btn-sm' disabled={isEnding} onClick={handleEndVisit}>End Visit</button>
                <button className="btn sms-grey-btn btn-sm" onClick={handleCloseEndVisit}>No</button>
            </DeleteDialog>

            <FullScreenDialog open={openDialog} title='Visit Details' handleClose={handleCloseDialog}>
                <SelectForm {...{options, option, handleChangeOption, handlePrint:handlePrintDetails}}/>
                <div ref={componentRef}>
                    {fontSize}
                    <ReusableTreatmentHistory {...{...patientProps, clinicalNotesComponent, triageComponent,diagnosisComponent}}/>
                </div>
            </FullScreenDialog>
            <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/>
            <div className="mui-tables">
                <DateTimeFilter {...{start_date,end_date, handleChange, submitted,handleSubmit,isPending, type:'date'}}/>
                <div className='row'>
                   {version == "Premium" && 
                    <div className="col-lg-5 pl-0">
                    <Form.Group as={Row}>
                        <Col sm={2} lg={2}>
                            <Label name='Provider'/>
                        </Col>
                        <Col sm={6} lg={6}>
                            <CustomSelect value={provider} options={providers} onChange={handleChangeProvider} isClearable/>
                        </Col>
                    </Form.Group>
                </div>
                   }
                </div>

                <MainSnackbar variant={type} handleCloseBar={closeSnackbar} open={openBar} message={message}/>
                <CustomTable title="Patients Seen" customPage handleChangeNextPage={handleChangeNextPage}
                             headData={headCells} handler={handleSearch} records={10} total_count={total_count}
                             activePage={activePage} data={doctor_list} colSpan={headCells.length} term={searchValue}
                             {...{all_hidden, handleAllHeadCells, handleHeadCells, pagination}}>
                    
                        <TableBody>
                            {isLoading ? <TableRow>
                                <TableCell colSpan={headCells.length} align="center"><img src="/images/smsloader.gif"
                                                                        alt="loader"/></TableCell>
                            </TableRow>:null}
                            {isSuccess ?  doctor_list.length > 0 ? doctor_list.slice(0, rowsPerPage)
                                .map((item, index) => {

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={item.patient_number}
                                            className='mui-table-row '
                                        >
                                            {headCells.map((head)=>(
                                                <TableCell  key={head.id} hidden={head.hidden}>{item[head.id]}</TableCell>
                                            ))}
                                        </TableRow>
                                    );
                                }):<TableRow>
                                <TableCell colSpan={headCells.length} align="center">No records found</TableCell>
                            </TableRow>:null}
                            {isRejected ? <TableRow>
                                <TableCell colSpan={headCells.length} align="center">The server did not return a valid response</TableCell>
                            </TableRow> : null }
                        </TableBody>
                </CustomTable>
            </div>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientsSeen);
