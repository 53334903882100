import {useState, useEffect} from "react";
import axios from 'axios';
import { config } from "../../../Helpers/env"
import {logoutErrorMessage,errorMessages} from "../../../Helpers/ErrorMessages";

export const useAddWard = (actions) => {
    const [state, setState] = useState({ward: '', number_of_rooms: '', bed_capacity: '',
        description: '', status: '1'
    });
    const [wardens, setWardens] = useState([]);
    const [warden, setWarden] = useState('')
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted]= useState('idle')
    const [open, setOpen] = useState(false)
    const [wardDetails, setWardDetails] = useState({});
    const [openEditDialog, setOpenEditDialog] =useState(false);
    const [ward_id, setWardId] = useState('');
    const [isBlocking, setIsBlocking] = useState(false)
    const [isBlockDialog, setIsBlockDialog] = useState(false)



    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = _token.user_roles_id ? _token.user_roles_id : ''
    const in_charge = `${!_token.first_name ? "" : _token.first_name} ${!_token.last_name ? "" : _token.last_name}`;
  
    useEffect(()=>{
        if(!ward_id){
            return
        }
        const formData = new FormData();
        formData.append('ward_id', ward_id);
        axios.post(`${config.smsUrl}/cbedmanager/ward_update_form`, formData).then(res=>{
            const data = res.data;
            const dt = !data ? {} :data;
            setWardDetails(dt)
        }).catch(err=>{
            logoutErrorMessage(err, null, actions)
        })
    },[ward_id]);

    useEffect(()=>{
        if(!ward_id && wardDetails){
            return
        }
        const {name, number_of_rooms, bed_capacity,  description, in_charge,first_name, last_name} = wardDetails;
        setState( {ward:name, number_of_rooms, bed_capacity,  description, status:'1'});
        setWarden({value:in_charge, label:`${first_name ? first_name : ''} ${last_name ? last_name : ''}`})
    },[wardDetails]);

    useEffect(()=>{
        axios.get(`${config.smsUrl}/chumanresource/get_all_employees`).then(res=>{
            const data = res.data;
            const dt = !data ? [] : data;
            setWardens(dt)
        }).catch(err=>{
            logoutErrorMessage(err, null, actions)
        })
    },[])


    const handleChangeInCharge = (value) =>{
        setWarden(value)
        setIsBlocking(true)
    }

    const handleOpenDialogForm =()=>{
        setOpen(true)
    }

    const handleCloseDialogForm =()=> {
        if(isBlocking){
            setIsBlockDialog(true)
        }else{
            setOpen(false)
            setIsBlockDialog(false)
            setIsBlocking(false)
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setSubmitted(true);
       
        const formData = new FormData(event.currentTarget);
        const fieldValue = Object.fromEntries(formData.entries())
        if(ward_id){
            formData.append('ward_id', ward_id);
        }
        formData.append('in_charge', warden.value);
        formData.append('user_roles_id', user_roles_id)
        setIsBlocking(false)
        if (fieldValue['name'] && fieldValue['number_of_rooms'] && fieldValue['bed_capacity'] ) {
            setIsSubmitted('pending')
            const url = ward_id ? 'ward_update' : 'add_ward'
             axios.post(`${config.smsUrl}/cbedmanager/${url}`, formData).then(()=>{
                actions.snackbarActions.snackSuccess('Ward added successfully');
                 setIsSubmitted('resolved')
                setState({ward: '', number_of_rooms: '', bed_capacity: '',
              description: '', status: '1'})
                setSubmitted(false)
                setOpen(false)
                 setOpenEditDialog(false)
                 setWardId('')
            }).catch(err=>{
                errorMessages(err, null, actions)
                 setIsSubmitted('rejected')
            })
        }
    };

    const handleOpenEditDialog =(ward_id)=>{
        setOpenEditDialog(true);
        setWardId(ward_id)
    }

    const handleCloseEditDialog =()=>{
        setOpenEditDialog(false)
        setState({ward: '', number_of_rooms: '', bed_capacity: '',
            description: '', status: '1'})
        setWarden(null)
        setWardId('')
    }

    const handleReset = () => {
        setOpen(false)
        setIsBlockDialog(false)
        setIsBlocking(false)
        setState({ward: '', number_of_rooms: '', bed_capacity: '', description: '', status: ''})
        setWarden(null)
    };

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar();
    };

    const isPending = isSubmitted === 'pending';
    const isResolved = isSubmitted === 'resolved';
    const isRejected = isSubmitted === 'rejected';

    const blockingProps = {handleReset, isBlocking, isBlockDialog, setIsBlockDialog, setIsBlocking}

    const wardProps = {state, submitted,warden,handleChangeInCharge,
        handleSubmit, closeSnackbar, wardens,handleCloseEditDialog,
        in_charge, handleOpenDialogForm,handleCloseDialogForm,open,
        isPending, isRejected, isResolved, openEditDialog, handleOpenEditDialog,blockingProps}

    return {wardProps, isResolved}
}