import React, {useState, useEffect} from 'react';
import axios from "axios";
import { config } from "../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {sentenceCase} from "../../Users/addUser";
import {longestString} from "../PatientPreviousVisits";


export const useEditCompletedDiagnosis = (itemObj) => {
    const {actions, treatmentDetails,setTreatmentDetails, visit_id} = itemObj
    const [state, setState] = useState({ notes:'', date:'', isDisabled:false, diagnosis_id:''});
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [allCodes, setAllCodes] = useState([]);
    const [codes, setCodes] = useState([]);
    const [status, setStatus] = useState('disease');
    const [submitted, setSubmitted] = useState(false);
    const [showDiagnosis, setShowDiagnosis] = useState(false);
    const [disease, setDisease] = useState('');
    const [notesStatus, setNotesStatus] = useState('idle')
    const [error, setError] = useState(null);
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const doctor_name = `${!_token.first_name ? "" : _token.first_name} ${!_token.last_name ? "" : _token.last_name}`;
    const [diagnosisRemoved, setDiagnosisRemoved] = useState([])
    const [isNew, setIsNew] = useState(false)
    const [isEditDiagnosis, setIsEditDiagnosis] = useState(false)

    const {patientDiagnosis} = treatmentDetails

    const isResolved = isSubmitted === 'resolved'
    // useEffect(() => {
    //     if(isSuccess){
    //         return
    //     }
    //     const formData = new FormData();
    //     formData.append('visit_id', visit_id)
    //     axios.post(`${config.smsUrl}/cdoctor/get_previous_diagnosis`, formData).then(res => {
    //         const data = res.data;
    //         const dt = !data ? {} : data;
    //         const items = dt.diagnosis ? dt.diagnosis : []
    //         const id = items[items.length - 1] ? items[items.length - 1] : {};
    //         const note = id.diagnosis_id ? id.diagnosis_id : '';
    //         setPreviousDiagnosis(items);
    //         setIsDiagnosis(note)
    //     }).catch(error => {
    //         logoutErrorMessage(error, null, actions)
    //     });
    // }, [isResolved, notesStatus])


    // TO DOOOOOOOOOOOOOO
    // concatenate the strings in to one long note
    useEffect(() => {
        if(!patientDiagnosis){
            return;
        }
        const arr = patientDiagnosis.map(item => ({
            ...item, isAdded:false
        }))
        const diag_codes = arr.filter(item=>item.diagnosis_code || item.diagnosis).map(item=>({
            code:item.diagnosis_code, ...item
        }))
        const prevNotes = arr.filter(item=>item.notes).map(item => ({notes:item.notes, diagnosis_id:item.diagnosis_id}));

        const diagnosis_arr = patientDiagnosis.filter(item=>item.notes).map(item=>item.notes)
        const diag_notes = longestString(diagnosis_arr)

        const newArr = [...new Set(prevNotes)];
        const notesArr = newArr.filter(item=>Boolean(item))
        const diagnosis = notesArr ? notesArr : [];

        const id = diagnosis[notesArr.length - 1] ? diagnosis[notesArr.length - 1] : {};

        const diagnosis_id = id.diagnosis_id ? id.diagnosis_id : '';

        setState({...state, notes:diag_notes, isDisabled: false, diagnosis_id})
        setCodes(diag_codes)
    }, [patientDiagnosis]);


    const handleChangeState = (event) => {
        const {name, value} = event.target;
        setState({...state, [name]: value})
        setIsSubmitted('idle')
    };

    const handleChangeStatus = (event) => {
        setStatus(event.target.value)
        setDisease('')
        setShowDiagnosis(false)
    }



    const handleCreateNewDisease = () =>{
        setIsNew(true)
        setShowDiagnosis(false)
    }

    const retrieveAllCodes = (event) => {
        const {value} = event.target
        setDisease(event.target.value)
        if (event.target.value !== '' && !isNew) {
            const formData = new FormData();
            formData.append('code', status === 'code' ? value.toUpperCase() : '');
            formData.append('diagnosis', status === 'disease' ? sentenceCase(value) : '');
            axios.post(`${config.smsUrl}/cdoctor/auto_search_codes`, formData).then(res => {
                const data = res.data;
                const dt = !data ? [] : data;
                if (dt.length > 0) {
                    setAllCodes(dt);
                } else {
                    setAllCodes([{code: '', diagnosis:`No results found for ${value}`}])
                }

            }).catch(error => {
                logoutErrorMessage(error, null, actions)
            });
            setShowDiagnosis(true)
        } else {
            setShowDiagnosis(false)
        }
        setIsSubmitted('idle')
    };

    const getDetails = (event) => {
        const formData = new FormData();
        formData.append('code', status === 'code' ? event.value : '');
        formData.append('diagnosis', status === 'disease' ? event.innerText : '');
        if (event.value) {
            axios.post(`${config.smsUrl}/cdoctor/retrieve_icd_info`, formData).then(res => {
                const resp = res.data;
                let data = !resp ? {} : resp;
                data['code'] = event.value
                data['isAdded'] = true
                setCodes([...codes, data])
            }).catch(error => {
                logoutErrorMessage(error, null, actions)
            });
            setError(null)
        } else {
            setError('Please enter a valid disease or code')
        }
        setShowDiagnosis(false)

    };

    const handleAddNewDiagnosis = () =>{
        const obj = {code:'', diagnosis:disease, isAdded:true}
        setCodes([...codes, obj])
        setIsNew(false)
        setDisease('')
    }

    const cancelNewDiagnosis = () =>{
        setIsNew(false)
        setDisease('')
    }

    const removeRow = (idx) => {
        const arr = codes.filter((item, index ) => index !== idx)
        const removed =  codes.filter((item, index) => index === idx)
        setCodes(arr);
        setDiagnosisRemoved([...diagnosisRemoved,...removed])
    };

    const handleSubmitNotes = (event) => {
        event.preventDefault();
        const {notes, diagnosis_id} = state;

        // get the icd codes
        // get the ones with the valid code and diagnosis
        const filteredArr = codes.filter(item => item.diagnosis && item.code);


        const newDiseases = codes.filter(item=>item.code === '')

        // const diagnosisArr = filteredArr.map((item, index)=>{
        //     if (index === 0){
        //         return {...item,disease:newDiseases}
        //     }
        //     return item
        //
        // })


        const params = {
            notes_diagnosis_object: {
                diagnosis_id,
                notes
            },
            visit_id,
            diagnosis: [...filteredArr, ...newDiseases].filter(item=>!item.isAdded).map(({isAdded,...item })=>item),
            // disease:newDiseases,
            new_diagnosis:  [...filteredArr, ...newDiseases].filter(item=>item.isAdded).map(({isAdded, ...item})=>({...item})),
            removed_diagnosis: diagnosisRemoved.map(({isAdded, ...item})=>({...item})).filter(item=>item.diagnosis_id)
        }

        setSubmitted(true);
        if (notes || (filteredArr.length > 0)) {
            setIsSubmitted('pending');
            axios.post(`${config.smsUrl}/cdoctor/update_diagnosis`, params ).then(() => {
                actions.snackbarActions.snackSuccess(`Diagnosis updated successfully`)
                setIsSubmitted('resolved');
                setCodes([]);
                //  setState({notes: '', date:'', isDisabled:false, diagnosis_id:''})
                setSubmitted(false)
                setNotesStatus('idle')
                setIsEditDiagnosis(false)
            }).catch(err => {
                setIsSubmitted('rejected');
                errorMessages(err, null, actions)
            })
        }
    };

    const handleEditDiagnosis = () =>{
        setIsEditDiagnosis(true)
    }

    const handleCancelEdit =() =>{
        setIsEditDiagnosis(false)
    }

    const handleReset = () =>{
        setState({notes:'', date:'', isDisabled:false})
        setCodes([])
        setNotesStatus('success')
    }

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const isPending = isSubmitted === 'pending'
    const isResolvedDiagnosis = isSubmitted === 'resolved'




    const diagnosisProps = {
        isSubmitted, doctor_name, handleChangeState, handleSubmitNotes, closeSnackbar, state,
        allCodes, removeRow, retrieveAllCodes, showDiagnosis, getDetails, isResolved,
        status, handleChangeStatus, submitted, disease, error, handleEditDiagnosis,handleReset,
        isNew, handleCreateNewDisease, handleAddNewDiagnosis, cancelNewDiagnosis, isPending,
        codes, handleCancelEdit, isEditDiagnosis
    }

    return {
        isResolvedDiagnosis, diagnosisProps
    }
}