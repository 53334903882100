import React from "react";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PageTitle from "../../Utils/smsTitle";
import Card from "@material-ui/core/Card";
import ReactToPrint from "react-to-print";
import {useTheme} from "@material-ui/core/styles";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../../actions/snackbarActions"
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {faShippingFast} from "@fortawesome/free-solid-svg-icons/faShippingFast";
import {useViewPurchaseOrder} from "./hooks/useViewPurchaseOrder";
import {dateStringConvert} from "../../Utils/ConvertDate";
import ReceiptTemplate from "../../Utils/ReceiptTemplate";


export function ViewPurchaseOrder({match, actions, snackbars}) {
    const {
        purchaseOrder,  componentRef,
        closeSnackbar
    } = useViewPurchaseOrder(match, actions);
    const {product_list, purchaseorder} = purchaseOrder;
    const product = !product_list ? [] : product_list;
    const order = !purchaseorder ? [] : purchaseorder;
    const details = !order[0] ? {} : order[0];
    const {
        create_date, comments, ship_to_address, shipping_termes, supplier_contact,
        supplier_email, supplier_name, total,
        fob,  tax, grand_total, local_purchase_order_id
    } = details;
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    const {openBar, type, message} = snackbars;
    return (
        <div data-testid="view-purchase-order">
            <PageTitle title="Purchase Order"/>
            <SubHeader title="Inventory" subTitle="Purchase order">
                <FontAwesomeIcon icon={faShippingFast}/>
            </SubHeader>
            <section className="general-lab-report">
                <MainSnackbar variant={type} handleCloseBar={closeSnackbar} open={openBar} message={message}/>
                <div className="text-right">
                    <ReactToPrint content={() => componentRef.current}
                                  trigger={() => <button className="btn sms-amber-btn mb-2">Print</button>}/>
                </div>
                <Card className="p-2 lab-report" ref={componentRef} id="prescription-print">
                    {/*<div className="lab-report-heading">*/}
                    {/*    <HospitalAddress/>*/}
                    <ReceiptTemplate>
                        <div className={`title-background text-center ${palette.type === 'dark' && 
                        'dark-title-background'}`}
                            id="title-background">
                            <h6>Purchase Order</h6>
                        </div>
                        {/*</div>*/}
                        <table className="table table-sm table-borderless prescription-table">
                            <tr>
                                <td/>
                                <td align="right" className={palette.type === 'dark' && 'dark-theme-color'}>
                                    <p>Date: {dateStringConvert(create_date)}</p></td>
                            </tr>
                            <tr>
                                <td/>
                                <td align="right" className={palette.type === 'dark' && 'dark-theme-color'}><p>Purchase
                                    Order No: {local_purchase_order_id}</p>
                                </td>
                            </tr>
                        </table>
                        <table className="table table-sm table-borderless prescription-table">
                            <tr>
                                <td colSpan={2}
                                    className={`parameter-row ${palette.type === 'dark' && 'dark-theme-color dark-parameter-row'}`}>
                                    <p>VENDOR</p></td>
                                <td/>
                                <td/>
                                <td/>
                                <td/>
                                <td/>
                                <td/>
                                <td className={`parameter-row ${palette.type === 'dark' && 'dark-theme-color dark-parameter-row'}`}>
                                    <p>SHIP TO</p></td>
                            </tr>
                            <tr>
                                <td className={palette.type === 'dark' && 'dark-theme-color'}><p>{supplier_name}</p>
                                </td>
                                <td/>
                                <td/>
                                <td/>
                                <td/>
                                <td/>
                                <td/>
                                <td className={palette.type === 'dark' && 'dark-theme-color'}><p>{ship_to_address}</p>
                                </td>
                            </tr>
                            <tr>
                                <td className={palette.type === 'dark' && 'dark-theme-color'}><p>{supplier_contact}</p>
                                </td>
                                <td/>
                                <td/>
                                <td/>
                                <td/>
                                <td/>
                                <td/>
                                <td className={palette.type === 'dark' && 'dark-theme-color'}><p/></td>
                            </tr>
                            <tr>
                                <td className={palette.type === 'dark' && 'dark-theme-color'}><p>{supplier_email}</p>
                                </td>
                                <td/>
                                <td/>
                                <td/>
                                <td/>
                                <td/>
                                <td/>
                                <td className={palette.type === 'dark' && 'dark-theme-color'}><p/></td>
                            </tr>
                        </table>
                        <table className="table table-bordered table-sm profile-table bill-receipt-table">
                            <thead>
                            <tr className={`parameter-row ${palette.type === 'dark' && 'dark-parameter-row'}`}
                                id="parameter-row">
                                <td align="center" className={palette.type === 'dark' && 'dark-theme-color'}
                                    style={{border: 0}}><p>Requisitioner</p></td>
                                <td align="center" className={palette.type === 'dark' && 'dark-theme-color'}
                                    style={{border: 0}}><p>F.O.B</p></td>
                                <td align="center" className={palette.type === 'dark' && 'dark-theme-color'}
                                    style={{border: 0}}><p>Shipping Terms</p></td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td className={palette.type === 'dark' && 'dark-theme-color'}><p></p></td>
                                <td className={palette.type === 'dark' && 'dark-theme-color'}><p>{fob}</p></td>
                                <td className={palette.type === 'dark' && 'dark-theme-color'}><p>{shipping_termes}</p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div className="table-responsive">
                            <table className="table table-bordered table-sm profile-table bill-receipt-table">
                                <thead>
                                <tr className={`parameter-row ${palette.type === 'dark' && 'dark-parameter-row'}`}
                                    id="parameter-row">
                                    <td align="center" className={palette.type === 'dark' && 'dark-theme-color'}>
                                        <p>NO</p></td>
                                    <td align="center" className={palette.type === 'dark' && 'dark-theme-color'}>
                                        <p>Product Name/Description</p></td>
                                    <td align="center" className={palette.type === 'dark' && 'dark-theme-color'}>
                                        <p>Quantity</p></td>
                                    <td align="center" className={palette.type === 'dark' && 'dark-theme-color'}><p>Unit
                                        Price</p></td>
                                    <td align="center" className={palette.type === 'dark' && 'dark-theme-color'}>
                                        <p>Total</p></td>
                                </tr>
                                </thead>
                                <tbody>
                                {product.map((item, index) => {
                                    const count = index += 1;
                                    return (
                                        <tr key={index}>
                                            <td className={palette.type === 'dark' && 'dark-theme-color'}><p>{count}</p>
                                            </td>
                                            <td className={palette.type === 'dark' && 'dark-theme-color'}>
                                                <p>{item.product_name}</p></td>
                                            <td className={palette.type === 'dark' && 'dark-theme-color'}>
                                                <p>{item.quantity}</p></td>
                                            <td className={palette.type === 'dark' && 'dark-theme-color'}>
                                                <p>{item.rate}</p></td>
                                            <td className={palette.type === 'dark' && 'dark-theme-color'}>
                                                <p>{item.rate * item.quantity}</p></td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                                <tr>
                                    <td colSpan={4} align="right">Sub Total</td>
                                    <td>{total}</td>
                                </tr>
                                <tr>
                                    <td colSpan={4} align="right">Tax</td>
                                    <td>{tax}</td>
                                </tr>
                                <tr>
                                    <td colSpan={4} align="right">Total</td>
                                    <td>{grand_total}</td>
                                </tr>
                            </table>
                            <table className="table table-sm table-borderless prescription-table">
                                <tr>
                                    <td className={palette.type === 'dark' && 'dark-theme-color'}>
                                        <p>Comments &amp; Special Instructions</p></td>
                                    <td align="center" className={palette.type === 'dark' && 'dark-theme-color'}>
                                        <p>Signature &amp; Stamp</p></td>
                                </tr>
                                <tr>
                                    <td className={palette.type === 'dark' && 'dark-theme-color'}><p>{comments}</p></td>
                                    <td align="right" className={palette.type === 'dark' && 'dark-theme-color'}><p
                                        className={`doctor-stamp  mt-3 ${palette.type === 'dark' && 'dark-doctor-stamp'}`}
                                        id="doctor-stamp"/></td>
                                </tr>
                            </table>
                        </div>
                    </ReceiptTemplate>
                </Card>
            </section>
        </div>
    )

}

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewPurchaseOrder);
