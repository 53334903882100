import {useEffect, useRef, useState} from "react";
import axios from "axios";
import {config} from "../../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import {history} from "../../../../Helpers/history";
import {useAddUnit} from "./useAddUnit";
import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";
import {useAddMedicineType} from "./useAddMedicineType";
import {useCurrency} from "../../../../Context/currency-context";

export const  useAddProduct = (props, noRoute=false) =>{
    const {currency} = useCurrency(props)
    const {actions, product_id} = props
    const [state, setState] = useState({country_of_origin:''});
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [category_name, setCategory] = useState('');
    const [categories, setCategories] = useState([]);
    const [product_type, setProductType] = useState('');
    const [productTypes, setProductTypes] = useState([]);
    const [unit_name, setUnit] = useState('');
    const [units, setUnits] = useState([]);
    const [product, setProduct] = useState({});
    const [isCategory, setIsCategory] = useState(false);
    const [openProductDialog,setOpenProductDialog] = useState(false)
    const [isBlocking, setIsBlocking] = useState(false)
    const {renderAddUnit, handleOpenDialog, isResolved:isResolvedUnit} = useAddUnit(actions)
    const {renderAddMedicineType, isResolved:isResolvedMedicineType, handleOpenDialog:handleOpenMedicineType} = useAddMedicineType(actions)

    const formRef = useRef()



    // Have to register the languages you want to use
    countries.registerLocale(enLocale);
  
    // Returns an object not a list
    const countryObj = countries.getNames("en", { select: "official" });
  
    // if we need to use country codes, we can, just uncomment the key
    const countryArr = Object.entries(countryObj).map(([key, value]) => {
      return {
        label: value,
        value: key
      };
    });

    useEffect(() => {
        axios.get(`${config.smsUrl}/cpharmacy/manage_category`).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const cats = !dt.category_list ? [] : dt.category_list;
            const prod_cat =  cats.map(item=>({value:item.product_category_id,label:item.category_name}))
            setCategories(prod_cat);
        }).catch((err) => {
            logoutErrorMessage(err, null, actions)
        });
    }, []);

    useEffect(() => {
        axios.get(`${config.smsUrl}/cpharmacy/type_list`).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const all_types = !dt.type_list ? [] : dt.type_list;
            const types = all_types.map(item=> ({value:item.product_type_id,label:item.type_name}))
            setProductTypes(types);
        }).catch((err) => {
            logoutErrorMessage(err, null, actions)
        });
    }, [isResolvedMedicineType]);

    useEffect(() => {
        axios.get(`${config.smsUrl}/cpharmacy/unit_list`).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const all_units = !dt.unit ? [] : dt.unit;
            const units = all_units.map(item=>
                ({value:item.unit_id,label:item.name}))
            setUnits(units);
        }).catch((err) => {
            logoutErrorMessage(err, null, actions)
        })
    }, [isResolvedUnit]);

    useEffect(()=>{
        if(!product_id){
            return
        }
        const formData = new FormData();
        formData.append('product_id',product_id);
        axios.post(`${config.smsUrl}/cpharmacy/medicine_update_form`,formData).then(res=>{
            const data = res.data;
            const dt = !data ? {} : data;
            // console.log(dt)
            setProduct(dt);
        }).catch(err=>{
            logoutErrorMessage(err,null,actions)
        })

    },[product_id])

    useEffect(()=>{
        if (!product_id && product){
            return
        }
        const {country_of_origin, product_type_id, product_type_name,product_category_id,
            category_name,unit_id, unit_name,reorder_level}=product
        let new_country_of_origin = '';
        Object.entries(countryObj).forEach(([k,v]) => {
            if (v === country_of_origin) { new_country_of_origin = k;}
        })

        setState({...product, 'country_of_origin' : new_country_of_origin,'reorder_level' : reorder_level})
        
        setProductType({label:product_type_name,value:product_type_id});
        setCategory({label:category_name,value:product_category_id});
        setUnit({label:unit_name,value:unit_id})
    },[product])


    const handleChange = (event) =>{
        const {name,value} = event.target;
        if (category_name){
            setIsCategory(false)
        }else {
            setIsCategory(true)
        }
        setState({...state,[name]:value})
        setIsBlocking(true)
    }

    const handleChangeCategory = (value) =>{
        const val = value !== null ? value : '';
        setCategory(val)
        setIsCategory(false)
        setIsBlocking(true)
    }

    const handleChangeType = (value) =>{
        setProductType(value)
        setIsBlocking(true)
    }

    const handleChangeUnit = (value) =>{
        setUnit(value)
        setIsBlocking(true)
    }

    const handleSubmit = (event) =>{
        event.preventDefault();
        const {country_of_origin} = state;

        const form = new FormData(event.currentTarget) //this gets the name and value pair of the inputs

        const formData = new FormData();
        const fieldValues = Object.fromEntries(form.entries())
      
        if (product_id){
            formData.append('product_id',product_id)
        }

        for (let field in fieldValues){
            if (field !== 'country_of_origin'){
                if(field === 'reorder_level'){
                    formData.append(field,fieldValues[field] === '' ? 0 : fieldValues[field])
                }
                else {
                    formData.append(field,fieldValues[field])
                }
        
            }

        }
        formData.append('product_category_id',category_name?.value)
        formData.append('product_type_id',!product_type.value ? 0 : product_type.value);
        formData.append('unit_id',!unit_name.value ? '' : unit_name.value);
        formData.append('image','')
        formData.append('currency_id',currency?.id)
        formData.append('country_of_origin',!country_of_origin ? "" : countryObj[country_of_origin])
        // formData.append('reorder_level',!reorder_level ? 0 : reorder_level)
        setSubmitted(true)
        setIsBlocking(false)
        const url = product_id ? 'medicine_update' : 'add_medicine'
        // const isRequired = fieldValues['product_name'] && product_type && unit_name && category_name && fieldValues['price']
        if (fieldValues['product_name'] && unit_name && category_name && fieldValues['price']){
            setIsSubmitted('pending')
            axios.post(`${config.smsUrl}/cpharmacy/${url}`,formData).then(()=>{
                actions.snackbarActions.snackSuccess('Product added successfully');
                setIsSubmitted('resolved');
                if(!noRoute){
                    history.push('/productlist');
                }else{
                    setOpenProductDialog(false)
                }
                setState({country_of_origin:''})
                setProductType('');
                setUnit('')
                setSubmitted(false)
            }).catch(err=>{
                setIsSubmitted('rejected');
                errorMessages(err,null,actions);
            })
        }

    }
    
    const handleCloseSnackbar = () =>{
        actions.snackbarActions.hideSnackbar()
    }
    const isPending = isSubmitted === 'pending';
    const isResolved = isSubmitted === 'resolved';
    const isRejected = isSubmitted === 'rejected'


    const productProps = {handleSubmit, productTypes,handleChangeType, product_type,units, handleChangeUnit,unit_name,
        state, handleChange, submitted, categories, category_name, handleChangeCategory,
         isSubmitted,isCategory, isPending,isRejected, isResolved,
         countryArr, formRef,isBlocking, setIsBlocking
    }

    return {productProps, handleCloseSnackbar,openProductDialog, setOpenProductDialog, renderAddUnit, handleOpenDialog,
    renderAddMedicineType, handleOpenMedicineType}
}