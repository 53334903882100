import {useEffect, useRef, useState} from "react";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import {dateTimeConvert} from "../../../Utils/ConvertDate";
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {history} from "../../../../Helpers/history";

const retrieveAdmission = (formData) =>{
    return axios.post(`${config.smsUrl}/cadmission/admission_update_form`, formData)
};
export const useEditAdmission = (props) =>{
    const form = useRef();
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;
    const {patient_admission_id} = props.match.params;
    const [p_number, setPatientNumber] = useState(patient_admission_id);
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [admission, setAdmission]= useState({});
    const [state, setState] = useState({admission_date: '', first_name: "", last_name: "",
        relationship: '', contact: '', address: '', nin: ''});


    useEffect(() =>{
        const {actions} = props;
        const formData = new FormData();
        formData.append('patient_admission_id',patient_admission_id);
        retrieveAdmission(formData).then(res=>{
            const data = res.data;
            const dt = !data ? {} : data;
            setAdmission(dt);
        }).catch(err=>{
            logoutErrorMessage(err,null, actions)
        })
    },[]);


    useEffect(()=>{
        const {patient_number, name, phone_no, address, relationship, nin, admission_date} = admission;
        setState({admission_date: dateTimeConvert(admission_date), first_name: name, relationship: relationship, contact: phone_no,
            address: address, nin: nin});
        setPatientNumber(patient_number)
    },[admission]);


    const handleChange = event => {
        const {name, value} = event.target;
        setState({...state, [name]: value})
    };


    const handleSubmit = event => {
        event.preventDefault();
        const {actions} = props;
        const {admission_date, first_name,  relationship, contact, address, nin} = state;
        const formData = new FormData();
        formData.append('patient_admission_id', patient_admission_id);
        formData.append('patient_number', p_number);
        formData.append('first_name', first_name);
        formData.append('relationship', relationship);
        formData.append('phone_no', contact);
        formData.append('address', address);
        formData.append('nin', nin);
        formData.append('user_roles_id', user_roles_id);
        setSubmitted(true);
        if (p_number && admission_date) {
            setIsSubmitted(true);
            axios.post(`${config.smsUrl}/cadmission/update_admission`, formData).then(() => {
                setIsSubmitted(false);
                actions.snackbarActions.snackSuccess("Patient admission registered successfully")
                history.push('/admissionlist')
            }).catch(err => {
                setIsSubmitted(false);
                errorMessages(err, null, actions)
            })
        }
    };

    const closeSnackbar = () => {
        const {actions} = props;
        actions.snackbarActions.hideSnackbar();
    };

    return {state, p_number,  submitted, isSubmitted, form, handleChange,
         handleSubmit, closeSnackbar,admission,setPatientNumber,userObj, token, _token, user_roles_id}
}