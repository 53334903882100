import React from "react";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CustomTable from "../../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import {getComparator, stableSort} from "../../Utils/Tables/sortingFunctions";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import *  as snackbarActions from "../../../actions/snackbarActions";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {faBookMedical} from "@fortawesome/free-solid-svg-icons/faBookMedical";
import {MainSearchComponent} from "../MainSearch";
import Card from '@material-ui/core/Card'
import {CustomSelect} from "../../Utils/FormInputs/SelectInput";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FilterResults from "react-filter-search";
import CustomizedPieChart from "../../Utils/Charts/CustomPieChart";
import TextField from "../../Utils/FormInputs/TextField";
import {useMedicineUse} from "./CustomHooks/useMedicineUse";
import SmsLineChart from "../../Main/Charts/LineChart";
import {Cell,  Line, Bar, Legend, XAxis, YAxis} from "recharts";
import CustomBarChart from "../../Utils/Charts/CustomBarChart";

const data = [
    {name: "General Medicine", value: 40},
    {name: "Labs", value: 30},
    {name: "Scans & Radiology", value: 30},
];
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];


const MedicineUse = (props) => {
    const {
        department_id, handleChangeDepartment, closeSnackbar, search_type, handleSubmit, chips,
        handleDeleteChip, text, textType, year, date, handleChangeSearch, sortBy, chartType, displayChart,
        handleChangeDate, handleChangeYear, handleIncrementYear, statistics, isSubmitted, submitted,
        searchValue, order, orderBy, loading, product, showProduct, products, displayText, showSort,
        tableData, departments, page, rowsPerPage, handleSearch, handleRequestSort, handleChangePage,
        handleChangeRowsPerPage, handleChangeSortBy, autoSearchProduct, selectProduct, handleChangeChart,
        activeIndex, onPieEnter
    } = useMedicineUse(props)
    const {product_name} = product
    const {start_date, end_date} = date;
    const {openBar, type, message} = props.snackbars;
    const label = search_type === '2' ? "Months" : 'Dates';
    const lb = sortBy.label === 'Department' ? 'Department' : 'Ward'
    const headData = [
        {id: 'sl', numeric: false, disablePadding: false, label: 'Sl'},
        {id: 'department', numeric: false, disablePadding: false, label: lb},
        {id: 'total', numeric: false, disablePadding: false, label: 'Quantity'}
    ];
    return (
        <div>
            <PageTitle title="Inventory Reports"/>
            <SubHeader title="Reports" subTitle="Medicine Use per Department ">
                <FontAwesomeIcon icon={faBookMedical}/>
            </SubHeader>
            <div className="mui-tables">
                <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>
                <div className="text-center">
                    <MainSearchComponent text={text} type={textType} changeDate={handleChangeDate}
                                         handleSubmit={handleSubmit} isSubmitted={isSubmitted}
                                         changePeriod={handleChangeSearch} end_date={end_date}
                                         handleChangeValue={handleChangeYear} submitted={submitted}
                                         search_type={search_type} handleChangeYear={handleIncrementYear}
                                         start_date={start_date} year={year}/>
                </div>

                {/*<div className="row">*/}
                {/*    <div className="col-md-9">*/}
                <div className="row col-md-12">
                    <div className="col-md-4">
                        <Form.Group as={Row} className="medicine-use-group">
                            <Form.Label style={{marginTop: '.5rem'}} sm={4}>Search Product:</Form.Label>
                            <Col sm={7}>
                                <TextField value={product_name} onChange={autoSearchProduct} type="text"/>
                                {showProduct &&
                                <Form.Control as="select" className="drug-ul prescription-ul" multiple>
                                    {products.map((val, idx) => (
                                        <option key={idx} value={val.value} className="drug-li "
                                                onClick={() => selectProduct({
                                                    value: val.value,
                                                    label: val.label
                                                })} data-testid="product_drop_down">{val.label}</option>
                                    ))}
                                </Form.Control>}
                            </Col>
                        </Form.Group>
                    </div>
                    <div className="col-md-4">
                        {/*{showSort && */}
                        <Form.Group as={Row} className="medicine-use-group">
                            <Form.Label style={{marginTop: '.5rem'}} sm={4}>Sort By:</Form.Label>
                            <Col sm={8}>
                                <CustomSelect options={[{value: 1, label: 'Department'},
                                    {value: 2, label: 'Ward'}]} value={sortBy} onChange={handleChangeSortBy}/>
                            </Col>
                        </Form.Group>
                        {/*}*/}
                    </div>
                    <div className="col-md-4">
                        {/*{showSort && */}
                        <Form.Group as={Row} className="medicine-use-group">
                            <Form.Label style={{marginTop: '.5rem'}} sm={4}>Department:</Form.Label>
                            <Col sm={8}>
                                <CustomSelect options={departments} onChange={handleChangeDepartment}
                                              value={department_id}/>
                            </Col>
                        </Form.Group>
                        {/*// }*/}
                    </div>

                </div>
                {/*    </div>*/}
                {/*</div>*/}
                <div className="row mr-0">
                    <div className="col-md-6">
                        <Card className="p-3 general-card mb-2">
                            <div className="row">
                                <div className="col-md-8">
                                    <h6>{product_name} use statistics per {sortBy.label}</h6>
                                </div>
                                <div className="col-md-4">
                                    {(displayChart && chips.length > 0) && <div>
                                        <button
                                            className={` ${chartType === 1 && 'active-pie-btn'} btn btn-sm pie-btn l-btn  py-1`}
                                            onClick={() => handleChangeChart(1)}>Line
                                        </button>
                                        <button
                                            className={` ${chartType === 2 && 'active-pie-btn'} btn btn-sm pie-btn r-btn  py-1`}
                                            onClick={() => handleChangeChart(2)}>Bar
                                        </button>
                                    </div>}
                                </div>
                            </div>
                            {/*{(statistics.length && chartType === 1) > 0 ?*/}
                            {(!department_id || chips.length <= 0) ?
                                <CustomizedPieChart data={data} activeIndex={activeIndex} onPieEnter={onPieEnter}>
                                    {data.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>
                                    ))}
                                </CustomizedPieChart> : <div>
                                    {chartType === 1 && <SmsLineChart data={statistics}  dataKey="date"
                                                                      label={{
                                                                          value: 'Quantity',
                                                                          angle: -90,
                                                                          position: 'insideLeft'
                                                                      }}
                                                                      height={350}
                                                                      xlabel={{
                                                                          value: label,
                                                                          position: 'bottom',
                                                                          offset: 0
                                                                      }}>
                                        <Legend verticalAlign="top" height={40} align="right" onClick={handleDeleteChip}/>
                                        {chips.length > 0 &&
                                        chips.map((item) => {
                                            return (
                                                <Line key={`line_${item.value}`} type="monotoneX"
                                                      dataKey={`value_${item.value}`} name={item.label}
                                                      stroke={item.color} activeDot={{r: 5}}/>)
                                        })}
                                    </SmsLineChart>}
                                    {chartType === 2 && <CustomBarChart data={statistics} height={300}>
                                        <Legend verticalAlign="top" height={40} align="right" onClick={handleDeleteChip}/>
                                        <XAxis dataKey="date" allowDecimals={false}
                                               label={{value: label, position: 'bottom', offset: -10}}/>
                                        <YAxis label={{
                                            value: 'Quantity',
                                            angle: -90,
                                            position: 'insideLeft'
                                        }}/>
                                        {chips.length > 0 &&
                                        chips.map((item) => {
                                            return (
                                                <Bar key={`bar_${item.value}`}
                                                     dataKey={`value_${item.value}`} name={item.label}
                                                     fill={item.color}/>
                                            )
                                        })
                                        }
                                    </CustomBarChart>}
                                </div>}
                            {/*: <div className="text-center">{displayText}</div>}*/}

                        </Card>
                    </div>
                    <div className="col-md-6">
                        <CustomTable title="" term={searchValue} handler={handleSearch}
                                     handleChangePage={handleChangePage}
                                     handleChangeRowsPerPage={handleChangeRowsPerPage}
                                     page={page} rowsPerPage={rowsPerPage} headData={headData}
                                     handleRequestSort={handleRequestSort} data={tableData} colSpan={5} order={order}
                                     orderBy={orderBy} filter fewRows>
                            {loading ? <TableBody><TableRow>
                                <TableCell colSpan="8" align="center"><img src="/images/smsloader.gif"
                                                                           alt="loader"/></TableCell>
                            </TableRow></TableBody> : tableData.length > 0 ?
                                <FilterResults value={searchValue} data={tableData}
                                               renderResults={results => (
                                                   <TableBody>
                                                       {stableSort(results, getComparator(order, orderBy))
                                                           .slice(0, 10)
                                                           .map((item, index) => {
                                                               const count = (page * rowsPerPage) + index + 1;
                                                               return (
                                                                   <TableRow
                                                                       hover
                                                                       role="checkbox"
                                                                       tabIndex={-1}
                                                                       key={index}
                                                                       className='mui-table-row'>
                                                                        <TableCell>{count}</TableCell>
                                                                       <TableCell>{item.name}</TableCell>
                                                                       <TableCell>{item.value}</TableCell>
                                                                   </TableRow>
                                                               );
                                                           })}
                                                   </TableBody>)}/> : <TableBody><TableRow>
                                    <TableCell colSpan="8" align="center">No records found</TableCell>
                                </TableRow></TableBody>}
                        </CustomTable>
                    </div>
                </div>
            </div>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(MedicineUse);
