import {useEffect, useState} from "react";
import {dateTimeConvert} from "../../Utils/ConvertDate";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {history} from "../../../Helpers/history";
import { useLabReport } from "./useLabReport";

export const useEnterResults = (actions) => {
    const url = {tableUrl:'retrieve_collected_samples', exportUrl:'retrieve_collected_samples'};
    const [resultState, setResultState] = useState({date: dateTimeConvert(), report: '', observations: ''});
    const [patient, setPatient] = useState({});
    const [parameters, setParameters] = useState([]);
    const [patientDetails, setPatientDetails] = useState({});
    const [tests, setTests] = useState([]);
    const [submittedResult, setSubmittedResult] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle')
    const [result, setResult] = useState('');
    const [q_type, setQtype] = useState('')
    const [sample_type, setSampleType] = useState('')
    const [sampleTypes, setSampleTypes] = useState([]);
    const [approvedBy,setApprovedBy] = useState('')
    const [users, setUsers] = useState([])
    const [labTests, setLabTests] = useState([])
    const [openDialog, setOpenDialog] = useState(false)
    const [openPrint, setOpenPrint] = useState(false);
    const [loading, setLoading] = useState(true);
    const [ids, setIds] = useState({ visit_id:'', patient_type:'',labrequest_id:'', activity_id:'',approvedBy_id:''})
    const [test_id, setTestId] = useState({sample_id:'',labrequests_detail_id:''})
    const isFetching = isSubmitted === 'pending';
    const isFetchedSuccess = isSubmitted === 'resolved';
    const isFetchedError = isSubmitted === 'rejected';
    const  {searchValue, order, orderBy,  activePage, total_count, reports,
        handleSearch, handleRequestSort, handleChangeNextPage,closeSnackbar,
        handleClickSample, isError,isLoading, isSuccess, state, submitted, isPending, handleChange, handleSubmit
    } = useLabReport(actions,url,isFetchedSuccess)
    const [status, setStatus] = useState('idle')
    const [isBlocking, setIsBlocking] = useState(false)
    const [isBlockDialog, setIsBlockDialog] = useState(false)


    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const technician = `${!_token.first_name ? "" : _token.first_name} ${!_token.last_name ? "" : _token.last_name}`;
    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;
    const department_id = !_token.department_id ? "" : _token.department_id;
    const {visit_id, patient_type,labrequest_id, activity_id} = ids;

    useEffect(() => {
        if(!visit_id){
            return
        }
        const formData = new FormData();
        formData.append('visit_id', visit_id);
        axios.post(`${config.smsUrl}/claboratory/get_collected_sample_details_by_id`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const pt = !dt.patient_info ? {} : dt.patient_info;
            const param = !dt.params ? [] : dt.params;
            setPatientDetails(pt);
            // setTests(param)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, [visit_id]);

    useEffect(() => {
        if(!(visit_id && labrequest_id)){
            return
        }
        const formData = new FormData();
        formData.append('visit_id', visit_id);
        formData.append('labrequest_id', labrequest_id);
        axios.post(`${config.smsUrl}/claboratory/get_samples_saved_per_lab_request_by_id`,formData).then(res => {
            const data = res.data;
            const dt = !data ? [] : data;
            const samArr = dt.params ? dt.params : [];
            const arr = samArr.map(item => ({
                value: item.sample_type,
                label: item.type_name
            }))
            setSampleTypes(arr);
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, [visit_id, labrequest_id]);

    useEffect(() => {
        setLoading(true);
        const formData = new FormData()
        formData.append('department_id',department_id)
        axios.post(`${config.smsUrl}/permission/return_department_users`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const list = !dt.department_users ? [] : dt.department_users;
            const arr = list.map(item => ({
                value: item.user_roles_id,
                label: `${item.first_name} ${item.last_name}`
              }));
            setUsers(arr);
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        })
    },[visit_id, labrequest_id]);


    useEffect(() => {
        setPatient({
            ...patientDetails,
            patient_number: patientDetails.patient_number,
            patient_name: `${!patientDetails.patient_firstname ? "" : patientDetails.patient_firstname} 
            ${!patientDetails.patient_lastname ? "" : patientDetails.patient_lastname}`,
            dob: patientDetails.age,
            gender: patientDetails.gender,
            result_type: patientDetails.result_type ? patientDetails.result_type : '',
            bill_no: patientDetails.transaction_id,
            sample_no: patientDetails.sample_id,
            sample_description: patientDetails.sample_description,
            lab_test: patientDetails.hospital_test_name,
            drawn_date: patientDetails.time_taken,
            patient_type:patientDetails.patient_type,
            drawn_by: `${!patientDetails.tech_firstname ? "" : patientDetails.tech_firstname} ${!patientDetails.tech_lastname ? "" : patientDetails.tech_lastname}`
        });
    }, [patientDetails, tests]);

    const handleChangeSampleType = (value) =>{
        setSampleType(value)
        const {patient_type} = patient;
        const formData = new FormData();
        formData.append('visit_id', visit_id);
        formData.append('sample_type_id', value.value);
        formData.append('patient_type', patient_type);
        axios.post(`${config.smsUrl}/claboratory/get_sample_type_tests`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const param = !dt.lab_tests ? [] : dt.lab_tests;
            const arr = param.map(item=>({
                ...item, isSelected:false
            }))
            setLabTests(arr)
            setStatus('sample_selected')
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
        setIsBlocking(true)
    }

    const handleChangeApprovedBy = (value) => {
        setApprovedBy(value);
        setIsBlocking(true); 
      }
 

    const handleSelectTest = (hospital_test_id, index) => {
        const arr = labTests.map((item, idx)=>{
            if(idx ===  index){
                const formData = new FormData();
                formData.append('hospital_test_id', hospital_test_id);
                    axios.post(`${config.smsUrl}/claboratory/get_labtests_parameters`, formData).then(res => {
                        const data = res.data;
                        const dt = !data ? [] : data;
                        const paramsArr = dt.map(item=>({
                            ...item,test_value:''
                        }))
                        setParameters(paramsArr)
                        setStatus('test_selected')
                    }).catch(err => {
                        logoutErrorMessage(err, null, actions)
                    })
                return {...item, isSelected:!item.isSelected}
            }else{
                return {...item, isSelected:false}
            }
        })
        const selectedTest = arr.filter(item=>item.isSelected);
        const [test] = selectedTest;
        const obj = test ? test : {}
        const sample = obj.sample_id ? obj.sample_id : '';
        const test_detail_id = obj.labrequests_detail_id ? obj.labrequests_detail_id : '';
        setTestId({sample_id:sample,labrequests_detail_id:test_detail_id})
        setLabTests(arr)
        setIsBlocking(true)
      
    };

    
    const handleOpenReceipt = (e) => {
        e.preventDefault();
        const arr = parameters.length > 0 && parameters.every(test=>test.hospital_test_detail_id && test.service_id && (test.test_value.value || test.test_value))
        if (arr && sample_type){
            setOpenPrint(true);
        }else {
            setSubmittedResult(true)
        }
    }
    

    const handleChangeResult = (event, idx) => {
        const arr = parameters.map((item, index)=>{
            if(index === idx){
                return {...item,test_value:event.target.value}
            }
            return item
        })
        setParameters(arr)
        setIsBlocking(true)
    };

    const handleChangeResultState = (event) => {
        const {value, name} = event.target;
        setResultState({...resultState, [name]: value})
        setIsBlocking(true)
    };

    const handleChangeQualitative = (val, idx) => {
        const arr = parameters.map((item, index)=>{
            if(index === idx){
                return {...item,test_value:val}
            }
            return item
        })
        setParameters(arr)
        setIsBlocking(true)
    }

    const handleCloseResult = () => {
        setOpenPrint(false);
    };


    const handleOpenDialog = (item) =>{
        const {visit_id, patient_type, labrequest_id, activity_id:id,approvedBy_id,user_roles_id} = item;
        setIds({visit_id, patient_type,labrequest_id, activity_id: id,approvedBy_id,user_roles_id})
        setOpenDialog(true)
    }

    const handleCloseDialog = () =>{
        if(isBlocking){
            setIsBlockDialog(true)
        }else{
            setIsBlockDialog(false)
            setIsBlocking(false)
            setOpenDialog(false)
            setParameters([])
            setStatus('idle')
            setLabTests([])
            setSampleType('')
            setApprovedBy('')
            setResultState({...resultState,report:''})
        }

    }

    const handleSubmitResult = (event) => {
        event.preventDefault();
        const {report} = resultState;
        const {patient_type} = patient
        const formData = new FormData();
        const {result_type} = patient;
        const {labrequest_id,approvedBy_id} = ids
        const {sample_id, labrequests_detail_id} = test_id;
        
        const obj = parameters[0] ? parameters[0] : {};
        formData.append('visit_id',visit_id);
        formData.append('labrequest_id',labrequest_id);
        formData.append('activity_id',activity_id);
        formData.append('labrequests_detail_id',labrequests_detail_id)
        formData.append('user_roles_id', user_roles_id);
        // formData.append('approvedBy_id', approvedBy.value);
        formData.append('approvedBy', approvedBy.label);
        formData.append('result_type', result_type);
        formData.append('notes', report ? report : '');
        formData.append('sample_id', sample_id);
        formData.append('patient_type', patient_type);
        formData.append('lab_state', 3);
        formData.append('is_external', obj.is_external);
        setIsBlocking(false)
        parameters.filter(test=>test.hospital_test_detail_id).forEach(item => {
            const isReactive = item.sub_test_name === 'reactive/non-reactive';
            const isBlood  = item.sub_test_name === 'blood_group';
            const isPositive = item.sub_test_name === '+ve/-ve';
            const value = (isBlood || isReactive || isPositive) ? item.test_value.value : item.test_value;
            formData.append('test_value', value);
            formData.append('service_id', item.service_id);
            formData.append('hospital_test_detail_id', item.hospital_test_detail_id);
        });
        setSubmittedResult(true);
        const arr = parameters.length > 0 && parameters.every(test=>test.hospital_test_detail_id && test.service_id && (test.test_value.value || test.test_value))
        if (arr && sample_type){
            setIsSubmitted('pending')
            axios.post(`${config.smsUrl}/claboratory/save_lab_results`, formData).then(() => {
                actions.snackbarActions.snackSuccess('Lab results saved successfully');
                history.push('/pendingsamples')
                setIsSubmitted('resolved');
                setSubmittedResult(false)
                setOpenPrint(false)
                setParameters([])
                handleChangeSampleType(sample_type)
                setResultState({...resultState,report:''})
                // setSampleType('')
            }).catch(err => {
                errorMessages(err, null, actions)
                setIsSubmitted('rejected');
            })
        }

    };

    const handleResetForm = () =>{
        setIsBlockDialog(false)
        setIsBlocking(false)
        setOpenDialog(false)
        setParameters([])
        setStatus('idle')
        setLabTests([])
        setSampleType('')
        setApprovedBy('')
        setResultState({...resultState,report:''})
    }

    const isTestIdle = status === 'idle';
    const isSampleSelected = status === 'sample_selected';
    const isTestSelected = status === 'test_selected'


    const resultProps = {
        resultState, patient, parameters, patientDetails, tests, submittedResult,handleOpenReceipt,
        patient_type, technician, handleChangeResult,q_type,isSubmitted,handleCloseResult,openPrint,
        handleChangeResultState, handleSubmitResult, closeSnackbar, result, handleChangeQualitative,
        sampleTypes, sample_type, handleChangeSampleType, handleSelectTest, isFetchedError, isFetchedSuccess,
        isFetching, labTests, isTestIdle,isSampleSelected, isTestSelected,isBlockDialog, isBlocking,
        setIsBlockDialog, setIsBlocking, handleResetForm,users,approvedBy,handleChangeApprovedBy,
        visit_id
    }

    return {resultProps,openDialog, handleOpenDialog, handleCloseDialog,searchValue, order, orderBy,
        activePage, total_count, reports,handleSearch, handleRequestSort, handleChangeNextPage,closeSnackbar,
        handleClickSample, isError,isLoading, isSuccess, state, submitted, isPending, handleChange, handleSubmit}
}