import {useEffect, useState} from "react";
import {dateTimeConvert} from "../../Utils/ConvertDate";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import { useLabReport } from "./useLabReport";

export const usePendingLabRequests = (actions) => {
    const url = {tableUrl:'get_all_pending_lab_requests', exportUrl:'get_all_pending_lab_requests'};

    const [sampleState, setSampleState] = useState({drawn_date: dateTimeConvert()});
    const [patient, setPatient] = useState({
        patient_id: '', patient_name: '', age: '',
        gender: '', bill_no: '', payment_status: '', clinic: '', requested_by: '', lab_test: '',
        lab: '', time_requested: '', patient_type:''
    });
    const [patientDetails, setPatientDetails] = useState({});
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [submittedSample, setSubmittedSample] = useState(false)
    const [openSampleNo, setOpenSampleNo] = useState(false);
    const [sample_no, setSampleNo] = useState([]);
    const [sampleTypes, setSampleTypes] = useState([]);
    const [labTests, setLabTests] = useState([])
    const [openDialog, setOpenDialog] = useState(false)
    const [ids, setIds] = useState({labrequest_id:'', visit_id:'', current_date:'', activity_id:''})
    const [isBlocking, setIsBlocking] = useState(false)
    const [isBlockDialog, setIsBlockDialog] = useState(false)
    const isFetching = isSubmitted === 'pending';
    const isFetchedSuccess = isSubmitted === 'resolved';
    const isFetchedError = isSubmitted === 'rejected';
    const  {
        searchValue, order, orderBy, loading, activePage, total_count, reports, handleClickSample,
        handleSearch, handleRequestSort, handleChangeNextPage,  handleClickRow,
        retrieveAllRecords, csvRef, downloadCsv,allRecords, isLoading, isSuccess, isError, state,submitted,
        handleChange, handleSubmit, isPending, isRejected, isResolved
    } = useLabReport(actions,url,isFetchedSuccess)

    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const technician = `${!_token.first_name ? "" : _token.first_name} ${!_token.last_name ? "" : _token.last_name}`;
    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;

    const {labrequest_id, current_date, activity_id, visit_id} = ids;

    const [lab_queue, setLabQueue] = useState(0);
    useEffect(() => {
        (async ()=>{
            try {
                const formData = new FormData();
                formData.append('search', '');
                axios.post(`${config.smsUrl}/claboratory/lab_queue/${0}`, formData).then(res=>{
                    const data = res.data;
                    const dt = !data ? {} : data;
                    const count = !dt.total_count ? 0 : dt.total_count;
                    setLabQueue(count);
                }).catch(err=>{
                    
                })
            } catch (error) {
                // errorMessages(error, null, actions)
            }
        })()
    }, [])

    useEffect(() => {
        axios.get(`${config.smsUrl}/claboratory/get_all_sample_types`).then(res => {
            const data = res.data;
            const dt = !data ? [] : data;
            setSampleTypes(dt);
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, []);

    useEffect(() => {
        if(!labrequest_id){
            return;
        }
        const formData = new FormData();
        formData.append('labrequest_id', labrequest_id);
        axios.post(`${config.smsUrl}/claboratory/get_patient_info`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            setPatientDetails(dt);
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, [labrequest_id]);

    useEffect(() => {
        const tests  = patientDetails.lab_info ? patientDetails.lab_info : []
        const labs = tests.map(item=>({
            ...item,
            sample_type:'',
            sample_description:'',
            add_to_invoice: false
        }))
        const patientInfo = patientDetails.patient_info ? patientDetails.patient_info : {}
        setPatient({
            patient_id: patientInfo.patient_number,
            patient_name: `${!patientInfo.patient_firstname ? "" : patientInfo.patient_firstname} 
            ${!patientInfo.patient_lastname ? "" : patientInfo.patient_lastname}`,
            age: patientInfo.age,
            gender: patientInfo.gender,
            bill_no: patientInfo.transaction_id,
            clinic: patientInfo.department_name,
            requested_by: `${!patientInfo.doctor_firstname ? "" : patientInfo.doctor_firstname} 
            ${!patientInfo.doctor_lastname ? "" : patientInfo.doctor_lastname}`,
            lab_test: patientInfo.hospital_test_name,
            time_requested: patientInfo.time_requested,
            patient_type : patientInfo.patient_type
        })
        setLabTests(labs)
    }, [patientDetails]);

    const handleChangeSampleTypes = (value, _id) => {
        const arr = labTests.map(item=>{
            if(item.labrequests_detail_id === _id){
                return {...item, sample_type:value}
            }
            return item
        })
        setLabTests(arr)
        setIsBlocking(true)
    };

    const handleChangeSampleDescription = (event, _id) => {
        const arr = labTests.map(item=>{
            if(item.labrequests_detail_id === _id){
                return {...item, sample_description:event.target.value}
            }
            return item
        })
        setLabTests(arr)
        setIsBlocking(true)
    };

    const handleChangeAddToInvoice = (event, _id) => {
        const arr = labTests.map(item=>{
            if(item.labrequests_detail_id === _id){
                return {...item, add_to_invoice: event.target.checked}
            }
            return item
        })
        setLabTests(arr)
        setIsBlocking(true)
    };



    const handleChangeSampleState = (event) => {
        const {name, value} = event.target;
        setSampleState({...sampleState, [name]: value})
        setIsBlocking(true)
    };


    const closeSampleNo = () => {
        setOpenSampleNo(false);
        setOpenDialog(false)
    };

    const handleOpenDialog = (item) =>{
        const {labrequest_id, current_date, visit_id, activity_id:id} = item;
        setIds({labrequest_id, current_date, visit_id,activity_id:id})
        setOpenDialog(true)
    }

    const handleCloseDialog = () =>{
        if(isBlocking){
            setIsBlockDialog(true)
            setOpenDialog(false)
        }else{
            setOpenDialog(false)
            setSampleNo([])
            setIsBlockDialog(false)
            setIsBlocking(false)
        }

    }


    const handleSubmitSample = (event) => {
        event.preventDefault();
        const {patient_type} = patient
        const formData = new FormData();
        labTests.forEach(item=>{
            const sample_type = item.sample_type ? item.sample_type:''
            formData.append('sample_type', sample_type.value);
            formData.append('service_id', item.service_id);
            formData.append('sample_description', item.sample_description);
            formData.append('labrequests_detail_id', item.labrequests_detail_id);
            formData.append('add_to_invoice', item.add_to_invoice ? '1' : '0');
        })

        formData.append('requested_by', user_roles_id);
        formData.append('user_roles_id', user_roles_id);
        formData.append('visit_id',visit_id)
        formData.append('labrequest_id', labrequest_id);
        formData.append('activity_id', activity_id);
        formData.append('patient_type', patient_type);
        formData.append('lab_state', 2);
        formData.append('take_up_time ', current_date ? current_date : '');
        const arr = labTests.every(item=>item.sample_type.value);
        setSubmittedSample(true);
        setIsBlocking(false)
        if (arr){
            setIsSubmitted('pending')
            axios.post(`${config.smsUrl}/claboratory/save_sample`, formData).then(res => {
                const data = res.data;
                const dt = data ? data : [];
                setSampleNo(dt);
                // setOpenSampleNo(true);
                actions.snackbarActions.snackSuccess('Sample registered successfully');
                setIsSubmitted('resolved');
                setSubmittedSample(false)
                const cleared = labTests.map((item)=>({
                    ...item,sample_type:'',sample_description:''
                }))
                setLabTests(cleared)
                setOpenDialog(false)
            }).catch(err => {
                errorMessages(err, null, actions)
                setIsSubmitted('rejected');
            })
        }

    };

    const handleResetForm  = () =>{
        setOpenDialog(false)
        setIsBlockDialog(false)
        setIsBlocking(false)
        const cleared = labTests.map((item)=>({
            ...item,sample_type:'',sample_description:'', add_to_invoice: false
        }))
        setLabTests(cleared)
    }

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const sampleProps = {
        sampleState, patient,  isSubmitted, openSampleNo, sample_no,
        sampleTypes, technician, handleChangeSampleState, closeSampleNo, handleChangeSampleTypes,
        handleSubmitSample,closeSnackbar,  submitted, labTests,handleChangeSampleDescription, 
        isFetchedError, isFetchedSuccess, isFetching, submittedSample,isBlockDialog, isBlocking,
        setIsBlockDialog, setIsBlocking, handleResetForm, visit_id, handleChangeAddToInvoice
    }
    return {sampleProps,searchValue, order, orderBy, loading, activePage, total_count, reports, handleClickSample,
        handleSearch, handleRequestSort, handleChangeNextPage,  handleClickRow,closeSnackbar, lab_queue,
        retrieveAllRecords, csvRef, downloadCsv,allRecords, isLoading, isSuccess, isError, state,submitted,
        handleChange, handleSubmit, isPending, isRejected, isResolved, openDialog, handleOpenDialog, handleCloseDialog
    }
}
