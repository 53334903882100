import {useEffect, useRef, useState} from "react";
import axios from "axios";
import {config} from "../../../../Helpers/env";
import {logoutErrorMessage} from "../../../../Helpers/ErrorMessages";


export const useDispatchNote = (props) => {
    const [products, setProducts] = useState([]);
    const [state, setState] = useState({})
    const componentRef = useRef();
    const {dispatch_id,status} = props.match.params;
    const {actions} = props;

    useEffect(() => {
        const formData = new FormData();
        formData.append('dispatch_id', dispatch_id);
        axios.post(`${config.smsUrl}/cinventory/pharmacy_dispatch_items`, {
            dispatch_id
        }).then(res => {
            const data = res.data;
            const all_data = !data.data ? [] : data.data;
            const obj = all_data[0] ? all_data[0] : {}
            setProducts(all_data);
            setState(obj)
        }).catch(e => {
            logoutErrorMessage(e, null, actions)
        })
    }, []);


    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    return { componentRef, closeSnackbar, products, state, status}
}