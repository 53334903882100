import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {faUserMd} from "@fortawesome/free-solid-svg-icons/faUserMd";
import ReusableDoctorTabs, {doctorRoutes} from "../../Utils/Menu/ReusableDoctorTabs";
import {formatDate} from "../../Utils/ConvertDate";
import {PatientInformation} from "../PatientInfo/PatientInformation";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../../actions/snackbarActions";
import {connect} from "react-redux";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {CenteredContainer} from "../../Utils/styledComponents";
import {LoadingGif} from "../../Utils/Loader";
import {CollapsibleList, DataCell, ModuleTable} from "../../Utils/Lists/CollapsibleList";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {Container, FinishedStatus, P, PendingStatus, StyledIcon} from "../doctorStyles";
import TableRow from "@material-ui/core/TableRow";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import NewAntenatalService from "./NewAntenatalService";


const headData = [{item:'Service', isRequired:false}, {item:'Quantity', isRequired:false},
    {item:'Department', isRequired:false}, {item:'Doctor', isRequired:false}, {item:'Status', isRequired:false},
    {item:'Action', isRequired:false}]
const RequestedAntenatalServices = ({actions, snackbars,match:{params}}) => {

    const {patient_number, visit_id,  patient_type,patient_admission_id} = params;
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const doctor_name = `${!_token.first_name ? "" : _token.first_name} ${!_token.last_name ? "" : _token.last_name}`;

    const [previousProcedures, setPreviousProcedures] = useState([])
    const [status, setStatus] = useState('idle')
    const [isSubmitted, setIsSubmitted] = useState('idle')
    const [openDialog, setOpenDialog] = useState(false)


    const isResolved = isSubmitted === 'resolved'
    const groupByDate = (arr=[]) =>{
        let result = []
        let knowledge = {}
        arr.forEach(item =>{
            let id = formatDate(item.date_created);
            if(id in knowledge){
                result[knowledge[id]].services.push(item)
            }else {
                knowledge[id] = result.length
                result.push({
                    id: id,
                    date:id,
                    doctor_name:`${item.doctor_firstname ? item.doctor_firstname:''} ${item.doctor_lastname ? item.doctor_lastname : ''}`,
                    services: [item]
                })

            }
        })
        return result
    }

    useEffect(() => {
        const formData = new FormData();
        formData.append('visit_id', visit_id);
        setStatus('pending')
        axios.post(`${config.smsUrl}/cdoctor/get_previous_procedure_by_visit_id`, formData).then(res => {
            const resp = res.data;
            const data = !resp ? {} : resp;
            const arr = data.procedure ? data.procedure : []
            const services = groupByDate(arr)
            const prods = services.map(item=>({...item,open:true}))
            setPreviousProcedures(prods);
            setStatus('success')
        }).catch(error => {
            logoutErrorMessage(error, null, actions)
            setStatus('error')
        });
    }, [isResolved])


    const handleOpen = (id) =>{
        const itemsArr = previousProcedures.map((item)=>{
            if(item.id === id){
                return {...item,open:!item.open}
            }else{
                return item
            }
        })
        setPreviousProcedures(itemsArr)
    }

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };


    const isLoading = status === 'pending'
    const isSuccess = status === 'success'
    const isError = status === 'error'

    const arr = doctorRoutes(patient_number, visit_id, patient_type,patient_admission_id)

    const {openBar, type, message} = snackbars;

    return (
        <div className='journals'>
            <PageTitle title="Antenatal Care"/>
            <SubHeader title="Doctor" subTitle="AntenatalCare" doctor_name={doctor_name}>
                <FontAwesomeIcon icon={faUserMd}/>
            </SubHeader>
            <MainSnackbar open={openBar} handleCloseBar={closeSnackbar} variant={type} message={message}/>
            <ReusableDoctorTabs components={arr} patient_type={+patient_type}>
                <PatientInformation {...{patient_number, visit_id}}/>
                <Container>
                    <NewAntenatalService {...{actions, openDialog, setOpenDialog,visit_id, isSubmitted, setIsSubmitted}}/>

                    {/*<button type='button' onClick={()=>{*/}
                    {/*    setOpenDialog(true)*/}
                    {/*}} className='btn btn-sm sms-info-btn mb-2'>Add Antenatal Service</button>*/}
                    {/*{isLoading ? <CenteredContainer>*/}
                    {/*    <LoadingGif/>*/}
                    {/*</CenteredContainer> : null}*/}
                    {/*{isSuccess ? previousProcedures.length > 0 ? previousProcedures.map((item)=>{*/}
                    {/*    const payStatus =  item.services.some(dt=>dt.pay_status === 0)*/}
                    {/*    return(*/}
                    {/*        <CollapsibleList key={item.id} open={item.open} name={item.date} handleOpen={()=>handleOpen(item.id)} button={*/}
                    {/*            <>*/}
                    {/*                {payStatus ? <Tooltip title='Edit Request'>*/}
                    {/*                    <IconButton>*/}
                    {/*                        <StyledIcon/>*/}
                    {/*                    </IconButton>*/}
                    {/*                </Tooltip> : null}*/}
                    {/*            </>*/}
                    {/*        }*/}
                    {/*        >*/}
                    {/*            <ModuleTable headData={headData}>*/}
                    {/*                {item.services.map((row, index) => {*/}
                    {/*                    const isPending = row.item_status === 'Pending' || row.item_status === null;*/}
                    {/*                    const isFinished = row.item_status === 'Done';*/}
                    {/*                    const isPaid = row.pay_status === 1 ||  row.pay_status === 3*/}
                    {/*                    return(*/}
                    {/*                        <TableRow*/}
                    {/*                            key={index}*/}
                    {/*                        >*/}
                    {/*                            <DataCell>*/}
                    {/*                                {row.procedure_name}*/}
                    {/*                            </DataCell>*/}
                    {/*                            <DataCell>*/}
                    {/*                                {row.quantity}*/}
                    {/*                            </DataCell>*/}
                    {/*                            <DataCell>*/}
                    {/*                                {row.department_name}*/}
                    {/*                            </DataCell>*/}
                    {/*                            <DataCell>*/}
                    {/*                                {row.doctor_name}*/}
                    {/*                            </DataCell>*/}
                    {/*                            <DataCell>{isPending ? <PendingStatus>{row.item_status ? row.item_status : 'Pending'}</PendingStatus>:isFinished ? <FinishedStatus>{row.item_status}</FinishedStatus>: null}</DataCell>*/}
                    {/*                            <DataCell>*/}
                    {/*                            </DataCell>*/}
                    {/*                        </TableRow>*/}
                    {/*                    )})}*/}
                    {/*            </ModuleTable>*/}
                    {/*        </CollapsibleList>*/}
                    {/*    )*/}
                    {/*}) : <CenteredContainer>*/}
                    {/*    <P>No procedures added for patient, click the add procedure button to add a procedure</P>*/}
                    {/*</CenteredContainer>:null}*/}
                    {/*{isError ? <CenteredContainer>*/}
                    {/*    <P>An error occurred on the server</P>*/}
                    {/*</CenteredContainer>:null}*/}
                </Container>
            </ReusableDoctorTabs>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbar
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(RequestedAntenatalServices);