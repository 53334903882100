import React from "react";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CustomTable from "../../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import {getComparator, stableSort} from "../../Utils/Tables/sortingFunctions";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import *  as snackbarActions from "../../../actions/snackbarActions";
import DeleteDialog from "../../Utils/Dialogs/DeleteDialog";
import {faShippingFast} from "@fortawesome/free-solid-svg-icons/faShippingFast";
import {faEdit} from "@fortawesome/free-solid-svg-icons/faEdit";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {titleCase} from "../../Users/addUser";
import {useSupplierList} from "./hooks/useSupplierList";
import {DataExportButton} from "../../Utils/Buttons/DataExportationButton";
import CustomDialog from "../../Utils/Dialogs/CustomDialog";
import {SupplierForm}  from './AddEditSupplier';
import { Container } from "../../DoctorsModule/doctorStyles";

const headData = [
    {id: 'sl', numeric: false, disablePadding: false, label: 'SL'},
    {id: 'supplier', numeric: false, disablePadding: false, label: 'Supplier Name'},
    {id: 'telephone', numeric: false, disablePadding: false, label: 'Contact'},
    {id: 'email', numeric: false, disablePadding: false, label: 'Email'},
    {id: 'address', numeric: false, disablePadding: false, label: 'Address'},
    {id: 'actions', numeric: false, disablePadding: true, label: 'Action'},
];


export const SupplierList = ({actions, snackbars}) => {
    const {
        searchValue, order, orderBy, loading, suppliers, openDelete, activePage,item,
        total_count, handleOpenDelete, handleChangeActivePage, handleCloseDelete, handleDelete,
        closeSnackbar, handleSearch, handleRequestSort, csvRef, allRecords, downloadCsv, downloadPdf,
        supplierProps, handleOpenDialog, openDialog, handleCloseDialog
    } = useSupplierList(actions)

    const headers = [{label: 'Supplier Name', key: ' supplier_name'}, {label: 'Contact', key: 'supplier_contact'},
        {label: 'Email', key: '  supplier_emai'}, {label: 'Address', key: 'supplier_adress'}]

    const {openBar, type, message} = snackbars;
    const {isPending, supplier_id} = supplierProps;
    const isEdit = supplier_id !== ''
    const {status} = item;
    return (
        <div data-testid="supplier-list" className='journals'>
            <PageTitle title="Suppliers"/>
            <SubHeader title="Inventory" subTitle="Suppliers">
                <FontAwesomeIcon icon={faShippingFast}/>
            </SubHeader>
            <CustomDialog open={openDialog} handleClose={handleCloseDialog} 
            title={`${isEdit ? 'Update': 'Add'} Supplier`} maxWidth="md">
                <SupplierForm {...supplierProps}>
                    <button type="submit" disabled={isPending} className="btn btn-sm sms-btn">{isPending ? 'Saving...' : `${isEdit ? 'Update': 'Add'} Supplier`}</button>
                </SupplierForm>
            </CustomDialog>
            <Container>
                <div className='general-ledger-header'>
                    <div className="row">
                        <div className="col-md-5">
                        </div>
                        <div className="col-md-7">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="text-right">
                                        <button onClick={()=>handleOpenDialog('')} id="add-supplier" className="btn btn-sm sms-btn"><FontAwesomeIcon icon={faPlus}/> Add Supplier</button>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="text-right">
                                        <DataExportButton data={allRecords} title='Suppliers List' csvRef={csvRef}
                                                        exportCsv={downloadCsv} downLoadPdf={downloadPdf} headers={headers}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="mui-tables">
                    <DeleteDialog message={status === 1 ?  'deactivate':'activate'} openDialog={openDelete} handleClose={handleCloseDelete}
                                        text="supplier" title={`${status === 1 ? 'Deactivate':'Activate'} Supplier`}>
                            <button className={`btn btn-sm ${status === 1 ? 'sms-gray-btn' : 'sms-info-btn'}`} onClick={handleDelete}>{status === 1 ? 'Deactivate':'Activate'}</button>
                            <button className="btn sms-grey-btn btn-sm" onClick={handleCloseDelete}>No</button>    
                    </DeleteDialog>
                    <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>
                    <CustomTable title="Suppliers" total_count={total_count} handleChangeNextPage={handleChangeActivePage}
                                headData={headData} handler={handleSearch} activePage={activePage} records={10} customPage
                                handleRequestSort={handleRequestSort} data={suppliers} colSpan={5} order={order}
                                orderBy={orderBy} term={searchValue}>
                        {loading ? <TableBody><TableRow>
                            <TableCell colSpan="5" align="center"><img src="/images/smsloader.gif"
                                                                    alt="loader"/></TableCell>
                        </TableRow></TableBody> : suppliers.length > 0 ?
                            <TableBody>
                                {stableSort(suppliers, getComparator(order, orderBy))
                                    .slice(0, 10)
                                    .map((item, index) => {
                                        const count = ((activePage - 1) * 10) + index + 1;
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={index}
                                                className='mui-table-row'>
                                                <TableCell>{count}</TableCell>
                                                <TableCell>{titleCase(item.supplier_name)}</TableCell>
                                                <TableCell>{item.supplier_contact}</TableCell>
                                                <TableCell>{item.supplier_email}</TableCell>
                                                <TableCell>{item.supplier_adress}</TableCell>
                                                <TableCell>
                                                    <button onClick={()=>handleOpenDialog(item.supplier_id)} className="btn btn-sm sms-info-btn mr-1 mb-1">
                                                        <FontAwesomeIcon icon={faEdit}/></button>
                                                    <button onClick={() => handleOpenDelete(item)}
                                            className={`btn btn-sm mb-1 ${item.status === 1 ? 'sms-gray-btn' : 'sms-info-btn'}`}>
                                            {item.status === 1 ? 'Deactivate':item.status === 0 ?  'Activate':''}
                                            </button>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                            : <TableBody><TableRow>
                                <TableCell colSpan="5" align="center">No records found</TableCell>
                            </TableRow></TableBody>}
                    </CustomTable>
                </div>
            </Container>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(SupplierList);
