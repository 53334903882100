import {useState, useEffect} from 'react';
import axios from "axios";
import {config} from "../../../Helpers/env";
import { logoutErrorMessage } from "../../../Helpers/ErrorMessages";

export const usePatientProfile = (patient_number,actions) =>{
    const[state,setState] = useState({
        showHealth: true,
        showMedication: false,
        showBilling: false,
        showLabresults: false,
        expanded: false,
    })
    const[patient,setPatient] = useState({})
    const[getPreviousVisits,setPreviousVisits] = useState({})


    const FetchPatientDetails = (formData)=>{
        axios.post(config.smsUrl + "/cpatient/patient_update_form", formData).then((res)=>{
        const data = res.data
        const dt = !data ? {} : data;
            setPatient(dt)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }

    const FetchPreviousVisits = (formData) => {
        axios.post(`${config.smsUrl}/cpatient/get_patient_previous_visits`, formData).then((res)=>{
        const data = res.data
        const dt= !data? {}: data
        setPreviousVisits(dt)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }


    useEffect(()=>{
        const formData = new FormData()
        formData.append('patient_number', patient_number)
        FetchPatientDetails(formData)
        FetchPreviousVisits(formData)
    },[])
     const handleChange = (panel, visit_id) => (event, isExpanded) => {
        
        const formData = new FormData();
        formData.append('visit_id', visit_id);
        setState({expanded: isExpanded ? panel : false}, () => {
            actions.patientActions.getVisitDetails(formData)
        });
    };

    const showHealthInfo = () => {
    setState({showHealth: true, showMedication: false, showBilling: false,})
    };
    const showMedication = () => {
        setState({showMedication: true, showHealth: false, showBilling: false,})
    };
    const showBilling = () => {
        setState({showBilling: true, showMedication: false, showHealth: false,})
    };
    const patientProps= {showHealthInfo,showMedication,showBilling,handleChange}

    return{patientProps, patient}
}