import React from "react";
import PageTitle from "../Utils/smsTitle";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CustomTable from "../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import {getComparator, stableSort} from "../Utils/Tables/sortingFunctions";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {Link} from "react-router-dom";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../actions/snackbarActions";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import DeleteDialog from "../Utils/Dialogs/DeleteDialog";
import {faCalendarCheck} from "@fortawesome/free-solid-svg-icons/faCalendarCheck";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {useShiftCategoryList} from "./CustomHooks/useShiftCategoryList";
import CustomDialog from '../Utils/Dialogs/CustomDialog'
import AddShiftCategory from "./AddShiftCategory";
import { Container } from "../DoctorsModule/doctorStyles";

const headData = [{id: 'sl', numeric: false, disablePadding: false, label: 'SL'},
    {id: 'shiftName', numeric: false, disablePadding: false, label: 'Shift Name'},
    {id: 'period', numeric: false, disablePadding: false, label: 'Period'},
    {id: 'actions', numeric: false, disablePadding: true, label: 'Action'},
];

export const ShiftCategoryList = (props) => {

    const {searchValue, order, orderBy,  shiftCategories, loading, openDelete, activePage,
        total_count, handleOpenDelete, handleCloseDelete, handleChangeActivePage, handleDelete,
        handleSearch, handleRequestSort, closeSnackbar, addShiftCatProps, editShiftCatProps} = useShiftCategoryList(props)
    const {openBar, type, message} = props.snackbars;
    const status = 1;
    return (
        <div data-testid="shift-category-list" className='journals'>
            <PageTitle title="Shift Category List"/>
            <SubHeader title="Shifts & Schedule" subTitle="Shift category list">
                <FontAwesomeIcon icon={faCalendarCheck}/>
            </SubHeader>
            <CustomDialog open={addShiftCatProps.openAddDialog} handleClose={addShiftCatProps.handleCloseAddDialog} title="Add Shift Category" maxWidth="md">
                    <AddShiftCategory {...addShiftCatProps} />
                </CustomDialog>
            <CustomDialog open={editShiftCatProps.openEditDialog} handleClose={editShiftCatProps.handleCloseEditDialog} title="Edit Shift Category" maxWidth="md">
                 <AddShiftCategory {...editShiftCatProps} />
            </CustomDialog>
            <Container>
            <div className='general-ledger-header'>
                <div className="text-right">
                    <button className="btn sms-btn btn-sm" onClick={addShiftCatProps.handleOpenAddDialog}><FontAwesomeIcon icon={faPlus}/> Add Shift Category</button>
                </div>
            </div>
            <div className="mui-tables">
                <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>
                <DeleteDialog message={status === 1 ?  'deactivate':'activate'} openDialog={openDelete} handleClose={handleCloseDelete}
                                    text="shift category" title={`${status === 1 ? 'Deactivate':'Activate'} Shift Category`}>
                            <button className='btn sms-info-btn btn-sm' onClick={handleDelete}>{status === 1 ? 'Deactivate':'Activate'}</button>
                            <button className="btn sms-grey-btn btn-sm" onClick={handleCloseDelete}>No</button>    
                </DeleteDialog>
                <CustomTable title="Shift Category List" term={searchValue}
                             handleChangeNextPage={handleChangeActivePage}
                             headData={headData} handler={handleSearch} total_count={total_count}
                             activePage={activePage}
                             handleRequestSort={handleRequestSort} data={shiftCategories} colSpan={5} order={order}
                             orderBy={orderBy} id="searchValue" records={10} customPage>
                    {loading ? <TableBody><TableRow>
                        <TableCell colSpan="6" align="center"><img src="/images/smsloader.gif"
                                                                   alt="loader"/></TableCell>
                    </TableRow></TableBody> : shiftCategories.length > 0 ?
                        <TableBody>
                            {stableSort(shiftCategories, getComparator(order, orderBy))
                                .slice(0, 10)
                                .map((item, index) => {
                                    const count = ((activePage - 1) * 10) + index + 1;
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={index}
                                            className='mui-table-row'
                                        >
                                            <TableCell>{count}</TableCell>
                                            <TableCell>{item.shift_name}</TableCell>
                                            <TableCell>{`${item.start_time} - ${item.end_time}`}</TableCell>
                                            <TableCell>
                                       
                                                 
                                                    <button onClick={()=> editShiftCatProps.handleOpenEditDialog(item.shift_id)}
                                                        className="btn btn-sm sms-info-btn mr-1">Edit
                                                    </button>
                                               
                                                <button
                                                    onClick={() => handleOpenDelete(item.shift_id)}
                                                    className="btn btn-sm sms-btn-dismiss">Delete
                                                </button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody> : <TableBody><TableRow>
                            <TableCell colSpan="6" align="center">No records found</TableCell>
                        </TableRow></TableBody>}
                </CustomTable>
            </div>
            </Container>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(ShiftCategoryList);
