import {useEffect, useState} from "react";
import {dateConvert} from "../../../Utils/ConvertDate";
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {errorMessages} from "../../../../Helpers/ErrorMessages";
import {history} from "../../../../Helpers/history";

export const useRecordIncomingReferral = (props, type, patient_number, handleReferralDialog) => {
    const [state, setState] = useState({
        referral_date: dateConvert(), referral_number: "",
        visit_date: dateConvert(), diagnosis: "", reason: "", notes: "",
        from_hospital: "", patient_history: "", treatment: ""
    });
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const [referral_id, setReferralID] = useState('');
    const [openPrint, setOpenPrint] = useState(false);
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;
    const {actions} = props;

    const [company_name,setCompanyName]= useState(null);
    const [departments, setDepartments] = useState([]);

    useEffect(() => {
        axios.get(`${config.smsUrl}/cuser/view_company_info`)
            .then((response) => {
                const data= response.data? response.data:{}
                setCompanyName(data.company_name);
            }).catch(err=>{
                errorMessages(err, null, actions)
        });
    }, []);

    useEffect(() => {
        axios.get(`${config.smsUrl}/cvisit/retrieve_department`).then(res => {
            const data = res.data;
            const dt = !data ? [] : data;
            const departs = !dt.departments ? [] : dt.departments;
            const arr = departs.map(department => ({value: department.department_id,
                label: department.department_name
            }))
            setDepartments(arr)
        }).catch(err => {
            errorMessages(err, null, actions)
        })
    }, [])

    const handleChange = (event) => {
        const {name, value} = event.target;
        setState({...state, [name]: value})
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const {
            referral_date, visit_date, diagnosis, treatment, patient_history, from_hospital
        } = state;
        setSubmitted(true);

        const referral_details = {
            patient_number,
            referred_from : from_hospital,
            referred_to : company_name,
            referral_type : type,
            visit_date,
            diagnosis,
            'reason': patient_history,
            treatment_given : treatment,
            user_roles_id
        }
        if (referral_date && from_hospital && diagnosis !== '') {
            sessionStorage.setItem(`record_incoming_referral_${patient_number}`, JSON.stringify(referral_details));
            handleReferralDialog(event,'newvisit');

            setSubmitted(false);
            actions.snackbarActions.snackSuccess('Referral recorded successfully')
        }
        // else {
        //     actions.snackbarActions.snackError('Missing referral date')
        // }
    };

    const handleCloseReferral = () => {
        setOpenPrint(false)
    };

    const handleClick = () => {
        const url = type === 'internal' ? `/internalreferralnote/${referral_id}` : `/outgoingreferralprint/${referral_id}`
        history.push(url)
    };

    const handleReset = () => {
        setState({
            referral_date: '', referral_number: "",
            visit_date: "", diagnosis: "", reason: "", notes: "",
            from_hospital: "", patient_history: "", treatment: ""
        });
        setSubmitted(false)
    };
    const handleCloseSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    return { state, submitted, isSubmitted, referral_id,
        openPrint, handleChange, company_name,
        handleSubmit, handleCloseReferral, handleClick, handleReset, handleCloseSnackbar, departments
    }
}