import React from "react";
import * as snackbarActions from "../../../actions/snackbarActions"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClipboardList} from "@fortawesome/free-solid-svg-icons/faClipboardList";
import {DataExportButton} from "../../Utils/Buttons/DataExportationButton";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import CustomTable, {CustomTablePagination} from "../../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { BackButton } from "../../Utils/Buttons/DataExportationButton";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import SelectInput, {CustomSelect} from "../../Utils/FormInputs/SelectInput";
import Label from "../../Utils/FormInputs/Label";
import { useInsuranceSummary } from "./CustomHooks/useInsuranceSummary";
import { DateTimeFilter } from "../../Utils/ReusableComponents/DateTimeFilter";
import {Container, RightAlignedContainer} from "../../Utils/styledComponents";
import {useExcelReports, usePdfDownload} from "../../Utils/ReusableComponents/useExcelExport";
import {formatDateTime} from "../../Utils/ConvertDate"
import Form from "react-bootstrap/Form";


const headData = [
    {id: 'sl', numeric: false, disablePadding: false, label: 'SL'},
    {id: 'date', numeric: false, disablePadding: false, label: 'Date'},
    {id: 'invoice_number', numeric: false, disablePadding: false, label: 'Invoice No '},
    {id: 'patient_name', numeric: false, disablePadding: false, label: 'Patient Name '},
    {id: 'account_name', numeric: false, disablePadding: false, label: 'Provider Name'},
    {id: 'scheme_name', numeric: false, disablePadding: false, label: 'Scheme Name'},
    {id: 'amount', numeric: false, disablePadding: false, label: 'Amount'}
];

const InsuranceSummaryReport = ({actions, snackbars}) => {
   
    const{state,handleChange, isPending,handleChangeActivePage,handleSubmit,handleCustomSearch,
        count,activePage,customSearch,data,
        closeSnackbar,submitted,loading,provider,
        providers, handleChangeProvider, page, rowsPerPage, handleChangeRowsPerPage, handleChangePage} = useInsuranceSummary(actions)


    const {start_date, end_date,status} = state;

    const params =  {
        end_date: end_date,
        start_date:start_date,
        page:activePage,
        search: customSearch,
        status: status
    }

    const pdfObj = {file_name:'Insurance Summary List', url:'cbilling/insurance_summary_exports', params:{...params, download_type:2, provider_id:provider?.value}}

    const {exportPdf, isLoadingPdf} = usePdfDownload(actions, pdfObj)

    const {exportExcel, isLoading:isLoadingExcel} = useExcelReports(actions, {file_name:'Insurance Summary List', url:'cbilling/insurance_summary_exports',
        params:{...params, download_type:1, provider_id:provider?.value}})


    const {openBar, type, message} = snackbars;

    const year = new Date().getFullYear().toString()
    const current_year = year.split('').slice(-2).join('')

    const isLoading = loading === 'pending'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'


    const pagination = (
        <CustomTablePagination
            colSpan={3}
            page={page}
            rowsPerPage={rowsPerPage}
            count={count}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            isTotalCount
        />
    );
   
    return (
        <div className='journals' data-testid="insurance-summary">
            <PageTitle title='Insurance Summary'/>
            <SubHeader title="Reports" subTitle='Insurance Summary'>
                <FontAwesomeIcon icon={faClipboardList}/>
            </SubHeader>
            <Container>
                <div style={{margin: '10px'}}>
                    <BackButton text={'General Reports'} to={'/reports/generalreports'}
                                data-testid="product-sales-report-back-button"/>
                </div>

                <RightAlignedContainer>
                    <DataExportButton title='Insurance Summary Report' downLoadPdf={exportPdf}
                                      exportCsv={exportExcel} isLoading={isLoadingPdf || isLoadingExcel}/>
                </RightAlignedContainer>

                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={4}>
                        <Row>
                            <Col xs={12} sm={2} md={2} lg={3} xl={3}>
                                <Label name="Filter by"/>
                            </Col>
                            <Col xs={12} sm={6} md={6} lg={6} xl={8}>
                                <SelectInput value={status} onChange={handleChange} name="status"
                                             options={[{value: 0, text: 'All'}, {value: 2, text: 'Complete'}, {
                                                 value: 1,
                                                 text: 'Incomplete'
                                             },
                                                 {value: 'ON_SMART', text: 'ON SMART'}, {
                                                     value: 'OFF_SMART',
                                                     text: 'OFF SMART'
                                                 }]}/>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={8}>
                        <DateTimeFilter {...{
                            start_date,
                            end_date,
                            handleChange,
                            submitted,
                            handleSubmit,
                            isPending,
                            type: 'date'
                        }}/>
                    </Col>
                </Row>
                <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>
                <div className='row mb-0'>
                    <div className="col-lg-4">
                        <Form.Group as={Row}>
                            <Col xs={12} sm={2} md={2} lg={3} xl={3} className='pl-0'>
                                <Label name="Provider"/>
                            </Col>
                            <Col xs={12} sm={6} md={6} lg={6} xl={8}>
                                <CustomSelect defaultValue={{label:"Select provider...", value:''}}
                                              id="insurance_provider" value={provider}
                                              options={providers} onChange={handleChangeProvider}
                                              isClearable/>
                            </Col>
                        </Form.Group>
                    </div>
                </div>
                <CustomTable title="" handleChangeNextPage={handleChangeActivePage} term={customSearch}
                             records={10} pagination={pagination}
                             headData={headData} handler={handleCustomSearch} total_count={count}
                             activePage={activePage}
                             data={data} colSpan={headData.length}
                             customPage>
                        <TableBody>
                            {isLoading ? <TableRow>
                                    <TableCell colSpan={headData.length} align="center">
                                        <img src="/images/smsloader.gif"
                                             alt="loader"/>
                                    </TableCell>
                                </TableRow>: null}
                            {isSuccess ? data.length > 0 ?  data.slice(0, rowsPerPage).map((item, index) => {
                                const cnt = ((activePage - 1) * rowsPerPage) + index + 1;
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={index}
                                        className='mui-table-row'>
                                        <TableCell>{cnt}</TableCell>
                                        <TableCell>{item.begin_datetime ? formatDateTime(item.begin_datetime) : ''}</TableCell>
                                        <TableCell>{`${item?.visit_id}/${current_year}`}</TableCell>
                                        <TableCell>{`${item.patient_firstname} ${item.patient_lastname}`}</TableCell>
                                        <TableCell>{item.provider_name}</TableCell>
                                        <TableCell>{item.scheme_name}</TableCell>
                                        <TableCell>{(item.total_amount)}</TableCell>
                                    </TableRow>
                                );
                            }) :
                                <TableRow>
                                    <TableCell colSpan={headData.length} align="center">No records found</TableCell>
                                </TableRow> : null}
                            {isError ? <TableRow>
                                <TableCell colSpan={headData.length} align="center">The server did not return a valid response</TableCell>
                            </TableRow>: null}
                        </TableBody>
                </CustomTable>
            </Container>


        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InsuranceSummaryReport);
