import {useEffect, useState} from "react";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";

export const useUsersList = (props) =>{
    const [searchValue, setSearchValue] = useState('');
    const [openMenu,setOpenMenu] = useState(null);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('sl');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [user_id,setUserId] = useState('');
    const [users,setUsers] = useState([]);
    const [loading,setLoading] = useState(false);
    const [openDelete,setDelete] = useState(false);
    const {actions} = props


    useEffect(()=>{
        setLoading(true);
        axios.get(`${config.smsUrl}/cuser/manage_user`).then(res=>{
            const data = res.data;
            const dt = !data ? {} : data;
            const list = !dt.user_list ? [] : dt.user_list;
            const arr = list.map((it, idx) => ({
                ...it,
                'sl' : (page * rowsPerPage) + idx + 1
            }))
            setUsers(list);
            setLoading(false);
        }).catch((err)=>{
            logoutErrorMessage(err, null, actions)
            setLoading(false);
        })
    }, []);

    const handleOpenDelete = (_id) =>{
        setDelete(true)
        setUserId(_id)
    };

    const handleCloseDelete = () =>{
        setDelete(false)
    };

    const handleDelete = () =>{
        const formData = new FormData();
        formData.append('user_id',user_id);
        axios.post(`${config.smsUrl}/cuser/user_delete`, formData).then(()=>{
            const allUsers = users.filter(user=>user.user_id !== user_id);
            setUsers(allUsers);
            actions.snackbarActions.snackSuccess('User deleted successfully');
        }).catch(err=>{
            errorMessages(err, null, actions)
        });
        setDelete(false)
        setOpenMenu(false)
    };

    const handleCloseSnackbar = () =>{
        actions.snackbarActions.hideSnackbar()
    };

    const handleSearch = (searchValue) =>{
        setSearchValue(searchValue)
    };

    const handleOpenMenu = (event,user_id) =>{
        setOpenMenu(event.currentTarget);
        setUserId(user_id)
    };

    const handleCloseMenu = () =>{
        setOpenMenu(null)
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return {searchValue, openMenu, order, orderBy, page, rowsPerPage, user_id, users, loading,
        openDelete, handleOpenDelete, handleCloseDelete, handleDelete, handleCloseSnackbar,
        handleSearch, handleOpenMenu, handleCloseMenu, handleRequestSort, handleChangePage,
        handleChangeRowsPerPage}
}