
import React from 'react';
import ReceiptTemplate from '../../../Utils/ReceiptTemplate';
import { GetTriageDetails, getMetricValues } from '../../../DoctorsModule/ReusableTreatmentHistory';
import { useTheme } from '@material-ui/core';
import { ReusableReferralSubHead } from '../../../DoctorsModule/ReferralNote/ReusableReferralDetails';
import { ReusableLabResults } from '../../../Lab/ReusableLabResults';
import { ReusableReferralDetails } from '../../../DoctorsModule/ReferralNote/ReusableReferralDetails';
import { CustomTableData } from './../../../DoctorsModule/ReferralNote/ReusableTreatmentTable';
import { titleCase } from '../../../Users/addUser';
import { formatDateTime, convertDateStrokeTime } from '../../../Utils/ConvertDate';
import { splitNotes } from '../../../Radiology/RadiologyReport';
import { TableHeading } from './../../../DoctorsModule/ReferralNote/ReusableTreatmentTable';
import { ReusableResults } from './../../../Radiology/ReusableResults';
import { DisplayAnteNatalDetails } from "../../../DoctorsModule/ReusableTreatmentHistory";

/**
 * Component that generates the report for a previous Ante Natal visit
 * @param {*} param0 
 * @returns 
 */
export const PrintANCReport = ({patientANCDetails}) => {

    const option = [{value:'labRequest', label:'Laboratory Request(s)'},{value:'labResults', label:'Laboratory Results(s)'},
    {value:'radiologyRequest', label:'Radiology Request(s)'},{value:'radiologyResults', label:'Radiology Result(s)'},
         {value:'referral', label:'Referral Note'}
    ]

    const {
        patient_details, antenatal_data, triage:triage_details, prescription, diagnosis,
        referral, lab, lab_result, scan, visit_detail, exam_notes: all_notes, other_medicine, scan_result
    } = patientANCDetails;

    const {
        Age, gender, patient_number, address, chronic_illness, first_name, last_name,
    } = patient_details;

    const {
        patient_type
    } = visit_detail;

    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const doctor_name = `${!_token.first_name ? "" : _token.first_name} ${!_token.last_name ? "" : _token.last_name}`;
    const visit = visit_detail ? visit_detail : {};
    const date = visit.begin_datetime ? formatDateTime(visit.begin_datetime) : ''

    //prescription
    const presc = prescription ? prescription : [];
    const others = other_medicine ? other_medicine : [];
    const other_meds = others.filter(Boolean);
    const arr = [...presc, ...other_meds];
    const {metrics_titles, percentage_metrics} = GetTriageDetails(triage_details);

    const title_notes = patient_type === 1 ? 'Examination Notes' : 'Round Notes';

    const diag = diagnosis ? diagnosis : [];
    const diagno = diag.map(({notes})=>notes).filter(item=>Boolean(item)).join(' ');
    const diag_notes = diagno ? diagno : '';

    const radiology_reqs = scan ? scan : [];
    const radiology_results = scan_result ? scan_result : [];

    const lab_reqs = lab ? lab : [];
    const results = lab_result ? lab_result : [];
    
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;

    //referral
    const ref = referral ? referral : [];
    const refs = ref[0] ? ref[0] : {};
    const {reason, referred_from, referred_to, treatment_given} = refs;

    const viewNotesDetails = (item) => (
    <tr key={item.round_id}>
        <td width='auto' style={{borderBottom:'1px solid gray'}}>
            <p style={{margin:0}}>{item.notes ?  splitNotes(item.notes):''} </p>
        </td>
    </tr>
    )
    
  return (
       <div className='patient-triage mt-2 p-3' data-testid='print-ante-natal-report'>
            <ReceiptTemplate message="Presiding Doctor" name={doctor_name} footer>
                <table className="table table-sm table-borderless prescription-table">
                    <tr>
                        <td className={palette.type === 'dark' && 'dark-theme-color'}><p>Date: <strong>{date}</strong></p></td>
                        <td className={palette.type === 'dark' && 'dark-theme-color'}>
                        <p>Patient Number: <strong>{patient_number}</strong></p></td>
                    </tr>
                    <tr>
                    <td className={palette.type === 'dark' && 'dark-theme-color'}><p>Patient Name: <strong>{titleCase(`${!first_name ? "" : first_name} ${!last_name ? "" : last_name}`)}</strong></p></td>
                        <td className={palette.type === 'dark' && 'dark-theme-color'}>
                            <p>Age: <strong>{Age}</strong></p>
                        </td>
                    </tr>
                    <tr>
                        <td className={palette.type === 'dark' && 'dark-theme-color'}>
                            <p>Gender: <strong>{titleCase(!gender ? "" : gender)}</strong></p></td>
                            <td className={palette.type === 'dark' && 'dark-theme-color'}>
                            <p>Address: <strong>{titleCase(!address ? "" : address)}</strong></p>
                        </td>
                    </tr>
                </table>

                {triage_details && triage_details.length > 0 ?
                <div>
                    <TableHeading title='Triage' colSpan={2}/>
                    <table className="table table-bordered profile-table mt-1">
                        <thead>
                            <tr>
                                <td rowSpan={2} style={{textAlign:'center'}}><strong>Date</strong></td>
                                <td colSpan={metrics_titles.length} style={{textAlign:'center'}}><strong>Metrics</strong></td>
                            </tr>
                            <tr>
                                {metrics_titles.map((it) => (
                                    <td key={it.metric_id} style={{textAlign:'center'}}>
                                    <p><strong>{`${it.metric_name} ${it.uom ? `(${it.uom})` : ''}`}</strong></p>
                                    </td>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {triage_details.map((element, index) => (
                                <tr key={index}>
                                    <td style={{width:'20%', textAlign:'center'}}>
                                    <p>
                                    {convertDateStrokeTime(element.metric_date)}
                                    </p>
                                    </td>
                                    {metrics_titles.map((it, idx) => (
                                        getMetricValues(element.data, it.metric_name, percentage_metrics)
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                : null}

                {/**
                 * Ante Natal Health Details
                 */}
                {antenatal_data !== {} ?
                    <DisplayAnteNatalDetails antenatal_data={antenatal_data} chronic_illness_dt={chronic_illness}/>
                : null}

                {patient_type == 1 ?
                    <>
                    {all_notes.length > 0 ?
                    <TableHeading title={`${title_notes}`}>
                    {viewNotesDetails(all_notes[all_notes.length - 1])}
                    </TableHeading>
                    : null}
                    </>
                :
                all_notes.length > 0 ?
                <TableHeading title={`${title_notes}`}>
                    {all_notes.map(item=>{
                        return (
                            <tr key={item.round_id}>
                                <td width='auto' style={{borderBottom:'1px solid gray'}}>
                                    <p style={{margin:0}}>
                                        <span className="mr-5">Written by: <strong>{`${!item.doctor_firstname ? "" : item.doctor_firstname} ${!item.doctor_lastname? "" : item.doctor_lastname}`}</strong></span>
                                        <strong>{formatDateTime(item.start_time)} </strong>
                                    </p>
                                    <p style={{margin:0}}>{item.findings ?  splitNotes(item.findings):''} </p>
                                </td>
                            </tr>
                    )})}
                </TableHeading> : null}

                {lab_reqs.length > 0  ? option.map((item) => {
                    if (item.value === 'labRequest' || item.value === 'labResults') {
                        return (
                            <div key={item.value}>
                                {(item.value === 'labRequest' && lab_reqs.length > 0) ?
                                <>
                                    <TableHeading title='Laboratory Request(s)'/>
                                    <CustomTableData headData={["Lab Test", "Lab Type"]}>
                                        {lab_reqs.map((item) => (
                                            <tr key={item.hospital_test_id}>
                                                <td><p>{item.hospital_test_name}</p></td>
                                                <td><p>{item.hospital_test_type}</p></td>
                                            </tr>
                                        ))}
                                    </CustomTableData></> : null}
                                {item.value === 'labResults' && results.length > 0 ?
                                    <>
                                        <TableHeading title='Laboratory Results(s)'/>
                                        <ReusableLabResults labTests={results}/>
                                    </>:null}
                            </div>
                        )
                    }
                }):null}

                {radiology_reqs.length > 0 && option && option.length > 0 ? option.map((item) => {
                    if (item.value === 'radiologyRequest' || item.value === 'radiologyResults') {
                        return (
                            <div key={item.value}>
                                {item.value === 'radiologyRequest' && radiology_reqs.length > 0 ?
                                <>
                                    <TableHeading title='Radiology Requests(s)'/>
                                    <CustomTableData headData={["Scan Name", "Purpose"]}>
                                        {radiology_reqs.map((item) => (
                                            <tr key={item.hospital_scan_id}>
                                                <td><p>{item.hospital_scan_name}</p></td>
                                                <td><p>{item.purpose}</p></td>
                                            </tr>
                                        ))}
                                    </CustomTableData></>:null}
                                { item.value === 'radiologyResults' && radiology_results.length > 0 ?
                                    <>
                                        <TableHeading title='Radiology Results(s)'/>
                                        {radiology_results.map(item => (
                                            <ReusableResults key={item.scan_result_id} notes={item.notes}
                                                                image_path={item.image_path}
                                                                hospital_scan_name={item.hospital_scan_name}
                                                                indication={item.indication}
                                                                conclusion={item.conclusion}/>
                                        ))}
                                    </>:null}
                            </div>
                        )
                    }
                }):null}

                {diag.length > 0 &&
                <TableHeading title='Diagnosis' colSpan={2}>
                    <table className="table table-bordered profile-table mt-1 mb-0">

                            <tr>
                                <td><p><strong>Disease</strong></p></td>
                                <td><p><strong>ICD code</strong></p></td>
                            </tr>

                            {diag.filter(item=>!(item.diagnosis === null && item.diagnosis_code === null)).map(item => (
                                <tr key={item.diagnosis_id}>
                                    <td><p>{item.diagnosis}</p></td>
                                    <td><p>{item.diagnosis_code}</p></td>
                                </tr>
                            ))}
                            <tr>
                                <td colSpan={2}><p><strong>Diagnosis Notes</strong></p></td>
                            </tr>
                            <tr>
                                <td colSpan={2}>{splitNotes(diag_notes)}</td>
                            </tr>
                    </table>
                </TableHeading>}

                {arr.length > 0 && <TableHeading title='Prescription'/>}
                {arr.length > 0 &&
                <CustomTableData
                    headData={["Drug Name", "Dosage", "Frequency", "Number of Days", "Instructions"]}>
                    {arr.map((item, index) => (
                        <tr key={index}>
                            <td><p>{item.product_name || item.other_medicine}</p></td>
                            <td><p>{item.dosage}</p></td>
                            <td><p>{item.frequency}</p></td>
                            <td><p>{item.number_of_days}</p></td>
                            <td><p>{item.key_information}</p></td>
                        </tr>
                    ))}
                </CustomTableData>}

                {referral.length > 0 && (option && option.length > 0) && option.map((item) => {
                    if (item.value === 'referral') {
                        return (
                            <div key={item.value}>
                                <TableHeading title='Referral Note'/>
                                <ReusableReferralSubHead referred_from={referred_from}
                                                            referred_to={referred_to}/>
                                <ReusableReferralDetails value={reason} text="Reason"/>
                                <ReusableReferralDetails value={refs.diagnosis ? refs.diagnosis : ''}
                                                            text="Diagnosis"/>
                                <ReusableReferralDetails value={treatment_given}
                                                            text="Treatment Given"/>
                            </div>
                        )
                    }
                })}
            </ReceiptTemplate>
        </div>
  )
}

