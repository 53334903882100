import * as React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import styled from "@emotion/styled/macro";
import * as colors from '../../../styles/colors'
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";





const ListContainer = styled.div({
    border:`1px solid ${colors.gray001}`,
    overflow:'hidden',
    borderRadius:'4px',
    background:colors.base,
    position:'relative',
    margin:'10px 0'
})
const ItemIcon = styled(ListItemIcon)({
    minWidth:'30px'
})

const StyledList = styled(List)({
    paddingTop:0,
    paddingBottom:0,

})

const ItemButton = styled(ListItem)({
    paddingLeft:'6px',
    paddingRight:'6px',
})

const ItemText = styled(ListItemText)({
    '& .MuiTypography-root':{
        fontSize:'14px',
        fontWeight:700

    }

})

const ArrowDown = styled(KeyboardArrowDownIcon)({
    width: '1rem',
    height:'1rem'
})

const ArrowRight = styled(KeyboardArrowRightIcon)({
    width: '1rem',
    height:'1rem'
})










function PreviousVisitContainer({handleOpen, children, name, open,idx, button=null}) {
    return (
        <ListContainer>
            <StyledList
                sx={{ width: '100%'}}
                component="nav"
                aria-labelledby="collapsible-list"
            >
                <ItemButton button  onClick={handleOpen} id={`visit-container-list-${idx}`}>
                    <ItemIcon>
                        {open ?   <ArrowDown /> : <ArrowRight/>}
                    </ItemIcon>
                    <ListItemText  primary={name}  />
                    {button}
                </ItemButton>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    {children}
                </Collapse>
            </StyledList>
        </ListContainer>
    );
}
export {PreviousVisitContainer,  ItemText}
