import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import {withStyles} from "@material-ui/core/styles/index";
import React from "react";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import {Button } from './styledComponents';

export const style = {
  textRight: {
    textAlign: 'right'
  },
  col: {
    maxWidth: 700,
    width: '90%',
    margin: 'auto'
  }

}
export const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    paddingTop:'8px',
    paddingBottom:'8px',
    fontSize: "13px"
  },
  title:{
    paddingTop:'8px',
    paddingBottom:'8px'

  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(),
    top: theme.spacing(),
    color: theme.palette.grey[500],
    padding:0
  },
  printButton: {
    position: "absolute",
    right: '45px',
    top: theme.spacing(),
  }
}))(props => {
  const {children, classes, onClose, isPrint, printSurgery} = props;

  return (
    <MuiDialogTitle disableTypography classes={{root:classes.root}} >
      <Typography variant="h6">{children}</Typography>

      {isPrint ? (
            <Button variant='orange'className={classes.printButton} onClick={printSurgery}>Print</Button>
          ) : null}

      {onClose ? (
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon/>
        </IconButton>
      ) : null}

    </MuiDialogTitle>
  );
});

export const DialogContent = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(3),
    fontSize: "13px"
  }
}))(MuiDialogContent);

export const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(),
    fontSize: "13px"
  }
}))(MuiDialogActions);
