/** @jsxImportSource @emotion/react */
import React, {useEffect, useState} from 'react'
import {css} from '@emotion/react';
import {detailsTable, table, textWeight} from "../../styles/tableStyles";
import {PrintTemplate} from "../Utils/Templates/PrintTemplate";
import styled from "@emotion/styled/macro";
import axios from "axios";
import {config} from "../../Helpers/env";

const heading = css`
    display: inline-block;
    text-decoration: underline;
`
const widths= {
    '100':{
        width:'100%'
    },
    '80':{
        width:'80%'
    },
    '60':{
        width:'60%'
    },
    '40':{
        width:'40%'
    },
    '20':{
        width:'20%'
    },
    '10':{
        width:'10%'
    },
    '5':{
        width:'5%'
    }
}
const ResultSpan = styled.span({
    display: 'inline-block',
    borderBottom: '2px dotted #807d7d',
    marginTop:' 5px',
    lineHeight:2
},({width})=>widths[width])

const Span = styled.span({
    display:'inline-block',
    marginRight:'8px',
    padding:'0.25rem 0',
    lineHeight:2
})

const splitText = (txt = '', testId='') =>{
    const nt = txt ? txt : '';
    return nt.split('\n').map(text=><ResultSpan width='100' data-testid={testId}>{text}</ResultSpan>)
}

export function ExaminationDetails(props) {
    const {complaints, history_of_presenting_complaints, past_medical_history,
        investigations, treatment_plan, extraOral, softIntraOral, hardIntraOral, diagnosis,
        medication} = props

    const joinText = (text='') =>{
        return  text.split('_').join(' ')
    }


    const notesTable = (
        <table css={[table,detailsTable]}>
            <tbody>
            <tr>
                <td colSpan={2}><span>Presenting complaints (PC) {complaints ? splitText(complaints,'complaints') : complaints}</span></td>
            </tr>
            <tr>
                <td><span>History of presenting complaints (HPC) {history_of_presenting_complaints ? splitText(history_of_presenting_complaints, 'complaints_history'): history_of_presenting_complaints}</span></td>
            </tr>
            <tr>
                <td><span>Past medical history (PMH) {past_medical_history ? splitText(past_medical_history, 'medical_history'): past_medical_history}</span></td>
            </tr>
            </tbody>
        </table>
    )


    const examinationTable = (
        <table css={[table,detailsTable]}>
            <tbody>
            <tr>
                <td colSpan={3}><span css={[textWeight]}>Examination </span></td>
            </tr>
            <tr>
                <td colSpan={3}><span css={[heading,textWeight]}>Extra oral </span></td>
            </tr>
            <tr>
                <td width='100px'><span>Scalp  <ResultSpan width='100' data-testid='scalp'>{extraOral?.scalp}</ResultSpan></span></td>
                <td width='100px'><span>Hair  <ResultSpan width='100' data-testid='hair'>{extraOral?.hair}</ResultSpan></span></td>
                <td width='100px'><span>Ears  <ResultSpan width='100' data-testid='ears'>{extraOral?.ears}</ResultSpan></span></td>
            </tr>
            <tr>
                <td width='100px'><span>Eyes  <ResultSpan width='100' data-testid='eyes'>{extraOral?.eyes}</ResultSpan></span></td>
                <td width='100px'><span>Nose  <ResultSpan width='100' data-testid='nose'>{extraOral?.nose}</ResultSpan></span></td>
                <td width='100px'><span>TMJ  <ResultSpan width='100' data-testid='tmj'>{extraOral?.tmj}</ResultSpan></span></td>
            </tr>
            <tr>
                <td width='100px'><span>Lymph  <ResultSpan width='100' data-testid='lymph'>{extraOral?.lymph}</ResultSpan></span></td>
                <td width='100px'><span>Nodes  <ResultSpan width='100' data-testid='nodes'>{extraOral?.nodes}</ResultSpan></span></td>
                <td width='100px'><span>Lips  <ResultSpan width='100' data-testid='lips'>{extraOral?.lips}</ResultSpan></span></td>
            </tr>

            <tr>
                <td colSpan={2}><span css={[heading, textWeight]}>Intra oral(soft tissue) </span></td>
            </tr>
            <tr>
                <td><span>Tongue  <ResultSpan width='100'  data-testid='tongue'>{softIntraOral?.tongue}</ResultSpan></span></td>
                <td><span>Tonsilis  <ResultSpan width='100'  data-testid='tonsilis'>{softIntraOral?.tonsilis}</ResultSpan></span></td>
                <td><span>Uvula  <ResultSpan width='100'  data-testid='uvula'>{softIntraOral?.uvula}</ResultSpan></span></td>

            </tr>
            <tr>
                <td><span>Mucosa  <ResultSpan width='100'  data-testid='mucosa'>{softIntraOral?.mucosa}</ResultSpan></span></td>
                <td><span>Palate  <ResultSpan width='100'  data-testid='palate'>{softIntraOral?.palate}</ResultSpan></span></td>
            </tr>
            <tr>
                <td><span>Gingiva  <ResultSpan width='100'  data-testid='gingiva'>{softIntraOral?.gingiva}</ResultSpan></span></td>
                <td><span>Vestibule  <ResultSpan width='100'  data-testid='vestibule'>{softIntraOral?.vestibule}</ResultSpan></span></td>
            </tr>
            <tr>
            </tr>


            <tr>
                <td colSpan={2}><span css={[heading,textWeight]}>Intra oral(hard tissue) </span></td>
            </tr>
            <tr>
                <td><span>Occlusion  <ResultSpan width='100'  data-testid='occlusion'>{hardIntraOral?.occlusion}</ResultSpan></span></td>
                <td><span>Decayed  <ResultSpan width='100'  data-testid='decayed'>{hardIntraOral?.decayed}</ResultSpan></span></td>
                <td colSpan={2}><span>Artificial tooth/teeth  <ResultSpan width='100'  data-testid='artificial_teeth'>{hardIntraOral?.artificial_teeth}</ResultSpan></span></td>

            </tr>
            <tr>
                <td><span>Missing  <ResultSpan width='100'  data-testid='missing'>{hardIntraOral?.missing}</ResultSpan></span></td>
                <td><span>Filled  <ResultSpan width='100'  data-testid='filled'>{hardIntraOral?.filled}</ResultSpan></span></td>
            </tr>
            <tr>
            </tr>

            <tr>
                <td colSpan={3}><span>Investigations <ResultSpan width='100' data-testid="investigation">{investigations ? joinText(investigations): investigations}</ResultSpan></span></td>
            </tr>
            {diagnosis?.length > 0 ? <>
            <tr>
                <td><span css={[heading,textWeight]}>Diagnosis</span></td>
            </tr>
            {diagnosis?.map((item, index)=>{
                return(
                    <tr key={index}>
                        <td colSpan={3}><span data-testid={`disease_name-${index}`}>{item?.diagnosis}</span></td>
                    </tr>
                )
            })}
            </>: null}
            <tr>
                <td colSpan={3}><span>Treatment plan management: {treatment_plan ? splitText(treatment_plan, 'treatment_plan'): treatment_plan}</span></td>
            </tr>
            {medication?.length > 0 ? <>
            <tr>
                <td colSpan={3}><span css={[heading,textWeight]}>Medication</span></td>
            </tr>
            {medication?.map((item, index)=>{
                return (
                    <tr key={index}>
                        <td colSpan={3}>
                            <span data-testid={`product_name-${index}`}>{item.product_name}</span><br/>
                            {item.quantity_prescribed ? <Span>Quantity prescribed: <strong>{item.quantity_prescribed}</strong></Span>: null}
                            {item.dosage ? <Span>Dosage: <strong>{item.dosage}</strong></Span> : null}
                            {item.frequency ? <Span>Frequency: <strong>{item.frequency}</strong></Span>: null}
                            {item.number_of_days ? <Span>Duration: <strong>{item.number_of_days}</strong></Span>: null}
                            {item.key_information ? <Span>Instructions: <strong>{item.key_information}</strong></Span>: null}
                        </td>
                    </tr>
                )
            })}
            </>: null}
            </tbody>
        </table>
    )
    return(
       <>
           {notesTable}
           {examinationTable}
       </>
    )
}

function DentalExaminationReport(props) {
    const {patient_number,children} = props

    const [patient, setPatient] = useState({})



    useEffect (() => {
        if (!patient_number){
            return
        }
        const formData = new FormData();
        formData.append('patient_number', patient_number)
        axios.post(`${config.smsUrl}/cpatient/patient_update_form`, formData).then(response => {
            const patient = response.data;
            const patientDetails = !patient ? {} : patient;
            setPatient({
                ...patientDetails,
                patient_name: `${!patientDetails.first_name ? "" : patientDetails.first_name} ${!patientDetails.last_name ? "" : patientDetails.last_name}`,
                age: patientDetails.Age,
                gender: patientDetails.gender,
                address: patientDetails.address,
                phone: patientDetails.phone_no
            })

        }).catch(err => {
            // logoutErrorMessage(err, null, actions)
        } )

    },[patient_number])


    const patientDetails = (
        <tbody>
        <tr>
            <td><span>Patient No.: </span> <span css={[textWeight]}>{patient?.patient_number}
                    </span> </td>
            <td><span>Age:</span> <span css={[textWeight]}>{patient?.age}</span></td>

        </tr>

        <tr>

            <td><span>Patient Name: </span> <span css={[textWeight]}>{patient?.patient_name}</span></td>
            <td><span>Phone No.:</span> <span css={[textWeight]}>{patient?.phone}</span></td>
        </tr>
        <tr>
            <td><span>Gender: </span> <span css={[textWeight]}>{patient?.gender}</span></td>
            <td><span>Address:</span> <span css={[textWeight]}>{patient?.address}</span></td>
        </tr>
        </tbody>
    )

    const itemDetails = (
        <>
            <ExaminationDetails {...{...props}}/>
            {children}
        </>
    )

    return(
        <div>
            <PrintTemplate  {...{patientDetails,itemDetails}}/>
        </div>
    )
}
export default DentalExaminationReport