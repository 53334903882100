import React from "react";
import Form from "react-bootstrap/Form";
import TextField from "../Utils/FormInputs/TextField";
import Label from "../Utils/FormInputs/Label";
import {ErrorMessage} from "../Utils/styledComponents";

const AttachPatient = ({p_number,showNumber,patientNumbers,patientDetails,submitted,
    handleClickPatientNumber,handleChangePatientNumbers, children}) => {

    const {age, gender, patient_number} = patientDetails;
    return (
            <div>
                <Form.Group>
                    <Label name="Patient Name" type/>
                    
                        <TextField type="text"
                                        name="p_number"
                                        value={p_number}
                                        onChange={handleChangePatientNumbers}/>
                        {(submitted && !p_number) &&
                        <ErrorMessage>Patient name is required</ErrorMessage>}
                        {showNumber && <Form.Control as="select" multiple id="patient_number_select"
                                                        className="drug-ul visit-ul">
                            {patientNumbers.map((number, index) => (
                                <option className="drug-li" key={index}
                                        onClick={()=>handleClickPatientNumber({value:number.value, label:number.label})}
                                        value={number.value} id="numbers">{number.label}</option>
                            ))}
                        </Form.Control>}
                    
                </Form.Group>
                <Form.Group >
                    <Label name="Patient Number" type/>
                    
                        <TextField type="text" name="patient_number" value={patient_number}
                                    disabled={true}/>
                    
                </Form.Group>
                <Form.Group >
                    <Label name="Age"/>
                    
                        <TextField type="text" name="last_name" value={age} 
                                    disabled={true}/>
                    
                </Form.Group>
                <Form.Group >
                    <Label name="Gender"  type/>
                    
                        <TextField type="text" name="last_name" value={gender} 
                                    disabled={true}/>
                    
                </Form.Group>
                {children}
            </div>
    )
};


export {AttachPatient};
