import React, {useEffect, useState} from "react";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faNotesMedical} from "@fortawesome/free-solid-svg-icons/faNotesMedical";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as departmentActions from "../../actions/DoctorsActions/departmentActions";
import * as doctorActions from "../../actions/DoctorsActions/doctorActions";
import * as snackbarActions from "../../actions/snackbarActions";
import PageTitle from "../Utils/smsTitle";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import {RightAlignedContainer} from "../Utils/styledComponents";
import {MiniHeader} from "../Utils/Menu/MiniSubHeader";
import Triage from "../Triage/Triage"
import {PatientDetails} from "../Visit/NewService/SelectService";
import {useTakeVitals} from "./hooks/useTakeVitals";
import axios from "axios";
import { config } from "../../Helpers/env";
import {logoutErrorMessage} from "../../Helpers/ErrorMessages";
import styled from "@emotion/styled/macro";
import * as mq from "../../styles/media-queries";
import {BackButton} from "../Utils/Buttons/DataExportationButton";

const TriageContainer = styled.div({
    width: "80%",
    margin: "0 auto",
    marginTop:'50px',
    [mq.medium]:{
        width:'1080px',
    }
});


const TakeVitals = ({actions, match:{params},  snackbar}) => {
    const {patient_number, visit_id, activity_id} = params;
    const  {triageProps, handleCloseSnackbar, handleSubmitTriage, isPending} = useTakeVitals(actions, {p_number:patient_number, visit_id, activity_id});
    const [patientDetails, setPatientDetails] = useState({})

    useEffect(()=>{
        const formData = new FormData();
        formData.append('patient_number', patient_number);
        axios.post(`${config.smsUrl}/cdoctor/get_patient_details`, formData)
            .then(res => {
                const details = res.data;
                const dt = details ? details : {}
                const {first_name, last_name, Age, gender} = dt
                setPatientDetails({first_name: first_name ? first_name : '', last_name: last_name ? last_name : '', age:Age ? Age : '', gender:gender ? gender : ''})
            }).catch(error => {
            logoutErrorMessage(error, null, actions)
        })
    },[])



    const {first_name, last_name,age, gender} = patientDetails;


    const {openBar, type, message} = snackbar;

    return (
        <div>
            <PageTitle title="Take Vitals"/>
            <SubHeader title="Visit" subTitle="Take Vitals">
                <FontAwesomeIcon icon={faNotesMedical}/>
            </SubHeader>
            <MainSnackbar variant={type} message={message} open={openBar} handleCloseBar={handleCloseSnackbar}/>
            <TriageContainer>
                <div className='mb-3'>
                    <BackButton text='Triage Waiting List' to='/triagewaitinglist'/>
                </div>
                <MiniHeader title="Take Vitals"/>
                <form autoComplete='off' onSubmit={handleSubmitTriage}>
                    <div className="mb-5 mt-3">
                        <PatientDetails {...{patient_number:patient_number,patient_name:`${first_name ?? ''} ${last_name ?? ''}`,age, gender}}/>
                    </div>
                    <Triage {...triageProps}>
                        <RightAlignedContainer>
                            <button type="submit" disabled={isPending}
                                    className="btn btn-sm sms-info-btn px-4">Save</button>

                        </RightAlignedContainer>
                    </Triage>
                </form>

            </TriageContainer>

        </div>
    )
};

function mapStateToProps(state) {
    const {departments, doctors, snackbar} = state;
    return {departments, doctors, snackbar}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            departmentActions: bindActionCreators(departmentActions, dispatch),
            doctorActions: bindActionCreators(doctorActions, dispatch),
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TakeVitals);
