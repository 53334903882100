import Form from "react-bootstrap/Form";
import Label from "../../../../Utils/FormInputs/Label";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TextField from '../../../../Utils/FormInputs/TextField';
import { FormLabel } from "../../../../Utils/FormInputs/Label";
import {AnteNatalProgressExamTable} from "./AnteNatalTables";

const AnteNatalThirdScreen = ({third_screen_props}) => {

    const  {
        recommendations_delivery, handleRecommendations, progress_table_props, treatment_notes, handleNotesChange,
    } = third_screen_props;

    const {high_risk_mother, partner_disclosure, adherence_counselling, infant_feeding_counselling, referred_psychosocial,
        niverapine_syrup} = recommendations_delivery;

    return (
        <div data-testid="ante-natal-patient-form-third-screen">

            <div>
                <h5 className="antenatal-form">ANTENATAL PROGRESS EXAMINATION</h5>
                <hr class="rounded"/>
                <AnteNatalProgressExamTable progress_table_props={progress_table_props}/>
            </div>

            <div>
                <h5 className="antenatal-form">RISK FACTORS / RECOMMENDATION FOR DELIVERY</h5>
                <hr class="rounded"/>
                <Row className="surgery-schedule-flex">
                    <Col>
                        <Label name="High risk Mother"/>
                        <TextField autocomplete="off" name="high_risk_mother" value={high_risk_mother} onChange={handleRecommendations}/>
                    </Col>
                    <Col>
                        <Label name="Partner disclosure support done:"/>
                        <TextField autocomplete="off" name="partner_disclosure" value={partner_disclosure} onChange={handleRecommendations}/>
                    </Col>
                    <Col>
                        <Label name="Adherence Counseling"/>
                        <TextField autocomplete="off" name="adherence_counselling" value={adherence_counselling} onChange={handleRecommendations}/>
                    </Col>
                </Row>

                <Row className="surgery-schedule-flex">
                    <Col>
                        <Label name="Infant feeding counseling done"/>
                        <TextField autocomplete="off" name="infant_feeding_counselling" value={infant_feeding_counselling} onChange={handleRecommendations}/>
                    </Col>
                    <Col>
                        <Label name="Referred psychosocial groups (PSSF5G)"/>
                        <TextField autocomplete="off" name="referred_psychosocial" value={referred_psychosocial} onChange={handleRecommendations}/>
                    </Col>
                    <Col>
                        <Label name="Niverapine Syrup for baby"/>
                        <TextField autocomplete="off" name="niverapine_syrup" value={niverapine_syrup} onChange={handleRecommendations}/>
                    </Col>
                </Row>
            </div>

            <div>
                <Form.Group>
                    <FormLabel className="antenatal-form" name='NOTES/TREATMENT' toolTip='Capture diagnosis, other prescriptions, complications and risk factors '/>
                    <hr class="rounded"/>
                    <Form.Control as="textarea" rows="8" cols="4" name="treatment_notes" id="treatment_notes" autoComplete="off"
                                value={treatment_notes} onChange={handleNotesChange} className={`form__control`}/>
                </Form.Group>
            </div>
        </div>
    )
}


export default AnteNatalThirdScreen;