import { history } from "./history";
import { snackError, snackInfo } from "../actions/snackbarActions";

export const errorMessages = (error, dispatch, actions) => {
  // console.log(error.response)
  const response = !error?.response ? {} : error?.response;
  const errorStatus = !response?.status ? "" : response?.status;
  let msg = response?.data;
  let message = !msg ? {} : msg;
  if (typeof message === "object") {
    message = message.message;
  } else {
    message = !response.data ? "" : response.data;
  }
  const custom_message = message
    ? message
    : "Operation unsuccessful. An error occurred on the server";
  const message_response = !response.data ? {} : response.data;
  const error_message = message_response
    ? message_response.error || message_response.message
    : "Operation unsuccessful. An error occurred on the server";

  switch (errorStatus) {
    case 403:
      actions
        ? actions.snackbarActions.snackError("You need to login first")
        : dispatch(snackError("You need to login first"));
      setTimeout(() => {
        history.push("/login");
      }, 2000);
      break;
    case 406 || 400:
      actions
        ? actions.snackbarActions.snackError(message)
        : dispatch(snackError(message));
      break;
    // case 401:
    //     actions ? actions.snackbarActions.errorSnackbar(message):
    //         dispatch(errorSnackbar(message));
    //     setTimeout(()=>{
    //         history.push('/subscriptionexpired')
    //     },1000);
    //     break;
    //informative responses
    case 405: //# this shows informative message instead of errors
      actions
        ? actions.snackbarActions.snackInfo(error_message)
        : dispatch(snackInfo(error_message));
      break;
    // case 400:
    //   actions
    //     ? actions.snackbarActions.snackError(message)
    //     : dispatch(snackError(message));
    //   break;
    case 404:
      actions
        ? actions.snackbarActions.snackError(
            "The server cannot find the requested resource"
          )
        : dispatch(snackError("The server cannot find the requested resource"));
      break;
    case 500:
      actions
        ? actions.snackbarActions.snackError(custom_message)
        : dispatch(snackError(custom_message));
      break;
    default:
      actions
        ? actions.snackbarActions.snackError(
            "Operation unsuccessful. An error occurred on the server"
          )
        : dispatch(
            snackError(
              "Operation unsuccessful. An error occurred on the server"
            )
          );
      break;
  }
};

export const logoutErrorMessage = (error, dispatch, actions) => {
  const response = !error.response ? {} : error.response;
  const errorStatus = !response.status ? "" : response.status;
  if (errorStatus === 403) {
    actions
      ? actions.snackbarActions.snackError("You need to login first")
      : dispatch(snackError("You need to login first"));
    setTimeout(() => {
      history.push("/login");
    }, 2000);
  }
};
