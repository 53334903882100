import React from "react";
import Form from "react-bootstrap/Form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus} from "@fortawesome/free-solid-svg-icons/faMinus";
import { AutoCompleteProducts } from "../../Utils/FormInputs/AutoCompleteInput";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as departmentActions from "../../../actions/DoctorsActions/departmentActions";
import * as snackbarActions from "../../../actions/snackbarActions";
import Label from "../../Utils/FormInputs/Label";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {useTheme} from "@material-ui/core/styles";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import Card from "@material-ui/core/Card";
import {faBed} from "@fortawesome/free-solid-svg-icons/faBed";
import {useAddRequisition} from "./hooks/useAddRequisition";
import PrintDialog from "../../Utils/Dialogs/PrintDialog";
import TextField from "../../Utils/FormInputs/TextField";
import { ErrorMessage } from "../../Utils/styledComponents";


export const RequisitionForm = ({products, items, state, requested_by, 
    retrieveProducts, clickProduct, handleChangeItems, handleAddRow,
    removeRow, handleSubmit,  user_name, submitted,  isPending, department=''}) =>{
    const {date_requested} = state;
    const all_drugs = !items ? [] : items;
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    return(
        <form onSubmit={handleSubmit} autoComplete="off">     
            <div className="row">
                <div className="col-lg-6">
                    <Form.Group as={Row}>
                        <Label name="Date Requested" htmlFor='date_requested' sm="3" column/>
                        <Col sm="6">
                            <Form.Control type="date" value={date_requested} disabled={true}
                                        className={`form__control ${palette.type === "dark" && 'form-control-dark'}`}
                                        id='date_requested'
                                        name="date_requested"/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Label name="Requested By" htmlFor='requestedby' sm="3" column/>
                        <Col sm="6">
                            <Form.Control type="text"
                                        className={`form__control ${palette.type === "dark" && 'form-control-dark'}`}
                                        name="requested_by" id='requestedby'value={requested_by} disabled={true}/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Label name="Email" htmlFor='email' sm="3" column/>
                        <Col sm="6">
                            <Form.Control type="email" value={user_name} disabled={true} id='email'
                                        className={`form__control ${palette.type === "dark" && 'form-control-dark'}`}
                            />
                        </Col>
                    </Form.Group>
                </div>
            </div>
            <div className="table-responsive">
                <table
                    className={`table table-sm table-bordered ${palette.type === 'dark' && 'dark-table dark-table-bordered'}`}>
                    <thead>
                    <th className={palette.type === 'dark' && 'dark-theme-color'}>Particulars<span className="help-block">*</span>
                    </th>
                    <th className={palette.type === 'dark' && 'dark-theme-color'}>Store Stock</th>
                    <th className={palette.type === 'dark' && 'dark-theme-color'}>Quantity<span className="help-block">*</span>
                    </th>
                    <th className={palette.type === 'dark' && 'dark-theme-color'}>Available Stock{department ? `(${department})` : ''}</th>
                    <th/>
                    </thead>
                    <tbody>
                    {products.map((item, index) => {
                        const isSundries = item.category_name === "Sundries"
                        
                        return (
                        <tr key={index} data-testid="drugs-row">
                            <td>
                                <AutoCompleteProducts options={all_drugs} submitted={submitted} idx={index} handleChange={retrieveProducts} 
                                    medicine_value={{label:item.product_name, value: item.product_id, generic_name:item.generic_name}} id="test_name" name="drug_name"
                                    errorMsg_Valid={"Please enter a valid product"} valid_error_check={item.isError && submitted} testId={`product_name-${index}`}
                                    handleProductData={clickProduct} placeholder="Type Product Name" errorMsg="Please a select a valid product"/>

                            </td>
                            <td>{item.available_quantity}</td>
                            <td><TextField submitted={submitted} value={item.quantity} name="quantity"
                                            onChange={(e) => handleChangeItems(e, index)} type="number"
                                            data-testid={`quantity-${index}`} placeholder="Input quantity"/>
                                {item.quantity && item.available_quantity === 0 ? <ErrorMessage>No stock available for this product</ErrorMessage> : null }
                                {(submitted && !item.quantity)  && <ErrorMessage>Quantity is required</ErrorMessage>}
                            </td>

                            <td>
                                {
                                  isSundries ?
                                  <>
                                      <TextField submitted={submitted} value={item.item_quantity} name="item_quantity"
                                                 onChange={(e) => handleChangeItems(e, index)} type="number"
                                                 data-testid="item_quantity" placeholder="Input quantity"/>
                                      {(submitted && item.item_quantity !== '0' )  && <ErrorMessage>Quantity should be zero</ErrorMessage>}
                                  </>:item.item_quantity
                                }

                            </td>

                            <td>
                                    <button onClick={() => removeRow(item.uuid)} type="button"
                                            className="btn btn-sm sms-btn-dismiss" data-testid="remove-row">
                                        <FontAwesomeIcon icon={faMinus}/>
                                    </button>
                            </td>
                        </tr>)})}
                        <tr>
                            <td colSpan={4}>
                                <button type="button" className="btn btn-sm sms-gray-btn" id="add-requisition-row"
                                        onClick={handleAddRow}>Add another line
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <button type="submit" disabled={isPending}
                    id="yesBtn" className="btn sms-btn btn-sm ">{isPending ? "Saving..." : "Save"}</button>
    </form>
    )

}



const AddRequisition = ({actions, snackbars, match}) => {
    const {component} = match.params;
    const  { closeSnackBar,handleCancel,handleClick,handleCloseDialog,openDialog,  requisitionProps} = useAddRequisition(actions,component)
    const {openBar, type, message} = snackbars;
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    return (
        <div data-testid="add-requisition">
            <PageTitle title="Make Requisition"/>
            <SubHeader title="Ward Manager" subTitle="Make requisition">
                <FontAwesomeIcon icon={faBed}/>
            </SubHeader>
            <PrintDialog openDialog={openDialog} handleClose={handleCloseDialog} message="Print Requisition Note"
                         text="Would you like to print this requisition note" handleClick={handleClick} handleCancel={handleCancel}/>
            <MainSnackbar message={message} variant={type} handleCloseBar={closeSnackBar} open={openBar}/>
            <div className="mui-tables">
                <Card className="p-3 general-card">
                    <h6 className={`visit-heading ${palette.type === "dark" && "dark-visit-heading"}`}>Make
                        Requisition</h6>
                    <RequisitionForm {...requisitionProps}/>
                </Card>
            </div>
        </div>

    )
};

function mapStateToProps(state) {
    return {
        snackbars: state.snackbar,
        departs: state.departments
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch),
            departmentActions: bindActionCreators(departmentActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddRequisition);
