import React, {useEffect, useState} from "react";
import * as snackbarActions from "../../actions/snackbarActions"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PageTitle from './../Utils/smsTitle';
import { TableCell, TableBody, TableRow } from "@material-ui/core";
import CustomTable from "../Utils/Tables/CustomTable";
import {dateConvert, formatDate} from "../Utils/ConvertDate";
import {SubHeader} from "../../Containers/SubHeader";
import axios from "axios";
import {config} from "../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../Helpers/ErrorMessages";
import {SurgeryDateFilter} from "./SurgeryDateFilter";
import {PrintSurgeryReport} from "./PrintSurgeryReport";

/**
 * table heading data
 * @type {[{disablePadding: boolean, numeric: boolean, id: string, label: string},{disablePadding: boolean, numeric: boolean, id: string, label: string},{disablePadding: boolean, numeric: boolean, id: string, label: string},{disablePadding: boolean, numeric: boolean, id: string, label: string},{disablePadding: boolean, numeric: boolean, id: string, label: string},null,null,null]}
 */
const headData = [{id: 'count', numeric: false, disablePadding: false, label: 'SL'},
    {id: 'date', numeric: false, disablePadding: false, label: 'Operation Date'},
    {id: 'patient_number', numeric: false, disablePadding: false, label: 'Patient No.'},
    {id: 'patient_name', numeric: false, disablePadding: false, label: 'Patient Name'},
    {id: 'department_name', numeric: false, disablePadding: false, label: 'Department'},
    {id: 'operation_class', numeric: false, disablePadding: false, label: 'Operation Class'},
    {id: 'service_name', numeric:false, disablePadding:false, label: 'Surgery Performed'},
    {id: 'actions', numeric: false, disablePadding: true, label: 'Action'},
];


/**
 * Component to display table for past surgeries
 */
const PastSurgeries = ({actions}) => {
    const [searchValue, setSearchValue] = useState('');
    const [status, setStatus] = useState('idle');
    const [activePage, setActivePage] = useState(1);
    const [surgeryList, setSurgeryList] = useState({surgeries: [], total_count:0});
    const [surgeryItem, setSurgeryItem] = useState({})
    const [openDialog, setOpenDialog] = useState(false)
    const [filter_by, setFilterBy] = useState({value:'1', label:'Operation Date'})
    const [filter_type, setFilterType] = useState({})
    const [filterTypes, setFilterTypes] = useState([])
    const [date, setDate] = useState({start_date:dateConvert(), end_date:dateConvert()})
    const [isSubmitted, setIsSubmitted] = useState('idle')
    const [submitted, setSubmitted] = useState(false)

    const filterBy = filter_by?.value

    const {start_date, end_date} = date

    const isResolved = isSubmitted === 'resolved'


    const dateKey =  {
        '2':'department_id',
        '3':'operation_class',
        '4':'surgery_performed'
    }


    /**
     * Retrieve surgeries when the components mounts
     */
    useEffect(() => {

        setStatus('pending');
        const formD = new FormData();
        formD.append("status", 2);
        formD.append("page", activePage);
        formD.append("search", searchValue);

        formD.append('option',filterBy);
        if (filterBy === '1'){
            formD.append('start_date', start_date);
            formD.append('end_date', end_date);
            // formD.append("department_id", '');
            // formD.append('operation_class','');
            // formD.append('surgery_performed','');
        }else {
            formD.append(dateKey[filterBy],filter_type?.value ? filter_type?.value : '');
        }
        axios.post(`${config.smsUrl}/surgery/list/${activePage}`, formD).then(res => {
            const response = res['data'];
            const dt = response ? response : [];
            const surgery_arr = dt.map((item, index) => ({
                ...item,
                count: (activePage - 1) * 10 + (index + 1),
                patient_name: `${item.first_name} ${item.last_name}`,
                operation_date: item.operation_date ? formatDate(item.operation_date) : item.operation_date,
                action: (
                    <button onClick={() => {
                        setOpenDialog(true)
                        setSurgeryItem(item)
                    }}
                            className="btn btn-sm sms-info-btn mr-1">View</button>
                )
            }));
            setSurgeryList({surgeries: surgery_arr, total_count: surgery_arr.length});
            setStatus('success');
        }).catch(err => {
            errorMessages(err, null, actions)
             setStatus('error')
        })

    }, [activePage,searchValue, filter_type, isResolved]);


    /**
     * onChange for searching the list
     * @param event
     */
    const handleSearch = (event) => {
        setSearchValue(event.target.value);
    }

    /**
     * onChange for page numbers
     * @param page
     */
    const handleChangeNewPage = (page) => {
        setActivePage(page);
    }


    /**
     * Retrieve departments api call
     */
    const retrieveDepartments = () => {
        axios.get(`${config.smsUrl}/cvisit/retrieve_department`).then(res => {
            const data = res.data;
            const dt = !data ? [] : data;
            const departs = !dt.departments ? [] : dt.departments;
            const arr = departs.map(department => ({value: department.department_id,
                label: department.department_name
            }))
            setFilterTypes(arr)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }

    /**
     * Retrieve surgeries api call
     */
    const  retrieveSurgeriesPerformed = () => {
        const formData = new FormData()
        formData.append('service_type','Surgery')
        axios.post(`${config.smsUrl}/cvisit/retrieve_services_by_service_type`, formData).then(res => {
            const data = res.data;
            const dt = !data ? [] : data;
            const arr = dt.map(department => ({value: department.service_id,
                label: department.service_name
            }))
            setFilterTypes(arr)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }

    /**
     * onChange for filter_by
     * @param value
     */
    const handleChangeFilterBy = (value) =>{
        setFilterBy(value)
        if (value?.value === '4'){
            retrieveSurgeriesPerformed()
        }else if (value?.value === '2'){
            console.log('yeye')
            retrieveDepartments()
        }
    }

    /**
     * onChange for dates
     * @param event
     */
    const handleChange = (event) =>{
        const {name,value} = event.target;
        setDate({...date,[name]:value})
    }

    /**
     * onSubmit for date filters
     * @param event
     */
    const handleSubmit = (event) =>{
        event.preventDefault()
        setSubmitted(true)
        if (start_date && end_date){
            setIsSubmitted('pending')
            setStatus('pending');
            const formD = new FormData();
            formD.append("status", 2);
            formD.append("page", activePage);
            formD.append("search", searchValue);
            formD.append('option',filterBy);
            if (filterBy === '1'){
                formD.append('start_date', start_date);
                formD.append('end_date', end_date);
                // formD.append("department_id", '');
                // formD.append('operation_class','');
                // formD.append('service_id','');
            }else {
                formD.append(dateKey[filterBy],filter_type?.value ? filter_type?.value : '');
            }
            axios.post(`${config.smsUrl}/surgery/list/${activePage}`, formD).then(res => {
                const response = res['data'];
                const dt = response ? response : [];
                const surgery_arr = dt.map((item, index) => ({
                    ...item,
                    count: (activePage - 1) * 10 + (index + 1),
                    patient_name: `${item.first_name} ${item.last_name}`,
                    operation_date: item.operation_date ? formatDate(item.operation_date) : item.operation_date,
                    action: (
                        <button onClick={() => {
                            setOpenDialog(true)
                            setSurgeryItem(item)
                        }}
                                className="btn btn-sm sms-info-btn mr-1">View</button>
                    )
                }));
                setSurgeryList({surgeries: surgery_arr, total_count: surgery_arr.length});
                setStatus('success');
                setIsSubmitted('resolved')
                setSubmitted(false)
            }).catch(err => {
                errorMessages(err, null, actions)
                setStatus('error')
                setIsSubmitted('rejected')
                setSubmitted('false')
            })
        }
    }


    const isLoading = status === 'pending';
    const isSuccess = status === 'success';
    const isError = status === 'error';

    const isPending = isSubmitted === 'pending'

    const {surgeries, total_count} = surgeryList;

    return (
        <div>
            <PageTitle title="Past Surgeries List"/>
            <SubHeader title="Surgery" subTitle="Past Surgery List" >
                <img src="/images/surgery-21.png" alt="surgery" className="icon-resize surgery-icon-tabs"/>
            </SubHeader>
            <PrintSurgeryReport {...{actions, surgeryItem, openDialog, setOpenDialog}}/>

            <div className='mui-tables'>
                <SurgeryDateFilter {...{filter_by, handleChange, start_date, end_date, submitted, handleSubmit, isPending,filterTypes,
                    handleChangeFilterBy, filter_type,setFilterType}}/>
                <CustomTable  headData={headData} handler={handleSearch} term={searchValue} searchPlaceHolder={"Search by Patient Name"} title="Past Surgeries"
                              data={surgeries} colSpan={headData.length} customPage records={10} activePage={activePage}
                              handleChangeNextPage={handleChangeNewPage} total_count={total_count}>
                    <TableBody>
                        {isLoading ? <TableRow></TableRow> : null}
                        {isSuccess ?  surgeries.length > 0 ?
                                surgeries.map((item,idx) => {
                                    return (
                                        <TableRow hover role ="checkbox" key={item.visit_id} tabIndex={-1} className="mui-table-row">
                                            {headData.map((col)=>(
                                                <TableCell key={item.id}>{item[col.id]}</TableCell>
                                            ))}
                                        </TableRow>
                                    )
                                }) :
                                <TableRow>
                                    <TableCell colSpan={headData.length} align="center">
                                        No records found
                                    </TableCell>
                                </TableRow>
                            : null
                        }
                        {/** Error handling */}
                        {isError ?
                            <TableRow>
                                <TableCell colSpan={headData.length} align="center">
                                    The server did not return a successful response
                                </TableCell>
                            </TableRow>
                            : null}
                    </TableBody>
                </CustomTable>
            </div>

        </div>
    )
};

function mapStateToProps(state) {
    const {snackbar} = state;
    return {snackbar}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PastSurgeries);