import * as types from "../../actions/actionTypes";

const user = JSON.parse(sessionStorage.getItem("user"));
const initialState = {
    loggedUser: user ? user : {}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case types.LOGIN_REQUEST:
            return {loading: true};
        case types.LOGIN_SUCCESS:
            return {...state, loggedUser: action.loggedUser, loading: false};
        case types.LOGIN_FAILURE:
            return {loading: false};
        default:
            return state;
    }

}
