import React, {useState} from "react";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faClipboardList } from "@fortawesome/free-solid-svg-icons/faClipboardList";
import {BackButton} from "../../Utils/Buttons/DataExportationButton";
import Form from "react-bootstrap/Form"
import Label from "../../Utils/FormInputs/Label";
import {CustomSelect} from "../../Utils/FormInputs/SelectInput";
import NumberofServicesPerformedTable from "./NumberofServicesPerformedTable";
import { useVersionsContext } from "../../../Context/versions-context";




const NumberofServicesPerformed = (props) => {

    const {subscription} = useVersionsContext()
    const version  = subscription?.version

    const components = version === "Basic" ?
        [{value:'consultation', label:'Consultation'},
        {value:'lab', label:'Laboratory'},
        {value:'scan', label:'Radiology'}] :  [
        {value:'consultation', label:'Consultation'},
        {value:'lab', label:'Laboratory'},
        {value:'scan', label:'Radiology'},
        {value:'dental', label:'Dental'}
    ]
    const [component, setComponent] = useState({value:'consultation', label:'Consultation'})

    const handleChangeComponent = (value) =>{
        setComponent(value)
    }

    const componentValue = component?.value ? component?.value : ''
    const title = {
        'consultation':'Consultation report',
        'lab':'Lab report',
        'scan':'Radiology',
        'dental':'Dental'
    }
    return (
        <div className='journals'>
            <PageTitle title={title[componentValue]}/>
            <SubHeader title="Reports" subTitle={title[componentValue]}>
                <FontAwesomeIcon icon={faClipboardList}/>
            </SubHeader>
            <div className='mui-tables mt-3'>
                <BackButton text='General Reports' to='/reports/generalreports'/>
                <Form.Group>
                    <Label name='Filter by service'/>
                    <div style={{width:'30%', marginBottom:'10px'}}>
                        <CustomSelect id='service_filter' options={components} onChange={handleChangeComponent} value={component}/>
                    </div>
                </Form.Group>
                <NumberofServicesPerformedTable component={componentValue}/>
            </div>

        </div>
    )
};
export default NumberofServicesPerformed;
