import {useEffect, useState} from "react";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";

const setShiftsEmployee = (shifts) => {

    const new_arr = shifts.map((item) => {
        const new_dict = {
            'shift_id' : item.shift_id,
            'shift_name' : item.shift_name,
            'end_time' : item.end_time,
            'start_time' : item.start_time,
            'days' : {
                'Monday' :false,
                'Tuesday' : false,
                'Wednesday' : false,
                'Thursday' : false,
                'Friday' : false,
                'Saturday' : false,
                'Sunday' : false            
            }
        }
        return new_dict;
    })

    return new_arr;
}

const convertForAPI = (employee_id, employee_shift) => {
    let monday = [];
    let tuesday = [];
    let wednesday = [];
    let thursday = [];
    let friday = [];
    let saturday = [];
    let sunday = [];

    employee_shift.forEach(element => {
        const shft_id = element.shift_id;
        Object.entries(element.days).forEach(([k,v]) => {
            if (v) {
                k === 'Monday' ? monday.push(shft_id) :
                k === 'Tuesday' ? tuesday.push(shft_id) :
                k === 'Wednesday' ? wednesday.push(shft_id) :
                k === 'Thursday' ? thursday.push(shft_id) :
                k === 'Friday' ? friday.push(shft_id) :
                k === 'Saturday' ? saturday.push(shft_id) :
                sunday.push(shft_id);
            }
        })
    });

    const new_dict = {
        employee_id,
        days : [
            {'Monday' : monday,
            'Tuesday' : tuesday,
            'Wednesday' : wednesday,
            'Thursday' : thursday,
            'Friday' : friday,
            'Saturday' : saturday,
            'Sunday' : sunday}     
        ]
    }
    return new_dict;
}

const convertFromAPI = (shifts, employee_shifts) => {
    // send days only
    // no need for the employee_id
    const new_arr = shifts.map((item) => {
        const shft_id = item.shift_id;
        const new_dict = {
            'shift_id' : item.shift_id,
            'shift_name' : item.shift_name,
            'end_time' : item.end_time,
            'start_time' : item.start_time,
            'days' : {
                'Monday' :employee_shifts.Monday.includes(shft_id),
                'Tuesday' : employee_shifts.Tuesday.includes(shft_id),
                'Wednesday' : employee_shifts.Wednesday.includes(shft_id),
                'Thursday' : employee_shifts.Thursday.includes(shft_id),
                'Friday' : employee_shifts.Friday.includes(shft_id),
                'Saturday' : employee_shifts.Saturday.includes(shft_id),
                'Sunday' : employee_shifts.Sunday.includes(shft_id),            
            }
        }
        return new_dict;
    })

    return new_arr;
}

export const useAssignShifts = (props) =>{
    const {actions} = props;

    const [shifts, setShifts] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState('idle');

    const [searchValue, setSearchValue] = useState('');
    const [openMenu, setOpenMenu] = useState(null);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('sl');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [employee, setEmployeeId] = useState({employee_id: '', status: 1,user_role_status:1, user_status:1});

    const [current_employee, setCurrentEmployee] = useState({
    employee_id: "", first_name: "",
    last_name: "", email: "", gender: "", address: "", mobile_no: "", kin_first_name: "",
    kin_telephone: "", kin_relationship: "", kin_last_name: "", kin_address: "", biography: "",
    specialist: "", education: "", status: "1", nin: "", dob: ""
    });

    const [employeeList, setEmployeeList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const [employee_count, setCount] = useState(0);
    const [st, setStatus] = useState('');

    const isResolved = isSubmitted === 'resolved';
         
    const [screen, setScreen] = useState("");

    const [openDialog, setOpenDialog] = useState(false);
    const [shiftsList, setShiftsList] = useState({});

    const [currentEmployeeShift, setCurrentEmployeeShift] = useState([]);

    const [isAssign, setIsAssign] = useState({});

    // get the shifts categories
    useEffect(() => {
        axios.get(`${config.smsUrl}/cshiftschedule/retrieve_shift_category`).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const arr = !dt.shift ? [] : dt.shift;
            const list = arr.filter(item=>Boolean(item))
            setShifts(list);
        }).catch(err => {
            logoutErrorMessage(err,null,actions)
        })

    }, []);
    
    // get the list of employees
    // will update the list of employees when deleting a shift assignment or assigning to change the statusS
    useEffect(() => {
        setLoading(true);
        const formData = new FormData();
        formData.append('search', searchValue);
        axios.post(`${config.smsUrl}/cshiftschedule/retrieve_shifts/${activePage}`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const empList = !dt.shift ? [] : dt.shift;
            const count = !dt.total_count ? 0 : dt.total_count;
            setLoading(false);
            setCount(count);
            setEmployeeList(empList);
        }).catch(e => {
            logoutErrorMessage(e, null, actions);
            setLoading(false)
        })
    }, [activePage, searchValue, st, isResolved]);

    // get the 
    
    // search according to employee name
    const handleSearch = (event) => {
        setSearchValue(event.target.value);
    };
     
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
     
    const handleChangeNewPage = (page) => {
        setActivePage(page);
    };

    const handleChangePage = (event, newPage) => {
        setActivePage(newPage);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
       // setActivePage(newPage);
        setPage(0);
    };
    
    const handleDelete = () => {
        setIsSubmitted("solving");
        const formData = new FormData();
        formData.append('employee_shift_id', current_employee.employee_shift_id);
        axios.post(`${config.smsUrl}/cshiftschedule/deactivate_shift`, formData).then(() => {
            actions.snackbarActions.snackSuccess('Shift deleted successfully');
            setIsSubmitted("resolved");
            handleCloseDialog();
        }).catch(err => {
            errorMessages(err, null, actions)
        })
    };
    
    const closeSnackBar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const handleOpenDialog = (item, screen_name) => {
        setCurrentEmployee(item);
        setIsSubmitted("solving");
        
        if (screen_name === 'edit') {
            const formData = new FormData();
            formData.append('employee_shift_id', item.employee_shift_id);
            // add the to save
            axios.post(`${config.smsUrl}/cshiftschedule/staff_shift_update_form`, formData).then(res => {
                const data = res.data;
                const dt = !data ? {} : data;
                const dl = convertFromAPI(shifts, data.days[0]);

                let dictionary = Object.assign({}, ...dl.map((x) => ({[x.shift_id]: x.days})));
                setIsAssign(dictionary);

                setCurrentEmployeeShift(dl);
                setShiftsList(prevState => ({...prevState, [item.employee_id]: dl}));

            }).catch(err => {
            logoutErrorMessage(err, null, actions)
            })

        }

        else {
            const new_shift = setShiftsEmployee(shifts);

            setCurrentEmployeeShift(new_shift);
            setShiftsList(prevState => ({...prevState, [item.employee_id]: new_shift}));

            let dictionary = Object.assign({}, ...new_shift.map((x) => ({[x.shift_id]: x.days})));
            setIsAssign(dictionary);
        }
        setOpenDialog(true);
        setScreen(screen_name);
    }

    const handleCloseDialog = () => {

        // store the 
        setCurrentEmployee({
            employee_id: "", first_name: "",
            last_name: "", email: "", gender: "", address: "", mobile_no: "", kin_first_name: "",
            kin_telephone: "", kin_relationship: "", kin_last_name: "", kin_address: "", biography: "",
            specialist: "", education: "", status: "1", nin: "", dob: ""
        });
        // set everything to empty
        setOpenDialog(false);
        setScreen("")
    }

    const handleUpdateShift = () => {
        // store the variables
        setShiftsList(prevState => ({...prevState, [current_employee.employee_id]: currentEmployeeShift}));

        //send to api
        const to_save = convertForAPI(current_employee.employee_id, currentEmployeeShift);

        const params = {
            "employee_shift_id" : current_employee.employee_shift_id,
            "days":to_save.days
        }

        // add the to save
        axios.post(`${config.smsUrl}/cshiftschedule/update_staff_shift`, params).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            setIsSubmitted("resolved");
            handleCloseDialog();
        }).catch(err => {
           logoutErrorMessage(err, null, actions)
        })

    }

    const handleAssignShift = () => {
        // store the variables
        setShiftsList(prevState => ({...prevState, [current_employee.employee_id]: currentEmployeeShift}));

        //send to api
        const to_save = convertForAPI(current_employee.employee_id, currentEmployeeShift);

        axios.post(`${config.smsUrl}/cshiftschedule/add_staff_to_shift`, to_save).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;

            setIsSubmitted("resolved");
            handleCloseDialog();
           // setShift(dt);
        }).catch(err => {
           logoutErrorMessage(err, null, actions)
        })

    }

    // handle the change of the colour
    const handleCellColour = (idx, shft_id, week_day) => {
        const new_val = currentEmployeeShift[idx];
        new_val.days[week_day] = !new_val.days[week_day]
        const new_arr = currentEmployeeShift;
        new_arr[idx] = new_val;
        setCurrentEmployeeShift(new_arr);
        setIsAssign({...isAssign, [shft_id]:new_val.days});
    }

    return {
        shifts, searchValue, openMenu, order, orderBy, page, rowsPerPage,
        employeeList, loading, activePage, employee_count, handleSearch,
        handleRequestSort, handleChangeNewPage,
        employee, handleDelete, closeSnackBar, setEmployeeId, setEmployeeList,
        shiftsList, currentEmployeeShift, handleChangePage, handleChangeRowsPerPage,
        handleOpenDialog, handleCloseDialog, openDialog, current_employee, handleUpdateShift,
        isAssign, handleCellColour, screen, handleAssignShift
    }
}