import React, {useEffect,  useState} from "react";
import {CustomTablePagination, EnhancedSearchTableToolbar} from "../../Utils/Tables/CustomTable";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import *  as snackbarActions from "../../../actions/snackbarActions";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {MedicalReportFilter} from "../ReportFilter";
import {Container} from "../../Utils/styledComponents";
import axios from "axios";
import {config} from "../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {dateConvert} from "../../Utils/ConvertDate";
import {LoadingGif} from "../../Utils/Loader";
import {Link} from "react-router-dom";
import {CustomTableCell, MuiPaper, StickyHeadTable} from "../../Utils/Tables/StickyHeaderTable";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClipboardList} from "@fortawesome/free-solid-svg-icons/faClipboardList";
import {BackButton} from "../../Utils/Buttons/DataExportationButton";
import {useDebounce} from "../../Lab/useDebounce";
import {useCurrency} from "../../../Context/currency-context";




const RevenuePerDepartment = ({actions, snackbars}) => {
    const {currency:selectedCurrency} = useCurrency({actions, snackbars})
    const [date, setDate] = useState({start_date: dateConvert(), end_date: dateConvert()});
    const [searchValue, setSearchValue] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isSubmitted, setIsSubmitted] = useState('idle');;
    const [data, setData] = useState({revenueList:[], loading:'idle', total:0});
    const [submitted, setSubmitted] = useState(false);
    const [payment, setPayment] = useState({value:'', label:'All'})
    const [patientType, setPatientType] = useState({value:'', label:'All'})
    const [activePage, setActivePage] = useState(1)
    const [providers, setProviders] = useState([])
    const [provider, setProvider] = useState({value:'', label:''})
    const [debouncedInputValue] = useDebounce(searchValue, 500)


    const {start_date, end_date} = date;

    const {loading, total, revenueList} = data


    useEffect(() => {
        if (payment?.label === 'All' || payment?.label === 'Cash'){
            return
        }
        axios.get(`${config.smsUrl}/cbilling/insurance_providers/list`).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const arr = !dt.data ? [] : dt.data;
            const list = arr.filter(item=>item.provider_type === payment.label).map(item=>({
                value:item.provider_id,
                label:item.provider_name
            }))
            setProviders(list);
        }).catch(err => {
            logoutErrorMessage(err,null,actions)
        })

    }, [payment?.label]);

    const isCash = payment?.label === 'All' || payment?.label === 'Cash'


    useEffect(() => {

        const pay_types = { '': 'All', 1:'Cash', 2:'Insurance Company', 3:'Organization', 4:'Individual'}
        setData({...data, loading: 'pending'})
        axios.get(`${config.smsUrl}/cbilling/revenue-per-department`, {
            params: {
                start_date: start_date,
                end_date: end_date,
                patient_type: patientType?.value ? patientType?.value : '',
                pay_type:payment?.value ? pay_types[payment?.value] : '',
                search:debouncedInputValue,
                provider_id:provider?.value
            }
        }).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const arr = dt.items ? dt.items : []
            const total_count = dt.total ? dt.total : 0
            const revenues = arr.map(item=>({
                ...item,
                department_name : (
                    <Link to={{pathname:`/department_revenue/${item.department_id}/${item.department_name}/${start_date}/${end_date}`}}>
                        {item.department_name}
                    </Link>
                )
            }))
            setData({...data, loading: 'success', revenueList: revenues, total: total_count})
        }).catch((err) => {
            setData({...data, loading: 'error'})
            logoutErrorMessage(err, null, actions);
        })
    }, [isCash, patientType, debouncedInputValue, provider]);

    const handleSubmit = (event) => {
        event.preventDefault();
        const {start_date, end_date} = date;
        setSubmitted(true)
        if (start_date && end_date) {
            setIsSubmitted('pending')
            setData({...data, loading: 'pending'})
            axios.get(`${config.smsUrl}/cbilling/revenue-per-department`, {
                params: {
                    start_date: start_date,
                    end_date: end_date,
                    patient_type: patientType?.value ? patientType?.value : '',
                    pay_type:payment?.value ? payment?.value : '',
                    search:searchValue
                }
            }).then(res => {
                const data = res.data;
                const dt = !data ? {} : data;
                const arr = dt.items ? dt.items : []
                const total_count = dt.total ? dt.total : 0
                const revenues = arr.map(item=>({
                    ...item,
                    department_name : (
                        <Link to={{pathname:`/department_revenue/${item.department_id}/${item.department_name}/${start_date}/${end_date}`}}>
                            {item.department_name}
                        </Link>
                    )
                }))
                setData({...data, loading: 'success', revenueList: revenues, total: total_count})
                setIsSubmitted('resolved')
            }).catch(err => {
                setIsSubmitted('rejected')
                errorMessages(err, null, actions);
                setData({...data, loading: 'error'})
            })
        }
    }

    const handleChangeDate = (event) => {
        const {value, name} = event.target;
        setDate({...date, [name]: value})
    }


    const handleSearch = (event) => {
        setSearchValue(event.target.value)
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setActivePage(newPage+1)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        setActivePage(1);
    };

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };



    const {openBar, type, message} =snackbars;

    const isLoading = loading === 'pending'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'

    const isPending = isSubmitted === 'pending'

    const pagination = (
        <CustomTablePagination
            colSpan={3}
            activePage={activePage}
            page={page}
            rowsPerPage={rowsPerPage}
            count={total}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            isTotalCount
        />
    );

    const search_bar = (
        <EnhancedSearchTableToolbar
            term={searchValue} handler={handleSearch}
        />
    )
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, revenueList.length - page * rowsPerPage);

    const headData = [
        {id: 'count', numeric: false, disablePadding: false, label: 'Sl'},
        {id: 'department_name', numeric: false, disablePadding: false, label: 'Department'},
        {id: 'total_amount', numeric: false, disablePadding: false, label: `Total`},
        // {id: 'revenue_ssp', numeric: false, disablePadding: false, label: 'Total(SSP)'}
    ];

    return (
        <div data-testid={`revenue-per-department-report`}>
            <PageTitle title='Revenue per department'/>
            <SubHeader title="Reports" subTitle='Revenue per department'>
                <FontAwesomeIcon icon={faClipboardList}/>
            </SubHeader>
            <Container>
                <BackButton to='/reports/generalreports' text='General Reports'/>

                <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>
                <MedicalReportFilter {...{payment, setPayment, patientType, setPatientType,handleSubmit,
                    start_date, end_date, handleChange:handleChangeDate,isPending, submitted,actions,
                    provider, providers, setProvider, file_name:'Revenue per department',
                    excelUrl:'cbilling/revenue-per-department', excelParams:{download:1},
                    pdfUrl:'cbilling/revenue-per-department', pdfParams:{download:2}
                }}/>

                <MuiPaper>
                    {search_bar}
                    <StickyHeadTable {...{width:'100%',data:revenueList, columns:headData.filter(item=>Boolean(item)), pagination}}>
                        {isLoading ? <TableRow><TableCell align='center' {...{colSpan:headData.length}}><LoadingGif/></TableCell></TableRow>:null}
                        {isSuccess ? revenueList.length > 0 ?
                            <>
                                {revenueList.slice(0, 10).map((row, index) => {
                                    const count = ((activePage - 1) * 10) + index + 1;
                                    return (
                                        <TableRow  tabIndex={-1} key={index}>
                                            {headData.map(col=>(
                                                col.id === 'count' ? <CustomTableCell>{count}</CustomTableCell>:
                                                    <CustomTableCell>{row[col.id]}</CustomTableCell>
                                            ))}
                                        </TableRow>
                                    );
                                })}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 20 * emptyRows }}>
                                        <TableCell colSpan={headData.length} />
                                    </TableRow>
                                )}
                            </>:<TableRow><TableCell align='center'  {...{colSpan:headData.length}}>No records found</TableCell></TableRow>:null}
                        {isError ? <TableRow><TableCell align='center'  {...{colSpan:headData.length}}>The server did not return a valid response</TableCell></TableRow>:null}
                    </StickyHeadTable>
                </MuiPaper>
            </Container>

        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(RevenuePerDepartment);
