import React from "react";
import PageTitle from "../Utils/smsTitle";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCog} from "@fortawesome/free-solid-svg-icons/faCog";
import Card from "@material-ui/core/Card";
import FormGroup from "../Utils/FormInputs/FormGroup";
import Form from "react-bootstrap/Form"
import Row from "react-bootstrap/Row";
import Label from "../Utils/FormInputs/Label";
import Col from "react-bootstrap/Col";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../actions/snackbarActions";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import {useTheme} from "@material-ui/core/styles";
import {CustomSelect} from "../Utils/FormInputs/SelectInput";
import {useAddUser} from "./CustomHooks/useAddUser";

export function AddUser(props) {
    const {state, submitted, isSubmitted, employee_id, employees, employee_email, handleChange,
        handleChangeEmployee, handleReset, handleSubmit, handleCloseSnackbar} = useAddUser(props)
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    const {openBar, type, message} = props.snackbars;
    return (
        <div data-testid="add-user">
            <PageTitle title="Add User"/>
            <SubHeader title="Settings" subTitle="Add User">
                <FontAwesomeIcon icon={faCog}/>
            </SubHeader>
            <div className="add-service-card ">
                <Card className="p-3 general-card">
                    <h6 className={`visit-heading ${palette.type === "dark" && "dark-visit-heading"}`}>Add User</h6>
                    <MainSnackbar handleCloseBar={handleCloseSnackbar} message={message} open={openBar} variant={type}/>
                    <form onSubmit={handleSubmit} autoComplete="off">
                        <Form.Group as={Row}>
                            <Label column sm={2} name="Employee" htmlFor='employee_name' type/>
                            <Col sm={7}>
                                <CustomSelect value={employee_id} onChange={handleChangeEmployee}
                                options={employees.map(employee => ({
                                    label: `${employee.first_name} ${employee.last_name}`,
                                    value: employee.employee_id
                                }))} id='employee_name' dataTest="employee_id"/>
                                {(submitted && !employee_id) &&
                                <div className="help-block">Employee name is required</div>}
                            </Col>
                        </Form.Group>
                        <FormGroup id='_user_email' submitted={submitted} labelType={true}
                                   value={employee_email} colSm={7} labelSm={2}
                                   dataTest="email" name="email" type="email" labelName="Email/Username" disabled={true}/>
                        <Form.Group as={Row}>
                            <Label column sm={2} name="Password" htmlFor='adduser_password' type/>
                            <Col sm={7}>
                                <Form.Control value={state.password} type="password"
                                              id='adduser_password'
                                              onChange={handleChange} name="password"
                                              className={`form__control ${palette.type === "dark" && 'form-control-dark'}`}/>
                                {(submitted && !state.password) ? <div className="help-block">Password is required</div> :
                                    (submitted && state.password.length < 6) &&
                                    <div className="help-block">Password should be at least 6 characters or more than </div>}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Label column sm={2} name="Status" type/>
                            <Col sm={7}>
                                <Form.Check type="radio" inline label="Active" name="status" value="1"
                                            onChange={handleChange} id="active" checked={state.status === "1"}/>
                                <Form.Check type="radio" inline label="Inactive" name="status" value="0"
                                            onChange={handleChange} id="inactive" checked={state.status === "0"}/>
                                {(submitted && !state.status) && <div className="help-block">Status is required</div>}
                            </Col>
                        </Form.Group>
                        <div className="text-right">
                            <button onClick={handleReset} type="button" className="btn btn-sm sms-grey-btn px-4 mr-2"
                                    id="reset_user">Reset
                            </button>
                            <button type="submit" disabled={isSubmitted === 'pending' || isSubmitted === 'resolved'} id="submit_user"
                                    className="btn btn-sm sms-btn px-4">{isSubmitted === 'pending' ? "Saving..." : "Save"}</button>
                        </div>
                    </form>
                </Card>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(AddUser);
