import * as React from 'react'
import {CompanyProvider} from "./company-context";
import {SnackbarProvider} from "./snackbar-context";
import {CurrencyProvider} from "./currency-context";


function AppProviders({children}) {
    return (
        <SnackbarProvider>
            <CurrencyProvider>
                <CompanyProvider>
                    {children}
                </CompanyProvider>
            </CurrencyProvider>
        </SnackbarProvider>

    )
}

export {AppProviders}
