import  {useEffect, useState} from 'react';
import {logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {usePatientDetails} from "../../ReferralNote/usePatientDetails";

const retrieveNotes = (formData) => {
    return axios.post(`${config.smsUrl}/cdoctor/retrieve_exam_notes`, formData)
};

export const useExaminationNotes = (patient_number, actions, visit_id, _token, patient_type) => {
    const storage = JSON.parse(sessionStorage.getItem('notes'))
    const item = storage ? storage : {}
    const note_item = item[visit_id] ? item[visit_id] : ''
    const note = () => note_item || ''
    const [examination_notes_id, setExaminationNoteId] = useState('')
    const [notes, setExamNotes] = useState(note)
    const [prevNotes, setPrevNotes] = useState('')
    const [patient, setPatient] = useState({patient_name: "", age: "", gender: "", address: "", phone: ""});
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const {patient: patientDetails} = usePatientDetails(actions, patient_number, '')
    const [submitted, setSubmitted] = useState(false)

    const isResolved = isSubmitted === 'resolved';

    useEffect(() => {
        const formData = new FormData();
        formData.append('visit_id', visit_id);
        retrieveNotes(formData).then(res => {
            const data = res.data;
            const dt = !data ? [] : data;
            const not = dt[dt.length-1] ? dt[dt.length-1] : {}
            const arr = not.notes ? not.notes : ''
            if (patient_type === 1) {
                if (dt.length === 1) {
                    setExaminationNoteId(dt[0].examination_notes_id);
                    setPrevNotes(dt[0].notes);
                } else {
                    if (dt.length > 1) {
                        setExaminationNoteId(not.examination_notes_id);
                        setPrevNotes(arr);
                    } else {
                        setPrevNotes(arr);
                    }
                }

            } else {
                setPrevNotes(arr)
            }

        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, [patient_type, visit_id, isResolved]);

    useEffect(()=>{
        if (!prevNotes){
            return
        }
        setExamNotes(prevNotes)

    },[prevNotes])


    useEffect(() => {
        setPatient({
            patient_name: `${!patientDetails.first_name ? "" : patientDetails.first_name} ${!patientDetails.last_name ? "" : patientDetails.last_name}`,
            age: patientDetails.Age,
            gender: patientDetails.gender,
            address: patientDetails.address,
            phone: patientDetails.phone_no
        });
    }, [patientDetails]);


    const handleChangeExamNotes = (event) => {
        const _note = event.target.value
        setExamNotes(_note)
        const obj = {[visit_id]:_note}
        sessionStorage.setItem('notes', JSON.stringify({...item, ...obj}))
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('visit_id', visit_id);
        formData.append('notes', notes);

        if (examination_notes_id) {
            formData.append('examination_notes_id', examination_notes_id);
        }
        setSubmitted(true)
        if(notes){
            setIsSubmitted('pending');
            const url = examination_notes_id ? 'update_exam_notes' : 'save_exam_notes';
            axios.post(`${config.smsUrl}/cdoctor/${url}`, formData).then(() => {
                actions.snackbarActions.snackSuccess(`Notes ${examination_notes_id ? 'updated' : 'saved'}  successfully`);
                setSubmitted(false)
                setIsSubmitted('resolved');
            }).catch(err => {
                logoutErrorMessage(err, null, actions)
                setIsSubmitted('rejected');
            })
        }

    };

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    return {notes,  patient, isSubmitted, handleChangeExamNotes,
        handleSubmit, closeSnackbar, submitted,
    }
}