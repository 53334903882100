import { ReusableRouteTabs } from "../../Utils/Dialogs/ReusableTabs";
import React, { useEffect, useState } from "react";
import PageTitle from "../../Utils/smsTitle";
import { SubHeader } from "../../../Containers/SubHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomTable from "../../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as snackbarActions from "../../../actions/snackbarActions";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import { Link } from "react-router-dom";
import { LoadingGif } from "../../Utils/Loader";
import axios from "axios";
import { config } from "../../../Helpers/env";
import { logoutErrorMessage } from "../../../Helpers/ErrorMessages";
import { faVials } from "@fortawesome/free-solid-svg-icons/faVials";
import { errorMessages } from "../../../Helpers/ErrorMessages";
import { formatAmount } from "../../Utils/formatNumbers";
import { ReusableNewCOA } from "../ChartofAccounts/NewCOAccount";
import { history } from "../../../Helpers/history";
import { Container, RightAlignedContainer } from "../../Utils/styledComponents";

const headData = [
  {
    id: "count",
    numeric: false,
    disablePadding: false,
    label: "",
  },
  {
    id: "accountname",
    numeric: false,
    disablePadding: false,
    label: "Account Name",
  },
  {
    id: "category_name",
    numeric: false,
    disablePadding: false,
    label: "Account Catagory",
  },
  {
    id: "parent_acct_name",
    numeric: false,
    disablePadding: false,
    label: "Account Detail",
  },
  {
    id: "account_balance",
    numeric: false,
    disablePadding: false,
    // align: "center",
    label: "Account Balance",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
    align: "center",
  },
];

const AccountsList = (props) => {
  const { actions } = props;
  const [isSubmittedItem, setIsSubmittedItem] = useState("idle");
  const [openAccount, setopenAccount] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [account_id, setaccount_id] = useState("");
  const [loading, setLoading] = useState("idle");
  const [accountList, setList] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [total_count, setCount] = useState(0);

  useEffect(() => {
    setLoading("pending");
    const formData = new FormData();
    formData.append("search", searchValue);
    formData.append("page", activePage);
    axios
      .post(`${config.smsUrl}/accounting/accounts/list`, formData)
      .then((res) => {
        const data = res.data;
        const dt = !data.data ? {} : data.data;
        const arr = !dt.accounts ? [] : dt.accounts;
        const count = !dt.total_count ? 0 : dt.total_count;
        setLoading("success");
        setCount(count);
        setList(arr);
      })
      .catch((err) => {
        logoutErrorMessage(err, null, actions);
        setLoading("error");
      });
  }, [activePage, searchValue, isSubmittedItem]);

  const handleSearch = (event) => {
    setSearchValue(event.target.value);
  };

  const handleChangePage = (page) => {
    setActivePage(page);
  };

  const handleEditAccount = (account_id) => {
    setaccount_id(account_id);
    setopenAccount(true);
  };

  const closeSnackbar = () => {
    actions.snackbarActions.hideSnackbar();
  };

  const isLoading = loading === "pending";
  const isSuccess = loading === "success";
  const isError = loading === "error";
  const isResolvedItem = isSubmittedItem === "resolved";

  const { openBar, type, message } = props.snackbars;
  const components = [
    { label: "Account List", path: "/accounting/accounts/list" },
    { label: "Chart of Accounts", path: "/accounting/coa" },
  ];

  return (
    <div data-testid="lab-stock-list" className="journals">
      <PageTitle title="Chart Of Accounts List" />
      <SubHeader title="Chart Of Accounts" subTitle="Accounts">
        <FontAwesomeIcon icon={faVials} />
      </SubHeader>
      <ReusableNewCOA
        {...{
          actions,
          isSubmitted: isSubmittedItem,
          openDialog: openAccount,
          setIsSubmitted: setIsSubmittedItem,
          setOpenDialog: setopenAccount,
          account_id: account_id,
        }}
      />

      <Container>
        <ReusableRouteTabs
          value={history.location.pathname}
          tabHeading={components}
        />
        <div className="mui-tables">
          <RightAlignedContainer>
            <button
              type="button"
              onClick={() => setopenAccount(true)}
              className="btn sms-gray-btn  small-btn mb-1 btn-sm"
            >
              New Account
            </button>
          </RightAlignedContainer>
          <MainSnackbar
            variant={type}
            open={openBar}
            message={message}
            handleCloseBar={closeSnackbar}
          />
          <CustomTable
            title="Accounts List"
            term={searchValue}
            records={100}
            activePage={activePage}
            headData={headData}
            handler={handleSearch}
            total_count={total_count}
            data={accountList}
            colSpan={5}
            handleChangeNextPage={handleChangePage}
            customPage
          >
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={headData.length} align="center">
                    <LoadingGif />
                  </TableCell>
                </TableRow>
              ) : null}
              {isSuccess ? (
                accountList.length > 0 ? (
                  <>
                    {accountList.slice(0, 100).map((item, index) => {
                      const count = (activePage - 1) * 100 + index + 1;
                      return (
                        <TableRow hover key={item.id}>
                          <TableCell>{count}</TableCell>
                          <TableCell>{item.category_name}</TableCell>
                          <TableCell>
                            <Link
                              to={{
                                pathname: `/accounting/view-transactions/${item.id}/${item.head_code}-${item.accountname}`,
                              }}
                            >
                              {item.accountname}
                            </Link>
                          </TableCell>

                          <TableCell>{item.parent_acct_name}</TableCell>
                          <TableCell>
                            {formatAmount(item.account_balance)}
                          </TableCell>

                          <TableCell>
                            <button
                              type="button"
                              onClick={() => handleEditAccount(item.id)}
                              className="btn sms-gray-btn  small-btn mb-1 btn-sm"
                            >
                              Edit
                            </button>
                            <button
                              type="button"
                              onClick={() => console.log("Lab")}
                              className="btn sms-gray-btn  small-btn mb-1 btn-sm"
                            >
                              deactive
                            </button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    <TableRow>
                      <TableCell colSpan={headData.length} align="right">
                        <strong>Total Count: {total_count}</strong>
                      </TableCell>
                    </TableRow>
                  </>
                ) : (
                  <TableRow>
                    <TableCell colSpan={headData.length} align="center">
                      No records found
                    </TableCell>
                  </TableRow>
                )
              ) : null}
              {isError ? (
                <TableRow>
                  <TableCell colSpan={headData.length} align="center">
                    The server did not return a valid response
                  </TableCell>
                </TableRow>
              ) : null}
            </TableBody>
          </CustomTable>
        </div>
      </Container>
    </div>
  );
};

function mapStateToProps(state) {
  return { snackbars: state.snackbar };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: { snackbarActions: bindActionCreators(snackbarActions, dispatch) },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountsList);
