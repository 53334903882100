import * as types from "../../actions/actionTypes"


const initialState = {
    request: {},
    scanRequests: [],
    scanResults: {},
    total_count:0,
    allScanResults:[]
};

export default function scanReducer(state = initialState, action) {
    switch (action.type) {
        case types.ADD_SCAN_RESULTS_REQUEST:
            return {loading: true};
        case types.ADD_SCAN_RESULTS_SUCCESS:
            return {loading: false};
        case types.ADD_SCAN_RESULTS_ERROR:
            return {loading: false};
        case types.GET_SCAN_REQUEST_BY_ID:
            return {...state, request: action.request};
        case types.GET_SCAN_REQUESTS:
            return {loading: true};
        case types.TOTAL_SCAN_COUNT:
            return {...state,total_count: action.total_count};
        case types.GET_SCAN_RESULTS_SUCCESS:
            return {...state, allScanResults: action.allScanResults, loading: false};
        case types.GET_SCAN_REQUEST_SUCCESS:
            return {...state, scanRequests: action.scanRequests, loading: false};
        case types.GET_SCAN_REQUEST_ERROR:
            return {loading: false};
        case types.EDIT_SCAN_RESULTS_REQUEST:
            return {loading: true};
        case types.EDIT_SCAN_RESULTS_SUCCESS:
            return {loading: false};
        case types.EDIT_SCAN_RESULTS_ERROR:
            return {loading: false};
        case types.DELETE_SCAN_REQUEST:
            return {...state,
                scanRequests: state.scanRequests.filter(scan => scan.scan_request_id !== action.scan_request_id)
            };
        case types.VIEW_SCAN_RESULTS:
            return {...state, scanResults: action.scanResults};
        default:
            return state;
    }
}
