/** @jsxImportSource @emotion/react */
import React, {useEffect, useState} from "react";
import {css, jsx} from "@emotion/react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../../actions/snackbarActions";
import {connect} from "react-redux";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import { DataCell, ItemIcon,  ModuleTable} from "../../Utils/Lists/CollapsibleList";
import TableRow from "@material-ui/core/TableRow";
import {LoadingGif} from "../../Utils/Loader";
import {CenteredContainer, RightAlignedContainer} from "../../Utils/styledComponents";
import {Container, P} from "../../DoctorsModule/doctorStyles";
import {faCoins} from "@fortawesome/free-solid-svg-icons/faCoins";
import axios from "axios";
import {config} from "../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import Collapse from "@material-ui/core/Collapse";
import {CustomTableCell, MuiPaper, StickyHeadTable} from "../../Utils/Tables/StickyHeaderTable";
import {NewApprovalChecks} from "./NewApprovalChecks";
import CustomTable, {MuiPagination} from "../../Utils/Tables/CustomTable";
import FilterResults from "react-filter-search";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import {history} from "../../../Helpers/history";
import CustomDialog from "../../Utils/Dialogs/CustomDialog";
import {formatDate} from "../../Utils/ConvertDate";
import {formatAmount} from "../../Utils/formatNumbers";

const div = css`
    padding: 8px 0;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    border-top: 1px solid rgba(224, 224, 224, 1);
    cursor: pointer;
`



const headData = [
    {id: 'date', numeric: false, disablePadding: false, label: 'Date'},
    {id: 'check', numeric: false, disablePadding: false, label: 'Check'},
    {id: 'service_type', numeric: false, disablePadding: false, label: 'Service type'},
    {id: 'patient_type', numeric: false, disablePadding: false, label: 'Patient type'},
    {id: 'general', numeric: false, disablePadding: false, label: 'Is general'},
    {id: 'action', numeric: false, disablePadding: false, label: 'Action'},
];

const ApprovalChecksList = ({actions, snackbars, match:{params}}) => {
    const [data, setData] = React.useState({checksList:[], loading:'idle', total_count:0})
    const [isSubmitted, setIsSubmitted] = React.useState('idle')
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [activePage, setActivePage] = React.useState(1)
    const [searchValue,setSearchValue] = React.useState('')
    const [openDialog, setOpenDialog] = React.useState(false)
    const [openEdit, setOpenEdit] = React.useState(false)
    const [approvalCheckId, setApprovalCheckId] = React.useState('')
    const [openDelete, setOpenDelete] = React.useState(false)
    const [isDelete, setIsDelete] = React.useState('idle')
    const {checksList, loading, total_count} = data


    const isResolved = isSubmitted === 'resolved'
    const isDeleted = isDelete === 'resolved'

    useEffect(() => {
        setData({...data, loading: 'loading'})
        axios.get(`${config.smsUrl}/insurance-claim-approval-checks/`,{params:{page:activePage, per_page:rowsPerPage}}).then(res => {
            const resp = res.data;
            const data = !resp ? {} : resp;
            const list = data.items ? data.items : [];
            const count = data.total_elements ? data.total_elements : 0
            const arr = list.map(item=>{
                const p_type = {
                    1:'Out-patient',
                    2:'In-patient'
                }

                const buttons = (
                    <>
                        <button type='button' onClick={()=>{
                            setOpenEdit(true)
                            setApprovalCheckId(item.id)
                        }} className='btn btn-sm sms-info-btn small-btn mr-3'>Edit</button>

                        <button type='button' onClick={()=>{
                            setOpenDelete(true)
                            setApprovalCheckId(item.id)
                        }} className='btn btn-sm sms-btn-dismiss small-btn'>Delete</button></>
                )
                const type = item?.service_types?.length > 0 ? item?.service_types?.join(', ') : ''
                const patient = item?.patient_types?.length > 0 ? item?.patient_types?.map(item=>p_type[item]).join(', ') : ''
                return {
                    ...item,
                    date:item.create_at ? formatDate(item.create_at) : '',
                    check:item.name,
                    service_type: item.any_service_type ? 'Any service type' : type,
                    patient_type: item.any_patient_type ? 'Any patient type' : patient,
                    general: item.any_provider ? 'Yes' : 'No',
                    action:buttons
            }})
            setData({...data, total_count:count, checksList:arr, loading: 'success' })
        }).catch(error => {
            logoutErrorMessage(error, null, actions)
            setData({...data, loading: 'error'})
        });
    }, [isResolved, isDeleted, activePage, rowsPerPage])

    const handleSearch = (searchValue) => {
        setSearchValue(searchValue)
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setActivePage(newPage + 1)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        setActivePage(1)
    };

    const handleDelete = () =>{
        setIsDelete('pending')
        axios.delete(`${config.smsUrl}/insurance-claim-approval-checks/${approvalCheckId}`).then(() => {
            actions.snackbarActions.snackSuccess('Check deleted successfully')
            setIsDelete('resolved')
            setOpenDelete(false)
        }).catch(error => {
            errorMessages(error, null, actions)
            setIsDelete('rejected')
        });
    }



    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const isLoading = loading === 'loading'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'

    const isDeleting  = isDelete === 'pending'

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, checksList.length - page * rowsPerPage);

    const {openBar, type, message} = snackbars;

    const buttons = (
        <>
            <button type='button' onClick={handleDelete} className='btn btn-sm small-btn sms-btn-dismiss mr-3'>Delete</button>
            <button type='button' onClick={()=>setOpenDelete(false)} className='btn btn-sm small-btn sms-gray-btn'>Cancel</button>
        </>
    )
    return (
        <div className='journals'>
            <PageTitle title="Insurance"/>
            <SubHeader title="Setup approval checks" subTitle="Insurance" >
                <FontAwesomeIcon icon={faCoins}/>
            </SubHeader>
            <MainSnackbar open={openBar} handleCloseBar={closeSnackbar} variant={type} message={message}/>
            <CustomDialog maxWidth='sm' open={openDelete} handleClose={()=>setOpenDelete(false)}
                          title='Delete check'  buttons={buttons}>
                <p style={{margin:0, textAlign:'center'}}>You are about to delete a check,Are you sure you want to continue?</p>
            </CustomDialog>

            <CustomDialog maxWidth='sm' open={openEdit} handleClose={()=>setOpenEdit(false)} title='Edit invoice approval checks'>
                <NewApprovalChecks {...{actions, isSubmitted, setIsSubmitted, openDialog:openEdit, setOpenDialog:setOpenEdit, approvalCheckId}}/>
            </CustomDialog>
            <Container>
                <RightAlignedContainer>
                    <button onClick={()=>setOpenDialog(true)} type='button' className='btn btn-sm sms-info-btn mb-2'>Add approval checks</button>
                </RightAlignedContainer>
                <CustomDialog maxWidth='md' open={openDialog} handleClose={()=>setOpenDialog(false)} title='Add invoice approval checks'>
                    <NewApprovalChecks {...{actions, isSubmitted, setIsSubmitted, openDialog, setOpenDialog}}/>
                </CustomDialog>
                <MuiPaper>
                    <StickyHeadTable {...{width:'100%'
                        ,data:checksList, columns:headData.filter(item=>Boolean(item))}}>
                        {isLoading ? <TableRow><TableCell align='center' colSpan={headData.length}><LoadingGif/></TableCell></TableRow>:null}
                        {isSuccess ? checksList.length > 0 ?
                            <>
                                {checksList.map((row) => {
                                    return (
                                        <TableRow  tabIndex={-1} key={row.id}>
                                            {headData.map(head=>(
                                                <CustomTableCell key={head.id}>{row[head.id]}</CustomTableCell>
                                            ))}
                                        </TableRow>
                                    );
                                })}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 43 * emptyRows }}>
                                        <TableCell colSpan={headData.length} />
                                    </TableRow>
                                )}
                            </>:<TableRow><TableCell align='center'  colSpan={headData.length}>No records found</TableCell></TableRow>:null}
                        {isError ? <TableRow><TableCell align='center'  colSpan={headData.length}>The server did not return a valid response</TableCell></TableRow>:null}
                    </StickyHeadTable>
                    <MuiPagination {...{colSpan:0,total_count ,page,handleChangePage,handleChangeRowsPerPage,isTotal:true,
                        rowsPerPage,rows:[10, 25,50, 100, 200, 300, 500, {label: 'All', value: -1}]}}/>
                </MuiPaper>
            </Container>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbar
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ApprovalChecksList);