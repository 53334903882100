import {useEffect,  useState} from "react";
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {errorMessages} from "../../../../Helpers/ErrorMessages";
import {titleCase} from "../../../Users/addUser";
import {history} from "../../../../Helpers/history";
import { useFileUploader } from "../../../PatientRecords/CustomHooks/useFileUploader";

function useInitialStock(actions) {
    const [products, setProducts] = useState([])
    const [product, setProduct] = useState({product_name:'', product_id:'', selected_product:''})
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [drugs, setDrugs] = useState([])
    const [openConfirmation, setOpenConfirmation] = useState(false)

    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = _token.user_roles_id ? _token.user_roles_id : '';

    const isDraftSaved = isSubmitted === 'resolvedDraft';

    const obj = {url:'cpharmacy/uploadCsv_product', download_url:'cpharmacy/product_csv', file_name:'Stock'};
    const {uploadProps, isResolved:isResolvedUpload} = useFileUploader(actions, obj)

    const groupByProductId = (arr=[]) =>{
        let result = []
        let knowledge = {}
        arr.forEach(item =>{
            let id = item.product_id;
            if(id in knowledge){
                result[knowledge[id]].batches.push(item)
            }else {
                knowledge[id] = result.length
                result.push({
                    ...item,
                    batches: [item]
                })

            }
        })
        return result
    }

    useEffect(()=>{
        axios.post(`${config.smsUrl}/cinventory/check_stock_draft`).then(res=>{
            const dataRes = res.data;
            const dt = dataRes ? dataRes : []
            const arr = groupByProductId(dt).map(item=>({
                ...item,
                selected_product:`${item.product_name}-${item.country_of_origin}`,
                batches:item?.batches?.map(it=>({
                    ...it,
                    cost_price:it.cost_price ?  it.cost_price.toFixed(4) : 0,
                    total_cost:  (+it.quantity * it.cost_price).toFixed(0)
                })),
                open:true
            }))

            setProducts(arr)
        }).catch(err=>{
            errorMessages(err, null, actions)
        })
    },[isDraftSaved])



    const handleOpen = (idx) =>{
        const itemsArr = products.map((item, index)=>{
            if(index === idx){
                return {...item,open:!item.open}
            }else{
                return item
            }
        })
        setProducts(itemsArr)
    }

    const handleRetrieveDrugs = (event) => {
        const val = event.target ? event.target.value : event;
        setProduct({...product,product_name:val})
        let tmp = val.trim();
        const formData = new FormData();
        formData.append('product_name', titleCase(val));
        if (tmp !== '') {
            axios.post(`${config.smsUrl}/cdoctor/autocomplete_search_medicine`, formData).then(response => {
                const drugs = response.data;
                const dt = !drugs ? [] : drugs;
                if (dt[0] === "No Product Found") {
                    const val = [{value: "", label: "No Product Found"}]
                    setDrugs(val)
                } else {
                    const arr = dt.map(item=>({value:item.value, label:`${item.label}-${item.generic_name}-${item.country_of_origin}`}))
                    setDrugs(arr);
                }
            }).catch(error => {
                errorMessages(error, null, actions)
            })
        }
    };

    const handleClickDrug = (event) => {
        setProduct({product_id: event.value, product_name:'', selected_product: event.innerText})
        const batchData = [{  batch_number:'',
            expiry_date:'',
            cost_price:'',
            quantity:'',
            total_cost:''
        }]
        const obj = {product_name:event.innerText,selected_product: event.innerText, product_id:event.value, open:true, batches:batchData}
        setProducts([obj,...products])
    };

    const handleAddBatch = (idx) =>{
        const obj =  {  batch_number:'',
            expiry_date:'',
            cost_price:'',
            quantity:'',
            total_cost:''
        }
        const arr = products.map((item, index)=>{
            if(index === idx){
                return {...item, batches:[...item.batches,obj]}
            }
            return item
        })
        setProducts(arr)

    }

    const handleRemoveProduct = (index) =>{
        const arr = products.filter((_, idx) => index !== idx)
        setProducts(arr)
    }


    const handleRemoveBatch = (idx, batch_idx) =>{
        const arr = products.map((item, index)=>{
            if(index === idx){
                const arr = item.batches.filter((_, batch_index)=>batch_index !== batch_idx)
                return {...item, batches:arr}
            }
            return item
        })
        setProducts(arr)
    }

    const handleChangeItem = (event,idx, batch_idx) =>{
        const {name, value} = event.target
        const arr = products.map((item, index)=>{
            if(index === idx){
                const arr = item.batches.map((batch, batch_index)=>{
                    if (batch_index === batch_idx){
                        return {...batch, [name]:value}
                    }
                    return batch
                })
                return {...item, batches:arr}
            }
            return item
        })
        setProducts(arr)
    }

    const handleChangeQuantity = (event,idx, batch_idx) =>{
        const { value} = event.target
        const arr = products.map((item, index)=>{
            if(index === idx){
                const arr = item.batches.map((batch, batch_index)=>{
                    if (batch_index === batch_idx){
                        return {...batch, quantity:value,cost_price: +batch.total_cost / +value, total_cost: +value * batch.cost_price}
                    }
                    return batch
                })
                return {...item, batches:arr}
            }
            return item
        })
        setProducts(arr)
    }

    const handleChangeCostPrice = (event,idx, batch_idx) =>{
        const { value} = event.target
        const arr = products.map((item, index)=>{
            if(index === idx){
                const arr = item.batches.map((batch, batch_index)=>{
                    if (batch_index === batch_idx){
                        return {...batch, quantity:batch.quantity,cost_price: +value, total_cost: +batch.quantity * +value}
                    }
                    return batch
                })
                return {...item, batches:arr}
            }
            return item
        })
        setProducts(arr)
    }

    const handleChangeTotalCost = (event,idx, batch_idx) =>{
        const { value} = event.target
        const arr = products.map((item, index)=>{
            if(index === idx){
                const arr = item.batches.map((batch, batch_index)=>{
                    if (batch_index === batch_idx){
                        return {...batch, quantity:batch.quantity, cost_price: +value / +batch.quantity, total_cost: value}
                    }
                    return batch
                })
                return {...item, batches:arr}
            }
            return item
        })
        setProducts(arr)
    }




    const handleOpenConfirmation = (event) =>{
        event.preventDefault()
        const data = products.filter(item=>item.product_id).reduce((r, a) => r.concat(a.batches.map(({cost_price,quantity, ...b}) => ({product_id:
            a.product_id, cost_price:+cost_price, quantity:+quantity, ...b}))), []);
        const arr = data.every(item => item.batch_number && item.expiry_date && item.cost_price && item.quantity);
        setSubmitted(true)
        if (arr){
            setOpenConfirmation(true)
        }
    }

    const handleCloseConfirmation = () =>{
        setOpenConfirmation(false)
    }

    const handleSaveDraft = () =>{
        // sessionStorage.setItem('products', JSON.stringify(products))
        const data = products.filter(item=>item.product_id).reduce((r, a) => r.concat(a.batches.map(({cost_price,quantity, ...b}) => ({product_id:
            a.product_id, cost_price:+cost_price, quantity:+quantity, ...b}))), []);
        setIsSubmitted('pendingDraft')
        axios.post(`${config.smsUrl}/cinventory/initial_stock_draft`,{products:data,user_roles_id}).then(()=>{
            actions.snackbarActions.snackSuccess('Draft saved Successfully');
            setIsSubmitted('resolvedDraft')
        }).catch(err=>{
            errorMessages(err, null, actions)
            setIsSubmitted('rejectedDraft')
        })
    }



    const handleSubmit = event => {
        event.preventDefault();


        setSubmitted(true)
        const data = products.filter(item=>item.product_id).reduce((r, a) => r.concat(a.batches.map(({cost_price,quantity, ...b}) => ({product_id:
            a.product_id, cost_price:+cost_price, quantity:+quantity, ...b}))), []);
        const arr = data.every(item => item.batch_number && item.expiry_date && item.cost_price && item.quantity);
        if (arr) {
            setIsSubmitted('pending')
            axios.post(`${config.smsUrl}/cpharmacy/initial_pharmacy_setup`, {products:data,user_roles_id})
                .then(() => {
                    actions.snackbarActions.snackSuccess('Stock added Successfully');
                    setSubmitted(false)
                    setIsSubmitted('resolved')
                    setOpenConfirmation(false)
                    sessionStorage.removeItem('products')
                    history.push('/stocklist')
                }).catch(err=>{
                errorMessages(err,null, actions)
                setIsSubmitted('rejected')
                // setRows(initialState)
            })
        }
    };




    const handleCloseBar = () => {
        actions.snackbarActions.hideSnackbar();
    };

    const isPending = isSubmitted === 'pending'
    const isResolved = isSubmitted === 'resolved'
    const isRejected = isSubmitted === 'rejected'

    const isPendingDraft = isSubmitted === 'pendingDraft'


    return {products, drugs, handleOpen, handleClickDrug, handleRetrieveDrugs,
        isPending, submitted, handleAddBatch, handleRemoveBatch, isResolvedUpload,
        handleRemoveProduct, handleChangeItem, handleCloseBar, handleSubmit, openConfirmation,
        handleOpenConfirmation, handleCloseConfirmation, handleSaveDraft,isPendingDraft, uploadProps,
        handleChangeQuantity, handleChangeCostPrice, handleChangeTotalCost, isRejected, isResolved,}
}
export {useInitialStock}