/* eslint-disable react/prop-types */
import React  from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as patientActions from "../../actions/PatientActions/patientActions";
import { bindActionCreators } from "redux";
import { titleCase } from "../Users/addUser";
import "react-multi-carousel/lib/styles.css";
import { withTheme } from "@material-ui/core/styles";
import { SubHeader } from "../../Containers/SubHeader";
import { faWheelchair } from "@fortawesome/free-solid-svg-icons/faWheelchair";
import Card from "@material-ui/core/Card";
import PageTitle from "../Utils/smsTitle";
import {usePatientProfile} from "./CustomHooks/usePatientProfile";
import {BackButton} from "../Utils/Buttons/DataExportationButton";


const PatientProfile  = ({actions, match}) => {

    const {patient_number} = match.params;
    const {patient}=usePatientProfile(patient_number, actions)
    
    const singlePatient = !patient ? {} : patient;
  
 
       
        return (
            <div data-testid="patient-profile">
                <PageTitle title="Patient Profile"/>
                <SubHeader title="Patient" subTitle="Patient Profile">
                    <FontAwesomeIcon icon={faWheelchair}/>
                </SubHeader>
                <div className='col-md-12'>
                    <BackButton text='Patient List' to="/patientDetails/reception"/>
                </div>

                <div className="row col-md-12">
                    <div className="col-md-3 mb-3">
                        <Card className="profile-card text">
                            {/*<div className="edit-icon mx-2 mt-2 text-right">*/}
                            {/*    <Link*/}
                            {/*        to={`/editPatientProfile/${patient_number}`}*/}
                            {/*        className="edit-patient">*/}
                            {/*        <button className="btn sms-gray-btn btn-sm py-0">Edit</button>*/}
                            {/*        /!*<span><IconButton className="pen-icon"><FontAwesomeIcon*!/*/}
                            {/*        /!*  icon={faPen}/></IconButton></span>*!/*/}
                            {/*    </Link>*/}
                            {/*</div>*/}
                            <h6 className="text-center  mb-3 heading"> Patient Profile </h6>
                            <div className="patient-img mb-3">
                                <img className="avatar " src="/images/user.jpg" alt="patient"/>
                            </div>
                            <div className="text-center mb-3">
                                <span
                                    style={{fontSize: 14}}>Name: <strong>{titleCase(`${!singlePatient.first_name ? "" : singlePatient.first_name} 
                                    ${!singlePatient.last_name ? "" : singlePatient.last_name}`)}</strong></span><br/>
                                <span
                                    style={{fontSize: 14}}>Gender: <strong>{titleCase(!singlePatient.gender ? "" : singlePatient.gender)}</strong></span><br/>
                                <span
                                    style={{fontSize: 14}}>Age: <strong>{!singlePatient.Age ? "" : singlePatient.Age}</strong></span><br/>
                                <span
                                    style={{fontSize: 14}}>{!singlePatient.email ? "" : singlePatient.email}</span>
                                <h6 className="patient-info mt-2">Address Information</h6>
                                <span
                                    style={{fontSize: 14}}><strong>{titleCase(!singlePatient.address ? "" : singlePatient.address)}</strong></span>
                            </div>
                        </Card>
                    </div>
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-md-6">
                                <Card className="profile-card text patient-prof-other-card mb-2">
                                    <h6 style={{fontSize: 15}}><strong>Other Information</strong></h6>
                                    <p>Blood Group: <strong>{!singlePatient.blood_type ? "" : singlePatient.blood_type.toUpperCase()}</strong>
                                    </p>
                                    <p>NIN: <strong>{!singlePatient.id_nin ? "" : singlePatient.id_nin.toUpperCase()}</strong>
                                    </p>
                                    <p>Tel: <strong>{!singlePatient.phone_no ? "" : singlePatient.phone_no}</strong></p>
                                    <p>Email: <strong>{!singlePatient.email ? "" : singlePatient.email}</strong></p>
                                </Card>
                            </div>
                            <div className="col-md-6">
                                <Card className="profile-card text patient-prof-other-card mb-2">
                                    <h6 style={{fontSize: 15}}><strong>Next of Kin</strong></h6>
                                    <p>Name: <strong>{titleCase(`${!singlePatient.kin_firstname ? "" :
                                        singlePatient.kin_firstname} ${!singlePatient.kin_lastname ? "" : singlePatient.kin_lastname}`)}</strong>
                                    </p>
                                    <p>Contact: <strong>{!singlePatient.kin_contact ? "" : singlePatient.kin_contact}</strong>
                                    </p>
                                    <p>Address: <strong>{!singlePatient.kin_address ? "" : titleCase(singlePatient.kin_address)}</strong>
                                    </p>
                                    <p>Relationship: <strong>{!singlePatient.relationship ? "" : titleCase(singlePatient.relationship)}</strong>
                                    </p>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

    }


function mapStateToProps(state) {
  return {
    patients: state.patients,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      patientActions: bindActionCreators(patientActions, dispatch),
    },
  };
}

export default withTheme(
  connect(mapStateToProps, mapDispatchToProps)(PatientProfile)
);
