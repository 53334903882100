import React, {useEffect, useState} from 'react';
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../../actions/snackbarActions";
import {connect} from "react-redux";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {Container, RightAlignedContainer} from "../../Utils/styledComponents"
import {CustomTableCell, MuiPaper, StickyHeadTable} from "../../Utils/Tables/StickyHeaderTable";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {LoadingGif} from "../../Utils/Loader";
import axios from "axios";
import {config} from "../../../Helpers/env";
import {logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {faShippingFast} from "@fortawesome/free-solid-svg-icons/faShippingFast";
import {formatDate} from "../../Utils/ConvertDate";
import {Link} from "react-router-dom"
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import styled from "@emotion/styled/macro";
import {formatAmount} from "../../Utils/formatNumbers";


const ReportIcon = styled(LibraryBooksIcon)({
    width:'0.8em',
    height:'0.8em'
})

const headData = [
    {id: 'date',  label: 'Date', minWidth: 200},
    {id: 'invoice_no',  label: 'Invoice#',minWidth: 200},
    {id: 'name', label: 'Supplier Name', minWidth: 200},
    {id: 'total_amount',  label: 'Total',minWidth: 200},
    {id: 'action',  label: 'Action',minWidth: 200},
];

const ReturnList = ({actions, snackbars, match:{params}}) => {
    const {component} = params;

    const [data, setData] = useState({returnList:[], status:'loading', total_count:0})
    const [activePage, setActivePage] = useState(1)

    const urls = {
        'supplier':1,
        'wastage':2,
        'damages':3

    }


    useEffect(() => {
        setData({...data, status: 'loading'})
        const formData = new FormData()
        formData.append('search', '')
        formData.append('return_type', urls[component])
        axios.post(`${config.smsUrl}/cinventory/product_returns/${activePage}`, formData).then(res => {
            const data = res.data;
            const dt = data ? data : {};
            const arr = dt.product_returns ? dt.product_returns : []
            const count = dt.total_count ? dt.total_count : []
            setData({...data, returnList:arr, total_count:count, status: 'success'})
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
            setData({...data, status: 'error'})
        })
    }, [component, activePage])

    useEffect(()=>{
        if(!component){
            return
        }
        setActivePage(1)

    },[component])



    const closeSnackbar = () =>{
        actions.snackbarActions.hideSnackbar()
    }

    const {returnList, status} = data
    const isLoading = status === 'loading'
    const isSuccess = status === 'success'
    const isError = status === 'error'

    const {openBar, type, message} = snackbars;
    const emptyRows = 10 - Math.min(10, data.length - 0);

    const title = {
        'supplier':'Supplier Returns',
        'wastage':'Wastage Returns',
        'damages':'Damages'
    }

    return (
        <div className='journals'>
            <PageTitle title={title[component]}/>
            <SubHeader title="Inventory" subTitle={title[component]}>
                <FontAwesomeIcon icon={faShippingFast}/>
            </SubHeader>
            <MainSnackbar message={message} open={openBar} handleCloseBar={closeSnackbar} variant={type}/>
            <Container>
                <RightAlignedContainer>
                    <Link to='/returnproducts'><button className='btn btn-sm sms-info-btn mb-3'>Return Products</button></Link>
                </RightAlignedContainer>
                {/*<ReusableRouteTabs value={history.location.pathname} tabHeading={components}/>*/}
                <MuiPaper>
                <StickyHeadTable {...{width:'100%',data:returnList, columns:headData.filter(item=>Boolean(item))}}>
                    {isLoading ? <TableRow><TableCell align='center' colSpan={headData.length}><LoadingGif/></TableCell></TableRow>:null}
                    {isSuccess ? returnList.length > 0 ?
                        <>
                            {returnList.map((row, index) => {
                                return (
                                    <TableRow  tabIndex={-1} key={index}>
                                        <CustomTableCell>{formatDate(row.date)}</CustomTableCell>
                                        <CustomTableCell>{row.invoice_no}</CustomTableCell>
                                        <CustomTableCell>{row.supplier_name}</CustomTableCell>
                                        <CustomTableCell>{formatAmount(row.grand_total)}</CustomTableCell>
                                        <CustomTableCell>
                                            <Tooltip title='Return Report'>
                                                <Link to={{pathname:`/returnreport/${row.product_purchase_id}`}}>
                                                    <IconButton>
                                                    <ReportIcon/>
                                                    </IconButton>
                                                </Link>
                                            </Tooltip>
                                        </CustomTableCell>
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 43 * emptyRows }}>
                                    <TableCell colSpan={headData.length} />
                                </TableRow>
                            )}
                        </>:<TableRow><TableCell align='center' colSpan={headData.length}>No records found</TableCell></TableRow>:null}
                    {isError ? <TableRow><TableCell align='center'  colSpan={headData.length}>The server did not return a valid response</TableCell></TableRow>:null}
                </StickyHeadTable>
                </MuiPaper>

            </Container>
        </div>

    )
}

function mapStateToProps(state) {
    const {snackbar} = state;
    return {snackbars:snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(ReturnList);