import React from "react";
import RadiologyReport from "../../Radiology/RadiologyReport";
import Chip from "@material-ui/core/Chip";
import { usePrint } from "../../Utils/Templates/usePrint";
import { RightAlignedContainer } from "../../Utils/styledComponents";


const DoctorRadiologyResults = ({radiologyResults,  retrieveResults, scanResults, activeChip}) => {
   
    const {componentRef, handlePrint, fontSize}  = usePrint();
    
    if (radiologyResults.length < 1) {
        return null
    }
    const results = !scanResults ? {} : scanResults;
    const patient_info = !results.patient_info ? {} : results.patient_info;
    const result_details = !results.scan_result ? {} : results.scan_result;
    const details = Object.values(result_details).length;
    const dt = Object.values(patient_info).length;

    

    return (
        <div>
            <div className="mb-2">
                {radiologyResults.map((result, index) => (
                    <Chip
                        key={result.scan_result_id}
                        label={result.hospital_scan_name}
                        className="m-2"
                        color={activeChip===index ? 'primary':'default'}
                        onClick={() => retrieveResults(result.scan_result_id, index)}
                    />
                ))}
            </div>
            <RightAlignedContainer>
                <button onClick={handlePrint} className="btn sms-amber-btn  btn-sm">
                Print
                </button>
            </RightAlignedContainer>
            <div ref={componentRef}>
                    {/*{fontSize}*/}
                {(details > 1 && dt > 1) &&
                                <RadiologyReport patient_info={patient_info}
                                                scan_result={result_details}/>}

            </div>

          
        </div>
    )
};



export default DoctorRadiologyResults;
