import axios from "axios";
import { config } from "../../../Helpers/env";
import { useEffect, useState } from "react";
import { errorMessages } from "../../../Helpers/ErrorMessages";
import { useQuery } from "react-query";

/* export function useRetrievePatientCount(actions) {
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const department_id = !_token.department_id ? "" : _token.department_id;


    const [total_patients, setTotalPatients] = useState(0)

    const retrievePatients = () => {
        const formData = new FormData();
        formData.append('search', '');
        formData.append('department_id', department_id);
        axios.post(`${config.smsUrl}/cdoctor/doctor_waiting_list/${1}`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const count = !dt.total_count ? 0 : dt.total_count;
            setTotalPatients(count)
        }).catch(err => {
            errorMessages(err, null, actions)
        })
    }



    useEffect(() => {
        retrievePatients()
        const interval = setInterval(() => {
            retrievePatients()
        }, 10000)
        return () => clearInterval(interval)
    }, []);

    // const retrievePatients = async () =>{
    //     const formData = new FormData();
    //     formData.append('search', '');
    //     formData.append('department_id', department_id);
    //     const res = await axios.post(`${config.smsUrl}/cdoctor/doctor_waiting_list/${1}`, formData)
    //     const data = res.data;
    //     const dt = !data ? {} : data;
    //     return  !dt.total_count ? 0 : dt.total_count
    // }
    //
    // const {data:total_patients} = useQuery('waiting_list_patient_count', retrievePatients,{
    //     onError:(err)=>{
    //         errorMessages(err, null, actions)
    //         },
    //
    //     })

    return { total_patients }
}
 */


export function useRetrievePatientCount(actions) {
    /* 
     * user: { 
     * status: "",
     * token: [""],
     * }
     * */
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const department_id = !_token.department_id ? "" : _token.department_id;



    const { data, isLoading, isSuccess, isError } = useQuery(
        ["patientsQuery"],
        async () => {
            try {
                const formData = new FormData();

                formData.append("search", "");

                formData.append("department_id", department_id);

                const response = await axios.post(
                    `${config.smsUrl}/cdoctor/doctor_waiting_list/${1}`,
                    formData,
                    { headers: { "Content-Type": "multipart/formdata" }, }
                );

                return response.data;
            } catch (error) {
                // console.log(error)
                errorMessages(error, null, actions)
                return {};
            }
        },
        { retry: 1, staleTime: 60000, }
    );

    const total_patients = isSuccess ? data.total_count : 0;

    return { total_patients }
}
