import {useCallback, useEffect, useState} from "react";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {history} from "../../../Helpers/history";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";

export const useEditService = (props) =>{
    const [state, setState] = useState({
        service_name: "", description: "", has_consumables: false,
        quantity: "", rate: "", status: "1",service_type: ''
    });
    const [submitted, setSubmitted] = useState(false);
    const [service, setService] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [department, setDepartment] = useState('');
    const [service_type, setServiceType] = useState('');
    const {actions} = props;

    const retrieveDepartments = useCallback(() => {
        actions.departmentActions.loadDepartments();
    }, []);

    useEffect(() => {
        retrieveDepartments()
    }, []);

    useEffect(() => {
        const {service_id} = props.match.params;
        const formData = new FormData();
        formData.append('service_id', service_id);
        axios.post(`${config.smsUrl}/cbilling/service_update_form`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            setService(dt)
        }).catch(err => {
        logoutErrorMessage(err, null,actions)
        })
    }, []);

    useEffect(() => {
        setState({
            service_type:service.service_type,
            has_consumables:service.has_consumables ? service.has_consumables : false,
            service_name: service.service_name, 
            description: service.service_type,
            quantity: service.quantity, 
            rate: service.cost, status: "1"
        });
        setServiceType({label:service.service_type, value:service.service_type})
        setDepartment({label:service.department_name,value:service.department_id})
    }, [service]);

    const handleChange = (event) => {
        const {name, value} = event.target;
        setState({...state, [name]: value});
    };

    const handleChangeServiceType = (value) =>{
        setServiceType(value)
    }

    const handleChangeDepartment = (value) => {
        setDepartment(value)
    };

    const handleCloseSnackbar = () => {
        actions.snackbarActions.hideSnackbar();
    };

    const handleUpdateService = (event) => {
        event.preventDefault();
        const {service_id} = props.match.params;
        const {service_name, rate,description,status, has_consumables} = state
        setSubmitted(true);
        setIsSubmitted(true);
        let params = {
            service_name,
            'service_type' : service_type.value ? service_type.value : '',
            description,
            'service_id' : parseInt(service_id),
            'department_id' : department.value ? department.value : '',
            'is_service_consumables' :  has_consumables,
            'cost' : state.rate,
            status,
        }

        if (service_name && rate){
            axios.post(`${config.smsUrl}/cbilling/service_update`, params).then(() => {
                setIsSubmitted(false);
                actions.snackbarActions.snackSuccess('Service Updated Successfully');
                history.push('/servicelist/1')
            }).catch(err => {
                setIsSubmitted(false);
                errorMessages(err, null, actions)
            })
        }
    };

    return {state, submitted, service, isSubmitted, department, service_type, handleChange,
        handleChangeServiceType, handleChangeDepartment, handleUpdateService, handleCloseSnackbar}
}