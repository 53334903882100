import {useEffect, useState} from "react";
import axios from "axios";
import { config } from "../../../Helpers/env";
import { logoutErrorMessage, errorMessages } from "../../../Helpers/ErrorMessages";
import { useGetSurgeryDetails , checkDuration, validateOpDateStartTime} from "./useGetSurgeryDetails";
import { useDDOFilterComponent } from "./useDDOFilterComponent";
import { history } from "../../../Helpers/history";
import { useVisitList } from "../../Visit/NewVisit/hooks/useVisitList";

const GetBillDetails = (item) => {
    const bill_details = {
        'bill_id':item.bill_id,
        'invoice_no': item.invoice_no,
        'service_id': item.service_id,
        'quantity': item.quantity,
        'rate': item.rate
    }
    const new_dets = {'bill_details':bill_details};
    const invoices_removed = [];
    invoices_removed.push(new_dets);
    const cancel_details = {
        'visit_id': item.visit_id,
        'surgery_id': item.id,
        'bill_type': 2,
        'invoices_removed' : invoices_removed
    }

    return cancel_details;
}

export const useCurrentSurgeries = (actions, surgeriesByStatus) => {

    // handles loading of the table
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const isPending = isSubmitted === 'pending';

    const isRejected = isSubmitted === 'reject';
    const [loading, setLoading] = useState('idle');

    const isResolved = isSubmitted === 'resolved';

    const [searchValue, setSearchValue] = useState('');

    // status of the searching in the db
    const [status, setStatus] = useState('success');
    // keep track of the page numbers in the table
    const [activeTablePage, setActiveTablePage] = useState(1);

    // hooks to handle getting the surgeries
    const [surgeryStatus, setSurgeryStatus] = useState(surgeriesByStatus);

    const [surgeryList, setSurgeryList] = useState({surgeries: [], total_count:0});

    const [departments, setDepartments] = useState([]);

    // getting the filters
    const {
        currentstate, handleSubmitFilter, handleFilterChange, submittedDDOFilter
    } = useDDOFilterComponent(actions, activeTablePage, setSurgeryList, surgeryStatus);

    // checking the filter values
    const {
        start_date, end_date, filter_by, department, filter_operation_class,
    }  = currentstate;


    useEffect(() => {
    axios.get(`${config.smsUrl}/admission/retrieve_department`).then(res => {
            const data = res.data;
            const dt = !data ? [] : data;
            const departs = !dt.departments ? [] : dt.departments;
            const arr = departs.map(department => ({value: department.department_id,
                text: department.department_name
            }))
            setDepartments(arr)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, []);

    const handleSearch = (value) => {
        setSearchValue(value);
        //if (originalSurgeryList.surgeries.length > 0) {
            const formData = new FormData();
            formData.append("status", surgeryStatus);
            formData.append("page", activeTablePage);
            
            if (value !== '') {
                formData.append('search', value);
            }

            if (filter_by) {
                formData.append('option', Number(filter_by));
            }
            
            if (filter_by === '1') {
                // filter by operation date
                if (start_date && end_date) {
                    formData.append('start_date', start_date);
                    formData.append('end_date', end_date);
                }
            } else if (filter_by === '2') {
                // filter by department/surgery_name
                if (department) {
                    formData.append('department_id', department);
                }
            } else if (filter_by === '3') {
                //filter by operation class
                if (filter_operation_class) {
                    formData.append('operation_class', filter_operation_class);
                }
            }
                // check the value
                axios.post(`${config.smsUrl}/surgery/list/${activeTablePage}`, formData).then(res => {

                    const response = res['data'];
                    const dt = response ? response : [];
                    const surgery_arr = dt.map((item, index) => ({
                        count: (activeTablePage - 1) * 10 + (index + 1),
                        date: item.begin_datetime,
                        surgery_id: item.id,
                        visit_id: item.visit_id,
                        patient_number: item.patient_number,
                        patient_name: `${item.first_name} ${item.last_name}`,
                        status: item.status,
                        operation_date: item.operation_date,
                        operation_class: item.operation_class,
                        surgery_name : item.service_name,
                        lead_surgeon: item.lead_surgeon,
                        service_id : item.service_id,
                        surgery_department : item.department_name,
                        patient_type : item.patient_type
                    }));
                    setSurgeryList({surgeries: surgery_arr, total_count: surgery_arr.length});
                    setLoading('success');
                }).catch((err) => {
                    logoutErrorMessage(err, null, actions);
                    setLoading('error');
                })
    }

    const handleChangeNewPage = (page) => {
        setActiveTablePage(page);
    }

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('sl');
    
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    
    const isLoading = status === 'pending';
    const isSuccess = status === 'success';
    const isError = status === 'error';

    const [ids, setIds] = useState({
        patient_number: '', visit_id: '', status:0, visit_type:'', patient_name:'', 
        age:'', gender:'', screen:'', operation_date:'', estimated_start_time:'', estimated_duration:'',
        operation_class:'', lead_surgeon:'', surgery_name:'',  lead_anaestheist:"",
        other_anaestheists:"", other_surgeons:"", anaesthesiologist:"", scrub_nurse:"",
        circulating_nurse:"", anesthesia_type:"", 
        // notes
        preoperative_instructions:"", postoperative_instructions:"", preoperative_diagnosis:"", //planned_procedures:"", 
        summary_report:""
        // add
    });

    const [openDialog, setOpenDialog] = useState(false);
    
    const {
        assignSurgeryDetails,
        surgery_time,  onChangeSurgeryTime, matchAdmitProps,
        general_notes, handleNotesState, admissionProps, patient_type,
        operation_class, operationClasses, handleOpClassChange, 
        anaesthesia_type, anaesthesiaTypes, handleAnesTypeChange, 
        p_number, handleUpdateSurgery, surgery_id,
        surgery_name, handleSurgeryTypeChange, surgeryTypes, 
        handleCloseConfirm, openConfirmationDialog, surgeryConfirmation, setConfirmation, 
        surgeon,surgeons, isOpen, handleChangeSurgeon, handleSelectSurgeon, surgery_notes,
        other_anaestheists, other_surgeons, isVisitingDoctor, inputDoc,
        handleOtherSurgeons, handleOtherAnaestheists, handleChangeDoctors, handleChangeInputSurgeons, 
    } = useGetSurgeryDetails(actions); 

    const { handleSubmitTriage, triageProps, handlePatientDetails,
    } = useVisitList(actions)

    const handleOpenDialog = (item, screen, surgery_visit_view) => {

        const doctor_view = surgery_visit_view ? false : true;
        const formD = new FormData();
        formD.append("surgery_id", item.surgery_id);
        formD.append("visit_id", item.visit_id);
        formD.append("doctor_view", doctor_view.toString());

        axios.post(`${config.smsUrl}/surgery/view_surgery_details`, formD).then(res => {
            const resp = res['data']; 

            if (surgery_visit_view) {

                const dt = resp ? resp : {};
                const surgery_data = dt.surgery_data ? dt.surgery_data : {};
                
                setIds({...item, ...surgery_data, screen})
                assignSurgeryDetails({...item, ...surgery_data, screen});

            } else {
                const response = resp ? resp : {};
                const diagnosis = response.diagnosis ? response.diagnosis : [];
                const triage = response.triage ? response.triage : [];
    
                const surgery_data = response.surgery_data ? response.surgery_data : {};
    
                setIds({...item, ...surgery_data, diagnosis, triage, screen})
                assignSurgeryDetails({...item, ...surgery_data, diagnosis, triage, screen});

                if (screen === 'startS') {
                    handlePatientDetails({...item,  triage, screen});
                }
            }

            setOpenDialog(true);
        }).catch((err) => {
            errorMessages(err, null, actions);
        });
    }

    const {openAdmission, setAdmissionDialog} = admissionProps;

    const handleOpenAdmission = (item, screen, surgery_visit_view) => {
        setAdmissionDialog(true);

        const doctor_view = surgery_visit_view ? false : true;
        const formD = new FormData();
        formD.append("surgery_id", item.surgery_id);
        formD.append("visit_id", item.visit_id);
        formD.append("doctor_view", doctor_view.toString());

        axios.post(`${config.smsUrl}/surgery/view_surgery_details`, formD).then(res => {
            const resp = res['data']; 

            if (surgery_visit_view) {

                const dt = resp ? resp : {};
                const surgery_data = dt.surgery_data ? dt.surgery_data : {};
                
                setIds({...item, ...surgery_data, ['screen']:screen})
                assignSurgeryDetails({...item, ...surgery_data, ['screen']:screen});

            } else {
                const response = resp ? resp : {};
                const diagnosis = response.diagnosis ? response.diagnosis : [];
                const triage = response.triage ? response.triage : [];
    
                const surgery_data = response.surgery_data ? response.surgery_data : {};
    
                setIds({...item, ...surgery_data, ['diagnosis']:diagnosis, ['triage']:triage, ['screen']:screen})
                assignSurgeryDetails({...item, ...surgery_data, ['diagnosis']:diagnosis, ['triage']:triage, ['screen']:screen});

                if (screen === 'startS') {
                    handlePatientDetails({...item,  ['triage']:triage, ['screen']:screen});
                }
            }


        }).catch((err) => {
            errorMessages(err, null, actions);
        });
    }

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const [cancelDialog , setCancelDialog] = useState(false);
    const handleCancelSurgery = (item) => {
        
        const params = { ...item.cancel_details};
        setIsSubmitted('pending');

        axios.post(`${config.smsUrl}/surgery/cancel_surgery`, {...params}).then((res) => {
            actions.snackbarActions.snackSuccess('Surgery cancelled');
            setIsSubmitted('resolved');
            setConfirmation(false);
        }).catch((err) => {
            setIsSubmitted('rejected');
            setConfirmation(false);
            errorMessages(err, null, actions);
        });

        // snackbar saying thingy is removed
        setCancelDialog(false);
    }

    const handleOpenCancel = (item) => {
        setIds(item);
        assignSurgeryDetails(item);
        setCancelDialog(true);
    }

    const handleCloseCancel = () => {
        setCancelDialog(false);
    }

    const [startDialog , setStartDialog] = useState(false);
    const handleStartSurgery = (event) => {

        event.preventDefault();
        setStartDialog(false);
        setSurgeryStatus(1);
        
        const params2 = {surgery_id, "status":1}

        const {lead_surgeon, lead_anaestheist, anaesthesiologist, scrub_nurse, circulating_nurse} = surgeon

        const anaesT = (anaesthesia_type['value']  === undefined) ? "" : anaesthesia_type['value'];

        const params1 = { 
            surgery_id, lead_surgeon, anaesthesiologist, lead_anaestheist, scrub_nurse, circulating_nurse,
            preoperative_instructions : general_notes.preoperative_instructions,
            preoperative_diagnosis : general_notes.preoperative_diagnosis,
            other_anaestheists : other_anaestheists.map(a => a.label).join(","),
            other_surgeons : other_surgeons.map(a => a.label).join(","), anaesthesia_type : anaesT
        }

        const req1 = axios.post(`${config.smsUrl}/surgery/update_surgery_details`, {...params1});
        const req2 = axios.post(`${config.smsUrl}/surgery/start_surgery`, {...params2});

        setIsSubmitted('pending');

        Promise.all([req1, req2]).then(([res1, res2]) => {
            actions.snackbarActions.snackSuccess('Surgery started');
            history.push(`/completesurgerydetails/${p_number}/${patient_type}/${ids.visit_id}/${surgery_id}`)
            setIsSubmitted('resolved');
            setConfirmation(false);
        }).catch(errs => {
            logoutErrorMessage(errs, null, actions)
            setIsSubmitted('rejected');
            setConfirmation(false);
        })
    }

    const handleCloseStart= () => {
        setStartDialog(false);
    }

    const handleRescheduleSurgeryScreen = (event) => {
 
        event.preventDefault();

        if (p_number && checkDuration(surgery_time.duration_hr, surgery_time.duration_mins) && validateOpDateStartTime(surgery_time.operation_date, surgery_time.estimated_start_time)) {
            
            const estimated_duration = `${surgery_time.duration_hr}:${surgery_time.duration_mins}`
            const params = {
                patient_number: p_number, surgery_id, estimated_start_time: surgery_time.estimated_start_time, estimated_duration, operation_date: surgery_time.operation_date,
            }
            setIsSubmitted('pending');

            axios.post(`${config.smsUrl}/surgery/reschedule_surgery`, {...params}).then((res) => {
            
                actions.snackbarActions.snackSuccess('Surgery updated');
                setIsSubmitted('resolved');
                setConfirmation(false);
                setOpenDialog(false);
            }).catch((err) => {
                setIsSubmitted('rejected');
                setConfirmation(false);
                errorMessages(err, null, actions);
            });
        } else {
            setIsSubmitted('rejected');
            setConfirmation(false);
        }
    }

    const handleUpdateSurgeryScreen = (event) => {
        handleUpdateSurgery(event);
        setOpenDialog(false);
        setIsSubmitted('resolved');
    }

        // api call to get the visits
    // send a date filter
    useEffect(() => {
        const formD = new FormData();
        formD.append("status", surgeryStatus);
        formD.append("page", activeTablePage);
        axios.post(`${config.smsUrl}/surgery/list/${activeTablePage}`, formD).then(res => {

            const response = res['data'];
            const dt = response ? response : [];

            const surgery_arr = dt.map((item, index) => ({
                count: (activeTablePage - 1) * 10 + (index + 1),
                date: item.begin_datetime,
                surgery_id: item.id,
                visit_id: item.visit_id,
                patient_number: item.patient_number,
                patient_name: `${item.first_name} ${item.last_name}`,
                status: item.status,
                operation_date: item.operation_date,
                operation_class: item.operation_class,
                surgery_name : item.service_name,
                lead_surgeon: item.lead_surgeon,
                service_id : item.service_id,
                surgery_department : item.department_name,
                cancel_details : GetBillDetails(item),
                patient_type : item.patient_type
            }));

            setSurgeryList({surgeries: surgery_arr, total_count: surgery_arr.length});
            //setOriginalSurgeryList({surgeries: surgery_arr, total_count: surgery_arr.length});
            setLoading('success');
        }).catch(err => {
            logoutErrorMessage(err, null, actions);
            setLoading('error');
        }) 

    }, [surgeryStatus, activeTablePage, isResolved, openAdmission]); 


    return {
        isPending, isRejected, isResolved, searchValue, activeTablePage,
        isError, isLoading, isSuccess, handleSearch, handleChangeNewPage,
        handleOpenDialog, handleCloseDialog, ids, openDialog, setStartDialog,
        surgeryList, surgeryStatus, setSurgeryStatus, handleRescheduleSurgeryScreen,
        cancelDialog, handleCancelSurgery, handleCloseCancel, handleOpenCancel,
        submitted, startDialog, handleStartSurgery, handleCloseStart,
        currentstate, handleSubmitFilter, handleFilterChange, submittedDDOFilter,
        surgery_time,  onChangeSurgeryTime, general_notes, handleNotesState,
        operation_class, operationClasses, handleOpClassChange,
        anaesthesia_type, anaesthesiaTypes, handleAnesTypeChange,
        p_number, handleUpdateSurgeryScreen, handleRequestSort, admissionProps,
        surgery_name, handleSurgeryTypeChange, surgeryTypes,
        handleCloseConfirm, openConfirmationDialog, surgeryConfirmation,
        setConfirmation, surgeon,surgeons, isOpen, handleChangeSurgeon, handleSelectSurgeon,
        other_anaestheists, other_surgeons, isVisitingDoctor, inputDoc, handleOpenAdmission,
        handleOtherSurgeons, handleOtherAnaestheists, handleChangeDoctors, handleChangeInputSurgeons,
        handleSubmitTriage, triageProps, order, orderBy,  departments,
    }
}