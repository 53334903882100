import React, {useEffect,  useState} from "react";
import PageTitle from "../Utils/smsTitle";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CustomTable from "../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {Link} from "react-router-dom";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import *  as snackbarActions from "../../actions/snackbarActions";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import { config } from "../../Helpers/env";
import axios from "axios";
import {logoutErrorMessage} from "../../Helpers/ErrorMessages";
import {faPrescriptionBottle} from "@fortawesome/free-solid-svg-icons/faPrescriptionBottle";
import { Container } from "../Utils/styledComponents";
import { faWheelchair } from "@fortawesome/free-solid-svg-icons/faWheelchair";

const headData = [
    {id: 'dispatch_id', numeric: false, disablePadding: false, label: 'Dispatch#'},
    {id: 'dispatched_by', numeric: false, disablePadding: false, label: 'Dispatched By'},
    {id: 'action', numeric: false, disablePadding: false, label: 'Action'},
];


const EmergencyDispatchList = ({actions, snackbars, match}) => {
    const [loading, setLoading] = useState(false);
    const [dispatchList, setList] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        setLoading(true);
        axios.post(`${config.smsUrl}/cinventory/pharmacy_dispatch_list`,{status:0, to_store:'Patient Emergency'}).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const cats = !dt.data ? [] : dt.data;
            setList(cats);
            setLoading(false);
        }).catch((err) => {
            logoutErrorMessage(err, null, actions);
            setLoading(false);
        })
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const dispatches = !dispatchList ? [] : dispatchList;
    const {openBar, type, message} = snackbars;

    return (
        <div data-testid="dispatch-list" className='journals'>
            <PageTitle title="Dispatch List"/>
            <SubHeader title="Patient Emergency" subTitle="Dispatch List">
                <FontAwesomeIcon icon={faWheelchair}/>
            </SubHeader>
            <Container>
                <div className="mui-tables">
                    <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>
                    <CustomTable title="Dispatched Products"  records={10}
                                headData={headData}  data={dispatches} colSpan={5}
                                handleChangePage={handleChangePage}
                                handleChangeRowsPerPage={handleChangeRowsPerPage}
                                page={page} rowsPerPage={rowsPerPage}
                                id="searchValue" filter >
                        {loading ? <TableBody><TableRow>
                            <TableCell colSpan="6" align="center"><img src="/images/smsloader.gif"
                                                                    alt="loader"/></TableCell>
                        </TableRow></TableBody> : dispatches.length > 0 ?
                            <TableBody>
                                {dispatches.slice(0, 10)
                                    .map((item, index) => {
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={index}
                                                className='mui-table-row'>
                                                <TableCell>{item.product_dispacth_id}</TableCell>
                                                <TableCell>{item.created_by}</TableCell>

                                                <TableCell>
                                                    <Link
                                                        to={{pathname: `/dispatcheditems/emergency/${item.product_dispacth_id}`}}>
                                                        <button className="btn btn-sm sms-info-btn mr-1">
                                                            Receive Products</button>
                                                    </Link>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                            : <TableBody><TableRow>
                                <TableCell colSpan="6" align="center">No records found</TableCell>
                            </TableRow></TableBody>}
                    </CustomTable>
                </div>
            </Container>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(EmergencyDispatchList);
