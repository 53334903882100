import React from "react";
import axios from "axios";
import {config} from "../../../Helpers/env";

export function useDentalMedication(props) {
    const {formRef, visit_id} = props
    const [prescribedDrugs, setPrescribedDrugs] = React.useState([])
    const [removedProducts, setRemovedProducts] = React.useState([])
    const [products, setProducts]  = React.useState([])
    const [product, setProduct] = React.useState('')
    const [alert, setAlert] = React.useState({})
    const [stockAlert, setStockAlert] = React.useState({})
    const [confirmation, setConfirmation] = React.useState(false)
    const [product_id, setProductId] = React.useState('')
    const [submitted, setSubmitted] = React.useState(false)



    const autoSearchComplete = (event) =>{
        const formData = new FormData();
        formData.append('product_name', event);
        setProduct(event)
        if (event !== '') {
            axios.post(`${config.smsUrl}/cdoctor/autocomplete_search_medicine`, formData).then(res => {
                const resp = res.data;
                const data = !resp ? [] : resp;
                if (data[0] === "No Product Found") {
                    setProducts([{value: '', label: 'No medicine found'}])
                } else {
                    const arr = data.map(item => ({
                        value: item.value,
                        label: `${item.label}-${item.generic_name}-${item.country_of_origin}`
                    }))
                    setProducts(arr);
                }
            }).catch(error => {
                // logoutErrorMessage(error, null, actions)
            });
        }
        setAlert({...alert, open: false})
    }

    const clickDrug = (event) => {
        const formData = new FormData();
        formData.append('product_id', event.value);
        formData.append('location', 2); // 2 for pharmacy
        formData.append('to_store', 'Pharmacy');
        setProduct(event.innerText)
        if (event.value) {
            axios.post(`${config.smsUrl}/cdoctor/get_medicinedetails_byproduct_id`, formData).then(res => {
                const resp = res.data;
                const data = !resp ? {} : resp;
                const {quantity} = data
                setAlert({...alert, open: data.isExpired, message: event.innerText, isExpired: data.isExpired})
                setStockAlert({...stockAlert, openAlert: quantity <= 0, alertMessage: event.innerText, isStockOut: quantity <= 0})
                axios.post(`${config.smsUrl}/cbilling/item_cost`, {visit_id: +visit_id, item_id:+event.value, item_type:'product'}).then(res => {
                    const resObj = res.data;
                    const dataObj = !resObj.data ? {} : resObj.data;
                    const obj = { rate:  dataObj.cost ?? 0,status: quantity,...dataObj,isExpired:data.isExpired,
                        currency: dataObj.currency_id, currency_symbol: dataObj.currency, stock:quantity,isStockOut: quantity <= 0,
                        product_name:event.innerText, dosage:'', frequency:'',number_of_days:'', quantity_prescribed:'', key_information:'',isApi:false,
                        product_id:event.value, isRequired:true, quantity_to_bill: 0}

                    const arr = [obj,...prescribedDrugs]

                    const filteredArr = arr.filter(item=>!item.isExpired)

                    setPrescribedDrugs(filteredArr)
                    if(dataObj.is_provider === 1 && dataObj.is_provided === 0){
                        setProductId(event.value)
                        setConfirmation(true)
                    }
                }).catch(error => {
                    // errorMessages(error, null, actions)
                });
            }).catch(error => {
                // errorMessages(error, null, actions)
            });
        }
    }

    const handleCloseAlert = () =>{
        setAlert({...alert, open: false})
    }


    const handleCloseStockAlert = () =>{
        setStockAlert({...stockAlert, openAlert: false})
    }

    const addItemsToReceipt = () =>{
        // const arr = prescribedDrugs.filter(item=>item.product_id === product_id)
        // setPrescribedDrugs([...prescribedDrugs,...arr])
        setConfirmation(false)
    }

    const handleCloseConfirmation = () =>{
        const arr = prescribedDrugs.filter(item=>item.product_id !== product_id)
        setPrescribedDrugs(arr)
        setConfirmation(false)
    }

    const handleCancelConfirmation = () =>{
        setConfirmation(false)
    }

    const handleAddItem = (item) =>{
        const formData = new FormData(formRef.current)
        const dosage = formData.get(`dosage_${item['product_id']}`)
        const frequency = formData.get(`frequency_${item['product_id']}`)
        const number_of_days = formData.get(`number_of_days_${item['product_id']}`)
        const quantity_prescribed = formData.get(`quantity_prescribed_${item['product_id']}`)
        const key_information = formData.get(`key_information_${item['product_id']}`)
        const quantity_to_bill = formData.get(`quantity_to_bill_${item['product_id']}`)

        const arr = prescribedDrugs.map((presc)=>{
            if (item.product_id === presc.product_id){
                return {...item, number_of_days, dosage, frequency, quantity_prescribed, key_information,
                    isRequired:!quantity_prescribed, isAdded:true, isApi:false, quantity_to_bill}
            }
            return presc
        })
        setPrescribedDrugs(arr)
    }

    const handleRemove = (item) =>{
        const arr = prescribedDrugs.filter((presc)=>item.product_id !== presc.product_id)
        const removed = prescribedDrugs.filter((presc)=>item.product_id === presc.product_id)
        setPrescribedDrugs(arr)
        setRemovedProducts(removed)
    }



    const prescriptionProps = {prescribedDrugs, product, products, alert, confirmation, autoSearchComplete,
        clickDrug, handleAddItem, handleRemove, addItemsToReceipt, handleCloseConfirmation, handleCancelConfirmation,handleCloseAlert,
    removedProducts, setPrescribedDrugs, stockAlert, handleCloseStockAlert, submitted}

    return {prescriptionProps}
}