import React from "react";

export const RenderCustomizedPercentLabel = (props) => {
    const {x, y, stroke, value} = props;
    return <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">{`${value}%`}</text>;
};

export const CustomizedLabel = (props) =>  {
    const {x, y, stroke, value} = props;
    return <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">{value}</text>;
}

export const RenderCustomizedBarLabel = (props) => {
    const {x, y, width, value} = props;
    return (
        <g>
            <text x={x + width / 2} y={y - 10} textAnchor="middle" dominantBaseline="middle">{`${value}%`}</text>
        </g>
    );
};

export const toPercentTick = (value) => `${value}%`;
