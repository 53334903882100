import React from "react";
import PageTitle from "../Utils/smsTitle";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCog} from "@fortawesome/free-solid-svg-icons/faCog";
import {Card} from "@material-ui/core";
import Form from "react-bootstrap/Form";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../actions/snackbarActions";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import {useTheme} from "@material-ui/core/styles";
import {useAddRole} from "./CustomHooks/useAddRole";
import { BackButton } from "../Utils/Buttons/DataExportationButton";
import {CollapsibleList, HeadCell, DataCell, ModuleTable} from "../Utils/Lists/CollapsibleList";
import TableRow from "@material-ui/core/TableRow";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Label from "../Utils/FormInputs/Label";
import TextField from "../Utils/FormInputs/TextField";
import {ErrorMessage} from "../Utils/styledComponents";


const headData = [{item:'Module Name', align:'left'},{item:'Create',align: 'left'},
    {item:'Read', align:'left'},{item:'Update', align:'left'},{item:'Delete', align: 'left'}];

function AddRole({actions,snackbars, match:{params}, location:{state}}) {
    const {role_id} = params;
    const {
        rows, role_name, submitted, isSubmitted, handleChangeRoleName,
        handleChangeCheckAll, handleChangeCheck, handleSubmit, handleReset, closeSnackbar,
        handleOpen, handleCheckRow,isSuccess, isLoading, isError
    } = useAddRole(actions, role_id, state)
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    const {openBar, type, message} = snackbars;
    return (
        <div data-testid="add-role">
            <PageTitle title="Add Role"/>
            <SubHeader title="Settings" subTitle="Add Role">
                <FontAwesomeIcon icon={faCog}/>
            </SubHeader>
            <div className="add-role-card">
                <div className='mb-3'>
                    <BackButton text='Roles List' to='/roles'/>
                </div>
                <Card className="p-3">
                    <h6 className={`visit-heading ${palette.type === "dark" && "dark-visit-heading"}`}>Add Role</h6>
                    <MainSnackbar variant={type} handleCloseBar={closeSnackbar} open={openBar} message={message}/>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={1} xl={1}>
                                <Label name="Role" type/>
                            </Col>
                            <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                                <TextField submitted={submitted} onChange={handleChangeRoleName} value={role_name}
                                           name="role_name"  id="role_name"
                                            type="text" />
                                {submitted && !role_name ? <ErrorMessage>Role is required</ErrorMessage>:null}
                            </Col>
                        </Form.Group>
                        {isLoading ? <div className="text-center mt-3">Loading....</div> :null}
                            {isSuccess ?  rows.length > 0 ?  rows.map((item, index) => {
                                const contents = item.params ? item.params : []
                                return (
                                    <div key={index}>
                                        <CollapsibleList key={index} open={item.open} name={item.module_name} handleOpen={()=>handleOpen(index)}>

                                            <ModuleTable headData={headData} extraCell={
                                                <HeadCell align='center'>
                                                    <Form.Check type="checkbox" checked={item.check}
                                                                name="check"
                                                                onChange={e => handleChangeCheckAll(e, index)}
                                                                style={{transform: "scale(1.2)"}}
                                                                id={`select-${item.module_name}`}/>
                                                </HeadCell>
                                            }>
                                                {contents.map((det, ind) => {
                                                    return(
                                                        <TableRow
                                                            key={ind}
                                                        >
                                                            <DataCell align="center">
                                                                <Form.Check type="checkbox"
                                                                            checked={det.checkedRow} name="check"
                                                                            onChange={e => handleCheckRow(e, index, ind)}
                                                                            style={{transform: "scale(1.2)"}}
                                                                            id={`select-${det.sub_module_name}`}/>
                                                            </DataCell>
                                                            <DataCell>
                                                                {det.sub_module_name}
                                                            </DataCell>
                                                            <DataCell>
                                                                <input type="checkbox" name="create"
                                                                       style={{transform: "scale(1.3)"}}
                                                                       checked={+det.create}
                                                                       onChange={e => handleChangeCheck(e, index, ind)}/>
                                                            </DataCell>
                                                            <DataCell >
                                                                <input type="checkbox" style={{transform: "scale(1.3)"}}
                                                                       name="read"
                                                                       checked={+det.read}
                                                                       onChange={e => handleChangeCheck(e, index, ind)}/>
                                                            </DataCell>
                                                            <DataCell>
                                                                <input type="checkbox" style={{transform: "scale(1.3)"}}
                                                                       checked={+det.update} name="update"
                                                                       onChange={e => handleChangeCheck(e, index, ind)}/>
                                                            </DataCell>
                                                            <DataCell>
                                                                <input type="checkbox" style={{transform: "scale(1.3)"}}
                                                                       checked={+det.delete} name="delete"
                                                                       onChange={e => handleChangeCheck(e, index, ind)}/>
                                                            </DataCell>
                                                        </TableRow>
                                                    )})}
                                            </ModuleTable>
                                        </CollapsibleList>
                                    </div>
                                )
                            }): <div className="text-center">No modules and sub modules</div>:null}
                        {isError ? <div className="text-center">The server did not return a valid response</div>:null}
                        <div className="text-right">
                            <button onClick={handleReset} type="button"
                                    className="btn btn-sm sms-grey-btn px-4 mr-2"
                                    id="reset_role">Reset
                            </button>
                            <button type="submit" disabled={isSubmitted === 'pending' || isSubmitted === 'resolved'} id="submit_role"
                                    className="btn btn-sm sms-btn px-4">{isSubmitted === 'pending' ? "Saving..." : "Save"}</button>
                        </div>

                    </Form>
                </Card>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(AddRole);
