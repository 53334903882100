import * as types from "../../actions/actionTypes";

const initialState = {
    bill_report: [],
    patientBills: [],
    consultation_bill: [],
    procedure_bill: [],
    lab_bill: [],
    scan_bill: [],
    monthly_visits: "",
    daily_visits: "",
    this_months_sales: [],
    drugs_in_range: {},
    monthly_visit_percentage: {},
    drugs_by_date: {},
    drug_sales: {},
    presc_drugs: [],
    customers: [],
    billingList: [],
    total_count: 0
};
export default function billingsReducer(state = initialState, action) {
    switch (action.type) {
        case types.BILL_TOTAL_COUNT:
            return {...state, total_count: action.total_count};
        case types.BILL_LIST_REQUEST:
            return {loading: true};
        case types.BILL_LIST_SUCCESS:
            return {...state, billingList: action.billingList, loading: false};
        case types.BILL_LIST_ERROR:
            return {loading: false};
        case types.DELETE_BILL:
            return {...state, billingList: state.billingList.filter(bill => bill.bill_id !== action.bill_id)};

        // case types.BILL_REPORT:
        //     return {...state, bill_report: action.bill_report};
        // case types.GET_ALL_BILLS:
        //     return {...state, patientBills: action.patientBills};
        // case types.GET_SCAN_BILL:
        //     return {
        //         ...state, scan_bill: action.scan_bill
        //     };
        // case types.GET_LAB_BILL:
        //     return {...state, lab_bill: action.lab_bill};
        // case types.GET_PROCEDURE_BILL:
        //     return {...state, procedure_bill: action.procedure_bill};
        // case types.GET_CONSULTATION_BILL:
        //     return {...state, consultation_bill: action.consultation_bill};
        // case types.MONTHLY_VISITS:
        //     return {...state, monthly_visits: action.monthly_visits};
        // case types.DAILY_VISITS:
        //     return {...state, daily_visits: action.daily_visits};
        // case types.THIS_MONTHS_SALES:
        //     return {...state, this_months_sales: action.this_months_sales};
        // case types.DRUGS_IN_RANGE:
        //     return {...state, drugs_in_range: action.drugs_in_range};
        // case types.MONTHLY_VISIT_PERCENTAGE:
        //     return {...state, monthly_visit_percentage: action.monthly_visit_percentage};
        // case types.DRUGS_BY_DATE:
        //     return {...state, drugs_by_date: action.drugs_by_date};
        // case types.GET_ALL_PRESC_DRUGS:
        //     return {...state, presc_drugs: action.presc_drugs};
        // case types.UPDATE_DRUGS_BY_DATE:
        //     return {...state, drug_sales: action.drug_sales};
        // case types.GET_CUSTOMER_VISITS:
        //     return {...state, customers: action.customers};
        default:
            return state;
    }
}
