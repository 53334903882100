import React from "react";
import Card from "@material-ui/core/Card";
import PageTitle from "../Utils/smsTitle";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faNotesMedical} from "@fortawesome/free-solid-svg-icons/faNotesMedical";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Label from "../Utils/FormInputs/Label";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../actions/snackbarActions";
import * as departmentActions from "../../actions/DoctorsActions/departmentActions";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import {useTheme} from "@material-ui/core/styles";
import {CustomSelect} from "../Utils/FormInputs/SelectInput";
import {titleCase} from "../Users/addUser";
import {makeStyles} from "@material-ui/core/styles";
import {useAddService} from "./CustomHooks/useAddService";
import {Link} from "react-router-dom";
import {faListAlt} from "@fortawesome/free-solid-svg-icons/faListAlt";
import { ErrorMessage } from "../Utils/styledComponents";
import ServiceConsumables from "./ServiceConsumables";
import TextField from "../Utils/FormInputs/TextField";
import {ResizableTextarea} from "../Utils/FormInputs/Textarea";
import {BackButton} from "../Utils/Buttons/DataExportationButton";


const useStyles = makeStyles((theme) => ({
    cardRoot: {
        overflow: "inherit",
        borderRadius: '20px',

    }
}));


export function AddService(props) {
    const classes = useStyles();
    const {state, submitted, isSubmitted, department, service_type, handleChange, handleChangeDepartment,
        isServiceConsumables, handleConsumablesToggle, consumablesObj:serviceConsumablesProps,
        handleChangeServiceType, handleResetForm, handleSubmitForm, handleCloseSnackbar} = useAddService(props)
    const {openBar, type, message} = props.snackbars;
    const {departments} = props.departments;
    const departs = !departments ? [] : departments;
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    return (
        <div data-testid="add-service" className='journals'>
            <PageTitle title="Add Service"/>
            <SubHeader title="Services" subTitle="Add Service">
                <FontAwesomeIcon icon={faNotesMedical}/>
            </SubHeader>
            <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={handleCloseSnackbar}/>
            <div style={{ padding: '10px 20px'}}>
                <div className="text-right">
                    <Link to="/servicelist/1">
                        <button className="btn sms-btn mb-2"><FontAwesomeIcon icon={faListAlt} style={{marginRight:5}}/>Services</button>
                    </Link>
                </div>
            </div>
            <div className="add-service-card">
                <BackButton text='Service list' to='/servicelist/1'/>
                <Card className="p-3 general-card" classes={{root: classes.cardRoot}}>
                    <h6 style={{marginBottom: '15px', fontSize: '20px', textAlign: 'center', fontWeight:'600'}}>Add Service</h6>
                   
                    <form onSubmit={handleSubmitForm} id="submit_service_form" autoComplete="off">
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                                <Label  name="Service Type" type/>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                            <CustomSelect submitted={submitted} onChange={handleChangeServiceType} value={service_type}
                                              id='servicetype'
                                              options={[
                                                    {label: 'Procedure', value: 'Procedure'},
                                                    {label: 'Lab', value: 'Lab'},
                                                    {label: 'Radiology', value: 'Radiology'},
                                                    {value:'Dental', label:'Dental'},
                                                    {value:'Surgery', label:'Surgery'},
                                                    {label: 'Ante Natal Care', value: 'Ante Natal Care'}
                                                    ]} dataTest="service_type"/>
                                {(submitted && !service_type) &&
                                    <div className="help-block" id="status_help_block">Service Type  is required</div>}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                                <Label  name="Service Name" htmlFor='service_name' type/>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                                <TextField type="text"  id="service_name"
                                           value={titleCase(state.service_name)}
                                           name="service_name" onChange={handleChange}
                                           submitted={submitted}/>
                                {submitted && !state.service_name ? <ErrorMessage>Service name is required</ErrorMessage>:null}
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                                <Label  name="Clinic/Department"  htmlFor='department' type/>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                                <CustomSelect onChange={handleChangeDepartment} id="department" value={department}
                                              options={departs.map(dep => ({
                                                  label: dep.department_name, value: dep.department_id
                                              }))} dataTest="department" isDisabled={service_type.label === 'Lab' ||
                                    service_type.label === 'Radiology'} submitted={submitted}/>
                                {(submitted && !department) &&
                                    <ErrorMessage>Department  is required</ErrorMessage>}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                                <Label  name="Cost" htmlFor='rate' type/>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                                <TextField onChange={handleChange}  value={state.rate}
                                           name="rate" type="number"  submitted={submitted}
                                           placeholder = 'Input service cost'  id='rate'/>
                                {submitted && !state.rate ? <ErrorMessage>Service cost is required</ErrorMessage>:null}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                                <Label  name="Description" htmlFor='description'/>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                                <ResizableTextarea value={titleCase(state.description)} id="description"
                                                   onChange={handleChange} rows={3} name="description"/>
                            </Col>
                        </Form.Group>

                        {/*
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                                <Label  name="Status" htmlFor='status' type/>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                                <Form.Check type="radio" inline label="Active" name="status" value="1"
                                            checked={state.status === "1"}
                                            onChange={handleChange} id="active"/>
                                <Form.Check type="radio" inline label="Inactive" name="status" value="0"
                                            checked={state.status === "0"}
                                            onChange={handleChange} id="inactive"/>
                                {(submitted && !state.status) &&
                                    <div className="help-block" id="status_help_block">Status is required</div>}
                            </Col>
                        </Form.Group>
                        */}

                        {(service_type !== '' && state.service_name !== '' && service_type['value'] === 'Lab') ? <div>
                            <Form className="switch-admission-prescription" id="is-add-service-consumables">
                                <Label name="Add Service Consumables?" className="label-admission-prescription"/>
                                <text className="label-admission-prescription">No</text>
                                <Form.Check type="switch" id="custom-switch" defaultChecked={isServiceConsumables} onChange={handleConsumablesToggle} className="toggle-admission-prescription"/>
                                <text className="label-admission-prescription">Yes</text>
                            </Form>

                            {isServiceConsumables ?
                                <ServiceConsumables {...serviceConsumablesProps}/>
                            : null}
                        </div> : null}

                        <div className="text-right">
                            <button onClick={handleResetForm} type="button"
                                    className="btn btn-sm sms-grey-btn px-4 mr-2" id="reset_service">Reset
                            </button>
                            <button type="submit" id="submit_service" className="btn btn-sm sms-btn px-4"
                                    disabled={isSubmitted==='pending'|| isSubmitted === 'resolved'}>
                                {isSubmitted === 'pending' ? "Saving..." : "Save"}</button>
                        </div>
                    </form>
                </Card>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {snackbars: state.snackbar, departments: state.departments}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch),
            departmentActions: bindActionCreators(departmentActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddService);
