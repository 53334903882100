import React from 'react'
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faShippingFast} from "@fortawesome/free-solid-svg-icons/faShippingFast";
import {Container, ErrorMessage, RightAlignedContainer} from "../../Utils/styledComponents";
import {StockProductsTable, StockProductRow} from "./StockCollapsibleTable";
import {ModuleTable} from "../../Utils/Lists/CollapsibleList";
import {TableRow} from "@material-ui/core";
import TextField from "../../Utils/FormInputs/TextField";
import {StyledCell} from "../../DoctorsModule/doctorStyles";
import Form from "react-bootstrap/Form";
import Label from "../../Utils/FormInputs/Label";
import Col from "react-bootstrap/Col";
import {AutoCompleteUnControlled} from "../../Utils/FormInputs/SelectInput";
import styled from "@emotion/styled/macro";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../../actions/snackbarActions";
import {connect} from "react-redux";
import {useInitialStock} from "./hooks/useInitialStock";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import TableCell from "@material-ui/core/TableCell";
import CustomDialog from "../../Utils/Dialogs/CustomDialog";
import {ItemsTable} from "../../Utils/Templates/PrintTemplate";
import {BackButton} from "../../Utils/Buttons/DataExportationButton";
import { CsvDialog } from '../../Utils/Dialogs/CsvDialog';

const TextContainer = styled.div({
    width:'30%'
})

const parentHeadData = [{name:'Product Name'},{name:''}]
const initialHeadData = [{item:'Batch#', isRequired:true},{item:'Expiry Date', isRequired:true},
    {item:'Quantity', isRequired:true},{item:'Cost', isRequired:true},{item:'Total Cost', isRequired:false},
    {item:'Action', isRequired:false}]

const previewHeadData = [{key:'batch_id',value:'Batch#'}, {key:'expiry_date',value:'Expiry Date'}, {key:'quantity',value:'Quantity'},
    {key:'cost',value:'Unit Cost'},{key:'total_cost',value:'Total Cost'}]
function AddInitialStock({actions, snackbars}) {
   const {products, drugs, handleOpen, handleClickDrug, handleRetrieveDrugs,
        isPending, submitted, handleAddBatch, handleRemoveBatch,
       handleRemoveProduct, handleChangeItem, handleCloseBar, handleSubmit, openConfirmation,
       handleOpenConfirmation, handleCloseConfirmation, handleSaveDraft, uploadProps,
       handleChangeQuantity, handleChangeCostPrice, handleChangeTotalCost, isPendingDraft} = useInitialStock(actions)

    const {openBar, type, message} = snackbars;
    const {handleOpenCsvDialog} = uploadProps;

   const previewProducts = (
       <StockProductsTable  headData={parentHeadData} colSpan={initialHeadData.length - 1}>
           {products.map((prod, index)=>{
               const batches = prod.batches ? prod.batches : []
               return(
                   prod.product_id ?  <StockProductRow cellSpan={initialHeadData.length + 1} key={index} colSpan={initialHeadData.length - 1} open={prod.open} product={prod.selected_product}
                                                       handleOpen={()=>handleOpen(index)} preview={true}>
                       <ItemsTable headData={previewHeadData}>
                           <tbody>

                           </tbody>
                           {batches.map((item, idx) => (
                               <tr  key={idx}>
                                   <td>
                                       {item.batch_number}
                                   </td>
                                   <td>
                                       {item.expiry_date}
                                   </td>
                                   <td>
                                       {item.quantity}
                                   </td>
                                   <td>
                                       {item.cost_price}
                                   </td>
                                   <td>
                                       {+item.quantity * +item.cost_price}
                                   </td>
                               </tr>
                           ))}
                       </ItemsTable>
                   </StockProductRow> :  null
               )})}
       </StockProductsTable>
   )

   const buttons = (
       <>
           <button type='button' onClick={handleSubmit} disabled={isPending} className='btn btn-sm sms-info-btn mr-4'>{isPending ? 'Saving...' :'Continue'}</button>
           <button type='button' onClick={handleCloseConfirmation} className='btn btn-sm sms-gray-btn mr-4'>Cancel</button>
       </>
   )
    return(
        <div>
            <PageTitle title="Stores"/>
            <SubHeader title="Stores" subTitle="Add Initial Stock">
                <FontAwesomeIcon icon={faShippingFast}/>
            </SubHeader>
            <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={handleCloseBar}/>
            <CustomDialog open={openConfirmation} handleClose={handleCloseConfirmation} title='Preview Products' buttons={buttons} maxWidth='lg'>
                <p style={{margin:'0 0 20px 0', fontSize:'15px'}}>Please confirm that you have entered the right data before
                    continuing because this action is irreversible</p>
                {previewProducts}
            </CustomDialog>
            <CsvDialog {...{...uploadProps, file:"/csv/labreagents.csv", title:'Upload Initial Stock'}}/>
            <Container>
                <div style={{display:'flex', justifyContent:'space-between'}}>
                    <div className='mb-3'>
                        <BackButton to='/stocklist' text='Stock  List' data-testid="stock-list-back-button"/>
                    </div>
                    <div>
                        <button onClick={handleOpenCsvDialog} id="upload-products" className="btn sms-info-btn btn-sm mr-4">Upload Initial Stock
                        </button>
                    </div>
                </div>
                <form autoComplete='off' onSubmit={handleOpenConfirmation}>
                    <Form.Group as={Col} style={{paddingLeft:0}}>
                        <Label type name='Product' htmlFor='product'/>
                        <TextContainer>
                            <AutoCompleteUnControlled options={drugs} handleChange={handleRetrieveDrugs} label_text="label"
                                                      medicine_value={{}} id="product_name" value_text="value"
                                                      handleProductData={handleClickDrug} placeholder="Type Product Name" errorMsg="Enter Product Name"/>
                        </TextContainer>
                    </Form.Group>
                    {products.length > 0 ?
                        <StockProductsTable  headData={parentHeadData} colSpan={initialHeadData.length - 1}>
                        {products.map((prod, index)=>{
                            const batches = prod.batches ? prod.batches : []
                            const removeAction = (
                                <TableCell style={{padding:'7px 8px'}}>
                                    <button type='button' onClick={()=>handleRemoveProduct(index)} className='btn small-btn sms-btn-dismiss'>Remove Product</button>
                                    {/*<Tooltip title='Remove'>*/}
                                    {/*    */}
                                    {/*    <IconButton onClick={()=>handleRemoveProduct(index)}>*/}
                                    {/*        <StyledRemoveIcon/>*/}
                                    {/*    </IconButton>*/}
                                    {/*</Tooltip>*/}
                                </TableCell>

                            )
                            return(
                                prod.product_id ?  <StockProductRow cellSpan={initialHeadData.length + 1} key={index} colSpan={initialHeadData.length - 1} open={prod.open} product={prod.selected_product}
                                     handleOpen={()=>handleOpen(index)} action={removeAction}>
                                        <ModuleTable headData={initialHeadData}>
                                            {batches.map((item, idx) => (
                                                <TableRow  key={idx}>
                                                    <StyledCell>
                                                        <TextField type="text"
                                                                   name="batch_number"
                                                                   value={item.batch_number}
                                                                   onChange={(e) => handleChangeItem(e, index, idx)}
                                                                   placeholder="Input batch number"
                                                                   submitted={submitted}
                                                        />
                                                        {(submitted && !item.batch_number) ?
                                                            <ErrorMessage>Please enter the batch id</ErrorMessage> :null}
                                                    </StyledCell>
                                                    <StyledCell>
                                                        <TextField type="date" submitted={submitted}
                                                                   name="expiry_date" onChange={(e) =>handleChangeItem(e, index, idx)}
                                                                   value={item.expiry_date}/>
                                                        {(submitted && !item.expiry_date) &&
                                                            <ErrorMessage>Expiry date is required</ErrorMessage>}
                                                    </StyledCell>
                                                    <StyledCell>
                                                        <TextField type="number"
                                                                   name="quantity"
                                                                   value={item.quantity}
                                                                   onChange={(e) => handleChangeQuantity(e, index, idx)}
                                                                   placeholder="Input quantity"
                                                                   submitted={submitted}
                                                        />
                                                        {(submitted && !item.quantity) ?
                                                            <ErrorMessage>Please enter the quantity</ErrorMessage> :null}
                                                    </StyledCell>
                                                    <StyledCell>
                                                        <TextField type="number"
                                                                   name="cost_price"
                                                                   value={item.cost_price}
                                                                   onChange={(e) => handleChangeCostPrice(e, index, idx)}
                                                                   placeholder="Input unit cost price"
                                                                   submitted={submitted}
                                                        />
                                                        {(submitted && !item.cost_price) ?
                                                            <ErrorMessage>Please enter the cost</ErrorMessage> :null}
                                                    </StyledCell>
                                                    <StyledCell>
                                                        <TextField type="number"
                                                                   name="total_cost"
                                                                   disabled={!item.quantity}
                                                                   value={item.total_cost}
                                                                   onChange={(e) => handleChangeTotalCost(e, index, idx)}
                                                                   placeholder="Input total cost"
                                                        />
                                                    </StyledCell>
                                                    <StyledCell>
                                                        <button type='button' onClick={()=>handleRemoveBatch(index, idx)} className='btn small-btn sms-btn-dismiss'>Remove</button>
                                                        {/*<Tooltip title='Remove'>*/}
                                                        {/*    <IconButton onClick={()=>handleRemoveBatch(index, idx)}>*/}
                                                        {/*        <StyledRemoveIcon/>*/}
                                                        {/*    </IconButton>*/}
                                                        {/*</Tooltip>*/}
                                                    </StyledCell>
                                                </TableRow>
                                            ))}
                                            <TableRow>
                                                <StyledCell colSpan={initialHeadData.length}>
                                                    <button onClick={()=>handleAddBatch(index)} type='button' className='btn btn-sm sms-gray-btn'>Add another batch</button>
                                                </StyledCell>
                                            </TableRow>
                                        </ModuleTable>
                                    </StockProductRow> :  null
                            )})}
                    </StockProductsTable>: null}

                    {products.length > 0 ?
                    <RightAlignedContainer>
                        <button type='button' disabled={isPendingDraft} onClick={handleSaveDraft}
                                className='btn btn-sm sms-info-btn px-3 mr-4 mt-3'> {isPendingDraft ? 'Saving...': 'Save Draft'}</button>
                        <button type='submit'  className='btn btn-sm sms-btn px-3 mt-3'> Submit final</button>
                    </RightAlignedContainer>: null}
                </form>
            </Container>

        </div>
    )
}

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(AddInitialStock)