import {useEffect, useState} from "react";
import {dateConvert} from "../../Utils/ConvertDate";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";

export const useBedTransfer = (actions) =>{
    const [admission_no, setAdmissionNo] = useState('');
    const [patientNumber, setPatNumber] = useState('');
    const [showNumber, setShowNumber] = useState(false);
    const [patientNumbers, setPatientNumbers] = useState([]);
    const [state, setState] = useState( {transfer_date:dateConvert(),discharge_date:'',description:'',status:'1'});
    const [submitted, setSubmitted] = useState(false);
    const [rooms, setRooms] = useState([]);
    const [wards, setWards] = useState([]);
    const [bedList, setBeds] = useState([]);
    const [ward_name, setWard] = useState('');
    const [room_name, setRoom] = useState('');
    const [bed_name, setBed] = useState('');
    const [to_bed, setToBed] = useState('');
    const [to_ward, setToWard] = useState('');
    const [to_room, setToRoom] = useState('');
    const [admission_id,setAdmissionId] = useState('');
    const [v_id, setVisitId] = useState('');
    const [room_cost, setCost] = useState(0);
    const [assignment_id, setId] = useState('');
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [patient_name, setPatientName] = useState('');
    const [open, setOpen] =useState(false)
    const [currencyDetails, setCurrencyDetails] = useState({currency_id:'',currency_symbol:'', cost:0, is_provider:0, is_provided:0})
    const [openConfirmation, setOpenConfirmation] = useState(false)
    const [patient_room_id, setPatientRoomId] = useState('')

    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;

    useEffect(() => {
        axios.get(`${config.smsUrl}/cbedmanager/getAllWards`).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const list = !dt.wards ? [] : dt.wards;
            setWards(list)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, []);


    const retrieveBeds = (room_id) =>{
        const formData = new FormData();
        formData.append('room_id', room_id);
        axios.post(`${config.smsUrl}/cbedmanager/retrieve_bed_by_room_id`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const cst = !dt.cost ? {} : dt.cost;
            const ct = !cst.charge ? 0 : cst.charge;
            const list = !dt.bed ? [] : dt.bed;
            setBeds(list);
            setCost(ct)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }

    const retrieveRooms = (ward_id) =>{
        const formData = new FormData();
        formData.append('ward_id', ward_id);
        axios.post(`${config.smsUrl}/cbedmanager/retrieve_room_by_ward_id`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const list = !dt.room ? [] : dt.room;
            setRooms(list);
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    };

    const handleChangeRoom = (value) =>{
        setRoom(value);
        retrieveBeds(value.value)
    };

    const handleChangeWard = (value) => {
        setWard(value);
        setRoom('')
        retrieveRooms(value.value)
    };

    const handleChangeBed = (value) =>{
        setBed(value)
    };

    const handleChangeToWard = (value) =>{
        setToWard(value);
        setToRoom('');
        retrieveRooms(value.value)
    };

    const handleChangeToRoom = (value) =>{
        setToRoom(value);
        const formData = new FormData();
        formData.append('room_id', value.value);
        axios.post(`${config.smsUrl}/cbedmanager/retrieve_bed_by_room_id`, formData).then(res => {
            const resp = res.data;
            const dt = !resp ? {} : resp;
            axios.post(`${config.smsUrl}/cbilling/item_cost`, {visit_id: +v_id, item_id: +value.value, item_type:'room'}).then(data => {
                const resObj = data.data;
                const dataObj = !resObj.data ? {} : resObj.data;
                setCurrencyDetails({...dataObj, currency_symbol: dataObj.currency})
                if(dataObj.is_provider === 1 && dataObj.is_provided === 0){
                    setPatientRoomId(value.value)
                    setOpenConfirmation(true)
                }
                const list = !dt.bed ? [] : dt.bed;
                const arr = list.map(bd => ({label: bd.bed_number, value: bd.bed_id}))
                setBeds(arr);
            }).catch(error => {
                errorMessages(error, null, actions)
            });

        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    };

    const changeToBed = (val) =>{
        setToBed(val)
    };

    const handleChange = (event) =>{
        const {name,value} = event.target;
        setState({...state,[name]:value})
    };

    const handleChangePatientNumbers = (event) => {
        setAdmissionNo(event.target.value);
        const formData = new FormData();
        if (event.target.value !== '') {
            formData.append('patient_admission_id', event.target.value.toUpperCase());
            axios.post(`${config.smsUrl}/cadmission/auto_complete_admission`, formData)
                .then(res => {
                    const details = res.data;
                    const data = !details ? [] : details;
                    const dt  = data.map(item=>({
                        value:item.patient_admission_id,
                        label:item.patient_admission_id
                    }))
                    if (data.length > 0){
                        setPatientNumbers(dt)
                    }else {
                        setPatientNumbers([{value:'',label:'No admission found'}])
                    }
                }).catch(error => {
                logoutErrorMessage(error, null, actions)
            });
            setShowNumber(true);
        } else {
            setShowNumber(false);
        }

    };

    const handleClickPatientNumber = (event) => {
        const innerText = typeof event === 'string' ? event : event.target.innerText
        const value = typeof event === 'string' ? event : event.target.value
        const formData = new FormData();
        setAdmissionNo(innerText);
        formData.append('patient_admission_id', value);
        axios.post(`${config.smsUrl}/cbedmanager/retrieve_patient_admissiondetails`, formData)
            .then(res => {
                const details = res.data;
                const data = !details ? {} : details;
                const {ward_id, word_name,bed_number, room_name, bed_id,room_id,bed_assignment_id,
                    patient_admission_id, visit_id, patient_number, first_name, last_name} = data;
                setWard({value: ward_id, label: word_name});
                setRoom({value: room_id, label: room_name});
                setBed({value: bed_id, label: bed_number});
                setPatNumber(patient_number)
                setAdmissionId(patient_admission_id);
                setVisitId(visit_id);
                setId(bed_assignment_id);
                setPatientName(`${first_name} ${last_name}`)
                retrieveRooms(ward_id);
                retrieveBeds(room_id)
            }).catch(error => {
            logoutErrorMessage(error, null, actions)
        });
        setShowNumber(false);
    };


    const handleReset = () =>{
        setState({transfer_date:dateConvert(),description:''});
        setRoom('');
        setWard('');
        setBed('');
        setAdmissionNo('');
        setPatientName('');
        setToBed('');
        setToWard('');
        setToRoom('');
        setRooms([]);
        // setWards([]);
        setBeds([]);
        setCurrencyDetails({currency_id:'',currency_symbol:'', cost:0, is_provider:0, is_provided:0});
    };


    const handleSubmit = (event) =>{
        event.preventDefault();
        setSubmitted(true);
        const {transfer_date, description} = state;
        const {currency_id, is_provided, is_provider, cost} = currencyDetails
        const params = {patient_number:patientNumber,patient_admission_id:admission_id,visit_id:v_id,
            bill_details:{rate:cost,quantity:1, currency_id, is_provided, is_provider},from_bed:bed_name.value, to_bed:to_bed.value,
        reason:description,user_roles_id, from_bed_assignment_id:assignment_id, bill_type:1}
        if (admission_no && ward_name && room_name && bed_name && transfer_date && to_bed && bed_name.value !== to_bed.value) {
            setIsSubmitted('pending')
            axios.post(`${config.smsUrl}/cbedmanager/bed_transfer`, {...params}).then(()=>{
                actions.snackbarActions.snackSuccess('Bed transferred successfully');
                setIsSubmitted('resolved')
                setSubmitted(false)
                setOpen(false)
                handleReset()
            }).catch(err=>{
                errorMessages(err, null, actions)
                setIsSubmitted('rejected')
            })
        }
    };

    const handleCloseConfirmation = () =>{
        if(patient_room_id === to_room?.value){
            setToRoom({value:null, label: null})
            setBeds([])
            setCurrencyDetails({...currencyDetails, cost: 0})
        }
        setOpenConfirmation(false)
    }

    const handleBillRoom = () =>{
        setOpenConfirmation(false)
    }

    const handleOpenDialogForm =(_id='')=>{
        setOpen(true)
        // setID(_id)
        if(_id){
            handleClickPatientNumber(_id)
        }
    }

    const handleCloseDialogForm =()=> {
        setOpen(false)
    }


    const isPending = isSubmitted === 'pending';
    const isResolved = isSubmitted === 'resolved';
    const isRejected = isSubmitted === 'rejected';

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar();
    };

    const transferProps = {openConfirmation,  handleCloseConfirmation,handleBillRoom,
        admission_no, showNumber, patientNumbers, state, submitted, rooms, wards,
        bedList, ward_name, room_name, bed_name, to_bed, to_ward, to_room, admission_id,
        v_id, room_cost, assignment_id,handleChangeRoom, handleChangeWard, handleChangeBed, currencyDetails,
        handleChangeToWard, handleChangeToRoom, changeToBed, handleChange, handleChangePatientNumbers,
        handleClickPatientNumber, handleSubmit, handleReset, closeSnackbar,retrieveBeds,
        retrieveRooms, isSubmitted,patient_name, open, handleCloseDialogForm,handleOpenDialogForm,
        isPending, }

    return {isResolved, isRejected,transferProps}
}