import {useEffect, useState} from "react";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";

export const useEditShiftCategory = (actions,match) =>{
    const [state, setState] = useState({ status: '1', start_period: '', end_period: '', shift_name:''});
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsubmitted] = useState('idle');
    const [shift, setShift] = useState({});
    const [shift_name,setShiftName] = useState('');
    const [shift_category_id, setShiftCategoryId] = useState('')
    const [openEditDialog, setOpenEditDialog] = useState(false);

    useEffect(()=>{
        if (shift_category_id) {
            const formData = new FormData();
            formData.append('shift_id', shift_category_id);
            axios.post(`${config.smsUrl}/cshiftschedule/shift_category_update_form`, formData).then(res=>{
                const data = res.data;
                const dt = !data ? {} : data;
                setShift(dt)
            }).catch(err=>{
                logoutErrorMessage(err, null, actions)
            })
        }
    },[shift_category_id]);

    useEffect(()=>{
        setState({ start_period:shift.start_time, end_period:shift.end_time, shift_name:shift.shift_name, status:shift.status});
        setShiftName({value:shift.shift_name, label:shift.shift_name})
    },[shift]);

    const handleChange = (event) => {
        const {name, value} = event.target;
        setState({...state, [name]: value})
    };

    const handleChangeShift = (value) =>{
        setShiftName(value)
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setSubmitted(true);
        const { start_period, end_period, shift_name} = state;
        const shift_n = shift_name.label
        const formData = new FormData();
        formData.append('shift_id', shift_category_id);
        formData.append('shift_name', shift_name);
        formData.append('start_time', start_period);
        formData.append('end_time', end_period);
        if (shift_name && start_period && end_period) {
            setIsubmitted('pending');
            axios.post(`${config.smsUrl}/cshiftschedule/shift_category_update`, formData).then(()=>{
                actions.snackbarActions.snackSuccess('Shift category updated successfully')
                setIsubmitted('resolved');
                setOpenEditDialog(false)
            }).catch(err=>{
                errorMessages(err, null,actions)
                setIsubmitted('rejected');
            })
        }
    };

    const handleOpenEditDialog = (id) => {
        setOpenEditDialog(true)
        setShiftCategoryId(id)
    }

    const handleCloseEditDialog =()=>{
        setOpenEditDialog(false)
    }
    const isResolved = isSubmitted === 'resolved';

    const handleCloseSnackbar = () =>{
        actions.snackbarActions.hideSnackbar()
    };

    const editShiftCatProps = {state, submitted, isSubmitted, shift, handleChange, handleSubmit, handleCloseSnackbar,
        shift_name, handleChangeShift, openEditDialog,isResolved, handleOpenEditDialog, handleCloseEditDialog}

    return { editShiftCatProps}
}