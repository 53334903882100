import React, {useCallback, useEffect, useState} from "react";
import axios from "axios";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import { config } from "../../../../Helpers/env";
import {useCommonState} from "../../CustomHooks/useReusableFunctions";
import {titleCase} from "../../../Users/addUser";
import {randomColor} from "../../../Utils/Charts/utilityFunctions";

export const useMedicineUse = (props) => {
    const {actions} = props;
    const [product, setProduct] = useState({product_id: '', product_name: ''});
    const [showProduct, setShowProduct] = useState(false);
    const [products, setProducts] = useState([]);
    const [sortBy, setSortBy] = useState({value: 1, label: 'Department'});
    const [displayText, setDisplayText] = useState('Please select product');
    const [showSort, setShowSort] = useState(false);
    const [department_id, setId] = useState('');
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [chips, setChips] = useState([]);
    const [statistics, setStatistics] = useState([]);
    const [loading, setLoading] = useState(false);
    const [tableData, setList] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [chartType, setChart] = useState(1);
    const [displayChart, setDisplayChart] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);

    const {
        closeSnackbar, search_type, text, textType, year, date, handleChangeSearch,
        handleChangeDate, handleChangeYear, handleIncrementYear,
        searchValue, order, orderBy, page, rowsPerPage, setSearchType,
        handleSearch, handleRequestSort, handleChangePage, handleChangeRowsPerPage
    } = useCommonState(actions)

    const parameters = (data, _id, sortType) => {
        const {start_date, end_date} = date;
        const s_date = search_type === '2' ? year : start_date;
        const current = s_date ? s_date : year
        const s_type = current === year ? '2' : search_type

        if (search_type === '3') {
            if (sortType.label === 'Department') {
                return {
                    start_date: s_date,
                    end_date: end_date,
                    option: search_type,
                    department_id: _id,
                    data: data
                }
            } else {
                return {
                    start_date: s_date,
                    end_date: end_date,
                    option: search_type,
                    ward_id: _id,
                    data: data
                }
            }

        } else {
            if (sortType.label === 'Department') {
                return {
                    start_date: current,
                    option: s_type,
                    department_id: _id,
                    data: data
                }
            } else {
                return {
                    start_date: current,
                    option: s_type,
                    ward_id: _id,
                    data: data
                }
            }
        }

    }

    useEffect(() => {
        const url = sortBy.label === 'Department' ? 'creport/departmental_revenue' :
            'cbedmanager/getAllWards'
        axios.get(`${config.smsUrl}/${url}`).then(res => {
            const data = res.data;
            const dt = !data ? {} : data
            if (sortBy.label === 'Department') {
                const departs = !dt.revenue ? [] : dt.revenue;
                let dps = departs.map(item => ({
                    value: item.department_id,
                    label: item.department_name
                }))
                setDepartments(dps);
            } else {
                const wards = !dt.wards ? [] : dt.wards;
                let wds = wards.map(item => ({
                    value: item.ward_id,
                    label: item.name
                }))
                setDepartments(wds);
            }

        }).catch(err => {
            logoutErrorMessage(err, null, actions);
        })
        setDepartments([])
    }, [sortBy]);


    useEffect(() => {
        const url = sortBy.label === 'Department' ? 'revenue_in_time_period' :
            'get_procedure_collections';
        setLoading(true);
        axios.post(`${config.smsUrl}/creport/${url}`, null, {
            params: parameters(true, '', sortBy)
        }).then(res => {
            const data = res.data;
            const dt = !data ? [] : data;
            if (sortBy.label === 'Department') {
                const ls = dt.map(item => ({
                    name: item.department_name,
                    value: item.revenue ? item.revenue : 0
                }))
                setList(ls);
            } else {
                const ls = dt.map(item => ({
                    name: item.service_name,
                    value: item.revenue ? item.revenue : 0
                }))
                setList(ls);
            }

            setLoading(false);
        }).catch((err) => {
            setLoading(false);
            logoutErrorMessage(err, null, actions);
        })
        setList([])
    }, [sortBy]);


    const handleChangeSortBy = (value) => {
        setSortBy(value)
    }

    const handleChangeChart = (chr) => {
        setChart(chr)
    }

    const autoSearchProduct = (event) => {
        const {value} = event.target;
        setProduct({product_id: '', product_name: value})
        const formData = new FormData();
        formData.append('product_name', titleCase(value));
        axios.post(`${config.smsUrl}/creport/product_auto_search`, formData).then(res => {
            const resp = res.data;
            const data = !resp ? [] : resp;
            if (resp === "No Medicine Found") {
                const val = [{value: "", label: "No Medicine Found"}]
                setProducts(val)
            } else {
                setProducts(data);
            }
            setShowProduct(true)
        }).catch(error => {
            setShowProduct(false)
            logoutErrorMessage(error, null, actions)
        });
    };


    const selectProduct = (obj) => {
        const {value, label} = obj;
        setProduct({product_id: value, product_name: label})
        setDisplayText('Please select sort by method')
        setShowSort(true)
    }

    const handleChangeDepartment = (value) => {
        const url = sortBy.label === 'Department' ? 'revenue_in_time_period' :
            'get_procedure_collections';
        setId(value)
        setDisplayChart(true)
        const stat = [...statistics]
        const val = {...value, color: randomColor()}
        const existingIds = chips.map((id) => id.value)
        if (!existingIds.includes(val.value)) {
            for (let i = 0; i < stat.length; i++) {
                stat[i][`value_${value.value}`] = Math.floor((Math.random() * 100) + 1);
            }
            setStatistics(stat)
            setChips([...chips, val])
            axios.post(`${config.smsUrl}/creport/${url}`, null, {params: parameters(false, value.value, sortBy)}).then(res => {
                const data = res.data;
                const dt = data ? data : [];
                for (let i = 0; i < stat.length; i++) {
                    stat[i][`value_${value.value}`] = dt[i].value;
                }
                setStatistics(stat)
                setChips([...chips, val])
            }).catch(err => {
                errorMessages(err, null, actions);
            })
        }

    }


    const handleDeleteChip = (chipToDelete) => {
        setChips((chips) => chips.filter((chip) => `value_${chip.value}` !== chipToDelete.dataKey));
    };


    const handleRetrieveData = (val, collect, submit) => {
        const {start_date, end_date} = date;
        const url = collect.label === 'Department' ? 'revenue_in_time_period' :
            'get_procedure_collections';
        submit && setSubmitted(true)
        setLoading(true)
        if (start_date || (start_date && end_date) || year) {
            submit && setIsSubmitted('pending')
            setChips([])
            axios.post(`${config.smsUrl}/creport/${url}`, null, {
                params: parameters(val, '', collect)
            }).then(res => {
                const data = res.data;
                const dt = data ? data : [];
                if (collect.label === 'Department') {
                    const ls = dt.map(item => ({
                        name: item.department_name,
                        value: item.revenue ? item.revenue : 0
                    }))
                    setList(ls);
                } else {
                    const ls = dt.map(item => ({
                        name: item.service_name,
                        value: item.revenue ? item.revenue : 0
                    }))
                    setList(ls);
                }

                submit && setIsSubmitted('resolved')
                setLoading(false)
            }).catch(err => {
                submit && setIsSubmitted('rejected')
                setLoading(false)
                errorMessages(err, null, actions);
            })
        }
        setList([])
    }

    const onPieEnter = useCallback(
        (_, index) => {
            setActiveIndex(index);
        },
        [setActiveIndex]
    );


    const handleSubmit = (event) => {
        event.preventDefault();
        handleRetrieveData(true, sortBy, true);
    }


    return {department_id, handleChangeDepartment, closeSnackbar, search_type, handleSubmit, chips,
        handleDeleteChip, text, textType, year, date, handleChangeSearch, sortBy, chartType,
        handleChangeChart, handleChangeDate, handleChangeYear, handleIncrementYear, setSortBy,
        statistics, isSubmitted, submitted, searchValue, order, orderBy, loading, product,
        setSearchType, parameters, showProduct, products, displayText, showSort, tableData, departments,
        page, rowsPerPage, handleSearch, handleRequestSort, handleChangePage, handleChangeRowsPerPage,
        handleRetrieveData, handleChangeSortBy, autoSearchProduct, selectProduct, displayChart, activeIndex,
        onPieEnter}
}