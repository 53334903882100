
export function titleCase(str) {
    if (str) {
        // let splitStr = str.toLowerCase().split(' ');
        // for (let i = 0; i < splitStr.length; i++) {
        //     splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        // }
        // return splitStr.join(' ');
        // return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
        return str.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')

    }
}

export const sentenceCase = (str) =>{
    if (str) {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }
}

export const formatDate = (date) => {
    return new Date(date).toLocaleString()
};
