import * as types from "../actionTypes";
import { config } from "../../Helpers/env";
import axios from "axios";
import {logoutErrorMessage} from "../../Helpers/ErrorMessages";

export function loadDepartmentSuccess(departments) {
    return {
        type: types.LOAD_DEPARTMENTS,
        departments
    };
}

export function loadDepartments() {
    return function (dispatch) {
        return axios.get(config.smsUrl + "/cvisit/retrieve_department").then(response => {
            const data = response.data;
            const dt = !data ? {} : data;
            const departs = !dt.departments ? [] : dt.departments;
            dispatch(loadDepartmentSuccess(departs));
        }).catch(error => {
            logoutErrorMessage(error, dispatch, null)
        });
    };
}
