import React, {useEffect, useState} from 'react'
import axios from "axios";
import {config} from "../../../../Helpers/env";
import {logoutErrorMessage} from "../../../../Helpers/ErrorMessages";


export const useEditPurchaseData = (actions, purchase_id) =>{
    const [purchaseObj, setPurchaseObj] = useState({});
    const [productsArr, setProducts] = useState([]);
    useEffect(() =>{
        if (!purchase_id){
            return
        }
        const formData = new FormData();
        formData.append('product_purchase_id', purchase_id)
        axios.post(`${config.smsUrl}/cinventory/purchase_update_form`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const prods = dt.purchase_info ?? [];
            setPurchaseObj(dt)
            setProducts(prods)
        }).catch((err) => {
            logoutErrorMessage(err, null, actions)
        })
    },[])

    return {productsArr,purchaseObj}
}

export const useEditPurchaseOrderData = (actions, local_purchase_order_id) =>{
    const [orderObj, setOrderData] = useState({});
    const [orderArr, setOrderArr] = useState([]);
    useEffect(() =>{
        if (!local_purchase_order_id){
            return
        }
        const formData = new FormData();
        formData.append('local_purchase_order_id',local_purchase_order_id)
        axios.post(`${config.smsUrl}/cinventory/purchaseoder_update_form`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const prods = dt.product_list ?? [];
            const {supplier_id, supplier_name, grand_total} = dt;
            setOrderData({supplier_id,supplier_name,grand_total})
            const arr = prods.map(prod=>({
                product_name:prod.product_name,
                product_id:prod.product_id,
                expiry_date:'',
                stock:0,
                quantity:prod.quantity,
                supplier_price:prod.rate,
                sub_total:prod.total,
                batch_id:'',
                serial:'',
                warranty:'',
                condition:'',
                model:'',
            }))
            setOrderArr(arr)
        }).catch((err) => {
            logoutErrorMessage(err, null, actions)
        })
    },[])

    return {orderArr,orderObj}
}