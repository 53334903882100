import React from 'react';
import PageTitle from "../Utils/smsTitle";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWheelchair} from "@fortawesome/free-solid-svg-icons/faWheelchair";
import styled from "@emotion/styled/macro";
import { Container, RightAlignedContainer, CenteredContainer} from '../Utils/styledComponents';
import { MiniHeader } from '../Utils/Menu/MiniSubHeader';
import { ReusableRouteTabs } from '../Utils/Dialogs/ReusableTabs';
import { history } from '../../Helpers/history';
import Triage from '../Triage/Triage';
import {useRecordPatient} from './hooks/useRecordPatient';
import * as mq from "../../styles/media-queries";
import { EmergencyTreatment } from './EmergencyTreatment';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import CustomDialog from '../Utils/Dialogs/CustomDialog';
import { PatientForm } from '../PatientRecords/PatientForm';
import { AttachPatient } from './AttachPatient';
import MainSnackbar from '../Utils/Snackbar/SmsSnackbar';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as snackbarActions from "../../actions/snackbarActions"

const StyledDiv = styled.div({
    marginTop:'20px',
    [mq.medium]:{
      width:'1080px',
    },
    [mq.large]:{
        width:'1080px',
    },
    [mq.extraLarge]:{
        width:'1080px',
    }
});

export const PatientContainer = styled.div({
    marginTop:'20px',
    [mq.medium]:{
      width:'600px',
    },
    [mq.large]:{
        width:'600px',
    },
    [mq.extraLarge]:{
        width:'600px',
    }
});

const RecordPatient =({actions, snackbar})=>{
    const component =[  
        {label:'Record Vitals', path:'/EmergencyPatient'},
        {label:'Emergency list', path:'/emergencylist'}
    ]
    const {triageProps,handleSubmitTriage, handleCancel, showComponent,prescriptionProps,
    handleSubmitPrescription,openDialog,handleOpenDialog, handleCloseDialog,savePatientProps,
    attachPatientProps,handleCloseSnackbar, handleSubmitPatient, isPending} = useRecordPatient(actions)
    const isTriage = showComponent === 0;
    const isTreatment = showComponent === 1;
    const isPatient = showComponent === 2;
    const title =  {
        0:'Record Vitals',
        1:'Record Treatment',
        2:'Attach Patient'
    }
    const {openBar, type, message} = snackbar;

    return (
        <div  className="patientEmergency">
            <PageTitle title={title[showComponent]} />
            <SubHeader title=" Patient Emergency" subTitle={title[showComponent]}>
                <FontAwesomeIcon icon={faWheelchair}/>
            </SubHeader>
            <MainSnackbar variant={type} message={message} open={openBar} handleCloseBar={handleCloseSnackbar}/>
            <CustomDialog open={openDialog} handleClose={handleCloseDialog} title="Register Patient" maxWidth='lg'>
                <PatientForm {...savePatientProps}>
                    <button type='submit' className='btn sms-btn px-3'>Save</button>
                </PatientForm>
            </CustomDialog>
            <Container>
                {isPatient ? <CenteredContainer>

                </CenteredContainer>:null}
                <MiniHeader title={title[showComponent]}/>
                {isTriage ? <ReusableRouteTabs value={history.location.pathname} tabHeading={component} />:null}
                {isTriage ? <StyledDiv>
                    <form autoComplete='off'>
                        <Triage {...{...triageProps, handleSubmitTriage, handleCancel}} >
                            <RightAlignedContainer>
                                <button type="submit" 
                                        onClick={(e)=>handleSubmitTriage(e,1)}
                                        className="btn btn-sm sms-btn mr-5"> Record treatment</button>
                            </RightAlignedContainer>
                        </Triage>
                    </form>
                </StyledDiv>:null}
                {isTreatment ? 
                    <form autoComplete='off' className='mt-3'>
                        <EmergencyTreatment {...prescriptionProps}/>
                        <RightAlignedContainer>
                                <button type="submit" 
                                        onClick={(e)=>handleSubmitPrescription(e,2)}
                                        className="btn btn-sm sms-btn px-4"> Attach patient</button>
                        </RightAlignedContainer>
                    </form>
                :null}
                {isPatient ? 
                <PatientContainer>
                    <RightAlignedContainer>
                        <button onClick={handleOpenDialog} className='btn btn-sm sms-info-btn'><FontAwesomeIcon icon={faPlus}/> Quick Add Patient</button>
                    </RightAlignedContainer>
                    <form autoComplete='off'>
                        <AttachPatient {...attachPatientProps}>
                            <RightAlignedContainer>
                                <button type="submit" disabled={isPending}
                                        onClick={(e)=>handleSubmitPatient(e,0)}
                                        className="btn btn-sm sms-btn px-4">{isPending ? 'Saving...': 'Save patient'}</button>
                            </RightAlignedContainer>
                        </AttachPatient>

                    </form>
                </PatientContainer>:null}
               
            </Container>
            
            
            
           


        

        </div>
    )

}

function mapStateToProps(state) {
    const { snackbar} = state;
    return { snackbar}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(RecordPatient);