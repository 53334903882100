import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPhone} from "@fortawesome/free-solid-svg-icons/faPhone";
import PageTitle from "../Utils/smsTitle";
import {SubHeader} from "../../Containers/SubHeader";
import {faCog} from "@fortawesome/free-solid-svg-icons/faCog";



const SupportPage = () => {
    return (
        <div>
            <PageTitle title="User Support"/>
            <SubHeader title="Settings" subTitle="User support">
                <FontAwesomeIcon icon={faCog}/>
            </SubHeader>
            <div className="user-support">
              
                <div className=" p-3">
                    <div className="text-center mb-3">
                        <div className="mb-4">
                            <h5>Get In Touch</h5>
                        </div>
                        <p>Want to get in touch? We'd love to hear from you.Here's how you can reach us...</p>
                    </div>
                    <div className="text-center">
                        <div className="mb-3">
                            <div className="support-icon">
                                <FontAwesomeIcon icon={faPhone}/>
                            </div>
                            <div className="team-text mt-2">
                                <p style={{margin:0}}>Talk to the Team</p>
                                <p style={{margin:0}}>MaraScientific</p>
                            </div>
                        </div>
                        <div className="my-2">
                            <table className="table table-borderless table-sm" width='70%'>
                                <tbody>
                                    <tr>
                                        <td>( Bob Mugyenyi, Support Engineer Fort Portal)</td>
                                        <td>(Brolin Kayondo, Project Manager)</td>
                                    </tr>
                                    <tr>
                                        <td><strong>0753267124</strong></td>
                                        <td><strong>0702144852 / 078621140</strong></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>  
                    </div>
                </div>
            </div>
        </div>
    )
};
export default SupportPage;