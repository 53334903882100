import React, {useEffect, useState} from 'react';
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMoneyBill} from "@fortawesome/free-solid-svg-icons/faMoneyBill";
import Grid from '@material-ui/core/Grid';
import Card from "@material-ui/core/Card";
import {makeStyles} from '@material-ui/core/styles';
import ReusableList from "./ReusableList";
import {NoTransaction, ReusableJournalTable} from "./ReusableJournalTable";
import Typography from "@material-ui/core/Typography";
import TextField from "../../Utils/FormInputs/TextField";
import {titleCase} from "../../Users/addUser";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from '../../../actions/snackbarActions'
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import LedgerEntry from "./LedgerEntry";
import {LoadingGif} from "../../Utils/Loader";
import axios from "axios";
import {config} from "../../../Helpers/env";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {MuiPagination} from "../../Utils/Tables/CustomTable";

const useStyles = makeStyles(() => ({
    item: {
        borderRight: '1px solid #c7c6c7',
        borderLeft: '1px solid #c7c6c7',
        height: '100vh',
        overflow: "auto",
        '&::-webkit-scrollbar': {
            width: "5px",
            height: "5px"
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#c7c6c7',
            "-webkit-border-radius": "10px",
            borderRadius: "10px",
        }
    },
    rounded: {
        borderRadius: 0
    },
    body1: {
        fontSize: '0.98em'
    },
    body2: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '0.85em'
    },


}));
const Journals = ({actions, snackbar, match:{params}}) => {

    const classes = useStyles()
    const [allJournals, setAllJournals] = useState([]);
    const [journalDetails, setJournalDetails] = useState({head_name: '', head_code: ''});
    const [search, setSearch] = useState('');
    const [specificItem, setItem] = useState({id:'', head_name:'', head_code:''})
    const [isSubmitLedger, setIsSubmitLedger] = React.useState('idle')
    const [loadingJournals, setLoadingJournals] = useState('idle')
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);


    const isResolved = isSubmitLedger === 'resolved'

    useEffect(() => {
        setLoadingJournals('loading')
        axios.get(`${config.smsUrl}/accounting/chart/journals`).then(res => {
            const data = res.data;
            const dt = data ? data : {};
            const arr = dt.data ? dt.data : []
            const item = arr[0] ? arr[0] : {};
            const dat = arr.map((item, index)=>{
                if (index === 0){
                    return {...item, isActive:'active'}
                }
                return item
            })
            setAllJournals(dat)
            setItem(item)
            setJournalDetails({head_name:item.head_name, head_code:item.head_code})
            setLoadingJournals('success')
        }).catch(err => {
            errorMessages(err, null,actions)
            setLoadingJournals('error')
        })

    }, [isResolved])


    const handleClickJournal = (item) => {
        const {id,head_name, head_code} = item;
        const dat = allJournals.map(item=>{
            if (item.id === id){
                return {...item, isActive:'active'}
            }
            return {...item, isActive:'inactive'}
        })
        setAllJournals(dat)
        setItem(item)
        setJournalDetails({head_name, head_code})
    }

    const handleSearch = (e) =>{
        const {value} = e.target;
        setSearch(value)
    }


    const filteredList = (arr) =>{
        return arr.filter((data) => {
            if (search === '')
                return data
            else if (titleCase(data.head_name).includes(titleCase(search))) {
                return data
            }
            // return data
        })
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    }


    const isLoadingJournals = loadingJournals === 'loading';
    const isSuccessJournals = loadingJournals === 'success';
    const isErrorJournals = loadingJournals === 'error';
    const {component} = params 
    const {head_name, head_code} = journalDetails;
    const perm = JSON.parse(sessionStorage.getItem('permission'));
    const permission = !perm ? {} : perm;
    const acc_journals = !permission.journals ? {} : permission.journals;
    const {openBar, type, message} = snackbar;


    return (
        <div className='journals'>
            <PageTitle title="Journals"/>
            <SubHeader title="Accounting" subTitle="Journal">
                <FontAwesomeIcon icon={faMoneyBill}/>
            </SubHeader>
            <MainSnackbar message={message} open={openBar} handleCloseBar={closeSnackbar} variant={type}/>
            <Card elevation={0} classes={{root: classes.rounded}}>
                <Grid container spacing={0}>
                    <Grid item xs={3} md={4} lg={4} classes={{item: classes.item}}>
                        <div className="top-bar all-journals">
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={6}>
                                    <TextField type='text' value={titleCase(search)} name='search'
                                               onChange={handleSearch}
                                               placeholder='Search journal'/>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    
                                </Grid>
                            </Grid>

                        </div>
                        {isLoadingJournals ? <div className="text-center mt-5">
                            <LoadingGif/>
                        </div> :null}
                        {isSuccessJournals ? allJournals.length > 0 ?
                            <>
                                {filteredList(allJournals).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, idx) => {
                                const isActive = item.isActive === 'active'
                                return(
                                    <ReusableList key={item.id} primaryText={item.head_name} data-testid={'journal-'+idx} id={'journal-'+idx} isActive={isActive}
                                                  secondaryText={item.head_code} onClick={() => handleClickJournal(item)}/>
                                )})}
                                <MuiPagination {...{colSpan:0, data:allJournals,page,handleChangePage,handleChangeRowsPerPage,
                                    rowsPerPage,rows:[10, 25,50, 100, 200, 300, 500, {label: 'All', value: -1}]}}/>
                            </>
                            : <NoTransaction text='journals'/>:null}
                        {isErrorJournals ? <div className="text-center mt-5">The server did not return a valid response</div>:null}
                    </Grid>
                    <Grid item xs={9} md={8} lg={8}>
                        <div className="top-bar">
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={8}>
                                    <Typography variant='body1'
                                                classes={{body1: classes.body1}}>{head_name}</Typography>
                                    <Typography variant='body2'
                                                classes={{body2: classes.body2}}>{head_code}</Typography>
                                </Grid>
                                <Grid item xs={6} md={4}>
                                    {acc_journals.create && component === 'account' ? <LedgerEntry {...{actions, isSubmitLedger, setIsSubmitLedger}}/>:null}
                                </Grid>
                            </Grid>
                        </div>
                        <ReusableJournalTable {...{actions, specificItem, isResolved}}/>
                    </Grid>
                </Grid>
            </Card>

        </div>
    )
}
function mapStateToProps(state) {
    const {snackbar} = state;
    return {snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(Journals);
