import React, { useState } from 'react';
import axios from "axios";
import { config } from "../../../Helpers/env";
import { history } from "../../../Helpers/history";
import { errorMessages, logoutErrorMessage } from "../../../Helpers/ErrorMessages";
import { useViewCompletedDetails } from "./useViewCompletedDetails";
import { useQuery } from 'react-query';
import {dateConvert} from "../../Utils/ConvertDate";
import {Progress} from "../WaitingList";
import {FinishedStatus, PendingStatus} from "../doctorStyles";


export const useDoctorWaitingList = (props, _token) => {
    const { actions } = props;
    const [searchValue, setSearchValue] = useState('');
    const [openMenu, setOpenMenu] = useState(null);
    const [activePage, setActivePage] = useState(1);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [openAssign, setOpenAssign] = useState(false)
    const [doctor_name, setDoctor] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [doctors, setDoctors] = useState([]);
    const [department, setDepartment] = useState('');
    const [ids, setIds] = useState({ patient_number: '', visit_id: '', patient_name: '', age: '', gender: '' });
    const [openEnd, setOpenEnd] = useState(false)
    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;
    const department_id = !_token.department_id ? "" : _token.department_id;
    const department_name = !_token.department_name ? "" : _token.department_name;
    const [isEnd, setIsEnd] = useState('idle')
    const [state, setState] = useState({start_date:dateConvert(), end_date:dateConvert()})
    const { patientProps } = useViewCompletedDetails(actions)
    const {handleOpenDialog} = patientProps

    const isPending = isSubmitted === 'pending';
    const isResolved = isSubmitted === 'resolved';
    const isError = isSubmitted === 'reject';

    const isEnding = isEnd === 'pending';
    const isEnded = isEnd === 'resolved';
    const isEndError = isEnd === 'reject';

    const departmentsQuery = useQuery(
        ["departmentsQuery"],
        async () => {
            try {
                const response = await axios.get(`${config.smsUrl}/cvisit/retrieve_department`);

                const data = response.data ?? {}

                let departments = data.departments ?? [];

                departments = departments.map((department) => ({ label: department.department_name, value: department.department_id }))

                return departments;
            } catch (error) {
                logoutErrorMessage(error, null, actions)
            }
        },
        { retry: 1 });

    const departments = departmentsQuery.isSuccess ? departmentsQuery.data : [];

    const patientsQuery = useQuery(
        ["patientsQuery", activePage,rowsPerPage, state.start_date, state.end_date, searchValue, isResolved, isEnded],
        async () => {
            try {
                const formData = new FormData();

                formData.append('search', searchValue);
                formData.append('start_date', state.start_date);
                formData.append('end_date', state.end_date);
                formData.append('department_id', department_id);
                formData.append('per_page', rowsPerPage);

                const response = await axios.post(`${config.smsUrl}/cdoctor/doctor_waiting_list/${activePage}`, formData);

                const data = response?.data ?? { waiting_list: [], total_count: 0 };

                const patients = data?.waiting_list ?? [];

                const total_count = data?.total_count ?? 0;

                const waitingList = patients.map((item, index) => {
                    const status={
                        2:<PendingStatus>Pending</PendingStatus>,
                        3:<Progress>Treatment ongoing</Progress>,
                        4:<FinishedStatus>Complete</FinishedStatus>
                    }
                    const buttons={
                        2: (
                            <button
                                className={`btn btn-sm mr-3 mb-sm-3 mb-2  ${item.status === 2 ? 'sms-info-btn' : 'sms-amber-btn'}`}
                                onClick={() => handleClickPatient(item.patient_number, item.visit_id, 1, item.activity_id)}>{
                                item.status === 2 ? "Take up" : "Continue"}
                            </button>
                        ),
                        3: (
                            <>
                                <button
                                    className={`btn btn-sm mr-3 mb-sm-3 mb-2  ${item.status === 2 ? 'sms-info-btn' : 'sms-amber-btn'}`}
                                    onClick={() => handleClickPatient(item.patient_number, item.visit_id, 1, item.activity_id)}>{
                                    item.status === 2 ? "Take up" : "Continue"}
                                </button>
                                <button onClick={() => handleOpenEndVisit(item)}
                                        className="btn btn-sm sms-info-btn mb-sm-3 mb-2 mr-3 ">End Visit
                                </button>
                            </>


                        ),
                        4: (
                            <>
                                <button className='btn btn-sm mr-3 sms-gray-btn mb-sm-3 mb-2'
                                        onClick={() => handleEndVisit('revert', item.visit_id)}>Revert Visit
                                </button>
                                <button className='btn btn-sm sms-info-btn mb-sm-3 '
                                        onClick={() => handleOpenDialog(item)}>View Details
                                </button>
                            </>


                        )
                    }
                    return {
                        ...item,
                        count: ((activePage - 1) * rowsPerPage) + index + 1,
                        date: item.begin_datetime,
                        patient_number: item.patient_number,
                        patient_name: `${!item.first_name ? "" : item.first_name} ${!item.last_name ? "" : item.last_name}`,
                        doctor_name:`${item.doctor_first_name} ${item.doctor_last_name}`,
                        status: status[item.status],
                        action:buttons[item.status]
                }});

                return { waitingList, total_count }


            } catch (error) {
                logoutErrorMessage(error, null, actions);
                return { waitingList:[], total_count:0 }
            }
        },
        { retry: 1, refetchInterval: 10000 });

    const status = patientsQuery.isLoading ? "pending" : patientsQuery.isSuccess ? "success" : "rejected";

    let {waitingList, total_count} = patientsQuery.isSuccess ? patientsQuery.data : {waitingList: [], total_count: 0}

    const isLoading = status === 'pending';
    const isSuccess = status === 'success';
    const isRejected = status === 'error';

    const handleChange = (event) =>{
        const {value, name} = event.target
        setState({...state, [name]:value})
    }
    const handleSearch = (event) => {
        setSearchValue(event.target.value);
        setActivePage(1)
        setPage(0)
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setActivePage(newPage+1)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        setActivePage(1);
    };


    const retrieveDoctors = (d_id) => {
        const formData = new FormData();
        formData.append('department_id', d_id);
        axios.post(`${config.smsUrl}/cvisit/getdoctorsbydepartment_id`, formData).then(res => {
            const data = res.data;
            const dt = !data ? [] : data;
            const doctors = !dt.doctors ? [] : dt.doctors;
            const arr = doctors.map(doctor => ({
                value: doctor.user_roles_id,
                label: `${doctor.first_name} ${doctor.last_name}`
            }))
            setDoctors(arr)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }

    const handleOpenAssign = (item) => {
        setOpenAssign(true)
        const { visit_id, patient_number, patient_name, age, gender } = item
        setIds({ visit_id, patient_number, patient_name, age, gender });
    };

    const handleCloseAssign = () => {
        setOpenAssign(false)
    };

    const handleOpenEndVisit = (item) => {
        setOpenEnd(true)
        const { visit_id, patient_number, patient_name, age, gender } = item
        setIds({ visit_id, patient_number, patient_name, age, gender });
    };

    const handleCloseEndVisit = () => {
        setOpenEnd(false)
    };

    const handleCloseMenu = () => {
        setOpenMenu(null)
    };

    const handleChangeNextPage = (value) => {
        setActivePage(value)
    };

    const handleChangeDoctor = value => {
        setDoctor(value)
    }

    const handleChangeDepartment = value => {
        setDepartment(value)
        retrieveDoctors(value.value)
    }

    const handleClickPatient = (patient_number, visit_id, patient_type, activity_id, labrequest_id, scan_request_id, bill_id, patient_admission_id) => {
        const formData = new FormData();
        formData.append('visit_id', visit_id ? visit_id : '');
        formData.append('bill_id', bill_id ? bill_id : '');
        axios.post(`${config.smsUrl}/cvisit/Update_visit_takeup_status`, formData).then(() => {
            if (department_name.includes('Dental')){
                history.push(`/dental-medical-history/${patient_number}/${visit_id}`)
            }else {
                history.push(`/previousvisits/${patient_number}/${visit_id}/${patient_type}/${patient_admission_id ?? null}`)
            }
        }).catch((err) => {
            logoutErrorMessage(err, null, actions)
        });
    };

    const assignDoctor = (event) => {
        event.preventDefault();
        const formData = new FormData();
        const { visit_id } = ids;
        // formData.append('patient_number', patient_number);
        formData.append('visit_id', visit_id);
        formData.append('user_roles_id', doctor_name?.value);
        setSubmitted(true)
        if (doctor_name && department) {
            setIsSubmitted('pending')
            axios.post(`${config.smsUrl}/cvisit/reassign_patient`, formData).then(() => {
                actions.snackbarActions.snackSuccess('Patient reassigned successfully');
                setIsSubmitted('resolved')
                setOpenAssign(false)
                setDepartment('');
                setDoctor('')
                setSubmitted(false)
            }).catch(e => {
                errorMessages(e, null, actions);
                setIsSubmitted('rejected')
                setOpenAssign(false)
            });

        }
    };

    const handleEndVisit = (action, v_id=null) => {
        const formData = new FormData();
        const { visit_id } = ids;
        const id = visit_id ? visit_id : v_id
        formData.append('visit_id', id);
        formData.append('user_roles_id', user_roles_id);
        formData.append('department_id', department_id);
        formData.append('action', action);

        const isEnded = action === 'end'
        if (id) {
            setIsEnd('pending')
            axios.post(`${config.smsUrl}/cvisit/complete_visit`, formData).then(() => {
                actions.snackbarActions.snackSuccess(`Visit ${isEnded ? 'ended':'reverted'} successfully`);
                setOpenEnd(false)
                setIsEnd('resolved')
            }).catch(e => {
                errorMessages(e, null, actions);
                setIsEnd('rejected')
            });
        }
    };

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar();
    };

    return {
        searchValue, openMenu,  isEnding, isEnded, isEndError,
        activePage, handleSearch, handleCloseMenu, handleChangeNextPage,
        handleClickPatient, closeSnackbar, waitingList, total_count, isLoading, isSuccess, isRejected,
        openAssign, handleOpenAssign, doctor_name, doctors, handleChangeDoctor, submitted,
        handleCloseAssign, isPending, isResolved, isError, ids, assignDoctor, isSubmitted, department,
        departments, handleChangeDepartment, openEnd, handleOpenEndVisit, handleCloseEndVisit, handleEndVisit,
        patientProps, state, handleChange,page, rowsPerPage, handleChangePage, handleChangeRowsPerPage
    }
}
