import React from 'react';
import Popover from '@material-ui/core/Popover';


export default function ReusablePopOver({children, anchorEl,handleClose}) {
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                {children}
            </Popover>
    );
}
