import {useState, useEffect} from "react";
import axios from "axios";
import {config} from "../../../../Helpers/env"
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import {history} from "../../../../Helpers/history";
import {formatDate} from "../../../Utils/ConvertDate";

function useReturnProduct(actions) {
    const [supplier, setSupplier] = useState(null)
    const [suppliers, setSuppliers] = useState([])
    const [purchase_id, setPurchaseId] = useState('')
    const [purchaseIds, setPurchaseIds] = useState([])
    const [rows, setRows] = useState([]);
    const [data, setData] = useState([]);
    const [totals, setTotals] = useState({total_discount: 0, grand_total_price: 0});
    const [state, setState] = useState({manufacturer_name: '', date: '', manufacturer_id: '', radio:1});
    const [details, setDetails] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [receipt_no, setReceiptNo] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [isData, setIsData] = useState('')
    const [status, setStatus] = useState('idle')
    const [invoice, setInvoice] = useState('')

    useEffect(() => {
        axios.get(`${config.smsUrl}/csupplier/retrieve_all_suppliers`).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const supps = !dt.total_manufacturers ? [] : dt.total_manufacturers;
            const arr = supps.map(item=>({value:item.supplier_id, label:item.supplier_name}))
            setSuppliers(arr);
        }).catch((err) => {
            logoutErrorMessage(err, null, actions)
        })
    }, []);

    const groupByPurchaseId = (arr=[]) =>{
        let result = []
        let knowledge = {}
        arr.forEach(item =>{
            let id = item.product_purchase_id;
            if(id in knowledge){
                result[knowledge[id]].products.push(item)
            }else {
                knowledge[id] = result.length
                result.push({
                    id: id,
                    date:formatDate(item.purchase_date),
                    invoice_no:item.chalan_no,
                    products: [item]
                })

            }
        })
        return result
    }

    const handleChangeInvoice = (value) =>{
        setInvoice(value)
        const arr = data.filter(item=>item.invoice_no === value?.value).map(row => ({...row, open:true,products:row.products.map(item=>({
                ...item, product_quantity: '', total: 0,available_quantity:item.remaining_stock,
                rtn: false, discount: '', rate:item.rate, purchase_quantity:item.quantity
            }))
        }))
        setRows(arr)

    }



    const handleChangeSupplier = (value) =>{
        setSupplier(value)
        setStatus('pending')
        axios.get(`${config.smsUrl}/cinventory/purchases/${value.value ?? value.value}`).then(response => {
            const data = response.data;
            const dt = !data ? [] : data;
            const id = dt[0].product_purchase_id ? dt[0].product_purchase_id :''
            const arr = groupByPurchaseId(dt)
            setData(arr)
            setIsData(id)
            setStatus('success')
        }).catch((err) => {
            actions.snackbarActions.snackInfo("No invoices were found")
            setStatus('error')
        })
    }

    //
    // useEffect(() => {
    //     if(!isData){
    //         return
    //     }
    //     const invoiceArr = data ?? []
    //     const arr = invoiceArr.map(row => ({...row, open:true,products:row.products.map(item=>({
    //             ...item, product_quantity: '', total: 0,available_quantity:item.remaining_stock,
    //             rtn: false, discount: '', rate:item.rate, purchase_quantity:item.quantity
    //         }))
    //     }))
    //     setRows(arr)
    // }, [data, isData])

    const handleOpen = (id) =>{
        const itemsArr = rows.map((item)=>{
            if(item.id === id){
                return {...item,open:!item.open}
            }else{
                return item
            }
        })
        setRows(itemsArr)
    }



    const handleChangeState = (event) =>{
        setState({...state, radio: event.target.value})
    }

    const handleChangeDetails = event => {
        setDetails(event.target.value)
    };

    const calculateTotal = (item) => {
        return +(item.product_quantity) * +item.rate;
    };

    const calculateDiscount = (item) => {
        let discount_value = !item.discount ? 0 : item.discount;
        let val = +(discount_value) / 100;
        return calculateTotal(item) * val;
    };

    const calculateTotalAmount = (arr) => {
        let total_discount = arr.reduce((acc, row) => {
            const discount = !row.discount ? 0 : row.discount;
            const product_quantity = !row.product_quantity ? 0 : row.product_quantity;
            const total = product_quantity * row.rate;
            const val = total * discount / 100;
            return acc + val
        }, 0);
        let grand_total_price = arr.reduce((acc, row) => {
            const dt = row.total ? row.total : 0
            return acc + dt;
        }, 0);
        setTotals({total_discount, grand_total_price});
    };

    const calculateFinalTotal = (item) => {
        return calculateTotal(item) - calculateDiscount(item);
    };




    const handleChangeQuantity = (event,parent_id, purchase_detail_id) => {
        const {value} = event.target;
        const arr = rows.map(row => {
            if(parent_id === row.id){
                return {
                    ...row,
                    products: row.products.map(item=>{
                        if (item.product_purchase_details_id === purchase_detail_id) {
                            return {
                                ...item,
                                product_quantity: value,
                                total: calculateFinalTotal({rate: item.rate, product_quantity: value, discount: item.discount})
                            }
                        }
                        return item
                    })
                }
            }
            return row
        })
        const calcArr = arr.reduce((a, item)=>a.concat(item.products),[])
        calculateTotalAmount(calcArr);
        setRows(arr)
    };

    const handleRemove = (parent_id, purchase_detail_id) => {
        const arr = rows.map(row => {
            if(parent_id === row.id){
                return {
                    ...row,
                    products: row.products.filter(item=>item.product_purchase_details_id !== purchase_detail_id)
                }
            }
            return row
        })
        const calcArr = arr.reduce((a, item)=>a.concat(item.products),[])
        calculateTotalAmount(calcArr);
        setRows(arr)
    };

    const handleChangeDiscount = (event, parent_id, purchase_detail_id) => {
        const {value} = event.target;
        const arr = rows.map(row => {
            if(parent_id === row.id){
                return {
                    ...row,
                    products: row.products.map(item=>{
                        if (item.product_purchase_details_id === purchase_detail_id) {
                            return {
                                ...item,
                                discount: value,
                                total: calculateFinalTotal({rate: item.rate, product_quantity: item.product_quantity, discount:value})
                            }
                        }
                        return item
                    })
                }
            }
            return row
        })
        const calcArr = arr.reduce((a, item)=>a.concat(item.products),[])
        calculateTotalAmount(calcArr);
        setRows(arr)
    };

    const handleCloseDialog = () => {
        setOpenDialog(false)
    }

    const handleCancel = () =>{
        const {radio} = state;
        const obj = {'1':'/returns/supplier', '2':'/returns/wastage', '3':'/returns/damages'}
        history.push(`${obj[+radio]}`)
    }

    const handleOpenReceipt = () =>{
        history.push(`/returnreport/${receipt_no}`)
    }


    const handleSubmit = (event, item) => {
        event.preventDefault();
        const {radio} = state
        /*const formData = new FormData();
        formData.append('product_purchase_id',item.id);
        formData.append('details',details);
        formData.append('return_type',radio);
        formData.append('chalan_no',item.invoice_no);
        item.products.filter(item=>item.product_quantity).forEach(dt=>{
                formData.append('product_id', dt.product_id);
                formData.append('quantity', dt.product_quantity);
                formData.append('batch_id', dt.batch_id);
        }) */
        const arr = item.products.filter(item=>item.product_quantity).map(dt=>{
            return {
                'batch' :  dt.batch_id,
                'return_quantity' :  dt.product_quantity,
                'product_id' : dt.product_id
            }
        })
        const params = {
            'product_purchase_id' : item.id,
            'reason' : details,
            'return_detail' : arr
        }
        const required = item.products.some(row => row.product_quantity)
        const isQuantity = item.products.filter(item=>item.product_quantity).every(row=>row.product_quantity <= row.purchase_quantity)
        setSubmitted(true)
        if (required && isQuantity) {
            setIsSubmitted('pending')
            axios.post(`${config.smsUrl}/cinventory/product_return`, params).then(response => {
                const data = response.data.data[0];
                const dt = data.product_purchase_id ?? '';
                setIsSubmitted('resolved')
                actions.snackbarActions.snackSuccess('Product(s) successfully returned')
                setReceiptNo(dt)
                setOpenDialog(true)
                const arr = rows.map(row=>{
                    if(row.product_purchase_id === item.id){
                        return {...row,open:false}
                    }
                    return row
                })
                setRows(arr)
            }).catch(err => {
                setIsSubmitted('rejected')
                errorMessages(err, null, actions)
            });
        }

    }

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    }

    const isPending = isSubmitted === 'pending'
    const isResolved = isSubmitted === 'resolved'
    const isRejected = isSubmitted === 'rejected'

    const isLoading = status === 'pending'
    const isSuccess = status === 'success'
    const isError = status === 'error'

    return {supplier, suppliers, purchase_id, purchaseIds, rows,state, details,openDialog, submitted,totals,
        isRejected, isPending, isResolved, handleChangeSupplier,  handleChangeDetails,invoice, handleChangeInvoice,
        handleSubmit, handleCancel, handleChangeDiscount, handleChangeQuantity, handleChangeState, handleCloseDialog,
        handleOpenReceipt, closeSnackbar, isLoading, isSuccess, isError, handleOpen, data,handleRemove}
}

export {useReturnProduct}