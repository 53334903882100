import {useTheme} from "@material-ui/core/styles";
import TextField from "../../../../Utils/FormInputs/TextField";
import { CustomSelect } from "../../../../Utils/FormInputs/SelectInput";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import { convDate, dateStringConvert } from "../../../../Utils/ConvertDate";
import TableContainer from "@material-ui/core/TableContainer";

export const convertJSONToTable = (ante_table, isStart='', table_type) => {
    const isGravida = table_type === 'gravida';
    const isProgress = table_type === 'progress';
    const arr = ante_table.map((item) => {
        const new_dict = {};
        let isID = false;
        for (const [k,v]  of Object.entries(item)) {
            
            const ifDropDown = k === 'type_of_delivery' || k === 'puer_perium';
            const ifDate = k === 'exam_date' || k === 'return_date';

            if (k === 'id') {
                isID = true;
                if (isGravida) {
                    new_dict['gravida_id'] = v;
                }
                if (isProgress) {
                    new_dict['exam_id'] = v;
                }
            } else if (ifDropDown) {
                // if a drop-down
                new_dict[k] = v ? {'value' : v, 'label' : v} : [];
            } else if (ifDate) {
                // if type date
                new_dict[k] = convDate(v);
            } else {
                new_dict[k] = v;
            }

            if (isStart === 'start_ante_natal' && isProgress === true) {
                new_dict['isDisabled'] = true;
            } else if (isProgress === true) {
                new_dict['isDisabled'] = false;
            }

        }

        if (isGravida && (isID=== false)) {
            new_dict['gravida_id'] = '';
        }

        if (isProgress && (isID=== false)) {
            new_dict['exam_id'] = '';
        }

        return new_dict;
    })

    return arr;
}

export const convertTableToJSON = (ante_table, table_type) => {
    const isGravida = table_type === 'gravida';
    const isProgress = table_type === 'progress';
    const arr = ante_table.map((item) => {
        const new_dict = {};
        let isID = false;
        for (const [k, v] of Object.entries(item)) {
            if (k !== 'abortion_year') {
                if (k === 'id' || k === 'gravida_id' || k === 'exam_id') { 
                    isID = true;
                    if (isGravida) {
                        new_dict['gravida_id'] = v;
                    }
                    if (isProgress) {
                        new_dict['exam_id'] = v;
                    }
                    
                } else if (typeof v === 'string') {
                    new_dict[k] = v;
                } else if (typeof v === 'number') {
                    new_dict[k] = v;
                } else {
                    new_dict[k] = v['value'] ? v['value'] : '';
                }
                
            }
        }
        if (isGravida && (isID=== false)) {
            new_dict['gravida_id'] = '';
        }

        if (isProgress && (isID=== false)) {
            new_dict['exam_id'] = '';
        }
        return new_dict;
    });

    return arr;
}

export const ReportPreviousObsHistoryTable = ({prev_obstetric_history}) => {

    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;

    return (
        <div style={{marginTop:'20px'}} data-testid='previous-obstetrics-history-table'>
            
            <table >
                <thead >
                    <tr style={{borderTop: "1px solid #909090"}}>
                        <td  style={{borderRight: "1px solid #909090"}}  colSpan={2} className={`${palette.type === 'dark' && 'dark-theme-color'} `}  align="center"><strong></strong>
                        </td>
                        <td  style={{borderRight: "1px solid #909090"}}  colSpan={2} className={`${palette.type === 'dark' && 'dark-theme-color'}`}  align="center"><strong>Abortions</strong>
                        </td>
                        <td  style={{borderRight: "1px solid #909090"}}  colSpan={6} className={`${palette.type === 'dark' && 'dark-theme-color'}`} align="center"><strong>Result</strong>
                        </td>
                        <td colSpan={5} className={`${palette.type === 'dark' && 'dark-theme-color'}`} align="center"><strong>Child</strong>
                        </td>
                    </tr>
                    <tr style={{borderTop: "1px solid #909090", borderBottom:"1px solid #909090"}}>                                
                        
                        <td className={`${palette.type === 'dark' && 'dark-theme-color'}`} style={{transform: 'rotate(-180deg)', writingMode:'vertical-lr', borderRight: "1px solid #909090"}}
                            align="center"><strong>Pregnancy</strong>
                        </td>
                        <td  className={`${palette.type === 'dark' && 'dark-theme-color'} `} style={{transform: 'rotate(-180deg)', writingMode:'vertical-lr', borderRight: "1px solid #909090"}}
                            align="center"><strong>Year</strong><span className="help-block">*</span>
                        </td>

                        <td  className={`${palette.type === 'dark' && 'dark-theme-color'}`}  style={{borderRight: "1px solid #909090"}} 
                            align="center"><strong>Below 12 wks</strong>
                        </td>
                        <td  className={`${palette.type === 'dark' && 'dark-theme-color'}`}  style={{borderRight: "1px solid #909090"}} 
                            align="center"><strong>Above 12 wks</strong>
                        </td>

                        <td  className={`${palette.type === 'dark' && 'dark-theme-color'}`} style={{transform: 'rotate(-180deg)', writingMode:'vertical-lr', borderRight: "1px solid #909090"}}
                            align="center"><strong>Pre Mature</strong>
                        </td>
                        <td  className={`${palette.type === 'dark' && 'dark-theme-color'}`} style={{transform: 'rotate(-180deg)', writingMode:'vertical-lr', borderRight: "1px solid #909090"}}
                            align="center"><strong>Full Term</strong>
                        </td>
                        <td  className={`${palette.type === 'dark' && 'dark-theme-color'}`}  style={{borderRight: "1px solid #909090"}} 
                            align="center"><strong>Type of Delivery</strong>
                        </td>
                        <td  className={`${palette.type === 'dark' && 'dark-theme-color'}`}  style={{borderRight: "1px solid #909090"}} 
                            align="center"><strong>Place of Delivery</strong>
                        </td>
                        <td  className={`${palette.type === 'dark' && 'dark-theme-color'}`} style={{borderRight: "1px solid #909090"}} 
                            align="center"><strong>Third Stage</strong>
                        </td>
                        <td  className={`${palette.type === 'dark' && 'dark-theme-color'}`} style={{borderRight: "1px solid #909090"}} 
                            align="center"><strong>Puer perium</strong>
                        </td>
                    
                        <td  className={`${palette.type === 'dark' && 'dark-theme-color'}`} style={{borderRight: "1px solid #909090"}} 
                            align="center"><strong>Alive SB/ NND</strong>
                        </td>
                        <td  className={`${palette.type === 'dark' && 'dark-theme-color'}`} style={{borderRight: "1px solid #909090"}} 
                            align="center"><strong>Sex</strong>
                        </td>
                        <td  className={`${palette.type === 'dark' && 'dark-theme-color'}`} style={{borderRight: "1px solid #909090"}} 
                            align="center"><strong>BirthWeight (kg)</strong>
                        </td>
                        <td  className={`${palette.type === 'dark' && 'dark-theme-color'}`} style={{borderRight: "1px solid #909090"}} 
                            align="center"><strong>Immun</strong>
                        </td>
                        <td  className={`${palette.type === 'dark' && 'dark-theme-color'}`}
                            align="center"><strong>Health Condition</strong>
                        </td>
                    </tr>
                </thead>

                <tbody > 
                    {prev_obstetric_history.map((item, index) => (
                        <tr key={index} className="tablerowspace" style={{borderBottom: "1px solid #909090"}}>

                            <td  style={{borderRight: "1px solid #909090"}} >
                            {item.pregnancy_number}                                        
                            </td>    

                            <td  style={{borderRight: "1px solid #909090"}} >
                            {item['year']}
                            </td>

                            <td  style={{borderRight: "1px solid #909090"}} >
                            {item['abortion_below_twelve_weeks']}
                            </td>
                            
                            <td  style={{borderRight: "1px solid #909090"}} >
                                {item['abortion_above_twelve_weeks']}
                            </td>

                            <td style={{borderRight: "1px solid #909090"}} >
                                {item['pre_mature']}
                            </td>

                            <td style={{borderRight: "1px solid #909090"}} >
                                {item['full_term']}
                            </td>

                            <td style={{borderRight: "1px solid #909090"}} >
                                {item['type_of_delivery']}
                            </td>

                            <td style={{borderRight: "1px solid #909090"}} >
                                {item['place_of_delivery']}
                            </td>

                            <td style={{borderRight: "1px solid #909090"}} >
                                {item['third_stage']}
                            </td>

                            <td style={{borderRight: "1px solid #909090"}} >
                                {item['puer_perium']}
                            </td>

                            <td style={{borderRight: "1px solid #909090"}} >
                                {item['alive_sb_nnd']}
                            </td>

                            <td style={{borderRight: "1px solid #909090"}} >
                                {item['sex']}
                            </td>

                            <td style={{borderRight: "1px solid #909090"}} >
                                {item['child_birth_weight']}
                            </td>

                            <td style={{borderRight: "1px solid #909090"}} >
                                {item['immun']}
                            </td>

                            <td >
                                {item['health_condition']}
                            </td>
                              
                        </tr>
                    ))}
                </tbody>
            </table>

            {/**
             * Add comments about previous pregnancies
             */}

        </div>
    )
}

export const PreviousObsHistoryTable = ({ prev_obstetric_history, handleObsChange}) => {

    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;

    // function for ANC 1 Timing
    const type_deliveries = [
        { label: 'Safe vaginal delivery (SVD)', value: "Safe vaginal delivery (SVD)" },
        { label: 'Cesarian section (C/S)', value: "Cesarian section (C/S)" }
    ];

    const alive_sb_nnd = [
        { label: 'Alive', value: "Alive" },
        { label: 'SB', value: "SB" },
        { label: 'NND', value: "NND" }
    ];

    return (
        <div data-testid='ante-natal-previous-obstetric-table'  style={{overflow:'scroll', maxWidth:'100vw'}}>
            <h5>Previous Obstetric History</h5>

            <table className={`table table-sm billing-invoice-table procedure-table  table-responsive `}>
                <thead>
                    <tr style={{borderTop: "1px solid #909090"}}>
                        <td  style={{borderRight: "1px solid #909090", fontSize:'11px'}}  colSpan={2} className={`${palette.type === 'dark' && 'dark-theme-color'} `}  align="center"><strong></strong>
                        </td>
                        <td  style={{borderRight: "1px solid #909090", fontSize:'11px'}}  colSpan={2} className={`${palette.type === 'dark' && 'dark-theme-color'}`}  align="center"><strong>Abortions</strong>
                        </td>
                        <td  style={{borderRight: "1px solid #909090", fontSize:'11px'}}  colSpan={6} className={`${palette.type === 'dark' && 'dark-theme-color'}`} align="center"><strong>Result</strong>
                        </td>
                        <td colSpan={5} className={`${palette.type === 'dark' && 'dark-theme-color'}`} align="center" style={{fontSize:'10px'}}><strong>Child</strong>
                        </td>
                    </tr>
                    <tr style={{borderTop: "1px solid #909090", fontSize:'2px', borderBottom:"1px solid #909090"}}>                                
                        <td  className={`${palette.type === 'dark' && 'dark-theme-color'} tablerightborder`} style={{padding:'15px', fontSize:'11px'}}
                            align="center"><strong>Pregnancy</strong>
                        </td>
                        <td  className={`${palette.type === 'dark' && 'dark-theme-color'} tablerightborder`} style={{padding:'10px', fontSize:'11px'}}
                            align="center"><strong>Year</strong><span className="help-block">*</span>
                        </td>

                        <td  className={`${palette.type === 'dark' && 'dark-theme-color'} tablerightborder`} style={{padding:'10px', fontSize:'11px'}}
                            align="center"><strong>Below 12 wks</strong>
                        </td>
                        <td  className={`${palette.type === 'dark' && 'dark-theme-color'} tablerightborder`} style={{padding:'10px', fontSize:'11px'}}
                            align="center"><strong>Above 12 wks</strong>
                        </td>

                        <td  className={`${palette.type === 'dark' && 'dark-theme-color'} tablerightborder`} style={{padding:'10px', fontSize:'11px'}}
                            align="center"><strong>Pre Mature</strong>
                        </td>
                        <td  className={`${palette.type === 'dark' && 'dark-theme-color'} tablerightborder`} style={{padding:'10px', fontSize:'11px'}}
                            align="center"><strong>Full Term</strong>
                        </td>
                        <td  className={`${palette.type === 'dark' && 'dark-theme-color'} tablerightborder`} style={{padding:'10px', fontSize:'11px'}}
                            align="center"><strong>Type of Delivery</strong>
                        </td>
                        <td  className={`${palette.type === 'dark' && 'dark-theme-color'} tablerightborder`} style={{padding:'10px', fontSize:'11px'}}
                            align="center"><strong>Place of Delivery</strong>
                        </td>
                        <td  className={`${palette.type === 'dark' && 'dark-theme-color'} tablerightborder`} style={{padding:'10px', fontSize:'11px'}}
                            align="center"><strong>Third Stage</strong>
                        </td>
                        <td  className={`${palette.type === 'dark' && 'dark-theme-color'} tablerightborder`} style={{padding:'10px', fontSize:'11px'}}
                            align="center"><strong>Puer perium</strong>
                        </td>
                    
                        <td  className={`${palette.type === 'dark' && 'dark-theme-color'} tablerightborder`} style={{padding:'10px', fontSize:'11px'}}
                            align="center"><strong>Alive SB/ NND</strong>
                        </td>
                        <td  className={`${palette.type === 'dark' && 'dark-theme-color'} tablerightborder`} style={{padding:'10px', fontSize:'11px'}}
                            align="center"><strong>Sex</strong>
                        </td>
                        <td  className={`${palette.type === 'dark' && 'dark-theme-color'} tablerightborder`} style={{padding:'10px', fontSize:'11px'}}
                            align="center"><strong>BirthWeight (kg)</strong>
                        </td>
                        <td  className={`${palette.type === 'dark' && 'dark-theme-color'} tablerightborder`} style={{padding:'10px', fontSize:'11px'}}
                            align="center"><strong>Immun</strong>
                        </td>
                        <td  className={`${palette.type === 'dark' && 'dark-theme-color'}`} style={{padding:'10px',  fontSize:'11px'}}
                            align="center"><strong>Health Condition</strong>
                        </td>

                    </tr>
                </thead>

                <tbody > 
                    {prev_obstetric_history.map((item, index) => (
                        <tr key={index} className="tablerowspace" style={{borderBottom: "1px solid #909090"}}>

                            <td className="tablerightborder" style={{fontSize:'11px'}}>
                            {item.pregnancy_number}                                        
                            </td>    

                            <td className="tablerightborder" style={{fontSize:'11px'}}>
                            <TextField style={{width:'inherit'}} autocomplete="off" type="number" min="0" onChange={(e)=>handleObsChange(e,index)} value={item['year']} name="year"/>
                            </td>

                            <td className="tablerightborder" style={{fontSize:'11px'}}>
                            <TextField style={{width:'inherit'}} autocomplete="off" type="number" min="0"  onChange={(e)=>handleObsChange(e,index)} value={item['abortion_below_twelve_weeks']} name="abortion_below_twelve_weeks"/>
                            </td>
                            
                            <td className="tablerightborder" style={{fontSize:'11px'}}>
                            <TextField style={{width:'inherit'}} autocomplete="off" type="number" min="0" onChange={(e)=>handleObsChange(e,index)} value={item['abortion_above_twelve_weeks']} name="abortion_above_twelve_weeks"/>
                            </td>

                            <td className="tablerightborder" style={{fontSize:'11px'}}>
                            <TextField style={{width:'inherit'}} autocomplete="off"  onChange={(e)=>handleObsChange(e,index)} value={item['pre_mature']} name="pre_mature"/>
                            </td>

                            <td className="tablerightborder" style={{fontSize:'11px'}}>
                            <TextField style={{width:'inherit'}} autocomplete="off"  onChange={(e)=>handleObsChange(e,index)} value={item['full_term']} name="full_term" />
                            </td>

                            <td className="tablerightborder" style={{fontSize:'11px'}}>
                            <CustomSelect width='120' options={type_deliveries} onChange={(e)=>handleObsChange(e,index, "type_of_delivery")} value={item['type_of_delivery']}/>
                            </td>

                            <td className="tablerightborder" style={{fontSize:'11px'}}>
                            <TextField style={{width:'inherit'}} autocomplete="off"  onChange={(e)=>handleObsChange(e,index)} value={item['place_of_delivery']} name="place_of_delivery"/>
                            </td>

                            <td className="tablerightborder" style={{fontSize:'11px'}}>
                            <TextField style={{width:'inherit'}} autocomplete="off"  onChange={(e)=>handleObsChange(e,index)} value={item['third_stage']} name="third_stage"/>
                            </td>

                            <td className="tablerightborder" style={{fontSize:'11px'}}>
                            <CustomSelect width='120' options={alive_sb_nnd} value={item['puer_perium']}  onChange={(e)=>handleObsChange(e,index, "puer_perium")}/>
                            </td>

                            <td className="tablerightborder" style={{fontSize:'11px'}}>
                            <TextField style={{width:'inherit'}} autocomplete="off"  onChange={(e)=>handleObsChange(e,index)} value={item['alive_sb_nnd']} name="alive_sb_nnd"/>
                            </td>

                            <td className="tablerightborder" style={{fontSize:'11px'}}>
                            <TextField style={{width:'inherit'}} autocomplete="off"  onChange={(e)=>handleObsChange(e,index)} value={item['sex']} name="sex"/>
                            </td>

                            <td className="tablerightborder" style={{fontSize:'11px'}}>
                            <TextField style={{width:'inherit'}} autocomplete="off" type="number"  onChange={(e)=>handleObsChange(e,index)} value={item['child_birth_weight']} name="child_birth_weight"/>
                            </td>

                            <td className="tablerightborder" style={{fontSize:'11px'}}>
                            <TextField style={{width:'inherit'}} autocomplete="off"  onChange={(e)=>handleObsChange(e,index)} value={item['immun']} name="immun"/>
                            </td>

                            <td style={{fontSize:'11px'}}>
                                <TextField style={{width:'inherit'}} autocomplete="off"  onChange={(e)=>handleObsChange(e,index)} value={item['health_condition']} name="health_condition"/>
                            </td>
                              
                        </tr>
                    ))}
                </tbody>
            </table>

            {/**
             * Add comments about previous pregnancies
             */}

        </div>
    )
}

export const ReportAnteNatalProgressTable = ({progress_table}) => {
    return (
        <div data-testid='ante-natal-progress-table'>
            <table className="table table-sm table-borderless">
                <thead>
                    <tr  style={{borderTop: "1px solid #909090", borderBottom: "1px solid #909090"}}>
                        <td style={{transform: 'rotate(-180deg)', writingMode:'vertical-lr', borderRight: "1px solid #909090"}}>Exam Date</td>
                        <td style={{transform: 'rotate(-180deg)', writingMode:'vertical-lr', borderRight: "1px solid #909090"}}>WOA</td>
                        <td style={{transform: 'rotate(-180deg)', writingMode:'vertical-lr',borderRight: "1px solid #909090"}}>Presentation</td>
                        <td style={{borderRight: "1px solid #909090"}}>Position</td>
                        <td style={{borderRight: "1px solid #909090"}}>Relation PP/Brim</td>
                        <td style={{borderRight: "1px solid #909090"}}>Foetal Heart Rate</td>
                        <td style={{borderRight: "1px solid #909090"}}>Weight (kg)</td>
                        <td style={{borderRight: "1px solid #909090"}}>BP (mm lg)</td>
                        <td style={{borderRight: "1px solid #909090"}}>Varicose (v) Code (0)</td>
                        <td style={{borderRight: "1px solid #909090"}}>Urine test (Glucosa, Protein, Acetone)</td>
                        <td style={{borderRight: "1px solid #909090"}}>eMTCT Code</td>
                        <td style={{borderRight: "1px solid #909090"}}>HB</td>
                        <td style={{borderRight: "1px solid #909090"}}>Iron Folic Acid (No. of Pills)</td>
                        <td style={{borderRight: "1px solid #909090"}}>IPT</td>
                        <td style={{borderRight: "1px solid #909090"}}>Complaints and Returns</td>
                        <td style={{borderRight: "1px solid #909090"}}>Return Date</td>
                        <td>Name of Examiner & Cadmi</td>
                    </tr>
                </thead>

                <tbody>
                    {progress_table.map((item, index) => (
                        <tr key={index} style={{borderBottom: "1px solid #909090"}}>
                            <td variant='50'  style={{borderRight: "1px solid #909090"}}>
                                {dateStringConvert(item['exam_date'])}
                            </td>
                            <td   style={{borderRight: "1px solid #909090"}}>
                                {item['exam_woa']}
                            </td>
                            <td   style={{borderRight: "1px solid #909090"}}>
                                {item['presentation']}
                            </td>
                            <td  style={{borderRight: "1px solid #909090"}}>
                                {item['position']}
                            </td>
                            <td variant='50' style={{borderRight: "1px solid #909090"}}>
                                {item['relation_pp_brim']}
                            </td>
                            <td variant='50'  style={{borderRight: "1px solid #909090"}}>
                                {item['footal_heart_rate']}
                            </td>
                            <td variant='50' style={{borderRight: "1px solid #909090"}}>
                                {item['mother_weight']}
                            </td>
                            <td variant='50' style={{borderRight: "1px solid #909090"}}>
                                {item['bp_mm_lg']}
                            </td>
                            <td  style={{borderRight: "1px solid #909090"}}>
                                {item['varicose_code']}
                            </td>
                            <td  style={{borderRight: "1px solid #909090"}}>
                                {item['urine_test']}
                            </td>
                            <td  style={{borderRight: "1px solid #909090"}}>
                                {item['emtct_code']}
                            </td>
                            <td  style={{borderRight: "1px solid #909090"}}>
                                {item['hb']}
                            </td>
                            <td  style={{borderRight: "1px solid #909090"}}>
                                {item['iron_folic_acid_no_of_pills']}
                            </td>
                            <td  style={{borderRight: "1px solid #909090"}}>
                                {item['ipt']}
                            </td>
                            <td  style={{borderRight: "1px solid #909090"}}>
                                {item['complaints_and_returns']}
                            </td>
                            <td  style={{borderRight: "1px solid #909090"}}>
                                {dateStringConvert(item['return_date'])}
                            </td>
                            <td  >
                                {item['name_of_examiner']}
                            </td>
                        </tr>
                    ))}

                </tbody>

            </table>
        </div>
    )
}

export const AnteNatalProgressExamTable = ({progress_table_props}) => {
    const {
        progress_table, removeProgressRow, handleProgressChange, handleNewProgressRow
    } = progress_table_props;
    
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;

    return (
        <div data-testid='ante-natal-progress-table' style={{overflow:'scroll', maxWidth:'100vw'}}>
            <table className={`table table-responsive table-sm billing-invoice-table procedure-table table-borderless`}>
                <thead>
                    <tr style={{borderTop: "1px solid #909090", borderBottom: "1px solid #909090"}}>
                        <td style={{borderRight: "1px solid #909090", fontSize:'11px'}}>Exam Date</td>
                        <td style={{borderRight: "1px solid #909090", fontSize:'11px'}}>WOA</td>
                        <td style={{borderRight: "1px solid #909090", fontSize:'11px'}}>Fundal Height</td>
                        <td style={{borderRight: "1px solid #909090", fontSize:'11px'}}>Presentation</td>
                        <td style={{borderRight: "1px solid #909090", fontSize:'11px'}}>Position</td>
                        <td style={{borderRight: "1px solid #909090", fontSize:'11px'}}>Relation PP/Brim</td>
                        <td style={{borderRight: "1px solid #909090", fontSize:'11px'}}>Foetal Heart Rate</td>
                        <td style={{borderRight: "1px solid #909090", fontSize:'11px'}}>Weight (kg)</td>
                        <td style={{borderRight: "1px solid #909090", fontSize:'11px'}}>BP (mm lg)</td>
                        <td style={{borderRight: "1px solid #909090", fontSize:'11px'}}>Varicose (v) Code (0)</td>
                        <td style={{borderRight: "1px solid #909090", fontSize:'11px'}}>Urine test (Glucosa, Protein, Acetone)</td>
                        <td style={{borderRight: "1px solid #909090", fontSize:'11px'}}>eMTCT Code</td>
                        <td style={{borderRight: "1px solid #909090", fontSize:'11px'}}>HB</td>
                        <td style={{borderRight: "1px solid #909090", fontSize:'11px'}}>Iron Folic Acid (No. of Pills)</td>
                        <td style={{borderRight: "1px solid #909090", fontSize:'11px'}}>IPT</td>
                        <td style={{borderRight: "1px solid #909090", fontSize:'11px'}}>Complaints and Remarks</td>
                        <td style={{borderRight: "1px solid #909090", fontSize:'11px'}}>Return Date</td>
                        <td style={{borderRight: "1px solid #909090", fontSize:'11px'}}>Name of Examiner & Cadmi</td>   
                        <td style={{fontSize: '11px'}}>Action</td>                                                                     
                    </tr>
                </thead>

                <tbody>
                    {progress_table.map((item, index) => (
                        <tr key={index} style={{borderBottom: "1px solid #909090"}}>
                        <td  style={{borderRight: "1px solid #909090", fontSize:'11px'}}>
                            <TextField style={{width:'inherit'}} autocomplete="off" className={`form__control ${palette.type === "dark" && 'form-control-dark'} `}
                            type='date' value={item['exam_date']} name="exam_date" onChange={(e)=>handleProgressChange(e, index)} disabled={item['isDisabled']}/>
                        </td>
                        <td   style={{borderRight: "1px solid #909090", fontSize:'11px'}}>
                            <TextField style={{width:'inherit'}} autocomplete="off" value={item['exam_woa']} disabled={true} name='exam_woa'/>
                        </td>
                        <td  style={{borderRight: "1px solid #909090", fontSize:'11px'}}>
                            <TextField style={{width:'inherit'}} autocomplete="off" value={item['fundal_height']} name="fundal_height" onChange={(e)=>handleProgressChange(e, index)} disabled={item['isDisabled']}/>
                        </td>
                        <td  style={{borderRight: "1px solid #909090", fontSize:'11px'}}>
                            <TextField style={{width:'inherit'}} autocomplete="off" value={item['presentation']} name="presentation" onChange={(e)=>handleProgressChange(e, index)}  disabled={item['isDisabled']}/>
                        </td>
                        <td style={{borderRight: "1px solid #909090", fontSize:'11px'}}>
                            <TextField style={{width:'inherit'}} autocomplete="off" value={item['position']} name="position" onChange={(e)=>handleProgressChange(e, index)}  disabled={item['isDisabled']}/>
                        </td>
                        <td style={{borderRight: "1px solid #909090", fontSize:'11px'}}>
                            <TextField style={{width:'inherit'}} autocomplete="off" value={item['relation_pp_brim']} name="relation_pp_brim" onChange={(e)=>handleProgressChange(e, index)}  disabled={item['isDisabled']}/>
                        </td>
                        <td  style={{borderRight: "1px solid #909090", fontSize:'11px'}}>
                            <TextField style={{width:'inherit'}} autocomplete="off" value={item['footal_heart_rate']} name="footal_heart_rate" type="number" min="0"
                            onChange={(e)=>handleProgressChange(e, index)}  disabled={item['isDisabled']}/>
                        </td>
                        <td style={{borderRight: "1px solid #909090", fontSize:'11px'}}>
                            <TextField style={{width:'inherit'}} autocomplete="off" value={item['mother_weight']} name="mother_weight" type="number" min="0" onChange={(e)=>handleProgressChange(e, index)}  disabled={item['isDisabled']}/>
                        </td>
                        <td style={{borderRight: "1px solid #909090", fontSize:'11px'}}>
                            <TextField style={{width:'inherit'}} autocomplete="off" value={item['bp_mm_lg']} name="bp_mm_lg" type="number" min="0" onChange={(e)=>handleProgressChange(e, index)}  disabled={item['isDisabled']}/>
                        </td>
                        <td style={{borderRight: "1px solid #909090", fontSize:'11px'}}>
                            <TextField style={{width:'inherit'}} autocomplete="off" value={item['varicose_code']} name="varicose_code" onChange={(e)=>handleProgressChange(e, index)}  disabled={item['isDisabled']}/>
                        </td>
                        <td style={{borderRight: "1px solid #909090", fontSize:'11px'}}>
                            <TextField style={{width:'inherit'}} autocomplete="off" value={item['urine_test']} name="urine_test" onChange={(e)=>handleProgressChange(e, index)}  disabled={item['isDisabled']}/>
                        </td>
                        <td style={{borderRight: "1px solid #909090", fontSize:'11px'}}>
                            <TextField style={{width:'inherit'}} autocomplete="off" value={item['emtct_code']} name="emtct_code" onChange={(e)=>handleProgressChange(e, index)}  disabled={item['isDisabled']}/>
                        </td>
                        <td style={{borderRight: "1px solid #909090", fontSize:'11px'}}>
                            <TextField style={{width:'inherit'}} autocomplete="off" value={item['hb']} name="hb" onChange={(e)=>handleProgressChange(e, index)}  disabled={item['isDisabled']}/>
                        </td>
                        <td style={{borderRight: "1px solid #909090", fontSize:'11px'}}>
                            <TextField style={{width:'inherit'}} autocomplete="off" value={item['iron_folic_acid_no_of_pills']} name="iron_folic_acid_no_of_pills" onChange={(e)=>handleProgressChange(e, index)}  disabled={item['isDisabled']}/>
                        </td>
                        <td style={{borderRight: "1px solid #909090", fontSize:'11px'}}>
                            <TextField style={{width:'inherit'}} autocomplete="off" value={item['ipt']} name="ipt" onChange={(e)=>handleProgressChange(e, index)}  disabled={item['isDisabled']}/>
                        </td>
                        <td style={{borderRight: "1px solid #909090", fontSize:'11px'}}>
                            <TextField style={{width:'inherit'}} autocomplete="off" value={item['complaints_and_returns']} name="complaints_and_returns" onChange={(e)=>handleProgressChange(e, index)}  disabled={item['isDisabled']}/>
                        </td>
                        <td style={{borderRight: "1px solid #909090", fontSize:'11px'}}>
                            <TextField style={{width:'inherit'}} autocomplete="off" type="date" value={item['return_date']} className={`form__control ${palette.type === "dark" && 'form-control-dark'} ante-table-width`} name="return_date"
                            onChange={(e)=>handleProgressChange(e, index)}  disabled={item['isDisabled']}/>
                        </td>
                        <td style={{borderRight: "1px solid #909090", fontSize:'11px'}}>
                            <TextField style={{width:'inherit'}} autocomplete="off" value={item['name_of_examiner']} name="name_of_examiner" onChange={(e)=>handleProgressChange(e, index)}  disabled={item['isDisabled']}/>
                        </td>
                        <td style={{fontSize:'11px'}}>
                            <button onClick={() => removeProgressRow(index, item['exam_id'])} type="button"
                                    className="btn btn-sm sms-btn-dismiss" id="remove-row"
                                    data-testid="presc-remove-row">
                                <FontAwesomeIcon icon={faTrash}/>
                            </button>
                        </td>
                        </tr>
                    ))}

                    <tr>
                        <td colSpan={6} style={{fontSize:'11px'}}>
                            <button type="button" onClick={handleNewProgressRow} className="btn btn-sm sms-gray-btn"
                                    id="add-row">Add another line
                            </button>
                        </td>
                    </tr> 
                </tbody>

            </table>
        </div>
    )
}

export const ReportUltraSoundReportTable = ({ultrasound_table}) => {
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;

    return (
        <div data-testid='ultrasound-report-table'>
            <table className="table table-sm table-borderless">
                <thead>
                    <tr style={{borderTop: "1px solid #909090", borderBottom: "1px solid #909090"}}>
                        <td  style={{borderRight: "1px solid #909090"}} ><strong>Date</strong></td>
                        <td style={{borderRight: "1px solid #909090"}} ><strong>Gestational Age</strong></td>
                        <td style={{borderRight: "1px solid #909090"}} ><strong>Placenta (site and Maturity)</strong></td>
                        <td style={{borderRight: "1px solid #909090"}} ><strong>Amniotic Fluid</strong></td>
                        <td style={{borderRight: "1px solid #909090"}} ><strong>Complication/Abnormality</strong></td>
                        <td  style={{borderRight: "1px solid #909090"}}><strong>Comments</strong></td>
                    </tr>
                </thead>

                <tbody>
                    {ultrasound_table.map((item, index) => (
                        <tr key={index} style={{borderBottom: "1px solid #909090"}}>
                            <td style={{borderRight: "1px solid #909090"}}>
                                {item['ultra_date']}
                            </td>
                            <td style={{borderRight: "1px solid #909090"}} >
                                {item['gestational_age']}
                            </td>
                            <td style={{borderRight: "1px solid #909090"}} >
                                {item['placenta']}
                            </td>
                            <td style={{borderRight: "1px solid #909090"}}>
                                {item['amniotic_fluid']}
                            </td>
                            <td style={{borderRight: "1px solid #909090"}} >
                                {item['complication']}
                            </td>
                            <td style={{borderRight: "1px solid #909090"}} >
                                {item['comments']}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>            
        </div>
    )
}

export const UltraSoundReportTable = ({ultrasound_props}) => {
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;

    const {
        ultrasound_table, removeUltraRow, handleNewUltraRow, handleUltraChange
    } = ultrasound_props;
    return (
        <div data-testid='ultrasound-report-table' style={{overflow:'scroll', maxWidth:'100vw'}}>
            <table className="table table-sm table-borderless table-responsive"  style={{width:'max-content'}}>
                <thead>
                    <tr style={{borderTop: "1px solid #909090", borderBottom: "1px solid #909090", fontSize:'11px'}}>
                        <td  style={{borderRight: "1px solid #909090", fontSize:'11px'}} ><strong>Date</strong></td>
                        <td style={{borderRight: "1px solid #909090", fontSize:'11px'}} ><strong>Gestational Age</strong></td>
                        <td style={{borderRight: "1px solid #909090", fontSize:'11px'}} ><strong>Placenta (site and Maturity)</strong></td>
                        <td style={{borderRight: "1px solid #909090", fontSize:'11px'}} ><strong>Amniotic Fluid</strong></td>
                        <td style={{borderRight: "1px solid #909090", fontSize:'11px'}} ><strong>Complication/Abnormality</strong></td>
                        <td  style={{borderRight: "1px solid #909090", fontSize:'11px'}}><strong>Comments</strong></td>
                        <td>Action</td> 
                    </tr>
                </thead>

                <tbody>
                    {ultrasound_table.map((item, index) => (
                        <tr key={index} style={{borderBottom: "1px solid #909090"}}>
                        <td style={{borderRight: "1px solid #909090", fontSize:'11px'}}>
                            <TextField autocomplete="off" type="date" value={item['ultra_date']} name="ultra_date" onChange={(e)=>handleUltraChange(e, index)}/>
                        </td>
                        <td style={{borderRight: "1px solid #909090", fontSize:'11px'}} >
                            <TextField autocomplete="off" value={item['gestational_age']} name="gestational_age" onChange={(e)=>handleUltraChange(e, index)}/>
                        </td>
                        <td style={{borderRight: "1px solid #909090", fontSize:'11px'}} >
                            <TextField autocomplete="off" value={item['placenta']} name="placenta" onChange={(e)=>handleUltraChange(e, index)}/>
                        </td>
                        <td style={{borderRight: "1px solid #909090", fontSize:'11px'}}>
                            <TextField autocomplete="off" value={item['amniotic_fluid']} name="amniotic_fluid" onChange={(e)=>handleUltraChange(e, index)}/>
                        </td>
                        <td style={{borderRight: "1px solid #909090", fontSize:'11px'}} >
                            <TextField autocomplete="off" value={item['complication']} name="complication" onChange={(e)=>handleUltraChange(e, index)}/>
                        </td>
                        <td style={{borderRight: "1px solid #909090", fontSize:'11px'}} >
                            <TextField autocomplete="off" value={item['comments']} name="comments" onChange={(e)=>handleUltraChange(e, index)}/>
                        </td>
                        <td style={{fontSize:'11px'}}>
                            <button type="button" onClick={(e) => removeUltraRow(e, index)}
                                    className="btn btn-sm sms-btn-dismiss" id="remove-row"
                                    data-testid="presc-remove-row">
                                <FontAwesomeIcon icon={faTrash}/>
                            </button>
                        </td>
                        </tr>

                    ))}

                    <tr>
                        <td colSpan={6} style={{fontSize:'11px'}}>
                            <button type="button" className="btn btn-sm sms-gray-btn" onClick={handleNewUltraRow}
                                    id="add-row">Add another line
                            </button>
                        </td>
                    </tr> 
                </tbody>
            </table>            
        </div>
    )
}