import React, {useState} from "react";
import axios from "axios";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import {config} from "../../../../Helpers/env";
import {titleCase} from "../../../Users/addUser";
import {v4 as uuidv4} from "uuid";
import {ConfirmationDialog} from "../../../Utils/ReusableComponents/ConfirmationDialog";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Label from "../../../Utils/FormInputs/Label";
import Col from "react-bootstrap/Col";
import {CustomSelect} from "../../../Utils/FormInputs/SelectInput";
import {RightAlignedContainer} from "../../../Utils/styledComponents";
import {LabTable} from "../LabTable";
import {BlockNavigationComponent} from "../../../Utils/ReusableComponents/BlockNavigationComponent";
import {history} from "../../../../Helpers/history";

export function useNewSelfLabRequest(obj) {
    const initialLab = [{hospital_test_id: '', isError: false, uuid: uuidv4(),
        test_name: '', specimen: '', hospital_test_type: '', service_id: '',
        qty: 1, price: 0, total_amount: 0, showTest: false,cost:0,
    }];

    const {visit_id, service_type,  actions, department_name, patient_number, visit_type,setOpenDialog, setIsBlocking, isBlocking,setServiceType} = obj
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [labTests, setLabTests] = useState(initialLab);
    const [tests, setTests] = useState([]);
    const [isBlockDialog, setIsBlockDialog] = useState(false)



    //lab dialog
    const [openLab, setOpenLab] = useState(false)
    const [labCashItems, setLabCashItems] = useState([])
    const [lab_id, setLabId] = useState('')


    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = _token.user_roles_id ? _token.user_roles_id : ''
    // const requested_by = `${!_token.first_name ? "" : _token.first_name} ${!_token.last_name ? "" : _token.last_name}`;



    const retrieveTests = (eventTxt, idx) => {
        const arr = labTests.map((item, index) => {
            if (index === idx) {
                const formData = new FormData();
                formData.append('hospital_test_name', titleCase(eventTxt));
                if (eventTxt !== '') {
                    axios.post(`${config.smsUrl}/cdoctor/autocompletesearch_hospt_tests`, formData).then(res => {
                        const data = res.data;
                        const dt = !data ? [] : data;
                        if (dt[0] === "No Labrequests Found") {
                            setTests([{value: '', label: 'No Labrequests Found'}])
                        }
                        setTests(dt);
                    }).catch(error => {
                        logoutErrorMessage(error, null, actions)
                    });
                    return {...item, test_name: eventTxt, showTest: true, isError: false}
                }
                return {...item, test_name: eventTxt, showTest: false, isError: false}
            }
            return item
        })
        setLabTests(arr)
        setIsSubmitted('idle')
        setIsBlocking(true)
    };

    const getTestDetails = (event, idx) => {
        let arr = labTests.map((item, index)=>{
            if(index === idx){
                return {...item, test_name:event.innerText, hospital_test_id:event.value, isError: false, showTest: false}
            }
            return item
        })
        if(event.value){
            const formData = new FormData();
            formData.append('hospital_test_id', event.value);
            axios.post(`${config.smsUrl}/cdoctor/get_testdetails_by_testid`, formData).then(res => {
                const resp = res.data;
                const data = !resp ? {} : resp;
                axios.post(`${config.smsUrl}/cbilling/item_cost`, {visit_id:+visit_id, item_id:+data.service_id, item_type:'service'}).then(res => {
                    const resObj = res.data;
                    const dataObj = !resObj.data ? {} : resObj.data;
                    arr = arr.map((item, index)=>{
                        if(index === idx){
                            return {...item, specimen:data.specimen ? data.specimen : '', ...dataObj,
                                hospital_test_type: data.hospital_test_type ? data.hospital_test_type : '',
                                service_id:data.service_id ? data.service_id : '', cost:  dataObj.cost ?? 0,
                                currency: dataObj.currency_id, currency_symbol: dataObj.currency, is_external:data.is_external}
                        }
                        return item
                    })
                    setLabTests(arr)
                    if(dataObj.is_provider === 1 && dataObj.is_provided === 0){
                        setLabId(data.hospital_test_id)
                        setOpenLab(true)
                    }
                }).catch(error => {
                    logoutErrorMessage(error, null, actions)
                });
            }).catch(error => {
                logoutErrorMessage(error, null, actions)
            });
        }
        setIsBlocking(true)

    };

    const addLabTestsToReceipt = () =>{
        const arr = labTests.filter(item=>item.hospital_test_id === lab_id)
        setLabCashItems(arr)
        setOpenLab(false)
    }

    const handleCloseLab = () =>{
        const arr = labTests.filter(item=>item.hospital_test_id !== lab_id)
        setLabTests(arr)
        setOpenLab(false)
    }

    const handleCancelLab = () =>{
        setOpenLab(false)
    }


    const handleAddRow = () => {
        setLabTests([...labTests, ...initialLab])
    };


    const removeRow = (item_uuid) => {
        if (labTests.length > 1) {
            const arr = labTests.filter((e) => e.uuid !== item_uuid)
            setLabTests(arr);
        }
    };



    const handleSubmitLabRequest = (event) => {
        event.preventDefault();

        const lab = labTests.filter(test => test.hospital_test_id && test.service_id).map(item => ({
            hospital_test_id: item.hospital_test_id,
            service_id: item.service_id,
            quantity:1,
            rate:item.cost,
            is_provided: item.is_provided,
            is_provider: item.is_provider,
            currency_id:item.currency
        }))

        // required parameters
        const  params = {
            patient_number:patient_number, visit_type: +visit_type?.value ?? '',assigned_to: '',
            department_id: department_name.value ?? '', receptionist:user_roles_id, user_roles_id, patient_type: 1,
            result_type: 2, bill_type:2, visit_id: +visit_id, lab_state:1,
            bill_details: lab
        }

        setSubmitted(true);
        setIsBlocking(false)
        const labArr = labTests.every(test => test.hospital_test_id && test.service_id)
        if (service_type &&  labArr ) {
                setIsSubmitted('pending');
                axios.post(`${config.smsUrl}/cdoctor/save_labrequest`, {...params}).then(() => {
                    setIsSubmitted('resolved');
                    actions.snackbarActions.snackSuccess(`Lab request(s) submitted successfully`);
                    setLabTests(initialLab)
                    setSubmitted(false);
                    if(setOpenDialog){
                        setOpenDialog(false)
                    }
                    const arr = labTests.map(item=>({
                        ...item,
                        hospital_test_id: '', isError: false, uuid: uuidv4(),
                        test_name: '', specimen: '', hospital_test_type: '', service_id: '',
                        qty: 1, price: 0, total_amount: 0, showTest: false,cost:0,
                    }))
                    setLabTests(arr)
                    setServiceType('')
                }).catch((err) => {
                    errorMessages(err, null, actions)
                    setSubmitted(false);
                    setIsSubmitted('rejected');
                });
            }
    }

    const handleResetForm = () =>{
        if(setOpenDialog){
            setOpenDialog(false)
        }
        const arr = labTests.map(item=>({
            ...item,
            hospital_test_id: '', isError: false, uuid: uuidv4(),
            test_name: '', specimen: '', hospital_test_type: '', service_id: '',
            qty: 1, price: 0, total_amount: 0, showTest: false,cost:0,
        }))
        setLabTests(arr)
        setIsBlockDialog(false)
        setIsBlocking(false)
        setSubmitted(false);
        setServiceType('')
    }

    const handleCloseLabDialog = () => {
        if(isBlocking){
            setIsBlockDialog(true)
        }else{
            handleResetForm()
        }
    };

    const isPending = isSubmitted === 'pending'
    const isResolved = isSubmitted === 'resolved'
    const isRejected = isSubmitted === 'rejected'

    return {
        isResolved,
        isRejected,
        handleSubmitLabRequest,
        handleCloseLabDialog,
        submitted,
        renderLabRequest:(
            <React.Fragment>
                <BlockNavigationComponent
                    when={isBlocking}
                    shouldBlockNavigation={()=>{
                        return isBlocking;
                    }}
                    navigate={path=>history.push(path)}
                    dialogClose={isBlockDialog}
                    setIsBlockDialog={setIsBlockDialog}
                    clearForm={handleResetForm}
                />
                <ConfirmationDialog open={openLab} handleClose={handleCancelLab}
                                    title='Make cash payment'
                                    confirmItems={addLabTestsToReceipt}
                                    removeItems={handleCloseLab} service='lab test'/>
                <div className="row">
                    <div className="col-lg-6">

                        <Form.Group as={Row}>
                            <Label name="Department" column sm="3" type/>
                            <Col sm="8">
                                <CustomSelect submitted={submitted}   id='visit_department'
                                              value={department_name} options={[]}
                                              data-test="department_name" isDisabled={true}/>
                            </Col>
                        </Form.Group>
                    </div>
                    <div className="col-lg-6"/>
                </div>
                <LabTable getTestDetails={getTestDetails} handleAddRow={handleAddRow} labTests={labTests}
                          tests={tests} removeRow={removeRow} retrieveTests={retrieveTests}
                          submitted={submitted} isResolved={isResolved}/>
                <RightAlignedContainer>
                    <button type="submit" id='submit_form'
                            className="sms-btn btn btn-sm px-3 mt-3"  disabled={isPending}>{isPending ? 'Saving...' : 'Save Lab Request'}</button>
                    {/*<button type="button"  id="reset_form"  className="sms-gray-btn btn btn-sm ml-2" >Cancel</button>*/}
                </RightAlignedContainer>
            </React.Fragment>
        )
    }
}


