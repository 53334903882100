import React, {useEffect, useState} from 'react'
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from '../../../actions/snackbarActions'
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {Container, PLContainer, ProfitContainer} from '../../Utils/styledComponents';
import {usePrint} from '../../Utils/Templates/usePrint';
import {faClipboardList} from '@fortawesome/free-solid-svg-icons/faClipboardList';
import {BackButton} from '../../Utils/Buttons/DataExportationButton';
import {PrintPdfButtons} from "./PrintPdfButtons";
import Card from '@material-ui/core/Card';
import {dateConvert, formatDate} from '../../Utils/ConvertDate';
import {NoTransaction} from "../ChartofAccounts/ReusableJournalTable";
import {LoadingGif} from '../../Utils/Loader';
import TableRow from "@material-ui/core/TableRow";
import TableHead from '@material-ui/core/TableHead';
import axios from "axios";
import {config} from "../../../Helpers/env";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {HospitalAddress} from "../../Utils/HospitalAddress";
import {AccountingDateFilter} from "../../Utils/AccountingUtils/AccountingDateFilter";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Label from "../../Utils/FormInputs/Label";
import SelectInput from "../../Utils/FormInputs/SelectInput";
import {BalanceSheetTreeView} from "../Utils/BalanceSheetTreview";
import {BalanceSheetTableCustomCell} from "../ChartofAccounts/TreeViewComponent";
import {BalanceSheetPrint, CoaCustomCell} from "../Utils/BalanceSheetPrint";
import {formatDigits} from "../../Utils/formatNumbers";

const months = {
    1:'Jan',
    2:'Feb',
    3:'Mar',
    4:'Apr',
    5:'May',
    6:'Jun',
    7:'Jul',
    8:'Aug',
    9:'Sep',
    10:'Oct',
    11:'Nov',
    12:'Dec'
}

const isCurrentMonth = (start_date, end_date) =>{
    const this_month = new Date(end_date)
    const selected_date = new Date(start_date)
    return selected_date.getMonth() === this_month.getMonth()
}

const getDate = (date) =>{
    const cur_date = new Date(date)
    return cur_date.getDate() < 10  ? `0${cur_date.getDate()}` : cur_date.getDate()
}

export const getMonth = (start_date,end_date, category="monthly") =>{
    const from_date = new Date(start_date)
    const to_date = new Date(end_date)
    const start_date_month = months[from_date.getMonth() + 1]
    const end_date_month = months[to_date.getMonth() + 1]
    const current_month= isCurrentMonth(start_date, end_date) ? `${end_date_month} ${getDate(end_date)}` :
        `${start_date_month} ${getDate(start_date)} - ${end_date_month} ${getDate(end_date)}`
    const current_period = category === 'monthly' ?  current_month : `${getDate(end_date)}-${end_date_month}-${to_date.getFullYear()}`
    const prev_year = to_date.getFullYear() - 1
    const prev_month = (from_date.getMonth() + 1) - 1
    const previous_period =category === 'monthly' ? `${months[prev_month]} ${getDate(end_date)}`   : `${getDate(end_date)}-${end_date_month}-${prev_year}`
    return {
        monthly:{
            current_period:current_period,
            previous_period:previous_period
        },
        yearly:{
            current_period,
            previous_period
        }

    }
}



export const formatData = (data={}) =>{
    let arr = []
    for (let item in data){
        let obj = {
            account_name: item,
            head_level:1,
            id:item,
            accounts: {
                ...data[item],
               [ `TOTAL ${item}`]:{
                   current_total:data[item].current_total,
                   previous_total: data[item].previous_total
               },
            }
        }
        if (!(obj.account_name === 'previous_balance' || obj.account_name === 'current_balance' || item ==='sub_categories'
            || item === 'totals_liabilities_equity' || item === "totals_assets")){
            arr.push(obj)
        }
    }
    return arr
}


const BalanceSheet = ({actions, snackbar}) => {

    const [data, setData] = useState(null);
    const [date, setDate] = useState({from_date:dateConvert(), to_date:dateConvert()});
    const [loading, setLoading] = useState('idle')
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const {from_date, to_date} = date;
    const [category, setCategory] = useState('monthly')
    const [totals, setTotals] = useState({current_balance:0, previous_balance:0})





    useEffect(() => {
        setLoading('pending')
        axios.get(`${config.smsUrl}/accounting/new_balance_sheet_one`,{params:{start_date:from_date, end_date:to_date, category}}).then(res => {
            const data = res.data;
            const dt = data ? data : {};
            const dat = dt.data ? dt.data : {}
            const obj = formatData(dat)
            setData(obj)
            setTotals({current_balance: dat?.totals_liabilities_equity?.current_total,
                previous_balance: dat?.totals_liabilities_equity?.previous_total})
            setLoading('success')
        }).catch(err => {
            errorMessages(err, null, actions)
            setLoading('error')
        })

    }, [category])

    const handleChangeDate = (event) =>{
        const {value, name} = event.target;
        setDate({...date,[name]:value})
    }

    const handleChangeCategory = (event) =>{
        setCategory(event.target.value)
    }


    const handleSubmit = (event) =>{
        setSubmitted(true)
        event.preventDefault()
        setLoading('pending')
        setIsSubmitted('pending')
        axios.get(`${config.smsUrl}/accounting/new_balance_sheet_one`,
            {params:{start_date:from_date, end_date:to_date, category}}).then(res => {
            const data = res.data;
            const dt = data ? data : {};
            const dat = dt.data ? dt.data : {}
            const obj = formatData(dat)
            setData(obj)
            setTotals({current_balance: dat?.totals_liabilities_equity?.current_total,
                previous_balance: dat?.totals_liabilities_equity?.previous_total})
            setLoading('success')
            setIsSubmitted('resolved')
        }).catch(err => {
            errorMessages(err, null, actions)
            setLoading('error')
            setIsSubmitted('rejected')
        })
    }

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    }
    const isLoading = loading === 'pending';
    const isSuccess = loading === 'success';
    const isError = loading === 'error';

    const isPending = isSubmitted === 'pending';
    const isResolved = isSubmitted === 'resolved';
    const isRejected = isSubmitted === 'rejected';


    const {open, variant, message} = snackbar;

    const balanceSheet = data ? data : [];

    const period = getMonth(date.from_date, date.to_date,category)[category]

    const current_month = isCurrentMonth(from_date, to_date)

    const balance_sheet_period = current_month ? formatDate(to_date) : `${formatDate(from_date)} to ${formatDate(to_date)}`

    const tableHeader = (
            <TableRow>
                <BalanceSheetTableCustomCell><strong>Account</strong></BalanceSheetTableCustomCell>
                <BalanceSheetTableCustomCell align='center'><strong>{period.previous_period}</strong></BalanceSheetTableCustomCell>
                <BalanceSheetTableCustomCell align='center'><strong>{period.current_period}</strong></BalanceSheetTableCustomCell>
            </TableRow>
    )

    const tableFooter = (
            <TableRow>
                <BalanceSheetTableCustomCell><strong>TOTAL LIABILITIES & EQUITY</strong></BalanceSheetTableCustomCell>
                <BalanceSheetTableCustomCell align='center'><strong>{formatDigits(totals.previous_balance)}</strong></BalanceSheetTableCustomCell>
                <BalanceSheetTableCustomCell align='center'><strong>{formatDigits(totals.current_balance)}</strong></BalanceSheetTableCustomCell>
            </TableRow>
    )

    const {componentRef, handlePrint} = usePrint( `Balance Sheet(${to_date})`)
    const header = (
        <TableHead>
            <TableRow>
                <CoaCustomCell><strong>Account</strong></CoaCustomCell>
                <CoaCustomCell align='center'><strong>{period.previous_period}</strong></CoaCustomCell>
                <CoaCustomCell align='center'><strong>{period.current_period}</strong></CoaCustomCell>
            </TableRow>
        </TableHead>
    )
    const printDoc = (
        <div  ref={componentRef}>
            <Container>
                <HospitalAddress/>
                <table className="table table-sm table-borderless prescription-table">
                    <tbody>
                    <tr>
                        <td align='center'><h5><strong>Balance Sheet</strong></h5></td>
                    </tr>
                    <tr>
                        <td align='center'><h6><strong>As of {balance_sheet_period}</strong></h6></td>
                    </tr>
                    </tbody>
                </table>
                <BalanceSheetPrint  {...{ data:balanceSheet, isPrint:true,header, footer:tableFooter}}/>
                <p style={{textAlign: "left", fontStyle: "italic"}}  className="mpeke-footer">Powered by Mara Scientific</p>
            </Container>
        </div>
    )

    return (
        <div className='journals'>
            <PageTitle title="Balance Sheet"/>
            <SubHeader title="Reports" subTitle="Balance Sheet">
                <FontAwesomeIcon icon={faClipboardList}/>
            </SubHeader>

            <MainSnackbar message={message} open={open} handleCloseBar={closeSnackbar} variant={variant}/>


            <PLContainer>
                <div className="my-3">
                    <BackButton to='/reports/generalreports' text='General Reports'/>
                </div>
                <AccountingDateFilter {...{from_date,to_date, handleChange:handleChangeDate,
                    submitted,handleSubmit,isPending}}>
                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={2} lg={2} xl={2}>
                            <Label name='Category'  type/>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                            <SelectInput onChange={handleChangeCategory} options={[{value:'monthly', text:'Monthly'},
                                {value:'yearly', text:'Yearly'}]} value={category}/>
                        </Col>
                    </Form.Group>
                </AccountingDateFilter>
                {isLoading ? <div className='text-center mt-5'>
                    <LoadingGif/>
                </div>:null}
                {isSuccess ? balanceSheet.length > 0 ?
                    <Card>
                        <div style={{padding:'8px 32px', borderBottom:'1px solid rgba(224, 224, 224, 1)'}}>
                            <PrintPdfButtons   handlePrint={handlePrint}/>
                        </div>
                        <div style={{padding:'32px'}}>
                            <div  id="profit-loss-statement-pdf" style={{overflow: "hidden"}}>
                                <HospitalAddress/>
                                <table className="table table-sm table-borderless prescription-table">
                                    <tbody>
                                        <tr>
                                            <td align='center'><h5><strong>Balance Sheet</strong></h5></td>
                                        </tr>
                                        <tr>
                                            <td align='center'><h6><strong> As of {balance_sheet_period}</strong></h6></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <BalanceSheetTreeView  {...{ data:balanceSheet, reports:true,header:tableHeader, footer:tableFooter, start_date:from_date, end_date:to_date}}/>
                                <p style={{textAlign: "center", fontStyle: "italic"}}>Powered by Mara Scientific</p>

                            </div>

                        </div>
                    </Card>: <NoTransaction text='transactions'/>: null}
                {isError ? <div>The server did not return a valid response</div>:null}
                <div style={{display:'none'}}>
                    {printDoc}
                </div>
            </PLContainer>

            {/* </Container> */}
        </div>
    );
}

function mapStateToProps(state) {
    const {snackbar} = state;
    return {snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(BalanceSheet);
