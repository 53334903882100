import {useEffect, useRef, useState} from "react";
import axios from "axios";
import {config} from "../../../../Helpers/env";
import {logoutErrorMessage} from "../../../../Helpers/ErrorMessages";


export const useStockAdjustmentDetails = (props) => {
    const [adjustment, setAdjustment] = useState({});
    const [details, setDetails] = useState([]);
    const componentRef = useRef();
    const {stock_adjustment_id} = props.match.params;
    const {actions} = props;

    useEffect(() => {
        const formData = new FormData();
        formData.append('stock_adjustment_id', stock_adjustment_id);
        axios.post(`${config.smsUrl}/cinventory/get_stock_adj_details`, formData).then(res => {
            const data = res.data;
            const all_data = !data ? [] : data;
            const det = all_data[0] ? all_data[0] : {};
            const {first_name, last_name, date_created, stock_type} = det;
            const stock = stock_type ? stock_type : ''
            setAdjustment({adjusted_by:`${first_name ? first_name :''} ${last_name ? last_name : ''}`,
            date:date_created ? date_created : '', stock_type:stock === 0 ? 'Available Stock':'Old Stock'});
            setDetails(all_data);
        }).catch(e => {
            logoutErrorMessage(e, null, actions)
        })
    }, []);


    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    return {adjustment, componentRef, closeSnackbar, details}
}