import React, {Fragment} from 'react';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import {formatDigits} from "../../Utils/formatNumbers";
import {history} from '../../../Helpers/history';
import TableRow from "@material-ui/core/TableRow";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import {
    BalanceSheetTableCustomCell,
    MinusSquare,
    PlusSquare,
    StyledTreeItem,
    useStyles
} from "../ChartofAccounts/TreeViewComponent"
import {TableFooter} from "@material-ui/core";


export const formatTreeData = (data={}) =>{
    let arr = []
    for (let item in data){
        let obj = {
            account_name: item,
            // head_level:-1,
            id:item,
            ...data[item]
        }
        if (!(obj.account_name === 'previous_total' || obj.account_name === 'current_total' || item ==='sub_categories')){
            arr.push(obj)
        }

    }
    return arr
}

function BalanceSheetTreeView({children, start_date, end_date, header=null,footer=null,data=[] }) {

    const tree_label = (str_type, nav, isheadLevel, padding, isParent) => {

        if (str_type === 'final_level') {
            return (
                <TableRow>
                    <BalanceSheetTableCustomCell data-testid={nav.account_name}
                        onClick={()=>history.push(`/view_transactions_details/${nav.account_id}/${nav.account_name}/${start_date}/${end_date}`)}
                                         style={{cursor:'pointer',paddingLeft:!isParent ? '60px' : ''}}>
                        <span
                            style={{color: '#0065ff',   display:'inline-block'}}
                        >{nav.account_name || nav?.total?.name}</span></BalanceSheetTableCustomCell>
                    {!nav?.total?.name ? <>
                        <BalanceSheetTableCustomCell data-testid={`${nav.account_name}-prev_total`} align="center"><strong>{ nav.previous_total ? formatDigits( nav.previous_total) : nav.previous_total}</strong></BalanceSheetTableCustomCell>
                        <BalanceSheetTableCustomCell data-testid={`${nav.account_name}-current_total`} align="center"><strong>{ nav.current_total ? formatDigits( nav.current_total) : nav.current_total}</strong></BalanceSheetTableCustomCell>
                    </>: null}

                </TableRow>

            )
        } else if (str_type === 'current_parent') {
            return (
                <TableRow>
                    {nav.account_id ?   <BalanceSheetTableCustomCell data-testid={nav.account_name}
                        onClick={()=>history.push(`/view_transactions_details/${nav.account_id}/${nav.account_name}/${start_date}/${end_date}`)}
                                                             style={{cursor:'pointer',paddingLeft:'60px'}}>
                        <span
                            style={{color: '#0065ff',   display:'inline-block'}}
                        >{nav.account_name || nav?.total?.name}</span>
                    </BalanceSheetTableCustomCell>: <BalanceSheetTableCustomCell data-testid={nav.account_name}  style={{fontWeight : isParent ? 'bold':'',paddingLeft: !isParent ? '30px' : ''}}>
                        <span style={{fontWeight : isParent ? 'bold':''}}>{nav.account_name || nav?.total?.name}</span>
                    </BalanceSheetTableCustomCell>}
                    {!nav?.total?.name ? <>
                        <BalanceSheetTableCustomCell data-testid={`${nav.account_name}-prev_total`} align="center"><strong>{ nav.previous_total ? formatDigits( nav.previous_total) : nav.previous_total}</strong></BalanceSheetTableCustomCell>
                        <BalanceSheetTableCustomCell data-testid={`${nav.account_name}-current_total`} align="center"><strong>{ nav.current_total ? formatDigits( nav.current_total) : nav.current_total}</strong></BalanceSheetTableCustomCell>
                    </>: null}
                </TableRow>
            )
        } else {
            return (
                    <TableRow>
                        <BalanceSheetTableCustomCell data-testid={nav.account_name} style={{fontWeight : isParent ? 'bold':'', paddingLeft:!(isheadLevel || isParent) ? '30px':''}} >{nav.account_name || nav?.total?.name}</BalanceSheetTableCustomCell>
                        {!nav?.total?.name ? <>
                            <BalanceSheetTableCustomCell data-testid={`${nav.account_name}-prev_total`} align="center"><strong>{ nav.previous_total ? formatDigits( nav.previous_total) : nav.previous_total}</strong></BalanceSheetTableCustomCell>
                            <BalanceSheetTableCustomCell data-testid={`${nav.account_name}-current_total`} align="center"><strong>{ nav.current_total ? formatDigits( nav.current_total) : nav.current_total}</strong></BalanceSheetTableCustomCell>
                        </>: null}
                    </TableRow>
            )
        }
    }




    function nestedMenu(items) {
        return items.map((nav) => {
            const head_level = nav.head_level ? nav.head_level : 0;
            const padding  = head_level === 0 ? 4 :  head_level * 8;
            const isTotal = nav?.account_name?.toLowerCase()?.includes('total') || nav?.total?.name?.toLowerCase()?.includes('total')
            const isParent = !!head_level || isTotal;
            const isheadLevel = head_level > 0;
            const nav_children = Array.isArray(nav.accounts) ? nav.accounts : formatTreeData(nav.accounts)
            if (nav_children && nav_children.length === 0) {
                return (
                    <>
                        {nav.is_final_level ?
                            tree_label('final_level', nav, isheadLevel, padding, isParent)
                            :
                            tree_label('current_parent', nav, isheadLevel, padding, isParent)
                        }
                    </>
                )
            }
            return (
                <>
                    {tree_label('parent', nav, isheadLevel, padding, isParent)}
                    {nestedMenu(nav_children)}

                </>
            )
        })
    }

    return (
        <Table>
            <TableHead>
                {header}
            </TableHead>
            <TableBody>
                {nestedMenu(data)}
                {children}
            </TableBody>
            <TableHead>
                {footer}
            </TableHead>
        </Table>
    );
}
export {BalanceSheetTreeView}
