/** @jsxImportSource @emotion/react */
import { css} from '@emotion/react'
import React from "react";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PageTitle from "../../Utils/smsTitle";
import Card from "@material-ui/core/Card";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../../actions/snackbarActions"
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {faShippingFast} from "@fortawesome/free-solid-svg-icons/faShippingFast";
import {useDispatchNote} from "./hooks/useDispatchNote";
import {formatDate} from "../../Utils/ConvertDate";
import {usePrint} from "../../Utils/Templates/usePrint";
import {ItemsTable, PrintTemplate} from "../../Utils/Templates/PrintTemplate";
import {Button, MiniCenteredDiv, RightAlignedContainer} from "../../Utils/styledComponents";
import {BackButton} from "../../Utils/Buttons/DataExportationButton";
import styled from "@emotion/styled/macro";


const textWeight = css`
    font-weight:600;
`


const StyledContainer = styled(RightAlignedContainer)({
    marginBottom:'5px'
})


export function DispatchNote(props) {
    const {closeSnackbar, products, state} = useDispatchNote(props)
    const {openBar, type, message} = props.snackbars;

    const {componentRef, handlePrint,fontSize}  = usePrint(`Dispatch Note`)

    const headData = [{key:'product',value:'Product'},{key:'batch_id',value:'Batch#'}, {key:'quantity',value:'Quantity'},]

    const data = (
        <tbody>
        <tr>
            <td><span>Date: </span> <span css={[textWeight]}>{state?.date_dispacthed ? formatDate(state?.date_dispacthed): null}</span></td>
        </tr>
        <tr>
            <td><span>From: </span> <span><strong>{state?.from_store}</strong></span></td>
        </tr>
        <tr>
            <td><span>To: </span> <span css={[textWeight]}>{state?.to_store}</span></td>
        </tr>
        <tr>
            <td><span>Dispatched by: </span> <span css={[textWeight]}>{state?.dispatched_by}</span></td>
        </tr>
        </tbody>
    )

    const itemDetails = (
        <ItemsTable {...{headData}}>
            <tbody>
            {products.map((item, index) => {
                return (
                    <tr key={index}>
                        <td><span>{item.product_name}</span></td>
                        <td><span>{item.batch_id}</span></td>
                        <td><span>{item.quantity}</span></td>
                    </tr>
                )
            })}
            </tbody>
        </ItemsTable>
    )

    return (
        <div data-testid="dispatch-note">
            <PageTitle title="Dispatch Note"/>
            <SubHeader title="Inventory" subTitle="Dispatch note">
                <FontAwesomeIcon icon={faShippingFast}/>
            </SubHeader>
            <MiniCenteredDiv>
                <MainSnackbar variant={type} handleCloseBar={closeSnackbar} open={openBar} message={message}/>
                <BackButton to='/dispatchlist/new' text='Dispatch  List' data-testid="dispatch-list-back-button"/>
                <StyledContainer>
                    <Button variant='orange' onClick={handlePrint}>Print</Button>
                </StyledContainer>
                <Card>
                    <div ref={componentRef} style={{padding:'60px'}} className="requisition-receipt">
                        <style>
                            {fontSize()}
                        </style>
                        <PrintTemplate title='Dispatch Note' {...{patientDetails:data,itemDetails}}/>
                    </div>
                </Card>
            </MiniCenteredDiv>
        </div>
    )

}

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(DispatchNote);
