import { useState} from "react";
import {dateConvert} from "../../../Utils/ConvertDate";
import {titleCase} from "../../../Users/addUser";
import axios from "axios";
import {config} from "../../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import {history} from "../../../../Helpers/history";
import { v4 as uuidv4 } from 'uuid';

export const useAddRequisition = (actions, component='') => {
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const requested_by = `${!_token.first_name ? "" : _token.first_name} ${!_token.last_name ? "" : _token.last_name}`;
    const user_name = !_token.user_name ? "" : _token.user_name;
    const user_roles_id = !_token.user_roles_id ? 1 : _token.user_roles_id;
    const initialState = [{product_name: '', product_id: '', available_quantity: '', generic_name: '',
    quantity: '', showProduct: false, isError:false, uuid: uuidv4(), item_quantity:'0', system_stock:0}];
    const [products, setProducts] = useState(initialState);
    const [items, setItems] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [state, setState] = useState({date_requested: dateConvert(), date_needed: '', contact: '',
        remarks: '', email: ''
    })
    const [openDialog, setDialog] = useState(false);
    const [requisition_id, setId] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [openEmmergency, setOpenEmmergency] = useState(false)

    const to_store = {
        "ward":"",
        "pharmacy":"Pharmacy",
        "lab":"Labs",
        "patient_emergency":""
    }

    const retrieveProducts = (eventTxt, idx) => {

        const productCopy = [...products];

        if (productCopy[idx]) {
            productCopy[idx].product_name = eventTxt;
            const formData = new FormData();
            formData.append('product_name', titleCase(eventTxt));
            formData.append('to_store', to_store[component])
            if (eventTxt !== '') {
                axios.post(`${config.smsUrl}/cinventory/product_search_by_name`, formData).then(res => {
                    const resp = res.data;
                    const data = !resp ? [] : resp;
                    if (resp === "No Medicine Found") {
                        const val = [{value: "", label: "No Medicine Found"}]
                        setItems(val)
                    } else {
                        setItems(data);
                    }
                }).catch(error => {
                    logoutErrorMessage(error, null, actions)
                });
            }
            productCopy[idx].showProduct = true;
            productCopy[idx].isError = false;
        } else {
            productCopy[idx].isError = false;
            productCopy[idx].showProduct = false;
        }

        setProducts(productCopy)
    };

    const clickProduct = (event, idx) => {
        const productCopy = [...products];
        const formData = new FormData();
        const isWard = component ==='ward' ? 'Ward' :
            component === 'pharmacy' ? 'Pharmacy' :
            component === 'lab' ? 'Labs' :
            component === 'patient_emergency' ? 'Patient Emergency' : '';
        formData.append('product_id', event.value);
        formData.append('location', 1); // 1 for store
        formData.append('to_store', isWard)
        if (productCopy[idx]) {
            productCopy[idx].product_name = event.innerText;
            productCopy[idx].product_id = event.value;
            productCopy[idx].generic_name = event.generic_name;
            if (event.value) {
                axios.post(`${config.smsUrl}/cdoctor/get_medicinedetails_byproduct_id`, formData).then(res => {
                    const resp = res.data;
                    const data = !resp ? {} : resp;
                    const {quantity, stock, category_name} = data;
                    productCopy[idx].available_quantity = stock;
                    productCopy[idx].category_name = category_name;
                    productCopy[idx].item_quantity = quantity.toString();
                    productCopy[idx].system_stock = quantity;
                    setProducts(productCopy)
                }).catch(error => {
                    logoutErrorMessage(error, null, actions)
                });
                productCopy[idx].isError = false;
            }else{
                productCopy[idx].isError = true;
            }
            productCopy[idx].showProduct = false;
        }
        // setProducts(productCopy)
    };

    const handleChangeItems = (event, index) => {
        const {value, name} = event.target;
        const arr = products.map((item,idx)=>{
            if (index === idx){
                return {...item,[name]:value}
            }
             return item
        })
        setProducts(arr)
    };


    const handleAddRow = () => {
        setProducts([...products, ...initialState])
    };

    const removeRow = (item_uuid) => {
        const new_arr = products.filter(e => e.uuid !== item_uuid);
        setProducts(new_arr);
    };


    const handleCloseDialog = () => {
        setDialog(false)
    }


    const handleSubmit = (event) => {
        event.preventDefault();
        const {remarks} = state;
        setSubmitted(true);
        const productIds = products.filter(drug=>drug.product_id && drug.quantity);
        const arr = products.every(drug=>drug.product_id  && drug.quantity && drug.available_quantity !== 0  )
        const isSundry = products.every(drug=>(drug.category_name === "Sundries" && drug.item_quantity=== '0') ||
            (drug.category_name !== "Sundries"))
        const isWard = component ==='ward' ? 'Ward' :
            component === 'pharmacy' ? 'Pharmacy' :
            component === 'lab' ? 'Labs' :
            component === 'patient_emergency' ? 'Patient Emergency' : '';
        if (arr && isSundry) {
            setIsSubmitted('pending');
            const jsonData = {
                user_roles_id, from_department:isWard, store_id:1,
                remarks, products: productIds.map(item=>({
                    product_id:item.product_id,
                    quantity:+item.quantity,
                    main_store_stock:+item.available_quantity,
                    depart_store_stock:item.system_stock,
                    physical_stock:+item.item_quantity
                    
                }))
            }
            axios.post(`${config.smsUrl}/cinventory/make_requisition`, jsonData).then((res) => {
                const data = res.data;
            
                const dt = !data.data ? {} : data.data;
                const _id = !dt.requisition_id ? '' : dt.requisition_id;
                setId(_id)
                setDialog(true);
                setOpenEmmergency(false)
                actions.snackbarActions.snackSuccess('Requisition saved successfully');
                setIsSubmitted('resolved');
                const arr = products.map(item=>({
                    ...item,product_name: '', product_id: '', available_quantity: '',
                    quantity: '', showProduct: false, isError:false
                }))
                setProducts(arr)
                setSubmitted(false)
            }).catch(err => {
                setIsSubmitted('rejected');
                errorMessages(err, null, actions);
            })
        }
    };

    const handleClick = () => {
        history.push(`/viewrequisition/${component}/${requisition_id}`)
    }

    const handleCancel = () => {
        setDialog(false)
        setProducts([{product_name: '', product_id: '', available_quantity: '',
            quantity: '', showProduct: false}]);
        setState({date_requested: dateConvert(), date_needed: '', contact: '', remarks: ''});
        setSubmitted(false)
    }
    const closeSnackBar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const isPending = isSubmitted === 'pending';
    const isResolved = isSubmitted === 'resolved';
    const isRejected = isSubmitted === 'rejected';

    const requisitionProps = {products, items, state, requested_by, retrieveProducts, clickProduct, handleChangeItems, 
        handleAddRow,removeRow, handleSubmit,  user_name, submitted,  isPending}

    return {requisitionProps, closeSnackBar, openDialog,requisition_id,handleCloseDialog, handleClick,
         handleCancel, submitted, isRejected, isResolved, openEmmergency, setOpenEmmergency
    }
}