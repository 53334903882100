import {useEffect, useState} from "react";
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import {useRetrieveVisits} from "../../CustomHooks/useRetrieveVisits"

export const useConsultationList = (actions) => {
    const [ids, setIds] = useState({patient_number: '', visit_id: '',department_id:'', department_name:''
    ,status:0, visit_type:'', 
    patient_name:'', age:'', gender:'',});
    const [openDialog, setOpenDialog] = useState(false);
    const [visitList, setVisitList] = useState({visits: [], total_count: 0});
    const [doctor_name, setDoctor] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [doctors, setDoctors] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [department, setDepartment] = useState('');
    const [openAssign, setOpenAssign] = useState(false)
    const {patient_number, visit_id, visit_type} = ids

    
    const isPending = isSubmitted === 'pending';
    const isResolved = isSubmitted === 'resolved';
    const isRejected = isSubmitted === 'reject';

    const {searchValue, data,  activePage,isError, isLoading, isSuccess,
    handleSearch,handleChangeNewPage} =useRetrieveVisits(actions, isResolved, 'cdoctor/doc_waiting_list')
    

    
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = _token.user_roles_id ? _token.user_roles_id : ''
    
    useEffect(()=>{
        const list = !data.waiting_list ? [] : data.waiting_list;
        const count = !data.total_count ? 0 : data.total_count;
        if(!(list && count)){
            return
        }
        const arr = list.map((item, index) => ({
            ...item,
            count: (activePage - 1) * 10 + (index + 1),
            date: item.begin_datetime,
            visit_id: item.visit_id,
            patient_number: item.patient_number,
            patient_name: `${item.first_name ? item.first_name : ''} ${item.last_name ? item.last_name : ''}`,
            doctor_name:`${item.doc_first_name ? item.doc_first_name : ''} ${item.doc_second_name ? item.doc_second_name : ''}`,
            status: item.status
        }))
        setVisitList({visits:arr,total_count:count});

    }, [data])

    useEffect(() => {
        axios.get(`${config.smsUrl}/cvisit/retrieve_department`).then(res => {
            const data = res.data;
            const dt = !data ? [] : data;
            const departs = !dt.departments ? [] : dt.departments;
            const arr = departs.map(department => ({value: department.department_id,
                label: department.department_name
            }))
            setDepartments(arr)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, [])

    const retrieveDoctors = (d_id) =>{
        const formData = new FormData();
        formData.append('department_id', d_id);
        axios.post(`${config.smsUrl}/cvisit/getdoctorsbydepartment_id`, formData).then(res => {
            const data = res.data;
            const dt = !data ? [] : data;
            const doctors = !dt.doctors ? [] : dt.doctors;
            const arr = doctors.map(doctor => ({value: doctor.user_roles_id,
                label: `${doctor.first_name} ${doctor.last_name}`
            }))
            setDoctors(arr)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }

    const handleChangeDepartment = (value) =>{
        setDepartment(value)
        retrieveDoctors(value.value)
    }


    const handleOpenAssign = (item) => {
        setOpenAssign(true)
        const {visit_id, patient_number, department_id, department_name, status, patient_name, age, gender} = item
        setIds({visit_id, patient_number, department_id, department_name,status, patient_name, age, gender});
        setDepartment({value:department_id, label:department_name})
    
    };

    const handleCloseAssign = () => {
        setOpenAssign(false)
    };

    const handleChangeDoctor = value => {
        setDoctor(value)
    }

    const assignDoctor = (event) => {
        event.preventDefault();
        const formData = new FormData();
        const {visit_id} = ids;
        // formData.append('patient_number', patient_number);
        formData.append('visit_id', visit_id);
        formData.append('user_roles_id', doctor_name.value);
        setSubmitted(true)
        if (doctor_name) {
            setIsSubmitted('pending')
            axios.post(`${config.smsUrl}/cvisit/reassign_patient`, formData).then(() => {
                actions.snackbarActions.snackSuccess('Patient reassigned successfully');
                setIsSubmitted('resolved')
                setOpenAssign(false)
            }).catch(e => {
                errorMessages(e, null, actions);
                setIsSubmitted('rejected')
                setOpenAssign(false)
            });
           
        }
    };

    const closeSnackBar = () => {
        actions.snackbarActions.hideSnackbar()
    };


    return {searchValue, activePage, handleSearch, handleChangeNewPage, closeSnackBar, 
        visitList, submitted, isSubmitted,  handleChangeDoctor,
        assignDoctor, doctor_name, doctors, department, departments,handleChangeDepartment,
        ids, isPending, isRejected, isResolved,openAssign,handleOpenAssign, handleCloseAssign,isLoading, isSuccess, isError
    }
}