import React from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Grid from '@material-ui/core/Grid';
import {useAccountingStyles} from "../Accounting/Expenses/hooks/useAccountingStyles";
import Label from "../Utils/FormInputs/Label";
import TextField from "../Utils/FormInputs/TextField";
import {ErrorMessage} from "../Utils/styledComponents";
import {useExcelReports, usePdfDownload} from "../Utils/ReusableComponents/useExcelExport";
import {DataExportButton} from "../Utils/Buttons/DataExportationButton";


export const ReportDateFilter = ({handleSubmit, title,children, start_date, end_date, handleChange,isPending, submitted, excelUrl,
                                     file_name, actions, pdfUrl,  excelParams={},pdfParams={}}) => {
    const classes = useAccountingStyles();


    const commonParams = {start_date , end_date}

    const fileName = `${start_date} to ${end_date} ${file_name}`

    const excelObj = {file_name:fileName,params:{...commonParams, ...excelParams}, url:excelUrl}

    const pdfObj = {file_name:file_name,params:{...commonParams, ...pdfParams}, url:pdfUrl}


    const {exportExcel, isLoading} = useExcelReports(actions,excelObj)
    const {exportPdf, isLoading:isLoadingPdf} = usePdfDownload(actions,pdfObj)
    return (
        <div>
            {children}
            <form onSubmit={handleSubmit}>
                <Grid container spacing={0}>
                    <Grid item xs={12} md={8} lg={8}>
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={5} xl={5} style={{paddingLeft:0}}>
                                <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={2} lg={2} xl={2} style={{paddingLeft:0}}>
                                        <Label name='From' type/>
                                    </Col>
                                    <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                                        <TextField type='date'
                                                   onChange={handleChange}
                                                   value={start_date} name='start_date'/>
                                        {submitted && !start_date && (
                                            <ErrorMessage>Start date is required</ErrorMessage>
                                        )}
                                    </Col>
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={5} xl={5} style={{paddingLeft:0}}>
                                <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={2} lg={2} xl={2} style={{paddingLeft:0}}>
                                        <Label name='To'  type/>
                                    </Col>
                                    <Col xs={12} sm={12} md={8} lg={8} xl={8}>

                                        <TextField type='date' value={end_date} onChange={handleChange}
                                                   name='end_date'/>
                                        {submitted && !end_date && (
                                            <ErrorMessage>End date is required</ErrorMessage>
                                        )}
                                    </Col>
                                </Form.Group>
                            </Col>
                            <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                                <button type='submit' disabled={isPending}
                                        className="btn sms-btn-outline btn-sm px-2 btn-sm mt-2">{isPending ? 'Filtering...' : 'Filter'}</button>
                            </Col>

                        </Form.Group>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4} classes={{root: classes.item}}>
                        <div className='export-dropdown'>
                            <DataExportButton isLoading={isLoading || isLoadingPdf}  title={title} downLoadPdf={exportPdf} exportCsv={exportExcel}/>
                        </div>
                    </Grid>
                </Grid>
            </form>
        </div>

    )
}