import {useEffect, useState} from "react";
import axios from "axios";
import { config } from "../../../../Helpers/env";
import { logoutErrorMessage } from "../../../../Helpers/ErrorMessages";
import { useDates } from "../../../Accounting/CustomHooks/useDates";
import {dateConvert} from "../../../Utils/ConvertDate";

export const useReferralReports = (actions, referral_type ) => {

    // get the current user's details
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = _token.user_roles_id ? _token.user_roles_id : '';

    const [patientDetails, setIds] = useState({patient_number: '', referral_date: '',hospital_from:'', kin_name:'',
      patient_name:'', phone_no:'', patient_type: 1});
    const [referralList, setReferralList] = useState({referrals: [], total_count: 0});


    const [date, setDate] = useState({start_date:dateConvert(), end_date:dateConvert()})
    const [searchValue, setSearchValue] = useState('');
    const [status, setStatus] = useState('idle')
    const [activePage, setActivePage] = useState(1);
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted]  = useState('idle')
    const [payment, setPayment] = useState({value:1,label:'Cash'})
    const [patientType, setPatientType] = useState({value:1,label:'Out-patient'})
    const [providers, setProviders] = useState([])
    const [provider, setProvider] = useState({value:'', label:''})

    const {start_date, end_date} = date
    

    const isLoading = status === 'pending';
    const isSuccess = status === 'success';
    const isError = status === 'error';
    const isPending = isSubmitted === 'pending';



    const handleReferralList = () => {
        const formData = new FormData();
        formData.append('patient_type',patientType?.value ? patientType?.value :'');
        formData.append('mode_of_payment', payment?.value ? payment?.value :'');
        formData.append('search', searchValue);
        formData.append('page', activePage);
        formData.append('referral_type',referral_type)
        formData.append('start_date',start_date)
        formData.append('end_date',end_date)
        formData.append('provider_id',provider?.value)
        setSubmitted(true);
        setStatus('pending');
        setIsSubmitted('pending')
        axios.post(`${config.smsUrl}/cmedicationround/referral_list/${activePage}`, formData).then(res => {
            const dt = res.data?? {};
            const dt_cnt = dt.total_count?? 0;
            const r_list = dt.referral ?? []
            setStatus('success');
            setSubmitted(false);
            setIsSubmitted('resolved')
            setReferralList({referrals:r_list,total_count:dt_cnt});
        }).catch(e => {
            logoutErrorMessage(e, null, actions);
            setStatus('error')
            setIsSubmitted('idle')
        })
    }

    const handleDateFilterSubmit = (event) => {
        event.preventDefault();
        handleReferralList();
    }

    const handleChange = (event) =>{
        // const {name, value} = event.target;
        setPatientType(event.target.value)
    }


    useEffect(() => {
        if (payment.label === 'All' || payment.label === 'Cash'){
            return
        }
        axios.get(`${config.smsUrl}/cbilling/insurance_providers/list`).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const arr = !dt.data ? [] : dt.data;
            const list = arr.filter(item=>item.provider_type === payment.label).map(item=>({
                value:item.provider_id,
                label:item.provider_name
            }))
            setProviders(list);
        }).catch(err => {
            logoutErrorMessage(err,null,actions)
        })

    }, [payment.label]);

    const isCash = payment.label === 'All' || payment.label === 'Cash'

    // call to get the referral list
    useEffect(() => {
        handleReferralList();
    },[isCash,patientType, activePage, searchValue, provider]);


    const handleSearch = (event) => {
        setSearchValue(event.target.value);
    };

    const handleChangeNewPage = (page) => {
        setActivePage(page);
    };

    const handlePatientDetails = (item) => {
        setIds(item);
    }

    const handleChangeDate = (event) =>{
        const {name, value} = event.target
        setDate({...date,[name]:value})
    }

    const closeSnackBar = () => {
        actions.snackbarActions.hideSnackbar()
    };


    return {searchValue, activePage, handleSearch, handleChangeNewPage, closeSnackBar, user_roles_id,
        referralList, actions, _token, date, handleChangeDate, handleChange,
        patientDetails, isLoading, isSuccess, isError, handlePatientDetails, submitted,
        handleDateFilterSubmit, patientType, payment, setPayment, setPatientType, isPending,
    provider, providers, setProvider}
}