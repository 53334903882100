import React, {useState, useEffect} from "react";
import Form from "react-bootstrap/Form";
import {useTheme} from "@material-ui/core/styles";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import { TableHead } from "../Utils/FormInputs/Label";
import { useSurgeryEquipment } from "./hooks/useSurgeryEquipment";
import * as snackbarActions from "../../actions/snackbarActions"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import ReusableTabs, {TabPanel} from "../Utils/Dialogs/ReusableTabs";
import { useRef } from "react";
import { usePatientDetails } from "../DoctorsModule/ReferralNote/usePatientDetails";
import SmsSnackbar from "../Utils/Snackbar/SmsSnackbar";
import ReactToPrint from "react-to-print";
import { Card } from "@material-ui/core";
import { titleCase } from "../Users/addUser";
import { NoTransaction } from "../MedicationandVisits/TreatmentHistory";
import axios from "axios";
import { config } from "../../Helpers/env";
import {errorMessages} from "../../Helpers/ErrorMessages";

const PreviousEquipment = ({actions, snackbar,match, isResolved=''}) => {
    const { patient_number, visit_id} = match.params

    const printRef = useRef()
    const {openBar, message, type} = snackbar
    const {patient} = usePatientDetails(actions, patient_number, visit_id);
    const {first_name, last_name} = patient


    const [equipmentHistory, setEquipmentHistory] = useState([])
    const [loading, setLoading] = useState('idle')

    useEffect(()=>{
        const formData = new FormData();
        formData.append('visit_id', visit_id)
        //formData.append('surgery_id', patient_surgery_id)
        setLoading('pending')
        axios.post(`${config.smsUrl}/surgery/view_surgery_materials`,formData).then(res=>{
            const data = res.data;
            const dt = data ?? {};
            const arr = dt ? dt['materials_used'] : [];
            setEquipmentHistory(arr);
            setLoading('resolved')
        }).catch(err=>{
            errorMessages(err, null, actions)
            setLoading('rejected')
        })
    },[isResolved]);

    const closeSnackbar = () =>{
        actions.snackbarActions.hideSnackbar()
    }

     
    return (
        <div className='mt-4'>
            <SmsSnackbar open={openBar} message={message} variant={type} handleCloseBar={closeSnackbar}/>
            {loading === 'pending' ? <div>Loading data....</div> :
                loading === 'resolved' && equipmentHistory.length > 0 ?
                    <div>
                        <div className='text-right'>
                            <ReactToPrint trigger={() =>
                                <button className="btn btn-sm sms-amber-btn">Print</button>} content={() => printRef.current}/>
                        </div>
                        <div ref={printRef} id="treatment-print">
                            <Card className="general-card p-3">
                                <table className='table table-sm table-borderless prescription-table'>
                                    <tr>
                                        <td colSpan={2} align='center'><h6>TREATMENT CHART</h6></td>
                                    </tr>
                                    <tr>
                                        <td/>
                                        <td align='right'><p>Patient No.: <strong>{patient_number}</strong></p></td>
                                    </tr>
                                    <tr>
                                        <td/>
                                        <td align='right'><p>Patient Name: <strong>{titleCase(`${first_name ? first_name : ''} ${last_name ? last_name : ''}`)}</strong></p>
                                        </td>
                                    </tr>
                                </table>
                                <table
                                    className="table table-bordered prescription-table profile-table bill-receipt-table">
                                    <thead>
                                    <tr>
                                        <td><p><strong>Drug</strong></p></td>
                                        <td><p><strong>Dosage</strong></p></td>
                                        <td><p><strong>Quantity</strong></p></td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {equipmentHistory.filter(item => item.patient_medication_detail_id).map((item,) => (
                                        <tr key={item.patient_medication_detail_id}>
                                            <td>
                                                <p>{item.product_name}</p>
                                            </td>
                                            <td>
                                                <p>{item.dosage}</p>
                                            </td>
                                            <td>
                                                <p>{item.quantity}</p>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </Card>
                        </div>
                    </div> : <NoTransaction text='No previous treatment records found'/>}
        </div>
    );
}


const EquipmentTable = ({equipProps}) => {
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;

    const {
        equipment, handleAddRow, deleteRow, handleChangeItems, submitted, handleChange,
        supplies, getEquipment, clickEquipment, handleSubmitEquipment,
    } = equipProps;

    const handleChangeSubmit = (event) => {
        handleSubmitEquipment(event);
        handleChange(event, 1);
    }

    return (
        <div className="table-responsive">
            <form onSubmit={handleChangeSubmit} autoComplete="off" data-testid="doc-prescription">
                <table
                    className={`table table-sm table-bordered billing-invoice-table procedure-table ${palette.type === 'dark' && 'dark-table dark-table-bordered'}`}>
                    <thead>
                        <TableHead width="200px" title='Item' toolTip='Enter surgery item' isRequired></TableHead>
                        <TableHead width="200px" title='Dosage' toolTip='Enter dose'></TableHead>
                        <TableHead width="200px" title='Quantity' toolTip='Enter number of items needed' ></TableHead>
                        <th width="80px"/>
                    </thead>
                    
                    <tbody>
                        {equipment.map((item, index) => (
                            <tr key={index}>
                            <td>
                                <Form.Control
                                    type="text" value={item["eqp_name"]} name="eqp_name" onChange={(event) => getEquipment(event, index)}
                                    className={`form__control  drug_name ${palette.type === "dark" && 'form-control-dark'}`}
                                />
                                {item.showEquipment &&
                                    <Form.Control as="select" className="" multiple>
                                        {supplies.map((val, idx) => (
                                            <option key={idx} value={val.value} className="surgery-li"
                                                    onClick={() => clickEquipment({
                                                        value: val.value,
                                                        innerText: val.label
                                                    }, index)}
                                            >
                                                {val.label}
                                            </option>))}
                                    </Form.Control>
                                }
                                {/*(item.status === 0 || item.status === -1) && item["product_id"] ? */}
                                {item.max_quantity !== null && item.max_quantity <= 0 ? <div className="col-lg-4 pl-0">
                                    <span className="help-block" style={{ marginTop: 5, display: 'inline-block' }}>(Out of stock)</span>
                                </div>:null}
                            </td>

                            <td>
                                <Form.Control type="number"  value={item.dosage} name="dosage" onChange={(e) => handleChangeItems(e, index)} min={0}
                                className={`form__control  ${palette.type === "dark" && 'form-control-dark'}`}/>
                            </td>

                            <td>
                                <Form.Control type="number" value={item.quantity}  
                                onChange={(e) => handleChangeItems(e, index)} min={0}
                                className={`form__control  ${palette.type === "dark" && 'form-control-dark'}`} name="quantity"/>
                                {item.quantity > item.max_quantity ? <div className="col-lg-4 pl-0">
                                            <span className="help-block" style={{
                                                marginTop: 5, maxWidth:'100%'}}>{`Quantity more than stock - max quantity: (${item.max_quantity})`}</span>
                                </div>:null}
                                {submitted && item.quantity === 0 && item.product_id ? <div className="col-lg-4 pl-0">
                                            <span className="help-block" style={{
                                                marginTop: 5, maxWidth:'100%'}}>Please add quantity</span>
                                </div>: null}
                            </td>

                            <td>
                                <button type="button" onClick={() => deleteRow(index)} className="btn btn-sm sms-btn-dismiss">
                                    <FontAwesomeIcon icon={faTrash}/>
                                </button>
                            </td>

                            </tr>

                        ))}

                        <tr>
                            <td colSpan={6}>
                                <button type="button" className="btn btn-sm sms-gray-btn" id="add-row" onClick={handleAddRow}>Add another line</button>
                            </td>
                        </tr>               
                    </tbody>
                </table>
                <div className="text-right">
                        <button type="submit" className="btn sms-btn mt-2">Save</button>
                </div>
            </form>
        </div>
    )
}


const SurgeryEquipment = ({actions,snackbar, match}) => {
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];

    const [value, setValue] = useState(0);

    const tabHeading = ['Record Equipment', 'Previous Equipment']

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const {
        equipment, handleAddRow, deleteRow, handleChangeItems, submitted, isResolved,
        supplies, getEquipment, clickEquipment, handleSubmitEquipment, isSubmitted
    } = useSurgeryEquipment(actions, match, _token);


    const equipProps = {
        equipment, handleAddRow, deleteRow, handleChangeItems, submitted, handleChange,
        supplies, getEquipment, clickEquipment, handleSubmitEquipment, isSubmitted
    }

    return (
        <div className='patient-triage mt-2'>

            <ReusableTabs value={value} handleChange={handleChange} tabHeading={tabHeading}>
               <TabPanel value={value} index={0}>
                    <EquipmentTable equipProps={equipProps}/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <PreviousEquipment actions={actions} snackbar={snackbar} match={match} isResolved={isResolved}/>
                </TabPanel>

            </ReusableTabs>

        </div>

    )
};

function mapStateToProps(state) {
    const {snackbar} = state;
    return {snackbar}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(SurgeryEquipment);
