import React from "react";
import {DataCell, ModuleTable} from "../../Utils/Lists/CollapsibleList";
import TableRow from "@material-ui/core/TableRow";
import TextField from "../../Utils/FormInputs/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {StyledCell, StyledRemoveIcon} from "../doctorStyles";
import InputGroup from "react-bootstrap/InputGroup";
import {AutoCompleteUnControlled} from "../../Utils/FormInputs/AutoCompleteInput";
import {ErrorMessage} from "../../Utils/styledComponents";

const headData = [{item:'Procedure', isRequired:true},{item:'Quantity', isRequired:true},{item:'Cost', isRequired:false},{item:'Action', isRequired:false}]

const NewProcedureTable = ({  submitted, all_procedures,getProcedureDetails, handleAddRow,handleChangeProcedureQuantity,
                               removeRow,procedures, retrieveAllProcedures,isEdit=false, isOutPatient=false}) =>{
    return (
        <ModuleTable headData={headData}>
            {procedures.map((test, index) => (
                <TableRow
                    key={index}
                >
                    <DataCell variant='200'>
                        { isEdit && test.procedure_payment_id ? test.service_name :
                            <>

                                <AutoCompleteUnControlled options={all_procedures} submitted={submitted} idx={index} handleChange={retrieveAllProcedures} label_text="label"
                                                          medicine_value={{label:test.service_name, value: test.service_id}} id="service_name" value_text="value"
                                                          valid_error_check={test.isError} errorMsg_Valid="Please a select a valid procedure"
                                                          testId={`procedure_name-${index}`}
                                                          handleProductData={getProcedureDetails} placeholder="Type procedure" errorMsg="procedure is required"/>
                            </> }
                    </DataCell>
                    <DataCell variant='200'>
                        <TextField submitted={submitted}  id='service_quantity'
                                   value={test.quantity} name='quantity'
                                   data-test="service_quantity" onChange={e=>handleChangeProcedureQuantity(e, index)}/>
                        {(submitted && test.quantity) &&
                            <ErrorMessage>Quantity is required</ErrorMessage>}
                    </DataCell>
                    <DataCell variant='150'>
                        <InputGroup>
                            <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">{test.currency_symbol}</InputGroup.Text>
                            <TextField  type="number" value={test.rate} style={{pointerEvents:'none'}}/>
                        </InputGroup>
                    </DataCell>
                    <DataCell>
                        <Tooltip title='Remove'>
                            <IconButton type='button' onClick={() => removeRow(index, test)}>
                                <StyledRemoveIcon/>
                            </IconButton>
                        </Tooltip>
                    </DataCell>
                </TableRow>
            ))}
            {!isOutPatient ? <TableRow>
                <StyledCell colSpan={headData.length}>
                    <button type="button" onClick={handleAddRow} className="btn btn-sm sms-gray-btn"
                            id="add-row">Add another line
                    </button>
                </StyledCell>
            </TableRow> : null}

        </ModuleTable>
    )
}

export {NewProcedureTable}


