import React from 'react';
import { CustomizedTick, CustomTooltip } from './MainPage';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import "react-multi-carousel/lib/styles.css";

const empty_data = [
  {
    month: 'Jan',
    labaratory: 0,
    radiology: 0,
    procedures: 0,
  },
  {
    month: 'Feb',
    labaratory: 0,
    radiology: 0,
    procedures: 0,
  },
  {
    month: 'Mar',
    labaratory: 0,
    radiology: 0,
    procedures: 0,
  },
  {
    month: 'Apr',
    labaratory: 0,
    radiology: 0,
    procedures: 0,
  },
  {
    month: 'May',
    labaratory: 0,
    radiology: 0,
    procedures: 0,
  },
  {
    month: 'Jun',
    labaratory: 0,
    radiology: 0,
    procedures: 0,
  },
  {
    month: 'Jul',
    labaratory: 0,
    radiology: 0,
    procedures: 0,
  },
  {
    month: 'Aug',
    labaratory: 0,
    radiology: 0,
    procedures: 0,
  },
  {
    month: 'Sept',
    labaratory: 0,
    radiology: 0,
    procedures: 0,
  },
  {
    month: 'Oct',
    labaratory: 0,
    radiology: 0,
    procedures: 0,
  },
  {
    month: 'Nov',
    labaratory: 0,
    radiology: 0,
    procedures: 0,
  },
  {
    month: 'Dec',
    labaratory: 0,
    radiology: 0,
    procedures: 0,
  },
];

const TimeToService = ({time_to_service}) => {

    return (
      <div>
        <div>
            <p className="dashboard-card-font-color">Time to Service</p>
            <div className="orange-short-border"></div>
        </div>

        <div style={{marginTop: '5px'}}>
          <ResponsiveContainer width={'99%'} height={300}>
            <BarChart
              width={480}
              height={300}
              data={time_to_service.length > 0 ? time_to_service : empty_data}
              margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 10,
              }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis type="number" domain={['dataMin', 'dataMax']}  tick={<CustomizedTick />} />
                <Tooltip content={<CustomTooltip/>}/>
                <Legend verticalAlign="top" align="right" iconType={'wye'}/>
                <Bar dataKey="labaratory" name="Laboratory" fill="#6665DD"/>
                <Bar dataKey="radiology" name="Radiology" fill="#FF715B" />
                <Bar dataKey="procedures" name="Procedures" fill="#04E762" />
              </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    );

}

export default TimeToService;