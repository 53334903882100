import React from 'react';
import {bindActionCreators} from "redux";
import * as departmentActions from "../../actions/DoctorsActions/departmentActions";
import {connect} from 'react-redux';
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import * as snackbarActions from "../../actions/snackbarActions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {SubHeader} from "../../Containers/SubHeader";
import {faEdit} from "@fortawesome/free-solid-svg-icons/faEdit";
import CustomTable, {useHiddenColumns} from "../Utils/Tables/CustomTable";
import PageTitle from "../Utils/smsTitle";
import {formatDateTime} from "../Utils/ConvertDate";
import {useTriageWaitingList} from "../Visit/CustomHooks/useTriageWaitingList"
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";

const headData = [
    {id: 'count', numeric: false, disablePadding: false, label: '#', hidden:false},
    {id: 'date', numeric: false, disablePadding: false, label: 'Date',hidden:false},
    {id: 'patient_number', numeric: false, disablePadding: false, label: 'Patient No.',hidden:false},
    {id: 'patient_name', numeric: false, disablePadding: false, label: 'Patient Name',hidden:false},
    {id: 'phone_no', numeric: false, disablePadding: false, label: 'Phone',hidden:false},
    {id: 'kin_phone_no', numeric: false, disablePadding: false, label: 'NOK Phone',hidden:false},
    {id: 'action', numeric: false, disablePadding: false, label: 'Action',hidden:false},
];


const TriageWaitingList = ({actions, snackbars}) => {
    const {waitingList,  total_count, term, activePage, handler,
        handleChangeActivePage, handleClickRow, isLoading, isSuccess, isError,
        isPending, closeSnackBar
    } = useTriageWaitingList(actions)
    // const triage_list = !waitingList ? [] : waitingList;

    const triage_list = waitingList.map(item=>({
        ...item,
        date:formatDateTime(item.date),
        action:(
            <button disabled={isPending} onClick={() => handleClickRow(item.activity_id,item.visit_id, item.patient_number)}
                    className="btn btn-sm sms-info-btn">Take Vitals</button>
        )
    }))

    const {
        headCells, all_hidden, handleAllHeadCells, handleHeadCells
    } = useHiddenColumns(headData);

    const {openBar, type, message} = snackbars;
    return (
        <div>
            <PageTitle title="Triage Waiting List"/>
            <SubHeader title="Triage Waiting List" subTitle="Triage waiting list">
                <FontAwesomeIcon icon={faEdit}/>
            </SubHeader>
            <MainSnackbar variant={type} message={message} open={openBar} handleCloseBar={closeSnackBar}/>
            <div className="mui-tables">
                <CustomTable headData={headCells} 
                                handler={handler} data={triage_list}
                                 colSpan={7} term={term}
                                title="" handleChangeNextPage={handleChangeActivePage} records={10}
                                total_count={total_count} activePage={activePage} customPage
                             {...{all_hidden, handleAllHeadCells, handleHeadCells}}>
                        <TableBody>
                            {isLoading ? <TableRow>
                                <TableCell colSpan={headCells.length} align="center"><img src="/images/smsloader.gif"
                                                                            alt="loader"/></TableCell>
                            </TableRow>:null} 
                            {isSuccess ? triage_list.length > 0 ?  triage_list.slice(0, 10)
                                .map((item, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={index}
                                            className='mui-table-row'
                                            >
                                            {headCells.map((head)=>(
                                                <TableCell id={`${labelId} ${head.id}`}  key={head.id}
                                                           hidden={head.hidden}>{item[head.id]}</TableCell>
                                            ))}
                                        </TableRow>
                                    );
                                }): <TableRow>
                                <TableCell colSpan={headCells.length} align="center">No records found</TableCell>
                            </TableRow>:null}
                            {isError ? <TableRow>
                                <TableCell colSpan={headCells.length} align="center">The server didnot return a valid response</TableCell></TableRow>:null}
                        </TableBody>
                </CustomTable>
            </div>
        </div>
    );
    
}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbar,
        departments: state.departments
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch),
            departmentActions: bindActionCreators(departmentActions, dispatch),
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TriageWaitingList);

