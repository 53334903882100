import React from "react";
import PageTitle from "../Utils/smsTitle";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CustomTable from "../Utils/Tables/CustomTable";
import FilterResults from "react-filter-search";
import TableBody from "@material-ui/core/TableBody";
import {getComparator, stableSort} from "../Utils/Tables/sortingFunctions";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {Link} from "react-router-dom";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../actions/snackbarActions";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import {dateStringConvert} from "../Utils/ConvertDate";
import {useReceivedItems} from "./CustomHooks/useReceivedItems";
import {faMortarPestle} from "@fortawesome/free-solid-svg-icons/faMortarPestle";

const headData = [{id: 'sl', numeric: false, disablePadding: false, label: 'SL'},
    {id: 'date', numeric: false, disablePadding: false, label: 'Date Requested'},
    {id: 'requestedBy', numeric: false, disablePadding: false, label: 'Requested By'},
    {id: 'department', numeric: false, disablePadding: false, label: 'From'},
    {id: 'dateIssued', numeric: false, disablePadding: false, label: 'Date Issued'},
    {id: 'issuedBy', numeric: false, disablePadding: false, label: 'Issued By'},
    {id: 'actions', numeric: false, disablePadding: true, label: 'Action'},
];

export const ReceivedItems = (props) => {
    const { order, orderBy, loading, receivedItems, closeSnackbar, handleRequestSort,page, rowsPerPage,
        handleSearch, handleChangePage, handleChangeRowsPerPage,searchValue, handleRefresh} = useReceivedItems(props)
    const {openBar, type, message} = props.snackbars;
    return (
        <div data-testid="received-items">
            <PageTitle title="Received Items"/>
            <SubHeader title="Pharmacy" subTitle="Received items">
                <FontAwesomeIcon icon={faMortarPestle}/>
            </SubHeader>
            <div className="mui-tables">
                <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>
                <div className="text-right">
                    <button onClick={handleRefresh} className="btn btn-sm sms-info-btn mb-1">Refresh</button>
                </div>
                <CustomTable title="Received Items" handleChangePage={handleChangePage}
                             handleChangeRowsPerPage={handleChangeRowsPerPage}
                             headData={headData} handler={handleSearch} page={page} rowsPerPage={rowsPerPage}
                             handleRequestSort={handleRequestSort} data={receivedItems} colSpan={5} order={order}
                             orderBy={orderBy} id="searchValue" filter>
                    {loading ? <TableBody><TableRow>
                        <TableCell colSpan="6" align="center"><img src="/images/smsloader.gif"
                                                                   alt="loader"/></TableCell>
                    </TableRow></TableBody> : receivedItems.length > 0 ?
                        <FilterResults value={searchValue} data={receivedItems}
                                       renderResults={results => (
                                           <TableBody>
                                               {stableSort(results, getComparator(order, orderBy))
                                                   .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                   .map((item, index) => {
                                                       const count = (page * rowsPerPage) + index + 1;
                                                       return (
                                                           <TableRow
                                                               hover
                                                               role="checkbox"
                                                               tabIndex={-1}
                                                               key={item.dispatch_id}
                                                               className='mui-table-row'
                                                               id="services"
                                                           >
                                                               <TableCell>{count}</TableCell>
                                                               <TableCell>{dateStringConvert(item.date_created)}</TableCell>
                                                               <TableCell>{item.requested_by_name}</TableCell>
                                                               <TableCell>{item.store_name}</TableCell>
                                                               <TableCell>{dateStringConvert(item.date_created)}</TableCell>
                                                               <TableCell>{item.recieved_by_name}</TableCell>

                                                               <TableCell>
                                                                   <Link
                                                                       to={{pathname: `/checkitems/${item.dispatch_id}/${item.requisition_id}`}}>
                                                                       <button className="btn btn-sm sms-btn mr-1">
                                                                           Check Items</button>
                                                                   </Link>
                                                               </TableCell>
                                                           </TableRow>
                                                       );
                                                   })}
                                           </TableBody>
                                       )}/>:<TableBody><TableRow>
                            <TableCell colSpan="6" align="center">No records found</TableCell>
                        </TableRow></TableBody>}
                </CustomTable>
            </div>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceivedItems);
