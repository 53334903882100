import React from "react";
import Card from "@material-ui/core/Card";
import FormGroup from "../Utils/FormInputs/FormGroup";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../actions/snackbarActions";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import {useTheme} from "@material-ui/core/styles";
import PageTitle from "../Utils/smsTitle";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWeight} from "@fortawesome/free-solid-svg-icons/faWeight";
import CustomTable from "../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import FilterResults from "react-filter-search";
import {getComparator, stableSort} from "../Utils/Tables/sortingFunctions";
import {Link} from "react-router-dom";
import {useLabTypeList} from "../Lab/CustomHooks/useLabTypeList";
import {useOutPatientTriage} from "./hooks/useOutPatientTriage";
import {useStyles} from "../Settings/UserRolesList";
import ReusableDialog from "../Utils/Dialogs/ReusableDialog";

const headData = [{id: 'sl', numeric: false, disablePadding: false, label: 'Sl'},
    {id: 'name', numeric: false, disablePadding: true, label: 'Metric Name'},
    {id: 'unit', numeric: false, disablePadding: true, label: 'Unit'},
    {id: 'actions', numeric: false, disablePadding: true, label: ''},
];

const TriageMetricList = (props) => {
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    const {actions} = props;
    const {metric_id} = props.match.params
    const classes = useStyles()
    const {
        searchValue, order, orderBy, page, rowsPerPage, handleSearch, handleRequestSort,
        handleChangePage, handleChangeRowsPerPage, closeSnackbar
    } = useLabTypeList(props)
    const {
        allMetrics,
        metric_name,
        uom,
        submitMetric,
        isMetricSubmit,
        handleOpenMetricDialog,
        handleCloseMetricDialog,
        metricDialog,
        handleChangeMetric,
        handleSubmitMetric,
        loading,
        handleDelete
    } = useOutPatientTriage(actions, metric_id, true)
    const {openBar, type, message} = props.snackbars;
    const title = metric_id ? 'Edit metric' : 'Triage Metrics'
    return (
        <div className='journals'>
            <PageTitle title="Triage"/>
            <SubHeader title="Triage Metrics" subTitle={title}>
                <FontAwesomeIcon icon={faWeight}/>
            </SubHeader>
            <div className='general-ledger-header'>
                <div className="text-right">
                    {metric_id ? <Link to='/triagemetrics'><button className='btn btn-sm, sms-btn-outline' type='button'
                    >Metrics</button></Link> : <button className='btn btn-sm, sms-btn-outline' type='button'
                                           onClick={handleOpenMetricDialog}>New Metric</button>}
                </div>
            </div>
            <ReusableDialog openDialog={metricDialog} width='sm' handleClose={handleCloseMetricDialog}
                            handleSubmit={handleSubmitMetric} isSubmitted={isMetricSubmit} message='New Metric'>
                <FormGroup colSm={8} submitted={submitMetric} onChange={handleChangeMetric} id='metric_name' value={metric_name}
                           name='metric_name' type="text" labelName="Name" labelSm={1} labelType/>
                <FormGroup colSm={8} submitted={submitMetric} id='uom' onChange={handleChangeMetric} value={uom}
                           name='uom' type="text" labelName="Unit" labelSm={1} labelType/>
            </ReusableDialog>
            {metric_id ? <div className="add-unit-form my-3">
                <Card className="p-3">
                    <h6 className={`visit-heading ${palette.type === "dark" && "dark-visit-heading"}`}>{`${metric_id ? 'Edit' : 'New'} Metric`}</h6>
                    <MainSnackbar message={message} variant={type} handleCloseBar={closeSnackbar} open={openBar}/>
                    <form onSubmit={handleSubmitMetric} autoComplete="off">
                        <FormGroup colSm={8} submitted={submitMetric} onChange={handleChangeMetric} id='metric_name' value={metric_name}
                                   name='metric_name' type="text" labelName="Metric Name" labelSm={2} labelType/>
                        <FormGroup colSm={8} submitted={submitMetric} onChange={handleChangeMetric} id='uom' value={uom}
                                   name='uom' type="text" labelName="Unit" labelSm={2} labelType/>
                        <div className="text-center mr-5">
                            <button type="submit" className="btn btn-sm sms-btn px-4"
                                    disabled={isMetricSubmit === 'pending' || isMetricSubmit === 'resolved'}
                                    id="submit_unit">
                                {isMetricSubmit === 'pending' ? "Saving..." : "Save"}</button>
                        </div>
                    </form>
                </Card>
            </div> : null}
            <div className="mui-tables mt-3">
                {!metric_id && <CustomTable title="Triage Metrics" handleChangePage={handleChangePage}
                                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                                            headData={headData} handler={handleSearch} page={page}
                                            rowsPerPage={rowsPerPage}
                                            handleRequestSort={handleRequestSort} data={allMetrics} colSpan={5}
                                            order={order}
                                            orderBy={orderBy} id="searchValue" filter>
                    {loading ? <TableBody><TableRow>
                        <TableCell colSpan="6" align="center"><img src="/images/smsloader.gif"
                                                                   alt="loader"/></TableCell>
                    </TableRow></TableBody> : allMetrics.length > 0 ?
                        <FilterResults value={searchValue} data={allMetrics}
                                       renderResults={results => (
                                           <TableBody>
                                               {stableSort(results, getComparator(order, orderBy))
                                                   .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                   .map((item, index) => {
                                                       const count = (page * rowsPerPage) + index + 1;
                                                       return (
                                                           <TableRow
                                                               role="checkbox"
                                                               tabIndex={-1}
                                                               key={item.metric_id}
                                                               className='mui-table-row'
                                                               classes={{selected: classes.selected}}
                                                               selected={item.status === 0}
                                                           >
                                                               <TableCell>{count}</TableCell>
                                                               <TableCell>{item.metric_name}</TableCell>
                                                               <TableCell>{item.uom}</TableCell>
                                                               <TableCell>
                                                                   {item.status === 1 && <Link
                                                                       to={{pathname: `/editmetrics/${item.metric_id}`}}>
                                                                       <button
                                                                           className="btn btn-sm sms-btn   mr-1">Edit
                                                                       </button>
                                                                   </Link>}
                                                                   <button
                                                                       onClick={() => handleDelete(item.metric_id, item.status === 1 ? 0 : 1)}
                                                                       className={`btn btn-sm ${item.status === 1 ? 'sms-grey-btn' : 'sms-info-btn'}`}>
                                                                       {item.status === 1 ? 'Deactivate' : 'Activate'}
                                                                   </button>
                                                               </TableCell>
                                                           </TableRow>
                                                       );
                                                   })}
                                           </TableBody>
                                       )}/> : <TableBody><TableRow>
                            <TableCell colSpan="6" align="center">No records found</TableCell>
                        </TableRow></TableBody>}
                </CustomTable>}
            </div>
        </div>
    )
};

function mapStateToProps(state) {
    return {
        snackbars: state.snackbar
    }
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(TriageMetricList);
