import axios from "axios";
import {useState,useEffect} from "react";
import {useVisitPatientTriage} from "../../Triage/hooks/useVisitPatientTriage";
import { config } from "../../../Helpers/env";

import { errorMessages } from "../../../Helpers/ErrorMessages";
import { useEmergencyTreatment } from "./useEmergencyTreatment";
import { useAttachPatient } from "./useAttachPatient";


function useRecordPatient(actions){
    const [showComponent, setShowComponent] = useState(0);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [submitted, setSubmitted] = useState(false);
    const [visit_id, setVisitId] = useState('')
    const [openAttach, setOpenAttach] = useState(false)
    const {triageProps} = useVisitPatientTriage(actions,{p_number:'', visit_id:'', setShowTriage:'',setOpenDialog:''})
    const {setSubmitted:setSubmitTriage,setWeight, setHeight, setTriageList, 
        setOtherMetric, setOptionalMetrics, setTriage} = triageProps
    const {weight_metric_name, weight_metric_id,height_metric_id,height_metric_name,
        triageList, triage, optionalMetrics,others} = triageProps;

    const {prescriptionProps, setSubmitted:setSubmittedPrescription, setIsSubmitted:setIsSubmitPrescription,
        setPrescription,  initialState} = useEmergencyTreatment(actions)
    const {prescription} = prescriptionProps;

    const {openDialog,handleOpenDialog, handleCloseDialog,savePatientProps,attachPatientProps} = useAttachPatient(actions, visit_id, setOpenAttach)
    const {p_number, patientDetails, setPatientDetails, setPatientNumber} = attachPatientProps
    const {patient_number} = patientDetails
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;

    
    
    const cleareTriage = (arr) =>{
        const listArr = arr.map(item=>({
            ...item, metric_value:''
        }))
        return listArr
    }
    

    const handleSubmitTriage=(event, value)=>{
        event.preventDefault();
        const formData = new FormData();
        setSubmitTriage(true);

        if(weight_metric_name){
            formData.append('metric_id', weight_metric_id);
            formData.append('metric_value',weight_metric_name);
        }
        if(height_metric_name){
            formData.append('metric_id', height_metric_id);
            formData.append('metric_value', height_metric_name);
        }
        triageList.filter(metric => metric.metric_value && metric.metric_id).forEach(metric => {
            formData.append('metric_id', metric.metric_id);
            formData.append('metric_value', metric.metric_value);
        });
        triage.filter(metric => metric.metric_value && metric.metric_id).forEach(metric => {
            formData.append('metric_id', metric.metric_id);
            formData.append('metric_value', metric.metric_value);
        });
        optionalMetrics.filter(metric => metric.metric_value && metric.metric_id).forEach(metric => {
            formData.append('metric_id', metric.metric_id);
            formData.append('metric_value', metric.metric_value);
        });
        others.filter(metric => !metric.metric_value).forEach(metric => {
            formData.append('metric_id', metric.value);
            formData.append('metric_value', metric.metric_value ? metric.metric_value:'');
        });
        formData.append('metric_type_id', 1);
        const arr  = triageList.filter(metric => metric.metric_id !==6);
        const arr1 = triage.every(metric => metric.metric_value)
        if(arr && arr1){
            setIsSubmitted('pending');
            axios.post(`${config.smsUrl}/cvisit/emergency_patient_triage`, formData).then(res =>{

                const data = res.data;
               
                const dt = data ? data : {};
                const v_id = dt.visit_id ? dt.visit_id : ''
                setVisitId(v_id)
                
                actions.snackbarActions.snackSuccess('Triage Information added successful');
                setShowComponent(value);
                setIsSubmitted('resolved');
                setTriageList(cleareTriage(triageList));

                
                setHeight({height_metric_id:1, height_metric_name: ''});
                setWeight({weight_metric_id:2, weight_metric_name: ''})
                
                setOptionalMetrics(cleareTriage(optionalMetrics))
                setTriage(cleareTriage(triage));
                setOtherMetric('');
                setSubmitTriage(false);

            }).catch((err) => {
                errorMessages(err, null, actions)
                setIsSubmitted(('rejected'));
            });
            
        }
       
    };

    const handleSubmitPrescription = (event, value) => {
    
        event.preventDefault();
        const arr = prescription.some(item => item.product_id)
        setSubmittedPrescription(true);
        if (arr) {
            setIsSubmitPrescription('pending');
            const params = {
                bill_type: 3,
                visit_id,
                prescribed_by:user_roles_id,
                bill_details: prescription.filter(item=>Boolean(item)).filter(drug => drug.product_id).map(presc => ({
                    dosage: presc.dosage,
                    rt: presc.route,
                    instructions: presc.comments,
                    product_id: presc.product_id,
                    rate: presc.rate,
                    quantity: +presc.quantity,
                    intake_time:presc.intake_time
                   
                }))
            }
            axios.post(`${config.smsUrl}/cmedicationround/emergency_treatments`, { ...params
            }).then(() => {
                actions.snackbarActions.snackSuccess(`Prescription added successfully`);
                setIsSubmitPrescription('resolved');
                setSubmittedPrescription(false);
                setPrescription(initialState)
                setShowComponent(value)
               
              
            }).catch(err => {
                setIsSubmitPrescription('rejected');
                errorMessages(err, null, actions);

            })
        }
    };

    const handleSubmitPatient=(event, value)=>{
        event.preventDefault();
        const formData = new FormData();
        setSubmitted(true);
        formData.append('visit_id', visit_id);
        formData.append('patient_number', patient_number);
        if(p_number){
            setIsSubmitted('pending');
            axios.post(`${config.smsUrl}/cvisit/update_emergency_visit`, formData).then(res =>{
                actions.snackbarActions.snackSuccess('Patient added successful');
                setShowComponent(value);
                setIsSubmitted('resolved');
                setSubmitted(false); 
                setOpenAttach(false)
                setPatientDetails({first_name: '', last_name: '', age:'', gender:'', patient_number:''})
                setPatientNumber('')
            }).catch((err) => {
                errorMessages(err, null, actions)
                setIsSubmitted(('rejected'));
            });
            
        }
       
    };

    const handleCancel =()=>{
        setShowComponent(0);
        setSubmitted(false);
    }

    const handleCloseSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const isPending = isSubmitted === 'pending'

 return {triageProps,handleSubmitTriage, handleCancel,  showComponent, setShowComponent, prescriptionProps,
handleSubmitPrescription,openDialog,handleOpenDialog, handleCloseDialog,savePatientProps,attachPatientProps,
handleCloseSnackbar, handleSubmitPatient, isPending, openAttach, setOpenAttach, isSubmitted, setVisitId};
}



export {useRecordPatient};