import React from 'react';
import {useObservationChart} from "./CustomHooks/useObservationChart";
import SmsLineChart from '../Main/Charts/LineChart';
import {Legend, Line} from "recharts";
import {CustomizedLabel} from "../Main/Charts/CustomLabels";
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../actions/snackbarActions";
import SmsSnackbar from "../Utils/Snackbar/SmsSnackbar";
import {convertDateStrokeTime, removeGmt} from "../Utils/ConvertDate";
import ReusableAccordion from "../Utils/Dialogs/ReusableAccordion";
import { GetTriageDetails, getMetricValues } from '../DoctorsModule/ReusableTreatmentHistory';

export const ReloadObservationChart = ({   tabular, handleChangeTabular, tableData, graphData,
    expanded,handleChange}) => {

    return (
        <div>
            <div className="text-right mb-1">
                <div className="btn-group">
                    <button onClick={() => handleChangeTabular(1)} type="button"
                            className={`btn btn-sm ${tabular === 1 ? 'active-sms-info-btn' : 'sms-info-btn'}`}>Tabular
                    </button>
                    <button onClick={() => handleChangeTabular(2)} type="button"
                            className={`btn btn-sm ${tabular === 2 ? 'active-sms-info-btn' : 'sms-info-btn'}`}>Graphical
                    </button>
                </div>

            </div>
            {tabular === 1 ? tableData.map((item, index) => (
                <ReusableAccordion key={index} handleChange={handleChange} id={index} expanded={expanded}
                                   title={`Date : ${item.metric_date ? removeGmt(item.metric_date) : item.metric_date}`}>
                    <table className='table table-sm table-bordered profile-table'>
                        <thead>
                        <th><strong>Metric Name</strong></th>
                        <th><strong>Metric Value</strong></th>
                        </thead>
                        <tbody>
                        {item.data.filter(it=>it.metric_value !== '0').map(metric=>(
                            <tr key={metric.metric_id}>
                                <td>{metric.metric_name}</td>
                                <td>{metric.metric_value}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </ReusableAccordion>
            )) : <SmsLineChart dataKey="date"
                               data={graphData}
                               label={{
                                   value: "Metric",
                                   angle: -90,
                                   position: "insideLeft",
                               }}
                               height={350}
                               xlabel={{
                                   value: "Date",
                                   position: "bottom",
                                   offset: 0,
                               }}
                               noTick
            >
                <Legend verticalAlign="top" height={40} align="right"/>
                <Line
                    type="monotone"
                    dataKey="temperature"
                    name="Temperature"
                    stroke="#008000"
                    strokeWidth={2}
                    label={<CustomizedLabel/>}
                    activeDot={{r: 5}}
                />
                <Line
                    type="monotone"
                    dataKey="heart_rate"
                    name="Pulse"
                    stroke="#666600"
                    label={<CustomizedLabel/>}
                    activeDot={{r: 5}}
                />
                {/*<Line*/}
                {/*    type="monotone"*/}
                {/*    dataKey="blood_pressure"*/}
                {/*    name="Blood Pressure"*/}
                {/*    stroke="#c7c700"*/}
                {/*    label={<CustomizedLabel/>}*/}
                {/*    activeDot={{r: 5}}*/}
                {/*/>*/}
            </SmsLineChart>}
        </div>
    )
}


export const ReloadObservationTriageChart = ({tabular, handleChangeTabular, tableData, graphData,
    expanded, handleChange, handleOpenEditTriage=null, isDoctorScreen=false}) => {

    const {metrics_titles, percentage_metrics} = GetTriageDetails(tableData);

    return (
        <div>
            {tableData && tableData.length > 0 ?
                <div className="text-right mb-1">
                    <div className="btn-group">
                        <button onClick={() => handleChangeTabular(1)} type="button"
                                className={`btn btn-sm ${tabular === 1 ? 'active-sms-info-btn' : 'sms-info-btn'}`}>Tabular
                        </button>
                        <button onClick={() => handleChangeTabular(2)} type="button"
                                className={`btn btn-sm ${tabular === 2 ? 'active-sms-info-btn' : 'sms-info-btn'}`}>Graphical
                        </button>
                    </div>
                </div>
            : null}
            {tabular === 1 && tableData && tableData.length > 0 ?
                <div className='table-responsive'>
                    <table className="table table-bordered profile-table mt-1 triage-table"> 
                        <thead>
                            <tr>
                                <td rowSpan={2} style={{textAlign:'center', padding:'2px'}}><strong>Date</strong></td>
                                <td colSpan={metrics_titles.length} style={{textAlign:'center', padding:'2px'}}><strong>Metrics</strong></td>
                                {isDoctorScreen ? 
                                <td rowSpan={2} style={{textAlign:'center', padding:'2px'}}><strong>Action</strong></td> : null
                                }
                            </tr>
                            <tr>
                                {metrics_titles.map((it) => (
                                    <td key={it.metric_id} style={{textAlign:'center', padding:'2px'}}>
                                        <p style={{margin:'0px', fontSize:'12px'}}><strong>{`${it.metric_name} ${it.uom ? `(${it.uom})` : ''}`}</strong></p>
                                    </td>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {tableData.map((element, index) => (
                                <tr key={index}>
                                    <td style={{width:'20%', textAlign:'center', padding:'2px'}}>
                                        <p style={{margin:'0px', fontSize:'12px'}}>
                                        {convertDateStrokeTime(element.metric_date)}
                                        </p>
                                    </td>
                                    {metrics_titles.map((it, idx) => (
                                        getMetricValues(element.data, it.metric_name, percentage_metrics)
                                    ))}
                                    {isDoctorScreen ?
                                    <td style={{textAlign:'center', padding:'2px'}}>
                                        <button type="submit" className="btn btn-sm sms-amber-btn" onClick={()=>handleOpenEditTriage(element)}
                                         style={{marginTop:"5px", marginBottom:"5px"}}>Edit</button>
                                    </td>
                                    : null
                                    }
                                </tr>
                            ))}
                        </tbody>
                    </table> 
                </div>
                : tabular === 2 ?
                <SmsLineChart dataKey="date"
                               data={graphData}
                               label={{
                                   value: "Metric",
                                   angle: -90,
                                   position: "insideLeft",
                               }}
                               height={350}
                               xlabel={{
                                   value: "Date",
                                   position: "bottom",
                                   offset: 0,
                               }}
                               noTick
                >
                <Legend verticalAlign="top" height={40} align="right"/>
                <Line
                    type="monotone"
                    dataKey="temperature"
                    name="Temperature"
                    stroke="#008000"
                    strokeWidth={2}
                    label={<CustomizedLabel/>}
                    activeDot={{r: 5}}
                />
                <Line
                    type="monotone"
                    dataKey="heart_rate"
                    name="Pulse"
                    stroke="#666600"
                    label={<CustomizedLabel/>}
                    activeDot={{r: 5}}
                />
                </SmsLineChart>
            : null}
        </div>
    )
}


const ObservationChart = ({visit_id, actions, snackbar}) => {
    const {tabular, handleChangeTabular, tableData, graphData, closeSnackbar,
    expanded,handleChange} = useObservationChart(actions, visit_id)
    const {openBar, type, message} = snackbar;
    return (
        <div>
        <SmsSnackbar message={message} open={openBar} handleCloseBar={closeSnackbar} variant={type}/>
        <ReloadObservationTriageChart {...{tabular, handleChangeTabular, tableData, graphData, closeSnackbar,
            expanded,handleChange}}/>
        </div>
    )
}

function mapStateToProps(state) {
    const {snackbar} = state
    return {snackbar}

}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(ObservationChart);