import React from "react";
import {Route, Redirect, Link} from "react-router-dom";
import {ErrorBoundary} from 'react-error-boundary';

export function ErrorFallback() {
    const style = {
        container: {
            textAlign: 'center',
            margin: '5% auto',

        },
        heading: {
            fontSize: '180px',
            margin: 0,
            textTransform: 'uppercase',
            fontStyle: 'italic',
            background: '-webkit-linear-gradient(#1fa7b0, #333)',
            '-webkit-background-clip': 'text',
            '-webkit-text-fill-color': 'transparent'
        },
        subheading: {
            textTransform: 'uppercase',
            fontSize: '30px',
            marginTop: '-5%',
            marginBottom: 0,
            color: 'gray'
        },
        text: {
            fontSize: '16px',
            display: 'block',
            color: 'gray',
            marginTop: '20px'
        },
        btn: {
            borderRadius: '50px',
            marginTop: '30px',
            padding: '10px',
            textTransform: 'uppercase'
        },
        link: {
            textDecoration: 'underline'
        }

    }
    return (
        <div role="alert" style={style.container}>
            <p style={style.heading}>OOps!</p>
            <p style={style.subheading}>Something went wrong:</p>
            <span style={style.text}>An error occurred while accessing this page.<br/>
                Please refresh the page and if it persists contact our <Link to='/support' style={style.link}>support team</Link></span>
            <Link to='/'>
                <button className="btn btn-primary" style={style.btn}>GO TO DASHBOARD</button>
            </Link>
        </div>
    )
}

export const PrivateRoute = ({component: Component, ...rest}) => (
    <Route {...rest} render={props => (
        sessionStorage.getItem('user') ?
            <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Component {...props}/>
            </ErrorBoundary> :
            <Redirect to={{pathname: '/login', state: {from: props.location}}}/>

    )}/>
);
