import React, {useEffect, useState} from 'react';
import axios from "axios";
import { config } from "../../../Helpers/env";
import {logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {removeGmt} from "../../Utils/ConvertDate";


export const useObservationChart = (actions, visit_id, isResolved='') => {
    const [tabular, setTabular] = useState(1);
    const [tableData, setTableData] = useState([]);
    const [graphData, setGraphData] = useState([]);
    const [expanded, setExpanded] = React.useState(false);

    useEffect(() => {
     //   if (patient_type !== 2) {return;}
        const formData = new FormData();
        formData.append('visit_id', visit_id)
        axios.post(`${config.smsUrl}/cmedicationround/inpatient_triage_metrics`, formData).then(res => {
            const data = res.data;
            const dt = data ? data : [];
            setTableData(dt)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, [tabular === 1, isResolved])


    useEffect(() => {
        const formData = new FormData();
        formData.append('visit_id', visit_id)
        axios.post(`${config.smsUrl}/cmedicationround/inpatient_triage_details`, formData).then(res => {
            const data = res.data;
            const dt = data ? data : [];
            const arr = dt.map(item => ({
                date: item['date'] ? removeGmt(item['date']) : item['date'],
                blood_pressure: +item['Blood Pressure'],
                heart_rate: +item['Heart Rate'],
                temperature: +item['Temperature']
            }))
            setGraphData(arr)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, [tabular === 2, isResolved])

    const handleChangeTabular = (_id) => {
        setTabular(_id)
    }

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    }

    return {tabular, handleChangeTabular, tableData, graphData, closeSnackbar, expanded, handleChange}
}