import  configData  from './config.json';

// Remove trailing slash from URL
let rootUrl = window.location.protocol + '//' + window.location.hostname;
if (window.location.port) {
    rootUrl += ':' + window.location.port;
}
export const config = {
    smsUrl: configData.smsUrl === "/" ? rootUrl : configData.smsUrl,
    drugsUrl: configData.drugsUrl,
    assetTracker: configData.assetTracker,
    version: configData.version,
};