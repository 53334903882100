import React, {useEffect, useState} from "react";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as snackbarActions from "../../actions/snackbarActions";
import {SubHeader} from "../../Containers/SubHeader";
import PageTitle from "../Utils/smsTitle";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import CustomTable, {useHiddenColumns} from "../Utils/Tables/CustomTable";
import {faPrescriptionBottle} from "@fortawesome/free-solid-svg-icons/faPrescriptionBottle";
import * as prescriptionActions from "../../actions/PrescriptionActions/PrescriptionActions"
import {withTheme} from "@material-ui/core/styles";
import {dateConvert, formatDateTime} from "../Utils/ConvertDate";
import {Container} from "../Utils/styledComponents";
import FillPrescription from "../Prescription/FillPrescription";
import { DateTimeFilter } from "../Utils/ReusableComponents/DateTimeFilter";
import {titleCase} from "../Users/addUser";
import axios from "axios";
import {config} from "../../Helpers/env";
import {logoutErrorMessage} from "../../Helpers/ErrorMessages";

const headData = [
    {id: 'count', numeric: false, disablePadding: false, label: 'SL', hidden:false},
    {id: 'date', numeric: false, disablePadding: false, label: 'Date', hidden:false},
    {id: 'patient_number', numeric: false, disablePadding: false, label: 'Patient No.',hidden:false},
    {id: 'patient_name', numeric: false, disablePadding: false, label: 'Patient Name',hidden:false},
    {id: 'phone', numeric: false, disablePadding: false, label: 'Phone',hidden:false},
    {id: 'kin_phone_no', numeric: false, disablePadding: false, label: 'NOK Phone',hidden:false},
    {id: 'doctor_name', numeric: false, disablePadding: false, label: 'Doctor Name',hidden:false},
    {id: 'action', numeric: false, disablePadding: false, label: 'Action',hidden:false}
];


const OutPatientPrescriptionList = ({actions, snackbars}) => {
    const {openBar, type, message} = snackbars;
    const [term, setTerm] = useState('');
    const [activePage, setActivePage] = useState(1);
    const [prescriptions, setPrescriptions] = useState([]);
    const [status, setStatus] = useState('idle')
    const [total_count, setTotalCount] = useState(0)
    const [state, setState] = useState({start_date:dateConvert(), end_date:dateConvert()})
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle')
    const [prescriptionIds, setPrescriptionIds] = useState({})
    const [openDialog, setOpenDialog] = useState(false)


    const {start_date, end_date} = state;



    /**
     * Retrieve data for a give component when it mounts and when any of the dependencies change
     */
    useEffect(() =>{
        const formData = new FormData();
        const searchValue = term ? titleCase(term) : ''
        formData.append('start_date', start_date);
        formData.append('end_date', end_date);
        formData.append('start', activePage);
        formData.append('search', searchValue);
        setStatus('pending')
        axios.post(`${config.smsUrl}/cpharmacy/previous_filled_prescription_list`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const dataArr = !dt.data ? [] : dt.data;
            const prescription = dataArr.map(item=>({
                ...item, patient_name:`${item.patient_first_name ? item.patient_first_name : ''} ${item.patient_last_name ? item.patient_last_name : ''}`,
                doctor_name: `${item.doctor_first_name ? item.doctor_first_name : ''} ${item.doctor_last_name ? item.doctor_last_name : ''}`
            }))
            const count = !dt.iTotalRecords ? 0 : dt.iTotalRecords;

            setPrescriptions(prescription)
            setTotalCount(count)
            setStatus('success')
        }).catch(err => {
            logoutErrorMessage(err,null,actions)
            setStatus('rejected')
        })
    },[term, activePage])


    /**
     * Onchange handler for searching the table
     * @param event
     */
    const handler = (event) => {
        setTerm(event.target.value)
    };

    /**
     * Changes the page number
     * @param value
     */
    const handleChangeActivePage = (value) => {
        setActivePage(value)
    };

    /**
     * Closes the snackbar
     */
    const closeSnackBar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    /**
     * Onchange for the start and end date
     * @param event
     */
    const handleChange = (event) =>{
        const {name, value} = event.target;
        setState({...state,[name]:value})
    }

    /**
     * Filters the table based on the date filter -  start and end date
     * @param event
     */
    const handleSubmit = (event) =>{
        event.preventDefault();
        setSubmitted(true);
        if(start_date && end_date){
            setStatus('pending');
            setIsSubmitted('pending')
            const formData = new FormData();
            formData.append('start_date', start_date);
            formData.append('end_date', end_date);
            formData.append('start', activePage);
            formData.append('search', term);
            axios.post(`${config.smsUrl}/cpharmacy/previous_filled_prescription_list`, formData).then(res => {
                const data = res.data;
                const dt = !data ? {} : data;
                const dataArr = !dt.data ? [] : dt.data;
                const prescription = dataArr.map(item=>({
                    ...item, patient_name:`${item.patient_first_name ? item.patient_first_name : ''} ${item.patient_last_name ? item.patient_last_name : ''}`,
                    doctor_name: `${item.doctor_first_name ? item.doctor_first_name : ''} ${item.doctor_last_name ? item.doctor_last_name : ''}`
                }))
                const count = !dt.iTotalRecords ? 0 : dt.iTotalRecords;
                setPrescriptions(prescription)
                setTotalCount(count)
                setStatus('success')
                setIsSubmitted('complete')
            }).catch((err) => {
                logoutErrorMessage(err, null, actions);
                setStatus('error')
                setIsSubmitted('error')
            })
        }
    }


    const unfilledPrescription = prescriptions.map((unfilled, index)=>{
        const count = ((activePage - 1) * 10) + index + 1;
        return {
            ...unfilled,
            count,
            date:formatDateTime(unfilled.date_created),
            phone:unfilled.patient_Phone_number,
            kin_phone_no:unfilled.patient_kin_Phone_number,
            action: (
                <button type="button" onClick={() =>{
                    setOpenDialog(true)
                    setPrescriptionIds(unfilled)
                }} className="btn btn-sm sms-info-btn mr-1 mb-2">View</button>
            )
        }
    })

    const isLoading =  status === 'pending';
    const isSuccess = status === 'success';
    const isRejected = status === 'error';

    const isPendingForm =  isSubmitted === 'pending';

    const {
        headCells, all_hidden, handleAllHeadCells, handleHeadCells
    } = useHiddenColumns(headData);

    return (
        <div id="prescription-list" className='journals'>
            <PageTitle title='Outpatient Prescriptions'/>
            <SubHeader title="Pharmacy" subTitle='Outpatient prescriptions'>
                <FontAwesomeIcon icon={faPrescriptionBottle}/>
            </SubHeader>
            <FillPrescription {...{component:'previous',actions, openDialog, setOpenDialog, prescriptionIds,setPrescriptionIds,
                isSubmitted, setIsSubmitted}}/>
            <MainSnackbar handleCloseBar={closeSnackBar} open={openBar} message={message} variant={type}/>
            <Container>
                <DateTimeFilter {...{start_date,end_date, handleChange, submitted,handleSubmit,isPending:isPendingForm, type:'date'}}/>
                <CustomTable headData={headCells} title='' data={unfilledPrescription}
                             customPage handleChangeNextPage={handleChangeActivePage}
                             records={10} total_count={total_count} activePage={activePage} term={term}
                             id='search-prescription' handler={handler} colSpan={headCells.length}
                             {...{all_hidden, handleAllHeadCells, handleHeadCells}}
                >
                    <TableBody>
                        {isLoading ? <TableRow>
                            <TableCell colSpan={headCells.length} align="center"><img src="/images/smsloader.gif"
                                                                                      alt="loader"/></TableCell>
                        </TableRow>:null}
                        {isSuccess ? unfilledPrescription.length > 0 ? unfilledPrescription.slice(0, 10)
                            .map((unfilled, index) => {
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={index}
                                        className='mui-table-row'>
                                        {headCells.map((head)=>(
                                            <TableCell id={head.id}  key={head.id}
                                                       hidden={head.hidden}>{unfilled[head.id]}</TableCell>
                                        ))}
                                    </TableRow>
                                );
                            }):<TableRow>
                            <TableCell colSpan={headCells.length} align="center">No records found</TableCell>
                        </TableRow>:null}
                        {isRejected ? <TableRow>
                            <TableCell colSpan={headCells.length} align="center">The server did not return a valid response</TableCell>
                        </TableRow>:null}
                    </TableBody>
                </CustomTable>
            </Container>
        </div>
    );

}

function mapStateToProps(state) {
    return {prescriptions: state.prescriptions, snackbars: state.snackbar};
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            prescriptionActions: bindActionCreators(prescriptionActions, dispatch),
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    };
}

export default withTheme(connect(mapStateToProps, mapDispatchToProps)(OutPatientPrescriptionList));
