import React, { useEffect } from "react";


import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTheme} from "@material-ui/core/styles";
import { RightAlignedContainer } from "../Utils/styledComponents";
import { SubHeader } from "../../Containers/SubHeader";
import PageTitle from "../Utils/smsTitle";
import AddSubscriber from "./AddSubscriber";
import { useCreateSubscription } from "./CustomHooks/useCreateSubscription";
import { faCog } from "@fortawesome/free-solid-svg-icons/faCog";
import { useSnackbar } from "../../Context/snackbar-context";
import { Col, Form, Row } from "react-bootstrap";
import { AutoCompleteControlled } from "../Utils/FormInputs/AutoCompleteInput";
import { CustomInput } from "../Inventory/CustomInput";
import Label from "../Utils/FormInputs/Label";
import { CustomSelect } from "../Utils/FormInputs/SelectInput";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import CustomTable from "../Utils/Tables/CustomTable";
import { TableBody, TableCell, TableRow } from "@material-ui/core";
import { LoadingGif } from "../Utils/Loader";
import ReceiptTemplate from "../Utils/ReceiptTemplate";
import { formatDateTime } from "../Utils/ConvertDate";
import { useVersionsContext } from "../../Context/versions-context";

const headData = [{id: 'sl', numeric: false, disablePadding: false, label: 'SL'},
    {id: 'subName', numeric: false, disablePadding: false, label: 'Subscriber name'},
    {id: 'type', numeric: false, disablePadding: false, label: 'Subscription Type'},
    {id: 'version', numeric: false, disablePadding: false, label: 'Version'},
];

const CreateSubscription = ({actions, snackbar, match:{params}}) => {

    const { name, handleChangeSub, handleChangeVersion, handleChangeSubType, handleSubmit, versions, sub_types, subName, subType, version, isLoading, loading, subscriptions} = useCreateSubscription(actions)
    const {subscription:subs} = useVersionsContext()
    
    useEffect(() => {
        if(loading == "success")
            window.location.reload();  
    }, [loading]);

    const {state:snackbarState, handleClose:handleCloseSnackbar} = useSnackbar({actions, snackbar, match:{params}})
    const {open:openBar, variant, message} = snackbarState


    return (
        <div data-testid="add-subscription">
            <PageTitle title='Create Prescription'/>
            <SubHeader title="Settings" subTitle='Create Subscription'>
                <FontAwesomeIcon icon={faCog}/>
            </SubHeader>
            <MainSnackbar
                    open={openBar}
                    message={message}
                    variant={variant}
                    handleCloseBar={handleCloseSnackbar}
                />
           
           
            <div className="add-bill-card">
             

                <div className="row">
                    <div className="col-md-6 " style={{marginTop: "60px"}}>
                    <form autoComplete="off" onSubmit={handleSubmit}>
                       
                        <Form.Group as={Row}>

                            <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                                <Label name="Subscriber name"  type/>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                                {/* <CustomInput name='name' isRequired submitted={submitted} inputValue={subName} type='text' placeholder='Enter subscriber name' reset={isResolved}/> */}

                                <Form.Control id={name}
                                       
                                        type="text" value={subName} name='name'
                                        onChange={handleChangeSub}
                                        
                                        className={`form__control}`} />
                           
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                                <Label name="Subsctiption type"  type/>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                                <CustomSelect options={sub_types} value={subType} onChange={handleChangeSubType}/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                                <Label name="Version"  type/>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                                <CustomSelect options={versions} value={version} onChange={handleChangeVersion}/>
                            </Col>
                        </Form.Group>
                        <button type="submit" disabled={isLoading}
                        className="btn sms-btn btn-sm px-3">{isLoading === 'loading' ? "Updating..." : "Update"}</button>
                        </form>
                    </div>
                    <div className="col-md-6">
                    <ReceiptTemplate>
                <div className={`patient-lab-report`}
                     id="patient-radiology-header-report">
                    <table className="table table-sm table-borderless">
                        <tbody>
                      
                            <tr>
                                <p style={{ paddingTop: "8px" }}>Subscriber:
                                    <strong> {subscriptions?.subscriber_name}</strong>
                                </p>
                            </tr>
                            <tr >
                                <p style={{ paddingTop: "8px" }}>Running version: <strong>{subscriptions?.version_name}</strong></p>
                            </tr>
                            <tr > 
                                <p style={{ paddingTop: "8px" }}>Subscription type:
                                    <strong> {subscriptions?.subscription_type}</strong>
                                </p>
                            </tr>

                            <tr >
                                <p style={{ paddingTop: "8px" }}>Begin date:
                                    <strong> {subscriptions?.created_at}</strong>
                                </p>
                            </tr>
                            <tr >
                                <p style={{ paddingTop: "8px" }}>Expiry date: <strong>{subscriptions?.expiration_date}</strong></p>
                            </tr>
                     

                       

                        
                        
                        </tbody>
                    </table>
                </div>
                
         
            </ReceiptTemplate>
                        </div>
                    </div>
            </div>
        </div>
    )
};

export default CreateSubscription;
