import React from "react";
import Card from "@material-ui/core/Card";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTheme} from "@material-ui/core/styles";
import {faCoins} from "@fortawesome/free-solid-svg-icons/faCoins";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../../actions/snackbarActions";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {useClearOutPatientDebt} from "./hooks/useClearOutPatientDebt";
import PrintDialog from "../../Utils/Dialogs/PrintDialog";
import {titleCase} from "../../Users/addUser";
import TextField from "../../Utils/FormInputs/TextField";
import {dateStringConvert} from "../../Utils/ConvertDate";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Label from "../../Utils/FormInputs/Label";
import Form from "react-bootstrap/Form"
import SelectInput from "../../Utils/FormInputs/SelectInput";
import {ErrorMessage, RightAlignedContainer} from "../../Utils/styledComponents";
import {ResizableTextarea} from "../../Utils/FormInputs/Textarea";
import { BackButton } from '../../Utils/Buttons/DataExportationButton';

function ClearOutPatientDebt({actions, snackbars, match}) {
    const {
        closeSnackbar, open_receipt, payment_method, handleChangePayment,
        handleOpenReceipt, handleCloseReceipt, bills, handleChangeBillPayment, payTypes,
        totals, handleSubmit, isSubmitted, submitted,
        memo, handleChangeMemo, amount_paid
    } = useClearOutPatientDebt(actions, match)

    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    const {openBar, type, message} = snackbars;
    const {due_amount_total, total_bill_amount} = totals;
    const partial = bills.filter(bill => bill.invoice.pay_status === 4);
    const unpaid = bills.filter(bill => bill.invoice.pay_status === 3);
    const servicesArr = unpaid.filter(bill =>!bill.invoice.bed_assignment_id);
    const bedArr = unpaid.filter(bill =>bill.invoice.bed_assignment_id);
    const billed_to = bills.map(item =>  {
        const {invoice} = item
        return invoice.billed_to
    })

  
    const partial_obj = partial[0] ? partial[0] : {};
    const partial_invoice = partial_obj.invoice ? partial_obj.invoice : {};
    
    return (
        <div>
            <PageTitle title="Make Payment"/>
            <SubHeader title="Billing" subTitle="Make payment">
                <FontAwesomeIcon icon={faCoins}/>
            </SubHeader>
            <div style={{marginLeft: '15px'}}>
                <BackButton to='/debtors' text='Debtors List' data-testid="clear-out-patient-debt-back-button"/>
            </div>
            <div className="add-bill-card">
                <PrintDialog handleCancel={handleCloseReceipt} openDialog={open_receipt}
                             handleClose={handleCloseReceipt}
                             handleClick={handleOpenReceipt} message="View receipt"
                             text="Would you like to view and print this receipt"/>
                <BackButton text='Debtors List' to='/debtors'/>

                <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>
                <Card className="p-3">
                    <form onSubmit={handleSubmit}>
                        <h6 className={`visit-heading ${palette.type === "dark" && "dark-visit-heading"}`}>Make Payment</h6>
                        <div className='row'>
                            <div className='col-lg-6 col-md-6 pl-0'>
                                <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                        <Label name="Billed to."/>
                                    </Col>
                                    <Col xs={12} sm={12} md={8} lg={10} xl={7}>
                                        <TextField value={titleCase(billed_to[0])}
                                                   type="text"  disabled/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                        <Label name="Payment Mode" type/>
                                    </Col>
                                    <Col xs={12} sm={12} md={8} lg={10} xl={7}>
                                        <SelectInput submitted={submitted}
                                                     options={payTypes.filter(type=>type.label !== 'CREDIT' ).map(item => ({value: item.value, text: item.label}))}
                                                     value={payment_method}
                                                     onChange={handleChangePayment}
                                                     name="payment_method"  defaultOption='select method...'/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                        <Label name="Memo"/>
                                    </Col>
                                    <Col xs={12} sm={12} md={8} lg={10} xl={7}>
                                        <ResizableTextarea value={memo} onChange={handleChangeMemo} name='memo' rows='3'/>
                                    </Col>
                                </Form.Group>
                            </div>
                            <div className='col-lg-6 col-md-6 pl-0'>
                                <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                        <Label name="Total Bill"/>
                                    </Col>
                                    <Col xs={12} sm={12} md={8} lg={10} xl={7}>
                                        <TextField value={total_bill_amount}
                                                   type="text"  disabled/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                        <Label name="Total Amount Due"/>
                                    </Col>
                                    <Col xs={12} sm={12} md={8} lg={10} xl={7}>
                                        <TextField value={due_amount_total}
                                                   type="text"  disabled/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                        <Label name="Payment"/>
                                    </Col>
                                    <Col xs={12} sm={12} md={8} lg={10} xl={7}>
                                        <TextField type='number' value={amount_paid}
                                                   onChange={handleChangeBillPayment}/>
                                        {(submitted && !amount_paid) ?
                                            <ErrorMessage>Paid amount is required</ErrorMessage>:
                                            +amount_paid > due_amount_total ? <ErrorMessage>
                                                Paid Amount shouldn't be greater than due amount</ErrorMessage> :  null}
                                    </Col>
                                </Form.Group>

                            </div>

                        </div>
                        {partial.length > 0 ?
                            <div key={partial_invoice.bill_id}>
                                <tr>
                                    <td>
                                        <h6>{`${partial_invoice.service_id ? partial_invoice.service_name :
                                            partial_invoice.product_id ? partial_invoice.product_name : partial_invoice.bed_assignment_id ? `Bed No.: ${partial_invoice.bed_number}` : ''}#${partial_invoice.invoice_no}`}
                                            <span
                                                className="ml-3">Amount: <strong>{`${partial_invoice.quantity * partial_invoice.rate}(${partial_invoice.pay_status === 3 ? 'Unpaid' : 'Partially paid'})`}</strong></span>
                                        </h6>
                                    </td>
                                </tr>

                                {partial_obj.receipts.length > 0 ?
                                    <div className='table-responsive'>
                                    <table className='table table-bordered table-sm billing-invoice-table'>
                                        <thead>
                                        <th>Date</th>
                                        <th>Receipt#</th>
                                        <th>Memo</th>
                                        <th>Amount Paid</th>
                                        <th>Balance</th>
                                        </thead>
                                        <tbody>

                                        {partial_obj?.receipts?.map(rec => {
                                            const t_amount =partial_invoice.quantity * partial_invoice.rate
                                            const p_amount = partial_invoice.pay_status === 3 ? 0 : rec.amount_paid ?? 0
                                            const d_amount = partial_invoice.pay_status === 3 ? t_amount - p_amount : rec.balance;
                                            const receipt = partial_invoice.pay_status === 4 ? rec.receipt_no : '';
                                            return (
                                                <tr key={rec.id}>
                                                    <td>{dateStringConvert(rec.date)}</td>
                                                    <td>{receipt}</td>
                                                    <td>{rec.memo}</td>
                                                    <td>{p_amount}</td>
                                                    <td>{d_amount}</td>
                                                </tr>
                                            )
                                        })}

                                        </tbody>
                                    </table></div> : null}
                            </div>:null}
                        {servicesArr.length > 0 ?
                            <div className='table-responsive'>
                                <table className='table table-bordered table-sm billing-invoice-table'>
                            <thead>
                            <tr>
                                <td colSpan={5} align='center'><strong>Items</strong></td>
                            </tr>
                            <tr>
                                <td>Item</td>
                                <td>Invoice#</td>
                                <td>Quantity</td>
                                <td>Rate</td>
                                <td>Amount</td>
                            </tr>
                            </thead>
                            <tbody>
                            {servicesArr.filter(bill => bill.invoice.pay_status === 3).map(item => (
                                <tr key={item.invoice.bill_id}>
                                    <td>{item.invoice.service_id ? item.invoice.service_name :
                                        item.invoice.product_id ? item.invoice.product_name : ''}</td>
                                    <td>{item.invoice.invoice_no}</td>
                                    <td>{item.invoice.quantity}</td>
                                    <td>{item.invoice.rate}</td>
                                    <td>{item.invoice.quantity * item.invoice.rate}</td>
                                </tr>
                            ))}
                            </tbody>
                                </table></div>:null}
                        {bedArr.length > 0 ?<div className="table-responsive">
                            <table
                                className="table table-bordered table-sm billing-invoice-table">
                                <thead>
                                <tr>
                                    <td colSpan="9" align="center"><strong>Unpaid Bed(s) Invoices</strong></td>
                                </tr>
                                <tr>
                                    <td><strong>Ward</strong></td>
                                    <td><strong>Room</strong></td>
                                    <td><strong>Bed NO.</strong></td>
                                    <td><strong>Assign Date</strong></td>
                                    <td><strong>End Date</strong></td>
                                    <td><strong>Total Days</strong></td>
                                    <td><strong>Rate</strong></td>
                                    <td><strong>Amount</strong></td>
                                </tr>
                                </thead>
                                <tbody>
                                {bedArr.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{item.invoice.ward_name}</td>
                                            <td>{item.invoice.room_name}</td>
                                            <td>{item.invoice.bed_number}</td>
                                            <td>{dateStringConvert(item.invoice.assign_date)}</td>
                                            <td>
                                                {item.invoice.end_date ? dateStringConvert(item.invoice.end_date) : ''}
                                            </td>
                                            <td>{item.invoice.quantity}</td>
                                            <td>{item.invoice.rate}</td>
                                            <td>{item.invoice.rate * item.invoice.quantity}</td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>:null}
                        <RightAlignedContainer>
                            <button type='submit' className='btn btn-sm sms-info-btn'
                            disabled={isSubmitted === 'pending' || isSubmitted === 'resolved'}>
                            {isSubmitted === 'pending' ? 'Submitting...' : 'Submit'}</button>
                        </RightAlignedContainer>
                    </form>
                </Card>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}


export default connect(mapStateToProps, mapDispatchToProps)(ClearOutPatientDebt);
