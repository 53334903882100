export const ALERT_SUCCESS = "SUCCESS";
export const ALERT_ERROR = "ERROR";
export const ALERT_CLEAR = "CLEAR";
export const USERS_REGISTER_REQUEST = "USERS_REGISTER_REQUEST";
export const USERS_REGISTER_SUCCESS = "USERS_REGISTER_SUCCESS";
export const USERS_REGISTER_FAILURE = "USERS_REGISTER_FAILURE";
export const USERS_LOGIN_REQUEST = "USERS_LOGIN_REQUEST";
export const USERS_LOGIN_SUCCESS = "USERS_LOGIN_SUCCESS";

export const USER = "USER";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const USERS_LOGOUT = "USERS_LOGOUT";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const USER_PHARMACIST = "USER_PHARMACIST";
export const USER_LABTECH = "USER_LABTECH";
export const USER_DOCTOR = "USER_DOCTOR";
export const USER_RECEPTIONIST = "USER_RECEPTIONIST";

export const USERS_GETALL_FAILURE = "USERS_GETALL_FAILURE";
export const CREATE_APPOINTMENT_SUCCESS = "CREATE_APPOINTMENT_SUCCESS";
export const LOAD_PATIENTS_SUCCESS = "LOAD_PATIENTS_SUCCESS";
export const CREATE_PATIENT_SUCCESS = "CREATE_PATIENT_SUCCESS";
export const SAVE_PATIENT_SUCCESS = "SAVE_PATIENT_SUCCESS";
export const SAVE_KIN_SUCCESS = "SAVE_KIN_SUCCESS";
export const CREATE_DOCTOR_SUCCESS = "CREATE_DOCTOR_SUCCESS";
export const CREATE_KIN_SUCCESS = "CREATE_KIN_SUCCESS";
export const SAVE_DOCTOR_SUCCESS = "SAVE_DOCTOR_SUCCESS";
export const LOAD_INSURANCE_COMPANIES = "LOAD_INSURANCE_COMPANIES";
export const LOAD_DEPARTMENTS = "LOAD_DEPARTMENTS";
export const LOAD_DOCTORS = "LOAD_DOCTORS";
export const LOAD_USERS = "LOAD_USERS";
export const CLEAR_USERS = "CLEAR_USERS";
export const SEARCH_PATIENT_BY_PHONE = "SEARCH_PATIENT_BY_PHONE";
export const SEARCH_PATIENT_BY_EMAIL = "SEARCH_PATIENT_BY_EMAIL";
export const SEARCH_PATIENT = "SEARCH_PATIENT";
export const LOAD_APPOINTMENTS = "LOAD_APPOINTMENTS";
export const GET_READABLE_APPOINTMENTS = "GET_READABLE_APPOINTMENTS";
export const CREATE_RECEPTIONIST_SUCCESS = "CREATE_RECEPTIONIST_SUCCESS";
export const SAVE_RECEPTIONIST_SUCCESS = "SAVE_RECEPTIONIST_SUCCESS";
export const CREATE_TECHNICIAN_SUCCESS = "CREATE_TECHNICIAN_SUCCESS";
export const SAVE_TECHNICIAN_SUCCESS = "SAVE_TECHNICIAN_SUCCESS";
export const ADD_PRESCRIPTION = "ADD_PRESCRIPTION";
export const ALL_PROCEDURES = "ALL_PROCEDURES";
export const ALL_SCANS = "ALL_SCANS";
export const ADD_PRESCRIBED_DRUG = "ADD_PRESCRIBED_DRUG";
export const DOCTORS_LOGIN_REQUEST = "DOCTORS_LOGIN_REQUEST";
export const DOCTORS_LOGIN_SUCCESS = "DOCTORS_LOGIN_SUCCESS";
export const DOCTORS_LOGIN_FAILURE = "DOCTORS_LOGIN_FAILURE";
export const DELETE_APPOINTMENT_REQUEST = "DELETE_APPOINTMENT_REQUEST";
export const DELETE_APPOINTMENT_SUCCESS = "DELETE_APPOINTMENT_SUCCESS";
export const DELETE_APPOINTMENT_FAILURE = "DELETE_APPOINTMENT_FAILURE";
export const GET_ALL_DRUGS = "GET_ALL_DRUGS";
export const GET_UNFILLED_PRESCRIPTIONS = "GET_UNFILLED_PRESCRIPTIONS";
export const GET_PATIENTS = "GET_PATIENTS";
export const GET_READABLE_PATIENTS = "GET_READABLE_PATIENTS";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const GET_PRESCRIBED_DRUGS = "GET_PRESCRIBED_DRUGS";
export const SAVE_DRUG_SUCCESS = "SAVE_DRUG_SUCCESS";
export const GET_DRUG_ID = "GET_DRUG_ID";
export const GET_PRESCRIPTION_ID = "GET_PRESCRIPTION_ID";
export const GET_ALL_TESTS = "GET_ALL_TESTS";
export const ADD_LAB_REQUEST = "ADD_LAB_REQUEST";
export const GET_ALL_REQUESTS = "GET_ALL_REQUESTS";
export const GET_REQUESTS_BY_APPOINTMENTID = "GET_REQUESTS_BY_APPOINTMENTID";
export const GET_SAMPLE_TYPES = "GET_SAMPLE_TYPES";
export const GET_TECHNICIAN_BY_DEPARTMENT = "GET_TECHNICIAN_BY_DEPARTMENT";
export const CLEAR_TECH_LIST = "CLEAR_TECH_LIST";
export const ADD_TEST = "ADD_TEST";
export const ADD_SAMPLE = "ADD_SAMPLE";
export const ADD_TEST_DETAILS = "ADD_TEST_DETAILS";
export const GET_LAB_REQUESTID = "GET_LAB_REQUESTID";
export const GET_HOSP_TEST_DETAILS = "GET_HOSP_TEST_DETAILS";
export const GET_USER_ID = "GET_USER_ID";
export const GET_DOCTOR_ID = "GET_DOCTOR_ID";
export const GET_TECHNICIAN_ID = "GET_TECHNICIAN_ID";
export const SET_TECHNICIAN_BY_USERID = "SET_TECHNICIAN_BY_USERID";
export const GET_CURRENT_SCREEN = "GET_CURRENT_SCREEN";
export const AUTHENTICATION_STATUS = "AUTHENTICATION_STATUS";
export const GET_LABREQUESTID = "GET_LABREQUESTID";
export const ADD_SAMPLE_TO_REQUEST = "ADD_SAMPLE_TO_REQUEST";
export const ADD_DIAGNOSIS = "ADD_DIAGNOSIS";
export const GET_ALL_MEASUREMENTS = "GET_ALL_MEASUREMENTS";
export const GET_METRIC_BY_MEASUREMENT_ID = "GET_METRIC_BY_MEASUREMENT_ID";
export const ADD_APPOINTMENT_METRIC = "ADD_APPOINTMENT_METRIC";
export const SAVE_OTHER_MEASUREMENT = "SAVE_OTHER_MEASUREMENT";
export const ADD_METRIC_VALUE = "ADD_METRIC_VALUE";
export const PATIENT_REGISTRATION_FAILURE = "PATIENT_REGISTRATION_FAILURE";
export const SNACKBAR_SUCCESS = "SNACKBAR_SUCCESS";
export const SNACKBAR_ERROR ="SNACKBAR_ERROR";
export const SNACKBAR_INFO ="SNACKBAR_INFO";
export const SNACKBAR_WARNING ="SNACKBAR_WARNING";
export const HIDE_SNACKBAR = "HIDE_SNACKBAR ";
export const ADD_TEST_RESULTS ="ADD_TEST_RESULTS";
export  const ISSUE_DRUG = "ISSUE_DRUG";
export const GET_REQUEST_BY_ID = "GET_REQUEST_BY_ID";
export  const GET_HOSP_DETAIL_BY_ID = "GET_REQUEST_BY_ID";
export const GET_LABRESULT_BY_ID = "GET_LABRESULT_BY_ID";
export const GET_READABLE_REQUESTS = "GET_READABLE_REQUESTS";
export const GET_APPOINTMENT_BY_ID ="GET_APPOINTMENT_BY_ID";
export const GET_SCAN_NAME_BY_TYPE = "GET_SCAN_NAME_BY_TYPE";
export const GET_ALL_SCAN_REQUESTS = "GET_ALL_SCAN_REQUESTS";
export const GET_SCAN_BY_APPOINTMENT_ID = "GET_SCAN_BY_APPOINTMENT_ID";
export const GET_UPLOAD_PROGRESS = "GET_UPLOAD_PROGRESS";
export const GET_SCAN_RESULTS_BY_ID = "GET_SCAN_RESULTS_BY_ID";
export const GET_DIAGNOSIS_BY_ID = "GET_DIAGNOSIS_BY_ID";
export const GET_APPOINTMENT_BY_PATIENT_ID = "GET_APPOINTMENT_BY_PATIENT_ID";
export const GET_PATIENTMETRIC_BY_ID = "GET_PATIENTMETRIC_BY_ID";
// Billing
export const BILL_REPORT = "BILL_REPORT";
export const MONTHLY_VISITS = "MONTHLY_VISITS";
export const DAILY_VISITS = "DAILY_VISITS";
export const THIS_MONTHS_SALES = "THIS_MONTHS_SALES";
export const DRUGS_IN_RANGE = "DRUGS_IN_RANGE";
export const DRUGS_BY_DATE = "DRUGS_BY_DATE";
export const MONTHLY_VISIT_PERCENTAGE = "MONTHLY_VISIT_PERCENTAGE";
export const GET_ALL_BILLS = "GET_ALL_BILLS";
export const GET_ALL_PROCEDURES = "GET_ALL_PROCEDURES";
export const GET_ALL_AVAILABLE_DRUGS = "GET_ALL_AVAILABLE_DRUGS";
export const GET_CONSULTATION_BILL = "GET_CONSULTATION_BILL";
export const GET_LAB_BILL = "GET_LAB_BILL";
export const GET_PROCEDURE_BILL = "GET_PROCEDURE_BILL";
export const GET_SCAN_BILL = "GET_SCAN_BILL";
export const GET_ALL_PRESC_DRUGS = "GET_ALL_PRESC_DRUGS";
export const UPDATE_DRUGS_BY_DATE = "UPDATE_DRUGS_BY_DATE";
export const GET_CUSTOMER_VISITS = "GET_CUSTOMER_VISITS";
export const GET_KIN_USER = "GET_KIN_USER";
export const GET_DRUG_TYPES = "GET_DRUG_TYPES";
export const GET_LAB_APPOINTMENTS = "GET_LAB_APPOINTMENTS";
export const GET_DOCTOR_WAITING_LIST = "GET_DOCTOR_WAITING_LIST";
export const GET_EMPLOYEE_REQUEST = "GET_EMPLOYEE_REQUEST";
export const GET_ALL_EMPLOYEES = "GET_ALL_EMPLOYEES";
export const GET_EMPLOYEE_ERROR = "GET_EMPLOYEE_ERROR";
export const EMPLOYEE_UPDATE_FORM = "EMPLOYEE_UPDATE_FORM";
export const DELETE_EMPLOYEE = "DELETE_EMPLOYEE";
export const ADD_EMPLOYEE_REQUEST = "ADD_EMPLOYEE_REQUEST";
export const ADD_EMPLOYEE_SUCCESS = "ADD_EMPLOYEE_SUCCESS";
export const ADD_EMPLOYEE_ERROR = "ADD_EMPLOYEE_ERROR";
export const EDIT_EMPLOYEE_REQUEST = "EDIT_EMPLOYEE_REQUEST";
export const EDIT_EMPLOYEE_SUCCESS = "EDIT_EMPLOYEE_SUCCESS";
export const EDIT_EMPLOYEE_ERROR = "EDIT_EMPLOYEE_ERROR";
export const GET_EMPLOYEE_DETAILS = "GET_EMPLOYEE_DETAILS";
export const GET_ALL_LAB_TESTS = "GET_ALL_LAB_TESTS";
export const PRESCRIPTION_LIST_REQUEST = "PRESCRIPTION_LIST_REQUEST";
export const PRESCRIPTION_LIST_SUCCESS = "PRESCRIPTION_LIST_SUCCESS";
export const PRESCRIPTION_LIST_ERROR = "PRESCRIPTION_LIST_ERROR";
export const DELETE_PRESCRIPTION = "DELETE_PRESCRIPTION";
export const CASE_STUDY_UPDATE_FORM = "CASE_STUDY_UPDATE_FORM";
export const CASE_STUDY_LIST_REQUEST = "CASE_STUDY_LIST_REQUEST";
export const CASE_STUDY_LIST_SUCCESS = "CASE_STUDY_LIST_SUCCESS";
export const CASE_STUDY_LIST_ERROR = "CASE_STUDY_LIST_ERROR";
export const DELETE_CASE_STUDY = "DELETE_CASE_STUDY";
export const PRODUCT_LIST_REQUEST = "PRODUCT_LIST_REQUEST";
export const PRODUCT_LIST_SUCCESS = "PRODUCT_LIST_SUCCESS";
export const PRODUCT_LIST_ERROR = "PRODUCT_LIST_ERROR";
export const PRODUCT_UPDATE_FORM = "PRODUCT_UPDATE_FORM";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const ADD_PRODUCT_REQUEST = "ADD_PRODUCT_REQUEST";
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS";
export const ADD_PRODUCT_ERROR = "ADD_PRODUCT_ERROR";
export const EDIT_PRODUCT_REQUEST = "EDIT_PRODUCT_REQUEST";
export const EDIT_PRODUCT_SUCCESS = "EDIT_PRODUCT_SUCCESS";
export const EDIT_PRODUCT_ERROR = "EDIT_PRODUCT_ERROR";
export const ADD_PRODUCT_CATEGORY_REQUEST = "ADD_PRODUCT_CATEGORY_REQUEST";
export const ADD_PRODUCT_CATEGORY_SUCCESS = "ADD_PRODUCT_CATEGORY_SUCCESS";
export const ADD_PRODUCT_CATEGORY_ERROR = "ADD_PRODUCT_CATEGORY_ERROR";
export const EDIT_PRODUCT_CATEGORY_REQUEST = "EDIT_PRODUCT_CATEGORY_REQUEST";
export const EDIT_PRODUCT_CATEGORY_SUCCESS = "EDIT_PRODUCT_CATEGORY_SUCCESS";
export const EDIT_PRODUCT_CATEGORY_ERROR = "EDIT_PRODUCT_CATEGORY_ERROR";
export const ADD_CASESTUDY_REQUEST = "ADD_CASESTUDY_REQUEST";
export const ADD_CASESTUDY_SUCCESS = "ADD_CASESTUDY_SUCCESS";
export const ADD_CASESTUDY_ERROR = "ADD_CASESTUDY_ERROR";
export const EDIT_CASESTUDY_REQUEST = "EDIT_CASESTUDY_REQUEST";
export const EDIT_CASESTUDY_SUCCESS = "EDIT_CASESTUDY_SUCCESS";
export const EDIT_CASESTUDY_ERROR = "EDIT_CASESTUDY_ERROR";
export const CATEGORY_LIST_REQUEST = "CATEGORY_LIST_REQUEST";
export const CATEGORY_LIST_SUCCESS = "CATEGORY_LIST_SUCCESS";
export const CATEGORY_LIST_ERROR = "CATEGORY_LIST_ERROR";
export const CATEGORY_UPDATE_FORM = "CATEGORY_UPDATE_FORM";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const BILL_LIST_REQUEST = "BILL_LIST_REQUEST";
export const BILL_LIST_SUCCESS = "BILL_LIST_SUCCESS";
export const BILL_LIST_ERROR = "BILL_LIST_ERROR";
export const DELETE_BILL = "DELETE_BILL";
export const REGISTER_PATIENT_REQUEST = "REGISTER_PATIENT_REQUEST";
export const REGISTER_PATIENT_SUCCESS = "REGISTER_PATIENT_SUCCESS";
export const REGISTER_PATIENT_ERROR = "REGISTER_PATIENT_ERROR";
export const RETRIEVE_PATIENTS_REQUEST = "RETRIEVE_PATIENTS_REQUEST";
export const RETRIEVE_PATIENTS_SUCCESS = "RETRIEVE_PATIENTS_SUCCESS";
export const RETRIEVE_PATIENTS_ERROR = "RETRIEVE_PATIENTS_ERROR";
export const DELETE_PATIENT = "DELETE_PATIENT";
export const EDIT_PATIENT_REQUEST = "EDIT_PATIENT_REQUEST";
export const EDIT_PATIENT_SUCCESS = "EDIT_PATIENT_SUCCESS";
export const EDIT_PATIENT_ERROR = "EDIT_PATIENT_ERROR";
export const DELETE_SCAN_REQUEST = "DELETE_SCAN_REQUEST";
export const PATIENT_COUNT = "PATIENT_COUNT";
export const GET_PATIENT_PREVIOUS_VISITS = "GET_PATIENT_PREVIOUS_VISITS";
export const GET_SCAN_REQUESTS = "GET_SCAN_REQUESTS";
export const GET_SCAN_REQUEST_SUCCESS = "GET_SCAN_REQUEST_SUCCESS";
export const GET_SCAN_REQUEST_ERROR = "GET_SCAN_REQUEST_ERROR";
export const GET_SCAN_REQUEST_BY_ID = "GET_SCAN_REQUEST_BY_ID";
export const ADD_SCAN_RESULTS_REQUEST = "ADD_SCAN_RESULTS_REQUEST";
export const ADD_SCAN_RESULTS_SUCCESS = "ADD_SCAN_RESULTS_SUCCESS";
export const ADD_SCAN_RESULTS_ERROR = "ADD_SCAN_RESULTS_ERROR";
export const EDIT_SCAN_RESULTS_REQUEST = "ADD_SCAN_RESULTS_REQUEST";
export const EDIT_SCAN_RESULTS_SUCCESS = "ADD_SCAN_RESULTS_SUCCESS";
export const EDIT_SCAN_RESULTS_ERROR = "ADD_SCAN_RESULTS_ERROR";
export const VIEW_SCAN_RESULTS = "VIEW_SCAN_RESULTS";
export const BILL_TOTAL_COUNT = "BILL_TOTAL_COUNT";
export const GET_DOCTOR_WAITING_LIST_REQUEST = "GET_DOCTOR_WAITING_LIST_REQUEST";
export const GET_DOCTOR_WAITING_LIST_ERROR = "GET_DOCTOR_WAITING_LIST_ERROR";
export const WAITING_LIST_TOTAL_COUNT = "WAITING_LIST_TOTAL_COUNT";
export const OPEN_VISIT = "OPEN_VISIT";
export const CLOSE_VISIT = "CLOSE_VISIT";
export const GET_PATIENT_ID = "GET_PATIENT_ID";
export const TOTAL_SCAN_COUNT = "TOTAL_SCAN_COUNT";
export const PRESCRIPTION_TOTAL_COUNT = "PRESCRIPTION_TOTAL_COUNT";
export const GET_SCAN_RESULTS_SUCCESS = "GET_SCAN_RESULTS_SUCCESS";
export const GET_PREVIOUS_VISIT_DETAILS = "GET_PREVIOUS_VISIT_DETAILS";
export const BED_REQUEST = "BED_REQUEST";
export const BED_ERROR = "BED_ERROR";
export const BED_SUCCESS = "BED_SUCCESS";
export const CONFIRM_ITEMS_REQUEST = "CONFIRM_ITEMS_REQUEST";
export const CONFIRM_ITEMS_SUCCESS = "CONFIRM_ITEMS_SUCCESS";
export const CONFIRM_ITEMS_ERROR = "CONFIRM_ITEMS_ERROR";
export const NEXT_STEP = "NEXT_STEP";
export const PREVIOUS_STEP = "PREVIOUS_STEP";







