import React from "react";
import {titleCase} from "../../Users/addUser";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus} from "@fortawesome/free-solid-svg-icons/faMinus";
import { AutoCompleteUnControlled} from "../../Utils/FormInputs/AutoCompleteInput";
import InputGroup from "react-bootstrap/InputGroup";
import TextField from "../../Utils/FormInputs/TextField";
import TableRow from "@material-ui/core/TableRow";
import {DataCell, ModuleTable} from "../../Utils/Lists/CollapsibleList";
import {StyledCell} from "../../DoctorsModule/doctorStyles";

const headData = [{item:'Lab Test', toolTip:'Enter lab test', isRequired:true},
    {item:'Lab',  toolTip:'Enter lab'}, {item:'Cost'}, {item:'Status'},{item:'Action'}]


export const LabTable = ({labTests, retrieveTests, tests, getTestDetails, handleAddRow, removeRow,submitted, isResolved}) => {
    return (
            <ModuleTable headData={headData}>
                {labTests?.map((test, index) => (
                    <TableRow
                        key={test.uuid}
                    >
                        <DataCell variant='250'>
                            <AutoCompleteUnControlled reset={isResolved} options={tests} submitted={submitted} idx={index} handleChange={retrieveTests} label_text="label"
                                                      medicine_value={{label:test.test_name, value: test.test_id}} id="test_name" value_text="value"
                                                      errorMsg_Valid = "Please enter a valid lab test" valid_error_check={test.isError}
                                                      handleProductData={getTestDetails} placeholder="Type Lab Test" errorMsg="Please a select a valid lab test"/>
                        </DataCell>
                        <DataCell variant='150'>
                            {titleCase(test.hospital_test_type)}
                        </DataCell>
                        <DataCell variant='200'>
                            <InputGroup>
                                <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">{test.currency_symbol}</InputGroup.Text>
                                <TextField  type="number" value={test.cost} style={{pointerEvents:'none'}}/>
                            </InputGroup>
                        </DataCell>
                        <DataCell variant='150'>
                            {test.is_external=== true ? 'Internal Test': test.is_external=== false ?  'External Test' : ''}
                        </DataCell>
                        <DataCell>
                            <button onClick={() => removeRow(test.uuid)} type="button"
                                    className="btn btn-sm sms-btn-dismiss" data-testid="remove-row">
                                <FontAwesomeIcon icon={faMinus}/>
                            </button>
                        </DataCell>

                    </TableRow>
                ))}
                <TableRow>
                    <StyledCell colSpan={headData.length}>
                        <button type="button" onClick={handleAddRow} className="btn btn-sm sms-gray-btn"
                                id="add-row">Add another line</button>
                    </StyledCell>
                </TableRow>
            </ModuleTable>
    )
}
