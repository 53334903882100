import React, {useEffect, useState} from "react";
import {makeStyles} from '@material-ui/core/styles';
import axios from "axios";
import { config } from "../../Helpers/env";
import { getActualDay } from "../Utils/ConvertDate";
import { errorMessages } from "../../Helpers/ErrorMessages";
import AccountCircle from '@material-ui/icons/AccountCircle';

// styles for the elements in the AppHeader
const useDoctorsOnDutyStyles = makeStyles({
    iconButtonColor: {
      color: `hsl(${Math.floor(Math.random() *359)}, ${Math.floor(Math.random() *40) +60}%, ${Math.floor(Math.random() *15) +7}%) !important`,
      fontSize: '32px'
    }
  });

const DoctorsOnDuty = ({actions}) => {
    const [doctors, setDoctors] = useState([]);
    const [shift_height_div, setShiftHeightDiv] = useState(0);
    const [shifts, setShifts] = useState([]);
    const [loading, setLoading] = useState(false);
    const classes = useDoctorsOnDutyStyles();
    
    useEffect(()=> {
        setLoading(true);
        axios.get(`${config.smsUrl}/cshiftschedule/retrieve_shift_category`).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const arr = !dt.shift ? [] : dt.shift;
            const list = arr.filter(item=>Boolean(item));
            setLoading(false);
            setShifts(list);
            setShiftHeightDiv(parseInt(600/(list.length)));
        }).catch(err => {
            errorMessages(err, null,actions);
            setLoading(false);
        })
    },[])
    
    useEffect(()=>{
        setLoading(true);
        const formData = new FormData();
        const today = new Date()
        formData.append("day", getActualDay(today.getDay()));
        axios.post(`${config.smsUrl}/admin/doctors_on_duty`, formData).then(res=>{
            const data = res.data
            const duty = data ? data : {};
            const dt = duty.data ? duty.data : [];
            setLoading(false);
            setDoctors(dt);
        }).catch(err=>{
            errorMessages(err, null,actions);
            setLoading(false);
        })
    },[])

    const display_shifts = (shft) => {
        const current_shift = doctors.filter(item => item.shifts.includes(shft.shift_id))
        return (
            <div className="mb-2" style={{height:`${shift_height_div}px`, overflow:'hidden'}}>
                <h4 style={{fontWeight:500, fontSize:'12px', color:'#6F777F'}} 
                className="mb-1">{shft.shift_name}</h4>
                <div style={{ borderBottom: '1px solid #3E4954', marginBottom: '8px'}}></div>   
                <div className="doctors_side_bar" style={{ height:`${shift_height_div-15}px`, overflowY:'scroll'}}>
                {
                    current_shift.map(doctor => {
                        return (
                            <div style={{display:'flex'}}>
                                <div style={{padding:'0px', width:'20%'}}>
                                    <AccountCircle colorInterpolationFilters="inherit" classes={{root: classes.iconButtonColor}}/>                                
                                </div>

                                <div style={{padding:'0px', width:'80%'}}>
                                    <span className="float-center p-1" style={{
                                        marginTop: 2,
                                        fontFamily: 'Visby CF',
                                        fontStyle: 'normal',
                                        fontWeight: 700,
                                        fontSize: '12px',
                                        color: '#696974'
                                        }}>{doctor.first_name} {doctor.last_name}
                                    </span>
                                    <div className="float-center p-1" style={{
                                        fontSize: "12px",
                                        color: "#696974",
                                        marginLeft: 6,
                                        fontWeight: 500,
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal'
                                    }}>{doctor.specialisation}</div>
                                </div>
                        </div>
                        )
                    })
                }
                </div>
            </div>
        )
    }

    return (
        <div>
            <div>
              <p  className="dashboard-card-font-color">PERSONNEL ON DUTY</p>
              <div className="orange-short-border"></div>
            </div>
            {doctors.length === 0 ?
                <div className="text-center py-2" style={{fontSize:'13px'}}>
                    No Doctors On Duty Today
                </div>
            : loading ? 
                <div className="text-center py-2" style={{fontSize:'13px'}}>Loading....</div>
            : 
            doctors.length > 0 ? 
                <div style={{marginTop:'10px'}}>
                {shifts.map(item => {
                    return (
                        display_shifts(item)
                    )
                })}
                </div>
            :
                <div className="text-center py-2" style={{fontSize:'13px'}}>
                    No Doctors On Duty Today
                </div>
            }
        </div>
    )
}

export default DoctorsOnDuty;