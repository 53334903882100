import React, {useEffect, useState} from "react";
import CustomTable from "../../Utils/Tables/CustomTable";
import FilterResults from "react-filter-search";
import TableBody from "@material-ui/core/TableBody";
import {getComparator, stableSort} from "../../Utils/Tables/sortingFunctions";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../../actions/snackbarActions";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import DeleteDialog from "../../Utils/Dialogs/DeleteDialog";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMortarPestle} from "@fortawesome/free-solid-svg-icons/faMortarPestle";
import axios from "axios";
import {config} from "../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {LoadingGif} from "../../Utils/Loader";
import {useAddMedicineType} from "./hooks/useAddMedicineType";
import {Container} from "reactstrap";
import {RightAlignedContainer} from "../../Utils/styledComponents";

const headData = [{id: 'sl', numeric: false, disablePadding: false, label: 'SL'},
    {id: 'typeName', numeric: false, disablePadding: false, label: 'Type Name'},
    {id: 'actions', numeric: false, disablePadding: false, label: 'Action'},
];

export const MedicineTypeList = ({actions, snackbars}) => {
    const [searchValue, setSearchValue] = useState('');
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('sl');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [item, setItem] = useState({product_type_id:'', status:''});
    const [isDelete, setIsDelete] = useState('idle')
    const [typeList, setTypes] = useState([]);
    const [loading, setLoading] = useState('idle');
    const [openDelete, setOpenDelete] = useState(false);
    const {renderAddMedicineType, isResolved, handleOpenDialog, handleOpenEdit} = useAddMedicineType(actions)


    const isDeleted = isDelete === 'deleted';


    useEffect(() => {
        setLoading('loading');
        axios.get(`${config.smsUrl}/cpharmacy/type_list`).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const all_types = !dt.type_list ? [] :dt.type_list;
            setTypes(all_types);
            setLoading('success')
        }).catch(err => {
            logoutErrorMessage(err, null,actions);
            setLoading('error')
        })

    }, [isResolved, isDeleted]);

    const handleOpenDelete = (obj) => {
        const {product_type_id, status} = obj
        setItem({product_type_id, status});
        setOpenDelete(true)
    };

    const handleCloseDelete = () => {
        setOpenDelete(false)
    };

    const handleDelete = () => {
        const {product_type_id, status} = item
        const formData = new FormData();
        const isActive = status === 1 ? 0 : status === 0 ? 1 : ''
        formData.append('product_type_id', product_type_id);
        formData.append('status', isActive);
        setIsDelete('pending')
        axios.post(`${config.smsUrl}/cpharmacy/type_delete`, formData).then(() => {
            const message = status === 1 ? 'deactivated' : status === 0 ? 'activated' : ''
            actions.snackbarActions.snackSuccess(`Medicine type ${message} successfully`);
            setIsDelete('deleted')
            setOpenDelete(false)
        }).catch(err => {
            setIsDelete('rejected')
            errorMessages(err, null, actions)
        })
    };


    const handleSearch = (value) => {
        setSearchValue(value)
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const isLoading = loading === 'loading'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'

    const {openBar, type, message} = snackbars;
    const {status} = item;
    return (
        <div data-testid="medicine-type-list">
            <PageTitle title="Medicine Types"/>
            <SubHeader title="Pharmacy" subTitle="Medicine Types">
                <FontAwesomeIcon icon={faMortarPestle}/>
            </SubHeader>
            <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>
                <DeleteDialog message={status === 1 ?  'deactivate':'activate'} openDialog={openDelete} handleClose={handleCloseDelete}
                                    text="medicine type" title={`${status === 1 ? 'Deactivate':'Activate'} Medicine Type`}>
                        <button className={`btn btn-sm ${status === 1 ? 'sms-gray-btn' : 'sms-info-btn'}`} onClick={handleDelete}>{status === 1 ? 'Deactivate':'Activate'}</button>
                        <button className="btn sms-grey-btn btn-sm" onClick={handleCloseDelete}>No</button>    
                </DeleteDialog>
            {renderAddMedicineType}
            <Container>
                <div className='general-ledger-header'>
                    <RightAlignedContainer>
                        <button onClick={handleOpenDialog} className='btn btn-sm sms-info-btn'>New Medicine Type</button>
                    </RightAlignedContainer>
                </div>
                <CustomTable  title="Medicine Type List" handleChangePage={handleChangePage}
                              handleChangeRowsPerPage={handleChangeRowsPerPage}
                              headData={headData} handler={handleSearch} page={page} rowsPerPage={rowsPerPage}
                              handleRequestSort={handleRequestSort} data={typeList} colSpan={headData.length} order={order}
                              orderBy={orderBy} id="searchValue" filter>
                    <FilterResults value={searchValue} data={typeList}
                       renderResults={results => (
                           <TableBody>
                               {isLoading ? <TableRow>
                                   <TableCell colSpan={headData.length} align="center"><LoadingGif/></TableCell>
                               </TableRow> :  null}
                               {isSuccess ? typeList.length > 0 ?  stableSort(results, getComparator(order, orderBy))
                                   .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                   .map((item, index) => {
                                       const count = (page * rowsPerPage) + index + 1;
                                       return (
                                           <TableRow
                                               hover
                                               role="checkbox"
                                               tabIndex={-1}
                                               key={item.product_type_id}
                                               className='mui-table-row'
                                               id="unit"
                                           >
                                               <TableCell>{count}</TableCell>
                                               <TableCell>{item.type_name}</TableCell>
                                               <TableCell>
                                                   <button onClick={()=>handleOpenEdit(item.product_type_id)} className="btn btn-sm sms-info-btn mr-1">Edit</button>
                                                   <button onClick={()=>handleOpenDelete(item)}
                                                           className={`btn btn-sm ${item.status === 1 ? 'sms-gray-btn' : 'sms-info-btn'}`}> {item.status === 1 ? 'Deactivate':item.status === 0 ?  'Activate':'Deactivate'}</button>
                                               </TableCell>
                                           </TableRow>
                                       );
                                   }): <TableRow>
                                   <TableCell colSpan={headData.length} align="center">No records found</TableCell>
                               </TableRow>: null}
                               {isError ? <TableRow>
                                   <TableCell colSpan={headData.length} align="center">The server did not return a valid response</TableCell>
                               </TableRow>: null}
                           </TableBody>
                    )}/>
                </CustomTable>
            </Container>


        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(MedicineTypeList);
