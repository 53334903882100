import React, {useEffect,  useState} from "react";
import {CustomTablePagination, EnhancedSearchTableToolbar} from "../../Utils/Tables/CustomTable";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import *  as snackbarActions from "../../../actions/snackbarActions";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {Container} from "../../Utils/styledComponents";
import axios from "axios";
import {config} from "../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {LoadingGif} from "../../Utils/Loader";
import {Link} from "react-router-dom";
import {CustomTableCell, MuiPaper, StickyHeadTable} from "../../Utils/Tables/StickyHeaderTable";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClipboardList} from "@fortawesome/free-solid-svg-icons/faClipboardList";
import {BackButton} from "../../Utils/Buttons/DataExportationButton";
import {AccountingDateFilter} from "../../Utils/AccountingUtils/AccountingDateFilter";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Label from "../../Utils/FormInputs/Label";
import SelectInput from "../../Utils/FormInputs/SelectInput";
import {getMonth} from "./BalanceSheet";
import {formatDigits} from "../../Utils/formatNumbers";
import FilterResults from "react-filter-search";



const BalanceSheetReportTransactions = ({actions, snackbars, match:{params}}) => {
    const {category_id, category_name, from_date:start_date, to_date:end_date} = params
    const [date, setDate] = useState({from_date: start_date, to_date: end_date});
    const [searchValue, setSearchValue] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isSubmitted, setIsSubmitted] = useState('idle');;
    const [data, setData] = useState({revenueList:[], loading:'idle', total:0});
    const [submitted, setSubmitted] = useState(false);
    const [activePage, setActivePage] = useState(1)
    const [category, setCategory] = useState('monthly')


    const {from_date, to_date} = date;

    const {loading, total, revenueList} = data


    const formatData = (arr=[]) =>{
        return arr.map(item=>({
            ...item,
            previous_total:item.previous_total ? formatDigits(item.previous_total) : item.previous_total,
            current_total:item.current_total ? formatDigits(item.current_total) : item.current_total,
            change_total:item.change_total ? formatDigits(item.change_total) : item.change_total,

        }))
    }


    useEffect(() => {
        setData({...data, loading: 'pending'})
        axios.get(`${config.smsUrl}/accounting/new_balance_sheet/category`, {
            params: {
                start_date: from_date,
                end_date: to_date,
                category: category,
                account:category_id,
                page:activePage
            }
        }).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const dat = dt.data ? dt.data : {}
            const arr = dat.children ? dat.children : []
            const total_count = dat.total_pages ? dat.total_pages : 0
            setData({...data, loading: 'success', revenueList: formatData(arr), total: total_count})
        }).catch((err) => {
            setData({...data, loading: 'error'})
            logoutErrorMessage(err, null, actions);
        })
    }, [category, activePage]);

    const handleSubmit = (event) => {
        event.preventDefault();
        setSubmitted(true)
        if (from_date && to_date) {
            setIsSubmitted('pending')
            setData({...data, loading: 'pending'})
            axios.get(`${config.smsUrl}/accounting/new_balance_sheet/category`, {
                params: {
                    start_date: from_date,
                    end_date: to_date,
                    category: category,
                    account:category_id,
                    page:activePage
                }
            }).then(res => {
                const data = res.data;
                const dt = !data ? {} : data;
                const dat = dt.data ? dt.data : {}
                const arr = dat.children ? dat.children : []
                const total_count = dat.total_pages ? dat.total_pages : 0
                setData({...data, loading: 'success', revenueList: formatData(arr), total: total_count})
                setIsSubmitted('resolved')
            }).catch(err => {
                setIsSubmitted('rejected')
                errorMessages(err, null, actions);
                setData({...data, loading: 'error'})
            })
        }
    }

    const handleChangeDate = (event) => {
        const {value, name} = event.target;
        setDate({...date, [name]: value})
    }

    const handleChangeCategory = (event) =>{
        setCategory(event.target.value)
    }


    const handleSearch = (event) => {
        setSearchValue(event.target.value);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setActivePage(newPage+1)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        setActivePage(1);
    };

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };



    const {openBar, type, message} =snackbars;

    const isLoading = loading === 'pending'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'

    const isPending = isSubmitted === 'pending'

    const pagination = (
        <CustomTablePagination
            colSpan={3}
            activePage={activePage}
            page={page}
            rowsPerPage={rowsPerPage}
            count={total}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            isTotalCount
        />
    );

    const period = getMonth(date.from_date, date.to_date,category)[category]

    const headData = [
        {id: 'count', numeric: false, disablePadding: false, label: 'Sl'},
        {id: 'account_name', numeric: false, disablePadding: false, label: 'Account'},
        {id: 'previous_total', numeric: false, disablePadding: false, label: `${period.previous_period} Total`},
        {id: 'current_total', numeric: false, disablePadding: false, label: `${period.current_period} Total`},
        {id: 'change_total', numeric: false, disablePadding: false, label: 'Balance'}
    ];

    const search_bar = (
        <EnhancedSearchTableToolbar
            term={searchValue} handler={handleSearch}
        />
    )
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, revenueList.length - page * rowsPerPage);

    return (
        <div data-testid={`revenue-per-department-report`}>
            <PageTitle title="Balance Sheet"/>
            <SubHeader title="Reports" subTitle={`${category_name} transactions`}>
                <FontAwesomeIcon icon={faClipboardList}/>
            </SubHeader>
            <Container>
                <BackButton to='/accounting/accreports/balancesheet' text='Balance sheet'/>
                <AccountingDateFilter {...{from_date,to_date, handleChange:handleChangeDate,
                    submitted,handleSubmit,isPending}}>
                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={2} lg={2} xl={2}>
                            <Label name='Category'  type/>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                            <SelectInput onChange={handleChangeCategory} options={[{value:'monthly', text:'Monthly'},
                                {value:'yearly', text:'Yearly'}]} value={category}/>
                        </Col>
                    </Form.Group>
                </AccountingDateFilter>
                <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>
                <MuiPaper>
                    {search_bar}
                    <StickyHeadTable {...{width:'100%',data:revenueList, columns:headData.filter(item=>Boolean(item)), pagination}}>
                        <FilterResults value={searchValue} data={revenueList}
                                       renderResults={results => (
                           <React.Fragment>
                                {isLoading ? <TableRow><TableCell align='center' {...{colSpan:headData.length}}><LoadingGif/></TableCell></TableRow>:null}
                                {isSuccess ? results.length > 0 ?
                                    <>
                                        {results.slice(0, 10).map((row, index) => {
                                            const count = ((activePage - 1) * 10) + index + 1;
                                            return (
                                                <TableRow  tabIndex={-1} key={index}>
                                                    {headData.map(col=>(
                                                        col.id === 'count' ? <CustomTableCell>{count}</CustomTableCell>:
                                                            <CustomTableCell>{row[col.id]}</CustomTableCell>
                                                    ))}
                                                </TableRow>
                                            );
                                        })}
                                        {emptyRows > 0 && (
                                            <TableRow style={{ height: 20 * emptyRows }}>
                                                <TableCell colSpan={headData.length} />
                                            </TableRow>
                                        )}
                                    </>:<TableRow><TableCell align='center'  {...{colSpan:headData.length}}>No records found</TableCell></TableRow>:null}
                                {isError ? <TableRow><TableCell align='center'  {...{colSpan:headData.length}}>The server did not return a valid response</TableCell></TableRow>:null}
                           </React.Fragment>

                        )}/>
                    </StickyHeadTable>
                </MuiPaper>
            </Container>

        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(BalanceSheetReportTransactions);
