import React from 'react'
import Form from 'react-bootstrap/Form';
import {CustomSelect} from "../../Utils/FormInputs/SelectInput";
import TextField from '../../Utils/FormInputs/TextField';
import Col from 'react-bootstrap/Col';
import Label from "../../Utils/FormInputs/Label";
import {ErrorMessage} from "../../Utils/styledComponents";

function AccountSetUpForm({rows, entities, isAllAccounts,
    accounts,
    handleChangeCreditAccount, handleChangeDebitAccount, handleSubmit, 
     isPending,  handleAddRow, handleRemoveRow,submitted,
    handleChangeEntity, component, headData=[]}) {
  return (
        <form onSubmit={handleSubmit} autoComplete='off'>
            {/* <table  className='table table-borderless table-sm'>
                <tbody>
                    <tr>
                        <td width='220px' style={{verticalAlign:'middle'}}>
                            <Form.Check value={isAllAccounts} onChange={handleChange} label={`Same accounts for all ${component}`}/>
                        </td>
                        <td width='100px' valign='middle' style={{verticalAlign:'middle'}}> Debit Account</td>
                        <td width='300px'>
                            <CustomSelect value={debitAccount} options={accounts} isDisabled={!isAllAccounts}
                                            onChange={handleChangeAllDebitAccount}/>
                        </td>
                        <td width='20px'/>
                        <td width='100px' style={{verticalAlign:'middle'}}>Credit Account</td>
                        
                        <td width='300px'>
                        <CustomSelect value={creditAccount} options={accounts} isDisabled={!isAllAccounts}
                                            onChange={handleChangeAllCreditAccount}/>
                        </td>
                    </tr>
                </tbody>
            </table> */}
            <div className='table-responsive'>
                <table className="table table-bordered">
                    <thead>
                    <tr>
                        {headData.map((item, index)=>(
                            <td key={index} width={ item.isAction ? '100px':''}>{item.name} {item.isRequired ?  <span className="help-block">*</span>:null}</td>
                        ))}
                    </tr>
                </thead>
                <tbody>
                {rows.map((item, index) => (
                    <tr key={index}>
                        <td>
                        <CustomSelect value={item.id} options={entities} isDisabled={isAllAccounts}
                                            onChange={value => handleChangeEntity(value, index)} submitted={submitted}/>
                            {(submitted && !item.id) ? <ErrorMessage>This field  is required</ErrorMessage>:null}
                        </td>
                        {/* <td><Textarea value={item.description}
                                        name='description'/></td> */}
                        <td>
                            <CustomSelect value={item.debit_account} options={accounts} isDisabled={isAllAccounts}
                                            onChange={value => handleChangeDebitAccount(value, index)} submitted={submitted}/>
                            {(submitted && !item.debit_account) ? <ErrorMessage>Debit account is required</ErrorMessage>:null}
                            {/* {(submitted && !item.debit_account) &&
                            <ErrorMessage>Select account</ErrorMessage>} */}
                        </td>
                        <td>
                            <CustomSelect value={item.credit_account} options={accounts} isDisabled={isAllAccounts}
                                            onChange={value => handleChangeCreditAccount(value, index)} submitted={submitted}/>
                            {(submitted && !item.credit_account) ? <ErrorMessage>Credit account is required</ErrorMessage>:null}
                        {/* {(submitted && !item.credit_account) &&
                            <ErrorMessage>Select account</ErrorMessage>} */}
                            </td>
                            <td>
                                <button type='button' onClick={()=>handleRemoveRow(index)} className='btn btn-sm sms-btn-dismiss'>Remove</button>
                            </td>
                        </tr>
                    ))}
                    <tr>
                        <td colSpan={4}> <button type='button' onClick={handleAddRow} className='btn btn-sm sms-gray-btn'>Add another line</button></td>

                    </tr>
                    </tbody>
                </table>
            </div>

            <button type='submit' disabled={isPending}  className='btn btn-sm sms-btn'>{isPending ? 'Submitting...':'Submit'}</button>
        </form>
  )
}

function EditSetupForm({value, debit, credit, isPendingEdit, handleChangeCredit,
     handleChangeDebit, handleEditLedger, item_name, accounts}) {
    return(
        <form autoComplete='off' onSubmit={handleEditLedger}>
        <Form.Group as={Col}>
            <Label name={item_name}/>
            <TextField readOnly value={value}/>
        </Form.Group>
        <Form.Group as={Col}>
            <Label name='Debit Ledger'/>
            <CustomSelect value={debit} options={accounts} onChange={handleChangeDebit}/>
        </Form.Group>
        <Form.Group as={Col}>
            <Label name='Credit Ledger'/>
            <CustomSelect value={credit} options={accounts} onChange={handleChangeCredit}/>
        </Form.Group>
        <button type='submit' disabled={isPendingEdit} className='btn btn-sm sms-btn'>{isPendingEdit ? 'Submitting...' :'Submit'}</button>
    </form>
    )
}

export  {AccountSetUpForm, EditSetupForm}