import {useEffect, useState} from "react";
import axios from "axios";
import { useAddSubscriber } from "./useAddSubscriber";
import { config } from "../../../Helpers/env";
import { useSnackbar } from "../../../Context/snackbar-context";
import { useVersionsContext } from "../../../Context/versions-context";
import { errorMessages } from "../../../Helpers/ErrorMessages";


export const useCreateSubscription = (actions) => {
    const {handleSuccess, handleError,handleInformation} = useSnackbar()
    const [loading, setLoading] = useState('')
    const [subName, setSubname] = useState('')
    const [version, setVersion] = useState('')
    const [subType, setSubType] = useState('')
    const [subscribers, setSubscribers] = useState([]);
    const [subscriptions, setSubsriptions] = useState({})
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchValue, setSearchValue] = useState('');
    const versions = [{value:"Basic", label: "Basic"},{value: "Standard" ,label: "Standard"}, {value:"Premium", label: "Premium"}]
    const sub_types = [{value: "Monthly", label: "Monthly"}, {value: "Yearly", label: "Yearly"}, {value: "Lifetime", label: "Lifetime"}]
    const {handleOpenDialog, handleSubmit:handleSubmitSubscriber, title, open, name, handleClose, submitted,isPending} = useAddSubscriber(actions)
    

    useEffect(() => {
        axios.get(`${config.smsUrl}/cuser/get_all_subscriptions`).then(res =>{
            const data = res.data; // Assign res.data to data
            
            const dt = !data ? [] : data;
            setSubname(dt[0].subscriber_name)
            setSubsriptions(dt[0]);
            
        }).catch(err => {
            handleInformation("Something went wrong. Perhaps you havent setup any version")
        });
    },[loading]);

    

    const handleChangeSubType = (value) =>{
        setSubType(value)
    }

    const handleChangeSub = (e) =>{
        
        setSubname(e.currentTarget.value)
    }
    
    const handleChangeVersion = (value) => {
        
        setVersion(value)
    }


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleSearch = (value) => {
        setSearchValue(value)
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData()
        formData.append("version", version.value)
        formData.append("subscription_type", subType.value)
        formData.append("subscriber_name", subName)
        if(subName && subType && version){
            setLoading('loading');
            
            axios.post(`${config.smsUrl}/cuser/create_subscription`, formData).then(()=>{
                handleSuccess('Subscriber added successfully')
                setLoading('success');
                setSubname('')
                setSubType('')
                setVersion('')
                setSubscribers({})
            }).catch(err=>{
                setLoading('error');
                handleError("Something went wrong. Please try again later")
            })
        }
    }

    

    const isLoading = loading === 'loading'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'

    return {
  
        isSuccess, isLoading, loading, isError, handleSubmitSubscriber,title, open, name, handleClose, isPending, submitted, handleOpenDialog, subscribers, handleChangeSub, handleChangeVersion, handleChangeSubType, handleSubmit, versions, sub_types, subName, subType, version, handleChangePage, handleChangeRowsPerPage, page, rowsPerPage, subscriptions, searchValue, handleSearch
    }
}