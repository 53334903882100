import * as types from "../actions/actionTypes"

const initialState = {
    activeStep: 0
};

export default function steppersReducer(state = initialState, action) {
    switch (action.type) {
        case types.NEXT_STEP:
            return {...state,activeStep: state.activeStep + 1};
        case types.PREVIOUS_STEP:
            return {...state,activeStep: state.activeStep - 1};
        default:
            return state;

    }
}
