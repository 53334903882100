import RegisterPatient from "./components/PatientRecords/RegisterPatients";
import MainPage from "./components/Main/MainPage";
import PatientProfile from "./components/PatientRecords/PatientProfile";
import PatientDetails from "./components/PatientRecords/PatientDetails";
import PrescriptionList from "./components/Prescription/PrescriptionList";
import PaymentList from "./components/Billing/out-patient/BillingList";
import ScanRadiology from "./components/Radiology/scanradiology";
import AddScanRequest from "./components/Radiology/addScanRequest";
import ViewResults from "./components/Radiology/ViewResults";
import FileUpload from "./components/Radiology/addFileUpload";
import AddBill from "./components/Billing/out-patient/AddBill";
import NewVisit from "./components/Visit/NewVisit/NewVisit";
import PreviousVisits from "./components/Visit/PreviousVisits";
import PatientWaitingList from "./components/DoctorsModule/WaitingList";
import EmployeeList from "./components/HRM/EmployeeList";
import BillingReceipt from "./components/Billing/out-patient/BillingReceipt";
import RefundReceipt from "./components/Billing/out-patient/RefundReceipt";
import FillPrescription from "./components/Prescription/FillPrescription";
import AddService from "./components/HospitalServices/AddService";
import ServiceList from "./components/HospitalServices/ServiceList";
import EditService from "./components/HospitalServices/EditService";
import LabRequestList from "./components/Lab/LabRequestList";
import OutPatientTriage from "./components/DoctorsModule/Triage/OutPatientTriage";
import ExaminationNotes from "./components/DoctorsModule/Notes/ExaminationNotes";
import DoctorPrescription from "./components/DoctorsModule/Prescription/Prescription";
import MainRadiology from "./components/DoctorsModule/Radiology/MainRadiology";
import AddUser from "./components/Settings/AddUser";
import UsersList from "./components/Settings/UsersList";
import EditUser from "./components/Settings/EditUser";
import AssignRole from "./components/Settings/AssignRole";
import RolesList from "./components/Settings/RolesList";
import UserRoles from "./components/Settings/UserRolesList";
import EditAssignedRole from "./components/Settings/EditUserRoles";
import AddRole from "./components/Settings/AddRole";
import CollectSample from "./components/Lab/CollectSample";
import EnterResults from "./components/Lab/EnterResult";
import LabResultsList from "./components/Lab/ResultList";
import EditLabResults from "./components/Lab/EditResults";
import GeneralLabReport from "./components/Lab/GeneralLabReport";
import EmployeeProfile from "./components/HRM/EmployeeProfile";
import PendingSamples from "./components/Lab/PendingSamples";
import PrescriptionReport from "./components/DoctorsModule/Prescription/PrescriptionReport";
import LabReports from "./components/Lab/LabReports";
import ChangePassword from "./components/Users/ChangePassword";
import ScanResultsList from "./components/Radiology/ScanResultsList";
import WardList from "./components/BedManager/WardList";
import RoomList from "./components/BedManager/RoomList";
import BedList from "./components/BedManager/BedList";
import BedAssignList from "./components/BedManager/BedAssignList";
import BedTransferList from "./components/BedManager/BedTransferList";
import DoctorLabResultList from "./components/DoctorsModule/DoctorLabResults";
import AddShiftCategory from "./components/ShiftsandSchedules/AddShiftCategory";
import ShiftCategoryList from "./components/ShiftsandSchedules/ShiftCategoryList";
import AddSchedule from "./components/ShiftsandSchedules/AddSchedule";
import ScheduleList from "./components/ShiftsandSchedules/ScheduleList";
import PatientAdmissionForm from "./components/Visit/NewAdmission/PatientAdmissionForm";
import AdmissionList from "./components/Billing/in-patient/AdmissionList";
import PatientBillingList from "./components/Billing/in-patient/InpatientBillingList";
// import InpatientBillForm from "./components/Billing/in-patient/InpatientBillForm";
import InpatientBillingReceipt from "./components/Billing/in-patient/InpatientBillingReceipt";
// import PatientMedicationList from "./components/MedicationandVisits/MedicationList";
import RequisitionList from "./components/Inventory/stock/RequisitionList";
import AddRequisition from "./components/Inventory/stock/AddRequisition";
// import DischargeForm from "./components/Visit/DischargeForm ";
import ViewRequisition from "./components/Inventory/stock/ViewRequisition";
import EditRequisition from "./components/Inventory/stock/EditRequisition";
import ProductDispatchList from "./components/Inventory/stock/ProductDisptachList";
import DispatchNote from "./components/Inventory/stock/DispatchNote";
import EditDispatchNote from "./components/Inventory/stock/EditDispatchNote";
import PurchaseOrderList from "./components/Inventory/purchase/PurchaseOrderList";
import AddPurchaseOrder from "./components/Inventory/purchase/AddPurchaseOrder";
import ViewPurchaseOrder from "./components/Inventory/purchase/ViewPurchaseOrder";
import PurchaseList from "./components/Inventory/purchase/PurchaseList";
import AddPurchase from "./components/Inventory/purchase/AddPurchase";
import StockList from "./components/Inventory/Stores/StockList";
import ProductList from "./components/Inventory/products/ProductList";
import AddProduct from "./components/Inventory/products/AddProduct";
import EditAdmission from "./components/Visit/NewAdmission/EditAdmission";
import DischargeNote from "./components/Visit/NewAdmission/DischargeNote";
import ScanAndLabResults from "./components/MedicationandVisits/ScanAndLabResults";
import InternalPrintableReferralNote from "./components/DoctorsModule/ReferralNote/InternalPrintableNote";
import OutgoingReferralPrint from "./components/DoctorsModule/ReferralNote/OutgoingPrintableReferralNote";
import SupplierList from "./components/Inventory/purchase/SupplierList";
import DoctorSchedule from "./components/DoctorsModule/DoctorSchedule";
import PharmacyStock from "./components/Pharmacy/PharmacyStock";
import ConfirmItems from "./components/Pharmacy/ConfirmItems";
import DeathReport from "./components/Reports/OtherReports/DeathReport";
import RegisterDeath from "./components/Reports/OtherReports/RegisterDeath";
import InpatientTreatment from "./components/MedicationandVisits/InpatientTreatment";
import ParameterList from "./components/Lab/ParameterList";
import MainDepartment from "./components/Departments/MainDepartments";
import ReceivedItems from "./components/Pharmacy/ReceivedItems";
import PatientPreviousVisits from "./components/DoctorsModule/PatientPreviousVisits";
import AnteNatalPreviousVisits from "./components/DoctorsModule/AnteNatalPreviousVisits";
import EditDeath from "./components/Reports/OtherReports/EditDeath";
import ViewDeathReport from "./components/Reports/OtherReports/ViewDeathReport";
import TotalPositivesAndNegatives from "./components/Reports/LabReports/TotalPositivesAndNegatives";
import ExpiredProducts from "./components/Reports/InventoryReports/ExpiredProducts";
import StockStatusReport from "./components/Reports/InventoryReports/StockStatusReport";
import ProductTrends from "./components/Reports/InventoryReports/ProductTrends";
import MedicineUse from "./components/Reports/InventoryReports/MedicineUse";
import Support from "./components/Settings/Support";
import StockAdjustmentList from "./components/Inventory/stock/StockAdjustmentList";
import StockAdjustmentDetails from "./components/Inventory/stock/StockAdjustmentDetails";
import TriageMetricList from "./components/Triage/TriageMetricsList";
import ChangeUserPassword from "./components/Settings/ChangeUserPassword";
import MedicationTriage from "./components/MedicationandVisits/MedicationTriage";
import AddCompanyInfo from "./components/Settings/AddCompanyInfo";
import VisitList from "./components/Visit/NewVisit/VisitList";
import ChartOfAccounts from "./components/Accounting/ChartofAccounts/ChartOfAccounts";
import BalanceSheet from "./components/Accounting/Reports/BalanceSheet";
import Journals from "./components/Accounting/ChartofAccounts/Journals";
import GeneralReports from "./components/Reports/GeneralReports";
import NewLedgerEntry from "./components/Accounting/ChartofAccounts/NewLedgerEntry";
import ProfitLoss from "./components/Accounting/Reports/ProfitLossStatement";
import ProductSalesReport from "./components/Reports/FinancialReports/ProductSalesReport";
import AccountReceivables from "./components/Accounting/Reports/AccountReceivables";
import AccountsPayables from "./components/Accounting/Reports/AccountsPayables";
import AccountingPurchaseReport from "./components/Reports/InventoryReports/AccountingPurchaseReport";
import AccountingPeriod from "./components/Accounting/Setup/AccountingPeriod";
import Creditors from "./components/Billing/debtors/Creditors";
import CreditorsLedger from "./components/Billing/debtors/CreditorsLedger";
import ClearOutPatientDebt from "./components/Billing/debtors/ClearOutPatientDebt";
import CreditorDetails from "./components/Billing/debtors/CreditorDetails";
import RevenuePerService from "./components/Reports/RevenuePerService";
import PurchaseOrderApproval from "./components/Inventory/purchase/PurchaseOrderApproval";
import Expenses from "./components/Accounting/Expenses/Expenses";
import CreateInvoice from "./components/Billing/out-patient/CreateInvoice";
import DoctorDiagnosis from "./components/DoctorsModule/Diagnosis/DoctorDiagnosis";
import PrescriptionTab from "./components/DoctorsModule/Prescription/Prescription";
import TreatmentDetails from "./components/DoctorsModule/TreatmentDetails";
import ReferralNote from "./components/DoctorsModule/ReferralNote/ReferralNote";
import ViewAllReceipts from "./components/Billing/out-patient/ViewAllReceipts";
import ViewBedTransfer from "./components/BedManager/ViewBedTransfer";
import ViewInPatientBill from "./components/Billing/in-patient/ViewInPatientBill";
import DoctorDischargeForm from "./components/MedicationandVisits/DoctorDischargeForm";
import RevenuePerDepartment from "./components/Reports/FinancialReports/RevenuePerDepartment";
import RevenuePerProcedure from "./components/Reports/FinancialReports/RevenuePerProcedure";
import RevenuePerCashier from "./components/Reports/FinancialReports/RevenuePerCashier";
import RevenuePerPaymentMode from "./components/Reports/FinancialReports/RevenuePerPaymentMode";
import OutofStock from "./components/Reports/InventoryReports/OutofStock";
import LabReportDetails from "./components/Reports/LabReports/LabReportsDetails";
import DetailedRadiologyReport from "./components/Reports/RadiologyReports/DetailedRadiologyReport";
// import MohReports from "./components/Reports/OtherReports/MOHReports";
import SurgeryMOH from "./components/Reports/OtherReports/SurgeryMOH";
import DoctorAdmission from "./components/DoctorsModule/Admission/DoctorAdmission";
import AdmissionFormTemplate from "./components/Visit/NewAdmission/AdmissionFormTemplate";
import InvoiceReceipt from "./components/Billing/debtors/Invoice";
import EmergencyStockList from "./components/PatientEmergency/EmergencyStockList";
import EmergencyDispatchList from "./components/PatientEmergency/EmergencyDispatchList";
import LabTypeList from "./components/Lab/LabTypeList";
import SampleTypes from "./components/Lab/SampleTypes";
import TotalRevenueGeneratedReport from "./components/Reports/FinancialReports/TotalRevenueGeneratedReport";
import ConsultaionList from "./components/Visit/NewVisit/ConsultaionList";
import InvoiceProviders from "./components/Billing/customers/InvoiceProviders";
import AdmissionsWithoutBeds from "./components/BedManager/AdmissionsWithoutBeds";
import SurgeryVisit from "./components/Visit/Surgery/SurgeryVisit";
import Surgery from "./components/DoctorsModule/Surgery/Surgery";
import CurrentSurgeries from "./components/SurgeryModule/CurrentSurgeries";
import PastSurgeries from "./components/SurgeryModule/PastSurgeries";
import RecordPatient from "./components/PatientEmergency/RecordPatient";
import EmergencyList from "./components/PatientEmergency/EmergencyList";
import OutpatientPrescriptionList from "./components/PatientEmergency/OutpatientPrescriptionList";
import PatientPreviousVisitInfo from "./components/DoctorsModule/PatientPreviousVisitInfo";
import ViewPurchase from "./components/Inventory/purchase/ViewPurchase";
import ProductLog from "./components/Reports/FinancialReports/ProductLog";
import CustomerTransactions from "./components/Billing/customers/CustomerTransactions";
import ViewJournalTransactions from "./components/Accounting/ChartofAccounts/ViewJournalTransactions";
import PatientFindings from "./components/MedicationandVisits/Findings/PatientFindings";
import SetUpSuppliers from "./components/Accounting/Setup/SetUpSuppliers";
import SetupServices from "./components/Accounting/Setup/SetupServices";
import SetupProducts from "./components/Accounting/Setup/SetupProducts";
import PatientsSeen from "./components/DoctorsModule/PatientsSeen";
import DischargeList from "./components/MedicationandVisits/DischargeList";
import DischargedPatientDetails from "./components/MedicationandVisits/DischargedPatientDetails";
import RefundBill from "./components/Billing/out-patient/RefundBill";
import CompleteSurgeryDetails from "./components/SurgeryModule/CompleteSurgeryDetails";
import OutPatientProcedure from "./components/DoctorsModule/Procedure/OutPatientProcedure";
import InPatientProcedure from "./components/DoctorsModule/Procedure/InPatientProcedure";
import OutPatientLab from "./components/DoctorsModule/Lab/OutPatientLab";
import InPatientLab from "./components/DoctorsModule/Lab/InPatientLab";
import InPatientRadiology from "./components/DoctorsModule/Radiology/InPatientRadiology";
import OutPatientRadiology from "./components/DoctorsModule/Radiology/OutPatientRadiology";
import ViewReferal from "./components/DoctorsModule/ReferralNote/ViewReferal";
import InpatientBillingInvoice from "./components/Billing/in-patient/InpatientBillingInvoice";
import InpatientBillInvoice from "./components/Billing/in-patient/InpatientBillInvoice";
import ExpenseItems from "./components/Accounting/Expenses/ExpenseItems";
import CensusInformationTable from "./components/Reports/OtherReports/WardReport";
import ReturnList from "./components/Inventory/stock/ReturnList";
import ReturnProducts from "./components/Inventory/stock/ReturnProducts";
import ReturnReport from "./components/Inventory/stock/ReturnReport";
import ProviderServicesReport from "./components/Reports/OtherReports/ProviderServicesReport";
import ANCLists from "./components/MotherChild/AnteNatal/ANCLists/ANCLists";
import AnteNatalPatientForm from "./components/MotherChild/AnteNatal/ANCScreen/AnteNatalPatientForm";
import AnteNatalTriage from "./components/MotherChild/AnteNatal/ANCScreen/AnteNatalTriage";
import AnteNatalLabRequest from "./components/MotherChild/AnteNatal/ANCScreen/AnteNatalLabRequest";
import AnteNatalAdmission from "./components/MotherChild/AnteNatal/ANCScreen/AnteNatalAdmission";
import AnteNatalReferralNote from "./components/MotherChild/AnteNatal/ANCScreen/AnteNatalReferralNote";
import AnteNatalRadiology from "./components/MotherChild/AnteNatal/ANCScreen/AnteNatalRadiology";
import AnteNatalPrescription from "./components/MotherChild/AnteNatal/ANCScreen/AnteNatalPrescription";
import AnteNatalDiagnosis from "./components/MotherChild/AnteNatal/ANCScreen/AnteNatalDiagnosis";
import AnteNatalTreatmentDetails from "./components/MotherChild/AnteNatal/ANCScreen/AnteNatalTreatmentDetails";
import PaidInvoices from "./components/Billing/out-patient/PaidInvoices";
import BilledInvoicesReceipt from "./components/Billing/out-patient/BilledInvoicesReceipt";
import AssignShifts from "./components/ShiftsandSchedules/AssignShifts";
import StockSummary from "./components/Reports/InventoryReports/StockSummary";
import StockBatchWiseReport from "./components/Reports/InventoryReports/StockBatchwiseReport";
import AccountsPayablesDetails from "./components/Accounting/Reports/AccountsPayableDetails";
import DebtorsReportDetails from "./components/Accounting/Reports/DebtorsReportDetails";
import AddDispatch from "./components/Inventory/Stores/AddDispatch";
import DispatchedItems from "./components/Pharmacy/DispatchedItems";
import PharmacyDispatchList from "./components/Pharmacy/PharmacyDispatchList";
import QueueList from "./components/Queue/QueueList";
import ItemsProviderAllow from "./components/Billing/customers/ItemsProviderAllow";
import CompanyDetails from "./components/Settings/CompanyDetails";
import TriageWaitingList from "./components/Triage/TriageWaitingList";
import TakeVitals from "./components/Triage/TakeVitals";
import ViewBill from "./components/Billing/out-patient/ViewBill";
import ConsumablesList from "./components/HospitalServices/ConsumablesList";
import AddInitialStock from "./components/Inventory/Stores/AddInitialStock";
import DiagnosesReport from "./components/Reports/OtherReports/DiagnosesReport";
import FixedAssetList from "./components/Accounting/FixedAssets/FixedAssetList";
import NewFixedAsset from "./components/Accounting/FixedAssets/NewFixedAsset";
import ReverseInvoices from "./components/Billing/debtors/ReverseInvoices";
import FixedAssetDetails from "./components/Accounting/FixedAssets/AssetDetails";
import DispenseConsumables from "./components/Prescription/DispenseConsumables";
import UnitList from "./components/Inventory/products/UnitList";
import MedicineTypeList from "./components/Inventory/products/MedicineTypeList";
import NewServicePrescriptionRequest from "./components/Visit/NewService/NewServicePrescriptionRequest";
import LabStockList from "./components/Lab/stock/LabStock";
import TrialBalance from "./components/Accounting/Reports/TrialBalance";
import GeneralLedger from "./components/Accounting/Reports/GeneralLedger";
import ReferralReport from "./components/Reports/OtherReports/ReferralReport";
import OutwardReferrals from "./components/Reports/OtherReports/OutwardReferrals";
import CanceledPurchases from "./components/Inventory/purchase/CanceledPurchases";
import PatientTransactionDetails from "./components/Reports/FinancialReports/PatientTransactionDetails";
import InpatientBillFormTwo from "./components/Billing/in-patient/InpatientBillForm";
import AccountReceivablesAgeingSummary from "./components/Accounting/Reports/DebtorsAgeingSummary";
import InsuranceSummaryReport from "./components/Reports/FinancialReports/InsuranceSummaryReport";
import SchemeList from "./components/Billing/customers/SchemeList";
import DentalList from "./components/Dental/DentalList";
import DentalMedicalHistory from "./components/Dental/DentalMedicalHistory";
import DentalExamination from "./components/Dental/DentalExamination";
import DentalServicesRequest from "./components/Dental/DentalServicesRequest";
import DraftedPurchases from "./components/Inventory/purchase/DraftedPurchases";
import ApprovalChecksList from "./components/Billing/customers/ApprovalChecksList";
import ChronicDiseasesReport from "./components/Reports/MedicalReports/ChronicDiseasesReport";
import ServicesPerformedByAgeGroupReport from "./components/Reports/MedicalReports/ServicesPerformedByAgeGroupReport";
import NumberofServicesPerformed from "./components/Reports/MedicalReports/NumberofServicesPerformed";
import FastMovingStockReport from "./components/Reports/InventoryReports/FastMovingStockReport";
import DepartmentRevenueDetails from "./components/Reports/FinancialReports/DepartmentRevenueDetails";
import PreviousBedAssignments from "./components/BedManager/PreviousBedAssignments";
import RequestedAntenatalServices from "./components/DoctorsModule/Antenatal/RequestedAntenatalServices";

import ExpenseReceiptDetails from "./components/Accounting/Expenses/ExpenseReceiptDetails";
import DepreciateFixedAssets from "./components/Accounting/FixedAssets/DepreciateFixedAssets";
import BalanceSheetReportTransactions from "./components/Accounting/Reports/BalanceSheetReportTransactions";
import PaidAccountReceivables from "./components/Accounting/Reports/PaidAccountReceivables";
import PaidAccountsPayables from "./components/Accounting/Reports/PaidAccountsPayables";
import SetupCurrencies from "./components/Settings/SetupCurrencies";
import CreateSubscription from "./components/Settings/CreateSubscription";
import DentalPrescription from "./components/Dental/DentalPrescription";
import ReverseInpatientBill from "./components/Billing/in-patient/ReverseInpatientBill";
import { components } from "react-select/dist/react-select.cjs.prod";
import AccountsList from "./components/Accounting/ChartofAccounts/AccountsList";

export const routes = [
  { path: "/", exact: true, component: MainPage },
  { path: "/currencysetup", component: SetupCurrencies },
  { path: "/accounting/accounts/list", component: AccountsList },
  {
    path: "/reverseinpatientbill/:visit_id/:patient_admission_id/:pay_status",
    component: ReverseInpatientBill,
  },
  {
    path: "/view_transactions_details/:category_id/:category_name/:from_date/:to_date",
    component: BalanceSheetReportTransactions,
  },
  { path: "/reports/chronicdiseases", component: ChronicDiseasesReport },
  { path: "/reports/fastmovingproducts", component: FastMovingStockReport },
  { path: "/reports/numberofservices", component: NumberofServicesPerformed },
  {
    path: "/reports/servicesbyagegroup",
    component: ServicesPerformedByAgeGroupReport,
  },
  { path: "/previousbedassignlist", component: PreviousBedAssignments },
  {
    path: "/department_revenue/:department_id/:department_name/:start_date/:end_date",
    component: DepartmentRevenueDetails,
  },
  { path: "/insurancechecks", component: ApprovalChecksList },
  { path: "/debtorsagingsummary", component: AccountReceivablesAgeingSummary },
  {
    path: "/patient-transactions/:patient_id/:component/:start_date/:end_date",
    component: PatientTransactionDetails,
  },
  {
    path: "/patient-transactions/:patient_id/:component",
    component: PatientTransactionDetails,
  },
  {
    path: "/dental-medical-history/:patient_number/:visit_id",
    component: DentalMedicalHistory,
  },
  {
    path: "/dental-request/:patient_number/:visit_id",
    component: DentalServicesRequest,
  },
  {
    path: "/dental-prescription/:patient_number/:visit_id",
    component: DentalPrescription,
  },
  {
    path: "/dental-examination/:patient_number/:visit_id",
    component: DentalExamination,
  },
  { path: "/dental-list", component: DentalList },
  { path: "/lab-stock-list", component: LabStockList },
  { path: "/canceled-purchases", component: CanceledPurchases },
  { path: "/drafted-purchases", component: DraftedPurchases },
  { path: "/dispenseConsumables", component: DispenseConsumables },
  {
    path: "/new_service_request/:patient_number/:visit_id/:visit_type",
    component: NewServicePrescriptionRequest,
  },
  { path: "/add_initial_stock", component: AddInitialStock },
  { path: "/add_dispatch", component: AddDispatch },
  { path: "/queues/:component", component: QueueList },
  { path: "/invoicedbills", component: PaidInvoices },
  { path: "/pharmacydispatchlist", component: PharmacyDispatchList },
  {
    path: "/dispatcheditems/:module_name/:dispatch_id",
    component: DispatchedItems,
  },
  { path: "/out-patient-prescriptions", component: OutpatientPrescriptionList },
  {
    path: "/previousvisits/:patient_number/:visit_id/:patient_type/:patient_admission_id",
    component: PatientPreviousVisits,
  },
  {
    path: "/examinationnotes/:patient_number/:visit_id/:patient_type/:patient_admission_id",
    component: PatientFindings,
  },
  {
    path: "/outpatientprocedure/:patient_number/:visit_id/:patient_type/:patient_admission_id",
    component: OutPatientProcedure,
  },
  {
    path: "/inpatientprocedure/:patient_number/:visit_id/:patient_type/:patient_admission_id",
    component: InPatientProcedure,
  },
  {
    path: "/outpatienttriage/:patient_number/:visit_id/:patient_type/:patient_admission_id",
    component: OutPatientTriage,
  },
  {
    path: "/outpatientlab/:patient_number/:visit_id/:patient_type/:patient_admission_id",
    component: OutPatientLab,
  },
  {
    path: "/inpatientlab/:patient_number/:visit_id/:patient_type/:patient_admission_id",
    component: InPatientLab,
  },
  {
    path: "/surgerydoctor/:patient_number/:visit_id/:patient_type/:patient_admission_id",
    component: Surgery,
  },
  {
    path: "/inpatientradiology/:patient_number/:visit_id/:patient_type/:patient_admission_id",
    component: InPatientRadiology,
  },
  {
    path: "/outpatientradiology/:patient_number/:visit_id/:patient_type/:patient_admission_id",
    component: OutPatientRadiology,
  },
  {
    path: "/doctordiagnosis/:patient_number/:visit_id/:patient_type/:patient_admission_id",
    component: DoctorDiagnosis,
  },
  {
    path: "/doctorprescription/:patient_number/:visit_id/:patient_type/:patient_admission_id",
    component: PrescriptionTab,
  },
  {
    path: "/doctoradmission/:patient_number/:visit_id/:patient_type/:patient_admission_id",
    component: DoctorAdmission,
  },
  {
    path: "/doctortreatment/:patient_number/:visit_id/:patient_type/:patient_admission_id",
    component: TreatmentDetails,
  },
  {
    path: "/referralnote/:patient_number/:visit_id/:patient_type/:patient_admission_id",
    component: ReferralNote,
  },
  {
    path: "/inpatientdischarge/:patient_number/:visit_id/:patient_type/:patient_admission_id/:component",
    component: DoctorDischargeForm,
  },
  {
    path: "/inpatientdischarge/:patient_number/:visit_id/:patient_admission_id/:component",
    component: DoctorDischargeForm,
  },
  {
    path: "/admissiontemplate/:patient_admission_id/:visit_id",
    component: AdmissionFormTemplate,
  },
  { path: "/createinvoice/:visit_id", component: CreateInvoice },
  {
    path: "/patient-history/:patient_number",
    component: PatientPreviousVisitInfo,
  },
  {
    path: "/revenueperservice/:service_id/:service_name/:start_date/:end_date",
    component: RevenuePerService,
  },
  { path: "/accounting/newfixedasset", component: NewFixedAsset },
  { path: "/accounting/fixedassets", component: FixedAssetList },
  { path: "/accounting/depreciateassets", component: DepreciateFixedAssets },
  {
    path: "/accounting/fixedassetdetails/:fixed_asset_id",
    component: FixedAssetDetails,
  },
  { path: "/accounting/setup", component: AccountingPeriod },
  { path: "/accounting/coa", component: ChartOfAccounts },
  { path: "/accounting/newledgerentry", component: NewLedgerEntry },
  { path: "/accounting/journals/:component", component: Journals },
  { path: "/accounting/setupledger/:component", component: SetUpSuppliers },
  { path: "/accounting/setupservices", component: SetupServices },
  { path: "/accounting/setupproducts", component: SetupProducts },
  {
    path: "/accounting/view-transactions/:transaction_id/:account/:start_date/:end_date",
    component: ViewJournalTransactions,
  },
  {
    path: "/accounting/view-transactions/:transaction_id/:account",
    component: ViewJournalTransactions,
  },
  {
    path: "/reports/labtestdetails/:hospital_test_id/name=:name/:positives",
    component: LabReportDetails,
  },
  {
    path: "/reports/labtestdetails/:hospital_test_id/name=:name",
    component: LabReportDetails,
  },
  {
    path: "/reports/detailedradiology/:hospital_scan_id/name=:name",
    component: DetailedRadiologyReport,
  },
  { path: "/stocksummary", component: StockSummary },
  { path: "/stockbatchwise", component: StockBatchWiseReport },
  { path: "/reports/productlog/:component/:product_id", component: ProductLog },
  { path: "/reports/outofstock", component: OutofStock },
  { path: "/reports/referralreports", component: ReferralReport },
  { path: "/reports/outwardreferrals", component: OutwardReferrals },
  { path: "/reports/expiredproducts", component: ExpiredProducts },
  { path: "/reports/stockstatus", component: StockStatusReport },
  { path: "/reports/totalpositives", component: TotalPositivesAndNegatives },
  { path: "/reports/revenuebycashier", component: RevenuePerCashier },
  { path: "/reports/revenuebypaymentmode", component: RevenuePerPaymentMode },
  { path: "/reports/revenuebyprocedure", component: RevenuePerProcedure },
  { path: "/reports/revenuebydepartment", component: RevenuePerDepartment },
  {
    path: "/reports/totalrevenuegenerated",
    component: TotalRevenueGeneratedReport,
  },
  {
    path: "/reports/insurancesummaryreport",
    component: InsuranceSummaryReport,
  },
  //{path: "/reports/moh_reports", component: MohReports}, -- COMMENTED OUT FOR LANE GENERAL HOSPITAL
  { path: "/reports/surgery_reports", component: SurgeryMOH },
  { path: "/reports/ward_reports", component: CensusInformationTable },
  { path: "/registerdeath", component: RegisterDeath },
  { path: "/reports/deathreports", component: DeathReport },
  { path: "/reports/diagnoses", component: DiagnosesReport },
  { path: "/reports/moh_reports", component: ProviderServicesReport }, // LANE WEEKLY REPORT TEMPLATE
  {
    path: "/viewdeathreport/:patient_death_id/:patient_type",
    component: ViewDeathReport,
  },
  {
    path: "/editdeathreport/:patient_death_id/:patient_number",
    component: EditDeath,
  },
  { path: "/reports/generalreports", component: GeneralReports },
  { path: "/customerreceivables", component: AccountReceivables },
  { path: "/paidcustomerreceivables", component: PaidAccountReceivables },
  {
    path: "/invoicereportdetails/:customer_id/:customer_name/:pay_status",
    component: DebtorsReportDetails,
  },
  { path: "/accounting/purchasesreport", component: AccountingPurchaseReport },
  { path: "/creditors/report", component: AccountsPayables },
  { path: "/creditors/paidsuppliers", component: PaidAccountsPayables },
  { path: "/creditdetails/:invoice_no", component: AccountsPayablesDetails },
  { path: "/accounting/generalledger", component: GeneralLedger },
  { path: "/accounting/trialbalance", component: TrialBalance },
  { path: "/accounting/balancesheet", component: BalanceSheet },
  { path: "/accounting/productsales", component: ProductSalesReport },
  { path: "/accounting/accreports/plstatement", component: ProfitLoss },
  { path: "/accounting/accreports/balancesheet", component: BalanceSheet },
  {
    path: "/accounting/expensereceipt/:expense_id",
    component: ExpenseReceiptDetails,
  },
  {
    path: "/accounting/expensereceipt_deleting/:expense_id/:delete_expense",
    component: ExpenseReceiptDetails,
  },
  { path: "/accounting/expenses", component: Expenses },
  { path: "/accounting/expense-items", component: ExpenseItems },
  {
    path: "/cleardebt/:provider_id/:pay_status",
    component: ClearOutPatientDebt,
  },
  { path: "/cleardebt/:visit_id", component: ClearOutPatientDebt },
  {
    path: "/debtorsdetails/:provider_id/:status/:receipt_number",
    component: CreditorDetails,
  },
  {
    path: "/debtorsdetails/:provider_id/:receipt_number",
    component: CreditorDetails,
  },
  {
    path: "/debtorsledger/:provider_id/:pay_status",
    component: CreditorsLedger,
  },
  { path: "/debtorsledger/:provider_id", component: CreditorsLedger },
  { path: "/debtors", component: Creditors },
  { path: "/visitlist", component: VisitList },
  { path: "/consultationlist", component: ConsultaionList },

  {
    path: "/inpatienttreatment/:visit_id/:patient_admission_id/:patient_number",
    component: InpatientTreatment,
  },
  {
    path: "/treatmentdetails/:patient_number/:visit_id",
    component: TreatmentDetails,
  },
  { path: "/changeuserpassword/:user_id", component: ChangeUserPassword },
  {
    path: "/takeinpatientvitals/:patient_number/:visit_id/:patient_admission_id",
    component: MedicationTriage,
  },
  { path: "/takeinpatientvitals", component: MedicationTriage },
  { path: "/companyinfo", component: AddCompanyInfo },
  { path: "/triagemetrics", component: TriageMetricList },
  { path: "/editmetrics/:metric_id", component: TriageMetricList },
  { path: "/stockadjustment", component: StockAdjustmentList },
  {
    path: "/adjustmentdetails/:stock_adjustment_id",
    component: StockAdjustmentDetails,
  },
  { path: "/addsample", component: SampleTypes },
  { path: "/support", component: Support },
  { path: "/producttrends", component: ProductTrends },
  { path: "/medicineuse", component: MedicineUse },
  { path: "/departments", component: MainDepartment },
  { path: "/confirmitems", component: ReceivedItems },
  { path: "/checkitems/:dispatch_id/:requisition_id", component: ConfirmItems },
  { path: "/pharmacystock", component: PharmacyStock },
  { path: "/doctorschedule", component: DoctorSchedule },
  { path: "/suppliers", component: SupplierList },
  {
    path: "/internalreferralnote/:referral_id",
    component: InternalPrintableReferralNote,
  },
  { path: "/medicationresults", component: ScanAndLabResults },
  {
    path: "/outgoingreferralprint/:referral_id/",
    component: OutgoingReferralPrint,
  },
  { path: "/viewreferal/:visit_id/", component: ViewReferal },
  {
    path: "/dischargenote/:patient_discharge_id/:visit_id",
    component: DischargeNote,
  },
  { path: "/dischargenote/:patient_discharge_id", component: DischargeNote },
  { path: "/editadmission/:patient_admission_id", component: EditAdmission },
  { path: "/editproduct/:product_id", component: AddProduct },
  { path: "/addproduct", component: AddProduct },
  { path: "/productlist", component: ProductList },
  { path: "/stocklist", component: StockList },
  { path: "/emergencystock", component: EmergencyStockList },
  { path: "/emergencydispatchlist", component: EmergencyDispatchList },
  { path: "/view-purchase/:purchase_id/:component", component: ViewPurchase },
  { path: "/editpurchase/:purchase_id", component: AddPurchase },
  { path: "/newpurchase/:local_purchase_order_id", component: AddPurchase },
  { path: "/addpurchase/:purchase_id/:draft", component: AddPurchase },
  { path: "/addpurchase", component: AddPurchase },
  { path: "/purchaselist", component: PurchaseList },
  {
    path: "/viewpurchaseorder/:local_purchase_order_id",
    component: ViewPurchaseOrder,
  },
  {
    path: "/editpurchaseordertemplate/:local_purchase_order_id/:status/:template",
    component: AddPurchaseOrder,
  },
  {
    path: "/editpurchaseorder/:local_purchase_order_id/:status",
    component: AddPurchaseOrder,
  },
  {
    path: "/purchaseorderapproval/:local_purchase_order_id/:lpo_status",
    component: PurchaseOrderApproval,
  },
  { path: "/addpurchaseorder", component: AddPurchaseOrder },
  { path: "/purchaseorderlist", component: PurchaseOrderList },
  {
    path: "/editdispatchnote/:dispatch_id/:status",
    component: EditDispatchNote,
  },
  { path: "/editdispatchnote/:dispatch_id", component: EditDispatchNote },
  { path: "/dispatchnote/:dispatch_id/:status", component: DispatchNote },
  { path: "/dispatchnote/:dispatch_id", component: DispatchNote },
  { path: "/dispatchlist/:component", component: ProductDispatchList },
  // {path: "/dispatchlist", component: ProductDispatchList},
  {
    path: "/editrequisition/:requisition_id/:status",
    component: EditRequisition,
  },
  { path: "/editrequisition/:requisition_id", component: EditRequisition },
  { path: "/editrequisition/:requisition_id", component: EditRequisition },
  {
    path: "/viewrequisition/:component/:requisition_id",
    component: ViewRequisition,
  },
  // {path: ["/dischargepatient/:patient_admission_id","/dischargeform"], component: DischargeForm},
  { path: "/makerequisition/:component", component: AddRequisition },
  { path: "/requisitionlist/:status", component: RequisitionList },
  // {path: "/medicationlist", component: PatientMedicationList},
  {
    path: "/inpatientreceipt/:visit_id/:receipt_number",
    component: InpatientBillingReceipt,
  },
  {
    path: "/viewinpatientinvoice/:visit_id/:receipt_number",
    component: InpatientBillInvoice,
  },
  {
    path: "/inpatientbillform/:visit_id/:patient_admission_id/:status/:invoice_number/:pay_status",
    component: InpatientBillFormTwo,
  },
  {
    path: "/inpatientbillinvoice/:visit_id/:patient_admission_id/:status/:invoice_number",
    component: InpatientBillingInvoice,
  },
  { path: "/patientbillinglist", component: PatientBillingList },
  { path: "/patientroundlist", component: AdmissionList },
  { path: "/dischargelist", component: DischargeList },
  { path: "/admissionform/:patient_number", component: PatientAdmissionForm },
  { path: "/admissionform", component: PatientAdmissionForm },
  { path: "/schedulelist", component: ScheduleList },
  { path: "/addschedule", component: AddSchedule },
  { path: "/shiftlist", component: AssignShifts },
  { path: "/shiftcategorylist", component: ShiftCategoryList },
  { path: "/addshift", component: AddShiftCategory },
  { path: "/bedtransferlist", component: BedTransferList },
  { path: "/viewbedtransfer/:bed_transfer_id", component: ViewBedTransfer },
  { path: "/bedassignlist", component: BedAssignList },
  { path: "/unassignedbeds", component: AdmissionsWithoutBeds },
  { path: "/bedlist", component: BedList },
  { path: "/roomlist", component: RoomList },
  { path: "/wardlist", component: WardList },
  { path: "/edituserrole/:user_role_id", component: EditAssignedRole },
  { path: "/changepassword", component: ChangePassword },
  { path: "/userroles", component: UserRoles },
  { path: "/pendingdoctorrequests", component: DoctorLabResultList },
  { path: "/patientsSeen", component: PatientsSeen },
  { path: "/labtype", component: LabTypeList },
  {
    path: "/collectsample/:labrequest_id/:current_date",
    component: CollectSample,
  },
  {
    path: "/captureresults/:sample_id/:labrequests_detail_id/:patient_type",
    component: EnterResults,
  },
  { path: "/pendingsamples", component: PendingSamples },
  { path: "/labresultlist", component: LabResultsList },
  { path: "/labreports", component: LabReports },
  {
    path: "/generallabreport/:patient_number/:lab_request_id",
    component: GeneralLabReport,
  },
  {
    path: "/editlabresults/:sample_id/:labrequests_detail_id/:test_results_id",
    component: EditLabResults,
  },
  { path: "/addrole", component: AddRole },
  { path: "/editrole/:role_id", component: AddRole },
  { path: "/roles", component: RolesList },
  { path: "/assignrole", component: AssignRole },
  { path: "/edituser/:user_id", component: EditUser },
  { path: "/users", component: UsersList },
  { path: "/typelist", component: MedicineTypeList },
  { path: "/unitlist", component: UnitList },
  {
    path: "/prescriptiondetails/:patient_number/:visit_id/:labrequest_id/:scan_request_id/:patient_type/:patient_admission_id/:prescription_id",
    component: PrescriptionReport,
  },
  { path: "/examnotes/:patient_id", component: ExaminationNotes },
  { path: "/doctorprescription/:patient_id", component: DoctorPrescription },
  { path: "/doctorradiology/:patient_id", component: MainRadiology },
  { path: "/labrequests", component: LabRequestList },
  { path: "/labsettings", component: ParameterList },
  {
    path: "/fillprescription/:prescription_id/:patient_type",
    component: FillPrescription,
  },
  {
    path: "/billingreceipt/:visit_id/:receipt_number",
    component: BillingReceipt,
  },
  { path: "/billingreceipt/:visit_id", component: ViewAllReceipts },
  {
    path: "/refundreceipt/:visit_id/:active_page/:patient_number",
    component: RefundReceipt,
  },
  {
    path: "/viewinpatientbill/:visit_id/:patient_admission_id/:pay_status",
    component: ViewInPatientBill,
  },
  { path: "/employeeprofile/:employee_id", component: EmployeeProfile },
  { path: "/addservice", component: AddService },
  {
    path: "/service_consumables/:service_id/:consumable_status",
    component: ConsumablesList,
  },
  { path: "/servicelist/:service_status", component: ServiceList },
  { path: "/editservice/:service_id", component: EditService },
  { path: "/referralnote/1", component: ReferralNote },
  { path: "/employeelist", component: EmployeeList },
  {
    path: "/triage/:activity_id/:visit_id/:patient_number",
    component: TakeVitals,
  }, // not sure
  //{path: "/triage/:visit_id/:patient_number", component: TakeVitals}, // not sure
  { path: "/waitinglist", component: PatientWaitingList },
  { path: "/editPatientProfile/:patient_number", component: RegisterPatient },
  { path: "/registerPatient", component: RegisterPatient },
  { path: "/patientDetails/:component", component: PatientDetails },
  { path: "/editbill/:visit_id/:transaction_id", component: AddBill },
  { path: "/newbill/:visit_id", component: AddBill },
  { path: "/refundbill/:visit_id", component: RefundBill },
  { path: "/invoice/:visit_id/:receipt_number", component: InvoiceReceipt },
  {
    path: "/billedinvoice/:visit_id/:patient_type/:provider_id/:claim_status/:provider_type",
    component: BilledInvoicesReceipt,
  },
  {
    path: "/parentinvoice/:visit_id/:patient_type/:provider_id/:claim_status/:provider_type",
    component: BilledInvoicesReceipt,
  },
  {
    path: "/childinvoice/:visit_id/:patient_type/:provider_id/:claim_status/:provider_type/:parent",
    component: BilledInvoicesReceipt,
  },
  {
    path: "/reverseinvoice/:visit_id/:patient_type/:provider_id",
    component: ReverseInvoices,
  },
  { path: "/patientProfile/:patient_number", component: PatientProfile },
  {
    path: "/examination/:appointment_id/:patient_id",
    component: ExaminationNotes,
  },
  { path: "/triagewaitinglist", component: TriageWaitingList },
  { path: "/medicalAnalysis/:component", component: PrescriptionList },
  { path: "/paymentList", component: PaymentList },
  { path: "/providers", component: InvoiceProviders },
  {
    path: "/customer-transactions/:provider_id/:provider_name",
    component: CustomerTransactions,
  },
  { path: "/scanradiology", component: ScanRadiology },
  { path: "/scanresults", component: ScanResultsList },
  {
    path: "/addscanrequest/:appointment_id/:hospital_scan_id/:scan_request_id",
    component: AddScanRequest,
  },
  {
    path: "/viewresults/:visit_id/:patient_number/:scan_requests_detail_id/:scan_result_id/:patient_type",
    component: ViewResults,
  },
  {
    path: "/scanupload/:service_id/:visit_id/:patient_number/:hospital_scan_id/:scan_requests_detail_id/:scan_request_id/:patient_type/:activity_id/:current_date",
    component: FileUpload,
  },
  {
    path: "/editscanresults/:visit_id/:patient_number/:scan_requests_detail_id/:scan_result_id/:patient_type",
    component: FileUpload,
  },
  { path: "/adduser", component: AddUser },
  {
    path: ["/newvisit/:patient_number/:patientID", "/newvisit"],
    component: NewVisit,
  },
  { path: "/previous_visits/:feedback_status", component: PreviousVisits },
  {
    path: "/completesurgerydetails/:patient_number/:patient_type/:visit_id/:patient_surgery_id",
    component: CompleteSurgeryDetails,
  },
  {
    path: ["/surgeryvisit/:patient_number", "/surgeryvisit"],
    component: SurgeryVisit,
  },
  { path: "/surgerymodule/currentsurgeries", component: CurrentSurgeries },
  { path: "/surgerymodule/pastsurgeries", component: PastSurgeries },
  { path: "/EmergencyPatient", component: RecordPatient },
  { path: "/emergencylist", component: EmergencyList },
  {
    path: "/patientadmissiondetails/:visit_id",
    component: DischargedPatientDetails,
  },
  { path: "/returns/:component", component: ReturnList },
  { path: "/returnproducts", component: ReturnProducts },
  { path: "/returnreport/:return_id", component: ReturnReport },
  { path: "/anc_lists", component: ANCLists },
  {
    path: "/antenatal_previous_visits/:patient_number/:visit_id/:labrequest_id/:scan_request_id/:patient_type/:patient_admission_id/:anc_id/:anc_status",
    component: AnteNatalPreviousVisits,
  },
  {
    path: "/antenatal_patient_form/:patient_number/:visit_id/:labrequest_id/:scan_request_id/:patient_type/:patient_admission_id/:anc_id/:anc_status",
    component: AnteNatalPatientForm,
  },
  {
    path: "/antenatal_diagnosis/:patient_number/:visit_id/:labrequest_id/:scan_request_id/:patient_type/:patient_admission_id/:anc_id/:anc_status",
    component: AnteNatalDiagnosis,
  },
  {
    path: "/antenatal_outpatient_triage/:patient_number/:visit_id/:labrequest_id/:scan_request_id/:patient_type/:patient_admission_id/:anc_id/:anc_status",
    component: AnteNatalTriage,
  },
  {
    path: "/antenatal_laboratory/:patient_number/:visit_id/:labrequest_id/:scan_request_id/:patient_type/:patient_admission_id/:anc_id/:anc_status",
    component: AnteNatalLabRequest,
  },
  {
    path: "/antenatal_radiology/:patient_number/:visit_id/:labrequest_id/:scan_request_id/:patient_type/:patient_admission_id/:anc_id/:anc_status",
    component: AnteNatalRadiology,
  },
  {
    path: "/antenatal_prescription/:patient_number/:visit_id/:labrequest_id/:scan_request_id/:patient_type/:patient_admission_id/:anc_id/:anc_status",
    component: AnteNatalPrescription,
  },
  {
    path: "/antenatal_admission/:patient_number/:visit_id/:labrequest_id/:scan_request_id/:patient_type/:patient_admission_id/:anc_id/:anc_status",
    component: AnteNatalAdmission,
  },
  {
    path: "/antenatal_referral/:patient_number/:visit_id/:labrequest_id/:scan_request_id/:patient_type/:patient_admission_id/:anc_id/:anc_status",
    component: AnteNatalReferralNote,
  },
  {
    path: "/antenatal_details/:patient_number/:visit_id/:labrequest_id/:scan_request_id/:patient_type/:patient_admission_id/:anc_id/:anc_status",
    component: AnteNatalTreatmentDetails,
  },
  { path: "/assignshifts", component: AssignShifts },
  {
    path: "/items_provider_allows/:provider_id/:provider_name/:item_status/:currency_id",
    component: ItemsProviderAllow,
  },
  { path: "/companydetails", component: CompanyDetails },
  { path: "/viewbill/:visit_id", component: ViewBill },
  { path: "/schemelist", component: SchemeList },

  { path: "/super-admin", component: CreateSubscription },
];
