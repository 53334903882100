import React, {useEffect, useState} from "react";
import * as snackbarActions from "../../../actions/snackbarActions"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClipboardList} from "@fortawesome/free-solid-svg-icons/faClipboardList";
import {MainSearchComponent} from "../MainSearch";
import {DataExportButton} from "../../Utils/Buttons/DataExportationButton";
import {useProductSalesReport} from "./CustomHooks/useProductSalesReport";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import CustomTable from "../../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {ReusableRouteTabs} from "../../Utils/Dialogs/ReusableTabs";
import {history} from "../../../Helpers/history";
import { BackButton } from "../../Utils/Buttons/DataExportationButton";
import { Link } from "react-router-dom";
import {useExcelReports, usePdfDownload} from "../../Utils/ReusableComponents/useExcelExport";
import {useCurrency} from "../../../Context/currency-context";


const ProductSalesReport = ({actions, snackbars}) => {
    const {currency:selectedCurrency} = useCurrency({actions, snackbars})
    const obj =  {url:'sales_purchase',id:'type_id'}
    const {closeSnackbar, search_type, handleSubmit, data, count, customSearch, handleChangeActivePage,
        text, textType, year, date, handleChangeSearch, handleCustomSearch,category,
        handleChangeDate, handleChangeYear, handleIncrementYear, isSubmitted, submitted,
        loading,  inputType, csvRef,   handleChangeCategory, activePage
    } = useProductSalesReport(actions, obj)
    const [categories, setCategories] = useState([]);
    const {start_date, end_date} = date;
    const dataArr = data.sales ?? []
    useEffect(() => {
        axios.get(`${config.smsUrl}/cpharmacy/type_list`).then(res => {
            const data = res.data;
            const dt = data ?? {};
            const all_types = dt.type_list ?? [];
            const arr = all_types.map(item => ({
                value: item.product_type_id,
                label: item.type_name
            })) 
            setCategories([{value:'', label:'All'},...arr]);
        }).catch(err => {
            logoutErrorMessage(err, null, actions);
        })
    }, [])


    const headData = [
        {id: 'sl', numeric: false, disablePadding: false, label: 'SL'},
        {id: 'product', numeric: false, disablePadding: false, label: 'Product'},
        {id: 'quantity', numeric: false, disablePadding: false, label: 'Quantity Sold'},
        // {id: 'price', numeric: false, disablePadding: false, label: 'Unit Price'},
        {id: 'total_amountr', numeric: false, disablePadding: false, label: `Amount`},
        // {id: 'ssp_amount', numeric: false, disablePadding: false, label: 'Amount(SSP)'},
        // {id: 'stock', numeric: false, disablePadding: false, label: 'Stock'}
    ];


    const {openBar, type, message} = snackbars;

    const components = [
    {label: 'Sales by Service', path: '/reports/revenuebyprocedure'},
    {label: 'Sales by Product', path: '/accounting/productsales'},
]

    const excelObj = {file_name:'Sales per Product',params:{start_date, end_date, download:2, option:3, date_type:3, page:1, type_id:category?.value ? category?.value : ''}, url:'creport/sales_purchase_exports'}

    const pdfObj = {file_name:'Sales per Product',params:{start_date, end_date, download:1,option:3, date_type:3, page:1,type_id:category?.value ? category?.value : ''}, url:'creport/sales_purchase_exports'}


    const {exportExcel, isLoading:isLoadingExcel} = useExcelReports(actions,excelObj)
    const {exportPdf, isLoading:isLoadingPdf} = usePdfDownload(actions,pdfObj)
    return (
        <div className='journals' data-testid="revenue-generated">
            <PageTitle title='Sales per Product'/>
            <SubHeader title="Reports" subTitle='Sales per Product'>
                <FontAwesomeIcon icon={faClipboardList}/>
            </SubHeader>
            <div style={{margin: '10px'}}>
                <BackButton text={'General Reports'} to={'/reports/generalreports'} data-testid="product-sales-report-back-button"/>
            </div>
            <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/> 
        
            <MainSearchComponent text={text} type={textType} changeDate={handleChangeDate}
                                    handleSubmit={handleSubmit} isSubmitted={isSubmitted}
                                    changePeriod={handleChangeSearch} end_date={end_date}
                                    handleChangeValue={handleChangeYear} inputType={inputType}
                                    search_type={search_type} handleChangeYear={handleIncrementYear}
                                    start_date={start_date} year={year} submitted={submitted}>
                <DataExportButton isLoading={isLoadingExcel || isLoadingPdf} downLoadPdf={exportPdf} exportCsv={exportExcel}/>
            </MainSearchComponent>

            <div>
                {/* <SortSelect categories={categories} category={category} handleChangeCategory={handleChangeCategory}
                            placeholder='Select product type...'/> */}
                <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>
                <CustomTable title="" handleChangeNextPage={handleChangeActivePage} term={customSearch}
                             records={10}
                             
                             headData={headData} handler={handleCustomSearch} total_count={count}
                             activePage={activePage}
                              data={dataArr} colSpan={headData.length}
                              customPage>
                    {loading ? <TableBody><TableRow>
                        <TableCell colSpan={headData.length} align="center"><img src="/images/smsloader.gif"
                                                                   alt="loader"/></TableCell>
                    </TableRow></TableBody> : dataArr.length > 0 ?

                        <TableBody>
                            {dataArr.slice(0, 10).map((item, index) => {
                                    const cnt = ((activePage - 1) * 10) + index + 1;
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={index}
                                            className='mui-table-row'>
                                            <TableCell>{cnt}</TableCell>
                                            <TableCell>
                                               <Link to={{pathname:`/reports/productlog/reports/${item.product_id}`}}>{item.product_name}</Link> 
                                            </TableCell>
                                            <TableCell>{item.quantity_sold}</TableCell>
                                            <TableCell>{item.total_amount}</TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody> : <TableBody><TableRow>
                            <TableCell colSpan={headData.length} align="center">No records found</TableCell>
                        </TableRow></TableBody>}
                </CustomTable>
            </div>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductSalesReport);
