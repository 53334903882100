import React from "react";
import {BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer} from "recharts";


export default function CustomBarChart({data, children, height, layout}) {
    return (
        <ResponsiveContainer width="100%" height={height}>
        <BarChart
            data={data}
            margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5
            }}
            barGap={1}
            layout={layout}
        >
            <Tooltip />
            {children}
        </BarChart>
        </ResponsiveContainer>
    );
}
