import {useState, useEffect} from "react";
import axios from "axios";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import { config } from "../../../../Helpers/env";
import {titleCase} from "../../../Users/addUser";
import {history} from "../../../../Helpers/history"
import { dateConvert } from "../../../Utils/ConvertDate";
import { useQuery } from "react-query";


function useNewVisit(actions, patient_number, patientID) {
    const [p_number, setPatientNumber] = useState(patient_number ? patient_number : '');
    const [patient_no, setPatientNo] = useState(patient_number ? patient_number : '')
    const [showNumber, setShowNumber] = useState(false);
    const [patientNumbers, setPatientNumbers] = useState([]);
    const [patientDetails, setPatientDetails] = useState({first_name: '', last_name: '', age:'', gender:'', phone_no:''});
    const [provider_id, setProviderId] = useState('')
    const [isProvider, setIsProvider] = useState(null)
    const [providerDetails, setProviderDetails] = useState({account_name:'', card_number:'', provider_type:''})
    const [providers, setProviders] = useState([])
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [submitted, setSubmitted] = useState(false)
    const [visit_type, setVisitType] = useState({value: '0', label: 'Select...'});
    const [search_parameter, setSearchParameter] = useState('patient_no')
    const [visit_id, setVisitId] = useState('')
    const [isAccumulatedBill, setAccumulatedBill] = useState(false)
    const [isOnInsurance, setIsOnInsurance] = useState(false)
    const [openNewInsurance, setOpenNewInsurance] = useState(false)
    const [new_insurance, setNewInsurance] = useState('')
    const [newProviderDetails, setNewProviderDetails] = useState({account_name:'', card_number:''})
    const [isBlocking, setIsBlocking] = useState(false)
    const [referralDialog, setReferralDialog] = useState(false);
    const [addReferral, setAddReferral] = useState(false)
    const [company_name,setCompanyName]= useState(null);
    const [referrals, setReferrals] = useState({
        referral_date: dateConvert(), referral_number: "",
        visit_date: dateConvert(), diagnosis: "", reason: "", notes: "",
        from_hospital: "", patient_history: "", treatment: ""
    });
    const [insurance_status, setInsurancStatus] = useState('')
    const [insurance_scheme, setInsuranceScheme] = useState('')
    const [insuranceSchemes, setInsuranceSchemes] = useState([])


 

    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = _token.user_roles_id ? _token.user_roles_id : ''


    const handleChangeSearchParam = (event) =>{
        setSearchParameter(event.target.value)
        setPatientNumber('')
        setPatientNo('')
        setPatientDetails({first_name: '', last_name: '', age:'', gender:''})
        setShowNumber(false);
        setIsBlocking(true)
    }

    const handleChangeInsuranceStatus = (event) =>{
        setInsurancStatus(event.target.value)
    }

    const handleChangeInsuranceScheme = (value) =>{
        setInsuranceScheme(value)
    }

    const visitTypeListQuery = useQuery(
        ["visitTypeListQuery", patientID,],
        async () => {
            try{
                const response = await axios.get(
                    `${config.smsUrl}/cvisit/determine-current-visit-type/patient-id/${patientID}`,
                    {}
                );

                const updatedVisitType = patientID === undefined ? visit_type : {
                        label: response?.data,
                        value: response?.data?.includes("new") || response?.data?.includes("New") ? "1" : "2",
                    }

                setVisitType(updatedVisitType)

                return [ updatedVisitType ];
            } catch(error){
                //debugger;
               // console.log(error) 
            }
        },
        {
            retry: 1,
        }
    );

    const visitTypeList = visitTypeListQuery?.isSuccess ? visitTypeListQuery?.data : [];

    const fetchInsuranceSchemes = (_id) =>{
        const insurance_id = _id.split('-')[0]
        const ins_id = Number(insurance_id)
        axios.get(`${config.smsUrl}/insurance-scheme/insurance-provider/${ins_id}`)
          .then(res => {
              const details = res.data;
              const dt = details ? details : []
              const arr = dt.map(item=>({
                  value:item.id,
                  label:item.name
              }))
              setInsuranceSchemes(arr)

          }).catch(error => {
            logoutErrorMessage(error, null, actions)
        })
    }


    const fetchPatientByPatientNumber = (patientNumber) => {
        const formData = new FormData();
        formData.append('patient_number', patientNumber);
        axios.post(`${config.smsUrl}/cdoctor/get_patient_details`, formData)
            .then(res => {
                const details = res.data;
                const dt = details ? details : {}
                const {first_name, last_name, Age, gender, phone_no} = dt

                setPatientDetails({
                    first_name: first_name ? first_name : '', last_name: last_name ? last_name : '',
                    age:Age ? Age : '', gender:gender ? gender : '', phone_no : phone_no ? phone_no : '',
                })

                const provider_id = details.provider_id ? `${details.provider_id}-${details.provider_type}-${details.provider_name}` : ''
                const provider_name = details.provider_id ? details.provider_name : ''
                const prov = provider_id ? {value:provider_id , label:provider_name} : ''

                if (provider_id){
                    fetchInsuranceSchemes(provider_id)
                }

                setProviderId(prov)
                setNewInsurance(prov)
                setIsProvider(provider_id ? provider_id : '')

                setProviderDetails({account_name: details?.account_name,  card_number: details?.card_number,
                    provider_type: details?.provider_type})
                setNewProviderDetails({account_name: details?.account_name, card_number: details?.card_number})

            }).catch(error => {
            logoutErrorMessage(error, null, actions)
        })
        setIsBlocking(true)
    };



    useEffect(() => {
        if(!patient_number){
            return;
        }
        fetchPatientByPatientNumber(patient_number);
    }, [patient_number]);

    useEffect(() => {
        axios.get(`${config.smsUrl}/cuser/view_company_info`)
            .then((response) => {
                const data= response.data? response.data:{}
                setCompanyName(data.company_name);
            }).catch(err=>{
                errorMessages(err, null, actions)
        });
    }, []);


    const handleChangePatientNumbers = (event) => {
        setPatientNumber(event.target.value);
        const formData = new FormData();
        if (event.target.value !== '') {
            const patient_no = search_parameter === 'patient_no' ? event.target.value.toUpperCase() : ''
            const patient_name = search_parameter === 'patient_name' ? titleCase(event.target.value) : ''
            const phone_no = search_parameter === 'phone_no' ? event.target.value : ''
            formData.append('patient_number', patient_no);
            formData.append('name', patient_name);
            formData.append('phone_no', phone_no);
            axios.post(`${config.smsUrl}/cvisit/search_patient_number`, formData)
                .then(res => {
                    const details = res.data;
                    const data = !details ? [] : details;
                    if (data[0] === "No Patient Found") {
                        setPatientNumbers([{value: '', label: 'No patient found'}])
                    } else {
                        const arr = data.map(item => ({
                            value: item.patient_number,
                            label: `(${item.patient_number}) ${item.first_name} ${item.last_name} - ${item.phone_no}`
                        }))
                        setPatientNumbers(arr)
                    }

                }).catch(error => {
                errorMessages(error, null, actions)
            });
            setShowNumber(true);
        } else {
            setShowNumber(false);
            setProviderId('')
            setProviderDetails({account_name: '', card_number: ''})
            setPatientDetails({first_name: '', last_name: '', age:'', gender:''})
        }
        setIsSubmitted('idle')
        setIsBlocking(true)
    };

    const handleClickPatientNumber = (event) => {
        setPatientNumber(event.innerText);
        setPatientNo(event.value)
        fetchPatientByPatientNumber(event.value);
        setShowNumber(false);
    };


    const handleVisitTypeChange = (val) => {
        setVisitType(val)
        setIsBlocking(true)
    }


    const handleChangeAccumulatedBill = (event) =>{
        setAccumulatedBill(event.target.checked)
        setIsBlocking(true)
    }


    useEffect(() => {
        axios.get(`${config.smsUrl}/cbilling/insurance_providers/list?status=1`).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const arr = !dt.data ? [] : dt.data;
            const provArr = arr.map(item=>({value:`${item.provider_id}-${item.provider_type}-${item.provider_name}`, label:item.provider_name}))
            setProviders(provArr);
        }).catch(err => {
            logoutErrorMessage(err,null,actions)
        })
    }, []);


    const handleAddReferral = (event) =>{
        setAddReferral(event.target.checked)
    }

    const handleChangeReferral = (event) => {
        const {name, value} = event.target;
        setReferrals({...referrals, [name]: value})
    };



    const handleChangeProvider = (value) =>{
        setProviderId(value)
        const provider_type = value?.value?.split('-')[1]
        const isInsuranceProvider = provider_type === 'Insurance Company'
        if (isInsuranceProvider){
            fetchAllSchemes()
        }
        setNewProviderDetails({account_name: '', card_number: ''})

    }

    const handleChangeProviderDetails = (event) =>{
        const {name, value} = event.target
        setProviderDetails({...providerDetails, [name]:value})
    }

    const handleChangeNewProvider = (value) =>{
        setNewInsurance(value)
        setProviderDetails({account_name: '', card_number: ''})
    }

    const handleChangeNewProviderDetails = (event) =>{
        const {name, value} = event.target
        setNewProviderDetails({...newProviderDetails, [name]:value})
    }

    const handleChangeIsInsurance = (event) =>{
        setIsOnInsurance(event.target.checked)
        setIsBlocking(true)
    }

    const handleOpenNewInsurance = () =>{
        setOpenNewInsurance(true)
    }

    const handleCloseNewInsurance = () =>{
        setOpenNewInsurance(false)

    }

    const handleSubmitProvider = (event) =>{
        event.preventDefault()
        const prov = new_insurance ? new_insurance : {}
        const prov_value = prov.value ? prov.value : ''
        const [id, provider_type] = prov_value.split('-')
        const isInsurance = provider_type === 'Insurance Company'
        const {account_name, card_number} = newProviderDetails
        const formData = new FormData()
        formData.append('patient_number', patient_no)
        formData.append('provider_id',  id)
        formData.append('account_name', account_name ? account_name:'')
        formData.append('card_number', card_number ? card_number: '')
    
        setSubmitted(true)

        const isProviderRequired = (isInsurance && account_name && card_number) || (!isInsurance && account_name)
        if(new_insurance && isProviderRequired){
            setIsSubmitted('pendingInsurance')
            axios.post(`${config.smsUrl}/cpatient/patient_insurance_detail/add`, formData).then((res) => {
                setIsSubmitted('resolvedInsurance')
                setOpenNewInsurance(false)
                fetchPatientByPatientNumber(patient_no)
            }).catch((err) => {
                setIsSubmitted('rejectedInsurance');
                errorMessages(err, null, actions)
            });
        }
    }

    const createANCVisit = (v_id) => {
        const formData = new FormData();
        formData.append('service_type', 'Ante Natal Care');
        axios.post(`${config.smsUrl}/cvisit/retrieve_services_by_service_type`, formData)
            .then(res => {
                const details = res.data;
                const data = !details ? 0 : details[0].service_id;
                const params = {
                    antenatal_data : {
                        'service_id': data,
                        'visit_id':v_id,
                    },
                }
                axios.post(`${config.smsUrl}/antenatal/create_anc_service`, {...params}).then(() => {
                    actions.snackbarActions.snackSuccess('Antenatal service created  successful');
                }).catch((err) => {
                    errorMessages(err, null, actions)
                    setSubmitted(false);
                    setIsSubmitted('rejected');
                });
            }).catch(error => {
            errorMessages(error, null, actions)
        });
    }


    const handleSubmitVisit = (event, route, component=null) => {
        event.preventDefault();
        const visit_state = component === 'triage' ? {triage_state:1} : {triage_state: 0};
        const record_incoming_referral = JSON.parse(sessionStorage.getItem(`record_incoming_referral_${patient_no}`));
        const is_referred = record_incoming_referral !== null;
        const {account_name, card_number} = providerDetails
        const prov_obj  = provider_id ? provider_id : {};
        const prov_value = prov_obj.value ? prov_obj.value : ''
        const [id, provider_type] = prov_value.split('-')
        const {
            diagnosis,treatment, patient_history, from_hospital
         } = referrals;
        const isInsurance = provider_type === 'Insurance Company'

        const schemeIds = [insurance_scheme].map(item=>({
            scheme_id:item.value
        }))

        const params = {patient_number: patient_no, 
            visit_type: +visit_type?.value ?? '', user_roles_id, 
            patient_type:1, accumulated_bill:isAccumulatedBill, 
            take_triage: (component === 'triage')  ? 1 : 0,
            ...visit_state, 
            is_referred: addReferral,
            referral_details: addReferral === false ? '' : {
                referred_from : from_hospital,
                referred_to: company_name,
                referral_type: "incoming",
                visit_date: '',
                diagnosis: diagnosis,
                reason: patient_history,
                treatment_given: treatment,
                user_role_id: ''},
            provider_id: +id,
            is_on_insurance:isOnInsurance ? 1 : 0,
            account_name,
            card_number,
            scheme_id:schemeIds.length > 0 ? schemeIds : '',
            insurance_scheme_status:insurance_status ? insurance_status : ''
        };

        const {first_name, last_name} = patientDetails;

        setSubmitted(true);
        setIsBlocking(false)

        const required_data = ((isInsurance && account_name && card_number && insurance_status && insurance_scheme) || (!isInsurance && account_name))
        const required_insurance = (isOnInsurance && required_data) || !isOnInsurance
        const referral_data = (addReferral && (from_hospital && diagnosis)) || !addReferral
        

        if (first_name && last_name  && required_insurance && referral_data) {
            setIsSubmitted('pending');
            axios.post(`${config.smsUrl}/cvisit/new_visit`, {...params}).then((res) => {
                const data = res.data ? res.data : {};
                const dt = data ? data : {};
                const id = dt.visit_id ? dt.visit_id:'';
                setVisitId(id)
                actions.snackbarActions.snackSuccess('Visit registered successful');
                setIsSubmitted('resolved');
                setSubmitted(false);

                if (visit_type.value === '3') {
                    createANCVisit(id);
                    return;
                }

                history.push(`/${route}/${patient_no}/${id}/${visit_type?.value}`)

                sessionStorage.removeItem(`record_internal_referral_${patient_no}`);
                setAccumulatedBill(false);
                setIsOnInsurance(false)
                setProviderId('')
                setReferralDialog(false)
            }).catch((err) => {
                setIsSubmitted('rejected');
                errorMessages(err, null, actions)
            });
        }
        
    };

    const handleCancel = () =>{

        setPatientNumber('');
        setPatientDetails({first_name: '', last_name: ''})
        setAccumulatedBill(false)
        setSubmitted(false);
    }

    const handleCloseSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const isPending = isSubmitted === 'pending'
    
    const isResolved = isSubmitted === 'resolved'
    
    const isRejected = isSubmitted === 'rejected'

    const isPendingProvider = isSubmitted === 'pendingInsurance'

    const providerProps = {
        submitted,
        provider_id,
        handleChangeProvider,
        providers,
        handleChangeProviderDetails,
        providerDetails,
    }

    const referralProps = {addReferral, handleAddReferral, referrals, company_name, handleChangeReferral}
    
    const newProviderProps = {
        submitted,
        provider_id:new_insurance,
        handleChangeProvider:handleChangeNewProvider,
        providers,
        handleChangeProviderDetails:handleChangeNewProviderDetails,
        providerDetails:newProviderDetails,
    }

    return {
        patient_number,p_number,showNumber,patientNumbers,patientDetails,submitted,visit_type,patient_no,
        isSubmitted,fetchPatientByPatientNumber,handleClickPatientNumber,handleSubmitVisit,isResolved, isRejected,
        handleCloseSnackbar,handleChangePatientNumbers,userObj,token,_token, user_roles_id,setVisitType,
        handleCancel, isPending, search_parameter, handleChangeSearchParam,referralProps,
        isAccumulatedBill, handleChangeAccumulatedBill,  handleVisitTypeChange,isProvider,
        isOnInsurance, handleChangeIsInsurance, providerProps, openNewInsurance, handleCloseNewInsurance,isPendingProvider,
        handleSubmitProvider, handleOpenNewInsurance, newProviderProps, isBlocking,setIsBlocking,
        referralDialog, setReferralDialog, insurance_status, handleChangeInsuranceStatus,
        insuranceSchemes, insurance_scheme, handleChangeInsuranceScheme, visitTypeList,
    }

}

export { useNewVisit }

