import React from "react";
import Form from "react-bootstrap/Form"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons/faQuestionCircle";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import {useTheme} from "@material-ui/core/styles";


const FormLabel = ({name, toolTip, isRequired, ...rest}) =>(
  <Form.Label className="form__label" style={{fontSize:'14px'}}  {...rest} >{name}
  <span className="ml-1"><OverlayTrigger placement="top" overlay={<Popover><p style={{margin:'0.8rem'}}>{toolTip}</p></Popover>}>
    <FontAwesomeIcon icon={faQuestionCircle}/>
  </OverlayTrigger></span>
  {isRequired ? <span className="ml-1 help-block">*</span>:null}
  </Form.Label>
)

const ToolTip = ({toolTip}) =>(
  <OverlayTrigger placement="top" overlay={<Popover><p style={{margin:'0.8rem'}}>{toolTip}</p></Popover>}>
    <FontAwesomeIcon icon={faQuestionCircle}/>
  </OverlayTrigger>
)

const TableHead = ({title, isRequired, toolTip, ...rest}) =>{
  const theme = useTheme();
  const them = !theme ? {} : theme;
  const palette = !them.palette ? {} : them.palette;
  return(
  <td className={palette.type === 'dark' && 'dark-theme-color'} {...rest}>{title}
   <span className="ml-1"><OverlayTrigger placement="top" overlay={<Popover><p style={{margin:'0.8rem'}}>{toolTip}</p></Popover>}>
    <FontAwesomeIcon icon={faQuestionCircle}/>
  </OverlayTrigger></span>
  {isRequired ? <span className="help-block">*</span> : null}
  </td>
  )
}


const Label = ({name, type, ...rest}) => {
  return (
    <Form.Label className="form__label"  {...rest} >{name}{type && <span className="ml-1 help-block">*</span>}</Form.Label>
  )
};

export {FormLabel, TableHead, ToolTip}


export default Label;





