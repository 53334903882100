import {useEffect, useState} from "react";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import moment from "moment";
import {formatDate, formatTime} from "../../DoctorsModule/DoctorSchedule";
import { useAddSchedule } from "./useAddSchedule";
import { useEditSchedule } from "./useEditSchedule";

export const useScheduleList = (props) =>{
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const {actions, } = props;
    const [searchValue, setSearchValue] = useState('');
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('sl');
    const [schedule_id, setScheduleId] = useState('');
    const [schedules, setSchedules] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const [loading, setLoading] = useState('idle');
    const [openDelete, setOpenDelete] = useState(false);
    const [total_count, setCount] = useState(0);
    const [calenderData, setCalenderData] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [details, setDetails] = useState({event:'', date:'', resource:'', description:''})

    const { addScheduleProps
     } = useAddSchedule(actions, _token);

     const { editScheduleProps } = useEditSchedule(actions)

    useEffect(() => {
        setLoading('pending');
        const formData = new FormData();
        formData.append('search', searchValue);
        axios.post(`${config.smsUrl}/cshiftschedule/scedule_list/${activePage}`, formData).then(res => {
            const data = res.data;
            const dt = !data.schedule ? [] : data.schedule;
            const count = !data.total_count ? 0 : data.total_count;
            const arr = dt.map(item=>{
                const date = item.slot_date.split('T')[0];
                return {...item,
                start_time:moment(`${date}T${item.start_time}`).format('LT'),
                end_time:moment(`${date}T${item.end_time}`).format('LT'),
            }})
            const calender =  dt.map(item=>{
                const date = item.slot_date.split('T')[0];
                return {
                    id:item.schedule_id,
                    start:moment(`${date}T${item.start_time}`).toDate(),
                    end:moment(`${date}T${item.end_time}`).toDate(),
                    title:item.slot_name,
                    resource:`${item.schedule_owner}-${item.description ? item.description : ''}`
                }})
            setSchedules(arr);
            setCalenderData(calender)
            setCount(count);
            setLoading('resolved')
        }).catch(err => {
            setLoading('rejected');
            logoutErrorMessage(err, null, actions)
        })
    }, [searchValue, activePage, addScheduleProps.isResolved, editScheduleProps.isResolved]);

    const handleClick = (e,event) =>{
        const {title, resource, start, end} = e;
        setAnchorEl(event.currentTarget);
        setDetails({event:title, resource:resource.split('-')[0],description:resource.split('-')[1], date: `${formatDate(start)}, ${formatTime(start)} - ${formatTime(end)}`})
    }
    const handleClose = () =>{
        setAnchorEl(null)
    }

    const handleOpenDelete = (id) => {
        setOpenDelete(true);
        setScheduleId(id);
    };

    const handleCloseDelete = () => {
        setOpenDelete(false)
    };

    const handleDelete = () => {
        const formData = new FormData();
        formData.append('schedule_id', schedule_id);
        axios.post(`${config.smsUrl}/cshiftschedule/delete_schedule`, formData).then(() => {
            const list = schedules.filter(shift => shift.schedule_id !== schedule_id);
            setSchedules(list);
            actions.snackbarActions.snackSuccess('Doctor schedule deleted successfully');
            setOpenDelete(false)
        }).catch(err => {
            errorMessages(err, null, actions)
        })
    };

    const handleSearch = (event) => {
        setSearchValue(event.target.value);
    };

    const handleChangeActivePage = (page) => {
        setActivePage(page)
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    return {searchValue, order, orderBy, schedule_id, schedules, activePage, loading, openDelete,
        total_count, handleOpenDelete, handleCloseDelete, handleDelete, handleSearch, handleChangeActivePage,
        handleRequestSort, closeSnackbar,setScheduleId, setSchedules,calenderData, anchorEl,
        details, handleClick, handleClose,  addScheduleProps, editScheduleProps }
}