import React from "react";
import Dialog from "@material-ui/core/Dialog";
import {DialogContent, DialogTitle} from "../SmsDialog";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
paper:{
    overflow:'initial'
}
}))

const ReusableDialog = ({openDialog, handleClose, message, handleSubmit, isSubmitted, children, width}) => {
    const classes = useStyles()
    return (
        <Dialog open={openDialog} maxWidth={width} fullWidth classes={{paper:classes.paper}}>
            <DialogTitle onClose={handleClose}>
                <div style={{fontSize: 15}} className="text-center">
                    {message}
                </div>
            </DialogTitle>
            <DialogContent classes={{root:classes.paper}}>
                <form onSubmit={handleSubmit} autoComplete="off" className="general-card">
                    {children}
                    <button type="submit" className="btn btn-sm sms-info-btn"
                            disabled={isSubmitted === 'pending'}>{isSubmitted === 'pending' ? 'Submitting...' : 'Submit'}</button>
                </form>
            </DialogContent>

        </Dialog>
    )

};
export default ReusableDialog;
