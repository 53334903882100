/** @jsxImportSource @emotion/react */
import {jsx, css} from '@emotion/react'
import * as React from 'react';
import Collapse from '@material-ui/core/Collapse';
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";       ;
import {table, smallTable} from "../../../styles/tableStyles";
import {Fragment} from "react";

const paddingLeft = css`
    padding-left: 15px;
`

export const AssetInfo = ({state={}}) =>{
    const {serial_number, model_number,product_condition,warranty, manufacturer} = state
    return (
        <Fragment>
            <tr>
                <td css={[paddingLeft]}>
                    Serial Number: <strong>{serial_number ? serial_number : 'N/A'}</strong>
                </td>
                <td>
                    Model Number: <strong>{model_number ? model_number : 'N/A'}</strong>
                </td>
            </tr>
            <tr>
                <td css={[paddingLeft]}>
                    Product Condition: <strong>{product_condition ? product_condition : 'N/A'}</strong>
                </td>
                <td>
                    Warranty: <strong>{warranty ? warranty : 'N/A'}</strong>
                </td>
            </tr>
            <tr>
                <td colSpan={2} css={[paddingLeft]}>
                    Manufacturer: <strong>{manufacturer ?  manufacturer : 'N/A'}</strong>
                </td>
            </tr>
        </Fragment>
    )
}


export function ViewDetailsComponent({title, handleOpen, children, name, open,  button=null, user=null}) {
    return (
        <Fragment>
            <table css={[table]}>
                <tbody>
                <tr>
                    <td css={[paddingLeft]} colSpan={2} onClick={handleOpen}><strong>{title}</strong><span style={{color:'blue', fontSize:'12px',display:'inline-block', marginLeft:8,cursor:'pointer' }}>{open ? 'Hide details' : 'Show details'}</span></td>
                </tr>
                </tbody>
            </table>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <table css={[table]}>
                    <tbody>
                        {children}
                    </tbody>
                </table>
            </Collapse>

        </Fragment>
);
}

