import * as types from "../../actions/actionTypes";


const initialState = {
    prescriptions: [],
    caseStudyList: [],
    caseStudy: {},
    total_count:0
};

export default function (state = initialState, action) {
    switch (action.type) {
        case types.ADD_CASESTUDY_REQUEST:
            return {loading: true};
        case types.ADD_CASESTUDY_SUCCESS:
            return {loading: false};
        case types.ADD_CASESTUDY_ERROR:
            return {loading: false};
        case types.PRESCRIPTION_TOTAL_COUNT:
            return {...state, total_count: action.total_count};
        case types.PRESCRIPTION_LIST_REQUEST:
            return {...state, loading: true};
        case types.PRESCRIPTION_LIST_SUCCESS:
            return {...state, prescriptions: action.prescriptions, loading: false};
        case types.PRESCRIPTION_LIST_ERROR:
            return {...state, loading: false};
        case types.DELETE_PRESCRIPTION:
            return {...state,
                prescriptions: state.prescriptions.filter(prescription => prescription.prescription_id !== action.prescription_id)
            };
        case types.CASE_STUDY_LIST_REQUEST:
            return {...state, loading: true};
        case types.CASE_STUDY_LIST_SUCCESS:
            return {...state, caseStudyList: action.caseStudyList, loading: false};
        case types.CASE_STUDY_LIST_ERROR:
            return {...state, loading: false};
        case types.CASE_STUDY_UPDATE_FORM:
            return {...state, caseStudy: action.caseStudy};
        case types.DELETE_CASE_STUDY:
            return {...state,
                caseStudyList: state.caseStudyList.filter(caseStudy => caseStudy.casestudy_id !== action.casestudy_id)
            };
        default:
            return state;
    }
}
