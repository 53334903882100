import {css} from '@emotion/react';
import { history } from '../../../Helpers/history';
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCoins} from "@fortawesome/free-solid-svg-icons/faCoins";
import PageTitle from "../../Utils/smsTitle";
import { Button,  MiniCenteredDiv, RightAlignedContainer } from '../../Utils/styledComponents'
import { PrintTemplate, ItemsTable } from '../../Utils/Templates/PrintTemplate'
import { usePrint } from '../../Utils/Templates/usePrint';
import * as colors from '../../../styles/colors';
import { formatAmount } from '../../Utils/formatNumbers';
import Card from '@material-ui/core/Card'
import { LoadingGif } from '../../Utils/Loader';
import { useViewCreditorsReceipt } from "./hooks/useViewCreditorsReceipt";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as snackbarActions from "../../../actions/snackbarActions"
import MainSnackbar from '../../Utils/Snackbar/SmsSnackbar';
import {coloredInvoice, thUppercase} from "../../../styles/tableStyles";
import { BackButton } from '../../Utils/Buttons/DataExportationButton';


const textWeight = css`
    font-weight:600;
`

const tableCell = css`
    border-top:1.4px solid ${colors.text00};
    padding-top: 16px !important;
`


const titleText = css`
    margin: 0;
    color: ${colors.darkBlue001};
    font-size: 15px;
    
`

const borderless = css`
   border-bottom: 0;
`

const tableMargin = css`
    margin-bottom: 0;
`

function CreditorsLedger({actions, snackbars, match}) {
    const headData = [{key:'name',value:'Item'}, {key:'quantity',value:'Quantity'}, {key:'rate',value:'Rate'}, {key:'amount',value:'Amount'}]
    const  { isPending, isResolved, isRejected, invoices, totals, closeSnackbar} = useViewCreditorsReceipt(actions, match)
    const {openBar, type, message} = snackbars;
    const {paid_amount, due_amount_total, total_bill_amount} = totals;
    const servicesArr = invoices.filter(bill =>!bill.bed_assignment_id);
    const obj = servicesArr[0] ? servicesArr[0] : {}

    const handleOnAfterPrint = () => {
        history.push('/debtors');
    }

    const {componentRef, handlePrint, getPageMargins, fontSize}  = usePrint('CreditorsLedger', handleOnAfterPrint)
    const patientDetails = (
        <tbody>
        <tr>
            <td css={[textWeight, thUppercase]} ><p css={[titleText]}>Invoice To:</p></td>
        </tr>
        <tr>
            <td ><span>Name: </span> <span css={[textWeight]}>{obj.billed_to ? obj.billed_to : ''}</span></td>

        </tr>
        <tr>
            <td ><span>Contact:</span> <span css={[textWeight]}></span></td>
        </tr>
        <tr>
            <td><span>Account Holder: </span> <span css={[textWeight]}>{obj.account_name}</span></td>
        </tr>
        </tbody>
    )
  const itemDetails = (
    <ItemsTable {...{headData,coloredTableHead:coloredInvoice}}>
      <tbody>
      {servicesArr.map((item) => {
            const qty = item.quantity ? item.quantity : 0;
            const rate = item.rate ? item.rate : 0
            return (
                <tr key={item.bill_id}>
                    <td><span>{item.service_id ? item.service_name : item.product_id ? item.product_name : ''}</span></td>
                    <td><span>{item.quantity}</span></td>
                    <td><span>{formatAmount(item.rate)}</span></td>
                    <td><span>{formatAmount(qty * rate)}</span></td>
                </tr>
            )
        })}

          <tr>
              <td css={[tableCell]}/>
              <td css={[tableCell]}/>
              <td css={[tableCell,textWeight]}><span >Total Bill</span></td>
              <td css={[tableCell]}><span css={[textWeight]}>{formatAmount(total_bill_amount)}</span></td>
          </tr>
          <tr>
              <td/>
              <td/>
              <td css={[textWeight]}><span >Paid Amount</span></td>
              <td><span css={[textWeight]}>{formatAmount(paid_amount)}</span></td>
          </tr>
          <tr>
              <td/>
              <td/>
              <td css={[textWeight]}><span >Due Amount</span></td>
              <td><span css={[textWeight]}>{formatAmount(due_amount_total)}</span></td>
          </tr>
      </tbody>
    </ItemsTable>
  )
  return (
      <div>
            <PageTitle title="Patient Ledger"/>
            <SubHeader title="Billing" subTitle="Patient ledger">
                <FontAwesomeIcon icon={faCoins}/>
            </SubHeader>
            <MainSnackbar variant={type} handleCloseBar={closeSnackbar} open={openBar} message={message}/>
            {isPending ? <LoadingGif/>:null}
            {isResolved ?
                <MiniCenteredDiv>
                    <BackButton to='/debtors' text='Debtors List' data-testid="creditors-ledger-back-button"/>
                    <RightAlignedContainer>
                    <Button variant='orange' onClick={handlePrint}>Print</Button>
                    </RightAlignedContainer>
                    <Card className='mt-2 p-3'>
                        <div ref={componentRef} className="requisition-receipt">
                            <style>
                                {getPageMargins()}
                                {fontSize()}
                            </style>
                            <PrintTemplate title='INVOICE' {...{patientDetails,itemDetails,bioBordered:borderless, bioMargin:tableMargin}}/>
                        </div>
                    </Card>
                </MiniCenteredDiv> : null}
                {isRejected ? <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                    <p>The server did not return a valid response</p>
                </div>:null}
        </div>
  )
}


function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(CreditorsLedger);
