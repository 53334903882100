import React from "react";
import PageTitle from "../Utils/smsTitle";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCog} from "@fortawesome/free-solid-svg-icons/faCog";
import Card from "@material-ui/core/Card";
import FormGroup from "../Utils/FormInputs/FormGroup";
import Form from "react-bootstrap/Form"
import Row from "react-bootstrap/Row";
import Label from "../Utils/FormInputs/Label";
import Col from "react-bootstrap/Col";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../actions/snackbarActions"
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import {useTheme} from "@material-ui/core/styles";
import {CustomSelect} from "../Utils/FormInputs/SelectInput";
import {useEditUser} from "./CustomHooks/useEditUser";


export function EditUser(props) {
    const {state, submitted, isSubmitted,  employee_id, employees, handleChangeEmployee,
        handleChange, handleSubmit, handleCloseSnackbar} = useEditUser(props)
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    const {openBar, type, message} = props.snackbars;
    return (
        <div data-testid="edit-user">
            <PageTitle title="Edit User"/>
            <SubHeader title="Settings" subTitle="Edit User">
                <FontAwesomeIcon icon={faCog}/>
            </SubHeader>
            <div className="add-service-card">
                <Card className="p-3">
                    <h6 className={`visit-heading ${palette.type === "dark" && "dark-visit-heading"}`}>Edit User</h6>
                    <MainSnackbar message={message} open={openBar} handleCloseBar={handleCloseSnackbar} variant={type}/>
                    <Form onSubmit={handleSubmit}>
                        {/*<FormGroup onChange={handleChange} submitted={submitted} labelType={true} value={state.first_name} colSm={7} labelSm={2}*/}
                        {/*           dataTest="first_name" name="first_name" type="text" labelName="First Name"/>*/}
                        {/*<FormGroup onChange={handleChange} submitted={submitted} labelType={true} value={state.last_name} colSm={7} labelSm={2}*/}
                        {/*           dataTest="last_name" name="last_name" type="text" labelName="Last Name"/>*/}
                        <Form.Group as={Row}>
                            <Label column sm={2} name="Employee" html='employee_id' type/>
                            <Col sm={7}>
                                <CustomSelect options={employees.map(employee => ({
                                    label: `${employee.first_name} ${employee.last_name}`,
                                    value: employee.employee_id
                                }))} onChange={handleChangeEmployee} value={employee_id} dataTest="employee_id"/>
                                {(submitted && !employee_id) &&
                                <div className="help-block">Employee name is required</div>}
                            </Col>
                        </Form.Group>
                        <FormGroup onChange={handleChange} submitted={submitted} labelType={true} value={state.email}
                                   colSm={7} labelSm={2}
                                   dataTest="email" name="email" type="email" labelName="Email/Username"/>
                        {/*<FormGroup onChange={handleChange} submitted={false} labelType={true} value={state.password} colSm={7} labelSm={2}*/}
                        {/*           dataTest="password" name="password" type="password" labelName="Password"/>*/}
                        <Form.Group as={Row}>
                            <Label column sm={2} name="Status" type/>
                            <Col sm={7}>
                                <Form.Check type="radio" inline label="Active" name="status" value={1}
                                            onChange={handleChange} id="active" checked={state.status === 1}/>
                                <Form.Check type="radio" inline label="Inactive" name="status" value={0}
                                            onChange={handleChange} id="inactive" checked={state.status === 0}/>
                            </Col>
                        </Form.Group>
                        <div className="text-right">
                            <button type="submit" disabled={isSubmitted === 'pending' || isSubmitted === 'resolved'} className="btn btn-sm sms-btn px-4">
                                {isSubmitted === 'pending' ? "Saving..." : "Save"}</button>
                        </div>
                    </Form>
                </Card>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {snackbars: state.snackbar}

}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(EditUser);
