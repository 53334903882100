import React from "react";
import PageTitle from "../Utils/smsTitle";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CustomTable, {ReusableCustomPagination} from "../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import {getComparator, stableSort} from "../Utils/Tables/sortingFunctions";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {Link} from "react-router-dom";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../actions/snackbarActions";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import DeleteDialog from "../Utils/Dialogs/DeleteDialog";
import {faCalendarCheck} from "@fortawesome/free-solid-svg-icons/faCalendarCheck";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {dateStringConvert} from "../Utils/ConvertDate";
import {useScheduleList} from "./CustomHooks/useScheduleList";
import ReusableTabs, {TabPanel} from "../Utils/Dialogs/ReusableTabs";
import {Calendar, momentLocalizer, Views} from "react-big-calendar";
import moment from "moment";
import {useCalenderStyles} from "../DoctorsModule/DoctorSchedule";
import ReusablePopOver from "../Utils/Dialogs/ReusablePopOver";
import Typography from "@material-ui/core/Typography";
import CustomDialog from '../Utils/Dialogs/CustomDialog'
import AddSchedule from "./AddSchedule";

const headData = [{id: 'sl', numeric: false, disablePadding: false, label: 'SL'},
    {id: 'doctor', numeric: false, disablePadding: false, label: 'Doctor Name'},
    {id: 'slot', numeric: false, disablePadding: false, label: 'Slot Name'},
    {id: 'description', numeric: false, disablePadding: false, label: 'Description'},
    {id: 'day', numeric: false, disablePadding: false, label: 'Date'},
    {id: 'startTime', numeric: false, disablePadding: false, label: 'Start Time'},
    {id: 'endTime', numeric: false, disablePadding: false, label: 'End Time'},
    {id: 'actions', numeric: false, disablePadding: true, label: 'Action'},
];

export const ScheduleList = (props) => {
    const localizer = momentLocalizer(moment)
    const classes = useCalenderStyles();
    const {searchValue, order, orderBy, schedules, activePage, loading, openDelete,
        total_count, handleOpenDelete, handleCloseDelete, handleDelete, handleSearch, handleChangeActivePage,
        handleRequestSort, closeSnackbar,calenderData, anchorEl,
        details, handleClick, handleClose,addScheduleProps, editScheduleProps } = useScheduleList(props)
    const {event,resource,date,description} = details;
    const [value, setValue] = React.useState(0);

    const tabHeading = ['Table', 'Calendar']

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const status = 1;
    const {openBar, type, message} = props.snackbars;
    return (
        <div data-testid="schedule-list" className='journals'>
            <PageTitle title="Schedule List"/>
            <SubHeader title="Shifts & Schedule" subTitle="Schedule list">
                <FontAwesomeIcon icon={faCalendarCheck}/>
            </SubHeader>
            <ReusableTabs value={value} handleChange={handleChange} tabHeading={tabHeading}>
                <TabPanel value={value} index={0}>
                    <div className='general-ledger-header'>
                        <div className="text-right">
                            <button className="btn sms-btn btn-sm" onClick={addScheduleProps.handleOpenAddDialog}><FontAwesomeIcon icon={faPlus}/> Add Schedule</button>
                        </div>

                        <CustomDialog  open={addScheduleProps.openAddDialog} handleClose={addScheduleProps.handleCloseAddDialog} title="Add Schedule"> 
                            <AddSchedule { ...addScheduleProps }/>
                      </CustomDialog>

                    </div>

                    <CustomDialog  open={editScheduleProps.openEditDialog} handleClose={editScheduleProps.handleCloseEditDialog} title="Edit Schedule"> 
                            <AddSchedule { ...editScheduleProps }/>
                      </CustomDialog>
                    <div className="mui-tables">
                        <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>
                        <DeleteDialog message={status === 1 ?  'deactivate':'activate'} openDialog={openDelete} handleClose={handleCloseDelete}
                                    text="schedule" title={`${status === 1 ? 'Deactivate':'Activate'} Schedule`}>
                            <button className='btn sms-info-btn btn-sm' onClick={handleDelete}>{status === 1 ? 'Deactivate':'Activate'}</button>
                            <button className="btn sms-grey-btn btn-sm" onClick={handleCloseDelete}>No</button>    
                        </DeleteDialog>
                        <CustomTable title="Schedule List" handleChangeNextPage={handleChangeActivePage} records={10}
                                     headData={headData} handler={handleSearch} activePage={activePage} term={searchValue}
                                     handleRequestSort={handleRequestSort} data={schedules} colSpan={5} order={order}
                                     total_count={total_count}
                                     orderBy={orderBy} id="searchValue" customPage>
                            {loading === 'pending' ? <TableBody><TableRow>
                                <TableCell colSpan="9" align="center"><img src="/images/smsloader.gif"
                                                                           alt="loader"/></TableCell>
                            </TableRow></TableBody> : schedules.length > 0 ?
                                <TableBody>
                                    {stableSort(schedules, getComparator(order, orderBy))
                                        .slice(0, 10)
                                        .map((item, index) => {
                                            const count = ((activePage - 1) * 10) + index + 1;
                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    key={item.schedule_id}
                                                    className='mui-table-row'
                                                >
                                                    <TableCell>{count}</TableCell>
                                                    <TableCell>{item.schedule_owner}</TableCell>
                                                    <TableCell>{item.slot_name}</TableCell>
                                                    <TableCell>{item.description}</TableCell>
                                                    <TableCell>{dateStringConvert(item.slot_date)}</TableCell>
                                                    <TableCell>{item.start_time}</TableCell>
                                                    <TableCell>{item.end_time}</TableCell>
                                                    <TableCell>
                                                
                                                        <button onClick={()=> editScheduleProps.handleOpenEditDialog(item.schedule_id) }
                                                            className="btn btn-sm sms-info-btn mr-1 mb-2">Edit
                                                        </button>
                                                       
                                                        <button
                                                            onClick={() => handleOpenDelete(item.schedule_id)}
                                                            className="btn btn-sm sms-btn-dismiss">Delete
                                                        </button>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody> : <TableBody><TableRow>
                                    <TableCell colSpan="9" align="center">No records found</TableCell>
                                </TableRow></TableBody>}
                        </CustomTable>
                    </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <ReusablePopOver handleClose={handleClose} anchorEl={anchorEl}>
                        <Typography variant="h5" className={classes.typography}>{event}</Typography>
                        <Typography variant="span" className={classes.span}>{date}</Typography>
                        {description ? <Typography className={classes.typography}>{description}</Typography>: null}
                        <Typography className={classes.typography}>{`Dr. ${resource}`}</Typography>
                    </ReusablePopOver>
                    {/*<div className='general-ledger-header'>*/}
                    {/*    <div className="text-right">*/}
                    {/*        <Link to="/addschedule">*/}
                    {/*            <button className="btn sms-btn btn-sm"><FontAwesomeIcon icon={faPlus}/> Add Schedule</button>*/}
                    {/*        </Link>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {loading === 'pending' ? <div className='text-center mt-5'><img src="/images/listPreloader.gif"
                                                                                    alt="loader" style={{width: '10%'}}/></div> : loading === 'resolved' && calenderData
                        .length > 0 ?
                        <div className='px-3 mt-3'>
                            <Calendar selectable
                                      popup
                                      localizer={localizer}
                                      events={calenderData}
                                      defaultView={Views.MONTH}
                                      views={['month', 'week','day']}
                                      style={{height: "70vh"}}
                                      onSelectEvent={(event, e) => handleClick(event,e)}
                            />
                            <ReusableCustomPagination activePage={activePage} total_count={total_count} data={calenderData}
                            records={10} handleChangeNextPage={handleChangeActivePage}/>
                        </div> : <div className='text-center mt-5'>No events scheduled</div>}
                </TabPanel>
            </ReusableTabs>

        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleList);
