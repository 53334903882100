import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as scanActions from "../../actions/RadiologyActions/scanActions";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXRay} from "@fortawesome/free-solid-svg-icons/faXRay";
import Card from "@material-ui/core/Card";
import PageTitle from "../Utils/smsTitle";
import RadiologyReport from "./RadiologyReport";
import * as snackbarActions from "../../actions/snackbarActions"
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import axios from 'axios';
import { logoutErrorMessage } from "../../Helpers/ErrorMessages";
import { config } from "../../Helpers/env";
import { formatDateTime } from "../Utils/ConvertDate";
import { history } from "../../Helpers/history";
import { usePrint } from "../Utils/Templates/usePrint";
import {BackButton} from "../Utils/Buttons/DataExportationButton";

const  ViewResults = ({snackbars, actions, match}) =>{
    const [scanResults, setScanResults] = useState({})
    const {scan_requests_detail_id, scan_result_id, visit_id, patient_number} = match.params;

    useEffect(()=>{
        const formData = new FormData();
        formData.append('scan_result_id',scan_result_id);
        axios.post(`${config.smsUrl}/cradiology/get_scan_results`, formData).then((res) => {
            const data = res.data;
            const dt = !data ? {} : data;
            setScanResults({...dt, patient_info:{...dt?.patient_info, visit_id}})
        }).catch(err => {
          logoutErrorMessage(err, null, actions)
        })
    },[scan_result_id])

    const handleOnAfterPrint = () => {
        history.push('/scanresults');
    }

    const closeSnackbar = () =>{
        actions.snackbarActions.hideSnackbar()
    };

    const results = !scanResults ? {}: scanResults;
    const patient_info = !results.patient_info ?{}:results.patient_info;
    const result_details = !results.scan_result ?{}:results.scan_result;
    const {patient_firstname,  patient_lastname, time_requested,patient_type} = patient_info;
    const patient_name = `${!patient_firstname ? "" : patient_firstname} ${!patient_lastname ? "" : patient_lastname}`;
    const date = time_requested ? formatDateTime(time_requested) : ''
    const {openBar, type, message} = snackbars;
    const {componentRef, handlePrint, a4Styles}  = usePrint(`RadiologyReport-{${patient_number}}-{${date}}`, handleOnAfterPrint);
    const a4Paper = a4Styles();
    return (
        <div>
            <PageTitle title="Scan Results"/>
            <SubHeader title="Radiology" subTitle="View Scan Results">
                <FontAwesomeIcon icon={faXRay}/>
            </SubHeader>
            <div className="radiology-report">
                <MainSnackbar message={message} variant={type} handleCloseBar={closeSnackbar} open={openBar}/>
                <BackButton text='Scan Results' to='/scanresults'/>
                <div className="text-right button-grp mb-1">
                    <button  onClick={handlePrint} className="btn btn-sm sms-amber-btn mr-3">Print</button>
                    
                    <Link to={{pathname: `/editscanresults/${visit_id}/${patient_number}/${scan_requests_detail_id}/${scan_result_id}/${patient_type}`}}>
                        <button className="btn btn-sm sms-info-btn">Edit</button>
                    </Link>
                </div>
                <Card className={a4Paper.centreItems}>
                    <div className="lab-report" ref={componentRef}  id="radiology-report" style={{ width: "80%", margin:"auto"}}>
                        <RadiologyReport patient_info={patient_info} scan_result={result_details}/>
                    </div>
                </Card>
            </div>
        </div>

    )
}

function mapStateToProps(state) {
    return {
        scans: state.scans,
        snackbars: state.snackbar
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            scanActions: bindActionCreators(scanActions, dispatch),
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewResults);
