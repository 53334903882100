import {useEffect, useState} from "react";
import {useOutPatientTriage} from "./useOutPatientTriage"
import axios from "axios";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import { config } from "../../../Helpers/env";

export const useVisitPatientTriage = (actions, obj) => {
    const [allMetrics, setAllMetrics] = useState([])
    const [triageList, setTriageList] = useState([]);
    const [triage, setTriage] = useState([])
    const [{weight_metric_id, weight_metric_name, weight_patientmsr_id}, setWeight] = useState({weight_metric_id: 2, weight_metric_name: '', weight_patientmsr_id:''});
    const [{height_metric_id, height_metric_name, height_patientmsr_id}, setHeight] = useState({height_metric_id: 1, height_metric_name: '', height_patientmsr_id:''});
    const [bmi, setBMI] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const {metric_name, uom, submitMetric, 
        isMetricSubmit, openDialog, handleOpenDialog, handleCloseDialog,
        handleChangeMetric, handleSubmitMetric, setSaveTriage
    } = useOutPatientTriage(actions)
    const [otherMetric, setOtherMetric] = useState('');
    const [others, setOthers] = useState([]);
    const [optionalMetrics, setOptionalMetrics] = useState([]);

    const [triage_element, setTriageElement] = useState({});
    const [isEditTriage, setEditTriage] = useState(false);
    const [allergies, setAllergies] = useState('')
    const [hasAllergies, setHasAllergies] = useState(false)
    const [chronic_illness, setChronicIllness] = useState({cardiac_disease:false, kidney_disease:false,
        hypertension:false, tb:false, asthma:false, sti:false, sickle_cell_disease:false, epilepsy:false,
        diabetes:false, other_chronic_illness:''});

    const [isBlocking, setIsBlocking] = useState(false)
    // const {p_number, visit_id,activity_id} = obj

    const isSubmitting = isSubmitted === 'pending';

    const {patient_number:p_number, visit_id, setShowTriage,setOpenDialog,activity_id} = obj

    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;

    useEffect(() => {
            axios.get(`${config.smsUrl}/cvisit/retrieve_all_metrics`).then(res => {
                const data = res.data;
                const dt = data ? data : {};
                const metrics = dt.metrics ? dt.metrics : [];
                setAllMetrics(metrics)
            }).catch(err => {
                logoutErrorMessage(err, null, actions)
            })
    }, [])

    useEffect(() => {
        if (isEditTriage === true) {
   
            const filteredArr = allMetrics.sort((p1, p2) => {
                if ( p1.metric_id < p2.metric_id ){
                    return -1;
                  }
                  if ( p1.metric_id > p2.last_nommetric_id ){
                    return 1;
                  }
                  return 0;
            })

            // set the metrices < 2, the height and weight params
            let old_weight = 0;
            let old_height = 0;

            const weight_height_arr = filteredArr.slice(0,2);

            // set the height
            const hght = weight_height_arr[0];
            old_height = parseInt(hght['metric_value']);
            setHeight({
                height_metric_id:1, height_metric_name: parseInt(hght['metric_value']),
                height_patientmsr_id: hght['patientmsr_id']
            })

            // set the weight
            const wght = weight_height_arr[1];
            old_weight = parseInt(wght['metric_value']);
            setWeight({
                weight_metric_id:2, weight_metric_name: parseInt(wght['metric_value']),
                weight_patientmsr_id: wght['patientmsr_id']
            })

            calculateBMI(old_weight, old_height);

            const tArr = filteredArr.slice(2, 3).map(item => ({
                ...item,
                metric_id: item.metric_id,
                metric_name: `${item.metric_name}(${item.uom ? item.uom : ''})`,
                metric_value: item.metric_value , name: `metric_${item.metric_id}`
            }))

            setTriage(tArr)

            const tList = filteredArr.slice(3, 5).map(item => ({
                ...item,
                metric_id: item.metric_id,
                metric_name: `${item.metric_name}(${item.uom ? item.uom : ''})`,
                metric_value: item.metric_value, name: `metric_${item.metric_id}`
            }));
            setTriageList(tList);

            const other_metrics = filteredArr.slice(5).map(item => ({
                ...item,
                value: item.metric_id,
                label: `${item.metric_name}(${item.uom ? item.uom : ''})`,
                metric_value: item.metric_value
            })) 
            setOptionalMetrics(other_metrics);
        } else {
            const filteredArr = allMetrics.filter(item => item.metric_id > 2);
            const tArr = filteredArr.slice(0, 1).map(item => ({
                ...item,
                metric_id: item.metric_id,
                metric_name: `${item.metric_name}(${item.uom ? item.uom : ''})`,
                metric_value: '', name: `metric_${item.metric_id}`
            }))
            setTriage(tArr)
            const tList = filteredArr.slice(1, 3).map(item => ({
                ...item,
                metric_id: item.metric_id,
                metric_name: `${item.metric_name}(${item.uom ? item.uom : ''})`,
                metric_value: '', name: `metric_${item.metric_id}`
            }))
            const other_metrics = filteredArr.slice(3).map(item => ({
                ...item,
                value: item.metric_id,
                label: `${item.metric_name}(${item.uom ? item.uom : ''})`,
                metric_value:''
            })) 
            setOthers(other_metrics);
            setTriageList(tList)
        }
    }, [allMetrics])

    const handleChange = (event, index) => {
        const triageCopy = [...triage];
        const {value} = event.target;
        triageCopy[index].metric_value = value;
        setTriage(triageCopy)
        setIsSubmitted('idle')
    };

    const clearTriageDetails = () => {
        setBMI('');
        setOtherMetric('');
        setWeight({weight_metric_id: 2, weight_metric_name: ''});
        setHeight({height_metric_id: 1, height_metric_name: ''});
        setOtherMetric('');
        const arr1 = triageList.map(item=>({...item, metric_value:''}));
        setTriageList(arr1);
        const arr2 = optionalMetrics.map(item => ({...item, metric_value:''}));
        setOptionalMetrics(arr2);
        const arr3 = triage.map(item => ({...item, metric_value:''}));
        setTriage(arr3);
        const arr4 = others.map(item => ({...item, metric_value:''}));
        setOthers(arr4);
        setSubmitted(false);
    }

    const handleChangeOthersMetrics = (value) => {
        setOtherMetric(value)
        const obj = {};
        obj['metric_value'] = '';
        obj['metric_id'] = value.value;
        obj['metric_name'] = value.label;
        const existingIds = optionalMetrics.map((id) => id.metric_id)
        if (!existingIds.includes(value.value)) {
            setOptionalMetrics([...optionalMetrics, obj])
        }
        setIsSubmitted('idle')

        const arr = others.map(item=>{
            if (item.value === value.value){
                return {...item, metric_value:value.value}
            }
            return item
        })
        setOthers(arr)

    }

    const handleChangeOptional = (event, metric_id) => {
        const arr = optionalMetrics.map(metric => {
            if (metric.metric_id === metric_id) {
                return {...metric, metric_value: event.target.value}
            }
            return metric
        })
        setOptionalMetrics(arr)
        setIsSubmitted('idle')
    }

    const removeMetric = (metric_id) =>{
        const arr = optionalMetrics.filter(item=>item.metric_id !== metric_id);
        setOptionalMetrics(arr)
    }

    const handleChangeVitals = (event, index) => {
        const triageCopy = [...triageList];
        const {value} = event.target;
        triageCopy[index].metric_value = value;
        setTriageList(triageCopy)
        setIsSubmitted('idle')
    };

    const calculateBMI = (weight, height) => {
        const h = height ? height : 0;
        if (h > 0) {
            setBMI(((+weight / Math.pow(+h, 2)) * 10000).toFixed(2))
        } else {
            setBMI(0)
        }

    }

    const handleChangeWeight = (event) => {
        const {value} = event.target;
        setWeight(currentState => ({...currentState, weight_metric_name: value}))
        calculateBMI(value, height_metric_name)
        setIsSubmitted('idle')
    }

    const handleChangeHeight = (event) => {
        const {value} = event.target;
        setHeight(currentState => ({...currentState, height_metric_name: value}))
        calculateBMI(weight_metric_name, value)
        setIsSubmitted('idle')
    }

    const handleOtherMedicals = (event) => {
        setChronicIllness({...chronic_illness, other_chronic_illness:event.target.value});
        setIsBlocking(true)
    }
    const handleMedicalIllness = (event) => {
        const {name, checked} = event.target;
        setChronicIllness({...chronic_illness, [name]:checked});
        setIsBlocking(true)
    }

    const handleHasAllergies = (event) => {
        const {value} = event.target
        if(value === 'yes'){
            setHasAllergies(true)
        }else if(value === 'no'){
            setHasAllergies(false)
        }
        setIsBlocking(true)

    }

    const handleChangeAllergies = (event) => {
        const {value} = event.target
        setAllergies(value)
        setIsBlocking(true)
    }

    const cleareTriage = (arr) =>{
        const listArr = arr.map(item=>({
            ...item, metric_value:''
        }))
        return listArr
    }

    const handleSubmitTriage = (event, selectService) => {
        event.preventDefault();
        setSubmitted(true);
        const formData = new FormData(event.currentTarget);
        const fieldValues = Object.fromEntries(formData.entries())
        if(hasAllergies === false && allergies){
            setAllergies('');
        }
        const {other_chronic_illness, ...rest} = chronic_illness
        const illness = fieldValues['chronic_illness']
        const allergyData = fieldValues['allergies'] ? fieldValues['allergies'] : {}
        const others_arr =  others.filter(metric => !metric.metric_value)
        const triage_arr = [...triageList,...triage,...optionalMetrics].filter(metric => metric.metric_value && metric.metric_id)
        const jsonData = {
            'patient_number':p_number,
            'metric_type_id':1,
            'visit_id' :visit_id,
            'activity_id' :activity_id !== "undefined" ? activity_id : '',
            'visit_state':3,
            'user_roles_id' : user_roles_id,
            'chronic_illness': {...rest, other_chronic_illness:illness},
            allergies: allergyData,
                
            triage_info : [
                        ...triage_arr,...others_arr,
                           {  
                                'metric_id': height_metric_id,
                                'metric_value': height_metric_name
                           },
                           {
                                'metric_id': weight_metric_id,
                                'metric_value': weight_metric_name
                            }
                        
            ]
                    }
        const arr = triageList.filter(metric=>!(metric.metric_id === 6 || metric.metric_id === 5)).every(metric=>metric.metric_value);
        const arr1 = triage.every(metric => metric.metric_value)
        if (p_number) {
            setIsSubmitted('pending');
            axios.post(`${config.smsUrl}/cvisit/add_triage`, jsonData).then(res => {
                setIsSubmitted('resolved');
                actions.snackbarActions.snackSuccess('Triage Information added successfully');
                setTriageList(cleareTriage(triageList))
                setHeight({height_metric_id: 1, height_metric_name: ''})
                setWeight({weight_metric_id: 2, weight_metric_name: ''})
                setOptionalMetrics(cleareTriage(optionalMetrics))
                setTriage(cleareTriage(triage))
                setOtherMetric('');
                setBMI('');
                setSubmitted(false);
                if(setShowTriage){
                    setShowTriage(selectService)
                }
                if(setOpenDialog){
                    setOpenDialog(false)
                }
            }).catch((err) => {
                errorMessages(err, null, actions)
                setIsSubmitted('rejected');
            });
        }
        
    };

    const handleEditTriage = (event) => {
        // call to edit the triage
        event.preventDefault();
        setSubmitted(true);
        const formData = new FormData(event.currentTarget);
        const fieldValues = Object.fromEntries(formData.entries())
        if(hasAllergies === false && allergies){
            setAllergies('');
        }
        const {other_chronic_illness, ...rest} = chronic_illness
        const illness = fieldValues['chronic_illness']
        const allergyData = fieldValues['allergies'] ? fieldValues['allergies'] : {}
        const others_arr =  others.filter(metric => !metric.metric_value && metric.patientmsr_id)
        const triage_arr = [...triageList,...triage,...optionalMetrics].filter(metric => metric.metric_value &&
            metric.metric_id && metric.patientmsr_id)
        const jsonData = {
            patient_number:p_number,
            visit_id,
            visit_state:3,
            activity_id:activity_id !== "undefined" ? activity_id : '',
            user_roles_id,
            chronic_illness: {...rest, other_chronic_illness:illness},
           allergies: allergyData,
            metric_type_id:1,
            triage_info:[...triage_arr, ...others_arr,
                {metric_id:weight_metric_id, metric_value:weight_metric_name,patientmsr_id:weight_patientmsr_id},
                {metric_id:height_metric_id, metric_value:height_metric_name,patientmsr_id:height_patientmsr_id}]

        }
        const arr = triageList.filter(metric=>metric.metric_id !== 6).every(metric=>metric.metric_value);
        const arr1 = triage.every(metric => metric.metric_value)
        if (p_number && arr) {
            setIsSubmitted('pending');
            axios.post(`${config.smsUrl}/cvisit/update_triage`, jsonData).then(res => {
                setIsSubmitted('resolved');
                actions.snackbarActions.snackSuccess('Triage Information added successful');
                setTriageList(cleareTriage(triageList))
                setHeight({height_metric_id: 1, height_metric_name: '', height_patientmsr_id:''})
                setWeight({weight_metric_id: 2, weight_metric_name: '', weight_patientmsr_id:''})
                setOptionalMetrics(cleareTriage(optionalMetrics))
                setTriage(cleareTriage(triage))
                setOtherMetric('');
                setBMI('');
                setSubmitted(false);
            }).catch((err) => {
                errorMessages(err, null, actions)
                setIsSubmitted('rejected');
            });
        }
        
    }

    const isPending = isSubmitted === 'pending'
    const isResolved = isSubmitted === 'resolved'
    const isRejected = isSubmitted === 'rejected'


    const triageProps = {triageList, submitted, isSubmitted, handleChange,weight_metric_id, height_metric_id,
        handleChangeVitals, setTriageList, handleChangeWeight,handleSubmitTriage,isPending,setSubmitted, clearTriageDetails,
        handleChangeHeight, bmi, weight_metric_name, height_metric_name, triage, metric_name, uom, removeMetric,
        handleChangeMetric, handleSubmitMetric, isMetricSubmit, submitMetric, openDialog, handleOpenDialog,
        chronic_illness, handleMedicalIllness,handleOtherMedicals,handleHasAllergies,allergies,handleChangeAllergies,hasAllergies,
        handleCloseDialog, otherMetric, others, handleChangeOthersMetrics, optionalMetrics, handleEditTriage,setIsBlocking,
        handleChangeOptional, isRejected, isResolved, setHeight,setOptionalMetrics,setWeight, setTriage, setOtherMetric,
        triage_element, setTriageElement, isEditTriage, setEditTriage, setAllMetrics, allMetrics, setSaveTriage
    }

    return {triageProps}
}
