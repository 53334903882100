import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import {errorMessages,logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import { config } from "../../../../Helpers/env";
import {useCommonState} from "../../CustomHooks/useReusableFunctions";
import { exportPDF } from "../../../Utils/downloadPDF";
import {convertMonth} from "../../../Utils/ConvertDate";


export const useCashCollections = (actions, urls, component) => {
    const [generic_id, setId] = useState('');
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [chips, setChips] = useState([]);
    const [statistics, setStatistics] = useState([]);
    const [loading, setLoading] = useState('idle');
    const [collections, setList] = useState([]);
    const [cashiers, setCashiers] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [totals, setTotals] = useState({total_out:0, total_in:0, grand_total:0})
    const csvRef = useRef();
    const {closeSnackbar, search_type, text, textType, year, date, handleChangeSearch,
        handleChangeDate, handleChangeYear, handleIncrementYear, inputType,
        searchValue, order, orderBy, page, rowsPerPage, setSearchType,
        handleSearch, handleRequestSort, handleChangePage, handleChangeRowsPerPage
    } = useCommonState(actions)
    const {revenue_url, data_url} = urls
    const {start_date, end_date} = date;

    const calculateTotal = (arr) =>{
        const out_patient = arr.reduce((a,b)=>{
            return +a + +b.out
        },0)
        const in_patient = arr.reduce((a,b)=>{
            return +a + +b.in
        },0)
        const sum = arr.reduce((a,b)=>{
            return +a + +b.value
        },0)
        setTotals({total_out:out_patient, total_in:in_patient, grand_total:sum})
    }
    useEffect(() => {
        axios.get(`${config.smsUrl}/creport/${revenue_url}`).then(res => {
            const data = res.data;
            const dt = !data ? {} : data
            const payment = !dt.revenue ? [] : dt.revenue;
            if (component === 'cashier') {
                const pays = payment.map(item => ({
                    value: item.received_by,
                    label: `${item.first_name} ${item.last_name}`
                }))
                setCashiers(pays);
            } else {
                const pays = payment.map(item => ({
                    value: item.payment_type,
                    label: item.payment_method,
                }))
                setCashiers(pays);
            }
        }).catch(err => {
            logoutErrorMessage(err, null, actions);
        })
    }, []);

    useEffect(() => {
        setLoading('pending');
        axios.post(`${config.smsUrl}/creport/${data_url}`, null, {
            params: { start_date: start_date,
                end_date: end_date,
                option: 3,
                user_roles_id: '',
                data: true
            }
        }).then(res => {
            const data = res.data;
            const dt = !data ? [] : data;
            if (component === 'cashier') {
                const items = dt.map(item => ({
                    name: `${item.first_name} ${item.last_name}`,
                    value: item.cashier_revenue,
                    out: item.out_patient_collection,
                    in: item.in_patient_collection
                }))
                setList(items);
                calculateTotal(items)
            } else {
                const payTypes = dt.map(item => ({
                    name: item.payment_method, value: item.method_revenue, out: item.out_patient_collection,
                    in: item.in_patient_collection
                }))
                calculateTotal(payTypes)
                setList(payTypes);
            }
            setLoading('success');
        }).catch((err) => {
            setLoading('error');
            logoutErrorMessage(err, null, actions);
        })
    }, []);

    useEffect(() => {
        axios.post(`${config.smsUrl}/creport/${data_url}`, null, {
            params: { start_date: start_date,
                end_date: end_date,
                option: 3,
                user_roles_id: '',
                data: false
            }
        }).then(res => {
            const data = res.data;
            const dt = data ? data : [];
            setStatistics(dt)
        }).catch(err => {
            logoutErrorMessage(err, null, actions);
        })

    }, []);

    const handleChangeRevenueType = (value) => {
        setId(value)
        axios.post(`${config.smsUrl}/creport/${data_url}`, null, {params: { start_date: start_date,
            end_date: end_date,
            option: 3,
            user_roles_id: value.value,
            data:false
        }}).then(res => {
            const data = res.data;
            const dt = data ? data : [];
            setStatistics(dt)
        }).catch(err => {
            errorMessages(err, null, actions);
        })
    }

    const handleDeleteChip = (chipToDelete) => {
        setChips((chips) => chips.filter((chip) => `value_${chip.value}` !== chipToDelete.dataKey));
    };


    const handleRetrieveData = (val,  submit) => {
        submit && setSubmitted(true)
        if (start_date && end_date) {
            submit && setIsSubmitted('pending')
            setChips([])
            setLoading('idle')
            axios.post(`${config.smsUrl}/creport/${data_url}`, null, {
                params: { start_date: start_date,
                    end_date: end_date,
                    option: 3,
                    user_roles_id: '',
                    data: val
                }
            }).then(res => {
                const data = res.data;
                const dt = data ? data : [];
                if (val === false) {
                    setStatistics(dt)
                } else {
                    if (component === 'cashier') {
                        const items = dt.map(item => ({
                            name: `${item.first_name} ${item.last_name}`,
                            value: item.cashier_revenue,
                            out: item.out_patient_collection,
                            in: item.in_patient_collection
                        }))
                        setList(items);
                        calculateTotal(items)
                    } else {
                        const payTypes = dt.map(item => ({
                            name: item.payment_method, value: item.method_revenue, out: item.out_patient_collection,
                            in: item.in_patient_collection
                        }))
                        setList(payTypes);
                        calculateTotal(payTypes)
                    }
                }
                submit && setIsSubmitted('resolved')
                setLoading('success')
            }).catch(err => {
                submit && setIsSubmitted('rejected')
                setLoading('error')
                errorMessages(err, null, actions);
            })
        }
    }



    const handleSubmit = (event) => {
        event.preventDefault();
        handleRetrieveData(false, true);
        handleRetrieveData(true, true);
    }

    const downloadPdf = () => {
        const {end_date, start_date} = date
        const time = search_type === '0' ? start_date :  search_type === '1'? convertMonth(start_date) :
            search_type === '2' ? year : `${start_date} to ${end_date}`;
        const category = component === 'cashier' ? 'Cashier' : 'Payment Type'
        exportPDF(`Revenue Collections per ${category}(${time})`, [[category, "Out Patients", "In Patients", "Amount"]],
            collections.map((item) => [item.name, item.out,item.in,  item.value]),
            `Revenue Collections per ${category}(${time}).pdf`);

    }
    const downloadCsv = () => {
        csvRef.current.link.click()
    }

    const isLoading = loading === 'pending';
    const isSuccess = loading === 'success';
    const isError = loading === 'error';



    return {
        generic_id, handleChangeRevenueType, closeSnackbar, search_type, handleSubmit, chips,
        handleDeleteChip, text, textType, year, date, handleChangeSearch,
        handleChangeDate, handleChangeYear, handleIncrementYear, statistics, isSubmitted, submitted,
        searchValue, order, orderBy, loading, collections, cashiers, page, rowsPerPage,
        handleSearch, handleRequestSort, handleChangePage, handleChangeRowsPerPage, handleRetrieveData,
         setChips, setSearchType, inputType, csvRef, downloadCsv, downloadPdf,
        isLoading, isSuccess, isError, totals
    }
}