import {useEffect, useRef, useState} from "react";
import axios from "axios";
import {config} from "../../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import {titleCase} from "../../../Users/addUser";
import {exportPDF} from "../../../Utils/downloadPDF";
import {dateStringConvert} from "../../../Utils/ConvertDate";
import { useAddRequisition } from "./useAddRequisition";

export const useRequisitionList = (actions, match) =>{
    const [searchValue, setSearchValue] = useState('');
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('sl');
    const [requisition_id, setId] = useState('');
    const [loading, setLoading] = useState(false);
    const [requisitionList, setList] = useState([]);
    const [openDelete, setDelete] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const [total_count, setCount] = useState(0);
    const [refresh, setRefresh] = useState(false);
    const [allRecords, setAllRecords] = useState([]);
    const csvRef = useRef();

    const {status} = match.params;
    //const url = status ? `${config.smsUrl}/cpharmacy/pharm_requisition_list/${activePage}`: `${config.smsUrl}/cinventory/requisition_list/${activePage}`

    const url =  `${config.smsUrl}/cinventory/requisition_list/${activePage}`;

    useEffect(() => {
        const formData = new FormData();
        formData.append('search', searchValue ? titleCase(searchValue) : '')
        setLoading(true);
        axios.post(url, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const items = !dt.requisition ? [] : dt.requisition;
            const count = !dt.total_count ? 0 : dt.total_count;
            setList(items);
            setCount(count)
            setLoading(false);
        }).catch((err) => {
            logoutErrorMessage(err, null, actions);
            setLoading(false);
        })
    }, [searchValue,activePage, refresh]);

    const retrieveAllRecords = () =>{
        const url = status ? `${config.smsUrl}/cpharmacy/pharm_request_list`:
        `${config.smsUrl}/cinventory/requisitions`
       return  axios.get(url)
    }

    const handleRefresh = () =>{
        setRefresh(!refresh)
    }
    
    const handleOpenDelete = (id) => {
        setDelete(true);
        setId(id)
    };

    const handleCloseDelete = () => {
        setDelete(false)
    };

    const handleDelete = () => {
        const formData = new FormData();
        formData.append('requisition_id', requisition_id);
        axios.post(`${config.smsUrl}/cinventory/delete_requisition`, formData).then(() => {
            const allCategories = requisitionList.filter(category => category.requisition_id !== requisition_id);
            setList(allCategories);
            actions.snackbarActions.snackSuccess('Requisition deleted successfully');
            setDelete(false)
        }).catch(err => {
            errorMessages(err, null, actions)
        })
    };

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const handleSearch = (event) => {
        setSearchValue(event.target.value)
    };


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangeActivePage = (pg) =>{
        setActivePage(pg)
    }

    const downloadPdf = () =>{
        const formData = new FormData();
        formData.append('search', searchValue)
        retrieveAllRecords().then(res => {
            const data = res.data;
            const items = !data ? [] : data;
            exportPDF("Requisition List", [["SL", "Date", "Department", "Requested By"]],
                items.map((item, index) => [index += 1, dateStringConvert(item.date_created),
                    item.department_name, `${!item.first_name ? "":item.first_name} ${!item.last_name ? "":item.last_name}`,
                ]),
                "Requisition List.pdf");
            setAllRecords(items);
        }).catch((err) => {
            logoutErrorMessage(err, null, actions);
        })

    }
    const downloadCsv = () =>{
        retrieveAllRecords().then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const items = !dt.requisition ? [] : dt.requisition;
            setAllRecords(items);
            csvRef.current.link.click()
        }).catch((err) => {
            logoutErrorMessage(err, null, actions);
        })
    }

    const {requisitionProps, openEmmergency:openRequsition , setOpenEmmergency, isResolved,
        openDialog, handleCancel, handleClick, handleCloseDialog} = useAddRequisition(actions, 'pharmacy')

    const handleOpenRequisitionDialog = () =>{
        setOpenEmmergency(true)
    }

    const handleCloseRequisitionDialog = () =>{
        setOpenEmmergency(false)
    }

    return {searchValue, order, orderBy, loading, requisitionList, openDelete, activePage,
        total_count, handleOpenDelete, handleCloseDelete, handleDelete, closeSnackbar, handleSearch,
        handleRequestSort, handleChangeActivePage,status, handleRefresh, csvRef, downloadCsv,downloadPdf, allRecords,
        handleOpenRequisitionDialog, handleCloseRequisitionDialog, openRequsition, requisitionProps,
        handleCloseDialog, openDialog, handleCancel, handleClick
    }
}