import {useState, useEffect} from "react";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {history} from "../../../Helpers/history";

export const useConfirmItems = (actions, match) =>{
    const [products, setProducts] = useState([{product_id:1, product_name: '',balance:'',
        quantity_requested: '', quantity_supplied: '', remarks: '', check: false}])
    const [submitted, setSubmitted] = useState(false);
    const [item,setItem] = useState({});
    const {dispatch_id} = match.params;


    useEffect(() => {
        const formData = new FormData();
        formData.append('dispatch_id', dispatch_id);
        axios.post(`${config.smsUrl}/cinventory/dispatch_update_form`, formData).then(res => {
            const data = res.data;
            const all_data = !data ? {} : data;
            setItem(all_data);
        }).catch(e => {
            logoutErrorMessage(e, null, actions)
        })
    }, [dispatch_id]);

    useEffect(()=>{
        const {product} = item;
        const prdts = !product ? [] : product;
        const prods = prdts.map(item=>({
            product_name:item.product_name,
            quantity_requested:item.quantity_requested,
            quantity_supplied:item.quantity,
            product_id:item.product_id,
            balance:item.quantity_requested - item.quantity,
            remarks: '', check: false
        }))
        setProducts(prods)
    },[item])

    const handleChangeCheck = (event, index) => {
        const prods = [...products]
        const {checked} = event.target;
        if (prods[index]){
            prods[index].check = checked
        }
        setProducts(prods)
    }

    const handleSubmit = (event) =>{
        event.preventDefault();
        setSubmitted(true);
        const formData = new FormData();
        formData.append('dispatch_id', dispatch_id);
        axios.post(`${config.smsUrl}/cpharmacy/confirm_receipt`, formData).then(()=>{
            setSubmitted(false);
            actions.snackbarActions.snackSuccess('Items received successfully')
            history.push('/confirmitems')
        }).catch(err=>{
            setSubmitted(false)
            errorMessages(err, null, actions)
        })
    }

    const closeSnackbar = () =>{
        actions.snackbarActions.hideSnackbar()
    }

    return {products, submitted, handleChangeCheck, handleSubmit, closeSnackbar, item}
}