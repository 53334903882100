import React, {useState} from "react";
import {dateConvert} from "../../Utils/ConvertDate";
import axios from "axios";
import {config} from "../../../Helpers/env";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import CustomDialog from "../../Utils/Dialogs/CustomDialog";
import Form from "react-bootstrap/Form";
import Label from "../../Utils/FormInputs/Label";
import TextField from "../../Utils/FormInputs/TextField";
import SelectInput from "../../Utils/FormInputs/SelectInput";
import {ResizableTextarea} from "../../Utils/FormInputs/Textarea";

export function StockAdjustmentUpload({openStockDialog, setOpenStockDialog,setOpenDialog, actions, location,isSubmitted,setIsSubmitted}) {
    const [purchase_date, setPurchaseDate] = useState(dateConvert());
    const [stockCsv, setStockCsv] = useState(null);
    const [submitCsv, setSubmitCSV] = useState(false);
    const [state, setState] = useState({reason:'',description:'', positive_reason:''})


    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = _token.user_roles_id ? _token.user_roles_id : '';
    const adjusted_by = `${_token.first_name ? _token.first_name : ''} ${_token.last_name ? _token.last_name : ''}`

    const reasons = [{text:'Wastage', value: 'Wastage'},{text: 'Damage', value: 'Damage'} ,{text: 'Expiry', value: 'Expiry'},
        {text: 'Positive adjustment for', value: 'Positive adjustment for'},
        {text: 'Negative adjustment for', value: 'Negative adjustment for'}, {value:'Monthly stock take', text:'Monthly stock take'}]



    const handleInput = event => {
        const {value} = event.target;
        setPurchaseDate(value);
    };

    const handleChange = (event) =>{
        const {name, value} = event.target;
        setState({...state, [name]:value})
    }

    const handleCloseStockDialog = () => {
        setOpenStockDialog(false)
    };


    const handleChangePurchaseCsv = event => {
        // const isAccepted = event.target.files[0].name.substr(event.target.files[0].name.length - 4)
        // if (isAccepted === ".csv") {
        setStockCsv(event.target.files[0])
        // } else {
        //     setStockCsv(null)
        //     actions.snackbarActions.snackError('Please upload only csv  files')
        // }
    };


    const handleUploadCsv = (event) => {
        event.preventDefault();
        const {reason, description, positive_reason} = state;
        const formData = new FormData();
        formData.append('upload_csv_file', stockCsv);
        formData.append('user_roles_id', user_roles_id);
        formData.append('reason', `${reason} ${positive_reason}`);
        formData.append('description', description);
        formData.append('date', purchase_date);
        formData.append('location', location);
        setSubmitCSV(true)
        const isPositive = reason.includes('Positive') || reason.includes('Negative')
        const isPositiveRequired = (isPositive && positive_reason) || !(isPositive && positive_reason)
        if (stockCsv && reason && isPositiveRequired) {
            setIsSubmitted('pending')
            axios.post(`${config.smsUrl}/cinventory/upload_adjustment_CSV`, formData)
                .then(() => {
                    actions.snackbarActions.snackSuccess('Stock csv uploaded Successfully');
                    setOpenDialog(false)
                    setIsSubmitted('resolved')
                    setOpenStockDialog(false)
                }).catch(error => {
                errorMessages(error, null, actions);
                setIsSubmitted('rejected')
            })
        }

    };


    const fetchData = () => {
        axios.get(`${config.smsUrl}/cinventory/upload_adjustment_excel_data`,{responseType: 'blob', params:{location}}).then(response => {
            const data  = response.data;
            const url = URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href =  url;
            link.setAttribute('download', `Stock.csv`);
            document.body.appendChild(link);
            link.click();
        }).catch(err => {
            errorMessages(err, null, actions)
        })
    };





    const isPending = isSubmitted === 'pending';
    const {reason, positive_reason} = state;

    const isPositive = reason.includes('Positive') || reason.includes('Negative')

    const positiveReasons = [{value:'Monthly stock take', text:'Monthly stock take'}]

    const element = (
        <>

            <Form.Group >
                <Label  name='Date' htmlFor='date'/>

                <TextField name="purchase_date" value={purchase_date} type="date" id='date'
                           onChange={handleInput}
                />

            </Form.Group>
            <Form.Group >
                <Label  name=' Adjusted by' htmlFor='adjustedBy'/>

                <TextField value={adjusted_by} type="text" readOnly id='adjustedBy'/>

            </Form.Group>
            <Form.Group >
                <Label  name='Reason' htmlFor='upload_reason' type/>
                <SelectInput defaultOption="Select reason..." value={reason} options={reasons} submitted={submitCsv}
                             type="text" name="reason" id='upload_reason' onChange={handleChange} errorMsg="Reason is required"/>

            </Form.Group>

            {isPositive ? <Form.Group>
                <Label  name={reason} htmlFor='positive_reason'/>

                <ResizableTextarea value={positive_reason} placeholder={`Write ${reason?.toLowerCase()}...`} name="positive_reason" id='positive_reason'
                                   onChange={handleChange} rows={2} />

            </Form.Group>: null}
        </>
    )
    return (
        <CustomDialog open={openStockDialog} handleClose={handleCloseStockDialog} title='Upload csv'>
            <div className="text-right p-1 my-2">
                <button onClick={fetchData}  className="btn btn-sm sms-btn small-btn">Download Sample File</button>
            </div>
            <form onSubmit={handleUploadCsv}>
                {element}
                <div className="card p-2 mb-2">
                    <Form.Control type="file" data-testid="stock_adjustment_file" onChange={handleChangePurchaseCsv}/>
                    {(submitCsv && !stockCsv) && <div className="help-block">Please select csv file</div>}
                </div>
                <div className="text-right">
                    <button className="btn sms-btn btn-sm mr-2" type='submit'
                            disabled={isPending}>{isPending ? 'Submitting...' : 'Submit'}</button>
                    <button type="button" className="btn sms-grey-btn btn-sm" onClick={handleCloseStockDialog}>Close
                    </button>
                </div>
            </form>
        </CustomDialog>
    )
}