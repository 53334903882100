import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {titleCase} from "../../Users/addUser";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../../actions/snackbarActions"
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {formatDateTime} from "../../Utils/ConvertDate";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { DateTimeFilter } from "../../Utils/ReusableComponents/DateTimeFilter";
import { faCoins } from "@fortawesome/free-solid-svg-icons/faCoins";
import { useCustomerTransactions } from "./hooks/useCustomerTransactions";
import Label from "../../Utils/FormInputs/Label";
import Form from 'react-bootstrap/Form'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { RightAlignedContainer } from "../../Utils/styledComponents";
import {MuiPaper, StickyHeadTable} from '../../Utils/Tables/StickyHeaderTable';
import { LoadingGif } from '../../Utils/Loader';
import { BackButton } from "../../Utils/Buttons/DataExportationButton";
import TextField from "../../Utils/FormInputs/TextField";




const headData = [{id: 'date', numeric: false, disablePadding: false, label: 'Date'},
    {id: 'provider_name', numeric: false, disablePadding: false, label: 'Provider Name'},
    {id: 'provider_name', numeric: false, disablePadding: false, label: 'Invoice Number'},
    {id: 'patient_name', numeric: false, disablePadding: false, label: 'Patient Name'},
    {id: 'item', numeric: false, disablePadding: false, label: 'Item'},
    {id: 'quantity', numeric: false, disablePadding: false, label: 'Quantity'},
    {id: 'rate', numeric: false, disablePadding: false, label: 'Rate'},
    {id: 'total_amount', numeric: false, disablePadding: false, label: 'Total Amount'},
];
const CustomerTransactions = ({actions, snackbars, match:{params}}) => {
    const {provider_id} = params;
    const {openBar, type, message} = snackbars;
    const {
         data, closeSnackbar,
        isLoading, isSuccess, isError, state,submitted, providers_dictionary,
        handleChange, handleSubmit, isPending,
    } = useCustomerTransactions(actions,provider_id)
    const {transactions,  total_amount} = data
    const {start_date, end_date, provider} = state
    const emptyRows = 5 - Math.min(5, transactions.length);
    return (
        <div className='journals'>
            <PageTitle title='Customer Transactions'/>
            <SubHeader title="Billing" subTitle='Customer Transactions'>
                <FontAwesomeIcon icon={faCoins}/>
            </SubHeader>
            <MainSnackbar variant={type} handleCloseBar={closeSnackbar} open={openBar} message={message}/>
            <div className='mui-tables mt-2'>
                <BackButton to='/providers' text='Customer List' data-testid="customer-transactions-back-button"/>
                <div className="mt-4">
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={4} >
                            <Row>
                                <Col xs={12} sm={2} md={2} lg={3} xl={2}>
                                    <Label name="Customer" />
                                </Col>
                                <Col xs={12} sm={6} md={6} lg={6} xl={8}>
                                    <TextField type='text' value={providers_dictionary[provider]} disabled/>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={8} >
                            <DateTimeFilter {...{start_date,end_date, handleChange, submitted,handleSubmit,isPending,type:'date'}}/>
                        </Col>
                    </Row>
                </div>
                <RightAlignedContainer>
                    <h5 className="mr-5">Total Amount : <strong>{total_amount}</strong></h5>
                </RightAlignedContainer>
                <MuiPaper>
                <StickyHeadTable {...{data:transactions, columns:headData.filter(item=>Boolean(item))}}>
                    {isLoading ? <TableRow><TableCell align='center'  colSpan={headData.length}><LoadingGif/></TableCell></TableRow>:null}
                    {isSuccess ? transactions.length > 0 ? 
                        <>
                            {transactions.map((item, index) => {
                                const rate = item.rate ? item.rate : 0;
                                const quantity = item.quantity ? item.quantity : 0;
                                const total_amount =  quantity * rate;
                                const year = new Date(item.date_created).getFullYear().toString().slice(-2);
                                const invoice_number = item.visit_id ? `${item.visit_id}/${year}` : '';
                            return (
                            <TableRow  key={index}>
                                <TableCell>{item.date_created ? formatDateTime(item.date_created) : ""}</TableCell>
                                <TableCell>{item.provider_name}</TableCell>
                                <TableCell>{invoice_number}</TableCell>
                                <TableCell>{titleCase(`${item.first_name ? item.first_name :''} ${item.last_name ? item.last_name : ''}`)}</TableCell>
                                <TableCell>{item.service_id ? item.service_name : item.product_id ? item.product_name : ''}</TableCell>
                                <TableCell>{item.quantity}</TableCell>
                                <TableCell>{`${item.currency_symbol} ${rate}`}</TableCell>
                                <TableCell>{`${item.currency_symbol} ${total_amount}`}</TableCell>
                            </TableRow>
                            );
                    })}
                    {emptyRows > 0 && (
                        <TableRow style={{ height: 43 * emptyRows }}>
                        <TableCell colSpan={headData.length} />
                        </TableRow>
                    )}
                    </>:<TableRow><TableCell align='center'  colSpan={headData.length}>No records found</TableCell></TableRow>:null}
                    {isError ? <TableRow><TableCell align='center'   colSpan={headData.length}>The server did not return a valid response</TableCell></TableRow>:null}
                </StickyHeadTable>
                </MuiPaper>
            
            </div>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerTransactions);
