import {useEffect, useState} from "react";
import {errorMessages} from "../../../../Helpers/ErrorMessages";
import axios from "axios";
import { config } from "../../../../Helpers/env";

const illnesses = [{disease:'Cardiac Disease',name:'cardiac_disease', cardiac_disease:false},
    {disease:'Kidney Disease', name:'kidney_disease', kidney_disease:false},
    {disease:'Hypertension',name:'hypertension', hypertension:false},
    {disease:'TB', name:'tb', tb:false},
    {disease:'Sickle Cell Disease',name:'sickle_cell_disease', sickle_cell_disease:false},
    {disease:'Epilepsy (seizures)', name:'epilepsy', epilepsy:false},
    {disease:'Diabetes', name:'diabetes', diabetes:false}]

export const useAddFindings = (actions, visit_id, patient_type,patient_number) =>{
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;

    const storage = JSON.parse(sessionStorage.getItem(`${visit_id}_${user_roles_id}`))
    const item = storage ? storage : {}
    const note_item = item[visit_id] ? item[visit_id] : {}

    //diseases obj
    const diseaseStorage = JSON.parse(sessionStorage.getItem('storedDiseases'))
    const diseaseItem = diseaseStorage ? diseaseStorage : {}
    const diseaseObj = diseaseItem[visit_id] ? diseaseItem[visit_id]:[]
    const diseaseState = () => (diseaseObj.length > 0 && diseaseObj) || illnesses

    const notes = note_item['notes'] ? note_item['notes'] : ''
    const complaints = note_item['complaints'] ? note_item['complaints'] : ''
    const exam = note_item['physical_examination'] ? () => note_item['physical_examination'] || '': ''
    const medical_history = note_item['past_medical_history'] ? () => note_item['past_medical_history'] || '' : ''
    const obs_history = note_item['past_obstetric_history'] ? ()=> note_item['past_obstetric_history'] || '' : ''
    const fam_history = note_item['family_history'] ? () => note_item['family_history'] || '' : ''
    const disease = note_item['other_diseases'] ? ()=> note_item['other_diseases'] ||'' : ''
    const allergy_actions = note_item['allergy'] ? ()=> note_item['allergy'] || '' : ''
    const other_allergies = note_item['other_diseases'] ? ()=> note_item['other_diseases'] || '' : ''
    const isAdd = note_item['isAdd'] ? note_item['isAdd'] : false

    const isAddNotes = () => isAdd || false

    const [examNotes, setExamNotes] = useState({physical_examination:exam,
        past_medical_history:medical_history,
        past_obstetric_history:obs_history, family_history:fam_history,
        notes:notes,complaints:complaints,other_diseases: disease,allergy:allergy_actions, allergies:other_allergies});
    const [diseases, setDiseases] = useState(diseaseState())
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [submitted, setSubmitted] = useState(false)
    const [openAddNotes, setOpenAddNotes] = useState(isAddNotes)

    const isResolved = isSubmitted === 'resolved'



    useEffect(()=>{
        const formData = new FormData();
        formData.append('patient_number', patient_number)
        axios.post(`${config.smsUrl}/cpatient/patient_update_form`, formData).then(response => {
            const patient = response.data;
            const pt = !patient ? {} : patient;
            const chronic = pt.chronic_illness ? pt.chronic_illness : {};
            const arr = diseases.map(item=>({...item, [item.name]:chronic[item.name]}))
            setDiseases(arr)
            setExamNotes({...examNotes, other_diseases:chronic.other_chronic_illness, allergy:pt.allergies ? 'Yes' : '',
                allergies:pt.allergies})
        }).catch(err => {
            // logoutErrorMessage(err, null, actions)
        } )
    },[])



    const handleChangeState = (event) => {
        const {name, value} = event.target
        setExamNotes({...examNotes,[name]:value})
        const obj = {[visit_id]:{...note_item,[name]:value, isAdd:true}}
        sessionStorage.setItem(`${visit_id}_${user_roles_id}`, JSON.stringify({...item, ...obj}))
    };

    const handleSubmit = (event) =>{
        // const {physical_examination, notes,complaints} = examNotes
        event.preventDefault();
        setSubmitted(true)
        const object = diseases.reduce(
            (obj, item) => Object.assign(obj, { [item.name]: item[item.name] }), {});
        const jsonData = {visit_id:+visit_id,user_roles_id,...examNotes, chronic_illness:object,history_of_presenting_complaints:''}

        // if(physical_examination && notes && complaints){
            setIsSubmitted('pending');
            const url = patient_type === '1' ? 'cdoctor/save_exam_notes' :'cmedicationround/save_round_findings'
            axios.post(`${config.smsUrl}/${url}`,jsonData).then(()=>{
                setIsSubmitted('resolved');
                const message = patient_type === '1' ? 'Clinical notes' : 'Findings'
                actions.snackbarActions.snackSuccess(`${message} saved successfully`);
                setSubmitted(false)
                setOpenAddNotes(false)
                sessionStorage.removeItem(`${visit_id}_${user_roles_id}`)
                sessionStorage.removeItem(`storedDiseases`)
                setExamNotes({physical_examination:'', past_medical_history:'',
                    past_obstetric_history:'', family_history:'', notes:'',complaints:'',other_diseases: '',allergy:'', allergies:''})
                const arr = diseases.map(item=>({
                    ...item, [item.name]:''
                }))
                setDiseases(arr)
            }).catch(err=>{
                setIsSubmitted('rejected');
                errorMessages(err,null, actions)
            })
        // }

    };


    const handleChangeDisease = (event, index) =>{
        const {checked} = event.target
        const arr = diseases.map((item, idx)=>{
            if(index === idx){
                return {...item, [item.name]:checked}
            }
            return item
        })
        setDiseases(arr)
        const diseaseObj = {[visit_id]:arr}
        sessionStorage.setItem('storedDiseases', JSON.stringify({...diseaseItem, ...diseaseObj}))
    }

    const handleOpenNotesInput = () =>{
        setOpenAddNotes(true)
    }

    const handleCloseNotesInput = () =>{
        setOpenAddNotes(false)
    }

    const isPending = isSubmitted === 'isPending'

    const addNotesProps = {examNotes, isPending, handleChangeState, handleSubmit,
        openAddNotes, handleOpenNotesInput, handleCloseNotesInput, submitted,
        diseases, handleChangeDisease}


    return {addNotesProps, isResolved }
}