import { useEffect, useReducer, useState } from "react";
import { dateConvert } from "../../../Utils/ConvertDate";
import axios from "axios";
import { config } from "../../../../Helpers/env";
import { errorMessages, logoutErrorMessage } from "../../../../Helpers/ErrorMessages";
import { history } from "../../../../Helpers/history";
import { useInvoiceProviders } from "../../customers/hooks/useInvoiceProviders";
import { billingServicesReducer } from "../../utils/billingServicesReducer";
import { billingProductsAndServicesReducer, billingProductsReducer } from "../../utils/billingProductsReducer";
import { filterInpatientBeds, filterInpatientProducts, filterInpatientServices } from "../../utils/SanitizeArr";
import * as billingActions from "../../utils/billingActions";
import { useQuery } from "react-query";

export const useInPatientInvoice = (props) => {
    const { actions } = props;
    const { patient_admission_id, visit_id } = props.match.params;
    // const [{procedureData,invoicesEdited,invoicesRemoved,invoicesPaid}, dispatch] = useReducer(billingServicesReducer,{
    //     procedureData:[],invoicesEdited:[],invoicesRemoved:[],invoicesPaid:[]
    // })
    // const [{products,productsEdited,productsRemoved,productsPaid}, dispatchProducts] = useReducer(billingProductsReducer,{
    //     products:[],productsEdited:[],invoicesRemoved:[],productsPaid:[],productsRemoved:[]
    // })

    const [
        { products, productsEdited, productsRemoved, productsPaid, procedureData, invoicesEdited, invoicesRemoved, invoicesPaid },
        dispatchProductsAndServices,
    ] = useReducer(billingProductsAndServicesReducer, {
        products: [],
        productsEdited: [],
        productsRemoved: [],
        productsPaid: [],
        procedureData: [],
        invoicesEdited: [],
        invoicesRemoved: [],
        invoicesPaid: [],
    });

    const [state, setState] = useState({
        patient_number: "", first_name: '', last_name: '', phone_no: '',
        address: '', dob: '', gender: '', admission_date: '', discharge_date: '', total_days: ''
    });
    const [bedBill, setBedBill] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [openPrint, setOpenPrint] = useState(false);
    const [totals, setTotals] = useState({
        total_amount: 0, bed_amount: 0, total_product: 0, due_amount: 0,
        balance: 0, net_total: 0, tax_amount: 0, discount_amount: 0
    })
    const [paidAmount, setPaidAmount] = useState("");
    const [billDetails, setBillDetails] = useState({});
    const [currencies, setCurrencies] = useState([])
    const [currency, setCurrency] = useState('')
    const [exchangeRate, setExchangeRate] = useState(0)
    const [currencyTotal, setCurrencyTotal] = useState(0)
    const [billState, setBillState] = useState({
        invoice_date: dateConvert(), payment_type: '', memo: '',
        insurance_provider: '', account_name: '', card_number: '', policy_holder: ''
    })
    const [isBlocking, setIsBlocking] = useState(false)
    const [is_converted, setIsCoverted] = useState(false);


    const { providerProps, openDialog: openProvider, handleOpenDialog,
        handleCloseDialog, providerOptions: providerList } = useInvoiceProviders(actions, true)

    const providers = providerList.map(item => ({ value: `${item.provider_id}-${item.provider_type}-${item.provider_name}`, text: item.provider_name }))


    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;


    const calculateTotal = (servicesArr, prdts, rate = 0, bed = []) => {

        //usd total amount
        const totalBedBill = bed.reduce((a, item) => {
            return +(a + item.amount)
        }, 0);

        const totalProduct = prdts.reduce((a, item) => {
            return +(a + item.subTotal)
        }, 0);
        const totalService = servicesArr.reduce((a, item) => {
            return +(a + item.amount)
        }, 0);

        // non usd total amount
        const currency_service_total = servicesArr.reduce((a, item) => {
            const base_total = rate ? (+item.rate / rate) : 0
            return a + +base_total
        }, 0)

        const product_service_total = prdts.reduce((a, item) => {
            const base_total = rate ? (+item.rate / rate) * +item.quantity : 0
            return a + +base_total
        }, 0)

        const currency_bed_total = bed.reduce((a, item) => {
            const base_total = rate ? (+item.rate / rate) * +item.quantity : 0
            return a + +base_total
        }, 0)

        const non_currency_total_amt = totalProduct + totalService + totalBedBill
        const currency_total_amount = currency_bed_total + currency_service_total + product_service_total
        const amt = paidAmount - (non_currency_total_amt);
        const bal = amt < 0 ? 0 : amt;
        setTotals({ ...totals, total_amount: non_currency_total_amt, balance: bal })
        setCurrencyTotal(currency_total_amount)
    }

    const inPatientPendingClearanceQuery = useQuery(
        ["inPatientPendingClearanceQuery"], async () => {
            try {
                const formData = new FormData();

                formData.append('visit_id', visit_id);

                formData.append('patient_admission_id', patient_admission_id);

                formData.append('pay_status', 0);

                const response = await axios.post(`${config.smsUrl}/cbilling/get_inpatient_bill`, formData)

                const data = response.data ?? {};

                setBillDetails(data);

                const { patient_info, admission_bill } = data;

                const p_info = patient_info ?? [];

                const pt_info = p_info[0] ?? {};

                const service_bill = admission_bill ?? [];

                const {
                    admission_date, discharge_date, p_address, p_dob, p_first_name,
                    p_gender, p_last_name, patient_number, phone_no
                } = pt_info

                setState({
                    patient_number, first_name: p_first_name, last_name: p_last_name, address: p_address, dob: p_dob,
                    gender: p_gender, admission_date, discharge_date, phone_no
                })

                const srvc = filterInpatientServices(exchangeRate, service_bill.filter(service => service.service_id))

                const prdts = filterInpatientProducts(exchangeRate, service_bill.filter(prod => prod.product_id))

                const bd = filterInpatientBeds(exchangeRate, service_bill.filter(bd => bd.bed_assignment_id))

                dispatchProductsAndServices({
                    type: billingActions.RETRIEVE_PRODUCTS_AND_SERVICES,
                    services: srvc,
                    products: prdts,
                    bed: bd,
                    exchangeRate,
                    calculateTotal,
                })

                setBedBill(bd)

            } catch (error) {
                logoutErrorMessage(error, null, actions)
            }
        }, { retry: 1, refetchInterval: 5000, }
    );

    // useEffect(() => {
    //     function retrieveBillDetails() {
    //         const formData = new FormData();
    //         formData.append('visit_id', visit_id);
    //         formData.append('patient_admission_id', patient_admission_id);
    //         formData.append('pay_status', 0);
    //
    //         axios.post(`${config.smsUrl}/cbilling/get_inpatient_bill`, formData)
    //             .then(res => {
    //                 const details = res.data;
    //                 const data = !details ? {} : details;
    //                 setBillDetails(data)
    //             }).catch(error => {
    //             logoutErrorMessage(error, null, actions)
    //         });
    //     }
    //     retrieveBillDetails()
    //     const interval = setInterval(()=>{
    //         retrieveBillDetails()
    //     }, 15000)
    //
    //     return ()=>clearInterval(interval)
    //
    // }, [])
    //
    //
    //
    // useEffect(() => {
    //     const {patient_info, admission_bill} = billDetails;
    //     const p_info = !patient_info ? [] : patient_info;
    //     const pt_info = !p_info[0] ? {} : p_info[0];
    //     const service_bill = !admission_bill ? [] : admission_bill;
    //
    //     const {
    //         admission_date, discharge_date, p_address, p_dob, p_first_name,
    //         p_gender, p_last_name, patient_number, phone_no
    //     } = pt_info
    //
    //     setState({
    //         patient_number, first_name: p_first_name, last_name: p_last_name, address: p_address, dob: p_dob,
    //         gender: p_gender, admission_date, discharge_date, phone_no
    //     })
    //
    //     const srvc = filterInpatientServices(exchangeRate,service_bill.filter(service => service.service_id))
    //
    //     const prdts = filterInpatientProducts(exchangeRate, service_bill.filter(prod => prod.product_id))
    //
    //     const bd = filterInpatientBeds(exchangeRate, service_bill.filter(bd => bd.bed_assignment_id))
    //
    //
    //     dispatch({type:billingActions.RETRIEVE_SERVICES, services:srvc})
    //     dispatchProducts({type: billingActions.RETRIEVE_PRODUCTS,products:prdts})
    //
    //     setBedBill(bd)
    //     calculateTotal(srvc, prdts,  exchangeRate,bd)
    //
    //
    // }, [billDetails])



    // get currencies
    useEffect(() => {
        axios.get(`${config.smsUrl}/accounting/currencies`).then(res => {
            const data = res.data;
            const all_data = !data ? {} : data;
            const arr = all_data.data ?? [];
            const list = arr.map(item => ({ value: `${item.id}-${item.is_base_currency}-${item.currency_symbol}`, text: `${item.currency_symbol} ${item.currency_name}` }))
            setCurrencies(list)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, []);




    const calculateBedAmount = (rate) => {
        const bedArr = bedBill.map(item => ({
            ...item,
            currency_rate: +rate ? +item.rate / +rate : 0, currency_total: (+rate ? +item.rate / +rate : 0) * +item.quantity,
            amount: +item.rate * +item.quantity
        }))
        setBedBill(bedArr)
    }


    //Change currency
    const handleChangeCurrency = (event) => {
        setCurrency(event.target.value)
        setPaidAmount('')
        setTotals({ ...totals, balance: 0 })
        setExchangeRate('')
        setIsBlocking(true)
    }

    //Change exchange rate
    const handleChangeExchangeRate = (event) => {
        setExchangeRate(event.target.value)
        setIsCoverted(true);
        // calculateBedAmount(event.target.value)
        // dispatchProducts({type: billingActions.CALCULATE_PRODUCT_AMOUNT, rate:event.target.value})
        // dispatch({type: billingActions.CALCULATE_SERVICE_AMOUNT, rate:event.target.value})
        setExchangeRate(event.target.value < 0 ? 0 : event.target.value)

        calculateBedAmount(event.target.value < 0 ? 0 : event.target.value)

        // dispatchProducts({type: billingActions.CALCULATE_PRODUCT_AMOUNT, rate:event.target.value})
        // dispatch({type: billingActions.CALCULATE_SERVICE_AMOUNT, rate:event.target.value})
        // calculateTotal(procedureData, products, event.target.value, bedBill)

        dispatchProductsAndServices({
            type: billingActions.CALCULATE_PRODUCT_AMOUNT,
            rate: event.target.value
        })

        dispatchProductsAndServices({
            type: billingActions.CALCULATE_SERVICE_AMOUNT,
            rate: event.target.value
        })

        calculateTotal(procedureData, products, event.target.value < 0 ? 0 : event.target.value, bedBill)

        setIsBlocking(true)
    }


    const handleChangeServiceCurrencyRate = (event, bill_id) => {
        const prods = products.filter(item => !item.isExcluded)

        // dispatch({
        //     type:billingActions.CHANGE_SERVICE_CURRENCY_RATE,
        //     value:event.target.value,
        //     bill_id,
        //     exchangeRate,
        //     calculateTotal,
        //     products:prods,
        //     bedBill
        // })

        dispatchProductsAndServices({
            type: billingActions.CHANGE_SERVICE_CURRENCY_RATE,
            value: event.target.value,
            bill_id,
            exchangeRate,
            calculateTotal,
            products: prods,
            bedBill
        })

        setIsBlocking(true)
    }

    const handleChangeServiceRate = (event, bill_id) => {
        const prods = products.filter(item => !item.isExcluded)

        // dispatch({
        //     type:billingActions.CHANGE_SERVICE_RATE,
        //     value:event.target.value,
        //     bill_id,
        //     exchangeRate,
        //     calculateTotal,
        //     products:prods,
        //     bedBill
        // })

        dispatchProductsAndServices({
            type: billingActions.CHANGE_SERVICE_RATE,
            value: event.target.value,
            bill_id,
            exchangeRate,
            calculateTotal,
            products: prods,
            bedBill
        })

        setIsBlocking(true)
    }

    const handleChangeProductRate = (event, bill_id) => {
        const procedures = procedureData.filter(item => !item.isExcluded)

        // dispatchProducts({
        //     type:billingActions.CHANGE_PRODUCT_RATE,
        //     value:event.target.value,
        //     bill_id,
        //     exchangeRate,
        //     calculateTotal,
        //     services:procedures,
        //     bedBill
        // })

        dispatchProductsAndServices({
            type: billingActions.CHANGE_PRODUCT_RATE,
            value: event.target.value,
            bill_id,
            exchangeRate,
            calculateTotal,
            services: procedures,
            bedBill
        })

        setIsBlocking(true)
    }

    const handleChangeProductCurrencyRate = (event, bill_id) => {

        const procedures = procedureData.filter(item => !item.isExcluded)
        // dispatchProducts({
        //     type:billingActions.CHANGE_PRODUCT_CURRENCY_RATE,
        //     value:event.target.value,
        //     bill_id,
        //     exchangeRate,
        //     calculateTotal,
        //     services:procedures,
        //     bedBill
        // })

        dispatchProductsAndServices({
            type: billingActions.CHANGE_PRODUCT_CURRENCY_RATE,
            value: event.target.value,
            bill_id,
            exchangeRate,
            calculateTotal,
            services: procedures,
            bedBill
        })

        setIsBlocking(true)
    }


    const handleDeleteServices = (item) => {
        const { bill_id } = item;
        const prods = products.filter(item => !item.isExcluded)

        // dispatch({
        //     type:billingActions.DELETE_SERVICE,
        //     bill_id,
        //     exchangeRate,
        //     calculateTotal,
        //     products:prods,
        //     bedBill
        // })

        dispatchProductsAndServices({
            type: billingActions.DELETE_SERVICE,
            bill_id,
            exchangeRate,
            calculateTotal,
            products: prods,
            bedBill
        })

        setIsBlocking(true)
    };

    const handleChangeProductQuantity = (event, bill_id) => {
        const procedures = procedureData.filter(item => !item.isExcluded)

        // dispatchProducts({
        //     type:billingActions.CHANGE_PRODUCT_CURRENCY_RATE,
        //     value:event.target.value,
        //     bill_id,
        //     exchangeRate,
        //     calculateTotal,
        //     services:procedures,
        //     bedBill
        // })

        dispatchProductsAndServices({
            type: billingActions.CHANGE_PRODUCT_CURRENCY_RATE,
            value: event.target.value,
            bill_id,
            exchangeRate,
            calculateTotal,
            services: procedures,
            bedBill
        })

        setIsBlocking(true)
    }

    const handleDeleteProduct = (item) => {
        const { bill_id } = item
        const procedures = procedureData.filter(item => !item.isExcluded)

        // dispatchProducts({
        //     type:billingActions.DELETE_PRODUCT,
        //     bill_id,
        //     exchangeRate,
        //     calculateTotal,
        //     services:procedures,
        //     bedBill
        // })


        dispatchProductsAndServices({
            type: billingActions.DELETE_PRODUCT,
            bill_id,
            exchangeRate,
            calculateTotal,
            services: procedures,
            bedBill
        })

        setIsBlocking(true)
    };



    const handleSubmitBill = (event) => {
        event.preventDefault();
        setSubmitted(true);
        setIsBlocking(false)
        const { invoice_date, insurance_provider, card_number, account_name, policy_holder } = billState;
        const provider = insurance_provider.split('-')[1];
        const isInsurance = provider === 'Insurance Company'
        const insuranceRequired = isInsurance && policy_holder && card_number;
        const organizationRequired = !isInsurance && account_name
        const arr = products.every(item => item.quantity)
        if (invoice_date && arr && insurance_provider && (insuranceRequired || organizationRequired)) {
            setOpenPrint(true)
        }

    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setBillState({
            ...billState, [name]: value
        })
        setIsBlocking(true)
    };

    const handleCloseReceipt = () => {
        setOpenPrint(false);
        history.push('/patientbillinglist');

    };

    const handleClickReceipt = () => {
        const { notes } = state;
        const { total_amount } = totals
        const { invoice_date, insurance_provider, card_number, account_name
            , policy_holder } = billState;
        const prov = insurance_provider.split('-')[0]
        const provider = insurance_provider.split('-')[1];
        const billed_to = insurance_provider.split('-')[2];
        const isInsurance = provider === 'Insurance Company'
        const insuranceRequired = isInsurance && policy_holder && card_number;
        const organizationRequired = !isInsurance && account_name
        const arr = products.every(item => item.quantity)
        if (invoice_date && arr && insurance_provider && (insuranceRequired || organizationRequired)) {
            setIsSubmitted('pending');
            const bedArr = bedBill.map(bed => ({
                ...bed,
                assign_date: bed.assign_date,
                bill_type: bed.bill_type, invoice_no: bed.invoice_no,
                pay_status: bed.pay_status,
                visit_id: bed.visit_id,
                room_name: bed.room_name,
                bill_id: bed.bill_id,
                ward_name: bed.ward_name,
                bed_number: bed.bed_number,
                rate: bed.rate,
                bed_assignment_id: bed.bed_assignment_id,
                quantity: bed.quantity
            }))
            const editedServices = invoicesEdited.filter(item => !item.isExcluded);
            const editedProducts = productsEdited.filter(item => !item.isExcluded);
            const paidServices = invoicesPaid.filter(item => !item.isExcluded);
            const paidProducts = productsPaid.filter(item => !item.isExcluded);
            const editedInvoices = [...editedServices, ...editedProducts]
            const paidInvoices = [...paidServices, ...bedArr, ...paidProducts]
            const removedInvoices = [...invoicesRemoved, ...productsRemoved]
            const params = {
                invoices_paid: paidInvoices,
                invoices_removed: removedInvoices,
                invoices_edited: editedInvoices
            };

            const isPaid = paidInvoices.length > 0;
            const isEdited = editedInvoices.length > 0;
            const isInvoice = isPaid || isEdited;

            const isBase = currency.split('-')[1]
            const amount = isInvoice ? 0 : null;

            const isNotBase = isBase === 'false'
            const isBaseCurrency = isBase === 'true'

            const currency_id = currency.split('-')[0]


            const isBaseData = isBaseCurrency ?  {is_base_currency:isBase, currency_id:+currency_id,is_converted: is_converted,amount_paid: +amount,
                rate_input_by_cashier: +exchangeRate,amount_paid_in_the_currency: +currencyTotal} : {}
            const isNotBaseData = isNotBase ?  {currency_id,amount_paid_in_the_currency: +currencyTotal, rate_input_by_cashier: +exchangeRate,
                total_amount_in_base_currency: +total_amount,
                is_base_currency: isBase
            } : {}

            axios.post(`${config.smsUrl}/cbilling/pay_in_patientbill`, {
                pay_type: 4,
                visit_id,
                received_by: user_roles_id,
                amount_paid: 0,
                bill_mode: "account",
                insurance_provider: +prov,
                card_number: card_number,
                billed_to,
                account_name: isInsurance ? policy_holder : account_name,
                memo: notes ?? '',
                ...params, ...isBaseData, ...isNotBaseData
            }).then((res) => {
                const data = res.data ?? {};
                let receipt_number;
                if (typeof data.receipt_numbers === "string") {
                    receipt_number = data.receipt_numbers ?? '';
                } else {
                    const numbers = data.receipt_numbers ?? [];
                    receipt_number = numbers.join('-');
                }
                actions.snackbarActions.snackSuccess('Invoice created successfully');
                history.push(`/viewinpatientinvoice/${visit_id}/${receipt_number ? receipt_number : 0}`);
                setIsSubmitted('resolved');
                setOpenPrint(false);
            }).catch(err => {
                setIsSubmitted('rejected');
                errorMessages(err, null, actions)
            })
        }

    };


    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    return {
        billState,
        providers,
        state,
        bedBill,
        services: procedureData,
        products,
        isSubmitted,
        openPrint,
        submitted,
        handleDeleteServices,
        handleSubmitBill,
        handleCloseReceipt,
        handleClickReceipt,
        closeSnackbar,
        handleChangeProductRate,
        handleChangeServiceRate,
        openProvider,
        handleChange,
        providerProps,
        handleCloseDialog,
        handleOpenDialog,
        handleChangeProductQuantity,
        handleDeleteProduct,
        currencies, currency, handleChangeExchangeRate, handleChangeServiceCurrencyRate,
        exchangeRate, currencyTotal, handleChangeProductCurrencyRate,
        handleChangeCurrency, totals,
        isBlocking
    }

}