import React, {useEffect, useState} from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import * as snackbarActions from "../../../actions/snackbarActions"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {BackButton} from "../../Utils/Buttons/DataExportationButton";
import {Container} from "../../Utils/styledComponents"
import axios from "axios";
import { config } from "../../../Helpers/env";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {CustomTableCell, MuiPaper, StickyHeadTable} from "../../Utils/Tables/StickyHeaderTable";
import {LoadingGif} from "../../Utils/Loader";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClipboardList} from "@fortawesome/free-solid-svg-icons/faClipboardList";
import { CustomTablePagination, EnhancedSearchTableToolbar } from "../../Utils/Tables/CustomTable";
import { Grid } from "@material-ui/core";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Label from "../../Utils/FormInputs/Label";
import { SelectItem } from "../../Utils/FormInputs/AutoCompleteInput";
import InputGroup from "react-bootstrap/InputGroup";
import TextField from "../../Utils/FormInputs/TextField";
import Dropdown from "react-bootstrap/Dropdown";
import { formatDate, formatDateTime } from "../../Utils/ConvertDate";


const headData = [
    {id: 'date', numeric: false, disablePadding: false, label: 'Date'},
    // {id: 'invoice_no', numeric: false, disablePadding: false, label: 'Invoice#'},
    {id: 'patient_name', numeric: false, disablePadding: false, label: 'Patient Name'},
    {id: 'provider_name', numeric: false, disablePadding: false, label: 'Provider'},
    {id: 'due_date', numeric: false, disablePadding: false, label: 'Date Paid'},
    {id: 'amount', numeric: false, disablePadding: false, label: 'Amount'},
];

const AccountReceivablesAgeingSummary = ({actions, snackbars, match:{params}}) => {
    const [state, setState] = useState({period:'days', period_value:7})
    const [data, setData] = React.useState({debtors:[], loading:'idle'});
    const [page, setPage] = React.useState(0);
    const [submitted, setSubmitted] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState('idle')
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const {debtors, loading} = data
    const [activePage, setActivePage] = React.useState(1);
    const [term, setTerm] = React.useState('');
    const {period, period_value} = state;


    const periods = [{value: 'days', text: 'Days'}, {value: 'weeks', text: 'Weeks'},{value: 'months', text: 'Months'}]

    const isPending = isSubmitted === 'pending'

    const text = {
        'days':'Day(s)',
        'weeks':'Week(s)',
        'months':'Month(s)'
    }

    useEffect(() => {
        setData({...data, loading: 'pending'})
        axios.get(`${config.smsUrl}/creport/insurance_claim_aging_analysis`, {
            params: {
                page:activePage,
                search:term,
                aging_period:period_value,
                period_type:period
            }}).then(res => {
            const response = res.data;
            const arr = response ? response : [];
            const debt_arr = arr.claims_analysis ? arr.claims_analysis : [];
            console.log(debt_arr)
            setData({debtors: debt_arr, loading: 'success'})
        }).catch(err => {
            errorMessages(err, null, actions);
            setData({...data, loading: 'error'});
        })
    }, [activePage, term])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setActivePage(newPage+1);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        setActivePage(1);
    };

    const handleChange = (event) => {
        const {name, value} = event.target
        setState({...state,[name]:value});
    };

    const handleSubmit = () =>{
        event.preventDefault()
        setSubmitted(true)
        if(period_value){
        setData({...data, loading: 'pending'})
        axios.get(`${config.smsUrl}/creport/insurance_claim_aging_analysis`, {
            params: {
                page:activePage,
                search:term,
                aging_period:period_value,
                period_type:period
            }}).then(res => {
            const response = res.data;
            const arr = response ? response : [];
            const debt_arr = arr.claims_analysis ? arr.claims_analysis : [];
            console.log(debt_arr)
            setData({debtors: debt_arr, loading: 'success'})
        }).catch(err => {
            errorMessages(err, null, actions);
            setData({...data, loading: 'error'});
        })
     }
    }

    const closeSnackbar = () =>{
        actions.snackbarActions.hideSnackbar();
    };


    const isLoading = loading === 'pending'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'


    const {openBar, type, message} = snackbars;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, debtors.length - page * rowsPerPage);

    const handler = (event) => {
        setTerm(event.target.value)
    }

    const pagination = (
        <CustomTablePagination
            colSpan={3}
            activePage={activePage}
            page={page}
            rowsPerPage={rowsPerPage}
            count={debtors}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
    );

    const search_bar = (
        <EnhancedSearchTableToolbar
            term={term} handler={handler}
        />
    )
    // console.log(debtors)

    return (
        <div>
            <PageTitle title="Aging Summary"/>
            <SubHeader title="Reports" subTitle="Aging summary">
                <FontAwesomeIcon icon={faClipboardList}/>
            </SubHeader>
            <Container>
                <div className='mb-3'>
                    <BackButton to='/reports/generalreports' text='General Reports'/>
                </div>
                <MainSnackbar open={openBar} message={message} variant={type} handleCloseBar={closeSnackbar}/>
                <Grid container spacing={0}>
                    <Grid item xs={6} md={8}>
                        <form onKeyDown={(e)=>{if (e.key === 'Enter'){e.preventDefault();}}}>
                            <Form.Group as={Row} className="mb-2">
                                <Col sm={2} lg={2} xl={2} md={2}>
                                  <Label  name='Filter by:' column style={{textAlign:'right'}}/>
                                </Col>
                                <Col sm={4} lg={4} xl={4} md={4}> 
                                    <SelectItem onChange={handleChange}  label_text="label" id='filter_by'
                                value={period}   value_text="value" name='period'
                                options={periods} />
                                </Col>
                                <Col sm={3} lg={3} xl={3} md={3}>
                                  
                                    <InputGroup className="mb-0">
                                        <TextField type="number"
                                                   name="period_value"
                                                   value={period_value}
                                                   onChange={handleChange}

                                        />
                                        <InputGroup.Text className='form__input__text' id="period_value">{text[period]}</InputGroup.Text>
                                    </InputGroup>
                                    {submitted && !period_value ?  <div className="invalid-text">Please input {text[period]} </div>: null}
                                </Col>
                                <Col sm={2} lg={2} xl={2} md={2}>
                                    <button disabled={isPending} type="submit" style={{marginTop:'0.3rem'}} className='btn btn-sm sms-info-btn' onClick={handleSubmit} >{isPending ? 'Filtering....':'Filter'}</button>
                                </Col>
                            </Form.Group>
                        </form>
                    </Grid>
                    <Grid item xs={6} md={2}/>
                </Grid>
                <MuiPaper>
                    {search_bar}
                    <StickyHeadTable {...{width:'100%',data:debtors, columns:headData.filter(item=>Boolean(item)), pagination, search_bar}}>
                        {isLoading ? <TableRow><TableCell align='center' {...{colSpan:headData.length}}><LoadingGif/></TableCell></TableRow>:null}
                        {isSuccess ? debtors.length > 0 ?
                            <>
                                {debtors.map((row, index) => {
                                    const count = ((activePage - 1) * 10) + index + 1;
                                    return (
                                        <TableRow  tabIndex={-1} key={index}>
                                            <CustomTableCell>{formatDate(row.visit_date)}</CustomTableCell>
                                            <CustomTableCell> {`${row.patient_firstname} ${row.patient_lastname}`}</CustomTableCell>
                                            <CustomTableCell> {row.provider_name}</CustomTableCell>
                                            <CustomTableCell>{formatDate(row.bill_paid_date)}</CustomTableCell>
                                            <CustomTableCell>{row.total_amount}</CustomTableCell>
                                        </TableRow>
                                    );
                                })}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 43 * emptyRows }}>
                                        <TableCell colSpan={headData.length} />
                                    </TableRow>
                                )}
                            </>:<TableRow><TableCell align='center'  {...{colSpan:headData.length}}>No records found</TableCell></TableRow>:null}
                        {isError ? <TableRow><TableCell align='center'  {...{colSpan:headData.length}}>The server did not return a valid response</TableCell></TableRow>:null}
                    </StickyHeadTable>
                </MuiPaper>

            </Container>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountReceivablesAgeingSummary);
