
import React from "react";
import Form from "react-bootstrap/Form";
import Label from "../Utils/FormInputs/Label";
import { FormLabel } from "../Utils/FormInputs/Label";
import { RightAlignedContainer } from "../Utils/styledComponents";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

/**
 * Component to render the patient visit feedback pop up screen
 * @param {*} param0 
 * @returns 
 */
export const PatientVisitFeedback = ({handleFeedbackNotesChange, feedback_notes, 
    rating_value, handleRatingChange, handleAddFeedback, handlEditFeedback, isEdit}) => {

    return (
        <>
            {/** Add text field for Feedback */}
            <Form.Group>
                <FormLabel className="pl-1" name='Feedback' toolTip='Capture your feedback here'/>
                <Form.Control as="textarea" rows="6" cols="4"  autoComplete="off"
                            value={feedback_notes}
                            onChange={handleFeedbackNotesChange}
                            className={`form__control`}/>
            </Form.Group>

            {/** Rating */}
            <Label className="pl-1" name='On a rating of 1 to 5 with 1 being very bad and 5 being very good how would you rate you experience' />
            <FormControl component="fieldset">
                <RadioGroup row aria-label="gender" name="gender1" value={rating_value} onChange={(e)=>handleRatingChange(e)}>
                    <Label className="pl-1" name='Very Bad' style={{paddingTop:'15px'}}/>
                    <FormControlLabel value={1} control={<Radio color="primary"/>} label="1" labelPlacement="bottom" />
                    <FormControlLabel value={2} control={<Radio color="primary"/>} label="2" labelPlacement="bottom"/>
                    <FormControlLabel value={3} control={<Radio color="primary"/>} label="3"  labelPlacement="bottom"/>
                    <FormControlLabel value={4} control={<Radio color="primary"/>} label="4" labelPlacement="bottom"/>
                    <FormControlLabel value={5} control={<Radio color="primary"/>} label="5" labelPlacement="bottom"/>
                    <Label className="pl-1" name='Very Good' style={{paddingTop:'15px'}}/>
                </RadioGroup>
            </FormControl>   

            <RightAlignedContainer>
                <button className="btn btn-sm sms-info-btn"  onClick={isEdit ? handlEditFeedback : handleAddFeedback}
                    style={{marginRight: '10px', marginBottom: '5px'}}>{isEdit ? `Edit`: `Add`} Feedback</button>
            </RightAlignedContainer>
        </>
    )
}