import {useTheme} from "@material-ui/core/styles";
import Form from "react-bootstrap/Form";
import Label from "../../Utils/FormInputs/Label";
import Col from "react-bootstrap/Col";
import TextField from "../../Utils/FormInputs/TextField";
import {Container, ErrorMessage} from "../../Utils/styledComponents";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus} from "@fortawesome/free-solid-svg-icons/faMinus";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import PrintDialog from "../../Utils/Dialogs/PrintDialog";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import Card from "@material-ui/core/Card";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../../actions/snackbarActions";
import * as departmentActions from "../../../actions/DoctorsActions/departmentActions";
import {connect} from "react-redux";
import React from "react";
import SelectInput from "../../Utils/FormInputs/SelectInput";
import {useAddDispatch} from "./hooks/useAddDispatch";
import {dateConvert} from "../../Utils/ConvertDate";
import {faShippingFast} from "@fortawesome/free-solid-svg-icons/faShippingFast";
import { AutoCompleteProducts } from "../../Utils/FormInputs/AutoCompleteInput";
import {DataCell} from "../../Utils/Lists/CollapsibleList";

const AddDispatch = ({actions, snackbars}) => {
    const  {closeSnackBar, openDialog,handleCloseDialog, handleClick,
        handleCancel, submitted, products, items, to_department,
        departments,  retrieveProducts, clickProduct, handleChangeItems,
        handleAddRow,removeRow, handleSubmit,    isPending, handleChangeBatch,
        handleChangeDepartment
    } = useAddDispatch(actions)
    const {openBar, type, message} = snackbars;
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;

    return (
        <div data-testid="add-requisition">
            <PageTitle title="Inventory"/>
            <SubHeader title="Inventory" subTitle="Add Dispatch">
                <FontAwesomeIcon icon={faShippingFast}/>
            </SubHeader>
            <PrintDialog  openDialog={openDialog} handleClose={handleCloseDialog} message="Print Dispatch Note"
                         text="Would you like to print this dispatch note" handleClick={handleClick}
                         handleCancel={handleCancel}/>
            <MainSnackbar message={message} variant={type} handleCloseBar={closeSnackBar} open={openBar}/>
            <Container>
                <Card className="p-3 general-card">
                    <form onSubmit={handleSubmit} autoComplete="off">
                        <div className="row">
                            <div className="col-lg-4">
                                <Form.Group as={Col}>
                                    <Label name="Date" />
                                    {/*<Col sm="6">*/}
                                        <TextField type="date" value={dateConvert()} disabled={true}
                                                   name="date"/>
                                    {/*</Col>*/}
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Label name="From"/>
                                    {/*<Col sm="6">*/}
                                        <TextField type="text"
                                                   name="from" value="Stores" disabled={true}/>
                                    {/*</Col>*/}
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Label name="To" htmlFor="to_store" />
                                    {/*<Col sm="6">*/}
                                        <SelectInput submitted={submitted} value={to_department} name='to_department' id='to_store'
                                                     onChange={handleChangeDepartment} options={departments} defaultOption='select...'/>

                                    {/*</Col>*/}
                                </Form.Group>
                            </div>
                            <div className='col-lg-6'/>

                        </div>
                        <div className="table-responsive">
                            <table
                                className={`table table-sm table-bordered ${palette.type === 'dark' && 'dark-table dark-table-bordered'}`}>
                                <thead>
                                    <th className={palette.type === 'dark' && 'dark-theme-color'}>Product<span className="help-block">*</span></th>
                                    <th className={palette.type === 'dark' && 'dark-theme-color'}>Batch#<span className="help-block">*</span></th>
                                    <th className={palette.type === 'dark' && 'dark-theme-color'}>Available Stock</th>
                                    <th className={palette.type === 'dark' && 'dark-theme-color'}>Quantity<span className="help-block">*</span></th>
                                    <th>Action</th>
                                </thead>
                                <tbody>
                                {products.map((item, index) => (
                                    <tr key={item.uuid} data-testid="drugs-row">
                                        <DataCell variant='250'>
                                            <AutoCompleteProducts options={items} submitted={submitted} idx={index} testId={`product_name-${index}`} handleChange={retrieveProducts} 
                                            medicine_value={{label:item.product_name, value:item.product_id, generic_name:item.generic_name}} id="drug_name" 
                                            errorMsg_Valid={"Please enter a valid product"} valid_error_check={item.isError && submitted}
                                            handleProductData={clickProduct} placeholder="Type Product Name" errorMsg="Enter Product Name"/>
                                        </DataCell>
                                        <DataCell variant='150'>
                                            <SelectInput submitted={submitted} value={item.batch_id} name='batch_id' data-testid={`batch_id-${index}`}
                                                         onChange={(e)=>handleChangeBatch({value:e.target.value}, index)} options={item.batches} defaultOption='select...'/>
                                        </DataCell>
                                        <DataCell>{item.available_quantity}</DataCell>
                                        <DataCell variant='150'>
                                            <TextField submitted={submitted} value={item.quantity} name="quantity"
                                                       onChange={(e) => handleChangeItems(e, index)} type="number"
                                                       placeholder="Input quantity" data-testid={`quantity-${index}`}/>
                                            {(submitted && !item.quantity) ? <ErrorMessage>Quantity is required</ErrorMessage>: null}
                                            {item.quantity > item.available_quantity ? <ErrorMessage>You can only dispatch up to {item.available_quantity} items</ErrorMessage>: null}
                                        </DataCell>
                                        <DataCell>
                                            <button onClick={() => removeRow(item.uuid)} type="button"
                                                    className="btn btn-sm sms-btn-dismiss" data-testid="remove-row">
                                                <FontAwesomeIcon icon={faMinus}/>
                                            </button>
                                        </DataCell>
                                    </tr>
                                ))}
                                <tr>
                                    <DataCell colSpan={5}>
                                        <button type="button" className="btn btn-sm sms-gray-btn" id="add-dispatch-row"
                                                onClick={handleAddRow}>Add another line
                                        </button>
                                    </DataCell>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                        <button type="submit" disabled={isPending} id="submit_dispatch"
                                className="btn sms-btn btn-sm ">{isPending ? "Saving..." : "Save"}</button>
                    </form>
                </Card>
            </Container>
        </div>

    )
};

function mapStateToProps(state) {
    return {
        snackbars: state.snackbar,
        departs: state.departments
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch),
            departmentActions: bindActionCreators(departmentActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddDispatch);