import React from "react";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CustomTable, { useHiddenColumns } from "../../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import {getComparator, stableSort} from "../../Utils/Tables/sortingFunctions";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {Link} from "react-router-dom";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import *  as snackbarActions from "../../../actions/snackbarActions";
import DeleteDialog from "../../Utils/Dialogs/DeleteDialog";
import {faShippingFast} from "@fortawesome/free-solid-svg-icons/faShippingFast";
import {faEdit} from "@fortawesome/free-solid-svg-icons/faEdit";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {useProductList} from "./hooks/useProductList";
import {DataExportButton} from "../../Utils/Buttons/DataExportationButton";
import {CsvDialog} from "../../Utils/Dialogs/CsvDialog";
import {RightAlignedContainer} from "../../Utils/styledComponents";
import { Container } from "../../DoctorsModule/doctorStyles";
import {useExcelReports, usePdfDownload} from "../../Utils/ReusableComponents/useExcelExport";


const headData = [
    {id: 'sl', numeric: false, disablePadding: false, label: 'SL', hidden:false},
    {id: 'product', numeric: false, disablePadding: false, label: 'Product', hidden:false},
    {id: 'generic_name', numeric: false, disablePadding: false, label: 'Generic Name', hidden:false},
    {id: 'strength', numeric: false, disablePadding: false, label: 'Strength', hidden:false},
    {id: 'box_size', numeric: false, disablePadding: false, label: 'Box Size', hidden:false},
    {id: 'country_of_origin', numeric: false, disablePadding: false, label: 'Country of Origin', hidden:false},
    {id: 'sellPrice', numeric: false, disablePadding: false, label: 'Sell Price', hidden:false},
    {id: 'reorderLevel', numeric: false, disablePadding: false, label: 'Reorder Level', hidden:false},
    {id: 'actions', numeric: false, disablePadding: false, label: 'Action', hidden:false},
];

const headDataEquip = [{id: 'sl', numeric: false, disablePadding: false, label: 'SL', hidden:false},
    {id: 'product', numeric: false, disablePadding: false, label: 'Product', hidden:false},
    {id: 'sellPrice', numeric: false, disablePadding: false, label: 'Sell Price', hidden:false},
    {id: 'actions', numeric: false, disablePadding: false, label: 'Action', hidden:false},
];

export const ProductList = ({actions, snackbars}) => {
    const {
        searchValue, order, orderBy, loading, productList, openDelete, activePage, total_count,
        handleOpenDelete, handleCloseDelete, handleDelete, closeSnackbar, handleChangeActivePage,
        handleSearch, handleRequestSort, csvRef,  downloadCsv, allRecords, downloadPdf, item, isPending,handleChangeStatus, activeStatus,
        uploadProps, headCells, all_hidden, handleAllHeadCells, handleHeadCells
    } = useProductList(actions, headData, headDataEquip);

    const prdts = !productList ? [] : productList;
    const all_products = prdts.map((item, index) => ({
        count: ((activePage - 1) * 10) + index + 1,
        product_name: item.product_name,
        price: item.price,
        product_id: item.product_id,
        ...item
    }))

   const {handleOpenCsvDialog} = uploadProps

    const headers = [{label: 'Product Name', key: 'product_name'}, {label: 'Sell Price', key: 'price'}]
    
    const {openBar, type, message} = snackbars;
    const perm = JSON.parse(sessionStorage.getItem('permission'));
    const permission = !perm ? {} : perm;
    const products = !permission.products ? {} : permission.products;
    const {status} = item;


    const fileName = `Products`

    const excelObj = {file_name:fileName,params:{category_id:activeStatus,  download:1}, url:`cpharmacy/medicines`}

    const pdfObj = {file_name:fileName,params:{category_id:activeStatus, download:2}, url:`cpharmacy/medicines`}


    const {exportExcel, isLoading:isLoadingExcel} = useExcelReports(actions,excelObj)
    const {exportPdf, isLoading:isLoadingPdf} = usePdfDownload(actions,pdfObj)

    return (
        <div data-testid="product-list" className='journals'>
            <PageTitle title="Products"/>
            <SubHeader title="Inventory" subTitle="Products">
                <FontAwesomeIcon icon={faShippingFast}/>
            </SubHeader>
            <CsvDialog {...{...uploadProps, file:"/csv/labreagents.csv", title:'Upload Products'}}/>
            <Container>
                <RightAlignedContainer>
                    <Link to="/addProduct">
                        <button className="btn sms-btn  mr-4"><FontAwesomeIcon icon={faPlus}/> Add Product</button>
                    </Link>
                    <button onClick={handleOpenCsvDialog} id="upload-products" className="btn sms-info-btn btn-sm mr-4">Upload Products
                    </button>
                    <DataExportButton isLoading={isLoadingExcel || isLoadingPdf} downLoadPdf={exportPdf}
                                      exportCsv={exportExcel}/>
                </RightAlignedContainer>

                {/*<div className="row">*/}
                {/*    <div className="col-md-4"/>*/}
                {/*    <div className="col-md-8">*/}
                {/*        <div className="row">*/}
                {/*            <div className="col-md-6">*/}
                {/*                <RightAlignedContainer>*/}
                {/*                    <Link to="/addProduct">*/}
                {/*                        <button className="btn sms-btn btn-sm mr-3"><FontAwesomeIcon icon={faPlus}/> Add Product</button>*/}
                {/*                    </Link>*/}
                {/*                    <button onClick={handleOpenCsvDialog} id="upload-products" className="btn sms-info-btn btn-sm">Upload Products*/}
                {/*                    </button>*/}
                {/*                </RightAlignedContainer>*/}
                {/*            </div>*/}
                {/*            <div className="col-md-6">*/}
                {/*                <DataExportButton data={allRecords} title='Product List' csvRef={csvRef}*/}
                {/*                                  exportCsv={downloadCsv} downLoadPdf={downloadPdf} headers={headers}/>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div className="mui-tables">
                    <DeleteDialog message={status === 1 ?  'deactivate':'activate'} openDialog={openDelete} handleClose={handleCloseDelete}
                                        text="product" title={`${status === 1 ? 'Deactivate':'Activate'} Product`}>
                            <button className='btn btn-sm sms-info-btn' disabled={isPending}
                            onClick={handleDelete}>Continue</button>
                            <button className="btn sms-grey-btn btn-sm" onClick={handleCloseDelete}>No</button>    
                    </DeleteDialog>
                    
                    <div className='general-ledger-header inpatient-billing-header'>
                        <a className={`billing-span btn btn-sm ${activeStatus === 1 ? 'active-billing-span':''}`} 
                            onClick={()=>handleChangeStatus(1)}>Medicine</a>
                        <a className={`billing-span btn btn-sm ${activeStatus === 3 ? 'active-billing-span':''}`} 
                            onClick={()=>handleChangeStatus(3)}>Sundries</a>
                        <a className={`billing-span btn btn-sm ${activeStatus === 4 ? 'active-billing-span':''}`} 
                            onClick={()=>handleChangeStatus(4)}>Lab Reagents</a>
                        <a className={`billing-span btn btn-sm ${activeStatus === 2 ? 'active-billing-span':''}`} 
                            onClick={()=>handleChangeStatus(2)}>Medical Equipment</a>
                        <a className={`billing-span btn btn-sm ${activeStatus === 0 ? 'active-billing-span':''}`} 
                            onClick={()=>handleChangeStatus(0)}>In-Active Products</a>
                    </div>

                    <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>
                    <CustomTable title={activeStatus === 1 ? 'Medicine' : activeStatus === 2 ? 'Medical Equipment' : activeStatus === 3 ? 'Sundries' : activeStatus === 0 ? 'In-Active Products' : ''}
                                term={searchValue} records={10} total_count={total_count}
                                activePage={activePage} id={searchValue}
                                headData={headCells} handler={handleSearch} handleChangeNextPage={handleChangeActivePage}
                                handleRequestSort={handleRequestSort} data={all_products} colSpan={5} order={order}
                                orderBy={orderBy} customPage all_hidden={all_hidden} handleAllHeadCells={handleAllHeadCells} 
                                handleHeadCells={handleHeadCells}
                    >
                        {loading ? <TableBody><TableRow>
                            <TableCell colSpan="7" align="center"><img src="/images/smsloader.gif"
                                                                    alt="loader"/></TableCell>
                        </TableRow></TableBody> : all_products.length > 0 ?
                            <TableBody>
                                {stableSort(all_products, getComparator(order, orderBy))
                                    .slice(0, 10)
                                    .map((item, index) => {
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={index}
                                                className='mui-table-row'>
                                                <TableCell>{item.count}</TableCell>
                                                <TableCell  hidden={headCells[1].hidden}>
                                                    <Link to={{pathname:`/reports/productlog/products/${item.product_id}`}}>{item.product_name}</Link>
                                                </TableCell>
                                                {activeStatus === 1 ? <TableCell hidden={headCells[2].hidden}>{item.generic_name}</TableCell>:''}
                                                {activeStatus === 1 ? <TableCell hidden={headCells[3].hidden}>{item.strenght}</TableCell>:''}
                                                {activeStatus === 1 ? <TableCell hidden={headCells[4].hidden}>{item.box_size}</TableCell>:''}
                                                {activeStatus === 1 ? <TableCell hidden={headCells[5].hidden}>{item.country_of_origin}</TableCell>:''}
                                                <TableCell hidden={activeStatus === 1? headCells[6].hidden : headCells[2].hidden}>{item.price}</TableCell>
                                                {activeStatus === 1 ? <TableCell hidden={headCells[7].hidden}>{item.reorder_level}</TableCell> :""}
                                                <TableCell hidden={activeStatus === 1? headCells[8].hidden : headCells[3].hidden}>
                                                    {products.update && item.status === 1 ?
                                                        <Link to={{pathname: `/editproduct/${item.product_id}`}} id='edit-button'>
                                                            <button type='button' className="btn btn-sm sms-info-btn mr-2 mb-2">
                                                                <FontAwesomeIcon icon={faEdit}/></button>
                                                        </Link> : <div/>}
                                                        <button onClick={() => handleOpenDelete(item)}
                                                                className={`btn btn-sm mb-2 ${item.status === 1 ? 'sms-gray-btn' : 'sms-info-btn'}`}>
                                                                {item.status === 1 ? 'Deactivate':item.status === 0 ?  'Activate':''}
                                                        </button>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                            : <TableBody><TableRow>
                                <TableCell colSpan="7" align="center">No records found</TableCell>
                            </TableRow></TableBody>}
                    </CustomTable>
                </div>
            </Container>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
