/** @jsxImportSource @emotion/react */
import {
    detailsTable,
    paddingCell,
    table,
    tableBordered,
    tableMargin,
    textWeight,
    topTableMargin
} from "../../styles/tableStyles";
import {
    Border,
    Heading as ResultHeading,
    longestString,
    ResultsHeading
} from "../DoctorsModule/PatientPreviousVisits";
import {ItemsTable, PrintTemplate} from "../Utils/Templates/PrintTemplate";
import {formatDateTime} from "../Utils/ConvertDate";
import React, {useEffect,  useState} from "react";
import {Spacer} from "../DoctorsModule/ReusableTreatmentHistory";
import axios from "axios";
import {config} from "../../Helpers/env";
import {logoutErrorMessage} from "../../Helpers/ErrorMessages";
import {splitConclusion, splitNotes} from "../Radiology/RadiologyReport";
import {SavedNotesContainer} from "../DoctorsModule/Notes/PatientNotesContainer";
import {LabResultsTable} from "../Lab/PatientLabReport";
import styled from "@emotion/styled/macro";
import {ReportList} from "../Utils/Lists/ReportList";
import {InpatientLabReport} from "./InpatientLabReport";


export const Heading = styled.h4({
    fontSize:'16px',
    fontWeight:700,
    marginTop:'25px',
    // textDecoration:'underline'
    borderBottom: '2px solid black'
})

const RadiologyResultReport = ({item,handleOpenItem}) => {
    const {hospital_scan_name,  notes, conclusion, indication, } = item;
    return (
            <table css={[table,detailsTable, tableBordered,topTableMargin]}>
                <tbody>
                <tr>
                    <td><span css={[textWeight]}>{hospital_scan_name?.toUpperCase()}</span></td>
                </tr>
                <tr>
                    <td><span><span css={[textWeight]}>Indication: </span>{indication ? indication : ''}</span></td>
                </tr>
                <tr/>
                <tr>
                    <td style={{paddingBottom: 0, borderBottom: 0}}><span css={[textWeight]}>Findings</span></td>
                </tr>
                <tr>
                    <td style={{borderTop: 0}}>{splitNotes(notes)}</td>
                    {/*/ <- changed it to this to fix the pushing of text to the next page when printing. */}
                </tr>

                {conclusion ? <tr>
                    <td style={{borderBottom: 0, paddingBottom: 0}}><span css={[textWeight]}>Conclusion</span></td>
                </tr> : null}
                {conclusion ? <tr>
                    <td style={{borderTop: 0}}>{splitConclusion(conclusion)}</td>
                </tr> : null}
                </tbody>
            </table>
    )
};

function DischargeNotesTemplate({actions, visit_id}) {
    const [details, setDetails] = useState({});

    useEffect(() => {
        const formData = new FormData();
        formData.append('visit_id', visit_id)
        axios.post(`${config.smsUrl}/cvisit/view_patient_admission_info`, formData).then(res => {
            const data = res.data;
            const all_data = !data ? {} : data;
            setDetails({...details, ...all_data, admission_details:all_data?.bed_assignment[0], findings:all_data?.findings[all_data?.findings?.length-1],
                discharge_details:all_data?.discharge_info[0], scan_result:all_data?.scan_result?.map(item=>({
                    ...item, open:true
                }))})
        }).catch(e => {
            logoutErrorMessage(e, null, actions)
        })
    }, [visit_id])

    const handleOpenResults = (visit_id,index,arr,item_name) =>{
        const openedArr = arr.map((child, idx)=>{
            if(idx === index){
                return {...child, open:!child.open}
            }else {
                return child
            }
        })
        const obj = {...details, [item_name]:openedArr}
        setDetails(obj)
    }




    const patientDetails = (
        <tbody>
        <tr>
            <td><span>Patient No.: </span> <span css={[textWeight]}>{details?.patient_info?.patient_number}
                    </span> </td>
            <td><span>D.O.B:</span> <span css={[textWeight]}>{details?.patient_info?.dob}</span></td>

        </tr>

        <tr>

            <td><span>Patient Name: </span> <span css={[textWeight]}>{`${details?.patient_info?.first_name} ${details?.patient_info?.last_name}`}</span></td>
            <td><span>Gender:</span> <span css={[textWeight]}>{details?.patient_info?.gender}</span></td>
        </tr>
        <tr>
            <td><span>Admission Date: </span> <span css={[textWeight]}>{formatDateTime(details?.admission_details?.assign_date)}</span></td>
            <td><span>Discharge Date:</span> <span css={[textWeight]}>{formatDateTime(details?.admission_details?.end_date)}</span></td>
        </tr>
        </tbody>
    )

    const diagnosis_arr = details?.diagnosis?.filter(item=>item.notes).map(item=>item.notes)
    const diag_notes = longestString(diagnosis_arr)

    const diagnosisComponent = (
        details?.diagnosis?.length > 0 ? <>
            <ResultsHeading>
                <Heading>Diagnosis</Heading>
            </ResultsHeading>
            <table css={[table,detailsTable, tableBordered, paddingCell]}>
                <tr>
                    <td><span><strong>ICD code</strong></span></td>
                    <td><span><strong>Disease</strong></span></td>

                </tr>

                {details?.diagnosis?.filter(item=>!(item.diagnosis === null && item.diagnosis_code === null)).map(item => (
                    <tr key={item.diagnosis_id}>
                        <td><span>{item.diagnosis_code ? item.diagnosis_code : 'N/A'}</span></td>
                        <td><span>{item.diagnosis}</span></td>
                    </tr>
                ))}
                <tr>
                    <td colSpan={2}><span><strong>Diagnosis Notes</strong></span></td>
                </tr>
                <tr>
                    <td colSpan={2}>{splitNotes(diag_notes)}</td>
                </tr>
            </table>

        </>: <p className='m-0'>There was no diagnosis that was recorded for this patient</p>
    )

    const presentingComplaints = (
        <>
            <ResultsHeading>
                <Heading>Presenting Complaints</Heading>
            </ResultsHeading>
            {details?.findings?.complaints ? <table css={[table, detailsTable, paddingCell]}>
                <SavedNotesContainer data-testid="p_complaints" className="mb-0">
                    {splitNotes(details?.findings?.complaints)}
                </SavedNotesContainer>
            </table> : <p className='m-0'>There are no presenting complaints recorded</p>}
        </>
    )

    const investigations = (
        <>
            <ResultsHeading>
                <Heading>Investigations</Heading>
            </ResultsHeading>
            <Spacer>
                {details?.lab_result?.length > 0 ? <>
                    <ResultsHeading>
                        {/*<ResultHeading>Lab Results</ResultHeading>*/}
                        {/*<Border/>*/}
                    </ResultsHeading>
                    {details?.lab_result?.map((item, index) => {
                        return (
                            <InpatientLabReport item={item} key={index}/>
                        )
                    })}
                </>: null}
            </Spacer>
            <Spacer>
                {details?.scan_result?.length > 0 ? <>
                <ResultsHeading>
                    {/*<ResultHeading>Radiology Report(s)</ResultHeading>*/}
                    {/*<Border/>*/}
                </ResultsHeading>
                {details?.scan_result?.map((item, index) => {
                    return (
                        <RadiologyResultReport item={item} key={index} handleOpenItem={()=>handleOpenResults(visit_id,index, details?.scan_result,'scan')}/>
                    )
                })}
            </>: null}
            </Spacer>

        </>
    )

    const headData = [{key:'drug_name',value:"Drug Name"}, {key:'quantity_prescribed',value:"Quantity Prescribed"}, {key:'dosage',value:"Dosage"},
        {key:'frequency',value:"Frequency"}, {key:'number_of_days',value:"Number of Days"},
        {key:'instructions',value:"Instructions"}]

    const treatmentHeadData = [{key:'drug_name',value:"Drug Name"},  {key:'dosage',value:"Dosage"},
        {key:'quantity_prescribed',value:"Quantity Administered"},{key:'route',value:"Route"},
        {key:'intake_time',value:"Intake Time"},
        {key:'frequency',value:"Frequency"}, {key:'number_of_days',value:"Number of Days"},
        {key:'instructions',value:"Instructions"}]

    const treatmentOnWard = (
        <>
            <ResultsHeading>
                <Heading>Treatment on Ward</Heading>
            </ResultsHeading>
            {details?.prescription?.length > 0 ?
            <>
            <ItemsTable
                headData={treatmentHeadData}>
                {details?.prescription?.filter(item=>item.patient_medication_detail_id)?.map((item, index) => (
                    <tr key={index}>
                        <td><span>{item.product_name || item.other_medicine}</span></td>
                        <td><span>{item.dosage}</span></td>
                        <td><span>{item.quantity_prescribed || item.quantity}</span></td>
                        <td><span>{item.route}</span></td>
                        <td><span>{item.intake_time}</span></td>
                        <td><span>{item.frequency}</span></td>
                        <td><span>{item.number_of_days}</span></td>
                        <td><span>{item.key_information}</span></td>
                    </tr>
                ))}
            </ItemsTable>
            </> : <p className='m-0'>There was no treatment on ward given to the patient</p>}
        </>
    )

    const dischargeMedication = (
        <>
            <ResultsHeading>
                <Heading>Discharge Medication</Heading>
            </ResultsHeading>

            {details?.discharge_prescription?.length > 0 ?
            <>
                <ItemsTable
                    headData={headData}>
                    {details?.discharge_prescription?.map((item, index) => (
                        <tr key={index}>
                            <td><span>{item.product_name || item.other_medicine}</span></td>
                            <td><span>{item.quantity_prescribed || item.quantity}</span></td>
                            <td><span>{item.dosage}</span></td>
                            <td><span>{item.frequency}</span></td>
                            <td><span>{item.number_of_days}</span></td>
                            <td><span>{item.key_information}</span></td>
                        </tr>
                    ))}
                </ItemsTable>
            </> : <p className='m-0'>There was no discharge medication given to the patient</p>}
        </>
    )

    const recommendation = (
        <>
            <ResultsHeading>
                <Heading>Recommendation</Heading>
            </ResultsHeading>
            <table css={[table,detailsTable, paddingCell]}>
                <SavedNotesContainer data-testid="p_complaints" className="mb-0">
                    {splitNotes(details?.discharge_details?.recommendations)}
                </SavedNotesContainer>
            </table>
        </>
    )


    const itemDetails =(
        <>
            <Spacer>
                {diagnosisComponent}
            </Spacer>
           <Spacer>
               {presentingComplaints}
           </Spacer>
            <Spacer>
                {investigations}
            </Spacer>

            <Spacer>
                {treatmentOnWard}
            </Spacer>
            <Spacer>
                {dischargeMedication}
            </Spacer>
            <Spacer>
                {recommendation}
            </Spacer>

        </>

    )


    return (
            <PrintTemplate title='Discharge Summary' {...{patientDetails,itemDetails}}/>
    )
}
export {DischargeNotesTemplate}