import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus} from "@fortawesome/free-solid-svg-icons/faMinus";
import {titleCase} from "../../Users/addUser";
import {ResizableTextarea} from "../../Utils/FormInputs/Textarea";
import { AutoCompleteUnControlled } from "../../Utils/FormInputs/AutoCompleteInput"
import InputGroup from "react-bootstrap/InputGroup";
import TextField from "../../Utils/FormInputs/TextField";
import {DataCell, ModuleTable} from "../../Utils/Lists/CollapsibleList";
import TableRow from "@material-ui/core/TableRow";
import {StyledCell} from "../../DoctorsModule/doctorStyles";

const headData = [{item:'Test Name', toolTip:'Enter test name', isRequired:true},
    // {item:'Purpose',  toolTip:'Enter purpose'}, 
    {item:'Cost'},{item:'Action'}]

export const RadiologyTable = ({scans, getScanTests, all_scans, retrieveScanDetails, handleAddRow,
                                   removeRow, submitted,
                                    // handleChangePurpose,
                                    isResolved}) => {
    return (
            <ModuleTable headData={headData}>
                {scans?.map((scan, index) => (
                    <TableRow
                        key={scan.uuid}
                    >
                        <DataCell variant='250'>
                            <AutoCompleteUnControlled reset={isResolved} options={all_scans} submitted={submitted} idx={index} handleChange={getScanTests} label_text="hospital_scan_name"
                                                      medicine_value={{label:scan.scan_name, value: scan.hospital_scan_id}} id="scan_name" value_text="hospital_scan_id"
                                                      errorMsg_Valid={"Please enter a valid name"} valid_error_check={scan.isError}
                                                      handleProductData={retrieveScanDetails} placeholder="Type Test name" errorMsg="Test name is required<"/>
                        </DataCell>
                        {/* <DataCell variant='200'>
                            <ResizableTextarea
                                    //   value={scan.purpose ? titleCase(scan.purpose) : scan.purpose}
                                      onChange={e => handleChangePurpose(e, index)}
                                      name="purpose" rows={2}/>
                        </DataCell> */}
                        <DataCell variant='200'>
                            <InputGroup>
                                <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">{scan.currency_symbol}</InputGroup.Text>
                                <TextField  type="number" value={scan.cost} style={{pointerEvents:'none'}}/>
                            </InputGroup>
                        </DataCell>
                        <DataCell>
                            <button onClick={() => removeRow(scan.uuid)} type="button"
                                    className="btn btn-sm sms-btn-dismiss" id="remove-row">
                                <FontAwesomeIcon icon={faMinus}/>
                            </button>
                        </DataCell>

                    </TableRow>
                ))}
                <TableRow>
                    <StyledCell colSpan={headData.length}>
                        <button type="button" onClick={handleAddRow} className="btn btn-sm sms-gray-btn"
                                id="add-row">Add another line
                        </button>
                    </StyledCell>
                </TableRow>
            </ModuleTable>

    )
}