import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {faUserMd} from "@fortawesome/free-solid-svg-icons/faUserMd";
import ReusableDoctorTabs, {doctorRoutes} from "../../Utils/Menu/ReusableDoctorTabs";
import {dateConvert, formatDateTime} from "../../Utils/ConvertDate";
import {PatientInformation} from "../PatientInfo/PatientInformation";
import {usePatientDetails} from "../ReferralNote/usePatientDetails";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../../actions/snackbarActions";
import {connect} from "react-redux";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {CenteredContainer} from "../../Utils/styledComponents";
import {LoadingGif} from "../../Utils/Loader";
import {CollapsibleList, DataCell, ModuleTable} from "../../Utils/Lists/CollapsibleList";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {Container, FinishedStatus, P, PendingStatus, StyledIcon} from "../doctorStyles";
import TableRow from "@material-ui/core/TableRow";
import CustomDialog from "../../Utils/Dialogs/CustomDialog";
import { StyledContainer} from "../Prescription/NewPrescription";
import {NewProcedureTable} from "./NewProcedure";
import {useDoctorProcedure} from "./hooks/useDoctorProcedure";
import {useEditDoctorProcedure} from "./hooks/useEditProcedure";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {ConfirmationDialog} from "../../Utils/ReusableComponents/ConfirmationDialog";
import {BlockNavigationComponent} from "../../Utils/ReusableComponents/BlockNavigationComponent";
import {history} from "../../../Helpers/history";
import {FormDialog} from "../../Utils/Dialogs/FormDialog";


const headData = [{item:'Procedure', isRequired:false},{item:'Quantity', isRequired:false},
    {item:'Status', isRequired:false}, {item:'Action', isRequired:false}]
const InPatientProcedure = ({actions, snackbars,match:{params}}) => {
    const {patient_number, visit_id, labrequest_id,activity_id,
        scan_request_id, patient_type,patient_admission_id} = params;
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const doctor_name = `${!_token.first_name ? "" : _token.first_name} ${!_token.last_name ? "" : _token.last_name}`;
    const [previousProcedures, setPreviousProcedures] = useState([])
    const [status, setStatus] = useState('idle')
    const [isDone, setIsDone] = useState('idle')
    const [previousProcedure, setPreviousProcedure] = useState([])
    const [openIsDone, setOpenIsDone] = useState(false)
    const [item, setItem] = useState({})

    const editParams = {...params, previousProcedure}
    const {addProps} = useDoctorProcedure(actions, params)
    const {editProps} = useEditDoctorProcedure(actions, editParams)
    const {isResolved,  openConfirmation, handleCloseConfirmation,
        addItemsToReceipt,handleCancelConfirmation, isBlockDialog, isBlocking, setIsBlockDialog,  handleResetForm} = addProps
    const {isResolved:isResolvedEdit} = editProps



    const [patient, setPatient] = useState({patient_name: "", age: "", gender: "", address: "", phone: ""});
    const {patient: patientDetails} = usePatientDetails(actions, patient_number, '')

    useEffect(() => {
        setPatient({
            patient_name: `${!patientDetails.first_name ? "" : patientDetails.first_name} ${!patientDetails.last_name ? "" : patientDetails.last_name}`,
            age: patientDetails.Age,
            gender: patientDetails.gender,
            address: patientDetails.address,
            phone: patientDetails.phone_no
        });
    }, [patientDetails]);

    const isIncomplete = isDone === 'pending'
    const isComplete = isDone === 'success'


    useEffect(() => {
        const formData = new FormData();
        formData.append('visit_id', visit_id);
        setStatus('pending')
        axios.post(`${config.smsUrl}/cdoctor/get_previous_procedure_by_visit_id`, formData).then(res => {
            const resp = res.data;
            const data = !resp ? {} : resp;
            const arr = data.procedure ? data.procedure : []
            const services = groupByDate(arr)
            const prods = services.map(item=>({...item,open:true}))
            setPreviousProcedures(prods);
            setPreviousProcedure(arr)
            setStatus('success')
        }).catch(error => {
            logoutErrorMessage(error, null, actions)
            setStatus('error')
        });
    }, [isResolved, isResolvedEdit, isComplete])

    const groupByDate = (arr=[]) =>{
        let result = []
        let knowledge = {}
        arr.forEach(item =>{
            let id = formatDateTime(item.date_created);
            if(id in knowledge){
                result[knowledge[id]].services.push(item)
            }else {
                knowledge[id] = result.length
                result.push({
                    id: id,
                    date:id,
                    doctor_name:`${item.doctor_firstname ? item.doctor_firstname:''} ${item.doctor_lastname ? item.doctor_lastname : ''}`,
                    services: [item]
                })

            }
        })
        return result
    }

    const handleOpenDone = (row) =>{
        setOpenIsDone(true)
        setItem(row)
    }

    const handleCloseDone = () =>{
        setOpenIsDone(false)
    }

    const markAsDone = () =>{
        setIsDone('pending')
        axios.get(`${config.smsUrl}/cbilling/update_inpatient_service_bill`, {params:{bill_id:item.bill_id}}).then(res => {
            actions.snackbarActions.snackSuccess('Procedure completed successfully')
            setIsDone('success')
            setOpenIsDone(false)
        }).catch(error => {
            errorMessages(error, null, actions)
            setIsDone('error')
        });
    }

    const handleOpen = (id) =>{
        const itemsArr = previousProcedures.map((item)=>{
            if(item.id === id){
                return {...item,open:!item.open}
            }else{
                return item
            }
        })
        setPreviousProcedures(itemsArr)
    }

    const isLoading = status === 'pending'
    const isSuccess = status === 'success'
    const isError = status === 'error'




    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const arr = doctorRoutes(patient_number, visit_id, patient_type,patient_admission_id)
    const {patient_name, age, gender, address, phone} = patient;
    const {openBar, type, message} = snackbars;
    const {openDialog, handleCloseDialog, handleOpenDialog, handleSubmit, isPending} = addProps
    const {openEdit, handleCloseEdit, handleOpenEdit, handleSubmit:handleSubmitEdit, isPending:isPendingEdit} = editProps

    const buttons = (
        <>
            <button type='button' onClick={markAsDone} disabled={isIncomplete}
                    className='btn btn-sm small-btn sms-info-btn mr-3'>{isIncomplete ? 'Marking...':'Yes'}</button>
            <button type='button' onClick={handleCloseDone} className='btn btn-sm small-btn sms-gray-btn mr-3'>Cancel</button>
        </>
    )
    return (
        <div className='journals'>
            <PageTitle title="Procedures"/>
            <SubHeader title="Doctor" subTitle="Procedures" doctor_name={doctor_name}>
                <FontAwesomeIcon icon={faUserMd}/>
            </SubHeader>
            <BlockNavigationComponent
                when={isBlocking}
                shouldBlockNavigation={()=>{
                    return isBlocking;
                }}
                navigate={path=>history.push(path)}
                dialogClose={isBlockDialog}
                setIsBlockDialog={setIsBlockDialog}
                clearForm={handleResetForm}
            />
            <CustomDialog open={openIsDone} title={`Mark ${item?.procedure_name} as done`} handleClose={handleCloseDone} buttons={buttons}>
                <p style={{textAlign:'center', margin:0}}>Are you sure you want to mark <strong>{item?.procedure_name}?</strong> as done</p>
            </CustomDialog>
            <FormDialog open={openDialog} handleClose={handleCloseDialog} title='Add Procedure' maxWidth='lg'
            handleSubmit={handleSubmit} buttons={  <StyledContainer>
                <button type='submit' className='btn sms-btn btn-sm' disabled={isPending}>{isPending ? 'Saving...' :'Save Procedure'}</button>
            </StyledContainer>}>
                    <NewProcedureTable {...addProps}/>
            </FormDialog>
            <ConfirmationDialog open={openConfirmation} handleClose={handleCancelConfirmation}
                                title='Make cash payment'
                                confirmItems={addItemsToReceipt}
                                removeItems={handleCloseConfirmation} service='procedure'/>
            <FormDialog open={openEdit} handleClose={handleCloseEdit} title='Edit Procedure' maxWidth='lg'
             buttons={ <StyledContainer>
                 <button type='submit' className='btn sms-btn btn-sm' disabled={isPendingEdit}>{isPendingEdit ? 'Saving...' :'Save Procedure'}</button>
             </StyledContainer>} handleSubmit={handleSubmitEdit}>
                    <NewProcedureTable {...{...editProps, isEdit:true, isOutPatient: true}}/>
            </FormDialog>
            <MainSnackbar open={openBar} handleCloseBar={closeSnackbar} variant={type} message={message}/>
            <ReusableDoctorTabs components={arr} patient_type={+patient_type}>
                <PatientInformation {...{patient_number, visit_id}}/>
                <Container>
                    <button onClick={handleOpenDialog}  type='button' className='btn btn-sm sms-info-btn mb-2'>Add Procedure</button>
                    {isLoading ? <CenteredContainer>
                        <LoadingGif/>
                    </CenteredContainer> : null}
                    {isSuccess ? previousProcedures.length > 0 ? previousProcedures.map((item)=>{

                        // const isAdded = item.services.some(dt=>dt.item_status !== 'Done')

                        // const isDone = item.services.some(dt=>dt.item_status === 'Done')
                        const payStatus =  item.services.some(dt=>dt.pay_status === 0)
                        return(
                            <CollapsibleList key={item.id} open={item.open} name={item.date} handleOpen={()=>handleOpen(item.id)} button={
                                <>
                                    {payStatus ? <Tooltip title='Edit Request'>
                                        <IconButton onClick={()=>handleOpenEdit(item.id)}>
                                            <StyledIcon/>
                                        </IconButton>
                                    </Tooltip> : null}
                                    {/*{isDone ?*/}
                                    {/*    <button onClick={()=>handleOpenReport(item.id)} className='btn btn-sm sms-info-btn btn-sm'>View Radiology Report</button>*/}
                                    {/*    : null}*/}
                                </>
                            }
                                // user={ <ItemText  primary={`Requested By: ${item.doctor_name}`} />}
                            >
                                <ModuleTable headData={headData}>
                                    {item.services.map((row, index) => {
                                        const isPending = row.item_status === 'Pending';
                                      
                                        const isFinished = row.item_status === 'Done';
                                        return(
                                            <TableRow
                                                key={index}
                                            >
                                                <DataCell>
                                                    {row.procedure_name}
                                                </DataCell>
                                                <DataCell>
                                                    {row.quantity}
                                                </DataCell>
                                                <DataCell>{isPending ? <PendingStatus>{row.item_status ? row.item_status : 'Pending'}</PendingStatus>:isFinished ? <FinishedStatus>{row.item_status}</FinishedStatus>: null}</DataCell>
                                                <DataCell>
                                                    {isPending  ? <button type='button' onClick={()=>handleOpenDone(row)} disabled={isIncomplete}
                                                            className='btn btn-sm small-btn sms-info-btn'>{isIncomplete ? 'Marking...' : 'Mark as done'}</button>: null}
                                                </DataCell>
                                            </TableRow>
                                        )})}
                                </ModuleTable>
                            </CollapsibleList>
                        )
                    }) : <CenteredContainer>
                        <P>No procedures added for patient, click the add procedure button to add a procedure</P>
                    </CenteredContainer>:null}
                    {isError ? <CenteredContainer>
                        <P>An error occurred on the server</P>
                    </CenteredContainer>:null}
                </Container>
            </ReusableDoctorTabs>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbar
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(InPatientProcedure);