import { faClipboardList } from "@fortawesome/free-solid-svg-icons/faClipboardList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { useQuery } from "react-query";
import { connect } from "react-redux";
import AsyncSelect from "react-select/async";
import { bindActionCreators } from "redux";
import * as snackbarActions from "../../../actions/snackbarActions";
import { SubHeader } from "../../../Containers/SubHeader";
import { config } from "../../../Helpers/env";
import { errorMessages } from "../../../Helpers/ErrorMessages";
import { titleCase } from "../../Users/addUser";
import { BackButton } from "../../Utils/Buttons/DataExportationButton";
import Label from "../../Utils/FormInputs/Label";
import { CustomSelect } from "../../Utils/FormInputs/SelectInput";
import TextField from "../../Utils/FormInputs/TextField";
import { LoadingGif } from "../../Utils/Loader";
import { useExcelReports } from "../../Utils/ReusableComponents/useExcelExport";
import PageTitle from "../../Utils/smsTitle";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import { Container } from "../../Utils/styledComponents";
import {CustomTablePagination, ReusableCustomPagination} from "../../Utils/Tables/CustomTable";
import { CustomHeadCell, CustomTableCell, MuiPaper, NonStickyHeadTable } from "../../Utils/Tables/StickyHeaderTable";
import * as colors from "../../../styles/colors";



const parentHeadData = [
    { id: 'opening_stock', numeric: false, disablePadding: false, label: 'Opening Stock' },
    { id: 'quantity_in', numeric: false, disablePadding: false, label: 'Quantity In' },
    { id: 'quantity_out', numeric: false, disablePadding: false, label: 'Quantity Out' },
    { id: 'closing_stock', numeric: false, disablePadding: false, label: 'Closing Stock' },
];

const allHeadData = [
    { id: 'product', numeric: false, disablePadding: false, label: 'Particulars', isBorder: true },
    { id: 'opening_store_qty', numeric: false, disablePadding: false, label: 'Store'},
    { id: 'opening_pharmacy_qty', numeric: false, disablePadding: false, label: 'Pharmacy' },
    { id: 'opening_lab_qty', numeric: false, disablePadding: false, label: 'Lab'  },
    { id: 'opening_stock', numeric: false, disablePadding: false, label: 'Total', isBorder: true  },
    { id: 'opening_bal_rate', numeric: false, disablePadding: false, label: 'Rate', rowSpan:2},
    { id: 'opening_bal_value', numeric: false, disablePadding: false, label: 'Value',rowSpan:2, isBorder: true},
    { id: 'in_store_qty', numeric: false, disablePadding: false, label: 'Store'  },
    { id: 'in_pharmacy_qty', numeric: false, disablePadding: false, label: 'Pharmacy' },
    { id: 'in_lab_qty', numeric: false, disablePadding: false, label: 'Lab'},
    { id: 'stock_in', numeric: false, disablePadding: false, label: 'Total', isBorder: true  },
    { id: 'quantity_in_rate', numeric: false, disablePadding: false, label: 'Rate' },
    { id: 'quantity_in_value', numeric: false, disablePadding: false, label: 'Value', isBorder: true},
    { id: 'out_store_qty', numeric: false, disablePadding: false, label: 'Store' },
    { id: 'out_pharmacy_qty', numeric: false, disablePadding: false, label: 'Pharmacy' },
    { id: 'out_lab_qty', numeric: false, disablePadding: false, label: 'Lab'},
    { id: 'stock_out', numeric: false, disablePadding: false, label: 'Total', isBorder: true  },
    { id: 'quantity_out_rate', numeric: false, disablePadding: false, label: 'Rate'},
    { id: 'quantity_out_value', numeric: false, disablePadding: false, label: 'Value', isBorder: true},
    { id: 'closing_store_qty', numeric: false, disablePadding: false, label: 'Store' },
    { id: 'closing_pharmacy_qty', numeric: false, disablePadding: false, label: 'Pharmacy' },
    { id: 'closing_lab_qty', numeric: false, disablePadding: false, label: 'Lab'},
    { id: 'closing_stock', numeric: false, disablePadding: false, label: 'Total', isBorder: true  },
    { id: 'closing_bal_rate', numeric: false, disablePadding: false, label: 'Rate'},
    { id: 'closing_bal_value', numeric: false, disablePadding: false, label: 'Value', isBorder: true},

];

const parentAllHeadData = [
    { id: 'product', numeric: false, disablePadding: false, label: '', isBorder: true, bottomBorder:true},
    { id: 'opening_bal_qty', numeric: false, disablePadding: false, label: 'Quantity', isBorder:true, colSpan:4 },
    { id: 'opening_bal_rate', numeric: false, disablePadding: false, label: '',bottomBorder:true},
    { id: 'opening_bal_value', numeric: false, disablePadding: false, label: '', isBorder: true,bottomBorder:true},
    { id: 'quantity_in_qty', numeric: false, disablePadding: false, label: 'Quantity', isBorder:true, colSpan: 4 },
    { id: 'quantity_in_rate', numeric: false, disablePadding: false, label: '',bottomBorder:true },
    { id: 'quantity_in_value', numeric: false, disablePadding: false, label: '', isBorder: true,bottomBorder:true },
    { id: 'quantity_out_qty', numeric: false, disablePadding: false, label: 'Quantity',isBorder:true, colSpan:4  },
    { id: 'quantity_out_rate', numeric: false, disablePadding: false, label: '',bottomBorder:true },
    { id: 'quantity_out_value', numeric: false, disablePadding: false, label: '', isBorder: true,bottomBorder:true },
    { id: 'closing_bal_qty', numeric: false, disablePadding: false, label: 'Quantity',isBorder:true,colSpan:4 },
    { id: 'closing_bal_rate', numeric: false, disablePadding: false, label: '',bottomBorder:true},
    { id: 'closing_bal_value', numeric: false, disablePadding: false, label: '',bottomBorder:true},
];

const useStyles = makeStyles((theme) => ({
    borderCell:{
        borderRight: `1px solid ${colors.gray500}`
    },
    bottomBorder:{
        borderBottom: 0
    }
}));


const StockSummary = ({ actions, snackbars }) => {
    const classes = useStyles()
    const theme = useTheme();

    const palette = theme?.palette ?? {};

    const customAsyncInputStyles = {
        control: (provided, { isDisabled }) => ({
            ...provided,
            backgroundColor: palette.type === 'dark' ? ' #424242' : isDisabled ? '#e9ecef' : 'white',
            borderRadius: '.7rem',
            color: palette.type === 'dark' && '#fff',
            // border: palette.type === 'dark' && '1px solid  rgba(255, 255, 255, 0.12)',
            fontSize: '14px',
            // width: `${width}px`,
            // border: isSubmitted ? '1px solid red' : ''
        }),
        option: (provided) => {
            return {
                ...provided,
                backgroundColor: '#ffff',
                color: '#181818',
                cursor: 'pointer',
                overflowY: 'auto',
                ':hover': {
                    ...provided[':provided'],
                    backgroundColor: 'rgba(20,1,125,0.1)',
                },
                ':active': {
                    ...provided[':active'],
                    backgroundColor: '#ffff',
                    color: '#0b0b0b'
                },
                fontSize: '14px'

            };
        },
        menuPortal: (base) => ({ ...base, zIndex: 0 }),
        singleValue: (styles) => ({
            ...styles,
            color: palette.type === 'dark' && '#fff',
        })
    };

    const [page, setPage] = React.useState(0);

    const [stockSummaryQueryParams, setStockSummaryQueryParams] = useState({
        location: { label: "Pharmacy", value: "pharmacy" },
        start_date: `${moment().format("YYYY-MM-DD")}`,
        end_date: `${moment().format("YYYY-MM-DD")}`,
        search: { label: "Search...", value: "" },
        product_id: "",
        per_page: 50,
        page: 1,
    });

    const productQuery = async (inputValue) => {
        try {
            const formData = new FormData();

            formData.append('product_name', titleCase(inputValue));

            const response = await axios.post(
                `${config.smsUrl}/cdoctor/autocomplete_search_medicine`,
                formData
            );

            return response?.data;
        } catch (error) {
            // throw new Error(error);
            return [];
        }
    }

    const loadOptions = async (inputValue, callback) => {

        setStockSummaryQueryParams({ ...stockSummaryQueryParams, search: { label: "Search...", value: "" } })

        callback(await productQuery(inputValue));
    }

    const stockSummaryQuery = useQuery(
        ["stockSummaryQuery", stockSummaryQueryParams],
        async () => {
            try {

                // debugger;

                const updatedParams = {
                    start_date: stockSummaryQueryParams?.start_date,
                    end_date: stockSummaryQueryParams?.end_date,
                    product_id: stockSummaryQueryParams?.search?.value ?? stockSummaryQueryParams?.search?.product_id,
                    per_page: stockSummaryQueryParams?.per_page,
                    page: stockSummaryQueryParams?.page,
                }

                Object.keys(updatedParams).forEach((key) => {
                    if (updatedParams[key] === null || updatedParams[key] === "") {
                        delete updatedParams[key];
                    }
                });


                const response = await axios.get(
                    `${config.smsUrl}/cinventory/stock_summary/${stockSummaryQueryParams?.location?.value}`,
                    {
                        params: updatedParams,
                    }
                );

                // console.log(response)

                return response?.data
            } catch (error) {
                errorMessages(error, null, actions)
                return { items: [], total_count: 0, total_pages: 0 }
            }
        },
        {
            retry: 1,
        }
    );

    const { items: products, total_count: totalCount, total_pages: totalPages } = stockSummaryQuery?.isSuccess ? stockSummaryQuery?.data : { items: [], total_count: 0, total_pages: 0 };

    const handleChangePage = (event, newPage) => {
        setStockSummaryQueryParams({
            ...stockSummaryQueryParams,
            page: newPage+1 > totalPages ? stockSummaryQueryParams?.page : newPage+1,
        });
        setPage(newPage);
    };



    const handleChangeRowsPerPage = (event) => {
        setStockSummaryQueryParams({
            ...stockSummaryQueryParams,
            page: 1,
            per_page: parseInt(event.target.value, 10)
        });
        setPage(0);

    };

    const handleChange = (event, eventExtra) => {
        // this is because event can be of type SyntheticEvent or {label: "", value: ""} 
        // and the latter uses a second argument (eventExtra)
        if (eventExtra === undefined) {

            setStockSummaryQueryParams({ ...stockSummaryQueryParams, [event?.target?.name]: event?.target?.value });

            return;
        }

        setStockSummaryQueryParams({
            ...stockSummaryQueryParams,
            [eventExtra?.name]: event,
        });

        return;
    }

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar();
    };

    const { openBar, type, message } = snackbars;

    const excelDownloadData = {
        file_name: 'Stock Summary Report',
        url: `/cinventory/stock_summary/${stockSummaryQueryParams?.location?.value}`,
        params: {
            start_date: stockSummaryQueryParams?.start_date,
            end_date: stockSummaryQueryParams?.end_date,
        },
    }

    const { exportExcel, isLoading: excelDownloading } = useExcelReports(actions, excelDownloadData);


    const parentHead = (
        <TableRow>
            <CustomHeadCell />
            {parentHeadData.map((column) => (
                <CustomHeadCell
                    key={column.id}
                    align='center'
                    style={{ minWidth: column.minWidth }}
                    className={{ root: {} }}
                    colSpan={stockSummaryQueryParams?.location?.value === "all" ? 6 : 3}
                >
                    <strong>{column.label}</strong>
                </CustomHeadCell>
            ))}
        </TableRow>
    )

    const allHeadRows = (
        <TableRow>
            {parentAllHeadData.map((column) => (
                <CustomTableCell
                    key={column.id}
                    colSpan={column.colSpan}
                    rowSpan={column.rowSpan}
                    align="center"
                    style={{ minWidth: column.minWidth }}
                    className={`${column.isBorder ?  classes.borderCell : null} ${column.bottomBorder ?  classes.bottomBorder : null}`}
                >
                    <strong>{column.label}</strong>
                </CustomTableCell>
            ))}
        </TableRow>
    )

    const pagination = (
        <CustomTablePagination
            colSpan={3}
            activePage={stockSummaryQueryParams.page}
            page={page}
            rowsPerPage={stockSummaryQueryParams.per_page}
            count={totalCount}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            isTotalCount
        />
    );

    const isQuantityBorder = stockSummaryQueryParams?.location?.value === "all" ? true : false

    const headData = [
        { id: 'product', numeric: false, disablePadding: false, label: 'Particulars', isBorder: true,rowSpan:stockSummaryQueryParams?.location?.value === "all" ? 2 : 0 },
        { id: 'opening_bal_qty', numeric: false, disablePadding: false, label: 'Quantity', isBorder:isQuantityBorder, colSpan:stockSummaryQueryParams?.location?.value === "all" ?  4 : 0 },
        { id: 'opening_bal_rate', numeric: false, disablePadding: false, label: 'Rate',},
        { id: 'opening_bal_value', numeric: false, disablePadding: false, label: 'Value', isBorder: true},
        { id: 'quantity_in_qty', numeric: false, disablePadding: false, label: 'Quantity', isBorder:isQuantityBorder, colSpan: stockSummaryQueryParams?.location?.value === "all" ? 4 :0 },
        { id: 'quantity_in_rate', numeric: false, disablePadding: false, label: 'Rate',rowSpan:stockSummaryQueryParams?.location?.value === "all" ? 2 : 0 },
        { id: 'quantity_in_value', numeric: false, disablePadding: false, label: 'Value', isBorder: true,rowSpan:stockSummaryQueryParams?.location?.value === "all" ? 2 : 0 },
        { id: 'quantity_out_qty', numeric: false, disablePadding: false, label: 'Quantity',isBorder:isQuantityBorder, colSpan:stockSummaryQueryParams?.location?.value === "all" ? 4 : 0  },
        { id: 'quantity_out_rate', numeric: false, disablePadding: false, label: 'Rate',rowSpan:stockSummaryQueryParams?.location?.value === "all" ? 2 : 0 },
        { id: 'quantity_out_value', numeric: false, disablePadding: false, label: 'Value', isBorder: true,rowSpan:stockSummaryQueryParams?.location?.value === "all" ? 2 :0 },
        { id: 'closing_bal_qty', numeric: false, disablePadding: false, label: 'Quantity',isBorder:isQuantityBorder,colSpan:stockSummaryQueryParams?.location?.value === "all" ? 4 :0  },
        { id: 'closing_bal_rate', numeric: false, disablePadding: false, label: 'Rate',rowSpan:stockSummaryQueryParams?.location?.value === "all" ? 2: 0 },
        { id: 'closing_bal_value', numeric: false, disablePadding: false, label: 'Value',rowSpan:stockSummaryQueryParams?.location?.value === "all" ? 2 : 0 },
    ];




    const data = products.map(item=>({
        ...item,
        product:item.product_name ?? 'N/A',
        opening_bal_qty:item?.opening_stock?.quantity ?? 0,
        opening_bal_rate:item?.opening_stock?.rate ?? 0,
        opening_bal_value : item?.opening_stock?.value ?? 0,
        quantity_in_qty:item?.stock_in?.quantity ?? 0,
        quantity_in_rate:item?.stock_in?.rate ?? 0,
        quantity_in_value:item?.stock_in?.value ?? 0,
        quantity_out_qty:item?.stock_out?.quantity ?? 0,
        quantity_out_rate:item?.stock_out?.rate ?? 0,
        quantity_out_value:item?.stock_out?.value ?? 0,
        closing_bal_qty:item?.closing_stock?.quantity ?? 0,
        closing_bal_rate:item?.closing_stock?.rate ?? 0,
        closing_bal_value:item?.closing_stock?.value ?? 0,
        opening_store_qty:item?.store?.opening_stock?.quantity ?? 0,
        opening_pharmacy_qty:item?.pharmacy?.opening_stock?.quantity ?? 0,
        opening_lab_qty:item?.lab?.opening_stock?.quantity ?? 0,
        in_store_qty:item?.store?.stock_in?.quantity ?? 0,
        in_pharmacy_qty:item?.pharmacy?.stock_in?.quantity ?? 0,
        in_lab_qty:item?.lab?.stock_in?.quantity ?? 0,
        out_store_qty:item?.store?.stock_out?.quantity ?? 0,
        out_pharmacy_qty:item?.pharmacy?.stock_out?.quantity ?? 0,
        out_lab_qty:item?.lab?.stock_out?.quantity ?? 0,
        closing_store_qty:item?.store?.closing_stock?.quantity ?? 0,
        closing_pharmacy_qty:item?.pharmacy?.closing_stock?.quantity ?? 0,
        closing_lab_qty:item?.lab?.closing_stock?.quantity ?? 0,
        opening_stock:item?.total?.opening_stock ?? 0,
        closing_stock:item?.total?.closing_stock ?? 0,
        stock_in:item?.total?.stock_in ?? 0,
        stock_out:item?.total?.stock_out ?? 0,
    }))

    return (
        <div>
            <PageTitle title="Stock Summary" />

            <SubHeader title="Reports" subTitle="Stock Summary">
                <FontAwesomeIcon icon={faClipboardList} />
            </SubHeader>

            <div className='mui-tables'>
                <div className='row align-items-center'>
                    <div className="col">
                        <BackButton text='General Reports' to='/reports/generalreports' />
                    </div>

                    <div className="col">
                        <Form.Group>
                            <Label name="Location" className="mb-n2" />
                            <CustomSelect
                                // submitted={submitted}
                                name="location"
                                onChange={handleChange}
                                value={stockSummaryQueryParams?.location}
                                options={[{ label: "All", value: "all" },{ label: "Pharmacy", value: "pharmacy" },
                                    { label: "Store", value: "store" },
                                    { label: "Laboratory", value: "lab" }]}

                            />
                            {/* <div className="help-block">Currency is required</div> */}
                        </Form.Group>

                    </div>
                    <div className="col">
                        <Form.Group>
                            <Label name="Start Date" className="mb-n2" />
                            <TextField
                                type='date'
                                // submitted={submitted}
                                name='start_date'
                                value={stockSummaryQueryParams?.start_date}
                                onChange={handleChange}
                                max={stockSummaryQueryParams?.end_date}
                            />
                            {/*}<div className="help-block">Currency is required</div>*/}
                        </Form.Group>

                    </div>

                    <div className="col">
                        <Form.Group>
                            <Label name="End Date" className="mb-n2" />
                            <TextField
                                type='date'
                                // submitted={submitted}
                                name='end_date'
                                value={stockSummaryQueryParams?.end_date}
                                onChange={handleChange}
                                min={stockSummaryQueryParams?.start_date}
                                max={"9999-12-31"}
                            />
                            {/*<div className="help-block">Currency is required</div>*/}
                        </Form.Group>

                    </div>

                    <div className="col">
                        <Form.Group>
                            <Label name="&nbsp;" className="mb-n2" />
                            <AsyncSelect
                                name="search"
                                cacheOptions
                                defaultOptions
                                styles={customAsyncInputStyles}
                                loadOptions={loadOptions}
                                onChange={handleChange}
                                value={stockSummaryQueryParams?.search}
                                isClearable
                            />
                        </Form.Group>
                    </div>

                    <div className="col d-flex justify-content-end h-auto">
                        <button
                            className="btn"
                            style={{ backgroundColor: "#429082", color: "#fff" }}
                            onClick={exportExcel}
                        >
                            {excelDownloading ?
                                <div className="d-flex justify-content-between align-items-center h-auto">
                                    <span class="spinner-border spinner-border-sm w-15 h-15" role="status" aria-hidden="true"></span>
                                    &nbsp;&nbsp;Loading...
                                </div>
                                : <>
                                    <svg
                                        width="15"
                                        height="15"
                                        viewBox="0 0 15 15"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M7.5 1.05a.45.45 0 0 1 .45.45v6.914l2.232-2.232a.45.45 0 1 1 .636.636l-3 3a.45.45 0 0 1-.636 0l-3-3a.45.45 0 1 1 .636-.636L7.05 8.414V1.5a.45.45 0 0 1 .45-.45M2.5 10a.5.5 0 0 1 .5.5V12c0 .554.446 1 .996 1h7.005A1 1 0 0 0 12 12v-1.5a.5.5 0 0 1 1 0V12a2 2 0 0 1-1.999 2H3.996A1.997 1.997 0 0 1 2 12v-1.5a.5.5 0 0 1 .5-.5"
                                            fill="#fff"
                                        />
                                    </svg>
                                    &nbsp;&nbsp;Excel</>}
                        </button>
                    </div>


                </div>
                <MainSnackbar
                    open={openBar}
                    message={message}
                    variant={type}
                    handleCloseBar={closeSnackbar}
                />

                <MuiPaper style={{ zIndex: 0 }}>
                    <NonStickyHeadTable
                        {...{
                            width: '100%',
                            data: products,
                            columns:stockSummaryQueryParams?.location?.value === "all" ? allHeadData :  headData.filter(item => Boolean(item)),
                            parentHeadData: parentHead,
                            headRows: stockSummaryQueryParams?.location?.value === "all" ?  allHeadRows : null
                        }}

                    >

                        {stockSummaryQuery?.isLoading ?
                            <TableRow>
                                <TableCell
                                    align='center'
                                    {...{ colSpan: headData.length }}
                                >
                                    <LoadingGif />
                                </TableCell>
                            </TableRow>
                            : null
                        }

                        {stockSummaryQuery?.isSuccess ? products?.length > 0 ?
                            <>
                                {data?.slice(0, stockSummaryQueryParams.per_page)?.map((row, index) => {
                                    const headColumns = stockSummaryQueryParams.location?.value === "all" ? allHeadData : headData
                                    return (
                                        <TableRow tabIndex={-1} key={index}>
                                            {headColumns.map(col=>(
                                                <CustomTableCell key={col.id} isBorder={col.isBorder}>
                                                    {row[col.id]}
                                                </CustomTableCell>
                                            ))}
                                        </TableRow>
                                    );
                                })}

                                {/*emptyRows > 0 && (
                                    <TableRow style={{ height: 43 * emptyRows }}>
                                        <TableCell colSpan={headData.length} />
                                    </TableRow>
                                )*/}

                            </>
                            :
                            <TableRow>
                                <TableCell
                                    align='center'
                                    {...{ colSpan: headData.length }}
                                >
                                    No records found
                                </TableCell>
                            </TableRow>
                            : null
                        }

                        {stockSummaryQuery?.isError ?
                            <TableRow>
                                <TableCell
                                    align='center'
                                    {...{ colSpan: headData.length }}
                                >
                                    The server did not return a valid response
                                </TableCell>
                            </TableRow>
                            : null
                        }
                    </NonStickyHeadTable>

                    {
                        products?.length > 0 ?
                            pagination
                            // <div className="d-flex w-100 h-auto justify-content-around align-items-center">
                            //     <ReusableCustomPagination
                            //         data={products}
                            //         activePage={stockSummaryQueryParams?.page}
                            //         records={stockSummaryQueryParams?.per_page}
                            //         total_count={totalCount}
                            //         handleChangeNextPage={handleChangePage}
                            //     />
                            //
                            // </div>

                            : null
                    }

                </MuiPaper>
            </div>
        </div>
    )
};

function mapStateToProps(state) {
    return { snackbars: state.snackbar }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StockSummary);
