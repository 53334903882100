import "react-multi-carousel/lib/styles.css";
import { PieChart, Pie, Cell, Legend } from 'recharts';
import Col from "react-bootstrap/Col";

/**
 * components for Gender Pie chart
 * @param {*} param0 
 * @returns 
 */

const GENDER_COLORS = ['#1fa7b0', '#F76B1D'];

const RADIAN = Math.PI / 180;
const renderGenderPieChartLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = outerRadius * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={'start'} dominantBaseline="top">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const renderColorfulLegendText = (value, entry) => {
  const { color , payload} = entry;
  return <span style={{ color , fontSize:'10px'}}>{value} - {payload['value']}</span>;
};

const GenderPieChart = ({gender_data}) => {
    return (
      <div>
        <div>
          <p  className="dashboard-card-font-color-subtitle">By Gender</p>
        </div>
        <PieChart width={140} height={140}>

          {gender_data.length > 0 ? <Pie
            data={gender_data}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderGenderPieChartLabel}
            outerRadius={46}
            innerRadius={0}
            fill="#8884d8"
            dataKey="value"
          >
            {gender_data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={GENDER_COLORS[index % GENDER_COLORS.length]} />
            ))}
            
          </Pie>
          : <Pie data={[{name: 'No Data Avaliable', value: 1}]} fill="#3e236e" />}
          <Legend iconType={'rect'} align={'center'} formatter={renderColorfulLegendText} />
        </PieChart>
      </div>
    );
}



/**
 * Components for the Age Groups
 * @param {*} param0 
 * @returns 
 */

 const AgeGroupBar = ({props}) => {
  const {bgcolor, completed, percentage_no, age_group} = props;
  
  const containerStyle = {
    height: 6,
    width: '100%',
    backgroundColor: '#e0e0de',
    borderRadius: 50,
    margin: 6
  }

  const fillerStyle = {
    height: '100%',
    width: `${percentage_no}%`,
    backgroundColor: bgcolor,
    borderRadius: 'inherit',
    textAlign: 'right',
  }
  
  return (

      <div className="row" style={{margin:'5px'}}>
        <Col md={3} style={{textAlign:'right', padding: '2px', fontSize:'12px'}}>
    
          {`${age_group}`}
 
        </Col>

        <Col md={7} style={{ padding: '2px', paddingRight:'10px'}}>
                <div style={containerStyle}>
        <div style={fillerStyle}>
          <span/>
        </div>
        </div>
        </Col>

        <Col md={2} style={{ padding: '2px', fontSize:'12px'}}>
       
            {`${completed}`}
       
        </Col>
      </div>



  )
}

const AgeGroups = ({age_groups}) => {
  const age_data = age_groups.length > 0 ? age_groups : [                   
    { bgcolor: "#04E762", completed: 0, percentage_no : 0, age_group: '0 - 28 days' },
    { bgcolor: "#F17105", completed: 0, percentage_no : 0, age_group: '29 days - 4 years' },
    { bgcolor: "#6665DD", completed: 0, percentage_no : 0, age_group: '5 - 9 years' },
    { bgcolor: "#34D1BF", completed: 0, percentage_no : 0, age_group: '10 - 19 years' },
    { bgcolor: "#FF715B", completed: 0, percentage_no : 0, age_group: '20+ years' }
  ];

  return (
    <div>

      <div>
        <p  className="dashboard-card-font-color-subtitle mb-2">By Age Groups</p>
      </div>

      <div >
      {age_data.map((item, idx) => (
        <AgeGroupBar key={idx} props={item}/>
      ))}
      </div>

    </div>
  )
}




const PatientStatistics = ({title_report_card, gender_data, age_groups}) => {
  return (
    <div>
      {title_report_card}
      <div className="row">

        <div className="col-md-4" style={{padding:'5px', paddingLeft:'15px'}}>
        <GenderPieChart gender_data={gender_data}/>
        </div>

        <div className="col-md-1" style={{padding: '5px', paddingTop: '10px'}}>
        <div style={{borderLeft: '2px solid #137A35', borderRadius:'10px',
            height: '90%',
            position:'absolute',
            left: '50%'}}></div>
        </div> 
       
        
        <div className="col-md-7" style={{ padding: '5px'}}>
        <AgeGroups age_groups={age_groups}/>        
        </div>

      </div>    
    </div>
  );
}

export default PatientStatistics;
