import React from "react";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CustomTable, {CustomTablePagination, useHiddenColumns} from "../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import {getComparator, stableSort} from "../Utils/Tables/sortingFunctions";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import * as snackbarActions from "../../actions/snackbarActions"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PageTitle from "../Utils/smsTitle";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import {history} from "../../Helpers/history";
import {faNotesMedical} from "@fortawesome/free-solid-svg-icons/faNotesMedical";
import { useAdmissionList } from "../Billing/in-patient/hooks/useAdmissionList";
import {dateStringConvert} from "../Utils/ConvertDate";
import {ReusableRouteTabs} from "../Utils/Dialogs/ReusableTabs";
import { LoadingGif } from "../Utils/Loader";
import FollowUpDateFilter from "../Utils/FollowUpDateFilter";
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Container } from "../DoctorsModule/doctorStyles";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Label from "../Utils/FormInputs/Label";
import {CustomSelect} from "../Utils/FormInputs/SelectInput";
import {DataExportButton} from "../Utils/Buttons/DataExportationButton";
import {useExcelReports, usePdfDownload} from "../Utils/ReusableComponents/useExcelExport";
import {RightAlignedContainer} from "../Utils/styledComponents";



const headData = [{id: 'count', numeric: false, disablePadding: false, label: 'SL', hidden:false},
    {id: 'patient_admission_id', numeric: false, disablePadding: false, label: 'Admission ID', hidden:false},
    {id: 'admission_date', numeric: false, disablePadding: false, label: 'Admission Date', hidden:false},
    {id: 'patient_name', numeric: false, disablePadding: false, label: 'Patient Name', hidden:false},
    {id: 'phone_no', numeric: false, disablePadding: false, label: 'Phone', hidden:false},
    {id: 'kin_phone_no', numeric: false, disablePadding: false, label: 'NOK Phone', hidden:false},
    {id: 'discharge_date', numeric: false, disablePadding: false, label: 'Discharge Date', hidden:false},
    {id: 'discharged_by', numeric: false, disablePadding: false, label: 'Discharged By', hidden:false},
    {id: 'follow_up_date', numeric: false, disablePadding: false, label: 'Follow-up Date', hidden:false},
    {id: 'admitted_by', numeric: false, disablePadding: false, label: 'Admitted By', hidden:false},
    {id: 'action', numeric: false, disablePadding: false, label: 'Action', hidden:false},
];

const DischargeList = ({actions, snackbars}) => {
    const {searchValue,  order, orderBy, activePage, admissionList:admission_list, isLoading,
        isSuccess,isError, handleSearch, handleRequestSort,handleClick, handleChangeNextPage, closeSnackbar,
        state,handleChangeOption,handleChangeDate , handleSubmit,
        payment, setPayment,page, rowsPerPage, handleChangeRowsPerPage, handleChangePage
    } = useAdmissionList(actions,'cvisit/discharge_list');
    
   
    const {openBar, type, message} = snackbars;
    const components = [{label: 'Admitted Patients', path: `/patientroundlist`},
        // {label: 'Lab/Radiology Reports', path: `/medicationresults`},
        {label: 'Discharged Patients', path: `/dischargelist`}];
    const obj = admission_list ? admission_list : {};
    const dischargeList = !obj.discharge_list ? [] : obj.discharge_list;
    const total_count = !obj.total_count ? 0 : obj.total_count;

    const admissionList = dischargeList.map((item, index)=>{
        const count  = ((activePage - 1) * rowsPerPage) + index + 1;
        return {
            ...item,
            count,
            admission_date:item.admission_date ? dateStringConvert(item.admission_date):null,
            patient_name: `${!item.p_first_name ? "" : item.p_first_name} ${!item.p_last_name ? "" : item.p_last_name}`,
            admitted_by: `${!item.admitted_by_first_name ? "" : item.admitted_by_first_name} ${!item.admitted_by_last_name ? "" : item.admitted_by_last_name}`,
            discharged_by: `${!item.discharged_by_first_name ? "" : item.discharged_by_first_name} ${!item.discharged_by_last_name ? "" : item.discharged_by_last_name}`,
            phone_no:item.p_phone_no,
            discharge_date: item.discharge_date ?  dateStringConvert(item.discharge_date) : null,
            follow_up_date:item.followup_date ? dateStringConvert(item.followup_date):null,
            action:(
                <VisibilityIcon onClick={() => handleClick(item.visit_id)} />
            )
        }
    })

    const {
        headCells, all_hidden, handleAllHeadCells, handleHeadCells
    } = useHiddenColumns(headData);

    const paymentTypes = [{value: '', label: 'All'},
        {value: 1, label: 'Cash'},
        {value: 2, label: 'Insurance Company'},
        {value: 3, label: 'Organization'},
        {value: 4, label: 'Individual'},
        ]

    const {from_date, to_date} = state


    const commonParams = {from_date , to_date,
        mode_of_payment:payment?.value ? payment?.value:''}

    const fileName = `${payment?.label}-${from_date} to ${to_date} discharge report`

    const excelObj = {file_name:fileName,params:commonParams, url:'creport/dischargelist'}

    const pdfObj = {file_name:fileName,params:commonParams, url:'creport/dischargelist'}

    const {exportPdf, isLoading:isLoadingPdf} = usePdfDownload(actions, pdfObj)

    const {exportExcel, isLoading:isLoadingExcel} = useExcelReports(actions, excelObj)


    const exportButton = (
        <RightAlignedContainer>
            <DataExportButton downLoadPdf={exportPdf} exportCsv={exportExcel} isLoading={isLoadingPdf || isLoadingExcel}/>
        </RightAlignedContainer>
    )


    const pagination = (
        <CustomTablePagination
            colSpan={3}
            activePage={activePage}
            page={page}
            rowsPerPage={rowsPerPage}
            count={total_count}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            isTotalCount
        />
    );
    return (
        <div data-testid="admission-list" className='journals'>
            <PageTitle title="Discharged Patients"/>
            <SubHeader title="Medication and Rounds" subTitle="Discharged patients">
                <FontAwesomeIcon icon={faNotesMedical}/>
            </SubHeader>
            <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/>

            <Container>
                <div className="mui-tables">
                    <MainSnackbar variant={type} handleCloseBar={closeSnackbar} open={openBar} message={message}/>
                    <FollowUpDateFilter handleChangeDate={handleChangeDate} handleSubmit={handleSubmit} from_date={from_date}
                                        to_date={to_date} handleChangeOption={handleChangeOption} isLoading={isLoading}
                                        exportButton={exportButton} >
                        {/*<div className='col-lg-6'>*/}
                        {/*    <Form.Group as={Row}>*/}
                        {/*        <Col xs={12} sm={12} md={2} lg={2} xl={2} >*/}
                        {/*            <Label name='Payment'/>*/}
                        {/*        </Col>*/}
                        {/*        <Col xs={12} sm={12} md={7} lg={7} xl={7}>*/}
                        {/*            <CustomSelect value={payment} onChange={(value) => setPayment(value)}*/}
                        {/*                          options={paymentTypes}/>*/}
                        {/*        </Col>*/}
                        {/*    </Form.Group>*/}
                        {/*</div>*/}

                    </FollowUpDateFilter>
                    {/* <div className="text-right">
                        <button onClick={handleRefresh} className="btn btn-sm sms-info-btn mb-1">Refresh</button>
                    </div> */}
                    <CustomTable title="Discharged Patients" customPage
                                handleChangeNextPage={handleChangeNextPage}
                                headData={headCells} handler={handleSearch} records={10} total_count={total_count}
                                activePage={activePage}
                                handleRequestSort={handleRequestSort} data={admissionList} colSpan={headCells.length} order={order}
                                orderBy={orderBy} term={searchValue}
                                 {...{all_hidden, handleAllHeadCells, handleHeadCells, pagination}}>
                            <TableBody>
                                {isLoading ? <TableRow>
                                    <TableCell colSpan={headCells.length} align="center"><LoadingGif/></TableCell>
                                </TableRow>:null}
                                {isSuccess ? admissionList.length > 0 ?  stableSort(admissionList, getComparator(order, orderBy))
                                    .slice(0, rowsPerPage)
                                    .map((item, index) => {
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={index}
                                                className='mui-table-row'
                                            >
                                                {headCells.map((head)=>(
                                                    <TableCell id={head.id}  key={head.id}
                                                               hidden={head.hidden}>{item[head.id]}</TableCell>
                                                ))}
                                            </TableRow>
                                        );
                                    }):<TableRow>
                                    <TableCell colSpan={headCells.length} align="center">No records found</TableCell>
                                </TableRow>:null}
                                {isError ? <TableRow>
                                    <TableCell colSpan={headCells.length} align="center">The server did not return a valid response</TableCell>
                                </TableRow>: null}
                            </TableBody>
                    </CustomTable>
                </div>
            </Container>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DischargeList);
