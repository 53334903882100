import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import { config } from "../../../../Helpers/env";
import {useCommonState} from "../../CustomHooks/useReusableFunctions";
import { exportPDF } from "../../../Utils/downloadPDF";
import {convertMonth} from "../../../Utils/ConvertDate";


export const useNumberOfPositives = (actions) => {
    const [hospital_test_id, setId] = useState('');
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [statistics, setStatistics] = useState([]);
    const [loading, setLoading] = useState(false);
    const [labTests, setList] = useState([]);
    const [allTests, setLabTests] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const csvRef = useRef();
    const {closeSnackbar, search_type,  text, textType, year, date, handleChangeSearch,
        handleChangeDate, handleChangeYear, handleIncrementYear,inputType,
        searchValue, order, orderBy, page, rowsPerPage,setSearchType,
        handleSearch, handleRequestSort, handleChangePage, handleChangeRowsPerPage} = useCommonState(actions)
        const {start_date, end_date} = date;

    // useEffect(() => {
    //     axios.get(`${config.smsUrl}/creport/qualitative_tests`).then(res => {
    //         const data = res.data;
    //         const dt = data ? data : {};
    //         const cats = !dt.Qualitative ? [] : dt.Qualitative
    //         setLabTests(cats);
    //     }).catch((err) => {
    //         logoutErrorMessage(err, null, actions);
    //     })
    // }, []);


    useEffect(() => {
        setLoading(true);
        axios.post(`${config.smsUrl}/creport/number_of_positives`, null,{
            params: {
                start_date: start_date,
                end_date: end_date,
                option: 3,
                hospital_test_id: '',
                data: true
            }
        }).then(res => {
            const data = res.data;
            const dt = !data ? [] : data;
            setList(dt);
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
            logoutErrorMessage(err, null, actions);
        })
    }, []);

    useEffect(() => {
        axios.post(`${config.smsUrl}/creport/number_of_positives`, null,{
            params: {
                start_date: start_date,
                end_date: end_date,
                option: 3,
                hospital_test_id: hospital_test_id ? hospital_test_id.value : '',
                data: false
            }
        }).then(res => {
            const data = res.data;
            const dt = data ? data : [];
            setStatistics(dt)
        }).catch(err => {
            logoutErrorMessage(err, null, actions);
        })

    }, [])


    const handleChangeTest = (value) => {
        setId(value)
        axios.post(`${config.smsUrl}/creport/number_of_positives`,null, {params: {
            start_date: start_date,
            end_date: end_date,
            option: 3,
            hospital_test_id: value.value,
            data: false
        }}).then(res => {
            const data = res.data;
            const dt = data ? data : [];
            setStatistics(dt)
        }).catch(err => {
            errorMessages(err, null, actions);
        })


    }


    const handleRetrieveData = (val) => {
        const {start_date, end_date} = date;
        setSubmitted(true)
        if (start_date && end_date) {
            setIsSubmitted('pending')
            axios.post(`${config.smsUrl}/creport/number_of_positives`, null,{
                params:{
                    start_date: start_date,
                    end_date: end_date,
                    option: 3,
                    hospital_test_id: val === false ? hospital_test_id.value : '',
                    data: val
                }
            }).then(res => {
                const data = res.data;
                const dt = data ? data : [];
                if (val === false) {
                    setStatistics(dt)
                } else {
                    setList(dt)
                }
                setIsSubmitted('resolved')
            }).catch(err => {
                setIsSubmitted('rejected')
                errorMessages(err, null, actions);
            })
        }
    }


    const handleSubmit = (event) => {
        event.preventDefault();
        handleRetrieveData(false);
        handleRetrieveData(true);
    }

    const downloadPdf = () => {
        const {end_date, start_date} = date
        const time = (search_type === '0' || search_type === '1') ? convertMonth(start_date) :
            search_type === '2' ? year : `${start_date} to ${end_date}`
        exportPDF(`Positives & Negatives(${time})`, [["Test Name", "Positives", "Negatives",'Total']],
            labTests.map((item) => [item.hospital_test_name,  item.positives, item.negatives,
                +item.positives + +item.negatives]),
            "Positives & Negatives.pdf");

    }
    const downloadCsv = () => {
        csvRef.current.link.click()
    }


    return {hospital_test_id, handleChangeTest, closeSnackbar, search_type, handleSubmit,
        text, textType, year, date, handleChangeSearch,handleRetrieveData,setSearchType,
        handleChangeDate, handleChangeYear, handleIncrementYear, statistics, isSubmitted, submitted,
        searchValue, order, orderBy, loading, labTests, allTests, page, rowsPerPage,inputType,
        handleSearch, handleRequestSort, handleChangePage, handleChangeRowsPerPage, csvRef,
        downloadCsv,downloadPdf
    }
}