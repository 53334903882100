import React, {useState} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../actions/snackbarActions"
import PageTitle from "../Utils/smsTitle";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCog} from "@fortawesome/free-solid-svg-icons/faCog";
import Card from "@material-ui/core/Card";
import SmsSnackbar from "../Utils/Snackbar/SmsSnackbar";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import Label from "../Utils/FormInputs/Label";
import {faEyeSlash} from "@fortawesome/free-regular-svg-icons/faEyeSlash";
import {faEye} from "@fortawesome/free-regular-svg-icons/faEye";
import axios from "axios";
import { config } from "../../Helpers/env"
import {errorMessages} from "../../Helpers/ErrorMessages";
import {useEditUser} from "./CustomHooks/useEditUser";
import {titleCase} from "../Users/addUser";
import {history} from "../../Helpers/history";
import TextField from '../Utils/FormInputs/TextField';
import { ErrorMessage } from '../Utils/styledComponents';
import { BackButton } from '../Utils/Buttons/DataExportationButton';

const ChangeUserPassword = ({actions, snackbar, match}) => {
    const [password, setPassword] = useState('');
    const [showPassword, SetShowPassword] = useState(false)
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle')
    const {user_id} = match.params;

    const handleChange = (event) => {
        const {value} = event.target;
        setPassword(value)
    }

    const handleShowPassword = () => {
        SetShowPassword(!showPassword)
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('user_id', user_id);
        formData.append('password', password);
        setSubmitted(true)
        if (password) {
            setIsSubmitted('pending')
            axios.post(`${config.smsUrl}/cuser/change_user_password`, formData).then(() => {
                actions.snackbarActions.snackSuccess('User password changed successfully');
                setIsSubmitted('resolved');
                history.push('/userroles')
            }).catch(err => {
                setIsSubmitted('rejected')
                errorMessages(err, null, actions)
            })
        }

    }

    const handleCloseSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    }
    const {state} = useEditUser({actions, snackbar, match})
    const {first_name, last_name} = state;
    const {openBar, type, message} = snackbar;
    const name = `${first_name ? first_name : ''} ${last_name ? last_name : ''}`
    return (
        <div>
            <PageTitle title="Change Password"/>
            <SubHeader title="Settings" subTitle="Change Password">
                <FontAwesomeIcon icon={faCog}/>
            </SubHeader>
            <BackButton to='/userroles' text='User Roles'/>
            <SmsSnackbar message={message} open={openBar} handleCloseBar={handleCloseSnackbar} variant={type}/>
            <div className="change-password-card">
                <div className='mb-2'>
                    <BackButton text='User Roles' to='/userroles'/>
                </div>
                <Card className="p-2">
                    <h6 className="visit-heading"> Change Password</h6>
                    <form autoComplete="off" onSubmit={handleSubmit}>
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                                <Label name='User' htmlFor='name' type />
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                                <TextField type='text' id='name' value={name ? titleCase(name) : name} disabled
                                            />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                                <Label name='Password' htmlFor='password' type/>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={8} xl={8}>

                                <div className='form-group-div'>
                                    <TextField {...{submitted}} type={showPassword ? 'text' : 'password'} id='password' value={password}
                                                  onChange={handleChange} />
                                    {password && <span className="form-icon"><FontAwesomeIcon
                                        icon={showPassword ? faEyeSlash : faEye} onClick={handleShowPassword}/></span>}
                                </div>
                                {(submitted && !password) && <ErrorMessage>Password is required</ErrorMessage>}
                            </Col>
                        </Form.Group>
                        <div className="text-center">
                            <button type="submit" className="btn btn-sm sms-btn text-center"
                                    disabled={isSubmitted === 'pending' || isSubmitted === 'resolved'}>{isSubmitted === 'pending' ? 'Submitting...' : 'Submit'}</button>
                        </div>
                    </form>
                </Card>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    const {snackbar} = state
    return {snackbar}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeUserPassword);