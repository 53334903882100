import {useEffect, useRef, useState} from "react";
import axios from "axios";
import { dateConvert } from "../../../Utils/ConvertDate";
import { config } from "../../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";


const useInsuranceSummary = (actions) =>{
    const [state, setState] = useState({start_date:dateConvert(), end_date:dateConvert(),status:'0'})

    const [loading, setLoading] = useState('idle');
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [data, setData] = useState({});
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [activePage, setActivePage] = useState(1)
    const [count, setCount] = useState(0)
    const [customSearch, setCustomSearch] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [provider, setProvider] = useState({value:'', label:''})
    const [providers, setProviders] = useState([])

    const {start_date, end_date,status} = state;

    const isPending = isSubmitted === 'pending';
    const isResolved = isSubmitted === 'resolved';


    useEffect(() => {
        setActivePage(1)
        setPage(0)
        setRowsPerPage(50)
    }, [start_date,end_date]);

    /**
     * List of providers
     */
    useEffect(() => {
        axios.get(`${config.smsUrl}/cbilling/insurance_providers/list`).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const arr = !dt.data ? [] : dt.data;
            const dataArr = arr.map(item=>({
                value:item.provider_id,
                label:item.provider_name
            }))
            setProviders(dataArr);
        }).catch(err => {
            logoutErrorMessage(err,null,actions)
        })
    }, []);

//gets insurance summary list
    useEffect(() => {

        setLoading('pending');
        axios.get(`${config.smsUrl}/cbilling/insurance_summary`,{params: {
            end_date: end_date,
            start_date:start_date,
            page:activePage,
            search: customSearch,
            status: status,
            provider_id: provider?.value,
            per_page:rowsPerPage
        }}).then(res => {
            const data = res.data;
            const arr = data?.data ? data?.data : []
            const t_count = data.count ? data.count : 0
            setData(arr)
            setCount(t_count)
            setLoading('success');
        }).catch((err) => {
            logoutErrorMessage(err, null, actions);
            setLoading('error');
        })
    }, [status,isResolved,customSearch, provider, activePage, rowsPerPage]);
    

    const handleCustomSearch = (event) => {
        setCustomSearch(event.target.value)
    };

    const handleChange = (event) =>{
        const {name, value} = event.target;
        setState({...state,[name]:value})
    }

    const handleChangeProvider = (value) =>{
        setProvider(value)
    }


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setActivePage(newPage+1);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        setActivePage(1);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setSubmitted(true);
        if (start_date && end_date) {
            setIsSubmitted('pending')
            setLoading('idle')
            axios.get(`${config.smsUrl}/cbilling/insurance_summary`,{params: {
            end_date: end_date,
            start_date:start_date,
            page:activePage,
            search: customSearch,
            status: status,
            provider_id: provider?.value,
            per_page:rowsPerPage
            }}).then(res => {
                const data = res.data;
                const arr = data?.data ? data?.data : []
                const t_count = data.count ? data.count : 0
                setData(arr)
                setCount(t_count)
                setLoading('success');
                setIsSubmitted('resolved')
            }).catch(err => {
                setIsSubmitted('rejected')
                setLoading('error')
                errorMessages(err, null, actions);
            })
        }
    }

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };


   

    return{
        state,handleChange, isPending,page,rowsPerPage,handleSubmit,handleCustomSearch,
        count,activePage,customSearch,data,handleChangePage, handleChangeRowsPerPage,
        closeSnackbar,submitted,loading, provider, providers, handleChangeProvider
    }
}

export {useInsuranceSummary}