import React from 'react';
import {useTheme} from "@material-ui/core/styles";

export const ReusableReferralDetails =  ({text, value})=>{
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    return (
        <>
            <table className="table table-borderless prescription-table table-sm mb-0">
                <tr>
                    <td colSpan={2} className={palette.type === 'dark' && 'dark-theme-color'}><p>{text}</p></td>
                </tr>
            </table>
            <table className="table table-bordered table-sm  bill-receipt-table">
                <tr>
                    <td rowSpan={5}><p>{value}</p></td>
                </tr>
            </table>
        </>

    )
}

export const ReusableReferralSubHead = ({referred_from, referred_to}) =>{
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    return (
        <table className="table table-bordered table-sm  bill-receipt-table">
            <tr>
                <td colSpan={2} className={palette.type === 'dark' && 'dark-theme-color'}><p>From: {referred_from}</p></td>
                <td colSpan={3} className={palette.type === 'dark' && 'dark-theme-color'}><p>To: {referred_to}</p></td>
            </tr>
            <tr>
                <td colSpan={2} className={palette.type === 'dark' && 'dark-theme-color'}><p>Title:</p></td>
                <td colSpan={3} className={palette.type === 'dark' && 'dark-theme-color'}><p>Title:</p></td>
            </tr>
            <tr>
                <td colSpan={2} className={palette.type === 'dark' && 'dark-theme-color'}><p>Address:</p></td>
                <td colSpan={3} className={palette.type === 'dark' && 'dark-theme-color'}><p>Address:</p></td>
            </tr>

        </table>
    )
}