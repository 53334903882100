import PageTitle from "../../../Utils/smsTitle";
import { SubHeader } from "../../../../Containers/SubHeader";
import React from 'react';
import Box from '@material-ui/core/Box';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AnteNatalFirstScreen from "./PatientFormScreens/AnteNatalFirstScreen";
import AnteNatalSecondScreen from "./PatientFormScreens/AnteNatalSecondScreen";
import AnteNatalThirdScreen from "./PatientFormScreens/AnteNatalThirdScreen";
import { useTheme } from "@material-ui/core/styles";
import ReusableAnteNatalTabs, { anteNatalRoutes } from './ReusableAnteNatalTabs';
import { dateConvert } from "../../../Utils/ConvertDate";
import { PatientInformation } from "../../../DoctorsModule/PatientInfo/PatientInformation";
import { makeStyles } from "@material-ui/styles";
import { useAnteNatalPatientForm } from './hooks/useAnteNatalPatientForm';
import PrintDialog from "../../../Utils/Dialogs/PrintDialog";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../../../actions/snackbarActions";
import MainSnackbar from "../../../Utils/Snackbar/SmsSnackbar";


/** Customizing the style for the stepper */
const stepStyles = makeStyles(() => ({
  root: {
    "& .MuiStepIcon-active" : {color:"#027a5f"},
    "& .MuiStepIcon-completed": { color: "#027a5f" },
    backgroundColor:"transparent",
    paddingRight: '0px',
    "& .MuiStepConnector-line": {
      display: "block",
      borderColor: "#1fa7b0"
    }
  }
}))

/**
 * Component for returning the Patient Form for the Ante Natal Patient Card
 * @param {*} param0 
 * @returns 
 */
const AnteNatalPatientForm = ({actions, match, snackbars}) => {

  const theme = useTheme();
  const them = !theme ? {} : theme;
  const palette = !them.palette ? {} : them.palette;
  const {patient_number, labrequest_id, scan_request_id, visit_id, patient_type,patient_admission_id, anc_id, anc_status} = match.params;
  const arr = anteNatalRoutes(patient_number, visit_id, labrequest_id, scan_request_id, patient_type,patient_admission_id, anc_id, anc_status);
  const stepCs = stepStyles();

  const user = JSON.parse(sessionStorage.getItem('user'));
  const userObj = !user ? {} : user;
  const token = !userObj.token ? [] : userObj.token;
  const _token = !token[1] ? {} : token[1];
  const doctor_name = `${!_token.first_name ? "" : _token.first_name} ${!_token.last_name ? "" : _token.last_name}`;

  const {
    stepProps, patient, first_screen_props, second_screen_props, third_screen_props, handleReset, resetConfirmation, handleCloseConfirm, openResetConfirmation,
    handleSaveForm, closeSnackbar
  } = useAnteNatalPatientForm(actions, patient_number, anc_id, anc_status, visit_id);

  const {
    allStepsCompleted, handleBack, handleStep, handleComplete,
    isFirstScreen, isSecondScreen, isThirdScreen, activeStep, completed, steps, handleNext
  } = stepProps;

  const {patient_name, age, gender, address, phone} = patient;
  const {openBar, type, message} = snackbars;
    return (
      <div data-testid="ante-natal-patient-form">
        <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>
        <PageTitle title="Ante Natal"/>
        <SubHeader title="Ante Natal" subTitle={`Patient Form`}  doctor_name={doctor_name}>
            <div className="header-icon"><img src="/images/antenatal-blue.png" className="icon-resize surgery-icon-tabs ante-natal-green"/></div>
        </SubHeader>

        {resetConfirmation ?
          <PrintDialog handleCancel={handleCloseConfirm} openDialog={resetConfirmation} handleClose={handleCloseConfirm}
          handleClick={handleReset} message="Reset Patient Form"
          text="Would you like to reset this form?"/> : null
        }

        <ReusableAnteNatalTabs components={arr}>          
          <section style={{display:'flex', marginBottom: '5px'}}>
            <div style={{flexGrow:'4'}}>
            <PatientInformation phone={phone} gender={gender} age={age} patient_name={patient_name} address={address} date={dateConvert()} patient_number={patient_number}/>
            </div>
              <div style={{ justifyContent:'end', width:'20%' }}>
                <Stepper nonLinear activeStep={activeStep} className={stepCs.root}>
                      {steps.map((label, index) => (
                        <Step key={label} completed={completed[index]}>
                          <StepButton color="027a5f" onClick={handleStep(index)}/>
                        </Step>
                      ))}
                </Stepper>
              </div>
          </section>

          <div style={{padding:'20px'}}>

            {isFirstScreen ? 
              <AnteNatalFirstScreen first_screen_props={first_screen_props} handleStep={handleStep}/>
            : isSecondScreen ?
              <AnteNatalSecondScreen second_screen_props={second_screen_props} first_screen_props={first_screen_props} third_screen_props={third_screen_props}/>
            : isThirdScreen ?
              <AnteNatalThirdScreen third_screen_props={third_screen_props}/>
            : null}

            <div>
                {allStepsCompleted() ? (
                  <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                      All steps completed - you&apos;re finished
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button onClick={handleReset}>Reset</Button>
                    </Box>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 , paddingLeft:'15px'}}>
                      <Box sx={{ flex: '1 1 auto' }} />
                      <button className="btn btn-sm sms-info-btn" onClick={openResetConfirmation} style={{marginRight: '30px'}}>Reset</button>
                      <button type="submit" disabled={activeStep === 0} onClick={handleBack} className="btn btn-sm sms-btn">Back</button>
                      {activeStep !== 2 ? 
                      <button onClick={handleNext}  className="btn btn-sm sms-hl-btn" style={{marginLeft: '30px'}}>Next</button>                      
                      :
                      <button onClick={handleSaveForm}  className="btn btn-sm sms-hl-btn" style={{marginLeft: '30px'}}>Save</button>                      
                      }
                    </Box>
                  </React.Fragment>
                )}
            </div>

          </div>
        </ReusableAnteNatalTabs>
      </div>
    )
}


function mapStateToProps(state) {
  return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
  return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(AnteNatalPatientForm);