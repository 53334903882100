import * as types from "../actions/actionTypes";

const initialState = {loading:'idle'};
export default function (state = initialState, action) {
    switch (action.type) {
        case types.ADD_EMPLOYEE_REQUEST:
            return {loading: 'pending'};
        case types.ADD_EMPLOYEE_SUCCESS:
            return {loading: 'resolved'};
        case types.ADD_EMPLOYEE_ERROR:
            return {loading: 'rejected'};
        case types.EDIT_EMPLOYEE_REQUEST:
            return {loading: 'pending'};
        case types.EDIT_EMPLOYEE_SUCCESS:
            return {loading: 'resolved'};
        case types.EDIT_EMPLOYEE_ERROR:
            return {loading: 'rejected'};
        default:
            return state;
    }
}
