import React, {useEffect,  useState} from "react";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CustomTable from "../../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {Link} from "react-router-dom";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import *  as snackbarActions from "../../../actions/snackbarActions";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {config} from "../../../Helpers/env";
import axios from "axios";
import {logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {faPrescriptionBottle} from "@fortawesome/free-solid-svg-icons/faPrescriptionBottle";
import {faShippingFast} from "@fortawesome/free-solid-svg-icons/faShippingFast";
import {Container} from "../../Utils/styledComponents";
import {ReusableRouteTabs} from "../../Utils/Dialogs/ReusableTabs";
import {history} from "../../../Helpers/history";
import { formatDateTime } from "../../Utils/ConvertDate";

const headData = [
    {id: 'sl', numeric: false, disablePadding: false, label: 'SL'},
    {id: 'date', numeric: false, disablePadding: false, label: 'Date#'},
    {id: 'dispatch_id', numeric: false, disablePadding: false, label: 'Dispatch#'},
    {id: 'dispatched_by', numeric: false, disablePadding: false, label: 'Dispatched By'},
    {id: 'action', numeric: false, disablePadding: false, label: 'Action'},
];


const ProductDispatchList = ({actions, snackbars, match:{params}}) => {
    const [loading, setLoading] = useState(false);
    const [dispatchList, setList] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const {component} = params


    const dispatch_status = {
        'new':0,
        'received':1,
        'canceled':9
    }


    useEffect(() => {
        setLoading(true);
        axios.post(`${config.smsUrl}/cinventory/pharmacy_dispatch_list`,{status:dispatch_status[component], to_store:''}).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const cats = !dt.data ? [] : dt.data;
            setList(cats);
            setLoading(false);
        }).catch((err) => {
            logoutErrorMessage(err, null, actions);
            setLoading(false);
        })
    }, [component]);



    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };




    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };




    const dispatches = !dispatchList ? [] : dispatchList;
    const {openBar, type, message} = snackbars;


    const components = [{label: 'New Dispatches', path: '/dispatchlist/new'},
        {label: 'Received Dispatches', path: '/dispatchlist/received'},
        {label: 'Cancelled Dispatches', path: '/dispatchlist/canceled'}]



    return (
        <div data-testid="dispatch-list">
            <PageTitle title="Dispatch List"/>
            <SubHeader title="Inventory" subTitle="Dispatch List">
                <FontAwesomeIcon icon={faShippingFast}/>
            </SubHeader>
            <Container>
                <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>
                <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/>
                <CustomTable title={`${component === 'new' ? 'New' : component === 'received' ? 'Received' : 'Canceled'} Dispatches`}  
                            records={10}
                             headData={headData}  data={dispatches} colSpan={5}
                             handleChangePage={handleChangePage}
                             handleChangeRowsPerPage={handleChangeRowsPerPage}
                             page={page} rowsPerPage={rowsPerPage}
                             id="searchValue" filter >
                    {loading ? <TableBody><TableRow>
                        <TableCell colSpan="6" align="center"><img src="/images/smsloader.gif"
                                                                   alt="loader"/></TableCell>
                    </TableRow></TableBody> : dispatches.length > 0 ?
                        <TableBody>
                            {dispatches.slice(0, 10)
                                .map((item, index) => {
                                    const count = (page * rowsPerPage) + index + 1;
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={index}
                                            className='mui-table-row'>
                                            <TableCell>{count}</TableCell>
                                            <TableCell>{formatDateTime(item.date_dispacthed)}</TableCell>
                                            <TableCell>{item.product_dispacth_id}</TableCell>
                                            <TableCell>{`${item.first_name} ${item.last_name}`}</TableCell>

                                            <TableCell>
                                                <Link
                                                    to={{pathname: `/dispatchnote/${item.product_dispacth_id}`}}>
                                                    <button className="btn btn-sm sms-info-btn mr-1">View</button>
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                        : <TableBody><TableRow>
                            <TableCell colSpan="6" align="center">No records found</TableCell>
                        </TableRow></TableBody>}
                </CustomTable>
            </Container>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductDispatchList);
