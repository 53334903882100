import React, {useEffect} from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import * as snackbarActions from "../../../actions/snackbarActions"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {BackButton} from "../../Utils/Buttons/DataExportationButton";
import {Container} from "../../Utils/styledComponents"
import axios from "axios";
import { config } from "../../../Helpers/env";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {CustomTableCell, MuiPaper, StickyHeadTable} from "../../Utils/Tables/StickyHeaderTable";
import {LoadingGif} from "../../Utils/Loader";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClipboardList} from "@fortawesome/free-solid-svg-icons/faClipboardList";


const headData = [
    {id: 'product_name', numeric: false, disablePadding: false, label: 'Product'},
    {id: 'strength', numeric: false, disablePadding: false, label: 'Strength'},
    {id: 'batch_id', numeric: false, disablePadding: false, label: 'Batch#'},
    {id: 'expiry_date', numeric: false, disablePadding: false, label: 'Expiry Date'},
    {id: 'in_quantity', numeric: false, disablePadding: false, label: 'In Quantity'},
    {id: 'sold_quantity', numeric: false, disablePadding: false, label: 'Sold Quantity'},
    {id: 'stock', numeric: false, disablePadding: false, label: 'Stock'},

];



const StockBatchWiseReport = ({actions, snackbars}) => {
    const [data, setData] = React.useState({debtors:[], loading:'idle'});
    const [state, setState] = React.useState({from_date:'', to_date:''});
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [submitted, setSubmitted] = React.useState(false);
    const [isSubmitted, setIsSubmitted] = React.useState('idle');
    const {debtors, loading} = data

    const {from_date, to_date} = state

    const isResolved = isSubmitted === 'resolved'



    useEffect(() => {
        setData({...data, loading: 'pending'})
        axios.get(`${config.smsUrl}/Creport/stock_summary`).then(res => {
            const response = res.data;
            const dt = response ? response : {};
            const arr = dt.data ? dt.data : []
            setData({debtors: arr, loading: 'success'})
        }).catch(err => {
            errorMessages(err, null, actions)
            setData({...data, loading: 'error'})

        })
    }, [isResolved])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleChange = (event) =>{
        const {value, name} = event.target;
        setState({...state,[name]:value})
    }

    const closeSnackbar = () =>{
        actions.snackbarActions.hideSnackbar();
    };

    const handleSubmit = (event) =>{
        event.preventDefault()
        setSubmitted(true)
        if (from_date && to_date){
            setIsSubmitted('resolved')
        }
    }


    const isLoading = loading === 'pending'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'

    const {openBar, type, message} = snackbars;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, debtors.length - page * rowsPerPage);

    return (
        <div>
            <PageTitle title="Stock Batch-wise Report"/>
            <SubHeader title="Reports" subTitle="Stock Batch-wise Report">
                <FontAwesomeIcon icon={faClipboardList}/>
            </SubHeader>
            <Container>
                <div className='mb-3'>
                    <BackButton text='General Reports' to='/reports/generalreports'/>
                </div>
                <MainSnackbar open={openBar} message={message} variant={type} handleCloseBar={closeSnackbar}/>
                <MuiPaper>
                <StickyHeadTable {...{width:'100%', data:debtors, columns:headData.filter(item=>Boolean(item))}}>
                    {isLoading ? <TableRow><TableCell align='center' {...{colSpan:headData.length}}><LoadingGif/></TableCell></TableRow>:null}
                    {isSuccess ? debtors.length > 0 ?
                        <>
                            {debtors.map((row, index) => {
                                return (
                                    <TableRow  tabIndex={-1} key={index}>
                                        <CustomTableCell>{row.product_name}</CustomTableCell>
                                        <CustomTableCell>{row.strength}</CustomTableCell>
                                        <CustomTableCell>{row.batch_id}</CustomTableCell>
                                        <CustomTableCell>{row.expiry_date}</CustomTableCell>
                                        <CustomTableCell>{row.in_quantity}</CustomTableCell>
                                        <CustomTableCell>{row.sold_quantity}</CustomTableCell>
                                        <CustomTableCell>{row.stock}</CustomTableCell>
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 43 * emptyRows }}>
                                    <TableCell colSpan={headData.length} />
                                </TableRow>
                            )}
                        </>:<TableRow><TableCell align='center'  {...{colSpan:headData.length}}>No records found</TableCell></TableRow>:null}
                    {isError ? <TableRow><TableCell align='center'  {...{colSpan:headData.length}}>The server did not return a valid response</TableCell></TableRow>:null}
                </StickyHeadTable>
                </MuiPaper>
              
            </Container>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StockBatchWiseReport);
