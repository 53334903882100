import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Label from "../Utils/FormInputs/Label";
import Col from "react-bootstrap/Col";
import TextField from "../Utils/FormInputs/TextField";
import { ErrorMessage } from "../Utils/styledComponents";

const AddShiftCategory = ({ handleChange, handleSubmit, state, submitted, isSubmitted,}) => {

    const {start_period, end_period, shift_name} = state;
    return (
        <form onSubmit={handleSubmit} autoComplete="off">


                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                                <Label  name="Shift Name" htmlFor='shift_name' type/>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6}>

                                <TextField  {...{submitted}} value={shift_name} onChange={handleChange} type="text"
                                            name="shift_name" id='shift_name' placeholder="Enter shift name eg Day Shift"
                                            data-testid="shift_name"/>
                                {(submitted && !shift_name) &&
                                    <ErrorMessage id="status_help_block">Shift Name is required</ErrorMessage>}

                            </Col>

                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                                <Label  name="Period" htmlFor='period' type/>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                <Row>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <TextField  {...{submitted}} value={start_period} onChange={handleChange} type="time"
                                                   name="start_period" id='period' placeholder="Start Time"
                                                   data-testid="start-period"/>
                                        {(submitted && !start_period) &&
                                        <ErrorMessage id="status_help_block">Start Time is required</ErrorMessage>}

                                    </Col>

                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <TextField  {...{submitted}} value={end_period} onChange={handleChange} type="time"
                                                   name="end_period"
                                                   id='period'
                                                   placeholder="End Time"/>
                                        {(submitted && !end_period) &&
                                        <ErrorMessage id="status_help_block">End Time is required</ErrorMessage>}
                                    </Col>
                                </Row>
                            </Col>
                        </Form.Group>
                        <div className="text-right mr-5">
                          
                            <button type="submit" className="btn btn-sm sms-btn px-4" disabled={isSubmitted === 'pending'}
                                    data-testid="submit-shift-category">
                                {isSubmitted === 'pending' ? "Saving..." : "Save"}</button>
                        </div>
                    </form>
    )
};


export default AddShiftCategory;
