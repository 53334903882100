import * as React from 'react';
import axios from "axios";
import { config } from "../../../Helpers/env";
import {history} from "../../../Helpers/history";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {useState} from "react";


function useTriageWaitingList(actions) {
    const [term, setTerm] = React.useState('');
    const [waitingList, setWaitingList] = React.useState([]);
    const [loading, setLoading] = React.useState('idle');
    const [activePage, setActivePage] = React.useState(1);
    const [total_count, setTotalCount] = React.useState(0)
    const [vitalStatus, setVitalStatus] = useState('idle')

    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;




    React.useEffect(()=>{
        setLoading('pending')
        const formData = new FormData();
        formData.append('search', term);
        axios.post(`${config.smsUrl}/cvisit/triage_waiting_list/${activePage}`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const list = !dt.triage_list ? [] : dt.triage_list;
            const count = !dt.total_count ? 0 : dt.total_count;
            const t_list = list.map((appointment, index) => ({
                ...appointment,
                count: ((activePage - 1) * 10) + (index + 1),
                visit_id: appointment.visit_id,
                patient_number: appointment.patient_number,
                date: appointment.begin_datetime,
                patient_name: `${appointment.first_name} ${appointment.last_name}`,
                department_name: appointment.department_name,
                status: appointment.activity_status
            }))
            setWaitingList(t_list);
            setTotalCount(count);
            setLoading('success')
        }).catch((err) => {
            logoutErrorMessage(err, null, actions)
            setLoading('error')
        })

    }, [term, activePage])


    const handleChangeActivePage = (page) => {
        setActivePage(page)
    };


    const handler = (event) => {
        setTerm(event.target.value)
    }


    const handleClickRow = (activity_id,visit_id, patient_number) => {
        setVitalStatus('pending')
        const formData = new FormData()
        formData.append('visit_id',visit_id)
        formData.append('activity_id',activity_id)
        formData.append('visit_state',2)
        formData.append('user_roles_id',user_roles_id)
        axios.post(`${config.smsUrl}/cvisit/update_visit_activity`,formData).then(() => {
            setVitalStatus('resolved')
            history.push(`/triage/${activity_id}/${visit_id}/${patient_number}`)
        }).catch(err => {
            errorMessages(err, null, actions)
            setVitalStatus('rejected')
        })

    };

    const closeSnackBar = () => {
        actions.snackbarActions.hideSnackbar();
    };

    const handleUnAuthorizedClick = () => {
        history.push('/triagewaitinglist')
    };

    const isLoading = loading === 'pending';
    const isSuccess = loading === 'success';
    const isError = loading === 'error';

    const isPending = vitalStatus === 'pending'


    return {waitingList, loading, total_count, term, activePage, handler,
        handleChangeActivePage, handleClickRow, closeSnackBar,handleUnAuthorizedClick,
        isLoading, isSuccess, isError, isPending
    }

}

export {useTriageWaitingList}