import {useEffect, useState} from "react";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {titleCase} from "../../Users/addUser";
import { useBedTransfer } from "./useBedTransfer";


export const useBedTransferList = (props) =>{
    const [searchValue, setSearchValue] = useState('');
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('sl');
    const [bed_transfer_id, setId] = useState('');
    const [openDelete, setDelete] = useState(false);
    const [transferList, setList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const [count, setCount] = useState(0);
    const {actions} = props;
    const {isResolved, transferProps} = useBedTransfer(actions)

    useEffect(() => {
        setLoading(true);
        const formData = new FormData();
        const search = searchValue ? titleCase(searchValue) :searchValue
        formData.append('search', search);
        axios.post(`${config.smsUrl}/cbedmanager/bed_tansfer_list/${activePage}`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const list = !dt.bed_transfer ? [] : dt.bed_transfer;
            const t_count = !dt.total_count ? 0 : dt.total_count;
            setLoading(false);
            setCount(t_count);
            setList(list)
        }).catch(e => {
            logoutErrorMessage(e, null, actions);
            setLoading(false)
        })
    }, [activePage, searchValue, isResolved]);


    const handleSearch = (event) => {
        setSearchValue(event.target.value);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangeNewPage = (page) => {
        setActivePage(page);
    };

    const handleOpenDelete = (id) => {
        setDelete(true);
        setId(id)
    };

    const handleCloseDelete = () => {
        setDelete(false)
    };

    const handleDelete = () => {
        const formData = new FormData();
        formData.append('bed_transfer_id', bed_transfer_id);
        axios.post(`${config.smsUrl}/cbedmanager/delete_bed_tranfer`, formData).then(() => {
            const bds = transferList.filter(bd => bd.bed_transfer_id !== bed_transfer_id);
            setList(bds);
            setDelete(false);
            actions.snackbarActions.snackSuccess('Bed transfer deleted successfully');
        }).catch(e => {
            errorMessages(e, null, actions);
            setDelete(false)
        });
    };

    const closeSnackBar = () => {
        const {actions} = props;
        actions.snackbarActions.hideSnackbar()
    };

    return {searchValue, order, orderBy, openDelete, transferList, loading, activePage, count,
        handleSearch, handleRequestSort, handleChangeNewPage, handleOpenDelete, handleCloseDelete,
        handleDelete, closeSnackBar, bed_transfer_id,  transferProps}
}