import jsPDF from "jspdf";
import "jspdf-autotable";
import hospitalAddress from '../../HospitalAddress.json'
import mpekehmis from "./images/mpekehmis.png"


function toDataURL(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
        var reader = new FileReader();
        reader.onloadend = function() {
            callback(reader.result);
        }
        reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
}




export const exportPDF = (heading, column_headers, information, download_title, footer) => {

    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape


    const marginLeft = 20;
    const doc = new jsPDF(orientation, unit, size);
    // doc.setFontSize(11);
    const title = heading;
    toDataURL(mpekehmis, function(base64Img) {
        let content = {
            startY: 180,
            margin: 3,
            head: column_headers,
            headStyles:{fontStyle: 'bold'},
            body: information,
            // didDrawPage: function (data) {
            //     if (base64Img) {
            //         doc.addImage(base64Img, 'PNG', data.settings.margin.left, 15, 400, 80)
            //     }
            // },
            foot: !footer ? [[]] : footer,
            showFoot: 'lastPage',
            footStyles: {fillColor: '#fff', textColor: '#000000DE', fontStyle: 'bold'},
        };

        doc.text(title, marginLeft,130);
        doc.autoTable({body:[[{content: doc.addImage(base64Img, 'PNG', 15, 15, 400, 150),colSpan:10}]],
            headStyles: {cellPadding:0}, theme:'plain'})
        doc.autoTable(content);
        doc.save(download_title);
    })



};
