import React from "react";
import {ReferralTextArea, ReferralTextField, ReferralTextInput} from "./MainReferralNote";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {bindActionCreators} from "redux";
import {connect} from "react-redux"
import * as snackbarActions from "../../../actions/snackbarActions";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import PrintDialog from "../../Utils/Dialogs/PrintDialog";
import {useRecordIncomingReferral} from "./hooks/useRecordIncomingReferral";

const RecordInternalReferral = (props) => {

    const {patient_name, patient_number, handleReferralDialog, snackbars} = props;
    const {openBar, type, message} = snackbars;

    const {
        state, submitted, isSubmitted, handleChange, company_name,
        handleSubmit, handleCloseReferral, handleClick, openPrint,
        handleReset, handleCloseSnackbar
    } = useRecordIncomingReferral(props, 'incoming', patient_number, handleReferralDialog);

    const {referral_date,  from_hospital, visit_date, patient_history, diagnosis, treatment} = state;

    return (
        <div className='p-3'>
            <form id="save_form" onSubmit={handleSubmit} autoComplete="off">
                <div className="row">
                    <PrintDialog message="View Referral Note" openDialog={openPrint} handleClose={handleCloseReferral}
                                 text="Would you like to view and print this referral note" handleClick={handleClick}
                                 handleCancel={handleCloseReferral}/>
                    <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={handleCloseSnackbar}/>
                    <div className="col-md-7 mt-4  ">
                        <ReferralTextField submitted={submitted} value={referral_date} labelSm="4" colSm="8"
                                           name="referral_date" dataTest="referral_date" type="date"
                                           labelName="Date of Referral" disabled={true}/>
                        <div style={{marginTop:'5px'}}/>
                        <ReferralTextInput onChange={handleChange} value={from_hospital} colSm="8"
                                           labelSm="4" name="from_hospital" type="text" labelName="FROM: "
                                           dataTest="from_hospital" required={true} submitted={submitted}/>
                        <ReferralTextInput value={company_name} colSm="8" disabled={true}
                                           labelSm="4" name="from_hospital" type="text" labelName="TO: "/>
                        <Form.Group as={Row}  className="referral-form-group">
                            <Form.Label column sm={"4"}  className="sms-form-label">REFERENCE:</Form.Label>
                            <Col sm={"8"}/>
                        </Form.Group>
                        <ReferralTextInput value={patient_number ? patient_number : ''} colSm="8"
                                           labelSm="4" type="text" labelName="Patient Number:"
                                           dataTest="patient_number" disabled={true}/>
                        <ReferralTextInput value={patient_name} colSm="8"
                                           labelSm="4" type="text" labelName="Patient Name:"
                                           dataTest="patient_name" disabled={true}/>
                        <ReferralTextInput onChange={handleChange} value={visit_date} colSm="8"
                                           labelSm="4" name="visit_date" type="date" labelName="Date of visit"
                                           dataTest="visit_date"/>
                    </div>
                    <div className="col-md-5 mt-3">
                        <ReferralTextArea value={patient_history} onChange={handleChange} name="patient_history"
                                          labelName="Reason"
                                          dataTest="history"/>
                        <ReferralTextArea value={diagnosis} onChange={handleChange} name="diagnosis" 
                                          labelName="Diagnosis" submitted={submitted} required={true}
                                          dataTest="diagnosis"/>
                        <ReferralTextArea value={treatment} onChange={handleChange} name="treatment"
                                          labelName="Treatment Given"
                                          dataTest="treatment"/>
                    </div>
                </div>
                <div className="text-right mr-5">
                    <button type="button" className="btn btn-sm sms-grey-btn px-4 mr-2" onClick={handleReset}
                            id="reset_form">Reset
                    </button>
                    <button type="submit" className="btn btn-sm sms-btn px-4"
                            disabled={!!isSubmitted}>{isSubmitted ? "Saving..." : "Save"}</button>
                </div>
            </form>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbar
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(RecordInternalReferral);
