import React, {useEffect} from "react";
import {Route, Router, Switch} from "react-router-dom";
import Login from "./Users/Login";
import {history} from "../Helpers/history";
import * as alertActions from "../actions/alertActions";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import 'react-datepicker/dist/react-datepicker.css';
import axios from "axios";
import SideBar from "../Containers/SideBar";
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import {useDarkMode} from "./DarkModeHook";
import ForgotPassWord from "./Users/ForgotPassWord";
import PasswordReset from "./Users/PasswordReset";
import {ErrorFallback} from "../PrivateRoute";
import {ErrorBoundary} from "react-error-boundary";
import styled from "@emotion/styled/macro";
import {AppProviders} from "../Context";
import { VersionsProvider } from "../Context/versions-context";


const themeObject = {
    palette: {type: 'light'}
};

const Container = styled.div({
    height: '100vh',
    display: 'flex',
    flexDirection: 'column'
})

const StyledFooter = styled.footer({
    marginTop: 'auto',
    borderTop: '1px solid #cbcbcb',
    padding:'16px 0'
})
export const App = (props) => {
    const [theme, toggleDarkMode] = useDarkMode(themeObject);
    const themeConfig = createMuiTheme(theme);

    axios.interceptors.request.use((config) => {
        const user = JSON.parse(sessionStorage.getItem('user'));
        const userObj = !user ? {} : user;
        const token = !userObj.token ? [] : userObj.token;
        config.headers.token = !token[0] ? "" : token[0];
        return config;
    });


    useEffect(() => {
        history.listen((location, action) => {
            props.actions.alertActions.alertClear();
        });
    }, [props.actions.alertActions]);


    // $(window).on('load', function () {
    //   var pre_loader = $('#preloader');
    //   pre_loader.fadeOut('slow', function () {
    //     $(this).remove();
    //   });
    // });

    const {palette} = theme;
    return (

            <Container>
                <Router history={history}>
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <VersionsProvider>
                        <Switch>
                            <Route path="/login" component={Login}/>
                            <Route path="/forgotpassword" component={ForgotPassWord}/>
                            <Route path="/reset/:reset_token" component={PasswordReset}/>
                                <ThemeProvider theme={themeConfig}>
                                    <AppProviders>
                                        <SideBar type={palette.type} onClick={toggleDarkMode} palette={palette}/>
                                    </AppProviders>
                                </ThemeProvider>
                        </Switch>
                    </VersionsProvider>
                    </ErrorBoundary>
                </Router>
                <StyledFooter>
                    <p className="text-center">&copy; Mara Scientific <span
                        style={{marginLeft: 5, marginRight: 5}}>|</span> Mpeke HMIS</p>
                </StyledFooter>
            </Container>
    );
};

function mapStateToProps(state) {
    return {
        alert: state.alerts,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            alertActions: bindActionCreators(alertActions, dispatch)
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
