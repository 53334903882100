import * as React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import styled from "@emotion/styled/macro";
import * as colors from '../../../styles/colors'
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {ErrorStar} from "../styledComponents";
import * as mq from "../../../styles/media-queries"




const ListContainer = styled.div({
    border:`0.78px solid ${colors.gray00}`,
    marginBottom:'10px'
})
const ItemIcon = styled(ListItemIcon)({
    minWidth:'5px'
})

const StyledList = styled(List)({
    paddingTop:0,
    paddingBottom:0,

})

const ItemButton = styled(ListItem)({
    '&::-webkit-tap-highlight-color':{
        background: colors.base
    },
    ':hover':{
        background:colors.gray001
    },
    paddingLeft:'6px',
    paddingRight:'6px',
    background:colors.gray001
})

const ItemText = styled(ListItemText)({
    '& .MuiTypography-root':{
        fontSize:'14px',
        // fontWeight:700

    }

})

const StyledCell = styled(TableCell)({
    padding:'6px 13px',
    fontSize:'14px'
})

const HeadCell = styled(StyledCell)({
    borderTop:`0.78px solid ${colors.gray00}`,
    borderBottom:`0.78px solid ${colors.gray00}`,
    background:colors.gray001,
    fontWeight: 700,
    padding:'6px 13px'
})

const widths = {
    "500":{
        [mq.small]:{
            minWidth:'500px'
        },
        [mq.medium]:{
            minWidth:'500px'
        },
        [mq.large]:{
            minWidth: "500px"
        },
        [mq.larger]:{
            minWidth: "500px"
        },
        [mq.extraLarge]:{
            minWidth: "500px"
        }


    },
    "300":{
        [mq.small]:{
            minWidth:'300px'
        },
        [mq.medium]:{
            minWidth:'300px'
        },
        [mq.large]:{
            minWidth: "300px"
        },
        [mq.larger]:{
            minWidth: "300px"
        },
        [mq.extraLarge]:{
            minWidth: "300px"
        }
    },
    "250":{
        [mq.small]:{
            minWidth:'250px'
        },
        [mq.medium]:{
            minWidth:'250px'
        },
        [mq.large]:{
            minWidth: "250px"
        },
        [mq.larger]:{
            minWidth: "250px"
        },
        [mq.extraLarge]:{
            minWidth: "250px"
        },
    },
    "200":{
        [mq.small]:{
            minWidth:'200px'
        },
        [mq.medium]:{
            minWidth:'200px'
        },
        [mq.large]:{
            minWidth: "200px"
        },
        [mq.larger]:{
            minWidth: "200px"
        },
        [mq.extraLarge]:{
            minWidth: "200px"
        }

    },
    "150":{
        [mq.small]:{
            minWidth:'150px'
        },
        [mq.medium]:{
            minWidth:'150px'
        },
        [mq.large]:{
            minWidth: "150px"
        },
        [mq.larger]:{
            minWidth: "150px"
        },
        [mq.extraLarge]:{
            minWidth: "150px"
        }

    },
    "100":{
        [mq.small]:{
            minWidth:'100px'
        },
        [mq.medium]:{
            minWidth:'100px'
        },
        [mq.large]:{
            minWidth: "100px"
        },
        [mq.larger]:{
            minWidth: "100px"
        },
        [mq.extraLarge]:{
            minWidth: "100px"
        }


    },
    "50":{
        [mq.small]:{
            minWidth:'50px'
        },
        [mq.medium]:{
            minWidth:'50px'
        },
        [mq.large]:{
            minWidth: "50px"

        },
        [mq.larger]:{
            minWidth: "50px"
        },
        [mq.extraLarge]:{
            minWidth: "50px"
        },
    },

}
const DataCell = styled(StyledCell)({
    borderBottom:0
},({variant})=>widths[variant])




function ModuleTable({children, extraCell=null,extraRow=null, headData=[]}) {
    return (
        <TableContainer>
            <Table aria-label="module table">
                <TableHead>
                    {extraRow}
                    <TableRow>
                        {extraCell}
                        {headData.map((item, index)=>(
                            <HeadCell key={index}>{item.item}{item.isRequired ? <ErrorStar>*</ErrorStar>:null} </HeadCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {children}
                </TableBody>
            </Table>
        </TableContainer>
    );
}


function CollapsibleList({handleOpen, children, name, open,  button=null, user=null}) {
    return (
        <ListContainer>
            <StyledList
                sx={{ width: '100%'}}
                component="nav"
                aria-labelledby="collapsible-list"
            >
                <ItemButton  button>
                    <ItemIcon onClick={handleOpen}>
                        {open ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
                    </ItemIcon>
                    <ItemText  primary={name}  />
                    {user}
                    {button}
                </ItemButton>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    {children}
                </Collapse>
            </StyledList>
        </ListContainer>
    );
}
export {CollapsibleList, DataCell, ModuleTable, ItemText, ItemIcon, HeadCell}
