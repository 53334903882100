import React from 'react'
import {Paragraph } from '../styledComponents';
import styled from '@emotion/styled/macro';
import * as colors from '../../../styles/colors'
import { today } from '../ConvertDate';



export const TitleContainer = styled.div({
  display:'flex',
  alignItems:'center'
})

export const Paragraph1 = styled(Paragraph)({
  marginRight:'17.84px',
  color:colors.blue001,
})

export const Paragraph2 = styled(Paragraph)({
  color:colors.muted001
})

const LedgerParagraph =  styled(Paragraph)({
  color:colors.text00,
  fontSize:'25px'
})





function MiniHeader({title}) {
    const current_date = today()
  return (
          <TitleContainer>
            <Paragraph1 variant='p1'>{title}</Paragraph1>
            <Paragraph2 variant='p2'>{current_date}</Paragraph2>
          </TitleContainer>
  )
}

function AccountingMiniHeader({title}) {
return (
      <LedgerParagraph variant='p1'>{title}</LedgerParagraph>    
)
}

export {MiniHeader,AccountingMiniHeader, LedgerParagraph}