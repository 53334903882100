import React, {useState, useEffect, useRef} from 'react';
import PageTitle from "../Utils/smsTitle";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCoins} from "@fortawesome/free-solid-svg-icons/faCoins";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import ReactToPrint from "react-to-print";
import Card from "@material-ui/core/Card";
import ReceiptTemplate from "../Utils/ReceiptTemplate";
import axios from "axios";
import { config } from "../../Helpers/env";
import {logoutErrorMessage} from "../../Helpers/ErrorMessages";
import {useTheme} from "@material-ui/core/styles";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../actions/snackbarActions";
import {connect} from "react-redux";
import {convertGmtTime} from "../Utils/ConvertDate";
import {titleCase} from "../Users/addUser";


const ViewBedTransfer = ({actions, snackbars, match}) => {
    const [data, setData] = useState({});
    const componentRef = useRef();
    const {bed_transfer_id} = match.params;
    useEffect(() => {
        const formData = new FormData();
        formData.append('bed_transfer_id', bed_transfer_id);
        axios.post(`${config.smsUrl}/cbedmanager/bed_transfer_update_form`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            setData(dt)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, []);


    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    const {openBar, type, message} = snackbars;
    const transferDetails = data?.to_bed_detials ?? '';
    const arr = transferDetails.split('-')
    return (
        <div>
            <PageTitle title="Creditor Details"/>
            <SubHeader title="Billing" subTitle="Creditor Billing Details">
                <FontAwesomeIcon icon={faCoins}/>
            </SubHeader>
            <section className="billing-details">
                <MainSnackbar variant={type} handleCloseBar={closeSnackbar} open={openBar} message={message}/>
                <div className="text-right">
                    <ReactToPrint content={() => componentRef.current}
                                  trigger={() => <button className="btn sms-amber-btn mb-2">Print</button>}/>
                </div>
                <Card className="p-2 billing-receipt" ref={componentRef} id="prescription-print">
                    <ReceiptTemplate>
                        <table className="table table-sm table-borderless prescription-table">
                            <tr>
                                <td className={palette.type === 'dark' && 'dark-theme-color'}><p>Patient
                                    No: <strong>{data.patient_number}</strong></p></td>
                                <td className={palette.type === 'dark' && 'dark-theme-color'}>
                                    <p>Admission No.: <strong>{" "} {data.patient_admission_id}</strong></p></td>
                            </tr>
                            <tr>
                                <td className={palette.type === 'dark' && 'dark-theme-color'}><p>Patient
                                    Name: <strong>{`${!data.first_name ? "" : data.first_name} 
                            ${!data.last_name ? "" : data.last_name}`}</strong></p></td>
                                <td className={palette.type === 'dark' && 'dark-theme-color'}>
                                    <p>Transferred by: <strong>{titleCase(data.transfered_by_name)}</strong></p></td>
                            </tr>
                            <tr>
                                <td className={palette.type === 'dark' && 'dark-theme-color'}>
                                    <p>Gender: <strong>{titleCase(data.gender)}</strong></p></td>
                            </tr>
                            <tr>
                                <td className={palette.type === 'dark' && 'dark-theme-color'}>
                                    <p>Address: <strong>{" "} {data.address}</strong></p></td>
                            </tr>
                        </table>
                        <div
                            className={`bill-patient-details mb-3 ${palette.type === "dark" && "dark-bill-patient-details"}`}
                            id="patient-lab-report"/>
                        <table className="table table-borderless">
                            <tr>
                                <td colSpan="2">
                                    <div className="table-responsive">
                                        <table
                                            className="table table-bordered table-sm profile-table bill-receipt-table">
                                            <thead>
                                            <tr>
                                                <td><p><strong>Date</strong></p></td>
                                                <td><p><strong>From Ward</strong></p></td>
                                                <td><p><strong>To Ward</strong></p></td>
                                                <td><p><strong>From Room</strong></p></td>
                                                <td><p><strong>To Room</strong></p></td>
                                                <td><p><strong>From Bed</strong></p></td>
                                                <td><p><strong>To Bed</strong></p></td>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td><p>{convertGmtTime(data.date_transfered)}</p></td>
                                                <td><p>{data.from_ward}</p></td>
                                                <td><p>{data.to_ward_name}</p></td>
                                                <td><p>{data.from_room}</p></td>
                                                <td><p>{data.to_room_name}</p></td>
                                                <td><p>{data.from_bed_number}</p></td>
                                                <td><p>{data.to_bed_number}</p></td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </td>

                            </tr>
                        </table>
                    </ReceiptTemplate>
                </Card>
            </section>
        </div>
    )
}

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewBedTransfer);
