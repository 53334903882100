import {useEffect, useState} from "react";
import axios from "axios";
import { config } from "../../../../../Helpers/env";
import {logoutErrorMessage} from "../../../../../Helpers/ErrorMessages";

export const useANCMedicalHistory = (actions, patient_number) =>{
    const options =  [{label:'Triage', value:'triage'},{label:'Antenatal', value:'antenatal'},
        {label:'Laboratory Result(s)', value:'lab'},{label:'Radiology Report(s)', value:'radiology'},
        {label:'Diagnosis', value:'diagnosis'},{label:'Prescription', value:'prescription'}]

    const [patient, setPatient] = useState({});
    const [visits, setVisits] = useState([]);
    const [visitDetails, setVisitDetails] = useState({labResults:[],radiologyResults:[], patientDiagnosis:[],
        medication:[], clinicalNotes:[], patientTriage:[], visit_date:'', visit_type:'', antenatal_data:{},chronic_illness_dt:[]})
    const [openPrint, setOpenPrint] = useState(false)

    const [option, setOption] = useState([{label:'Triage', value:'triage'},{label:'Antenatal', value:'antenatal'}])

    const tabs = [{value:'Triage', component:'triage',isActive:true},{value:'Antenatal', component:'antenatal'},
        {value:' Clinical Notes', component:'notes'},
        {value:'Laboratory', component:'lab'},{value:'Radiology', component:'radiology'},
        {value:'Diagnosis', component:'diagnosis'},{value:'Prescription', component:'prescription'}]


    const endPoints = [
        `${config.smsUrl}/cdoctor/get_all_patient_treatment_details`,
        `${config.smsUrl}/antenatal/view_antenatal_details`
    ]

    function sortArr(arr=[]) {
        return arr.sort((a, b)=>new Date(b.begin_datetime) - new Date(a.begin_datetime))
    }

    const retrieveVisitDetails = (v_id) =>{
        const formData = new FormData();
        formData.append('visit_id', v_id);
        Promise.all(endPoints.map((endpoint) => axios.post(endpoint, formData)))
            .then(([{data: visitData}, {data: antenatalData}] )=> {
                //visit Data
                const details = !visitData ? {} : visitData;
                const {lab_result,scan_result, diagnosis,prescription,other_medicine,exam_notes,triage} = details
                const presc = prescription ? prescription : [];
                const others = other_medicine ? other_medicine : [];
                const other_meds = others.filter(item=>Boolean(item));
                const medicationArr = [...presc, ...other_meds];
                const labArr = lab_result?.map(item=>({...item, open:true}))
                const scanArr = scan_result?.map(item=>({...item, open:true}))

                //antenatal data
                const antenatal_details = antenatalData ? antenatalData : {}
                const arr = visits.map((item)=> {
                    if (item.visit_id === v_id) {
                        return {
                            ...item, components: tabs, activeItem: 'triage', open: !item.open,
                            labResults: labArr,
                            radiologyResults: scanArr,
                            patientDiagnosis: diagnosis,
                            medication: medicationArr,
                            patientTriage: triage,
                            clinicalNotes: exam_notes,
                            antenatal_data:antenatal_details,
                            chronic_illness_dt:[]
                        }
                    }
                    return item
                })
                setVisits(arr)
        })
    }


    useEffect(() => {
        if(!patient_number){
            return;
        }
        const formData = new FormData();
        formData.append('patient_number', patient_number);
        axios.post(`${config.smsUrl}/antenatal/patient_anc_visits`, formData).then(res => {
            const data = res.data;
            const visit = !data ? [] : data;
            const visitsArr = sortArr(visit)
            const singleVisit = visitsArr[0] ? visitsArr[0] :{};
            const v_id = singleVisit.visit_id ? singleVisit.visit_id : ''
            const formData = new FormData();
            formData.append('visit_id', v_id);
            Promise.all(endPoints.map((endpoint) => axios.post(endpoint, formData)))
                .then(([{data: visitData}, {data: antenatalData}] )=> {
                    //visit Data
                    const details = !visitData ? {} : visitData;
                    const {lab_result,scan_result, diagnosis,prescription,other_medicine,exam_notes,triage} = details
                    const presc = prescription ? prescription : [];
                    const others = other_medicine ? other_medicine : [];
                    const other_meds = others.filter(item=>Boolean(item));
                    const medicationArr = [...presc, ...other_meds];
                    const labArr = lab_result?.map(item=>({...item, open:true}))
                    const scanArr = scan_result?.map(item=>({...item, open:true}))

                    //antenatal data
                    const antenatal_details = antenatalData ? antenatalData : {}
                    const arr = visitsArr.map((item, index)=> {
                        if (item.visit_id === v_id) {
                            return {
                                ...item, components: tabs, activeItem: 'triage', open: index === 0,
                                labResults: labArr,
                                radiologyResults: scanArr,
                                patientDiagnosis: diagnosis,
                                medication: medicationArr,
                                patientTriage: triage,
                                clinicalNotes:  exam_notes.map(note=>({
                                    ...note, openNote:true
                                })),
                                antenatal_data:antenatal_details,
                                chronic_illness_dt:[]
                            }
                        }
                        return item
                    })
                    setVisits(arr)
                });
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, []);



    useEffect(() => {
        if(!patient_number){
            return;
        }
        const formData = new FormData();
        formData.append('patient_number', patient_number);
        axios.post(`${config.smsUrl}/cpatient/patient_update_form`, formData).then(res => {
            const data = res.data;
            const details = !data ? {} : data;
            setPatient(details)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, [patient_number]);



    const handleOpenResults = (visit_id,index,arr,item_name) =>{
        const visitArr = visits.map((item)=>{
            if (item.visit_id === visit_id){
                const openedArr = arr.map((child, idx)=>{
                    if(idx === index){
                        return {...child, open:!child.open}
                    }else {
                        return child
                    }
                })
                return {...item, [item_name]:openedArr}
            }
            return item
        })
        setVisits(visitArr)
    }


    const handleOpenVisit =  (v_id) => {
        retrieveVisitDetails(v_id)
    }

    const handleClickComponent = (index, item_name, v_id) => {
        const arr = visits.map(item=>{
            if(item.visit_id === v_id){
                return  {...item, activeItem:item_name, components: item?.components?.map((component, idx)=>{
                        if (idx === index){
                            return {...component, isActive:true}
                        }
                        return {...component, isActive:false}
                    })}
            }
            return item

        })
        setVisits(arr)
    }

    const handleOpenNote = (v_id,notes_id) => {
        const arr = visits.map(item=>{
            if(item.visit_id === v_id){
                return  {...item,  clinicalNotes: item?.clinicalNotes?.map((note, idx)=>{
                        if (note.examination_notes_id === notes_id){
                            return {...note, openNote:!note.openNote}
                        }
                        return note
                    })}
            }
            return item

        })
        setVisits(arr)
    }

    const handleOpenPrint = (item) =>{
        setOpenPrint(true)
        const formData = new FormData();
        formData.append('visit_id', item.visit_id);
        Promise.all(endPoints.map((endpoint) => axios.post(endpoint, formData)))
            .then(([{data: visitData}, {data: antenatalData}] )=> {
                //visit Data
                const details = !visitData ? {} : visitData;
                const {lab_result,scan_result, diagnosis,prescription,other_medicine,exam_notes,triage} = details
                const presc = prescription ? prescription : [];
                const others = other_medicine ? other_medicine : [];
                const other_meds = others.filter(item=>Boolean(item));
                const medicationArr = [...presc, ...other_meds];
                const labArr = lab_result?.map(item=>({...item, open:true}))
                const scanArr = scan_result?.map(item=>({...item, open:true}))

                //antenatal data
                const antenatal_details = antenatalData ? antenatalData : {}
                setVisitDetails({
                    ...visitDetails,
                    labResults: labArr,
                    radiologyResults: scanArr,
                    patientDiagnosis: diagnosis,
                    medication: medicationArr,
                    patientTriage: triage,
                    clinicalNotes: exam_notes,
                    visit_date: item.begin_datetime,
                    visit_type: item.patient_type,
                    antenatal_data: antenatal_details

                })
            });
        setOpenPrint([{label:'Triage', value:'triage'},{label:'Antenatal', value:'antenatal'}])
    }

    const handleClosePrint = () =>{
        setOpenPrint(false)
    }

    const handleChangeOption = (value) =>{
        setOption(value)
    }



    const closeSnackbar = () =>{
        actions.snackbarActions.hideSnackbar()
    };

    const {first_name, last_name, Age, address, gender, phone_no} = patient

    const patientInfo = {patient_name:`${first_name} ${last_name}`, age:Age, gender, phone:phone_no, address}


    return {visits, patientInfo, handleOpenVisit, handleClickComponent,option, options, handleChangeOption,
        handleOpenResults, visitDetails, closeSnackbar, openPrint, handleOpenPrint, handleClosePrint, handleOpenNote}
}