import {useEffect,  useState} from "react";
import axios from "axios";
import { config } from "../../../../Helpers/env";


export const useViewInvoice = (match={}) => {
    const [receipt, setReceipt] = useState({});
    const [items, setItems] = useState([]);
    const [total_amount, setTotalAmount] = useState({currency_total:0, usd_total:0})
    const [status, setStatus] = useState('idle')
    const {visit_id, receipt_number} = match.params;
    const [exchangeRate, setExchangeRate] = useState(0)
    const [currency, setCurrency] = useState('USD')
    const [currencies, setCurrencies] = useState([])

    // get currencies
    useEffect(() => {
        axios.get(`${config.smsUrl}/accounting/currencies`).then(res => {
            const data = res.data;
            const all_data = !data ? {}: data;
            const arr = all_data.data ?? [];
            const list = arr.map(item=>({value:`${item.currency_symbol}`, label:`${item.currency_symbol} ${item.currency_name}`}))
            setCurrencies(list)
        }).catch(err => {
            // logoutErrorMessage(err, null, actions)
        })
    }, []);

    useEffect(() => {
        const numbers = receipt_number.split('-')
        setStatus('pending')
        const formData = new FormData();
        formData.append('visit_id', visit_id);
        axios.post(`${config.smsUrl}/cbilling/get_invoice_by_visit_id`,{visit_id, invoices:numbers}).then(res => {
            const data = res.data;
            const all_data = !data ? {} : data;
            const info = !all_data.patient_info ? {} : all_data.patient_info;
            const invoices = all_data.invoice ?? [];
            let invoicesArr = [];

            invoices.forEach(item=>{
                invoicesArr = [...invoicesArr,...item]
            });

            const obj = invoicesArr[0] ? invoicesArr[0] :{};
            const exchange_rate = obj.currency_rate ? obj.currency_rate : 0;
            const current_currency = obj.currency_id === 1 ? 'USD' : 'SSP'

            setReceipt({...info, ...obj});

            setStatus('resolved')

            setCurrency(current_currency)
            setExchangeRate(exchange_rate)
            convertList(exchange_rate,  invoicesArr)
        }).catch(e => {
            setStatus('rejected')
        })
    }, []);


    const totalCurrency = (arr=[]) =>{
        return arr.reduce((a, item) => {
            return (+a + item.usd_total)
        }, 0)
    }

    const totalNonCurrency = (arr=[]) =>{
        return arr.reduce((a, item) => {
            return (+a + item.currency_total)
        }, 0)
    }

    const convertList = (ex_rate,arr=[]) =>{

        const invArr = arr.map(item=>{
            const qty = item.quantity ? item.quantity : 0;
            const rate = item.rate ? item.rate : 0
            const total = rate * qty
            return {
                ...item,
                item_name: item.service_id ? item.service_name : item.product_id ? item.product_name : '',
                currency_rate: ex_rate ?  item.rate / ex_rate : item.rate,
                usd_rate:item.rate,
                usd_total : total,
                currency_total: (ex_rate ?  item.rate / ex_rate : item.rate) *  (!item.quantity ? 0 : item.quantity),
            }})

        const t_amount = totalCurrency(invArr)
        const t_currency = totalNonCurrency(invArr)
        setTotalAmount({usd_total: t_amount, currency_total: t_currency})
        setItems(invArr);
    }

    //Change currency
    const handleChangeCurrency = (e) =>{
        setCurrency(e.target.value)
        // setExchangeRate(0)

    }

    //Change exchange rate
    const handleChangeExchangeRate = (event) =>{
        setExchangeRate(event.target.value)
        convertList(+event.target.value, items)

    }

    const isPending = status === 'pending'
    const isResolved = status === 'resolved'
    const isRejected = status === 'rejected'



    return {receipt, items,   total_amount, isPending, isResolved, isRejected,
        exchangeRate, currencies, currency, handleChangeExchangeRate, handleChangeCurrency}
}