import React, {useEffect,  useState} from "react";
import CustomTable from "../../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import *  as snackbarActions from "../../../actions/snackbarActions";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import FilterResults from "react-filter-search";
import {MedicalReportFilter} from "../ReportFilter";
import {RightAlignedContainer} from "../../Utils/styledComponents";
import axios from "axios";
import {config} from "../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {dateConvert} from "../../Utils/ConvertDate";
import {LoadingGif} from "../../Utils/Loader";
import {Link} from "react-router-dom";

const headData = [
    {id: 'sl', numeric: false, disablePadding: false, label: 'Sl'},
    {id: 'service', numeric: false, disablePadding: false, label: 'Service'},
    {id: 'total', numeric: false, disablePadding: false, label: 'Total'}
];


const NumberofServicesPerformedTable = ({actions, snackbars, component}) => {
    const [date, setDate] = useState({start_date: dateConvert(), end_date: dateConvert()});
    const [searchValue, setSearchValue] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isSubmitted, setIsSubmitted] = useState('idle');;
    const [loading, setLoading] = useState('idle');
    const [labTests, setList] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [total, setTotal] = useState(0)
    const [payment, setPayment] = useState({value:1, label:'Cash'})
    const [patientType, setPatientType] = useState({value:1, label:'Out-patient'})
    const [providers, setProviders] = useState([])
    const [provider, setProvider] = useState({value:'', label:''})

    const {start_date, end_date} = date;


    useEffect(() => {
        if (payment.label === 'All' || payment.label === 'Cash'){
            return
        }
        axios.get(`${config.smsUrl}/cbilling/insurance_providers/list`).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const arr = !dt.data ? [] : dt.data;
            const list = arr.filter(item=>item.provider_type === payment.label).map(item=>({
                value:item.provider_id,
                label:item.provider_name
            }))
            setProviders(list);
        }).catch(err => {
            logoutErrorMessage(err,null,actions)
        })

    }, [payment.label]);

    useEffect(() => {
        // setDate({start_date:dateConvert(), end_date:dateConvert()})
        setPatientType({value:1, label:'Out-patient'})
        setPayment({value:1, label:'Cash'})
    }, [component]);

    const calculateTotal = (arr) =>{
        const sum = arr.reduce((a,b)=>{
            return +a + b.total
        },0)
        setTotal(sum)
    }



    const url = {
        'lab':'number_of_tests',
        'scan':'number_of_scans',
        'consultation':'total_number_of_consulations',
        'dental':'total_number_of_dental_services_offered'
    }

    const isCash = payment.label === 'All' || payment.label === 'Cash'


    useEffect(() => {
        setLoading('pending');
        axios.get(`${config.smsUrl}/creport/${url[component]}`, {
            params: {
                start_date: start_date,
                end_date: end_date,
                patient_type: patientType?.value ? patientType?.value : '',
                mode_of_payment:payment?.value ? payment?.value : '',
                provider_id:provider.value
            }
        }).then(res => {
            const data = res.data;
            const dt = !data ? [] : data;
            const serviceData = {
                'lab':dt.map(item=>({
                    id:item.hospital_test_id,
                    service_name:(
                        // <Link to={{pathname:`/reports/labtestdetails/${item.hospital_test_id}/name=${item.hospital_test_name}`}}>
                            item.service_name
                        // {/*</Link>*/}
                    ),
                    total:item.count
                })),
                'scan':dt.map(item=>({
                    id:item.hospital_detail_id,
                    service_name:(
                        // <Link to={{pathname:`/reports/detailedradiology/${item.hospital_scan_id}/name=${item.hospital_scan_name}`}}>
                        item.service_name
                    // </Link>
                    ),
                    total:item.count
                })),
                'consultation':dt.map(item=>({
                    id:item.service_id,
                    service_name:item.service_name,
                    total:item.count
                })),
                'dental':dt.map(item=>({
                    id:item.service_id,
                    service_name:item.service_name,
                    total:item.count
                }))
            }
            setList(serviceData[component]);
            calculateTotal(serviceData[component])
            setLoading('success');
        }).catch((err) => {
            setLoading('error');
            logoutErrorMessage(err, null, actions);
        })
    }, [isCash, patientType, component, provider]);

    const handleSubmit = (event) => {
        event.preventDefault();
        const {start_date, end_date} = date;
        setSubmitted(true)
        if (start_date && end_date) {
            setIsSubmitted('pending')
            setLoading('pending')
            axios.get(`${config.smsUrl}/creport/${url[component]}`, {
                params: {
                    start_date: start_date,
                    end_date: end_date,
                    patient_type: patientType?.value ? patientType?.value : '',
                    mode_of_payment:payment?.value ? payment?.value : '',
                    provider_id:provider.value
                }
            }).then(res => {
                const data = res.data;
                const dt = data ? data : [];
                const serviceData = {
                    'lab':dt.map(item=>({
                        id:item.hospital_test_id,
                        service_name:(
                            // <Link to={{pathname:`/reports/labtestdetails/${item.hospital_test_id}/name=${item.hospital_test_name}`}}>
                                item.service_name
                            // {/*</Link>*/}
                        ),
                        total:item.count
                    })),
                    'scan':dt.map(item=>({
                        id:item.hospital_detail_id,
                        service_name:(
                            // <Link to={{pathname:`/reports/detailedradiology/${item.hospital_scan_id}/name=${item.hospital_scan_name}`}}>
                                item.service_name
                            // </Link>
                        ),
                        total:item.count
                    })),
                    'consultation':dt.map(item=>({
                        id:item.service_id,
                        service_name:item.service_name,
                        total:item.count
                    })),
                    'dental':dt.map(item=>({
                        id:item.service_id,
                        service_name:item.service_name,
                        total:item.count
                    }))
                }
                setList(serviceData[component]);
                calculateTotal(serviceData[component])
                setIsSubmitted('resolved')
                setLoading('success')
            }).catch(err => {
                setIsSubmitted('rejected')
                errorMessages(err, null, actions);
                setLoading('error')
            })
        }
    }

    const handleChangeDate = (event) => {
        const {value, name} = event.target;
        setDate({...date, [name]: value})
    }


    const handleSearch = (value) => {
        setSearchValue(value)
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const exportUrl = {
        'consultation':'creport/total_number_of_consulations_exports',
        'lab':'creport/number_of_lab_tests_export',
        'scan':'creport/number_of_scans_exports',
        'dental':'creport/total_number_of_dental_services_offered_exports',
    }

    const title = {
        'consultation':'Consultation Report',
        'lab':'Lab Report',
        'scan':'Radiology Report',
        'Dental':'Dental Report'
    }

    const totalText = {
        'consultation':'consultations',
        'lab':'tests',
        'scan':'scans/x-rays',
        'Dental':'dental services'
    }


    const {openBar, type, message} =snackbars;

    const isLoading = loading === 'pending'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'

    const isPending = isSubmitted === 'pending'
    return (
        <div data-testid={`${component}-report`}>
            <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>
            <MedicalReportFilter {...{payment, setPayment, patientType, setPatientType,handleSubmit,provider, providers, setProvider,
                start_date, end_date, handleChange:handleChangeDate,isPending, submitted,actions, file_name:title[component],
                excelUrl:exportUrl[component], excelParams:{export_type:2},
                pdfUrl:exportUrl[component], pdfParams:{export_type:1}
            }}/>
            <RightAlignedContainer>
                <p style={{fontSize:'16px'}}>Total {totalText[component]} performed: { isLoading ? <strong>0</strong>: <strong>{total}</strong>}</p>
            </RightAlignedContainer>
            <CustomTable title="" term={searchValue} handler={handleSearch}
                         handleChangePage={handleChangePage}
                         handleChangeRowsPerPage={handleChangeRowsPerPage}
                         page={page} rowsPerPage={rowsPerPage} headData={headData}
                         data={labTests} colSpan={5}
                         filter fewRows>
                <FilterResults value={searchValue} data={labTests}
                               renderResults={results => (
                                   <TableBody>
                                       {isLoading ? <TableRow>
                                           <TableCell colSpan={headData.length} align='center'><LoadingGif/></TableCell>
                                       </TableRow>:null}
                                       { isSuccess ? results.length > 0 ?  results.slice(page, page * rowsPerPage + rowsPerPage)
                                           .map((item, index) => {
                                               const count =(page * rowsPerPage) + index + 1;
                                               return (
                                                   <TableRow
                                                       hover
                                                       role="checkbox"
                                                       tabIndex={-1}
                                                       key={index}
                                                       className='mui-table-row'>
                                                       <TableCell>{count}</TableCell>
                                                       <TableCell>
                                                           {item.service_name}
                                                       </TableCell>
                                                       <TableCell>{item.total}</TableCell>
                                                   </TableRow>
                                               );
                                           }):<TableRow>
                                           <TableCell colSpan={headData.length} align='center'>No records found</TableCell>
                                       </TableRow>: null}
                                       {isError ? <TableRow>
                                           <TableCell colSpan={headData.length} align='center'>The server did not return a valid response</TableCell>
                                       </TableRow>: null}
                                   </TableBody>
                               )}/>
            </CustomTable>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(NumberofServicesPerformedTable);
