import React, {useEffect, useState} from "react";
import PageTitle from "../Utils/smsTitle";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../actions/snackbarActions";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import { Container } from "../DoctorsModule/doctorStyles";
import {faHouseUser} from "@fortawesome/free-solid-svg-icons/faHouseUser";
import CustomDialog from "../Utils/Dialogs/CustomDialog";
import DeleteDialog from "../Utils/Dialogs/DeleteDialog";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import CustomTable from "../Utils/Tables/CustomTable";
import FilterResults from "react-filter-search";
import TableBody from "@material-ui/core/TableBody";
import {getComparator, stableSort} from "../Utils/Tables/sortingFunctions";
import {useAddDepartment} from "./CustomHooks/useAddDepartment";
import axios from "axios";
import {config} from "../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../Helpers/ErrorMessages";
import {AddDepartmentForm} from "./AddDepartmentForm";
import {LoadingGif} from "../Utils/Loader";
const headData = [{id: 'sl', numeric: false, disablePadding: false, label: 'SL'},
    {id: 'department_name', numeric: false, disablePadding: false, label: 'Department Name'},
    {id: 'department_type', numeric: false, disablePadding: false, label: 'Department Type'},
    {id: 'actions', numeric: false, disablePadding: false, label: 'Action'},
];

const MainDepartment = ({actions, snackbars}) => {
    const [department_id, setId] = useState('');
    const [departments, setDepartments] = useState([]);
    const [loading, setLoading] = useState('idle');
    const [openDelete, setOpenDelete] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('sl');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const {departmentProps, isResolved} = useAddDepartment(actions)

    useEffect(() => {
        setLoading('loading');
        axios.get(`${config.smsUrl}/cvisit/retrieve_department`).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const departs = !dt.departments ? [] : dt.departments;
            setDepartments(departs);
            setLoading('success')
        }).catch(err => {
            logoutErrorMessage(err, null, actions);
            setLoading('error')
        })

    }, [isResolved]);


    const handleOpenDelete = (_id) => {
        setId(_id);
        setOpenDelete(true)
    };

    const handleCloseDelete = () => {
        setOpenDelete(false)
    };

    const handleDelete = () => {
        const formData = new FormData();
        formData.append('department_id', department_id);
        axios.post(`${config.smsUrl}/chumanresource/delete_department`, formData).then(() => {
            const departs = departments.filter(department => department.department_id !== department_id);
            setDepartments(departs);
            actions.snackbarActions.snackSuccess('Department deleted successfully');
            setOpenDelete(false)
        }).catch(err => {
            errorMessages(err, null, actions)
        })
    };



    const handleSearch = (searchValue) => {
        setSearchValue(searchValue)
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const closeSnackbar = () =>{
        actions.snackbarActions.hideSnackbar();
    };


    const isLoading = loading === 'loading'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'


    const {openBar, type, message} = snackbars;

    const {openDialog, openEditDialog, handleCloseEditDialog, handleCloseDialog, handleOpenDialog, handleOpenEditDialog} = departmentProps
    return (
        <div data-testid="main-department" className='journals'>
            <PageTitle title="Department"/>
            <SubHeader title="Department" subTitle="Department">
                <FontAwesomeIcon icon={faHouseUser}/>
            </SubHeader>
            <CustomDialog open={openDialog} handleClose={handleCloseDialog} title="Add department" >
                <AddDepartmentForm {...departmentProps}  />
            </CustomDialog>

            <CustomDialog open={openEditDialog} handleClose={handleCloseEditDialog} title="Edit department" >
                <AddDepartmentForm {...departmentProps}  />
            </CustomDialog>

            <div className="mui-tables">
                <div data-testid="department-list">
                    <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>
                    <DeleteDialog message="delete" openDialog={openDelete} handleClose={handleCloseDelete}
                                        text="department" title='Department'>
                            <button className='btn sms-btn-dismiss btn-sm' onClick={handleDelete}>Delete</button>
                            <button className="btn sms-grey-btn btn-sm" onClick={handleCloseDelete}>No</button>    
                    </DeleteDialog>
                    <Container>
                        <div className="text-right mb-3">
                            <button onClick={handleOpenDialog} className='btn btn-sm sms-info-btn'>New Department</button>
                        </div>
                        <CustomTable  title="Departments" handleChangePage={handleChangePage}
                                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                                    headData={headData} handler={handleSearch} page={page} rowsPerPage={rowsPerPage}
                                    handleRequestSort={handleRequestSort} data={departments} colSpan={5} order={order}
                                    orderBy={orderBy} id="searchValue" filter>
                                <FilterResults value={searchValue} data={departments}
                                            renderResults={results => (
                                                <TableBody>
                                                    {isLoading ? <TableRow>
                                                        <TableCell colSpan={headData.length} align="center"><LoadingGif/></TableCell>
                                                    </TableRow>: null}
                                                    {isSuccess ? departments.length > 0  ? stableSort(results, getComparator(order, orderBy))
                                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                        .map((item, index) => {
                                                            const count = (page * rowsPerPage) + index + 1;
                                                            return (
                                                                <TableRow
                                                                    hover
                                                                    role="checkbox"
                                                                    tabIndex={-1}
                                                                    key={item.department_id}
                                                                    className='mui-table-row'
                                                                    id="unit"
                                                                >
                                                                    <TableCell>{count}</TableCell>
                                                                    <TableCell>{item.department_name}</TableCell>
                                                                    <TableCell>{item.department_type}</TableCell>
                                                                    <TableCell>
                                                                        <button onClick={()=>handleOpenEditDialog(item.department_id)}
                                                                        className="btn btn-sm sms-info-btn mr-1">Edit</button>
                                                                        <button onClick={()=>handleOpenDelete(item.department_id)} className="btn btn-sm sms-gray-btn">Deactivate</button>
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        }): <TableRow>
                                                        <TableCell colSpan={headData.length} align="center">No records found</TableCell>
                                                    </TableRow>: null}
                                                    {isError ? <TableRow>
                                                        <TableCell colSpan={headData.length} align="center">The server did not return a valid response</TableCell>
                                                    </TableRow>: null}
                                                </TableBody>
                                            )}/>
                        </CustomTable>
                    </Container>
                </div>
            </div>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(MainDepartment);
