import React, { useState, useEffect, useCallback } from 'react'
import {StickyHeadTable, CustomTableCell, MuiPaper} from '../../Utils/Tables/StickyHeaderTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList } from '@fortawesome/free-solid-svg-icons/faClipboardList';
import PageTitle from '../../Utils/smsTitle';
import { SubHeader } from '../../../Containers/SubHeader';
import {dateConvert, formatDateTime} from '../../Utils/ConvertDate';
import axios from 'axios';
import { config } from "../../../Helpers/env"
import { errorMessages, logoutErrorMessage } from '../../../Helpers/ErrorMessages';
import * as snackbarActions from "../../../actions/snackbarActions";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import MainSnackbar from '../../Utils/Snackbar/SmsSnackbar';
import { DateTimeFilter } from '../../Utils/ReusableComponents/DateTimeFilter';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { LoadingGif } from '../../Utils/Loader';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {todayTime } from '../../Utils/ConvertDate';
import { BackButton } from '../../Utils/Buttons/DataExportationButton';
import Label from "../../Utils/FormInputs/Label";
import { AutoCompleteControlled } from '../../Utils/FormInputs/AutoCompleteInput';
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {RightAlignedContainer} from "../../Utils/styledComponents";
import { textWeight } from '../../../styles/tableStyles';
import {CustomTablePagination} from "../../Utils/Tables/CustomTable";
import {useCurrency} from "../../../Context/currency-context";



function PatientTransactionDetails({actions, snackbars, match:{params}}) {
    const {currency:selectedCurrency} = useCurrency({actions, snackbars, match:{params}})
    const {patient_id, component:tab, start_date:s_date, end_date:e_date} = params
    const component = +tab
    const current_date = todayTime()
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [state, setState] = useState({start_date:s_date ? s_date : '', end_date:e_date ? e_date : '' })
    const [revenues, setRevenues] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [loading, setLoading] = useState('idle')
    const {start_date, end_date} = state;
    const [totalCollections, setTotalCollections] = useState('')
    const [totalCollectionsSsp, setTotalCollectionsSsp] = useState(0)
    const [providerOptions, setProviderOptions] = useState([]);
    const [insurance_provider, setInsuranceProvider] = useState('');
    const [receivedbyOptions, setReceivedbyOptions] = useState('')
    const [received_by, setReceivedBy] = useState('')
    const [tabValue, setTabValue] = useState(0)
    const isCredit = component === 2;

    const patient_number = patient_id.split('-')[0]
    const patient_name = patient_id.split('-')[1].trim();


    const bill_mode = {
        0:'all',
        1:'cash',
        2:'account'
    }

    const headData = [
        { id: 'date_created', label: 'Date Cleared',minWidth: 200 },
        {id: 'item_name',label: 'Item Name',minWidth: 170},
        {id: 'item_category',label: 'Item Category',minWidth: 170},
        {id: 'item_status',label: 'Item Status',minWidth: 170},
        {id: 'quantity',label: 'Quantity',minWidth: 170},
        {id: 'unit_price',label: 'Unit Price',minWidth: 170},
        {id: 'amount',label: `Total Bill`, minWidth: 170},
        // {id: 'total_ssp_bill',label: 'Total Bill(SSP)',minWidth: 170},
        !isCredit ? {id: 'received_by',label: 'Received By'} : null,
        isCredit ? {id: 'provider_name',label: 'Provider Name',minWidth: 170} : null,
        isCredit ? {id: 'member_card_no',label: 'Member Card No.',minWidth: 170} : null,
    ]

    const retrieveData = useCallback(()=>{
        let params = {   start_date,end_date,
            bill_mode:bill_mode[component], download:false,
            provider_id : (component === 2) ? (insurance_provider?.value ? insurance_provider?.value : '') : '',
            received_by: received_by?.value ? received_by?.value :  '',
            patient_name: patient_name,
            visit_id:patient_number
        }
        setLoading('pending')
        axios.get(`${config.smsUrl}/cbilling/collections/transactions`, {params}).then(response=>{
            const res = response.data ? response.data:{};
            const data = res.data ? res.data : [];
            const total_amount = res.total_amount
            setRevenues(data)
            setTotalCollections(total_amount)
            setLoading('success')
        }).catch(err=>{
            logoutErrorMessage(err, null,actions)
            setLoading('error')
        })

    },[ insurance_provider, received_by])

    useEffect(()=>{
        retrieveData()
    },[retrieveData])

    useEffect(() => {
        if(component !== 2){
            if (insurance_provider) { setInsuranceProvider(''); }
            return
        }
        axios.get(`${config.smsUrl}/cbilling/insurance_providers/list`).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const arr = !dt.data ? [] : dt.data;
            const provs = arr.map(item=>({
                ...item,
                value:item.provider_id,
                label:item.provider_name}));
            setProviderOptions(provs);
        }).catch(err => {
            logoutErrorMessage(err,null,actions)
        })
    }, []);

    useEffect(() => {
        if(component !== 1){
            if (received_by) { setReceivedBy(''); }
            return
        }
        axios.post(`${config.smsUrl}/permission/user_roles_list`).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const arr = !dt.user_roles_list ? [] : dt.user_roles_list;
            const provs = arr.map(item=>({
                ...item,
                value:item.user_roles_id,
                label:(`${item.first_name} ${item.last_name}`)}));
            setReceivedbyOptions(provs);
        }).catch(err => {
            logoutErrorMessage(err,null,actions)
        })
    }, [ insurance_provider, received_by]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleProviderChange = (value) =>{
        setInsuranceProvider(value);

    }

    const handleChangeReceivedBy = (value) =>{
        setReceivedBy(value)
    }

    const handleChange = (event) =>{
        const {value, name} = event.target;
        setState({...state,[name]:value})
    }

    const handleSubmit = (event) =>{
        event.preventDefault()
        setSubmitted(true)
        if(start_date && end_date && end_date >= start_date){
            setIsSubmitted('pending')
            setLoading('pending')
            axios.get(`${config.smsUrl}/cbilling/collections/transactions`, {params:{start_date,end_date, bill_mode:bill_mode[component],
                    download:false,provider_id : (component === 2) ? (insurance_provider?.value ? insurance_provider?.value : '') : '',
                    received_by: received_by?.value ? received_by?.value :  '', visit_id:patient_number}}).then(response=>{
                const res = response.data ? response.data:{};
                const data = res.data ? res.data : [];
                const total_amount = res.total_amount ? res.total_amount : '';
                // console.log(insurance_provider)
                setTotalCollections(total_amount)
                setRevenues(data)
                // setPatientName(data)
                setLoading('success')
                setIsSubmitted('resolved')
            }).catch(err=>{
                errorMessages(err, null,actions)
                setLoading('error')
                setIsSubmitted('rejected')
            })
        }

    }

    const fileName = {
        0:'All Collections',
        1:'Cash Collections',
        2:'Credit Collections'
    }

    const exportRevenue = () => {
        axios.get(`${config.smsUrl}/cbilling/collections/transactions`, {params:{start_date,end_date,
                bill_mode:bill_mode[component],download:true, visit_id:patient_number},responseType: 'blob'}).then(response=>{
            const data  = response.data;
            const url = URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href =  url;
            link.setAttribute('download', `${fileName[component]}-${current_date}.xlsx`);
            document.body.appendChild(link);
            link.click();
        }).catch(err=>{
            errorMessages(err, null,actions)

        })
    }

    const handleChangeTabValue = (event, val) => {
        setTabValue(val)
    }


    const patientDetails = (
        <div>
            <td><span><strong>Patient Name:</strong> </span> <span css={[textWeight]}><strong>{patient_name}</strong></span></td>
            <p/>
            <td><span><strong>Visit No:</strong> </span> <span css={[textWeight]}><strong>{patient_number}</strong>
                </span> </td>
        </div>
    )

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const isLoading = loading === 'pending';
    const isSuccess = loading === 'success';
    const isError = loading === 'error';

    const isPending = isSubmitted === 'pending';
    const {openBar, type, message} = snackbars;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, revenues.length - page * rowsPerPage);

    const theme = useTheme();
    const isExtraLarge = useMediaQuery(theme.breakpoints.up('xl'));
    const isMedium = useMediaQuery(theme.breakpoints.up('md'));
    const isLarge = useMediaQuery(theme.breakpoints.up('lg'));

    const colSpan = isExtraLarge ? 12 : isMedium ? 6 : isLarge ? 10 : headData.length


    const total_text = {
        0:'Collections',
        1:'Cash Collections',
        2:'Credit Collections'
    }

    const filters = {
        0:(
            <div className='text-xl-right text-lg-right text-md-left text-sm-left'>
                <h5>{`Total ${total_text[component]}`} : <strong>{(totalCollections)}</strong></h5>
                {/*<h5>{`Total ${total_text[component]}(SSD)`} : <strong>{(totalCollectionsSsp)}</strong></h5>*/}
            </div>
            
        ),
        1:(
            <div className='row mb-0'>
                <div className='col-lg-6'>
                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                            <Label name="Filter by" type/>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={8} xl={8}>

                            <AutoCompleteControlled options={receivedbyOptions} handleChange={handleChangeReceivedBy}
                                                    placeholder="Filter by receiver..."  dataTest="received_by" medicine_value={received_by}
                                                    value_text='value' label_text='label' />

                        </Col>
                    </Form.Group>
                </div>
                <div className='col-lg-6'>
                    <RightAlignedContainer>
                    <div className='text-xl-right text-lg-right text-md-left text-sm-left'>
                <h5>{`Total ${total_text[component]}`} : <strong>{(totalCollections)}</strong></h5>
                {/*<h5>{`Total ${total_text[component]}(SSD)`} : <strong>{(totalCollectionsSsp)}</strong></h5>*/}
            </div>
                    </RightAlignedContainer>
                </div>
            </div>

        ),
        2: (
            <div className='row mb-0'>
                <div className='col-lg-6'>
                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                            <Label name="Filter by" type/>
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                            <AutoCompleteControlled options={providerOptions} medicine_value={insurance_provider} handleChange={handleProviderChange}
                                                    placeholder="Filter by provider..."  dataTest="filter_by_provider"
                                                    value_text='value' label_text='label' />
                        </Col>
                    </Form.Group>
                </div>
                <div className='text-xl-right text-lg-right text-md-left text-sm-left'>
                <h5>{`Total ${total_text[component]}`} : <strong>{(totalCollections)}</strong></h5>
                {/*<h5>{`Total ${total_text[component]}(SSD)`} : <strong>{(totalCollectionsSsp)}</strong></h5>*/}
            </div>
            </div>
        )
    }


    const pagination = (
        <CustomTablePagination
            colSpan={3}
            page={page}
            rowsPerPage={rowsPerPage}
            data={revenues}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
    );

    const reusableTable = (
        <div className='mt-3'>
            {filters[component]}
           {patientDetails}
            <MuiPaper>
                
                <StickyHeadTable {...{page,rowsPerPage,handleChangePage,handleChangeRowsPerPage,data:revenues, columns:headData.filter(item=>Boolean(item)), pagination}}>
                    {isLoading ? <TableRow><TableCell align='center' {...{colSpan}}><LoadingGif/></TableCell></TableRow>:null}
                    {isSuccess ? revenues.length > 0 ?
                        <>
                            {revenues.slice(page,page * rowsPerPage + rowsPerPage).map((row, index) => {
                                const rate = row.rate ? row.rate : 0;
                                const ssp_rate = row.rate_in_ssp ? row.rate_in_ssp : 0;
                                const quantity = row.quantity ? row.quantity : 0;
                                const total_amount =  quantity * rate;
                                const ssp_total = quantity * ssp_rate
                                return (
                                    <TableRow  tabIndex={-1} key={index}>
                                        <CustomTableCell>{formatDateTime(row.cleared_date)}</CustomTableCell>
                                        <CustomTableCell>{row.item_name}</CustomTableCell>
                                        <CustomTableCell>{row.item_type}</CustomTableCell>
                                        <CustomTableCell>{row.item_status}</CustomTableCell>
                                        <CustomTableCell>{row.quantity}</CustomTableCell>
                                        <CustomTableCell>{row.rate ? (row.rate) : 0}</CustomTableCell>
                                        <CustomTableCell>{row.amount}</CustomTableCell>
                                        {/*<CustomTableCell>{row.total_in_ssp ? row.total_in_ssp: 0}</CustomTableCell>*/}
                                        {!isCredit ? <CustomTableCell>{row.recieved_by}</CustomTableCell>: null}
                                        {isCredit ? <CustomTableCell>{row.provider_name}</CustomTableCell>: null}
                                        {isCredit ? <CustomTableCell>{row.card_number}</CustomTableCell>: null}
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 43 * emptyRows }}>
                                    <TableCell colSpan={headData.length} />
                                </TableRow>
                            )}
                        </>:<TableRow><TableCell align='center'  {...{colSpan}}>No records found</TableCell></TableRow>:null}
                    {isError ? <TableRow><TableCell align='center'  {...{colSpan}}>The server did not return a valid response</TableCell></TableRow>:null}
                </StickyHeadTable>
            </MuiPaper>

        </div>
    )
    return (
        <div className='journals'>
            <PageTitle title='Revenue Generated'/>
            <SubHeader title="Reports" subTitle='Revenue Generated'>
                <FontAwesomeIcon icon={faClipboardList}/>
            </SubHeader>
            <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>
            <div className='mx-3'>
                <div style={{margin: '10px'}}>
                    <BackButton text='Collections' to='/reports/totalrevenuegenerated'/>
                </div>

                <div className='general-ledger-header' style={{marginBottom:0, display:'flex', justifyContent:'space-between', flexWrap:'wrap'}}>
                    <DateTimeFilter {...{start_date,end_date, handleChange, submitted,handleSubmit,isPending,type:'date'}}/>
                    <div style={{margin: '10px'}}>
                        <button onClick={exportRevenue} className='btn sms-gray-btn' style={{paddingTop:'2px'}}>Download</button>
                    </div>
                </div>
                {reusableTable}
            </div>
        </div>
    )
}
function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientTransactionDetails);
