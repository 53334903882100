import {useEffect, useState} from "react";
import axios from "axios";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import { config } from "../../../Helpers/env";
import {history} from "../../../Helpers/history";

export const useTakeVitals = (actions, obj) => {
    const [triageList, setTriageList] = useState([]);
    const [triage, setTriage] = useState([])
    const [{weight_metric_id, weight_metric_name}, setWeight] = useState({weight_metric_id: 2, weight_metric_name: ''});
    const [{height_metric_id, height_metric_name}, setHeight] = useState({height_metric_id: 1, height_metric_name: ''});
    const [bmi, setBMI] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [allMetrics, setAllMetrics] = useState([])
    const [otherMetric, setOtherMetric] = useState('');
    const [others, setOthers] = useState([]);
    const [optionalMetrics, setOptionalMetrics] = useState([]);
    const [allergies, setAllergies] = useState('')
    const [hasAllergies, setHasAllergies] = useState(false)
    const [chronic_illness, setChronicIllness] = useState(
        {cardiac_disease:false, kidney_disease:false, hypertension:false, tb:false, asthma:false, sti:false, 
            sickle_cell_disease:false, epilepsy:false, diabetes:false, other_chronic_illness:''}
    );

    const [isBlocking, setIsBlocking] = useState(false)
    const {p_number, visit_id,activity_id} = obj

    const isSubmitting = isSubmitted === 'pending';
    const isResolved = isSubmitted === 'resolved';
    const isRejected = isSubmitted ===  'rejected'

    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;


    useEffect(() => {
        axios.get(`${config.smsUrl}/cvisit/retrieve_all_metrics`).then(res => {
            const data = res.data;
            const dt = data ? data : {};
            const metrics = dt.metrics ? dt.metrics : [];
            setAllMetrics(metrics)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, [])


    useEffect(() => {
        const filteredArr = allMetrics.filter(item => item.metric_id > 2);
        const tArr = filteredArr.slice(0, 1).map(item => ({
            ...item,
            metric_id: item.metric_id,
            metric_name: `${item.metric_name}(${item.uom ? item.uom : ''})`,
            metric_value: '', name: `metric_${item.metric_id}`
        }))
        setTriage(tArr)
        const tList = filteredArr.slice(1, 3).map(item => ({
            ...item,
            metric_id: item.metric_id,
            metric_name: `${item.metric_name}(${item.uom ? item.uom : ''})`,
            metric_value: '', name: `metric_${item.metric_id}`
        }))
        const other_metrics = filteredArr.slice(3).map(item => ({
            ...item,
            value: item.metric_id,
            label: `${item.metric_name}(${item.uom ? item.uom : ''})`,
            metric_value:''
        }))
        setOthers(other_metrics);
        setTriageList(tList)
    }, [allMetrics])

    const handleChange = (event, index) => {
        const triageCopy = [...triage];
        const {value} = event.target;
        triageCopy[index].metric_value = value;
        setTriage(triageCopy)
        setIsSubmitted('idle')
    };


    const handleOtherMedicals = (event) => {
        setChronicIllness({...chronic_illness, other_chronic_illness:event.target.value});
        setIsBlocking(true)
    }
    const handleMedicalIllness = (event) => {
        const {name, checked} = event.target;
        setChronicIllness({...chronic_illness, [name]:checked});
        setIsBlocking(true)
    }

    const handleHasAllergies = (event) => {
        const {value} = event.target
        if(value === 'yes'){
            setHasAllergies(true)
        }else if(value === 'no'){
            setHasAllergies(false)
        }
        setIsBlocking(true)

    }

    const handleChangeAllergies = (event) => {
        const {value} = event.target
        setAllergies(value)
        setIsBlocking(true)
    }

    const handleChangeOthersMetrics = (value) => {
        setOtherMetric(value)
        const obj = {};
        obj['metric_value'] = '';
        obj['metric_id'] = value.value;
        obj['metric_name'] = value.label;
        const existingIds = optionalMetrics.map((id) => id.metric_id)
        if (!existingIds.includes(value.value)) {
            setOptionalMetrics([...optionalMetrics, obj])
        }
        setIsSubmitted('idle')

        const arr = others.map(item=>{
            if (item.value === value.value){
                return {...item, metric_value:value.value}
            }
            return item
        })
        setOthers(arr)

    }

    const handleChangeOptional = (event, metric_id) => {
        const arr = optionalMetrics.map(metric => {
            if (metric.metric_id === metric_id) {
                return {...metric, metric_value: event.target.value}
            }
            return metric
        })
        setOptionalMetrics(arr)
        setIsSubmitted('idle')
    }

    const removeMetric = (metric_id) =>{
        const arr = optionalMetrics.filter(item=>item.metric_id !== metric_id);
        setOptionalMetrics(arr)
    }

    const handleChangeVitals = (event, index) => {
        const triageCopy = [...triageList];
        const {value} = event.target;
        triageCopy[index].metric_value = value;
        setTriageList(triageCopy)
        setIsSubmitted('idle')
    };

    const calculateBMI = (weight, height) => {
        const h = height ? height : 0;
        if (h > 0) {
            setBMI(((+weight / Math.pow(+h, 2)) * 10000).toFixed(2))
        } else {
            setBMI(0)
        }

    }

    const handleChangeWeight = (event) => {
        const {value} = event.target;
        setWeight(currentState => ({...currentState, weight_metric_name: value}))
        calculateBMI(value, height_metric_name)
        setIsSubmitted('idle')
    }

    const handleChangeHeight = (event) => {
        const {value} = event.target;
        setHeight(currentState => ({...currentState, height_metric_name: value}))
        calculateBMI(weight_metric_name, value)
        setIsSubmitted('idle')
    }

    const clearTriage = (arr) =>{
        return arr.map(item => ({
            ...item, metric_value: ''
        }))
    }

    const handleSubmitTriage = (event) => {
        event.preventDefault();
        setSubmitted(true);

        const formData = new FormData(event.currentTarget);
        const fieldValues = Object.fromEntries(formData.entries())
        if(hasAllergies === false && allergies){
            setAllergies('');
        }
        const {other_chronic_illness, ...rest} = chronic_illness
        const illness = fieldValues['chronic_illness']
        const allergyData = fieldValues['allergies'] ? fieldValues['allergies'] : {}
        const jsonData = {
        'patient_number':p_number,
        'metric_type_id':1,
        'visit_id' :visit_id,
        'activity_id' :activity_id,
        'visit_state':3,
        'user_roles_id' : user_roles_id,
        'chronic_illness': {...rest, other_chronic_illness:illness},
        allergies: allergyData,
            
        triage_info : [
                    ...triage,...triageList,...optionalMetrics,...others,
                    {
                        'metric_id': height_metric_id,
                        'metric_value': height_metric_name
                              },
                       {
                            'metric_id': weight_metric_id,
                            'metric_value': weight_metric_name
                        }
        ]
                }

        const arr = triageList.filter(metric=>!(metric.metric_id === 6 || metric.metric_id === 5)).every(metric=>metric.metric_value);
        const arr1 = triage.every(metric => metric.metric_value)

        if (p_number && arr && arr1) {
            setIsSubmitted('pending');
            axios.post(`${config.smsUrl}/cvisit/add_triage`, jsonData).then(() => {
                setIsSubmitted('resolved');
                actions.snackbarActions.snackSuccess('Triage Information added successful');
                setTriageList(clearTriage(triageList))
                setHeight({height_metric_id: 1, height_metric_name: ''})
                setWeight({weight_metric_id: 2, weight_metric_name: ''})
                setOptionalMetrics(clearTriage(optionalMetrics))
                setTriage(clearTriage(triage))
                setOtherMetric('');
                setBMI('');
                setSubmitted(false);
                history.push('/triagewaitinglist')
            }).catch((err) => {
                errorMessages(err, null, actions)
                setIsSubmitted('rejected');
            });
        }

    };

    const handleCloseSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const isPending = isSubmitted === 'pending'


    const triageProps = {  handleChangeVitals, handleChangeWeight,submitted, handleChange, triageList,
        handleChangeHeight, bmi, weight_metric_name, height_metric_name, triage,  removeMetric,isResolved,setIsBlocking,
        chronic_illness, handleMedicalIllness,handleOtherMedicals,handleHasAllergies,allergies,handleChangeAllergies,hasAllergies,
        otherMetric, others, handleChangeOthersMetrics, optionalMetrics, handleChangeOptional}



    return {triageProps, handleCloseSnackbar, handleSubmitTriage, isPending}
}
