import axios from 'axios'
import React, {useEffect, useState} from 'react'
import {config} from '../../../../Helpers/env';
import {errorMessages} from "../../../../Helpers/ErrorMessages";
import { convDate, extractDate, getActualMonthValue } from '../../../Utils/ConvertDate';

export const useAccountingPeriod = ({actions, isPopup=false, handleRouteChange=null}) => {
    
    //state handling
    const [submitted, setSubmitted] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState('idle')
    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    } 

    const [start_date, setStartDate] = useState('');
    const [end_date, setEndDate] = useState('');
    const [acc_period_present, setAccPeriodPresent] = useState(false);
    const [dataSource, setStartDataSource] = useState([{color: '', id:0, startDate:'', endDate:''}]);
    const [accPeriodDataSource, setAccPeriodDataSource] = useState([{id:0, startDate:'', endDate:''}]);
    const isPending = (isSubmitted === 'pending') || (dataSource[0].startDate === '')
    const [openDepreciation, setOpenDepreciation] = useState(false)
    const [accountStatus, setAccountingStatus] = useState({period_status:null, depreciation_status:null})

    const isResolved = isSubmitted === 'resolved'

    //custom functions
    const formatMonthYear = (date) => {
        return date.getFullYear() + "/" + getActualMonthValue(date.getMonth());
    } 
    
    const findEndFinancialDate = (date) => {
    
        const dateValue = new Date(date);
        let endd = new Date(dateValue.setMonth(dateValue.getMonth() + 12));
        endd = new Date(endd.setDate(endd.getDate() - 1));
        return endd;    
    }


    // check if there is an accounting period present
    useEffect(()=>{
        axios.get(`${config.smsUrl}/accounting/settings/status`).then(res=>{
            const data = res.data;
            const dt = data ?? {};
            const st = dt.message ?? '';

            axios.get(`${config.smsUrl}/accounting/depreciation_status`).then(res=>{
                const response = res.data ? res.data : {}
                const dep_status = response.dep_status ? response.dep_status : null
                setAccountingStatus({period_status: st.status, depreciation_status: dep_status})
                if (st.status === 0 && dep_status === 1) {
                    setAccPeriodPresent(false);
                    setOpenDepreciation(false)
                }else if(st.status === 0 && dep_status === 1){
                    setAccPeriodPresent(false);
                    setOpenDepreciation(true)
                }else {
                    setOpenDepreciation(false)
                    // an accounting period is present
                    setAccPeriodPresent(true);
                    //set the start and end dates
                    setStartDate(extractDate(st.start_date));
                    setEndDate(extractDate(st.end_date));
                }

            })
        }).catch(err=>console.log(err))
    },[isResolved])

    const handleCloseDepreciation = () =>{
        setOpenDepreciation(false)
    }


    const handleSubmit = (event) => {
        event.preventDefault()
        const formData = new FormData();
        setSubmitted(true)
        setOpenDepreciation(true)

        // check call to be made whenever a submisison is done
        // one should not be able to make another call ? of the same kind?
        if (dataSource[0].startDate !== '' && accountStatus.period_status === 0 && accountStatus.depreciation_status === 1) {
            formData.append('start_date', convDate(dataSource[0].startDate));
            formData.append('end_date',  convDate(dataSource[0].endDate));
            formData.append('year', new Date().getFullYear());
            formData.append('financial_year', `${formatMonthYear(dataSource[0].startDate)}-${formatMonthYear(dataSource[0].endDate)}`)

            setIsSubmitted('pending');
            setOpenDepreciation(false)

            axios.post(`${config.smsUrl}/accounting/accounting_period/add`, formData).then(res=> {
                const data = res.data;

                // what to do  with the returned response.
                // check if it is actually there.
                // disable the change of the start and end date
                // and onchage of the css line thingies on the calendar
                // setAccPeriodPresent(data.data);

                
                const newDataSource = data.data ? dataSource : accPeriodDataSource;
                setAccPeriodDataSource(newDataSource);

                setIsSubmitted('resolved');
                setSubmitted(false);

                if (isPopup === true && handleRouteChange !== null) { handleRouteChange(); }
            }).catch(err => {
                setIsSubmitted('rejected');
                errorMessages(err, null, actions)
            })
        }

    };

    const handleClickedDate = (event) => {

        const dateReceived = event.date;
        setStartDate(extractDate(dateReceived));

        const endddate = findEndFinancialDate(dateReceived);
        setEndDate(extractDate(endddate));

        let newPeriod = [{id:0, startDate:new Date(dateReceived), endDate:new Date(endddate)}];
        setStartDataSource(newPeriod)
    }
    const accountingProps = {handleSubmit, handleClickedDate, submitted, isSubmitted, start_date, end_date, closeSnackbar, acc_period_present, isPending, dataSource }
    
    return {accountingProps, handleSubmit, handleClickedDate, submitted, isSubmitted, start_date, end_date, closeSnackbar, acc_period_present, isPending, dataSource,
    openDepreciation, handleCloseDepreciation, accountStatus}
}