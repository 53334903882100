import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {faUserMd} from "@fortawesome/free-solid-svg-icons/faUserMd";
import ReusableDoctorTabs, {doctorRoutes} from "../../Utils/Menu/ReusableDoctorTabs";
import {dateConvert} from "../../Utils/ConvertDate";
import {PatientInformation} from "../PatientInfo/PatientInformation";
import {usePatientDetails} from "../ReferralNote/usePatientDetails";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../../actions/snackbarActions";
import {connect} from "react-redux";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import NewDiagnosis from "./NewDiagnosis";

const DoctorDiagnosis = ({actions, snackbars,match}) => {
    const {patient_number, visit_id, labrequest_id, scan_request_id,
        patient_type,patient_admission_id,activity_id} = match.params;
    // const [tabValue, setTabValue] = useState(0);
    const [patient, setPatient] = useState({patient_name: "", age: "", gender: "", address: "", phone: ""});
    const {patient: patientDetails} = usePatientDetails(actions, patient_number, '')
    useEffect(() => {
        setPatient({
            patient_name: `${!patientDetails.first_name ? "" : patientDetails.first_name} ${!patientDetails.last_name ? "" : patientDetails.last_name}`,
            age: patientDetails.Age,
            gender: patientDetails.gender,
            address: patientDetails.address,
            phone: patientDetails.phone_no
        });
    }, [patientDetails]);


    // const handleChangeTabValue = (event, val) => {
    //     setTabValue(val)
    // }


    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    // const tabs = ['New Diagnosis', 'Previous Diagnosis']
    const arr = doctorRoutes(patient_number, visit_id, patient_type,patient_admission_id)
    const {patient_name, age, gender, address, phone} = patient;
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const doctor_name = `${!_token.first_name ? "" : _token.first_name} ${!_token.last_name ? "" : _token.last_name}`;
    const {openBar, type, message} = snackbars;
    return (
        <div className='journals'>
            <PageTitle title="Diagnosis"/>
            <SubHeader title="Doctor" subTitle="Diagnosis" doctor_name={doctor_name}>
                <FontAwesomeIcon icon={faUserMd}/>
            </SubHeader>
            <MainSnackbar open={openBar} handleCloseBar={closeSnackbar} variant={type} message={message}/>
            <ReusableDoctorTabs components={arr} patient_type={+patient_type}>
                {/*<ReusableTabs value={tabValue} tabHeading={tabs} handleChange={handleChangeTabValue}>*/}
                    {/*<div className="text-right">
                        <p style={style}>Date: <strong>{dateConvert()}</strong></p>
                        <p style={style}>Doctor: <strong>{doctor_name}</strong></p>
                    </div>*/}
                    <PatientInformation {...{patient_number, visit_id}}/>
                    {/*<TabPanel value={tabValue} index={0}>*/}
                        <NewDiagnosis match={{visit_id, patient_number}} actions={actions}/>
                    {/*</TabPanel>*/}
                    {/*<TabPanel value={tabValue} index={1}>*/}
                    {/*    <PreviousDiagnosis actions={actions} match={{visit_id, patient_number}}/>*/}
                    {/*</TabPanel>*/}
                {/*</ReusableTabs>*/}
            </ReusableDoctorTabs>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbar
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(DoctorDiagnosis);