import {useEffect, useReducer, useState} from "react";
import {dateConvert} from "../../../Utils/ConvertDate";
import axios from "axios";
import {config} from "../../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import {history} from "../../../../Helpers/history";
import {useSelfRequestPrescription} from "../../../Visit/NewService/hooks/usePrescriptionSelfRequest";
import {useNewServiceRequest} from "../../../Visit/NewService/hooks/useNewServiceRequest";
import * as billingActions from "../../utils/billingActions"
import {billingProductsAndServicesReducer} from "../../utils/billingProductsReducer";
import {
    filterInpatientBeds,
    filterInpatientBedsWithCurrency,
    filterInpatientProducts,
    filterInpatientServices,
} from "../../utils/SanitizeArr";
import {useQuery} from "react-query";
import {useCurrency} from "../../../../Context/currency-context";
import {useVersionsContext} from "../../../../Context/versions-context";

export function useInPatientBillFormTwo (props) {

    const {actions} = props;
    const {patient_admission_id, visit_id, status, pay_status} = props.match.params;

    const {currency:selectedCurrency} = useCurrency(props)


    const [
        { products, productsEdited, productsRemoved, productsPaid, procedureData, invoicesEdited, invoicesRemoved, invoicesPaid,
            bedBill, checkAllServices,checkAllProducts,checkAllBeds},
        dispatchProductsAndServices
    ] = useReducer(
            billingProductsAndServicesReducer,
        {
            products: [],
            productsEdited: [],
            productsRemoved: [],
            productsPaid: [],
            procedureData: [],
            invoicesEdited: [],
            invoicesRemoved: [],
            invoicesPaid: [],
            bedBill:[],
            checkAllServices:false,
            checkAllProducts:false,
            checkAllBeds:false
        }, () => ({
            products: [],
            productsEdited: [],
            productsRemoved: [],
            productsPaid: [],
            procedureData: [],
            invoicesEdited: [],
            invoicesRemoved: [],
            invoicesPaid: [],
            bedBill:[],
            checkAllServices:false,
            checkAllProducts:false,
            checkAllBeds:false
    }));

    const user = JSON.parse(sessionStorage.getItem('user'));

    const userObj = user ?? {};

    const token = userObj.token ?? [];

    const _token = token[1] ?? {};

    const user_roles_id = _token.user_roles_id ?? "";

    const obj = {visit_id, user_roles_id, actions}


    const [payment_method, setPaymentMethod] = useState('');

    // const [patientDetails, setPatientDetails] = useState({
    //     patient_number: "",
    //     first_name: '',
    //     last_name: '',
    //     phone_no:'',
    //     address: '',
    //     dob: '',
    //     gender: '',
    //     admission_date: '',
    //     discharge_date: '',
    //     total_days: ''
    // });

    // const [bedBill, setBedBill] = useState([]);

    const [submitted, setSubmitted] = useState(false);

    const [isSubmitted, setIsSubmitted] = useState('idle');

    const [date, setDate] = useState(dateConvert());

    const [openPrint, setOpenPrint] = useState(false);

    const [totals, setTotals] = useState({
        total_amount:0,
        bed_amount:0,
        total_product:0,
        due_amount:0,
        balance:0,
        net_total:0,
        tax_amount:0,
        discount_amount:0
    })

    const [paidAmount, setPaidAmount] = useState("");

    const [billDetails, setBillDetails] = useState({});

    // const [payTypes, setPayTypes] = useState([]);

    const [addService, setAddService] = useState(false)

    const [addProduct, setAddProduct] = useState(false)

    const [departments, setDepartments] = useState([])

    //const [currencies, setCurrencies] = useState([])

    const [currency, setCurrency] = useState('')

    const [exchangeRate, setExchangeRate] = useState(0)
    const [currencyExchangeRate, setCurrencyExchangeRate] = useState(0)

    const [usdAmount, setUsdAmount] = useState(0)

    const [sspAmount, setSspAmount] = useState(0)

    const [isBlocking, setIsBlocking] = useState(false)

    const [paidReceipts, setPaidReceipts] = useState([])

    const [isConverted, setIsConverted] = useState(false);

    const [currencyTotal, setCurrencyTotal] = useState(0)

    const [currencyTotalSsp, setCurrencyTotalSsp] = useState(0)

    const [receiptTotals, setReceiptTotals] = useState({
        total_amount_paid:0,
        remaining_balance:0,
        ssp_amount_paid:0,
        ssp_remaining_balance:0
    })

    const {renderPrescription, isResolved:isPrescription, handleClosePrescriptionDialog} = useSelfRequestPrescription({...obj, setOpenDialog:setAddProduct},true)

    const {renderService, isResolvedService:isServiceResolved, handleCloseServiceDialog} = useNewServiceRequest({...obj,setOpenDialog:setAddService}, true)



    const {subscription} = useVersionsContext()
    const version = subscription?.version
    const isPremium = version === 'Premium'


    useEffect(()=>{
        if (isPremium){
            return
        }
        setCurrency(`${selectedCurrency?.id}-${selectedCurrency?.is_base_currency}-${selectedCurrency?.currency_symbol}`)
    },[version, selectedCurrency])

    const calculateTotal = (servicesArr, prdts, rate = 0, bed=[], selectedCurrency=currency) => {

        const totalPaidAmount = [...servicesArr, ...prdts, ...bed].filter(item=>item.isPaid).reduce((a, item) => {
            return +(a + item.amount)
        }, 0)

        const overallPaidAmount = selectedCurrency.includes('true') ? totalPaidAmount :  (totalPaidAmount ?   totalPaidAmount / rate : 0)


        //usd total amount
        const totalBedBill = bed.reduce((a, item) => {
            return +(a + item.amount)
        }, 0);

        const totalProduct = prdts.reduce((a, item) => {
            return +(a + item.subTotal)
        }, 0);

        const totalService = servicesArr.reduce((a, item) => {
            return +(a + item.amount)
        }, 0);

        // non usd total amount
        const currency_service_total = servicesArr.reduce((a, item) => {
            const base_total = rate ?  (+item.rate / rate) : 0
            return a + +base_total
        }, 0)

        const product_service_total = prdts.reduce((a, item) => {
            const base_total = rate ?  (+item.rate / rate) * +item.quantity : 0
            return a + +base_total
        }, 0)

        const currency_bed_total = bed.reduce((a, item) => {
            const base_total = rate ?  (+item.rate / rate) * +item.quantity : 0
            return a + +base_total
        }, 0)

        const product_service_total_ssp = prdts.reduce((a, item) => {
            const base_total = rate ?  (+item.rate * rate) * +item.quantity : 0
            return a + +base_total
        }, 0)

        const currency_service_total_ssp = servicesArr.reduce((a, item) => {
            const base_total = rate ? +item.rate * rate : 0;
            return a + +base_total;
        }, 0);

        const currency_bed_total_ssp = bed.reduce((a, item) => {
            const base_total = rate ?  (+item.rate * rate) * +item.quantity : 0
            return a + +base_total
        }, 0)

        const non_currency_total_amt = totalProduct + totalService + totalBedBill

        const currency_total_amount =  currency_bed_total + currency_service_total + product_service_total

        const currency_total_amount_ssp =  currency_bed_total_ssp + currency_service_total_ssp + product_service_total_ssp

        const amt = paidAmount - (non_currency_total_amt);

        const bal = amt < 0 ? 0 : amt;

        setTotals({...totals, total_amount: non_currency_total_amt, balance: bal})

        setCurrencyTotal(currency_total_amount)

        setCurrencyTotalSsp(currency_total_amount_ssp)
        setPaidAmount(overallPaidAmount)
        multiplyCurrency(rate, overallPaidAmount, currency)

    }

    // const calculateBedAmount = (rate) => {
    //
    //     const bedArray = bedBill.map((bed) => {
    //
    //         return ({
    //             ...bed,
    //             currency_rate: currency.includes("true") ? (Number(rate) <= 0 ? 0 : (bed.rate * rate) ) : (Number(rate) <= 0 ? 0 : (bed.rate / rate) ),
    //             currency_total: currency.includes("true") ? (Number(rate) <= 0 ? 0 : ( (bed.rate * rate) * bed.quantity) ) : (Number(rate) <= 0 ? 0 : ( (bed.rate / rate) * bed.quantity) ),
    //             amount: Number(bed.rate) * Number(bed.quantity)
    //         })
    //     });
    //
    //     setBedBill(bedArray)
    // }

    const multiplyCurrency = (exchange_rate, amountReceived, displayedCurrency) => {

        const {remaining_balance, total_amount_paid} = receiptTotals

        let [amountInUSD, amountInSSP, balanceInSsp, paidAmountSsp] = [0,0,0,0];

        // currency is USD
        if(displayedCurrency.includes("true")){
            amountInUSD = amountReceived

            amountInSSP = Number(exchange_rate) <= 0 ? 0 : Number(exchange_rate) * Number(amountReceived)

            balanceInSsp = Number(exchange_rate) <= 0 ? 0 : Number(remaining_balance) * Number(exchange_rate)
            paidAmountSsp = Number(exchange_rate) <= 0 ? 0 : Number(total_amount_paid) * Number(exchange_rate)

            setUsdAmount(amountInUSD);

            setSspAmount(amountInSSP);

            setReceiptTotals({...receiptTotals, ssp_remaining_balance: balanceInSsp, ssp_amount_paid: paidAmountSsp})

            return;
        }

        // currency is SSP
        if(displayedCurrency.includes("false")){
            amountInSSP = amountReceived;

            amountInUSD = Number(exchange_rate) <= 0 ? 0 : Number(amountReceived) * Number(exchange_rate)

            balanceInSsp = Number(exchange_rate) <= 0 ? 0 : Number(remaining_balance) / Number(exchange_rate)
            paidAmountSsp = Number(exchange_rate) <= 0 ? 0 : Number(total_amount_paid) / Number(exchange_rate)


            setUsdAmount(amountInUSD);

            setSspAmount(amountInSSP);
            setReceiptTotals({...receiptTotals, ssp_remaining_balance: balanceInSsp, ssp_amount_paid: paidAmountSsp})

            return;
        }


    }


    //Change currency
    const handleChangeCurrency = (event) =>{
        setCurrency(event.target.value)

        // setPaidAmount('')

        setTotals({...totals, balance: 0})

        setUsdAmount(0)

        setSspAmount(0)

        setExchangeRate(0)
        setCurrencyExchangeRate('')
        setReceiptTotals({...receiptTotals, ssp_remaining_balance: 0, ssp_amount_paid: 0})
        calculateTotal(procedureData, products, Number(exchangeRate), bedBill,event.target.value)
        setIsBlocking(true)
    }

    //Change exchange rate
    const handleChangeExchangeRate = (event) =>{
        setCurrencyExchangeRate(event.target.value)
        const currency_ex_rate = Number(event.target.value) > 0 ?  1 / Number(event.target.value) : 0
        setExchangeRate(currency_ex_rate)

        setIsConverted(true);

        multiplyCurrency(Number(currency_ex_rate), paidAmount, currency)



        dispatchProductsAndServices({
            type: billingActions.CALCULATE_PRODUCT_AMOUNT,
            rate:currency_ex_rate,
            currency:currency.split('-')[1]
        });

        dispatchProductsAndServices({
            type: billingActions.CALCULATE_SERVICE_AMOUNT,
            rate:currency_ex_rate,
            currency:currency.split('-')[1]
        });
        dispatchProductsAndServices({
            type: billingActions.CALCULATE_BED_AMOUNT,
            rate:currency_ex_rate,
            currency:currency.split('-')[1]
        });



        calculateTotal(procedureData, products, Number(currency_ex_rate), bedBill)

        setIsBlocking(true)
    }

    //change amount received
    const handleChangePaidAmount = (event) => {
        const {total_amount} = totals

        const isBaseCurrency = currency.includes('true');

        setPaidAmount(event.target.value);

        const total_amt = isBaseCurrency ? +total_amount : +currencyTotal

        const amount = +(event.target.value) -  total_amt;

        const amt = amount < 0 ? 0 : amount;

        const balance_amount = total_amt > 0 ? amt : 0

        setTotals({...totals, balance: balance_amount})

        multiplyCurrency(exchangeRate, event.target.value, currency)

        setIsBlocking(true)
    };

    const handleChangeServiceCurrencyRate = (event, bill_id) => {
        const prods = products.filter(item=>!item.isExcluded)

        dispatchProductsAndServices({
            type:billingActions.CHANGE_SERVICE_CURRENCY_RATE,
            value:event.target.value,
            bill_id,
            exchangeRate,
            calculateTotal,
            products:prods,
            bedBill
        });

        setIsBlocking(true)
    }

    const handleChangeServiceRate = (event, bill_id) => {

        const prods = products.filter(item=>!item.isExcluded)

        dispatchProductsAndServices({
            type:billingActions.CHANGE_SERVICE_RATE,
            value:event.target.value,
            bill_id,
            exchangeRate,
            calculateTotal,
            products:prods,
            bedBill
        })

        setIsBlocking(true)


    }

    const handleChangeProductRate = (event, bill_id) => {
        const procedures = procedureData.filter(item=>!item.isExcluded)

        dispatchProductsAndServices({
            type:billingActions.CHANGE_PRODUCT_RATE,
            value:event.target.value,
            bill_id,
            exchangeRate,
            calculateTotal,
            services:procedures,
            bedBill
        })

        setIsBlocking(true)
    }

    const handleChangeProductCurrencyRate = (event, bill_id) => {

        const procedures = procedureData.filter(item=>!item.isExcluded)

        dispatchProductsAndServices({
            type:billingActions.CHANGE_PRODUCT_CURRENCY_RATE,
            value:event.target.value,
            bill_id,
            exchangeRate,
            calculateTotal,
            services:procedures,
            bedBill
        })

        setIsBlocking(true)
    }


    const handleDeleteServices = (item) => {
        const {bill_id} = item;
        const prods = products.filter(item=>!item.isExcluded)

        dispatchProductsAndServices({
            type:billingActions.DELETE_SERVICE,
            bill_id,
            exchangeRate,
            calculateTotal,
            products:prods,
            bedBill
        })

        setIsBlocking(true)
    };

    const handlePayProcedure = (event,item) => {
        const { bill_id } = item;
        const prods = products.filter((item) => item.isPaid);
        const beds = bedBill.filter((item) => item.isPaid);
        dispatchProductsAndServices({
            type: billingActions.PAY_SERVICE,
            bill_id,
            exchangeRate,
            calculateTotal,
            products: prods,
            value:event.target.checked,
            bedBill:beds
        });

        setIsBlocking(true);
    };

    const handlePayAllProcedures = (event) => {
        const prods = products.filter((item) => item.isPaid);
        const beds = bedBill.filter((item) => item.isPaid);
        dispatchProductsAndServices({
            type: billingActions.CHECK_ALL_SERVICES,
            exchangeRate,
            calculateTotal,
            products: prods,
            value:event.target.checked,
            bedBill:beds
        });

        setIsBlocking(true);
    };

    const handleChangeProductQuantity = (event,bill_id) =>{
        const procedures = procedureData.filter(item=>!item.isExcluded)

        dispatchProductsAndServices({
            type:billingActions.CHANGE_PRODUCT_CURRENCY_RATE,
            value:event.target.value,
            bill_id,
            exchangeRate,
            calculateTotal,
            services:procedures,
            bedBill
        })

        setIsBlocking(true)
    }

    const handleDeleteProduct = (item) => {
        const {bill_id} = item
        const procedures = procedureData.filter(item=>!item.isExcluded)

        dispatchProductsAndServices({
            type:billingActions.DELETE_PRODUCT,
            bill_id,
            exchangeRate,
            calculateTotal,
            services:procedures,
            bedBill
        })

        setIsBlocking(true)
    };

    const handlePayProduct = (event,item) => {
        const { bill_id } = item;
        const procedures = procedureData.filter((item) => item.isPaid);
        const beds = bedBill.filter((item) => item.isPaid);
        dispatchProductsAndServices({
            type: billingActions.PAY_PRODUCT,
            bill_id,
            exchangeRate,
            calculateTotal,
            services: procedures,
            value:event.target.checked,
            bedBill:beds
        });

        setIsBlocking(true);
    };

    const handlePayAllProducts = (event) => {
        const procedures = procedureData.filter((item) => item.isPaid);
        const beds = bedBill.filter((item) => item.isPaid);
        dispatchProductsAndServices({
            type: billingActions.CHECK_ALL_PRODUCTS,
            exchangeRate,
            calculateTotal,
            services: procedures,
            value:event.target.checked,
            bedBill:beds
        });

        setIsBlocking(true);
    };

    const handlePayBed = (event,item) => {
        const { bill_id } = item;
        const prods = bedBill.filter((item) => item.isPaid);
        const procedures = procedureData.filter((item) => item.isPaid);
        dispatchProductsAndServices({
            type: billingActions.PAY_BED,
            bill_id,
            exchangeRate,
            calculateTotal,
            products:prods,
            services:procedures,
            value:event.target.checked,
        });

        setIsBlocking(true);
    };

    const handlePayAllBeds = (event) => {
        const prods = products.filter((item) => item.isPaid);
        const procedures = procedureData.filter((item) => item.isPaid);
        dispatchProductsAndServices({
            type: billingActions.CHECK_ALL_BEDS,
            exchangeRate,
            calculateTotal,
            products: prods,
            services:procedures,
            value:event.target.checked,
        });

        setIsBlocking(true);
    };


    const handleChangeDate = (event) => {
        setDate(event.target.value)
        setIsBlocking(true)
    };

    const handleChangeMethod = (event) => {
        const {value} = event.target;
        setPaymentMethod(value);
        setIsBlocking(true)
    };


    const handleSubmitBill = (event) => {
        event.preventDefault();
        setIsBlocking(false)
        setSubmitted(true)
        const {due_amount, total_amount} = totals
        const {remaining_balance,ssp_remaining_balance} = receiptTotals
        const arr = products.some(item=> item.isPaid)
        const serviceArr = procedureData.some(item=> item.isPaid)
        const bedArr = bedBill.some(item=> item.isPaid)
        const isPayment = (payment_method && paidAmount === due_amount) || (payment_method && paidAmount)

        const isItems = (status !== '1' && (arr || serviceArr || bedArr)) || (status === '1' && !(arr || serviceArr || bedArr))

        const isBase = currency.split('-')[1]
        const isBaseCurrency = isBase === 'true'
        const isNotBaseCurrency = isBase === 'false'
        const total_bill = isBaseCurrency ? total_amount : currencyTotal
        const total_remaining = isBaseCurrency ? remaining_balance : (exchangeRate ? remaining_balance / exchangeRate : remaining_balance)

        const correct_payment = (isNotBaseCurrency && paidAmount <= ssp_remaining_balance) || (isBaseCurrency && paidAmount <= remaining_balance)

        const t_amount = remaining_balance > 0 ? total_remaining : total_bill
        const isDeposit  =  status === '1' ? Number(paidAmount).toFixed(3) <= Number(t_amount).toFixed(3) :
            Number(paidAmount).toFixed(3) === Number(t_amount).toFixed(3)
        if (isPayment && isItems && correct_payment) {
            setOpenPrint(true)
        }

    };

    const handleCloseReceipt = () => {
        setOpenPrint(false);
        history.push('/patientbillinglist');

    };

    const bedArr = bedBill.map(bed => ({
        assign_date: bed.assign_date,
        bill_type: bed.bill_type, invoice_no: bed.invoice_no,
        pay_status: bed.pay_status,
        visit_id: bed.visit_id,
        room_name: bed.room_name,
        bill_id: bed.bill_id,
        ward_name: bed.ward_name,
        bed_number: bed.bed_number,
        rate: bed.rate,
        bed_assignment_id: bed.bed_assignment_id,
        quantity: bed.quantity,
        isPaid:bed.isPaid
    }))


    const editedServices = invoicesEdited.filter(item=>!item.isExcluded);

    const editedProducts = productsEdited.filter(item=>!item.isExcluded);

    const paidServices = invoicesPaid.filter(item=>!item.isExcluded);

    const paidProducts = productsPaid.filter(item=>!item.isExcluded);

    const editedInvoices = [...editedServices, ...editedProducts];

    const paidInvoices = [...paidServices, ...bedArr, ...paidProducts].filter(item=>item.isPaid);

    const removedInvoices = [...invoicesRemoved, ...productsRemoved];

    const handleClickReceipt = async () => {
        try {


            const {notes} = patientDetails;

            const isBase = currency.split('-')[1]

            const isNotBase = isBase === 'false'
            const isBaseCurrency = isBase === 'true'

            const currency_id = parseInt(currency.split('-')[0]);

            const isBaseData = isBaseCurrency ?
                {
                    is_base_currency: isBase,
                    amount_paid_in_the_currency: +currencyTotal,
                    currency_id,
                    amount_paid: +paidAmount,
                    rate_input_by_cashier: +exchangeRate,
                    is_converted: isConverted
                } : {};

            const isNotBaseData = isNotBase ?
                {
                    currency_id,
                    amount_paid_in_the_currency: +currencyTotal,
                    rate_input_by_cashier: +exchangeRate,
                    total_amount_in_base_currency: +usdAmount,
                    amount_paid: +usdAmount,
                    is_base_currency: isBase

                } : {};


            const arr = products.every(item => item.quantity >= 0)

            if (arr) {
                setOpenPrint(true)

                setIsSubmitted('pending');

                const params = status === '1' ? {} : {
                    invoices_paid: paidInvoices,
                    invoices_removed: removedInvoices,
                    invoices_edited: editedInvoices
                };

                const url = status === '1' ? 'make_inpatient_deposit' : 'pay_in_patientbill';

                const response = await axios.post(
                    `${config.smsUrl}/cbilling/${url}`,
                    {
                        currency_id,
                        pay_type: +payment_method,
                        visit_id,
                        received_by: user_roles_id,
                        amount_paid: +paidAmount,
                        bill_mode: "cash",
                        memo: notes ?? '',
                        ...params,
                        ...isBaseData, ...isNotBaseData
                    }
                )

                if(response.status === 200) {
                    const data = response.data ?? {};

                    let receipt_number;

                    if (typeof data.receipt_numbers === "string" || typeof data.receipt_numbers === "number") {

                        receipt_number = data.receipt_numbers ?? '';

                    } else {

                        const numbers = data.receipt_numbers ?? [];

                        receipt_number = numbers.join('-');
                    }

                    actions.snackbarActions.snackSuccess('Bill paid successfully');

                    setIsSubmitted('resolved');

                    setOpenPrint(false)

                    return history.push(`/inpatientreceipt/${visit_id}/${receipt_number ? receipt_number : 0}`);

                }

                setIsSubmitted("rejected")

                return errorMessages()

            }

        } catch (error) {
            logoutErrorMessage(error, null, actions)
        }

    };

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const handleAddService =() =>{
        setAddService(true)

    }

    const handleAddProduct = () => {
        setAddProduct(true)
    }

    const handleCloseAddService = () => {
        // setAddService(false)
        handleCloseServiceDialog()
    }

    const handleCloseAddProduct=()=>{
        // setAddProduct(false)
        handleClosePrescriptionDialog()
    }


    const payTypesQuery = useQuery(
        ["payTypesQuery"],
        async () => {
            try {

                const response = await axios.get(`${config.smsUrl}/cbilling/payment_methods`)

                const data = response.data ?? {};

                const arr = data?.data ?? [];

                const cash = arr.map(item=>({value:item.id, text:item.payment_type_name})).filter(item=> item.text === 'CASH')

                return ({
                        payTypes: cash
                })
            } catch (error) {
                logoutErrorMessage(error, null, actions);
                return {payTypes:[]}
            }
        },
        {retry: 1, refetchOnWindowFocus: true}
    );

    const payTypes = payTypesQuery.isSuccess ? payTypesQuery.data?.payTypes : [];

    const currenciesQuery = useQuery(
        ["currenciesQuery"],
        async () => {
            try {
                const response = await axios.get(`${config.smsUrl}/accounting/currencies`)

                const data = response.data ?? {};

                const arr = data.data ?? [];

                const list = arr.map(item=>({value:`${item.id}-${item.is_base_currency}-${item.currency_symbol}`, text:`${item.currency_symbol} ${item.currency_name}`}))

                return ({
                    currencies: list
                });
            } catch (error) {
                logoutErrorMessage(error, null, actions);
                return {currencies:[]}
            }
        },
        {retry: 1, refetchOnWindowFocus: true}
    );

    const currencies = currenciesQuery?.isSuccess ? currenciesQuery?.data?.currencies : [];



    const inPatientDepositsMakeDepositQuery = useQuery(
        ["inPatientDepositsMakeDepositQuery",isPrescription, isServiceResolved],
        async () => {
            try{
                const formData = new FormData();

                formData.append('visit_id', visit_id);

                formData.append('patient_admission_id', patient_admission_id);

                formData.append('pay_status', pay_status);

                formData.append('invoice_number', '');

                const response = await axios.post(`${config.smsUrl}/cbilling/get_inpatient_bill`, formData)

                const data = response.data ?? {};

                const bill_details = data ?? {};

                setBillDetails(data);

                const {patient_info, admission_bill, receipts, patient_deposit, balance} = bill_details;

                const p_info = patient_info ?? [];

                const receiptsArr = receipts ?? [];

                const receiptsObj = receiptsArr[receiptsArr.length-1] ? receiptsArr[receiptsArr.length-1] : {}

                const pt_info = p_info[0] ?? {};

                const service_bill = admission_bill ?? [];
                const deposit = patient_deposit ? patient_deposit : {}

                const base_currency = bill_details.base_currency ? bill_details.base_currency : {}

                const {
                    admission_date, discharge_date, p_address, p_dob, p_first_name,
                    p_gender, p_last_name, patient_number, phone_no
                } = pt_info;

                setCurrency(`${base_currency?.id}-${base_currency?.is_base_currency}-${base_currency?.currency_symbol}`)


                const srvc = filterInpatientServices(exchangeRate,service_bill.filter(service => service.service_id))

                const prdts = filterInpatientProducts(exchangeRate, service_bill.filter(prod => prod.product_id))



                const bd = filterInpatientBeds(exchangeRate, service_bill.filter(bd => bd.bed_assignment_id))

                setPaidReceipts(receiptsArr)

                const amount_paid = receiptsArr.reduce((prevValue, amount)=>prevValue + amount.amount_paid, 0)

                setReceiptTotals({...receiptTotals,total_amount_paid: patient_deposit?.bill, remaining_balance: balance ? balance : 0})


                // setBedBill(bd)

                dispatchProductsAndServices({
                    type: billingActions.RETRIEVE_PRODUCTS_AND_SERVICES,
                    services: srvc,
                    products: prdts,
                    bed: bd,
                    exchangeRate,
                    calculateTotal
                });

                return ({
                    patientDetails: {
                        patient_number,
                        first_name: p_first_name,
                        last_name: p_last_name,
                        address: p_address,
                        dob: p_dob,
                        gender: p_gender,
                        admission_date,
                        discharge_date,
                        phone_no
                    },
                })

            } catch (error) {
                logoutErrorMessage(error, null, actions)
                return { patientDetails: {}}
            }
        },
        {retry: 1,
            // refetchInterval:3000
        },
    );

    const {patientDetails} = inPatientDepositsMakeDepositQuery.isSuccess ? inPatientDepositsMakeDepositQuery.data : {
        patientDetails: {
            patient_number: "",
            first_name: "",
            last_name: "",
            address: "",
            dob: null,
            gender: "",
            admission_date: null,
            discharge_date: null,
            phone_no: ""
        },
    };

    const isExcluded = (patientDetails.first_name + ' ' + patientDetails.last_name) && (paidInvoices.length > 0 || editedInvoices.length > 0);



    // const query = useQuery(
    //     [""],
    //     async () => {
    //         try {
    //
    //         } catch (error) {
    //             logoutErrorMessage(error, null, actions);
    //         }
    //     },
    //     {retry: 1, refetchOnWindowFocus: true}
    // );
    //
    // const query = useQuery(
    //     [""],
    //     async () => {
    //         try {
    //
    //         } catch (error) {
    //             logoutErrorMessage(error, null, actions);
    //         }
    //     },
    //     {retry: 1, refetchOnWindowFocus: true}
    // );
    //
    // const query = useQuery(
    //     [""],
    //     async () => {
    //         try {
    //
    //         } catch (error) {
    //             logoutErrorMessage(error, null, actions);
    //         }
    //     },
    //     {retry: 1, refetchOnWindowFocus: true}
    // );

    return({
        payment_method,
        patientDetails,
        bedBill,
        services: procedureData,
        products,
        isSubmitted,
        date,
        openPrint,
        paidAmount,
        submitted,
        payTypes,
        currencies,
        currency,
        exchangeRate,
        usdAmount,
        sspAmount,
        currencyTotal,
        currencyTotalSsp,
        totals,
        isBlocking,
        isExcluded,
        receiptTotals,
        paidReceipts,
        addProduct,
        addService,
        renderService ,
        renderPrescription,
        handleChangePaidAmount,
        handleDeleteServices,
        handleChangeDate,
        handleChangeMethod,
        handleSubmitBill,
        handleCloseReceipt,
        handleClickReceipt,
        closeSnackbar,
        handleChangeProductRate,
        handleChangeServiceRate,
        handleDeleteProduct,
        handleChangeProductQuantity,
        handleAddProduct,
        handleAddService,
        handleCloseAddService,
        handleCloseAddProduct,
        handleChangeExchangeRate,
        handleChangeServiceCurrencyRate,
        handleChangeProductCurrencyRate,
        handleChangeCurrency,
        currencyExchangeRate,
        handlePayProcedure,
        handlePayProduct,
        checkAllProducts,
        checkAllServices,
        handlePayAllProcedures,
        handlePayAllProducts,
        checkAllBeds,
        handlePayBed,
        handlePayAllBeds
    })
}