import * as types from "../actions/actionTypes";


export default function (state = {}, action) {
    switch (action.type) {
        case types.BED_REQUEST:
            return {isSubmitted: true};
        case types.BED_SUCCESS:
            return {isSubmitted: false};
        case types.BED_ERROR:
            return {isSubmitted: false};
        default:
            return state;

    }
}
