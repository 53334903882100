import React from "react";
import Form from "react-bootstrap/Form";
import Label from "../../Utils/FormInputs/Label";
import SelectInput from "../../Utils/FormInputs/SelectInput";
import TextField from "../../Utils/FormInputs/TextField";
import { ReusablePhoneInput } from "../../Utils/FormInputs/FormGroup";
import { ErrorMessage } from "../../Utils/styledComponents";
import { RightAlignedContainer } from "../../Utils/styledComponents";
import { CustomSelect } from "../../Utils/FormInputs/SelectInput";

function NewProvider({state, handleChange, handleSubmit, contact, options,selectedOptions,setContact, isPending, handleUpdateProvider, isEditProvider,handleChangeOption,
    submitted, currencies, handleChangeCurrency, currency, handleOpenDialogScheme, renderAddScheme,checkList, selectedChecks, handleChangeInsuranceChecks, providerTypes=[]}) {

    const {provider_name, provider_type, discount} = state;


    const isInsurance = provider_type === 'Insurance Company'
    return (
        <div>
            {renderAddScheme}
            <RightAlignedContainer>
                        <button onClick={handleOpenDialogScheme}  className='btn btn-sm sms-info-btn mr-4'>Quick Add Scheme</button>
            </RightAlignedContainer>
        <form onSubmit={isEditProvider ? handleUpdateProvider : handleSubmit}  autoComplete="off">
            
            <Form.Group>
                <Label name="Provider Type" type htmlFor="provider_type"/>
                <SelectInput submitted={submitted} value={provider_type} testId="provider_type"
                             defaultOption="Select provider type..." name="provider_type"
                             options={providerTypes} onChange={handleChange} id="provider_type"/>
            </Form.Group>
            <Form.Group>
                <Label name="Provider Name" type htmlFor="provider_name"/>
                <TextField submitted={submitted} value={provider_name} name="provider_name" onChange={handleChange} id="provider_name"/>
                {submitted && !provider_name ? <ErrorMessage>This field is required</ErrorMessage>:null}
            </Form.Group>
            <Form.Group>
                <Label name="Contact" htmlFor="provider_contact"/>
                <ReusablePhoneInput contact={contact} setContact={setContact} id="provider_contact"/>
            </Form.Group>
            <Form.Group>
                <Label name="Currency"  type htmlFor="provider_currency"/>
                    <SelectInput submitted={submitted} value={currency} id="provider_currency"
                                                 onChange={handleChangeCurrency} options={currencies}
                                                 defaultOption="Select..." testId='provider_currency'/>
            </Form.Group>
            {isInsurance ? <>
                <Form.Group>
                    <Label name="Scheme" htmlFor="insurance_scheme" type/>
                    <CustomSelect  value={selectedOptions} options={options} submitted={submitted}
                                onChange={handleChangeOption} id="scheme" isMulti/>
                    {submitted && isInsurance && selectedOptions?.length === 0 ? <ErrorMessage>Insurance scheme(s) is required</ErrorMessage>:null}
                </Form.Group>

                <Form.Group>
                    <Label name="Approval checks" htmlFor="approval_checks" type/>
                    <CustomSelect  value={selectedChecks} options={checkList}
                                   onChange={handleChangeInsuranceChecks} id="insurance checks" isMulti/>
                    {/*{submitted && isInsurance && selectedChecks?.length === 0 ? <ErrorMessage>Approval check(s) is required</ErrorMessage>:null}*/}
                </Form.Group>

                <Form.Group>
                    <Label name="Co-payment(%)" htmlFor="discount"/>
                    <TextField submitted={submitted} value={discount} name="discount" onChange={handleChange} id="discount"/>
                </Form.Group>
            </>: null}
         
            <button type="submit"  className="btn btn-sm sms-btn px-4"
                    disabled={isPending}>
                {isPending ? "Saving..." : "Save"}</button>
        </form>
        </div>
        
    )
}

export {NewProvider}
