import * as types from "../actionTypes";
import axios from "axios";
import { config } from "../../Helpers/env";
// import { userService } from "../services/userService";
import {snackError, snackSuccess} from "../snackbarActions";
import {errorMessages, logoutErrorMessage} from "../../Helpers/ErrorMessages";

// import {loadDepartments} from "./departmentActions"
//
// export function createDoctorSuccess(user) {
//   return {type: types.CREATE_DOCTOR_SUCCESS, user};
// }
//
// function createKinSuccess(user) {
//   return {type: types.CREATE_KIN_SUCCESS, user};
// }
//
// export function saveKin(kin) {
//   return {type: types.SAVE_KIN_SUCCESS, kin};
// }
//
// export function saveDoctorSuccess(doctor) {
//   return {type: types.SAVE_DOCTOR_SUCCESS, doctor};
// }
//
// export function createReceptionistSuccess(user) {
//   return {type: types.CREATE_RECEPTIONIST_SUCCESS, user};
// }
//
// export function saveReceptionistSuccess(receptionist) {
//   return {type: types.SAVE_RECEPTIONIST_SUCCESS, receptionist};
// }
//
// export function saveTechnicianSuccess(technician) {
//   return {type: types.SAVE_TECHNICIAN_SUCCESS, technician};
// }
//
// export function registerUserSuccess(doctor) {
//   return {type: types.USERS_REGISTER_SUCCESS, doctor};
// }
//
// function registerFailure(error) {
//   return {
//     type: types.USERS_REGISTER_FAILURE,
//     error
//   };
// }
//
// export function loginRequest(user) {
//   return {
//     type: types.USERS_LOGIN_REQUEST,
//     user
//   };
// }
//
// export function loginSuccess(user) {
//   return {
//     type: types.USERS_LOGIN_SUCCESS,
//     user
//   };
// }
//
// export function loginFailure(error) {
//   return {
//     type: types.LOGIN_FAILURE,
//     error
//   };
// }
//
// export function doctorloginRequest(doctor) {
//   return {
//     type: types.DOCTORS_LOGIN_REQUEST,
//     doctor
//   };
// }
//
// export function userloginSuccess(user) {
//   return {
//     type: types.DOCTORS_LOGIN_SUCCESS,
//     user
//   };
// }
//
// export function doctorloginFailure(error) {
//   return {
//     type: types.DOCTORS_LOGIN_FAILURE,
//     error
//   };
// }
//
// export function loadDoctorSuccess(doctors) {
//   return {
//     type: types.LOAD_DOCTORS,
//     doctors
//   };
// }
//
// export function clearDoctorsList(doctors) {
//   return {type: types.LOAD_USERS, doctors};
// }


export function loadUserSuccess(doctors) {
    return {type: types.LOAD_USERS, doctors};
}

export const getWaitingList = (page, formData) => (dispatch) => {
    dispatch({type: types.GET_DOCTOR_WAITING_LIST_REQUEST});
    return axios.post(`${config.smsUrl}/cdoctor/doctor_waiting_list/${page}`, formData).then(response => {
        const data = response.data;
        const dt = !data ? {} : data;
        const list = !dt.waiting_list ? [] : dt.waiting_list;
        const doc_list = list.map((item, index) => ({
            count: ((page - 1) * 10) +  index + 1,
            date: item.begin_datetime,
            patient_number: item.patient_number,
            patient_name: `${!item.first_name ? "" : item.first_name} ${!item.last_name ? "" : item.last_name}`,
            status: item.status,
            visit_id: item.visit_id
        }))
        const total_count = !dt.total_count ? 0 : dt.total_count;
        dispatch({type: types.WAITING_LIST_TOTAL_COUNT, total_count});
        dispatch({type: types.GET_DOCTOR_WAITING_LIST, waitingList: doc_list})
    }).catch((err) => {
        dispatch({type: types.GET_DOCTOR_WAITING_LIST_ERROR});
        logoutErrorMessage(err, dispatch, null)
    })
};

export function loadDoctor(formData) {
    return function (dispatch) {
        return axios.post(config.smsUrl + "/cvisit/getdoctorsbydepartment_id", formData)
            .then(response => {
                const res = response.data;
                const data = !res ? {} : res;
                const doctors = !data.doctors ? [] : data.doctors;
                dispatch(loadUserSuccess(doctors));
            })
            .catch(error => {
                logoutErrorMessage(error, dispatch, null)
            });
    };
}


// export function createDoctor(imageFile, user, relationship, userdoctor, department_id, username, password, user_type,) {
//   return function (dispatch) {
//     const configuration = {
//       headers: {'Content-Type': 'multipart/form-data'}
//     };
//     axios.post(config.smsUrl + "/users/saveUser", {user}).then(response => {
//       if (response.data.status === 200) {
//         const _id = response.data.user_id;
//         user = userdoctor;
//         axios.post(config.smsUrl + "/users/saveUser", {user})
//           .then(response => {
//             const doctor_user_id = response.data.user_id;
//             console.log(response);
//             dispatch(createKinSuccess(_id));
//             axios
//               .get(config.smsUrl + "/kins/saveKin", {
//                 params: {
//                   relationship: relationship,
//                   user_one: _id,
//                   user_two: doctor_user_id
//
//                 }
//               })
//               .then(res => {
//                 console.log(res.data);
//                 const kin = res.data;
//                 const kin_id = res.data.kin.kin_id;
//                 console.log(kin_id);
//                 console.log(user_type);
//                 axios
//                   .get(config.smsUrl + "/users/adduserlogin", {
//                     params: {
//                       username: username,
//                       password: password,
//                       user_type: user_type,
//                       user_id: doctor_user_id
//                     }
//                   })
//                   .then(response => {
//                     console.log(doctor_user_id);
//                     if (response.status === 200) {
//                       dispatch(snackSuccess("User saved Successfully"));
//                       const formData = new FormData();
//                       formData.append('file', imageFile);
//                       formData.append('user_id', doctor_user_id);
//                       axios.post(config.smsUrl + "/users/upload",
//                         // null,
//                         formData,
//                         configuration
//                       ).then(() => {
//                         if ((user_type).toLowerCase() === "admin") {
//                           axios.get(config.smsUrl + "/admin/getRoleByRoleName", {params: {role_name: "admin"}}).then(response => {
//                             const role_id = response.data.role_id;
//                             axios.post(config.smsUrl + "/admin/addUserRole", null,
//                               {
//                                 params:
//                                   {
//                                     role_id,
//                                     user_id: doctor_user_id
//                                   }
//                               }).then(response => {
//                               axios
//                                 .post(config.smsUrl + "/admin/addAdmin", null, {
//                                   params: {
//                                     user_id: doctor_user_id,
//                                   }
//                                 })
//                                 .then(response => {
//                                   console.log(response);
//                                   const admin = response.data;
//                                   console.log(admin);
//                                   const user_id = response.data.admin.user.user_id;
//                                   const kin_id = response.data.admin.kin_id;
//                                   axios
//                                     .post(config.smsUrl + "/doctors/saveDoctor", null, {
//                                       params: {
//                                         user_id: user_id,
//                                         department_id: department_id,
//                                       }
//                                     })
//                                     .then(response => {
//                                       console.log(response);
//                                       const doctor = response.data;
//                                       console.log(doctor);
//                                     });
//                                   axios
//                                     .get(config.smsUrl + "/receptionists/saveReceptionist", {
//                                       params: {
//                                         user_id: user_id,
//                                       }
//                                     })
//                                     .then(response => {
//                                       console.log(response);
//                                       const receptionist = response.data;
//                                       console.log(receptionist);
//                                       // dispatch(saveReceptionistSuccess(receptionist));
//                                     });
//                                   axios
//                                     .post(config.smsUrl + "/labtechnician/savelabtechnician", null, {
//                                       params: {
//                                         department_id: department_id,
//                                         user_id: user_id,
//                                       }
//                                     })
//                                     .then(response => {
//                                       console.log(response);
//                                       const technician = response.data;
//                                       console.log(technician);
//                                       // dispatch(saveTechnicianSuccess(technician));
//                                     });
//                                   axios
//                                     .post(config.smsUrl + "/pharmacists/savepharmacist", null, {
//                                       params: {
//                                         user_id: user_id,
//                                       }
//                                     })
//                                     .then(response => {
//                                       console.log(response);
//                                       const technician = response.data;
//                                       console.log(technician);
//                                       // dispatch(saveTechnicianSuccess(technician));
//                                     });
//                                   axios
//                                     .post(config.smsUrl + "/radiologists/addRadiologist", null, {
//                                       params: {
//                                         user_id: user_id,
//                                       }
//                                     })
//                                     .then(response => {
//                                       console.log(response);
//                                       const technician = response.data;
//                                       console.log(technician);
//                                       // dispatch(saveTechnicianSuccess(technician));
//                                     });
//                                 });
//                             })
//                           });
//
//                         }
//                         if ((user_type).toLowerCase() === "doctor") {
//                           axios.get(config.smsUrl + "/admin/getRoleByRoleName", {params: {role_name: "doctor"}}).then(response => {
//                             const role_id = response.data.role_id;
//                             axios.post(config.smsUrl + "/admin/addUserRole", null, {
//                               params: {
//                                 role_id,
//                                 user_id: doctor_user_id
//                               }
//                             })
//                               .then(response => {
//                                 axios
//                                   .post(config.smsUrl + "/doctors/saveDoctor", null, {
//                                     params: {
//                                       user_id: doctor_user_id,
//                                       department_id: department_id,
//                                     }
//                                   })
//                                   .then(response => {
//                                     console.log(response);
//                                     const doctor = response.data;
//                                     console.log(doctor);
//                                     // dispatch(saveDoctorSuccess(doctor));
//                                   });
//                               });
//                           });
//                         }
//                         if ((user_type).toLowerCase() === "receptionist") {
//                           axios.get(config.smsUrl + "/admin/getRoleByRoleName", {params: {role_name: "receptionist"}}).then(response => {
//                             const role_id = response.data.role_id;
//                             axios.post(config.smsUrl + "/admin/addUserRole", null, {
//                               params: {
//                                 role_id,
//                                 user_id: doctor_user_id
//                               }
//                             })
//                               .then(response => {
//                                 axios
//                                   .get(config.smsUrl + "/receptionists/saveReceptionist", {
//                                     params: {
//                                       user_id: doctor_user_id,
//                                     }
//                                   })
//                                   .then(response => {
//                                     console.log(response);
//                                     const receptionist = response.data;
//                                     console.log(receptionist);
//                                     // dispatch(saveReceptionistSuccess(receptionist));
//                                   });
//
//                               })
//                           });
//                         }
//                         if ((user_type).toLowerCase() === "technician") {
//                           axios.get(config.smsUrl + "/admin/getRoleByRoleName", {params: {role_name: "technician"}}).then(response => {
//                             const role_id = response.data.role_id;
//                             axios.post(config.smsUrl + "/admin/addUserRole", null, {
//                               params: {
//                                 role_id,
//                                 user_id: doctor_user_id
//                               }
//                             })
//                               .then(response => {
//                                 axios
//                                   .post(config.smsUrl + "/labtechnician/savelabtechnician", null, {
//                                     params: {
//                                       user_id: doctor_user_id,
//                                     }
//                                   })
//                                   .then(response => {
//                                     console.log(response);
//                                     const technician = response.data;
//                                     console.log(technician);
//                                     // dispatch(saveTechnicianSuccess(technician));
//                                   });
//                               })
//                           });
//
//                         }
//                         if ((user_type).toLowerCase() === "pharmacist") {
//                           axios.get(config.smsUrl + "/admin/getRoleByRoleName", {params: {role_name: "pharmacist"}}).then(response => {
//                             const role_id = response.data.role_id;
//                             axios.post(config.smsUrl + "/admin/addUserRole", null, {
//                               params: {
//                                 role_id,
//                                 user_id: doctor_user_id
//                               }
//                             })
//                               .then(response => {
//                                 axios
//                                   .post(config.smsUrl + "/pharmacists/savepharmacist", null, {
//                                     params: {
//                                       user_id: doctor_user_id,
//                                     }
//                                   })
//                                   .then(response => {
//                                     console.log(response);
//                                     const technician = response.data;
//                                     console.log(technician);
//                                     // dispatch(saveTechnicianSuccess(technician));
//                                   });
//                               })
//                           });
//
//                         }
//                         if ((user_type).toLowerCase() === "radiologist") {
//                           axios.get(config.smsUrl + "/admin/getRoleByRoleName", {params: {role_name: "radiologist"}}).then(response => {
//                             const role_id = response.data.role_id;
//                             axios.post(config.smsUrl + "/admin/addUserRole", null, {
//                               params: {
//                                 role_id,
//                                 user_id: doctor_user_id
//                               }
//                             }).then(response => {
//                               axios
//                                 .post(config.smsUrl + "/radiologists/addRadiologist", null, {
//                                   params: {
//                                     user_id: doctor_user_id,
//                                   }
//                                 })
//                                 .then(response => {
//                                   console.log(response);
//                                   const technician = response.data;
//                                   console.log(technician);
//                                   // dispatch(saveTechnicianSuccess(technician));
//                                 });
//                             })
//                           })
//                         }
//                       })
//                     } else {
//                       dispatch(snackError("User Aready Exists"));
//                     }
//                   })
//               })
//           });
//
//         // });
//       }
//     });
//
//   };
// }
//
// export function createDoctorWithoutImage(user, relationship, userdoctor, department_id, username, password, user_type,) {
//   return function (dispatch) {
//
//     axios.post(config.smsUrl + "/users/saveUser", {user}).then(response => {
//       if (response.data.status === 200) {
//         const _id = response.data.user_id;
//         user = userdoctor;
//         axios
//           .post(config.smsUrl + "/users/saveUser", {user})
//           .then(response => {
//             const doctor_user_id = response.data.user_id;
//             console.log(response);
//             dispatch(createKinSuccess(_id));
//             axios
//               .get(config.smsUrl + "/kins/saveKin", {
//                 params: {
//                   relationship: relationship,
//                   user_one: _id,
//                   user_two: doctor_user_id
//
//                 }
//               })
//               .then(res => {
//                 console.log(res.data);
//                 const kin = res.data;
//                 const kin_id = res.data.kin.kin_id;
//                 // const user_id = res.data.kin.user_two
//                 console.log(kin_id);
//                 console.log(user_type);
//                 // dispatch(createDoctorSuccess(_id));
//
//                 axios
//                   .get(config.smsUrl + "/users/adduserlogin", {
//                     params: {
//                       username: username,
//                       password: password,
//                       user_type: user_type,
//                       user_id: doctor_user_id
//                     }
//                   })
//                   .then(response => {
//                     // dispatch(registerUserSuccess(response));
//                     if (response.status === 200) {
//                       dispatch(snackSuccess("User saved Successfully"));
//                       if ((user_type).toLowerCase() === "admin") {
//                         axios.get(config.smsUrl + "/admin/getRoleByRoleName", {params: {role_name: "admin"}}).then(response => {
//                           const role_id = response.data.role_id;
//                           axios.post(config.smsUrl + "/admin/addUserRole", null,
//                             {
//                               params:
//                                 {
//                                   role_id,
//                                   user_id: doctor_user_id
//                                 }
//                             }).then(response => {
//                             axios
//                               .post(config.smsUrl + "/admin/addAdmin", null, {
//                                 params: {
//                                   user_id: doctor_user_id,
//                                 }
//                               })
//                               .then(response => {
//                                 console.log(response);
//                                 const admin = response.data;
//                                 console.log(admin);
//                                 const user_id = response.data.admin.user.user_id;
//                                 const kin_id = response.data.admin.kin_id;
//                                 axios
//                                   .post(config.smsUrl + "/doctors/saveDoctor", null, {
//                                     params: {
//                                       user_id: user_id,
//                                       department_id: department_id,
//                                     }
//                                   })
//                                   .then(response => {
//                                     console.log(response);
//                                     const doctor = response.data;
//                                     console.log(doctor);
//                                     // dispatch(saveDoctorSuccess(doctor));
//                                   });
//                                 axios
//                                   .get(config.smsUrl + "/receptionists/saveReceptionist", {
//                                     params: {
//                                       user_id: user_id,
//                                     }
//                                   })
//                                   .then(response => {
//                                     console.log(response);
//                                     const receptionist = response.data;
//                                     console.log(receptionist);
//                                     // dispatch(saveReceptionistSuccess(receptionist));
//                                   });
//                                 axios
//                                   .post(config.smsUrl + "/labtechnician/savelabtechnician", null, {
//                                     params: {
//                                       department_id: department_id,
//                                       user_id: user_id,
//                                     }
//                                   })
//                                   .then(response => {
//                                     console.log(response);
//                                     const technician = response.data;
//                                     console.log(technician);
//                                     // dispatch(saveTechnicianSuccess(technician));
//                                   });
//                                 axios
//                                   .post(config.smsUrl + "/pharmacists/savepharmacist", null, {
//                                     params: {
//                                       user_id: user_id,
//                                     }
//                                   })
//                                   .then(response => {
//                                     console.log(response);
//                                     const technician = response.data;
//                                     console.log(technician);
//                                     // dispatch(saveTechnicianSuccess(technician));
//                                   });
//                                 axios
//                                   .post(config.smsUrl + "/radiologists/addRadiologist", null, {
//                                     params: {
//                                       user_id: user_id,
//                                     }
//                                   })
//                                   .then(response => {
//                                     console.log(response);
//                                     const technician = response.data;
//                                     console.log(technician);
//                                     // dispatch(saveTechnicianSuccess(technician));
//                                   });
//                               });
//                           })
//                         });
//
//                       }
//                       if ((user_type).toLowerCase() === "doctor") {
//                         axios.get(config.smsUrl + "/admin/getRoleByRoleName", {params: {role_name: "doctor"}}).then(response => {
//                           const role_id = response.data.role_id;
//                           axios.post(config.smsUrl + "/admin/addUserRole", null, {
//                             params: {
//                               role_id,
//                               user_id: doctor_user_id
//                             }
//                           })
//                             .then(response => {
//                               axios
//                                 .post(config.smsUrl + "/doctors/saveDoctor", null, {
//                                   params: {
//                                     user_id: doctor_user_id,
//                                     department_id: department_id,
//                                   }
//                                 })
//                                 .then(response => {
//                                   console.log(response);
//                                   const doctor = response.data;
//                                   console.log(doctor);
//                                   // dispatch(saveDoctorSuccess(doctor));
//                                 });
//                             });
//                         });
//                       }
//                       if ((user_type).toLowerCase() === "receptionist") {
//                         axios.get(config.smsUrl + "/admin/getRoleByRoleName", {params: {role_name: "receptionist"}}).then(response => {
//                           const role_id = response.data.role_id;
//                           axios.post(config.smsUrl + "/admin/addUserRole", null, {
//                             params: {
//                               role_id,
//                               user_id: doctor_user_id
//                             }
//                           })
//                             .then(response => {
//                               axios
//                                 .get(config.smsUrl + "/receptionists/saveReceptionist", {
//                                   params: {
//                                     user_id: doctor_user_id,
//                                   }
//                                 })
//                                 .then(response => {
//                                   console.log(response);
//                                   const receptionist = response.data;
//                                   console.log(receptionist);
//                                   // dispatch(saveReceptionistSuccess(receptionist));
//                                 });
//
//                             })
//                         });
//                       }
//                       if ((user_type).toLowerCase() === "technician") {
//                         axios.get(config.smsUrl + "/admin/getRoleByRoleName", {params: {role_name: "technician"}}).then(response => {
//                           const role_id = response.data.role_id;
//                           axios.post(config.smsUrl + "/admin/addUserRole", null, {
//                             params: {
//                               role_id,
//                               user_id: doctor_user_id
//                             }
//                           })
//                             .then(response => {
//                               axios
//                                 .post(config.smsUrl + "/labtechnician/savelabtechnician", null, {
//                                   params: {
//                                     user_id: doctor_user_id,
//                                   }
//                                 })
//                                 .then(response => {
//                                   console.log(response);
//                                   const technician = response.data;
//                                   console.log(technician);
//                                   // dispatch(saveTechnicianSuccess(technician));
//                                 });
//                             })
//                         });
//
//                       }
//                       if ((user_type).toLowerCase() === "pharmacist") {
//                         axios.get(config.smsUrl + "/admin/getRoleByRoleName", {params: {role_name: "pharmacist"}}).then(response => {
//                           const role_id = response.data.role_id;
//                           axios.post(config.smsUrl + "/admin/addUserRole", null, {
//                             params: {
//                               role_id,
//                               user_id: doctor_user_id
//                             }
//                           })
//                             .then(response => {
//                               axios
//                                 .post(config.smsUrl + "/pharmacists/savepharmacist", null, {
//                                   params: {
//                                     user_id: doctor_user_id,
//                                   }
//                                 })
//                                 .then(response => {
//                                   console.log(response);
//                                   const technician = response.data;
//                                   console.log(technician);
//                                   // dispatch(saveTechnicianSuccess(technician));
//                                 });
//                             })
//                         });
//
//                       }
//                       if ((user_type).toLowerCase() === "radiologist") {
//                         axios.get(config.smsUrl + "/admin/getRoleByRoleName", {params: {role_name: "radiologist"}}).then(response => {
//                           const role_id = response.data.role_id;
//                           axios.post(config.smsUrl + "/admin/addUserRole", null, {
//                             params: {
//                               role_id,
//                               user_id: doctor_user_id
//                             }
//                           }).then(response => {
//                             axios
//                               .post(config.smsUrl + "/radiologists/addRadiologist", null, {
//                                 params: {
//                                   user_id: doctor_user_id,
//                                 }
//                               })
//                               .then(response => {
//                                 console.log(response);
//                                 const technician = response.data;
//                                 console.log(technician);
//                                 // dispatch(saveTechnicianSuccess(technician));
//                               });
//                           })
//                         })
//                       }
//                     } else {
//                       dispatch(snackError("User Aready Exists"));
//                     }
//                   })
//               })
//           });
//
//         // });
//       }
//     });
//
//   };
// }
//
//
// export function createNewStaff(imageFile, user, relationship, userdoctor, department_id, username, password, user_type) {
//   return async function (dispatch) {
//     const configuration = {
//       headers: {'Content-Type': 'multipart/form-data'}
//     };
//     try {
//       const saveKinUser = axios.post(config.smsUrl + "/users/saveUser", {user});
//       const saveStaffUser = axios.post(config.smsUrl + "/users/saveUser", {user: userdoctor});
//       const [kinUserResponse, staffUserResponse] = await Promise.all([saveKinUser, saveStaffUser]);
//       const kin_user_id = kinUserResponse.data.user_id;
//       const staff_user_id = staffUserResponse.data.user_id;
//       const saveKin = axios.get(config.smsUrl + "/kins/saveKin",
//         {params: {relationship: relationship, user_one: kin_user_id, user_two: staff_user_id}});
//       const addUserLogin = axios.get(config.smsUrl + "/users/adduserlogin",
//         {
//           params: {
//             username: username,
//             password: password,
//             user_type: user_type,
//             user_id: staff_user_id
//           }
//         });
//       await Promise.all([saveKin, addUserLogin]);
//       if (imageFile !== ' ') {
//         const formData = new FormData();
//         formData.append('file', imageFile);
//         formData.append('user_id', staff_user_id);
//         await axios.post(config.smsUrl + "/users/upload", formData, configuration)
//       }
//       const role_name = user_type.toLowerCase() === "admin" ? "admin" : user_type.toLowerCase() === "doctor" ? "doctor" :
//         user_type.toLowerCase() === "receptionist" ? "receptionist" : user_type.toLowerCase() === "technician" ? "technician" :
//           user_type.toLowerCase() === "pharmacist" ? "pharmacist" : user_type.toLowerCase() === "radiologist" && "radiologist";
//       const roleResponse = await axios.get(config.smsUrl + "/admin/getRoleByRoleName", {params: {role_name: role_name}});
//       const role_id = roleResponse.data.role_id;
//       await axios.post(config.smsUrl + "/admin/addUserRole", null, {
//         params: {
//           role_id,
//           user_id: staff_user_id
//         }
//       });
//       if ((user_type).toLowerCase() === "admin") {
//         const adminResponse = await axios.post(config.smsUrl + "/admin/addAdmin", null, {params: {user_id: staff_user_id}});
//         const admin_user_id = adminResponse.data.admin.user.user_id;
//         const saveDoctor = axios.post(config.smsUrl + "/doctors/saveDoctor", null,
//           {params: {user_id: admin_user_id, department_id: department_id,}});
//         const saveReceptionist = axios.get(config.smsUrl + "/receptionists/saveReceptionist", {params: {user_id: admin_user_id}});
//         const saveTechnician = axios.post(config.smsUrl + "/labtechnician/savelabtechnician", null,
//           {params: {department_id: department_id, user_id: admin_user_id}});
//         const savePharmacist = axios.post(config.smsUrl + "/pharmacists/savepharmacist", null,
//           {params: {user_id: admin_user_id,}});
//         const saveRadiologist = axios.post(config.smsUrl + "/radiologists/addRadiologist", null,
//           {params: {user_id: admin_user_id,}});
//         await Promise.all([saveDoctor, saveReceptionist, saveTechnician, savePharmacist, saveRadiologist]);
//       }
//       if ((user_type).toLowerCase() === "doctor") {
//         await axios.post(config.smsUrl + "/doctors/saveDoctor", null,
//           {params: {user_id: staff_user_id, department_id: department_id,}});
//       }
//       if ((user_type).toLowerCase() === "receptionist") {
//         await axios.get(config.smsUrl + "/receptionists/saveReceptionist", {params: {user_id: staff_user_id}})
//       }
//       if ((user_type).toLowerCase() === "technician") {
//         await axios.post(config.smsUrl + "/labtechnician/savelabtechnician", null,
//           {params: {department_id: department_id, user_id: staff_user_id}});
//       }
//       if ((user_type).toLowerCase() === "pharmacist") {
//         axios.post(config.smsUrl + "/labtechnician/savelabtechnician", null,
//           {params: {department_id: department_id, user_id: staff_user_id}});
//       }
//       if ((user_type).toLowerCase() === "radiologist") {
//         axios.post(config.smsUrl + "/radiologists/addRadiologist", null,
//           {params: {user_id: staff_user_id,}})
//       }
//       dispatch(snackSuccess('User Saved Successfully'));
//
//     } catch (e) {
//       console.log(e);
//       dispatch(snackError('Unsuccessful, try again'));
//     }
//
//   }
//
// }
//
//
// export function createData(doctor) {
//   return {doctor};
// }


