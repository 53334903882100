import React from "react";
import Card from "@material-ui/core/Card";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ReusablePhoneInput} from "../../Utils/FormInputs/FormGroup";
import {useTheme} from "@material-ui/core/styles";
import {Link} from "react-router-dom";
import {faListAlt} from "@fortawesome/free-solid-svg-icons/faListAlt";
import {faShippingFast} from "@fortawesome/free-solid-svg-icons/faShippingFast";
import Form from "react-bootstrap/Form";
import Label from "../../Utils/FormInputs/Label";
import Col from "react-bootstrap/Col";
import {CustomInput, CustomResizableTextarea} from "../CustomInput";

export const SupplierForm = ({state, submitted,   handleSubmit, contact,setContact, children, isResolved}) =>{
    const {supplier_name,  email, address} = state;
    return (
        <form autoComplete='off' onSubmit={handleSubmit} >
            <Form.Group as={Col}>
                <Label name='Supplier Name' type htmlFor="supplier_name"/>
                <CustomInput type="text" inputValue={supplier_name} submitted={submitted}
                   name="supplier_name" isRequired placeholder="Enter supplier" reset={isResolved} />
            </Form.Group>
            <Form.Group as={Col}>
                <Label  name="Mobile" type htmlFor="supplier_mobile"/>
                <ReusablePhoneInput {...{contact,setContact, submitted, required:true}} id="supplier_mobile"/>
            </Form.Group>
            <Form.Group as={Col}>
                <Label name='Email' htmlFor="supplier_email"/>
                <CustomInput type="email" inputValue={email} name="supplier_email" reset={isResolved}  placeholder="Enter supplier email"/>
            </Form.Group>
            <Form.Group as={Col}>
                <Label name='Address' htmlFor="supplier_adress"/>
                <CustomResizableTextarea inputValue={address}
                   name="supplier_adress" reset={isResolved} rows={2}  />
            </Form.Group>
            {children}
        </form>
    )
}


export function AddEditSupplier({state, submitted, isPending, handleChange, handleResetForm, handleSubmit,
                                    title, text, addSupplier, contact,setContact}) {


    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
  
    return (
        <div data-testid="add-edit-supplier" className='journals'>
            <PageTitle title={title}/>
            <SubHeader title="Inventory" subTitle={text}>
                <FontAwesomeIcon icon={faShippingFast}/>
            </SubHeader>
            <div className='general-ledger-header'>
                <div className="text-right">
                    <Link to="/suppliers">
                        <button className="btn sms-btn btn-sm">
                            <FontAwesomeIcon icon={faListAlt}/> Suppliers
                        </button>
                    </Link>
                </div>
            </div>
            <div className="add-service-card">
                <Card className="p-3">
                    <h6 className={`visit-heading ${palette.type === "dark" && "dark-visit-heading"}`}>{title}</h6>
                   
                <SupplierForm {...{state, submitted, isPending, handleChange, handleResetForm, handleSubmit,
                                    addSupplier, contact,setContact}}/>
                </Card>
            </div>
        </div>
    )
}


export default AddEditSupplier;
