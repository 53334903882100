import React, {useEffect, useState} from "react";
import PageTitle from "./../../../Utils/smsTitle";
import ReusableAnteNatalTabs, { anteNatalRoutes } from './ReusableAnteNatalTabs';
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../../../actions/snackbarActions";
import {connect} from "react-redux";
import MainSnackbar from "./../../../Utils/Snackbar/SmsSnackbar";
import NewDiagnosis from "../../../DoctorsModule/Diagnosis/NewDiagnosis";
import { PatientInformation } from './../../../DoctorsModule/PatientInfo/PatientInformation';
import { dateConvert } from './../../../Utils/ConvertDate';
import { usePatientDetails } from './../../../DoctorsModule/ReferralNote/usePatientDetails';
import { SubHeader } from './../../../../Containers/SubHeader';

/**
 * Component renders the Diagnosis screen in Ante Natal
 * @param {*} param0 
 * @returns 
 */
const AnteNatalDiagnosis = ({actions, snackbars,match}) => {
    const {patient_number, labrequest_id, scan_request_id, visit_id, patient_type,patient_admission_id, anc_id, anc_status} = match.params;
    const arr = anteNatalRoutes(patient_number, visit_id, labrequest_id, scan_request_id, patient_type,patient_admission_id, anc_id, anc_status);
    const [patient, setPatient] = useState({patient_name: "", age: "", gender: "", address: "", phone: ""});
    const {patient: patientDetails} = usePatientDetails(actions, patient_number, '')
    useEffect(() => {
        setPatient({
            patient_name: `${!patientDetails.first_name ? "" : patientDetails.first_name} ${!patientDetails.last_name ? "" : patientDetails.last_name}`,
            age: patientDetails.Age,
            gender: patientDetails.gender,
            address: patientDetails.address,
            phone: patientDetails.phone_no
        });
    }, [patientDetails]);

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const {patient_name, age, gender, address, phone} = patient;
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const doctor_name = `${!_token.first_name ? "" : _token.first_name} ${!_token.last_name ? "" : _token.last_name}`;
    const {openBar, type, message} = snackbars;
    return (
        <div className='journals' data-testid="ante-natal-diagnosis">
            <PageTitle title="Diagnosis"/>
            <SubHeader title="Ante Natal" subTitle="Diagnosis" doctor_name={doctor_name}>
            <div className="header-icon-margin-right-zero"><img src="/images/antenatal-blue.png" className="icon-resize surgery-icon-tabs ante-natal-green"/></div>
            </SubHeader>
            <MainSnackbar open={openBar} handleCloseBar={closeSnackbar} variant={type} message={message}/>
            <ReusableAnteNatalTabs components={arr}>
                    <PatientInformation phone={phone} gender={gender} age={age} patient_name={patient_name}
                                        address={address} patient_number={patient_number}
                                        date={dateConvert()}/>
                        <NewDiagnosis match={{visit_id, patient_number}} actions={actions}/>
            </ReusableAnteNatalTabs>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbar
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(AnteNatalDiagnosis);