import {useEffect, useState} from "react";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {convertPhone} from "../../Utils/convertPhone";
import moment from "moment";

export const useEditSchedule = (actions,match) =>{
    const [state, setState] = useState({status: '1', start_period: '', end_period: '', patient_time: '',
     slot_name:'',doctor_name:'',description:''});
    const [staff_name, setStaffName] = useState('');
    const [employees, setEmployees] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [schedule, setSchedule] = useState({});
    const [phone, setPhone] = useState();
    const [openEditDialog, setOpenEditDialog ] =useState(false)
    const [schedule_id, setScheduleId ] = useState('')

    useEffect(() => {
        axios.get(`${config.smsUrl}/chumanresource/get_all_employees`).then(res => {
            const data = res.data;
            const dt = !data ? [] : data;
            const arr =dt.map(employee => ({label:
                `${employee.first_name} ${employee.last_name}`, value: employee.employee_id}))
            setEmployees([...arr, {value:'other', label:'Other'}]);
    
        }).catch((err) => {
            logoutErrorMessage(err, null, actions)
        })
    }, []);

    useEffect(() => {
        if (schedule_id) {
            const formData = new FormData();
            formData.append('schedule_id', schedule_id);
            axios.post(`${config.smsUrl}/cshiftschedule/schedule_update_form`,formData).then(res => {
                const data = res.data;
                const dt = !data ? {} : data;
                setSchedule(dt);
            }).catch(err => {
                logoutErrorMessage(err, null, actions)
            })
        }
    }, [schedule_id]);

    useEffect(()=>{
        const s_date = schedule.slot_date ?? '';
        setState({slot_name:schedule.slot_name, start_period: schedule.start_time, end_period: schedule.end_time,
            patient_time: s_date.split('T')[0],doctor_name:schedule.schedule_owner, description: schedule.description});
        setStaffName({value:schedule.schedule_owner, label:schedule.schedule_owner});
        setPhone(schedule.phone ?? undefined)
    
    },[schedule]);


    const handleChange = event => {
        const {name, value} = event.target;
        setState({...state, [name]: value})
    };

    const handleChangeStaff = (value) => {
        setStaffName(value);
    };


    const handleSubmit = (event) => {
        event.preventDefault();
        setSubmitted(true);
        const {patient_time, start_period, end_period, slot_name, doctor_name,description} = state;
        const date = new Date();
        const current_date = moment(date).format().split('T')[0];
        const formData = new FormData();
        formData.append('schedule_id', schedule_id);
        formData.append('slot_name', slot_name);
        formData.append('name', staff_name.value === 'other' ? doctor_name : staff_name.label);
        formData.append('slot_date', patient_time);
        formData.append('phone', phone ? convertPhone(phone) : phone);
        formData.append('start_time', start_period);
        formData.append('end_time', end_period);
        formData.append('description', description);
        if ( start_period && end_period && staff_name && slot_name && patient_time >= current_date && end_period >= start_period) {
            setIsSubmitted('pending');
            axios.post(`${config.smsUrl}/cshiftschedule/schedule_update`, formData).then(() => {
                actions.snackbarActions.snackSuccess('Schedule updated successfully');
                setOpenEditDialog(false)
                setIsSubmitted('resolved');
            }).catch(err => {
                errorMessages(err, null, actions)
                setIsSubmitted('rejected');
            })
        }
    };

    const handleOpenEditDialog =(id)=>{
        setOpenEditDialog(true);
        setScheduleId(id)
    }

    const handleCloseEditDialog =()=>{
        setOpenEditDialog(false)
    }

const isResolved = isSubmitted === 'resolved'

    const handleCloseBar = () => {
        actions.snackbarActions.hideSnackbar()
    };

     const editScheduleProps = { state, staff_name, employees, submitted, isSubmitted, schedule, handleChange, handleChangeStaff,
        handleSubmit, handleCloseBar,setState, setStaffName, phone, setPhone, openEditDialog, handleOpenEditDialog, isResolved, handleCloseEditDialog }
    return {editScheduleProps  }
}
