/** @jsxImportSource @emotion/react */
import {jsx, css} from '@emotion/react'
import React, {useEffect, useState} from "react";
import {titleCase} from "../Users/addUser";
import {dateStringConvert} from "../Utils/ConvertDate";
import {ItemsTable, PrintTemplate} from "../Utils/Templates/PrintTemplate";
import {coloredThead, table, textWeight, tMargin} from "../../styles/tableStyles";
import {usePrint} from "../Utils/Templates/usePrint";
import Card from "@material-ui/core/Card";
import axios from "axios";
import {config} from "../../Helpers/env";
import {logoutErrorMessage} from "../../Helpers/ErrorMessages";

const heading = css`
  padding: 0.2rem;
`

const headingText = css`
    font-weight: 700;
`

const headData = [{key:'drug_name',value:'Drug Name'},{key:'quantity_prescribed',value:'Quantity Prescribed'},
    {key:'dosage',value:'Dosage'}, {key:'frequency',value:'Frequency'}, {key:'no_of_days',value:'Number of Days'},
    {key:'instructions',value:'Instructions'}]
const DentalPrescriptionReport = ({actions, match}) => {
    const {visit_id, prescription_id}=match

    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const doctor_name = `${!_token.first_name ? "" : _token.first_name} ${!_token.last_name ? "" : _token.last_name}`;

    const [visitDetails, setVisitDetails] = useState({})

    const [prescription, setPrescription] = useState({});
    const [drugs, setDrugs] = useState([]);
    const [others, setOthers] = useState([]);

    const other_meds = others ? others : []
    const sundries = drugs.filter(d=>  d.category_name === 'Sundries')
    const presc = drugs.filter(d=> d.category_name === 'Medicine')

    useEffect(() => {
        const formData = new FormData();
        formData.append('prescription_id', prescription_id);
        axios.post(`${config.smsUrl}/cdoctor/get_prescription_details_by_visit_id`, formData).then(res => {
            const data = res.data;
            const resp = !data ? {} : data;
            const info = !resp.patient_info ? {} : resp.patient_info;
            const presc = !resp.prescription ? [] : resp.prescription;
            const other_med = !resp.other_medicine ? [] : resp.other_medicine;
            setDrugs(presc.filter(Boolean));
            setPrescription(info);
            setOthers(other_med.filter(Boolean))
        }).catch(err => {
            logoutErrorMessage(err,null, actions)
        })
    }, []);


    useEffect(()=>{
        if(!visit_id){
            return
        }
        axios.get(`${config.smsUrl}/cvisit/${visit_id}`).then(res=>{
            const data = res.data;
            const dt = !data ? {} : data;
            setVisitDetails(dt)
        }).catch(err=>{
            logoutErrorMessage(err,null, actions)
        })
    },[visit_id]);


    const patient_details = !prescription ? {} : prescription;
    const {componentRef, handlePrint,  fontSize}  = usePrint(`Report`)

    const patientDetails = (
        <tbody>
        <tr>
            <td><span>Patient
                    Name: <strong>{titleCase(`${!patient_details.patient_firstname ? "" : patient_details.patient_firstname} 
                            ${!patient_details.patient_lastname ? "" : patient_details.patient_lastname}`)}</strong></span>
            </td>
            <td>
                <span>Date: <strong>{patient_details.begin_datetime ? dateStringConvert(patient_details.begin_datetime) : ''}</strong></span>
            </td>
        </tr>
        <tr>
            <td><span>Age: <strong>{patient_details.age}</strong></span></td>
            <td>
                <span>Address: <strong>{titleCase(!patient_details.address ? "" : patient_details.address)}</strong></span>
            </td>
        </tr>
        <tr>
            <td><span>Gender: <strong>{titleCase(!patient_details.gender ? "" : patient_details.gender)}</strong></span>
            </td>
            <td><span>Payment Mode: </span> <span
                css={[textWeight]}>{visitDetails.is_on_insurance === 1 ? 'CREDIT' : visitDetails.is_on_insurance === 0 ?  'CASH':''}</span></td>
        </tr>

        {visitDetails.is_on_insurance === 1 ? <tr>
            <td><span>Provider Name: </span> <span
                css={[textWeight]}>{visitDetails?.insurance_provider_name}</span></td>
            <td><span>Account Name: </span> <span
                css={[textWeight]}>{visitDetails?.account_name}</span></td>
        </tr> : null}
        </tbody>
    )

    const itemDetails = (
        <ItemsTable {...{headData}}>
            <tbody>
            {presc.length > 0 ? presc.map((drug, index) => (
                <tr key={index}>
                    <td><span>{drug.product_name}</span></td>
                    <td><span>{drug.quantity_prescribed || drug.quantity}</span></td>
                    <td><span>{drug.dosage}</span></td>
                    <td><span>{drug.frequency}</span></td>
                    <td><span>{drug.number_of_days}</span></td>
                    <td><span>{drug.key_information}</span></td>
                </tr>
            )):null}

            {other_meds.map((drug, index) => (
                <tr key={index}>
                    <td><span>{drug.other_medicine}</span></td>
                    <td><span>{drug.quantity_prescribed || drug.quantity}</span></td>
                    <td><span>{drug.dosage}</span></td>
                    <td><span>{drug.frequency}</span></td>
                    <td><span>{drug.number_of_days}</span></td>
                    <td><span>{drug.key_information}</span></td>
                </tr>
            ))}
            {sundries.length > 0 ?  <>
                <tr>
                    <td css={[heading,coloredThead]} colSpan={headData.length}><span css={[headingText]}>Sundries</span></td>
                </tr>
                {sundries.map((drug, index) => (

                    <tr key={index}>

                        <td colSpan={headData.length}><span>{drug.product_name}</span></td>

                    </tr>
                ))}
            </>:null}
            </tbody>
        </ItemsTable>
    )
    const footer = (
        <table css={[table, tMargin]}>
            <tr>
                <td>
                    <span>Prescribed By</span></td>
                <td align="center">
                    <span>Signature &amp; Stamp </span></td>
            </tr>
            <tr>
                <td width="350px" ><span
                    className={`doctor-stamp  mt-3 `}
                    id="doctor-stamp"><strong>{doctor_name}</strong></span></td>
                <td width="350px" align="right" ><span
                    className={`doctor-stamp  mt-3`}
                    id="doctor-stamp"/></td>
            </tr>
        </table>
    )

    return (
        <div data-testid="doc-prescription-report">
            <div className="patient-prescription-report">
                <div className="text-right">
                    <button onClick={handlePrint} className="btn sms-amber-btn px-3 mb-2">Print</button>
                </div>
                <div className="lab-report" ref={componentRef}>
                    <style>
                        {fontSize()}
                    </style>
                    <PrintTemplate title='Patient Prescription' {...{patientDetails,itemDetails, footer}}/>
                </div>
            </div>
        </div>
    )
};
export default DentalPrescriptionReport;
