import {useEffect, useRef, useState} from "react";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import { useAddRequisition } from "../../Inventory/stock/hooks/useAddRequisition";

export const useEmergencyStock = (actions,isResolvedDispatch) =>{
    const [searchValue, setSearchValue] = useState('');
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('sl');
    const [loading, setLoading] = useState(false);
    const [stock, setList] = useState([]);
    const [total_count, setCount] = useState(0);
    const [activePage, setActivePage] = useState(1)
    const [activeStatus,  setActiveStatus] = useState(1)
    const csvRef = useRef();
    const {requisitionProps, openEmmergency, setOpenEmmergency, isResolved,
    openDialog, handleCancel, handleClick, handleCloseDialog} = useAddRequisition(actions, 'patient_emergency')

    useEffect(() => {
        setLoading(true);
        const formData = new FormData();
        formData.append('search', searchValue)
        formData.append('page', activePage)
        formData.append('to_store', "Patient Emergency")
        formData.append('category_id', activeStatus)
        axios.post(`${config.smsUrl}/cpharmacy/pharmacy_stock`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const st = !dt.stock ? [] : dt.stock;
            const count = !dt.total_count ? 0 : dt.total_count;
            setCount(count)
            setList(st);
            setLoading(false);
        }).catch((err) => {
            logoutErrorMessage(err, null, actions);
            setLoading(false);
        })
    }, [activePage,searchValue, isResolved, activeStatus,isResolvedDispatch]);

    const changeActiveStatus= (status) => {
        setActiveStatus(status)
    }

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const handleSearch = (event) => {
        setSearchValue(event.target.value)

    };

    const handleChangePage = (page) =>{
        setActivePage(page)
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleOpenEmergencyDialog = () =>{
        setOpenEmmergency(true)
    }

    const handleCloseEmergencyDialog = () =>{
        setOpenEmmergency(false)
    }


    return {searchValue, order, orderBy, loading, stock, total_count, activePage,
        handleCancel,csvRef,handleClick, closeSnackbar, handleSearch, handleChangePage,
        handleRequestSort, openDialog,handleOpenEmergencyDialog, handleCloseDialog,requisitionProps,
        handleCloseEmergencyDialog,openEmmergency, changeActiveStatus, activeStatus}
}