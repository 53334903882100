import React from "react";
import Row from "react-bootstrap/Row";
import Label from "../Utils/FormInputs/Label";
import Col from 'react-bootstrap/Col';
import { DateTimeFilter } from "../Utils/ReusableComponents/DateTimeFilter";
import {CustomSelect} from "../Utils/FormInputs/SelectInput";
import Form from "react-bootstrap/Form"

/**
 * Surgery date filter function
 * @param filter_by
 * @param handleChange
 * @param start_date
 * @param end_date
 * @param submitted
 * @param handleSubmit
 * @param isPending
 * @param filterTypes
 * @param handleChangeFilterBy
 * @param filter_type
 * @param handleChangeFilterType
 * @returns {Element}
 * @constructor
 */
export const SurgeryDateFilter = ({filter_by, handleChange, start_date, end_date, submitted, handleSubmit, isPending,filterTypes,
                                      handleChangeFilterBy, filter_type,setFilterType}) => {

    const operationClasses = [
        { label: 'Minor', value: "minor" },
        { label: 'Major', value: "major" }
    ];
    const filterBy = filter_by.value ? filter_by.value : ''

    const label = {
        '2':'Department',
        '3':'Operation class',
        '4':'Surgery performed'
    }


    const filterTypeOptions = filterBy === '3' ? operationClasses : filterTypes

    const isDate = filterBy === '1'
    return (
        <div className="row mb-0">
            <div className='col-lg-3'>
                <Form.Group as={Row}>
                    <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                        <Label name="Filter By:"/>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                        <CustomSelect value={filter_by} onChange={handleChangeFilterBy} name='filter' options={[
                            {value: '1', label: 'Operation Date'},
                            {value: '2', label: 'Department'},
                            {value: '3', label: 'Operation Class'},
                            {value: '4', label: 'Surgery Performed'}]}/>
                    </Col>
                </Form.Group>
            </div>
            <div className='col-lg-9'>
                { isDate ?
                    <DateTimeFilter {...{
                    start_date,
                    end_date,
                    handleChange,
                    submitted,
                    handleSubmit,
                    isPending,
                    type: 'date'
                }}/> : <Form.Group as={Row}>
                    <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                        <Label name={label[filterBy]}/>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                        <CustomSelect value={filter_type} onChange={value=>setFilterType(value)} name='filter_type' options={filterTypeOptions}/>
                    </Col>
                </Form.Group>}
            </div>
        </div>
    )
}