import React from "react";
import {useTheme} from "@material-ui/core/styles";
import Form from "react-bootstrap/Form";
import Label from "../Utils/FormInputs/Label";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {CustomSelect} from "../Utils/FormInputs/SelectInput";
import { FormLabel, ToolTip } from "../Utils/FormInputs/Label";
import TextField from "../Utils/FormInputs/TextField";
import { titleCase } from "../Users/addUser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";

//Reusable search component
const SearchComponent = ({name, value, handleChange, handleChangeSurgeon, isOpen, handleSelectSurgeon, surgeons, isVisitingDoctor}) => {

    return (
        <>
    {isVisitingDoctor[name] ? 
        <>
            <TextField type="text" name={name} value={value} onChange={(e)=>handleChange(e, name)} autoComplete="off"/>
        </>
        :
        <>
        <TextField type="text" name={name} value={value} onChange={(e)=>handleChangeSurgeon(e, name)} autoComplete="off"/>
    
        {isOpen[name] ? 
            <Form.Control as="select" multiple id="patient_number_select"
                className="drug-ul visit-ul"  
            >
            {surgeons.map((surgeon, index) => (
                <option className="drug-li" key={index}
                    onClick={()=>handleSelectSurgeon(surgeon.label, name)}
                    value={surgeon.label} id="surgeons"
                >
                    {surgeon.label}
                </option>
            ))}
            </Form.Control>
            :
            null    
        }
        </>
    }
        </>
    )
    
}

export const InputICD = ({icdProps}) => {

    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;

    const {
        handleChangeStatus,  allCodes, retrieveAllCodes,  getDetails,
        showDiagnosis, disease, error, status, codes, removeRow
    } = icdProps 

    const val = status === 'disease' ? disease :   disease.toUpperCase();
    const isDisease = status === 'disease';
    
    return (
        <div className="patient-triage mt-1">
            <div className="row">
                <div className="col-md-10 searchable">
                    <Form.Group as={Row}>
                        <Label name="Search by:" sm={2} column/>
                        <Col sm={4}>
                            <Form.Check inline value="disease" onChange={handleChangeStatus}
                                        name="status" label="Disease" type="radio"
                                        checked={status === 'disease'} className="form__label"/>
                            <Form.Check inline value="code" onChange={handleChangeStatus}
                                        name="status" label="ICD code" type="radio" className="form__label"/>
                        </Col>
                        <Col sm={5}>
                            <Form.Control value={disease ? val : ''} type="text"
                                        name="disease_name" data-testid="disease_name"
                                        className={`form__control  ${palette.type === "dark" && 'form-control-dark'}`}
                                        onChange={retrieveAllCodes}
                                        placeholder={`${status === 'disease' ? 'Enter keyword' : 'Enter ICD Code'}`}/>
                            {error && <div
                                className="help-block">{`Please enter a valid ${status === 'disease' ? 'keyword' : 'code'}`}</div>}
                            {showDiagnosis &&
                            <Form.Control as="select" className="drug-ul diagnosis-ul" multiple>
                                {allCodes.map((val, idx) => (
                                    <option key={idx} value={val.code} className="drug-li"
                                            data-testid="lab-tests"
                                            onClick={() => getDetails({
                                                value: val.code,
                                                innerText: val.diagnosis
                                            })}>
                                        {`${val.code} - ${titleCase(val.diagnosis)}`}</option>
                                ))}
                            </Form.Control>}
                        </Col>
                        <Col sm={1}>
                            <ToolTip toolTip={isDisease ? 'Enter disease name according to the ICD Standard' : 'Enter ICD code'}/>
                        </Col>

                    </Form.Group>
                </div>
            </div>
            <div className="table-responsive">
                <table
                    className={`table table-sm table-bordered billing-invoice-table procedure-table ${palette.type === 'dark' &&
                    'dark-table dark-table-bordered'}`}>
                    <thead>
                    <th className={palette.type === 'dark' && 'dark-theme-color'}>Disease</th>
                    <th className={palette.type === 'dark' && 'dark-theme-color'}>ICD code</th>
                    <th width="80px">Action</th>
                    </thead>
                    <tbody>
                    {codes.filter(item=>item.code).map((test, idx) => (
                        <tr key={idx}>
                            <td>{titleCase(test.diagnosis)}</td>
                            <td className={palette.type === 'dark' && 'dark-theme-color'}>{test.code}</td>
                            <td>
                                <div className="btn-group">
                                    <button onClick={() => removeRow(idx)} type="button"
                                            className="btn btn-sm sms-btn-dismiss"
                                            data-testid="remove-test-name">
                                        <FontAwesomeIcon icon={faTrash}/>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    ))}

                    </tbody>
                </table>
            </div>
        </div>
    )
}

export const DoctorsDetails = ({
    other_anaestheists, other_surgeons, 
    handleOtherAnaestheists, handleOtherSurgeons, handleChangeDoctors,
    isVisitingDoctor,handleChange, inputDoc,
    anaesthesia_type, anaesthesiaTypes, handleAnesTypeChange, 
    general_notes, handleNotesState,
    surgeon,surgeons, isOpen,
    handleChangeSurgeon, handleSelectSurgeon,
    doctorsModule= false,
    visitModule=false,
    completeSurgery=false,
    pendingSurgeryModule=false,
    startSurgery=false,

}) => {
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    const {
        preoperative_diagnosis,  preoperative_instructions //, post_operative_instructions, summary_report, planned_procedures,
    } = general_notes;

    const {lead_surgeon, lead_anaestheist, anaesthesiologist, 
        scrub_nurse, circulating_nurse} = surgeon

    const {input_visit_surgeon, input_visit_anaestheist} = inputDoc


    const PreoperativeDiagnonsis = (
        <Col sm={12}>
        <Form.Group>
            <FormLabel className="pl-1" name='Preoperative Diagnosis' toolTip='Capture Preoperative Diagnosis notes here'/>
            <Form.Control as="textarea" rows="6" cols="4" name="preoperative_diagnosis"
                        id="preoperativeDiagnonsis" autoComplete="off"
                        value={preoperative_diagnosis}
                        onChange={handleNotesState}
                        className={`form__control ${palette.type === "dark" && 'form-control-dark'}`}/>
        </Form.Group>
        </Col>
    )
 

    const PreoperativeInstructions = (
        <Col sm={12}>
        <Form.Group>
            <FormLabel className="pl-1 surgery-details" name='Preoperative Instructions' toolTip='Capture Preoperative Instructions here'/>
            <Form.Control as="textarea" rows="6" cols="4" name="preoperative_instructions"
                        id="preoperativeInstructions" autoComplete="off"
                        value={preoperative_instructions}
                        onChange={handleNotesState}
                        className={`form__control ${palette.type === "dark" && 'form-control-dark'}`}/>
        </Form.Group>
        </Col>
    )

    /*
    const PlannedProcedures = (
        <Col sm={12}>
        <Form.Group>
            <FormLabel className="pl-1" name='Planned Procedure(s)' toolTip='Capture Planned Procedure(s) notes here'/>
            <Form.Control as="textarea" rows="6" cols="4"  name="planned_procedures"
                        value={planned_procedures}
                        id="plannedProcedure"
                        onChange={handleNotesState}
                        className={`form__control ${palette.type === "dark" && 'form-control-dark'}`}/>
        </Form.Group>
        </Col>
    )

    
    const PostoperativeInstructions = (
        <Col sm={12}>
        <Form.Group>
            <FormLabel className="pl-1" name='post_operative_instructions' toolTip='Capture Post Operative Instructions notes here'/>
            <Form.Control as="textarea" rows="6" cols="4"  name="post_operative_instructions"
                        value={post_operative_instructions}
                        id="post_operative_instructions"
                        onChange={handleNotesState}
                        className={`form__control ${palette.type === "dark" && 'form-control-dark'}`}/>
        </Form.Group>
        </Col>
    )

    const SummaryReport = (
        <Col sm={12}>
        <Form.Group>
            <FormLabel className="pl-1" name='summary_report' toolTip='Capture Report notes here'/>
            <Form.Control as="textarea" rows="6" cols="4"  name="summary_report"
                        value={summary_report}
                        id="summary_report"
                        onChange={handleNotesState}
                        className={`form__control ${palette.type === "dark" && 'form-control-dark'}`}/>
        </Form.Group>
        </Col>
    )
    */

    return (
        <div className={`${palette.type === 'dark' && 'dark-patient-information'} doctors-details`}>

            {completeSurgery ?
                    <>
                        {PreoperativeDiagnonsis}
                        {PreoperativeInstructions}
                        {/*PlannedProcedures*/}
                    </>
                : null}
            
            <div className="mt-2 surgery-details">

                <Form.Group as={Row}>
                    <Col xs={12} sm={6} md={6} lg={4} xl={4}>
                        {/* Should be drop down? make api calls or is that too much? */}
                        <Label name="Lead Surgeon" className="surgery-details"/>
                        <SearchComponent name="lead_surgeon" value= {lead_surgeon} 
                        {...{isOpen, handleChange, handleChangeSurgeon, handleSelectSurgeon, surgeons, isVisitingDoctor}}/>
                    </Col>

                    <Col xs={12} sm={6} md={6} lg={4} xl={4}>
                        <Label name="Lead Anaestheist" className="surgery-details"/>
                        <SearchComponent name="lead_anaestheist" value= {lead_anaestheist} 
                        {...{isOpen, handleChange, handleChangeSurgeon, handleSelectSurgeon, surgeons, isVisitingDoctor}}/>
                    </Col>

                    <Col xs={12} sm={6} md={6} lg={4} xl={4}>
                        <Label name="Anaesthesiologist" className="surgery-details"/>
                        <SearchComponent name="anaesthesiologist" value= {anaesthesiologist} 
                        {...{isOpen, handleChange, handleChangeSurgeon, handleSelectSurgeon, surgeons, isVisitingDoctor}}/>
                    </Col>

                    <Col xs={12} sm={6} md={6} lg={4} xl={4}>
                        <Label name="Anaesthesia Type" className="surgery-details"/>
                        <CustomSelect id='anaesthesia_type' options={anaesthesiaTypes}
                            onChange={handleAnesTypeChange}    
                            value={anaesthesia_type}
                        />
                    </Col>

                    <Col xs={12} sm={6} md={6} lg={4} xl={4}>
                        <Label name="Scrub Nurse" className="surgery-details"/>
                        <SearchComponent name="scrub_nurse" value= {scrub_nurse} 
                        {...{isOpen, handleChange, handleChangeSurgeon, handleSelectSurgeon, surgeons, isVisitingDoctor}}/>
                    </Col>

                    <Col xs={12} sm={6} md={6} lg={4} xl={4}>
                        <Label name="Circulating Nurse" className="surgery-details"/>
                        <SearchComponent name="circulating_nurse" value= {circulating_nurse} 
                        {...{isOpen, handleChange, handleChangeSurgeon, handleSelectSurgeon, surgeons, isVisitingDoctor}}/>
                    </Col>
                </Form.Group>
            
                <Form.Group as={Row}> 
                    <Col sm={4}>
                        {isVisitingDoctor['other_surgeons'] ? 
                            <> 
                                <Label name="Enter Visiting Surgeon Name" className="surgery-details"/>
                                <TextField type="text" name="other_surgeons" value={input_visit_surgeon} onKeyDown={(e)=> handleChange(e, "other_surgeons")} onChange={(e)=>handleChange(e, "other_surgeons")} autoComplete="off" />
                            </>
                        : 
                        <>
                            <Label name="Other Surgeons" className="surgery-details"/>
                            <CustomSelect id='other_surgeons' value={other_surgeons} options={surgeons} onChange={handleOtherSurgeons} autoComplete="off"
                            onInputChange={handleChangeDoctors} placeholder={"Select surgeons"} isMulti/>
                        </>}
                    </Col>

                    <Col sm={4}>
                        {isVisitingDoctor["other_anaestheists"] ?
                            <> 
                                <Label name="Enter Visiting Anaestheist Name" className="surgery-details"/>
                                <TextField type="text" name="other_anaestheists" value={input_visit_anaestheist} onKeyDown={(e)=> handleChange(e, "other_anaestheists")} onChange={(e)=>handleChange(e, "other_anaestheists")} autoComplete="off"/>
                            </>                        
                        :
                        <>
                            <Label name="Other Anaestheists" className="surgery-details" />
                            <CustomSelect value={other_anaestheists} options={surgeons} onChange={handleOtherAnaestheists}  autoComplete="off"
                            onInputChange={handleChangeDoctors} placeholder={"Select anaestheists"} isMulti/>
                        </>}

                    </Col>
                </Form.Group>
            </div>

            <div className="mt-2">
                {doctorsModule ? 
                    <>   
                        {PreoperativeDiagnonsis}
                        {PreoperativeInstructions}
                        {/*PlannedProcedures*/}
                    </>
                : null}

                {visitModule ?
                    <>
                    {PreoperativeInstructions}
                    </>
                : null}

                {startSurgery || pendingSurgeryModule ?
                <>
                        {PreoperativeDiagnonsis}
                        {PreoperativeInstructions}
                        {/*PlannedProcedures*/}
                </>
                : null}

            </div>
        </div>
    )
};
