import React, {useState} from "react";
import AddEditDeathRegistration from "./AddEditDeathRegistration";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../../actions/snackbarActions";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import countries from "../../../Helpers/countries.json";
import PrintDialog from "../../Utils/Dialogs/PrintDialog";
import {useRegisterDeath} from "./CustomHooks/useRegisterDeath";


const RegisterDeath = ({actions, snackbar}) => {
    const {patient_number, patientNumbers, showNumber, patientDetails, nationality, submitted,
        isSubmitted, openDialog, reported_by, handleCloseDialog, viewReport,  p_number,
        handleChange, handleChaneNationality,  handleSearchPatientNumber,state,wards,ward, search_parameter,handleChangeSearchParam,
        handleClickPatientNumber, handleSubmit, handleReset, closeSnackbar, handleChangeWard, visit_id} = useRegisterDeath(actions)
    const {openBar, type, message} = snackbar;
    const {patient_name} = patientDetails
    return (
        <div data-testid="register-death">
            <MainSnackbar handleCloseBar={closeSnackbar} message={message} open={openBar} variant={type}/>
            <PrintDialog handleClick={viewReport} handleClose={handleCloseDialog} openDialog={openDialog}
                         message="View Report" text={`Would you like to view ${patient_name}'s report`}
                         handleCancel={handleCloseDialog}/>
            <AddEditDeathRegistration state={state} handleChange={handleChange} handleReset={handleReset} p_number={p_number}
                                      handleChangePatientNumbers={handleSearchPatientNumber} reported_by={reported_by}
                                      handleClickPatientNumber={handleClickPatientNumber} handleSubmit={handleSubmit}
                                      isSubmitted={isSubmitted} p_name={patient_name} patient={patientDetails}
                                      patientNumbers={patientNumbers} registerDeath showNumber={showNumber}
                                      submitted={submitted} subTitle="Register death" title="Register Death"
                                      countries={countries.map(country => ({label: country.text, value: country.text}))}
                                      handleChangeCountry={handleChaneNationality} nationality={nationality} wards={wards} ward={ward} handleChangeWard={handleChangeWard} search_parameter={search_parameter}handleChangeSearchParam={handleChangeSearchParam} visit_id={visit_id}/>
        </div>
    )
}

function mapStateToProps(state) {
    const {snackbar} = state;
    return {snackbar}

}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterDeath);