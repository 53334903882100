import React, {useEffect, useState} from 'react';
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import { titleCase } from '../../../Users/addUser';
import { useEntityLedgers } from './useEntityLedgers';
import { useEditProductSetup } from './useEditProductSetUp';


export const useSetupProducts = (actions) => {
    const [products, setProducts] = useState([]);
    const [rows, setRows] = useState([{id:'',name:'',isError:false,
        credit_account:'', debit_account:'', isOpen:false}])
    const [isAllProducts, setIsAllProducts] = useState(false)
    const [debitAccount, setDebitAccount] = useState('')
    const [creditAccount, setCreditAccount] = useState('')
    const [accounts, setAccounts] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [isSetUp, setIsSetUp] = useState(0)
    

    const {editProps,openEdit, handleCloseEdit, handleOpenEdit,
        credit_ledger, editData,setCredit,setDebit, setProduct} = useEditProductSetup(actions)
    const {isResolvedEdit} = editProps
    const isLedger = isSetUp === 1 
    const isEdit = isResolvedEdit

    const {data, isLoading, isSuccess, isError,openDelete,handleCloseDelete,
        handleOpenDelete,entity_id,setData, setOpenDelete} = useEntityLedgers('product', isLedger, isEdit, actions)
        
    const productLedgers = data.filter(item=>item.entity_type === 'product').map(item=>({
        ...item, name:item.product_name, id:item.id
    }))


    useEffect(() => {
        axios.get(`${config.smsUrl}/accounting/chart/journals`).then(res => {
            const data = res.data;
            const dt = data ? data : {};
            const arr = dt.data ? dt.data : []
            const list = arr.map(item => ({
                value: item.id,
                label: item.head_name
            }))
            setAccounts(list)
        }).catch(err => {
            errorMessages(err, null, actions)
        })
    }, [])

    useEffect(()=>{
        const {credit_ledger:credit,debit_ledger:debit, product_name,
            credit_ledger_id,debit_ledger_id} = editData;
        if(!credit_ledger){
            return
        }
        setCredit({value:credit_ledger_id, label:credit})
        setDebit({value:debit_ledger_id, label:debit})
        setProduct(product_name)

    },[credit_ledger])


    const handleChange = (event) => {
        const {checked} = event.target;
        setIsAllProducts(checked)
    }

    const handleChangeAllDebitAccount = (val) => {
        setDebitAccount(val)
    }

    const handleChangeAllCreditAccount = (val) => {
        setCreditAccount(val)
    }

    const handleAddRow = () =>{
        const obj = {id:'',
        name:'',
        credit_account:'',
        debit_account:'',isOpen:false, isError:false}
        setRows([...rows, obj])
    }

    const handleRemoveRow = (index) =>{
        const arr = rows.filter((_,ind)=>ind !== index)
        setRows(arr)
    }


    const retrieveProducts = (event, idx) => {
        const productCopy = [...rows];
        const formData = new FormData();
        formData.append('product_name', titleCase(event.target.value));
        if (productCopy[idx]) {
            productCopy[idx].name = event.target.value;
            if (event.target.value !== '') {
                axios.post(`${config.smsUrl}/cdoctor/autocomplete_search_medicine`, formData).then(res => {
                    const resp = res.data;
                    const data = !resp ? [] : resp;
                    if (data[0] === "No Product Found") {
                        const val = [{value: "", label: "No Medicine Found"}]
                        setProducts(val)
                    } else {
                        setProducts(data);
                    }
                }).catch(error => {
                    logoutErrorMessage(error, null, actions)
                });
                productCopy[idx].isOpen = true;
                productCopy[idx].isError = false;
            } else {
                productCopy[idx].isError = false;
                productCopy[idx].isOpen = false;
                setProducts([]);
            }
        } else {
            productCopy[idx].isOpen = false;
        }
        setRows(productCopy)
        setDebitAccount('')
        setCreditAccount('')
    };

    const clickProduct = (event, idx) => {
        const productCopy = [...rows];
        const formData = new FormData();
        formData.append('product_id', event.value);
        if (productCopy[idx]) {
            productCopy[idx].name = event.innerText;
            productCopy[idx].id = event.value;
            productCopy[idx].isOpen = false;
        }
        setRows(productCopy)
    };


    const handleChangeDebitAccount = (val, index) => {
        const arr = rows.map((item, ind) => {
            if (ind === index) {
                return {...item, debit_account: val}
            }
            return item
        })
        setRows(arr)
    }

    const handleChangeCreditAccount = (val, index) => {
        const arr = rows.map((item, ind) => {
            if (ind === index) {
                return {...item, credit_account: val}
            }
            return item
        })
        setRows(arr)
    }



    const handleSubmit = (event) => {
        event.preventDefault();
        setSubmitted(true);
        const data = rows.map(item=>{
            const credit  = item.credit_account ? item.credit_account : {};
            const credit_value = credit.value ? credit.value : ''
            const debit  = item.debit_account ? item.debit_account : {};
            const debit_value = debit.value ? debit.value : '';
            return{
            entity_id: +item.id,
            credit_ledger:+credit_value,
            debit_ledger :+debit_value,
            entity_type:'product',
            default:0
         }
        })
        const credit  = creditAccount ? creditAccount: {};
        const credit_value = credit.value ? credit.value : ''
        const debit  = debitAccount ? debitAccount : {};
        const debit_value = debit.value ? debit.value : ''
        const params = isAllProducts ? {data:[{credit_ledger: +credit_value,debit_ledger: +debit_value, 
            entity_id:'', entity_type:'product', default:1}]} : {data};
        const arr = rows.every(item=>item.id && item.debit_account && item.credit_account)
        if(arr){
            setIsSubmitted('pending');
            axios.post(`${config.smsUrl}/accounting/entity_behavior/add`,
                {...params}).then(() => {
                actions.snackbarActions.snackSuccess('Entry made successfully')
                setIsSubmitted('resolved')
                const arr = rows.map(item=>({
                    ...item,
                    id:'',name:'',isError:false,credit_account:'', debit_account:'', isOpen:false
                }))
                setRows(arr)
                setSubmitted(false)
            }).catch(err => {
                errorMessages(err, null, actions)
                setIsSubmitted('rejected')
            })
        }

     
    }

    const handleDelete = () => {
        const formData = new FormData();
        formData.append('id', entity_id);
        axios.post(`${config.smsUrl}/accounting/entity_behavior/delete`, formData).then(() => {
            const arr = data.filter(item=>item.id !== entity_id)
            actions.snackbarActions.snackSuccess(`Ledger deleted successfully`);
            setOpenDelete(false)
            setData(arr)
        }).catch(err => {
            errorMessages(err, null, actions)
        })
    }

    const handleChangeSetup = (status) =>{
        setIsSetUp(status)
    }

    const closeSnackbar = () =>{
        actions.snackbarActions.hideSnackbar()
    }


    const isPending = isSubmitted === 'pending'
    const isResolved = isSubmitted === 'resolved'
    const isRejected = isSubmitted === 'rejected'

    const ledgerData = {data:productLedgers, isLoading, isSuccess, isError,openEdit,
        handleOpenEdit, handleCloseEdit,openDelete,handleCloseDelete,handleOpenDelete,entity_id,handleDelete}
    
    const productProps = {rows,  isAllProducts, debitAccount, creditAccount,
    accounts, submitted, handleChange, handleChangeAllCreditAccount, handleChangeAllDebitAccount,
    handleChangeCreditAccount, handleChangeDebitAccount, handleSubmit,
    isPending, isRejected, isResolved, handleAddRow, handleRemoveRow,
    retrieveProducts, clickProduct,products, }

    return {ledgerData,productProps, isSetUp,handleChangeSetup, closeSnackbar,editProps}
}