/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import styled from "@emotion/styled/macro"
import * as colors from "./colors"

const P = styled.p({
    marginBottom:0,
})

const table = css`
    width: 100%;
    font-size:14px;
    margin-bottom:0.5rem;
`

const printTable = css`
    width: 100%;
    font-size:14px;
    margin-bottom:0.5rem;
    @media print {
        td, th, td span{
            font-size: 20px;
        }
        p{
            font-size: 28px;
        }
        td.table-items span, th.table-items span{
            font-size: 15px;
        }
        //.span-heading{
        //    font-weight: 600;
        //}
    }
`

const tMargin = css`
  margin-top:2rem;
  `
const heading = css`
  font-size:16px;
  margin: 0;
`

const tableMargin = css`
    margin-bottom: 1.5rem;
`

const topTableMargin = css`
    margin-top: 1rem;
`

const borderlessTableFooter = css`
    width: 100%;
    font-size:14px;
    margin-bottom:0.5rem;
`

const tableBordered = css`
    td,th{
        border:1px solid ${colors.text00};
        padding:0.3rem;
    } 
 
`
const borderTable = css`
    border-top:2px solid ${colors.text00};
    border-bottom:2px solid ${colors.text00};
`

const thinBorderTable =  css`
    border:1px solid ${colors.text00};
    padding:0.3rem;
`

const coloredHead = css`
      background-color: ${colors.lightGray001};
`

const coloredThead = css`
  background-color: ${colors.gray};
`

const coloredInvoice = css`
  background-color: ${colors.blue};
  color: ${colors.base};
`

const textWeight = css`
    font-weight:700;
`

const detailsTable = css`
    td,th{
        padding:0.5rem;
    } 
`

const borderedThead = css`
    th{
        border-top:2px solid ${colors.text00};
        border-bottom:2px solid ${colors.text00};
        
    }
`

const thinborderedThead = css`
    border-top:1px solid ${colors.text00};
    border-bottom:1px solid ${colors.text00};
    padding:0.2rem;
`

const smallUpperCase = css`
    font-weight:300;
    font-size:15px;
`
const smallTable = css`
    td{
      padding:0.1rem;
    }
`

const smallPadding = css`
  td{
  padding:0.5rem;
}
  th{
    padding:0.5rem;
  }
`

const tableCell = css`
    border-top:2px solid ${colors.text00};
`

const tableCellBorderBottom = css`
    border-bottom:2px solid ${colors.text00};
`
const thBorderTop = css`
    th{
        border-top:2px solid ${colors.text00};
    }
`
const thBorderBottom = css`
    th{
        border-bottom:2px solid ${colors.text00};
        text-transform: uppercase;
    }
    
`

const borderedCell = css`
  tr:last-child td{
    border-bottom:1.4px solid ${colors.text00};
  };
`

const paddingCell = css`
  tr:last-child td{
    padding-bottom: 1rem;
  };
  tr:first-child td{
    padding-top: 0.75rem;
  }
`

const thThinBorderBottom = `
    th{
        border-bottom:1px solid ${colors.text00};
    }
    
`

const thUppercase = `
    text-transform: uppercase;
`

const biggerFont = `
    font-size: 17px;
`

const mediumFont = `
    font-size: 16px;
`


const borderedTriage = css`
    td,th{
        border:1px solid ${colors.black003};
        padding:0.3rem;
    } 
 
`

const leftPadding = css`
    td,th{
        padding-left:0;
    } 
 
`

export {P,table,tableCell,textWeight, thBorderBottom, thBorderTop, borderTable,borderedThead, smallUpperCase,borderlessTableFooter,
     detailsTable,thUppercase,thinBorderTable,tableBordered,tableCellBorderBottom,thThinBorderBottom, thinborderedThead,
coloredHead, coloredThead, borderedCell, smallTable, tableMargin, heading, smallPadding, coloredInvoice, paddingCell, tMargin,
borderedTriage, leftPadding, biggerFont,topTableMargin, mediumFont, printTable}
