import {useEffect, useState} from "react";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import { useAddParameters } from "./useAddParameters";
import { useEditParameters } from "./useEditParameters";

export const useParameterList = (actions) =>{
    const [searchValue, setSearchValue] = useState('');
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('sl');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loading, setLoading] = useState('idle');
    const [parameters, setParameters] = useState([]);
    const [isExternal, setIsExternal] = useState('idle')
    const {parameterProps,openDialog, handleOpenDialog, handleCloseDialog,
         isResolved, edit_id} = useAddParameters(actions)
    const {editProps, openEdit, handleOpenEdit, handleCloseEdit,  isResolved:isEditResolved} = useEditParameters(actions)

    const isExternalSuccess = isExternal === 'success'

    useEffect(() => {
        setLoading('pending');
        axios.get(`${config.smsUrl}/claboratory/lab_test_list`).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const cats = !dt.lab_testlist ? [] : dt.lab_testlist;
            setParameters(cats);
            setLoading('success');
        }).catch((err) => {
            logoutErrorMessage(err, null, actions);
            setLoading('error');
        })
    }, [isResolved,isEditResolved,isExternalSuccess]);

    const handleMakeExternal = (test_id, status)=>{
        setIsExternal('pending');
        axios.post(`${config.smsUrl}/claboratory/make_test_external_or_internal`, {hospital_test_id:test_id, is_external:status === false}).then(() => {
            actions.snackbarActions.snackSuccess('Status changed successfully')
            setIsExternal('success');
        }).catch((err) => {
            errorMessages(err, null, actions);
            setIsExternal('error');
        })
    }

    const handleSearch = (searchValue) => {
        setSearchValue(searchValue)
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const isLoading = loading === 'pending'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'

    const isSaving = isExternal === 'pending'

    return {searchValue, order, orderBy, page, rowsPerPage, loading, parameters, setParameters,
        handleSearch, handleRequestSort, handleChangePage, handleChangeRowsPerPage, closeSnackbar,
    parameterProps,openDialog, handleOpenDialog, handleCloseDialog,isLoading,isSuccess,isError,
    edit_id,editProps, openEdit, handleOpenEdit, handleCloseEdit,handleMakeExternal, isSaving}
}