import React from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Grid from '@material-ui/core/Grid';
import {useAccountingStyles} from "../Accounting/Expenses/hooks/useAccountingStyles";
import Label from "../Utils/FormInputs/Label";
import TextField from "../Utils/FormInputs/TextField";
import {CustomSelect} from "../Utils/FormInputs/SelectInput";
import {ErrorMessage} from "../Utils/styledComponents";
import {useExcelReports, usePdfDownload} from "../Utils/ReusableComponents/useExcelExport";
import {DataExportButton} from "../Utils/Buttons/DataExportationButton";
import { useVersionsContext } from '../../Context/versions-context';


export const MedicalReportFilter = ({payment, setPayment, patientType, setPatientType,handleSubmit, title,
                                        start_date, end_date, handleChange,isPending, submitted, excelUrl,
                                        provider, providers, setProvider,
                                        file_name, actions, pdfUrl, customFilter=null, excelParams={},pdfParams={}}) => {
    const classes = useAccountingStyles();
    const {subscription} = useVersionsContext()
    const version  = subscription?.version



    const paymentTypes = version === "Premium" ? [
        {value: '', label: 'All'},
        {value: 1, label: 'Cash'},
        {value: 2, label: 'Insurance Company'},
        {value: 3, label: 'Organisation'},
        {value: 4, label: 'Individuals'},
    ]: [
        {value: 1, label: 'Cash'},
    ]

    const patientTypes =  (version === "Premium" || version === "Standard") ? [
        {value: '', label: 'All'},
        {value: 1, label: 'Out-patient'}, {value: 2, label: 'In-patient'},

    ]: [{value: 1, label: 'Out-patient'}]

    const commonParams = {start_date , end_date,patient_type:patientType?.value ? patientType?.value:'',
        mode_of_payment:payment?.value ? payment?.value:'', provider_id:provider?.value}

    const fileName = `${patientType?.label}-${payment?.label}-${start_date} to ${end_date} ${file_name}`

    const excelObj = {file_name:fileName,params:{...commonParams, ...excelParams}, url:excelUrl}

    const pdfObj = {file_name:file_name,params:{...commonParams, ...pdfParams}, url:pdfUrl}


    const {exportExcel, isLoading} = useExcelReports(actions,excelObj)
    const {exportPdf, isLoading:isLoadingPdf} = usePdfDownload(actions,pdfObj)

    const isCash = payment?.label === 'All' || payment?.label === 'Cash'
    return (
        <div>
            {/*<Grid container spacing={0}>*/}
            {/*    <Grid item xs={12} md={12} lg={12}>*/}
            <div className="report-search-bar">
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={isCash ? 5: 3} xl={isCash ? 5: 3} style={{paddingLeft:0}}>
                                <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={2} lg={2} xl={2} style={{paddingLeft:0}}>
                                        <Label name='Payment'/>
                                    </Col>
                                    <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                                        <CustomSelect value={payment} onChange={(value) => {
                                            setPayment(value)
                                            setProvider({value:'', label:''})
                                        }}
                                                      options={paymentTypes} id='payment_mode'/>
                                    </Col>
                                </Form.Group>
                            </Col>
                            {!isCash ? <Col xs={12} sm={12} md={12} lg={3} xl={3} style={{paddingLeft:0}}>
                                <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={2} lg={2} xl={2} style={{paddingLeft:0}}>
                                        <Label name='Provider'/>
                                    </Col>
                                    <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                                        <CustomSelect value={provider} onChange={(value) => setProvider(value)}
                                                      options={providers} id='provider'/>
                                    </Col>
                                </Form.Group>
                            </Col>: null}
                            <Col xs={12} sm={12} md={12} lg={isCash ? 5: 3} xl={isCash ? 5: 3} style={{paddingLeft:0}}>
                                <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={2} lg={2} xl={2} style={{paddingLeft:0}}>
                                        <Label name='Patient Type'/>
                                    </Col>
                                    <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                                        <CustomSelect value={patientType} onChange={(value) => setPatientType(value)}
                                                      options={patientTypes} id='filter_patient_type'/>
                                    </Col>
                                </Form.Group>
                            </Col>
                        </Form.Group>
                    </div>
            {/*    </Grid>*/}
            {/*</Grid>*/}
            <form onSubmit={handleSubmit}>
                <Grid container spacing={0}>
                    <Grid item xs={12} md={8} lg={8}>
                        {customFilter ? customFilter :
                            <Form.Group as={Row}>
                                <Col xs={12} sm={12} md={12} lg={5} xl={5} style={{paddingLeft:0}}>
                                    <Form.Group as={Row}>
                                        <Col xs={12} sm={12} md={2} lg={2} xl={2} style={{paddingLeft:0}}>
                                            <Label name='From' type htmlFor="start_date"/>
                                        </Col>
                                        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                                            <TextField type='date'
                                                       onChange={handleChange}
                                                       value={start_date} name='start_date' id="start_date"/>
                                            {submitted && !start_date && (
                                                <ErrorMessage>Start date is required</ErrorMessage>
                                            )}
                                        </Col>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={5} xl={5} style={{paddingLeft:0}}>
                                    <Form.Group as={Row}>
                                        <Col xs={12} sm={12} md={2} lg={2} xl={2} style={{paddingLeft:0}}>
                                            <Label name='To'  type htmlFor="end_date"/>
                                        </Col>
                                        <Col xs={12} sm={12} md={8} lg={8} xl={8}>

                                            <TextField type='date' value={end_date} onChange={handleChange}
                                                       name='end_date' id="end_date"/>
                                            {submitted && !end_date && (
                                                <ErrorMessage>End date is required</ErrorMessage>
                                            )}
                                        </Col>
                                    </Form.Group>
                                </Col>
                                <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                                    <button type='submit' disabled={isPending}
                                            className="btn sms-btn-outline btn-sm px-2 btn-sm mt-2">{isPending ? 'Filtering...' : 'Filter'}</button>
                                </Col>

                            </Form.Group>}
                    </Grid>
                    <Grid item xs={12} md={4} lg={4} classes={{root: classes.item}}>
                        <div className='export-dropdown'>
                            <DataExportButton isLoading={isLoading || isLoadingPdf}  title={title} downLoadPdf={exportPdf} exportCsv={exportExcel}/>
                        </div>
                    </Grid>
                </Grid>
            </form>
        </div>

    )
}