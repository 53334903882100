import React from "react";
import {PatientDischargeInformation, PatientInformation} from "../DoctorsModule/PatientInfo/PatientInformation";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../actions/snackbarActions"
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import PageTitle from "../Utils/smsTitle";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserMd} from "@fortawesome/free-solid-svg-icons/faUserMd";
import ReusableDoctorTabs, {doctorRoutes} from "../Utils/Menu/ReusableDoctorTabs";
import {dateConvert, dateStrokeConvert} from "../Utils/ConvertDate";
import {useDischargeForm} from "../Visit/NewAdmission/hooks/useDischargeForm";
import PrintDialog from "../Utils/Dialogs/PrintDialog";
import Label from "../Utils/FormInputs/Label";
import Form from "react-bootstrap/Form"
import TextField from "../Utils/FormInputs/TextField";
import {Container, ErrorMessage, MiniCenteredDiv, RightAlignedContainer} from "../Utils/styledComponents";
import {ResizableTextarea} from "../Utils/FormInputs/Textarea";
import {BackButton} from "../Utils/Buttons/DataExportationButton";
import { CustomTimePicker } from "./CustomTimePicker";
import {NewPrescriptionTable} from "../DoctorsModule/Prescription/NewPrescription";
import { ConfirmationDialog } from "../Utils/ReusableComponents/ConfirmationDialog";

const style = {
    margin: '5px 20px'
}

const headData = [{name: "Drug Name"},{name: "Quantity Prescribed"}, {name: "Dosage"},{name:"Frequency"},{name:"Number of Days"}, {name: "Instructions"}];


const DischargeForm = ({actions, match, snackbars, patientDetails=null}) =>{
    const {  state, submitted,  patient, printDialog,  handleChange, handleClosePrintDialog,
        handleSubmit, handleClick, closeSnackbar, isPending,handleChangeTime,start_time,
    isAddPrescription, handlePrescriptionToggle, addProps} = useDischargeForm(actions, match);
    const { openBar, type, message } = snackbars;
    const {patient_admission_id} = match
    const {
        summary_of_invention,
        final_diagnosis,
        recommendation,
        follow_up_date,
        description
    } = state;
    const {admission_date, ward, room, bed_number } = patient || {};
    const {openConfirmation, addItemsToReceipt,  
        handleCloseConfirmation, handleCancelConfirmation} = addProps
    const admissionDetails = (
        <>
            <h6 className="patient-personal-details">ADMISSION DETAILS</h6>
            <table className="table table-sm table-borderless prescription-table">
                <tbody>
                <tr>
                    <td>
                        <p>
                            Admission No.: <strong>{patient_admission_id}</strong>
                        </p>
                    </td>
                    <td>
                        <p>
                            Admission Date: <strong>{admission_date ? dateStrokeConvert(admission_date) : admission_date}</strong>
                        </p>
                    </td>
                    <td>
                        <p>
                            Ward: <strong>{ward}</strong>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>
                            Room: <strong>{room}</strong>
                        </p>
                    </td>
                    <td>
                        <p>
                            Bed: <strong>{bed_number}</strong>
                        </p>
                    </td>
                </tr>
                </tbody>
            </table>
        </>
    )
    return (
        <React.Fragment>
            <MainSnackbar variant={type} handleCloseBar={closeSnackbar} open={openBar} message={message} />
            <PrintDialog
                message="View Discharge Note"
                openDialog={printDialog}
                handleClose={handleClosePrintDialog}
                text="Would you like to view and print this discharge note"
                handleClick={handleClick}
                handleCancel={handleClosePrintDialog}
            />
               <ConfirmationDialog open={openConfirmation} handleClose={handleCancelConfirmation}
                                title='Make cash payment'
                                confirmItems={addItemsToReceipt}
                                removeItems={handleCloseConfirmation} service='product'/>
            <form onSubmit={handleSubmit} autoComplete="off" className="p-3">
                {patientDetails ? patientDetails : admissionDetails}
                <div className="patient-personal-details discharge"/>
                <div className="row">
                    <div className="col">
                        <Form.Group>
                            <Label name="Follow-up Date" htmlFor="follow_up_date" type/>
                            <TextField value={follow_up_date} submitted={submitted} type="date" onChange={handleChange}
                                       name="follow_up_date" id="follow_up_date"/>
                            {submitted && !follow_up_date && <ErrorMessage>Follow-up date is required</ErrorMessage>}
                        </Form.Group>
                        <Form.Group>
                            <Label name="Follow-up Time" htmlFor="start_time"/>
                            <CustomTimePicker time={start_time} id="start_time"
                                              handleChangeTime={handleChangeTime}
                            />
                            {/* <TextField  value={start_time} onChange={handleChange} type="time" name="start_time"
                                        placeholder="Start Time" /> */}
                            {/* {submitted && !start_time && <ErrorMessage>Follow-up time is required</ErrorMessage>} */}
                        </Form.Group>
                    </div>
                    <div className="col">
                        {/* <Form.Group>
                  <Label name="Phone"  htmlFor="phone_no" />
                  <ReusablePhoneInput  contact={contact} setContact={setContact} required id="phone_no" />
                </Form.Group> */}
                        <Form.Group>
                            <Label name="Follow-up Detail" htmlFor="follow_up_detail"/>
                            <ResizableTextarea rows={2} value={description} onChange={handleChange}
                                               id="follow_up_detail" name="description"
                                               placeholder="Write description..."/>
                            {/* {submitted && !description && <ErrorMessage>Follow-up detail is required</ErrorMessage>} */}
                        </Form.Group>
                    </div>
                </div>

                <div>
                    <div className="switch-admission-prescription">
                        <Label name="Do you want to add discharge medication?" className="label-admission-prescription"
                               />
                        <text className="label-admission-prescription">No</text>
                        <Form.Check type="switch" id="custom-switch" defaultChecked={isAddPrescription}
                                    onChange={handlePrescriptionToggle} className="toggle-admission-prescription"/>
                        <text className="label-admission-prescription">Yes</text>
                    </div>
                    {isAddPrescription ?
                        <NewPrescriptionTable  headData={headData}
                                               {...addProps}/>
                        : null}
                </div>
                <Form.Group>
                    <Label name="Diagnosis" htmlFor="final_diagnosis"/>
                    <ResizableTextarea placeholder="Write diagnosis...."  rows={2}
                                       value={final_diagnosis} onChange={handleChange} name="final_diagnosis"
                                       dataTest="final_diagnosis" id="final_diagnosis"/>
                    {/*{submitted && !final_diagnosis && <ErrorMessage>Final diagnosis is required</ErrorMessage>}*/}
                </Form.Group>
                <Form.Group>
                    <Label name="Summary of Invention" htmlFor="summary_of_invention"/>
                    <ResizableTextarea
                        placeholder="Write summary...."
                        value={summary_of_invention}
                        onChange={handleChange}
                        name="summary_of_invention"
                        rows={2}
                        dataTest="summary_of_invention"
                        id="summary_of_invention"
                    />
                    {/*{submitted && !summary_of_invention &&*/}
                    {/*    <ErrorMessage>Summary of invention is required</ErrorMessage>}*/}
                </Form.Group>

                <Form.Group>
                    <Label name="Recommendation" htmlFor="recommendation" type/>
                    <ResizableTextarea
                        placeholder="Write recommendation...."
                        {...{submitted}}
                        value={recommendation}
                        onChange={handleChange}
                        name="recommendation"
                        rows={2}
                        dataTest="recommendation"
                        id="recommendation"
                    />
                    {submitted && !recommendation && <ErrorMessage>Recommendation is required</ErrorMessage>}
                </Form.Group>
                <RightAlignedContainer>
                    <button type="submit" className="btn btn-primary mb-2" disabled={isPending}>
                        {isPending ? 'Submitting...' : 'Submit'}
                    </button>
                </RightAlignedContainer>
            </form>
        </React.Fragment>

    )
}

const DoctorDischargeForm = ({actions, snackbars, match}) => {
    const {
        patient_number,
        visit_id,
        labrequest_id,
        scan_request_id,
        patient_type,
        patient_admission_id,
        component
    } = match.params;

    const arr = doctorRoutes(patient_number, visit_id, patient_type, patient_admission_id);

    const {first_name, last_name} = JSON.parse(sessionStorage.getItem("user"))?.token?.[1] || {};
    const doctor_name = `${first_name || ""} ${last_name || ""}`;

    const isDoctor = component === 'doctor'

    const doctorDischarge = (
        <ReusableDoctorTabs components={arr} patient_type={+patient_type}>
            <div className="text-right">
                <p style={style}>
                    Date: <strong>{dateConvert()}</strong>
                </p>
                <p style={style}>
                    Doctor: <strong>{doctor_name}</strong>
                </p>
            </div>
            <div className='p-3'>
                <PatientInformation patient_number={patient_number} />
                <DischargeForm {...{actions, match, snackbars}}/>
            </div>

        </ReusableDoctorTabs>
    )

    const nurseDischarge = (
        <Container>
            {/*<div className='mb-3'>*/}
                <BackButton text='Admitted Patients' to='/patientroundlist'/>
            {/*</div>*/}
            <DischargeForm {...{actions, match,snackbars, patientDetails:<PatientDischargeInformation {...{patient_number, patient_admission_id}}/>}}/>
        </Container>
    )
  
    return (
      <div className="journals">
        <PageTitle title="Discharge Form" />
        <SubHeader title="Doctor" subTitle="Discharge Form">
          <FontAwesomeIcon icon={faUserMd} />
        </SubHeader>
          {isDoctor ? doctorDischarge :  nurseDischarge}
      </div>
    );
  };
  

function mapStateToProps(state) {
    return {
        snackbars: state.snackbar
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DoctorDischargeForm);
