import React from "react";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserFriends} from "@fortawesome/free-solid-svg-icons/faUserFriends";
import CustomTable from "../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import {getComparator, stableSort} from "../Utils/Tables/sortingFunctions";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import * as employeeActions from "../../actions/employeeActions";
import * as snackbarActions from "../../actions/snackbarActions"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import PageTitle from "../Utils/smsTitle";
import DeleteDialog from "../Utils/Dialogs/DeleteDialog";
import {useTheme} from "@material-ui/core/styles";
import { Container } from "../DoctorsModule/doctorStyles";
import CustomDialog from "../Utils/Dialogs/CustomDialog";
import { useAssignShifts } from './CustomHooks/useAssignShifts';
import { TableHeading } from '../DoctorsModule/ReferralNote/ReusableTreatmentTable';


const headData = [{id: 'sl', numeric: false, disablePadding: false, label: 'SL'},
    {id: 'employee', numeric: false, disablePadding: false, label: 'Employee Name'},
    {id: 'department', numeric: false, disablePadding: false, label: 'Department'},
    {id: 'specialization', numeric: false, disablePadding: false, label: 'Specialization'},
    {id: 'assigned', numeric: false, disablePadding: false, label: 'Assigned'},
    {id: 'actions', numeric: false, disablePadding: true, label: 'Action'},
];


const AssignShifts = (props) => {

    const {openBar, type, message} = props.snackbars;

    const {shifts, searchValue, order, orderBy, page, rowsPerPage,// openDelete,
        employeeList, loading, activePage, employee_count, handleSearch, handleOpenDialog,
        handleRequestSort, handleChangeNewPage,
         handleCloseDialog, handleUpdateShift,
         handleDelete, closeSnackBar, openDialog, current_employee,
      currentEmployeeShift, handleAssignShift,
        isAssign, handleCellColour, screen
    } = useAssignShifts(props);

    const employees = !employeeList ? [] : employeeList;


    const isEditShift = screen === 'edit';
    const isAssignShift = screen === 'assign';
    const isDeleteShift = screen === 'delete';

    return (
        <div data-testid="employee-list">
            <PageTitle title="Employee Shift List"/>
            <SubHeader title="HRM" subTitle="Employee Shift List">
                <FontAwesomeIcon icon={faUserFriends}/>
            </SubHeader>

            {isEditShift ?
                <CustomDialog title="Edit Shifts" open={openDialog} handleClose={handleCloseDialog} maxWidth="lg">

    `               {shifts.length > 0 ?
                    
                    <div>

                        <TableHeading title={`Edit shifts to ${current_employee.first_name} ${current_employee.last_name}`} colSpan={2}/>

                        <table className="table table-bordered profile-table mt-1">
                            <thead>
                                <tr>
                                    <td style={{width:'16%'}}>Shifts</td>
                                    <td style={{width:'12%'}}>Sunday</td>
                                    <td style={{width:'12%'}}>Monday</td>
                                    <td style={{width:'12%'}}>Tuesday</td>
                                    <td style={{width:'12%'}}>Wednesday</td>
                                    <td style={{width:'12%'}}>Thursday</td>
                                    <td style={{width:'12%'}}>Friday</td>
                                    <td style={{width:'12%'}}>Saturday</td>
                                </tr>
                            </thead>
                            <tbody>
                                {currentEmployeeShift.map((item, index) => (
                                <tr key={index}>
                                    <td>
                                        {`${item.shift_name} \n
                                        ${item.start_time} - ${item.end_time}`}
                                    </td>
                                    
                                    <td 
                                    name="Sunday"
                                    style={{
                                        backgroundColor: isAssign[item.shift_id].Sunday ? '#1fa7b0' : '',

                                    }}
                                    onClick={(event) => handleCellColour(index, item.shift_id,"Sunday")}>
                                        
                                    </td>

                                    <td
                                    name="Monday"
                                    style={{
                                        backgroundColor: isAssign[item.shift_id].Monday ? '#1fa7b0' : '',

                                    }}
                                    onClick={(event) => handleCellColour(index, item.shift_id,"Monday")}
                                    
                                    > {item.days['Monday']}</td>
                                    
                                    <td
                                    name="Tuesday"
                                    style={{
                                        backgroundColor: isAssign[item.shift_id].Tuesday ? '#1fa7b0' : '',

                                    }}
                                    onClick={(event) => handleCellColour(index, item.shift_id,"Tuesday")}
                                    > {item.days['Tuesday']}</td>
                                    
                                    
                                    <td
                                    name="Wednesday"
                                    style={{
                                        backgroundColor: isAssign[item.shift_id].Wednesday ? '#1fa7b0' : '',

                                    }}
                                    onClick={(event) => handleCellColour(index, item.shift_id,"Wednesday")}
                                    
                                    > {item.days['Wednesday']}</td>
                                    
                                    
                                    <td
                                    name="Thursday"
                                    style={{
                                        backgroundColor: isAssign[item.shift_id].Thursday ? '#1fa7b0' : '',

                                    }}
                                    onClick={(event) => handleCellColour(index, item.shift_id,"Thursday")}
                                    > {item.days['Thursday']}</td>
                                    
                                    
                                    <td
                                    name="Friday"
                                    style={{
                                        backgroundColor: isAssign[item.shift_id].Friday ? '#1fa7b0' : '',

                                    }}
                                    onClick={(event) => handleCellColour(index, item.shift_id,"Friday")}
                                    > {item.days['Friday']}</td>
                                    
                                    
                                    <td 
                                    name="Saturday"
                                    style={{
                                        backgroundColor: isAssign[item.shift_id].Saturday ? '#1fa7b0' : '',

                                    }}
                                    onClick={(event) => handleCellColour(index, item.shift_id,"Saturday")}
                                    
                                    > {item.days['Saturday']}</td>

                                </tr>
                            ))}

                            </tbody>
                        </table>

                        <button className="btn btn-sm sms-btn mr-1 mb-1" onClick={handleUpdateShift}>Save</button>
                    </div>
                    :
                    <div>
                        No avaliable Shift categories
                    </div>}

                </CustomDialog>
                : null
            }
            
            {isAssignShift ?
                <CustomDialog title="Assign Shifts" open={openDialog} handleClose={handleCloseDialog} maxWidth="lg">
    
                {shifts.length > 0 ?
                
                <div>

                    <TableHeading title={`Assign shifts to ${current_employee.first_name} ${current_employee.last_name}`} colSpan={2}/>

                    <table className="table table-bordered profile-table mt-1">
                        <thead>
                            <tr>
                                <td style={{width:'16%'}}>Shifts</td>
                                <td style={{width:'12%'}}>Sunday</td>
                                <td style={{width:'12%'}}>Monday</td>
                                <td style={{width:'12%'}}>Tuesday</td>
                                <td style={{width:'12%'}}>Wednesday</td>
                                <td style={{width:'12%'}}>Thursday</td>
                                <td style={{width:'12%'}}>Friday</td>
                                <td style={{width:'12%'}}>Saturday</td>
                            </tr>
                        </thead>
                        <tbody>
                            {currentEmployeeShift.map((item, index) => (
                            <tr key={index}>
                                <td>
                                    {`${item.shift_name} \n
                                    ${item.start_time} - ${item.end_time}`}
                                </td>
                                
                                <td 
                                name="Sunday"
                                style={{
                                    backgroundColor: isAssign[item.shift_id].Sunday ? '#1fa7b0' : '',

                                }}
                                onClick={(event) => handleCellColour(index, item.shift_id,"Sunday")}>
                                    
                                </td>

                                <td
                                name="Monday"
                                style={{
                                    backgroundColor: isAssign[item.shift_id].Monday ? '#1fa7b0' : '',

                                }}
                                onClick={(event) => handleCellColour(index, item.shift_id,"Monday")}
                                
                                > {item.days['Monday']}</td>
                                
                                <td
                                name="Tuesday"
                                style={{
                                    backgroundColor: isAssign[item.shift_id].Tuesday ? '#1fa7b0' : '',

                                }}
                                onClick={(event) => handleCellColour(index, item.shift_id,"Tuesday")}
                                > {item.days['Tuesday']}</td>
                                
                                
                                <td
                                name="Wednesday"
                                style={{
                                    backgroundColor: isAssign[item.shift_id].Wednesday ? '#1fa7b0' : '',

                                }}
                                onClick={(event) => handleCellColour(index, item.shift_id,"Wednesday")}
                                
                                > {item.days['Wednesday']}</td>
                                
                                
                                <td
                                name="Thursday"
                                style={{
                                    backgroundColor: isAssign[item.shift_id].Thursday ? '#1fa7b0' : '',

                                }}
                                onClick={(event) => handleCellColour(index, item.shift_id,"Thursday")}
                                > {item.days['Thursday']}</td>
                                
                                
                                <td
                                name="Friday"
                                style={{
                                    backgroundColor: isAssign[item.shift_id].Friday ? '#1fa7b0' : '',

                                }}
                                onClick={(event) => handleCellColour(index, item.shift_id,"Friday")}
                                > {item.days['Friday']}</td>
                                
                                
                                <td 
                                name="Saturday"
                                style={{
                                    backgroundColor: isAssign[item.shift_id].Saturday ? '#1fa7b0' : '',

                                }}
                                onClick={(event) => handleCellColour(index, item.shift_id,"Saturday")}
                                
                                > {item.days['Saturday']}</td>

                            </tr>
                        ))}

                        </tbody>
                    </table>

                    <button className="btn btn-sm sms-btn mr-1 mb-1" onClick={handleAssignShift}>Assign Shift</button>
                </div>
                :
                <div>
                    No avaliable Shift categories
                </div>}
                
                </CustomDialog>
                : null
            }

            {isDeleteShift ?
                <DeleteDialog message={'Shift deleted'} openDialog={openDialog} handleClose={handleCloseDialog}
                text="employee" title={`Delete Employee Shift`}>
                <button className='btn sms-info-btn btn-sm' onClick={handleDelete}>Yes</button>
                <button className="btn sms-grey-btn btn-sm" onClick={handleCloseDialog}>No</button>    
                </DeleteDialog>
                : null            
            }

            <Container>
                <div className="mui-tables">
                    
                    <MainSnackbar variant={type} message={message} open={openBar} handleCloseBar={closeSnackBar}/>
                    <CustomTable title="Employee Shift List" headData={headData} handler={handleSearch} term={searchValue} handleRequestSort={handleRequestSort} 
                        data={employees} handleChangeNextPage={handleChangeNewPage}
                        colSpan={5} order={order} orderBy={orderBy} customPage records={10} activePage={activePage} total_count={employee_count}>
                        
                        {loading ? 
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan="9" align="center"><img src="/images/smsloader.gif"  alt="loader"/></TableCell>
                                </TableRow>
                            </TableBody> : 
                        employees.length > 0 ?
                            <TableBody>
                                {stableSort(employees, getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((item, index) => {
                                        const count = ((activePage - 1) * 10) + index + 1;
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={item.employee_id} className='mui-table-row'>
                                                <TableCell>{count}</TableCell>
                                                <TableCell>{`${item.first_name} ${item.last_name}`}</TableCell>
                                                <TableCell>{item.department_name}</TableCell>
                                                <TableCell>{item.role}</TableCell>
                                                <TableCell>{item.employee_shift_id == null ? 'No' : 'Yes'}</TableCell>
                                                <TableCell>
                                                    {item.employee_shift_id == null  ?
                                                        <>
                                                        <button onClick={() => handleOpenDialog(item, 'assign')} className="btn btn-sm sms-info-btn mr-1 mb-1">
                                                        Assign
                                                    </button>
                                                        </>
                                                    :
                                                        <>
                                                        <button className="btn btn-sm sms-btn mr-1 mb-1" onClick={() => handleOpenDialog(item, 'edit')} >
                                                        Edit
                                                    </button>
                                                    
                                                    <button onClick={() => handleOpenDialog(item, 'delete')} className={`btn btn-sm py-1 mb-1 mr-1 sms-grey-btn`}>
                                                        Delete
                                                    </button>
                                                        </>
                                                    }
                                                    
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                            : <TableBody>
                                <TableRow>
                                    <TableCell colSpan="9" align="center">No records found</TableCell>
                                </TableRow>
                            </TableBody>}
                    </CustomTable>
                </div>
            </Container>
        </div>
    )
}

function mapStateToProps(state) {
    return {employees: state.employees, snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            employeeActions: bindActionCreators(employeeActions, dispatch),
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignShifts);
