import * as types from "../../actions/actionTypes";
// import initialState from "../initialState";
const initialState = {
    doctors: [],
    waitingList: [],
    total_count:0
};

export default function doctorReducer(state = initialState, action) {
    switch (action.type) {
        // case types.CREATE_KIN_SUCCESS:
        //     return {...state, user: action.user};
        // case types.SAVE_KIN_SUCCESS:
        //     return action.kin;
        // case types.CREATE_DOCTOR_SUCCESS:
        //     return action.user;
        // case types.USERS_REGISTER_SUCCESS:
        //     return action.doctor;
        // case types.USERS_REGISTER_FAILURE:
        //     return {};
        // case types.SAVE_DOCTOR_SUCCESS:
        //     return action.doctor;
        // case types.SAVE_RECEPTIONIST_SUCCESS:
        //     return action.receptionist;
        // case types.SAVE_TECHNICIAN_SUCCESS:
        //     return action.technician;
        case types.LOAD_DOCTORS:
            return {...state, doctors: action.doctors};

        case types.LOAD_USERS:
            return {...state, doctors: action.doctors};

        case types.CLEAR_USERS:
            return [];
        case types.GET_DOCTOR_WAITING_LIST_REQUEST:
            return {loading: true};
        case types.GET_DOCTOR_WAITING_LIST:
            return {...state, waitingList: action.waitingList, loading: false};
        case types.GET_DOCTOR_WAITING_LIST_ERROR:
            return {loading: false};
        case types.WAITING_LIST_TOTAL_COUNT:
            return {...state, total_count: action.total_count};
        default:
            return state;
    }
}
