import React from 'react'
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMoneyBill} from "@fortawesome/free-solid-svg-icons/faMoneyBill";
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../../actions/snackbarActions";
import {connect} from "react-redux";
import SmsSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {CustomTableCell, MuiPaper, StickyHeadTable} from "../../Utils/Tables/StickyHeaderTable";
import {LoadingGif} from "../../Utils/Loader";
import {history} from "../../../Helpers/history";
import {ReusableRouteTabs} from "../../Utils/Dialogs/ReusableTabs";
import {RightAlignedContainer, StyledEditIcon} from "../../Utils/styledComponents";
import CustomDialog from "../../Utils/Dialogs/CustomDialog";
import {useExpenseItems} from "./hooks/useExpenseItems";
import {NewExpenseItem} from "./NewExpenseItem";
import {ReusableNewCOA} from "../ChartofAccounts/NewCOAccount";
import {AccountingDrawer} from "../../Utils/AccountingUtils/AccountingDrawer";
import {BlockNavigationComponent} from "../../Utils/ReusableComponents/BlockNavigationComponent";

const headData = [{id: 'expense_item', numeric: false, disablePadding: false, label: 'Expense Item'},
    {id: 'debit_ledger', numeric: false, disablePadding: false, label: 'Debit Ledger'},
    {id: 'credit_ledger', numeric: false, disablePadding: false, label: 'Credit Ledger'},
    {id: 'action', numeric: false, disablePadding: false, label: 'Action'},
];

const components = [
    {label: 'Expenses', path: '/accounting/expenses'},
    {label: 'Expense Items', path: '/accounting/expense-items'},
]

const ExpenseItems = ({actions, snackbar}) => {
    const {expensesItems,  isLoading, isSuccess, isError, closeSnackbar, itemProps} = useExpenseItems(actions);

    const {openDialog, handleOpenDialog, handleCloseDialog, ledgerProps,id} = itemProps;
    const {openLedger, handleOpenLedger, handleCloseLedger, handleSubmit, isPending} = ledgerProps


    const {openBar, type, message} = snackbar

    const {isBlocking, isBlockDialog, setIsBlockDialog, handleResetForm} = itemProps


    const emptyRows = 10 - Math.min(10, expensesItems.length);

    const title = id ? 'Edit Expense Item' : 'New Expense Item'

    const reusableTable = (
        <MuiPaper>
               <StickyHeadTable {...{width:'100%'
            ,data:expensesItems, columns:headData.filter(item=>Boolean(item))}}>
            {isLoading ? <TableRow><TableCell align='center' colSpan={headData.length}><LoadingGif/></TableCell></TableRow>:null}
            {isSuccess ? expensesItems.length > 0 ?
                <>
                    {expensesItems.map((row) => {
                        return (
                            <TableRow  tabIndex={-1} key={row.id}>
                                <CustomTableCell>{row.expense_type_name}</CustomTableCell>
                                <CustomTableCell>{row.debit_ledger}</CustomTableCell>
                                <CustomTableCell>{row.credit_ledger}</CustomTableCell>
                                <CustomTableCell>
                                    <Tooltip title='Edit'>
                                        <IconButton onClick={()=>handleOpenDialog(row.id)}>
                                            <StyledEditIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </CustomTableCell>
                            </TableRow>
                        );
                    })}
                    {emptyRows > 0 && (
                        <TableRow style={{ height: 43 * emptyRows }}>
                            <TableCell colSpan={headData.length} />
                        </TableRow>
                    )}
                </>:<TableRow><TableCell align='center'  colSpan={headData.length}>No records found</TableCell></TableRow>:null}
            {isError ? <TableRow><TableCell align='center'  colSpan={headData.length}>The server did not return a valid response</TableCell></TableRow>:null}
        </StickyHeadTable>
        </MuiPaper>
    )
    return (
        <div className='journals'>
            <SubHeader title="Accounting" subTitle="Expenses">
                <FontAwesomeIcon icon={faMoneyBill}/>
            </SubHeader>

            <BlockNavigationComponent
                when={isBlocking}
                shouldBlockNavigation={()=>{
                    return isBlocking;
                }}
                navigate={path=>history.push(path)}
                dialogClose={isBlockDialog}
                setIsBlockDialog={setIsBlockDialog}
                clearForm={handleResetForm}
            />

            <RightAlignedContainer>
                <div className='mt-3 mx-3'>
                    <button className='btn btn-sm sms-info-btn' onClick={()=>handleOpenDialog('')}>New Expense Item</button>
                </div>
            </RightAlignedContainer>
            <AccountingDrawer open={openDialog} handleClose={handleCloseDialog} title={title} >
                <RightAlignedContainer>
                    <div className='mb-2'>
                        <button className='btn btn-sm sms-info-btn' onClick={handleOpenLedger}>Quick Add Ledger</button>
                    </div>
                </RightAlignedContainer>
                <NewExpenseItem {...itemProps}/>
            </AccountingDrawer>

            <CustomDialog open={openLedger} handleClose={handleCloseLedger} title='New Ledger Account' maxWidth='md'>
                <form onSubmit={handleSubmit} autoComplete="off" className="general-card">
                    <ReusableNewCOA {...{...ledgerProps, isExpense:true}}/>
                    <button type="submit" className="btn btn-sm sms-info-btn"
                            disabled={isPending}>{isPending ? 'Submitting...' : 'Submit'}</button>
                </form>
            </CustomDialog>
            <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/>
            <SmsSnackbar variant={type} handleCloseBar={closeSnackbar} open={openBar} message={message}/>
            {reusableTable}
        </div>
    )
}

function mapStateToProps(state) {
    const {snackbar} = state;
    return {snackbar}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch),
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseItems);