import React from "react";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCoins} from "@fortawesome/free-solid-svg-icons/faCoins";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PageTitle from "../../Utils/smsTitle";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../../actions/snackbarActions"
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import PrintDialog from "../../Utils/Dialogs/PrintDialog";
import {useAddBill} from "./hooks/useAddBill";
import {ReusableBillTable, ProductsTable} from "../utils/ReusableTable";
import TextField from "../../Utils/FormInputs/TextField";
import Textarea from "../../Utils/FormInputs/Textarea"
import {MiniHeader} from "../../Utils/Menu/MiniSubHeader"
import Label from "../../Utils/FormInputs/Label"
import SelectInput from '../../Utils/FormInputs/SelectInput';
import {Container, ErrorMessage} from "../../Utils/styledComponents"
import { BackButton } from "../../Utils/Buttons/DataExportationButton";
import CustomDialog from "../../Utils/Dialogs/CustomDialog";
import InputGroup from "react-bootstrap/InputGroup";
import {BlockNavigationComponent} from "../../Utils/ReusableComponents/BlockNavigationComponent";
import {history} from "../../../Helpers/history";
import {groupProductId, groupServiceId} from "../utils/SanitizeArr";
import {useCurrency} from "../../../Context/currency-context";
import {useVersionsContext} from "../../../Context/versions-context";


export function AddBill(props) {
    const {currency:selectedCurrency} = useCurrency(props)
    const {payment_method, state,handleChangeProductRate,
        isSubmitted, date, openPrint, total_amount, paidAmount, balance, submitted,
        handleChangeDate, handleDeleteProcedure,  handleOpenReceipt,
        handleChangeMethod, handleChangePaidAmount, handleSubmitBill, handleCloseReceipt,
        procedureData, closeSnackbar, transaction_id, isExcluded,
        handleChangeProductQuantity, handleDeleteProduct, products,handleChangeRate,
        payTypes, handleAddProduct, handleAddService, addProduct, addService,
        handleCloseAddService, handleCloseAddProduct,sspAmount,currencyTotalSsp,
        currencies, currency,usdAmount,currencyTotal,handleChangeCurrencyRate,
        handleChangeProductCurrencyRate,renderService , renderPrescription,
        handleChangeExchangeRate, handleChangeCurrency, isBlocking, currencyExchangeRate,
        handlePayProduct, handlePayProcedure,checkAllProducts, checkAllServices,
        handlePayAllProcedures, handlePayAllProducts} = useAddBill(props)
    const {patient_number, patient_name, address} = state
    const {openBar, type, message} = props.snackbars;

    const text = !isExcluded ? 'This action is irreversible, would you like to continue?' : 'Would you like to confirm this payment'
    const isBase = currency && currency.split('-')[1]
    const currency_symbol = currency && currency.split('-')[2]
    const isNotBase = isBase === 'false'
    const isBaseCurrency = isBase === 'true'

    const {subscription} = useVersionsContext()
    const version = subscription?.version
    const isPremium = version === 'Premium'

    const non_base_symbol = isNotBase ? currency_symbol : ''



    const isLab = procedureData.some(item=>item.is_external === true || item.is_external === false)

    const unpaid = [
        "Item",
        "Quantity",
        isNotBase ?  `Rate(${non_base_symbol})` : null,
        `Rate(${selectedCurrency.currency_symbol})`,
        isNotBase ? `Amount(${non_base_symbol})` : null,
        `Amount(${selectedCurrency.currency_symbol})`,
        isLab ? "Status" : null,
        "Pay"
    ]

    const productHeadData = [
        "Medicine Name",
        "Dosage",
        "Frequency",
        "No. of Days",
        "Prescribed Quantity",
        isNotBase ?  `Rate(${non_base_symbol})` : null,
        `Rate(${selectedCurrency.currency_symbol})`,
        isNotBase ? `Amount(${non_base_symbol})` : null,
        `Amount(${selectedCurrency.currency_symbol})`,
        "Pay"
    ]

    const groupedProducts  = groupProductId(products)

    const groupedServices = groupServiceId(procedureData)


    const isProducts = products.some(item=>item.isPaid)
    const isServices = procedureData.some(item=>item.isPaid)


    return (
        <div data-testid="add-bill-component" className='journals'>

            <PageTitle title={`${transaction_id ? 'Edit Bill' : 'Pay Bill'}`}/>

            <SubHeader title="Billing" subTitle={`${transaction_id ? 'Edit Bill' : 'Pay Bill'}`}>
                <FontAwesomeIcon icon={faCoins}/>
            </SubHeader>

                <BlockNavigationComponent
                    when={isBlocking}
                    shouldBlockNavigation={()=>{
                        return isBlocking;
                    }}
                    navigate={path=>history.push(path)}
                />
        
                <PrintDialog
                    handleCancel={handleCloseReceipt}
                    openDialog={openPrint}
                    handleClose={handleCloseReceipt}
                    handleClick={handleSubmitBill}
                    message={!isExcluded ? "Cancel Payment" : "Confirm Payment"}
                    text={text}
                />

                <CustomDialog
                    open={addService}
                    title="Add Service"
                    handleClose={handleCloseAddService}
                    maxWidth="md"
                    isContentOverflow={true}
                >
                    {renderService}
                </CustomDialog>

                <CustomDialog
                    open={addProduct}
                    title="Add Product"
                    handleClose={handleCloseAddProduct}
                    maxWidth="lg"
                    isContentOverflow={false}
                >
                    {renderPrescription}
                </CustomDialog>

            <MainSnackbar
                variant={type}
                handleCloseBar={closeSnackbar}
                open={openBar}
                message={message}
            />

                <Container>

                    <BackButton to='/paymentList' text='Billing List' data-testid="add-bill-back-button"/>

                    <MiniHeader  title='Create Receipt'/>

                <form
                    onSubmit={handleOpenReceipt}
                    autoComplete="off"
                    style={{marginTop:'1.5rem'}}
                >
                    <div className='row'>
                        <div className='col-lg-6'>

                            <Form.Group as={Row}>
                                <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                    <Label name="Patient No."/>
                                </Col>

                                <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                                    <TextField type="text" value={patient_number} readOnly/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row}>
                                <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                    <Label name="Patient Name"/>
                                </Col>

                                <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                                    <TextField type="text" value={patient_name} readOnly/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row}>
                                <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                    <Label name="Address"/>
                                </Col>

                                <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                                    <Textarea value={address} readOnly/>
                                </Col>

                            </Form.Group>

                            <div>

                                <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                        <Label name="Payment Method"  type/>
                                    </Col>

                                    <Col xs={12} sm={12} md={10} lg={10} xl={7}>

                                        <SelectInput
                                            testId='payment_method'
                                            submitted={submitted}
                                            value={payment_method}
                                            onChange={handleChangeMethod}
                                            options={payTypes}
                                            id="payment_method"
                                            defaultOption="Select..."
                                        />
                                    </Col>
                                </Form.Group>
                            </div>

                            {isPremium ? <Form.Group as={Row}>
                                <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                    <Label name="Currency" type htmlFor="currency"/>
                                </Col>

                                <Col xs={12} sm={12} md={10} lg={10} xl={7}>

                                    <SelectInput
                                        testId='currency'
                                        submitted={submitted}
                                        value={currency}
                                        onChange={handleChangeCurrency}
                                        options={currencies}
                                        id="currency"
                                        defaultOption="Select..."
                                    />

                                </Col>
                            </Form.Group>: null}

                            {
                                isNotBase ?

                                    <Form.Group as={Row} >
                                    <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                        <Label  name="Exchange Rate" htmlFor="exchange_rate"/>
                                    </Col>

                                        <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                                        <InputGroup className="">
                                            <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">{currency_symbol}</InputGroup.Text>

                                            <TextField id='exchange_rate' type="number"  value={currencyExchangeRate} onChange={handleChangeExchangeRate}/>
                                        </InputGroup>
                                            {!(isProducts || isServices) && !currencyExchangeRate ? <ErrorMessage>Enter the exchange rate</ErrorMessage>: null}

                                            <span style={{marginTop:'5px', display:'inline-block', fontWeight:700, color:'blue'}}>(1 {selectedCurrency?.currency_symbol} = {currencyExchangeRate} {currency_symbol})</span>
                                    </Col>
                                </Form.Group>
                                    :
                                    null
                            }

                            {/*{*/}
                            {/*    isBaseCurrency ?*/}
                            {/*<Form.Group as={Row} >*/}
                            {/*    <Col xs={12} sm={12} md={12} lg={12} xl={3}>*/}
                            {/*        <Label  name="Exchange Rate" htmlFor="exchange_rate"/>*/}
                            {/*    </Col>*/}

                            {/*    <Col xs={12} sm={12} md={10} lg={10} xl={7}>*/}
                            {/*        <InputGroup className="">*/}
                            {/*            <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">{currency_symbol}</InputGroup.Text>*/}
                            {/*            <TextField id='exchange_rate' type="number"  value={currencyExchangeRate} onChange={handleChangeExchangeRate}/>*/}
                            {/*        </InputGroup>*/}

                            {/*        <span style={{marginTop:'5px', display:'inline-block', fontWeight:700, color:'blue'}}>(1 SSP  = {currencyExchangeRate} {currency_symbol})</span>*/}
                            {/*    </Col>*/}
                            {/*</Form.Group>*/}
                            {/*        :*/}
                            {/*        null*/}
                            {/*}*/}

                        </div>

                        <div className='col-lg-6'>
                            <Form.Group as={Row}>
                                <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                    <Label name="Date" type/>
                                </Col>

                                <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                                    <TextField submitted={submitted} type="date" value={date} onChange={handleChangeDate}/>

                                    {submitted && !date ? <ErrorMessage>This is a required field</ErrorMessage>:null}
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row}>
                                <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                    <Label name={`Total Bill(${selectedCurrency?.currency_symbol})`} htmlFor='total_bill_usd'/>
                                </Col>

                                <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                                    <TextField type="text" value={total_amount} id='total_bill_usd' readOnly/>
                                </Col>
                            </Form.Group>

                            {
                                isNotBase ?

                                    <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                        <Label name={`Total Bill(${currency_symbol})`}/>
                                    </Col>

                                        <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                                        <TextField type="text" value={currencyTotalSsp} readOnly/>
                                    </Col>
                                </Form.Group>
                                    :
                                    null
                            }

                                {/*{*/}
                                {/*    isBaseCurrency ?*/}
                                {/*<Form.Group as={Row}>*/}
                                {/*    <Col xs={12} sm={12} md={12} lg={12} xl={3}>*/}
                                {/*        <Label name={`Total Bill(SSP)`}/>*/}
                                {/*    </Col>*/}

                                {/*    <Col xs={12} sm={12} md={10} lg={10} xl={7}>*/}
                                {/*        <TextField type="text" value={currencyTotalSsp} readOnly/>*/}
                                {/*    </Col>*/}
                                {/*</Form.Group>*/}
                                {/*        :*/}
                                {/*        null*/}
                                {/*}*/}

                            <div>

                                <Form.Group as={Row} >
                                    <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                        <Label  name={`Amount Received ${currency_symbol !== 'undefined' ? `(${currency_symbol})`:''} `}  type htmlFor="amount_received"/>
                                    </Col>

                                    <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                                        <TextField submitted={submitted} type="number"  id="amount_received" value={!isExcluded ? 0 : paidAmount} onChange={handleChangePaidAmount}/>

                                            {/*{submitted && isBaseCurrency && paidAmount  < total_amount ? <ErrorMessage>Amount is less than the required amount</ErrorMessage> :*/}

                                        { submitted && !paidAmount ? <ErrorMessage>This is a required field</ErrorMessage>:null}
                                    </Col>
                                </Form.Group>

                                {
                                    isNotBase ?
                                        <Form.Group as={Row} >
                                    <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                        <Label  name={`Amount Received(${selectedCurrency?.currency_symbol})`} htmlFor="amount_in_usd"/>
                                    </Col>

                                            <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">{selectedCurrency?.currency_symbol}</InputGroup.Text>

                                            <TextField submitted={submitted} id="amount_in_usd" type="number"  disabled value={ isBaseCurrency ? paidAmount : usdAmount}/>

                                        </InputGroup>

                                        {/*{submitted && isNotBase && usdAmount && usdAmount < total_amount ? <ErrorMessage>Amount  is less than the required amount</ErrorMessage>:null}*/}
                                    </Col>
                                </Form.Group>
                                        :
                                        null
                                }

                                {/*{*/}
                                {/*    isBaseCurrency ?*/}
                                {/*        <Form.Group as={Row} >*/}
                                {/*    <Col xs={12} sm={12} md={12} lg={12} xl={3}>*/}
                                {/*        <Label  name="Amount Received(SSP)" htmlFor="amount_in_ssp"/>*/}
                                {/*    </Col>*/}

                                {/*            <Col xs={12} sm={12} md={10} lg={10} xl={7}>*/}
                                {/*        <InputGroup className="mb-3">*/}
                                {/*            <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">SSP</InputGroup.Text>*/}

                                {/*            <TextField submitted={submitted} id="amount_in_ssp" type="number" disabled value={ isNotBase ? paidAmount : sspAmount}/>*/}

                                {/*        </InputGroup>*/}

                                {/*                {submitted && isNotBase && usdAmount && usdAmount < total_amount ? <ErrorMessage>Amount  is less than the required amount</ErrorMessage>:null}*/}
                                {/*    </Col>*/}
                                {/*</Form.Group>*/}
                                {/*        :*/}
                                {/*        null*/}
                                {/*}*/}
                            
                            {/*<Form.Group as={Row}>*/}
                            {/*    <Col xs={12} sm={12} md={12} lg={12} xl={3}>*/}
                            {/*        <Label name="Balance" />*/}
                            {/*    </Col>*/}

                            {/*    <Col xs={12} sm={12} md={10} lg={10} xl={7}>*/}
                            {/*        <TextField type="text" value={!isExcluded ? 0 :  balance} readOnly/>*/}
                            {/*    </Col>*/}
                            {/*</Form.Group>*/}
                            </div>
                        </div>
                    </div>

                        <div className="other-billing-details mb-3">
                            <div className="service-details">

                                {
                                    groupedServices.length > 0 ?

                                        <ReusableBillTable
                                        title="Service(s)"
                                        headData={ unpaid.filter(item=>Boolean(item))}
                                        services={groupedServices}
                                        transaction_id={transaction_id}
                                        handleDelete={handleDeleteProcedure}
                                        handleChangeRate={handleChangeRate}
                                        handleChangeCurrencyRate={handleChangeCurrencyRate}
                                        isCurrency={isNotBase}
                                        handlePayProcedure={handlePayProcedure}
                                        {...{checkAllServices, handlePayAllProcedures,}}
                                    />
                                        :
                                        null
                                }

                                {
                                    groupedProducts.length > 0 ?
                                        <ProductsTable
                                            products={groupedProducts}
                                            submitted={submitted}
                                            headData={productHeadData.filter(item=>Boolean(item))}
                                            transaction_id={transaction_id}
                                            handleChangeProductRate={handleChangeProductRate}
                                            handleChangeProductQuantity={handleChangeProductQuantity}
                                            handleChangeProductCurrencyRate={handleChangeProductCurrencyRate}
                                            handleDeleteProduct={handleDeleteProduct}
                                            isCurrency={isNotBase}
                                            handlePayProduct={handlePayProduct}
                                            {...{checkAllProducts, handlePayAllProducts}}
                                        />
                                        :
                                        null
                                }

                            </div>
                        </div>

                        <button
                            type="button"
                            className="btn btn-sm btn-primary mr-3"
                            onClick={handleAddProduct}
                            disabled={isSubmitted === 'pending'}
                        >
                            Add Product
                        </button>

                        <button
                            type="button"
                            className="btn btn-sm btn-secondary mr-3"
                            onClick={handleAddService}
                            disabled={isSubmitted === 'pending'}
                        >
                            Add Service
                        </button>

                        <button
                            type="submit"
                            className="btn btn-sm sms-info-btn"
                            disabled={isSubmitted === 'pending'}
                        >
                            {isSubmitted === 'pending' ?
                            "Saving..." : "Save"}
                        </button>
                </form>
            </Container>
        </div>
    )
}

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(AddBill);
