import {useState,  useRef} from "react";
import axios from "axios";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import { config } from "../../../Helpers/env";
import { useRegisterPatient } from "../../PatientRecords/CustomHooks/useRegisterPatient";
import { titleCase } from "../../Users/addUser";
import { history } from "../../../Helpers/history";

function useAttachPatient(actions, visit_id='', setOpenAttach=null) {
    const [p_number, setPatientNumber] = useState('');
    const [showNumber, setShowNumber] = useState(false);
    const [patientNumbers, setPatientNumbers] = useState([]);
    const [patientDetails, setPatientDetails] = useState({first_name: '', last_name: '', age:'', gender:'', patient_number:''});
    const [openDialog, setOpenDialog] = useState(false)
    const {patientProps, submitted, isSubmitted, setSubmitted, setIsSubmitted, submitPatientDetails}= useRegisterPatient(actions);
    const formRef = useRef()
    const {setBloodType, setState,setPatientRelation,
        setKinContact, setContact} = patientProps;


    const handleChangePatientNumbers = (event) => {
        setPatientNumber(event.target.value);
        const formData = new FormData();
        if (event.target.value !== '') {
            formData.append('patient_name', titleCase(event.target.value));
            axios.post(`${config.smsUrl}/cvisit/emergency_patient_search`, formData)
                .then(res => {
                    const details = res.data;
                    const data = !details ? [] : details;
                    if (data[0] === "No Patient Found") {
                        setPatientNumbers([{value: '', label: 'No patient found'}])
                    } else {
                        setPatientNumbers(data)
                    }

                }).catch(error => {
                errorMessages(error, null, actions)
            });
            setShowNumber(true);
        } else {
            setShowNumber(false);
        }
        setIsSubmitted('idle')
    };

    const handleClickPatientNumber = (event) => {
        setPatientNumber(event.label);
        const formData = new FormData();
        formData.append('patient_number', event.value);
        axios.post(`${config.smsUrl}/cdoctor/get_patient_details`, formData)
            .then(res => {
                const details = res.data;
                const dt = details ? details : {}
                const {first_name, last_name, Age, gender, patient_number} = dt
                setPatientDetails({first_name: first_name ? first_name : '', last_name: last_name ? last_name : '', age:Age ? Age : '', gender:gender ? gender : '', patient_number})
            }).catch(error => {
            logoutErrorMessage(error, null, actions)
        })
        setShowNumber(false);
    };

    const handleSavePatient = (event) => {
        event.preventDefault();
       submitPatientDetails(event).then((res) => {
                actions.snackbarActions.snackSuccess("Patient registered successfully");
                setIsSubmitted('resolved')
                const resp = res.data['patient_number'] ? res.data['patient_number'] : '';
                event.preventDefault();
                const formData2 = new FormData();
                setSubmitted(true);
                formData2.append('visit_id', visit_id);
                formData2.append('patient_number', resp);
                setOpenDialog(false);
                if(resp && visit_id){
                    setIsSubmitted('pending');
                    axios.post(`${config.smsUrl}/cvisit/update_emergency_visit`, formData2).then(res =>{
                        actions.snackbarActions.snackSuccess('Patient added successful');
                        setOpenAttach(false);   
                        // send user to the emergency list
                        history.push(`/emergencylist`);   
                        setIsSubmitted('resolved');
                        setSubmitted(false);           
                    }).catch((err) => {
                        errorMessages(err, null, actions)
                        setIsSubmitted(('rejected'));
                    });
                }
            }).catch((err) => {
                errorMessages(err, null, actions);
                setIsSubmitted('rejected')
            });

    };

    const  handleOpenDialog = () =>{
        setOpenDialog(true)
    }

    const handleCloseDialog = () =>{
        setOpenDialog(false)
    }

    const isPending = isSubmitted === 'pending';

    
    const savePatientProps = {...patientProps,handleSavePatient, submitted, formRef, isPending, openDialog, handleCloseDialog,
    handleOpenDialog}
    const attachPatientProps = {p_number,showNumber,patientNumbers,patientDetails,
        handleClickPatientNumber,handleChangePatientNumbers, setPatientDetails, setPatientNumber}
    return {openDialog,handleOpenDialog, handleCloseDialog,savePatientProps,attachPatientProps}
}

export { useAttachPatient }

