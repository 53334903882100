import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../../actions/snackbarActions"
import PrintableReferralNote from "./PrintableReferralNote";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {useInternalPrintableNote} from "./hooks/useInternalPrintableNote";


function OutgoingReferralPrint(props) {
    const {referral, closeSnackbar} = useInternalPrintableNote(props)
    const {openBar, type, message} = props.snackbars;
    return (
        <div>
            <MainSnackbar handleCloseBar={closeSnackbar} message={message} open={openBar} variant={type}/>
            <PrintableReferralNote title="Outgoing Referral Note" subTitle="Outgoing referral note" text="Please attend to the above person whom we
                are referring to your hospital for further action" referral={referral}/>
        </div>
    )
} 

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(OutgoingReferralPrint);
