import {useEffect, useState} from "react";
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {logoutErrorMessage} from "../../../../Helpers/ErrorMessages";




export const useViewCreditorsReceipt = (actions, match) => {
    const [patient, setPatient] = useState({});
    const {provider_id, pay_status} =match.params;
    const [invoices, setInvoices] = useState([]);
    const [billDetails, setBillDetails] = useState([]);
    const [totals, setTotals] = useState({paid_amount: 0, due_amount_total: 0, payment_total: 0,
        total_bill_amount:0})
    const [date, setDate] = useState('')
    const [status, setStatus] = useState('idle')

    useEffect(() => {
        setStatus('pending')
        const formData = new FormData();
        formData.append('provider_id', provider_id);
        formData.append('pay_status', pay_status)
        axios.post(`${config.smsUrl}/cpatient/previous_balance`, formData)
            .then(res => {
                const details = res.data;
                const data = !details ? {} : details;
                const inv = !data.previous ? [] : data.previous;
                const p_invoice = data.patient_info ?? [];
                const item = p_invoice[0] ?? {};
                const dt = inv[inv.length - 1] ? inv[inv.length - 1]  : {};
                const obj  =  dt.invoice ? dt.invoice : {};
                const date_created = obj.date_created ? obj.date_created : '';
                setDate(date_created)
                setPatient(item)
                setBillDetails(inv)
                setStatus('success')
            }).catch(error => {
            logoutErrorMessage(error, null, actions)
            setStatus('error')
        });
    }, []);

    const calculateTotal = (arr) => {
        return arr.reduce((sum, item) => {
            return +item.amount_paid + +sum
        }, 0)
    }

    const calculateTotalAmount = (arr) => {
        const t_amount = arr.reduce((sum, item) => {
            return +item.total_mount + +sum
        }, 0)

        const p_total = arr.reduce((sum, item) => {
            return +item.total_paid + +sum
        }, 0)

        const due_amt = t_amount - p_total
        setTotals({paid_amount: p_total, due_amount_total:due_amt, payment_total: 0, total_bill_amount: t_amount})
    }


    useEffect(() => {
        const arr = billDetails.map(item=>{
            const {invoice,receipts} = item;
            return ({
                total_mount:invoice.quantity * invoice.rate,
                total_paid:calculateTotal(receipts)
            })
        })
        const arr1 = billDetails.map(item=>{
            const {invoice,receipts} = item;
            return ({ ...invoice,
                total_amount:invoice.quantity * invoice.rate,
                total_paid:calculateTotal(receipts)
            })
        })
        setInvoices(arr1)
        calculateTotalAmount(arr)
    }, [billDetails])



    const isPending = status === 'pending'
    const isResolved = status === 'success'
    const isRejected = status === 'error'

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };
    return {patient, isPending, isResolved, isRejected, invoices, totals, closeSnackbar, date, billDetails}
}
