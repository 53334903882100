import React, {useEffect, useLayoutEffect, useState} from 'react';
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import { useEditProductSetup } from './useEditProductSetUp';
import { useEntityLedgers } from './useEntityLedgers';



export const useSetUpSuppliers = (actions, component) => {
    const [suppliers, setSuppliers] = useState([]);
    const [isAllSuppliers, setIsAllSuppliers] = useState(false)
    const [debitAccount, setDebitAccount] = useState('')
    const [creditAccount, setCreditAccount] = useState('') 
    const [accounts, setAccounts] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [rows, setRows] = useState([ {id:'',name:'',credit_account:'',
        debit_account:''}])

    const [isSetUp, setIsSetUp] = useState(0)

    const entity_type = {
        'suppliers':'supplier',
        'customers':'customer',
    }


    const {editProps,openEdit, handleCloseEdit, handleOpenEdit,
        credit_ledger, editData,setCredit,setDebit, setProduct} = useEditProductSetup(actions)
    const {isResolvedEdit} = editProps
    const isLedger = isSetUp === 1
    const isEdit =  isResolvedEdit

    const {data, isLoading, isSuccess, isError,openDelete,handleCloseDelete,
        handleOpenDelete,entity_id,setData, setOpenDelete} = useEntityLedgers(entity_type[component], isLedger,isEdit, actions)

    const ledgers = {
        'suppliers': data.filter(item=>item.entity_type === 'supplier').map(item=>({
            ...item, name:item.supplier_name, id:item.id
        })),
        'customers': data.filter(item=>item.entity_type === 'customer').map(item=>({
            ...item, name:item.provider_name, id:item.id
        }))
    }

    useEffect(()=>{
        const {credit_ledger:credit,debit_ledger:debit, supplier_name,
            provider_name,credit_ledger_id,debit_ledger_id} = editData;
        if(!credit_ledger){
            return
        }
        const entity_type = {
            'suppliers':supplier_name,
            'customers':provider_name,
        }
        setCredit({value:credit_ledger_id, label:credit})
        setDebit({value:debit_ledger_id, label:debit})
        setProduct(entity_type[component])

    },[credit_ledger,component])

    useLayoutEffect(()=>{
        if(!component){
            return;
        }
        setIsSetUp(0)
    },[component])
    

    const formData = new FormData();
    formData.append('page', '')


    useEffect(() => {
        axios.get(`${config.smsUrl}/accounting/chart/journals`).then(res => {
            const data = res.data;
            const dt = data ? data : {};
            const arr = dt.data ? dt.data : []
            const list = arr.map(item => ({
                value: item.id,
                label: item.head_name
            }))
            setAccounts(list)
        }).catch(err => {
            errorMessages(err, null, actions)
        })
    }, [])

    const retrieveData = {
        'suppliers':()=>axios.post(`${config.smsUrl}/csupplier/manage_supplier`,formData),
        'customers':()=>axios.get(`${config.smsUrl}/cbilling/insurance_providers`),
    }



    useEffect(() => {
        retrieveData[component]().then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const arr_data = {
                'suppliers':!dt.suppliers_list ? [] : dt.suppliers_list,
                'customers':!dt.data ? [] : dt.data,
            }
            const final_arr = {
                'suppliers':arr_data[component].map(item=>({
                    ...item,
                    value: item.supplier_id,
                    label: item.supplier_name
                })),
                'customers':arr_data[component].map(item=>({
                    ...item,
                    value: item.provider_id,
                    label: item.provider_name
                })),
            }
            setSuppliers(final_arr[component])
         
        }).catch((err) => {
            logoutErrorMessage(err, null, actions);
       
        })
    }, [component]);


    const handleChange = (event) => {
        const {checked} = event.target;
        setIsAllSuppliers(checked)
    }

    const handleChangeAllDebitAccount = (val) => {
        setDebitAccount(val)
    }

    const handleChangeAllCreditAccount = (val) => {
        setCreditAccount(val)
    }

    const handleChangeDebitAccount = (val, index) => {
        const arr = rows.map((item, ind) => {
            if (ind === index) {
                return {...item, debit_account: val}
            }
            return item
        })
        setRows(arr)
    }

    const handleChangeCreditAccount = (val, index) => {
        const arr = rows.map((item, ind) => {
            if (ind === index) {
                return {...item, credit_account: val}
            }
            return item
        })
        setRows(arr)
    }

    const handleAddRow = () =>{
        const obj = {id:'',
        name:'',
        credit_account:'',
        debit_account:''}
        setRows([...rows, obj])
    }

    const handleRemoveRow = (index) =>{
        const arr = rows.filter((_,ind)=>ind !== index)
        setRows(arr)
    }


    const handleChangeSupplier = (val, index) => {
        const arr = rows.map((item, ind) => {
            if (ind === index) {
                return {...item, id: val}
            }
            return item
        })
        setRows(arr)
    }



    const handleSubmit = (event) => {
        event.preventDefault();
        setSubmitted(true);
       
        const data = rows.map(item=>{
            const credit  = item.credit_account ? item.credit_account : {};
            const credit_value = credit.value ? credit.value : ''
            const debit  = item.debit_account ? item.debit_account : {};
            const debit_value = debit.value ? debit.value : ''
            const supplier = item.id ? item.id : {};
            const id = supplier.value ? supplier.value : ''
            return{
            entity_id: +id,
            credit_ledger:+credit_value,
            debit_ledger :+debit_value,
            entity_type:entity_type[component],
            default:0
         }
        })
        const credit  = creditAccount ? creditAccount: {};
        const credit_value = credit.value ? credit.value : ''
        const debit  = debitAccount ? debitAccount : {};
        const debit_value = debit.value ? debit.value : ''

        const params = isAllSuppliers ? {data:[{credit_ledger: +credit_value,debit_ledger: +debit_value, 
            entity_id:'', entity_type:entity_type[component], default:1}]} : {data};
        const arr = rows.every(item=>item.id && item.debit_account && item.credit_account)
        if(arr){
            setIsSubmitted('pending');
            axios.post(`${config.smsUrl}/accounting/entity_behavior/add`,
                {...params}).then(() => {
                actions.snackbarActions.snackSuccess('Entry made successfully')
                setIsSubmitted('resolved')
                const arr = rows.map(item=>({
                    ...item,id:'',name:'',credit_account:'', debit_account:'',
                }))
                setRows(arr)
                setSubmitted(false)
            }).catch(err => {
                errorMessages(err, null, actions)
                setIsSubmitted('rejected')
            })
        }

     
    }

    const handleDelete = () => {
        const formData = new FormData();
        formData.append('id', entity_id);
        axios.post(`${config.smsUrl}/accounting/chart/delete_account`, formData).then(() => {
            const arr = data.filter(item=>item.id !== entity_id)
            actions.snackbarActions.snackSuccess(`Ledger deleted successfully`);
            setOpenDelete(false)
            setData(arr)
        }).catch(err => {
            errorMessages(err, null, actions)
        })
    }

    const handleChangeSetup = (status) =>{
        setIsSetUp(status)
    }


    const closeSnackbar = () =>{
        actions.snackbarActions.hideSnackbar()
    }


    const isPending = isSubmitted === 'pending'
    const isResolved = isSubmitted === 'resolved'
    const isRejected = isSubmitted === 'rejected'

    const supplierProps = {entities:suppliers, isAllAccounts:isAllSuppliers, debitAccount, creditAccount, rows, handleAddRow,
        accounts, submitted, handleChange, handleChangeAllCreditAccount, handleChangeAllDebitAccount,
        handleChangeCreditAccount, handleChangeDebitAccount, handleSubmit, isLoading, isSuccess,
        isError, isPending, isRejected, isResolved, handleRemoveRow,handleChangeEntity:handleChangeSupplier}

    const ledgerData = {data:ledgers[component], isLoading, isSuccess, isError,openEdit,
            handleOpenEdit, handleCloseEdit,openDelete,handleCloseDelete,handleOpenDelete,entity_id,handleDelete}

    return {supplierProps, closeSnackbar, ledgerData, editProps, isSetUp, handleChangeSetup}
}