import React, {useEffect, useState} from 'react'
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from '../../../actions/snackbarActions'
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import { PLContainer,ProfitContainer, RightAlignedContainer} from '../../Utils/styledComponents';
import { usePrint } from '../../Utils/Templates/usePrint';
import { faClipboardList } from '@fortawesome/free-solid-svg-icons/faClipboardList';
import { BackButton, DataExportButton } from '../../Utils/Buttons/DataExportationButton';
import {PrintPdfButtons} from "./PrintPdfButtons";
import Card  from '@material-ui/core/Card';
import {NoTransaction} from "../ChartofAccounts/ReusableJournalTable";
import { LoadingGif } from '../../Utils/Loader';
import axios from "axios";
import { config } from "../../../Helpers/env";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {HospitalAddress} from "../../Utils/HospitalAddress";
import {COACell, TrialBalancePrint, TrialBalanceTree} from "../Utils/TrialBalanceTree";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {COATableCustomCell} from "../ChartofAccounts/TreeViewComponent";
import {history} from "../../../Helpers/history";
import {formatDigits} from "../../Utils/formatNumbers";
import Label from '../../Utils/FormInputs/Label';
import { CustomSelect } from '../../Utils/FormInputs/SelectInput';
import { Form } from 'react-bootstrap';
import { dateConvert } from '../../Utils/ConvertDate';
import { Table, TableBody, TableCell } from '@material-ui/core';
import { useExcelReports, usePdfDownload } from '../../Utils/ReusableComponents/useExcelExport';

// const header = (
//     <TableHead>
//         <TableRow>
//             <COATableCustomCell><strong></strong></COATableCustomCell>
//             <COACell variant='200'><strong>Debit</strong></COACell>
//             <COACell variant='120'><strong>Credit</strong></COACell>
//         </TableRow>
//     </TableHead>
// )

// const tree_label = (str_type, nav,   isParent) => {
//     if (str_type === 'final_level') {
//         return (
//             <TableRow>
//                 <COATableCustomCell  onClick={()=>history.push(`/view_transactions/${nav.id}/${nav.head_code}-${nav.head_name}`)}
//                                      style={{cursor:'pointer'}}>
//                     <span  style={{color: '#0065ff',   display:'inline-block'}}>{nav.account_name || nav.name}</span></COATableCustomCell>
//                 <COACell variant='200'><strong>{ nav.debit_value ? formatDigits( nav.debit_value) : nav.debit_value}</strong></COACell>
//                 <COACell variant='120'><strong>{ nav.credit_value ? formatDigits( nav.credit_value) : nav.credit_value}</strong></COACell>
//             </TableRow>
//         )
//     } else if (str_type === 'current_parent') {
//         return (
//             <TableRow>
//                 <COATableCustomCell  style={{fontWeight : isParent ? 'bold':''}}>
//                     <span style={{fontWeight : isParent ? 'bold':''}}>{nav.account_name || nav.name}</span>
//                 </COATableCustomCell>
//                 <COACell variant='200' ><strong>{ nav.debit_value ? formatDigits( nav.debit_value) : nav.debit_value}</strong></COACell>
//                 <COACell variant='120' ><strong>{ nav.credit_value ? formatDigits( nav.credit_value) : nav.credit_value}</strong></COACell>
//             </TableRow>
//         )
//     } else {
//         return (
//             <TableRow>
//                 <COATableCustomCell style={{fontWeight : isParent ? 'bold':''}} >{nav.account_name || nav.name}</COATableCustomCell>
//                 <COACell variant='200' ><strong>{ nav.debit_value ? formatDigits( nav.debit_value) : nav.debit_value}</strong></COACell>
//                 <COACell variant='120' ><strong>{ nav.credit_value ? formatDigits( nav.credit_value) : nav.credit_value}</strong></COACell>
//             </TableRow>
//         )
//     }
// }



const TrialBalance = ({actions, snackbar}) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState('idle')
    const [accountingPeriod,setPeriod] = useState([])
    const [changePeriod, setChangePeriod] = useState('')
    const [dates, setDates] = useState({"start_date": dateConvert(), "end_date": dateConvert()})

    const {start_date, end_date} = dates
    useEffect (() =>{
    axios.get(`${config.smsUrl}/accounting/get_new_trial_balance_report`,{params:{start_date: start_date, end_date: end_date}}).then(res => {
        const data = res.data;
        const dt = data ? data : {};
        setData(dt)
        setLoading('success')
        }).catch(err => {
            errorMessages(err, null, actions)
        })
    },[start_date, end_date])
    

    const handleChange = (e) => {
        const { name, value } = e.target; 
        setDates({ ...dates, [name]: value });
    };

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    }
    const isLoading = loading === 'pending';
    const isSuccess = loading === 'success';
    const isError = loading === 'error';


    const {open, variant, message} = snackbar;




    // const {componentRef, handlePrint} = usePrint( `Trial Balance`)

    const fileName = `Trial balance`

    const excelObj = {file_name:fileName,params:{ start_date:start_date, end_date:end_date, download: 1}, url:`/accounting/get_new_trial_balance_report_exports`}

    const pdfObj = {file_name:fileName,params:{ start_date:start_date, end_date:end_date, download: 2}, url:`/accounting/get_new_trial_balance_report_exports`}


    const {exportExcel, isLoading:isLoadingExcel} = useExcelReports(actions,excelObj)
    const {exportPdf, isLoading:isLoadingPdf} = usePdfDownload(actions,pdfObj)
   

    return (
        <div className='journals'>
            <PageTitle title="Trial Balance"/>
            <SubHeader title="Reports" subTitle="Trial Balance">
                <FontAwesomeIcon icon={faClipboardList}/>
            </SubHeader>

            <MainSnackbar message={message} open={open} handleCloseBar={closeSnackbar} variant={variant}/>

            <PLContainer>

                <Card className="mx-2 my-3">
                        <RightAlignedContainer>
                                 <DataExportButton isLoading={isLoadingExcel || isLoadingPdf} downLoadPdf={exportPdf}
                                            exportCsv={exportExcel} isExcel={true}/>
                        </RightAlignedContainer>
                    <div className="px-8 py-16" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} className='m-3'>
                            <div className="form-group pr-4" style={{display: 'flex',justifyContent: 'center', alignItems: 'center'}}>
                                <label className="" htmlFor="startDate">
                                    From&nbsp;
                                </label>
                                <input
                                id='startDate'
                                    type="date"
                                    name="start_date"
                                    value={start_date}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                    data-testid="start-date"
                                    // defaultValue={reverseDate())}
                                />
                            </div>

                            <div className="form-group" style={{display: 'flex',justifyContent: 'center', alignItems: 'center'}} >
                                <label className="ml-4" htmlFor="endDate">
                                    To&nbsp;
                                </label>
                                <input
                                id='endDate'
                                    type="date"
                                    name="end_date"
                                    value={end_date}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                    data-testid="end-date"
                                    // defaultValue={reverseDate())}
                                />
                            </div>
                        </div>
                        {/* <div className="">
                            <PrintPdfButtons
                                downloadPdf={() => {}}
                                handlePrint={handlePrint}
                            />
                        </div> */}
                    </div>
                    <div >
                        <table className="table table-sm table-borderless prescription-table">
                            <HospitalAddress/>
                            
                            <tr>
                                <td align="center">
                                    <h5>
                                        <strong>Trial Balance</strong>
                                    </h5>
                                </td>
                            </tr>
                            <tr>
                                <td align="center">
                                    {/* <h6>
                                        <strong>{formatDate(new Date(Date.now()))}</strong>
                                    </h6> */}
                                </td>
                            </tr>
                        </table>

                        <Table style={{ display: 'flex', flexDirection: 'column' }}>
                            <TableBody style={{ display: 'flex', flexDirection: 'column' }}>
                                <TableRow className="mt-5" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <TableCell className="w-50" style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}></TableCell>
                                    <TableCell className="w-25" style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                                        <strong>CREDITS</strong>
                                    </TableCell>
                                    <TableCell className="w-25" style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                                        <strong>DEBITS</strong>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                       

                        <Table className="" data-testid="bal">
                            <TableBody className="">
                                {data?.categories &&
                                Object.entries(data?.categories).length > 0 ? (
                                    Object.entries(data?.categories).map(
                                        ([key, values], idx) => (
                                            <>
                                                <Table className="" key={idx} style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <TableHead
                                                        style={{ display: 'flex', justifyContent: 'start', marginLeft: '2px', marginTop: '3px' }}
                                                        data-toggle={`category-${key}`}
                                                    >
                                                        <TableRow className='ml-3'>
                                                            <strong>{key}</strong>
                                                        </TableRow>
                                                    </TableHead>

                                                    <TableBody>
                                                        <Table id={`category-${key}`}>
                                                            {values?.account_details &&
                                                            values?.account_details.length > 0 ? (
                                                                values?.account_details.map(
                                                                    (accountDetail, index) => (
                                                                        <TableRow
                                                                            style={{ display: 'flex', flexDirection: 'row' }}
                                                                            key={index}
                                                                        >
                                                                            <TableCell
                                                                               style={{ width: '50%', display: 'flex', justifyContent: 'start', alignItems: 'center' }}
                                                                                // data-testid={`account_name`}
                                                                                data-testid={accountDetail.account}
                                                                            >
                                                                                {accountDetail?.account}
                                                                            </TableCell>
                                                                            <TableCell
                                                                                style={{width: '25%', display: 'flex', justifyContent: 'end', alignItems: 'center' }}
                                                                                data-testid={`${accountDetail.account}_credit`}
                                                                            >
                                                                                {accountDetail?.credit}
                                                                            </TableCell>
                                                                            <TableCell
                                                                                style={{width: '25%', display: 'flex', justifyContent: 'end', alignItems: 'center' }}
                                                                                data-testid={`${accountDetail.account}_debit`}
                                                                            >
                                                                                {accountDetail?.debit}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    )
                                                                )
                                                            ) : (
                                                                <TableRow style={{ display: 'flex' }}>
                                                                    <TableCell style={{ width: '100%', display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                                                                        No Data Available
                                                                    </TableCell>
                                                                </TableRow>
                                                            )}
                                                        </Table>
                                                    </TableBody>
                                                </Table>
                                            </>
                                        )
                                    )
                                ) : (
                                    <TableRow>
                                        <COATableCustomCell align="center">
                                            <strong>No Data available </strong>
                                        </COATableCustomCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>

                        <Table>
                            <TableBody>
                                <TableRow style={{ display: 'flex', marginTop: '5px' }}>
                                    <TableCell style={{ width: '50%', display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                        <strong>Total</strong>
                                    </TableCell>
                                    <TableCell style={{ width: '25%', display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                                        {data?.total_credits ?? 0}
                                    </TableCell>
                                    <TableCell style={{ width: '25%', display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                                        {data?.total_debits ?? 0}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        
                    </div>
                  
                </Card>
            </PLContainer>
        </div>)
}
function mapStateToProps(state) {
    const {snackbar} = state;
    return {snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(TrialBalance);

