import React from "react";
import Form from "react-bootstrap/Form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {faMinus} from "@fortawesome/free-solid-svg-icons/faMinus";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as departmentActions from "../../../actions/DoctorsActions/departmentActions";
import * as snackbarActions from "../../../actions/snackbarActions";
import Label from "../../Utils/FormInputs/Label";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {useTheme} from "@material-ui/core/styles";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import Card from "@material-ui/core/Card";
import {faShippingFast} from "@fortawesome/free-solid-svg-icons/faShippingFast";
import {dateStrokeConvert} from "../../Utils/ConvertDate";
import {useEditDispatch} from "./hooks/useEditDispatch";
import PrintDialog from "../../Utils/Dialogs/PrintDialog";


const EditDispatchNote = ({actions, snackbars,match}) => {
    const {products,  isSubmitted, state,submitted,handleChangeBatch,
        openDialog, handleCloseDialog, handleCancel, handleClick, handleChangeItems, handleAddRow, removeRow,
        handleSubmit, closeSnackBar,status} = useEditDispatch(actions,match)
    const {openBar, type, message} = snackbars;
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    const { date_requested,issued_by,
        date_issued, from_department, to_department, requested_by} = state;
    return (
        <div data-testid="edit-dispatch">
            <PageTitle title="Edit Dispatch"/>
            <SubHeader title="Inventory" subTitle="Edit Dispatch">
                <FontAwesomeIcon icon={faShippingFast}/>
            </SubHeader>
            <PrintDialog openDialog={openDialog} handleClose={handleCloseDialog} message="Print Dispatch Note"
                         text="Would you like to print this dispatch note" handleClick={handleClick} handleCancel={handleCancel}/>
            <div className="add-bill-card">
                <Card className="p-3">
                    <h6 className={`visit-heading ${palette.type === "dark" && "dark-visit-heading"}`}>Edit Dispatch</h6>
                    <form onSubmit={handleSubmit} autoComplete="off">
                        <MainSnackbar message={message} variant={type} handleCloseBar={closeSnackBar} open={openBar}/>
                        <div className="row">
                            <div className="col-md-6">
                                <Form.Group as={Row}>
                                    <Label name="Date Requested" htmlFor='date_requested' sm="4" column/>
                                    <Col sm="6">
                                        <Form.Control type="text" value={date_requested ? dateStrokeConvert(date_requested):''} disabled={true}
                                                      className={`form__control ${palette.type === "dark" && 'form-control-dark'}`}
                                                      name="date_issued" id='date_requested'/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Label name="Requested By" htmlFor='resquested_by' sm="4" column/>
                                    <Col sm="6">
                                        <Form.Control type="text" value={requested_by} disabled={true}
                                                      className={`form__control ${palette.type === "dark" && 'form-control-dark'}`}
                                                      name="received_by" id='requested_by' />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row}>
                                    <Label name="From" sm="4" htmlFor='from_department' column/>
                                    <Col sm="6">
                                        <Form.Control type="text" value={from_department} disabled={true}
                                                      className={`form__control ${palette.type === "dark" && 'form-control-dark'}`}
                                                      name="received_by" id='from_department' />
                                    </Col>
                                </Form.Group>


                            </div>
                            <div className="col-md-6">
                                <Form.Group as={Row}>
                                    <Label name="Date Issued" htmlFor='date_issued' sm="4" column/>
                                    <Col sm="6">
                                        <Form.Control type="text" value={date_issued ? dateStrokeConvert(date_issued):''} disabled={true}
                                                      className={`form__control ${palette.type === "dark" && 'form-control-dark'}`}
                                                      name="date_issued" id='date_issued'/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Label name="Received By" htmlFor='issued_by' sm="4" column/>
                                    <Col sm="6">
                                        <Form.Control type="text" value={issued_by} disabled={true}
                                                      className={`form__control ${palette.type === "dark" && 'form-control-dark'}`}
                                                      name="received_by" id='issued_by'/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Label name="To" htmlFor='to_deparment' sm="4" column/>
                                    <Col sm="6">
                                        <Form.Control type="text" value={to_department} disabled={true}
                                                      className={`form__control ${palette.type === "dark" && 'form-control-dark'}`}
                                                      name="received_by" id='to-department'/>
                                    </Col>
                                </Form.Group>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table
                                className={`table table-sm table-bordered ${palette.type === 'dark' && 'dark-table dark-table-bordered'}`}>
                                <thead>
                                <th className={palette.type === 'dark' && 'dark-theme-color'}>Particulars
                                </th>
                                <th className={palette.type === 'dark' && 'dark-theme-color'}>Unit
                                </th>
                                <th className={palette.type === 'dark' && 'dark-theme-color'}>Quantity Requested</th>
                                {!status &&<th className={palette.type === 'dark' && 'dark-theme-color'}>Batch ID<span className="help-block">*</span></th>}
                                <th
                                    className={palette.type === 'dark' && 'dark-theme-color'}>Available Quantity
                                </th>
                                <th
                                    className={palette.type === 'dark' && 'dark-theme-color'}>Quantity Issued
                                </th>
                                <th/>
                                </thead>
                                <tbody>
                                {products.map((item, index) => {
                                    // const count = index += 1;
                                    return (
                                        <tr key={index} id="drugs-row">
                                            {/*<td/>*/}
                                            <td>{item.product_name}</td>
                                            <td>{item.unit}</td>
                                            <td>{item.quantity_requested}</td>
                                            {!status && <td>
                                                <Form.Control as="select"
                                                              className={`form__control ${palette.type === "dark" && 'form-control-dark'}`}
                                                              value={item.batch_id}
                                                              onChange={(e) => handleChangeBatch(e, index)}>
                                                    {item.batches.map((batch, index) => (
                                                        <option value={batch} key={index}>{batch}</option>
                                                    ))}
                                                </Form.Control>
                                                {(submitted && !item.batch_id) &&
                                                <div className="help-block">Batch ID is required</div>}
                                            </td>}
                                            <td>{item.available_quantity}</td>
                                            <td><Form.Control value={item.quantity} name="quantity"
                                                              onChange={(e) => handleChangeItems(e, index)} type="text"
                                                              className={`form__control presc ${palette.type === "dark" && 'form-control-dark'}`}
                                                              data-testid="quantity"/>
                                                {item.isBatch ? <div className="help-block" >Please select a batch</div> :(item.quantity > item.available_quantity
                                                || (submitted && item.quantity > item.available_quantity)) &&
                                                    <div className="help-block" >Quantity should be less than available quantity</div>
                                                }
                                            </td>
                                            <td>
                                                <div className="btn-group">
                                                    <button type="button" className="btn btn-sm sms-btn" id="add-row"
                                                            onClick={handleAddRow}><FontAwesomeIcon
                                                        icon={faPlus}/>
                                                    </button>
                                                    <button onClick={() => removeRow(index)} type="button"
                                                            className="btn btn-sm sms-grey-btn" id="remove-row">
                                                        <FontAwesomeIcon icon={faMinus}/>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>)
                                })}
                                </tbody>
                            </table>
                        </div>
                        <div className="text-right mt-2">
                            <button type="reset" id="reset_form"
                                    className="sms-grey-btn btn btn-sm px-3 mr-2">Reset
                            </button>
                            <button type="submit" disabled={isSubmitted === 'pending' || isSubmitted ==='resolved'}
                                    className="btn sms-btn btn-sm">{isSubmitted === 'pending' ? "Saving..." : "Save"}</button>
                        </div>
                    </form>
                </Card>
            </div>
        </div>

    )
};

function mapStateToProps(state) {
    return {
        snackbars: state.snackbar,
        departs: state.departments
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch),
            departmentActions: bindActionCreators(departmentActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditDispatchNote);
