import React, {useEffect, useState} from "react";
import PageTitle from "../../../Utils/smsTitle";
import {SubHeader} from "../../../../Containers/SubHeader";
import { anteNatalRoutes } from "./ReusableAnteNatalTabs";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../../../actions/snackbarActions";
import {connect} from "react-redux";
import MainSnackbar from "../../../Utils/Snackbar/SmsSnackbar";
import { useNewLabRequest } from "../../../DoctorsModule/Lab/hooks/useNewLabRequest";
import { useEditLabRequest } from "../../../DoctorsModule/Lab/hooks/useEditLabRequest";
import ReusableAnteNatalTabs from "./ReusableAnteNatalTabs";
import { usePatientDetails } from '../../../DoctorsModule/ReferralNote/usePatientDetails';
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserMd} from "@fortawesome/free-solid-svg-icons/faUserMd";
import {dateConvert, formatDate} from "../../../Utils/ConvertDate";
import { PatientInformation } from "../../../DoctorsModule/PatientInfo/PatientInformation";
import { NewLabTable } from "../../../DoctorsModule/Lab/NewLabRequest";
import { CenteredContainer } from "../../../Utils/styledComponents";
import { LoadingGif } from "../../../Utils/Loader";
import {CollapsibleList, DataCell,  ModuleTable} from "../../../Utils/Lists/CollapsibleList";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import TableRow from "@material-ui/core/TableRow";
import CustomDialog from "../../../Utils/Dialogs/CustomDialog";
import { StyledContainer } from "../../../DoctorsModule/Prescription/NewPrescription";
import PatientLabReport from "../../../Lab/PatientLabReport";
import {PendingStatus, FinishedStatus, StyledIcon, P, Container, ViewButton, StyledLibraryIcon} from "../../../DoctorsModule/doctorStyles";
import { logoutErrorMessage } from "../../../../Helpers/ErrorMessages";
import {LabResultsReport} from "../../../Lab/LabResultsReport";

const headData = [{item:'Lab test', toolTip:'Make Lab test request here', isRequired:true},{item:'Lab', isRequired:true},
    {item:'Status', isRequired:false},{item:'Action', isRequired:false}]
/**
 * Component that renders the Lab Request screen in Ante Natal
 * @param {*} param0 
 * @returns 
 */
const AnteNatalLabRequest = ({actions, snackbars, match}) => {
    const {params} = match;
    const {patient_number, labrequest_id, scan_request_id, visit_id, patient_type,patient_admission_id, anc_id, anc_status} = match.params;
    const arr = anteNatalRoutes(patient_number, visit_id, labrequest_id, scan_request_id, patient_type,patient_admission_id, anc_id, anc_status);

    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const doctor_name = `${!_token.first_name ? "" : _token.first_name} ${!_token.last_name ? "" : _token.last_name}`;

    const [patient, setPatient] = useState({patient_name: "", age: "", gender: "", address: "", phone: ""});
    const [labRequests, setLabRequests] = useState([])
    const [status, setStatus] = useState('idle')
    const [previousRequest, setRequest] = useState([]);
    const [openReport, setOpenReport] = useState(false)
    const [id, setId] = useState('')
    const [openResult, setOpenResult] = useState(false)
    const [report, setReport] = useState({});
    const [labTests,setLabTests] = useState([]);
    const [resultDetails, setResultDetails] = useState({});
    const [resultParams,setParams] = useState([]);
    const [result_id, setResultId] = useState('')


    const {patient: patientDetails} = usePatientDetails(actions, patient_number, '')

    const editParams = {...params, previousRequest}

    const {addProps} = useNewLabRequest(actions,params,_token)
    const {isResolved:isResolvedAdd} = addProps;
    const {editProps} = useEditLabRequest(actions,editParams,_token)
    const {isResolved:isResolvedEdit, labTests:editTests} = editProps;


    useEffect(() => {
        setPatient({
            patient_name: `${!patientDetails.first_name ? "" : patientDetails.first_name} ${!patientDetails.last_name ? "" : patientDetails.last_name}`,
            age: patientDetails.Age,
            gender: patientDetails.gender,
            address: patientDetails.address,
            phone: patientDetails.phone_no
        });
    }, [patientDetails]);

    useEffect(()=>{
        if (!result_id){
            return;
        }
        const formData = new FormData();
        formData.append('test_results_id',result_id);
        axios.post(`${config.smsUrl}/claboratory/get_lab_results_details_by_id`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const patient_info = !dt.patient_info ? {} : dt.patient_info;
            const parameters = !dt.params ? [] : dt.params;
            setResultDetails(patient_info);
            setParams(parameters)
        }).catch(err => {
            logoutErrorMessage(err,null,actions);
        })
        return ()=>{
            setResultDetails({})
            setParams([])
        }

    },[result_id])


    useEffect(()=>{
        if(!id){
            return
        }
        const formData = new FormData();
        formData.append('visit_id', visit_id);
        axios.post(`${config.smsUrl}/cdoctor/get_lab_results_details_by_visit_id`, formData).then(res=>{
            const data = res.data;
            const dt = !data ? {} : data;
            const rp = !dt.patient_info ? {} : dt.patient_info;
            const tests = !dt.lab_result ? [] : dt.lab_result;
            const arr = tests.map(item=>({...item, open:false}))
            setReport(rp);
            setLabTests(arr)
        }).catch(err=>{
            logoutErrorMessage(err,null, actions)
        })
    },[id]);

    const handleOpenItem = (index) =>{
        const openedArr = labTests.map((item, idx)=>{
            if(idx === index){
                return {...item, open:!item.open}
            }else {
                return item
            }

        })
        setLabTests(openedArr)
    }




    useEffect(() => {
        const formData = new FormData();
        formData.append('visit_id', visit_id)
        setStatus('pending')
        axios.post(`${config.smsUrl}/cdoctor/get_previous_lab_request_by_visit_id`, formData).then(res => {
            const data = res.data;
            const dt = !data ? [] : data;
            const arr = groupByLabRequestId(dt)
            const requests = arr.map(item=>({...item, open:true}))
            setStatus('success')
            setLabRequests(requests);
            setRequest(dt)
        }).catch(error => {
            setStatus('error')
            logoutErrorMessage(error, null, actions)
        });
    }, [isResolvedAdd, isResolvedEdit])



    const groupByLabRequestId = (arr=[]) =>{
        let result = []
        let knowledge = {}
        arr.forEach(item =>{
            let id = formatDate(item.date_created);
            if(id in knowledge){
                result[knowledge[id]].tests.push(item)
            }else {
                knowledge[id] = result.length
                result.push({
                    id: item.labrequest_id,
                    date: formatDate(item.date_created),
                    doctor_name:`${item.doctor_firstname ? item.doctor_firstname:''} ${item.doctor_lastname ? item.doctor_lastname : ''}`,
                    tests: [item]
                })

            }
        })
        return result
    }

    const handleOpen = (id) =>{
        const itemsArr = labRequests.map((item)=>{
            if(item.id === id){
                return {...item,open:!item.open}
            }else{
                return item
            }
        })
        setLabRequests(itemsArr)
    }

    const handleOpenReport  = (_id) =>{
        setId(_id)
        setOpenReport(true)
    }

    const handleCloseReport  = () =>{
        setOpenReport(false)
    }

    const handleOpenResult  = (test_results_id) =>{
        setResultId(test_results_id)
        setOpenResult(true)
    }

    const handleCloseResult  = () =>{
        setOpenResult(false)
    }

    const isLoading = status === 'pending'
    const isSuccess = status === 'success'
    const isError = status === 'error'

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const {patient_name, age, gender, address, phone} = patient;
    const {openBar, type, message} = snackbars;
    const {openDialog, handleCloseDialog, handleOpenDialog, handleSubmitLabRequest, isPending} = addProps
    const {openEdit, handleCloseEdit, handleOpenEdit, handleSubmitLabRequest:handleSubmit, isPending:isPendingEdit} = editProps
    return (
        <div className='journals' data-testid="ante-natal-lab-request">
            <PageTitle title="Lab Request"/>
            <SubHeader title="Ante Natal" subTitle="Lab Request" doctor_name={doctor_name}>
            <div className="header-icon-margin-right-zero"><img src="/images/antenatal-blue.png" className="icon-resize surgery-icon-tabs ante-natal-green"/></div>
            </SubHeader>
            <CustomDialog open={openDialog} handleClose={handleCloseDialog} title='Add Lab Request' maxWidth='lg'>
                <form onSubmit={handleSubmitLabRequest}>
                    <NewLabTable {...addProps}/>
                    <StyledContainer>
                        <button type='submit' className='btn sms-btn btn-sm' disabled={isPending}>{isPending ? 'Saving...' :'Save Lab Request'}</button>
                    </StyledContainer>
                </form>
            </CustomDialog>

            <CustomDialog open={openReport} handleClose={handleCloseReport} title='Lab Results' maxWidth='lg'>
                <PatientLabReport data={report} labTests={labTests} handleOpenItem={handleOpenItem} labReport/>
            </CustomDialog>

            <CustomDialog open={openResult} handleClose={handleCloseResult} title='Lab Results' maxWidth='lg'>
                <LabResultsReport params={resultParams} resultDetails={resultDetails}/>
            </CustomDialog>

            <CustomDialog open={openEdit} handleClose={handleCloseEdit} title='Edit Lab Request' maxWidth='lg'>
                <form onSubmit={handleSubmit}>
                    <NewLabTable {...{...editProps, isEdit:true,isOutPatient:true}}/>
                    <StyledContainer>
                        <button type='submit' className='btn sms-btn btn-sm' disabled={isPendingEdit}>{isPendingEdit ? 'Saving...' :'Save Lab Request'}</button>
                    </StyledContainer>
                </form>
            </CustomDialog>
            <MainSnackbar open={openBar} handleCloseBar={closeSnackbar} variant={type} message={message}/>
            <ReusableAnteNatalTabs components={arr}>
            <PatientInformation phone={phone} gender={gender} age={age} patient_name={patient_name}
                                    address={address} patient_number={patient_number}
                                    date={dateConvert()}/>


                <Container>
                    <button onClick={handleOpenDialog}  type='button' className='btn btn-sm sms-info-btn mb-2'>Add Lab Request</button>
                    {isLoading ? <CenteredContainer>
                        <LoadingGif/>
                    </CenteredContainer> : null}
                    {isSuccess ? labRequests.length > 0 ? labRequests.map((item)=>{
                        // const isAdded = item.tests.some(item=>item.item_status !== 'Done')
                        const isDone = item.tests.some(item=>item.item_status === 'Done')
                        const payStatus = item.tests.some(item=>item.pay_status === 0)
                        return(
                            <CollapsibleList key={item.id} open={item.open} name={item.date} handleOpen={()=>handleOpen(item.id)} button={
                                <>
                                    {payStatus || editTests.length > 0 ? <Tooltip title='Edit Request'>
                                        <IconButton onClick={()=>handleOpenEdit(item)}>
                                            <StyledIcon/>
                                        </IconButton>
                                    </Tooltip> : null}
                                    {isDone ?
                                        <ViewButton onClick={()=>handleOpenReport(item.id)} className='btn btn-sm sms-info-btn btn-sm'>View Results</ViewButton>
                                        : null}
                                    {/*{isDone ? <Tooltip title='Lab Results'>*/}
                                    {/*    <IconButton onClick={()=>handleOpenReport(item.id)}>*/}
                                    {/*        <StyledLibraryIcon/>*/}
                                    {/*    </IconButton>*/}
                                    {/*</Tooltip>: null}*/}
                                </>
                            }
                                // user={ <ItemText  primary={`Requested By: ${item.doctor_name}`} />}
                            >
                                <ModuleTable headData={headData}>
                                    {item.tests.map((row, index) => {
                                        const isPending = row.item_status === 'Pending' || row.item_status === null;
                                        const isFinished = row.item_status === 'Done';
                                        const isRemoved = row.pay_status === 2;
                                        return(
                                            <TableRow
                                                key={index}
                                            >
                                                <DataCell>
                                                    {row.hospital_test_name}
                                                </DataCell>
                                                <DataCell>{row.hospital_test_type}</DataCell>
                                                <DataCell>{ isRemoved ? <PendingStatus>Test excluded</PendingStatus>:isPending ? <PendingStatus>{row.item_status ? row.item_status : 'Pending'}</PendingStatus>:
                                                    isFinished ? <FinishedStatus>{row.item_status}</FinishedStatus>: null}</DataCell>
                                                <DataCell>{isFinished ?
                                                    <Tooltip title='View Results'>
                                                        <IconButton onClick={()=>handleOpenResult(row.test_results_id)}>
                                                            <StyledLibraryIcon/>
                                                        </IconButton>
                                                    </Tooltip> : null}
                                                </DataCell>
                                            </TableRow>
                                        )})}
                                </ModuleTable>
                            </CollapsibleList>
                        )
                    }) : <CenteredContainer>
                        <P>No lab tests requested for patient, click the add request button to request lab tests for patient</P>
                    </CenteredContainer>:null}
                    {isError ? <CenteredContainer>
                        <P>An error occurred on the server</P>
                    </CenteredContainer>:null}
                </Container>
            </ReusableAnteNatalTabs>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbar
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(AnteNatalLabRequest);