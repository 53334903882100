import React from "react";
import PageTitle from "../Utils/smsTitle";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCog} from "@fortawesome/free-solid-svg-icons/faCog";
import Form from "react-bootstrap/Form";
import Card from "@material-ui/core/Card"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../actions/snackbarActions";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import Row from "react-bootstrap/Row";
import Label from "../Utils/FormInputs/Label";
import Col from "react-bootstrap/Col";
import {CustomSelect} from "../Utils/FormInputs/SelectInput";
import {useEditUserRole} from "./CustomHooks/useEditUserRole";
import {useAssignRole} from "./CustomHooks/useAssignRole";
import TextField from "../Utils/FormInputs/TextField";
import { BackButton } from "../Utils/Buttons/DataExportationButton";

export function EditAssignedRole(props) {
    const { employees,  handleChangeEmployee} = useAssignRole(props)
    const {role_name, submitted, isSubmitted, roles, handleChangeRoleName, handleSubmit,
        handleCloseSnackbar, employee_email,employee_id} = useEditUserRole(props)
    const {openBar, type, message} = props.snackbars;
    const rols = !roles ? [] : roles;
    return (
        <div data-testid="edit-user-role">
            <PageTitle title="Edit User Role"/>
            <SubHeader title="Settings" subTitle="Edit user role">
                <FontAwesomeIcon icon={faCog}/>
            </SubHeader>
            <div style={{margin: '10px'}}>
                <BackButton to={'/userroles'} text={'User Roles'}/>
            </div>    
            <div className="assign_role_card">
                <div className='mb-2'>
                    <BackButton text='User Roles' to='/userroles'/>
                </div>
                <Card className="p-3 general-card">
                    <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={handleCloseSnackbar}/>
                    <h6 className="visit-heading">Edit Assigned Role</h6>
                    <form onSubmit={handleSubmit} autoComplete="off">
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                                <Label  name="Employee" htmlfor='employee_name' type/>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={7} xl={7}>

                                <CustomSelect value={employee_id} onChange={handleChangeEmployee}
                                              options={employees.map(employee => ({
                                                  label: `${employee.first_name} ${employee.last_name}`,
                                                  value: employee.employee_id
                                              }))} id='employee_name' dataTest="employee_id" isDisabled/>
                                {(submitted && !employee_id) &&
                                <div className="help-block">Employee name is required</div>}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                                <Label name="Role" htmlFor='role' type/>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={7} xl={7}>

                                <CustomSelect value={role_name} onChange={handleChangeRoleName}
                                              id='role'
                                              options={rols.map(role => ({
                                                  value: role.role_id,
                                                  label: role.role_name
                                              }))} dataTest="role_name"/>
                                {(submitted && !role_name) && <div className="help-block">Role name is required</div>}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                                <Label name="Email" type/>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                                <TextField id='_user_email' submitted={submitted}
                                           value={employee_email}
                                           name="email" type="email"  disabled={true}/>
                            </Col>
                        </Form.Group>

                        <div className="text-right mr-5">
                            <button type="submit" disabled={isSubmitted === 'pending' || isSubmitted === 'resolved'} className="btn btn-sm sms-btn px-4">
                                {isSubmitted === 'pending' ? "Saving..." : "Save"}</button>
                        </div>
                    </form>
                </Card>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {snackbars: state.snackbar}

}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(EditAssignedRole);
