import axios from "axios";
import * as types from "../actionTypes";
import { config } from "../../Helpers/env"
import {errorMessages, logoutErrorMessage} from "../../Helpers/ErrorMessages";
import {snackSuccess} from "../snackbarActions";


export const getPrescriptionList = (page, formData) => (dispatch) => {
    dispatch({type: types.PRESCRIPTION_LIST_REQUEST});
    return axios.post(`${config.smsUrl}/cpharmacy/prescription_list/${page}`, formData).then(res => {
        const data = res.data;
        const dt = !data ? {} : data;
        const prescription = !dt.presc_list ? [] : dt.presc_list;
        const total_count = !dt.total_count ? 0 : dt.total_count;
        dispatch({type: types.PRESCRIPTION_TOTAL_COUNT, total_count});
        dispatch({type: types.PRESCRIPTION_LIST_SUCCESS, prescriptions: prescription})
    }).catch(err => {
        dispatch({type: types.PRESCRIPTION_LIST_ERROR});
        logoutErrorMessage(err,dispatch,null)
    })
};

export const deletePrescription = (prescription_id) =>(dispatch) =>{
    return axios.get(`${config.smsUrl}/cpharmacy/delete_prescription`).then(()=>{
        dispatch({type:types.DELETE_PRESCRIPTION,prescription_id});
        dispatch(snackSuccess("Prescription deleted successfully"))
    }).catch(err=>{
        errorMessages(err,dispatch,null)
    })
};
