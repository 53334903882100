import React, {useEffect, useState} from 'react'
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from '../../../actions/snackbarActions'
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import { PLContainer,ProfitContainer, RightAlignedContainer} from '../../Utils/styledComponents';
import {CoaList, CustomCell } from '../../Utils/AccountingUtils/CoaList';
import { usePrint } from '../../Utils/Templates/usePrint';
import { faClipboardList } from '@fortawesome/free-solid-svg-icons/faClipboardList';
import { BackButton, DataExportButton } from '../../Utils/Buttons/DataExportationButton';
import {PrintPdfButtons} from "./PrintPdfButtons";
import hospitalAddress from "../../../HospitalAddress"
import Card  from '@material-ui/core/Card';
import { formatDate } from '../../Utils/ConvertDate';
import { AccountingDateFilter } from '../../Utils/AccountingUtils/AccountingDateFilter';
import {NoTransaction} from "../ChartofAccounts/ReusableJournalTable";
import { LoadingGif } from '../../Utils/Loader';
import { history } from '../../../Helpers/history';
import TableRow from "@material-ui/core/TableRow";
import TableHead from '@material-ui/core/TableHead';
import {useDates} from "../Expenses/hooks/useDates";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {ReportsTreeView} from "./ReportsTreeView";
import {HospitalAddress} from "../../Utils/HospitalAddress";
import { useExcelReports, usePdfDownload } from '../../Utils/ReusableComponents/useExcelExport';

const ProfitLoss = ({actions, snackbar}) => {
    const [data, setData] = useState(null);
    const [date, handleChangeDate] = useDates();
    const [loading, setLoading] = useState('idle')
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const {from_date, to_date} = date;

    const url = 'new_profit_and_loss'
    // accounting/new_profit_and_loss_exports

    const formatData = (data={}) =>{
        let arr = []
        for (let item in data){
            let obj = {}
            if( typeof data[item] === "number"){
                const head_name = item.split('_').join(' ')
                const upper_case_name = head_name.toUpperCase()
                obj = {...obj ,head_name:upper_case_name, balance:data[item],
                    head_level:-1, isParent:true,children:[]}
            }
            if(typeof data[item] === "object"){
                obj = {...obj,
                    head_name: data[item]['category_name'],
                    head_level:-1,
                    children: data[item]['accounts'],
                    balance: data[item]['category_balance'],
                    isParent:true
                }
            }

            arr.push(obj)
        }

        return arr
    }

    const parseData = (items) =>{
        let orderedArr = [{head_name:'INCOME'},{head_name:'COGS'},{head_name:'GROSS PROFIT'},
            {head_name:'EXPENSES'}, {head_name:'PROFIT BEFORE TAX'}]
        let arr = []
        for (let item =0; item < orderedArr.length; item++){
            let obj={}
            for (let row=0; row < items.length; row++){
                if(orderedArr[item].head_name === items[row].head_name){
                    obj = {
                        head_name:orderedArr[item].head_name, head_level:items[row].head_level, children:items[row].children,
                        balance:items[row].balance, isParent:items[row].isParent
                    }
                }
            }
            arr.push(obj)
        }
        return arr
    }

    useEffect(() => {
        setLoading('pending')
        axios.get(`${config.smsUrl}/accounting/${url}`,{params:{start_date:from_date, end_date:to_date}}).then(res => {
            const data = res.data;
            const dt = data ? data : {};
            const dat = dt.data ? dt.data : {}
            const obj = formatData(dat)
            const arr = parseData(obj)
            setData(arr)
            setLoading('success')
        }).catch(err => {
            errorMessages(err, null, actions)
            setLoading('error')
        })

    }, [])

    const handleSubmit = (event) =>{
        setSubmitted(true)
        event.preventDefault()
        setLoading('pending')
        setIsSubmitted('pending')
        axios.get(`${config.smsUrl}/accounting/${url}`, {params:{start_date:from_date, end_date:to_date}}).then(res => {
            const data = res.data;
            const dt = data ? data : {};
            const dat = dt.data ? dt.data : {}
            const obj = formatData(dat)
            const arr = parseData(obj)
            setData(arr)
            setLoading('success')
            setIsSubmitted('resolved')
        }).catch(err => {
            errorMessages(err, null, actions)
            setLoading('error')
            setIsSubmitted('rejected')
        })
    }

    const fileName = `Profit and loss`

    const excelObj = {file_name:fileName,params:{ start_date:from_date, end_date:to_date, download: 2}, url:`/accounting/new_profit_and_loss_exports`}

    const pdfObj = {file_name:fileName,params:{ start_date:from_date, end_date:to_date, download: 1}, url:`/accounting/new_profit_and_loss_exports`}


    const {exportExcel, isLoading:isLoadingExcel} = useExcelReports(actions,excelObj)
    const {exportPdf, isLoading:isLoadingPdf} = usePdfDownload(actions,pdfObj)

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    }
    const isLoading = loading === 'pending';
    const isSuccess = loading === 'success';
    const isError = loading === 'error';
    const isPending = isSubmitted === 'pending';

    const {openBar, type, message} = snackbar;

    const handleOnAfterPrint = () => {
        history.push('/reports/generalreports');
    }
    const {componentRef, handlePrint} = usePrint( `Profit&Loss(${to_date})`, handleOnAfterPrint);

    const {image} = hospitalAddress
    const incomeStatement = data ? data : [] ;

    const header = (
        <TableHead>
            <TableRow>
                <CustomCell><strong></strong></CustomCell>
                <CustomCell align='center'><strong>Amount</strong></CustomCell>
            </TableRow>
        </TableHead>
    )
    const printDoc = (
    <div  ref={componentRef}>
        <ProfitContainer>
            <HospitalAddress/>
            <table className="table table-sm table-borderless prescription-table">
                <tbody>
                    <tr>
                        <td align='center'><h5><strong>PROFIT & LOSS</strong></h5></td>
                    </tr>
                    <tr>
                        <td align='center'><h6><strong>{formatDate(to_date)}</strong></h6></td>
                    </tr>
                </tbody>

            </table>
            <CoaList  {...{ data:incomeStatement, isPrint:true,header}}/>
            <p style={{textAlign: "left", fontStyle: "italic"}}  className="mpeke-footer">Powered by Mara Scientific</p>  
        </ProfitContainer>
    </div>
    )

    return (
        <div className='journals'>
            <PageTitle title="Profit/Loss"/>
            <SubHeader title="Reports" subTitle="Profit/Loss">
                <FontAwesomeIcon icon={faClipboardList}/>
            </SubHeader>
            
            <MainSnackbar message={message} open={openBar} handleCloseBar={closeSnackbar} variant={type}/>
                <PLContainer>
                    <div className="my-3">
                    <BackButton to='/reports/generalreports' text='General Reports' data-testid="profit-loss-statement-back-button"/>
                    </div>
                <AccountingDateFilter {...{from_date,to_date, handleChange:handleChangeDate, 
                submitted,handleSubmit,isPending}}/>
                    {isLoading ? <div className='text-center mt-5'>
                        <LoadingGif/>
                    </div>:null}
                    {isSuccess ? incomeStatement.length > 0 ?  
                    <Card>
                        <RightAlignedContainer>
                        <DataExportButton isLoading={isLoadingExcel || isLoadingPdf} downLoadPdf={exportPdf}
                                            exportCsv={exportExcel} isExcel/>
                        </RightAlignedContainer>
                        <div style={{padding:'32px'}}>
                            <div  id="profit-loss-statement-pdf" style={{overflow: "hidden"}}>
                                <HospitalAddress/>
                                <table className="table table-sm table-borderless prescription-table">
                                    <tbody>
                                        <tr>
                                            <td align='center'><h5><strong>PROFIT & LOSS</strong></h5></td>
                                        </tr>
                                        <tr>
                                            <td align='center'><h6><strong>{formatDate(to_date)}</strong></h6></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <ReportsTreeView  {...{ data:incomeStatement, reports:true,header, start_date:from_date, end_date:to_date}}/>
                                <p style={{textAlign: "center", fontStyle: "italic"}}>Powered by Mara Scientific</p>
                                
                            </div>           
                            
                        </div>
                    </Card>: <NoTransaction text='transactions'/>: null}
                    {isError ? <div>The server did not return a valid response</div>:null}
                    <div style={{display:'none'}}>
                        {printDoc}
                    </div>
                </PLContainer>
            {/* </Container> */}
        </div>
    );
}

function mapStateToProps(state) {
    const {snackbar} = state;
    return {snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfitLoss);
