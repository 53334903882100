import { useState, useEffect } from 'react';
import { errorMessages, logoutErrorMessage } from '../../../../Helpers/ErrorMessages';
import axios from "axios";
import { config } from "../../../../Helpers/env";

const validateOpDateStartTime = (date, time) => {
    if (date && time) {
        var d1 = new Date();
        var d2 = new Date(date + " " + time);
        return (d2 > d1);
    } 
    return false;
}

export const useSurgeryVisit = (actions, patient_number) => {

    // handle for getting the patient details
    const [p_number, setPatientNumber] = useState(patient_number ? patient_number : '');
    const [showNumber, setShowNumber] = useState(false);
    const [patientNumbers, setPatientNumbers] = useState([]);
    const [patientDetails, setPatientDetails] = useState({first_name: '', last_name: '', age:'', gender:'', address:'', phone:''});
    const [submitted, setSubmitted] = useState(false)
    //const initialState = {operation_date:"", start_time:"", duration_hr:0, duration_mins:0}
    const [state , setState] = useState({operation_date:"", estimated_start_time:"", duration_hr:0, duration_mins:0});
    
    //Initial state for users conducting surgery
    const [surgeon, setSurgeon] = useState({ lead_surgeon:"", lead_anaestheist:"", anaesthesiologist : "", scrub_nurse:"", circulating_nurse:""})
    
    //Initial state for list of users in the system
    const [surgeons, setSurgeons] = useState([])

    const [other_anaestheists, setOtherAnaestheists] = useState([])

    const [other_surgeons, setOtherSurgeons] = useState([]);

    //Initial state for dropdown when searching user
    const [isOpen, setIsOpen] = useState({})

    // the submission
    const [isSubmitted, setIsSubmitted] = useState('idle');

    const [inputDoc, setInputDoc] = useState({input_visit_anaestheist:"", input_visit_surgeon:""});

    // check for visiting doctor
    const [isVisitingDoctor, setVisitingDoctor] = useState({});

    const tabs = ['New Surgery', 'Pending Surgeries'] 

    const [tabValue, setTabValue] = useState(0);

    const handleChangeTabValue = (event, val) => {
        setTabValue(val);
    }

    const handleChange = (event, param) => {

        if (param === "other_surgeons") {
            if (event.key === "Enter") {
                
                if (event.target.value !== "") {
                    const new_val = event.target.value;
                    //setInputDoc({...inputDoc, ['input_visit_surgeon']:new_val});
                    const newD = [{label:new_val, value:new_val}];
                    setOtherSurgeons([...other_surgeons, ...newD])
                }

                setVisitingDoctor(prevState => ({...prevState, 'other_surgeons': false}));
                setInputDoc({...inputDoc,'input_visit_surgeon': ""});
            } else {
                setInputDoc({...inputDoc,'input_visit_surgeon': event.target.value});
            }

        } else if (param === "other_anaestheists") {

            if (event.key === "Enter") {
                
                if (event.target.value !== "") {
                    const new_val = event.target.value;
                    //setInputDoc({...inputDoc,['input_visit_anaestheist']: new_val});
                    const newD = [{label:new_val, value:new_val}];
                    setOtherAnaestheists([...other_anaestheists, ...newD]);
                } 

                setVisitingDoctor(prevState => ({...prevState, "other_anaestheists": false}));
                setInputDoc({...inputDoc,'input_visit_anaestheist': ""});
            } else {
                setInputDoc({...inputDoc,'input_visit_anaestheist': event.target.value});
            }
        } else {
            setSurgeon({...surgeon,[param]: event.target.value})
        }
    }

    // auto search function for users
    const handleChangeSurgeon = (event, param) => {
        const {value, name} = event.target;
        setSurgeon({...surgeon,[name]:value})
        const formData = new FormData();
        if (value !== "") {
            formData.append("first_name", value.toUpperCase());
            axios.post(`${config.smsUrl}/cuser/auto_search_users`, formData)
            .then(res => {
                const details = res.data;
                const data = !details ? [] : details;
                if (data[0] === "No User Found") {
                    setSurgeons([{value: "", label: "Other"}])
                    setIsOpen(prevState => ({...prevState, [param]: true}));
                } else {
                    setSurgeons(data);
                    setIsOpen(prevState => ({...prevState, [param]: true}));
                }
            }).catch(error => {
                errorMessages(error, null, actions)
            });
        } else {
            setIsOpen(prevState => ({...prevState, [param]: false}));
        }
        setIsSubmitted("idle");
    }

    //select user
    const handleSelectSurgeon = (value, param) =>{
        if (value === "Other") {
            // convert to the textfield
            setVisitingDoctor(prevState => ({...prevState, [param]: true}));
            setIsOpen(prevState => ({...prevState, [param]: false}));
        } else {
            setSurgeon({...surgeon,[param]:value})
            setIsOpen(prevState => ({...prevState, [param]: false}));
        }
    }

    // get the patient details
    const fetchPatientByPatientNumber = (patientNumber) => {
        const formData = new FormData();
        formData.append("patient_number", patientNumber);
        axios.post(`${config.smsUrl}/cdoctor/get_patient_details`, formData)
        .then(res => {
            const details = res.data;
            const dt = details ? details : {};
            const {first_name, last_name, Age, gender, address, phone_no} = dt;
            setPatientDetails({first_name: first_name ? first_name : '', last_name: last_name ? last_name : '', age:Age ? Age : '',
                                gender:gender ? gender : '', address:address ? address : '', phone : phone_no ? phone_no : ''});
        }).catch(error => {
            logoutErrorMessage(error, null, actions)
        })
    };

    // handle for placing the patient number
    useEffect(() => {
        if (!p_number) return;
        fetchPatientByPatientNumber(p_number);
    }, []);

    // clicking on a patient number
    const handleClickPatientNumber = (event) => {
        setPatientNumber(event.target.value);
        let patient_num = event.target.value;
        fetchPatientByPatientNumber(patient_num);
        setShowNumber(false);
    };

    // handle change in the patient numbers
    const handleChangePatientNumbers = (event) => {
        setPatientNumber((event.target.value).toUpperCase());
        const formData = new FormData();
        if (event.target.value !== "") {
            formData.append("patient_number", event.target.value.toUpperCase());
            axios.post(`${config.smsUrl}/cvisit/search_patient_number`, formData)
            .then(res => {
                const details = res.data;
                const data = !details ? [] : details;
                if (data[0] === "No Patient Found") {
                    setPatientNumbers([{value: '', label: 'No patient found'}])
                } else {
                    const arr = data.map(item => ({
                        value: item.patient_number,
                        label: item.patient_number//`${item.first_name} ${item.last_name} - ${item.phone_no}`
                    }))
                    setPatientNumbers(arr)
                }
            }).catch(error => {
                errorMessages(error, null, actions)
            });
            setShowNumber(true);
        } else {
            setShowNumber(false);
        }
        setIsSubmitted("idle");
    }

    // handler for the change of surgery values in the state
    const onChange = (event) => {
        setState({...state, [event.target.name]: event.target.value});
    }

    // functions for operation classes
    const operationClasses = [
        { label: 'Minor', value: "minor" },
        { label: 'Major', value: "major" }
    ];

    const [operation_class, setOpClass] = useState([]);

    const handleOpClassChange = (value) => {
        setOpClass(value);
    }

    // functions for anaesthesia types
    const anaesthesiaTypes = [
        { label: 'Local', value: "local" },
        { label: 'Spinal', value: "spinal" },
        { label: 'General', value: "general" },
        { label: 'Epidural', value: "epidural" }
    ];

    const [anaesthesia_type, setAnesType] = useState([]);

    const handleAnesTypeChange = (value) => {
        setAnesType(value);
    }

    // functions for surgery types
    const [surgery_name, setSurgeryName] = useState([]);
    const [surgeryTypes, setSurgeryTypes] = useState([]);

    useEffect(() => {
        const formData = new FormData();
            formData.append('service_type', 'Surgery');
            axios.post(`${config.smsUrl}/cvisit/retrieve_services_by_service_type`, formData)
                .then(res => {
                    const details = res.data;
                    const data = !details ? [] : details;
                    const arr = data.map(item => ({
                        value: `${item.service_id}-${item.cost}`,
                        label: item.service_name
                    }))
                    setSurgeryTypes(arr)
                }).catch(error => {
                errorMessages(error, null, actions)
            });
    }, []);


    const handleSurgeryTypeChange = (value) => {
        setSurgeryName(value);
    }

    // handles for the notes
    const [general_notes, setGeneralNotes] = useState({'preoperative_diagnosis': "", 'preoperative_instructions' : "", //'planned_procedures' : "", 
    'post_operative_instructions':"", 'summary_report':""});
    const handleNotesState = (event) => {
        setGeneralNotes({...general_notes, [event.target.name]: event.target.value});
    }

    // handle the submission of scheduling a surgery 
    const [surgeryConfirmation, setConfirmation] = useState(false);

    const clearSurgeryDetails = () => {
        setState({operation_date:"", estimated_start_time:"", duration_hr:0, duration_mins:0});
        setPatientNumber('');
        setSurgeon({ lead_surgeon:"", lead_anaestheist:"", anaesthesiologist : "", scrub_nurse:"", circulating_nurse:""});
        setOtherAnaestheists([]);
        setOtherSurgeons([]);
        setOpClass([]);
        setAnesType([]);
        setSurgeryName([]);
        setGeneralNotes({'preoperative_diagnosis': "", 'preoperative_instructions' : "", //'planned_procedures' : "", 
        'post_operative_instructions':"", 'summary_report':""});
        setPatientDetails({first_name: '', last_name: '', age:'', gender:'', address:'', phone:''});
    }

    const clearSurgeryDoctorDetails = () => {
        setState({operation_date:"", estimated_start_time:"", duration_hr:0, duration_mins:0});
        setSurgeon({ lead_surgeon:"", lead_anaestheist:"", anaesthesiologist : "", scrub_nurse:"", circulating_nurse:""});
        setOtherAnaestheists([]);
        setOtherSurgeons([]);
        setOpClass([]);
        setAnesType([]);
        setSurgeryName([]);
        setGeneralNotes({'preoperative_diagnosis': "", 'preoperative_instructions' : "", //'planned_procedures' : "", 
        'post_operative_instructions':"", 'summary_report':""});
    }

    
    const handleSubmitSurgery = (event) => {
        event.preventDefault();

        const {lead_surgeon, lead_anaestheist, anaesthesiologist, scrub_nurse, circulating_nurse} = surgeon

        const estimated_duration = `${state.duration_hr}:${state.duration_mins}`

        const anaesT = (anaesthesia_type['value']  === undefined) ? "" : anaesthesia_type['value'];

        const surgery_service = surgery_name['value'].split('-');
        const service_id = parseInt(surgery_service[0]);

        const params = {
            patient_number: p_number, visit_type:5, user_roles_id:1, patient_type:1, bill_type:2,

            estimated_start_time: state.estimated_start_time, estimated_duration, operation_date: state.operation_date,

            operation_class : operation_class['value'], service_id, 

            lead_surgeon : lead_surgeon, anaesthesiologist : anaesthesiologist, lead_anaestheist : lead_anaestheist,
            scrub_nurse : scrub_nurse, circulating_nurse: circulating_nurse, anaesthesia_type : anaesT, 

            preoperative_instructions : general_notes.preoperative_instructions,
            
            preoperative_diagnosis : general_notes.preoperative_diagnosis,

            //planned_procedures : general_notes.planned_procedures,

            //post_operative_instructions : general_notes.post_operative_instructions,

            //summary_report : general_notes.summary_report,
            
            other_anaestheists : other_anaestheists.map(a => a.label).join(","),
            
            other_surgeons : other_surgeons.map(a => a.label).join(","),
            bill_details : {
                service_id,
                quantity: 1,
                rate: parseInt(surgery_service[1]),
            }
        }

        // to get a new visit_id
        const {first_name, last_name} = patientDetails;

        if (first_name && last_name) {

            setIsSubmitted('pending');
            // new_surgery
            axios.post(`${config.smsUrl}/surgery/create_surgery_visit`, {...params}).then((res) => {

                // save the new visit
                /*const data = res.data ? res.data : {};
                const dt = data ? data : {};
                const v_id = dt.data ? dt.data : {};
                const id = v_id.visit_id ? v_id.visit_id:'';*/
            
                actions.snackbarActions.snackSuccess('Surgery scheduled');

                setSubmitted(false);
                setConfirmation(false);
                clearSurgeryDetails();
                setIsSubmitted('resolved');
                setTabValue(1);

            }).catch((err) => {
                setSubmitted(false);
                setConfirmation(false);
                setIsSubmitted('rejected');
                errorMessages(err, null, actions);
                clearSurgeryDetails();
            });
        } else {
            setSubmitted(false);
            setConfirmation(false);
            setIsSubmitted('rejected');
        }

    }


    const openConfirmationDialog = (event) => {
        // first validate the form
        event.preventDefault();
        setSubmitted(true);
        if (p_number && validateOpDateStartTime(state.operation_date, state.estimated_start_time) &&
            (state.duration_hr || state.duration_mins) && operation_class['value'] && surgery_name['value']) {
                setConfirmation(true);
        } 
    } 

    const handleCloseConfirm = () => {
        setConfirmation(false);
        setSubmitted(false);
    };

    const handleOtherAnaestheists = (data) => {
        if (data && data.some(e => e.label === 'Other')) {
            setVisitingDoctor({...isVisitingDoctor, 'other_anaestheists': true});
        } else {
            setOtherAnaestheists(data);
            setVisitingDoctor({...isVisitingDoctor, 'other_anaestheists': false});
        }
    }

    const handleOtherSurgeons = (data) => {
        if (data && data.some(e => e.label === 'Other')) {
           setVisitingDoctor({...isVisitingDoctor, 'other_surgeons': true});
        } else {
            setOtherSurgeons(data);
            setVisitingDoctor({...isVisitingDoctor, 'other_surgeons': false});
        }

    }

    const handleChangeDoctors = (data) => {
        const value = data;
        const formData = new FormData();
        if (value !== "") {
            formData.append("first_name", value.toUpperCase());
            axios.post(`${config.smsUrl}/cuser/auto_search_users`, formData)
            .then(res => {
                const details = res.data;
                const data = !details ? [] : details;
                if (data[0] === "No User Found") {
                    setSurgeons([{value: value, label: "Other"}])
                } else {
                    setSurgeons(data);
                }
            }).catch(error => {
                errorMessages(error, null, actions)
            });
        } else {
            setSurgeons([{value: "", label: "Other"}])
        }
        setIsSubmitted("idle");
    }

    return {state, patientDetails, onChange, handleSubmitSurgery,
        general_notes, handleNotesState, clearSurgeryDetails, clearSurgeryDoctorDetails,
        operation_class, operationClasses, handleOpClassChange,
        anaesthesia_type, anaesthesiaTypes, handleAnesTypeChange,
        p_number, patientNumbers, handleChangePatientNumbers, handleClickPatientNumber, submitted, showNumber,
        surgery_name, handleSurgeryTypeChange, surgeryTypes, setSubmitted,
        handleCloseConfirm, openConfirmationDialog, surgeryConfirmation,
        setConfirmation, surgeon,surgeons, isOpen, handleChangeSurgeon, handleSelectSurgeon,
        other_anaestheists, other_surgeons, isVisitingDoctor, inputDoc,
        handleOtherSurgeons, handleOtherAnaestheists, handleChangeDoctors, handleChange,
        tabs, tabValue, handleChangeTabValue
    };
}