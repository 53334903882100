import React, {useEffect, useState} from 'react';
import Form from "react-bootstrap/Form";
import * as snackbarActions from "../../actions/snackbarActions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {history} from "../../Helpers/history";
import * as alertActions from "../../actions/alertActions";
import axios from "axios";
import { config } from "../../Helpers/env";

function ForgotPassword(props){
    const[state,setState]= useState( {
        email: '',
        submitted: false,
        submitting: false
    })

        const {email, submitted, submitting} = state;
        const {alert} = props;
        const {type, message} = alert;

// class ForgotPassword extends Component {
//     constructor() {
//         super();
//         this.state = {
//             email: '',
//             submitted: false,
//             submitting: false
//         };
useEffect(() =>{
    history.listen((location, action) => { 
        const {actions} = this.props;
        actions.alertActions.alertClear();
    });
}
)
       
   const handleChange = event => {
        const {name, value} = event.target;
        setState({[name]: value})
    };

   const handleResetPassword = event => {
        event.preventDefault();
        const {actions} = this.props;
        // const {email} = state;
        const formData = new FormData();
        formData.append('user_name', email);
        setState({submitted: true});
        if (email) {
            setState({submitting: true})
            axios.post(`${config.smsUrl}/admin/forgot_password`, formData).then(() => {
                actions.alertActions.alertSuccess('Link has been sent to your email. Follow it to reset your password')
                setState({submitting: false})
            }).catch(() => {
            setState({submitting: false})
                actions.alertActions.alertFailure('Unsuccessful')
            })
        }
    };

        return (
            <div data-testid="forgot-password" className="styled-reset">
                {message && <div className={` alert ${type}`}>{message}</div>}
                <div className="card styled-reset-card p-4">
                    <div className="py-4">
                        <div className="text-center styled-reset-content mb-3">
                            <h4>Reset Password</h4>
                        </div>
                        <form onSubmit={handleResetPassword} autoComplete="off">
                            <Form.Group>
                                <Form.Label style={{fontSize:15}}>Email <span className="ml-1 help-block">*</span></Form.Label>
                                    <Form.Control type="email" placeholder="Recovery Email" data-testid='recovery-email' value={email} name="email"
                                                  onChange={handleChange}/>
                                    {(submitted && !email) && <div className="help-block">Please enter your email</div>}
                            </Form.Group>
                            <div className="text-center">
                                <button type="submit" data-testid="submit-email" className="btn sms-btn px-5 mt-3" disabled={!!submitting}
                                        style={{width: "100%"}}>{submitting ? "Sending...." : "Send email"}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
        }


function mapStateToProps(state) {
    return {
        alert: state.alerts,
        snackbars: state.snackbar
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch),
            alertActions: bindActionCreators(alertActions, dispatch)
        }
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);