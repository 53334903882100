import {useEffect, useState} from "react";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {logoutErrorMessage} from "../../../Helpers/ErrorMessages";

export const usePatientDetails = (actions, patient_number, visit_id, isANC=false) =>{

    const [patient, setPatient] = useState({});
    const [treatmentDetails, setTreatmentDetails] = useState([]);
    const [option, setOption] = useState([]);
    const [antenatal_data, setAnteNatalData] = useState({});
    const options = [{value:'labRequest', label:'Laboratory Request(s)'},{value:'labResults', label:'Laboratory Results(s)'},
        {value:'radiologyRequest', label:'Radiology Request(s)'},{value:'radiologyResults', label:'Radiology Result(s)'},
        {value:'referral', label:'Referral Note'}
    ]

    const handleChangeOption = (value) =>{
        setOption(value)
    }

    useEffect(() => {
        if (!visit_id){
            return
        }
            const formData = new FormData();
            formData.append('visit_id', visit_id);
            axios.post(`${config.smsUrl}/cdoctor/get_all_patient_treatment_details`, formData).then(res => {
                const data = res.data;
                const details = !data ? {} : data;
                setTreatmentDetails(details)
            }).catch(err => {
                logoutErrorMessage(err, null, actions)
            })

    }, []);

    useEffect(() => {
        const formData = new FormData();
        formData.append('patient_number', patient_number);
        axios.post(`${config.smsUrl}/cdoctor/get_patient_details`, formData).then(res => {
            const data = res.data;
            const details = !data ? {} : data;
            setPatient(details)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, []);

    useEffect(() => {
        if (isANC) {
            const formData = new FormData();
            formData.append('visit_id', visit_id);
            axios.post(`${config.smsUrl}/antenatal/view_antenatal_details`, formData).then(res => {
                const data = res.data;
                const details = !data ? {} : data;
                setAnteNatalData(details);
            }).catch(err => {
                logoutErrorMessage(err, null, actions)
            })
        }
}, []);

    const closeSnackbar = () =>{
        actions.snackbarActions.hideSnackbar()
    };
    return {patient,closeSnackbar, treatmentDetails, option, handleChangeOption, options, antenatal_data}
}