import React, {useEffect, useState} from 'react';
import axios from "axios";
import { config } from "../../../Helpers/env";
import {errorMessages} from "../../../Helpers/ErrorMessages";



export const useAddDepartment = (actions) =>{
    const [department, setDepart] = useState({});
    const [state, setState] = useState({department_name: '', department_type: ''});
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [department_id, setDepartmentId] =useState('')
    const [openEditDialog, setOpenEditDialog] = useState(false)
    const [openDialog, setOpenDialog] = useState(false);


    useEffect(()=>{
        if(!department){
            return
        }
        const {department_name, department_type} = department;
        setState({department_name: department_name, department_type: department_type});
    },[department])


    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const fieldValues = Object.fromEntries(formData.entries())
        if(department_id){
            formData.append('department_id', department_id);
        }
        setSubmitted(true);
        if (fieldValues['department_name'] && fieldValues['department_type']) {
            setIsSubmitted('pending');
            const url = department_id ? 'department_update' : 'add_department'
            axios.post(`${config.smsUrl}/chumanresource/${url}`, formData).then(() => {
                setSubmitted(false);
                actions.snackbarActions.snackSuccess('Department added successfully')
                setOpenEditDialog(false)
                setOpenDialog(false)
                setIsSubmitted('resolved')
                setState({department_name: '', department_type: ''})
            }).catch(err => {
                errorMessages(err, null, actions)
                setIsSubmitted('rejected')
            })
        }
    };

    const handleOpenEditDialog = (_id) =>{
        setDepartmentId(_id)
        setOpenEditDialog(true)
        const formData = new  FormData();
        formData.append('department_id', _id)
        axios.post(`${config.smsUrl}/chumanresource/department_update_form`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            setDepart(dt);
        }).catch(() => {
            // logoutErrorMessage(err, null, actions);
        })
    }

    const handleCloseEditDialog = () => {
        setOpenEditDialog(false)
        setState({department_name: '', department_type: ''})
    }

    const handleOpenDialog = () =>{
        setOpenDialog(true)
    }

    const handleCloseDialog = () =>{
        setOpenDialog(false)
    }

    const isResolved = isSubmitted === 'resolved'
    const isPending = isSubmitted === 'pending'

    const closeSnackbar = () =>{
        actions.snackbarActions.hideSnackbar();
    };
 
    const departmentProps = {department,submitted, isSubmitted, state, handleSubmit, isPending,
        closeSnackbar, openEditDialog, handleCloseEditDialog, handleOpenEditDialog, isResolved,
    openDialog, handleOpenDialog, handleCloseDialog}


    return {departmentProps, isResolved}
}