import React, {useEffect,  useState} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import *  as snackbarActions from "../../../actions/snackbarActions";
import { ReportsTable, BorderedCell,BoldBorderedCell } from "../../Utils/Tables/ReportsTable";
import  TableRow  from "@material-ui/core/TableRow";
import { LoadingGif } from "../../Utils/Loader";
import {MedicalReportFilter} from "../ReportFilter";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {dateConvert} from "../../Utils/ConvertDate";
import axios from "axios";
import {config} from "../../../Helpers/env";
import {logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {RightAlignedContainer} from "../../Utils/styledComponents";
import FilterResults from "react-filter-search";
import TextField from "../../Utils/FormInputs/TextField";

export const convertMales = (item) =>{
    return item['M'] ? item['M']:0;
}

export const convertFemales = (item) =>{
    return item['F'] ? item['F']:0;
}

export const filterData = (arr) =>{
    return arr.map(item=>{
        const a28days = item['a0to28days'] ? item['a0to28days'] : {};
        const a1month = item['a1monthto1Yr'] ? item['a1monthto1Yr'] : {};
        const a2yrs = item['a2to4Yrs'] ? item['a2to4Yrs'] : {};
        const a5yrs = item['a5to12Yrs'] ? item['a5to12Yrs'] : {};
        const a13yrs = item['a13to19Yrs'] ? item['a13to19Yrs'] : {};
        const a20yrs = item['a20to39Yrs'] ? item['a20to39Yrs'] : {};
        const a40yrs = item['a40to59Yrs'] ? item['a40to59Yrs'] : {};
        const a60yrs = item['a60Yrsandabove'] ? item['a60Yrsandabove'] : {};
        const total = convertMales(a28days) + convertFemales(a28days) + convertMales(a1month) + convertFemales(a1month) +
            convertFemales(a2yrs) + convertMales(a2yrs) + convertMales(a5yrs) + convertFemales(a5yrs) +
            convertMales(a13yrs) + convertFemales(a13yrs) + convertMales(a20yrs) + convertFemales(a20yrs) +
            convertMales(a40yrs) + convertFemales(a40yrs) + convertMales(a60yrs) + convertFemales(a60yrs);
        return{
            ...item,
            a28days,
            a1month,
            a2yrs,
            a5yrs,
            a13yrs,
            a20yrs,
            a40yrs,
            a60yrs,
            total
        }
    })

}

const ServicesPerformedByAgeGroupTable = ({actions, snackbars, component}) => {
    const [loading, setLoading] = useState('idle');
    const [reports, setReports] = useState([]);
    const [state, setState] = useState({start_date:dateConvert(), end_date:dateConvert()})
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [patientType, setPatientType] = useState({value:1, label:'Out-patient'})
    const [payment, setPayment] = useState({value:1, label:'Cash'})
    const [providers, setProviders] = useState([])
    const [provider, setProvider] = useState({value:'', label:''})
    const [searchValue, setSearchValue] = useState('')
    const {start_date, end_date} = state;

    useEffect(() => {
        if (payment.label === 'All' || payment.label === 'Cash'){
            return
        }
        axios.get(`${config.smsUrl}/cbilling/insurance_providers/list`).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const arr = !dt.data ? [] : dt.data;
            const list = arr.filter(item=>item.provider_type === payment.label).map(item=>({
                value:item.provider_id,
                label:item.provider_name
            }))
            setProviders(list);
        }).catch(err => {
            logoutErrorMessage(err,null,actions)
        })

    }, [payment.label]);

    useEffect(() => {
        // setState({start_date:dateConvert(), end_date:dateConvert()})
        setPatientType({value:1, label:'Out-patient'})
        setPayment({value:1, label:'Cash'})
    }, [component]);

    const url = {
        'lab':'number_of_lab_tests',
        'scan':'number_of_patient_scans',
        'consultation':'number_of_consultations',
        'dental':'number_of_dental_scans'
    }

    const isCash = payment.label === 'All' || payment.label === 'Cash'


    useEffect(() => {
        setLoading('pending');
        const formData = new FormData();
        formData.append('start_date', start_date);
        formData.append('end_date', end_date);
        formData.append('patient_type', patientType?.value);
        formData.append('mode_of_payment', payment?.value);
        formData.append('provider_id', provider?.value);
        axios.post(`${config.smsUrl}/creport/${url[component]}`, formData).then(res => {
            const data = res.data;
            const dt = !data ? [] : data;
            setReports(dt);
            setLoading('success')
        }).catch((err) => {
            logoutErrorMessage(err, null, actions);
            setLoading('error')
        })

    }, [isCash,patientType, provider, component]);



    const handleChange = (event) =>{
        const {name, value} = event.target;
        setState({...state,[name]:value})
    }

    const handleSubmit = (event) =>{
        event.preventDefault();
        setSubmitted(true);
        if(start_date && end_date){
            setLoading('pending');
            setIsSubmitted('pending')
            const formData = new FormData();
            formData.append('start_date', start_date);
            formData.append('end_date', end_date);
            formData.append('patient_type', patientType?.value);
            formData.append('mode_of_payment', payment?.value);
            formData.append('provider_id', provider?.value);
            axios.post(`${config.smsUrl}/creport/${url[component]}`, formData).then(res => {
                const data = res.data;
                const dt = !data ? {} : data;
                setReports(dt);
                setLoading('success')
                setIsSubmitted('resolved')
            }).catch((err) => {
                logoutErrorMessage(err, null, actions);
                setLoading('error')
                setIsSubmitted('rejected')
            })
        }
    }

    const handleSearch = (event) =>{
        setSearchValue(event.target.value)
    }

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const isLoading = loading === 'pending';
    const isSuccess = loading === 'success';
    const isError = loading === 'error';

    const isPending = isSubmitted === 'pending';

    const arr = filterData(reports)

    const sum = arr.reduce((a,item)=>{
        return a + item.total
    }, 0)

    const exportUrl = {
        'lab':'creport/number_of_lab_tests_exports_by_age_group',
        'scan':'creport/number_of_patient_scans_exports',
        'consultation':'creport/number_of_consultations_exports',
        'dental':'creport/number_of_dental_scans_exports'
    }

    const title = {
        'lab':'Lab Report by Gender',
        'scan':'Radiology Report by Gender',
        'consultation':'Consultation Report by Gender',
        'dental':'Dental Report by Gender'
    }


    const {openBar, type, message} = snackbars;
    return (
        <div>
            <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>
            <MedicalReportFilter {...{payment, setPayment, patientType, setPatientType,handleSubmit,
                providers, provider,setProvider, start_date, end_date, handleChange,isPending, submitted,
                actions, file_name:title[component],
                excelUrl:exportUrl[component], excelParams:{export_type:2},
                pdfUrl:exportUrl[component], pdfParams:{export_type:1} }}/>
            <RightAlignedContainer>
                <TextField id='search_service' value={searchValue} onChange={handleSearch} placeholder="Search..." style={{width:'300px', marginBottom:'5px'}}/>
            </RightAlignedContainer>
            <ReportsTable {...{headTitle:'Service'}}>
                <FilterResults value={searchValue} data={arr}
                               renderResults={results => (
                                   <React.Fragment>
                                       {isLoading ? <LoadingGif/>: null}
                                       {isSuccess ? results.length > 0 ?
                                               <>
                                                   {results.map((item,index)=>{
                                                       return(
                                                           <TableRow key={index} data-testid='tr_data'>
                                                               <BorderedCell>{item.test_name}</BorderedCell>
                                                               <BoldBorderedCell>{item.a28days['M'] ? item.a28days['M']:''}</BoldBorderedCell>
                                                               <BoldBorderedCell>{item.a28days['F'] ? item.a28days['F']:''}</BoldBorderedCell>
                                                               <BoldBorderedCell>{item.a1month['M'] ? item.a1month['M']:''}</BoldBorderedCell>
                                                               <BoldBorderedCell>{item.a1month['F'] ? item.a1month['F']:''}</BoldBorderedCell>
                                                               <BoldBorderedCell>{item.a2yrs['M'] ? item.a2yrs['M']:''}</BoldBorderedCell>
                                                               <BoldBorderedCell>{item.a2yrs['F'] ? item.a2yrs['F']:''}</BoldBorderedCell>
                                                               <BoldBorderedCell>{item.a5yrs['M'] ? item.a5yrs['M']:''}</BoldBorderedCell>
                                                               <BoldBorderedCell>{item.a5yrs['F'] ? item.a5yrs['F']:''}</BoldBorderedCell>
                                                               <BoldBorderedCell>{item.a13yrs['M'] ? item.a13yrs['M']:''}</BoldBorderedCell>
                                                               <BoldBorderedCell>{item.a13yrs['F'] ? item.a13yrs['F']:''}</BoldBorderedCell>
                                                               <BoldBorderedCell>{item.a20yrs['M'] ? item.a20yrs['M']:''}</BoldBorderedCell>
                                                               <BoldBorderedCell>{item.a20yrs['F'] ? item.a20yrs['F']:''}</BoldBorderedCell>
                                                               <BoldBorderedCell>{item.a40yrs['M'] ? item.a40yrs['M']:''}</BoldBorderedCell>
                                                               <BoldBorderedCell>{item.a40yrs['F'] ? item.a40yrs['F']:''}</BoldBorderedCell>
                                                               <BoldBorderedCell>{item.a60yrs['M'] ? item.a60yrs['M']:''}</BoldBorderedCell>
                                                               <BoldBorderedCell>{item.a60yrs['F'] ? item.a60yrs['F']:''}</BoldBorderedCell>
                                                               <BoldBorderedCell>{item.total}</BoldBorderedCell>
                                                           </TableRow>
                                                       )
                                                   })}
                                                   <TableRow>
                                                       <BorderedCell colSpan={17} align="right"><strong>Total</strong></BorderedCell>
                                                       <BorderedCell><strong>{sum}</strong></BorderedCell>
                                                   </TableRow>
                                               </>
                                               :<TableRow><BorderedCell colSpan={18} align="center">No records found</BorderedCell></TableRow>
                                           :null}
                                       {isError ? <TableRow><BorderedCell colSpan={18} align="center">The server did not return a valid response</BorderedCell></TableRow>:null}
                                   </React.Fragment>

                )}/>

                </ReportsTable>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(ServicesPerformedByAgeGroupTable);
