import {useCallback, useEffect, useState} from "react";
import {dateConvert} from "../../../Utils/ConvertDate";
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {errorMessages} from "../../../../Helpers/ErrorMessages";
import {history} from "../../../../Helpers/history";
import hospAddress from "../../../../HospitalAddress.json";

export const useInternalReferral = (props, type, _ids) => {
    const [state, setState] = useState({
        referral_date: dateConvert(), referral_number: "",
        visit_date: "", diagnosis: "", reason: "", notes: "",
        to_hospital: "", patient_history: "", treatment: ""
    });
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const [from_department, setFromDepartment] = useState('');
    const [to_department, setToDepartment] = useState('');
    const [referral_id, setReferralID] = useState('');
    const [openPrint, setOpenPrint] = useState(false);
    const {company_name} = hospAddress;
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;
    const {patient_number, visit_id} = _ids;
    const {actions} = props;

    const retrieveDepartments = useCallback(() => {
        actions.departmentActions.loadDepartments();
    }, []);

    useEffect(() => {
        retrieveDepartments()
    }, []);

    const handleChangeFromDepartment = (value) => {
        setFromDepartment(value)
    };

    const handleChangeToDepartment = (value) => {
        setToDepartment(value)
    };

    const handleChange = (event) => {
        const {name, value} = event.target;
        setState({...state, [name]: value})
    };
    const handleSubmit = event => {
        event.preventDefault();
        const {
            referral_date, referral_number, visit_date, diagnosis, reason, notes, to_hospital,
            patient_history, treatment
        } = state;
        setSubmitted(true);
        const formData = new FormData();
        formData.append('visit_id', visit_id);
        formData.append('patient_number', patient_number);
        formData.append('referred_from', type === 'internal' ? from_department.label : company_name);
        formData.append('referred_to', type === 'internal' ? to_department.label : to_hospital);
        formData.append('referral_type', type);
        formData.append('visit_date', visit_date);
        formData.append('diagnosis', diagnosis);
        formData.append('reason', type === 'internal' ? reason : patient_history);
        formData.append('treatment_given', type === 'internal' ? notes : treatment);
        formData.append('user_roles_id', user_roles_id);
        if (referral_date) {
            setIsSubmitted(true);
            axios.post(`${config.smsUrl}/cmedicationround/add_referral`, formData).then(res => {
                const data = res.data;
                const dt = !data ? {} : data;
                const _id = !dt.patient_referal_id ? '' : dt.patient_referal_id;
                setIsSubmitted(false);
                actions.snackbarActions.snackSuccess('Patient referred successfully');
                setReferralID(_id);
                setOpenPrint(true)
            }).catch(err => {
                setIsSubmitted(false);
                errorMessages(err, null, actions)
            })
        }
    };

    const handleCloseReferral = () => {
        setOpenPrint(false)
    };

    const handleClick = () => {
        const url = type === 'internal' ? `/internalreferralnote/${referral_id}` : `/outgoingreferralprint/${referral_id}`
        history.push(url)
    };

    const handleReset = () => {
        setState({
            referral_date: '', referral_number: "",
            visit_date: "", diagnosis: "", reason: "", notes: "",
            to_hospital: "", patient_history: "", treatment: ""
        });
        setSubmitted(false)
    };
    const handleCloseSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    return {retrieveDepartments, state, submitted, isSubmitted, from_department, to_department, referral_id,
        openPrint, handleChangeFromDepartment, handleChangeToDepartment, handleChange, company_name,
        handleSubmit, handleCloseReferral, handleClick, handleReset, handleCloseSnackbar
    }
}