import {useEffect, useRef, useState} from "react";
import axios from "axios";
import {config} from "../../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import {titleCase} from "../../../Users/addUser";
import {exportPDF} from "../../../Utils/downloadPDF";
import {useFileUploader} from "../../../PatientRecords/CustomHooks/useFileUploader";
import { useHiddenColumns } from "../../../Utils/Tables/CustomTable";

export const useProductList = (actions, headData, headDataEquip) =>{
    const [searchValue, setSearchValue] = useState('');
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('product_name');
    const [item, setItem] = useState({product_id:'', status:''}); 
    const [isDelete, setIsDelete] = useState('idle')
    const [loading, setLoading] = useState(false);
    const [productList, setList] = useState([]);
    const [openDelete, setDelete] = useState(false);
    const [activePage,setActivePage] = useState(1);
    const [total_count, setCount] = useState(0)
    const [allRecords, setAllRecords] = useState([]);
    const [activeStatus, setActiveStatus] = useState(1);
    const obj = {url:'cpharmacy/uploadCsv_product', download_url:'cpharmacy/product_csv', file_name:'Products'}
    const {uploadProps, isResolved:isResolvedUpload} = useFileUploader(actions, obj)
    const csvRef = useRef();

    const {
        headCells, all_hidden, handleAllHeadCells, handleHeadCells, handleChangeHeadCellsArray
    } = useHiddenColumns(headData);


    const isDeleted = isDelete === 'deleted'
    useEffect(() => {
        setLoading(true);
        const formData = new FormData();
        formData.append('search', searchValue)
        formData.append('category_id', activeStatus)
        const url = activeStatus !== 0 ? `cpharmacy/medicine_list/${activePage}` :
            activeStatus === 0 ? `cpharmacy/inactive_medicine_list/${activePage}` : null;

        const new_arr = activeStatus === 1 ? headData : headDataEquip;
        handleChangeHeadCellsArray(new_arr); 

        axios.post(`${config.smsUrl}/${url}`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const prdts = !dt.medicine_list ? [] : dt.medicine_list ;
            const count = !dt.total_count ? 0 : dt.total_count;
            
            setList(prdts);
            setCount(count);
            setLoading(false);
        }).catch((err) => {
            logoutErrorMessage(err, null, actions);
            setLoading(false);
        })
    },[activePage,searchValue,isDeleted, activeStatus, isResolvedUpload] );

    const retrieveAllRecords = () =>{
        return axios.get(`${config.smsUrl}/cpharmacy/medicines`)
    }

    const handleChangeStatus = (status) =>{
        setActiveStatus(status);
        setActivePage(1);
        let new_arr = status === 1 ? headData : headDataEquip;
        handleChangeHeadCellsArray(new_arr); 
    }

    const handleOpenDelete = (obj) => {
        const {product_id, status} = obj;
        setDelete(true);
        setItem({product_id, status})
    };

    const handleCloseDelete = () => {
        setDelete(false)
    };

    const handleDelete = () => {
        const {product_id, status} = item;
        const formData = new FormData();
        const isActive = status === 1 ? 0 : status === 0 ? 1 : '';
        formData.append('product_id', product_id);
        formData.append('status', isActive);
        setIsDelete('pending')
        axios.post(`${config.smsUrl}/cpharmacy/medicine_delete`, formData).then(() => {
            setIsDelete('deleted')
            const message = status === 1 ? 'deactivated' : status === 0 ? 'activated':''
            actions.snackbarActions.snackSuccess(`Product ${message} successfully`);
            setDelete(false)
        }).catch(err => {
            errorMessages(err, null, actions)
            setIsDelete('error')
        })
    };

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const handleChangeActivePage = (pg) =>{
        setActivePage(pg)
    }

    const handleSearch = (event) => {
        const {value} = event.target;
        setSearchValue(value ? titleCase(value) : value)
    };


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const downloadPdf = () =>{
        const formData = new FormData();
        formData.append('search', searchValue)
        retrieveAllRecords().then(res => {
            const data = res.data;
            const items = !data ? [] : data;
            exportPDF("Product List", [["SL", "Product", "Sell Price"]],
                items.map((item, index) => [index += 1, item.product_name,
                    item.price]), "Product List.pdf");
            setAllRecords(items);
        }).catch((err) => {
            logoutErrorMessage(err, null, actions);
        })
    
    }

    const downloadCsv = () =>{
        retrieveAllRecords().then(res => {
            const data = res.data;
            const items = !data ? [] : data;
            setAllRecords(items);
            csvRef.current.link.click()
        }).catch((err) => {
            logoutErrorMessage(err, null, actions);
        })
    }

    const isPending = isDelete === 'pending';

    return {searchValue, order, orderBy,  loading, productList, openDelete, activePage, total_count, headCells, all_hidden, handleAllHeadCells, handleHeadCells,
        handleOpenDelete, handleCloseDelete, handleDelete, closeSnackbar, handleChangeActivePage,uploadProps,
        handleSearch, handleRequestSort, csvRef,allRecords, downloadCsv, downloadPdf, item, isPending, setActiveStatus,handleChangeStatus, activeStatus};
}