import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../../actions/snackbarActions"
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {formatDate} from "../../Utils/ConvertDate";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { DateTimeFilter } from "../../Utils/ReusableComponents/DateTimeFilter";
import Label from "../../Utils/FormInputs/Label";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SelectInput from "../../Utils/FormInputs/SelectInput";
import { useProductLog } from "./CustomHooks/useProductLog";
import { faClipboardList } from "@fortawesome/free-solid-svg-icons/faClipboardList";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {StickyHeadTable, CustomTableCell, MuiPaper} from '../../Utils/Tables/StickyHeaderTable';
import { LoadingGif } from '../../Utils/Loader';
import {BackButton, DataExportButton} from '../../Utils/Buttons/DataExportationButton';
import {RightAlignedContainer} from "../../Utils/styledComponents";
import {useExcelReports, usePdfDownload} from "../../Utils/ReusableComponents/useExcelExport";


const headData = [{id: 'date',  label: 'Date'},
{id: 'item', label: 'Product Name'},
{id: 'quantity', label: 'Quantity'},
{id: 'action', label: 'Action'},
]; 
const ProductLog = ({actions,snackbars,  match:{params}}) => {
    const {product_id,component} = params;
    const {openBar, type, message} = snackbars;
    const {
        data, closeSnackbar, 
        isLoading, isSuccess, isError, state,submitted,
        handleChange, handleSubmit, isPending,  transactions
    } = useProductLog(actions,product_id)
    const {productTransactions} = data
    const {start_date, end_date, transaction_type} = state

    const route = {
        'pharmacy':'/pharmacystock',
        'products':'/productlist/1',
        'reports':'/accounting/productsales',
        'stores': '/stocklist',
        'lab':'/lab-stock-list'
    }

    const text = {
        'pharmacy':'Stock',
        'products':'Product List',
        'reports':'General Reports',
        'stores': 'Stock',
        'lab':'Stock'
    }

    const emptyRows = 10 - Math.min(10, productTransactions.length);

    const theme = useTheme();
    const isExtraLarge = useMediaQuery(theme.breakpoints.up('xl'));
    const isMedium = useMediaQuery(theme.breakpoints.up('md'));
    const isLarge = useMediaQuery(theme.breakpoints.up('lg'));

    const colSpan = isExtraLarge ? 12 : isMedium ? 6 : isLarge ? 10 : headData.length

    const excelObj = {file_name:'Product details',params:{start_date, end_date,product_id, actions:transaction_type, download:2}, url:'cinventory/product_log_list_exports'}

    const pdfObj = {file_name:'Product details',params:{start_date, end_date,product_id, actions:transaction_type, download:1}, url:'cinventory/product_log_list_exports'}


    const {exportExcel, isLoading:isLoadingExcel} = useExcelReports(actions,excelObj)
    const {exportPdf, isLoading:isLoadingPdf} = usePdfDownload(actions,pdfObj)
    return (
        <div className='journals'>
            <PageTitle title='Product Transactions'/>
            <SubHeader title="Reports" subTitle='Product Transactions'>
                <FontAwesomeIcon icon={faClipboardList}/>
            </SubHeader>
            <MainSnackbar variant={type} handleCloseBar={closeSnackbar} open={openBar} message={message}/>
            <div className=" mx-2">
                <BackButton to={route[component]} text={text[component]} data-testid="product-log-back-button"/>
                <div className='mui-tables mt-2'>
                    <div className="mt-4">
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={4} >
                                <Row>
                                    <Col xs={12} sm={2} md={2} lg={3} xl={2}>
                                        <Label name="Sort by"/>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6} xl={8}>
                                        <SelectInput value={transaction_type} onChange={handleChange} name="transaction_type"
                                                     options={transactions} defaultOption='ALL'/>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={8} >
                                <DateTimeFilter {...{start_date,end_date, handleChange, submitted,handleSubmit,isPending,type:'date'}}/>
                            </Col>
                        </Row>
                        {/*<DateTimeFilter {...{start_date,end_date, handleChange, submitted,handleSubmit,isPending,type:'date'}}>*/}
                        {/*    <div className="col-lg-4">*/}
                        {/*        <Form.Group as={Row}>*/}
                        {/*            <Label name="Sort by" column sm="4"/>*/}
                        {/*            <Col sm="7">*/}
                        {/*                <SelectInput value={transaction_type} onChange={handleChange} name="transaction_type" */}
                        {/*                options={transactions} defaultOption='ALL'/>*/}
                        {/*            </Col>*/}
                        {/*        </Form.Group>*/}
                        {/*    </div>*/}
                        {/*</DateTimeFilter>*/}
                    </div>
                    <RightAlignedContainer>
                        <div className="mb-1">
                            <DataExportButton isLoading={isLoadingExcel || isLoadingPdf} downLoadPdf={exportPdf} exportCsv={exportExcel}/>
                        </div>
                    </RightAlignedContainer>
                    <MuiPaper>
                    <StickyHeadTable {...{data:productTransactions, columns:headData.filter(item=>Boolean(item))}}>
                        {isLoading ? <TableRow><TableCell align='center' {...{colSpan}}><LoadingGif/></TableCell></TableRow>:null}
                        {isSuccess ? productTransactions.length > 0 ? 
                            <>
                                {productTransactions.map((row, index) => {
                                    const rate = row.rate ? row.rate : 0;
                                    const quantity = row.quantity ? row.quantity : 0;
                                    const total_amount =  quantity * rate;
                                return (
                                <TableRow  tabIndex={-1} key={index}>
                                    <CustomTableCell>{formatDate(row.created_at)}</CustomTableCell>
                                    <CustomTableCell>{row.product_name}</CustomTableCell>
                                    <CustomTableCell>{quantity}</CustomTableCell>
                                    <CustomTableCell>{row.actions}</CustomTableCell>
                                    {/* <CustomTableCell>{formatAmount(rate)}</CustomTableCell>
                                    <CustomTableCell>{formatAmount(total_amount)}</CustomTableCell> */}
                                </TableRow>
                                );
                        })}
                            {emptyRows > 0 && (
                            <TableRow style={{ height: 43 * emptyRows }}>
                            <TableCell colSpan={headData.length} />
                            </TableRow>
                        )}
                        </>:<TableRow><TableCell align='center'  {...{colSpan}}>No records found</TableCell></TableRow>:null}
                        {isError ? <TableRow><TableCell align='center'  {...{colSpan}}>The server did not return a valid response</TableCell></TableRow>:null}
                    </StickyHeadTable>
                    </MuiPaper>

                </div>
            </div>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductLog);
