import {useEffect, useState} from "react";
import axios from "axios";
import {config} from "../../../../Helpers/env";

export function usePatientInformation(patient_number, isResolved=false) {
    const [patient, setPatient] = useState({})
    const [openDialog, setOpenDialog] = useState(false)
    const [open,setOpen] = useState(false)

    useEffect (() => {
        const formData = new FormData();
        formData.append('patient_number', patient_number)
        axios.post(`${config.smsUrl}/cpatient/patient_update_form`, formData).then(response => {
            const patient = response.data;
            const patientDetails = !patient ? {} : patient;
            setPatient({
                ...patientDetails,
                patient_name: `${!patientDetails.first_name ? "" : patientDetails.first_name} ${!patientDetails.last_name ? "" : patientDetails.last_name}`,
                age: patientDetails.Age,
                gender: patientDetails.gender,
                address: patientDetails.address,
                phone: patientDetails.phone_no
            })

        }).catch(err => {
            // logoutErrorMessage(err, null, actions)
        } )

    },[patient_number, isResolved])

    const handleOpenDialog = () => {
        setOpenDialog(!openDialog)
    };

    const handleOpen = () =>{
        setOpen(!open)
    }

    return {patient, handleOpen, handleOpenDialog, open, openDialog}

}