import React, {useEffect,  useState} from "react";
import {CustomTablePagination, EnhancedSearchTableToolbar} from "../../Utils/Tables/CustomTable";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import *  as snackbarActions from "../../../actions/snackbarActions";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {Container, RightAlignedContainer} from "../../Utils/styledComponents";
import axios from "axios";
import {config} from "../../../Helpers/env";
import {logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {dateConvert} from "../../Utils/ConvertDate";
import {LoadingGif} from "../../Utils/Loader";
import {CustomTableCell, MuiPaper, StickyHeadTable} from "../../Utils/Tables/StickyHeaderTable";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {BackButton, DataExportButton} from "../../Utils/Buttons/DataExportationButton";
import {ReusableRouteTabs} from "../../Utils/Dialogs/ReusableTabs";
import {history} from "../../../Helpers/history";
import {Link} from "react-router-dom";
import {DateFilter} from "../../Billing/utils/DateFilter";
import {useExcelReports, usePdfDownload} from "../../Utils/ReusableComponents/useExcelExport";
import {useCurrency} from "../../../Context/currency-context";




const RevenuePerProcedure = ({actions, snackbars}) => {
    const {currency:selectedCurrency} = useCurrency({actions, snackbars})
    const [date, setDate] = useState({start_date: dateConvert(), end_date: dateConvert()});
    const [searchValue, setSearchValue] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isSubmitted, setIsSubmitted] = useState('idle');;
    const [data, setData] = useState({revenueList:[], loading:'idle', total:0});
    const [activePage, setActivePage] = useState(1)


    const {start_date, end_date} = date;

    const {loading, total, revenueList} = data


    useEffect(() => {
        setData({...data, loading: 'pending'})
        axios.post(`${config.smsUrl}/creport/procedural_revenue_time_periods`, null, {
            params: {
                start_date: start_date,
                end_date: end_date,
                option: 3,
                department_id: '',
                data: true
            }
        }).then(res => {
            const data = res.data;
            const dt = !data ? [] : data.data;
            const arr = dt.map(item=>({
                ...item,
                procedure_name:(
                    <Link
                        to={{pathname: `/revenueperservice/${item.service_id ? item.service_id:null}/${item.procedure_name}/${start_date}/${end_date}`}}>{item.procedure_name}</Link>
                )
            }))
            setData({...data, loading: 'success', revenueList: arr, total: arr.length})
        }).catch((err) => {
            setData({...data, loading: 'error'})
            logoutErrorMessage(err, null, actions);
        })
    }, [start_date, end_date]);


    const handleChangeDate = (event) => {
        const {value, name} = event.target;
        setDate({...date, [name]: value})
    }


    const handleSearch = (value) => {
        setSearchValue(value)
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setActivePage(newPage+1)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        setActivePage(1);
    };

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };



    const {openBar, type, message} = snackbars;

    const isLoading = loading === 'pending'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'

    const isPending = isSubmitted === 'pending'

    const pagination = (
        <CustomTablePagination
            data={revenueList}
            colSpan={3}
            activePage={activePage}
            page={page}
            rowsPerPage={rowsPerPage}
            // count={total}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            // isTotalCount
        />
    );

    const search_bar = (
        <EnhancedSearchTableToolbar
            term={searchValue} handler={handleSearch}
        />
    )
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, revenueList.length - page * rowsPerPage);


    const headData = [
        {id: 'count', numeric: false, disablePadding: false, label: 'Sl'},
        {id: 'procedure_name', numeric: false, disablePadding: false, label: 'Service'},
        {id: 'total_amount', numeric: false, disablePadding: false, label: `Amount`},
        // {id: 'rate_ssp', numeric: false, disablePadding: false, label: 'Amount(SSP)'},
    ];

    const components = [
        {label: 'Sales by Service', path: '/reports/revenuebyprocedure'},
        {label: 'Sales by Product', path: '/accounting/productsales'},
    ]

    const excelObj = {file_name:'Revenue per service',params:{start_date, end_date, download:2, option:3, department_id:''}, url:'creport/procedural_revenue_time_periods_exports'}

    const pdfObj = {file_name:'Revenue per service',params:{start_date, end_date, download:1,option:3, department_id:''}, url:'creport/procedural_revenue_time_periods_exports'}


    const {exportExcel, isLoading:isLoadingExcel} = useExcelReports(actions,excelObj)
    const {exportPdf, isLoading:isLoadingPdf} = usePdfDownload(actions,pdfObj)

    return (
        <div data-testid={`revenue-per-service-report`}>
            <PageTitle title={'Revenue per service'}/>
            <SubHeader title="Reports" subTitle={'Revenue per service'}>
                <FontAwesomeIcon icon={'Revenue per service'}/>
            </SubHeader>
            <Container>
                <BackButton to='/reports/generalreports' text='General Reports'/>
                <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/>

                <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>
                <DateFilter {...{start_date, end_date, isSubmit:true, handleChangeDate}}/>
                <RightAlignedContainer>
                    <div className="mb-1">
                        <DataExportButton isLoading={isLoadingExcel || isLoadingPdf} downLoadPdf={exportPdf} exportCsv={exportExcel}/>
                    </div>
                </RightAlignedContainer>
                <MuiPaper>
                    <StickyHeadTable {...{width:'100%',data:revenueList, columns:headData.filter(item=>Boolean(item)), pagination}}>
                        {isLoading ? <TableRow><TableCell align='center' {...{colSpan:headData.length}}><LoadingGif/></TableCell></TableRow>:null}
                        {isSuccess ? revenueList.length > 0 ?
                            <>
                                {revenueList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                    const count = (page * rowsPerPage) + index + 1;
                                    return (
                                        <TableRow  tabIndex={-1} key={index}>
                                            {headData.map(col=>(
                                                col.id === 'count' ? <CustomTableCell>{count}</CustomTableCell>:
                                                    <CustomTableCell>{row[col.id]}</CustomTableCell>
                                            ))}
                                        </TableRow>
                                    );
                                })}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 20 * emptyRows }}>
                                        <TableCell colSpan={headData.length} />
                                    </TableRow>
                                )}
                            </>:<TableRow><TableCell align='center'  {...{colSpan:headData.length}}>No records found</TableCell></TableRow>:null}
                        {isError ? <TableRow><TableCell align='center'  {...{colSpan:headData.length}}>The server did not return a valid response</TableCell></TableRow>:null}
                    </StickyHeadTable>
                </MuiPaper>
            </Container>

        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(RevenuePerProcedure);