import React, {useEffect, useState} from "react";
import {useTheme} from "@material-ui/styles";
import TextField from "../Utils/FormInputs/TextField";
import { ErrorMessage} from "../Utils/styledComponents";
import ReusablePrescriptionDetails from "./ReusablePrescription";
import axios from "axios";
import {config} from "../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../Helpers/ErrorMessages";
import PrintDialog from "../Utils/Dialogs/PrintDialog";
import CustomDialog from "../Utils/Dialogs/CustomDialog";


const returnHeadData = [{name:'Drug Name'},{name:'Quantity Dispensed'},
{name:'Quantity Used'},{name:'Balance'}, 
{name:'Choosen Batch ID(s)', sub_headings: ["Batch ID", "Dispensed Quantity", "Quantity to Return"]},
{name:'Quantity Returned'}]
const ReturnPrescription = ({actions, openDialog, setOpenDialog, prescriptionIds,
                                isSubmitted, setIsSubmitted}) => {

    const [medicineReturn, setMedicineReturn] = useState([{
        product_name: '',
        product_id:'',
        quantity_given:0,
        quantity_returned:0,
        quantity_dispensed:0,
        prescription_detial_id:'',
        bill_id:'',
        batches:[{
            batch_id:'', quantity:0, quantity_returned:0
        }]
    }]);
    const [state, setState] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [product, setProduct] = useState('');
    const {prescription_id, patient_type,visit_id} = prescriptionIds;
    const [confirmDialog, setConfirmDialog] = useState(false);



    /**
     * get the actual numbers of medicine returned, used, etc
     */
    useEffect(() => {
        if(!(prescription_id && patient_type === 2)){
            return;
        }
        const formData = new FormData();
        formData.append('visit_id',visit_id);
        formData.append('prescription_id',prescription_id);
        axios.post(`${config.smsUrl}/cpharmacy/get_previous_presc_and_treatment`, formData).then(res => {
            const data = res.data;
            const dt = !data ? [] : data;
            const item = dt[0] ? dt[0] :{};
            const product_id = item.product_name ? item.product_name : '';
            setProduct(product_id)
            if(!product){
                setMedicineReturn([]);
            }
            setMedicineReturn(dt);
        }).catch(err => {
            logoutErrorMessage(err,null,actions)
        })
    }, [prescription_id]);


    /**
     * get the patient details (patient_info)
     */
    useEffect(() => {
        if(!prescription_id){
            return;
        }
        const formData = new FormData();
        formData.append('prescription_id',prescription_id);
        formData.append('visit_id',visit_id)
        axios.post(`${config.smsUrl}/cpharmacy/get_patient_presc`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const prescription = !dt.patient_info ? {} : dt.patient_info;
            setState({
                patient_id: prescription.patient_number,
                patient_name: `${!prescription.patient_firstname ? "" :
                    prescription.patient_firstname} ${!prescription.patient_lastname ? "" :
                    prescription.patient_lastname}`, gender: prescription.gender,
                age: prescription.age, weight: prescription.weight, blood_pressure: prescription.blood_pressure,
                reference: prescription.reference, visit_id: prescription.visit_id, date: prescription.begin_datetime,
                policy_number: prescription.policy_number, address: prescription.address, phone:prescription.phone_no
            })
        }).catch(err => {
            logoutErrorMessage(err,null,actions)
        })
    }, [prescription_id]);


    /**
     * Submit returned medicine to the api
     */
    const submissionReturnCall = () => {
        const concatenated = medicineReturn.reduce((a,b)=>a.concat(b.batches ? b.batches.map((item)=>({...b, ...item})): b), [])
        const concatenatedArr = concatenated.map(({batches, ...item})=>
            ({...item, quantity_returned: item.quantity_returned ? item.quantity_returned : 0}))

        const params = {
            visit_id,
            products : concatenatedArr
        }

        setIsSubmitted('pending')
        axios.post(`${config.smsUrl}/cpharmacy/medicine_return`, params).then(() => {
            setIsSubmitted('resolved');
            actions.snackbarActions.snackSuccess('Product(s) received successfully');
            setSubmitted(false)
            setOpenDialog(false)
            setConfirmDialog(false);
        }).catch(err => {
            errorMessages(err, null, actions);
            setIsSubmitted('rejected');
        })

    };

    /**
     * open confirmation dialog before submitting returned medicine to the api
     * @param event
     */
    const handleSubmitReturn = (event) => {
        event.preventDefault();
        const concatenated = medicineReturn.reduce((a,b)=>a.concat(b?.batches ? b.batches.map((item)=>({...b, ...item})) : b), [])
        const arr = concatenated.every(item=>{
            const balance = parseInt(item.quantity_given ? item.quantity_given : 0) - parseInt(item.quantity_administered ? item.quantity_administered : 0);
            return (item.quantity_returned <= balance && item.quantity_returned >= 0)
        });
        setSubmitted(true);
        if (arr) {
            console.log('hello')
            setConfirmDialog(true)
        }

    }

    /**
     * OnChange for the quantity returned
     * @param event
     * @param btch - batch object {batch_id, quantity, ...rest}
     * @param btch_idx - Index of the current batch
     * @param index - Index of the product
     */
    const handleChangeBatchReturnQuantity = (event, btch, btch_idx, index) => {

        const {value} = event.target;
        let allRows = [...medicineReturn];

        allRows[index]['batches'][btch_idx]['quantity_returned'] = parseInt(value);
        let new_btches = allRows[index]['batches'];

        // calc the total_chosen_quantity
        let new_quantity_returned = 0;
        for (var x = 0; x < new_btches.length; x++) {
            new_quantity_returned += parseInt(new_btches[x].quantity_returned? new_btches[x].quantity_returned : 0);
        }

        allRows[index]['quantity_returned'] = parseInt(new_quantity_returned);
        setMedicineReturn(allRows);
    }

    const isPendingReturn = isSubmitted === 'pending';


    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;

    return (
        <>
            <PrintDialog openDialog={confirmDialog} handleClose={()=>setConfirmDialog(false)} handleCancel={()=>setConfirmDialog(false)}
                         text="Would you like to save the return?" handleClick={submissionReturnCall} message="Confirm return"/>

            <CustomDialog open={openDialog} handleClose={() => setOpenDialog(false)} title={'Return Remaining Stock'}
                          maxWidth="xl" isContentOverflow={false}>
                <form onSubmit={handleSubmitReturn} id="submit-prescription" className="mt-2" autoComplete="off">
                    <ReusablePrescriptionDetails {...{
                        headData: returnHeadData,
                        text: 'Receive Balances',
                        state,
                        isFillPrescription: true
                    }}>
                        <tbody>
                        {medicineReturn.length > 0 ? medicineReturn.map((row, index) => {
                                return (
                                    <tr key={row.product_id}>
                                        <td>{row.product_name}</td>
                                        <td>{row.quantity_given}</td>
                                        <td>{row.quantity_administered}</td>
                                        <td>{row.quantity_given - row.quantity_administered}</td>
                                        <td>
                                            <table>
                                                <tbody>
                                                {row.batches?.map((btch, btch_idx) => (
                                                    <tr key={btch_idx}>
                                                        <td style={{width: '30%'}}>
                                                            {btch.batch_id}
                                                        </td>
                                                        <td style={{width: '30%'}}>
                                                            {btch.quantity}
                                                        </td>
                                                        <td style={{width: '40%'}}>
                                                            <TextField submitted={submitted} type="number"
                                                                       value={btch.quantity_returned}
                                                                       style={{fontSize: '11px'}}
                                                                       onChange={(e) => handleChangeBatchReturnQuantity(e, btch, btch_idx, index)}/>
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        </td>
                                        <td>
                                            {row.quantity_returned}
                                            {submitted && (row.quantity_returned < 0) ?
                                                <ErrorMessage>This is a required field</ErrorMessage> : null}
                                            {submitted && (
                                                row.quantity_returned > (row.quantity_given - row.quantity_administered)
                                            ) ?
                                                <ErrorMessage>Quantity Returned is greater than balance</ErrorMessage> :
                                                null}
                                        </td>

                                    </tr>
                                )
                            }) :
                            <tr>
                                <td colSpan={returnHeadData.length} align="center"
                                    className={palette.type === 'dark' && 'dark-theme-color'}>No drugs
                                    given
                                </td>
                            </tr>}
                        </tbody>
                    </ReusablePrescriptionDetails>
                    <button type="submit" className="btn btn-sm sms-btn px-4" id="submit-btn"
                            disabled={isPendingReturn}>{isPendingReturn ? 'Saving...' : 'Save'}</button>

                </form>
            </CustomDialog>
        </>


    )
};

export {ReturnPrescription};
