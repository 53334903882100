import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {ErrorMessage} from "../../Utils/styledComponents";
import {AutoCompleteTextField, PopperMy, useStyles} from "../../Utils/FormInputs/AutoCompleteInput";


export const ServiceAutoComplete = ({value,  inputValue, options,handleSearch, getData, submitted,
                                             renderOption=(option)=>option.label, id="", errorMsg="", testId="",
                                         ...rest}) => {

    const classes = useStyles();

    return (
        <div>
            <Autocomplete
                fullWidth
                id={id}
                value={value}
                inputValue={inputValue}
                onChange={getData}
                getOptionSelected={(option, value) => {
                    if (!value) { return true; }
                    return option.value === value
                }}
                getOptionLabel={(option) => {
                    if (typeof(option) === 'string') {
                        // not a dictionary
                        return option;
                    }
                    return option.label
                }}
                onInputChange={handleSearch}
                options={options}
                renderOption={renderOption}
                classes={{
                    option: classes.option,
                    paper: classes.paper,
                    // popper: classes.popper
                }}
                PopperComponent={PopperMy}
                renderInput={(params) =>
                    <AutoCompleteTextField  {...params} variant="outlined" name={name} data-testid={testId}
                                            color={(value === null && !submitted) ? 'default' :
                                                (submitted && !value) ? 'error' : "default"}
                                            {...rest}/>}

            />
            {(submitted && !value)  ?
                    <ErrorMessage>{errorMsg}</ErrorMessage>
                    : null}
        </div>
    );
}