import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Label from "../Utils/FormInputs/Label";
import {CustomSelect} from "../Utils/FormInputs/SelectInput";
import { ErrorMessage } from "../Utils/styledComponents";
import {CustomInput, CustomResizableTextarea} from "../Inventory/CustomInput";


export function AddWardForm({state, submitted, isPending,isResolved,handleSubmit,
                                warden,wardens,handleChangeInCharge, setIsBlocking}) {

    const {ward,number_of_rooms,bed_capacity,description} = state;
    return (
        <form autoComplete='off' onSubmit={handleSubmit} >
            <Form.Group as={Row}>
                <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                    <Label  name="Ward" type htmlFor='name'/>
                </Col>
                <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                    <CustomInput submitted={submitted} name='name' type='text' setIsBlocking={setIsBlocking} reset={isResolved} inputValue={ward} isRequired />
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                    <Label  name="No. of Rooms" type htmlFor='number_of_rooms'/>
                </Col>
                <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                    <CustomInput submitted={submitted} name='number_of_rooms' type='number' setIsBlocking={setIsBlocking}
                                 reset={isResolved} inputValue={number_of_rooms} isRequired id='number_of_rooms'/>
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                    <Label  name="Bed Capacity" type htmlFor='bed_capacity'/>
                </Col>
                <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                    <CustomInput submitted={submitted} name='bed_capacity' type='number' setIsBlocking={setIsBlocking}
                                 reset={isResolved} inputValue={bed_capacity} isRequired id='bed_capacity'/>
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                    <Label  name="In-Charge" type/>
                </Col>
                <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                    <CustomSelect id="ward-incharge"  {...{submitted}} options={wardens.map(w=>({value:w.user_roles_id,
                        label:`${w.first_name ? w.first_name : ''} ${w.last_name ? w.last_name : ''}`}))}
                                   value={warden} onChange={handleChangeInCharge} dataTest='in-charge'/>
                    {(submitted && !warden) && <ErrorMessage>Warden is required</ErrorMessage>}
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                    <Label  name="Description" htmlFor='description'/>
                </Col>
                <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                    <CustomResizableTextarea rows={2}  name='description' setIsBlocking={setIsBlocking}  reset={isResolved} inputValue={description}  id='description'/>
                </Col>
            </Form.Group>
            <button type="submit" data-testid="submit-ward" className="btn btn-sm sms-btn px-4" disabled={isPending}>
                {isPending ? "Saving...":"Save"}</button>
        </form>
      
    )
}



export default AddWardForm;
