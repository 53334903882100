import React from 'react'
import {StickyHeadTable, CustomTableCell, MuiPaper} from '../../Utils/Tables/StickyHeaderTable';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { LoadingGif } from '../../Utils/Loader';




function SetupLedgerList({data,isLoading, isError, isSuccess,handleOpenDelete,handleOpenEdit, headData = []}) {
    const emptyRows = 10 - Math.min(10, data.length - 0 * 10);
  return (
    <MuiPaper>
         <StickyHeadTable {...{width:'100%',data, columns:headData.filter(item=>Boolean(item))}}>
        {isLoading ? <TableRow><TableCell align='center' colSpan={headData.length}><LoadingGif/></TableCell></TableRow>:null}
        {isSuccess ? data.length > 0 ? 
            <>
                {data.map((row, index) => {
                return (
                <TableRow  tabIndex={-1} key={index}>
                    <CustomTableCell>{row.name}</CustomTableCell>
                    <CustomTableCell>{row.debit_ledger}</CustomTableCell>
                    <CustomTableCell>{row.credit_ledger}</CustomTableCell>
                    <CustomTableCell>
                        {row.default === 0 ?  <>
                        <button onClick={()=>handleOpenEdit(row.id)} className='btn btn-sm sms-info-btn mr-1'>Edit</button>
                        <button onClick={()=>handleOpenDelete(row.id)} className='btn btn-sm sms-btn-dismiss'>Delete</button>
                        </>:null}
                    </CustomTableCell>
                </TableRow>
                );
            })}
            {emptyRows > 0 && (
            <TableRow style={{ height: 43 * emptyRows }}>
            <TableCell colSpan={headData.length} />
            </TableRow>
        )}
        </>:<TableRow><TableCell align='center' colSpan={headData.length}>No records found</TableCell></TableRow>:null}
        {isError ? <TableRow><TableCell align='center'  colSpan={headData.length}>The server did not return a valid response</TableCell></TableRow>:null}
    </StickyHeadTable>
    </MuiPaper>
   
  )
}


export default SetupLedgerList;
