import React, {useEffect, useState} from "react";
import { useInvoiceProviders } from "./hooks/useInvoiceProviders";
import CustomTable from "../../Utils/Tables/CustomTable";
import FilterResults from "react-filter-search";
import { TableBody } from "@material-ui/core";
import { getComparator, stableSort } from "../../Utils/Tables/sortingFunctions";
import { TableRow } from "@material-ui/core";
import { TableCell } from "@material-ui/core";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as snackbarActions from "../../../actions/snackbarActions";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import DeleteDialog from "../../Utils/Dialogs/DeleteDialog";
import axios from "axios";
import { config } from "../../../Helpers/env";
import { errorMessages, logoutErrorMessage } from "../../../Helpers/ErrorMessages";
import PageTitle from "../../Utils/smsTitle";
import { SubHeader } from "../../../Containers/SubHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMortarPestle } from "@fortawesome/free-solid-svg-icons/faMortarPestle";
import { Container } from "reactstrap";
import { RightAlignedContainer } from "../../Utils/styledComponents";
import { LoadingGif } from "../../Utils/Loader";


const headData = [{id: 'sl', numeric: false, disablePadding: false, label: 'SL'},
    {id: 'schemeName', numeric: false, disablePadding: false, label: 'Scheme Name'},
    {id: 'actions', numeric: false, disablePadding: false, label: 'Action'},
];

export const SchemeList =({actions, snackbars}) =>{
    const [searchValue, setSearchValue] = useState('');
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('sl');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [item, setItem] = useState({scheme_id:'', status:''});
    const [isDelete, setIsDelete] = useState('idle')
    const [schemeList, setSchemes] = useState([]);
    const [loading, setLoading] = useState('idle');
    const [openDelete, setOpenDelete] = useState(false);
    const {providerProps, } = useInvoiceProviders(actions)
    const {renderAddScheme, isResolvedScheme, handleOpenEdit,handleOpenDialogScheme,handleCloseEdit} = providerProps
    // const {uploadProps, isResolved:isResolvedUpload} = useFileUploader(actions, obj)

    const isDeleted = isDelete === "deleted"

    useEffect(() => {
        setLoading('loading');
        axios.get(`${config.smsUrl}/insurance-scheme/?per_page=10&page=1`).then(res => {
            const data = res.data;
            const list = data.items ? data.items : []
            setSchemes(list);
            setLoading('success')
        }).catch(err => {
            logoutErrorMessage(err,null,actions);
            setLoading('error')
        })

    }, [isResolvedScheme,isDeleted]);

    const handleOpenDelete = (scheme) => {
       if(scheme){ 
        const {id, is_active} = scheme
        setItem({id, is_active});
        setOpenDelete(true)}
         
    };
    // console.log(item)

    const handleCloseDelete = () => {
        setOpenDelete(false)
    };

    const handleDelete = () => {
        const {id, is_active} = item;
        // console.log(id)
        const formData = new FormData();
        // const isActive = status === 1 ? 0 : status === 0 ? 1 : ''
        formData.append('scheme_id', id);
        formData.append('status', is_active);
        setIsDelete('pending')
        axios.delete(`${config.smsUrl}/insurance-scheme/${id}`, formData).then(() => {
            const message = status === 0 ? 'deactivated' : status === 0 ? 'activated' : ''
            actions.snackbarActions.snackSuccess(`Unit deleted successfully`);
            setIsDelete('deleted')
            setOpenDelete(false)
        }).catch(err => {
            errorMessages(err, null, actions)
            setIsDelete('rejected')
        })
    };
    const handleSearch = (value) => {
        setSearchValue(value)
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const isLoading = loading === 'loading'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'

    // const {handleOpenCsvDialog} = uploadProps
    const {openBar, type, message} = snackbars
    const {status} = item;

    return (
        <div data-testid="scheme-list">
            <PageTitle title="Insurance Scheme"/>
            <SubHeader title="Insurance Scheme" subTitle="Scheme List">
                <FontAwesomeIcon icon={faMortarPestle}/>
            </SubHeader>
            {/* <CsvDialog {...{...uploadProps, file:"/csv/unit_data.csv", title:'Upload Units'}}/> */}
            <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>
            <DeleteDialog message={status === 1 ?  'deactivate':'activate'} openDialog={openDelete} handleClose={handleCloseDelete}
                                text="scheme" title={`${status === 1 ? 'Deactivate': item.status === 0 ? 'Activate':'Deactivate'} Scheme`}>
                    <button className={`btn btn-sm ${status === 1 ? 'sms-gray-btn' : 'sms-info-btn'}`} id="final_delete"
                    onClick={handleDelete}>{status === 1 ? 'Deactivate': item.status === 0 ? 'Activate':'Deactivate'}</button>
                    <button className="btn sms-grey-btn btn-sm" onClick={handleCloseDelete}>No</button>
            </DeleteDialog>
            {renderAddScheme}
            <Container>
                <div className='general-ledger-header'>
                    <RightAlignedContainer>
                        <button className='btn btn-sm sms-info-btn mr-4' onClick={()=>handleOpenDialogScheme('')}>New Scheme</button>
                    </RightAlignedContainer>
                </div>
                <CustomTable  title="Scheme List" handleChangePage={handleChangePage}
                              handleChangeRowsPerPage={handleChangeRowsPerPage} filter
                              headData={headData} handler={handleSearch} page={page} rowsPerPage={rowsPerPage}
                              handleRequestSort={handleRequestSort} data={schemeList} colSpan={5} order={order}
                              orderBy={orderBy} id="searchValue">
                        <FilterResults value={searchValue} data={schemeList}
                           renderResults={results => (
                               <TableBody>
                                   {isLoading ? <TableRow>
                                       <TableCell colSpan={headData.length} align="center"><LoadingGif/></TableCell>
                                   </TableRow>: null}
                                   {isSuccess ? schemeList.length > 0 ?  stableSort(results, getComparator(order, orderBy))
                                       .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                       .map((item, index) => {
                                           const count = (page * rowsPerPage) + index + 1;
                                           return (
                                               <TableRow
                                                   hover
                                                   role="checkbox"
                                                   tabIndex={-1}
                                                   key={item.id}
                                                   className='mui-table-row'
                                                   id="scheme"
                                               >
                                                   <TableCell>{count}</TableCell>
                                                   <TableCell >{item.name}</TableCell>
                                                   <TableCell>
                                                       <button onClick={()=>handleOpenEdit(item.id)} id="edit" className="btn btn-sm sms-btn mr-1">Edit</button>
                                                       <button onClick={() => handleOpenDelete(item)} id='delete'
                                                               className={`btn btn-sm ${item.is_active === true ? 'sms-gray-btn' : 'sms-info-btn'}`}>
                                                           {item.is_active === true ? 'Deactivate':item.is_active === false ?  'Activate':'Deactivate'}
                                                       </button>
                                                   </TableCell>
                                               </TableRow>
                                           );
                                       }) : <TableRow>
                                       <TableCell colSpan={headData.length} align="center">No records found</TableCell>
                                   </TableRow>: null}
                                   {isError ? <TableRow>
                                       <TableCell colSpan={headData.length} align="center">The server did not return a valid response</TableCell>
                                   </TableRow>: null}
                               </TableBody>
                        )}/>
                </CustomTable>
            </Container>

        </div>
    )
}
function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(SchemeList);