import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../../../actions/snackbarActions"
import MainSnackbar from "../../../Utils/Snackbar/SmsSnackbar";
import PageTitle from "../../../Utils/smsTitle";
import {SubHeader} from "../../../../Containers/SubHeader";
import { useAddAdmission } from "../../../Visit/NewAdmission/hooks/useAddAdmission";
import PrintDialog from "../../../Utils/Dialogs/PrintDialog";
import ReusableAnteNatalTabs, { anteNatalRoutes } from './ReusableAnteNatalTabs';
import { PatientInformation } from '../../../DoctorsModule/PatientInfo/PatientInformation';
import { usePatientDetails } from '../../../DoctorsModule/ReferralNote/usePatientDetails';
import { DoctorAdmissionForm } from "../../../DoctorsModule/Admission/DoctorAdmission";
import {FullScreenDialog} from "../../../Utils/Dialogs/FullScreenDialog";
import {AssignBed} from "../../../BedManager/AddEditBedAssign";
import CustomDialog from "../../../Utils/Dialogs/CustomDialog";
import Alert from "@material-ui/lab/Alert";
import {Link} from "react-router-dom";

/**
 * Component that renders the Admission screen for Ante Natal
 * @param {*} param0 
 * @returns 
 */
const AnteNatalAdmission = ({actions, snackbars, match:{params}}) => {
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const {patient_number, labrequest_id, scan_request_id, visit_id, patient_type,patient_admission_id, anc_id, anc_status} = params;
    const arr = anteNatalRoutes(patient_number, visit_id, labrequest_id, scan_request_id, patient_type,patient_admission_id, anc_id, anc_status);
    // added parameter for the admission notes
    const {admissionProps, closeSnackbar, printDialog, handleClosePrintDialog, handleClick,
        assignProps, openDialog, handleCloseDialog,openAssignBed,
        handleOpenAssignBed, assignBed, handleCloseAssignBed} = useAddAdmission(actions, patient_number, false, params)
    const {patient} = usePatientDetails(actions, patient_number, '')
    const doctor_name = `${!_token.first_name ? "" : _token.first_name} ${!_token.last_name ? "" : _token.last_name}`;
    const {openBar, type, message} = snackbars;
    const {first_name, last_name, Age, gender, address, phone_no} = patient;

    const {providerProps, openNewInsurance, handleCloseNewInsurance,isPendingProvider,
        handleSubmitProvider, handleOpenNewInsurance, newProviderProps,
        isDiagnosis,handleCloseAlert, handleSubmit, isPending} =  admissionProps

    const buttons = (
        <>
            <button type='button' onClick={handleSubmit} disabled={isPending} className='sms-info-btn btn-sm btn small-btn px-2 mr-3'>Yes</button>
            <button type='button' onClick={handleCloseAssignBed} className='sms-gray-btn btn btn-sm small-btn px-2'>No</button>
        </>

    )

    return (
        <div className="journals" data-testid="ante-natal-admission">
            <PageTitle title="Patient Admission"/>
            <SubHeader title="Ante Natal" subTitle={`Patient Admission`}  doctor_name={doctor_name}>
            <div className="header-icon-margin-right-zero"><img src="/images/antenatal-blue.png" className="icon-resize surgery-icon-tabs ante-natal-green"/></div>
        </SubHeader>

            <FullScreenDialog open={openDialog} handleClose={handleCloseDialog} title="Assign Bed">
                <AssignBed {...assignProps}/>
            </FullScreenDialog>
            <CustomDialog open={openAssignBed} buttons={buttons} handleClose={handleCloseAssignBed} title='Assign bed'>
                <p style={{textAlign:'center'}}>Would you like to assign a bed to this patient?</p>
            </CustomDialog>
            <PrintDialog message="Admission Form" openDialog={printDialog} handleClose={handleClosePrintDialog}
                         text="Would you like to view and print this admission form" handleClick={handleClick}
                         handleCancel={handleClosePrintDialog}/>
            <ReusableAnteNatalTabs components={arr}>
                <PatientInformation phone={phone_no} gender={gender} age={Age ? Age : ''} patient_name={`${!first_name ? "" : first_name} ${!last_name ? "" : last_name}`}
                                    address={address} patient_id={patient_number} patient_number={patient_number}/>
                <MainSnackbar open={openBar} handleCloseBar={closeSnackbar} variant={type}
                        message={message} duration={3500}/>
                <div className="mt-2 px-3">
                    {isDiagnosis  ? <Alert severity="error" style={{paddingTop:0, paddingBottom:0, marginBottom:'8px'}} onClose={handleCloseAlert}>
                        <span style={{display:'inline-block', marginRight:4}} >Before admitting a patient, please add their diagnosis</span>
                        <Link to={{pathname: `/doctordiagnosis/${patient_number}/${visit_id}/null/${labrequest_id}/${scan_request_id}/${patient_type}/${patient_admission_id}`}}>Add diagnosis</Link>
                    </Alert>: null}
                    <DoctorAdmissionForm  {...{providerProps,handleOpenNewInsurance, admissionProps}}>
                        <button type="button" onClick={handleOpenAssignBed} className="btn btn-sm sms-btn px-4"
                                data-testid="submitAdmissionForm">Save</button>
                    </DoctorAdmissionForm>
                </div>
            </ReusableAnteNatalTabs>
        </div>
    )
};

function mapStateToProps(state) {
    return {
        snackbars: state.snackbar
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AnteNatalAdmission);
