import React, {useState} from "react";
import PageTitle from "../Utils/smsTitle";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CustomTable from "../Utils/Tables/CustomTable";
import FilterResults from "react-filter-search";
import TableBody from "@material-ui/core/TableBody";
import {getComparator, stableSort} from "../Utils/Tables/sortingFunctions";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import *  as snackbarActions from "../../actions/snackbarActions";
import {faMortarPestle} from "@fortawesome/free-solid-svg-icons/faMortarPestle";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import {usePharmacyStock} from "./CustomHooks/usePharmacyStock";
import {FullScreenDialog} from "../Utils/Dialogs/FullScreenDialog"
import ReceiveStock from "./ReceiveStock";
import {StockAdjustment} from "../Inventory/stock/StockAdjustment";
import { Link } from "react-router-dom";
import { LoadingGif } from "../Utils/Loader";
import { RightAlignedContainer } from "../Utils/styledComponents";
import PrintDialog from "../Utils/Dialogs/PrintDialog";
import { RequisitionForm } from "../Inventory/stock/AddRequisition";
import {useReceiveDispatchLabStock} from "../Lab/stock/hooks/useReceiveDispatchLabStock";
import {StockAdjustmentUpload} from "../Inventory/stock/StockAdjustmentUpload";

const headData = [{id: 'sl', numeric: false, disablePadding: false, label: 'SL'},
    {id: 'product', numeric: false, disablePadding: false, label: 'Product'},
    {id: 'generic_name', numeric: false, disablePadding: false, label: 'Generic Name'},
    {id: 'strength', numeric: false, disablePadding: false, label: 'Strength'},
    {id: 'unit', numeric: false, disablePadding: false, label: 'Unit'},
    {id: 'sellPrice', numeric: false, disablePadding: false, label: 'Sell Price'},
    {id: 'stock', numeric: false, disablePadding: false, label: 'Stock'},
];
 
const adjustmentHeadData = [
    {name:'Product', isRequired:true},{name:'Stock', isRequired:false},
    {name:'Quantity', isRequired:true},{name:'Adjustment Type', isRequired:true},
    {name:'Total Stock', isRequired:false},{name:'Action', isRequired:false}
];

const PharmacyStock = ({actions, snackbars}) => {

    const [openReceive, setOpenReceive] = useState(false);


    const handleOpenReceiveDialog = () =>{
        setOpenReceive(true)
    }

    const handleCloseReceiveDialog = () =>{
        setOpenReceive(false)
    }

    const {receiveStorePharmacy,isResolvedDispatch} = useReceiveDispatchLabStock({actions, setOpenDialog:setOpenReceive,to_store:'Pharmacy'})

    const {
        searchValue, order, orderBy, isLoading,isSuccess,isError,
        stock, total_count, activePage,
        closeSnackbar, handleSearch, handleChangePage,
        handleRequestSort, openDialog, handleCloseDialog,
        stockProps,adjustmentProps,  changeActiveStatus, activeStatus,
        requisitionProps, openRequsition, requisitionDialog,
        handleReqCancel, handleReqClick, handleReqCloseDialog,
        handleCloseRequisitionDialog, handleOpenRequisitionDialog

    } = usePharmacyStock(actions,isResolvedDispatch);

    const products = !stock ? [] : stock;
    const {openBar, type, message} = snackbars;

    const {openDialog:openAdjustment, handleOpenDialog:handleOpenAdjustment,
        handleCloseDialog:closeAdjustment, openStockDialog, setOpenStockDialog,
        setOpenDialog, isCsv, setIsCsv, location} = adjustmentProps

    const uploadStockAdjustment = (
        <StockAdjustmentUpload {...{openStockDialog, actions,setOpenStockDialog,setOpenDialog,isSubmitted:isCsv,
            setIsSubmitted:setIsCsv, location}}/>
    )

    return (
        <div data-testid="pharmacy-stock" className='journals'>
            <PageTitle title="Stock"/>
            <SubHeader title="Pharmacy" subTitle="Stock">
                <FontAwesomeIcon icon={faMortarPestle}/>
            </SubHeader>
            <FullScreenDialog open={openDialog} handleClose={handleCloseDialog} title="Receive Stock" maxWidth="lg">
                <ReceiveStock {...stockProps}/>
            </FullScreenDialog>

            <FullScreenDialog open={openReceive} handleClose={handleCloseReceiveDialog} title="Receive Stock" maxWidth="lg">
                {receiveStorePharmacy}
            </FullScreenDialog>

            <FullScreenDialog open={openAdjustment} handleClose={closeAdjustment} title='Adjust Stock'>
                <StockAdjustment {...{...adjustmentProps, adjustmentUpload:uploadStockAdjustment,
                headData:adjustmentHeadData, fileName:'Stock.csv', user:'Added By'}}/>
            </FullScreenDialog>

            <PrintDialog openDialog={requisitionDialog} handleClose={handleReqCloseDialog} message="Print Requisition Note"
                text="Would you like to print this requisition note" handleClick={handleReqClick} handleCancel={handleReqCancel}/>
        
            <FullScreenDialog open={openRequsition} handleClose={handleCloseRequisitionDialog} title="Make Requisition">
                <RequisitionForm {...{...requisitionProps, department:'Pharmacy'}}/>
            </FullScreenDialog>

            {/*<div className='general-ledger-header'>*/}
            {/*    <div className="row">*/}
            {/*        <div className="col-lg-6"/>*/}
            {/*        <div className="col-lg-6">*/}
            <div className='mt-3'>
               <RightAlignedContainer>
                    <button type="button"  onClick={handleOpenRequisitionDialog}
                        className="btn sms-btn small-btn mb-1 mr-3">Make Requisition</button>

                   <button className='btn btn-sm sms-info-btn small-btn mb-1 mr-3' id="receive-store-stock"
                           onClick={handleOpenReceiveDialog}>Receive stock</button>

                   <button type="button" onClick={()=>handleOpenAdjustment('pharmacy')}
                        className="btn sms-gray-btn  small-btn mb-1 btn-sm">Adjust Stock</button>
               </RightAlignedContainer>
            </div>
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/* <div className='general-ledger-header'>
                <div className="row">
                    <div className="col-lg-8"/>
                    <div className="col-lg-4">
                        <div className='row'>
                            {setupRequired ? <div className="col-lg-4 col-sm-4 col-md-4">
                                <button type="button" onClick={handleOpenInitial} className="btn sms-btn btn-sm mb-2">Add Initial Stock</button>
                            </div>:null}
                            <div className="col-lg-4 col-sm-4 col-md-4">
                                <button type="button" onClick={handleOpenDialog} className="btn sms-info-btn btn-sm mb-2">Receive Stock</button>
                            </div>
                            <div className="col-lg-4 col-sm-4 col-md-4">
                               <button type="button" onClick={handleOpenAdjustment} className="btn sms-gray-btn btn-sm">Adjust Stock</button>
                            </div>
                            <div className="col-lg-4"> 
                                <DataExportButton data={allRecords} title='Pharmacy Stock List' csvRef={csvRef}
                                                    exportCsv={downloadCsv} downLoadPdf={downloadPdf} headers={headers}/>
                            </div> 
                        </div>
                    </div>
                </div>

            </div> */}

            <div className='general-ledger-header inpatient-billing-header'>
                <a className={`billing-span btn btn-sm ${activeStatus === 1 ? 'active-billing-span':''}`} 
                onClick={()=>changeActiveStatus(1)}>Products</a>
                
                <a className={`billing-span btn btn-sm ${activeStatus === 3 ? 'active-billing-span':''}`} 
                onClick={()=>changeActiveStatus(3)}>Sundries</a>
            </div>
            <div className="mui-tables">
                <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>
                <CustomTable title="Stock" handleChangeNextPage={handleChangePage} term={searchValue} records={10}
                             headData={headData} handler={handleSearch} total_count={total_count}
                             activePage={activePage}
                             handleRequestSort={handleRequestSort} data={products} colSpan={headData.length} order={order}
                             orderBy={orderBy} customPage>
                       
                    <TableBody>
                        {isLoading ? <TableRow>
                            <TableCell colSpan={headData.length} align="center"><LoadingGif/></TableCell>
                        </TableRow>:null}
                        {isSuccess ? products.length > 0 ? <FilterResults value={searchValue} data={products}
                            renderResults={results => (
                        stableSort(results, getComparator(order, orderBy))
                            .slice(0, 10)
                            .map((item, index) => {
                                const count = ((activePage - 1) * 10) + index + 1;
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={index}
                                        className='mui-table-row'>
                                        <TableCell>{count}</TableCell>
                                        <TableCell>
                                            <Link to={{pathname:`/reports/productlog/pharmacy/${item.product_id}`}}>
                                                {item.product_name}{item.country_of_origin ? ` - ${item.country_of_origin}`: null}
                                            </Link>
                                        </TableCell>
                                        <TableCell>{item.generic_name}</TableCell>
                                        <TableCell>{item.strenght}</TableCell>
                                        <TableCell>{item.name}</TableCell>
                                        <TableCell>{item.price}</TableCell>
                                    
                                        {/* <TableCell>{item.totalsold}</TableCell> */}
                                        <TableCell>{item.stock}</TableCell>
                                    </TableRow>
                                );
                            }))}/>:<TableRow>
                            <TableCell colSpan={headData.length} align="center">No records found</TableCell>
                        </TableRow>:null}
                        {isError ? <TableRow>
                            <TableCell colSpan={headData.length} align="center">The server did not return a valid response</TableCell>
                        </TableRow>:null}
                    </TableBody>
                </CustomTable>
            </div>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(PharmacyStock);
