import React, {useState} from "react";
import axios from "axios";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import {config} from "../../../../Helpers/env";
import {ProcedureTable} from "../ProcedureTable";
import {RightAlignedContainer} from "../../../Utils/styledComponents";
import {ConfirmationDialog} from "../../../Utils/ReusableComponents/ConfirmationDialog";
import {BlockNavigationComponent} from "../../../Utils/ReusableComponents/BlockNavigationComponent";
import {history} from "../../../../Helpers/history";


export function useNewMultipleProcedures(obj) {
    const initialProcedure = [{currency:'', currency_symbol:'',cost:'',
        procedure_id: 0, service: '', department_name: '', department_id: '', doctor_name: '', quantity:1
    }]
    const {visit_id, service_type, services, actions,setOpenDialog, setIsBlocking, isBlocking,setServiceType} = obj

    const [procedures, setProcedures] = useState(initialProcedure)
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [doctors, setDoctors] = useState([])
    const [openMultiService, setOpenMultiService] = useState(false)
    const [multi_service_id, setMultiServiceId] = useState('')
    const [isBlockDialog, setIsBlockDialog] = useState(false)



    const handleAddProcedureRow =() => {
        setProcedures([...procedures, ...initialProcedure])
        setIsBlocking(true)
    }

    const handleRemoveProcedureRow =(index)=>{
        if (procedures.length > 1) {
            const arr = procedures.filter((_, idx) => idx !== index)
            setProcedures(arr);
        }
        setIsBlocking(true)
    }

    const handleChangeDoctorMultipleProcedures = (value, idx) =>{
        let arr = procedures.map((item, index)=>{
            if (index === idx){
                return {...item, doctor_name:value}
            }
            return item
        })
        setProcedures(arr)
        setIsBlocking(true)
    }

    const handleRetrieveDoctors = (id) =>{
        const formData = new FormData();
        formData.append('department_id', id);
        axios.post(`${config.smsUrl}/cvisit/getdoctorsbydepartment_id`, formData)
            .then(response => {
                const res = response.data;
                const data = !res ? {} : res;
                const docs = !data.doctors ? [] : data.doctors;
                setDoctors(docs)
            })
            .catch(error => {
                logoutErrorMessage(error, null, actions)
            });
    }


    const handleChangeProcedureService = (e, idx)=>{
        let arr = procedures.map((item, index)=>{
            if (index === idx){
                return {...item, service:e.target.value}
            }
            return item
        })

        const formData = new FormData();
        const s_id =e.target.value ?? '';
        const id = s_id.split('-')[0];
        formData.append('service_id', id);
        const endPoints = [
            {
                url:`${config.smsUrl}/cdoctor/retrieve_procedures_by_service_id`,
                data: formData
            },
            {
                url:`${config.smsUrl}/cbilling/retrieve_department_by_service_id`,
                data: formData
            },
            {
                url:`${config.smsUrl}/cbilling/item_cost`,
                data: {visit_id :+visit_id, item_id: +id, item_type:'service'}
            }
        ]
        Promise.all(endPoints.map((endpoint) => axios.post(endpoint.url, endpoint.data)))
            .then(([{data: procedureData},{data: departmentData}, {data: currencyData}] )=> {
                const data = !departmentData ? {} : departmentData;
                const {department_id, department_name} = data;
                if (department_id) {
                    handleRetrieveDoctors(department_id)
                }

                const [procedure_data] = procedureData ? procedureData : []

                const visitObj = currencyData.data ? currencyData.data : {}

                arr = arr.map((item, index)=>{
                    if (index === idx){
                        return {...item, procedure_id:procedure_data.procedure_id,currency:visitObj.currency_id,
                            currency_symbol:visitObj.currency, cost:visitObj.cost, department_id, department_name,
                            ...visitObj}
                    }
                    return item
                })

                setProcedures(arr)

                if(visitObj.is_provider === 1 && visitObj.is_provided === 0){
                    setMultiServiceId(data.procedure_id)
                    setOpenMultiService(true)
                }
            })
        setIsBlocking(true)
    }



    const addMultiServiceToReceipt = () =>{
        setOpenMultiService(false)
    }

    const handleCloseMultiService = () =>{
        const arr = procedures.filter(item=>item.service !== multi_service_id)
        setProcedures(arr)
        setOpenMultiService(false)
    }

    const handleCancelMultiService = () =>{
        setOpenMultiService(false)
    }

    const handleChangeProcedureQuantity = (e, idx)=> {
        let arr = procedures.map((item, index) => {
            if (index === idx) {
                return {...item, quantity: e.target.value}
            }
            return item
        })
        setProcedures(arr)
    }

    const handleSubmitMultipleService = (event) => {
        event.preventDefault();
        const multipleProcedure = procedures.map(item => ({
            service_id: +item.service.split('-')[0],
            quantity: +item.quantity,
            rate: +item.cost,
            procedure_id: item.procedure_id,
            is_provided: item.is_provided,
            is_provider: item.is_provider,
            currency_id:item.currency_id,
            department_id:item.department_id
        }))

        //check if we are have multiple procedures
        const isArr = procedures.every(proc => proc.service && proc.quantity);


        // required parameters
        const  params = {
            visit_id, result_type: 1,bill_type:2,
            bill_details:  multipleProcedure
        }

        setSubmitted(true);
        setIsBlocking(false)
        if (service_type && isMultipleProcedure) {
            setIsSubmitted('pending');
            axios.post(`${config.smsUrl}/cdoctor/add_procedure`, {...params}).then(() => {
                setIsSubmitted('resolved');
                actions.snackbarActions.snackSuccess(`Service(s) submitted successfully`);
                setSubmitted(false)
                if(setOpenDialog){
                    setOpenDialog(false)
                }
                const arr = procedures.map(item=>({
                    ...item,
                    currency:'', currency_symbol:'',cost:'',
                    procedure_id: 0, service: '', department_name: '', department_id: '', doctor_name: ''
                }))
                setProcedures(arr)
                setServiceType('')
            }).catch((err) => {
                errorMessages(err, null, actions)
                setSubmitted(false);
                setIsSubmitted('rejected');
            });
        }

    };

    const handleResetForm = () =>{
        if(setOpenDialog){
            setOpenDialog(false)
        }
        const arr = procedures.map(item=>({
            ...item,
            currency:'', currency_symbol:'',cost:'',
            procedure_id: 0, service: '', department_name: '', department_id: '', doctor_name: ''
        }))
        setProcedures(arr)
        setServiceType('')
        setIsBlockDialog(false)
        setIsBlocking(false)
        setSubmitted(false);

    }

    const handleCloseMultiServiceDialog = () => {
        if(isBlocking){
            setIsBlockDialog(true)
        }else{
            handleResetForm()
        }
    };

    const isPending = isSubmitted === 'pending'
    const isResolved = isSubmitted === 'resolved'
    const isRejected = isSubmitted === 'rejected'

    const docs = doctors ? doctors : [];

    return  {
        isResolved,
        isRejected,
        submitted,
        handleCloseMultiServiceDialog,
        handleSubmitMultipleService,
        renderMultiProcedure: (
            <div  className="mt-3">
                <BlockNavigationComponent
                    when={isBlocking}
                    shouldBlockNavigation={()=>{
                        return isBlocking;
                    }}
                    navigate={path=>history.push(path)}
                    dialogClose={isBlockDialog}
                    setIsBlockDialog={setIsBlockDialog}
                    clearForm={handleResetForm}
                />
                <ConfirmationDialog open={openMultiService} handleClose={handleCancelMultiService}
                                    title='Make cash payment'
                                    confirmItems={addMultiServiceToReceipt}
                                    removeItems={handleCloseMultiService} service='procedure'/>
                <ProcedureTable {...{procedures, services, submitted, handleAddProcedureRow, handleRemoveProcedureRow,
                    docs,  handleChangeProcedureService,handleChangeProcedureQuantity,
                    handleChangeDoctorMultipleProcedures}}/>
                <RightAlignedContainer>
                    <button type="submit" id='submit_form'
                            className="sms-btn btn btn-sm px-3 mt-3"  disabled={isPending}>{isPending ? 'Saving...' : 'Save Service'}</button>
                    {/*<button type="button"  id="reset_form"  className="sms-gray-btn btn btn-sm ml-2" >Cancel</button>*/}
                </RightAlignedContainer>
            </div>
        )
    }

}


