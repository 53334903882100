import React, {useEffect, useState} from 'react';
import axios from "axios";
import { config } from "../../../Helpers/env";
import {logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import { useRecordPatient } from './useRecordPatient';


export const useEmergencyList = (actions) =>{
    const [searchValue, setSearchValue] = useState('');
    const [activePage, setActivePage] = useState(1);
    const [emergency, setEmergency] = useState({emergencies:[], total_count:0, status:'idle'})
    const [openDialog, setOpenDialog] = useState(false);
    const [emergencyDetails, setEmergencyDetails] = useState({emergencyObj:{},loading:'idle'});
    const {status} = emergency;
    const {loading} = emergencyDetails
    const [id, setVisitId] = useState('');
    const {savePatientProps,attachPatientProps,isSubmitted,setVisitId:setVisit,
         handleSubmitPatient, openAttach, setOpenAttach, isPending:isSavingPatient} = useRecordPatient(actions);

    const isLoading =  status === 'pending';
    const isSuccess = status === 'success';
    const isError = status === 'error';

    const ispending =  loading === 'pending';
    const isResolved = loading === 'success';
    const isRejected = loading === 'error';
    const isSubmittedPatient = isSubmitted === 'resolved';

    useEffect(() => {
        const formData = new FormData();
        formData.append('search', searchValue);
        setEmergency({...emergency, status:'pending'})
        axios.post(`${config.smsUrl}/cvisit/emergency_list/${activePage}`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const patients = !dt.emergency_list ? [] : dt.emergency_list;
            const count = !dt.total_count ? 0 : dt.total_count;
            setEmergency({emergencies:patients,total_count:count, status:'success'})
        }).catch(err => {
            logoutErrorMessage(err,null,actions)
            setEmergency({...emergency, status:'error'})
        })
    }, [activePage, searchValue,isSubmittedPatient]);

    useEffect(() => {
        if(!id){
            return;
        }
        const formData = new FormData();
        formData.append('visit_id', id);
        setEmergencyDetails({...emergencyDetails, loading:'pending'})
        axios.post(`${config.smsUrl}/cvisit/emergency_patient_details`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            setEmergencyDetails({emergencyObj:dt,loading:'success'})
        }).catch(err => {
            logoutErrorMessage(err,null,actions)
            setEmergencyDetails({...emergencyDetails, loading:'error'})
        })
    }, [id]);

    const handleSearch = (event) => {
        setSearchValue(event.target.value);
    };

    const handleOpenAttach = (id) =>{
        setOpenAttach(true);
        setVisit(id);
    }

    const handleCloseAttach = () =>{
        setOpenAttach(false);
    }

    const handleOpenDialog = (item) => {
        setOpenDialog(true);
        const {visit_id} = item;
        setVisitId(visit_id);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleChangeNextPage = (value) => {
        setActivePage(value);
    };

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar();
    };

    return {searchValue,activePage, emergency, emergencyDetails, openDialog, isLoading,isSuccess,
    isError, ispending, isRejected, isResolved, handleChangeNextPage, handleCloseDialog, 
    handleOpenDialog, handleSearch, closeSnackbar,savePatientProps,attachPatientProps,isSubmitted,
    handleSubmitPatient,  openAttach,handleOpenAttach, handleCloseAttach,isSavingPatient}
}