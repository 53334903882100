import Form from "react-bootstrap/Form";
import Label from "../../../../Utils/FormInputs/Label";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TextField from '../../../../Utils/FormInputs/TextField';
import { CustomSelect } from "../../../../Utils/FormInputs/SelectInput";
import { FormLabel } from "../../../../Utils/FormInputs/Label";
import InputGroup from "react-bootstrap/esm/InputGroup";

/**
 * Component renders Second Page for the Ante Natal Patient Form
 * @param {*} param0 
 * @returns 
 */
const AnteNatalSecondScreen = ({second_screen_props}) => {

    const  {
        handleFirstLMNPChange, first_day_of_lnmp,
        exam_notes, handleExamNotesChange,
        clinical_stages, who_clinical_stage, handleClinicalStagesChange,
        womanInitialResult, handleInitialResultChange, stk_given, handleSTKGiven,
        partnerEMTCT, handleSetPartnerEMTCT, 
        yes_nos, yes_no_linked_art, handleYesNoLinkedArt,
        tfv, handleTFV, emtct_codes,
        where_pre_art_no, handleWherePreArtNoChange,
        partner_stk_result, handlePartnerSTKResult,
        risk_assess, risk_assessments, handleRiskAssessment,
        family_plan, familyplannings, handleFamilyPlanningChange,
        infant_feeding, handleInfantFeeding, infant_feeding_options,
        tb_status, handleTBStatusChange, tb_codes,
        gbv_risk, handleGBVRisks, gbv_risks,
        handleCD4VLChanges, cd4_vl_vars, handleHBSickleCellChange,
        syphilis_types, syphilisHeB_Partner, syphilisHeB_Woman,
        handleSyphilisHEBPartnerChange, handleSyphilisHEBWomanChange,
        maternal_nutrition, handleMaternalNutritionChange,
        art_vars, handleARTChange, handleLLINChange, llin,
        blood_groups, blood_group, handleBloodGroup,
        handleEMTCTCodes, handleRoutineDate, handleRoutines, 
        routine_date, emtct, routines,
        rh, rh_factors, handleRHFactorChange, hb_sickle_cell,
        ipt_dose, ipt_doses, handleIPTDose, sickles, handlePelvicChecks, pelvic_checks,
        tetanus_doses, tetanus_dose, handleTetanusDoses, nutrtional_radios,
        mab_options, mabendazole, handleMabendazoleChange, handleRadioChecks,
        examination_checks, handleExaminationChecks, woa_props, onChangeWOA, 
        complications_checks, handleComplicationChecks, complications_texts, handleComplicationTexts
    } = second_screen_props;

    const {woa_months, woa_days} = woa_props;
    const {random_blood_sugar, bs_for_mps} = routines;
    
    const {
        syhpilis_partner, heb_partner
    } = syphilisHeB_Partner;

    const {
        syhpilis_woman, heb_woman
    } = syphilisHeB_Woman;
    const {partner_age, facility} = partnerEMTCT;
    const {where, pre_art_no, art_no} = where_pre_art_no;
    const {cd4, viral_load} = cd4_vl_vars;
    const {linakge_art_no, infant_arv_prophyloxis, art_code} = art_vars;
    const {
        hb, sickle_cell
    } = hb_sickle_cell;

    const {
        bleed_complication, excessive_comp, other_comp, hosp_yes, hosp_no, cough_comp, fever_comp, 
        weight_loss, night_sweats, hiv_yes, hiv_no, 
    } = complications_checks;

    const {
        normal_nutr, mam_nutr, sam_nutr
    } = nutrtional_radios;

    const {
        other_complication_text, any_hosp_text
    } = complications_texts;


    const {
        oral_thrush, angeria, deformities, teeth, eyes, lymph_nodes, neck, nails, herpes_zoosler,
        breasts, palms, heart, legs, jaundice, lungs,
    } = examination_checks;

    const {
        vulva, corvix, vagina_monliasis, monliasis
    } = pelvic_checks;

    return (
        <div data-testid="ante-natal-patient-form-second-screen">
            <Row>
                <Col md={6}>
                    <div>
                    <Form.Group as={Row} className="surgery-schedule-flex">
                            <Col xs={6} sm={6} lg={5} md={6} className="align-cols">
                                <Label name="First Day of LNMP"/>
                                <TextField autocomplete="off" type="date" value={first_day_of_lnmp} onChange={handleFirstLMNPChange}/>
                            </Col>
                            
                            <Col xs={6} sm={6} lg={5} md={6} className="align-cols">
                                <FormLabel  name={"WOA"} toolTip={"Weeks of Amenorrhea"}/>
                                <InputGroup className="mb-2">
                                    <Form.Control type="number" value={woa_months} name="woa_months" onChange={onChangeWOA} min={0} className="left-border-radius" autoComplete="off"/>
                                    <InputGroup.Text size="xs" className="zero-border-radius-txt-months">Months</InputGroup.Text>
                                    <Form.Control type="number" value={woa_days} name="woa_days" onChange={onChangeWOA} min={0} className="zero-border-radius" autoComplete="off"/>
                                    <InputGroup.Text size="xs" className="right-border-radius-txt-days">Days</InputGroup.Text>
                                </InputGroup>
                            </Col>
                    </Form.Group> 

                    <h6 className="antenatal-form">
                        Complications of Pregnancy if any:
                    </h6>
                    <hr class="rounded"/>
                    <Form.Group as={Row} className="surgery-schedule-flex">
                        <Col>
                            <Form.Check type="checkbox" label="Bleeding"  checked={bleed_complication} name="bleed_complication" onChange={handleComplicationChecks}/>
                        </Col>  
                        <Col>
                            <Form.Check type="checkbox" label="Excessive vomiting"  checked={excessive_comp} name="excessive_comp" onChange={handleComplicationChecks}/>
                        </Col>                     
                    </Form.Group> 
                    <Form.Group as={Row} className="surgery-schedule-flex">
                        <Col>
                        <Form.Check type="checkbox" label="Others:"  checked={other_comp} name="other_comp" onChange={handleComplicationChecks}/>
                        </Col>
                    </Form.Group>
                    <Row className="surgery-schedule-flex">
                        {other_comp === true ? 
                        <>
                            <Col>
                                <TextField autocomplete="off" placeholder={"Specify other complications here ..."} name="other_complication_text" value={other_complication_text} onChange={handleComplicationTexts}/>
                            </Col>
                        </>
                        : null}
                    </Row>


                    <Form.Group as={Row} className="surgery-schedule-flex">
                        <h7>
                            Any hospitalisation?
                        </h7>
                        <Col>
                            <Form.Check type="checkbox" label="YES"  checked={hosp_yes} name="hosp_yes" onChange={handleComplicationChecks}/>
                        </Col>  
                        <Col>
                            <Form.Check type="checkbox" label="NO"  checked={hosp_no} name="hosp_no" onChange={handleComplicationChecks}/>
                        </Col>                     
                    </Form.Group> 

                    <Row className="surgery-schedule-flex">
                        {hosp_yes === true ? 
                        <>
                            <Col>
                                <TextField autocomplete="off" placeholder={"If Yes, add hospitalisation here ... "} name="any_hosp_text" value={any_hosp_text} onChange={handleComplicationTexts}/>
                            </Col>
                        </>
                        : null}
                    </Row>           

                    <h7>Has any of the following been present for over 2 weeks?</h7>
                    <Form.Group as={Row} className="surgery-schedule-flex">
                        <Col>
                            <Form.Check type="checkbox" label="Cough"  checked={cough_comp} name="cough_comp" onChange={handleComplicationChecks}/>
                        </Col>
                        <Col>
                            <Form.Check type="checkbox" label="Fever"  checked={fever_comp} name="fever_comp" onChange={handleComplicationChecks}/>
                        </Col>  
                        <Col>
                            <Form.Check type="checkbox" label="Weight Loss" checked={weight_loss} name="weight_loss" onChange={handleComplicationChecks} />
                        </Col> 
                        <Col>
                            <Form.Check type="checkbox" label="Night Sweats"  checked={night_sweats} name="night_sweats" onChange={handleComplicationChecks}/>
                        </Col>                    
                    </Form.Group>

                    
                    <Form.Group as={Row} className="surgery-schedule-flex">
                        <h7>
                            Known HIV Status:
                        </h7>
                        <Col>
                            <Form.Check type="checkbox" label="YES"  checked={hiv_yes} name="hiv_yes" onChange={handleComplicationChecks}/>
                        </Col>  
                        <Col>
                            <Form.Check type="checkbox" label="NO"  checked={hiv_no} name="hiv_no" onChange={handleComplicationChecks}/>
                        </Col>                     
                    </Form.Group>

                    <h7>Nutritional Status:</h7>
                    <Form.Group as={Row} className="surgery-schedule-flex">
                        <Col>
                            <Form.Check type="radio" label="Normal" checked={normal_nutr} name="normal_nutr" onChange={handleRadioChecks} />
                        </Col>
                        <Col>
                        <div style={{display:'flex'}}>
                        <Form.Check type="radio" label="MAM" checked={mam_nutr} name="mam_nutr" onChange={handleRadioChecks}/>
                            <FormLabel toolTip={"Moderate Acute Malnutrition"} />
                        </div>
                            
                        </Col>  
                        <Col>
                        <div style={{display:'flex'}}>
                        <Form.Check type="radio" label="SAM" checked={sam_nutr} name="sam_nutr" onChange={handleRadioChecks}/>
                            <FormLabel toolTip={"Severe Acute Malnutrition"} />
                        </div>
                            
                        </Col>                     
                    </Form.Group>
                    </div>


                    <h5 className="antenatal-form">GENERAL EXAMINATION</h5>
                    <hr class="rounded"/>
                    <h6>Examine and Comment on the following:</h6>
                    <Form.Group as={Row}  className="surgery-schedule-flex">
                        <Col>
                            <Form.Check type="checkbox" label="Oral Thrush"  checked={oral_thrush} name="oral_thrush" onChange={handleExaminationChecks}/>
                        </Col>
                        <Col>
                            <Form.Check type="checkbox" label="Angeria"   checked={angeria} name="angeria" onChange={handleExaminationChecks}/>
                        </Col>  
                        <Col>
                            <Form.Check type="checkbox" label="Deformities"  checked={deformities} name="deformities" onChange={handleExaminationChecks} />
                        </Col>                    
                    </Form.Group>
                    <Form.Group as={Row}  className="surgery-schedule-flex">
                        <Col>
                            <Form.Check type="checkbox" label="Teeth"  checked={teeth} name="teeth" onChange={handleExaminationChecks} />
                        </Col>
                        <Col>
                            <Form.Check type="checkbox" label="Eyes"   checked={eyes} name="eyes" onChange={handleExaminationChecks}/>
                        </Col>  
                        <Col>
                            <Form.Check type="checkbox" label="Lymph Nodes"  checked={lymph_nodes} name="lymph_nodes" onChange={handleExaminationChecks} />
                        </Col>                    
                    </Form.Group>
                    <Form.Group as={Row}  className="surgery-schedule-flex">
                        <Col>
                            <Form.Check type="checkbox" label="Neck"   checked={neck} name="neck" onChange={handleExaminationChecks}/>
                        </Col>
                        <Col>
                            <Form.Check type="checkbox" label="Nails"  checked={nails} name="nails" onChange={handleExaminationChecks} />
                        </Col>  
                        <Col>
                            <Form.Check type="checkbox" label="Herpes zoosler"  checked={herpes_zoosler} name="herpes_zoosler" onChange={handleExaminationChecks} />
                        </Col>                    
                    </Form.Group>
                    <Form.Group as={Row}  className="surgery-schedule-flex">
                        <Col>
                            <Form.Check type="checkbox" label="Breasts"  checked={breasts} name="breasts" onChange={handleExaminationChecks} />
                        </Col>
                        <Col>
                            <Form.Check type="checkbox" label="Palms"   checked={palms} name="palms" onChange={handleExaminationChecks}/>
                        </Col>  
                        <Col>
                            <Form.Check type="checkbox" label="Hearts"   checked={heart} name="heart" onChange={handleExaminationChecks}/>
                        </Col>                    
                    </Form.Group>
                    <Form.Group as={Row}  className="surgery-schedule-flex">
                        <Col>
                            <Form.Check type="checkbox" label="Legs"   checked={legs} name="legs" onChange={handleExaminationChecks}/>
                        </Col>
                        <Col>
                            <Form.Check type="checkbox" label="Jaundice"   checked={jaundice} name="jaundice" onChange={handleExaminationChecks}/>
                        </Col>  
                        <Col>
                            <Form.Check type="checkbox" label="Lungs"   checked={lungs} name="lungs" onChange={handleExaminationChecks}/>
                        </Col>                    
                    </Form.Group>

                    <h6 className="antenatal-form">PELVIC EXAMINATION</h6>
                    <hr class="rounded"/>
                    <Form.Group as={Row}  className="surgery-schedule-flex">
                        <Col>
                            <Form.Check type="checkbox" label="Vulva"   checked={vulva} name="vulva" onChange={handlePelvicChecks}/>
                        </Col>
                        <Col>
                            <Form.Check type="checkbox" label="Corvix"   checked={corvix} name="corvix" onChange={handlePelvicChecks}/>
                        </Col>                    
                    </Form.Group>
                    <Form.Group as={Row}  className="surgery-schedule-flex">
                        <Col>
                            <Form.Check type="checkbox" label="Vagina Monliasis"   checked={vagina_monliasis} name="vagina_monliasis" onChange={handlePelvicChecks}/>
                        </Col>
                        <Col>
                            <Form.Check type="checkbox" label="Monliasis"   checked={monliasis} name="monliasis" onChange={handlePelvicChecks}/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}  className="surgery-schedule-flex">

                    <Form.Control as="textarea" rows="10" cols="4"  autoComplete="off"  onChange={handleExamNotesChange}
                        name="exam_notes" value={exam_notes} placeholder={"Add Examination notes here ..."}  className={`form__control`}/>
                    </Form.Group>

                    <h5 className="antenatal-form">INVESTIGATIONS</h5>
                    <hr class="rounded"/>

                    <h5 className="antenatal-form">{`ART (Anti Retroviral Treatment)`}</h5>
                    <hr class="rounded"/>
                    <h6 className="antenatal-form">{`ALREADY ON ART`}</h6>
                    <hr class="rounded"/>
                    <Form.Group as={Row} className="surgery-schedule-flex">
                        <Col xs={4} sm={4} lg={3} md={4} className="align-cols">
                            <Label name="ART Code"/>
                            <TextField autocomplete="off" name="art_code" value={art_code} onChange={handleARTChange}/>
                        </Col>

                        <Col xs={4} sm={4} lg={3} md={4} className="align-cols">
                            <Label name="Linkage Art No."/>
                            <TextField autocomplete="off" name="linakge_art_no" value={linakge_art_no} onChange={handleARTChange}/>
                        </Col>

                        <Col xs={4} sm={4} lg={3} md={4} className="align-cols">
                            <Label name="Infant Arv Prophyloxis"/>
                            <TextField autocomplete="off" value={infant_arv_prophyloxis} name="infant_arv_prophyloxis" onChange={handleARTChange}/>
                        </Col>
                    </Form.Group>


                    <h6 className="antenatal-form">{`NEWLY ON ART`}</h6>
                    <hr class="rounded"/>
                    <Form.Group as={Row} className="surgery-schedule-flex">
                        <Col xs={6} sm={6} lg={5} md={6} className="align-cols">
                            <Label name="Yes/No"/>
                            <CustomSelect options={yes_nos} value={yes_no_linked_art} onChange={handleYesNoLinkedArt}/>
                        </Col>

                        <Col xs={6} sm={6} lg={5} md={6} className="align-cols">
                            <Label name="TFV"/>
                            <TextField autocomplete="off" value={tfv} onChange={handleTFV}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="surgery-schedule-flex">
                        <Col xs={4} sm={4} lg={3} md={4} className="align-cols">
                            <Label name="Where"/>
                            <TextField autocomplete="off" type="text" value={where} name="where" onChange={handleWherePreArtNoChange}/>
                        </Col>

                        <Col xs={4} sm={4} lg={3} md={4} className="align-cols">
                            <Label name="Pre-Art No."/>
                            <TextField autocomplete="off" type="number" value={pre_art_no} name="pre_art_no" onChange={handleWherePreArtNoChange}/>
                        </Col>

                        <Col xs={4} sm={4} lg={3} md={4} className="align-cols">
                            <Label name="ART No."/>
                            <TextField autocomplete="off" type="number" value={art_no} name="art_no" onChange={handleWherePreArtNoChange}/>
                        </Col>
                    </Form.Group>

                </Col>

                <Col md={1}>
                    <div style={{borderLeft: '3px solid #027a5f',  borderRadius:'1px', height: '100%', position: 'relative', left: '50%', marginLeft: '-3px', top: '0' }}></div>
                </Col>
                
                <Col md={5}>
                    <h6 className="antenatal-form">{`TB Status & eMTCT Risk Assessment`}</h6>
                    <hr class="rounded"/>
                    <Form.Group as={Row} className="surgery-schedule-flex">
                        <Col xs={6} sm={6} lg={5} md={6} className="align-cols">
                            <Label name="TB Status"/>
                            <CustomSelect options={tb_codes} value={tb_status} onChange={handleTBStatusChange}/>
                        </Col>

                        <Col xs={6} sm={6} lg={5} md={6} className="align-cols">
                            <Label name="eMTCT Risk Assessment"/>
                            <CustomSelect options={risk_assessments} value={risk_assess} onChange={handleRiskAssessment}/>
                        </Col>
                    </Form.Group>

                    <h6 className="antenatal-form">eMTCT Status / Woman</h6>
                    <hr class="rounded"/>
                    <Form.Group as={Row} className="surgery-schedule-flex">
                        <Col xs={6} sm={6} lg={5} md={6} className="align-cols">
                            <Label name="Initial Result"/>
                            <CustomSelect options={emtct_codes} value={womanInitialResult} onChange={handleInitialResultChange}/>
                        </Col>

                        <Col xs={6} sm={6} lg={5} md={6} className="align-cols">
                            <Label name="STK Given?"/>
                            <CustomSelect options={yes_nos} value={stk_given} onChange={handleSTKGiven}/>
                        </Col>
                    </Form.Group>

                    <h6 className="antenatal-form">eMTCT Status / Partner</h6>
                    <hr class="rounded"/>
                    <Form.Group as={Row} className="surgery-schedule-flex">
                        <Col xs={6} sm={6} lg={5} md={6} className="align-cols">
                            <Label name="Age"/>
                            <TextField autocomplete="off" value={partner_age} name="partner_age" type="number" onChange={handleSetPartnerEMTCT}/>
                        </Col>
                        
                        <Col xs={6} sm={6} lg={5} md={6} className="align-cols">
                            <Label name="STK Result"/>
                            <CustomSelect value={partner_stk_result} onChange={handlePartnerSTKResult} options={emtct_codes}/>
                        </Col>
                    </Form.Group>

                    <h6 className="antenatal-form">{`CD4, VL & WHO Clinical Stage`}</h6>
                    <hr class="rounded"/>
                    <Form.Group as={Row} className="surgery-schedule-flex">
                        <Col xs={4} sm={4} lg={3} md={4} className="align-cols">
                            <FormLabel name="CD4 (cells/uL or Cells/mm3)"/>
                            <TextField autocomplete="off" value={cd4} name="cd4" onChange={handleCD4VLChanges}/>
                        </Col>

                        <Col xs={4} sm={4} lg={3} md={4} className="align-cols">
                            <Label name="Viral Load (copies/mml)"/>
                            <TextField autocomplete="off" value={viral_load} name="viral_load" onChange={handleCD4VLChanges}/>
                        </Col>

                        <Col xs={4} sm={4} lg={3} md={4} className="align-cols">
                            <Label name="WHO Clinical Stage"/>
                            <CustomSelect options={clinical_stages} value={who_clinical_stage} onChange={handleClinicalStagesChange}/>
                        </Col>
                    </Form.Group>

                    <h6 className="antenatal-form">Blood Screening</h6>
                    <hr class="rounded"/>
                    <Form.Group as={Row} className="surgery-schedule-flex">
                        <Col xs={3} sm={3} lg={3} md={3} className="align-cols">
                            <Label name="Blood Group"/>
                            <CustomSelect options={blood_groups} value={blood_group} onChange={handleBloodGroup}/>
                        </Col>

                        <Col xs={3} sm={3} lg={3} md={3} className="align-cols">
                            <Label name="Hb"/>
                            <TextField autocomplete="off" value={hb} onChange={handleHBSickleCellChange} name="hb"/>
                        </Col>

                        <Col xs={3} sm={3} lg={3} md={3} className="align-cols">
                            <Label name="Sickle Cell"/>
                            <CustomSelect options={sickles} value={sickle_cell} onChange={handleHBSickleCellChange} name="sickle_cell"/>
                        </Col>
                    </Form.Group>

                    <h6 className="antenatal-form">{`Test Results`}</h6>
                    <hr class="rounded"/>
                    <Form.Group as={Row} className="surgery-schedule-flex">
                    <Col xs={4} sm={4} lg={3} md={4} className="align-cols-center">
                        <h5 className="antenatal-form-h5">Woman Status:</h5>
                    </Col>
                        <Col xs={4} sm={4} lg={4} md={4} className="align-cols">
                            <Label name="Syphilis"/>
                            <CustomSelect options={syphilis_types} onChange={handleSyphilisHEBWomanChange} value={syhpilis_woman}/>
                        </Col>
                        <Col xs={4} sm={4} lg={4} md={4} className="align-cols">
                            <Label name="Hep B"/>
                            <TextField autocomplete="off" value={heb_woman} onChange={handleSyphilisHEBWomanChange} name="heb_woman"/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="surgery-schedule-flex">
                        <Col xs={4} sm={4} lg={3} md={4} className="align-cols-center"/>
                        <Col xs={4} sm={4} lg={4} md={4} className="align-cols">
                            <Label name="Random Blood Sugar:"/>
                            <TextField autocomplete="off" name="random_blood_sugar" value={random_blood_sugar} onChange={handleRoutines}/>
                        </Col>
                        <Col xs={4} sm={4} lg={4} md={4} className="align-cols">
                            <Label name="BS for MPs / RDT for Malaria:"/>
                            <TextField autocomplete="off" name="bs_for_mps" value={bs_for_mps} onChange={handleRoutines}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="surgery-schedule-flex">
                    <Col xs={4} sm={4} lg={3} md={4} className="align-cols-center">
                        <h5 className="antenatal-form-h5">Partner Status:</h5>
                        </Col>
                        <Col xs={4} sm={4} lg={4} md={4} className="align-cols">
                            <Label name="Syphilis"/>
                            <CustomSelect options={syphilis_types} onChange={handleSyphilisHEBPartnerChange} value={syhpilis_partner}/>
                        </Col>
                        <Col xs={4} sm={4} lg={4} md={4} className="align-cols">
                            <Label name="Hep B"/>
                            <TextField autocomplete="off" value={heb_partner} onChange={handleSyphilisHEBPartnerChange} name="heb_partner"/>
                        </Col>
                    </Form.Group>
            
                    <h6 className="antenatal-form">{`Treatments/Interventions`}</h6>
                    <hr class="rounded"/>
                    <Form.Group as={Row} className="surgery-schedule-flex">
                        <Col xs={6} sm={6} lg={5} md={6} className="align-cols">
                            <Label name="Tetanus Diphtheria (Td)"/>
                            <CustomSelect options={tetanus_doses} value={tetanus_dose} onChange={handleTetanusDoses}/>
                        </Col>

                        <Col xs={6} sm={6} lg={5} md={6} className="align-cols">
                            <FormLabel name="IPT/CTX" toolTip={"Intermittent Preventive Treatment"}/>
                            <CustomSelect options={ipt_doses} value={ipt_dose} onChange={handleIPTDose}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="surgery-schedule-flex">
                        <Col xs={6} sm={6} lg={5} md={6} className="align-cols">
                            <FormLabel name="LLIN" toolTip={"Long Lasting insecticide treated nets"}/>
                            <CustomSelect options={yes_nos} value={llin} onChange={handleLLINChange}/>
                        </Col>

                        <Col xs={6} sm={6} lg={5} md={6} className="align-cols">
                            <Label name="Mabendazole"/>
                            <CustomSelect options={mab_options} value={mabendazole} onChange={handleMabendazoleChange}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="surgery-schedule-flex">
                        <Col xs={6} sm={6} lg={5} md={6} className="align-cols">
                            <FormLabel name="LLIN Date" toolTip={"Date given Long Lasting insecticide treated nets"}/>
                            <TextField autocomplete="off" type="date" value={routine_date} onChange={handleRoutineDate}/>
                        </Col>

                        <Col xs={6} sm={6} lg={5} md={6} className="align-cols">
                            <Label name="EMTCT Code:"/>
                            <CustomSelect options={emtct_codes} value={emtct} onChange={handleEMTCTCodes}/>
                        </Col>
                    </Form.Group>

                    <h6 className="antenatal-form">{`Counselling`}</h6>
                    <hr class="rounded"/>
                    <Form.Group as={Row} className="surgery-schedule-flex">
                        <Col xs={6} sm={6} lg={5} md={6} className="align-cols">
                            <FormLabel name="IYCF Option" toolTip={`Infant & Young Child Feeding`}/>
                            <CustomSelect options={infant_feeding_options} onChange={handleInfantFeeding} value={infant_feeding}/>
                        </Col>

                        <Col xs={6} sm={6} lg={5} md={6} className="align-cols">
                            <Label name="Maternal Nutrition Counselling Done?"/>
                            <CustomSelect options={yes_nos} value={maternal_nutrition} onChange={handleMaternalNutritionChange}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="surgery-schedule-flex">
                        <Col xs={6} sm={6} lg={5} md={6} className="align-cols">
                            <Label name="Family Planning"/>
                            <CustomSelect options={familyplannings} onChange={handleFamilyPlanningChange} value={family_plan}/>
                        </Col>

                        <Col xs={6} sm={6} lg={5} md={6} className="align-cols">
                            <Label name="GBV Risk"/>
                            <CustomSelect options={gbv_risks} value={gbv_risk} onChange={handleGBVRisks}/>
                        </Col>
                    </Form.Group>

                </Col>
            </Row>
        </div>
    )
}
export default AnteNatalSecondScreen;
