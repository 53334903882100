import React, {useEffect, useState} from "react";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CustomTable from "../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import {getComparator, stableSort} from "../Utils/Tables/sortingFunctions";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import * as snackbarActions from "../../actions/snackbarActions"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import PageTitle from "../Utils/smsTitle";
import DeleteDialog from "../Utils/Dialogs/DeleteDialog";
import {faBed} from "@fortawesome/free-solid-svg-icons/faBed";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import AddBedForm from "./AddBedForm";
import CustomDialog  from "../Utils/Dialogs/CustomDialog";
import {useAddBed} from "./CustomHooks/useAddBed";
import {titleCase} from "../Users/addUser";
import axios from "axios";
import {config} from "../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../Helpers/ErrorMessages";
import {LoadingGif} from "../Utils/Loader";

const headData = [{id: 'sl', numeric: false, disablePadding: false, label: 'SL'},
    {id: 'ward', numeric: false, disablePadding: false, label: 'Ward'},
    {id: 'roomName', numeric: false, disablePadding: false, label: 'Room Name'},
    {id: 'bed', numeric: false, disablePadding: false, label: 'Bed Number'},
    {id: 'description', numeric: false, disablePadding: false, label: 'Description'},
    {id: 'status', numeric: false, disablePadding: false, label: 'status'},
    {id: 'actions', numeric: false, disablePadding: true, label: 'Action'},
];

export const BedList = (props) => {
    const [searchValue, setSearchValue] = useState('');
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('sl');
    const [bed_id, setBedId] = useState('');
    const [openDelete, setDelete] = useState(false);
    const [bedList, setBedList] = useState([]);
    const [loading, setLoading] = useState('idle');
    const [activePage, setActivePage] = useState(1);
    const [bed_count, setCount] = useState(0);

    const {actions} = props;
    const {bedProps, isResolved,} = useAddBed(actions)

    useEffect(() => {
        setLoading('loading');
        const formData = new FormData();
        const search = searchValue ? titleCase(searchValue) :searchValue
        formData.append('search',search );
        axios.post(`${config.smsUrl}/cbedmanager/bed_list/${activePage}`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const list = !dt.bed_list ? [] : dt.bed_list;
            const count = !dt.total_count ? 0 : dt.total_count;
            setLoading('success');
            setCount(count);
            setBedList(list)
        }).catch(e => {
            logoutErrorMessage(e, null, actions);
            setLoading('error')
        })
    }, [activePage, searchValue,isResolved, isResolved]);

    const handleSearch = (event) => {
        setSearchValue(event.target.value);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangeNewPage = (page) => {
        setActivePage(page);
    };

    // const handleOpenDelete = (id) => {
    //     setDelete(true);
    //     setBedId(id)
    // };

    const handleCloseDelete = () => {
        setDelete(false)
    };

    const handleDelete = () => {
        const formData = new FormData();
        formData.append('bed_id', bed_id);
        axios.post(`${config.smsUrl}/cbedmanager/delete_bed`, formData).then(() => {
            const wds = bedList.filter(wd => wd.bed_id !== bed_id);
            setBedList(wds);
            setDelete(false);
            actions.snackbarActions.snackSuccess('Bed deleted successfully');
        }).catch(e => {
            errorMessages(e, null, actions);
            setDelete(false)
        });
    };

    const closeSnackBar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const {openBar, type, message} = props.snackbars;
    const perm = JSON.parse(sessionStorage.getItem('permission'));
    const permission = !perm ? {} :perm;
    const add_bed = !permission.add_bed ? {} : permission.add_bed;
    const bed_list = !permission.bed_list ? {} : permission.bed_list;
    const beds = !bedList ? [] : bedList;

    const {open,openEditDialog, handleOpenEditDialog, handleOpenDialogForm, handleCloseEditDialog, handleCloseDialogForm} = bedProps

    const isLoading = loading === 'loading'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'

    return (
        <div data-testid = "bed-list" className='journals'>
            <PageTitle title="Bed List"/>
            <SubHeader title="Bed Management" subTitle="Bed list">
                <FontAwesomeIcon icon={faBed}/>
            </SubHeader>
            <div className='general-ledger-header'>
                <div className="text-right">
                    {(add_bed.create || add_bed.read || add_bed.update || add_bed.delete) && 
                       <button className="btn sms-btn btn-sm" onClick={handleOpenDialogForm}><FontAwesomeIcon icon={faPlus}/>Add Bed</button>
                    }
                    <CustomDialog open={open} handleClose={handleCloseDialogForm} title="Add Bed" maxWidth="md">
                        <AddBedForm {...bedProps}/>
                    </CustomDialog>
                </div>

                <CustomDialog open={openEditDialog}
                handleClose={handleCloseEditDialog} title="Edit Bed" maxWidth="md">
                        <AddBedForm {...bedProps}/>
                </CustomDialog>
            </div>
            <div className="mui-tables">
            <DeleteDialog message="delete" openDialog={openDelete} handleClose={handleCloseDelete}
                              text="bed" title='Delete Bed'>
                <button className=' btn sms-btn-dismiss btn-sm' onClick={handleDelete}>Delete</button>
                <button className="btn sms-grey-btn btn-sm" onClick={handleCloseDelete}>No</button>    
            </DeleteDialog>
                <MainSnackbar variant={type} message={message} open={openBar} handleCloseBar={closeSnackBar}/>
                <CustomTable title="Bed List" headData={headData} handler={handleSearch} term={searchValue}
                             handleRequestSort={handleRequestSort} data={beds} colSpan={5} order={order}
                             orderBy={orderBy} customPage records={10} activePage={activePage}
                             handleChangeNextPage={handleChangeNewPage} total_count={bed_count}>
                        <TableBody>
                            {isLoading ? <TableRow>
                                    <TableCell colSpan={headData.length} align="center"><LoadingGif/></TableCell>
                                </TableRow> : null}
                            {isSuccess ? beds.length > 0 ?  stableSort(beds, getComparator(order, orderBy))
                                .slice(0, 10)
                                .map((item, index) => {
                                    const count  = ((activePage - 1) * 10) + index + 1;
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={item.bed_id}
                                            className='mui-table-row'>
                                            <TableCell>{count}</TableCell>
                                            <TableCell>{item.ward_name}</TableCell>
                                            <TableCell>{item.room_name}</TableCell>
                                            <TableCell>{item.bed_number}</TableCell>
                                            <TableCell>{item.description}</TableCell>
                                            <TableCell>{item.status === 1 ? 'Occupied':'Vacant'}</TableCell>
                                            <TableCell>
                                                {bed_list.update?
                                                    <button className="btn btn-sm sms-info-btn mr-1" onClick={()=> handleOpenEditDialog(item.bed_id)}>Edit</button>
                                                :<div></div>}
                                                    
                                                {/*{bed_list.delete && <button onClick={() => handleOpenDelete(item.bed_id)}*/}
                                                {/*        className="btn btn-sm sms-btn-dismiss">Delete*/}
                                                {/*</button>}*/}
                                            </TableCell>
                                        </TableRow>
                                    );
                                }): <TableRow>
                                <TableCell colSpan={headData.length} align="center">No records found</TableCell>
                            </TableRow>: null}
                            {isError ? <TableRow>
                                <TableCell colSpan={headData.length} align="center">The server did not find a valid response</TableCell>
                            </TableRow> : null}
                        </TableBody>
                </CustomTable>
            </div>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BedList);
