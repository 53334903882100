/** @jsxImportSource @emotion/react */
import {jsx, css} from '@emotion/react'
import React, {Fragment} from 'react'
import {table} from "../../../styles/tableStyles";
import * as colors from "../../../styles/colors";
import styled from "@emotion/styled/macro";



const tableBordered = css`
    td,th{
        border:1px solid ${colors.text00};
        padding:0.3rem;
    }
`


const tablePadding = css`
    td,th{
        padding:0.3rem;
    }
`

const coloredRow = css`
      background-color: ${colors.gray500};
`

const fontWeight = css`
    font-weight: 700;
`

const heading = css`
    font-size: 16px;
`

const P = styled.p({
    margin:0
})

const SmallP = styled(P)({
    fontSize:'13.5px'
})

const AssetDetails = ({state={}}) =>{
    const {asset_name, serial_number, model_number,product_condition,warranty, manufacturer} = state
    return (
        <Fragment>
            <tr css={[coloredRow]}>
                <td css={[fontWeight,heading]} colSpan={2}>Asset Details</td>
            </tr>
            <tr>
                <td colSpan={2}>
                    <P css={[fontWeight]}>Asset Name</P>
                    <SmallP>{asset_name}</SmallP>
                </td>
            </tr>
            <tr>
                <td>
                    <P css={[fontWeight]}>Serial Number</P>
                    <SmallP>{serial_number ? serial_number : 'N/A'}</SmallP>
                </td>
                <td>
                    <P css={[fontWeight]}>Model Number</P>
                    <SmallP>{model_number ? model_number : 'N/A'}</SmallP>
                </td>
            </tr>
            <tr>
                <td>
                    <P css={[fontWeight]}>Product Condition</P>
                    <SmallP>{product_condition ? product_condition : 'N/A'}</SmallP>
                </td>
                <td>
                    <P css={[fontWeight]}>Warranty</P>
                    <SmallP>{warranty ? warranty : 'N/A'}</SmallP>
                </td>
            </tr>
            <tr>
                <td colSpan={2}>
                    <P css={[fontWeight]}>Manufacturer</P>
                    <SmallP>{manufacturer ?  manufacturer : 'N/A'}</SmallP>
                </td>
            </tr>
        </Fragment>
    )
}

const AssetAccountingDetails = ({state={},assetValue={}}) =>{
    const {date, isCash, asset_cost,  depreciation_rate, invoice_no} = state
    const {assetAccount,  paymentAccount, supplier,depreciationExpense, depreciationAccount} = assetValue
    return (
        <Fragment>
            <tr css={[coloredRow]}>
                <td css={[fontWeight,heading]} colSpan={2}>Accounting Details</td>
            </tr>
            <tr>
                <td colSpan={2}>
                    <P css={[fontWeight]}>Purchase date</P>
                    <SmallP>{date}</SmallP>
                </td>
            </tr>
            <tr>
                <td>
                    <P css={[fontWeight]}>Supplier</P>
                    <SmallP>{supplier?.label}</SmallP>
                </td>
                <td>
                    <P css={[fontWeight]}>Asset cost</P>
                    <SmallP>{asset_cost}</SmallP>
                </td>
            </tr>
            <tr>
                <td colSpan={2}>
                    <P css={[fontWeight]}>Invoice number</P>
                    <SmallP>{invoice_no ? invoice_no : 'N/A'}</SmallP>
                </td>
            </tr>
            <tr>
                <td colSpan={2}>
                    <P css={[fontWeight]}>Asset account</P>
                    <SmallP>{assetAccount?.label}</SmallP>
                </td>
            </tr>
            <tr>
                <td colSpan={2}>
                    <P css={[fontWeight]}>Depreciation rate</P>
                    <SmallP>{depreciation_rate}</SmallP>
                </td>
            </tr>
            <tr>
                <td>
                    <P css={[fontWeight]}>Depreciation expense ledger</P>
                    <SmallP>{depreciationExpense?.label}</SmallP>
                </td>
                <td>
                    <P css={[fontWeight]}>Accumulated depreciation ledger</P>
                    <SmallP>{depreciationAccount?.label}</SmallP>
                </td>
            </tr>
            {isCash ? <tr>
                <td colSpan={2}>
                    <P css={[fontWeight]}>Payment Account</P>
                    <SmallP>{paymentAccount?.label}</SmallP>
                </td>
            </tr>: null}

        </Fragment>
    )
}

function AssetDetailsTemplate({children}){
   return (
       <table css={[table,tablePadding,tableBordered]}>
           <tbody>
            {children}
           </tbody>
       </table>
   )
}
export {AssetDetailsTemplate, AssetAccountingDetails, AssetDetails}