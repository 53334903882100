import {v4 as uuidv4} from "uuid";
import React, {useEffect, useState} from "react";
import {dateConvert} from "../../Utils/ConvertDate";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {AccountingDrawer} from "../../Utils/AccountingUtils/AccountingDrawer";
import NewLedgerEntry from "./NewLedgerEntry";
import {BlockNavigationComponent} from "../../Utils/ReusableComponents/BlockNavigationComponent";
import {history} from "../../../Helpers/history";
import {useCurrency} from "../../../Context/currency-context";
import {useVersionsContext} from "../../../Context/versions-context";


export default function LedgerEntry({actions, isSubmitLedger, setIsSubmitLedger}) {
    const {currency:selectedCurrency} = useCurrency({actions, isSubmitLedger, setIsSubmitLedger})
    const initialState = [{
        id: uuidv4(),
        account_name: '',
        account_id: '',
        entry_type: '',
        debit: '',
        credit: ''
    },
        {id: uuidv4(), account_name: '', account_id: '', entry_type: '', debit: '', credit: ''}]
    const [state, setState] = useState({date: dateConvert(), notes: ''});
    const [accounts, setAccounts] = useState([]);
    const [ledger, setLedger] = useState(initialState);
    const [total, setTotal] = useState({debit_total: 0, credit_total: 0})
    const [submitted, setSubmitted] = useState(false);
    const [open, setOpen] = useState(false)
    const [isBlocking, setIsBlocking] = useState(false)
    const [isBlockDialog, setIsBlockDialog] = useState(false)
    const [currencies, setCurrencies] = useState([])
    const [currency, setCurrency] = useState('')
    const [exchangeRate, setExchangeRate] = useState(0)
    const [currencyExchangeRate, setCurrencyExchangeRate] = useState(0)

    const {subscription} = useVersionsContext()
    const version = subscription?.version
    const isPremium = version === 'Premium'



    useEffect(()=>{
        if (isPremium){
            return
        }
        setCurrency(`${selectedCurrency.id}-${selectedCurrency.is_base_currency}-${selectedCurrency.currency_symbol}`)
    },[version])



    useEffect(() => {
        axios.get(`${config.smsUrl}/accounting/chart/journals`).then(res => {
            const data = res.data;
            const dt = data ? data : {};
            const arr = dt.data ? dt.data : []
            const list = arr.map(item => ({
                value: item.id,
                label: item.head_name
            }))
            setAccounts(list)
        }).catch(err => {
            errorMessages(err, null, actions)
        })
    }, [])

    const handleChange = (event) => {
        const {value, name} = event.target;
        setState({...state, [name]: value})
        setIsBlocking(true)
    }

    const handleChangeAccount = (val, id) => {
        const arr = ledger.map(item => {
            if (item.id === id) {
                return {...item, account_id: val}
            }
            return item
        })
        setLedger(arr)
        setIsBlocking(true)
    }
    const calculateTotal = (arr) => {
        const debit_total = arr.reduce((sum, item) => {
            return +sum + +item.debit;
        }, 0)
        const credit_total = arr.reduce((sum, item) => {
            return +sum + +item.credit;
        }, 0)
        setTotal({debit_total, credit_total})
    }

    const handleBlurDebit = (id) => {
        const arr = ledger.map(item => {
            if (item.id === id) {
                if (item.credit !== '' && item.debit) {
                    return {...item, credit: '', entry_type: 'DEBIT'}
                } else {
                    return item;
                }
            }
            return item
        })
        setLedger(arr);
        calculateTotal(arr)
        setIsBlocking(true)
    }
    const handleBlurCredit = (id) => {
        const arr = ledger.map(item => {
            if (item.id === id) {
                if (item.debit !== '' && item.credit) {
                    return {...item, debit: '', entry_type: 'CREDIT'}
                } else {
                    return item;
                }
            }
            return item
        })
        setLedger(arr);
        calculateTotal(arr)
        setIsBlocking(true)
    }

    const handleChangeItems = (event, id) => {
        const {value, name} = event.target;
        const arr = ledger.map(item => {
            if (item.id === id) {
                return {...item, [name]: value, entry_type: name === 'debit' ? 'DEBIT' : name === 'credit' ? 'CREDIT':''}
            }
            return item
        })
        setLedger(arr)
        setIsBlocking(true)
    }

    const addItem = () => {
        const row = {
            id: uuidv4(),
            account_name: '',
            account_id: '',
            description: '',
            entry_type: '',
            debit: '',
            credit: ''
        }
        setLedger([...ledger, row])
    }

    const removeRow = (id) => {
        const arr = ledger.filter(item => item.id !== id)
        setLedger(arr)
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        const {date, notes} = state
        const currency_id = currency.split('-')[0]
        const {debit_total, credit_total} = total
        const payload = {
            date_added: date,
            memo: notes,
            currency_rate:+exchangeRate,
            currency_id: +currency_id,
            entries: ledger.filter(item => item.account_id).map(item => ({
                entry_type: item.entry_type,
                account_id: item.account_id,
                credit_ledger: item.entry_type === 'CREDIT' ? item.account_id : '',
                debit_ledger: item.entry_type === 'DEBIT' ? item.account_id : '',
                entry_category: item.entry_type,
                amount: item.debit ? item.debit : item.credit ? item.credit : 0,
                
            }))
        };
        const arr = ledger.every(item => item.account_id)
        console.log(arr)
        setIsBlocking(false)
        if (arr && date && (debit_total == credit_total)) {
            setIsSubmitLedger('pending');
            axios.post(`${config.smsUrl}/accounting/journals/entry`, payload).then(() => {
                actions.snackbarActions.snackSuccess('Entry made successfully')
                setOpen(false)
                setIsSubmitLedger('resolved')
                const arr = ledger.map(item=>({
                    ...item,
                    account_name: '',
                    account_id: '',
                    description: '',
                    entry_type: '',
                    debit: '',
                    credit: ''
                }))
                setSubmitted(false)
                setLedger(arr)
            }).catch(err => {
                errorMessages(err, null, actions)
                setIsSubmitLedger('rejected')
            })
        }
    }

    const handleResetForm = () =>{
        setOpen(false)
        setIsBlockDialog(false)
        setIsBlocking(false)
        const arr = ledger.map(item=>({
            ...item,
            account_name: '',
            account_id: '',
            description: '',
            entry_type: '',
            debit: '',
            credit: ''
        }))
        setLedger(arr)
    }

    const handleOpenDialog = () =>{
        setOpen(true)
    }

    const handleCloseDialog = () =>{
        if(isBlocking){
            setIsBlockDialog(true)
        }else{
            setOpen(false)
            setIsBlockDialog(false)
            setIsBlocking(false)
        }
    }

    // get currencies
    useEffect(() => {
        axios.get(`${config.smsUrl}/accounting/currencies`).then(res => {
            const data = res.data;
            const all_data = !data ? {}: data;
            const arr = all_data.data ?? [];
            const list = arr.map(item=>({value:`${item.id}-${item.is_base_currency}-${item.currency_symbol}`, text:`${item.currency_symbol} ${item.currency_name}`}))
            setCurrencies(list)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, []);

    // const calculateAmount = (rate) =>{
    //     const sumArr = ledger.map(item=>({
    //         ...item, usd_amount:+item.amount * +rate
    //     }))
    //     setExpenses(sumArr)
    // }

    //Change currency
    const handleChangeCurrency = (event) =>{
        setCurrency(event.target.value)
        setIsBlocking(true)
    }

    //Change exchange rate
    const handleChangeExchangeRate = (event) =>{
        const ex_rate = +event.target.value > 0 ? 1 / +event.target.value : 0
        setExchangeRate(ex_rate)
        setCurrencyExchangeRate(event.target.value)
        // calculateAmount(ex_rate)
        setIsBlocking(true)
    }

    const isBase = currency.split('-')[1]
    const currency_symbol = currency.split('-')[2]
    const isNotBase = isBase === 'false'

    const isPending = isSubmitLedger === 'pending'

    const ledgerProps = {
        state, accounts, ledger, handleChange, handleChangeAccount, handleChangeItems, removeRow,
        addItem, total, handleBlurCredit, handleBlurDebit, handleSubmit, isPending, submitted,
        isBlocking, isBlockDialog,setIsBlockDialog, setIsBlocking, open, setOpen, handleResetForm,
        currency_symbol, isNotBase, currencies, currency, base_currency_symbol:selectedCurrency?.currency_symbol,
        handleChangeCurrency, handleChangeExchangeRate, exchangeRate, currencyExchangeRate,
        isPremium
    }


 
    return (
        <div>
            <BlockNavigationComponent
                when={isBlocking}
                shouldBlockNavigation={()=>{
                    return isBlocking;
                }}
                navigate={path=>history.push(path)}
                dialogClose={isBlockDialog}
                setIsBlockDialog={setIsBlockDialog}
                clearForm={handleResetForm}
            />
            <div className="text-right">
                <button onClick={handleOpenDialog} className="btn btn-sm sms-info-btn">New Entry</button>
            </div>
            <AccountingDrawer open={open} title='New Ledger Entry' handleClose={handleCloseDialog}>
                <NewLedgerEntry {...ledgerProps}/>
            </AccountingDrawer>
        </div>
    )
}