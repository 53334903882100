import {useEffect, useState} from "react";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {useCommonState} from "./useReusableFunctions";
import moment from "moment";

export const useLabRadiologyDetailedReport = (actions, urls,match,component) => {
    const {hospital_test_id, name,hospital_scan_id} = match
    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState({});
    const [options, setOptions] = useState([]);
    const [total_count, setCount] = useState(0);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [loadingOptions, setLoadingOptions] = useState('idle');
    const [optionValue, setOptionValue] = useState('')
    const [id, setId] = useState(hospital_test_id ? hospital_test_id : hospital_scan_id);
    const [title, setTitle] = useState(name)
    const {closeSnackbar, search_type, text, textType, year, date, handleChangeSearch,
        handleChangeDate, handleChangeYear, handleIncrementYear, inputType,
        customSearch, order, orderBy, activePage, handleChangeActivePage, handleCustomSearch,
        handleSearch, handleRequestSort, handleChangePage} = useCommonState(actions)
    const {table_url, options_url} = urls;
    const {start_date, end_date} = date;
    const id_name = hospital_test_id ? 'hospital_test_id' : 'hospital_scan_id'


    useEffect(() => {
        setLoadingOptions('pending')
        axios.get(`${config.smsUrl}/${options_url}`).then(res => {
            const data = res.data;
            if (component === 'lab'){
                const dt = data ?? {};
                const dtArr = dt.lab_testlist || dt.Qualitative
                const arr = dtArr ?? [];
                const opts = arr.map(item=>({
                    value:item.hospital_test_id,
                    label:item.hospital_test_name
                }))
                setOptions(opts)
            }else{
                const dt = data ?? []
                const opts = dt.map(item=>({
                    value:item.hospital_scan_id,
                    label:item.hospital_scan_name
                }))
                setOptions(opts)
            }
            setLoadingOptions('resolved')
        }).catch((err) => {
            logoutErrorMessage(err, null, actions);
            setLoadingOptions('rejected')
        })
    }, [options_url, actions, component])


    useEffect(() => {
        setLoading(true);
        axios.post(`${config.smsUrl}/creport/${table_url}`,null, {params:{
            start_date: start_date,
            end_date: end_date,
            date_type: 3,
            page:activePage,
            [id_name]: id,
        }}).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const count = !dt.total_count ? 0 : dt.total_count;
            setTableData(dt)
            setCount(count)
            setLoading(false);
        }).catch((err) => {
            logoutErrorMessage(err, null, actions);
            setLoading(false);
        })
    }, [activePage, id, table_url]);

    const handleChangeOption = (value) =>{
        setOptionValue(value)
        setTitle(value.label)
        setId(value.value)
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setSubmitted(true)
        if (start_date && end_date) {
            setIsSubmitted(true)
            setLoading(true)
            axios.post(`${config.smsUrl}/creport/${table_url}`, null, {params:{
                start_date: start_date,
                end_date: end_date,
                date_type: 3,
                page:activePage,
                [id_name]: id,
            }}).then(res => {
                const data = res.data;
                const dt = !data ? {} : data;
                const count = !dt.total_count ? 0 : dt.total_count;
                setTableData(dt)
                setCount(count)
                setLoading(false);
                setIsSubmitted(false)
            }).catch(err => {
                setIsSubmitted(false)
                setLoading(false)
                errorMessages(err, null, actions);
            })
        }
    }
    const covertTimeTaken = (date) =>{
        return moment(date).format('Do, MMMM YYYY')        
    }

    return {tableData, options,loadingOptions, search_type, text, textType, year, date, handleChangeActivePage,
        handleCustomSearch, isSubmitted, submitted, customSearch, order, orderBy, loading, total_count,
        activePage, closeSnackbar, handleSearch, handleChangePage, handleRequestSort, inputType,
        handleChangeSearch, handleChangeDate, handleChangeYear, handleIncrementYear, handleSubmit,
        optionValue,handleChangeOption, title,covertTimeTaken}
}