/** @jsxImportSource @emotion/react */
import React, {useEffect, useState} from "react";
import {CenteredContainer, RightAlignedContainer} from "../Utils/styledComponents"
import axios from "axios";
import { config } from "../../Helpers/env";
import {logoutErrorMessage} from "../../Helpers/ErrorMessages";
import {usePrint} from "../Utils/Templates/usePrint";
import CustomDialog from "../Utils/Dialogs/CustomDialog";
import DentalExaminationReport from "./DentalExaminationReport";
import {DentalServiceResults, heading} from "./DentalServiceReport";
import {textWeight} from "../../styles/tableStyles";
import {LoadingGif} from "../Utils/Loader";

const DentalVisitDetails = ({actions, visitInfo, openPrint,setOpenPrint}) => {
    const [visitDetails, setVisitDetails] = useState({
        examination: {},
        dental_services: [],
    });

    const [loading, setLoading] = useState('idle')



    useEffect(() => {
      if (!visitInfo.visit_id){
          return
      }
        const formData = new FormData();
        formData.append("visit_id", visitInfo.visit_id);
        setLoading('loading')
        axios.post(
            `${config.smsUrl}/cdoctor/get_all_patient_treatment_details`,
            formData
        )
            .then((res) => {
                const data = res.data;
                const details = !data ? {} : data;
                const dental = details.dental_results ? details.dental_results : [];
                const exam_notes = details.exam_notes ? details.exam_notes : []
                const notes_obj = exam_notes[0] ? exam_notes[0] : {}
                const dental_exam = details.dental_exam ? details.dental_exam : {}
                const diagnosis = details.diagnosis ? details.diagnosis : {}
                const medication = details.prescription ? details.prescription : {}

                setVisitDetails({
                    ...visitDetails,
                    dental_services: dental,
                    examination: {...notes_obj,extraOral:dental_exam.extra_oral,
                        softIntraOral:dental_exam.intra_oral_soft_tissue,
                        hardIntraOral:dental_exam.intra_oral_hard_tissue, ...dental_exam,
                        diagnosis, medication},
                    visit_date: visitInfo.begin_datetime,
                    visit_type: visitInfo.patient_type,
                    visit_id: visitInfo.visit_id,
                });
                setLoading('success')
            }).catch((err) => {
                logoutErrorMessage(err, null, actions);
                setLoading('error')
            });
    }, [visitInfo.visit_id])

    const handleClosePrint = () => {
        setOpenPrint(false);
    };


    const {dental_services, examination} = visitDetails

    const isLoading = loading === 'loading'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'

    const {componentRef, handlePrint,  fontSize}  = usePrint('Medical History Report');

    return (

            <CustomDialog open={openPrint} handleClose={handleClosePrint} title='Print Preview' maxWidth='lg'
                          isContentOverflow={true} isPaperStyle={true}>
                {isLoading ?
                    <CenteredContainer>
                        <LoadingGif/>
                    </CenteredContainer>
                    : null}
                {isSuccess ? <>
                    <RightAlignedContainer>
                        <button type='button' className='btn btn-sm small-btn sms-amber-btn' onClick={handlePrint}>Print</button>
                    </RightAlignedContainer>
                    <div ref={componentRef}>
                        {fontSize}
                        <DentalExaminationReport {...{patient_number:visitInfo?.patient_number, ...examination}}>
                            <DentalServiceResults {...{dental_services,
                                title: dental_services.length > 0 ? (
                                    <thead>
                                    <tr>
                                        <td><span css={[heading, textWeight]}>Services performed</span></td>
                                    </tr>
                                    </thead>
                                ):''}}/>
                        </DentalExaminationReport>
                    </div>
                </>: null}
                {isError ? <CenteredContainer>
                    <p>The server did not return a valid response</p>
                </CenteredContainer>: null}

            </CustomDialog>
    )
};



export default DentalVisitDetails;
