import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Label from "../Utils/FormInputs/Label";
import {CustomSelect} from "../Utils/FormInputs/SelectInput";
import { ErrorMessage } from "../Utils/styledComponents";
import {CustomCheckInput, CustomInput, CustomResizableTextarea} from "../Inventory/CustomInput";


function AddRoomForm({state, submitted, isPending,  handleSubmit, ward_name, handleChangeWard, wards,  isResolved, setIsBlocking}) {

    const {room_name, bed_capacity, charge, description, status} = state;
    return (
        <form autoComplete='off' onSubmit={handleSubmit}>
            <Form.Group as={Row}>
                <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                    <Label name="Ward" type/>
                </Col>
                <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                    <CustomSelect  id="room-ward" {...{submitted}} options={wards} onChange={handleChangeWard} value={ward_name} dataTest="ward"/>
                    {(submitted && !ward_name) &&
                        <ErrorMessage>Ward required</ErrorMessage>}
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                    <Label name="Room Name" type htmlFor='name'/>
                </Col>
                <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                    <CustomInput name='name' isRequired inputValue={room_name} type='text'  setIsBlocking={setIsBlocking}
                                 submitted={submitted} reset={isResolved}/>
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                    <Label name="Bed Capacity" type htmlFor='bed_capacity'/>
                </Col>
                <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                    <CustomInput name='bed_capacity' isRequired inputValue={bed_capacity} type='number' setIsBlocking={setIsBlocking} id='bed_capacity' submitted={submitted} reset={isResolved}/>

                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                    <Label name="Room Cost" type htmlFor='charge'/>
                </Col>
                <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                    <CustomInput name='charge' isRequired inputValue={charge} type='number' id='charge'
                                 setIsBlocking={setIsBlocking}  submitted={submitted} reset={isResolved}/>
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                    <Label name="Description" htmlFor='descriprion'/>
                </Col>
                <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                    <CustomResizableTextarea rows={2} name='descriprion' setIsBlocking={setIsBlocking}  inputValue={description} type='number'   reset={isResolved}/>

                </Col>
            </Form.Group>

            <Form.Group as={Row}>
                <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                    <Label name="Status" type  htmlFor='status'/>
                </Col>
                <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                    <CustomCheckInput inputValue={status} isRequired name='status' type='radio' inline label='Active' value="1" id='1'/>
                    <CustomCheckInput inputValue={status} isRequired name='status' type='radio' inline label='Inactive' value="0" id='1'/>
                </Col>
            </Form.Group>
            <button type="submit" data-testid="submit-room" className="btn btn-sm sms-btn px-4"
                    disabled={isPending}>{isPending ? "Saving..." : "Save"}</button>
        </form>
          
    )
}


export default AddRoomForm;
