import {useEffect, useState} from "react";
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import { dateConvert } from "../../../Utils/ConvertDate";
import { useInvoiceProviders } from "./useInvoiceProviders";

export const useCustomerTransactions = (actions, provider_id) => {
    const [searchValue, setSearchValue] = useState('');
    const [data, setData] = useState({loading:'idle', total_count:0, transactions:[], total_amount:0});
    const [activePage, setActivePage] = useState(1);
    const [state, setState] = useState({start_date:dateConvert(), end_date:dateConvert(), provider:provider_id})
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [last_id, setLastId] = useState(0)
    const {start_date, end_date, provider} = state;
    const {providers:providerList} = useInvoiceProviders(actions)
    const providers = providerList.map(item=>({value:item.provider_id, text:item.provider_name}))
    const providers_dictionary = providers.reduce(
        (providers_dictionary, el) => (providers_dictionary[el.value] = el.text, providers_dictionary),
        {}
    );
    const {loading} = data;


    useEffect(() => {
        setData({...data, loading:'pending'})
        axios.get(`${config.smsUrl}/cbilling/customer_list/transactions`, {params:{start_date, end_date, provider_id:provider, last_id}}).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const arr = dt.data ? dt.data :[]
            const count = !dt.count ? 0 : dt.count;
            const total = dt.total ? dt.total : 0;
            setData({transactions:arr, total_count:count, loading:'success', total_amount:total})
        }).catch((err) => {
            logoutErrorMessage(err, null, actions);
            setData({...data, loading:'error'})
        })

    }, [last_id]);


    const handleSearch = (event) => {
        setSearchValue(event.target.value);
    };


    const handleChangeNextPage = (value) => {
        setActivePage(value)
        axios.get(`${config.smsUrl}/cbilling/customer_list/transactions`, {params:{start_date, end_date, provider_id:provider, last_id}}).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const id = dt.last_id ? dt.last_id :0
            setLastId(id)
        }).catch((err) => {
            logoutErrorMessage(err, null, actions);
        })
    };


    const handleChange = (event) =>{
        const {name, value} = event.target;
        setState({...state,[name]:value})
    }

    const handleSubmit = (event) =>{
        event.preventDefault();
        setSubmitted(true);
        if(start_date && end_date){
            setData({...data, loading:'pending'})
            setIsSubmitted('pending')
            axios.get(`${config.smsUrl}/cbilling/customer_list/transactions`, {params:{start_date, end_date, provider_id:provider, last_id}}).then(res => {
                const data = res.data;
                const dt = !data ? {} : data;
                const arr = dt.data ? dt.data :[]
                const count = !dt.count ? 0 : dt.count;
                const total = dt.total ? dt.total : 0;
                setData({transactions:arr, total_count:count, loading:'success', total_amount:total})
                setIsSubmitted('resolved')
            }).catch((err) => {
                errorMessages(err, null, actions);
                setIsSubmitted('rejected')
                setData({...data, loading:'error'})
                
            })
        }
    }

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const isLoading = loading === 'pending';
    const isSuccess = loading === 'success';
    const isError = loading === 'error';
    
    const isPending = isSubmitted === 'pending';
    const isResolved = isSubmitted === 'resolved';
    const isRejected = isSubmitted === 'rejected';
    return {
        searchValue, activePage, data,handleSearch,  handleChangeNextPage, closeSnackbar, 
        isLoading, isSuccess, isError, state,submitted, providers_dictionary,
        handleChange, handleSubmit, isPending, isRejected, isResolved, providers
    }
}