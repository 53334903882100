import React, {useEffect, useState} from "react"
import Form from "react-bootstrap/Form"
import Label from "../../Utils/FormInputs/Label"
import TextField from "../../Utils/FormInputs/TextField";
import {RightAlignedContainer} from "../../Utils/styledComponents";
import Textarea from "../../Utils/FormInputs/Textarea";
import SelectInput, {CustomSelect} from "../../Utils/FormInputs/SelectInput";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import {dateConvert} from "../../Utils/ConvertDate";
import axios from "axios";
import {config} from "../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {history} from "../../../Helpers/history";
import {makeStyles} from "@material-ui/core/styles";
import {AccountingDrawer} from "../../Utils/AccountingUtils/AccountingDrawer";
import {useCurrency} from "../../../Context/currency-context";


const useStyles = makeStyles((theme) => ({
    drawerWidth:{
        width: 800,
        padding:'16px'
    }
}));


function ReceiveDebtorsPayments({actions, openDialog, setOpenDialog, isSubmitted,setIsSubmitted,providerDetails}){
    const {currency:selectedCurrency} = useCurrency({actions, openDialog, setOpenDialog, isSubmitted,setIsSubmitted,providerDetails})

    const classes = useStyles()
    const [state, setState] = useState({payment_method:'', payment_date:dateConvert()})
    const [isFull, setIsFull] = useState(false)
    const [amountReceived, setAmountReceived] = useState(0)
    const [submitted, setSubmitted] = useState(false)
    const [memo, setMemo] = useState('');
    const [accounts, setAccounts] = useState([])
    const [debit_to, setDebitTo] = useState('')
    const [currencies, setCurrencies] = useState([])
    const [currency, setCurrency] = useState(0)
    const [currencyExchangeRate, setCurrencyExchangeRate] = useState('')
    const [exchangeRate, setExchangeRate] = useState('')
    const [amount, setAmount] = useState({sspAmount:0, usdAmount:0})

    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = _token.user_roles_id ? _token.user_roles_id : '';

    // get currencies
    useEffect(() => {
        axios
            .get(`${config.smsUrl}/accounting/currencies`)
            .then((res) => {
                const data = res.data;
                const all_data = !data ? {} : data;
                const arr = all_data.data ?? [];
                const list = arr.map((item) => ({
                    value: `${item.id}-${item.is_base_currency}-${item.currency_symbol}`,
                    text: `${item.currency_symbol} ${item.currency_name}`,
                }));
                setCurrencies(list);
            })
            .catch((err) => {
                logoutErrorMessage(err, null, actions);
            });
    }, []);


    useEffect(() => {
        axios.get(`${config.smsUrl}/accounting/ledgers_under_category`).then(res => {
            const data = res.data;
            const dt = data ? data : {};
            const arr = dt.data ? dt.data : []
            const list = arr.map(item => ({
                value: item.id,
                label: item.head_name
            }))
            setAccounts(list)
        }).catch(err => {
            errorMessages(err, null, actions)
        })
    }, [])

    useEffect(()=>{
        setAmountReceived('')
        setIsFull(false)
        // setExchangeRate(0)
        // setCurrencyExchangeRate('')
        setDebitTo('')
        setMemo('')
    }, [providerDetails?.visit_id])

    useEffect(() => {
        const exchange_rate = providerDetails.exchange_rate ? providerDetails.exchange_rate : 0;
        const ex_rate = Math.pow(exchange_rate, -1)
        setExchangeRate(exchange_rate)
        setCurrencyExchangeRate(ex_rate)
        setCurrency(`${providerDetails?.currency_id}-${providerDetails?.is_base_currency}-${providerDetails.currency_symbol}`)
        multiplyCurrency(exchange_rate, providerDetails?.grand_total_bill, `${providerDetails?.is_base_currency}`)
    }, [providerDetails]);

    const multiplyCurrency = (exchange_rate, receivedAmount, displayedCurrency) => {


        let [amountInUSD, amountInSSP] = [0,0];



        // currency is USD
        if(displayedCurrency.includes("true")){
            amountInUSD = receivedAmount
            amountInSSP = Number(exchange_rate) <= 0 ? 0 : Number(exchange_rate) * Number(receivedAmount)
            setAmount({sspAmount: Number(amountInSSP).toFixed(4), usdAmount: Number(amountInUSD).toFixed(4)})
            return;
        }

        // currency is SSP
        if(displayedCurrency.includes("false")){
            // amountInUSD = receivedAmount
            //
            // amountInSSP = Number(exchange_rate) <= 0 ? 0  :
            //     (Number(receivedAmount) === 0 ? 0 : Number(receivedAmount) / Number(exchange_rate));

            amountInUSD = Number(exchange_rate) <= 0 ? 0  :
                (Number(receivedAmount) === 0 ? 0 : Number(receivedAmount) * Number(exchange_rate));

            amountInSSP = receivedAmount

            setAmount({sspAmount: Number(amountInSSP).toFixed(4), usdAmount: Number(amountInUSD).toFixed(4)})
            return;
        }


    }


    const handleChangeCurrency = (event) => {
        setCurrency(event.target.value);
        // setExchangeRate(0)

    };

    //Change exchange rate
    const handleChangeExchangeRate = (event) => {
        const ex_rate = +event.target.value > 0 ? 1 / +event.target.value : 0
        setExchangeRate(ex_rate);
        setCurrencyExchangeRate(event.target.value)
        multiplyCurrency(ex_rate,providerDetails?.total_bill, currency);
    }
    const handleChangeMemo = (event) => {
        setMemo(event.target.value);
    }


    const handleCloseDialog = () =>{
        setOpenDialog(false);
    }

    const handleChange = (event) =>{
        const {name, value} = event.target;
        setState({...state, [name]:value})
        setExchangeRate(0)
        setCurrencyExchangeRate('')
        setAmount({sspAmount: 0, usdAmount: 0})
    }

    const handleIsFullBill = (event) =>{
        setIsFull(event.target.checked)

        const total_amount = isBaseCurrency ? amount.usdAmount : amount.sspAmount
        const full_amount = event.target.checked ? total_amount : ''
        setAmountReceived(full_amount)
        // multiplyCurrency(exchangeRate, full_amount, currency);

    }
    const handleChangeReceived = (event) =>{
        setAmountReceived(event.target.value)
        multiplyCurrency(exchangeRate, event.target.value, currency);
    }


    const handleChangeDebitTo = (val) =>{
        setDebitTo(val)
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setSubmitted(true);

        const currency_id = currency.split('-')[0]
        if (amountReceived && debit_to) {

            const params = {
                invoices_paid:providerDetails?.bills,
                amount_paid:+amountReceived,
                pay_type:2,
                received_by:user_roles_id,
                memo,
                insurance_provider:+providerDetails?.provider_id,
                debit_to: debit_to?.value,
                currency_id: Number(currency_id),
                currency_rate: Number(exchangeRate)
            }

            setIsSubmitted('pending')
            axios.post(`${config.smsUrl}/cbilling/clear_debt`, params).then(() => {
                actions.snackbarActions.snackSuccess('Debt cleared successfully')
                setIsSubmitted('resolved')
                setSubmitted(false)
                setOpenDialog(false)
                setAmountReceived(0)
                setIsFull(false)
                setDebitTo(null)
                // history.push('/customerreceivables')
            }).catch(err => {
                errorMessages(err, null, actions)
                setIsSubmitted('rejected')
            })
        }
    }


    const isPending = isSubmitted === 'pending';

    const {payment_date} = state



    const isBase = currency ?  currency?.split('-')[1] : ''
    const isNotBase = isBase === 'false'
    const isBaseCurrency = isBase === 'true'
    const currency_symbol = currency ?  currency?.split('-')[2] : ''

    const isFullSpan = (
        <span>Receive full amount(<strong>{isNotBase ? amount.sspAmount : amount.usdAmount}</strong>)</span>
    )
    return (
        <AccountingDrawer open={openDialog} title={`Payment for ${providerDetails?.provider_name}`} handleClose={handleCloseDialog} width={classes.drawerWidth}>
            <form onSubmit={handleSubmit}>
                <div className='row mb-3'>
                    <div className='col-lg-8'>
                        {/*<Form.Group>*/}
                        {/*    <Label name='Customer'/>*/}
                        {/*    <TextField type='text' readOnly value={customer_name}/>*/}
                        {/*</Form.Group>*/}
                        <Form.Group>
                            <Label name='Payment Date' type/>
                            <TextField type='date' value={payment_date} name='payment_date' submitted={submitted} onChange={handleChange}/>
                            {submitted && !payment_date ? <div className='help-block'>Payment date is required</div>:null}
                        </Form.Group>

                        <Form.Group >
                                <Label name="Currency" type htmlFor="currency"/>


                                <SelectInput
                                    testId='currency'
                                    submitted={submitted}
                                    value={currency}
                                    onChange={handleChangeCurrency}
                                    options={currencies}
                                    id="currency"
                                    defaultOption="Select..."
                                />


                        </Form.Group>

                        {
                            isNotBase ?

                                <Form.Group>
                                    <Label  name="Exchange Rate" htmlFor="exchange_rate"/>
                                        <InputGroup className="">
                                            <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">{currency_symbol}</InputGroup.Text>

                                            <TextField id='exchange_rate' type="number"  value={currencyExchangeRate} onChange={handleChangeExchangeRate}/>
                                        </InputGroup>

                                        <span style={{marginTop:'5px', display:'inline-block', fontWeight:700, color:'blue'}}>(1 {selectedCurrency.currency_symbol} = {currencyExchangeRate} {currency_symbol})</span>
                                </Form.Group>
                                :
                                null
                        }

                        {/*{*/}
                        {/*    isBaseCurrency ?*/}
                        {/*        <Form.Group>*/}
                        {/*            <Label  name="Exchange Rate" htmlFor="exchange_rate"/>*/}

                        {/*                <InputGroup className="">*/}
                        {/*                    <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">USD</InputGroup.Text>*/}

                        {/*                    <TextField id='exchange_rate' type="number"  value={currencyExchangeRate} onChange={handleChangeExchangeRate}/>*/}
                        {/*                </InputGroup>*/}

                        {/*                <span style={{marginTop:'5px', display:'inline-block', fontWeight:700, color:'blue'}}>(1 USD = {currencyExchangeRate} {currency.split('-')[2]} )</span>*/}
                        {/*        </Form.Group>*/}
                        {/*        :*/}
                        {/*        null*/}
                        {/*}*/}


                        {isNotBase ? <Form.Group >
                            <Label name="Total Bill"/>
                            <Row>
                                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">{selectedCurrency.currency_symbol}</InputGroup.Text>

                                        <TextField name='outstanding_amount' type="text" value={amount.usdAmount ? amount.usdAmount : 0} readOnly/>
                                    </InputGroup>
                                </Col>

                                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">{currency_symbol}</InputGroup.Text>

                                        <TextField name='outstanding_amount' type="text" value={amount.sspAmount ? amount.sspAmount : 0} readOnly/>
                                    </InputGroup>
                                </Col>
                            </Row>

                        </Form.Group>:
                            <Form.Group>
                                <Label name='Total Bill'  htmlFor='total_usd_bill'/>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">{currency_symbol}</InputGroup.Text>

                                    <TextField name='outstanding_amount' type="text" value={amount.usdAmount ? amount.usdAmount : 0} readOnly/>
                                </InputGroup>
                            </Form.Group>}

                        <Form.Group>
                            <Label name='Amount Received' type htmlFor='amount_received'/>
                            <TextField type='number' id='amount_received' value={amountReceived} submitted={submitted} onChange={handleChangeReceived}/>
                            {submitted && !amountReceived ? <div className='help-block'>Amount received is required</div>: null}
                           <div className='mt-2'>
                                <Form.Check checked={isFull} onChange={handleIsFullBill} label={isFullSpan} data-testid="receive-full-amount"/>
                            </div>

                        </Form.Group>


                        <Form.Group>
                            <Label name='Debit To' type/>
                            <CustomSelect id='debit_to' submitted={submitted} onChange={handleChangeDebitTo} value={debit_to} options={accounts}/>
                            {submitted && !debit_to ? <div className="help-block">Debit account is required</div>:null}
                        </Form.Group>

                        <Form.Group>
                            <Label name='Memo'/>
                            <Textarea rows='3' cols='3'  name="memo" onChange={handleChangeMemo} value={memo}/>
                        </Form.Group>

                        <RightAlignedContainer>
                            <button type='submit' disabled={isPending} className='btn btn-sm sms-btn'>{isPending ? 'Submitting....' : 'Submit'}</button>
                        </RightAlignedContainer>

                        {/*<Form.Group>*/}
                        {/*    <Label name='Payment Method' type/>*/}
                        {/*    <SelectInput options={paymentTypes} onChange={handleChange} value={payment_method} submitted={submitted}*/}
                        {/*                 name='payment_method' defaultOption='Select payment method...'/>*/}
                        {/*    {submitted && !payment_method ? <div className='help-block'>Payment method is required</div>:null}*/}
                        {/*</Form.Group>*/}
                    </div>

                    <div className='col-lg-4'>
                        {/*<p style={{marginTop: 24, fontSize:16}}>Amount Due : <strong>{total_amount}</strong></p>*/}
                        {/* <Form.Group>
                            <Label name='Amount Due'/>
                            <TextField type='number' value={due_amount ? formatAmount(due_amount) : due_amount} readOnly/>
                        </Form.Group> */}

                        {/*<Form.Group>*/}
                        {/*    <Label name='Amount Received' type/>*/}
                        {/*    <TextField type='number' value={amountReceived} submitted={submitted} onChange={handleChangeReceived}/>*/}
                        {/*    {submitted && !amountReceived ? <div className='help-block'>Amount received is required</div>:null}*/}
                        {/*    <div className='mt-2'>*/}
                        {/*        <Form.Check checked={isFull} onChange={handleIsFullBill} label={`Receive full amount(${due_amount ? formatAmount(due_amount) : due_amount})`}/>*/}
                        {/*    </div>*/}

                        {/*</Form.Group>*/}
                    </div>
                </div>
                {/**
                <p><strong>Outstanding Transactions</strong></p>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {headData.map((item, index)=>(
                                    <TableCell key={index}>{item.name}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {invoices?.map(item=>{
                                return(
                                    <TableRow key={item.invoice_id}>
                                        {/*<TableCell>
                                            {item.date ? dateStringConvert(item.date) : item.date}
                                        </TableCell> */}
                                        {/*<TableCell>
                                            {item.invoice_no}
                                        </TableCell>
                                        <TableCell>
                                            {formatAmount(item.total_amount)}
                                    </TableCell>*/}
                                        {/*<TableCell>*/}
                                        {/*    {item.prevous_due === 0 ? formatAmount(item.total_amount):formatAmount(item.prevous_due)}*/}
                                        {/*</TableCell>*/}
                                        {/*<TableCell>*/}
                                        {/*    <TextField value={item.payment} onChange={(e)=>handleChangePayment(e, item.invoice_id)}/>*/}
                                        {/*</TableCell>*/}
                                  {/*  </TableRow>
                                )
                            })}

                        </TableBody>
                    </Table>
                </TableContainer>                 * 
                 * 
                 */}


            </form>
        </AccountingDrawer>
    )
}

export {ReceiveDebtorsPayments}