import React, {useEffect, useState} from "react";
import CustomTable from "../../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import * as snackbarActions from "../../../actions/snackbarActions"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {SortSelect} from "../../Accounting/Reports/SortSelect";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {useProductSalesReport} from "../FinancialReports/CustomHooks/useProductSalesReport";
import {convertMonth, formatDate} from "../../Utils/ConvertDate";
import { exportPDF } from "../../Utils/downloadPDF";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClipboardList} from "@fortawesome/free-solid-svg-icons/faClipboardList";
import {MainSearchComponent} from "../MainSearch";
import {DataExportButton} from "../../Utils/Buttons/DataExportationButton";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {formatAmount, formatDigits} from "../../Utils/formatNumbers"
import { BackButton } from "../../Utils/Buttons/DataExportationButton";
import {useExcelReports, usePdfDownload} from "../../Utils/ReusableComponents/useExcelExport";

const headData = [
    {id: 'sl', numeric: false, disablePadding: false, label: 'SL'},
    {id: 'purchase_date', numeric: false, disablePadding: false, label: 'Purchase Date'},
    {id: 'supplier', numeric: false, disablePadding: false, label: 'Supplier'},
    {id: 'product', numeric: false, disablePadding: false, label: 'Product'},
    {id: 'batch_id', numeric: false, disablePadding: false, label: 'Batch#'},
    {id: 'quantity', numeric: false, disablePadding: false, label: 'Quantity Purchased'},
    {id: 'price', numeric: false, disablePadding: false, label: 'Unit Price'},
    {id: 'amount', numeric: false, disablePadding: false, label: 'Amount'},
];

const AccountingPurchaseReport = ({actions, snackbars}) => {
    const obj =  {url:'purchase_report',id:'supplier_name'}
    const {closeSnackbar, search_type, handleSubmit, data, count, customSearch, handleChangeActivePage,
        text, textType, year, date, handleChangeSearch, handleCustomSearch,category,
        handleChangeDate, handleChangeYear, handleIncrementYear, isSubmitted, submitted,
        loading,  inputType, csvRef, downloadCsv,  handleChangeCategory, activePage
    } = useProductSalesReport(actions, obj)
    const [suppliers, setSuppliers] = useState([]);
    const {start_date, end_date} = date;
    const dataArr = data.purchases ?? []
    useEffect(() => {
        axios.get(`${config.smsUrl}/csupplier/retrieve_all_suppliers`)
            .then(res => {
                const details = res.data;
                const dt = details ?? {}
                const data = dt.total_manufacturers ?? [];
                const arr = data.map(item => ({
                    value: item.supplier_id,
                    label: item.supplier_name
                }))
                setSuppliers([{value:'', label:'All'},...arr])
            }).catch(error => {
            logoutErrorMessage(error, null, actions)
        });
    }, [])

    // const downloadPdf = () => {
    //     const {end_date, start_date} = date
    //     const dataArr = data.data ?? []
    //     const time = search_type === '0' ? start_date : search_type === '1' ? convertMonth(start_date) :
    //         search_type === '2' ? year : `${start_date} to ${end_date}`;
    //     exportPDF(`Product Purchases (${time})`, [['Product', "Quantity", "Rate", "Amount"]],
    //         dataArr.map((item) => [item.product_name, item.quantity, item.rate, item.amount]),
    //         `Product Purchases (${time}).pdf`);
    // }

    const pdfObj = {file_name:'Purchase Report', url:'creport/purchase_pdf', params:{ start_date, end_date, supplier:category.value ? category.value : ''}}

    const {exportPdf, isLoading:isLoadingPdf} = usePdfDownload(actions, pdfObj)

    const excel = {file_name:'Purchase Report', url:'creport/purchase_excel', params:{ start_date, end_date, supplier:category.value ? category.value : ''}}

    const {exportExcel, isLoading} = useExcelReports(actions, excel)

    const headers = [{label: 'Product', key: 'product_name'}, {label: 'Quantity', key: 'quantity'},
        {label: 'Unit Price', key: 'rate'},{label: 'Amount', key: 'amount'}]
    const {openBar, type, message} = snackbars;
    return (
        <div className='journals' data-testid="revenue-generated">
            <PageTitle title='Purchases'/>
            <SubHeader title="Reports" subTitle='Purchases'>
                <FontAwesomeIcon icon={faClipboardList}/>
            </SubHeader>
            <div style={{margin: '10px'}}>
                <BackButton text={'General Reports'} to={'/reports/generalreports'}/>
            </div>
            <div className='general-ledger-header mb-3'>
                {/*<div className='mb-2'>*/}
                {/*    <BackButton text='General Reports' to='/reports/generalreports'/>*/}
                {/*</div>*/}
                <MainSearchComponent text={text} type={textType} changeDate={handleChangeDate}
                                     handleSubmit={handleSubmit} isSubmitted={isSubmitted}
                                     changePeriod={handleChangeSearch} end_date={end_date}
                                     handleChangeValue={handleChangeYear} inputType={inputType}
                                     search_type={search_type} handleChangeYear={handleIncrementYear}
                                     start_date={start_date} year={year} submitted={submitted}>
                    <DataExportButton title='Purchase Report' downLoadPdf={exportPdf}
                                      exportCsv={exportExcel} isLoading={isLoading || isLoadingPdf}/>
                </MainSearchComponent>

            </div>
            <div className="mui-tables">

                <SortSelect categories={suppliers} category={category} handleChangeCategory={handleChangeCategory} placeholder='Select supplier...'/>
                <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>
                <CustomTable title="" handleChangeNextPage={handleChangeActivePage} term={customSearch}
                             records={10}
                             headData={headData} handler={handleCustomSearch} total_count={count}
                             activePage={activePage}
                             data={dataArr} colSpan={headData.length}
                             customPage>
                    {loading ? <TableBody><TableRow>
                        <TableCell colSpan={headData.length} align="center"><img src="/images/smsloader.gif"
                                                                   alt="loader"/></TableCell>
                    </TableRow></TableBody> : dataArr.length > 0 ?
                        <TableBody>
                            {dataArr.slice(0, 10)
                                .map((item, index) => {
                                    const cnt = ((activePage - 1) * 10) + index + 1;
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={item.product_name}
                                            className='mui-table-row'>
                                            <TableCell>{cnt}</TableCell>
                                            <TableCell>{item.purchase_date ? formatDate(item.purchase_date) : item.purchase_date}</TableCell>
                                            <TableCell>{item.supplier_name}</TableCell>
                                            <TableCell>{item.product_name}</TableCell>
                                            <TableCell>{item.batch_id}</TableCell>
                                            <TableCell>{item.quantity}</TableCell>
                                            <TableCell>{formatDigits(item.rate)}</TableCell>
                                            <TableCell>{formatDigits(item.total_amount)}</TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                        : <TableBody><TableRow>
                            <TableCell colSpan={headData.length} align="center">No records found</TableCell>
                        </TableRow></TableBody>}
                </CustomTable>
            </div>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountingPurchaseReport);
