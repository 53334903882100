import React from "react";

export const useDebounce = (value, time) =>{
    const [debouncedInputValue, setDebouncedInputValue] = React.useState("");

    React.useEffect(() => {
        const timeoutId = setTimeout(() => {
            setDebouncedInputValue(value);
        }, time);
        return () => clearTimeout(timeoutId);
    }, [value, time]);

    return [debouncedInputValue, setDebouncedInputValue]
}