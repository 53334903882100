import * as types from "./actionTypes";
import axios from "axios";
import { config } from "../Helpers/env"
import {errorMessages} from "../Helpers/ErrorMessages";
import {snackSuccess} from "./snackbarActions";
import {history} from "../Helpers/history";


export const addEmployee = (formData) =>  (dispatch) => {
    dispatch({type: types.ADD_EMPLOYEE_REQUEST});
    return  axios.post(`${config.smsUrl}/chumanresource/add_employee`, formData).then(() => {
        dispatch({type: types.ADD_EMPLOYEE_SUCCESS});
        dispatch(snackSuccess('Employee added successfully'));
        history.push('/employeelist')
    }).catch(err => {
      dispatch({type:types.ADD_EMPLOYEE_ERROR});
        errorMessages(err, dispatch, null)
    })
};

export const editEmployee = (formData) =>  (dispatch) => {
  dispatch({type:types.EDIT_EMPLOYEE_REQUEST});
    return  axios.post(`${config.smsUrl}/chumanresource/edit_employee`, formData).then(() => {
      dispatch({type:types.EDIT_EMPLOYEE_SUCCESS});
        dispatch(snackSuccess('Employee profile updated successfully'));
        history.push('/employeelist')
    }).catch(err => {
      dispatch({type:types.EDIT_EMPLOYEE_ERROR});
        errorMessages(err, dispatch, null)
    })
};
