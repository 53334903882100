import * as types from "./actionTypes";

export function alertSuccess(message) {
  return {
    type: types.ALERT_SUCCESS,
    message
  };
}
export function alertFailure(message) {
  return {
    type: types.ALERT_ERROR,
    message
  };
}
export function alertClear() {
  return {type: types.ALERT_CLEAR};
}
