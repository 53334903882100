import React from "react";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CustomTable from "../../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import {getComparator, stableSort} from "../../Utils/Tables/sortingFunctions";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import *  as snackbarActions from "../../../actions/snackbarActions";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {useStockAdjustmentList} from "./hooks/useStockAdjustmentList";
import {Link} from "react-router-dom";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {dateStringConvert} from "../../Utils/ConvertDate";
import {DataExportButton} from "../../Utils/Buttons/DataExportationButton";
import { FullScreenDialog } from "../../Utils/Dialogs/FullScreenDialog";
import {StockAdjustment} from "./StockAdjustment";
import {faMortarPestle} from "@fortawesome/free-solid-svg-icons/faMortarPestle";

const headData = [{id: 'sl', numeric: false, disablePadding: false, label: 'SL'},
    {id: 'date', numeric: false, disablePadding: false, label: 'Date'},
    {id: 'adjustedBy', numeric: false, disablePadding: false, label: 'Adjusted By'},
    {id: 'reason', numeric: false, disablePadding: false, label: 'Reason'},
    {id: 'type', numeric: false, disablePadding: false, label: 'Stock Category'},
    {id: 'action', numeric: false, disablePadding: false, label: 'Action'},
];


const adjustmentHeadData = [{name:'Product', isRequired:true},{name:'Stock', isRequired:false},
{name:'Quantity', isRequired:true},{name:'Adjustment Type', isRequired:true},{name:'Total Stock', isRequired:false},{name:'Action', isRequired:false}]
export const StockAdjustmentList = ({actions, snackbars}) => {
    const {searchValue, order, orderBy, loading, stockList, activePage, total_count,
        closeSnackbar, handleSearch, handleChangePage, handleRequestSort,csvRef,downloadCsv,
        downloadPdf,allRecords,adjustmentProps} = useStockAdjustmentList(actions)
    const csvData = allRecords.map((item, index) => ({
        date_created: item.date_created,
        reason: item.reason,
        adjusted_by: `${item.first_name ? item.first_name : ''} ${item.last_name ? item.last_name : ''}`,
        stock_type: item.stock_type === 0 ? 'Available Stock' : 'Old Stock'
    }))
    const headers = [{label: 'Date', key: '   date_created'}, {label: 'Adjusted By', key: 'adjusted_by'},
        {label: 'Reason', key: '  reason'}, {label: 'Category', key: 'stock_type'}]
    const stocks = !stockList ? [] : stockList;
    const {openBar, type, message} = snackbars;
    const perm = JSON.parse(sessionStorage.getItem('permission'));
    const permission = !perm ? {} : perm;
    const adjust_stock = !permission.adjust_stock ? {} : permission.adjust_stock;
    const {openDialog:openAdjustment, handleOpenDialog:handleOpenAdjustment,
        handleCloseDialog:closeAdjustment} = adjustmentProps
    return (
        <div className='journals'>
            <PageTitle title="Stock Adjustment"/>
            <SubHeader title="Pharmacy" subTitle="Stock adjustment">
                <FontAwesomeIcon icon={faMortarPestle}/>
            </SubHeader>
            <FullScreenDialog open={openAdjustment} handleClose={closeAdjustment} title='Adjust Stock'>
                <StockAdjustment {...{...adjustmentProps, 
                headData:adjustmentHeadData, fileName:'Stock.csv', user:'Added By'}}/>
            </FullScreenDialog>
            <div className='general-ledger-header'>
                <div className="row">
                    <div className="col-md-7"/>
                    <div className="col-md-5">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="text-right">
                                    {((adjust_stock.read || adjust_stock.update ||
                                        adjust_stock.delete) && adjust_stock.create) &&
                                        <button onClick={handleOpenAdjustment} className="btn sms-btn"><FontAwesomeIcon icon={faPlus}/> Adjust Stock</button>
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <DataExportButton data={csvData} title='Stock Adjustment List' csvRef={csvRef}
                                                  exportCsv={downloadCsv} downLoadPdf={downloadPdf} headers={headers}/>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="mui-tables">
                <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>
                <CustomTable title="Stock Adjustment List" term={searchValue} records={10} activePage={activePage}
                             headData={headData} handler={handleSearch} total_count={total_count}
                             handleRequestSort={handleRequestSort} data={stocks} colSpan={5} order={order}
                             orderBy={orderBy} handleChangeNextPage={handleChangePage} customPage>
                    {loading ? <TableBody><TableRow>
                        <TableCell colSpan="8" align="center"><img src="/images/smsloader.gif"
                                                                   alt="loader"/></TableCell>
                    </TableRow></TableBody> : stocks.length > 0 ?
                        <TableBody>
                            {stableSort(stocks, getComparator(order, orderBy))
                                .slice(0, 10)
                                .map((item, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={item.stock_adjustment_id}
                                            className='mui-table-row'>
                                            <TableCell>{item.count}</TableCell>
                                            <TableCell>{item.date ? dateStringConvert(item.date) : item.date}</TableCell>
                                            <TableCell>{item.adjusted_by}</TableCell>
                                            <TableCell>{item.reason}</TableCell>
                                            <TableCell>{item.stock_type}</TableCell>
                                            <TableCell>
                                                <Link to={`/adjustmentdetails/${item.stock_adjustment_id}`}>
                                                    <button className="btn btn-sm sms-info-btn">View</button>
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                        : <TableBody><TableRow>
                            <TableCell colSpan="8" align="center">No records found</TableCell>
                        </TableRow></TableBody>}
                </CustomTable>
            </div>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(StockAdjustmentList);
