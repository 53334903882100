import React from "react";
import Form from "react-bootstrap/Form"
import Label from "../Utils/FormInputs/Label";
import {CustomInput, CustomSelectInput} from "../Inventory/CustomInput";
import {RightAlignedContainer} from "../Utils/styledComponents";

const options = [
    {value: '', text: 'Select'},
    {value: 'Clinical', text: 'Clinical'},
    {value: 'Nursing', text: 'Nursing'},
    {value: 'Administrative', text: 'Administrative'},
    {value: 'Accounts', text: 'Accounts'},
    {value: 'General Duties', text: 'General Duties'},
]

export function AddDepartmentForm({state, handleSubmit, isPending,isResolved, submitted}) {
    const {department_name, department_type} =  state
    return (
        <form onSubmit={handleSubmit} autoComplete='off'>
            <Form.Group>
                <Label type name='Name'/>
                <CustomInput type='text' submitted={submitted} isRequired reset={isResolved}
                             name='department_name' inputValue={department_name} />
            </Form.Group>
            <Form.Group>
                <Label type name='Type'/>
                <CustomSelectInput  submitted={submitted} isRequired reset={isResolved}
                             name='department_type' inputValue={department_type} options={options} defaultOption='Select...'/>
            </Form.Group>
            <RightAlignedContainer>
                <button type='submit' disabled={isPending} className='btn btn-sm sms-btn btn-sm'>{isPending ? 'Saving...': 'Save'}</button>
            </RightAlignedContainer>
        </form>
    )
}