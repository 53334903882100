import React from "react";
import PropTypes from 'prop-types';
import { FormControl, FormHelperText, InputLabel, makeStyles, MenuItem, Select, TextField } from "@material-ui/core"
const useStyles = makeStyles((theme) => ({
    formControl: {
      marginRight: theme.spacing(1),
      minWidth: 120,
      minHeight: 30,
    },
    root: {
        '& > *': {
            display : 'flex',
            flexDirection: 'column',
          marginRight: theme.spacing(1),
          // width: 120,
          minHeight: 30,
        },
    }
  }));
export const CurrencyToggle =({options, value,handleChangeCurrency, defaultValue})=>{
    const classes = useStyles();
    return(
        <>
         <FormControl className={classes.formControl}>
                <InputLabel id="sms-currency-toggle">Currency</InputLabel>
                <Select
                labelId="sms-currency-toggle"
                id="sms-currency-toggle"
                onChange={handleChangeCurrency}
                value={value}
                defaultValue={defaultValue}
                >
                 {options && options.map((item)=>{
                    return <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                 })}
                
                </Select>
            </FormControl>
           
        </>
    )
}

//adding proptypes 
CurrencyToggle.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string
    })).isRequired, 
    value: PropTypes.string.isRequired
};

export const FxRate =({currency, value,nonBaseCurrency, handleChangeRate})=>{
    const classes = useStyles();
    return(
        <form className={classes.root} autoComplete='off'>
        <TextField id="fx-rate" label="Rate" name="fxRate" value={value} onChange={handleChangeRate} /> 
        <span style={{marginTop:'5px', display:'inline-block', fontWeight:700, color:'blue'}}>(1 {currency} = {value} {nonBaseCurrency})</span>
     </form>
    )
}