import React from "react";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCoins} from "@fortawesome/free-solid-svg-icons/faCoins";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PageTitle from "../../Utils/smsTitle";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../../actions/snackbarActions"
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import PrintDialog from "../../Utils/Dialogs/PrintDialog";
import {ReusableBillTable, ProductsTable} from "../utils/ReusableTable";
import TextField from "../../Utils/FormInputs/TextField";
import Textarea from "../../Utils/FormInputs/Textarea"
import {MiniHeader} from "../../Utils/Menu/MiniSubHeader"
import Label from "../../Utils/FormInputs/Label"
import SelectInput from '../../Utils/FormInputs/SelectInput';
import {Container, ErrorMessage} from "../../Utils/styledComponents"
import {useInPatientBillFormTwo} from "./hooks/useInPatientBillForm";
import { formatDate, formatDateTime } from "../../Utils/ConvertDate";
import { BackButton } from "../../Utils/Buttons/DataExportationButton";
import CustomDialog from "../../Utils/Dialogs/CustomDialog";
import InputGroup from "react-bootstrap/InputGroup";
import {BlockNavigationComponent} from "../../Utils/ReusableComponents/BlockNavigationComponent";
import {history} from "../../../Helpers/history";
import {groupProductId, groupServiceId} from "../utils/SanitizeArr";
import {useCurrency} from "../../../Context/currency-context";
import {useVersionsContext} from "../../../Context/versions-context";
import {FinishedStatus} from "../../DoctorsModule/doctorStyles";


function InpatientBillFormTwo(props){
    const {currency:selectedCurrency} = useCurrency(props)
    const {
        payment_method,
        patientDetails,
        bedBill,
        services,
        products,
        isSubmitted,
        date,
        openPrint,
        paidAmount,
        submitted,
        handleChangePaidAmount,
        handleDeleteServices,
        handleChangeDate,
        handleChangeMethod,
        payTypes,
        handleSubmitBill,
        handleCloseReceipt,
        handleClickReceipt,
        closeSnackbar,
        handleChangeProductRate,
        handleChangeServiceRate,
        handleDeleteProduct, handleChangeProductQuantity,
        handleAddProduct, handleAddService, addProduct, addService,
        handleCloseAddService, handleCloseAddProduct,
        currencies, currency, handleChangeExchangeRate, handleChangeServiceCurrencyRate,
        exchangeRate, usdAmount, sspAmount, currencyTotal, currencyTotalSsp, handleChangeProductCurrencyRate,
        handleChangeCurrency, totals,
        renderService , renderPrescription,isBlocking,isExcluded,
        receiptTotals,
        paidReceipts,
        currencyExchangeRate,
        handlePayProcedure,
        handlePayProduct,
        checkAllProducts,
        checkAllServices,
        handlePayAllProcedures,
        handlePayAllProducts,
        checkAllBeds,
        handlePayBed,
        handlePayAllBeds
    } = useInPatientBillFormTwo(props)

    const {openBar, type, message} = props.snackbars;

    const {patient_number, first_name, last_name, address,  admission_date} = patientDetails;

    const {status} = props.match.params;

    const title = {
        '1':'Make Deposit',
        '2':'Pay Bill'
    }

    const groupedProducts  = groupProductId(products)

    const groupedServices = groupServiceId(services)

    const {total_amount,  balance} = totals

    const {total_amount_paid, remaining_balance, ssp_amount_paid, ssp_remaining_balance} = receiptTotals


    const isBase = currency && currency.split('-')[1]
    const currency_symbol = currency && currency.split('-')[2]
    const isNotBase = isBase === 'false'
    const isBaseCurrency = isBase === 'true'


    const isLab = groupedServices.some(item=>item.is_external === true || item.is_external === false)

    const isPatientDeposit = status === '1'

    const unpaid = ["Item", "Quantity",   isNotBase ?  `Rate(${currency_symbol})` : null,
        `Rate(${selectedCurrency.currency_symbol})`,
        isNotBase ? `Amount(${currency_symbol})` : null,
        `Amount(${selectedCurrency.currency_symbol})`,
        isLab ? "Status" : null,
        !isPatientDeposit ? "Pay" :  null]

    const productHeadData = ["Medicine Name", "Dosage", "Frequency", "No. of Days", "Quantity Administered",
    isNotBase ?  `Rate(${currency_symbol})` : null, `Rate(${selectedCurrency.currency_symbol})`,
        isNotBase ? `Amount(${currency_symbol})` : null, `Amount(${selectedCurrency.currency_symbol})`, !isPatientDeposit ? "Pay" :  null]

    const bedHeadData = ["Ward", "Room", "Bed No.", "Assign Date",
        "End Date","Days",`Rate(${selectedCurrency.currency_symbol})`,
        isNotBase ?  `Rate(${ currency_symbol})` : null,
        `Amount(${selectedCurrency.currency_symbol})`,
        isNotBase ? `Amount(${currency_symbol})` : null, !isPatientDeposit ? "Pay" :  null]


    const total_bill = isBaseCurrency ? total_amount : currencyTotal
    const total_remaining = isBaseCurrency ? remaining_balance : (exchangeRate ? remaining_balance / exchangeRate : remaining_balance)


    const t_amount = remaining_balance > 0 ? total_remaining : total_bill

    const isDeposit  =  status === '1' ? Number(paidAmount) > Number(t_amount) : Number(paidAmount) < Number(t_amount)


    const {subscription} = useVersionsContext()
    const version = subscription?.version
    const isPremium = version === 'Premium'

    const isProducts = products.some(item=>item.isPaid)
    const isServices = services.some(item=>item.isPaid)

    return (
        <>
            <div data-testid="add-bill-component" className='journals'>
                <PageTitle title={title[status]}/>

                <SubHeader title="Billing" subTitle={title[status]}>
                    <FontAwesomeIcon icon={faCoins}/>
                </SubHeader>

                <BlockNavigationComponent
                    when={isBlocking}
                    shouldBlockNavigation={()=>{
                        return isBlocking;
                    }}
                    navigate={path=>history.push(path)}
                />

                <PrintDialog
                    handleCancel={handleCloseReceipt}
                    openDialog={openPrint}
                    handleClose={handleCloseReceipt}
                    handleClick={handleClickReceipt}
                    message="View receipt"
                    text="Would you like to confirm this payment"/>

                <CustomDialog
                    open={addService}
                    title="Add Service"
                    handleClose={handleCloseAddService}
                    maxWidth="md"  isContentOverflow={true}
                >
                    {renderService}
                </CustomDialog>

                <CustomDialog
                    open={addProduct}
                    title="Add Sundries"
                    handleClose={handleCloseAddProduct}
                    maxWidth="lg"
                    isContentOverflow={false}
                >
                    {renderPrescription}
                </CustomDialog>

                <MainSnackbar
                    variant={type}
                    handleCloseBar={closeSnackbar}
                    open={openBar}
                    message={message}
                />

                <Container>
                    <BackButton
                        to='/patientbillinglist'
                        text='Billing List'
                        data-testid="inpatient-bill-form-back-button"
                    />

                    <MiniHeader  title={title[status]}/>

                    <form onSubmit={handleSubmitBill} autoComplete="off" style={{marginTop:'1.5rem'}}>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                        <Label name="Admission Date"/>
                                    </Col>

                                    <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                                        <TextField type="text" value={formatDate(admission_date)} readOnly/>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                        <Label name="Patient No."/>
                                    </Col>

                                    <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                                        <TextField type="text" value={patient_number} readOnly/>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                        <Label name="Patient Name" />
                                    </Col>

                                    <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                                        <TextField type="text" value={`${!first_name ? "" : first_name} ${!last_name ? "" : last_name}`} name="patient_name" readOnly/>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                        <Label name="Address" />
                                    </Col>

                                    <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                                        <Textarea value={address} readOnly/>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                        <Label name="Payment Method"  type/>
                                    </Col>

                                    <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                                        <SelectInput
                                            name='payment_method'
                                            submitted={submitted}
                                            value={payment_method}
                                            onChange={handleChangeMethod}
                                            options={payTypes}
                                            defaultOption="Select..."
                                        />
                                    </Col>
                                </Form.Group>

                                {isPremium ? <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                        <Label name="Currency" type htmlFor="currency"/>
                                    </Col>

                                    <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                                        <SelectInput
                                            submitted={submitted}
                                            value={currency}
                                            onChange={handleChangeCurrency}
                                            options={currencies}
                                            defaultOption="Select..."
                                            id="currency"
                                            name="currency"
                                        />
                                    </Col>
                                </Form.Group>: null}

                                {
                                    isNotBase ?

                                    <Form.Group as={Row} >
                                        <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                            <Label  name="Exchange Rate" />
                                        </Col>

                                        <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                                            <InputGroup className="">
                                                <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">{Boolean(currency_symbol) ? currency_symbol : null}</InputGroup.Text>

                                                <TextField type="number"  value={currencyExchangeRate} onChange={handleChangeExchangeRate} id="exchange_rate"/>
                                            </InputGroup>
                                            {!(isProducts || isServices) && !currencyExchangeRate ? <ErrorMessage>Enter the exchange rate</ErrorMessage>: null}

                                            <span style={{marginTop:'5px', display:'inline-block', fontWeight:700, color:'blue'}}>(1 {selectedCurrency.currency_symbol} = {currencyExchangeRate} {currency_symbol})</span>
                                        </Col>
                                    </Form.Group>
                                    :
                                    null
                                }

                                {/*{*/}
                                {/*    isBaseCurrency ?*/}

                                {/*        <Form.Group as={Row} >*/}

                                {/*            <Col xs={12} sm={12} md={12} lg={12} xl={3}>*/}
                                {/*                <Label  name="Exchange Rate" />*/}
                                {/*            </Col>*/}

                                {/*            <Col xs={12} sm={12} md={10} lg={10} xl={7}>*/}
                                {/*                <InputGroup className="">*/}
                                {/*                    <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">USD</InputGroup.Text>*/}

                                {/*                    <TextField type="number"  value={currencyExchangeRate} onChange={handleChangeExchangeRate}/>*/}
                                {/*            </InputGroup>*/}

                                {/*                <span style={{marginTop:'5px', display:'inline-block', fontWeight:700, color:'blue'}}>(1 SSP  = {currencyExchangeRate} {currency.split('-')[2]})</span>*/}
                                {/*            </Col>*/}
                                {/*        </Form.Group>*/}
                                {/*        :*/}
                                {/*        null*/}
                                {/*}*/}

                            </div>

                            <div className='col-lg-6'>
                                <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                        <Label name="Date"  type/>
                                    </Col>

                                    <Col xs={12} sm={12} md={10} lg={10} xl={9}>
                                        <TextField submitted={submitted} type="date" value={date} onChange={handleChangeDate}/>

                                        {
                                            submitted && !date ?
                                                <ErrorMessage>This is a required field</ErrorMessage>
                                                :
                                                null
                                        }

                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                        <Label name={`Total Bill(${selectedCurrency.currency_symbol})`}/>
                                    </Col>

                                    <Col xs={12} sm={12} md={10} lg={10} xl={9}>
                                        <TextField name='Total_Bill' type="text" value={total_amount.toFixed(3)} readOnly/>
                                    </Col>
                                </Form.Group>

                                {
                                    isNotBase ?

                                        <Form.Group as={Row}>
                                            <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                                <Label name={`Total Bill(${currency_symbol})`}/>
                                            </Col>

                                            <Col xs={12} sm={12} md={10} lg={10} xl={9}>
                                                <TextField type="text" value={currencyTotal.toFixed(3)} readOnly/>
                                            </Col>
                                        </Form.Group>
                                        :
                                        null
                                }

                                {/*{*/}
                                {/*        isBaseCurrency ?*/}

                                {/*            <Form.Group as={Row}>*/}
                                {/*                <Col xs={12} sm={12} md={12} lg={12} xl={3}>*/}
                                {/*                    <Label name={`Total Bill(SSP)`}/>*/}
                                {/*                </Col>*/}

                                {/*                <Col xs={12} sm={12} md={10} lg={10} xl={9}>*/}
                                {/*                    <TextField type="text" value={currencyTotalSsp.toFixed(3)} readOnly/>*/}
                                {/*                </Col>*/}
                                {/*            </Form.Group>*/}
                                {/*            :*/}
                                {/*            null*/}
                                {/*}*/}

                                {/*{*/}
                                {/*    paidReceipts.length > 0 ?*/}
                                        <>
                                            <Form.Group as={Row}>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                                    <Label name="Amount Paid"/>
                                                </Col>

                                                <Col xs={12} sm={12} md={10} lg={10} xl={9}>
                                                    {isNotBase ? <Row>
                                                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                            <InputGroup className="mb-3">
                                                                <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">{Boolean(currency_symbol) ? currency_symbol : null}</InputGroup.Text>

                                                                <TextField name='outstanding_amount' type="text" value={total_amount_paid.toFixed(3)} readOnly/>
                                                            </InputGroup>
                                                        </Col>

                                                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                            <InputGroup className="mb-3">
                                                                <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">{currency_symbol}</InputGroup.Text>

                                                                <TextField name='outstanding_amount' type="text" value={ssp_amount_paid.toFixed(3)} readOnly/>
                                                            </InputGroup>
                                                        </Col>
                                                    </Row>:
                                                        <InputGroup className="mb-3">
                                                            <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">{currency_symbol !== 'undefined' ? currency_symbol : selectedCurrency.currency_symbol}</InputGroup.Text>

                                                            <TextField name='outstanding_amount' type="text" value={total_amount_paid.toFixed(3)} readOnly/>
                                                        </InputGroup>}
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row}>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                                    <Label name="Remaining Balance"/>
                                                </Col>

                                                <Col xs={12} sm={12} md={10} lg={10} xl={9}>
                                                    {isNotBase ? <Row>
                                                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                        <InputGroup className="mb-3">
                                                            <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">{currency_symbol !== 'undefined' ? currency_symbol : null}</InputGroup.Text>

                                                            <TextField name='outstanding_amount' type="text" value={remaining_balance.toFixed(3)} readOnly/>
                                                        </InputGroup>
                                                        </Col>

                                                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                            <InputGroup className="mb-3">
                                                                <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">{currency_symbol}</InputGroup.Text>

                                                                <TextField name='outstanding_amount' type="text" value={ssp_remaining_balance.toFixed(3)} readOnly/>
                                                            </InputGroup>
                                                        </Col>
                                                    </Row>:
                                                        <InputGroup className="mb-3">
                                                            <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">{currency_symbol !== 'undefined' ? currency_symbol : selectedCurrency.currency_symbol}</InputGroup.Text>

                                                            <TextField name='outstanding_amount' type="text" value={remaining_balance.toFixed(3)} readOnly/>
                                                        </InputGroup>}
                                                </Col>
                                            </Form.Group>

                                        </>
                                {/*//         :*/}
                                {/*//         null*/}
                                {/*// }*/}

                                <div>
                                    <Form.Group as={Row} >
                                        <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                            <Label  name={`Amount Received ${ Boolean(currency_symbol) ? `(${currency_symbol ?? ''})`: ''}`}  type/>
                                        </Col>

                                        <Col xs={12} sm={12} md={10} lg={10} xl={9}>
                                            <TextField submitted={submitted} type="number"  value={paidAmount} onChange={handleChangePaidAmount} name="Amount_Received"/>
                                            {submitted && !paidAmount ? <ErrorMessage>Amount received is required</ErrorMessage>: null}
                                            {(isNotBase && ssp_remaining_balance && paidAmount > ssp_remaining_balance) || (isBaseCurrency && remaining_balance && paidAmount > remaining_balance) ?
                                            <ErrorMessage>{`Amount received is more than ${isBaseCurrency ? remaining_balance : ssp_remaining_balance}`}</ErrorMessage>: null}
                                            {/*{submitted && isDeposit ? <ErrorMessage>{ status === '1' ?*/}
                                            {/*    `Amount received is more than the ${remaining_balance > 0 ? 'remaining balance':'total amount'}`:*/}
                                            {/*'Amount received is less than the total amount'}</ErrorMessage>: null}*/}
                                        </Col>
                                    </Form.Group>

                                    {
                                        isNotBase ?
                                            <Form.Group as={Row}>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                                    <Label  name={`Amount Received(${selectedCurrency.currency_symbol})`}/>
                                                </Col>

                                                <Col xs={12} sm={12} md={10} lg={10} xl={9}>
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">{selectedCurrency.currency_symbol}</InputGroup.Text>

                                                        <TextField submitted={submitted} type="text" value={ isBaseCurrency ? paidAmount.toFixed(3) : usdAmount.toFixed(3)} style={{pointerEvents:'none'}}/>
                                                    </InputGroup>

                                                    {/* {submitted && status ==='2' &&  isNotBase && usdAmount && usdAmount < total_amount ? <ErrorMessage>Amount  is less than the required amount</ErrorMessage>:null} */}

                                                    {submitted && status ==='2' &&  isNotBase && usdAmount.toFixed(3) &&  usdAmount.toFixed(3) > total_amount.toFixed(3) ? <ErrorMessage>Amount  is more than the required amount</ErrorMessage>:null}
                                                </Col>
                                            </Form.Group>
                                            :
                                            null
                                    }

                                    {/*{*/}
                                    {/*    isBaseCurrency ?*/}
                                    {/*        <Form.Group as={Row} >*/}
                                    {/*            <Col xs={12} sm={12} md={12} lg={12} xl={3}>*/}
                                    {/*                <Label  name="Amount Received(SSP)"/>*/}
                                    {/*            </Col>*/}

                                    {/*            <Col xs={12} sm={12} md={10} lg={10} xl={9}>*/}
                                    {/*                <InputGroup className="mb-3">*/}
                                    {/*                    <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">SSP</InputGroup.Text>*/}

                                    {/*                    <TextField submitted={submitted} type="text" value={ isNotBase ? paidAmount : sspAmount} style={{pointerEvents:'none'}}/>*/}

                                    {/*                </InputGroup>*/}

                                    {/*                /!* {submitted && status ==='2' &&  isNotBase && usdAmount && usdAmount < total_amount ? <ErrorMessage>Amount  is less than the required amount</ErrorMessage>:null} *!/*/}

                                    {/*                {submitted && status ==='2' &&  isBaseCurrency && sspAmount &&  sspAmount > total_amount ? <ErrorMessage>Amount  is more than the required amount</ErrorMessage>:null}*/}
                                    {/*            </Col>*/}
                                    {/*        </Form.Group>*/}
                                    {/*        :*/}
                                    {/*        null*/}
                                    {/*}*/}

                                    {/*<Form.Group as={Row}>*/}
                                    {/*    <Col xs={12} sm={12} md={12} lg={12} xl={3}>*/}
                                    {/*        <Label name={`Change ${currency.split("-")[2] ?? ""}`}/>*/}
                                    {/*    </Col>*/}

                                    {/*    <Col xs={12} sm={12} md={10} lg={10} xl={9}>*/}
                                    {/*        <TextField name='balance' type="text" value={!isExcluded ? 0 :  balance} readOnly/>*/}
                                    {/*    </Col>*/}
                                    {/*</Form.Group>*/}

                                </div>
                            </div>
                        </div>

                        <div className="other-billing-details mb-3">
                            <div className="service-details">
                                {
                                    groupedServices.length > 0 ?

                                        <ReusableBillTable
                                            services={groupedServices}
                                            isInPatient={true}
                                            handleDelete={handleDeleteServices}
                                            headData={ unpaid.filter(item=>Boolean(item))}
                                            title="Service(s)"
                                            isCurrency={isNotBase}
                                            handleChangeRate={handleChangeServiceRate}
                                            handleChangeCurrencyRate={handleChangeServiceCurrencyRate}
                                            {...{handlePayProcedure, checkAllServices, handlePayAllProcedures,isPatientDeposit}}
                                        >
                                        </ReusableBillTable>
                                        :
                                        null
                                }

                                {
                                    groupedProducts.length > 0 ?

                                        <ProductsTable
                                            {...{handleChangeProductQuantity, handleDeleteProduct}}
                                            products={groupedProducts}
                                            submitted={submitted}
                                            headData={productHeadData.filter(item=>Boolean(item))}
                                            handleChangeProductRate={handleChangeProductRate}
                                            isInPatient={true}
                                            isCurrency={isNotBase}
                                            handleChangeProductCurrencyRate={handleChangeProductCurrencyRate}
                                            {...{handlePayProduct, checkAllProducts, handlePayAllProducts,isPatientDeposit}}
                                        >
                                        </ProductsTable>
                                        :
                                        null
                                }

                                {
                                    bedBill.length > 0 &&
                                    <table className="table table-bordered table-sm billing-invoice-table">
                                        <thead>
                                        <tr>
                                            <td colSpan={bedHeadData.length} align="center">Bed Payment</td>
                                        </tr>

                                        <tr>

                                            {bedHeadData.filter(item=>Boolean(item)).map((item, index) => {
                                                return  item === 'Pay' ? (<td key={index}>
                                                        <Form.Check type='checkbox' label={item} id='pay_all_beds'
                                                                    checked={checkAllBeds} onChange={handlePayAllBeds}/>
                                                    </td>):
                                                    (<td key={index}>{item}</td>)
                                            })}
                                            {/*{bedHeadData.filter(item=>Boolean(item)).map((item, index) => (<td key={index}>{item}</td>))}*/}
                                        </tr>
                                        </thead>
                                        <tbody>

                                        {
                                            bedBill.map((bed, index) =>{

                                                return (
                                                    <tr key={index}>
                                                        <td>{bed.ward_name}</td>
                                                        <td>{bed.room_name}</td>
                                                        <td>{bed.bed_number}</td>
                                                        <td>{formatDateTime(bed.assign_date)}</td>
                                                        <td>{formatDateTime(bed.discharge_date)}</td>
                                                        <td>{bed.quantity}</td>
                                                        <td>{bed.rate}</td>
                                                        {isNotBase ? <td>{bed.currency_rate}</td> : null}
                                                        <td>{bed.amount}</td>
                                                        {isNotBase ? <td>{bed.currency_total}</td> : null}
                                                        {!isPatientDeposit ? <td>
                                                            { bed.pay_status === 0 ?  <Form.Check type='checkbox' checked={bed.isPaid}
                                                                        onChange={(e) => handlePayBed(e, bed)}/>:
                                                                <FinishedStatus>Paid</FinishedStatus>}
                                                        </td> : null}
                                                    </tr>
                                                )
                                                }
                                            )}

                                        </tbody>

                                    </table>
                                }

                            </div>
                        </div>

                        <button
                            type="button"
                            onClick={handleAddProduct}
                            className="btn btn-sm btn-primary mr-3"
                            disabled={isSubmitted === 'pending'}
                        >
                            Add Sundries
                        </button>

                        <button
                            type="button"
                            onClick={handleAddService}
                            className="btn btn-sm btn-secondary mr-3"
                            disabled={isSubmitted === 'pending'}
                        >
                            Add Service
                        </button>

                        <button
                            type="submit"
                            className="btn btn-sm sms-info-btn"
                            disabled={isSubmitted === 'pending'}
                        >
                            {isSubmitted === 'pending' ? "Saving..." : "Save"}
                        </button>
                    </form>

                </Container>
            </div>
        </>
    );
}


function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(InpatientBillFormTwo);
