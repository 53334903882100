import React, { useEffect, useState } from "react";
import { useVisitPatientTriage } from "../../../Triage/hooks/useVisitPatientTriage"
import { useRetrieveVisits } from "../../CustomHooks/useRetrieveVisits"
import { useSelfRequestPrescription } from "../../NewService/hooks/usePrescriptionSelfRequest";
import { useNewServiceRequest } from "../../NewService/hooks/useNewServiceRequest";
import { useQuery } from "react-query";
import axios from "axios";
import { config } from "../../../../Helpers/env";
import moment from "moment";
import {dateConvert} from "../../../Utils/ConvertDate";
import {errorMessages} from "../../../../Helpers/ErrorMessages";

export const useVisitList = (actions, activity_id = null) => {

    const [ids, setIds] = useState({});
    const [openDialog, setOpenDialog] = useState(false);
    const [visitList, setVisitList] = useState({ visits: [], total_count: 0 });
    const [tabValue, setTabValue] = useState(0);
    const [filters, setFilters] = useState({
        visitType: "",
        startDate: dateConvert(),
        endDate: dateConvert(),
        page: 1,
        perPage: 100,
        download: false,
        search: "",
    });

    const [openCancel, setOpenCancel] = useState(false)
    const [cancel_visit_id, setCancelId] = useState('')
    const [isCanceled, setIsCanceled] = useState('idle')
    const [title, setTitle] = useState('')

    const isCancelResolved = isCanceled === 'resolved'

    const { patient_number, visit_id, visit_type } = ids

    const obj = { patient_number, visit_id, visit_type, setOpenDialog, activity_id, actions }

    const { renderPrescription, isResolved: isResolvedPrescription, handleClosePrescriptionDialog } = useSelfRequestPrescription(obj)

    const { renderService, isResolvedService, handleCloseServiceDialog } = useNewServiceRequest(obj)

    const { triageProps } = useVisitPatientTriage(actions, obj)

    const { isResolved: isTriage, handleSubmitTriage, handleEditTriage } = triageProps;

    const isResolved = isTriage || isResolvedPrescription || isResolvedService;


    // const {
    //     searchValue,
    //     data,
    //     activePage,
    //     // isError,
    //     // isLoading,
    //     // isSuccess,
    //     // handleSearch,
    //     // handleChangeNewPage,
    //     cancelProps } = useRetrieveVisits(actions, isResolved, 'cvisit/retrieve_all_visit_with_triage');

    const visitListQuery = useQuery(
        ["visitListQuery", {...filters, isCancelResolved}],
        async () => {
            try {

                const response = await axios.get(
                    `${config.smsUrl}/cvisit/visits`,
                    {
                        params: {
                            start_date: moment(filters?.startDate).format("YYYY-MM-DD"),
                            end_date: moment(filters?.endDate).format("YYYY-MM-DD"),
                            // download: !(filters?.download),
                            visit_type: filters?.visitType,
                            page: filters?.page,
                            per_page: filters?.perPage,
                            search: filters?.search,
                        },
                    }
                );

                return response.data;
            } catch (error) {
                //debugger
                //console.log(error);
            }
        },
        {
            retry: 1
        }
    );

    const { isError, isLoading, isSuccess, data: visitListData } = visitListQuery ?? { isSuccess: false, isError: true, isLoading: false, data: { items: [], pages: 0, total: 210 } };

    // const visitListData = visitListQuery?.isSuccess ? visitListQuery?.data : []; 

    /*useEffect(() => {
        const list = !data.triage_list ? [] : data.triage_list;

        const count = !data.total_count ? 0 : data.total_count;

        if (!(list && count)) {
            return
        }

        const arr = list.map((item, index) => ({
            ...item,
            count: (activePage - 1) * 10 + (index + 1),
            date: item.begin_datetime,
            visit_id: item.visit_id,
            patient_number: item.patient_number,
            patient_name: `${item.patient_fn} ${item.patient_ln}`,
            status: item.status,
            referal_status: item.referal_status,

        }))

        setVisitList({ visits: arr, total_count: count });

    }, [data]) */

    const handlePatientDetails = (item) => {
        setIds(item);
    }

    const handleOpenDialog = (item, screen) => {
        setOpenDialog(true)
        const { visit_id, patient_number, status, patient_name, age, gender } = item
        setIds({ visit_id, patient_number, status, patient_name, age, gender, screen });

    };

    const handleCloseDialog = () => {
        // setOpenDialog(false)
        if (tabValue === 1) {
            handleClosePrescriptionDialog()
        } else {
            handleCloseServiceDialog()
        }

    };

    const handleChangeTabValue = (event, val) => {
        setTabValue(val)
    }

    const closeSnackBar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const handleChangeFilter = (event) => {
        event.preventDefault();

        setFilters({
            ...filters,
            [event?.target?.name]: event?.target?.value
        });
    }

    const handleDownloadExcel = () => { }

    const handleDownloadPDF = () => { }

    const handleChangePage = (event, page) => {
        setFilters({
            ...filters,
            page: page < 1 ? 1 : page,
        });
    }

    const handleChangeRowsPerPage = (event, muiEvent) => {

        // console.log(event, muiEvent)

        setFilters({
            ...filters,
            perPage: event?.target?.value,
            page: 1,
        });
    }

    const handleOpenCancel = (id, action) => {
        setCancelId(id)
        setTitle(action)
        setOpenCancel(true)

    };

    const handleCloseCancel = () => {
        setOpenCancel(false)
    };

    const handleCancel = (action) =>{
        setIsCanceled('pending')
        axios.post(`${config.smsUrl}/cvisit/delete_visits_without_requests`,{visit_id: cancel_visit_id}).then(() => {
            actions.snackbarActions.snackSuccess(`Visit ${action} successfully`);
            setIsCanceled('resolved')
            setOpenCancel(false)
        }).catch(err => {
            errorMessages(err, null, actions)
            setIsCanceled('rejected')
        })
    }

    const cancelProps = {openCancel, handleCancel, handleOpenCancel, handleCloseCancel}


    return {
        /*searchValue, activePage, handleSearch, handleChangeNewPage,*/ closeSnackBar, openDialog, handleEditTriage,
        visitList, handleOpenDialog, handleCloseDialog, triageProps, isTriage, renderService, renderPrescription,
        ids, isLoading, isSuccess, isError, handlePatientDetails, handleSubmitTriage, tabValue, handleChangeTabValue, cancelProps, filters, handleChangeFilter, handleDownloadExcel, handleDownloadPDF,
        handleChangePage, handleChangeRowsPerPage, visitListData, title
    }
}
