import React from 'react';
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {makeStyles} from "@material-ui/core/styles";
import {history} from "../../../Helpers/history";
import {BackButton} from "../Buttons/DataExportationButton";
import { useVersionsContext } from '../../../Context/versions-context';

export const doctorRoutes = (patient_number, visit_id, patient_type,patient_admission_id = null) => {
    const procedureUrl = patient_type === '1' ?  'outpatientprocedure' : 'inpatientprocedure'
    const labUrl = patient_type === '1' ?  'outpatientlab' : 'inpatientlab'
    const radiologyUrl = patient_type === '1' ?  'outpatientradiology' : 'inpatientradiology'
    const {subscription} = useVersionsContext()
    const version = subscription?.version

    const arr =  [
        {
        text:'Medical History',
        path: `/previousvisits/${patient_number}/${visit_id}/${patient_type}/${patient_admission_id}`
        }, {
        text: 'Triage',
        path: `/outpatienttriage/${patient_number}/${visit_id}/${patient_type}/${patient_admission_id}`
    },
        {
        text: `${patient_type === '1' ? 'Clinical Notes' :'Findings'}`,
        path: `/examinationnotes/${patient_number}/${visit_id}/${patient_type}/${patient_admission_id}`
    },
        {text: 'Procedure', path: `/${procedureUrl}/${patient_number}/${visit_id}/${patient_type}/${patient_admission_id}`},
        {
            text: 'Laboratory',
            path: `/${labUrl}/${patient_number}/${visit_id}/${patient_type}/${patient_admission_id}`
        },
        {text: 'Radiology', path: `/${radiologyUrl}/${patient_number}/${visit_id}/${patient_type}/${patient_admission_id}`},
        {text: 'Diagnosis', path: `/doctordiagnosis/${patient_number}/${visit_id}/${patient_type}/${patient_admission_id}`},
        {
            text: 'Prescription',
            path: `/doctorprescription/${patient_number}/${visit_id}/${patient_type}/${patient_admission_id}`
        },
        // {
        //     text: 'Antenatal Care',
        //     path: `/doctorantenatal/${patient_number}/${visit_id}/${patient_type}/${patient_admission_id}`
        // },
        // added the surgery ui path, it is for both in and out patients
        (version === "Premium" || version === "Standard") ?{
            text: 'Surgery',
            path: `/surgerydoctor/${patient_number}/${visit_id}/${patient_type}/${patient_admission_id}`
        }:null,
        (patient_type === '1') && (version === "Premium" || version === "Standard") ? {
            text: 'Admission',
            path: `/doctoradmission/${patient_number}/${visit_id}/${patient_type}/${patient_admission_id}`
        }:null,
        {
            text: 'View Treatment Details',
            path: `/doctortreatment/${patient_number}/${visit_id}/${patient_type}/${patient_admission_id}`
        },
        {
            text: 'Referral Note',
            path: `/referralnote/${patient_number}/${visit_id}/${patient_type}/${patient_admission_id}`
        },
        patient_type === '2' ? {
            text: 'Discharge Note',
            path: `/inpatientdischarge/${patient_number}/${visit_id}/${patient_type}/${patient_admission_id}/doctor`
        }:null]
    return arr.filter(item=>Boolean(item))
}


export const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        // backgroundColor: theme.palette.background.paper,
        paddingTop: 0,
        paddingBottom: 0
    },
    pathListItem:{
        borderBottom: '1px solid #e8e8e8',
    },
    tabListItem:{
        borderBottom: '0.75px solid #e8e8e8',
    },
    listItem: {
        paddingTop: '10px',
        paddingBottom: '10px',
        cursor: 'pointer',
        textTransform:'capitalize',
        '&:hover': {
            borderRight: "2px solid #3f51b5",
            color: '#3f51b5',
            background: 'rgba(14, 118, 186, .08)'
        }
    },
    activeLi: {
        borderRight: "2px solid #3f51b5",
        color: '#3f51b5',
    },
    primary: {
        fontSize: '13.86px'
    },
    item2:{
         borderLeft: '1px solid #c7c6c7',
    },
    pathItem:{
        height: '100vh',
    },
    item: {
        // borderRight: '1px solid #c7c6c7',
        overflow: "auto",
        '&::-webkit-scrollbar': {
            width: "5px",
            height: "5px"
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#c7c6c7',
            "-webkit-border-radius": "10px",
            borderRadius: "10px",
        }
    },
    tabItem:{
        height:"auto"
    }
}));

const ReusableDoctorTabs = ({children, patient_type=1, components = []}) => {
    const classes = useStyles();
    const text = patient_type === 1 ? 'waiting list' : 'admitted patients'
    const url = patient_type === 1 ? '/waitinglist':'/patientroundlist'
    return (
        <div>
            {patient_type !== null ? <div className="general-ledger-header mb-0">
                <BackButton text={text} to={url}/>
            </div> : null}
            <Grid container spacing={0}>
                <Grid item xs={4} md={2} sm={2} lg={2} classes={{item: `${classes.item} ${classes.pathItem}`}}>
                    {components.filter(Boolean).map((item, index) => (
                        <List className={classes.root} key={index}>
                            <ListItem
                                classes={{root: `${classes.listItem} ${classes.pathListItem} ${history.location.pathname === item.path ? classes.activeLi : ''}`}}
                                onClick={() => {
                                    history.push(item.path)
                                }}>
                                <ListItemText primary={item.text} classes={{
                                    primary: classes.primary,
                                }}/>
                            </ListItem>
                        </List>
                    ))}

                </Grid>
                <Grid item sm={10} xs={8} md={10} lg={10} classes={{item: classes.item2}}>
                    {children}
                </Grid>
            </Grid>
        </div>

    )
}

export const PreviousHistoryTabs = ({handleClick, item }) => {
    const classes = useStyles();
    return (
                <List className={classes.root} >
                    <ListItem
                        classes={{root: `${classes.listItem} ${item.isActive ? classes.activeLi : ''}`}}
                        onClick={handleClick}>
                        <ListItemText primary={item.value} classes={{
                            primary: classes.primary,
                        }}/>
                    </ListItem>
                </List>
    )
}


export default ReusableDoctorTabs;