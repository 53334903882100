import {useState, useEffect} from "react";
import axios from "axios";
import {config} from "../../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import {convertPhone} from "../../../Utils/convertPhone";

export const useAddSupplier = (actions) =>{
    const [state, setState] = useState({supplier_name: '', mobile: '', email: '', address: ''});
    const [submitted, setSubmitted] = useState(false);
    const [isSubmittedSupplier, setIsSubmittedSupplier] = useState('idle');
    const [contact, setContact] = useState();
    const [openAddSupplier, setOpenAddSupplier] = useState(false)
    const [debitLedger, setDebitLedger] = useState('')
    const [creditLedger, setCreditLedger] = useState('')
    const [openDialog, setOpenDialog] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const [supplier, setSupplier] = useState({});
    const [supplier_id, setSupplierId] = useState('')



    // useEffect(()=>{
    //     axios.post(`${config.smsUrl}/accounting/chart/parent_account`).then(res=>{
    //         const data = res.data;
    //         const dt = !data ? {} : data;
    //         const arr = dt.data ? dt.data : []
    //         const items = arr.map(item=>({
    //             value:item.head_code,
    //             label:`${item.head_code} - ${item.name}`
    //         }))
    //         setAccounts(items)
    //     }).catch(err=>{
    //         logoutErrorMessage(err, null, actions)
    //     })
    // },[]);




    useEffect(()=>{
        if(!supplier){
            return
        }
        const {supplier_name, supplier_email,supplier_contact, supplier_adress} = supplier;
        setState({supplier_name, email:supplier_email, mobile:supplier_contact, address:supplier_adress});
        setContact(supplier_contact)
    },[supplier]);

    const handleChange = (event) => {
        const {name, value} = event.target;
        setState({...state, [name]: value})
    };

    const handleChangeDebitLedger = (value) =>{
        setDebitLedger(value)
    }

    const handleChangeCreditLedger = (value) =>{
        setCreditLedger(value)
    }

    const handleOpenDialog = (id) =>{
        setSupplierId(id)
        setOpenDialog(true)
        if(id){
            const formData = new FormData();
            formData.append('supplier_id', id);
            axios.post(`${config.smsUrl}/csupplier/supplier_update_form`, formData).then(res=>{
                const data = res.data;
                const dt = !data ? {} : data;
                setSupplier(dt)
            }).catch(err=>{
                logoutErrorMessage(err, null, actions)
            })
        }
    }

    const handleCloseDialog = () =>{
        setOpenDialog(false)
        setState({supplier_name: '', mobile: '', email: '', address: ''})
        setSupplierId('')
        setContact(undefined)
        setDebitLedger({value:'', label:'Select...'})
        setCreditLedger({value:'', label:'select...'})
    }

    const handleSubmit = (event) =>{
        event.preventDefault();
        const credit = creditLedger ? creditLedger : {};
        const credit_account = credit.value ? credit.value : '';

        const debit = debitLedger ? debitLedger : {};
        const debit_account = debit.value ? debit.value : '';
        setSubmitted(true);
        const formData = new FormData(event.currentTarget);
        const fieldValues = Object.fromEntries(formData.entries())
        if(supplier_id){
            formData.append('supplier_id', supplier_id);
        }
        formData.append("supplier_contact", contact ? convertPhone(contact): contact);
        formData.append('debit_account', debit_account);
        formData.append('credit_account', credit_account);
        const url = supplier_id ? 'supplier_update' : 'insert_supplier'
        if (fieldValues['supplier_name'] && contact){
            setIsSubmittedSupplier('pending');
            axios.post(`${config.smsUrl}/csupplier/${url}`, formData).then(()=>{
                setIsSubmittedSupplier('resolved');
                actions.snackbarActions.snackSuccess(`Supplier ${supplier_id ? 'updated': 'added'} successfully`);
                setOpenAddSupplier(false)
                setOpenDialog(false)
                setSubmitted(false)
                setContact(undefined)
            }).catch(err=>{
                setIsSubmittedSupplier('rejected');
                errorMessages(err, null, actions)
            })
        }
    };

    const handleReset = () =>{
        setState({supplier_name: '', mobile: '', email: '', address: ''})
    };

    const handleCloseSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };
    const isPending = isSubmittedSupplier === 'pending';
    const isResolved = isSubmittedSupplier === 'resolved';
    const isRejected = isSubmittedSupplier === 'rejected'

    const supplierProps = {state, submitted, handleChange, handleSubmit, handleReset, 
        contact, setContact, isPending, isResolved, isRejected, debitLedger, creditLedger,
    handleChangeCreditLedger, handleChangeDebitLedger, accounts, supplier_id}

    return {supplierProps,handleCloseSnackbar,openAddSupplier, 
        setOpenAddSupplier, isPending, isResolved, isRejected,
    openDialog, handleOpenDialog, handleCloseDialog}
}