import React from 'react';
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Card from "@material-ui/core/Card";
import {useTheme} from "@material-ui/core/styles";
import {Link} from "react-router-dom";
import {faListAlt} from "@fortawesome/free-solid-svg-icons/faListAlt";
import FormGroup, {FormTextArea} from "../../Utils/FormInputs/FormGroup";
import {titleCase} from "../../Users/addUser";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Label from "../../Utils/FormInputs/Label";
import Col from "react-bootstrap/Col";
import {CustomSelect} from "../../Utils/FormInputs/SelectInput";
import {faBookMedical} from "@fortawesome/free-solid-svg-icons/faBookMedical";
import { BackButton } from '../../Utils/Buttons/DataExportationButton';

const searchLabel = {
    'patient_no':'Patient No.',
    'patient_name':'Patient Name',
    'phone_no':'Phone No.'
}

const AddEditDeathRegistration = ({handleSubmit, title, subTitle, state, handleChange, submitted, isSubmitted,  handleReset,
                                      registerDeath,p_number, handleChangePatientNumbers, showNumber, patientNumbers,
                            handleClickPatientNumber, reported_by, patient,nationality,countries, handleChangeCountry,wards,ward, handleChangeWard, deathDetails,
                                      updateDeath, search_parameter, handleChangeSearchParam, visit_id}) => {
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    const { death_date, death_time, district, patient_type, witnessed_by, religion, tribe, recieved_by, occupation, bed_no, witness_tel, recievedby_tel, witness_rel, recievedby_rel, immediate, underlying, omorbid_illness} = state;
    const {patient_name,dob, gender,residence, age, patient_number} = patient
    const ward_options = wards.map((item)=>({label: item.name, value: item.ward_id}))
    return (
        <div data-testid="add-edit-death">
            <PageTitle title={title}/>
            <SubHeader title="Reports" subTitle={subTitle}>
                <FontAwesomeIcon icon={faBookMedical}/>
            </SubHeader>
            <div className="add-bill-card">
                <div style={{margin: '10px'}}>
                    <BackButton text={'Death Reports'} to={'/reports/deathreports'}/>
                </div>
                <Card className="p-3 general-card">
                    <h6 className={`visit-heading ${palette.type === "dark" && "dark-visit-heading"}`}>{title}</h6>
                    <form autoComplete="off" onSubmit={handleSubmit} data-testid="add-edit-death-form">
                        <div className="row">
                            <div className="col-md-6">
                            { !updateDeath && 
                                <>
                                <Form.Group as={Row}>
                                        <Label column sm="4" name="Search by:" type/>
                                        <Col sm={8}>
                                            <Form.Check type="radio" inline label="Patient No." name="search_parameter" value="patient_no"
                                                        checked={search_parameter === "patient_no"}
                                                        onChange={handleChangeSearchParam} data-testid="patient_no"/>
                                            <Form.Check type="radio" inline label="Patient Name" name="search_parameter" value="patient_name"
                                                        checked={search_parameter === "patient_name"}
                                                        onChange={handleChangeSearchParam} data-testid="patient_name"/>
                                            <Form.Check type="radio" inline label="Phone No." name="search_parameter" value="phone_no"
                                                        checked={search_parameter === "phone_no"}
                                                        onChange={handleChangeSearchParam} data-testid="phone_no"/>
                                        </Col>
                                    </Form.Group>
                                <Form.Group as={Row}>

                                    <Label name={searchLabel[search_parameter]} column sm="4" type/>
                                    <Col sm="7">
                                        <Form.Control type="text" disabled={updateDeath}
                                                      className={`form__control ${palette.type === "dark" && 'form-control-dark'}`}
                                                      name="p_name"
                                                      value={p_number}
                                                      onChange={handleChangePatientNumbers} id="patient_id"/>
                                        {(submitted && (!visit_id)) &&
                                        <div className="help-block">Missing name or visit</div>}
                                        {showNumber && <Form.Control as="select" multiple id="patient_number_select"
                                                                     className="drug-ul visit-ul">
                                            {patientNumbers.map((number, index) => (
                                                <option className="drug-li" key={index}
                                                        onClick={()=>handleClickPatientNumber({label: number.label, value: number.value})}
                                                        value={number.value} id="numbers">{number.label}</option>
                                            ))}
                                        </Form.Control>}
                                    </Col>
                                </Form.Group>
                                </>
                            }
                                <FormGroup name="patient_number" value={patient_number} disabled={true}
                                            colSm={7} labelSm={4} type="text" labelName="Deceased No."
                                           dataTest="patient_number" />
                                <FormGroup name="dob" disabled={true} value={dob}
                                           colSm={7} labelSm={4} type="date" labelName="Date of Birth"
                                           dataTest="dob"/>
                                <FormGroup name="gender" value={gender ? titleCase(gender):''} disabled={true}
                                           colSm={7} labelSm={4} type="text" labelName="Gender"
                                           dataTest="gender"/>
                                {/* <FormGroup name="residence" value={residence} disabled={true}
                                           colSm={7} labelSm={4} type="text" labelName="Residence"
                                           dataTest="residence"/> */}
                                <Form.Group as={Row}>
                                    <Label column sm={4} name="Nationality" type/>
                                    <Col sm={7}>
                                      <CustomSelect value={nationality} onChange={handleChangeCountry} options={countries} id={'nationality'}/>
                                        {(submitted && !nationality) &&
                                        <div className="help-block" id="status_help_block">Nationality is required</div>}
                                    </Col>
                                </Form.Group>
                                <FormGroup name="district" value={district} onChange={handleChange}
                                           colSm={7} labelSm={4} type="text" labelName="District"
                                           dataTest="district"/>
                                           <FormGroup name="religion" value={religion} onChange={handleChange}
                                           colSm={7} labelSm={4} type="text" labelName="Religion"
                                           dataTest="religion"/>
                             <FormGroup name="tribe" value={tribe} onChange={handleChange}
                                           colSm={7} labelSm={4} type="text" labelName="Tribe"
                                           dataTest="tribe"/>
                            <FormGroup name="occupation" value={occupation} onChange={handleChange}
                                           colSm={7} labelSm={4} type="text" labelName="Occupation"
                                           dataTest="occupation"/>
                                           

                              

                            </div>
                            <div className="col-md-6">
                           
                                <FormGroup name="death_date" value={death_date} onChange={handleChange}
                                           colSm={7} labelSm={4} type="date" labelName=" Date of Death"
                                           dataTest="death_date" labelType submitted={submitted}/>
                                <FormGroup name="death_time" value={death_time} onChange={handleChange}
                                           colSm={7} labelSm={4} type="time" labelName="Time of Death"
                                           dataTest="death_time" labelType submitted={submitted}/>
                                         
                                <Form.Group as={Row}>
                                    <Label column sm={4} name="Patient Type" />
                                    <Col sm={7}>
                                        <Form.Check type="radio" inline label="OPD" name="patient_type" value={1}
                                                    checked={patient_type == 1}
                                                    onChange={handleChange} id="opd"/>
                                        <Form.Check type="radio" inline label="IPD" name="patient_type" value={2}
                                                    checked={patient_type == 2}
                                                    onChange={handleChange} id="ipd"/>
                                        {(submitted && !patient_type) &&
                                        <div className="help-block" id="status_help_block">Patient type is required</div>}
                                    </Col>
                                </Form.Group>
                                {patient_type == 2 && 
                               <> <Form.Group as={Row}>
                                    <Label column sm={4} name="Ward" />
                                    <Col sm={7}>
                                        <CustomSelect value={ward} onChange={handleChangeWard} options={ward_options} id={'ward'}/>
                                        {(submitted && patient_type == 2 && !ward) &&
                                        <div className="help-block" id="status_help_block">Ward is required</div>}
                                    </Col>
                                </Form.Group>

                                <FormGroup name="bed_no" value={bed_no} onChange={handleChange} submitted={patient_type == 2 ? submitted : null}
                                            colSm={7} labelSm={4} type="number" labelName="Bed No." labelType
                                            dataTest="bed_no" /></>}
                               
                                <FormTextArea onChange={handleChange} colSm={7} labelSm={4} value={immediate} submitted={submitted}
                                              name="immediate" labelName="Immediate Cause of Death" dataTest="immediate_cause_of_death" labelType/>

                               <FormTextArea onChange={handleChange} colSm={7} labelSm={4} value={underlying} submitted={submitted}
                                              name="underlying" labelName="Underlying Cause of Death" 
                                            dataTest="underlying_cause_if_death" labelType/>

                                            <FormTextArea onChange={handleChange} colSm={7} labelSm={4} value={omorbid_illness} submitted={submitted}
                                              name="omorbid_illness" labelName="
                                              Comorbid Illness" dataTest="comorbid_illness" labelType/>
                               

                            </div>
                           
                        </div>
                    <h6 className={`visit-heading ${palette.type === "dark" && "dark-visit-heading"}`}>{}</h6>

                        <div className='row'>
                            
                        <div className='col-md-6'>
                            <FormGroup name="recieved_by" value={recieved_by} onChange={handleChange}
                                           colSm={7} labelSm={4} type="text" labelName="Recieved By"
                                           dataTest="recieved_by" />

                                            <FormGroup name="recievedby_rel" value={recievedby_rel} onChange={handleChange}
                                           colSm={7} labelSm={4} type="text" labelName="Relationship"
                                           dataTest="recieved_by_rel"/>
                                            <FormGroup name="recievedby_tel" value={recievedby_tel} onChange={handleChange}
                                           colSm={7} labelSm={4} type="text" labelName="Tel"
                                           dataTest="recievedby_tel"/>
                        </div>

                        <div className='col-md-6'>
                                <FormGroup name="witnessed_by" value={witnessed_by} onChange={handleChange}
                                            colSm={7} labelSm={4} type="text" labelName="Witnessed By" 
                                            dataTest="witnessed_by"  />
                                            <FormGroup name="witness_rel" value={witness_rel} onChange={handleChange}
                                           colSm={7} labelSm={4} type="text" labelName="Relationship"
                                           dataTest="witness_rel"/>
                                            <FormGroup name="witness_tel" value={witness_tel} onChange={handleChange}
                                           colSm={7} labelSm={4} type="text" labelName="Tel"
                                           dataTest="witness_tel"/>
                            </div>
                        </div>
                        <div className="text-right mt-2">
                            {/*registerDeath && <button type="reset" id="reset_form" onClick={handleReset}
                                    data-testid="reset-death"   className="sms-grey-btn btn btn-sm px-3 mr-2">Reset
                            </button> */}
                            <button type="submit" disabled={!!isSubmitted} data-testid="save-death"
                                    className="btn sms-btn btn-sm">{isSubmitted ? "Saving..." : "Save"}</button>
                        </div>
                    </form>
                </Card>
            </div>
        </div>
    )
};
export default AddEditDeathRegistration;
