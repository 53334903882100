/** @jsxImportSource @emotion/react */
import {css } from '@emotion/react'
import React from "react";
import {formatDateTime} from "../Utils/ConvertDate";
import {titleCase} from "../Users/addUser";
import {detailsTable, table, tableBordered, tableMargin, textWeight} from "../../styles/tableStyles";
import {splitNotes} from "../Radiology/RadiologyReport";


const zeroMargin = css`
  margin-bottom: 0;
`


const zeroBorder = css`
     border-bottom: 0;
 `



const headData = [{key:'parameter',value:'Test'}, {key:'result',value:'Result'}, //{key:'flag',value:'flag'},
    {key:'unit',value:'Unit'},{key:'ref_range',value:'Reference Range'}]

const noneRefData =  [{key:'parameter',value:'Parameter'}, {key:'result',value:'Result'}]

const QualitativeTable = ({test_name, params,comments}) =>(
    <table css={[table,detailsTable, tableMargin, tableBordered, zeroMargin]}>
        <tbody>
        <tr>
            <td colSpan={2} css={[textWeight]}><span><strong>{test_name}</strong></span></td>
        </tr>
        <tr>
            <td><span><strong>Date</strong></span></td>
            <td><span><strong>Result</strong></span></td>
        </tr>
        {params.map(test => {
            const isQualitative = (
                test.sub_test_name === '+ve/-ve' ?
                    <span>{test.test_value === '1' ? 'Positive' :
                        'Negative'}</span> : test.sub_test_name === 'reactive/non-reactive' ?
                        <span>{test.test_value === '3' ? 'Reactive' : 'Non-reactive'}</span> :
                        test.sub_test_name === 'blood_group' ?
                            <span>{test.test_value}</span> : null
            )
            return(
                <tr>
                    <td>
                        <span>{test.date_created ? formatDateTime(test.date_created): test?.date_created}</span>
                    </td>
                    <td>
                        <span>{test.result_type === 3 ? splitNotes(test.test_value) : isQualitative}</span>
                    </td>
                </tr>
            )
        })}
        {comments ? <tr>
            <td css={[zeroBorder]} colSpan={2} style={{paddingBottom: 0}}>
                <span css={[textWeight]}>Comments</span><br/>
                <span>{comments ? splitNotes(titleCase(comments)) : comments}</span>
            </td>
        </tr>: null}
        </tbody>
    </table>
)


export const InpatientLabReport = ({item}) => {
    const para = item.params ? item.params : [];
    const pr = para[0] ? para[0] : {};
    const comments = pr.notes ? pr.notes : '';
    const isReferenceRange =item?.params.some(item=>item.reference_range)

    const parentHeadData = (
        <tr>
            <td colSpan={headData.length} css={[textWeight]} ><span><strong>{item?.hospital_test_name}</strong></span></td>
        </tr>
    )


    const groupByDate = (arr=[]) =>{
        let result = []
        let knowledge = {}
        arr.forEach(item =>{
            let date = item.date_created;
            if(date in knowledge){
                result[knowledge[date]].params.push(item)
            }else {
                knowledge[date] = result.length
                result.push({
                    date,
                    params: [item]
                })

            }
        })
        return result
    }


    const quantitativeTests = groupByDate(item.params.filter(item=>item.result_type === 2))


    return (
        isReferenceRange  ?
            <table css={[table, detailsTable, tableMargin, tableBordered, zeroMargin]} data-testid={'quantitative-table'}>
                <tbody>
                {parentHeadData}
                {quantitativeTests.map((param) => {
                    return (
                        <>
                            <tr>
                                <td colSpan={headData.length}><strong>{param.date ? formatDateTime(param.date) : param.date}</strong></td>
                            </tr>
                            <tr>
                                {headData.map((item, index) => (
                                    <td css={[textWeight]} key={index}><span><strong>{item.value}</strong></span></td>
                                ))}
                            </tr>
                            {param.params.map(test => {
                                return (
                                    <tr key={test.hospital_test_id}>
                                        <td>
                                            <span>{test.sub_test_name}</span>
                                        </td>
                                        <td>
                                            <span>{test.test_value}</span>
                                        </td>
                                        <td>
                                            <span>{test.unit ? test.unit : 'NA'}</span>
                                        </td>
                                        <td>
                                            <span>{test.reference_range ? test.reference_range : 'NA'}</span>
                                        </td>

                                    </tr>
                                )
                            })}
                        </>
                    )
                })}
                {comments ? <tr>
                    <td css={[zeroBorder]} colSpan={headData.length} style={{paddingBottom: 0}}>
                        <span css={[textWeight]}>Comments</span><br/>
                        <span>{comments ? splitNotes(titleCase(comments)) : comments}</span>
                    </td>
                </tr> : null}
                </tbody>
            </table> :
            <QualitativeTable test_name={item?.hospital_test_name} params={item.params} comments={comments}/>


    )
};



