import {useEffect, useRef, useState} from "react";
import axios from "axios";
import {config} from "../../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import {exportPDF} from "../../../Utils/downloadPDF";
import {dateStringConvert} from "../../../Utils/ConvertDate";

export const usePurchaseOrders = (actions, status=false, setId) =>{
    const [purchaseOrders, setPurchaseOrders] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState('')
    const [searchValue, setSearchValue] = useState('')
    const csvRef = useRef()

    const calculateTotalAmount = (orders) => {
        const sum = orders.reduce((a, purchase) => {
            return +(a + purchase.grand_total);
        }, 0);
        setTotalAmount(sum)
    };

    useEffect(() => {
        setLoading(true)
        axios.get(`${config.smsUrl}/cinventory/retrieve_all_purchase_orders`).then(response => {
            const data = response.data;
            const dt = !data ? {} : data;
            const list = !dt.purchaseorder_list ? [] : dt.purchaseorder_list;
            const item = list[0] ? list[0] : {};
            const {local_purchase_order_id, status} = item;
            setPurchaseOrders(list);
            calculateTotalAmount(list);
            setId({lpo_id:local_purchase_order_id, lpo_status:status})
            setLoading(false)
        }).catch(error => {
            // errorMessages(error, null, actions);
            setLoading(false)
        })
    }, [status])

    const handleChangeValue = (e) =>{
        setValue(e.target.value)

    }
    const filteredList = (arr) =>{
        return arr.filter((data) => {
            if (value === '')
                return data
            else if (String(data.status).includes(String(value))) {
                return data
            }
        })
    }

    const downloadPdf = () => {
        exportPDF("Purchase Orders", [["SL", "Order Date", "Order Date", "Supplier", "Total Amount"]],
            purchaseOrders.map((item) => [item.sl, dateStringConvert(item.create_date),  item.local_purchase_order_id,
                item.supplier_name,  item.grand_total
            ]),
            "Purchase Orders.pdf");

    }
    const downloadCsv = () => {
        csvRef.current.link.click()
    }

    const handleSearch = (val) =>{
        setSearchValue(val)
    }

    const handleCloseBar = () => {
        actions.snackbarActions.hideSnackbar();
    };
    return {totalAmount,purchaseOrders,loading,handleCloseBar, value,handleChangeValue,
        filteredList, searchValue, handleSearch, downloadCsv,downloadPdf, csvRef}
}