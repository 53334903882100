import React, {Fragment} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {formatDigits} from "../../Utils/formatNumbers";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Table  from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import {formatTreeData} from "./BalanceSheetTreview";
import TableHead from "@material-ui/core/TableHead";


const WINDOW_HEIGHT = window.innerHeight
const height = WINDOW_HEIGHT - 250

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        // maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
        // maxHeight: height,

    },
    listItem:{
        paddingTop:0,
        paddingBottom:0,
    },
    sub:{
        paddingLeft: theme.spacing(4),
    },
    nested: {
        paddingLeft: theme.spacing(6),
    },
    nestedLi: {
        paddingLeft: theme.spacing(8),
    },
    nestedChild: {
        paddingLeft: theme.spacing(10),
    },
    parentText:{
        fontSize:'1.2rem',
        fontWeight:600
    },
    childText:{
        fontSize:'1rem',
    },
    smallText:{
        fontSize:'0.9rem'
    },
    tableCell:{
        border:0,
        padding:'10px',
        // '':'1px solid rgb(181 177 177)'
    },
    coaCell:{
        // borderBottom:'1px solid rgb(181 177 177)',
        border:0,
        padding:'10px',
        // width:'100%'
    },
    table:{
        border:'1px solid rgb(223 219 219)'
    },
    container:{
        maxHeight: height,
    }
}));

export const CustomCell = ({children, ...props}) =>{
    const classes = useStyles();
    return(
        <TableCell className={classes.tableCell} {...props}>{children}</TableCell>
    )

}

export const CoaCustomCell = ({children, ...props}) =>{
    const classes = useStyles();
    return(
        <TableCell className={classes.coaCell} {...props}>{children}</TableCell>
    )

}



function BalanceSheetPrint({children,  data=[],header=null, footer=null}) {

    const tree_label = (str_type, nav, isheadLevel, padding, isParent) => {
        if (str_type === 'final_level') {
            return (
                <Fragment>
                    <TableRow>
                        <CoaCustomCell style={{paddingLeft:!isParent ?  '60px':''}} >{nav.account_name || nav?.total?.name}</CoaCustomCell>
                        {!nav?.total?.name ? <>
                            <CoaCustomCell align="center"><strong>{ nav.previous_total ? formatDigits( nav.previous_total) : nav.previous_total}</strong></CoaCustomCell>
                            <CoaCustomCell align="center"><strong>{ nav.current_total ? formatDigits( nav.current_total) : nav.current_total}</strong></CoaCustomCell>
                        </>: null}

                    </TableRow>
                </Fragment>

            )
        } else if (str_type === 'current_parent') {
            return (
                <Fragment>
                    <TableRow>
                        {nav.account_id ?  <CoaCustomCell  style={{fontWeight : isParent ? 'bold':'', paddingLeft:!isParent ? '60px' : ''}}>
                            <span style={{fontWeight : isParent ? 'bold':''}}>{nav.account_name || nav?.total?.name}</span>
                        </CoaCustomCell>:<CoaCustomCell  style={{fontWeight : isParent ? 'bold':'', paddingLeft:!isParent ? '30px' : ''}}>
                            <span style={{fontWeight : isParent ? 'bold':''}}>{nav.account_name || nav?.total?.name}</span>
                        </CoaCustomCell>}
                        {!nav?.total?.name ? <>
                            <CoaCustomCell align="center"><strong>{ nav.previous_total ? formatDigits( nav.previous_total) : nav.previous_total}</strong></CoaCustomCell>
                            <CoaCustomCell align="center"><strong>{ nav.current_total ? formatDigits( nav.current_total) : nav.current_total}</strong></CoaCustomCell>
                        </>: null}
                    </TableRow>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <TableRow>
                        <CoaCustomCell style={{fontWeight : isParent ? 'bold':'', paddingLeft:!(isheadLevel || isParent) ? '30px':''}} >{nav.account_name || nav?.total?.name}</CoaCustomCell>
                        {!nav?.total?.name ? <>
                            <CoaCustomCell align="center"><strong>{ nav.previous_total ? formatDigits( nav.previous_total) : nav.previous_total}</strong></CoaCustomCell>
                            <CoaCustomCell align="center"><strong>{ nav.current_total ? formatDigits( nav.current_total) : nav.current_total}</strong></CoaCustomCell>
                        </>: null}
                    </TableRow>
                </Fragment>
            )
        }
    }
    function nestedMenu(items) {
        const data = items ? items : []
        return data.map((nav) => {
            const head_level = nav.head_level ? nav.head_level : 0;
            const padding  = head_level === 0 ? 4 :  head_level * 8;
            const isTotal = nav?.account_name?.toLowerCase()?.includes('total') || nav?.total?.name?.toLowerCase()?.includes('total')
            const isParent = !!head_level || isTotal;
            const isheadLevel = head_level > 0;
            const nav_children = Array.isArray(nav.accounts) ? nav.accounts : formatTreeData(nav.accounts)

            if  (nav_children && nav_children.length === 0) {
                return (
                    <>
                        {nav.is_final_level ?
                            tree_label('final_level', nav, isheadLevel, padding, isParent):
                            tree_label('current_parent', nav, isheadLevel, padding, isParent)}
                    </>
                )
            }
            return (
                <>
                    {tree_label('parent', nav, isheadLevel, padding, isParent)}
                    {nestedMenu(nav_children)}
                </>
            )
        })
    }
    return (
        <Table>
            {header}
            <TableBody>
                {nestedMenu(data)}
                {children}
            </TableBody>
            <TableHead>
                {footer}
            </TableHead>
        </Table>
    )
}
export {BalanceSheetPrint}
