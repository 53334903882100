import { useEffect, useState} from 'react';
import axios from "axios";
import { config } from "../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import { useOutPatientMedicalHistory } from './useOutPatientMedicalHistory';
import { dateConvert } from '../../Utils/ConvertDate';
import {useViewCompletedDetails} from "./useViewCompletedDetails";



export const useSeenPatients = (actions) =>{
    const [searchValue, setSearchValue] = useState('');
    const [activePage, setActivePage] = useState(1);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [state, setState] = useState({start_date:dateConvert(), end_date:dateConvert()})
    const [data, setData] = useState({patients:[], total_count:0, status:'idle'})
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [openEnd, setOpenEnd] = useState(false)
    const [ids, setIds] = useState({patient_number: '', visit_id: '',patient_name:'', age:'', gender:''});
    const [isEnd, setIsEnd] = useState('idle')
    const {patientProps} = useViewCompletedDetails(actions)
    const [provider, setProvider] = useState('')
    const [providers, setProviders] = useState([])
  
    const {status} = data;
    const {start_date, end_date} = state;

    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;
    const department_id = !_token.department_id ? "" : _token.department_id;


    const isPending = isSubmitted === 'pending';
    const isResolved = isSubmitted === 'resolved';
    const isError = isSubmitted === 'reject';

    const isLoading =  status === 'pending';
    const isSuccess = status === 'success';
    const isRejected = status === 'error';

    const isEnding = isEnd === 'pending';
    const isEnded = isEnd === 'resolved';
    const isEndError = isEnd === 'reject';

    useEffect(() => {
        setActivePage(1)
    }, [provider]);


    useEffect(() => {
        axios.get(`${config.smsUrl}/cbilling/insurance_providers/list`).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const arr = !dt.data ? [] : dt.data;
            const list = arr.map(item=>({
                value:item.provider_id,
                label:item.provider_name
            }))
            setProviders(list);
        }).catch(err => {
            logoutErrorMessage(err,null,actions)
        })
    }, []);


    useEffect(() => {
       
            const formData = new FormData();
            formData.append('department_id', department_id);
            formData.append('search', searchValue);
            formData.append('start_date', start_date);
            formData.append('end_date', end_date);
            formData.append('per_page', rowsPerPage);
            formData.append('provider', provider?.value ?? '' );
            setData({...data, status:'pending'})
            axios.post(`${config.smsUrl}/cdoctor/patients_worked_on_by_doctor/${activePage}`, formData).then(res => {
                const data = res.data;
                const dt = !data ? {} : data;
                const patients = !dt.patient_seen_list ? [] : dt.patient_seen_list;
                const count = !dt.total_count ? 0 : dt.total_count;
                setData({...data, status:'success',patients,total_count:count })
            }).catch(err => {
                logoutErrorMessage(err,null,actions)
                setData({...data, status:'error'})
            })
    }, [activePage, searchValue, isEnded, provider, rowsPerPage]);



    const handleSearch = (event) => {
        setSearchValue(event.target.value);
        setActivePage(1)
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setActivePage(newPage+1)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        setActivePage(1);
    };


    const handleChangeProvider = (value) =>{
        setProvider(value)
    }

    const handleChangeNextPage = (value) => {
        setActivePage(value)
    };

    const handleChange = (event) =>{
        const {value, name} = event.target
        setState({...state, [name]:value})
        setActivePage(1)
    }
    


    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('department_id', department_id);
        formData.append('search', searchValue);
        formData.append('start_date', start_date);
        formData.append('end_date', end_date);
        formData.append('per_page', rowsPerPage);
        setSubmitted(true)
        if (start_date !== '' && end_date !== '') {
            setIsSubmitted('pending')
            setData({...data, status:'pending'})
            axios.post(`${config.smsUrl}/cdoctor/patients_worked_on_by_doctor/${activePage}`, formData).then(res => {
                setIsSubmitted('resolved')
                const data = res.data;
                const dt = !data ? {} : data;
                const patients = !dt.patient_seen_list ? [] : dt.patient_seen_list;
                const count = !dt.total_count ? 0 : dt.total_count;
                setData({...data, status:'success',patients,total_count:count })
            }).catch(e => {
                errorMessages(e, null, actions);
                setIsSubmitted('rejected')
                setData({...data, status:'error'})
            });
        }
    };

    const handleOpenEndVisit = (item) => {
        setOpenEnd(true)
        const {visit_id, patient_number, patient_name, age, gender} = item
        setIds({visit_id, patient_number, patient_name, age, gender});
    };

    const handleCloseEndVisit = () => {
        setOpenEnd(false)
    };

    const handleEndVisit = () => {
        const formData = new FormData();
        const {visit_id} = ids;
        formData.append('visit_id', visit_id);
        formData.append('department_id', department_id);
        formData.append('user_roles_id', user_roles_id);
        formData.append('action', 'end');
        if (visit_id) {
            setIsEnd('pending')
            axios.post(`${config.smsUrl}/cvisit/complete_visit`, formData).then(() => {
                actions.snackbarActions.snackSuccess('Visit ended successfully');
                setOpenEnd(false)
                setIsEnd('resolved')
            }).catch(e => {
                errorMessages(e, null, actions);
                setIsEnd('rejected')
            });
        }
    };

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar();
    };



    return {searchValue, activePage, handleSearch,  handleChangeNextPage,handleSubmit,
         closeSnackbar,  isLoading, isSuccess, isRejected,submitted,state, data,handleChange,
        isPending, isResolved, isError, patientProps, openEnd,handleOpenEndVisit,
    handleCloseEndVisit, handleEndVisit, isEnding, isEnded, provider, providers, handleChangeProvider,
    page, rowsPerPage, handleChangePage, handleChangeRowsPerPage}
}