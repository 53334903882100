import React, {useState} from 'react';
import { dateConvert } from '../../../Utils/ConvertDate';


export const useDates = () =>{
    const [date, setDate] = useState({from_date:dateConvert(), to_date:dateConvert()});
    const handleChangeDate = (event) =>{
        const {value, name} = event.target;
        setDate({...date,[name]:value})
    }

    return [date, handleChangeDate, setDate]
}