import React from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Grid from '@material-ui/core/Grid';
import {useAccountingStyles} from "../Accounting/Expenses/hooks/useAccountingStyles";
import Label from "../Utils/FormInputs/Label";
import TextField from "../Utils/FormInputs/TextField";
import {titleCase} from "../Users/addUser";


export const MainSearchComponent = ({search_type, start_date, end_date, changeDate, handleSubmit,
                                        isSubmitted, submitted,children,noDate, inputType={}}) => {
    const classes = useAccountingStyles();
    return (

        <Grid container spacing={0} data-testid="main-search-component"> 
            <Grid item xs={12} md={8} lg={8}>
                {!noDate ? <div className="report-search-bar">
                    <form onSubmit={handleSubmit}>
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={5} xl={5} >
                                <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={2} lg={2} xl={2}>
                                        <Label name='From' type/>
                                    </Col>
                                    <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                                        <TextField type='date'
                                                    onChange={changeDate}
                                                    value={start_date} name='start_date'
                                                    data-testid="start-date"/>
                                        {submitted && !start_date && (
                                            <div className="help-block">{titleCase(inputType[search_type])} is
                                                required</div>
                                        )}
                                    </Col>
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={5} xl={5}>
                                <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={2} lg={2} xl={2}>
                                        <Label name='To'  type/>
                                    </Col>
                                    <Col xs={12} sm={12} md={8} lg={8} xl={8}>

                                        <TextField type='date' value={end_date} onChange={changeDate}
                                                   name='end_date' data-testid="end-date" />
                                        {submitted && !end_date && (
                                            <div className="help-block">To date is required</div>
                                        )}
                                    </Col>
                                    <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                                        <button type='submit' disabled={isSubmitted === 'pending'}
                                                className="btn sms-btn-outline btn-sm px-2 btn-sm mt-2">{isSubmitted === 'pending' ? 'Searching...' : 'Search'}</button>
                                    </Col>
                                </Form.Group>
                            </Col>

                        </Form.Group>
                    </form>
                </div>: null}
            </Grid>
            <Grid item xs={12} md={4} lg={4} classes={{root: classes.item}}>
                <div className='export-dropdown'>
                    {children}
                </div>
            </Grid>
        </Grid>

    )
}