import React, {useEffect, useState} from "react";
import {CustomTablePagination, EnhancedSearchTableToolbar} from "../../Utils/Tables/CustomTable";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import *  as snackbarActions from "../../../actions/snackbarActions";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {Container, ErrorMessage, RightAlignedContainer} from "../../Utils/styledComponents";
import axios from "axios";
import {config} from "../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {LoadingGif} from "../../Utils/Loader";
import {CustomTableCell, MuiPaper, StickyHeadTable} from "../../Utils/Tables/StickyHeaderTable";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClipboardList} from "@fortawesome/free-solid-svg-icons/faClipboardList";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Label from "../../Utils/FormInputs/Label";
import TextField from "../../Utils/FormInputs/TextField";
import {dateStrokeConvert} from "../../Utils/ConvertDate";
import {BackButton, DataExportButton} from "../../Utils/Buttons/DataExportationButton";
import {useExcelReports, usePdfDownload} from "../../Utils/ReusableComponents/useExcelExport";
import {useCurrency} from "../../../Context/currency-context";




const DepartmentRevenueDetails = ({actions, snackbars,match:{params}}) => {
    const {currency:selectedCurrency} = useCurrency({actions, snackbars,match:{params}})
    const {department_id, department_name, start_date:s_date, end_date:e_date} = params
    const [date, setDate] = useState({start_date: s_date, end_date: e_date});
    const [searchValue, setSearchValue] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isSubmitted, setIsSubmitted] = useState('idle');;
    const [data, setData] = useState({revenueList:[], loading:'idle', total:0, total_usd:0, total_ssp:0});
    const [submitted, setSubmitted] = useState(false);
    const [activePage, setActivePage] = useState(1)
    const [totalResult, setTotalResult] = useState('')


    const {start_date, end_date} = date;

    const {loading, total, revenueList, total_ssp, total_usd} = data

    const route = axios.get(`${config.smsUrl}/cbilling/bill/department/${department_id}/${activePage}`, {
        params: {
            start_date: start_date,
            end_date: end_date,
            per_page:rowsPerPage,
            search:searchValue
        }
    })

    const manipulateArr = (arr=[]) =>{
        return arr.map(item => ({
            ...item,
            date: item.date_paid ? dateStrokeConvert(item.date_paid) : item.date_paid,
            name: item.service_name || item.product_name,
            patient_name:`${item.first_name} ${item.last_name}`,
            total_usd: item.currency_id === 1 ? item.total : 0,
            total_ssp: item.currency_id === 2 ? item.total : 0
        }))
    }

    useEffect(() => {
        setData({...data, loading: 'pending'})
        route.then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const arr = dt.items ? dt.items : []
            const total_count = dt.total ? dt.total : 0
            const ssp = dt?.total_result?.revenue_ssp ?? 0
            const usd = dt?.total_result?.revenue_usd ?? 0
            const total_result = data.total_result
            setData({...data, loading: 'success', revenueList: manipulateArr(arr), total: total_count, total_ssp: ssp, total_usd: usd})
            setTotalResult(total_result)
        }).catch((err) => {
            setData({...data, loading: 'error'})
            logoutErrorMessage(err, null, actions);
        })
    }, [activePage, searchValue, rowsPerPage]);

    const handleSubmit = (event) => {
        event.preventDefault();
        setSubmitted(true)
        if (start_date && end_date) {
            setIsSubmitted('pending')
            setData({...data, loading: 'pending'})
            route.then(res => {
                const data = res.data;
                const dt = !data ? {} : data;
                const arr = dt.items ? dt.items : []
                const total_count = dt.total ? dt.total : 0
                const ssp = dt?.total_result?.revenue_ssp ?? 0
                const usd = dt?.total_result?.revenue_usd ?? 0
                setData({...data, loading: 'success', revenueList: manipulateArr(arr), total: total_count, total_ssp: ssp, total_usd: usd})
                const total_result = data.total_result
                setTotalResult(total_result)
                setIsSubmitted('resolved')
            }).catch(err => {
                setIsSubmitted('rejected')
                errorMessages(err, null, actions);
                setData({...data, loading: 'error'})
            })
        }
    }

    const handleChangeDate = (event) => {
        const {value, name} = event.target;
        setDate({...date, [name]: value})
    }


    const handleSearch = (event) => {
        setSearchValue(event.target.value)
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setActivePage(newPage+1)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        setActivePage(1);
    };

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };



    const {openBar, type, message} =snackbars;

    const isLoading = loading === 'pending'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'

    const isPending = isSubmitted === 'pending'

    const pagination = (
        <CustomTablePagination
            colSpan={3}
            activePage={activePage}
            page={page}
            rowsPerPage={rowsPerPage}
            count={total}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            isTotalCount
        />
    );

    const search_bar = (
        <EnhancedSearchTableToolbar
            term={searchValue} handler={handleSearch}
        />
    )
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, revenueList.length - page * rowsPerPage);


    const fileName = `${start_date} to ${end_date} ${department_name} Revenue`

    const excelObj = {file_name:fileName,params:{start_date, end_date, download:1}, url:`cbilling/bill/department/${department_id}/${activePage}`}

    const pdfObj = {file_name:fileName,params:{start_date, end_date, download:2}, url:`cbilling/bill/department/${department_id}/${activePage}`}


    const {exportExcel, isLoading:isLoadingExcel} = useExcelReports(actions,excelObj)
    const {exportPdf, isLoading:isLoadingPdf} = usePdfDownload(actions,pdfObj)

    const headData = [
        {id: 'count', numeric: false, disablePadding: false, label: 'Sl'},
        {id: 'date', numeric: false, disablePadding: false, label: 'Date'},
        {id: 'patient_name', numeric: false, disablePadding: false, label: 'Patient Name'},
        {id: 'name', numeric: false, disablePadding: false, label: 'Item Name'},
        {id: 'quantity', numeric: false, disablePadding: false, label: 'Quantity'},
        {id: 'total', numeric: false, disablePadding: false, label: `Total`},
        // {id: 'total_ssp', numeric: false, disablePadding: false, label: 'Total(SSP)'}
    ];

    return (
        <div data-testid={`revenue-per-department-details-report`} className='journals'>
            <PageTitle title={`${department_name}'s Revenue`}/>
            <SubHeader title="Reports" subTitle={`${department_name}'s Revenue`}>
                <FontAwesomeIcon icon={faClipboardList}/>
            </SubHeader>
            <Container>
                <BackButton to='/reports/revenuebydepartment' text='Revenue per department'/>
                <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>
                <form onSubmit={handleSubmit}>
                    <div className='row mb-0 mt-2'>
                        <div className='col-lg-8'>
                            <Form.Group as={Row}>
                                <Col xs={12} sm={12} md={12} lg={5} xl={5} style={{paddingLeft: 0}}>
                                    <Form.Group as={Row}>
                                        <Col xs={12} sm={12} md={2} lg={2} xl={2} style={{paddingLeft: 0}}>
                                            <Label name='From' htmlFor="start_date" type/>
                                        </Col>
                                        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                                            <TextField type='date'
                                                       onChange={handleChangeDate}
                                                       value={start_date} name='start_date' id="start_date"/>
                                            {submitted && !start_date && (
                                                <ErrorMessage>Start date is required</ErrorMessage>
                                            )}
                                        </Col>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={5} xl={5} style={{paddingLeft: 0}}>
                                    <Form.Group as={Row}>
                                        <Col xs={12} sm={12} md={2} lg={2} xl={2} style={{paddingLeft: 0}}>
                                            <Label name='To' htmlFor="end_date" type/>
                                        </Col>
                                        <Col xs={12} sm={12} md={8} lg={8} xl={8}>

                                            <TextField type='date' value={end_date} onChange={handleChangeDate}
                                                       name='end_date' id="end_date"/>
                                            {submitted && !end_date && (
                                                <ErrorMessage>End date is required</ErrorMessage>
                                            )}
                                        </Col>
                                    </Form.Group>
                                </Col>
                                <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                                    <button type='submit' disabled={isPending}
                                            className="btn sms-btn-outline btn-sm px-2 btn-sm">{isPending ? 'Filtering...' : 'Filter'}</button>
                                </Col>

                            </Form.Group>
                        </div>
                        <div className='col-lg-4'>
                            <RightAlignedContainer>
                                <DataExportButton isLoading={isLoadingExcel || isLoadingPdf} downLoadPdf={exportPdf}
                                                  exportCsv={exportExcel}/>
                            </RightAlignedContainer>
                        </div>
                    </div>
                </form>
                <h6 className='text-xl-right text-lg-right text-md-left text-sm-left'>Total:
                    <span style={{display:'inline-block', marginRight:'15px', marginLeft:'10px'}}> <strong>{totalResult}</strong></span>
                    {/*<span>SSP <strong>{total_ssp}</strong></span>*/}
                </h6>


                <MuiPaper>
                    {search_bar}
                    <StickyHeadTable {...{
                        width: '100%',
                        data: revenueList,
                        columns: headData.filter(item => Boolean(item)),
                        pagination
                    }}>
                        {isLoading ? <TableRow><TableCell
                            align='center' {...{colSpan: headData.length}}><LoadingGif/></TableCell></TableRow> : null}
                        {isSuccess ? revenueList.length > 0 ?
                            <>
                                {revenueList.slice(0, rowsPerPage).map((row, index) => {
                                    const count = page * rowsPerPage + index + 1;
                                    return (
                                        <TableRow tabIndex={-1} key={index}>
                                            {headData.map(col => (
                                                col.id === 'count' ? <CustomTableCell>{count}</CustomTableCell> :
                                                    <CustomTableCell>{row[col.id]}</CustomTableCell>
                                            ))}
                                        </TableRow>
                                    );
                                })}
                                {emptyRows > 0 && (
                                    <TableRow style={{height: emptyRows}}>
                                        <TableCell colSpan={headData.length}/>
                                    </TableRow>
                                )}
                            </> : <TableRow><TableCell align='center'  {...{colSpan: headData.length}}>No records
                                found</TableCell></TableRow> : null}
                        {isError ?
                            <TableRow><TableCell align='center'  {...{colSpan: headData.length}}>The server did not
                                return a valid response</TableCell></TableRow> : null}
                    </StickyHeadTable>
                </MuiPaper>
            </Container>

        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentRevenueDetails);
