import {useState} from "react";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {errorMessages} from "../../../Helpers/ErrorMessages";

export const useAddShiftCategory = (actions) =>{
    const [state, setState] = useState({ status: '1', start_period: '', end_period: '', shift_name:''});
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsubmitted] = useState('idle');
    const [shift_name,setShiftName] = useState('')
    const [ openAddDialog, setOpenAddDialog ] = useState(false)
    

    const handleChange = (event) => {
        const {name, value} = event.target;
        setState({...state, [name]: value})
    };

    const handleChangeShift = (value) =>{
        setShiftName(value)
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        setSubmitted(true);
        const { start_period, end_period,shift_name} = state;
        const formData = new FormData();
        formData.append('shift_name', shift_name);
        formData.append('start_time', start_period);
        formData.append('end_time', end_period);
        if (shift_name && start_period && end_period) {
            setIsubmitted('pending');
            axios.post(`${config.smsUrl}/cshiftschedule/add_shift_category`, formData).then(()=>{
                actions.snackbarActions.snackSuccess('Shift category added successfully');
                setOpenAddDialog(false);
                setState({ status: '1', start_period: '', end_period: '', shift_name:''});
                setIsubmitted('resolved');
                setSubmitted(false);
            }).catch(err=>{
                setIsubmitted('rejected');
                errorMessages(err, null,actions)
            })
        }
    };

    const handleOpenAddDialog = () => {
        setOpenAddDialog(true)
    }

    const handleCloseAddDialog = () =>{
        setOpenAddDialog(false);
        setState({ status: '1', start_period: '', end_period: '', shift_name:''});
    }

    const isResolved = isSubmitted === 'resolved';

    const handleCloseSnackbar = () =>{
        actions.snackbarActions.hideSnackbar()
    };
    
    const addShiftCatProps = {state, submitted, isSubmitted, handleChange, handleSubmit, handleCloseSnackbar,
        setState, shift_name, handleChangeShift, isResolved, openAddDialog, handleOpenAddDialog, handleCloseAddDialog}

    return { addShiftCatProps }
}