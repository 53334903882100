import React from "react";
import {PatientInformation} from "../PatientInfo/PatientInformation";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../../actions/snackbarActions"
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {useExaminationNotes} from "./hooks/useExaminationNotes";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserMd} from "@fortawesome/free-solid-svg-icons/faUserMd";
import ReusableDoctorTabs, {doctorRoutes} from "../../Utils/Menu/ReusableDoctorTabs";
import styled from "@emotion/styled/macro";
import * as colors from "../../../styles/colors"
import {RightAlignedContainer} from "../../Utils/styledComponents";
import AddIcon from '@material-ui/icons/Add';
import {PreviousNotesTable} from "./PreviousNotesTable";
import {PatientNotesContainer, Button, StyledAdd} from "./PatientNotesContainer";







const headData = [{name:'Date'},{name:'Written By'}]

const OutPatientExaminationNotes = ({actions, snackbars, match}) => {
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const {patient_number, visit_id, labrequest_id, scan_request_id, patient_type, patient_admission_id} = match.params
    const {notes,  patient, isSubmitted, handleChangeExamNotes,
        handleSubmit, closeSnackbar, submitted,
    } = useExaminationNotes(patient_number, actions, visit_id, _token, patient_type)
    const doctor_name = `${!_token.first_name ? "" : _token.first_name} ${!_token.last_name ? "" : _token.last_name}`;
    const {openBar, type, message} = snackbars;
    const {patient_name, age, gender, address, phone} = patient;
   
    const arr = doctorRoutes(patient_number, visit_id,  patient_type, patient_admission_id)
    return (
        <div className="journals">
            <PageTitle title="Examination Notes"/>
            <SubHeader title="Doctor" subTitle="Examination Notes" doctor_name={doctor_name}>
                <FontAwesomeIcon icon={faUserMd}/>
            </SubHeader>
            <MainSnackbar open={openBar} handleCloseBar={closeSnackbar} variant={type}
                          message={message}/>
            <ReusableDoctorTabs components={arr} patient_type={+patient_type}>
                {/*<div className="text-right">
                    <p style={style}>Date: <strong>{dateConvert()}</strong></p>
                    <p style={style}>Doctor: <strong>{doctor_name}</strong></p>
                </div>*/}
                <PatientInformation {...{patient_number, visit_id}}/>
                <PatientNotesContainer button={
                    <RightAlignedContainer>
                    <Button><StyledAdd/>Add</Button>
                </RightAlignedContainer>}>
                    <PreviousNotesTable headData={headData}/>
                </PatientNotesContainer>

            </ReusableDoctorTabs>

        </div>

    )
};

function mapStateToProps(state) {
    return {
        snackbars: state.snackbar
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OutPatientExaminationNotes);
