import React from 'react';
import {useTheme} from "@material-ui/core/styles";

export const TableHeading = ({children, title = '',colSpan=0}) => {
    return (
        <table className="table table-sm table-borderless">
            <tr>
                <td colSpan={colSpan} style={{paddingLeft: 0}}><p style={{
                    borderBottom: '2px solid gray',
                    fontWeight:600,
                    margin:'14px 0 0 0'
                }}><strong>{title}</strong></p></td>
            </tr>
            {children}
        </table>
    )
}

export const CustomTableData = ({children, headData = []}) => {
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    return (
        <table className="table table-sm table-borderless">
            <thead>
            <tr className={`parameter-row ${palette.type === 'dark' && 'dark-parameter-row'}`}
                id="parameter-row">
                {headData.map(data => (
                    <td><p className={palette.type === 'dark' && 'dark-theme-color'}><strong>{data}</strong></p></td>))}
            </tr>
            </thead>
            <tbody>
            {children}
            </tbody>
        </table>
    )
}