import {useEffect, useState} from "react";
import axios from "axios";
import { config } from '../../../Helpers/env'

import { titleCase } from "../../Users/addUser";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import { useFileUploader } from "../../PatientRecords/CustomHooks/useFileUploader";
import { useAddServiceConsumables } from "./useAddService";
import {v4 as uuidv4} from 'uuid';

const useAddConsumables = (actions) => {
    const [openNewItems, setNewItems] = useState(false);
    const [s_id, setCurrentServiceID] = useState('');
    const [service_type, setServiceType] = useState('')

    // values for submission of api calls
    const [loading, setLoading] = useState('idle');
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const {serviceConsumablesProps} = useAddServiceConsumables(actions, submitted);
    const {setProductConsumables} = serviceConsumablesProps;

    // functions to cater for adding new items
    const handleOpenNewItems = (item) => {
        setCurrentServiceID(item.service_id);
        setServiceType(item.service_type)
        setNewItems(true);
    }

    const handleCloseNewItems = () => {
        setNewItems(false);
        setCurrentServiceID('');
        setProductConsumables([{
            product_name:'', product_id:'', quantity:'', showItems: false, product_options:[], uuid:uuidv4(), unit:''
        }]);
    }

    // function to save the list
    const handleAddConsumables = () => {
        const {product_consumables} = serviceConsumablesProps;

        let is_empty = false;
        const service_consumes = product_consumables.map((item) => { 
            if (item.product_id && item.product_name && item.quantity) {
                if ((service_type === 'Lab' && !item.unit)) {
                    is_empty = true;
                }
            } else {
                is_empty = true;
            }
            return {
                'product_id' : item.product_id,
                'product_name': item.product_name,
                'quantity' : Number(item.quantity),
                'unit':item.unit??''
        }})

        const params = {
            'service_consumables' : service_consumes,        
            'service_id' : parseInt(s_id)
        }

        setSubmitted(true);
        setIsSubmitted('pending');

        // check if the user put the compulsory fields
        if (is_empty === false) {
            axios.post(`${config.smsUrl}/cbilling/add_new_service_consumables`, params).then(() => {
                setIsSubmitted('resolved');
                actions.snackbarActions.snackSuccess('Consumables Added Successfully');
                handleCloseNewItems();
                setSubmitted(false);
            }).catch(err => {
                setLoading('error');
                errorMessages(err,null,actions);
                setIsSubmitted('rejected');
            })
        }
    }

    const isAddedConsumablesResolved = isSubmitted === 'resolved';
    const consumablesObj = {...serviceConsumablesProps, service_type}

    return {
        serviceConsumablesProps:consumablesObj, handleAddConsumables, handleOpenNewItems, handleCloseNewItems,
        openNewItems, isAddedConsumablesResolved, setServiceType,
        submitted, isSubmitted, setIsSubmitted, setSubmitted, loading, setLoading
    }

}

const useConsumablesList = (actions, service_id, consumable_status) => {

    // values for the main table
    const [searchValue, setSearchValue] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [service, setService] = useState({});
    const [openDeactivate, setDeactivate] = useState(false);

    const [openEditItem, setEditItem] = useState(false);
    const [current_item, setCurrentItem] = useState({});

    // get the useAddConsumables
    const {
        serviceConsumablesProps, handleAddConsumables, handleOpenNewItems, handleCloseNewItems, service_type,
        openNewItems, submitted, isSubmitted, setIsSubmitted, setSubmitted, loading, setLoading, setServiceType
    } = useAddConsumables(actions);

    const isPending = isSubmitted === 'pending';
    const isResolved = isSubmitted === 'resolved';
    const isRejected = isSubmitted === 'rejected';

    const isLoading = loading === 'pending';
    const isSuccess = loading === 'success';
    const isError = loading === 'error';

    // values for the new items pop up
    const [service_consumables, setServiceConsumables] = useState([]);

    // get the current user's details
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_role_id = _token.user_roles_id ? _token.user_roles_id : '';

    // get the current items list
    useEffect(() => {

        const params = {
            "service_id" : parseInt(service_id),
            "status" : consumable_status,
        };

        setSubmitted(true);
        axios.post(`${config.smsUrl}/cbilling/retrieve_consumables_by_service_id`, params).then(res => {
            const dt = res.data ?? {};
            const data = dt ?? [];
            const arr = data.map((item) => {

                if (item.service_type === 'Lab') {
                    return {
                        ...item,
                        'product_name' : item.product_name ?? '',
                        'quantity' : item.quantity ?? 0,
                        'unit' : item.unit ?? ''
                    }
                } else {
                    return {
                        ...item,
                        'product_name' : item.product_name ?? '',
                        'quantity' : item.quantity ?? 0,
                    }
                }
            })
            setServiceConsumables(arr)
            setLoading('success');
            setSubmitted(false);
            setIsSubmitted('idle');
        }).catch(err => {
            setLoading('error');
            logoutErrorMessage(err,null,actions);
        })
    }, [isResolved, consumable_status]);

    // get service details
    useEffect(() => {
        const formData = new FormData();
        formData.append('service_id', service_id);
        axios.post(`${config.smsUrl}/cbilling/service_update_form`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            setService(dt)
            setServiceType(dt.service_type??'');
        }).catch(err => {
        logoutErrorMessage(err, null,actions)
        })
    }, []);

    // functions to cater for editing an item
    const handleOpenEditItem = (index, item) => {
        setCurrentItem({...item, index});
        setEditItem(true);
    }

    const handleCloseEditItem = () => {
        setEditItem(false);
        // clear the current item
        setCurrentItem({});
    }

    const handleItemUpdate = () => {
        // make api to call to update the item's details
        let params = {
            'quantity': Number(current_item['quantity']),
            'consumable_id' : current_item['consumable_id'],
            'user_role_id' : user_role_id,
        };

        if (service['service_type'] === 'Lab') {
            params = {...params, 'unit' : current_item['unit']};
        }

        setSubmitted(true);
        setIsSubmitted('pending');

        const is_consumable_okay = service['service_type'] === 'Lab' ?  current_item['unit'] !== '' : true; 
        if (current_item['quantity'] && is_consumable_okay) {
            axios.post(`${config.smsUrl}/cbilling/update_service_consumables`, params).then(res => {
                setLoading('success');
                setIsSubmitted('resolved');
                setSubmitted(false);
            }).catch(err => {
                setLoading('error');
                logoutErrorMessage(err,null,actions);
                setIsSubmitted('rejected');
            })
            handleCloseEditItem();
        }
    }

    const handleChangeQuantity = (e) => {
        setCurrentItem({...current_item, quantity : e.target.value});
    }

    const handleChangeUnit = (e) => {
        setCurrentItem({...current_item, unit: e.target.value})
    }

    // functions to cater for the deactivation of the item
    const handleDeactivate = () => {
        // make api call to handle the deactivation
        const params = {
            'consumable_id': current_item['consumable_id'],
            'status': current_item['status'] === 0 ? 1 : 0,
            'user_role_id' : user_role_id
        };
        setSubmitted(true);
        setIsSubmitted('pending');
        axios.post(`${config.smsUrl}/cbilling/deactivate_consumable`, params).then(res => {
            setLoading('success');
            setIsSubmitted('resolved');
            setSubmitted(false);
        }).catch(err => {
            setLoading('error');
            logoutErrorMessage(err,null,actions);
            setIsSubmitted('rejected');
        })
        // change the status of the item
        handleCloseDeactivate();
    }   

    const handleCloseDeactivate = () => {
        setDeactivate(false);
        // clear the current item
        setCurrentItem({});
    }

    const handleOpenDeactivate = (index, item) => {
        setCurrentItem({...item, index})
        setDeactivate(true);
    }



    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleSearch = (searchValue) => {
        setSearchValue(searchValue)
    };

    const {service_name} = service;

    return {
        handleDeactivate, handleCloseDeactivate, openDeactivate, handleOpenDeactivate,
        service_consumables, service_name, serviceConsumablesProps, handleAddConsumables,
        handleCloseEditItem, openEditItem, handleOpenEditItem,
        openNewItems, handleCloseNewItems, handleOpenNewItems, service,
        current_item, handleItemUpdate, handleChangeQuantity, handleChangeUnit,
        closeSnackbar, handleChangeRowsPerPage, handleChangePage, handleSearch,
        searchValue, page, rowsPerPage,isLoading, isSuccess, isError,
    }
}


export {useConsumablesList, useAddConsumables}