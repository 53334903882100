import React from 'react';
import {titleCase} from "../Users/addUser";
import {useTheme} from "@material-ui/core/styles";
import {splitNotes} from "../Radiology/RadiologyReport";

export const ReusableLabResults = ({labTests=[]}) =>{
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    return (
        labTests.map((item, index) => {
                const para = item.params ? item.params : [];
                const pr = para[0] ? para[0] : {};
                const comments = pr.notes ? pr.notes : '';
                const result = pr.result_type ? pr.result_type : ''
                return (
                    <div key={index}>
                        <div className="row">
                            <div className="col-md-6">
                                <p className="general-txt">{item.hospital_test_name}</p>
                            </div>
                        </div>
                        <table className="table table-sm table-borderless prescription-table">
                            <thead>
                                <tr className={`parameter-row ${palette.type === 'dark' && 'dark-parameter-row'}`} id="parameter-row">
                                    <th><p className={palette.type === 'dark' && 'dark-theme-color'}><strong>Parameter</strong></p></th>
                                    <th><p className={palette.type === 'dark' && 'dark-theme-color'}><strong>Result</strong></p></th>
                                    {result !== 3 && <th><p className={palette.type === 'dark' && 'dark-theme-color'}><strong>Unit</strong></p>
                                    </th>}
                                    {result !== 3 && <th><p className={palette.type === 'dark' && 'dark-theme-color'}><strong>Ref. Range</strong></p></th>}
                                </tr>
                            </thead>
                            <tbody>
                            {item.params.map((test) => {
                                return (
                                    <tr key={test.hospital_test_id}>
                                        <td className={palette.type === 'dark' && 'dark-theme-color'}>                                                                          
                                            <p>{test.result_type === 2 ? titleCase(test.sub_test_name) : (test.result_type === 1 && test.sub_test_name === 'blood_group') ?
                                                'Blood Group' : test.result_type === 1 ? 'Result' : 'Observations'}</p>
                                        </td>
                                        {test.result_type === 1 ?
                                            <td className={palette.type === 'dark' && 'dark-theme-color'}>
                                                {test.sub_test_name === '+ve/-ve' ?
                                                    <p>{test.test_value === '1' ? 'Positive' :
                                                        'Negative'}</p> : test.sub_test_name === 'reactive/non-reactive' ?
                                                        <p>{test.test_value === '3' ? 'Reactive' : 'Non-reactive'}</p> :
                                                        test.sub_test_name === 'blood_group' ?
                                                            <p>{test.test_value}</p> : <p/>}
                                            </td> :
                                            <td colSpan={test.result_type === 3 ? 4 : 0} className={palette.type === 'dark' && 'dark-theme-color'}>
                                                <p>{test.result_type === 3 ? splitNotes(test.test_value) : test.test_value}</p></td>}
                                        {test.result_type !==3 &&<td className={palette.type === 'dark' && 'dark-theme-color'}>
                                            <p>{test.unit ? test.unit : 'NA'}</p>
                                        </td>}
                                        {test.result_type !==3 &&<td className={palette.type === 'dark' && 'dark-theme-color'}>
                                            <p>{(test.critical_low && test.critical_high) ? (`${!test.critical_low ? "" : test.critical_low} - ${!test.critical_high ? "" : test.critical_high}`) : 'NA'}</p>
                                        </td>}
                                    </tr>

                                )
                            })}

                                <tr>
                                    <td style={{paddingBottom:0}}>
                                        <p className="general-txt"><p>Comments</p></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={4} style={{padding:0}}>
                                        <p style={{padding:3}}>{comments ? splitNotes(titleCase(comments)) : comments}</p>
                                    </td>
                                </tr>

                            </tbody>
                        </table>

                    </div>
                )
            })
    )
}