import {useState} from "react";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {errorMessages} from "../../../Helpers/ErrorMessages";


export const useEditCompletedTriage = (itemObj) =>{
    const {actions, treatmentDetails,setTreatmentDetails, visit_id, patient_number} = itemObj
    const [submitted, setSubmitted] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState('idle')

    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;


    const handleEditTriage = (index) =>{
        const arr = treatmentDetails?.patientTriage?.map((item, idx)=>{
            if (index === idx){
                return {...item, isEdit:true}
            }
            return item
        })
        setTreatmentDetails({...treatmentDetails,patientTriage:arr })
    }

    const handleCancelEditTriage = (index) =>{
        const arr = treatmentDetails?.patientTriage?.map((item, idx)=>{
            if (index === idx){
                return {...item, isEdit:false}
            }
            return item
        })
        setTreatmentDetails({...treatmentDetails,patientTriage:arr })
    }

    const handleChangeValue = (event, index, metric_id) =>{
        const arr = treatmentDetails?.patientTriage?.map((item, idx)=>{
            if (index === idx){
                return {...item, data:item?.data?.map(metric=>{
                        if (metric.metric_id === metric_id){
                            return {...metric, metric_value:event.target.value}
                        }
                        return metric
                    })
                }
            }
            return item
        })
        setTreatmentDetails({...treatmentDetails,patientTriage:arr })
    }


    const handleSubmitTriage = (data, index) => {
        setSubmitted(true);
        const jsonData = {
            patient_number,
            visit_id,
            visit_state:3,
            activity_id: '',
            user_roles_id,
            chronic_illness: {},
            allergies: '',
            metric_type_id:1,
            triage_info:data

        }
        setIsSubmitted('pending');
        axios.post(`${config.smsUrl}/cvisit/update_triage`, jsonData).then(() => {
            setIsSubmitted('resolved');
            actions.snackbarActions.snackSuccess('Triage Information added successful');
            setSubmitted(false);

            const arr = treatmentDetails?.patientTriage?.map((item, idx)=>{
                if (index === idx){
                    return {...item, isEdit:false}
                }
                return item
            })
            setTreatmentDetails({...treatmentDetails,patientTriage:arr })
        }).catch((err) => {
            errorMessages(err, null, actions)
            setIsSubmitted('rejected');
        });

    }

    const isPending = isSubmitted === 'pending'
    const isResolveTriage = isSubmitted === 'resolved'


    const triageProps = {handleEditTriage, handleChangeValue, isPending,handleCancelEditTriage,handleSubmitTriage, submitted}
    return {triageProps, isResolveTriage}
}