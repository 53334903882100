import { faDownload } from "@fortawesome/free-solid-svg-icons/faDownload";
import { faMinus } from "@fortawesome/free-solid-svg-icons/faMinus";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { faShippingFast } from "@fortawesome/free-solid-svg-icons/faShippingFast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Card from "@material-ui/core/Card";
import { useTheme } from "@material-ui/core/styles";
import React from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as snackbarActions from "../../../actions/snackbarActions";
import { SubHeader } from "../../../Containers/SubHeader";
import { history } from "../../../Helpers/history";
import { BackButton } from "../../Utils/Buttons/DataExportationButton";
import CustomDialog from "../../Utils/Dialogs/CustomDialog";
import PrintDialog from "../../Utils/Dialogs/PrintDialog";
import { AutoCompleteControlled, AutoCompleteProducts } from "../../Utils/FormInputs/AutoCompleteInput";
import Label from "../../Utils/FormInputs/Label";
import SelectInput from "../../Utils/FormInputs/SelectInput";
import TextField from "../../Utils/FormInputs/TextField";
import { DataCell } from "../../Utils/Lists/CollapsibleList";
import { BlockNavigationComponent } from "../../Utils/ReusableComponents/BlockNavigationComponent";
import PageTitle from "../../Utils/smsTitle";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import { ErrorMessage } from "../../Utils/styledComponents";
import { CustomInput, CustomResizableTextarea } from "../CustomInput";
import { AddProductForm } from "../products/AddProductForm";
import { SupplierForm } from "./AddEditSupplier";
import { useAddPurchase } from "./hooks/useAddPurchase";
import PurchaseDialog from "./PurchaseDialog";
import {useCurrency} from "../../../Context/currency-context";
import {useVersionsContext} from "../../../Context/versions-context";

const AddPurchase = ({ actions, snackbar, match: { params } }) => {
    const {currency:selectedBaseCurrency} = useCurrency({actions, snackbar, match: { params }})
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;

    const { purchase_id, draft } = params;

    const { closeSnackbar, openDialog, purchases, purchaseCsv, ref, fetchData, submitCsv, isCsv,
        handleCloseDialog, handleUploadCsv, handleChangePurchaseCsv, formRef, handleOpenConfirm, handleCloseConfirm, confirmDialog,
        productProps, openProductDialog, handleCloseProductDialog, isPendingDraft,
        openAddSupplier, supplierProps, handleCloseSupplierDialog, purchaseProps } = useAddPurchase(actions, params);
    const { openBar, type, message } = snackbar;
    const { isPending } = supplierProps

    const { handleSubmit, products, retrieveProducts, clickProduct, productIds, submitted,
        handleAddRow, removeRow, isSubmitted, handleChangeQuantity, grand_total, handleSaveDraft,
        state, supplier, suppliers, handleChangeSupplier, handleChangePrice, handleOpenSupplierDialog,
        handleOpenAddProduct, handleChangeSubTotal, currencies, currency, usdAmount, handleChangeCurrency,
        handleChangeExchangeRate, isResolvedPurchase, isBlocking, setIsBlocking, currencyExchangeRate } = purchaseProps

    const title = purchase_id && !draft ? 'Edit Purchase' : 'Add Purchase'

    const isCanceled = draft === 'canceled_draft'

    const isDraft = draft === 'purchase_draft'

    const { expected_payment_date, details, purchase_date, invoice_no } = state;

    const currencyText = currency ? currency : ''
    const isBase = currencyText.split('-')[1]
    const currency_symbol = currencyText.split('-')[2]
    const isNotBase = isBase === 'false'
    const isBaseCurrency = isBase === 'true'

    const text = 'Please double-check that everything you typed is right because once you press the SAVE FINAL button, you will not be able to make any changes';

    const {subscription} = useVersionsContext()
    const version = subscription?.version
    const isPremium = version === 'Premium'
    return (
        <div data-testid="add-purchase">
            <PageTitle title={title} />

            <SubHeader title="Inventory" subTitle={title}>
                <FontAwesomeIcon icon={faShippingFast} />
            </SubHeader>

            <PurchaseDialog
                handleClose={handleCloseDialog}
                openDialog={openDialog}
                submitted={submitCsv}
                isSubmitted={isCsv}
                handleChangeUpload={handleChangePurchaseCsv}
                handleUploadCsv={handleUploadCsv}
                csv={purchaseCsv}
            >
                <button
                    onClick={fetchData}
                    className="btn btn-sm sms-info-btn"
                >
                    <FontAwesomeIcon icon={faDownload} />
                    Download File
                </button>

                <CSVLink
                    data={purchases}
                    ref={ref}
                    filename="Purchases.csv"
                    className="hidden"
                />
            </PurchaseDialog>

            <CustomDialog
                open={openAddSupplier}
                title="Add Supplier"
                handleClose={handleCloseSupplierDialog}
                maxWidth="md"
            >
                <SupplierForm {...supplierProps}>
                    <button
                        type="submit"
                        disabled={isPending}
                        className="btn btn-sm sms-btn"
                    >
                        {isPending ? 'Saving...' : 'Add Supplier'}
                    </button>
                </SupplierForm>

            </CustomDialog>

            <CustomDialog
                open={openProductDialog}
                title="Add Product"
                handleClose={handleCloseProductDialog}
                maxWidth="md"
            >
                <AddProductForm {...productProps} />
            </CustomDialog>

            <PrintDialog
                handleCancel={handleCloseConfirm}
                openDialog={confirmDialog}
                handleClose={handleCloseConfirm}
                handleClick={handleSubmit}
                message={"Confirm Purchase"}
                text={text}
            />

            <MainSnackbar
                variant={type}
                open={openBar}
                message={message}
                handleCloseBar={closeSnackbar}
            />

            <BlockNavigationComponent
                when={isBlocking}
                shouldBlockNavigation={() => {
                    return isBlocking;
                }}
                navigate={path => history.push(path)}
            />

            <div className='general-ledger-header'>
                <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <BackButton
                            to={isCanceled ? '/canceled-purchases' : '/purchaselist'}
                            text={isCanceled ? 'canceled purchases' : isDraft ? 'purchase draft' : 'purchase list'}
                            data-testid="purchase-details-back-button"
                        />

                        {/*<button onClick={handleOpenDialog} className="btn sms-info-btn btn-sm mr-2"><FontAwesomeIcon*/}
                        {/*    icon={faUpload}/> Upload CSV</button>*/}
                    </div>

                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="text-lg-right text-md-right text-sm-left text-xl-right">

                            <button
                                type="button"
                                id="quick-add-supplier"
                                className="btn btn-sm sms-info-btn mb-2  line-button"
                                onClick={handleOpenSupplierDialog}
                            >
                                <FontAwesomeIcon icon={faPlus} />
                                Quick Add Supplier
                            </button>

                            <button
                                type="button"
                                className="btn btn-sm sms-info-btn mb-2 line-button"
                                onClick={handleOpenAddProduct}
                            >
                                <FontAwesomeIcon icon={faPlus} />
                                Quick Add Product
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mui-tables">
                <Card className="p-3">
                    <h6 className={`visit-heading ${palette.type === "dark" && "dark-visit-heading"}`}>{title}</h6>

                    <form onSubmit={handleSubmit} ref={formRef} autoComplete="off">
                        <div className="row">
                            <div className="col-md-6">
                                <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                                        <Label name="supplier" type />
                                    </Col>

                                    <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                                        <AutoCompleteControlled
                                            options={suppliers}
                                            testId='supplier_name'
                                            handleChange={handleChangeSupplier}
                                            submitted={submitted}
                                            medicine_value={supplier}
                                            placeholder="Select supplier..."
                                            errorMsg='Supplier is required'
                                            value_text='value'
                                            label_text='label'
                                        />
                                    </Col>

                                </Form.Group>

                                <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                                        <Label name="Invoice no." type htmlFor="chalan_no" />
                                    </Col>

                                    <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                                        <CustomInput submitted={submitted} setIsBlocking={setIsBlocking} type='text' name='chalan_no' data-testid="invoice_no" reset={isResolvedPurchase} inputValue={invoice_no} />
                                    </Col>
                                </Form.Group>

                                {isPremium ? <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                                        <Label name="Currency" type htmlFor="currency_name" />
                                    </Col>

                                    <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                                        <SelectInput
                                            submitted={submitted}
                                            value={currency}
                                            onChange={handleChangeCurrency}
                                            options={currencies}
                                            defaultOption="Select..."
                                            id="currency_name"
                                        />
                                        {/* {submitted && !currency ? <ErrorMessage>This is a required field</ErrorMessage>:null} */}
                                    </Col>
                                </Form.Group>:
                                    <Form.Group as={Row}>
                                        <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                                            <Label name="Purchase Date" htmlFor="purchase_date" />
                                        </Col>

                                        <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                                            <CustomInput
                                                submitted={submitted}
                                                setIsBlocking={setIsBlocking}
                                                type='date' id="purchase_date"
                                                name='purchase_date'
                                                reset={isResolvedPurchase}
                                                inputValue={purchase_date}
                                                min={expected_payment_date}
                                            />
                                        </Col>
                                    </Form.Group>}

                                {isNotBase ?
                                    <Form.Group as={Row} >
                                        <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                                            <Label name="Exchange Rate" type htmlFor="exchange_rate" />
                                        </Col>

                                        <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                                            <InputGroup className="">
                                                <InputGroup.Text
                                                    style={{ borderRadius: '0.7rem 0 0 0.7rem' }}
                                                    id="basic-addon1"
                                                >
                                                    {currency_symbol}
                                                </InputGroup.Text>

                                                <TextField
                                                    submitted={submitted}
                                                    type="number"
                                                    step='any'
                                                    id="exchange_rate"
                                                    value={currencyExchangeRate}
                                                    onChange={handleChangeExchangeRate}
                                                />
                                            </InputGroup>

                                            <span
                                                style={{
                                                    marginTop: '5px',
                                                    display: 'inline-block',
                                                    fontWeight: 700,
                                                    color: 'blue',
                                                }}
                                            >
                                                (1 {selectedBaseCurrency?.currency_symbol} = {currencyExchangeRate} {currency.split('-')[2]})
                                            </span>
                                        </Col>
                                    </Form.Group> : null}
                            </div>

                            <div className="col-md-6">
                                {isPremium ? <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                                        <Label name="Purchase Date" htmlFor="purchase_date" />
                                    </Col>

                                    <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                                        <CustomInput
                                            submitted={submitted}
                                            setIsBlocking={setIsBlocking}
                                            type='date' id="purchase_date"
                                            name='purchase_date'
                                            reset={isResolvedPurchase}
                                            inputValue={purchase_date}
                                            min={expected_payment_date}
                                        />
                                    </Col>
                                </Form.Group>: null}

                                <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                                        <Label name="Expected Payment Date" htmlFor="expected_delivery_date" />
                                    </Col>

                                    <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                                        <CustomInput
                                            submitted={submitted}
                                            setIsBlocking={setIsBlocking}
                                            type='date'
                                            id="expected_delivery_date"
                                            name='expected_payment_date'
                                            reset={isResolvedPurchase}
                                            inputValue={expected_payment_date}
                                            min={purchase_date}
                                            max={"9999-12-31"}
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                                        <Label name="Details" htmlFor="payment_detials" />
                                    </Col>

                                    <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                                        <CustomResizableTextarea
                                            submitted={submitted}
                                            setIsBlocking={setIsBlocking}
                                            inputValue={details}
                                            data-testid="details"
                                            rows={2}
                                            name='payment_detials'
                                        />
                                    </Col>
                                </Form.Group>

                            </div>
                        </div>

                        <div className="table-responsive">
                            <table
                                className={`table table-sm table-bordered ${palette.type === 'dark' && 'dark-table dark-table-bordered'}`}>
                                <thead>
                                    <tr>
                                        <th className={palette.type === 'dark' && 'dark-theme-color'}>Product
                                            Name<span className="help-block">*</span>
                                        </th>

                                        <th className={palette.type === 'dark' && 'dark-theme-color'}>Quantity<span
                                            className="help-block">*</span></th>

                                        <th className={palette.type === 'dark' && 'dark-theme-color'}>Unit Cost{currency_symbol ? `(${currency_symbol}) ` : ''}<span
                                            className="help-block">*</span></th>

                                        {isNotBase ? <th className={palette.type === 'dark' && 'dark-theme-color'}>Unit Cost({selectedBaseCurrency?.currency_symbol})<span
                                            className="help-block">*</span></th> : null}

                                        <th className={palette.type === 'dark' && 'dark-theme-color'}>Total{currency_symbol ? `(${currency_symbol}) ` : ''}</th>

                                        {isNotBase ? <th className={palette.type === 'dark' && 'dark-theme-color'}>Total({selectedBaseCurrency?.currency_symbol})<span
                                            className="help-block">*</span></th> : null}
                                        <th />
                                    </tr>
                                </thead>

                                <tbody>
                                    {products.map((item, index) => (
                                        <tr key={item.uuid} id="drugs-row">
                                            <DataCell variant='250'>
                                                <AutoCompleteProducts
                                                    testId={`product_name-${index}`}
                                                    options={productIds}
                                                    submitted={submitted}
                                                    idx={index}
                                                    handleChange={retrieveProducts}
                                                    medicine_value={{
                                                        label: item.product_name,
                                                        value: item.product_id,
                                                        generic_name: item.generic_name ? item.generic_name : ''
                                                    }}
                                                    id="product_name"
                                                    valid_error_check={item.isError && submitted}
                                                    errorMsg_Valid="Please select a valid product"
                                                    handleProductData={clickProduct}
                                                    placeholder="Enter product name"
                                                    errorMsg="Product is required"
                                                />
                                            </DataCell>

                                            <DataCell variant='150'>
                                                <TextField
                                                    submitted={submitted}
                                                    value={item.quantity}
                                                    name="quantity"
                                                    data-testid={`quantity-${index}`}
                                                    placeholder="Input quantity"
                                                    onChange={(e) => handleChangeQuantity(e, index)}
                                                    type="number"

                                                />

                                                {
                                                    (submitted && !item.quantity) ?
                                                        <ErrorMessage> Quantity is required </ErrorMessage> : ''
                                                }
                                            </DataCell>

                                            <DataCell variant='150'>
                                                <TextField
                                                    submitted={submitted}
                                                    value={item.supplier_price}
                                                    name="supplier_price"
                                                    onChange={(e) => handleChangePrice(e, index)}
                                                    type="number"
                                                    placeholder="Input unit cost"
                                                    data-testid={`unit_cost-${index}`}
                                                />

                                                {
                                                    (submitted && !item.supplier_price) ?
                                                        <ErrorMessage> Unit cost is required </ErrorMessage> : ''
                                                }
                                            </DataCell>

                                            {
                                                isNotBase ?
                                                    <DataCell variant='200'>
                                                        <TextField
                                                            submitted={submitted}
                                                            value={item.usd_cost ? item.usd_cost.toFixed(3) : item.usd_cost}
                                                            name="usd_cost"
                                                            readOnly
                                                            type="number"
                                                        />
                                                    </DataCell>
                                                    : null
                                            }

                                            <DataCell variant='150'>
                                                <TextField
                                                    value={item.sub_total}
                                                    name="total"
                                                    onChange={(e) => handleChangeSubTotal(e, index)}
                                                    type="number"
                                                    id="sub_total"
                                                />
                                            </DataCell>

                                            {
                                                isNotBase ?
                                                    <DataCell variant='200'>
                                                        <TextField
                                                            value={item.usd_total ? item.usd_total.toFixed(3) : item.usd_cost}
                                                            name="usd_total"
                                                            type="number"
                                                            readOnly
                                                            id="usd_total"
                                                        />
                                                    </DataCell>
                                                    : null
                                            }

                                            <DataCell>
                                                <button
                                                    onClick={() => removeRow(item.uuid)}
                                                    type="button"
                                                    className="btn btn-sm sms-btn-dismiss"
                                                    data-testid="remove-row"
                                                >
                                                    <FontAwesomeIcon icon={faMinus} />
                                                </button>

                                            </DataCell>
                                        </tr>))}
                                    <tr>

                                        <DataCell style={{ borderRight: '1px solid #fff' }}>
                                            <button
                                                type="button"
                                                className="btn btn-sm sms-gray-btn"
                                                id="add-row"
                                                onClick={handleAddRow}
                                            >
                                                Add another line
                                            </button>
                                        </DataCell>

                                        <DataCell colSpan={isNotBase ? 3 : 2} align="right">
                                            Grand Total
                                        </DataCell>

                                        <DataCell colSpan={isBaseCurrency ? 2 : 0}>
                                            <TextField
                                                type="text"
                                                disabled={true}
                                                name='sub_total'
                                                value={grand_total}
                                            />
                                        </DataCell>

                                        {
                                            isNotBase ?
                                                <DataCell>
                                                    <TextField
                                                        type="text"
                                                        disabled={true}
                                                        name='sub_total'
                                                        value={usdAmount ? usdAmount.toFixed(3) : usdAmount}
                                                    />
                                                </DataCell>
                                                : null
                                        }
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        {/* <div className="col-lg-6 col-md-12 col-sm-12"> */}

                        <div className="text-lg-right text-md-right text-sm-left text-xl-right">
                            <button
                                type="button"
                                disabled={isPendingDraft}
                                onClick={(event) => { handleSubmit(event, true); }}
                                className="btn btn-sm sms-info-btn px-4 mr-4"
                            >
                                {isPending === 'pending' ? "Saving..." : "Save Draft"}
                            </button>

                            <button
                                type="button"
                                className="btn btn-sm sms-btn px-4"
                                onClick={handleOpenConfirm}
                            >
                                Save Final
                            </button>
                        </div>

                        {/* </div> */}

                    </form>
                </Card>
            </div>

        </div>
    )
};
function mapStateToProps(state) {
    const { snackbar } = state;
    return { snackbar };
}

function mapDispatchToProps(dispatch) {
    return { actions: { snackbarActions: bindActionCreators(snackbarActions, dispatch) } }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddPurchase);
