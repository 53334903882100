import React, { useState, useEffect, useCallback } from 'react'
import {StickyHeadTable, CustomTableCell, MuiPaper} from '../../Utils/Tables/StickyHeaderTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList } from '@fortawesome/free-solid-svg-icons/faClipboardList';
import PageTitle from '../../Utils/smsTitle';
import { SubHeader } from '../../../Containers/SubHeader';
import { dateConvert, formatDateTime } from '../../Utils/ConvertDate';
import axios from 'axios';
import { config } from "../../../Helpers/env"
import { errorMessages, logoutErrorMessage } from '../../../Helpers/ErrorMessages';
import * as snackbarActions from "../../../actions/snackbarActions";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import MainSnackbar from '../../Utils/Snackbar/SmsSnackbar';
import { DateTimeFilter } from '../../Utils/ReusableComponents/DateTimeFilter';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { LoadingGif } from '../../Utils/Loader';
import ReusableTabs, {TabPanel} from '../../Utils/Dialogs/ReusableTabs';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { todayTime } from '../../Utils/ConvertDate';
import {BackButton, DataExportButton} from '../../Utils/Buttons/DataExportationButton';
import Label from "../../Utils/FormInputs/Label";
import { AutoCompleteControlled } from '../../Utils/FormInputs/AutoCompleteInput';
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Link} from "react-router-dom";
import {useExcelReports, usePdfDownload} from "../../Utils/ReusableComponents/useExcelExport";
import {CustomTablePagination, EnhancedSearchTableToolbar} from "../../Utils/Tables/CustomTable";
import {useCurrency} from "../../../Context/currency-context";
import { useVersionsContext } from '../../../Context/versions-context';




function TotalRevenueGeneratedReport({actions, snackbars}) {
    const {currency:selectedCurrency} = useCurrency({actions, snackbars})
    const current_date = todayTime()
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [activePage, setActivePage] = React.useState(1);
    const [state, setState] = useState({start_date:dateConvert(), end_date:dateConvert()})
    const [revenues, setRevenues] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [loading, setLoading] = useState('idle')
    const {start_date, end_date} = state;
    const [tabValue, setTabValue] = useState(0)
    const [totalCollections, setTotalCollections] = useState(0)
    const [totalGrandCollections, setTotalGrandCollections] = useState('')
    const [totalCollectionsSsp, setTotalCollectionsSsp] = useState(0)
    const [total_count, setTotalCount] = useState(0)
    const [providerOptions, setProviderOptions] = useState([]);
    const [insurance_provider, setInsuranceProvider] = useState('');
    const [receivedbyOptions, setReceivedbyOptions] = useState('')
    const [received_by, setReceivedBy] = useState({value:'',label:''})
    const isCredit = tabValue === 2;
    const [search, setSearch] = useState('')
    const {subscription} = useVersionsContext()
    const version = subscription?.version

    const bill_mode = {
        0:'all',
        1:'cash',
        2:'account'
    }

    const headData = [
        {id: 'count', label: 'Sl'},
        {id: 'visit_date', label: 'Visit Date'},
        {id: 'visit', label: 'Visit No.',},
        {id: 'patient_number', label: 'Patient No.'},
        {id: 'patient_type', label: 'Patient Type'},
        {id: 'patient_name',label: 'Patient Name',},
        {id: 'total_amount',label: `Total Bill`},
        // {id: 'total_bill',label: 'Total Bill(SSP)'},
        isCredit ? {id: 'provider_name',label: 'Provider Name'} : null,
        isCredit ? {id: 'member_card_no',label: 'Member Card No.'} : null,
      ]

    const retrieveData = useCallback(()=>{
        let params = {   start_date,end_date,
            bill_mode:bill_mode[tabValue], download:false,
            provider_id : (tabValue === 2) ? (insurance_provider?.value ? insurance_provider?.value : '') : '',
            received_by: received_by?.value ? received_by?.value :  '',
            page:activePage,
            search: search,
            per_page:rowsPerPage
        }
        setLoading('pending')
        axios.get(`${config.smsUrl}/cbilling/collections`, {params}).then(response=>{
            const res = response.data ? response.data:{};
            const data = res.data ? res.data : [];
            const total_usd = res.total_usd ? res.total_usd : 0;
            const total_ssp = res.total_ssp ? res.total_ssp : 0;
            const grand_total_amount = res.grand_total_amount ? res.grand_total_amount : 0;
            const count = res.count ? res.count : 0;
            setTotalCount(count)
            setRevenues(data)
            setTotalCollections(total_usd)
            setTotalCollectionsSsp(total_ssp)
            setTotalGrandCollections(grand_total_amount)
            setLoading('success')
        }).catch(err=>{
            logoutErrorMessage(err, null,actions)
            setLoading('error')
        })

    },[tabValue, insurance_provider, received_by,activePage, search, rowsPerPage])

    useEffect(() => {
        setActivePage(1)
        setPage(0)
        setRowsPerPage(50)
    }, [tabValue]);

    useEffect(()=>{
        retrieveData()
    },[retrieveData])

    useEffect(() => {
        if(tabValue !== 2){
            if (insurance_provider) { setInsuranceProvider(''); }
            return
        }
        axios.get(`${config.smsUrl}/cbilling/insurance_providers/list`).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const arr = !dt.data ? [] : dt.data;
            const provs = arr.map(item=>({
                ...item, 
                value:item.provider_id, 
                label:item.provider_name}));
            setProviderOptions(provs);
        }).catch(err => {
         logoutErrorMessage(err,null,actions)
        })
    }, [tabValue]);

    useEffect(() => {
        if(tabValue !== 1){
            if (received_by) { setReceivedBy(''); }
            return
        }
        axios.post(`${config.smsUrl}/permission/user_roles_list`).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const arr = !dt.user_roles_list ? [] : dt.user_roles_list;
            const provs = arr.map(item=>({
                ...item, 
                value:item.user_roles_id, 
                label:(`${item.first_name} ${item.last_name}`)}));
            setReceivedbyOptions(provs);
        }).catch(err => {
         logoutErrorMessage(err,null,actions)
        })
    }, [tabValue, insurance_provider, received_by]);
  
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setActivePage(newPage+1);
    };
  
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        setActivePage(1);
    };

    const handleProviderChange = (value) =>{
        setInsuranceProvider(value);

    }

    const handleChangeReceivedBy = (value) =>{
        setReceivedBy(value)
    }

    const handleChange = (event) =>{
        const {value, name} = event.target;
        setState({...state,[name]:value})
    }

    const handleSubmit = (event) =>{
        event.preventDefault()
        
        setSubmitted(true)
        if(start_date && end_date && end_date >= start_date){
            setIsSubmitted('pending')
            setLoading('pending')
            axios.get(`${config.smsUrl}/cbilling/collections`, {params:{start_date,end_date,
                bill_mode:bill_mode[tabValue], download:false,
                provider_id : (tabValue === 2) ? insurance_provider.provider_id : '',
                received_by :  received_by?.value ? received_by?.value :  '', page:activePage,
                    per_page:rowsPerPage
                }}).then(response=>{
                const res = response.data ? response.data:{};
                const data = res.data ? res.data : [];
                const total_usd = res.total_usd ? res.total_usd : 0;
                const total_ssp = res.total_ssp ? res.total_ssp : 0;
                const grand_total_amount = res.grand_total_amount ? res.grand_total_amount : 0;
                const total = res.total ? res.total : 0;
                const count = res.count ? res.count : 0;
                setTotalCount(count)
                setTotalCollections(total)
                setRevenues(data)
                setTotalCollections(total_usd)
                setTotalCollectionsSsp(total_ssp)
                setTotalGrandCollections(grand_total_amount)
                setLoading('success')
                setIsSubmitted('resolved')
            }).catch(err=>{
                errorMessages(err, null,actions)
                setLoading('error')
                setIsSubmitted('rejected')
            })
        }

    }

    const fileName = {
        0:'All Collections',
        1:'Cash Collections',
        2:'Credit Collections'
    } 


    const handleChangeTabValue = (event, val) => {
        setTabValue(val)
    }

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const isLoading = loading === 'pending';
    const isSuccess = loading === 'success';
    const isError = loading === 'error';
    
    const isPending = isSubmitted === 'pending';
    // const isResolved = isSubmitted === 'resolved';
    // const isRejected = loading === 'rejected';
    const {openBar, type, message} = snackbars;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, revenues.length - page * rowsPerPage);

    const theme = useTheme();
    const isExtraLarge = useMediaQuery(theme.breakpoints.up('xl'));
    const isMedium = useMediaQuery(theme.breakpoints.up('md'));
    const isLarge = useMediaQuery(theme.breakpoints.up('lg'));

    const colSpan = isExtraLarge ? 12 : isMedium ? 6 : isLarge ? 10 : headData.length
  

    const total_text = {
        0:'Collections',
        1:'Cash Collections',
        2:'Credit Collections'
    }

    const pagination = (
        <CustomTablePagination
            colSpan={3}
            page={page}
            rowsPerPage={rowsPerPage}
            count={total_count}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            isTotalCount
        />
    );

    let params = {   start_date,end_date,
        bill_mode:bill_mode[tabValue], download:true,
        provider_id : (tabValue === 2) ? (insurance_provider.value ? insurance_provider.value : '') : '',
        received_by: received_by.value ? received_by.value :  '',
        page:activePage
    }

    const {exportExcel, isLoading:isLoadingExcel} = useExcelReports(actions,{file_name:`${fileName[tabValue]}-${current_date}`, url:`cbilling/collections`, params:{...params, download_type:1}})
    const {exportPdf, isLoading:isLoadingPdf} = usePdfDownload(actions,{file_name:`${fileName[tabValue]}-${current_date}`, url:`cbilling/collections`, params:{...params, download_type:2}})


    const handleSearch =(event)=>{
        setSearch(event.target.value)
    }

    const reusableTable = (
        <div className='mt-3'>
            {
                total_text[tabValue] === 'Credit Collections' ?
                // <div style={{display:'flex', justifyContent:'space-between', marginBottom:'20px'}}>
                //     <div style={{display:'flex'}}>
                //             <Label name='Filter by Provider:' style={{marginRight:'30px'}}/>
                //             <SelectInput submitted={submitted} value={insurance_provider} name="insurance_provider" onChange={handleProviderChange} options={providerOptions}
                //                 defaultOption="Select..."/>
                //     </div>
                //     <div>
                //         <RightAlignedContainer>
                //             <h5>{`Total ${total_text[tabValue]}`} : <strong>{formatAmount(totalCollections)}</strong></h5>
                //         </RightAlignedContainer>
                //     </div>
                <div>
                    <div className='col-lg-6'>
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                                <Label name="Filter by" type/>
                            </Col>

                            <Col xs={12} sm={12} md={12} lg={7} xl={7}>

                                <AutoCompleteControlled options={providerOptions} medicine_value={insurance_provider} handleChange={handleProviderChange}
                                    placeholder="Filter by provider..."  dataTest="filter_by_provider"
                                 value_text='value' label_text='label' />
                            </Col>
                       </Form.Group>
                    </div>
                {/*<div>*/}
                     <div className='text-xl-right text-lg-right text-md-left text-sm-left'>
                        <h5>{`Total ${total_text[tabValue]})`} : <strong>{totalGrandCollections}</strong></h5>
                    </div>
                    {/*<div className='text-xl-right text-lg-right text-md-left text-sm-left'>*/}
                    {/*    <h5>{`Total ${total_text[tabValue]}(SSP)`} : <strong>{totalCollectionsSsp}</strong></h5>*/}
                    {/*</div>*/}
                 {/*</div>*/}
                </div>
                 : total_text[tabValue] === 'Cash Collections' ?
                <div>
                    <div className='col-lg-6'>
                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                            <Label name="Filter by" type/>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                 
                    <AutoCompleteControlled options={receivedbyOptions} handleChange={handleChangeReceivedBy}
                         placeholder="Filter by receiver..."  dataTest="received_by" medicine_value={received_by}
                                            value_text='value' label_text='label' />
                    
                    </Col>
                    </Form.Group>
                    </div>
                    <div className='text-xl-right text-lg-right text-md-left text-sm-left'>
                            <h5>{`Total ${total_text[tabValue]}`} : <strong>{totalGrandCollections}</strong></h5>
                        </div>
                        {/*<div className='text-xl-right text-lg-right text-md-left text-sm-left'>*/}
                        {/*    <h5>{`Total ${total_text[tabValue]}(SSP)`} : <strong>{totalCollectionsSsp}</strong></h5>*/}
                        {/*</div>*/}
                </div>
                  
                :
                <>
                <div className='text-xl-right text-lg-right text-md-left text-sm-left'>
                <h5>{`Total ${total_text[tabValue]}`} : <strong>{(totalGrandCollections)}</strong></h5>
                </div>
               {/* <div className='text-xl-right text-lg-right text-md-left text-sm-left'>*/}
               {/* <h5>{`Total ${total_text[tabValue]}(SSP)`} : <strong>{(totalCollectionsSsp)}</strong></h5>*/}
               {/*</div>*/}
                </>
            
            }
            <MuiPaper>
                <EnhancedSearchTableToolbar term={search} handler={handleSearch}/>
               
            <StickyHeadTable {...{page,rowsPerPage,handleChangePage,handleChangeRowsPerPage,data:revenues, columns:headData.filter(item=>Boolean(item)), pagination}}>
            {isLoading ? <TableRow><TableCell align='center' {...{colSpan}}><LoadingGif/></TableCell></TableRow>:null}
            {isSuccess ? revenues.length > 0 ? 
                <>
                    {revenues.slice(0, rowsPerPage).map((row, index) => {
                        const patient_name = `${row.first_name ? row.first_name : ''} ${row.last_name ? row.last_name : ''}`
                        const url = start_date && end_date ? `/patient-transactions/${row.visit_id}-${patient_name}/${tabValue}/${start_date}/${end_date}`:
                            `/patient-transactions/${row.visit_id}-${patient_name}/${tabValue}`
                        const count = ((activePage - 1) * rowsPerPage) + (index + 1)
                    return (
                    <TableRow  tabIndex={-1} key={index}>
                        <CustomTableCell>{count}</CustomTableCell>
                        <CustomTableCell>{row.begin_datetime ? formatDateTime(row.begin_datetime): null}</CustomTableCell>
                        <CustomTableCell>{row.visit_id}</CustomTableCell>
                        <CustomTableCell>{row.patient_number}</CustomTableCell> 
                        <CustomTableCell>{row.patient_type==1? "Out Patient":"Inpatient"}</CustomTableCell>     
                        <CustomTableCell>
                            <Link to={{pathname:url}}>{patient_name}</Link>
                        </CustomTableCell>
                        <CustomTableCell>{(row.total_amount)}</CustomTableCell>
                        {/*<CustomTableCell>{(row.total_ssp)}</CustomTableCell>*/}
                        {isCredit ? <CustomTableCell>{row.billed_to}</CustomTableCell>: null}
                        {isCredit ? <CustomTableCell>{row.card_number}</CustomTableCell>: null}
                    </TableRow>
                    );
            })}
            {/*    {emptyRows > 0 && (*/}
            {/*    <TableRow style={{ height: 43 * emptyRows }}>*/}
            {/*    <TableCell colSpan={headData.length} />*/}
            {/*    </TableRow>*/}
            {/*)}*/}
            </>:<TableRow><TableCell align='center'  {...{colSpan}}>No records found</TableCell></TableRow>:null}
            {isError ? <TableRow><TableCell align='center'  {...{colSpan}}>The server did not return a valid response</TableCell></TableRow>:null}
        </StickyHeadTable>
            </MuiPaper>

    </div>
    )
    const tabs = ['All Collections', 'Cash Collections', 'Credit Collections']
    const tabsStandard = ['All Collections', 'Cash Collections']
    return (
        <div className='journals'>
            <PageTitle title='Revenue Generated'/>
            <SubHeader title="Reports" subTitle='Revenue Generated'>
                <FontAwesomeIcon icon={faClipboardList}/>
            </SubHeader>
            <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>
            <div className='mx-3'>
                <div style={{margin: '10px'}}>
                    <BackButton text={'General Reports'} to={'/reports/generalreports'}/>
                </div>

                <div className='general-ledger-header' style={{marginBottom:0, display:'flex', justifyContent:'space-between', flexWrap:'wrap'}}>
                    <DateTimeFilter {...{start_date,end_date, handleChange, submitted,handleSubmit,isPending,type:'date'}}/>
                    <div style={{margin: '10px'}}>
                        <DataExportButton exportCsv={exportExcel} isLoading={isLoadingExcel || isLoadingPdf} downLoadPdf={exportPdf}/>
                    </div>
                </div>
                <ReusableTabs value={tabValue} tabHeading={version == 'Premium' ? tabs: tabsStandard } handleChange={handleChangeTabValue}>
                        <TabPanel value={tabValue} index={0}>
                            {reusableTable}
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                            {reusableTable}
                        </TabPanel>
                        <TabPanel value={tabValue} index={2}>
                            {reusableTable}
                        </TabPanel>
                </ReusableTabs>
            
            </div>
        </div>
    )
}
function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(TotalRevenueGeneratedReport);
