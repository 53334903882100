/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useEffect, useState } from "react";
import { formatDateTime } from "../Utils/ConvertDate";
import { titleCase } from "../Users/addUser";
import { detailsTable, table, textWeight } from "../../styles/tableStyles";
import { ItemText } from "../Utils/Lists/ReportList";
import { ItemsTable, PrintTemplate } from "../Utils/Templates/PrintTemplate";
import { splitNotes } from "../Radiology/RadiologyReport";
import * as colors from "../../styles/colors";
import { usePrint } from "../Utils/Templates/usePrint";
import axios from "axios";
import { config } from "../../Helpers/env";
import {
  NewReleasesOutlined,
  SignalCellularNullTwoTone,
} from "@material-ui/icons";

const tdComments = css`
  border-bottom: 1px solid ${colors.text01};
`;

const approvalTable = css`
  margin-top: 3rem;
`;
const approvalSpan = css`
  border-bottom: 1px solid ${colors.text01};
  display: inline-block;
`;

const headData = [
  { key: "parameter", value: "Parameter" },
  { key: "result", value: "Result" }, //{key:'flag',value:'flag'},
  { key: "unit", value: "Unit" },
  { key: "ref_range", value: "Reference Range" },
];

const noneRefData = [
  { key: "parameter", value: "Parameter" },
  { key: "result", value: "Result" },
];
const LabResultsReport = ({ resultDetails, params }) => {
  console.warn(resultDetails);
  const {
    patient_firstname,
    patient_lastname,
    lab_first_name,
    lab_last_name,
    notes,
    age,
    gender,
    address,
    date_created,
    patient_number,
    hospital_test_name,
    doctor_first_name,
    doctor_last_name,
    approved_by_first_name,
    approved_by_last_name,
    visit_id,
    approved_at = null,
  } = resultDetails;
  const name = `${lab_first_name ? lab_first_name : ""} ${
    lab_last_name ? lab_last_name : ""
  }`;
  const patient_name = `${!patient_firstname ? "" : patient_firstname} ${
    !patient_lastname ? "" : patient_lastname
  }`;
  const date = date_created ? formatDateTime(date_created) : "";
  const approved_at_date = approved_at ? formatDateTime(approved_at) : "";
  const doctor_name = `${!doctor_first_name ? "" : doctor_first_name} ${
    !doctor_last_name ? "" : doctor_last_name
  }`;
  const approvedBy = `${approved_by_first_name ? approved_by_first_name : ""} ${
    approved_by_last_name ? approved_by_last_name : ""
  }`;

  const [visitDetails, setVisitDetails] = useState({});

  useEffect(() => {
    if (!visit_id) {
      return;
    }
    axios
      .get(`${config.smsUrl}/cvisit/${visit_id}`)
      .then((res) => {
        const data = res.data;
        const dt = !data ? {} : data;
        setVisitDetails(dt);
      })
      .catch((err) => {});
  }, [visit_id]);

  const isReferenceRange = params.some((item) => item.reference_range);
  const patientDetails = (
    <tbody>
      <tr>
        <td>
          <span>Patient Name: </span>{" "}
          <span css={[textWeight]}>{patient_name}</span>
        </td>
        <td>
          <span>Patient No.: </span>{" "}
          <span css={[textWeight]}>{patient_number}</span>
        </td>
      </tr>
      <tr>
        <td>
          <span>Gender: </span> <span css={[textWeight]}>{gender}</span>
        </td>
        <td>
          <span>Date & Time:</span> <span css={[textWeight]}>{date}</span>
        </td>
      </tr>
      <tr>
        <td>
          <span>Address: </span> <span css={[textWeight]}>{address}</span>
        </td>
        <td>
          <span>Lab Technician:</span> <span css={[textWeight]}>{name}</span>
        </td>
      </tr>
      <tr>
        <td>
          <span>Age: </span>{" "}
          <span>
            <strong>{age}</strong>
          </span>
        </td>
        <td>
          <span>Requested by: </span>{" "}
          <span css={[textWeight]}>{doctor_name}</span>
        </td>
      </tr>
      <tr>
        <td>
          <span>Payment Mode: </span>{" "}
          <span css={[textWeight]}>
            {visitDetails.is_on_insurance === 1
              ? "CREDIT"
              : visitDetails.is_on_insurance === 0
              ? "CASH"
              : ""}
          </span>
        </td>
        {visitDetails.is_on_insurance === 1 ? (
          <td>
            <span>Provider Name: </span>{" "}
            <span css={[textWeight]}>
              {visitDetails?.insurance_provider_name}
            </span>
          </td>
        ) : (
          <td />
        )}
      </tr>
      <tr>
        {visitDetails.is_on_insurance === 1 ? (
          <td>
            <span>Account Name: </span>{" "}
            <span css={[textWeight]}>{visitDetails?.account_name}</span>
          </td>
        ) : (
          <td />
        )}
        <td />
      </tr>

      <tr>
        <td>
          <span>Approved on: </span>{" "}
          <span css={[textWeight]}>{approved_at_date}</span>
        </td>
      </tr>
    </tbody>
  );
  const details = (
    <>
      <ItemText data-testid="lab_result_name">{hospital_test_name}</ItemText>
      <ItemsTable
        headData={isReferenceRange ? headData : noneRefData}
        testId="lab-results-details"
      >
        <tbody>
          {params.map((test) => {
            return (
              <tr key={test.hospital_test_id}>
                <td>
                  <span>
                    {test.result_type === 2
                      ? titleCase(test.sub_test_name)
                      : test.result_type === 1 &&
                        test.sub_test_name === "blood_group"
                      ? "Blood Group"
                      : test.result_type === 1
                      ? "Result"
                      : "Observations"}
                  </span>
                </td>
                {test.result_type === 1 ? (
                  <td>
                    {test.sub_test_name === "+ve/-ve" ? (
                      <span>
                        {test.test_value === "1" ? "Positive" : "Negative"}
                      </span>
                    ) : test.sub_test_name === "reactive/non-reactive" ? (
                      <span>
                        {test.test_value === "3" ? "Reactive" : "Non-reactive"}
                      </span>
                    ) : test.sub_test_name === "blood_group" ? (
                      <span>{test.test_value}</span>
                    ) : (
                      <p />
                    )}
                  </td>
                ) : (
                  <td colSpan={test.result_type === 3 ? 4 : 0}>
                    <span>
                      {test.result_type === 3
                        ? splitNotes(test.test_value)
                        : test.test_value}
                    </span>
                  </td>
                )}
                {isReferenceRange ? (
                  <td>
                    <span>{test.unit ? test.unit : "NA"}</span>
                  </td>
                ) : null}
                {isReferenceRange ? (
                  <td>
                    <span>
                      {test.reference_range ? test.reference_range : "NA"}
                    </span>
                  </td>
                ) : null}
              </tr>
            );
          })}
          {notes ? (
            <tr>
              <td
                colSpan={
                  isReferenceRange ? headData.length : noneRefData.length
                }
                css={[textWeight]}
                style={{ paddingBottom: 0 }}
              >
                <span>Comments</span>
              </td>
            </tr>
          ) : null}
          {notes ? (
            <tr>
              <td colSpan={headData.length} css={[tdComments]}>
                <span>{notes ? splitNotes(notes) : notes}</span>
              </td>
            </tr>
          ) : null}
        </tbody>
      </ItemsTable>
    </>
  );

  const approval = (
    <table
      css={[table, detailsTable, approvalTable]}
      className="lab-results-approval"
    >
      <tbody>
        <tr>
          <td>
            <span>Carried Out By </span>
          </td>
          <td />
          <td align="right">
            <span>Approved By</span>
          </td>
        </tr>
        <tr>
          <td>
            <span css={[approvalSpan]}>{name}</span>
          </td>
          <td />
          <td align="right">
            <span css={[approvalSpan]}>{approvedBy}</span>
          </td>
        </tr>
      </tbody>
    </table>
  );

  const itemDetails = (
    <>
      {details}
      {approval}
    </>
  );

  return (
    <div>
      <PrintTemplate title="Lab Results" {...{ patientDetails, itemDetails }} />
    </div>
  );
};
export { LabResultsReport };
