import React from "react";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CustomTable from "../../Utils/Tables/CustomTable";
import FilterResults from "react-filter-search";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {bindActionCreators} from "redux";
import {Container, RightAlignedContainer} from "../../Utils/styledComponents";
import {connect} from "react-redux";
import * as snackbarActions from "../../../actions/snackbarActions";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import { faCoins } from "@fortawesome/free-solid-svg-icons/faCoins";
import { useInvoiceProviders } from "./hooks/useInvoiceProviders";
import CustomDialog from "../../Utils/Dialogs/CustomDialog";
import { NewProvider } from "./NewProvider";
import { history } from "../../../Helpers/history";
import {CsvDialog} from "../../Utils/Dialogs/CsvDialog";
import {BlockNavigationComponent} from "../../Utils/ReusableComponents/BlockNavigationComponent";
import ReusableTabs, {TabPanel} from "../../Utils/Dialogs/ReusableTabs";

const headData = [
    {id: 'count', numeric: false, disablePadding: false, label: 'Sl'},
    {id: 'provider_name', numeric: false, disablePadding: false, label: 'Provider Name'},
    {id: 'provider_type', numeric: false, disablePadding: false, label: 'Provider Type'},
    {id: 'contact', numeric: false, disablePadding: false, label: 'Contact'},
    {id: 'action', numeric: false, disablePadding: false, label: 'Action'},
];

const InvoiceProviders = ({actions, snackbars}) => {
    const {providerProps,providers,searchValue, page, rowsPerPage,isLoading, isSuccess, isError,openDialog,
        handleSearch, handleChangePage, handleChangeRowsPerPage,setPage,
        closeSnackbar, handleOpenDialog, handleCloseDialog, uploadProps} = useInvoiceProviders(actions)
    const { isBlocking,isBlockDialog,setIsBlockDialog,handleResetForm, openEdit, handleOpenEditInsurance, handleCloseEditInsurance} = providerProps
    const {openBar, type, message} = snackbars;
    const {handleOpenCsvDialog} = uploadProps

    const [tabValue, setTabValue] = React.useState(0);

    const handleChangeTabValue = (event, val) => {
        setTabValue(val)
        setPage(0)
    }

    const tabs = ['Insurance Company', 'Individuals','Organisation']



    const table = (
        <CustomTable  handleChangePage={handleChangePage}
                      handleChangeRowsPerPage={handleChangeRowsPerPage}
                      headData={headData} handler={handleSearch} page={page} rowsPerPage={rowsPerPage}
                      data={providers.filter(item=>item.provider_type === tabs[tabValue])} colSpan={headData.length}
                      id="searchValue" filter>

            <FilterResults value={searchValue} data={providers}
               renderResults={results => (
                   <TableBody>
                       {isLoading ? <TableRow>
                           <TableCell colSpan={headData.length} align="center">
                               <img src="/images/smsloader.gif" alt="loader"/>
                           </TableCell>
                       </TableRow>:null}
                       {isSuccess ? providers.length ?  results
                           .filter(item=>item.provider_type === tabs[tabValue])
                           .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                           .map((item, idx) => {
                               const cnt = (page * rowsPerPage) + (idx + 1);
                               return (
                                   <TableRow
                                       hover
                                       role="checkbox"
                                       tabIndex={-1}
                                       key={item.provider_id}
                                       className='mui-table-row'
                                       id="services"
                                   >
                                       {headData.map(column=>(
                                           <TableCell key={column.id}>{column.id === 'count' ? cnt :  item[column.id]}</TableCell>
                                       ))}
                                   </TableRow>
                               );
                           }):<TableRow>
                           <TableCell colSpan={headData.length} align="center">No records found</TableCell>
                       </TableRow>:null}
                       {isError ? <TableRow>
                           <TableCell colSpan={headData.length} align="center">The server did not return a valid response</TableCell>
                       </TableRow>:null}
                   </TableBody>
               )}/>
        </CustomTable>
    )

    return (
        <div className='journals'>
            <PageTitle title="Invoice Providers"/>
            <SubHeader title="Billing" subTitle="Invoice Providers">
            <FontAwesomeIcon icon={faCoins}/>
            </SubHeader>
            <BlockNavigationComponent
                when={isBlocking}
                shouldBlockNavigation={()=>{
                    return isBlocking;
                }}
                navigate={path=>history.push(path)}
                dialogClose={isBlockDialog}
                setIsBlockDialog={setIsBlockDialog}
                clearForm={handleResetForm}
            />
            <CustomDialog open={openDialog} handleClose={handleCloseDialog} title={"New Provider"}>
                <NewProvider {...providerProps}/>
            </CustomDialog>

            <CustomDialog open={openEdit} handleClose={handleCloseEditInsurance} title={"Edit Provider"}>
                <NewProvider {...providerProps}/>
            </CustomDialog>
            <CsvDialog {...{...uploadProps, file:"/csv/provider_data.csv", title:'Upload Providers'}}/>

            <Container>
                <RightAlignedContainer>
                    <div className="my-3">
                        <button onClick={handleOpenDialog} className="btn sms-btn btn-sm mr-4">
                            <FontAwesomeIcon icon={faPlus}/> New Provider</button>
                        <button onClick={handleOpenCsvDialog} id="upload-providers" className="btn sms-info-btn btn-sm">Upload Providers
                        </button>
                    </div>

                </RightAlignedContainer>
                <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>

                <ReusableTabs value={tabValue} tabHeading={tabs} handleChange={handleChangeTabValue}>
                    <TabPanel value={tabValue} index={0}>
                        {table}
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        {table}
                    </TabPanel>
                    <TabPanel value={tabValue} index={2}>
                        {table}
                    </TabPanel>
                </ReusableTabs>


            </Container>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceProviders);
