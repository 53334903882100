import {useEffect, useState} from "react";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import { useAddShiftCategory } from "./useAddShiftCategory";
import { useEditShiftCategory } from "./useEditShiftCategory";

export const useShiftCategoryList = (props) =>{
    const {actions} = props;
    const [searchValue, setSearchValue] = useState('');
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('sl');
    const [shift_id, setShiftCategoryId] = useState('');
    const [shiftCategories, setShiftCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const [total_count, setCount] = useState(0);
    const {addShiftCatProps} =useAddShiftCategory(actions)
    const {editShiftCatProps} = useEditShiftCategory(actions)
    

    useEffect(() => {
        setLoading(true);
        const formData = new FormData();
        formData.append('search', searchValue);
        axios.post(`${config.smsUrl}/cshiftschedule/shift_list/${activePage}`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const arr = !dt.shift ? [] : dt.shift;
            const count = !dt.total_count ? 0 : dt.total_count;
            setShiftCategories(arr);
            setCount(count);
            setLoading(false)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
            setLoading(false)
        })

    }, [activePage, searchValue, addShiftCatProps.isResolved, editShiftCatProps.isResolved]);

    const handleOpenDelete = (id) => {
        setOpenDelete(true);
        setShiftCategoryId(id);
    };

    const handleCloseDelete = () => {
        setOpenDelete(false)
    };

    const handleChangeActivePage = (pg) => {
        setActivePage(pg)
    };

    const handleDelete = () => {
        const formData = new FormData();
        formData.append('shift_id', shift_id);
        axios.post(`${config.smsUrl}/cshiftschedule/delete_shift_category`, formData).then(() => {
            const list = shiftCategories.filter(shift => shift.shift_id !== shift_id);
            setShiftCategories(list);
            actions.snackbarActions.snackSuccess('Shift category deleted successfully');
            setOpenDelete(false)
        }).catch(err => {
            errorMessages(err, null, actions)
        })
    };

    const handleSearch = (event) => {
        setSearchValue(event.target.value);
    };


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };
    return {searchValue, order, orderBy, shift_id, shiftCategories, loading, openDelete, activePage,
        total_count, handleOpenDelete, handleCloseDelete, handleChangeActivePage, handleDelete,
        handleSearch, handleRequestSort, closeSnackbar,setShiftCategoryId,
        setShiftCategories, addShiftCatProps, editShiftCatProps}
}