import React from "react";
import  {AddProductForm} from "./AddProductForm";
import * as snackbarActions from "../../../actions/snackbarActions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux"
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {useAddProduct} from "./hooks/useAddProduct";
import {RightAlignedContainer} from "../../Utils/styledComponents";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faShippingFast} from "@fortawesome/free-solid-svg-icons/faShippingFast";
import {useTheme} from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import {Link} from "react-router-dom";
import {faListAlt} from "@fortawesome/free-solid-svg-icons/faListAlt";
import {BlockNavigationComponent} from "../../Utils/ReusableComponents/BlockNavigationComponent";
import {history} from "../../../Helpers/history";
import {BackButton} from "../../Utils/Buttons/DataExportationButton";


const AddProduct = ({actions, snackbar, match:{params}}) => {
    const {product_id} = params;
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    const {productProps, handleCloseSnackbar, renderAddUnit, handleOpenDialog,
        renderAddMedicineType, handleOpenMedicineType} = useAddProduct({actions, product_id});
    const {openBar,type,message} = snackbar;

    const {isBlocking} = productProps


    const unitButton = (
        <button type='button' id="quick-add-unit" onClick={handleOpenDialog} className='btn btn-sm sms-info-btn mr-4'>Quick add unit</button>
    )


    const medicineTypeButton = (
        <button type='button' id="quick-add-type" onClick={handleOpenMedicineType} className='btn btn-sm sms-info-btn mr-4'>Quick add product type</button>
    )
    return (
        <div data-testid="add-product">
            <PageTitle title='Add Product'/>
            <SubHeader title="Inventory" subTitle='Add Product'>
                <FontAwesomeIcon icon={faShippingFast}/>
            </SubHeader>
            {renderAddUnit}
            {renderAddMedicineType}
            <MainSnackbar handleCloseBar={handleCloseSnackbar} message={message} open={openBar} variant={type}/>
            <div className='general-ledger-header'>
                <div className='row'>
                    <div className='col-lg-6'>
                        <BackButton to='/productlist' text='Products'/>
                    </div>
                    <div className='col-lg-6'>
                        <RightAlignedContainer>
                            {unitButton}
                            {medicineTypeButton}
                        </RightAlignedContainer>
                    </div>
                </div>

            </div>
            <BlockNavigationComponent
                when={isBlocking}
                shouldBlockNavigation={()=>{
                    return isBlocking;
                }}
                navigate={path=>history.push(path)}
            />
            <div className="add-bill-card">
                <Card className="p-3 general-card">
                    <h6 className={`visit-heading ${palette.type === "dark" && "dark-visit-heading"}`}>{`${product_id ? 'Edit' : 'Add'} Product`}</h6>
                    <AddProductForm {...productProps}/>
                </Card>
            </div>

        </div>
    )
};

function mapStateToProps(state) {
    const {snackbar} = state;
    return {snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions:{snackbarActions:bindActionCreators(snackbarActions, dispatch)}}
}
export default connect(mapStateToProps,mapDispatchToProps)(AddProduct);
