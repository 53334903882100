import React from "react";
import {useTheme} from "@material-ui/core/styles";
import Form from "react-bootstrap/Form";
import Label from "../Utils/FormInputs/Label";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TextField from "../Utils/FormInputs/TextField";
import {CustomSelect} from "../Utils/FormInputs/SelectInput";
import { RightAlignedContainer} from "../Utils/styledComponents";
import InputGroup from "react-bootstrap/InputGroup";
import moment from "moment";

const validateOpDate = (date) => {
    if (date) {
        var d1 = new Date();
        d1.setHours(0,0,0,0)
        var d2 = new Date(date);
        return (d2 > d1);
    } 
    return false;
}

const validateOpDateStartTime = (date, time) => {
    if (date && time) {
        var d1 = new Date();
        var d2 = new Date(date + " " + time);
        return (d2 >= d1);
    } else if (time) {
        var d1 = new Date();
        const d = moment().format('L');
        const dd = moment(d +' '+ time).format();
        var d2 = new Date(dd);
        return (d2 >= d1);
    }
    return false;
}

const checkDuration = (hr, mins) => {
    if (hr || mins) {
        if ((hr === "0" || hr === "00") && (mins === "0" || mins === "00")) {
            return false;
        } return true; 
    } return false;
}

/**
 * Component to capture the patients details and schedule the surgery
 * @param {} param0 
 */
export const RescheduleSurgery = ({
    patient_number, surgery_time,  onChangeSurgeryTime, submitted, patient_name, handleRescheduleSurgeryScreen
}) => {

    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;

    const {
        operation_date, estimated_start_time, duration_hr, duration_mins,
        original_op_date, original_start_time, original_duration_hr, original_duration_mins
    } = surgery_time;

    return (
        <div className={`patient-information  ${palette.type === 'dark' && 'dark-patient-information'}`}>
            <div className="mt-2">
                <Form.Group as={Row}>

                    <Col sm={3} >
                        <Label name="Patient Number"  className="surgery-details"/>
                        <TextField type="text"
                        value={patient_number} disabled={true}
                        />

                    </Col>

                    <Col sm={3}>
                        <Label name="Patient Name" className="surgery-details"/>
                        <TextField type="text" value={patient_name}
                                    disabled={true}/>
                    </Col>
        
                </Form.Group>

                <h6 className='patient-personal-details surgery-details'>Current Operation Schedule</h6>

                <Form.Group as={Row}>

                    <Col sm={3}>
                    <Label name="Operation Date" className="surgery-details"/>
                    <Form.Control type="date" disabled={true}
                            defaultValue={original_op_date}
                            className={`form__control ${palette.type === "dark" && 'form-control-dark'}`}/>
                    </Col>


                    <Col sm={3}>
                    <Label name="Estimated Start Time" className="surgery-details"/>
                    <Form.Control type="time" 
                            defaultValue={original_start_time}  disabled={true}
                            className={`form__control ${palette.type === "dark" && 'form-control-dark'}`}/>               
                    </Col>

                    <Col sm={4}>
                    <Label name="Estimated Duration" className="surgery-details"/>
                    <InputGroup className="mb-2">
                        <Form.Control type="text" defaultValue={original_duration_hr}  className="left-border-radius"  disabled={true}/>
                            <InputGroup.Text size="sm" className="zero-border-radius-txt">Hrs</InputGroup.Text>
                            <Form.Control type="text" defaultValue={original_duration_mins} className="zero-border-radius"  disabled={true}/>
                            <InputGroup.Text size="sm" className="right-border-radius-txt">Mins</InputGroup.Text>
                        </InputGroup>                         
                    </Col>

                </Form.Group>


                <h6 className='patient-personal-details surgery-details'>New Operation Schedule</h6>
                <Form.Group as={Row}>
                    <Col sm={3}>
                    <Label name="Operation Date" className="surgery-details"/>
                    <Form.Control type="date"   name="operation_date" autoComplete="off"
                            value={operation_date}
                            onChange={onChangeSurgeryTime}
                            className={`form__control ${palette.type === "dark" && 'form-control-dark'}`}/>
                    {(submitted || (operation_date && !validateOpDate(operation_date)))  &&
                    <div className="help-block">The earliest operation date is today</div>}
                    </Col>


                    <Col sm={3}>
                    <Label name="Estimated Start Time" className="surgery-details"/>
                    <Form.Control type="time"   name="estimated_start_time" autoComplete="off"
                            defaultValue={estimated_start_time}
                            onChange={onChangeSurgeryTime}
                            className={`form__control ${palette.type === "dark" && 'form-control-dark'}`}/>
                    {(submitted || (estimated_start_time && (!validateOpDateStartTime(operation_date, estimated_start_time)))) &&
                    <div className="help-block">Start time should be later than current time</div>}                    
                    </Col>

                    <Col sm={4}>
                    <Label name="Estimated Duration" className="surgery-details"/>
                    <InputGroup className="mb-2">
                        <Form.Control autoComplete="off" type="number" value={duration_hr} name="duration_hr" onChange={onChangeSurgeryTime} min={0} className="left-border-radius"/>
                            <InputGroup.Text size="sm" className="zero-border-radius-txt">Hrs</InputGroup.Text>
                            <Form.Control autoComplete="off" type="number" value={duration_mins} name="duration_mins" onChange={onChangeSurgeryTime} min={0} max={59} className="zero-border-radius"/>
                            <InputGroup.Text size="sm" className="right-border-radius-txt">Mins</InputGroup.Text>
                        </InputGroup>
                        {submitted && (duration_mins > 59) ? <div className="">Max minutes is 59</div> : null}
                        {(!checkDuration(duration_hr, duration_mins)) ? <div className="help-block">Duration is required</div> : null}                           
                    </Col>

                </Form.Group>
                <RightAlignedContainer>
                    <button type="submit" className="btn btn-sm sms-btn" onClick={handleRescheduleSurgeryScreen}>Reschedule</button>
                </RightAlignedContainer>
            </div>
        </div>
    )
};