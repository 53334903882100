import React, {useEffect,  useState} from "react";
import {CustomTablePagination, EnhancedSearchTableToolbar} from "../Utils/Tables/CustomTable";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import *  as snackbarActions from "../../actions/snackbarActions";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import {Container, RightAlignedContainer} from "../Utils/styledComponents";
import axios from "axios";
import {config} from "../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../Helpers/ErrorMessages";
import {LoadingGif} from "../Utils/Loader";
import {CustomTableCell, MuiPaper, StickyHeadTable} from "../Utils/Tables/StickyHeaderTable";
import PageTitle from "../Utils/smsTitle";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDebounce} from "../Lab/useDebounce";
import {faCog} from "@fortawesome/free-solid-svg-icons/faCog";
import {AddCurrency} from "./AddCurrency";
import DeleteDialog from "../Utils/Dialogs/DeleteDialog";
import FilterResults from "react-filter-search";

const headData = [
    {id: 'count', numeric: false, disablePadding: false, label: 'Sl'},
    {id: 'currency_name', numeric: false, disablePadding: false, label: 'Currency'},
    {id: 'currency_symbol', numeric: false, disablePadding: false, label: 'Currency Symbol'},
    {id: 'is_base_currency', numeric: false, disablePadding: false, label: 'Is Base Currency'},
    {id: 'action', numeric: false, disablePadding: false, label: 'Action'}
];


const SetupCurrencies = ({actions, snackbars}) => {
    const [searchValue, setSearchValue] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [data, setData] = useState({currencies:[], loading:'idle'});
    const [debouncedInputValue] = useDebounce(searchValue, 500)
    const [openDialog, setOpenDialog] = useState(false)
    const [currencyDetails, setCurrencyDetails] = useState({})
    const [openDeactivate,setOpenDeactivate] =  React.useState(false)

    const isResolved = isSubmitted === 'resolved'

    const {loading, currencies} = data

    const handleDeactivate = () =>{
        setIsSubmitted('pending')
        const url = currencyDetails.status ? 'deactivate' : 'activate'
        axios.get(`${config.smsUrl}/currency/${url}/${currencyDetails.id}`)
            .then(() => {
                actions.snackbarActions.snackSuccess(`Currency ${currencyDetails.status ? 'deactivated' : 'activated'}  successfully`);
                setIsSubmitted('resolved');
                setOpenDeactivate(false);
            })
            .catch(err => {
                errorMessages(err, null, actions);
                setIsSubmitted('rejected');
            });
    }

    useEffect(() => {
        setData({...data, loading: 'pending'})
        axios.get(`${config.smsUrl}/currency/`).then(res => {
            const data = res.data;
            const dt = !data ? [] : data;
            const arr = dt.map(item=>({
                ...item,
                is_base_currency: item['is_base_currency'] ? 'Yes' : 'No',
                action : (
                    <button type="button" onClick={()=>{
                        setCurrencyDetails(item)
                        setOpenDeactivate(true)
                    }} className={`btn btn-sm small-btn ${item.status ? 'sms-gray-btn' : 'sms-info-btn '} `}>{item.status ? 'Deactivate':'Activate'}</button>
                )
            }))
            setData({...data, loading: 'success', currencies: arr})
        }).catch((err) => {
            setData({...data, loading: 'error'})
            logoutErrorMessage(err, null, actions);
        })
    }, [isResolved]);



    const handleSearch = (event) => {
        setSearchValue(event.target.value)
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };



    const {openBar, type, message} =snackbars;

    const isLoading = loading === 'pending'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'


    const pagination = (
        <CustomTablePagination
            colSpan={3}
            page={page}
            rowsPerPage={rowsPerPage}
            data={currencies}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
    );

    const search_bar = (
        <EnhancedSearchTableToolbar
            term={searchValue} handler={handleSearch}
        />
    )
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, currencies.length - page * rowsPerPage);

    return (
        <div data-testid={`setup-currencies`}>
            <PageTitle title="Setup Currencies"/>
            <SubHeader title="Settings" subTitle="Setup currencies">
                <FontAwesomeIcon icon={faCog}/>
            </SubHeader>
            <AddCurrency {...{openDialog, setOpenDialog,isSubmitted, setIsSubmitted, actions,currencyDetails}}/>
            <DeleteDialog openDialog={openDeactivate} handleClose={()=>{
                setOpenDeactivate(false)
            }} title={`${currencyDetails.status ? 'Deactivate' : 'Activate'} Currency`}
                          message={`${currencyDetails.status ? 'deactivate' : 'activate'}`} text='currency'>
                <button type='button' onClick={handleDeactivate}
                        className={`btn btn-sm small-btn ${currencyDetails.status ? 'sms-gray-btn' : 'sms-info-btn '} `}>
                    {currencyDetails.status ? 'Deactivate' : 'Activate'}</button>
                <button type='button' onClick={()=>setOpenDeactivate(false)}
                        className='btn btn-sm small-btn sms-btn-dismiss'>Cancel</button>
            </DeleteDialog>
            <Container>
                <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>
                <RightAlignedContainer>
                    <button type='button' onClick={()=>{
                        setOpenDialog(true)
                    }} className='btn btn-sm sms-info-btn small-btn mb-2'>Add Currency</button>
                </RightAlignedContainer>
                <MuiPaper>

                    {search_bar}
                    <StickyHeadTable {...{width:'100%',data:currencies, columns:headData.filter(item=>Boolean(item)), pagination}}>
                        <FilterResults value={searchValue} data={currencies}
                                       renderResults={results => (
                            <React.Fragment>
                                {isLoading ? <TableRow><TableCell align='center' {...{colSpan:headData.length}}><LoadingGif/></TableCell></TableRow>:null}
                                {isSuccess ? results.length > 0 ?
                                    <>
                                        {results.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                            const count = (page * rowsPerPage) + index + 1;
                                            return (
                                                <TableRow  tabIndex={-1} key={index}>
                                                    {headData.map(col=>(
                                                        col.id === 'count' ? <CustomTableCell>{count}</CustomTableCell>:
                                                            <CustomTableCell>{row[col.id]}</CustomTableCell>
                                                    ))}
                                                </TableRow>
                                            );
                                        })}
                                        {emptyRows > 0 && (
                                            <TableRow style={{ height: 20 * emptyRows }}>
                                                <TableCell colSpan={headData.length} />
                                            </TableRow>
                                        )}
                                    </>:<TableRow><TableCell align='center'  {...{colSpan:headData.length}}>No records found</TableCell></TableRow>:null}
                                {isError ? <TableRow><TableCell align='center'  {...{colSpan:headData.length}}>The server did not return a valid response</TableCell></TableRow>:null}
                            </React.Fragment>
                        )}/>
                    </StickyHeadTable>
                </MuiPaper>
            </Container>

        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(SetupCurrencies);
