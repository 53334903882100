import React from "react";
import {BorderlessTextInput, ReferralTextArea, ReferralTextField, ReferralTextInput} from "./MainReferralNote";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {bindActionCreators} from "redux";
import {connect} from "react-redux"
import * as snackbarActions from "../../../actions/snackbarActions";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import * as departmentActions from "../../../actions/DoctorsActions/departmentActions";
import Label from "../../Utils/FormInputs/Label";
import {CustomSelect} from "../../Utils/FormInputs/SelectInput";
import PrintDialog from "../../Utils/Dialogs/PrintDialog";
import {useInternalReferral} from "./hooks/useInternalReferral";
import TextField from "../../Utils/FormInputs/TextField";
import Textarea, {ResizableTextarea} from "../../Utils/FormInputs/Textarea";

const InternalReferralNote = (props) => {

    const {patient_name,  patient_number, visit_id} = props;
    const {
        state, submitted, isSubmitted, from_department, to_department,
        openPrint, handleChangeFromDepartment, handleChangeToDepartment, handleChange,
        handleSubmit, handleCloseReferral, handleClick,
        handleReset, handleCloseSnackbar
    } = useInternalReferral(props, 'internal', {patient_number, visit_id})
    const {openBar, type, message} = props.snackbars;
    const {departments} = props.departments;
    const departs = !departments ? [] : departments;
    const {referral_date, visit_date, diagnosis, reason, notes} = state;
    return (
        <div className='p-3'>
            <form onSubmit={handleSubmit} autoComplete="off">
                <MainSnackbar handleCloseBar={handleCloseSnackbar} message={message} open={openBar} variant={type}/>
                <PrintDialog message="View Referral Note" openDialog={openPrint} handleClose={handleCloseReferral}
                             text="Would you like to view and print this referral note" handleClick={handleClick}
                             handleCancel={handleCloseReferral}/>
                <div className="row">
                    <div className="col-md-6 mt-4  ">
                        <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                            <Label name="Date of Referral" />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                        <TextField submitted={submitted} value={referral_date}
                                           name="referral_date"  type="date"
                                           disabled={true}/>
                        {/*<ReferralTextField submitted={submitted} value={referral_number} onChange={handleChange}*/}
                        {/*                   name="referral_number" type="text" labelName="Referral Number"*/}
                        {/*                   dataTest="referral_number"/>*/}
                        </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                                <Label name="FROM:Department" />
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                                <CustomSelect value={from_department} onChange={handleChangeFromDepartment}
                                              options={departs.map(department => ({
                                                  value: department.department_id,
                                                  label: department.department_name
                                              }))} dataTest="from_department"/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                                <Label name="TO:Department" />
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                                <CustomSelect value={to_department} onChange={handleChangeToDepartment}
                                              options={departs.map(department => ({
                                                  value: department.department_id,
                                                  label: department.department_name
                                              }))} dataTest="to_department"/>
                            </Col>
                        </Form.Group>
                        <p className="referral-paragraph"><strong>REFERENCE:</strong></p>
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                                <Label name="Patient Number" />
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                                <BorderlessTextInput value={patient_number}  name="patient_number" type="text"
                                                     disabled={true}/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                                <Label name="Patient Name" />
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                                <BorderlessTextInput value={patient_name}
                                                      type="text"
                                                      disabled={true}/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                                <Label name="Date of first visit" />
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                                <BorderlessTextInput onChange={handleChange} value={visit_date}  name="visit_date" type="date"/>
                            </Col>
                        </Form.Group>
                    </div>
                    <div className="col-md-6 mt-3">
                        <Form.Group as={Col}>
                            <Label name="Reason"/>
                            <ResizableTextarea value={reason} onChange={handleChange} name="reason" />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Label name="Treatment Given"/>
                            <ResizableTextarea value={notes} onChange={handleChange} name="notes" />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Label name="Diagnosis"/>
                            <ResizableTextarea value={diagnosis} onChange={handleChange} name="diagnosis"/>
                        </Form.Group>
                    </div>
                </div>
                <div className="text-right mr-5">
                    <button type="button" className="btn btn-sm sms-grey-btn px-4 mr-2" onClick={handleReset}>Reset
                    </button>
                    <button type="submit" className="btn btn-sm sms-btn px-4"
                            disabled={!!isSubmitted}>{isSubmitted ? "Saving..." : "Save"}</button>
                </div>
            </form>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbar,
        departments: state.departments
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch),
            departmentActions: bindActionCreators(departmentActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InternalReferralNote);
