/** @jsxImportSource @emotion/react */
import {jsx, css} from '@emotion/react'
import React from 'react'
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCoins} from "@fortawesome/free-solid-svg-icons/faCoins";
import PageTitle from "../../Utils/smsTitle";
import { Button, MiniCenteredDiv, RightAlignedContainer } from '../../Utils/styledComponents'
import { PrintTemplate} from '../../Utils/Templates/PrintTemplate'
import { usePrint } from '../../Utils/Templates/usePrint';
import Card from '@material-ui/core/Card'
import { formatDate } from '../../Utils/ConvertDate';
import {connect} from "react-redux";
import * as snackbarActions from "../../../actions/snackbarActions"
import {bindActionCreators} from "redux";
import { useInPatientBillingReceipt } from './hooks/useInPatientBillingReceipt';
import { BackButton } from "../../Utils/Buttons/DataExportationButton";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {FooterCell, HeadCell, InpatientBillRow, TotalCell} from "./InpatientBillRow";
import TableBody from "@material-ui/core/TableBody";
import { history } from '../../../Helpers/history';
import {CurrencyToggle, FxRate} from "../../Utils/CurrencyToggle";
import {useVersionsContext} from "../../../Context/versions-context";


const textWeight = css`
    font-weight:600;
`


function InpatientBillingReceipt(props) {
    const {state,  patient, items,exchangeRate, currencies,selectedCurrency,
        currency, handleChangeExchangeRate, handleChangeCurrency,currencyExchangeRate} = useInPatientBillingReceipt(props)

    const isBaseCurrency = currency && currency.split('-')[1] === 'true'
    const currency_symbol = currency && currency.split('-')[0]

    const headData = [{key:'name',value:'Item'}, {key:'quantity',value:'Quantity'},
        {key: isBaseCurrency ? 'rate':'currency_rate',value:`Rate(${currency_symbol})`},
        {key: isBaseCurrency ? 'amount':'currency_total',value:`Amount(${currency_symbol})`}]

    const {total_bill, total_advance, due_amount, currency_total_bill,
        currency_paid_amount, currency_due_amount} = state;
    const {
        admission_id, patient_number, patient_name, admission_date,
        address, phone_no
    } = patient;

    const handleOnAfterPrint = () => {
        history.push('/patientbillinglist');
    }

    const {subscription} = useVersionsContext()
    const version = subscription?.version
    const isPremium = version === 'Premium'

    const {componentRef, handlePrint,  fontSize}  = usePrint('Current visit Information', handleOnAfterPrint);
    const patientDetails = (
        <tbody>
        <tr>
            <td><span>Admission Date: </span> <span css={[textWeight]}>{formatDate(admission_date)}</span> </td>
            <td><span>Patient Name: </span> <span css={[textWeight]}>{patient_name}</span> </td>

        </tr>
        <tr>
            <td><span>Patient Number: </span> <span css={[textWeight]}>{patient_number}</span></td>
            <td><span>Contact: </span> <span css={[textWeight]}>{phone_no}</span></td>
        </tr>
        <tr>
            <td><span>Admission No: </span> <span css={[textWeight]}>{admission_id}</span></td>
            <td><span>Address:</span> <span css={[textWeight]}>{address}</span></td>
        </tr>
        </tbody>
    )
    const itemDetails = (
        <TableContainer >
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        {headData.map((item, index)=>(<HeadCell style={{width: index === 0 ? '500px':'300px'}} key={item.key}>{item.value}</HeadCell>))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((row) => (
                        <InpatientBillRow key={row.id} row={row} headData={headData}/>
                    ))}
                    <TableRow>

                        <FooterCell></FooterCell>
                        <FooterCell></FooterCell>
                        <FooterCell >Total Bill({currency_symbol})</FooterCell>
                        <FooterCell>{isBaseCurrency ?  total_bill.toFixed(3) : currency_total_bill.toFixed(3)}</FooterCell>
                    </TableRow>

                    <TableRow>

                        <TotalCell></TotalCell>
                        <TotalCell></TotalCell>
                        <TotalCell >Amount Received({currency_symbol})</TotalCell>
                        <TotalCell>{isBaseCurrency ? total_advance.toFixed(3) :  currency_paid_amount.toFixed(3)}</TotalCell>
                    </TableRow>

                    <TableRow>

                        <TotalCell></TotalCell>
                        <TotalCell></TotalCell>
                        <TotalCell>Balance({currency_symbol})</TotalCell>
                        <TotalCell>{isBaseCurrency ?  due_amount.toFixed(2): currency_due_amount.toFixed(2)}</TotalCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )

      return (
          <div>
        <PageTitle title="Billing Receipt"/>
        <SubHeader title="Bill Details" subTitle="Bill Details">
            <FontAwesomeIcon icon={faCoins}/>
        </SubHeader>
        <MiniCenteredDiv>
            <BackButton to='/patientbillinglist' text='Billing List' data-testid="inpatient-billing-receipt-back-button"/>
            <RightAlignedContainer>
              <Button variant='orange' onClick={handlePrint}>Print</Button>
            </RightAlignedContainer>
            {/*<Card className='mt-2 p-3'>*/}
            {/*    <div ref={componentRef} style={{padding:10}}>*/}
            {/*        <style>*/}
            {/*            /!* {getPageMargins()} *!/*/}
            {/*            {fontSize()}*/}
            {/*        </style>*/}
            {/*        <PrintTemplate title='RECEIPT' {...{patientDetails,itemDetails}}/>*/}
            {/*    </div>*/}
            {/*</Card>*/}
            <Card className='mt-2'>
                {isPremium ? <div style={{padding:'8px 10px', borderBottom:'1px solid rgba(224, 224, 224, 1)'}}>
                    <RightAlignedContainer>
                        <CurrencyToggle options={currencies} value={currency} handleChangeCurrency={handleChangeCurrency} />
                        { !isBaseCurrency ? <FxRate currency={selectedCurrency?.currency_symbol} value={currencyExchangeRate}  handleChangeRate={handleChangeExchangeRate} nonBaseCurrency={currency_symbol}/>: null}
                    </RightAlignedContainer>
                </div>: null}
                <div ref={componentRef} style={{padding:10}}>
                    <style>
                        {fontSize()}
                    </style>
                    <PrintTemplate title='RECEIPT' {...{patientDetails,itemDetails}}/>
                </div>
            </Card>
        </MiniCenteredDiv>
        </div>
      )
}

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}


export default connect(mapStateToProps, mapDispatchToProps)(InpatientBillingReceipt);