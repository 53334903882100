import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {StickyHeadTable, CustomTableCell, MuiPaper} from '../../Utils/Tables/StickyHeaderTable';
import { LoadingGif } from '../../Utils/Loader';
import {dateConvert, formatDate, formatDateTime, formatLocalTime} from '../../Utils/ConvertDate';
import axios from "axios";
import {config} from "../../../Helpers/env";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {exportPDF} from "../../Utils/downloadPDF";
import {formatAmount} from "../../Utils/formatNumbers";
import {RightAlignedContainer} from "../../Utils/styledComponents";
import {AccountingDateFilter} from "../../Utils/AccountingUtils/AccountingDateFilter";
import { CustomTablePagination } from '../../Utils/Tables/CustomTable';
import { useExcelReports, usePdfDownload } from '../../Utils/ReusableComponents/useExcelExport';
import { DataExportButton } from '../../Utils/Buttons/DataExportationButton';

const style = {
    border: {
        borderTop: '1px solid #606060',
        borderBottom: '1px solid #606060'
    },
    image: {
        width: 130,
        border: '1px solid #e4e4e4',
        borderRadius: '60px',
        background: '#e4e4e4'
    },
    p: {
        margin: 0
    },
    parent: {
        textAlign: 'center',
        marginTop: '5em'
    }
}


const useStyles = makeStyles((theme) => ({
    margin: {
        margin: 0,
    },
    inputBase: {
        fontSize: '0.9em',
        padding: '2px'
    }
}));

export const NoTransaction = ({text}) => {
    return (
        <div style={style.parent}>
            <img src="/images/transaction.png" alt='transaction' style={style.image}/>
            <p className="text-center" style={style.p}>{`There are no ${text} available`}</p>
        </div>
    )
}

const headData = [
    { id: 'date', label: 'Date',minWidth: 200 },
    { id: 'credit', label: 'Credit',minWidth: 200 },
    { id: 'debit', label: 'Debit ',minWidth: 200 },
    { id: 'balance', label: 'Balance',minWidth: 170},
    { id: 'memo', label: 'Memo',minWidth: 170},
    {id: 'journal_id',label: 'Journal ID',minWidth: 170},
  ]

export const ReusableJournalTable = ({actions,specificItem, isResolved}) => {
    const {id, head_name} = specificItem
    const current_date = new Date()
    const [journal, setJournal] = useState([]);
    const [loading, setLoading] = useState('idle');
    const [running_balance, setBalance] = useState(0)
    const [state, setState] = React.useState({from_date:dateConvert(), to_date:dateConvert()})
    const [submitted, setSubmitted] = React.useState(false);
    const [isSubmitted, setIsSubmitted] = React.useState('idle');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [activePage, setActivePage] = React.useState(1);
    const [total_count, setTotalCount] = useState(0)

    const {from_date, to_date} =state;

    useEffect(()=>{

        if (id){
            const formData = new FormData();
            formData.append('ledger', id);
            formData.append('start_date', from_date);
            formData.append('end_date', to_date);

            setLoading('pending');
            axios.post(`${config.smsUrl}/accounting/ledger_details`,null, {params:{"ledger": id, "start_date": from_date, "end_date":to_date, page: activePage }}).then(res => {
                const data = res.data;
                const dt = data[0] ? data[0] : {};
                const arr = dt.details ? dt.details : [];
                const balance = dt.openingbalance ? dt.openingbalance : 0
                setTotalCount(dt.total_count)
                

                setJournal(arr)
                setBalance(balance)
                setLoading('success')
            }).catch(err => {
                errorMessages(err, null, actions)
                setLoading('error')
            })
        }
    },[id,isResolved, activePage])

    const handleChange = (event) =>{
        const {value, name} = event.target;
        setState({...state,[name]:value})
    }

    const handleSubmit = (event) =>{
        event.preventDefault()
        setSubmitted(true)
        const formData = new FormData();
        formData.append('ledger', id);
        formData.append('start_date', from_date);
        formData.append('end_date', to_date);
        setIsSubmitted('pending')
        setLoading('pending')
        if(from_date && to_date && to_date >= from_date){
            setIsSubmitted('pending')
            setLoading('pending')
            axios.post(`${config.smsUrl}/accounting/ledger_details`, null, {params:{"ledger": id, "start_date": from_date, "end_date":to_date, page: activePage}}).then(response=>{
                const data = response.data ? response.data:{};
                const dt = data[0] ? data[0] : {};
                const arr = dt.details ? dt.details : [];
                const balance = dt.openingbalance ? dt.openingbalance : 0
                setTotalCount(dt.total_count)
                
                setJournal(arr)
                setBalance(balance)
                setLoading('success')
                setIsSubmitted('resolved')
            }).catch(err=>{
                errorMessages(err, null,actions)
                setLoading('error')
                setIsSubmitted('rejected')
            })
        }

    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setActivePage(newPage+1);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        setActivePage(1);
    };

    const pagination = (
        <CustomTablePagination
            colSpan={3}
            activePage={activePage}
            page={page}
            rowsPerPage={rowsPerPage}
            count={total_count}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            isTotalCount
        />
    );
    // const downloadPDF = () => {
    //     exportPDF(`${head_name} Journal(${formatLocalTime(current_date)})`, [['Date', "Voucher No.", "Voucher Type", "Memo", "Amount", "Transaction Type"]],
    //         journal.filter(item=>Boolean(item)).map((item) => [formatDate(item.date), item.voucher_no, item.voucher_type, item.details, item.entry_amount,item.entry_category]),
    //         `${head_name} Journal(${formatLocalTime(current_date)}).pdf`,[["","","","Running Balance",  running_balance,""]]);
    // }

    const fileName = `Journals`

    const excelObj = {file_name:fileName,params:{ start_date:from_date, end_date:to_date, ledger: id}, url:`/accounting/ledger_details/excel`}

    const pdfObj = {file_name:fileName,params:{ start_date:from_date, end_date:to_date, ledger: id}, url:`accounting/ledger_details/pdf`}


    const {exportExcel, isLoading:isLoadingExcel} = useExcelReports(actions,excelObj)
    const {exportPdf, isLoading:isLoadingPdf} = usePdfDownload(actions,pdfObj)

    const isLoading = loading === 'pending';
    const isSuccess = loading === 'success';
    const isError = loading === 'error';

    const isPending = isSubmitted === 'pending'

    const data = journal ? journal : []
    const emptyRows = 10 - Math.min(10, data.length);
    return (
        <React.Fragment>
            <div className="sub-journal-bar">
                <RightAlignedContainer>
                    <h5>Opening Balance : <strong>{running_balance}</strong></h5>
                </RightAlignedContainer>
            </div>
            <div className="pt-3">
                <div className='mx-3'>
                    <AccountingDateFilter {...{from_date,to_date, handleChange, submitted,handleSubmit,isPending}}/>
                </div>
                
                <RightAlignedContainer>
                <DataExportButton isLoading={isLoadingExcel || isLoadingPdf} downLoadPdf={exportPdf}
                                      exportCsv={exportExcel}/>
                </RightAlignedContainer>
                <MuiPaper>
                    <StickyHeadTable {...{data, columns:headData.filter(item=>Boolean(item)), width:'100%', pagination}}>
                        {isLoading ? <TableRow><TableCell align='center' colSpan={headData.length}><LoadingGif/></TableCell></TableRow>:null}
                        {isSuccess ? data.length > 0 ?
                            <>
                                {data.slice(0, 50).map((row, index) => {
                                    return (
                                        <TableRow  tabIndex={-1} key={index}>
                                            <CustomTableCell>{formatDateTime(row.date)}</CustomTableCell>
                                            <CustomTableCell >{row.credit}</CustomTableCell>
                                            <CustomTableCell>{row.debit}</CustomTableCell>
                                            <CustomTableCell>{row.balance}</CustomTableCell>
                                            <CustomTableCell>{row.details}</CustomTableCell>
                                            <CustomTableCell>{row.journal}</CustomTableCell>
                                        </TableRow>
                                    );
                                })}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 43 * emptyRows }}>
                                        <TableCell colSpan={headData.length} />
                                    </TableRow>
                                )}
                            </>:<TableRow><TableCell align='center'  colSpan={headData.length}>No records found</TableCell></TableRow>:null}
                        {isError ? <TableRow><TableCell align='center'  colSpan={headData.length}>The server did not return a valid response</TableCell></TableRow>:null}
                    </StickyHeadTable>
                </MuiPaper>
              
            </div>
        </React.Fragment>

    )

}



