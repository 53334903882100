import React from "react";
import {PatientDetails} from "./SelectService";
import {usePatientInfo} from "./hooks/usePatientInfo";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../../actions/snackbarActions";
import {connect} from "react-redux";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faNotesMedical} from "@fortawesome/free-solid-svg-icons/faNotesMedical";
import {MiniHeader} from "../../Utils/Menu/MiniSubHeader";
import ReusableTabs, {TabPanel} from "../../Utils/Dialogs/ReusableTabs";
import {useNewServiceRequest} from "./hooks/useNewServiceRequest";
import {useSelfRequestPrescription} from "./hooks/usePrescriptionSelfRequest";
// import {Container} from "reactstrap";
import {RightAlignedContainer,Container} from "../../Utils/styledComponents";







const NewServicePrescriptionRequest = ({actions,match:{params}, snackbar}) => {
    const  {patient_number, visit_type, visit_id} = params


    const tabs = ['Service Request', 'Prescription Request']

    const [tabValue, setTabValue] = React.useState(0);


    const handleChangeTabValue = (event,val) => {
        setTabValue(val)
    }

    const handleCloseSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const {patient} = usePatientInfo(actions, patient_number)
    const {first_name, last_name, age, gender} = patient;

    const propsObj = {visit_id,actions,patient_number, visit_type}

    const {renderPrescription} = useSelfRequestPrescription(propsObj)
    const {renderService} = useNewServiceRequest(propsObj)
    


    const {openBar, type, message} = snackbar;
    return (
        <div>
            <PageTitle title={tabs[tabValue]}/>
            <SubHeader title={tabs[tabValue]} subTitle={tabs[tabValue]}>
                <FontAwesomeIcon icon={faNotesMedical}/>
            </SubHeader>
            <MainSnackbar variant={type} message={message} open={openBar} handleCloseBar={handleCloseSnackbar}/>
            <Container>
                <div className='mb-3'>
                    <PatientDetails {...{patient_number, visit_id, patient_name:`${first_name ? first_name : ''} ${ last_name ? last_name : ''}`, age, gender}}/>
                </div>

                <MiniHeader title={tabs[tabValue]}/>
                <ReusableTabs value={tabValue} tabHeading={tabs} handleChange={handleChangeTabValue}>
                    <TabPanel value={tabValue} index={0}>
                        {renderService}
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        {renderPrescription}
                    </TabPanel>
                </ReusableTabs>
            </Container>


        </div>
    )
};

function mapStateToProps(state) {
    const {snackbar} = state;
    return {snackbar}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewServicePrescriptionRequest);
