import { useState} from 'react';
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import {titleCase} from "../../../Users/addUser";
import {v4 as uuidv4} from "uuid";

export const useDoctorRadiologyRequest = (actions, match, _token ) => {
    const {visit_id,patient_type, activity_id} = match
    const initialState = [{
        hospital_scan_id: '', scan_name: '', scan_type: '', indication: '', service_id: '',
        qty: 1, showScan: false, purpose: '', isError: false, cost: 0,
        currency_id:'', currency_symbol:'',  uuid: uuidv4(),
    }]
    const [submitted, setSubmitted] = useState(false);
    const [scanTests, setScanTests] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [scans, setScans] = useState(initialState);
    const [openDialog, setOpenDialog] = useState(false)
    const [openConfirmation, setOpenConfirmation] = useState(false)
    const [cashItems, setCashItems] = useState([])
    const [scan_id, setScanId] = useState('')
    const [isBlocking, setIsBlocking] = useState(false)
    const [isBlockDialog, setIsBlockDialog] = useState(false)

    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;

    const handleResetForm = () =>{
        setIsBlockDialog(false)
        setIsBlocking(false)
        setOpenDialog(false);
        setSubmitted(false);
        const arr = scans.map(item=>({
            ...item,   hospital_scan_id: '', scan_name: '', scan_type: '', indication: '', service_id: '',
            qty: 1, showScan: false, purpose: '', isError: false, cost: 0,
            currency_id:'', currency_symbol:'',  uuid: uuidv4(),

        }))
        setScans(arr)
    }


    const handleOpenDialog = () =>{
        setOpenDialog(true)
    }

    const handleCloseDialog = () =>{
        if(isBlocking){
            setIsBlockDialog(true)
        }else{
            handleResetForm()
        }
    }


    const getScanTests = (eventTxt, idx) => {
        const arr = scans.map((item, index) => {
            if (index=== idx) {
                const formData = new FormData();
                formData.append('hospital_scan_name', titleCase(eventTxt));
                if (eventTxt !== '') {
                    axios.post(`${config.smsUrl}/cdoctor/autocompletesearch_hospt_scans`, formData).then(res => {
                        const resp = res.data;
                        const data = !resp ? [] : resp;
                        if (data.length > 0) {
                            const arr = data.map(item=>({...item, value:item.hospital_scan_id, label:item.hospital_scan_name}))
                            setScanTests(arr);
                        } else {
                            setScanTests([{hospital_scan_id: '', hospital_scan_name: 'No Scan tests found'}])
                        }
                    }).catch(error => {
                        logoutErrorMessage(error, null, actions)
                    });
                    return {...item, scan_name: eventTxt, showScan: true, isError: false}
                }
                return {...item, scan_name: eventTxt, showScan: false, isError: false}
            }
            return item
        })
        setScans(arr)
        setIsBlocking(true)
    };

    const retrieveScanDetails = (event, idx) => {
        let arr = scans.map((item, index)=>{
            if(index === idx){
                return {...item, scan_name:event.innerText, hospital_scan_id:event.value,
                    isError:false, showScan:false}
            }
            return item
        })
        const formData = new FormData();
        formData.append('hospital_scan_id', event.value);
        if (event.value) {
            axios.post(`${config.smsUrl}/cdoctor/getscandetails_by_scan_id`, formData).then(res => {
                const data = res.data;
                const details = !data ? {} : data;
                axios.post(`${config.smsUrl}/cbilling/item_cost`, {visit_id: +visit_id, item_id:data.service_id, item_type:'service'}).then(res => {
                    const resObj = res.data;
                    const dataObj = !resObj.data ? {} : resObj.data;
                    arr = arr.map((item, index)=>{
                        if(index === idx){
                            return {...item, scan_type:details.scan_type_name, service_id: details.service_id,cost:  dataObj.cost ?? 0,
                                currency: dataObj.currency_id, currency_symbol: dataObj.currency, ...dataObj }
                        }
                        return item
                    })
                    setScans(arr);
                    if(dataObj.is_provider === 1 && dataObj.is_provided === 0){
                        setScanId(details.hospital_scan_id)
                        setOpenConfirmation(true)
                    }
                }).catch(error => {
                    errorMessages(error, null, actions)
                });
            }).catch(error => {
                errorMessages(error, null, actions)
            });
        }
        setIsBlocking(true)
    }

    const addItemsToReceipt = () =>{
        const arr = scans.filter(item=>item.hospital_scan_id === scan_id)
        setCashItems(arr)
        setOpenConfirmation(false)
    }

    const handleCloseConfirmation = () =>{
        const arr = scans.filter(item=>item.hospital_scan_id !== scan_id)
        setScans(arr)
        setOpenConfirmation(false)
    }

    const handleCancelConfirmation = () =>{
        setOpenConfirmation(false)
    }

    const handleChangePurpose = (e, index) => {
        const {value} = e.target
        const arr = scans.map((item, idx) => {
            if (idx === index) {
                return {...item, purpose: value, isEdited: !!item.scan_requests_detail_id}
            }
            return item
        })
        setScans(arr)
        setIsBlocking(true)
    }

    const handleAddRow = () => {
        setScans([...scans, ...initialState])
        setIsSubmitted('idle')
    };

    const removeRow = (item_uuid) => {
        const arr = scans.filter((e) => e.uuid !== item_uuid)
        setScans(arr);
    };

    const handleSubmitScanRequest = (event) => {
        event.preventDefault();
        const arr = scans.every(item => item.hospital_scan_id)
        setSubmitted(true);
        const activityObj = patient_type === '1' ? { scan_state:1,
            activity_id: +activity_id} : { scan_state:0,
            activity_id: 0}
        const params = {
            visit_id:+visit_id, user_roles_id,
            ...activityObj,
            result_type: 3, bill_type: 2, bill_details: scans.filter(scan => scan.hospital_scan_id && scan.service_id &&
                !scan.scan_requests_detail_id).map(item => ({
                hospital_scan_id: item.hospital_scan_id,
                service_id: item.service_id,
                purpose: item.purpose,
                quantity: 1, rate: item.cost,
                is_provided: item.is_provided,
                is_provider: item.is_provider,
                currency_id:item.currency_id
            }))
        }
        setIsBlocking(false)
        if (arr) {
            setIsSubmitted('pending')
            axios.post(`${config.smsUrl}/cdoctor/save_scan_request`, {...params}).then(() => {
                actions.snackbarActions.snackSuccess('Requested submitted successfully');
                setIsSubmitted('resolved');
                setSubmitted(false);
                setScans(initialState)
                setOpenDialog(false)
            }).catch(err => {
                errorMessages(err, null, actions)
                setIsSubmitted('rejected');
            })
        }
    };

    const isPending = isSubmitted === 'pending'
    const isResolved = isSubmitted === 'resolved'
    const isRejected = isSubmitted === 'rejected'

    const addProps = {
        submitted, scanTests, getScanTests, retrieveScanDetails,openDialog,
        handleAddRow, removeRow,  handleSubmitScanRequest,  scans, handleChangePurpose,
        handleOpenDialog, handleCloseDialog, isPending, isResolved, isRejected,
        openConfirmation, handleCloseConfirmation, addItemsToReceipt,handleCancelConfirmation,
        isBlockDialog, isBlocking, setIsBlockDialog,  handleResetForm
    }


    return {addProps}
}