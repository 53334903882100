import React from "react";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserFriends} from "@fortawesome/free-solid-svg-icons/faUserFriends";
import CustomTable from "../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import {getComparator, stableSort} from "../Utils/Tables/sortingFunctions";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {Link} from "react-router-dom";
import * as employeeActions from "../../actions/employeeActions";
import * as snackbarActions from "../../actions/snackbarActions"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import PageTitle from "../Utils/smsTitle";
import DeleteDialog from "../Utils/Dialogs/DeleteDialog";
import {useEmployeeList} from "./CustomHooks/useEmployeeList";
import {faEye} from "@fortawesome/free-solid-svg-icons/faEye";
import {faEdit} from "@fortawesome/free-solid-svg-icons/faEdit";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import CustomDialog from "../Utils/Dialogs/CustomDialog";
import EmployeeForm from "./EmployeeForm";
import {RightAlignedContainer} from "../Utils/styledComponents";
import {CsvDialog} from "../Utils/Dialogs/CsvDialog";
import { Container } from "../DoctorsModule/doctorStyles";


const headData = [{id: 'sl', numeric: false, disablePadding: false, label: 'SL'},
    {id: 'employee', numeric: false, disablePadding: false, label: 'Employee Name'},
    {id: 'department', numeric: false, disablePadding: false, label: 'Department'},
    {id: 'email', numeric: false, disablePadding: false, label: 'Email'},
    {id: 'phone', numeric: false, disablePadding: false, label: 'Phone NO'},
    {id: 'address', numeric: false, disablePadding: false, label: 'Address'},
    {id: 'gender', numeric: false, disablePadding: false, label: 'Gender'},
    {id: 'actions', numeric: false, disablePadding: true, label: 'Action'},
];

export const EmployeeList = (props) => {
    const {openBar, type, message} = props.snackbars;
    const {searchValue, order, orderBy, page, rowsPerPage, openDelete,
        employeeList, loading, activePage, employee_count, handleSearch,
        handleRequestSort, handleChangeNewPage, handleOpenDelete,uploadProps,
        handleCloseDelete, handleDelete, closeSnackBar, employee, addEmployeeProps,
        closeSnackbar
    } = useEmployeeList(props)
    const perm = JSON.parse(sessionStorage.getItem('permission'));
    const permission = !perm ? {} : perm;
    const employee_list = !permission.employee_list ? {} : permission.employee_list;
    const employees = !employeeList ? [] : employeeList;
    const {status} = employee
    const {handleOpenCsvDialog} = uploadProps
    const {openEdit, openDialog, handleCloseDialog, handleOpenEditDialog,
    handleCloseEditDialog, handleOpenDialog} = addEmployeeProps
    return (
        <div data-testid="employee-list">
            <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>
            <PageTitle title="Employee List"/>
            <SubHeader title="HRM" subTitle="Employee List">
                <FontAwesomeIcon icon={faUserFriends}/>
            </SubHeader>
            <CsvDialog {...{...uploadProps, file:"/csv/hospital_employees.csv", title:'Upload Employees'}}/>
            <CustomDialog title="Add Employee" open={openDialog} handleClose={handleCloseDialog} maxWidth="lg" isPaperStyle  isContentOverflow={false}>
                <EmployeeForm {...addEmployeeProps}/>
            </CustomDialog>
            <CustomDialog title="Edit Employee" open={openEdit} handleClose={handleCloseEditDialog} maxWidth="lg" isPaperStyle  isContentOverflow={false}>
                <EmployeeForm {...addEmployeeProps} />
            </CustomDialog>
            <Container>
                <RightAlignedContainer>
                    <button onClick={handleOpenDialog}
                        className="btn sms-btn btn-sm mr-4"><FontAwesomeIcon icon={faPlus}/> Add Employee</button>
                    <button onClick={handleOpenCsvDialog} id="upload-employees" className="btn sms-info-btn btn-sm">Upload Employees
                    </button>
                </RightAlignedContainer>
                <div className="mui-tables mt-3">
                    <DeleteDialog message={status === 1 ?  'deactivate':'activate'} openDialog={openDelete} handleClose={handleCloseDelete}
                                        text="employee" title={`${status === 1 ? 'Deactivate':'Activate'} Employee`}>
                            <button className='btn sms-info-btn btn-sm' onClick={handleDelete}>{status === 1 ? 'Deactivate':'Activate'}</button>
                            <button className="btn sms-grey-btn btn-sm" onClick={handleCloseDelete}>No</button>    
                    </DeleteDialog>
                    <MainSnackbar variant={type} message={message} open={openBar} handleCloseBar={closeSnackBar}/>
                    <CustomTable title="Employee List" headData={headData} handler={handleSearch} term={searchValue}
                                handleRequestSort={handleRequestSort} data={employees} colSpan={5} order={order}
                                orderBy={orderBy} customPage records={10} activePage={activePage}
                                handleChangeNextPage={handleChangeNewPage} total_count={employee_count}>
                        {loading ? <TableBody><TableRow>
                            <TableCell colSpan="9" align="center"><img src="/images/smsloader.gif"
                                                                    alt="loader"/></TableCell>
                        </TableRow></TableBody> : employees.length > 0 ?
                            <TableBody>
                                {stableSort(employees, getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((item, index) => {
                                        const count = ((activePage - 1) * 10) + index + 1;
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={item.employee_id}
                                                className='mui-table-row'>
                                                <TableCell>{count}</TableCell>
                                                <TableCell>{`${item.first_name} ${item.last_name}`}</TableCell>
                                                {/*<TableCell>{item.last_name}</TableCell>*/}
                                                <TableCell>{item.department_name}</TableCell>
                                                <TableCell>{item.email}</TableCell>
                                                <TableCell>{item.phone_no}</TableCell>
                                                <TableCell>{item.address}</TableCell>
                                                <TableCell>{item.gender}</TableCell>
                                                {/*<TableCell>{item.date}</TableCell>*/}
                                                {/*<TableCell>{item.role}</TableCell>*/}
                                                <TableCell>
                                                    <Link
                                                        to={{pathname: `/employeeprofile/${item.employee_id}`}}>
                                                        <button className="btn btn-sm sms-btn mr-1 mb-1">
                                                            <FontAwesomeIcon icon={faEye} style={{fontSize: '13px'}}/>
                                                        </button>
                                                    </Link>
                                                    {employee_list.update ? 
                                                    
                                                        <button onClick={() => handleOpenEditDialog(item.employee_id)} className="btn btn-sm sms-info-btn mr-1 mb-1">
                                                            <FontAwesomeIcon icon={faEdit} style={{fontSize: '13px'}}/>
                                                        </button>
                                                    : <div/>}
                                                    {employee_list.delete ?
                                                        <button onClick={() => handleOpenDelete(item)}
                                                                className={`btn btn-sm py-1 mb-1 mr-1 ${item.status === 1 ? 'sms-grey-btn' : 'sms-info-btn'}`}>
                                                            {item.status === 1 ? 'Deactivate' : 'Activate'}
                                                        </button>
                                                        : null}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                            : <TableBody><TableRow>
                                <TableCell colSpan="9" align="center">No records found</TableCell>
                            </TableRow></TableBody>}
                    </CustomTable>
                </div>
            </Container>
        </div>
    )
};

function mapStateToProps(state) {
    return {employees: state.employees, snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            employeeActions: bindActionCreators(employeeActions, dispatch),
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeList);
