import React, {useEffect, useState} from 'react';
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import { useEditProductSetup } from './useEditProductSetUp';
import { useEntityLedgers } from './useEntityLedgers';


export const useSetupServices = (actions) => {
    const [services, setServices] = useState([]);
    const [rows, setRows] = useState([ {id:'',
        name:'',
        credit_account:'',
        debit_account:''}])
    const [isAllServices, setIsAllServices] = useState(false)
    const [debitAccount, setDebitAccount] = useState('')
    const [creditAccount, setCreditAccount] = useState('')
    const [accounts, setAccounts] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');

    const [isSetUp, setIsSetUp] = useState(0)
    

    const {editProps,openEdit, handleCloseEdit, handleOpenEdit,
        credit_ledger, editData,setCredit,setDebit, setProduct} = useEditProductSetup(actions)
    const {isResolvedEdit} = editProps
    const isLedger = isSetUp === 1
    const isEdit =  isResolvedEdit

    const {data, isLoading, isSuccess, isError,openDelete,handleCloseDelete,
        handleOpenDelete,entity_id,setData, setOpenDelete} = useEntityLedgers('service', isLedger,isEdit, actions)
        
    const serviceLedgers = data.filter(item=>item.entity_type === 'service').map(item=>({
        ...item, name:item.service_name, id:item.id
    }))


    useEffect(() => {
        axios.get(`${config.smsUrl}/accounting/chart/journals`).then(res => {
            const data = res.data;
            const dt = data ? data : {};
            const arr = dt.data ? dt.data : []
            const list = arr.map(item => ({
                value: item.id,
                label: item.head_name
            }))
            setAccounts(list)
        }).catch(err => {
            errorMessages(err, null, actions)
        })
    }, [])

    useEffect(()=>{
        const {credit_ledger:credit,debit_ledger:debit, service_name,
            credit_ledger_id,debit_ledger_id} = editData;
        if(!credit_ledger){
            return
        }
      
        setCredit({value:credit_ledger_id, label:credit})
        setDebit({value:debit_ledger_id, label:debit})
        setProduct(service_name)

    },[credit_ledger])


    useEffect(() => {
        axios.get(`${config.smsUrl}/cbilling/manage_service`).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const arr_data = dt.service_list ? dt.service_list : []
            const arr = arr_data.map(item=>({
                value: item.service_id,
                label: item.service_name
            }))
            setServices(arr)
        }).catch((err) => {
            logoutErrorMessage(err, null, actions);
        })
    }, []);


    const handleChange = (event) => {
        const {checked} = event.target;
        setIsAllServices(checked)
    }

    const handleChangeAllDebitAccount = (val) => {
        setDebitAccount(val)
    }

    const handleChangeAllCreditAccount = (val) => {
        setCreditAccount(val)
    }

    const handleAddRow = () =>{
        const obj = {id:'',
        name:'',
        credit_account:'',
        debit_account:''}
        setRows([...rows, obj])
    }

    const handleRemoveRow = (index) =>{
        const arr = rows.filter((_,ind)=>ind !== index)
        setRows(arr)
    }


    const handleChangeService = (val, index) => {
        const arr = rows.map((item, ind) => {
            if (ind === index) {
                return {...item, id: val}
            }
            return item
        })
        setRows(arr)
    }


    const handleChangeDebitAccount = (val, index) => {
        const arr = rows.map((item, ind) => {
            if (ind === index) {
                return {...item, debit_account: val}
            }
            return item
        })
        setRows(arr)
    }

    const handleChangeCreditAccount = (val, index) => {
        const arr = rows.map((item, ind) => {
            if (ind === index) {
                return {...item, credit_account: val}
            }
            return item
        })
        setRows(arr)
    }



    const handleSubmit = (event) => {
        event.preventDefault();
        setSubmitted(true);
        const data = rows.map(item=>{
            const credit  = item.credit_account ? item.credit_account : {};
            const credit_value = credit.value ? credit.value : ''
            const debit  = item.debit_account ? item.debit_account : {};
            const debit_value = debit.value ? debit.value : '';
            const service = item.id ? item.id : {};
            const service_id = service.value ? service.value : ''
            return{
            entity_id: +service_id,
            credit_ledger:+credit_value,
            debit_ledger :+debit_value,
            entity_type:'service',
            default:0
         }
        })
        const credit  = creditAccount ? creditAccount: {};
        const credit_value = credit.value ? credit.value : ''
        const debit  = debitAccount ? debitAccount : {};
        const debit_value = debit.value ? debit.value : ''

        const params = isAllServices ? {data:[{credit_ledger: +credit_value,debit_ledger: +debit_value, 
            entity_id:'', entity_type:'service', default:1}]} : {data};

        const arr = rows.every(item=>item.id && item.debit_account && item.credit_account)
        if(arr){
            setIsSubmitted('pending');
            axios.post(`${config.smsUrl}/accounting/entity_behavior/add`,
                {...params}).then(() => {
                actions.snackbarActions.snackSuccess('Entry made successfully')
                setIsSubmitted('resolved')
                const arr = rows.map(item=>({
                    ...item,id:'',name:'',credit_account:'', debit_account:'',
                }))
                setRows(arr)
                setSubmitted(false)
            }).catch(err => {
                errorMessages(err, null, actions)
                setIsSubmitted('rejected')
            })
        }

    }

    const handleDelete = () => {
        const formData = new FormData();
        formData.append('id', entity_id);
        axios.post(`${config.smsUrl}/accounting/entity_behavior/delete`, formData).then(() => {
            const arr = data.filter(item=>item.id !== entity_id)
            actions.snackbarActions.snackSuccess(`Ledger deleted successfully`);
            setOpenDelete(false)
            setData(arr)
        }).catch(err => {
            errorMessages(err, null, actions)
        })
    }

    const handleChangeSetup = (status) =>{
        setIsSetUp(status)
    }

    const closeSnackbar = () =>{
        actions.snackbarActions.hideSnackbar()
    }


    const isPending = isSubmitted === 'pending'
    const isResolved = isSubmitted === 'resolved'
    const isRejected = isSubmitted === 'rejected'


    const serviceProps = {rows, entities:services, isAllAccounts:isAllServices, debitAccount, creditAccount, 
        accounts, submitted, handleChange, handleChangeAllCreditAccount, handleChangeAllDebitAccount,
        handleChangeCreditAccount, handleChangeDebitAccount, handleSubmit, isLoading, isSuccess,
        isError, isPending, isRejected, isResolved, handleAddRow, handleRemoveRow,
        handleChangeEntity:handleChangeService}
    
    const ledgerData = {data:serviceLedgers, isLoading, isSuccess, isError,openEdit,
            handleOpenEdit, handleCloseEdit,openDelete,handleCloseDelete,handleOpenDelete,entity_id,handleDelete}

    return {serviceProps,ledgerData, closeSnackbar, isSetUp,handleChangeSetup, editProps}
}