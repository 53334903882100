import React from "react";
import CustomDialog from "../Utils/Dialogs/CustomDialog";
import Form from "react-bootstrap/Form";
import Label from "../Utils/FormInputs/Label";
import {CustomCheckInput, CustomInput} from "../Inventory/CustomInput";
import currencies from "./currencies.json"
import {CustomSelect} from "../Utils/FormInputs/SelectInput";
import axios from "axios";
import {config} from "../../Helpers/env";
import {errorMessages} from "../../Helpers/ErrorMessages";
import {ErrorMessage} from "../Utils/styledComponents";

export function AddCurrency({openDialog, setOpenDialog, isSubmitted, setIsSubmitted, actions, currencyDetails}) {
    const [submitted, setSubmitted] = React.useState(false)
    const [currency, setCurrency] = React.useState({value:'', label:'', name:''})
    const [error, setError] = React.useState(false)
    const formRef = React.useRef()

    React.useEffect(()=>{
        if (!currencyDetails?.currency_id){
            return
        }
        setCurrency({value:currencyDetails.currency_symbol, label: currencyDetails.currency_name})

    },[currencyDetails.currency_id])


    const handleSubmit = (event) =>{
        event.preventDefault()
        setSubmitted(true)
        const form = new FormData(event.currentTarget)
        const fieldValues = Object.fromEntries(form.entries())
        setError(!fieldValues?.is_base_currency)
        const ex_rate = fieldValues?.exchange_rate ?  Math.pow(Number(fieldValues?.exchange_rate), -1) : 1;
        if (currency?.value && fieldValues?.is_base_currency ){
            setIsSubmitted('pending')
            setError(false)
            axios.post(`${config.smsUrl}/currency/`, {
                currency_name: currency.name ? currency.name : '',
                currency_symbol: currency.value,
                is_base_currency: fieldValues?.is_base_currency,
                exchange_rate:ex_rate
            })
                .then(() => {
                    actions.snackbarActions.snackSuccess('Currency added successfully');
                    setIsSubmitted('resolved');
                    setSubmitted(false);
                    setCurrency({ value: '', label: '' });
                    setOpenDialog(false);
                })
                .catch(err => {
                    errorMessages(err, null, actions);
                    setIsSubmitted('rejected');
                });
        }
    }

    const currenciesList = JSON.stringify(currencies)
    const arr = JSON.parse(currenciesList)

    const isPending = isSubmitted === 'pending'
    const isResolved = isSubmitted === 'resolved'
    return(
        <CustomDialog handleClose={()=>{
            setOpenDialog(false)
        }} open={openDialog} title='Add Currency' buttons={null}>
            <form ref={formRef} autoComplete='off' onSubmit={handleSubmit}>
                <Form.Group>
                    <Label name='Currency' type/>
                    <CustomSelect submitted={submitted} options={arr.map(item=>({
                        ...item,
                        value:item.cc,
                        label:`${item.cc} ${item.name}`
                    }))} value={currency} onChange={(value)=>setCurrency(value)} id='currency'/>
                    {submitted && !currency?.value ? <ErrorMessage>Currency is required</ErrorMessage>:null}
                </Form.Group>
                <Form.Group>
                    <Label name="Exchange rate"  htmlFor="exchange_rate"/>
                    <CustomInput submitted={submitted} name='exchange_rate' type='text'/>
                </Form.Group>
                <Form.Group>
                    <CustomCheckInput type='radio' submitted={submitted} inputValue={currencyDetails.is_base_currency === true} value="true" name='is_base_currency' id="base_currency" reset={isResolved} label="Base currency"  inline/>
                    <CustomCheckInput type='radio' submitted={submitted} inputValue={currencyDetails.is_base_currency === false} value="false" name='is_base_currency' id="non_base_currency" reset={isResolved} label="None base currency"  inline/>
                    {error ? <ErrorMessage>Required</ErrorMessage>: null}
                </Form.Group>
                <button type='submit' disabled={isPending} className='btn btn-sm sms-info-btn small-btn'>{isPending ? 'Saving...':'Save'}</button>
            </form>
        </CustomDialog>
    )
}