import React from 'react';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import { formatDigits } from '../../Utils/formatNumbers';
import { history } from '../../../Helpers/history';
import TableRow from "@material-ui/core/TableRow";
import Table  from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import {useStyles,MinusSquare, PlusSquare, StyledTreeItem,  COATableCustomCell} from "./TreeViewComponent"







export default function COATable({data=[], children}) {
    const classes = useStyles();

    const openIds = data.map(item=>`${item.head_level}-${item.head_name}`)

    const tree_label = (str_type, nav, isheadLevel, padding, isParent) => {
        const bal = nav.balance ? nav.balance : 0
        if (str_type === 'final_level') {
            return (
                <TableRow>
                    <COATableCustomCell width='580' onClick={()=>history.push(`/accounting/view-transactions/${nav.id}/${nav.head_code}-${nav.head_name}`)}
                                        style={{paddingLeft:padding,  cursor:'pointer'}}>
                        <span  style={{color: '#0065ff',   display:'inline-block'}}>{nav.head_name}</span></COATableCustomCell>
                    <COATableCustomCell align='right'><strong>{bal ? formatDigits(bal) : 0}</strong></COATableCustomCell>
                </TableRow>
            )
        } else if (str_type === 'current_parent') {
            return (
                <TableRow>
                    <COATableCustomCell style={{paddingLeft:padding,fontWeight : isParent ? 'bold':'',background:isParent ?  'rgb(243 243 243)':''}}>
                        <span style={{fontWeight : isParent ? 'bold':''}}>{nav.head_name}</span>
                    </COATableCustomCell>
                    {isheadLevel ?
                        <COATableCustomCell  align='right'><strong>{bal ? formatDigits(bal) : 0}</strong></COATableCustomCell>:
                        <COATableCustomCell style={{background:isParent ?  'rgb(243 243 243)':''}} />}
                </TableRow>
            )
        } else {
            return (
                <TableRow>
                    <COATableCustomCell style={{paddingLeft:padding}} width='580'>{nav.head_name}</COATableCustomCell>
                    {isheadLevel ? <COATableCustomCell  align='right'><strong>{
                        bal ? formatDigits(bal) : 0}</strong></COATableCustomCell>: <COATableCustomCell style={{background:isParent ?  'rgb(243 243 243)':''}} />}
                </TableRow>
            )
        }
    }

    function nestedMenu(items) {
        return items.map((nav) => {
            const nav_arr = nav.children ? nav.children : []
            const head_level = nav.head_level ? nav.head_level : 0;
            const padding  = head_level === 0 ? 4 :  head_level * 8;
            const category = nav.related_category ? nav.related_category : null;
            const isParent = !category;
            const isheadLevel = head_level >= 1;
            const idxs = `${head_level}-${nav.head_name}`
            const nav_id = `${nav.id}-${nav.head_name}`
            if (nav_arr.length === 0) {
                return (
                    <>
                        {nav.is_final_level ?
                            <StyledTreeItem key={nav_id} nodeId={idxs} label={tree_label('final_level', nav, isheadLevel, padding, isParent)}/>
                            :
                            <StyledTreeItem key={nav_id} nodeId={idxs} label={tree_label('none', nav, isheadLevel, padding, isParent)}/>
                        }
                    </>
                )
            }
            return (
                <>
                    <StyledTreeItem key={nav_id} nodeId={idxs} label={tree_label('current_parent', nav, isheadLevel, padding, isParent)}>
                        {nestedMenu(nav_arr)}
                    </StyledTreeItem>

                </>
            )
        })
    }

  const table_head = (
    <TableRow>
        <COATableCustomCell><strong>Account</strong></COATableCustomCell>
        <COATableCustomCell align='right'><strong>Running Balances</strong></COATableCustomCell>
    </TableRow>
  )

  return (
      <Table className={classes.table} stickyHeader aria-label="sticky table" data-testid="coa-table">
          <TableHead>
            <TreeItem label={table_head} nodeId={-1}/>
          </TableHead>
        <TableBody>
        <TreeView
            className={classes.root}
            defaultCollapseIcon={<MinusSquare />}
            defaultExpandIcon={<PlusSquare />}
            defaultExpanded={openIds}
        >
          {nestedMenu(data)}
        </TreeView>
          {children}
        </TableBody>
      </Table>
  );
}
