import React from "react";
import PageTitle from "../Utils/smsTitle";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCog} from "@fortawesome/free-solid-svg-icons/faCog";
import Form from "react-bootstrap/Form";
import Card from "@material-ui/core/Card"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../actions/snackbarActions";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import Row from "react-bootstrap/Row";
import Label from "../Utils/FormInputs/Label";
import Col from "react-bootstrap/Col";
import {useTheme} from "@material-ui/core/styles";
import {CustomSelect} from "../Utils/FormInputs/SelectInput";
import {useAssignRole} from "./CustomHooks/useAssignRole";
import { ErrorMessage } from "../Utils/styledComponents";
import { TextField } from "@material-ui/core";


export function AssignRole({actions, snackbars}) {
    const { role_name, submitted, isSubmitted, roles,
        handleChangeRoleName, handleSubmit, handleReset,
        handleCloseSnackbar,state, employee_email,employees,handleChange,
        handleChangeEmployee, employee_id} = useAssignRole(actions)
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    const {openBar, type, message} = snackbars;
    const rols = !roles ? [] : roles;
    return (
        <div data-testid="assign-role">
            <PageTitle title="Assign User Role"/>
            <SubHeader title="Settings" subTitle="Assign role to user">
                <FontAwesomeIcon icon={faCog}/>
            </SubHeader>
            <div className="assign_role_card general-card">
                <form onSubmit={handleSubmit} autoComplete="off">
                    <Card className="p-3 mt-5 general-card">
                        <MainSnackbar variant={type} open={openBar} message={message}
                                      handleCloseBar={handleCloseSnackbar}/>
                        <h6 className={`visit-heading ${palette.type === "dark" && "dark-visit-heading"}`}> Assign Role</h6>
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                                <Label column sm={2} name="Employee" htmlFor='employee_name' type/>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={7} xl={7}>

                                <CustomSelect {...{submitted}} value={employee_id} onChange={handleChangeEmployee}
                                              options={employees.map(employee => ({
                                                  label: `${employee.first_name} ${employee.last_name}`,
                                                  value: employee.employee_id
                                              }))} id='employee_name' dataTest="employee_id"/>
                                {(submitted && !employee_id) &&
                                <ErrorMessage>Employee name is required</ErrorMessage>}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                                <Label  name="Role" htmlFor='role_name' type/>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={7} xl={7}>

                                <CustomSelect {...{submitted}} value={role_name} onChange={handleChangeRoleName} options={rols.map(role => ({
                                    value: role.role_id,
                                    label: role.role_name
                                }))} dataTest="role_name" id='role_name'/>
                                {(submitted && !role_name) && <ErrorMessage>Role name is required</ErrorMessage>}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                                <Label  name="Email" htmlFor="_user_email" type/>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                                <TextField id='_user_email' submitted={submitted}
                                           value={employee_email}
                                           name="email" type="email"  disabled={true}/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                                <Label name="Password" htmlFor='adduser-password' type/>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                                <TextField {...{submitted}} value={state.password} type="password"
                                              id='adduser_password'
                                              onChange={handleChange} name="password"
                                              className={`form__control ${palette.type === "dark" && 'form-control-dark'}`}/>
                                {(submitted && !state.password) ? <ErrorMessage>Password is required</ErrorMessage> :
                                    (submitted && state.password.length < 6) &&
                                    <ErrorMessage>Password should be at least 6 characters or more than </ErrorMessage>}
                            </Col>
                        </Form.Group>

                        <div className="text-right mr-5">
                            <button onClick={handleReset} type="button" className="btn btn-sm sms-grey-btn px-4 mr-2"
                                    id="reset-role">Reset
                            </button>
                            <button type="submit" disabled={isSubmitted === 'pending'} id='submit-role' className="btn btn-sm sms-btn px-4">
                                {isSubmitted === 'pending' ? "Saving..." : "Save"}</button>
                        </div>
                    </Card>
                </form>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {snackbars: state.snackbar}

}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignRole);
