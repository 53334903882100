import React from "react";
import Dialog from "@material-ui/core/Dialog";
import {DialogActions, DialogContent, DialogTitle} from "../SmsDialog";


const DeleteDialog = ({openDialog, handleClose,  message, text, title, testId, children}) => {
    return (
        <Dialog open={openDialog} data-testid={testId}>
            <DialogTitle onClose={handleClose}>
                <div className="text-center">
                    {title}
                </div>
            </DialogTitle>
            <DialogContent>
                <p style={{margin:0}}>{`You are about to  ${message} this ${text}, Are you sure you want to continue ?`}</p>
            </DialogContent>
            <DialogActions>
                {children}
            </DialogActions>
        </Dialog>
    )
 
};
export default DeleteDialog;
