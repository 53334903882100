import * as types from "../../actions/actionTypes";

const initialState = {
  departments:[]
};

export default function departmentReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOAD_DEPARTMENTS:
      return {...state, departments: action.departments};
    default:
      return state;
  }
}
