import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "@material-ui/core/Card";
import TextField from "../Utils/FormInputs/TextField";
import {titleCase} from "../Users/addUser";
import Textarea from "../Utils/FormInputs/Textarea";
import SmsSnackbar from "../Utils/Snackbar/SmsSnackbar";
import {CameraAlt} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import {useCompany} from "../../Context/company-context";
import {useSnackbar} from "../../Context/snackbar-context";
import PageTitle from "../Utils/smsTitle";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCog} from "@fortawesome/free-solid-svg-icons/faCog";
import {ErrorMessage} from "../Utils/styledComponents";
import Label from "../Utils/FormInputs/Label";


const CompanyDetails = (props) => {
    const {state, submitted, isSubmitted, filename, inputRef,
        handleChange, handleSubmit,  handleImageSelection} = useCompany(props);
    const {state:snackbarState, handleClose} = useSnackbar(props)
    const { companyName, companyEmail, mobile, address, website } = state;
    const {open, variant, message} = snackbarState

    return (
        <div>
            <PageTitle title="Company Details"/>
            <SubHeader title="Settings" subTitle="Company Details">
                <FontAwesomeIcon icon={faCog}/>
            </SubHeader>
            <div className="add-service-card">
                <SmsSnackbar
                    open={open}
                    message={message}
                    variant={variant}
                    handleCloseBar={handleClose}
                />
                <Card className="p-3">
                    <form autoComplete="off" className="mt-2" onSubmit={handleSubmit}>

                        <div className='company-image-container mb-3'>
                            <div className="edit-image company-image">
                                {filename ?  <img src={filename} alt="company"/>:<span>Upload company logo</span>}
                            </div>
                            <div className="company-icon-button">
                                <IconButton size="small" className="company-icon-holder" onClick={()=>inputRef.current.click()}><CameraAlt/></IconButton>
                            </div>
                        </div>
                        <input type='file' ref={inputRef} onChange={handleImageSelection} style={{display:'none'}}/>
                        <Form.Group as={Row} controlId="formGridEmail">
                            <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                                <Label  name='Company Name' type/>
                            </Col>

                            <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                                <TextField
                                    type="text"
                                    placeholder="Company name"
                                    name="companyName"
                                    value={titleCase(companyName)}
                                    onChange={handleChange}
                                    submitted={submitted}
                                />
                                {submitted && !companyName && (
                                    <ErrorMessage>
                                        Please enter the company name
                                    </ErrorMessage>
                                )}
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="formGridPassword">
                            <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                                <Label  name='Company Email'/>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                                <TextField
                                    type="email"
                                    placeholder="Email Address"
                                    name="companyEmail"
                                    value={companyEmail}
                                    onChange={handleChange}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formGridAddress1">
                            <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                                <Label name='Company Mobile' type/>
                            </Col>

                            <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                                <TextField
                                    type="text"
                                    name="mobile"
                                    value={mobile}
                                    placeholder="Company mobile"
                                    onChange={handleChange}
                                    submitted={submitted}
                                />
                                {submitted && !mobile && (
                                    <ErrorMessage>
                                        Please enter the company mobile
                                    </ErrorMessage>
                                )}
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="formGridAddress2">
                            <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                                <Label  name='Company Address' type/>
                            </Col>

                            <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                                <Textarea
                                    as="textarea"
                                    name="address"
                                    value={address}
                                    placeholder="Company address"
                                    onChange={handleChange}
                                    submitted={submitted}
                                />
                                {submitted && !address && (
                                    <ErrorMessage>
                                        Please enter the company address
                                    </ErrorMessage>
                                )}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formGridCity">
                            <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                                <Label  name='Website'/>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                                <TextField
                                    type="website"
                                    name="website"
                                    value={website}
                                    placeholder="website"
                                    onChange={handleChange}
                                    min="0"
                                />
                            </Col>
                        </Form.Group>
                        <button
                            type="submit"
                            disabled={isSubmitted === "pending"}
                            className={`btn sms-btn offset-md-5 mt-3 `}
                        >
                            {isSubmitted === "pending" ? "Updating..." : "Update"}
                        </button>
                    </form>
                </Card>
            </div>
        </div>
    );
};



export default CompanyDetails;
