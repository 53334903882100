import {useState} from 'react';
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import {titleCase} from "../../../Users/addUser";
import {v4 as uuidv4} from "uuid";

export const useNewLabRequest = (actions, match,  _token ) => {
    const {visit_id,patient_type, activity_id} = match
    const initialState = [{
        hospital_test_id: '', test_name: '', hospital_test_type: '', service_id: '', qty: 1,
        showTest: false, isError: false, cost: 0, currency:'', currency_symbol:'',  uuid: uuidv4(),
    }]
    const [submitted, setSubmitted] = useState(false);
    const [tests, setTests] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [labTests, setLabTests] = useState(initialState);
    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;
    const [openDialog, setOpenDialog] = useState(false)
    const [openConfirmation, setOpenConfirmation] = useState(false)
    const [cashItems, setCashItems] = useState([])
    const [test_id, setTestId] = useState('')
    const [isBlocking, setIsBlocking] = useState(false)
    const [isBlockDialog, setIsBlockDialog] = useState(false)

    const handleOpenDialog  = () =>{
        setOpenDialog(true)
    }

    const handleResetForm = () =>{
        setIsBlockDialog(false)
        setIsBlocking(false)
        setOpenDialog(false);
        setSubmitted(false);
        const arr = labTests.map(item=>({
            ...item, hospital_test_id: '', test_name: '', hospital_test_type: '', service_id: '', qty: 1,
            showTest: false, isError: false, cost: 0

        }))
        setLabTests(arr)
    }

    const handleCloseDialog  = () =>{
        if(isBlocking){
            setIsBlockDialog(true)
        }else{
           handleResetForm()
        }
    }


    const retrieveTests = (eventTxt, idx) => {
        const arr = labTests.map((item, index) => {
            if (index === idx) {
                const formData = new FormData();
                formData.append('hospital_test_name', titleCase(eventTxt));
                if (eventTxt !== '') {
                    axios.post(`${config.smsUrl}/cdoctor/autocompletesearch_hospt_tests`, formData).then(res => {
                        const data = res.data;
                        const dt = !data ? [] : data;
                        if (dt[0] === "No Labrequests Found") {
                            setTests([{value: '', label: 'No Lab tests found'}]);
                        } else {
                            setTests(dt);
                        }
                    }).catch(error => {
                        logoutErrorMessage(error, null, actions)
                    });
                    return {...item, test_name: eventTxt, showTest: true, isError: false}
                }
                return {...item, test_name: eventTxt, showTest: false, isError: false}
            }
            return item
        })
        setLabTests(arr)
        setIsBlocking(true)
    };

    const getTestDetails = (event, idx) => {
        let arr = labTests.map((item, index)=>{
            if(index === idx){
                return {...item, test_name:event.innerText, hospital_test_id:event.value, isError: false, showTest: false}
            }
            return item
        })
        if(event.value){
            const formData = new FormData();
            formData.append('hospital_test_id', event.value);
            axios.post(`${config.smsUrl}/cdoctor/get_testdetails_by_testid`, formData).then(res => {
                const resp = res.data;
                const data = !resp ? {} : resp;
                axios.post(`${config.smsUrl}/cbilling/item_cost`, {visit_id:+visit_id, item_id:data.service_id, item_type:'service'}).then(res => {
                    const resObj = res.data;
                    const dataObj = !resObj.data ? {} : resObj.data;
                    arr = arr.map((item, index)=>{
                        if(index === idx){
                            return {...item, specimen:data.specimen ? data.specimen : '', hospital_test_type: data.hospital_test_type ? data.hospital_test_type : '',
                                service_id:data.service_id ? data.service_id : '', cost:  dataObj.cost ?? 0,is_external:data.is_external,
                                currency_id: dataObj.currency_id, currency_symbol: dataObj.currency, ...dataObj}
                        }
                        return item
                    })
                    setLabTests(arr)
                    if(dataObj.is_provider === 1 && dataObj.is_provided === 0){
                        setTestId(data.hospital_test_id)
                        setOpenConfirmation(true)
                    }
                }).catch(error => {
                    errorMessages(error, null, actions)
                });
            }).catch(error => {
                errorMessages(error, null, actions)
            });
        }
        setIsBlocking(true)
    };

    const addItemsToReceipt = () =>{
        const arr = labTests.filter(item=>item.hospital_test_id === test_id)
        setCashItems(arr)
        setOpenConfirmation(false)
    }

    const handleCloseConfirmation = () =>{
        const arr = labTests.filter(item=>item.hospital_test_id !== test_id)
        setLabTests(arr)
        setOpenConfirmation(false)
    }

    const handleCancelConfirmation = () =>{
        setOpenConfirmation(false)
    }

    const handleAddRow = () => {
        const row = {
            hospital_test_id: '', test_name: '', hospital_test_type: '', service_id: '', qty: 1,
            showTest: false, isError: false, cost: 0,uuid: uuidv4(),
        }
        setLabTests([...labTests, row])
    };

    const removeRow = (item_uuid) => {
        const arr = labTests.filter((e) => e.uuid !== item_uuid)
        setLabTests(arr);
    };


    const handleSubmitLabRequest = (event) => {
        event.preventDefault();
        setSubmitted(true);
        const activityObj = patient_type === '1' ? { lab_state:1,
            activity_id: +activity_id} : { lab_state:0,
            activity_id: 0}
        const params = {
            visit_id: +visit_id,
            user_roles_id,
            ...activityObj,
            result_type: 2,
            bill_type: 2,
            bill_details: labTests.filter(test => test.hospital_test_id && test.service_id).map(item => ({
                hospital_test_id: item.hospital_test_id,
                service_id: item.service_id,
                quantity: 1,
                rate: item.cost,
                is_provided: item.is_provided,
                is_provider: item.is_provider,
                currency_id:item.currency_id
            }))
        }
        const arr = labTests.every(test => test.hospital_test_id && test.service_id)
        // const url = patient_type === '1' ? 'save_labrequest' : 'save_inpatient_labrequest';
        setIsBlocking(false)
        if (arr) {
            setIsSubmitted('pending');
            axios.post(`${config.smsUrl}/cdoctor/save_labrequest`, {...params}).then(() => {
                actions.snackbarActions.snackSuccess('Lab test(s) requested successfully');
                setIsSubmitted('resolved');
                setSubmitted(false);
                setOpenDialog(false)
                setLabTests(initialState)
            }).catch(err => {
                setIsSubmitted('rejected');
                errorMessages(err, null, actions)
            })
        }
    };



    const isPending = isSubmitted === 'pending'
    const isResolved = isSubmitted === 'resolved'
    const isRejected = isSubmitted === 'rejected'

    const addProps = {submitted, tests,  isSubmitted,openDialog,handleOpenDialog,
        labTests,  retrieveTests, getTestDetails, handleAddRow,handleCloseDialog,
        removeRow,  handleSubmitLabRequest, setIsSubmitted, isPending,isResolved, isRejected,
        openConfirmation, handleCloseConfirmation, addItemsToReceipt,handleCancelConfirmation,
        isBlockDialog, isBlocking, setIsBlockDialog,  handleResetForm}

    return {addProps}
}