import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {faUserMd} from "@fortawesome/free-solid-svg-icons/faUserMd";
import ReusableDoctorTabs, {doctorRoutes} from "../../Utils/Menu/ReusableDoctorTabs";
import {dateConvert, formatDate} from "../../Utils/ConvertDate";
import {PatientInformation} from "../PatientInfo/PatientInformation";
import {usePatientDetails} from "../ReferralNote/usePatientDetails";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../../actions/snackbarActions";
import {connect} from "react-redux";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {CenteredContainer} from "../../Utils/styledComponents";
import {LoadingGif} from "../../Utils/Loader";
import {CollapsibleList, DataCell, ModuleTable} from "../../Utils/Lists/CollapsibleList";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {FinishedStatus, PendingStatus, StyledIcon, P, Container, ViewButton, StyledLibraryIcon} from "../doctorStyles";
import TableRow from "@material-ui/core/TableRow";
import CustomDialog from "../../Utils/Dialogs/CustomDialog";
import {StyledContainer} from "../Prescription/NewPrescription";
import {NewRadiologyTable} from "./NewRadiologyRequest";
import DoctorRadiologyResults from "./RadiologyResults";
import {useDoctorRadiologyRequest} from "./hooks/useDoctorRadiologyRequest";
import {useEditRadiologyRequest} from "./hooks/useEditRadiologyRequest";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import RadiologyReport from "../../Radiology/RadiologyReport";
import {ConfirmationDialog} from "../../Utils/ReusableComponents/ConfirmationDialog";
import {BlockNavigationComponent} from "../../Utils/ReusableComponents/BlockNavigationComponent";
import {history} from "../../../Helpers/history";
import {FormDialog} from "../../Utils/Dialogs/FormDialog";
import { usePrint } from "../../Utils/Templates/usePrint";
import { RightAlignedContainer } from "../../Utils/styledComponents";

const headData = [{item:'Test Name',toolTip:'Make imaging request here', isRequired:true},
    {item:'Indication', isRequired:true},{item:'Status', isRequired:true},{item:'Action', isRequired:false}]


const OutPatientRadiology = ({actions, snackbars, match:{params}}) => {
    const {patient_number, visit_id, labrequest_id, activity_id,
        scan_request_id, patient_type,patient_admission_id} = params;

    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    // get the doctor's name
    const doctor_name = `${!_token.first_name ? "" : _token.first_name} ${!_token.last_name ? "" : _token.last_name}`;   

    const [patient, setPatient] = useState({patient_name: "", age: "", gender: "", address: "", phone: ""});
    const {patient: patientDetails} = usePatientDetails(actions, patient_number, '')
    const [scanRequests, setScanRequests] = useState([])
    const [status, setStatus] = useState('idle')
    const [id, setId] = useState('')
    const [previousScan, setPrevScan] = useState([]);
    const [openReport, setOpenReport] = useState(false)
    const [radiologyResults, setResults] = useState([]);
    const [scanResults, setScanResults] = useState({});
    const [activeChip, setActiveChip] = useState(0)
    const [openResult, setOpenResult] = useState(false)
    const [result_id, setResultId] = useState('')

    const editParams = {...params, previousScan, setPrevScan}

    const {addProps} = useDoctorRadiologyRequest(actions, params, _token)
    const {editProps} = useEditRadiologyRequest(actions, editParams, _token)

    const {isResolved, openConfirmation, handleCloseConfirmation, addItemsToReceipt,handleCancelConfirmation,
        isBlockDialog, isBlocking, setIsBlockDialog,  handleResetForm} = addProps
    const {isResolved: isEditResolved} = editProps



    useEffect(() => {
        setPatient({
            patient_name: `${!patientDetails.first_name ? "" : patientDetails.first_name} ${!patientDetails.last_name ? "" : patientDetails.last_name}`,
            age: patientDetails.Age,
            gender: patientDetails.gender,
            address: patientDetails.address,
            phone: patientDetails.phone_no
        });
    }, [patientDetails]);


    useEffect(()=>{
        if(!id){
            return
        }
        const formData = new FormData();
        formData.append('visit_id', visit_id);
        axios.post(`${config.smsUrl}/cdoctor/get_scan_results_details_by_visit_id`, formData).then(res=>{
            const data = res.data;
            const dt = !data ? {} : data;
            const list = !dt.scan_result ? [] : dt.scan_result;
            const result = list[0] ?? {};
            const r_id = result.scan_result_id ?? '';
            retrieveResults(r_id, 0)
            setResults(list)
        }).catch(err=>{
            logoutErrorMessage(err,null, actions)
        })
    },[id]);

    const retrieveResults = (scan_result_id, active) => {
        const formData = new FormData();
        formData.append('scan_result_id', scan_result_id);
        axios.post(`${config.smsUrl}/cradiology/get_scan_results`, formData).then((res) => {
            const data = res.data;
            const dt = !data ? {} : data;
            setScanResults({...dt, patient_info:{...dt?.patient_info, visit_id}})
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
        setActiveChip(active)
    };

    useEffect(()=>{
        if (!result_id){
            return
        }
        retrieveResults(result_id)
        return ()=>{
            setScanResults({})
        }
    },[result_id])



    useEffect(() => {
        const formData = new FormData();
        formData.append('visit_id', visit_id);
        setStatus('pending')
        axios.post(`${config.smsUrl}/cdoctor/get_previous_scan_request_by_visit_id`, formData).then(res => {
            const resp = res.data;
            const data = !resp ? [] : resp;
            const arr = groupByScanRequestId(data)
            const requests = arr.map(item=>({...item, open:true}))
            setScanRequests(requests);
            setPrevScan(data)
            setStatus('success')
        }).catch(error => {
            logoutErrorMessage(error, null, actions)
            setStatus('error')
        });
    }, [isResolved,isEditResolved])


    const groupByScanRequestId = (arr=[]) =>{
        let result = []
        let knowledge = {}
        arr.forEach(item =>{
            let id = formatDate(item.date_created);
            if(id in knowledge){
                result[knowledge[id]].scans.push(item)
            }else {
                knowledge[id] = result.length
                result.push({
                    id: item.scan_request_id,
                    date: formatDate(item.date_created),
                    doctor_name:`${item.doctor_firstname ? item.doctor_firstname:''} ${item.doctor_lastname ? item.doctor_lastname : ''}`,
                    scans: [item]
                })

            }
        })
        return result
    }

    const handleOpen = (id) =>{
        const itemsArr = scanRequests.map((item)=>{
            if(item.id === id){
                return {...item,open:!item.open}
            }else{
                return item
            }
        })
        setScanRequests(itemsArr)
    }

    const handleOpenReport  = (_id) =>{
        setId(_id)
        setOpenReport(true)
    }

    const handleCloseReport  = () =>{
        setOpenReport(false)
    }

    const handleOpenResult  = (_id) =>{
        setResultId(_id)
        setOpenResult(true)
    }

    const handleCloseResult  = () =>{
        setOpenResult(false)
    }

    const isLoading = status === 'pending'
    const isSuccess = status === 'success'
    const isError = status === 'error'




    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const results = !scanResults ? {} : scanResults;
    const patient_info = !results.patient_info ? {} : results.patient_info;
    const result_details = !results.scan_result ? {} : results.scan_result;


    const arr = doctorRoutes(patient_number, visit_id, patient_type,patient_admission_id)
    const {patient_name, age, gender, address, phone} = patient;

    const {componentRef, handlePrint, fontSize}  = usePrint();

    const {openBar, type, message} = snackbars;
    const {handleOpenEdit, openEdit, handleCloseEdit, handleSubmitScanRequest, isPending:isPendingEdit} = editProps
    const {handleOpenDialog,openDialog,handleCloseDialog, handleSubmitScanRequest:handleSubmit, isPending} = addProps

    return (
        <div className='journals'>
            <PageTitle title="Radiology Request"/>
            <SubHeader title="Doctor" subTitle="Radiology Request" doctor_name={doctor_name}>
                <FontAwesomeIcon icon={faUserMd}/>
            </SubHeader>

            <BlockNavigationComponent
                when={isBlocking}
                shouldBlockNavigation={()=>{
                    return isBlocking;
                }}
                navigate={path=>history.push(path)}
                dialogClose={isBlockDialog}
                setIsBlockDialog={setIsBlockDialog}
                clearForm={handleResetForm}
            />

            <FormDialog open={openDialog} handleClose={handleCloseDialog} title='Add  Request'
                        maxWidth='lg' handleSubmit={handleSubmit} buttons={
                <StyledContainer>
                    <button type='submit' className='btn sms-btn btn-sm' disabled={isPending}>
                        {isPending ? 'Saving...' :'Save  Request'}</button>
                </StyledContainer>
            }>
                    <NewRadiologyTable {...addProps}/>
            </FormDialog>

            <CustomDialog open={openReport} handleClose={handleCloseReport} title='Radiology Report' maxWidth='lg'>
                <DoctorRadiologyResults {...{radiologyResults,  retrieveResults, scanResults, activeChip}}/>
            </CustomDialog>

            <CustomDialog open={openResult} handleClose={handleCloseResult} title='Radiology Report' maxWidth='lg'>
                <RightAlignedContainer>
                    <button onClick={handlePrint} className="btn sms-amber-btn  btn-sm">
                    Print
                    </button>
                </RightAlignedContainer>
                <div ref={componentRef}>
                        {/*{fontSize}*/}
                    <RadiologyReport patient_info={patient_info} scan_result={result_details}/>
                </div>
               
            </CustomDialog>


            <ConfirmationDialog open={openConfirmation} handleClose={handleCancelConfirmation} title='Make cash payment'
                                confirmItems={addItemsToReceipt} removeItems={handleCloseConfirmation} service='scan'/>

            <FormDialog open={openEdit} handleClose={handleCloseEdit} title='Edit  Request'
                        maxWidth='lg' handleSubmit={handleSubmitScanRequest} buttons={
                <StyledContainer>
                    <button type='submit' className='btn sms-btn btn-sm' disabled={isPendingEdit}>{isPendingEdit ? 'Saving...' :'Save  Request'}</button>
                </StyledContainer>
            }>
                    <NewRadiologyTable {...{...editProps, isEdit:true, isOutPatient:true}}/>
            </FormDialog>
            <MainSnackbar open={openBar} handleCloseBar={closeSnackbar} variant={type} message={message}/>
            <ReusableDoctorTabs components={arr} patient_type={+patient_type}>
                <PatientInformation {...{patient_number, visit_id}}/>
                <Container>
                    <button onClick={handleOpenDialog}  type='button' className='btn btn-sm sms-info-btn mb-2'>Add Radiology Request</button>
                    {isLoading ? <CenteredContainer>
                        <LoadingGif/>
                    </CenteredContainer> : null}
                    {isSuccess ? scanRequests.length > 0 ? scanRequests.map((item)=>{
                        // const isAdded = item.scans.some(dt=>dt.item_status !== 'Done')

                        const isDone = item.scans.some(dt=>dt.item_status === 'Done')
                        const payStatus =  item.scans.some(dt=>dt.pay_status === 0 || dt.pay_status === 3)
                        return(
                            <CollapsibleList key={item.id} open={item.open} name={item.date} handleOpen={()=>handleOpen(item.id)} button={
                                <>
                                    {payStatus ? <Tooltip title='Edit Request'>
                                        <IconButton onClick={()=>handleOpenEdit(item)} >
                                            <StyledIcon/>
                                        </IconButton>
                                    </Tooltip> : null}
                                    {isDone ?
                                        <ViewButton onClick={()=>handleOpenReport(item.id)} className='btn btn-sm sms-info-btn btn-sm ml-3'>View  Report</ViewButton>
                                        : null}
                                </>
                            }
                                // user={ <ItemText  primary={`Requested By: ${item.doctor_name}`} />}
                            >
                                <ModuleTable headData={headData}>
                                    {item.scans.map((row, index) => {
                                        const isPending = row.item_status === 'Pending' || row.item_status === null;
                                        const isFinished = row.item_status === 'Done';
                                        const isRemoved = row.pay_status === 2;
                                        return(
                                            <TableRow
                                                key={index}
                                            >
                                                <DataCell>
                                                    {row.hospital_scan_name}
                                                </DataCell>

                                                <DataCell>{row.purpose}</DataCell>
                                                <DataCell>{isRemoved ? <PendingStatus>Scan excluded</PendingStatus>: isPending ? <PendingStatus>{row.item_status ? row.item_status : 'Pending'}</PendingStatus>:isFinished ? <FinishedStatus>{row.item_status}</FinishedStatus>: null}</DataCell>
                                                <DataCell>{isFinished ?
                                                    <Tooltip title='View Results'>
                                                        <IconButton onClick={()=>handleOpenResult(row.scan_result_id)}>
                                                            <StyledLibraryIcon/>
                                                        </IconButton>
                                                    </Tooltip> : null}
                                                </DataCell>
                                            </TableRow>
                                        )})}
                                </ModuleTable>
                            </CollapsibleList>
                        )
                    }) : <CenteredContainer>
                        <P>No radiology requests submitted for patient, click the add radiology request button to submit a request</P>
                    </CenteredContainer>:null}
                    {isError ? <CenteredContainer>
                        <P>An error occurred on the server</P>
                    </CenteredContainer>:null}
                </Container>
            </ReusableDoctorTabs>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbar
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(OutPatientRadiology);