import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {connect} from "react-redux";
import * as snackbarActions from "../../actions/snackbarActions";
import * as billingActions from "../../actions/BillingActions/BillingActions";
import {bindActionCreators} from "redux";
import {SubHeader} from "../../Containers/SubHeader";
import {faCoins} from "@fortawesome/free-solid-svg-icons/faCoins";
import CustomTable from "../Utils/Tables/CustomTable";
import PageTitle from "../Utils/smsTitle";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import {titleCase} from "../Users/addUser";
import {formatDateTime} from "../Utils/ConvertDate";
import {useTheme} from "@material-ui/core/styles";
import {Link} from "react-router-dom";
import {ReusableRouteTabs} from "../Utils/Dialogs/ReusableTabs";
import {history} from "../../Helpers/history";
import axios from "axios";
import { config } from "../../Helpers/env";
import { logoutErrorMessage } from "../../Helpers/ErrorMessages";
import moment from "moment";
import {LoadingGif} from "../Utils/Loader";
import {PendingStatus, Processing} from "../DoctorsModule/doctorStyles";



const headData = [
    {id: 'sl', numeric: false, disablePadding: false, label: 'Sl'},
    {id: 'date', numeric: false, disablePadding: false, label: 'Date'},
    {id: 'patient_number', numeric: false, disablePadding: false, label: 'Patient No.'},
    {id: 'patientName', numeric: false, disablePadding: false, label: 'Patient Name'},
    {id: 'status', numeric: false, disablePadding: false, label: 'Status'}
];


const QueueList = ({actions, snackbars, match:{params}}) => {
    const [activePage, setActivePage] = useState(1);
    const [term, setTerm] = useState('');
    const [data, setData] = useState({list:[], loading:'idle', total_count:0})

    const {component} = params

    const {list, total_count, loading} = data



    const url = {
        'triage':'cvisit/triage_queue',
        'lab':'claboratory/lab_queue',
        'scan':'cradiology/scan_queue'
    }



    useEffect(() => {
        const formData = new FormData();
        formData.append('search', term);
        setData({...data,loading: 'loading'})
        axios.post(`${config.smsUrl}/${url[component]}/${activePage}`, formData).then(res=>{
            const dataFormat = {
                'triage':'triage_queue',
                'lab':'queue_list',
                'scan':'scan_queue_list'
            }
            const data = res.data;
            const dt = !data ? {} : data;
            const arr = !dt[dataFormat[component]] ? [] : dt[dataFormat[component]];
            const count = !dt.total_count ? 0 : dt.total_count;
            setData({...data, list: arr, loading: 'success', total_count: count})
        }).catch(err=>{
            logoutErrorMessage(err,null,actions)
            setData({...data,loading: 'error'})
        })

    }, [component, activePage])


    const handleChangeActivePage = (page) => {
        setActivePage(page)
    };

    const handler = (event) => {
        setTerm(event.target.value)
    }

    const closeSnackBar = () => {
        actions.snackbarAction.hideSnackbar();
    };

    const isLoading =  loading === 'loading';
    const isSuccess = loading === 'success';
    const isError = loading === 'error';

    const {openBar, type, message} = snackbars;

    const components = [{label: 'Triage', path: '/queues/triage'},
        {label: 'Laboratory', path: '/queues/lab'},
        {label: 'Scan', path: '/queues/scan'},
    ]

    const title = {
        'triage':'Triage',
        'lab':'Laboratory',
        'scan':'Scan'
    }

    return (
        <div className='journals'>
            <PageTitle title="Queue"/>
            <SubHeader title="Queue" subTitle={`${title[component]} Queue`}>
                <FontAwesomeIcon icon={faCoins}/>
            </SubHeader>
            <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/>
            <div className='mui-tables'>
                <MainSnackbar handleCloseBar={closeSnackBar} message={message} open={openBar} variant={type}/>

                    <CustomTable colSpan={7} data={list}
                                 headData={headData}
                                 activePage={activePage} handler={handler}
                                 handleChangeNextPage={handleChangeActivePage} total_count={total_count}
                                 records={10} customPage term={term}>
                        <TableBody>
                            {isLoading ? <TableRow>
                                <TableCell colSpan={headData.length} align="center"><LoadingGif/></TableCell>
                            </TableRow> : null}
                            {isSuccess ?  list.length > 0 ? list.slice(0, 10)
                                .map((item, index) => {
                                    const count = ((activePage - 1) * 10) + index + 1;
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    const activityStatus =  {
                                        'TRIAGE':{
                                            1:<PendingStatus>Triage requested</PendingStatus>,
                                            2: <Processing> Triage Started</Processing>
                                        },
                                        'LAB':{
                                            1:<PendingStatus>Lab test, requested</PendingStatus>,
                                            2: <Processing>Samples collected</Processing>
                                        },
                                        'SCAN':{
                                            1:<PendingStatus>Scan requested</PendingStatus>,
                                            2: <Processing> Scan Started</Processing>
                                        },

                                    }
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={item.visit_id}
                                            className='mui-table-row'>
                                            <TableCell>{count}</TableCell>
                                            <TableCell component="th" id={labelId} scope="row"
                                                       padding="default"
                                                       className='mui-table-cell'>
                                                <span>{formatDateTime(item.begin_datetime)}</span>
                                            </TableCell>
                                            <TableCell>
                                                <span>{item.patient_number}</span>
                                            </TableCell>
                                            <TableCell>
                                                   <span>{titleCase(`${!item.first_name ? "" : item.first_name} 
                                                                       ${!item.last_name ? "" : item.last_name}`)}</span>
                                            </TableCell>
                                            <TableCell>
                                                {activityStatus[item.activity_name][item.activity_status]}
                                            </TableCell>
                                        </TableRow>
                                    );
                                }): <TableRow>
                                <TableCell colSpan={headData.length} align="center">No records found</TableCell>
                            </TableRow>:null}
                            {isError ? <TableRow>
                                <TableCell colSpan={headData.length} align="center">The server did not return a valid response</TableCell>
                            </TableRow>:null}
                        </TableBody>
                    </CustomTable>
            </div>
        </div>
    );

}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbar, billing: state.billing
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarAction: bindActionCreators(snackbarActions, dispatch),
            billingActions: bindActionCreators(billingActions, dispatch),
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(QueueList);
