import * as types from "../actionTypes";
import axios from "axios";
import { config } from "../../Helpers/env";
import { snackSuccess} from "../snackbarActions";
import {history} from "../../Helpers/history";
import {errorMessages, logoutErrorMessage} from "../../Helpers/ErrorMessages";



export const deleteScanRequest = (scan_request_id) => (dispatch) => {
    return axios.get(`${config.smsUrl}/cradiology/delete_request`, {params: {scan_request_id}}).then(() => {
        dispatch({type: types.DELETE_SCAN_REQUEST, scan_request_id});
        dispatch(snackSuccess("Scan deleted successfully"))
    }).catch(err => {
        errorMessages(err, dispatch, null)
    })
};

export const getAllScanRequests = (page, formData) => (dispatch) => {
    dispatch({type: types.GET_SCAN_REQUESTS});
    return axios.post(`${config.smsUrl}/cradiology/retrieve_all_scan_requests/${page}`, formData).then((res) => {
        const data = res.data;
        const dt = !data ? {} : data;
        const requests = !dt.scan_request_list ? [] :dt.scan_request_list;
        const total_count = !dt.total_count ? 0 : dt.total_count;
        dispatch({type:types.TOTAL_SCAN_COUNT, total_count});
        dispatch({type: types.GET_SCAN_REQUEST_SUCCESS, scanRequests:requests});
    }).catch(err => {
        logoutErrorMessage(err, dispatch, null);
        dispatch({type: types.GET_SCAN_REQUEST_ERROR});
    })
};

export const getAllScanResults = (page, formData) => (dispatch) => {
    dispatch({type: types.GET_SCAN_REQUESTS});
    return axios.post(`${config.smsUrl}/cradiology/retrieve_all_scan_results/${page}`, formData).then((res) => {
        const data = res.data;
        const dt = !data ? {} : data;
        const results = !dt.scan_result_list ? [] :dt.scan_result_list;
        const total_count = !dt.total_count ? 0 : dt.total_count;
        dispatch({type:types.TOTAL_SCAN_COUNT, total_count});
        dispatch({type: types.GET_SCAN_RESULTS_SUCCESS, allScanResults:results});
    }).catch(err => {
        logoutErrorMessage(err, dispatch, null);
        dispatch({type: types.GET_SCAN_REQUEST_ERROR});
    })
};

// export const getScanRequest = (formData) => (dispatch) => {
//     return axios.post(`${config.smsUrl}/cradiology/get_scan_request_by_id`, formData).then((res) => {
//         const data = res.data;
//         dispatch({type: types.GET_SCAN_REQUEST_BY_ID, request:data});
//     }).catch(err => {
//        console.log(err)
//     })
// };

export const enterScanResults = (url,formData) => (dispatch) => {
    dispatch({type: types.ADD_SCAN_RESULTS_REQUEST});
    return axios.post(url, formData).then(() => {
        dispatch({type: types.ADD_SCAN_RESULTS_SUCCESS});
        // dispatch(snackSuccess("Scan results captured successfully"));
        history.push('/scanradiology')
    }).catch(err => {
        dispatch({type: types.ADD_SCAN_RESULTS_ERROR});
        errorMessages(err, dispatch, null)
    })
};

export const editScanResults = (formData) => (dispatch) => {
    dispatch({type: types.EDIT_SCAN_RESULTS_REQUEST});
    return axios.post(`${config.smsUrl}/cradiology/edit_scan_results`, formData).then(() => {
        dispatch({type: types.EDIT_SCAN_RESULTS_SUCCESS});
        dispatch(snackSuccess("Scan results updated successfully"));
        history.push('/scanresults')
    }).catch(err => {
        dispatch({type: types.EDIT_SCAN_RESULTS_ERROR});
        errorMessages(err, dispatch, null)
    })
};

export const viewScanResults = (formData) => (dispatch) => {
    return axios.post(`${config.smsUrl}/cradiology/get_scan_results`, formData).then((res) => {
        const data = res.data;
        const dt = !data ? {} : data;
        // const {scan_result} = dt;
        // const {image_path} = scan_result;
        // dt['scan_result']['image'] = `${config.smsUrl}/cradiology/view_scan_result?image_name=${image_path}`
        dispatch({type: types.VIEW_SCAN_RESULTS, scanResults:dt});
    }).catch(err => {
      logoutErrorMessage(err, dispatch, null)
    })
};

