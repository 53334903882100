import React from "react";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PageTitle from "../../Utils/smsTitle";
import Card from "@material-ui/core/Card";
import ReactToPrint from "react-to-print";
import {useTheme} from "@material-ui/core/styles";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../../actions/snackbarActions"
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import ReceiptTemplate from "../../Utils/ReceiptTemplate";
import {useStockAdjustmentDetails} from "./hooks/useStockAdjustmentDetails";
import {convDate, formatDateTime} from "../../Utils/ConvertDate";
import {faMortarPestle} from "@fortawesome/free-solid-svg-icons/faMortarPestle";


export function StockAdjustmentDetails(props) {
    const {componentRef,details,adjustment,closeSnackbar} = useStockAdjustmentDetails(props)
    const {adjusted_by, date, stock_type} = adjustment;
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    const {openBar, type, message} = props.snackbars;
    return (
        <div data-testid="dispatch-note">
            <PageTitle title="Stock Adjustment"/>
            <SubHeader title="Pharmacy" subTitle="Stock adjustment details">
                <FontAwesomeIcon icon={faMortarPestle}/>
            </SubHeader>
            <section className="general-lab-report">
                <MainSnackbar variant={type} handleCloseBar={closeSnackbar} open={openBar} message={message}/>
                <div className="text-right">
                    <ReactToPrint content={() => componentRef.current}
                                  trigger={() => <button className="btn sms-amber-btn mb-2">Print</button>}/>
                </div>
                <Card className="p-2 lab-report" ref={componentRef} id="prescription-print">
                    <ReceiptTemplate>
                        <div className={`title-background text-center mb-2 ${palette.type === 'dark' &&
                        'dark-title-background'}`}
                             id="title-background">
                            <h6>Adjusted Stock</h6>
                        </div>
                        <table className="table table-sm table-borderless prescription-table">
                            <tr>
                                <td className={palette.type === 'dark' && 'dark-theme-color'}><p>Date: <strong>{date ? formatDateTime(date) : date}</strong></p></td>
                                <td className={palette.type === 'dark' && 'dark-theme-color'}><p>Adjusted By: <strong>{adjusted_by}</strong></p></td>
                                <td className={palette.type === 'dark' && 'dark-theme-color'}><p>Stock Type: <strong>{stock_type}</strong></p></td>
                            </tr>
                        </table>
                        <div className={`bill-patient-details mb-3 ${palette.type === "dark" &&
                        "dark-bill-patient-details"}`}
                             id="patient-lab-report"/>
                        <div className="table-responsive">
                            <table className="table table-bordered table-sm profile-table bill-receipt-table">
                                <thead>
                                <tr>
                                    <td className={palette.type === 'dark' && 'dark-theme-color'}><p><strong>Product</strong></p></td>
                                    {/* <td className={palette.type === 'dark' && 'dark-theme-color'}><p>Batch</p>
                                    </td> */}
                                    <td className={palette.type === 'dark' && 'dark-theme-color'}><p><strong>Expiry Date</strong></p></td>
                                    <td className={palette.type === 'dark' && 'dark-theme-color'} align="center">
                                        <p><strong>Quantity</strong></p></td>
                                    <td className={palette.type === 'dark' && 'dark-theme-color'} align="center">
                                        <p><strong>Adjustment Type</strong></p></td>
                                </tr>
                                </thead>
                                <tbody>
                                {details.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td><p>{item.product_name}</p></td>
                                            {/* <td><p>{item.batch_id}</p></td> */}
                                            <td><p>{item.expiry_date ? convDate(item.expiry_date) : item.expiry_date}</p></td>
                                            <td align="center"><p>{item.quantity}</p></td>
                                            <td align="center"><p>{item.adjustment_type === 'D' ? 'Decrement':'Increment'}</p></td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                    </ReceiptTemplate>
                </Card>
            </section>
        </div>
    )

}

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(StockAdjustmentDetails);
