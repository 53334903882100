import { useEffect, useReducer, useState } from "react";
import { dateConvert } from "../../../Utils/ConvertDate";
import axios from "axios";
import { config } from "../../../../Helpers/env";
import { errorMessages, logoutErrorMessage } from "../../../../Helpers/ErrorMessages";
import { titleCase } from "../../../Users/addUser";
import { history } from "../../../../Helpers/history";
import { useInvoiceProviders } from "../../customers/hooks/useInvoiceProviders";
import { useSelfRequestPrescription } from "../../../Visit/NewService/hooks/usePrescriptionSelfRequest";
import { useNewServiceRequest } from "../../../Visit/NewService/hooks/useNewServiceRequest";
import { billingServicesReducer } from "../../utils/billingServicesReducer";
import { billingProductsAndServicesReducer, billingProductsReducer } from "../../utils/billingProductsReducer";
import {
    filteredPayloadProducts,
    filterProducts as filterProductsArr, filterProductsWithCurrency,
    filterServices,
    filterServicesWithCurrency
} from "../../utils/SanitizeArr";
import * as billingActions from "../../utils/billingActions";
import { useQuery } from "react-query";

export const useCreateInvoice = (actions, match = {}) => {
    const [billState, setBillState] = useState({
        invoice_date: dateConvert(),
        payment_type: '',
        memo: '',
        insurance_provider: '',
        account_name: '',
        card_number: '',
        policy_holder: ''
    })
    const [state, setState] = useState({ patient_name: '', patient_number: '', address: '' });
    //
    // const [{procedureData,invoicesEdited,invoicesRemoved,invoicesPaid}, dispatch] = useReducer(billingServicesReducer,{
    //     procedureData:[],invoicesEdited:[],invoicesRemoved:[],invoicesPaid:[]
    // })
    // const [{products,productsEdited,productsRemoved,productsPaid}, dispatchProducts] = useReducer(billingProductsReducer,{
    //     products:[],productsEdited:[],productsRemoved:[],productsPaid:[]
    // })

    const [
        { products, productsEdited, productsRemoved, productsPaid, procedureData, invoicesEdited, invoicesRemoved, invoicesPaid },
        dispatchProductsAndServices,
    ] = useReducer(billingProductsAndServicesReducer, {
        products: [],
        productsEdited: [],
        productsRemoved: [],
        productsPaid: [],
        procedureData: [],
        invoicesEdited: [],
        invoicesRemoved: [],
        invoicesPaid: [],
    });
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [openPrint, setOpenPrint] = useState(false);
    const [total_amount, setTotalAmount] = useState(0);
    const [totals, setTotals] = useState({ total_procedure: 0, total_product: 0 })
    const [submitted, setSubmitted] = useState(false);
    const [addService, setAddService] = useState(false)
    const [addProduct, setAddProduct] = useState(false)
    const [departments, setDepartments] = useState([])
    const [currencies, setCurrencies] = useState([])
    const [currency, setCurrency] = useState('')
    const [exchangeRate, setExchangeRate] = useState(0)
    const [currencyTotal, setCurrencyTotal] = useState(0)
    const [isBlocking, setIsBlocking] = useState(false)
    const [is_converted, setIsCoverted] = useState(false);
    const [paidAmount, setPaidAmount] = useState("");


    const { visit_id } = match;
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;

    const obj = { visit_id, user_roles_id, actions }


    const { renderPrescription, isResolved, handleClosePrescriptionDialog } = useSelfRequestPrescription({ ...obj, setOpenDialog: setAddProduct })

    const { renderService, isResolvedService, handleCloseServiceDialog } = useNewServiceRequest({ ...obj, setOpenDialog: setAddService }, true)

    const { providerProps, openDialog: openProvider, handleOpenDialog,
        handleCloseDialog, providerOptions: providerList } = useInvoiceProviders(actions, true)
    const providers = providerList.map(item => ({ value: `${item.provider_id}-${item.provider_type}-${item.provider_name}`, text: item.provider_name }))

    const calculateTotal = (procArr, prdts, rate = 0) => {

        const proc_total = procArr.reduce((a, item) => {
            return a + item.subTotal
        }, 0)

        const prdt_total = prdts.reduce((a, item) => {
            return a + item.subTotal
        }, 0)

        const currency_service_total = procArr.reduce((a, item) => {
            const base_total = rate ? (+item.rate / rate) : 0
            return a + +base_total
        }, 0)

        const product_service_total = prdts.reduce((a, item) => {
            const base_total = rate ? (+item.rate / rate) * +item.quantity : 0
            return a + +base_total
        }, 0)

        setTotals({ ...totals, total_procedure: proc_total, total_product: prdt_total })

        setTotalAmount((proc_total + prdt_total).toFixed(3))

        setCurrencyTotal((currency_service_total + product_service_total).toFixed(3))
    }

    const outPatientUnpaidBillDetailsInvoiceQuery = useQuery(
        ["outPatientUnpaidBillDetailsInvoiceQuery", isResolved, isResolvedService],
        async () => {
            try {
                const formData = new FormData();

                formData.append('visit_id', visit_id);

                formData.append('pay_status', 0);

                const response = await axios.post(`${config.smsUrl}/cbilling/bill_update_form`, formData);

                const data = response.data ?? {};

                const patient = data?.patient_info ?? {};

                const bill_details = data.bill ?? [];

                setState({
                    patient_name: titleCase(`${!patient.patient_firstname ? "" : patient.patient_firstname} ${!patient.patient_lastname ? "" : patient.patient_lastname}`),
                    patient_number: !patient.patient_number ? "" : patient.patient_number,
                    address: titleCase(!patient.address ? "" : patient.address), notes: ''
                });

                setBillState((prevBillState) => ({
                    ...prevBillState,
                    insurance_provider: prevBillState.insurance_provider === "" ? `${patient.provider_id}-${patient.provider_type}-${patient.provider_name}` : prevBillState.insurance_provider,
                    account_name: prevBillState.account_name === "" ? patient.accounts : prevBillState.account_name,
                    card_number: prevBillState.card_number === "" ? patient.card_number : prevBillState.card_number,
                    policy_holder: prevBillState.policy_holder === "" ? patient.account_name : prevBillState.policy_holder,
                })
                )

                const filteredArr = bill_details.filter(item => item.product_id);

                const procs = bill_details.filter(item => item.service_id);

                // const services = filterServices(exchangeRate, procs)
                //
                // const prescr = filterProductsWith(exchangeRate, filteredArr);

                const services = filterServicesWithCurrency(exchangeRate, procs, currency)

                const prescr = filterProductsWithCurrency(exchangeRate, filteredArr, currency);

                dispatchProductsAndServices({
                    type: billingActions.RETRIEVE_PRODUCTS_AND_SERVICES,
                    services: services,
                    products: prescr,
                    exchangeRate,
                    calculateTotal,
                })

            } catch (error) {
                logoutErrorMessage(error, null, actions)
            }
        },
        { retry: 1, refetchInterval: 5000 }
    );

    //
    // useEffect(() => {
    //     function retrieveBillDetails() {
    //         const formData = new FormData();
    //         formData.append('visit_id', visit_id);
    //         formData.append('pay_status', 0);
    //         axios.post(`${config.smsUrl}/cbilling/bill_update_form`, formData).then(res => {
    //             const data = res.data;
    //             const all_data = !data ? {} : data;
    //             const patient = !all_data.patient_info ? {} : all_data.patient_info;
    //
    //             const bill_details = !all_data.bill ? [] : all_data.bill;
    //
    //             setState({
    //                 patient_name: titleCase(`${!patient.patient_firstname ? "" : patient.patient_firstname} ${!patient.patient_lastname ? "" : patient.patient_lastname}`),
    //                 patient_number: !patient.patient_number ? "" : patient.patient_number,
    //                 address: titleCase(!patient.address ? "" : patient.address), notes: ''
    //             });
    //             //
    //             setBillState({...billState, insurance_provider: `${patient.provider_id}-${patient.provider_type}-${patient.provider_name}`,
    //                 account_name: patient.accounts, card_number: patient.card_number ? patient.card_number : '', policy_holder: patient.account_name})
    //             //
    //             // setBillState({...billState,account_name: patient.accounts,
    //             //     card_number: patient.card_number ? patient.card_number : '',
    //             //     policy_holder: patient.account_name})
    //             //
    //             // if((patient.provider_id !== undefined && patient.provider_id) &&
    //             // (patient.provider_type !== undefined && patient.provider_type) && (patient.provider_name !== undefined && patient.provider_name)){
    //             //     setBillState({...billState ,insurance_provider: `${patient.provider_id}-${patient.provider_type}-${patient.provider_name}`})
    //             // }
    //
    //             const filteredArr = bill_details.filter(item=>item.product_id);
    //             const procs = bill_details.filter(item => item.service_id);
    //
    //             const services = filterServices(exchangeRate, procs)
    //
    //             const prescr = filterProductsArr(exchangeRate, filteredArr);
    //
    //             dispatch({type:billingActions.RETRIEVE_SERVICES, services})
    //             dispatchProducts({type: billingActions.RETRIEVE_PRODUCTS,products:prescr})
    //             calculateTotal(services, prescr)
    //
    //         }).catch(err => {
    //             logoutErrorMessage(err, null, actions)
    //         })
    //     }
    //     retrieveBillDetails()
    //     const interval = setInterval(()=>{
    //         retrieveBillDetails()
    //     }, 15000)
    //
    //     return ()=>clearInterval(interval)
    // }, [isResolved, isResolvedService]);


    //get departments
    useEffect(() => {
        axios.get(`${config.smsUrl}/cvisit/retrieve_department`).then(res => {
            const data = res.data;
            const dt = !data ? [] : data;
            const departs = !dt.departments ? [] : dt.departments;
            const arr = departs.map(department => ({
                value: department.department_id,
                label: department.department_name
            }))
            setDepartments(arr)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, [])


    // get currencies
    useEffect(() => {
        axios.get(`${config.smsUrl}/accounting/currencies`).then(res => {
            const data = res.data;
            const all_data = !data ? {} : data;
            const arr = all_data.data ?? [];
            const list = arr.map(item => ({ value: `${item.id}-${item.is_base_currency}-${item.currency_symbol}`, text: `${item.currency_symbol} ${item.currency_name}` }))
            setCurrencies(list)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, []);





    //Change currency
    const handleChangeCurrency = (event) => {
        setCurrency(event.target.value)
        setTotals({ ...totals, balance: 0 })
        setExchangeRate('')
        setIsBlocking(true)
    }

    //Change exchange rate
    const handleChangeExchangeRate = (event) => {
        setExchangeRate(event.target.value)
        setIsCoverted(true);
        // dispatchProducts({type: billingActions.CALCULATE_PRODUCT_AMOUNT, rate:event.target.value, currency:currency.split('-')[2]})
        // dispatch({type: billingActions.CALCULATE_SERVICE_AMOUNT, rate:event.target.value, currency:currency.split('-')[2]})

        // dispatchProducts({type: billingActions.CALCULATE_PRODUCT_AMOUNT, rate:event.target.value, currency:currency.split('-')[2]})
        // dispatch({type: billingActions.CALCULATE_SERVICE_AMOUNT, rate:event.target.value, currency:currency.split('-')[2]})

        dispatchProductsAndServices({
            type: billingActions.CALCULATE_PRODUCT_AMOUNT, exchangeRate: event.target.value, currency: currency.split('-')[2]
        })

        dispatchProductsAndServices({
            type: billingActions.CALCULATE_SERVICE_AMOUNT, exchangeRate: event.target.value, currency: currency.split('-')[2]
        })

        calculateTotal(procedureData, products, event.target.value)

        setIsBlocking(true)

    }


    const handleChangeRate = (event, bill_id) => {
        const prods = products.filter(item => !item.isExcluded)
        // dispatch({
        //     type:billingActions.CHANGE_SERVICE_RATE,
        //     value:event.target.value,
        //     bill_id,
        //     exchangeRate,
        //     calculateTotal,
        //     products:prods
        // })

        dispatchProductsAndServices({
            type: billingActions.CHANGE_SERVICE_RATE,
            value: event.target.value,
            bill_id,
            exchangeRate,
            calculateTotal,
            products: prods
        })

        setIsBlocking(true)
    }

    const handleChangeCurrencyRate = (event, bill_id) => {
        const prods = products.filter(item => !item.isExcluded)

        // dispatch({
        //     type:billingActions.CHANGE_SERVICE_CURRENCY_RATE,
        //     value:event.target.value,
        //     bill_id,
        //     exchangeRate,
        //     calculateTotal,
        //     products:prods
        // })

        dispatchProductsAndServices({
            type: billingActions.CHANGE_SERVICE_CURRENCY_RATE,
            value: event.target.value,
            bill_id,
            exchangeRate,
            calculateTotal,
            products: prods
        })

        setIsBlocking(true)
    }

    const handleDeleteProcedure = (item) => {
        const { bill_id } = item
        const prods = products.filter(item => !item.isExcluded)

        // dispatch({
        //     type:billingActions.DELETE_SERVICE,
        //     bill_id,
        //     exchangeRate,
        //     calculateTotal,
        //     products:prods
        // })

        dispatchProductsAndServices({
            type: billingActions.DELETE_SERVICE,
            bill_id,
            exchangeRate,
            calculateTotal,
            products: prods
        })

        setIsBlocking(true)
    };

    const handleChangeProductRate = (event, bill_id) => {
        const procedures = procedureData.filter(item => !item.isExcluded)

        // dispatchProducts({
        //     type:billingActions.CHANGE_PRODUCT_RATE,
        //     value:event.target.value,
        //     bill_id,
        //     exchangeRate,
        //     calculateTotal,
        //     services:procedures
        // })

        dispatchProductsAndServices({
            type: billingActions.CHANGE_PRODUCT_RATE,
            value: event.target.value,
            bill_id,
            exchangeRate,
            calculateTotal,
            services: procedures
        })

        setIsBlocking(true)
    }

    const handleChangeProductCurrencyRate = (event, bill_id) => {

        const procedures = procedureData.filter(item => !item.isExcluded)

        // dispatchProducts({
        //     type:billingActions.CHANGE_PRODUCT_CURRENCY_RATE,
        //     value:event.target.value,
        //     bill_id,
        //     exchangeRate,
        //     calculateTotal,
        //     services:procedures
        // })

        dispatchProductsAndServices({
            type: billingActions.CHANGE_PRODUCT_CURRENCY_RATE,
            value: event.target.value,
            bill_id,
            exchangeRate,
            calculateTotal,
            services: procedures
        })

        setIsBlocking(true)
    }


    const handleDeleteProduct = (item) => {
        const { bill_id } = item
        const procedures = procedureData.filter(item => !item.isExcluded)

        // dispatchProducts({
        //     type:billingActions.DELETE_PRODUCT,
        //     bill_id,
        //     exchangeRate,
        //     calculateTotal,
        //     services:procedures
        // })

        dispatchProductsAndServices({
            type: billingActions.DELETE_PRODUCT,
            bill_id,
            exchangeRate,
            calculateTotal,
            services: procedures
        })

        setIsBlocking(true)
    };


    const handleChange = (event) => {
        const { name, value } = event.target;
        setBillState({
            ...billState, [name]: value
        })
        setIsBlocking(true)
    };

    const paidProducts = filteredPayloadProducts(productsPaid).filter(item => !item.isExcluded)
    const editedProducts = filteredPayloadProducts(productsEdited).filter(item => !item.isExcluded)
    const servicesPaid = invoicesPaid.filter(item => !item.isExcluded)
    const servicesEdited = invoicesEdited.filter(item => !item.isExcluded)
    const invoicesAdded = [...servicesPaid, ...paidProducts];
    const editedInvoices = [...servicesEdited, ...editedProducts]
    const removedInvoices = [...filteredPayloadProducts(productsRemoved), ...invoicesRemoved];

    const handleOpenReceipt = (e) => {
        e.preventDefault()
        setIsBlocking(false)
        const { invoice_date, insurance_provider, card_number, account_name, policy_holder } = billState;
        const isPaid = invoicesAdded.length > 0;
        const isEdited = editedInvoices.length > 0;
        const isInvoice = isPaid || isEdited;
        const provider = insurance_provider.split('-')[1];
        const isInsurance = provider === 'Insurance Company'
        const insuranceRequired = isInsurance && policy_holder && card_number;
        const organizationRequired = !isInsurance && account_name
        const in_provider = !isInvoice ? '' : insurance_provider
        const isProvider = (isInvoice && in_provider) || (!in_provider && !isInvoice)
        const isInsuranceField = (isInvoice && insuranceRequired) || (!insuranceRequired && !isInvoice)
        const isOrganization = (isInvoice && organizationRequired) || (!isInvoice && !organizationRequired)

        const isBase = currency.split('-')[1]

        const isNotBase = isBase === 'false'
        const isBaseCurrency = isBase === 'true'

        const requiredData = (isNotBase && exchangeRate) || (isBaseCurrency && !exchangeRate)
        if (invoice_date && isProvider && (isInsuranceField || isOrganization)) {
            setOpenPrint(true);
        } else {
            setSubmitted(true)
        }
    }

    const handleSubmitBill = (event) => {
        event.preventDefault();
        const { memo, insurance_provider, card_number, account_name, policy_holder } = billState;
        setSubmitted(true);
        const isPaid = invoicesAdded.length > 0;
        const isEdited = editedInvoices.length > 0;
        const isInvoice = isPaid || isEdited;
        const prov = insurance_provider.split('-')[0]
        const provider = insurance_provider.split('-')[1];
        const billed_to = insurance_provider.split('-')[2];
        const isInsurance = provider === 'Insurance Company'
        const amount = isInvoice ? 0 : null;

        const isBase = currency.split('-')[1]

        const isNotBase = isBase === 'false'
        const isBaseCurrency = isBase === 'true'

        const currency_id = currency.split('-')[0]
        setIsBlocking(false)
        const isBaseData = isBaseCurrency ?  {is_base_currency:isBase, currency_id:+currency_id,is_converted: is_converted,amount_paid: +amount,
            rate_input_by_cashier: +exchangeRate,amount_paid_in_the_currency: +currencyTotal} : {}
        const isNotBaseData = isNotBase ?  {currency_id:+currency_id,amount_paid_in_the_currency: +currencyTotal, rate_input_by_cashier: +exchangeRate,
            total_amount_in_base_currency: +total_amount,is_base_currency:isBase} : {}

             setIsSubmitted('pending')
            axios.post(`${config.smsUrl}/cbilling/pay_bill`, {
                pay_type: 3,
                visit_id: +visit_id,
                received_by: user_roles_id,
                memo,
                amount_paid: 0,
                bill_mode:"account",
                insurance_provider: isInvoice ?  +prov : '',
                card_number: isInvoice ?  card_number : '',
                billed_to: isInvoice ? billed_to : '',
                account_name: isInvoice ?  (isInsurance ? policy_holder :account_name) : '',
                invoices_paid: invoicesAdded,
                invoices_removed: removedInvoices,
                invoices_edited:editedInvoices,
                ...isBaseData, ...isNotBaseData
               
            }).then((res) => {
                const data = res.data ?? {};
                let receipt_number;
                if (typeof data.receipt_numbers === "string"){
                    receipt_number = data.receipt_numbers ?? '';
                }else {
                    const numbers =  data.receipt_numbers ?? [];
                    receipt_number = numbers.join('-');
                }
                actions.snackbarActions.snackSuccess('Invoice created successfully');
                setIsSubmitted('resolved')
                const url = !isInvoice ? '/paymentList' : `/invoice/${visit_id}/${receipt_number}`
                history.push(url);
            }).catch(err => {
                errorMessages(err, null, actions)
                setIsSubmitted('rejected')
            })

    };



    const handleCloseReceipt = () => {
        setOpenPrint(false);
    };

    const handleCancel = () => {
        setOpenPrint(false);
    }


    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const handleAddService = () => {
        setAddService(true)

    }

    const handleAddProduct = () => {
        setAddProduct(true)
    }

    const handleCloseAddService = () => {
        // setAddService(false)
        handleCloseServiceDialog()
    }

    const handleCloseAddProduct = () => {
        setAddProduct(false)
        handleClosePrescriptionDialog()
    }

    const { patient_name } = state

    const isExcluded = patient_name && (invoicesAdded.length > 0 || editedInvoices.length > 0);

    const isPending = isSubmitted === 'pending';

    return {
        state, billState, openPrint, total_amount, submitted, procedureData, providers, isPending, handleCancel,
        handleDeleteProcedure, handleSubmitBill, handleCloseReceipt, closeSnackbar, products, handleOpenReceipt,
        handleChangeRate, handleChange, handleDeleteProduct, handleChangeProductRate,
        providerProps, openProvider, handleOpenDialog, handleCloseDialog, isExcluded, handleAddProduct,
        handleAddService, addProduct, addService, handleCloseAddService,
        handleCloseAddProduct, departments, currencies, currency, handleChangeCurrencyRate,
        handleChangeExchangeRate, handleChangeCurrency, exchangeRate, currencyTotal,
        handleChangeProductCurrencyRate, renderService, renderPrescription, isBlocking
    }
}