/** @jsxImportSource @emotion/react */
import {jsx, css} from '@emotion/react'
import React from 'react';
import { makeStyles, styled } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import * as colors from "../../../styles/colors"

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    icon: {
        width: '.95rem'
    },
});

const Cell = styled(TableCell)({
    borderBottom: 0,
    padding:'0.2rem',
})

const HeadCell = styled(Cell)({
    fontWeight:700,
    backgroundColor:colors.gray
})

const TotalCell = styled(Cell)({
    fontWeight:700,
})
const FooterCell = styled(TotalCell)({
    borderTop:`1.4px solid ${colors.text00}`,
    paddingTop: `16px !important`,

})

const span = css`
    display: inline-block;
    padding: 0.2rem 0.6rem;
    
`



function InpatientBillRow({row, headData=[]}) {
    const [open, setOpen] = React.useState(true);
    const classes = useRowStyles();

    return (
        <React.Fragment>
            <TableRow className={classes.root}>
                <Cell colSpan={headData.length}>
                    {row.values.length > 0 ? <span><strong>{row.name} <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                    {open ?
                        <KeyboardArrowDownIcon classes={{root: classes.icon}}/> :
                        <KeyboardArrowRightIcon classes={{root: classes.icon}}/>}</IconButton></strong></span>:null}
                </Cell>
            </TableRow>
            {row.values.length > 0 ? row.values.map(item=>{
                return(
                    <TableRow key={item.bill_id}>
                        {headData.map(({key})=>(
                            <Cell key={key}   style={{ paddingBottom: 0, paddingTop: 0 }}>
                                <Collapse in={open} timeout="auto" unmountOnExit>
                                    <span css={[span]}>{item[key]}</span>
                                </Collapse>
                            </Cell>
                        ))}
                    </TableRow>
                )
            }):null}


        </React.Fragment>
    );
}




export {InpatientBillRow,Cell, HeadCell, FooterCell, TotalCell}
