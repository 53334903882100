import React from "react";
import {BoldBorderedCell, BorderedCell} from "../../Utils/Tables/ReportsTable";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faClipboardList } from "@fortawesome/free-solid-svg-icons/faClipboardList";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import { BackButton } from "../../Utils/Buttons/DataExportationButton";
import { useSurgeryMOH } from "./CustomHooks/useSurgeryMOH";
import {MedicalReportFilter} from "../ReportFilter";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../../actions/snackbarActions";
import {connect} from "react-redux";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {Container} from "../../Utils/styledComponents";


const useStyles = makeStyles({
    table: {
      minWidth: 700,
    },
    tableCell:{
      border:'1px solid rgb(90 88 88)',
      padding:'8px'
    }
});

const repeat = (item, times) => {
    return new Array(times).fill(item)
}


const SurgeryMOH = ({actions, snackbars}) => {

    const classes = useStyles();

    const {surgery_reports, state,submitted,
        handleChange, handleSubmit, checkForData, isPending,
        patientType, payment, setPayment, setPatientType} = useSurgeryMOH(actions);
    const {start_date, end_date} = state;

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    // these are for the table heads

    const ageGroups = [{age:'0 - 28days'},{age:'1month - 1Yrs'},{age:'2 - 4Yrs'},
        {age:'5 - 12Yrs'},{age:'13 - 19Yrs'},{age:'20 - 39Yrs'},{age:'40 - 59Yrs'},{age:'60Yrs & Above'}]

    const genders = repeat({male:'M',female:'F'}, ageGroups.length);

    const {openBar, type, message} = snackbars;

    const createNewRow = (element, index) => {
        const item = Object.values(element)[0];
        return (
            <TableRow key={index}>
                <BorderedCell colSpan="3">{Object.keys(element)[0]}</BorderedCell>
                <BoldBorderedCell>{item.a28days['M'] ? item.a28days['M']:''}</BoldBorderedCell>
                <BoldBorderedCell>{item.a28days['F'] ? item.a28days['F']:''}</BoldBorderedCell>
                <BoldBorderedCell>{item.a1month['M'] ? item.a1month['M']:''}</BoldBorderedCell>
                <BoldBorderedCell>{item.a1month['F'] ? item.a1month['F']:''}</BoldBorderedCell>
                <BoldBorderedCell>{item.a2yrs['M'] ? item.a2yrs['M']:''}</BoldBorderedCell>
                <BoldBorderedCell>{item.a2yrs['F'] ? item.a2yrs['F']:''}</BoldBorderedCell>
                <BoldBorderedCell>{item.a5yrs['M'] ? item.a5yrs['M']:''}</BoldBorderedCell>
                <BoldBorderedCell>{item.a5yrs['F'] ? item.a5yrs['F']:''}</BoldBorderedCell>
                <BoldBorderedCell>{item.a13yrs['M'] ? item.a13yrs['M']:''}</BoldBorderedCell>
                <BoldBorderedCell>{item.a13yrs['F'] ? item.a13yrs['F']:''}</BoldBorderedCell>
                <BoldBorderedCell>{item.a20yrs['M'] ? item.a20yrs['M']:''}</BoldBorderedCell>
                <BoldBorderedCell>{item.a20yrs['F'] ? item.a20yrs['F']:''}</BoldBorderedCell>
                <BoldBorderedCell>{item.a40yrs['M'] ? item.a40yrs['M']:''}</BoldBorderedCell>
                <BoldBorderedCell>{item.a40yrs['F'] ? item.a40yrs['F']:''}</BoldBorderedCell>
                <BoldBorderedCell>{item.a60yrs['M'] ? item.a60yrs['M']:''}</BoldBorderedCell>
                <BoldBorderedCell>{item.a60yrs['F'] ? item.a60yrs['F']:''}</BoldBorderedCell>
                <BoldBorderedCell>{item.total}</BoldBorderedCell>
            </TableRow>
        )
    }

    return (
        <div className="journals">
            <PageTitle title='Outpatient Diagnosis'/>
            <SubHeader title="Reports" subTitle='Surgical Procedures'>
                <FontAwesomeIcon icon={faClipboardList}/>
            </SubHeader>
            <Container>
                <div style={{margin: '10px'}}>
                    <BackButton text={'General Reports'} to={'/reports/generalreports'}/>
                </div>
                <div className="mx-2 mt-2">
                    {/*<DateTimeFilter {...{start_date,end_date, handleChange, submitted,handleSubmit,isPending, type:'date'}}/>*/}
                    <MedicalReportFilter {...{
                        payment,
                        setPayment,
                        patientType,
                        setPatientType,
                        handleSubmit,
                        start_date,
                        end_date,
                        handleChange,
                        isPending,
                        submitted,
                        actions,
                        file_name: 'Surgery MOH Report',
                        excelUrl: 'creport/surgery_report_exports',
                        pdfUrl: 'creport/surgery_report_exports',
                        excelParams: {export_type: 2},
                        pdfParams: {export_type: 1},
                    }}/>
                </div>

                <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>

                <TableContainer component={Paper} elevation={0}>
                    <Table className={classes.table}>
                        <TableHead className="table-grey">
                            <TableRow>
                                <BorderedCell rowSpan="2" colSpan="3">
                                    <strong>Category</strong>
                                </BorderedCell>
                                {ageGroups.map((item, index) => (
                                    <BorderedCell key={index} align="center"
                                                  colSpan="2"><strong>{item.age}</strong></BorderedCell>
                                ))}
                                <BorderedCell/>
                            </TableRow>
                            <TableRow align="center">
                                {genders.map((item, index) => (<>
                                    <BorderedCell><strong>{item.male}</strong></BorderedCell>
                                    <BorderedCell><strong>{item.female}</strong></BorderedCell>
                                </>))}
                                <BorderedCell><strong>Total</strong></BorderedCell>
                            </TableRow>
                        </TableHead>

                        {checkForData ?
                            <>
                                {surgery_reports ?
                                    Object.entries(surgery_reports).map(([key, value]) => (
                                        <>
                                            <TableHead className="table-grey">
                                                <TableRow>
                                                    <BorderedCell colSpan="14"><strong>{key}</strong></BorderedCell>
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                {value["actual_surgeries"].map((item, index) => (
                                                    createNewRow(item, index)
                                                ))}
                                            </TableBody>
                                        </>
                                    )) : null}
                            </>
                            :
                            <TableBody>
                                <TableRow>
                                    <BorderedCell align='center' colSpan="20">No data available</BorderedCell>
                                </TableRow>
                            </TableBody>
                        }

                    </Table>
                </TableContainer>
            </Container>

        </div>
    )
}

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(SurgeryMOH);

