import {useEffect, useState} from "react";
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import {dateConvert} from "../../../Utils/ConvertDate";

const useDiagnosesReport = (actions) => {
    const [loading, setLoading] = useState('idle');
    const [diagnosis, setDiagnosis] = useState([]);
    const [state, setState] = useState({start_date:dateConvert(), end_date:dateConvert()})
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const {start_date, end_date, } = state;
    const [without_icd_notes, setWithoutICDNotes] = useState({});
    const [payment, setPayment] = useState({value:1,label:'Cash'})
    const [patientType, setPatientType] = useState({value:1,label:'Out-patient'})

    const errorHandler = (reponse) =>{
        const res = reponse ? reponse : {};
        const value = res.value ? res.value : {};
        const data = value.data ? value.data : [];
        return data
    }

    useEffect(() => {

        setLoading('pending');
        const formData = new FormData();
        formData.append('start_date', start_date);
        formData.append('end_date', end_date);
        formData.append('patient_type', patientType?.value ? patientType?.value :'');
        formData.append('mode_of_payment', payment?.value ? payment?.value :'');
        Promise.allSettled([
            axios.post(`${config.smsUrl}/creport/diagnosis_reports`, formData),
            axios.post(`${config.smsUrl}/creport/notes_diagnoses`, formData)
          ])
          .then(([res1, res4]) => {
            setLoading('success')
            //diagnosis
            const reports = errorHandler(res1);
            setDiagnosis(reports);
            
            // diagnosis notes
            const data = res4.value.data;
            const dt = !data ? {} : data;
            setWithoutICDNotes(dt);
          })
          .catch(err => {
            logoutErrorMessage(err, null, actions);
            setLoading('error')
          });
    }, [patientType, payment]);

    const handleChange = (event) =>{
        const {name, value} = event.target;
        setState({...state,[name]:value});
    }

    const handleSubmit = (event) =>{
        event.preventDefault();
        setSubmitted(true);
        if(start_date && end_date){
            setLoading('pending');
            setIsSubmitted('pending')
            const formData = new FormData();
            formData.append('start_date', start_date);
            formData.append('end_date', end_date);
            formData.append('patient_type', patientType?.value ? patientType?.value :'');
            formData.append('mode_of_payment', payment?.value ? payment?.value :'');
            Promise.allSettled([
                axios.post(`${config.smsUrl}/creport/diagnosis_reports`, formData),
                axios.post(`${config.smsUrl}/creport/notes_diagnoses`, formData)
              ])
              .then(([res1, res4]) => {
                setIsSubmitted('rejected')
                setLoading('success')
                //diagnosis
                const reports = errorHandler(res1);
                setDiagnosis(reports);

                // diagnosis notes
                const data = res4.value.data;
                const dt = !data ? {} : data;
                setWithoutICDNotes(dt);

              })
              .catch(err => {
                logoutErrorMessage(err, null, actions);
                setLoading('error')
                setIsSubmitted('rejected')
              });
        }
    }

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const isLoading = loading === 'pending';
    const isSuccess = loading === 'success';
    const isError = loading === 'error';
    
    const isPending = isSubmitted === 'pending';
    const isResolved = isSubmitted === 'resolved';
    const isRejected = loading === 'rejected';

    // for the diagnosis notes
    const handleChangeNewPage = (page) => {
      setActiveTablePage(page);
    }

    // keep track of the page numbers in the table
    const [activeTablePage, setActiveTablePage] = useState(1);

    return {diagnosis, closeSnackbar,  isLoading, isSuccess, isError, state,submitted,
        handleChange, handleSubmit, isPending, isRejected, isResolved,
        handleChangeNewPage, activeTablePage, without_icd_notes,
        payment, patientType, setPatientType, setPayment
      }
}
export {useDiagnosesReport}