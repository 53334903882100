import React from "react";
import PageTitle from "../Utils/smsTitle";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faNotesMedical} from "@fortawesome/free-solid-svg-icons/faNotesMedical";
import CustomTable from "../Utils/Tables/CustomTable";
import FilterResults from "react-filter-search";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {Link} from "react-router-dom";
import Label from "../Utils/FormInputs/Label";
import {bindActionCreators} from "redux";
import { CustomSelect } from "../Utils/FormInputs/SelectInput";
import { ErrorMessage } from "../Utils/styledComponents";
import Form from "react-bootstrap/Form";
import { Tooltip } from "@material-ui/core";
import TextField from "../Utils/FormInputs/TextField";
import { IconButton } from "@material-ui/core";
import {connect} from "react-redux";
import * as snackbarActions from "../../actions/snackbarActions";
import { StyledRemoveIcon } from "../DoctorsModule/doctorStyles";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import {useTheme} from "@material-ui/core/styles";
import SelectInput from "../Utils/FormInputs/SelectInput";


// component to show the form to update an item
export const EditConsumable = ({current_item, handleItemUpdate, handleChangeQuantity, submitted, service_type, handleChangeUnit}) => {

    return (
        <>
            <table className="table table-bordered billing-invoice-table procedure-table" data-testid="edit-consumable">
                <thead>
                    <tr>
                        <th><strong>Product Name</strong></th>
                        <th><strong>Quantity</strong></th>
                        {service_type === 'Lab' ? <th><strong>Unit</strong></th>: null}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{current_item.product_name}</td>
                        <td>
                            <TextField onChange={(e)=>handleChangeQuantity(e)} value={current_item.quantity} type='number'
                                    submitted={submitted} name='quantity' placeholder={`Quantity`} data-testid="quantity-edit"/>
                            {(submitted && !current_item.quantity) &&
                                <ErrorMessage>Quantity is required</ErrorMessage>}
                        </td>
                        {service_type === 'Lab' ?
                             <td>
                                <SelectInput name='unit' value={current_item.unit} onChange={(e)=>handleChangeUnit(e)} data-testid="unit-edit"
                                 options={[{value:'Litres', text:'Litres'},{value:'Mls', text:'Mls'}, {value:'Strip', text:'Strip'}]} defaultOption="select unit..."/>
                                 {(submitted && !current_item.unit) &&
                                <ErrorMessage>Unit is required</ErrorMessage>}
                            </td>:null}
                    </tr>
                </tbody>
            </table>
            <button type="submit" className="btn btn-sm sms-btn px-4" id="submit-btn" onClick={handleItemUpdate}
            data-testid="update-button">Update</button>
        </>
    )
}

// component to display the table items for the items
const ConsumablesTable = ({item_type, new_items=[],
    submitted, handleChangeItem, 
    getItems, setItemDetails, 
    handleAddRow, handleRemoveRow,service_type}) => (
    <>
        <table className='table table-sm table-bordered billing-invoice-table procedure-table' data-testid="consumables-table">
            <thead>
                <tr>
                    <th><strong>{`${item_type}`} Name</strong></th>
                    <th><strong>Quantity</strong></th>
                    {service_type === 'Lab' ? <th><strong>Unit</strong></th>: null}
                    <th><strong>Action</strong></th>
                </tr>
            </thead>
            <tbody>
                {new_items.map((item, index)=>{
                    return(
                        <tr key={item.uuid}>
                            <td>
                                <>
                                    <TextField submitted={submitted} value={item_type === 'Service' ? item.service_name : item.product_name}
                                        type="text" name={item_type === 'Service' ? 'service_name' : 'product_name'}
                                        className={`form__control `} data-testid={"item-name-"+index}
                                        onChange={(e) => getItems(e, index, item)} id={"item-name-"+index}/>
                                        {item.showItems && 
                                        <Form.Control as="select" className="drug-ul prescription-ul" multiple data-testid="item-name">
                                            {item_type === 'Service' ?
                                                item.service_options.map((val) => (
                                                    <option key={val.service_id} value={val.service_id} className="drug-li"
                                                        onClick={() => setItemDetails(val, index, item)}>{val.service_name}</option>
                                                ))
                                                :
                                            item.product_options.map((val) => (
                                                <option key={val.product_id} value={val.product_id} className="drug-li"
                                                    onClick={() => setItemDetails(val, index, item)}>{val.product_name}</option>
                                            ))}
                                        </Form.Control>}
                                    {(submitted && (item_type === 'Service' ? !item.service_name : !item.product_name)) &&
                                        <ErrorMessage>Product name is required</ErrorMessage>}
                                </>
                            </td>
                            <td>
                                <TextField onChange={(e)=>handleChangeItem(e, index, item)} value={item.quantity} type='number'
                                    data-testid={"quantity-"+index} submitted={submitted} name='quantity' placeholder={`Quantity`}/>
                                {(submitted && !item.quantity) &&
                                    <ErrorMessage>Quantity is required</ErrorMessage>}
                            </td>
                            {service_type === 'Lab' ?
                             <td>
                                <SelectInput name='unit' value={item.unit} onChange={(e)=>handleChangeItem(e, index, item)} data-testid={'unit-'+index}
                                 options={[{value:'Micro litres', text:'Micro litres'},{value:'Mls', text:'Mls'}, {value:'Strip', text:'Strip'}]} defaultOption="select unit..."/>
                                  {(submitted && !item.unit) &&
                                <ErrorMessage>Unit is required</ErrorMessage>}
                            </td>:null}
                            <td>
                                <Tooltip title='Remove'>
                                    <IconButton onClick={()=>handleRemoveRow(item.uuid)}>
                                        <StyledRemoveIcon/>
                                    </IconButton>
                                </Tooltip>
                            </td>
                        </tr>
                    )
                })}
                <tr>
                    <td colSpan={3}>
                        <button type='button' onClick={handleAddRow} className='btn btn-sm sms-gray-btn' data-testid="add-another-line-button">Add another line</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </>
)


export const ServiceConsumables = ({
    product_consumables, handleAddProductRow, handleRemoveProductRow, handleChangeProduct, getProductOptions,setProductDetails,
    submitted, service_type}) => {
    // products first -> quantity only
    return (
        <>
            {/** Table for the product consumables */}    
            <Label name="Products" className="label-admission-prescription"/>
            <ConsumablesTable item_type={'Product'}  new_items={product_consumables} submitted={submitted}
            handleAddRow={handleAddProductRow} handleRemoveRow={handleRemoveProductRow} handleChangeItem={handleChangeProduct}
            getItems={getProductOptions} setItemDetails={setProductDetails} service_type={service_type.value??service_type}
            />
        </>
    )

}

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceConsumables);
