import React from "react";
import Form from "react-bootstrap/Form";
import {useTheme} from "@material-ui/core/styles";


const Textarea = ({name,value,onChange,id,submitted=false, ...props}) =>{
  const theme = useTheme();
  const them = !theme ? {} : theme;
  const palette = !them.palette ? {} : them.palette;
  return(
    <Form.Control as="textarea" style={{border:submitted && !value ? '1px solid red':''}} id={id}
     name={name} value={value} onChange={onChange}  className={` form__control ${palette.type === "dark" && 'form-control-dark'}`} {...props}/>
  )

};

const MIN_TEXTAREA_HEIGHT = 45
export function ResizableTextarea({value, id, submitted, rows='8', ...props}){
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    const textareaRef = React.useRef(null);

  React.useLayoutEffect(() => {
    // Reset height - important to shrink on delete
    textareaRef.current.style.height = "inherit";
    // Set height
    textareaRef.current.style.height = `${Math.max(
        textareaRef.current.scrollHeight,
        MIN_TEXTAREA_HEIGHT
    )}px`;
  }, [value]);

  return(
      <Form.Control as="textarea"
          id={id}
          data-testid={id}
          ref={textareaRef}
          rows={rows}
          style={{
            minHeight: MIN_TEXTAREA_HEIGHT,
            resize: "none",
            border: submitted && !value ? `1px solid red`:''
          }}
          value={value}
          className={` form__control ${palette.type === "dark" && 'form-control-dark'}`}
          {...props}
      />
  )
}

export default Textarea;
