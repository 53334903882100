import { dateConvert, convDate } from "../../../../Utils/ConvertDate";
import { useState, useEffect } from "react";
import { convertChecksToString } from "./useAnteNatalPatientForm";
import { logoutErrorMessage } from "../../../../../Helpers/ErrorMessages";
import { convertTableToJSON, convertJSONToTable } from "../PatientFormScreens/AnteNatalTables";
import axios from "axios";
import { config } from "../../../../../Helpers/env";

export const useFirstScreenProps = (actions) => {

    const [openSkip, setOpenSkip] = useState(false);

    // Profile and Support Information
    const clientCategories = [
        { label: 'N', value: "N" },
        { label: 'R', value: "R" },
        { label: 'F', value: "F" }
    ];

    const [client_category, setClientCategories] = useState([]);

    const handleClientCategoryChange = (value) => {
        setClientCategories(value);
    }

    // functions for the anc visit options
    const anc_visit_options = [
        { label: '1', value: "1" },
        { label: '2', value: "2" },
        { label: '3', value: "3" },
        { label: '4', value: "4" },
        { label: '4+', value: "4+" }
    ];

    const [anc_visit, setANCVisits] = useState([]);

    const handleANCVisitChange = (value) => {
        setANCVisits(value);
        if (parseInt(value['label']) > 1) {
            setOpenSkip(true);
        }
    }

    // close the dialog box asking to skip to the third screen
    const handleCloseSkip = () => {
        setOpenSkip(false);
    }
    
    // function for ANC 1 Timing
    const anc1timings = [
        { label: 'Yes', value: "yes" },
        { label: 'No', value: "no" },
    ];

    // function for ANC 1 Timing
    const amount_mens = [
        { label: 'Heavy', value: "Heavy" },
        { label: 'Normal', value: "Normal" },
    ];

    const [anc1_timing, setANC1Timing] = useState([]);

    const handleANC1Timing = (value) => {
        setANC1Timing(value);
    };

    const [expected_date_delivery, setExpectedDate] = useState(dateConvert());

    const handleExpectedDate = (event) => {
        setExpectedDate(event.target.value);
    }

    // Last Normal Menstruation Period
    const [lnmp, setLMNP] = useState(dateConvert());

    const handleLMNPChange = (event) => {
        setLMNP(event.target.value);
    }

    const [gestational_age, setGestationalAge] = useState(0);

    const handleGestationalAgeChange = () => {
        // To calculate the time difference of two dates
        let today = new Date(); // current anc visit date
        let lm = new Date(lnmp);
        var Difference_In_Time = today.getTime() - lm.getTime();
            
        // To calculate the no. of days between two dates
        var Difference_In_Days = Math.ceil(Difference_In_Time / (1000 * 3600 * 24));
        setGestationalAge(Difference_In_Days);

    }

    useEffect(() => {
        handleGestationalAgeChange();
    }, [lnmp]);

    const [inr_no, setINRNo] = useState(0);
    const handleINRNoChange = (event) => {
        setINRNo(event.target.value)
    }

    const [muac, setMUAC] = useState(0);
    const handleMUACChange = (event) => {
        setMUAC(event.target.value)
    }

    const [prev_obstetric_history, setPrevObsHistory] = useState([]);  // will be an array of dictionaries

    const handleObsChange = (event, index, dropdown='') => {
        if (dropdown !== '') {
            const arr = prev_obstetric_history.map((item, idx) => {
                if (idx === index) {
                    return {...item, [dropdown]: event};
                }
                return item;
            })
            setPrevObsHistory(arr);
        } else {
            const {name, value} = event.target;
            const arr = prev_obstetric_history.map((item, idx) => {
                if (idx === index) {
                    return {...item, [name]: value};
                }
                return item;
            })
            setPrevObsHistory(arr);
        }
    }

    // add hooks to handle the actual number of previous preganancies
    const [prev_pregs, setPrevPregs] = useState(0);
    const [gravida, setGravida] = useState(0);
    const handleGravidaChange = (event) => {
        const {value} = event.target;
        setGravida(value);

        if ((value > 1) && (prev_pregs < value)) {
            let prev_arr = [];
            if (prev_obstetric_history.length === 0) {                
                for (var x = 1; x <= (value-1); x++) {
                    const preg = {
                        pregnancy_number: x, gravida_id:'', year:0,
                        abortion_below_twelve_weeks: 0, abortion_above_twelve_weeks : 0,
                        pre_mature: '' , full_term: '', type_of_delivery: [], place_of_delivery: '', third_stage: '', puer_perium:[],
                        alive_sb_nnd : '', sex : '', child_birth_weight: 0, immun: '', health_condition: '',
                    }
                    prev_arr.push(preg);
                }
                setPrevObsHistory(prev_arr);
            } 
            else {
                for (var x = 1; x <= (value-1-(prev_obstetric_history.length)); x++) {
                    const preg_no = (prev_obstetric_history.length) + x;
                    const preg = {
                        pregnancy_number: preg_no, gravida_id : '', year:0,
                        abortion_below_twelve_weeks: 0, abortion_above_twelve_weeks : 0,
                        pre_mature: '' , full_term: '', type_of_delivery: [], place_of_delivery: '', third_stage: '', puer_perium:[],
                        alive_sb_nnd : '', sex : '', child_birth_weight: 0, immun: '', health_condition: '',
                    }
                    prev_arr.push(preg);
                }
                setPrevObsHistory([...prev_obstetric_history, ...prev_arr]);
            }

        } else if ((value >= 0) && (prev_pregs >= value)) {

            let prev_arr = [];
            let ctr = 1;
            // there are rows
            for (var x = 0; x < prev_obstetric_history.length; x++) {
                ctr = ctr+1;
                const item = prev_obstetric_history[x];
                if (ctr > value) {
                    // to delete
                    if (item['gravida_id'] !== '') {
                        // make delete call
                        const formData = new FormData();
                        formData.append('gravida_id', item['gravida_id']);
                        axios.post(`${config.smsUrl}/antenatal/deactivate_antenatal_gravida`, formData).then(res => {
                            const data = res.data;
                            const dt = !data ? {} : data;
                        }).catch(err => {
                           logoutErrorMessage(err,null,actions)
                        })
                    }
                } else {
                    prev_arr.push(item);
                }
            }

            setPrevObsHistory(prev_arr);
        }

        const pp = value > 0 ? (value-1) : 0;
        setPrevPregs(pp);
    }

    const [parity, setParity] = useState(0);
    const handleParityChange = (event) => {
        setParity(event.target.value)
    }

    // hooks for menstrual and contraceptive history
    const [amount_menstrual, setAmountMenstrual] = useState([]);
    const mens_amounts = [
        { label: 'Heavy', value: "Heavy" },
        { label: 'Light', value: "Light" },
        { label: 'Normal', value: "Normal" },
    ];

    const handleAmountMenstrual = (value) => {
        setAmountMenstrual(value)
    }

    const [menstrual_history, setMenstrualHistory] = useState({
        family_plan:'', why_disconnected:'', why_never_used:''
    });

    const handleMenstrualHistory = (event) => {
        const {name, value} = event.target;
        setMenstrualHistory({...menstrual_history, [name]:value});
    }
    
    const [length_of_menses, setMenstrualLength] = useState(0);
    const handleMenstrualLength = (event) => {
        setMenstrualLength(event.target.value);
    }

    // hooks for the medical illness
    const [medical_illness, setMedicalIllness] = useState(
        {cardiac_disease:false, kidney_disease:false, hypertension:false, tb:false, asthma:false, sti:false, 
            sickle_cell_disease:false, epilepsy:false, diabetes:false, other_medical_illness:false}
    );

    const [other_medicals, setOtherMedicals] = useState('');

    const handleOtherMedicals = (event) => {
        setOtherMedicals(event.target.value);
    }

    const handleMedicalIllness = (event) => {
        const {name, checked} = event.target;
        setMedicalIllness({...medical_illness, [name]:checked});
    }

    // hooks for the surgery illness
    const [surgery_meds_checks, setSurgeryMedsChecks] = useState({
        surgery_operations:false, blood_transfusion_check:false, arvs:false, other_meds_check:false, fractures:false
    });

    const handleSurgeryMedsChecks = (event) => {
        const {name, checked} = event.target;
        setSurgeryMedsChecks({...surgery_meds_checks, [name]:checked});
    };

    const [surgery_meds_texts, setSurgeryMedsTexts] = useState({blood_transfusion_why_text:'', other_meds_text:''});

    const handleSurgeryMedsTexts = (event) => {
        const {name, value} = event.target;
        setSurgeryMedsTexts({...surgery_meds_texts, [name]:value});
    }
    
    // hooks for the sgbv 
    const sgbv_specifics_options = [
        { label: 'Physical', value: "Physical" },
        { label: 'Psycho-social', value: "Psycho-social" },
    ];

    const [sgbv_risk, setSGBVRisk] = useState({sgbv_yes:false, sgbv_no:false, sgbv_others:'', sgbv_husband_health:''});

    const [sgbv_sepcific, setSGBVSpecific] = useState([]);
    const handleSGBVSpecific = (value) => {
        setSGBVSpecific(value);
    }

    const handleSGBVRisk = (event) => {
        const {name} = event.target;

        if (name === 'sgbv_yes')  {
            const {checked} = event.target;
            if (checked === true && sgbv_risk['sgbv_no'] === true) {
                setSGBVRisk({...sgbv_risk, [name]:checked, 'sgbv_no':!checked});  
            } else  {
                setSGBVRisk({...sgbv_risk, [name]:checked});  
            }
        } else if (name === 'sgbv_no') {
            const {checked} = event.target;
            if (checked === true && sgbv_risk['sgbv_yes'] === true) {
                setSGBVRisk({...sgbv_risk, [name]:checked, 'sgbv_yes':!checked});  
            } else  {
                setSGBVRisk({...sgbv_risk, [name]:checked});  
            }
        } else {
            const {value} = event.target;
            setSGBVRisk({...sgbv_risk, [name]:value});
        }

    }

    // hook for the obs/gyn
    const [obs_gyn, setOBSGYN] = useState(
        {dc:false, ectopic_preg:false, caesarean_section:false, vacuur_extraction:false,  
            retained_placenta:false, pph:false, uterus_operation:false, cervical_circlage:false}
    );

    const handleOBSGYN = (event) => {
        const {name, checked} = event.target;
        setOBSGYN({...obs_gyn, [name]:checked});
    }


    // hooks for family and social history
    const [family_social_history, setFamilySocialHistory] = useState({
        diabetes_family:false, hypertension_family:false, sickle_cell_disease_family: false, 
        epilepsy_family:false, twins:false, others_family:false, smoking_alcohol:false 
    });

    const handleFamilySocialHistory = (event) => {
        const {name, checked} = event.target;
        setFamilySocialHistory({...family_social_history, [name]:checked});
    }

    const handleResetFirstScreen = () => {
        // Profile and Support info
        // first part
        setANCVisits([]);
        setClientCategories([]);
        setANC1Timing([]);
        setLMNP(dateConvert());
        setExpectedDate(dateConvert());

        // second part
        setGestationalAge(0);
        setINRNo(0);
        setMUAC(0);
        setGravida(0);
        setParity(0);

        // Previous obsterics table
        setPrevObsHistory([]);

        // Previous Illness
        // medical and surgery
        setMedicalIllness( {cardiac_disease:false, kidney_disease:false, hypertension:false, tb:false, asthma:false, sti:false, 
            sickle_cell_disease:false, epilepsy:false, diabetes:false, other_medical_illness:false});
        setOtherMedicals('');
        setSurgeryMedsChecks(
            {
                surgery_operations:false, blood_transfusion_check:false, arvs:false, other_meds_check:false, fractures:false
            }
        )
        setSurgeryMedsTexts(
            {blood_transfusion_why_text:'', other_meds_text:''}
        )

        // risk of sgbv
        setSGBVRisk(
            {sgbv_yes:false, sgbv_no:false, sgbv_others:'', sgbv_husband_health:''}
        )
        setSGBVSpecific([]);

        // obs/gyn
        setOBSGYN(
            {dc:false, ectopic_preg:false, caesarean_section:false, vacuur_extraction:false,  
                retained_placenta:false, pph:false, uterus_operation:false, cervical_circlage:false}
        )

        // Family and Social history
        setFamilySocialHistory(
            {
                diabetes_family:false, hypertension_family:false, sickle_cell_disease_family: false, 
                epilepsy_family:false, twins:false, others_family:false, smoking_alcohol:false 
            }
        );

        // Menstrual and Contraceptive History
        setMenstrualLength(0);
        setAmountMenstrual([]);
        setMenstrualHistory({
            family_plan:'', why_disconnected:'', why_never_used:''
        });


    }

    const assignFirstScreenProps = (item) => {

        // Profile and Support
        // First part
        setClientCategories(item['client_category'] ? {value:item['client_category'], label:item['client_category']} : []);
        setANC1Timing(item['anc_timing'] ? {value:item['anc_timing'] , label:item['anc_timing']} : []);
        setANCVisits(item['anc_visit'] ? {value:item['anc_visit'], label:item['anc_visit']} : []);

        setExpectedDate(item.expected_date_of_delivery ? convDate(item.expected_date_of_delivery) : dateConvert());

        setLMNP(item.last_normla_mps ? convDate(item.last_normla_mps) : dateConvert());

        // second part
        setMUAC(item.mauc_cm_and_color_code ? item.mauc_cm_and_color_code : 0);
        setGestationalAge(item.gestational_age_in_days ? item.gestational_age_in_days : 0);
        setINRNo(item.inr_no ? item.inr_no : 0);
        setGravida(item.gravida ? item.gravida : 0);
        setParity(item.parity ? item.parity : 0);


        // previous obstetrics table
        const gravs = item['gravida_info'] ? item['gravida_info'] : [];
        setPrevObsHistory(convertJSONToTable(gravs, item['isStart'], 'gravida'));
        setPrevPregs(gravs.length);


        // get the patient data
        const chronics = item['chronic_illness'] ? item['chronic_illness'] : [];
        let other_surgery_txt = '';
        let blood_trans_txt = '';

        chronics.forEach(element => {
            const pat_data = Object.values(element)[0];

            if (element['family_social_history']) {
                setFamilySocialHistory(
                    {
                        diabetes_family:pat_data.includes('diabetes_family'), hypertension_family:pat_data.includes('hypertension_family'), 
                        sickle_cell_disease_family: pat_data.includes('sickle_cell_disease_family'), epilepsy_family:pat_data.includes('epilepsy_family'), 
                        twins:pat_data.includes('twins'), others_family:pat_data.includes('others_family'), smoking_alcohol:pat_data.includes('smoking_alcohol') 
                    }
                );        
            }
            if (element['surgery_medications']) {
                setSurgeryMedsChecks(
                    {
                        surgery_operations:pat_data.includes('surgery_operations'), blood_transfusion_check:pat_data.includes('blood_transfusion_check'), 
                        arvs:pat_data.includes('arvs'), other_meds_check: pat_data.includes('other_meds_check'), fractures:pat_data.includes('fractures')
                    }
                )
            } 
            if (element['medical_illness']) {
                setMedicalIllness( {cardiac_disease:pat_data.includes('cardiac_disease'), kidney_disease:pat_data.includes('kidney_disease'), hypertension:pat_data.includes('hypertension'), 
                tb:pat_data.includes('tb'), asthma:pat_data.includes('asthma'), sti: pat_data.includes('sti'), diabetes:pat_data.includes('diabetes'),
                sickle_cell_disease: pat_data.includes('sickle_cell_disease'), epilepsy: pat_data.includes('epilepsy'),  other_medical_illness:pat_data.includes('other_medical_illness')});    
            }
            if (element['other_medicals']) {
                const othr_mds_txt = element['other_medicals'];
                setOtherMedicals(othr_mds_txt);
            }
            if (element['other_surgery_meds_text']) {
                other_surgery_txt  = element['other_surgery_meds_text'];
            } 
            if (element['blood_transfusion_why_text']) {
                blood_trans_txt  = element['blood_transfusion_why_text'];
            }
        });

        setSurgeryMedsTexts(
            { other_meds_text: other_surgery_txt, blood_transfusion_why_text: blood_trans_txt}
        )

        // Menstrual and Contraceptive History
        setMenstrualLength(item.length_of_menses ? item.length_of_menses : 0);
        setAmountMenstrual(item.amount ? {'label': item.amount, 'value': item.amount} : []);
        setMenstrualHistory({
            family_plan: item.family_planning_used ? item.family_planning_used : '', 
            why_disconnected: item.when_why_disconnected ? item.when_why_disconnected : '',
            why_never_used: item.never_used_why ? item.never_used_why : ''
        });
 
        
        // risk of sgbv
        const sg_rsk = item.sgbv_risk[0] ? item.sgbv_risk[0] : {};
        const check_sg = sg_rsk['is_sgbv'] ?  
                        sg_rsk['is_sgbv'].includes('yes') ? true : false : false;
        const sgbv_sepcific = sg_rsk['specific_sgbv'] ?  sg_rsk['specific_sgbv'].includes('Physical') ? 'Physical' : '' : sg_rsk['specific_sgbv'].includes('Psycho-social') ? 'Psycho-social' : '';
        setSGBVRisk(
            {sgbv_yes: check_sg, 
            sgbv_no: !check_sg, 
            sgbv_others: sg_rsk['others'] ? sg_rsk['others'] : '', 
            sgbv_husband_health: item.husband_partner_health ? item.husband_partner_health : ''}
        )

        setSGBVSpecific(sgbv_sepcific !== '' ? {label: sgbv_sepcific , value: sgbv_sepcific} : []);
        
        // obs / gyn
        const obz = item['obs_gyn'] ? item['obs_gyn'] : '';
        setOBSGYN(
            {dc: obz.includes('dc'), ectopic_preg:obz.includes('ectopic_preg'), caesarean_section:obz.includes('caesarean_section'), 
            vacuur_extraction:obz.includes('vacuur_extraction'), retained_placenta:obz.includes('retained_placenta'), pph:obz.includes('pph'), 
            uterus_operation:obz.includes('uterus_operation'), cervical_circlage:obz.includes('cervical_circlage')}
        )

    }

    const getFirstScreenValues = () => {

        const obs_gyn_string = convertChecksToString(obs_gyn);
        const sgbv_obj = [{
                is_sgbv : sgbv_risk['sgbv_no'] ? 'no' : 'yes',
                specific_sgbv : sgbv_sepcific['value'] ? sgbv_sepcific['value'] : '',
                others: sgbv_risk['sgbv_others'] ? sgbv_risk['sgbv_others'] : ''
            }];

        return {
            // Profile and Support Info
            // first part
            "anc_visit": anc_visit['value'] ? anc_visit['value'] : '', 
            "current_anc_visit_date" : dateConvert(), 
            "anc_timing":anc1_timing['value'] ? anc1_timing['value'] : '', 
            "client_category": client_category['value'] ? client_category['value'] : '', 
            "last_normla_mps" : lnmp, 
            "expected_date_of_delivery" : expected_date_delivery, 
            
            // second part
            "gestational_age_in_days" : gestational_age, 
            "inr_no" : inr_no, 
            "mauc_cm_and_color_code" : muac, 
            "gravida" : gravida, 
            "parity" : parity, 


            //emtct code:
            // previous obstetrics table
            'gravida_info' : convertTableToJSON(prev_obstetric_history, 'gravida'),

            // Previous Illness
            // Medical + Surgery + Family + Social History
            'first_patient_data': {
              //  'chronic_illness' : [],
                'chronic_illness': [
                    {'family_social_history' : convertChecksToString(family_social_history)},
                    {'surgery_medications' : convertChecksToString(surgery_meds_checks)},
                    {'medical_illness': convertChecksToString(medical_illness)},
                    {other_medicals},
                    {'other_surgery_meds_text': surgery_meds_texts['other_meds_text']},
                    {'blood_transfusion_why_text' : surgery_meds_texts['blood_transfusion_why_text']},
                ]
            },

            // risk of sgbv
            'sgbv_risk' : sgbv_obj,
            'husband_partner_health' : sgbv_risk['sgbv_husband_health'],
           
            // obs / gyn
            'obs_gyn' : obs_gyn_string,

            // Menstrual and Contraceptive History
            length_of_menses,
            'amount' : amount_menstrual['value'] ? amount_menstrual['value'] : '',
            'when_why_disconnected' : menstrual_history['why_disconnected'],
            'never_used_why' : menstrual_history['why_never_used'],
            'family_planning_used' : menstrual_history['family_plan'],

        }
    }

    const first_screen_props = {
        clientCategories, client_category, handleClientCategoryChange,
        anc_visit, anc_visit_options, handleANCVisitChange,
        anc1_timing, anc1timings, handleANC1Timing,
        expected_date_delivery, handleExpectedDate,
        gestational_age, assignFirstScreenProps,
        lnmp, handleLMNPChange,  handleCloseSkip, openSkip,
        inr_no, handleINRNoChange,
        muac, handleMUACChange,
        gravida, handleGravidaChange, length_of_menses, handleMenstrualLength,
        parity, handleParityChange, mens_amounts, amount_menstrual, handleAmountMenstrual,
        prev_pregs, menstrual_history, 
        prev_obstetric_history, handleObsChange,
        amount_mens, handleMenstrualHistory,
        medical_illness, handleOtherMedicals, other_medicals, handleMedicalIllness, sgbv_specifics_options,
        family_social_history, handleFamilySocialHistory,
        surgery_meds_texts, surgery_meds_checks, handleSurgeryMedsChecks, handleSurgeryMedsTexts,
        sgbv_risk, handleSGBVRisk, sgbv_sepcific, handleSGBVSpecific, handleOBSGYN, obs_gyn
    }

    return { first_screen_props, handleResetFirstScreen, assignFirstScreenProps, getFirstScreenValues }
}