import { useState, useEffect } from "react";
import { dateConvert, convDate } from "../../../../Utils/ConvertDate";
import { convertChecksToString } from "./useAnteNatalPatientForm";
import axios from "axios";
import { config } from "../../../../../Helpers/env";
import { logoutErrorMessage } from "../../../../../Helpers/ErrorMessages";
import { FormLabel } from "../../../../Utils/FormInputs/Label";

export const useSecondScreenProps = (patient_blood, visit_id, actions) => {
   
    const [currentTriageData, setCurrentTriageData] = useState({});
    useEffect(() => {
        const formData = new FormData();
        formData.append('visit_id', visit_id)
        axios.post(`${config.smsUrl}/cmedicationround/inpatient_triage_metrics`, formData).then(res => {
            const data = res.data;
            const dt = data ? data : [];
            const tri = dt.length > 0 ?  dt[0]['data'] : [];
            let tri_values = {};
            tri.forEach(element => {
                if (element['metric_name'] === 'Urine') {
                    tri_values['tri_urine'] = element['metric_value'];
                }
                if (element['metric_name'] === 'Blood Pressure') {
                    tri_values['tri_bp'] = element['metric_value'];
                }
                if (element['metric_name'] === 'Weight') {
                    tri_values['tri_weight'] = element['metric_value'];
                }
            });
            setCurrentTriageData(tri_values);
        }).catch(err => {
           logoutErrorMessage(err, null, actions)
        })
    }, [])

    const emtct_codes = [
        { label: (
            <>
            <FormLabel  name={"C"} toolTip={"Counseled or given information but declined HIV testing"}/>
            </>
        ), 
        value: "C" },
        { label:  (
            <>
            <FormLabel  name={"TR"} toolTip={"Tested results given, client tested HIV Negative"}/>
            </>
        ), 
        value: "TR" },
        { label:  (
            <>
            <FormLabel  name={"TRR"} toolTip={"Tested results given, client tested HIV Positive"}/>
            </>
        ), 
        value: "TRR" },
        { label:  (
            <>
            <FormLabel  name={"TRRDM"} toolTip={"for those who received the ARVs for PMTCT"}/>
            </>
        ), 
        value: "TRRDM" },
        { label:  (
            <>
            <FormLabel  name={"TRK"} toolTip={"new client comes in with documented evidence of HIV test results for HIV negative client."}/>
            </>
        ), 
        value: "TRK" },
        { label:  (
            <>
            <FormLabel  name={"TRRK"} toolTip={"new client comes in with documented evidence of HIV test results for positive"}/>
            </>
        ), 
        value: "TRRK" },
    ]

    // eMTCT status for Woman
    const [womanInitialResult, setWomanInitialResult] = useState([]);

    const handleInitialResultChange = (value) => {
        setWomanInitialResult(value);
    } 

    // function for STK  Given
    const yes_nos = [
        { label: 'Yes', value: "yes" },
        { label: 'No', value: "no" },
    ];

    const [stk_given, setSTKGiven] = useState([]);

    const handleSTKGiven = (value) => {
        setSTKGiven(value);
    }

    // eMTCT status for man

    const [partnerEMTCT, setPartnerEMTCT] = useState({partner_age:0, facility:''});
    const handleSetPartnerEMTCT = (event) => {
        const {name, value} = event.target;
        setPartnerEMTCT({...partnerEMTCT,[name]:value});
    }

    const [partner_stk_result, setPartnerSTKResult] = useState([]);

    const handlePartnerSTKResult = (value) => {
        setPartnerSTKResult(value);
    }

    const [yes_no_linked_art, setLinkedArt] = useState([]);

    const handleYesNoLinkedArt = (value) => {
        setLinkedArt(value);
    }
    
    const [tfv, setTFV] = useState('');
    const handleTFV = (event) => {
        setTFV(event.target.value);
    }

    const [where_pre_art_no, setWherePreArtNo] = useState({where: '', pre_art_no:0, art_no:0});
        
    const handleWherePreArtNoChange = (event) => {
        const {name, value} = event.target;
        setWherePreArtNo({...where_pre_art_no,[name]:value});
    }

    const [risk_assess, setRiskAssess] = useState([]);

    const risk_assessments = [
        { label: 'High', value: "High" },
        { label: 'Low', value: "Low" },
    ]

    const handleRiskAssessment = (value) => {
        setRiskAssess(value);
    }

    // family planning

    const [family_plan ,setFamilyPlan] = useState([]);

    const familyplannings = [
        { label: 'C (Counseled)', value: "C (Counseled)" },
        { label: 'C/D (Dual protection method and provide condoms)', value: "C/D (Dual protection method and provide condoms)" },
    ]

    const handleFamilyPlanningChange = (value) => {
        setFamilyPlan(value)
    }

    const infant_feeding_options = [
        { label: 'For Exclusive breast feeding', value: 'For Exclusive breast feeding' },
        { label: 'Replacement Feeding', value: 'Replacement Feeding' },
        { label: 'Mixed Feeding', value: 'Mixed Feeding' },
        { label: 'Others', value: "Others" },
    ]

    const [infant_feeding, setInfantFeeding] = useState([]);
    const handleInfantFeeding = (value) => {
        setInfantFeeding(value);
    }

    const [tb_status, setTBStatus] = useState([]);

    const handleTBStatusChange = (value) => {
        setTBStatus(value);
    }

    const tb_codes = [
        { label: 'NA (Not Applicable)', value: 'NA (Not Applicable)' },
        { label: '1', value: '1' },
        { label: '2', value: '2' },
        { label: '3', value: "3" },
    ]

    const [maternal_nutrition, setMaternaNutrition] = useState([]);
    const handleMaternalNutritionChange = (value) => {
        setMaternaNutrition(value)
    }

    const gbv_risks = [
        { label: 'Physical', value: "Physical" },
        { label: 'Sexual', value: "Sexual" },
        { label: 'Psychological', value: "Psychological" },
    ]

    const [gbv_risk, setGBVRisk] = useState([]);

    const handleGBVRisks = (value) => {
        setGBVRisk(value);
    }

    
    const clinical_stages = [
        { label: 'Stage I Asymptomatic', value: "Stage I Asymptomatic" },
        { label: 'Stage II Mild', value: "Stage II Mild" },
        { label: 'Stage III Advanced', value: "Stage III Advanced" },
        { label: 'Stage IV Severe', value: "Stage IV Severe" },
    ]

    const [who_clinical_stage, setClinicalStages] = useState([]);

    const handleClinicalStagesChange = (value) => {
        setClinicalStages(value);
    }

    const [cd4_vl_vars, setCD4VL] = useState({cd4:'', viral_load:''});

    const handleCD4VLChanges = (event) => {
        const {name, value} = event.target;
        setCD4VL({...cd4_vl_vars,[name]:value});
    }


    const [art_vars, setARTVARS] = useState({linakge_art_no:'', infant_arv_prophyloxis:'', art_code:''});
    const handleARTChange = (event) => {
        const {name, value} = event.target;
        setARTVARS({...art_vars,[name]:value});
    }

    // blood group
    const blood_groups = [
        { label: "A+", value: "A+" },
        { label: "A-", value: "A-" },
        { label: "B+", value: "B+" },
        { label: "B-", value: "B-" },
        { label: "O+", value: "O+" },
        { label: "O-", value: "O-" },
        { label: "AB+", value: "AB+" },
        { label: "AB-", value: "AB-" },
    ]

    const check_blood_group = patient_blood ? {label: patient_blood, value: patient_blood}: [];
    const [blood_group, setBloodGroup] = useState(check_blood_group);

    const handleBloodGroup = (value) => {
        setBloodGroup(value);
    }

    // rh
    const rh_factors = [
        { label: 'Positive', value: "Positive" },
        { label: 'Negative', value: "Negative" },
    ]

    const [rh, setRHFactor] = useState([]);

    const handleRHFactorChange = (value) => {
        setRHFactor(value);
    }

    const [tetanus_dose, setTetanusDose] = useState([]);
    const tetanus_doses = [
        { label: '1st', value: "1st" },
        { label: '2nd', value: "2nd" },
        { label: '3rd', value: "3rd" },
        { label: '4th', value: "4th" },
        { label: '5th', value: "5th" },
        { label: 'C (Complete)', value: 'C (Complete)' },
    ]

    const handleTetanusDoses = (value)=> {
        setTetanusDose(value);
    }

    const [ipt_dose, setIPTDose] = useState([]);
    const ipt_doses = [
        { label: 'IPT1', value: "IPT1" },
        { label: 'IPT2', value: "IPT2" },
        { label: 'ND (Not Due)', value: "ND (Not Due)" },
        { label: 'C (Complete)', value: "C (Complete)" },
        { label: 'on CTX (On Septrine)', value: "CTX" },
    ]

    const handleIPTDose = (value)=> {
        setIPTDose(value);
    }

    const [mabendazole, setMabendazole] = useState([]);
    const mab_options  = [
        { label: 'Yes', value: "Yes" },
        { label: 'No', value: "No" },
        { label: 'NA (Not applicable)', value: "NA (Not applicable)" },
        { label: 'C (Complete)', value: "C (Complete)" },
    ];

    const handleMabendazoleChange = (value) => {
        setMabendazole(value);
    }

    const [llin, setLLIN] = useState([]);

    const handleLLINChange = (value) => {
        setLLIN(value);
    }

    // blood group
    const sickles = [
        { label: 'Yes', value: "Yes" },
        { label: 'No', value: "No" },
        { label: 'NA (Not Applicable)', value: "NA (Not Applicable)" },
    ]

    const [hb_sickle_cell, setHBSickleCell] = useState({hb:'', sickle_cell:[]});

    const handleHBSickleCellChange = (event) => {
        if (event.label) {
            setHBSickleCell({...hb_sickle_cell, sickle_cell:event});
        } else {
            const {name, value} = event.target;
            setHBSickleCell({...hb_sickle_cell, [name]:value});
        }
    }

    // blood group
    const syphilis_types = [
        { label: (
            <>
            <FormLabel  name={"RX"} toolTip={"Client tested and reactive"}/>
            </>
        ), value: "RX" },
        { label: (
            <>
            <FormLabel  name={"NR"} toolTip={"Client tested results given, client non-reactive"}/>
            </>
        ), value: "NR" },
        { label: (
            <>
            <FormLabel  name={"NT"} toolTip={"Client not tested for syphilis"}/>
            </>
        ), value: "NT" },
    ]

    const [syphilisHeB_Woman, setSyphilisHeB_Woman] = useState({syhpilis_woman:[], heb_woman:''});
    const [syphilisHeB_Partner, setSyphilisHeB_Partner] = useState({syhpilis_partner:[], heb_partner:''});
    
    const handleSyphilisHEBWomanChange = (vals) => {
        if (vals.label) {
            setSyphilisHeB_Woman({...syphilisHeB_Woman, syhpilis_woman:vals});
        } else {
            const {name, value} = vals.target;
            setSyphilisHeB_Woman({...syphilisHeB_Woman,  [name]:value});
        }
    }

    const handleSyphilisHEBPartnerChange = (vals) => {
        if (vals.label) {
            setSyphilisHeB_Partner({...syphilisHeB_Partner, syhpilis_partner:vals});
        } else {
            const {name, value} = vals.target;
            setSyphilisHeB_Partner({...syphilisHeB_Partner,  [name]:value});
        }
    }

    // hooks for complications
    const [complications_checks, setComplicationChecks] = useState({
        bleed_complication:false, excessive_comp:false, other_comp:false, hosp_yes:false, hosp_no:false, cough_comp:false, fever_comp:false, 
        weight_loss:false, night_sweats:false, hiv_yes:false, hiv_no:false, 
    });

    const [nutrtional_radios, setNutRadios] = useState({
        normal_nutr:false, mam_nutr:false, sam_nutr:false
    })

    const handleComplicationChecks = (event) => {
        const {name, checked} = event.target;
        if (name === 'hosp_yes')  {
            if (checked === true && complications_checks['hosp_no'] === true) {
                setComplicationChecks({...complications_checks, [name]:checked, 'hosp_no':!checked});  
            } else  {
                setComplicationChecks({...complications_checks, [name]:checked});  
            }
        } else if (name === 'hosp_no') {
            if (checked === true && complications_checks['hosp_yes'] === true) {
                setComplicationChecks({...complications_checks, [name]:checked, 'hosp_yes':!checked});  
            } else  {
                setComplicationChecks({...complications_checks, [name]:checked});  
            }
        } else {
            setComplicationChecks({...complications_checks, [name]:checked});
        }
    }

    const handleRadioChecks = (event) => {
        const {name, checked} = event.target;
        const new_radios = {};
        for (const [key, value] of Object.entries(nutrtional_radios)) {
            if (key === name) {
                new_radios[name] = true;
            } else {
                new_radios[key] = false;
            }
          }
        setNutRadios(new_radios);
    
    }

    const [complications_texts, setComplicationTexts] = useState({
        other_complication_text:'', any_hosp_text:''
    });

    const handleComplicationTexts = (event) => {
        const {name, value} = event.target;
        setComplicationTexts({...complications_texts, [name]:value});
    }

    // hooks for general examinations
    const [examination_checks, setExaminationChecks] = useState({
        oral_thrush:false, angeria:false, deformities:false, teeth:false, eyes:false, lymph_nodes:false, neck:false, nails:false, herpes_zoosler:false,
        breasts:false, palms:false, heart:false, legs:false, jaundice:false, lungs:false
    });

    const [pelvic_checks, setPelvicChecks] = useState({
        vulva:false, corvix:false, vagina_monliasis:false, monliasis:false
    })

    const handlePelvicChecks = (event) => {
        const {name, checked} = event.target;
        setPelvicChecks({...pelvic_checks, [name]:checked});
    };    

    const handleExaminationChecks = (event) => {
        const {name, checked} = event.target;
        setExaminationChecks({...examination_checks, [name]:checked});
    };


    const [exam_notes, setExaminationNotes] = useState('');

    const handleExamNotesChange = (event) => {
        const {name, value} = event.target;
        setExaminationNotes(value);
    }

    const [first_day_of_lnmp, setFirstLMNP] = useState(dateConvert());

    const handleFirstLMNPChange = (event) => {
        setFirstLMNP(event.target.value);
    }

    
    const [woa_props, setWOAProps] = useState({woa_months:0, woa_days:0})

    const onChangeWOA = (event) => {
        const {name, value} = event.target;
        setWOAProps({...woa_props,[name]:value});
    }

    const [emtct, setEMTCTCodes] = useState([]);

    
    const handleEMTCTCodes = (value) => {
        setEMTCTCodes(value);
    }

    const [routines, setRoutines] = useState({random_blood_sugar:'', bs_for_mps:''});

    const handleRoutines = (event) => {
        const {name, value} = event.target;
        setRoutines({...routines, [name]:value});
    }

    const [routine_date, setRoutineDate] = useState(dateConvert());

    const handleRoutineDate = (event) => {
        setRoutineDate(event.target.value);
    }

    const handleResetSecondScreen = () => {
        // first part
        setFirstLMNP(dateConvert());

        // complications of pregnancy
        setComplicationChecks({
            bleed_complication:false, excessive_comp:false, other_comp:false, hosp_yes:false, hosp_no:false, cough_comp:false, fever_comp:false, 
            weight_loss:false, night_sweats:false, hiv_yes:false, hiv_no:false, 
        });
        setComplicationTexts({
            other_complication_text:'', any_hosp_text:''
        });
        setExaminationNotes('');

        // investigations
        // tb
        setTBStatus([]);
        setRiskAssess([]);

        // art
        setARTVARS({linakge_art_no:'', infant_arv_prophyloxis:'', art_code:''});

        // emtct w
        setWomanInitialResult([]);
        setSTKGiven([]);

        // emtct p
        setPartnerEMTCT({partner_age:0, facility:''});
        setPartnerSTKResult([]);

        // linked to art
        setLinkedArt([]);
        setTFV('');
        setWherePreArtNo({where: '', pre_art_no:0, art_no:0});

        // cd4, virol and who
        setCD4VL({cd4:'', viral_load:''});
        setClinicalStages([]);
        
        // blood screening
        setBloodGroup([]);
        setHBSickleCell({hb:'', sickle_cell:''});

        // syphilis and hep b
        setSyphilisHeB_Partner({syhpilis_partner:[], heb_partner:''});
        setSyphilisHeB_Woman({syhpilis_woman:[], heb_woman:''});

        // treatments
        setTetanusDose([]);
        setIPTDose([]);
        setMabendazole([]);
        setLLIN([]);

        // counselling
        setFamilyPlan([]);
        setInfantFeeding([]);
        setMaternaNutrition([]);
        setGBVRisk([]);

        // For second screen
        setWOAProps({woa_months:0, woa_days:0});

        setRoutines({random_blood_sugar:'', bs_for_mps:''});
        setRoutineDate(dateConvert());
        setEMTCTCodes([]);
    }

    const assignSecondScreenProps = (item) => {



        // first part
        setFirstLMNP(item['first_day_of_lnmp'] ? convDate(item['first_day_of_lnmp']) : dateConvert());

        // Complications of pregnancy
        const pregnacy_complis = item['pregnancy_complications'][0] ? item['pregnancy_complications'][0] : {};       
        const preg_comps = pregnacy_complis['complication_checks'] ? pregnacy_complis['complication_checks'] : '';
        const other_comps_txt = pregnacy_complis['other_complication_text']  ? pregnacy_complis['other_complication_text']  : '';
        const any_hsp_txt = pregnacy_complis['any_hosp_text'] ? pregnacy_complis['any_hosp_text'] : '';

        setComplicationChecks({
            bleed_complication:preg_comps.includes('bleed_complication'), 
            excessive_comp:preg_comps.includes('excessive_comp'), 
            other_comp:preg_comps.includes('other_comp'), 
            hosp_yes:preg_comps.includes('hosp_yes'), 
            hosp_no:preg_comps.includes('hosp_no'), 
            cough_comp:preg_comps.includes('cough_comp'), 
            fever_comp:preg_comps.includes('fever_comp'), 
            weight_loss:preg_comps.includes('weight_loss'), 
            night_sweats:preg_comps.includes('night_sweats'), 
            hiv_yes:preg_comps.includes('hiv_yes'), 
            hiv_no:preg_comps.includes('hiv_no'), 
        })
        const nut_st = item['nutritional_status'] ? item['nutritional_status'] : '';
        setNutRadios({
            normal_nutr:nut_st.includes('normal_nutr'),
            mam_nutr:nut_st.includes('mam_nutr'), 
            sam_nutr:nut_st.includes('sam_nutr')
        })
        setComplicationTexts({
            other_complication_text: other_comps_txt,
            any_hosp_text: any_hsp_txt
        })
                

        // General and Pelvic examinations
        const pe_exam = item['pelvic_exam'] ? item['pelvic_exam'] : '';
        setPelvicChecks({
            vulva:pe_exam.includes('vulva'), 
            corvix:pe_exam.includes('corvix'), 
            vagina_monliasis:pe_exam.includes('vagina_monliasis'), 
            monliasis:pe_exam.includes('monliasis')
        })
        const gen_exam = item['general_exam'] ? item['general_exam'] : '';
        setExaminationChecks({
            oral_thrush:gen_exam.includes('oral_thrush'), 
            angeria:gen_exam.includes('angeria'), 
            deformities:gen_exam.includes('deformities'), 
            teeth:gen_exam.includes('teeth'), 
            eyes:gen_exam.includes('eyes'), 
            lymph_nodes:gen_exam.includes('lymph_nodes'), 
            neck:gen_exam.includes('neck'), 
            nails:gen_exam.includes('nails'), 
            herpes_zoosler:gen_exam.includes('herpes_zoosler'),
            breasts:gen_exam.includes('breasts'), 
            palms:gen_exam.includes('palms'), 
            heart:gen_exam.includes('heart'), 
            legs:gen_exam.includes('legs'), 
            jaundice:gen_exam.includes('jaundice'), 
            lungs:gen_exam.includes('lungs')
        })
        setExaminationNotes(item['exam_notes'] ? item['exam_notes'] : '');

        // investigations
        // tb     
        const t_s = item['tb_status'] ? item['tb_status'] : '';
        setTBStatus({value: t_s, label: t_s});
        const rik = item['emtct_risk_assessment'] ? item['emtct_risk_assessment'] : '';
        setRiskAssess({value: rik, label: rik});

        // art
        setARTVARS({linakge_art_no:item['linkage_art_no'] ? item['linkage_art_no'] : '', 
            infant_arv_prophyloxis:item['infant_arv_prophyloxis'] ? item['infant_arv_prophyloxis'] : '', 
            art_code:item['art_code'] ? item['art_code'] : ''
        });


        // emtct status w
        setWomanInitialResult(item['emtct_woman_initial_result'] ? {value: item['emtct_woman_initial_result'], label: item['emtct_woman_initial_result']} : []);
        setSTKGiven(item['emtct_woman_stk_given'] ? {value: item['emtct_woman_stk_given'], label:item['emtct_woman_stk_given']} : []);

        // emtct status p       
        setPartnerEMTCT({partner_age:item['emtct_partner_age'] ? item['emtct_partner_age'] : 0, facility:''});
        const part_res = item['emtct_partner_stk_result'] ? item['emtct_partner_stk_result'] : '';
        setPartnerSTKResult({value: part_res, label: part_res});

        // linked to art 
        const y_n = item['linked_to_art_yes_no'] ? item['linked_to_art_yes_no'] : '';
        setLinkedArt({value : y_n, label: y_n});
        setTFV(item['linked_to_art_tfv'] ? item['linked_to_art_tfv'] : '');
        setWherePreArtNo({where: item['linked_to_art_where'] ? item['linked_to_art_where'] : '', 
            pre_art_no: item['linked_to_art_pre_art_no'] ? item['linked_to_art_pre_art_no'] : 0, 
            art_no:item['linked_to_art_art_no'] ? item['linked_to_art_art_no'] : 0
        });

        // cd4, virol and who
        const who_cl = item['who_clinical_stage'] ? item['who_clinical_stage'] : '';
        setClinicalStages({value: who_cl, label: who_cl});
        setCD4VL({cd4:item['cd4_cells_ul_mm3'] ? item['cd4_cells_ul_mm3'] : '', 
            viral_load:item['virol_load_copies_mm3'] ? item['virol_load_copies_mm3'] : ''});

        //blood screening    
        const check = item['blood_type'] ? item['blood_type'] : '';
        const blood =  { label: check, value: check };
        setBloodGroup(blood);
        setHBSickleCell({hb: item.hb  ? item.hb  : '', sickle_cell: item.sickle_cell ? item.sickle_cell : ''});
 
        // syphilis & Hep B Test Results
        const s_p = item.partner_syphilis_status ? {value:item.partner_syphilis_status, label: item.partner_syphilis_status} : [];
        setSyphilisHeB_Partner({syhpilis_partner: s_p, heb_partner: item.partner_hepatitis_b ? item.partner_hepatitis_b : ''});

        const w_s = item.woman_syphilis_status ? {value:item.woman_syphilis_status, label: item.woman_syphilis_status} : [];
        setSyphilisHeB_Woman({syhpilis_woman: w_s, heb_woman: item.woman_hepatitis_b ? item.woman_hepatitis_b : ''});

        // Treatments / Interventions
        const check_t = item.tetanus_diphtheria ? {label: item.tetanus_diphtheria, value: item.tetanus_diphtheria} : [];
        setTetanusDose(check_t);
        const ipt_d = item.ipt_ctx ? {value: item.ipt_ctx, label: item.ipt_ctx} : [];
        setIPTDose(ipt_d);
        const ln = item.llin ? {value: item.llin, label: item.llin} : []; 
        setLLIN(ln);
        const mb = item.mabendazole ? {value:item.mabendazole, label:item.mabendazole} : [];
        setMabendazole(mb);

        // Counselling  
        setFamilyPlan(item['family_planning'] ? {value:item['family_planning'], label:item['family_planning']} : []);
        const iy = item['iycf_option'] ? item['iycf_option'] : '';
        setInfantFeeding({value: iy, label: iy});
        const mat = item['maternal_nutrition_counselling'] ? item['maternal_nutrition_counselling'] : '';
        setMaternaNutrition({value: mat, label: mat});
        const gb = item['gbv_risk'] ? item['gbv_risk'] : '';
        setGBVRisk({value: gb, label: gb});

        // for second screen
        setWOAProps({woa_months: item.woa_month ? item.woa_month : 0, 
        woa_days: item.woa_day ? item.woa_day : 0});

        const rout_date = item.routine_medicine_date ? convDate(item.routine_medicine_date) : dateConvert();
        setRoutineDate(rout_date);

        const emt_code = item.emtc_code ? {value: item.emtc_code, label: item.emtc_code} : [];
        setEMTCTCodes(emt_code);

        const routes_bld = item.random_blood_sugar ? item.random_blood_sugar : '';
        const bs_mps = item.bs_for_mps_rdt_for_malaria ? item.bs_for_mps_rdt_for_malaria : '';
        setRoutines({random_blood_sugar:routes_bld, bs_for_mps:bs_mps});
                     
    }

    const getSecondScreenValues = () => {
        return {
            // Routine Meds/ Interventions
            'random_blood_sugar' : routines['random_blood_sugar'],
            'bs_for_mps_rdt_for_malaria' : routines['bs_for_mps'],
            'emtc_code' : emtct['value'] ? emtct['value'] : '',
            'routine_medicine_date' : convDate(routine_date),

            // first part
            first_day_of_lnmp,

            // Complications of pregnancy
            'pregnancy_complications' : [{
                'complication_checks':convertChecksToString(complications_checks),
                'other_complication_text' : complications_texts['other_complication_text'],
                'any_hosp_text': complications_texts['any_hosp_text']
            }],
            
            exam_notes,
            'nutritional_status' : convertChecksToString(nutrtional_radios),
            'general_exam' : convertChecksToString(examination_checks),
            'pelvic_exam' :  convertChecksToString(pelvic_checks),

            // investigations
            // tb
            "tb_status": tb_status['value'] ? tb_status['value'] : '', 
            "emtct_risk_assessment": risk_assess['value'] ? risk_assess['value'] : '', 
            
            // art
            "art_code": art_vars['art_code'] ? art_vars['art_code']  : '', 
            "linkage_art_no": art_vars.linakge_art_no ? art_vars.linakge_art_no : '', 
            "infant_arv_prophyloxis": art_vars['infant_arv_prophyloxis'] ? art_vars['infant_arv_prophyloxis'] : '', 
            
            // emtct status w
            "emtct_woman_initial_result": womanInitialResult['value'] ? womanInitialResult['value'] : '', 
            "emtct_woman_stk_given": stk_given['value'] ? stk_given['value'] : '', 

            // emtct status p
            "emtct_partner_age": partnerEMTCT.partner_age ? partnerEMTCT.partner_age : '', 
            "emtct_partner_stk_result": partner_stk_result['value'] ? partner_stk_result['value'] : '', 
            
            // linked to art
            "linked_to_art_yes_no": yes_no_linked_art['value'] ? yes_no_linked_art['value'] : '', 
            "linked_to_art_tfv": tfv ? tfv : '', 
            "linked_to_art_where": where_pre_art_no['where'] ? where_pre_art_no['where'] : '', 
            "linked_to_art_pre_art_no": where_pre_art_no['pre_art_no'] ? where_pre_art_no['pre_art_no'] : '', 
            "linked_to_art_art_no": where_pre_art_no['art_no'] ? where_pre_art_no['art_no'] : '', 

            // cd4, virol and who
            "cd4_cells_ul_mm3": cd4_vl_vars['cd4'] ? cd4_vl_vars['cd4'] : '', 
            "virol_load_copies_mm3": cd4_vl_vars['viral_load'] ? cd4_vl_vars['viral_load'] : '', 
            "who_clinical_stage": who_clinical_stage['value'] ? who_clinical_stage['value'] : '', 

            //blood screening
            'blood_group':blood_group['value'] ?  blood_group['value'] : '',
            'hb' : hb_sickle_cell['hb'] ? hb_sickle_cell['hb'] : '',
            'sickle_cell' : hb_sickle_cell.sickle_cell['value'] ?  hb_sickle_cell.sickle_cell['value']  : '',
   
            // syphilis & Hep B Test Results
            'partner_syphilis_status' : syphilisHeB_Partner.syhpilis_partner['value'] ?  syphilisHeB_Partner.syhpilis_partner['value'] : '',
            'partner_hepatitis_b' : syphilisHeB_Partner.heb_partner ?  syphilisHeB_Partner.heb_partner : '',
            'woman_syphilis_status' : syphilisHeB_Woman.syhpilis_woman['value'] ?  syphilisHeB_Woman.syhpilis_woman['value'] : '',
            'woman_hepatitis_b' : syphilisHeB_Woman.heb_woman ? syphilisHeB_Woman.heb_woman : '',

            // Treatments / Interventions
            'tetanus_diphtheria' : tetanus_dose['value'] ? tetanus_dose['value'] : '',
            'ipt_ctx' : ipt_dose['value'] ? ipt_dose['value'] : '',
            'llin': llin['value'] ?  llin['value'] :'',
            'mabendazole' : mabendazole['value'] ?  mabendazole['value'] : '',

            // Counselling
            "iycf_option": infant_feeding['value'] ? infant_feeding['value'] : '', 
            "maternal_nutrition_counselling": maternal_nutrition['value'] ? maternal_nutrition['value'] : '',
            "family_planning": family_plan['value'] ? family_plan['value'] : '', 
            "gbv_risk": gbv_risk['value'] ? gbv_risk['value'] : '', 
            
            // WOA
            "woa_month" : woa_props.woa_months,
            "woa_day" : woa_props.woa_days
        
        }
    }

    const second_screen_props = {
        handleFirstLMNPChange, first_day_of_lnmp,
        handleExamNotesChange,exam_notes,
        emtct_codes, handleEMTCTCodes, handleRoutineDate, handleRoutines, routine_date, emtct, routines,
        woa_props, onChangeWOA, currentTriageData,
        examination_checks, handleExaminationChecks,
        clinical_stages, who_clinical_stage, handleClinicalStagesChange,
        gbv_risk, handleGBVRisks, gbv_risks,
        womanInitialResult, handleInitialResultChange, stk_given, handleSTKGiven,
        partnerEMTCT, handleSetPartnerEMTCT, 
        yes_nos, yes_no_linked_art, handleYesNoLinkedArt,
        tfv, handleTFV, emtct_codes, hb_sickle_cell,
        where_pre_art_no, handleWherePreArtNoChange,
        partner_stk_result, handlePartnerSTKResult,
        risk_assess, risk_assessments, handleRiskAssessment,
        family_plan, familyplannings, handleFamilyPlanningChange,
        infant_feeding, handleInfantFeeding, infant_feeding_options,
        tb_status, handleTBStatusChange, tb_codes,
        maternal_nutrition, handleMaternalNutritionChange,
        handleCD4VLChanges, cd4_vl_vars, handleHBSickleCellChange,
        syphilis_types, syphilisHeB_Partner, syphilisHeB_Woman,
        handleSyphilisHEBPartnerChange, handleSyphilisHEBWomanChange,
        art_vars, handleARTChange, getSecondScreenValues, assignSecondScreenProps, handleLLINChange, llin,
        blood_groups, blood_group, handleBloodGroup,
        rh, rh_factors, handleRHFactorChange, nutrtional_radios,
        ipt_dose, ipt_doses, handleIPTDose, sickles,
        tetanus_doses, tetanus_dose, handleTetanusDoses, handlePelvicChecks, pelvic_checks,
        mab_options, mabendazole, handleMabendazoleChange, handleRadioChecks,
        complications_checks, handleComplicationChecks, complications_texts, handleComplicationTexts

    }

    return {second_screen_props, handleResetSecondScreen, assignSecondScreenProps, getSecondScreenValues}
}