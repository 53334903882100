import {useEffect, useState} from "react";
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import { useFileUploader } from "../../../PatientRecords/CustomHooks/useFileUploader";
import { todayTime } from "../../../Utils/ConvertDate";


const useItemsProviderAllows = (actions, provider_id, item_status, currency_id) => {

    const current_date = todayTime()

    // values for the main table
    const [searchValue, setSearchValue] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [openDeactivate, setDeactivate] = useState(false);
    const [openEditItem, setEditItem] = useState(false);
    const [openNewItems, setNewItems] = useState(false);
    const [current_item, setCurrentItem] = useState({});
    const [isBlocking, setIsBlocking] = useState(false)
    const [isBlockDialog, setIsBlockDialog] = useState(false)
    const [resetField, setResetField] = useState(false)

    // values for the new items pop up
    const [provider_items, setProviderItems] = useState([]);



    // value for the rooms
    const [room_options, setRoomOptions] = useState([]);

    // get the current user's details
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_role_id = _token.user_roles_id ? _token.user_roles_id : '';

    // initial item values
    const initial_service = [{
        item:'', cost:0,
        service_options:[], service_name:'', showItems:false,product_options:[],
        product_name:'', product_id:'', provider_id, service_id:'', name:'', room_id:'', pack_unit: '', quantity_billable: 0
    }];

    const [provider_products, setProviderProducts] = useState({
        isAddProducts:false, new_products:initial_service
    })

    const [provider_services, setProviderServices] = useState({
        isAddServices:false, new_services:initial_service
    })

    const [provider_rooms, setProviderRooms] = useState({
        isAddRooms:false, new_rooms:initial_service
    })

    // values for submission of api calls
    const [loading, setLoading] = useState('idle');
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');

    const isPending = isSubmitted === 'pending';
    const isResolved = isSubmitted === 'resolved';
    const isRejected = isSubmitted === 'rejected';

    const isLoading = loading === 'pending';
    const isSuccess = loading === 'success';
    const isError = loading === 'error';

//  handling csv uploads
    const obj = {url:'cbilling/insurance_providers/upload_bulk_provider_items', download_url:'cbilling/provider_item_csv', file_name:'Provider Items', params:{provider_id, currency_id}}
    const {uploadProps, isResolved:isResolvedUpload} = useFileUploader(actions, obj)

    // get the current items list
    useEffect(() => {

        const params = {
            "provider_id" : parseInt(provider_id),
            "status" : item_status,
            search:searchValue
        };

        setSubmitted(true);
        axios.post(`${config.smsUrl}/cbilling/provider_list/list`, params).then(res => {
            const dt = res.data?? {};
            const data = dt.data ?? [];
            const arr = data.map((item) => {
                return {
                    ...item,
                    'item_name' : item.service_id ? item.service_name : item.room_id ? item.room_name : item.product_id ? item.product_name : '',
                    'item_type' : item.service_id ? 'service' : item.room_id ? 'room' : item.product_id ? 'product' : '',
                    //'currency' : currency_dictionary[item.currency_id],
                    
                }
            })
            setProviderItems(arr)
            setLoading('success');
            setSubmitted(false);
            setIsSubmitted('idle');
        }).catch(err => {
            setLoading('error');
            logoutErrorMessage(err,null,actions);
        })
    }, [isResolved, item_status, isResolvedUpload, searchValue]);


     // get currencies
     useEffect(() => {
        /*axios.get(`${config.smsUrl}/accounting/currencies`).then(res => {
            const data = res.data;
            const all_data = !data ? {}: data;
            const arr = all_data.data ?? [];
            const list = arr.map(item=>({value:`${item.id}-${item.is_base_currency}-${item.currency_symbol}`, text:`${item.currency_symbol} ${item.currency_name}`}))
            setCurrencies(list)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        }) */
    }, []);

    // functions to cater for editing an item
    const handleOpenEditItem = (index, item) => {
        setCurrentItem({...item, index});
        setEditItem(true);
    }

    const handleCloseEditItem = () => {
        setEditItem(false);
        // clear the current item
        setCurrentItem({});

    }

    const fileName = {
        0:'Active Items',
        1:'In-Active Items',

    }

    const exportItems = () => {
        axios.get(`${config.smsUrl}/cbilling/provider_items_excel`, {params:{download:true, provider_id},responseType: 'blob'}).then(response=>{
            const data  = response.data;
            const url = URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href =  url;
            link.setAttribute('download', `Active Items-${current_date}.xlsx`);
            document.body.appendChild(link);
            link.click();
        }).catch(err=>{
            errorMessages(err, null,actions)
         
        })
    }

    const handleItemUpdate = () => {
        // make api to call to update the item's details
        const params = {
            'provider_id': provider_id,
            'provider_offering_id': current_item['id'],
            'cost':current_item['cost'],
            'currency_id' : current_item['currency_id'],
            'user_role_id' : user_role_id,
            'quantity_billable' : current_item['quantity_billable'],
            'pack_unit' : current_item['pack_unit']
        };

        setSubmitted(true);
        setIsSubmitted('pending');
        setIsBlocking(false)
        axios.post(`${config.smsUrl}/cbilling/provider_list/edit`, params).then(res => {
            setLoading('success');
            setIsSubmitted('resolved');
            setSubmitted(false);
        }).catch(err => {
            setLoading('error');
            logoutErrorMessage(err,null,actions);
            setIsSubmitted('rejected');
        })
        handleCloseEditItem();
    }

    const handleChangeItem = (e, to_change) => {
        
        /*if (to_change === 'currency') {
            setCurrentItem({...current_item, 'currency' : e.label, 'currency_id' : e.value});
        } else { */
            //  handle changes of item
            setCurrentItem({...current_item, [`${to_change}`] : e.target.value});
            setIsBlocking(true)
    }

    // functions to cater for the deactivation of the item
    const handleDeactivate = () => {
        // make api call to handle the deactivation
        const params = {
            'provider_id': provider_id,
            'provider_offering_id': current_item['id'],
            'status': current_item['status'] === 0 ? 1 : 0,
            'user_role_id' : user_role_id
        };
        setSubmitted(true);
        setIsSubmitted('pending');
        axios.post(`${config.smsUrl}/cbilling/provider_list/edit_status`, params).then(res => {
            setLoading('success');
            setIsSubmitted('resolved');
            setSubmitted(false);
        }).catch(err => {
            setLoading('error');
            logoutErrorMessage(err,null,actions);
            setIsSubmitted('rejected');
        })
        // change the status of the item
        handleCloseDeactivate();
    }   

    const handleCloseDeactivate = () => {
        setDeactivate(false);
        // clear the current item
        setCurrentItem({});
    }

    const handleOpenDeactivate = (index, item) => {
        setCurrentItem({...item, index})
        setDeactivate(true);
    }

    // functions to cater for adding new items
    const handleOpenNewItems = () => {
        setNewItems(true);
    }

    const handleCloseNewItems = () => {
        setNewItems(false);
        setProviderProducts({isAddProducts:false, new_products:initial_service});
        setProviderRooms({isAddRooms:false, new_rooms:initial_service});
        setProviderServices({isAddServices:false, new_services:initial_service});

        // if(isBlocking){
        //     setIsBlockDialog(true)
        // }else{
        //
        //     setIsBlockDialog(false)
        //     setIsBlocking(false)
        // }

    }

    // functions for handling the toggles
    const handleToggleProducts = () => {
        setProviderProducts({isAddProducts:!provider_products['isAddProducts'], new_products:initial_service});
        setIsBlocking(true)
    }

    const handleToggleRooms = () => {
        setProviderRooms({isAddRooms:!provider_rooms['isAddRooms'], new_rooms:initial_service});
        if (!provider_rooms['isAddRooms'] === true) {
            axios.get(`${config.smsUrl}/cbedmanager/getAllRooms`).then(res => {
                const data = res.data;
                const all_data = !data ? {}: data;
                const arr = all_data.rooms ?? [];
                const list = arr.map(it => (
                    {
                        ...it,
                        value: it.room_id,
                        label: it.name
                    }
                ))
                setRoomOptions(list);
            }).catch(err => {
                setLoading('error');
                logoutErrorMessage(err,null,actions);
            })            
        }
        setIsBlocking(true)

    }

    const handleToggleServices = () => {
        setProviderServices({isAddServices:!provider_services['isAddServices'], new_services:initial_service});
        setIsBlocking(true)
    }

    // function for handling the services table
    const handleAddServicesRow = () => {
        const new_list = [...provider_services['new_services'], ...initial_service];
        setProviderServices({...provider_services, new_services:new_list})
    }

    const handleRemoveServicesRow = (service_id) => {
        const new_list = provider_services['new_services'].filter((item, idx)=> (service_id !== item.service_id));

        setProviderServices({...provider_services, new_services:new_list})
    }

    const handleChangeService = (e, idx, item, type_to_change) => {
        let new_item = item;
        if (type_to_change === 'cost') {
            new_item['cost'] = e.target.value;           
        }
        /*if (type_to_change === 'currency') {
            new_item['currency'] = e.label;
            new_item['currency_id'] = e.value;
        } */
        if(type_to_change === 'quantity_billable'){
            new_item['quantity_billable'] = e.target.value
        }if(type_to_change === 'pack_unit'){
            new_item['pack_unit'] = e.target.value
        }

        let new_list = provider_services['new_services'];
        new_list[idx] = new_item;
        setProviderServices({...provider_services,  new_services:new_list});
        setIsBlocking(true)
    }

    const getServiceOptions = (e, index, item) => {
        // set the item.service to the value
        let new_item = item;
        new_item['service_name'] = e;

        // make the api call
        // set the service_options
        const params = {
            "service_name" : e,
        };

        axios.post(`${config.smsUrl}/cbilling/search_services`, params).then(res => {
            const dt = res.data?? {};
            const data = dt.data ?? [];
            new_item['service_options'] = data.map(serviceItem=>({
                value:serviceItem.service_id,
                label:serviceItem.service_name,
                ...serviceItem
            }));
            new_item['showItems'] = true;

            let new_list = provider_services['new_services'];

            new_list[index] = new_item;
            setProviderServices({...provider_services,  new_services:new_list});
        }).catch(err => {
            new_item['service_options'] = [];
            let new_list = provider_services['new_services'];
            new_list[index] = new_item;
            setProviderServices({...provider_services,  new_services:new_list});
            logoutErrorMessage(err,null,actions);
        })
        setIsBlocking(true)
    } 

    const setServiceDetails = (val, index, item) => {
        let new_item = item;
        new_item = {...new_item, ...val};
        new_item['showItems'] = false;
        new_item['service_options'] = [];
        let new_list = provider_services['new_services'];
        new_list[index] = new_item;
        setProviderServices({...provider_services,  new_services:new_list});
        setIsBlocking(true)
    }

    // function for handling the room table
    const handleAddRoomRow = () => {
        const new_list = [...provider_rooms['new_rooms'], ...initial_service];
        setProviderRooms({...provider_rooms, new_rooms:new_list})
    }

    const handleRemoveRoomRow = (room_id) => {
        const new_list = provider_rooms['new_rooms'].filter((item, idx)=> (item.room_id !== room_id));
        setProviderRooms({...provider_rooms, new_rooms:new_list})
    }

    const handleChangeRoom = (e, idx, item,type_to_change) => {
        let new_item = item;
        if (type_to_change === 'name') {
            new_item = {...new_item, ...e, 'cost':e.charge};
        }
        if (type_to_change === 'cost') {
            new_item['cost'] = e.target.value;
        }
        /*if (type_to_change === 'currency') {
            new_item['currency'] = e.label;
            new_item['currency_id'] = e.value;
        }*/
        if(type_to_change === 'quantity_billable'){
            new_item['quantity_billable'] = e.target.value
        }if(type_to_change === 'pack_unit'){
            new_item['pack_unit'] = e.target.value
        }
        let new_list = provider_rooms['new_rooms'];
        new_list[idx] = new_item;
        setProviderRooms({...provider_rooms,  new_rooms:new_list});
        setIsBlocking(true)
    }

    // function for handling the products table
    const handleAddProductRow = () => {
        const new_list = [...provider_products['new_products'], ...initial_service];
        setProviderProducts({...provider_products, new_products:new_list})
    }

    const handleRemoveProductRow = (product_id) => {
        const new_list = provider_products['new_products'].filter((item, idx)=> (item.product_id !== product_id));
        setProviderProducts({...provider_products, new_products:new_list})
    }

    const handleChangeProduct = (e, idx, item, type_to_change) => {
        let new_item = item;

        if (type_to_change === 'cost') {
            new_item['cost'] = e.target.value;
        }
        /*if (type_to_change === 'currency') {
            new_item['currency'] = e.label;
            new_item['currency_id'] = e.value;
        } */
        if(type_to_change === 'quantity_billable'){
            new_item['quantity_billable'] = e.target.value
        }if(type_to_change === 'pack_unit'){
            new_item['pack_unit'] = e.target.value
        }

        let new_list = provider_products['new_products'];
        new_list[idx] = new_item;
        setProviderProducts({...provider_products,  new_products:new_list});
        setIsBlocking(true)
    }

    const getProductOptions = (e, index, item) => {
        // set the item.service to the value
        let new_item = item;
        new_item['product_name'] = e;

        if (e === '') {
            new_item['product_options'] = [];
            new_item['showItems'] = false;
            let new_list = provider_products['new_products'];
            new_list[index] = new_item;
            setProviderProducts({...provider_products,  new_products:new_list});
        } else {
            // make the api call
            // set the service_options
            const formData = new FormData();
            formData.append('product_name', e);
            formData.append('to_store', "");
            axios.post(`${config.smsUrl}/cinventory/product_search_by_name`, formData).then(res => {
                const dt = res.data?? [];
    
                if (dt.length === 1 && dt[0] === 'No Product Found') {
                    const data = [{
                        'product_name' :  'No Product Found',
                        'product_id' : -1
                    }];
        
                    new_item['product_options'] = data.map(productItem=>({
                        value:productItem.product_id,
                        label:productItem.product_name,
                        ...productItem
                    }));
                    new_item['showItems'] = true;
    
                    let new_list = provider_products['new_products'];

                    new_list[index] = new_item;
                    setProviderProducts({...provider_products,  new_products:new_list});
                } else {
                    // const arr_list = new_list.map(item=>({value:item.value, label: `${item.label}-${item.generic_name}-${item.country_of_origin}`}))
                    const data = dt.map((it) => ({
                        'product_name' :`${it.label}-${it.generic_name}-${it.country_of_origin ? it.country_of_origin : ''}`,
                        'product_id' : it.value?? '',
                        'cost' : it.sell_price?? ''
                    }));
        
                    new_item['product_options'] = data.map(productItem=>({
                        value:productItem.product_id,
                        label:productItem.product_name,
                        ...productItem
                    }));
                    new_item['showItems'] = true;
    
                    let new_list = provider_products['new_products'];
                    new_list[index] = new_item;
                    setProviderProducts({...provider_products,  new_products:new_list});
                }    
            }).catch(err => {
                new_item['service_options'] = [];
                let new_list = provider_products['new_products'];
                new_list[index] = new_item;
                setProviderProducts({...provider_products,  new_products:new_list});
                logoutErrorMessage(err,null,actions);
            })
        }
        setIsBlocking(true)
    } 

    const setProductDetails = (val, index, item) => {
        let new_item = item;
        new_item = {...new_item, ...val};
        new_item['showItems'] = false;
        new_item['product_options'] = [];
        let new_list = provider_products['new_products'];
        new_list[index] = new_item;
        setProviderProducts({...provider_products,  new_services:new_list});
        setIsBlocking(true)
    }

    // function to save the list
    const handleAddItems = () => {

        const add_services = provider_services['new_services'].filter((it) => (it.service_id)).map((ex) => ({
            provider_id:parseInt(provider_id),
            'offering_id' : ex.service_id,
            'cost' : ex.cost,
            'currency_id':parseInt(currency_id),
            'offering_type' : 'service',
            'user_role_id' : user_role_id,
            'quantity_billable': ex.quantity_billable ?? '',
            'pack_unit': ex.pack_unit ?? ''
        }))

        const add_rooms = provider_rooms['new_rooms'].filter((it) => (it.room_id)).map((ex) => ({
            provider_id:parseInt(provider_id),
            'offering_id' : ex.room_id,
            'cost' : ex.cost,
            'currency_id':parseInt(currency_id),
            'offering_type' : 'room',
            'user_role_id' : user_role_id,
            'quantity_billable': ex.quantity_billable ?? '',
            'pack_unit': ex.pack_unit ?? ''
        }))

        const add_products = provider_products['new_products'].filter((it) => (it.product_id)).map((ex) => ({
            provider_id:parseInt(provider_id),
            'offering_id' : ex.product_id,
            'cost' : ex.cost,
            'currency_id':parseInt(currency_id),
            'offering_type' : 'product',
            'user_role_id' : user_role_id,
            'quantity_billable': ex.quantity_billable ?? '',
            'pack_unit': ex.pack_unit?? ''
        }))

        const params = {
            "data":[
                ...add_services,
                ...add_rooms,
                ...add_products
            ]
        };

        setSubmitted(true);
        setIsSubmitted('pending');
        setIsBlocking(false)

        const products_arr = provider_products['new_products'].every(item=>item.product_id)
        const is_all_products = (provider_products['isAddProducts'] && products_arr) && provider_rooms['isAddRooms'] && provider_services['isAddServices']
        const is_products_bed = ((provider_products['isAddProducts'] && products_arr) && provider_rooms['isAddRooms']) || provider_rooms['isAddRooms']
        const is_product_service = ((provider_products['isAddProducts'] && products_arr)  && provider_services['isAddServices']) || provider_services['isAddServices']
    

        if ((provider_products['isAddProducts'] && products_arr) || is_all_products || is_product_service || is_products_bed && (params.data.length > 0)) {
            axios.post(`${config.smsUrl}/cbilling/provider_list/add`, params).then(res => {
                const data = res.data;
                //clear items
                handleCloseNewItems();
                setLoading('success');
                setIsSubmitted('resolved');
                setSubmitted(false);
            }).catch(err => {
                if (err.response.data.includes('is already attached to the above provider please edit the details instead')) {
                    setSubmitted(false);
                    actions.snackbarActions.snackError(err.response.data);
                    //logoutErrorMessage(err,null,actions);
                } else {
                    setLoading('error');
                    errorMessages(err,null,actions);
                    setIsSubmitted('rejected');
                }
            })   
        } else if (!provider_products['isAddProducts'] && !provider_rooms['isAddRooms'] && !provider_services['isAddServices']) {
            //clear items
            handleCloseNewItems();
            setSubmitted(false);
            setIsSubmitted('idle');
        } else {
            setSubmitted(true);
            setIsSubmitted('idle');
        }
    }

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleSearch = (event) => {
        setSearchValue(event.target.value)
    };

    const newItemProps = {
        provider_products, provider_rooms, provider_services, uploadProps,
        handleToggleServices, handleToggleRooms, handleToggleProducts,
        handleAddServicesRow, handleRemoveServicesRow, handleChangeService, getServiceOptions, setServiceDetails, 
        handleAddRoomRow, handleRemoveRoomRow, handleChangeRoom, room_options,isResolved,
        handleAddItems, submitted,isBlocking,isBlockDialog,setNewItems,setIsBlockDialog,resetField,
        handleAddProductRow, handleRemoveProductRow, handleChangeProduct, getProductOptions, setProductDetails,
    }

    return {
        handleDeactivate, handleCloseDeactivate, openDeactivate, handleOpenDeactivate,
        provider_items,
        handleCloseEditItem, openEditItem, handleOpenEditItem,
        openNewItems, newItemProps, handleCloseNewItems, handleOpenNewItems,
        current_item, handleItemUpdate, handleChangeItem, 
        closeSnackbar, handleChangeRowsPerPage, handleChangePage, handleSearch,
        searchValue, page, rowsPerPage,isLoading, isSuccess, isError,
        uploadProps, exportItems
    }
}


export {useItemsProviderAllows}