import {useEffect, useState} from "react";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {useAddEmployee} from "./useAddEmployee";

export const useEmployeeProfile = (props) => {
    const [employee, setEmployee] = useState({});
    const {employee_id} = props.match.params;
    const {actions} = props;
    const {addEmployeeProps, isResolved} = useAddEmployee(actions)

    useEffect(() => {
        const formData = new FormData();
        formData.append('employee_id', employee_id);
        axios.post(`${config.smsUrl}/chumanresource/view_employee_profile`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            setEmployee(dt)
        }).catch(e => {
            logoutErrorMessage(e, null, actions)
        })
    }, [isResolved]);

    const closeSnackBar = () => {
        actions.snackbarActions.hideSnackbar()
    };
    return {employee, employee_id, closeSnackBar, addEmployeeProps}
}