import React from "react";
import Form from "react-bootstrap/Form";
import {useTheme} from "@material-ui/core/styles";
import { titleCase } from "../Users/addUser";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import { TableHead } from "../Utils/FormInputs/Label";
import TextField from "../Utils/FormInputs/TextField";
import Textarea from "../Utils/FormInputs/Textarea";
import { ErrorMessage } from "../Utils/styledComponents";


const EmergencyTreatment = ({retrieveDrugs, clickDrug, handleChangeItems, handleAddRow,
                                      removeRow,  submitted, drugs=[], prescription=[] }) => {
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
 
    return (
        <div className="table-responsive">
            <table
                className={`table table-sm table-bordered billing-invoice-table procedure-table ${palette.type === 'dark' && 'dark-table dark-table-bordered'}`}>
                <thead>
                    <TableHead width="200px" title='Drug Name' toolTip='Enter drug name' isRequired></TableHead>
                    <TableHead width="200px" title='Dosage' toolTip='Enter dosage'></TableHead>
                    <TableHead width="200px" title='Route' toolTip='Enter route' ></TableHead>
                    <TableHead width="200px" title='Quantity' toolTip='Enter quantity' ></TableHead>
                    {/* <TableHead width="200px" title='Intake Time' toolTip='Enter intake time' isRequired></TableHead> */}
                    <TableHead width="200px" title='Comments' toolTip='Enter comments' ></TableHead>
                    <th width="80px"/>
                </thead>
                <tbody>
                {prescription.map((item, index) => (
                    <tr key={index} id="drugs-row">
                        <td>
                           <TextField
                                    value={item.drug_name ? titleCase(item.drug_name) : item.drug_name}
                                    type="text"
                                    name="drug_name"
                                    onChange={(e) => retrieveDrugs(e, index)}
                                />
                                {(submitted && !item.drug_name) ?
                                    <ErrorMessage>Drug name is required</ErrorMessage> :
                                    item.isError &&
                                    <ErrorMessage>Please select a valid drug</ErrorMessage>}

                            {item.showDrug &&
                            <Form.Control as="select" className="drug-ul prescription-ul" multiple>
                                {drugs.map((val, idx) => (
                                    <option key={idx} value={val.value} className="drug-li"
                                            onClick={() => clickDrug({
                                                value: val.value,
                                                innerText: val.label
                                            }, index)}>{val.label}</option>
                                ))}
                            </Form.Control>}
                        </td>
                        <td>
                            <TextField value={item.dosage} name="dosage" 
                                              onChange={(e) => handleChangeItems(e, index)} type="text"/>
                        </td>
                        <td>
                            <TextField type="text" value={item.route} name="route"
                                onChange={(e) => handleChangeItems(e, index)}/>
                        </td>
                        <td>
                            <TextField type="number" value={item.quantity} name="quantity"
                                          onChange={(e) => handleChangeItems(e, index)}/>
                        </td>
                        {/* <td>
                            <TextField type="time" value={item.intake_time} name="intake_time"
                                          onChange={(e) => handleChangeItems(e, index)}/>
                            {submitted && !item.intake_time ? <ErrorMessage>This is a required field</ErrorMessage>:null}
                        </td> */}
                        <td>
                            <Textarea value={item.comments} name="comments"
                                          onChange={(e) => handleChangeItems(e, index)}/>
                        </td>
                        <td>
                            <button onClick={() => removeRow(index)} type="button"
                                    className="btn btn-sm sms-btn-dismiss" id="remove-row"
                                    data-testid="presc-remove-row">
                                <FontAwesomeIcon icon={faTrash}/>
                            </button>

                        </td>
                    </tr>))}
                    <tr>
                        <td colSpan={6}>
                            <button type="button" onClick={handleAddRow} className="btn btn-sm sms-gray-btn"
                                    id="add-row">Add another line
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}
export {EmergencyTreatment}



