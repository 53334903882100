import React from "react";
import CustomTable from "../Utils/Tables/CustomTable";
import FilterResults from "react-filter-search";
import TableBody from "@material-ui/core/TableBody";
import {getComparator, stableSort} from "../Utils/Tables/sortingFunctions";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import *  as snackbarActions from "../../actions/snackbarActions";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import PageTitle from "../Utils/smsTitle";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faVials} from "@fortawesome/free-solid-svg-icons/faVials";
import {useParameterList} from "./CustomHooks/useParameterList";
import { Container } from "../Utils/styledComponents";
import { history } from "../../Helpers/history";
import {ReusableRouteTabs} from "../Utils/Dialogs/ReusableTabs";
import AddLabParameters from "./AddLabParameters";
import CustomDialog from "../Utils/Dialogs/CustomDialog";


const headData = [
    {id: 'sl', numeric: false, disablePadding: false, label: 'Sl'},
    {id: 'labTest', numeric: false, disablePadding: false, label: 'Lab test'},
    {id: 'status', numeric: false, disablePadding: false, label: 'Status'},
    {id: 'set_up_parameters', numeric: false, disablePadding:false, label: 'Setup parameters'},
    {id: 'specify_category', numeric: false, disablePadding: false, label: 'Specify category'},
];

export const ParameterList = ({actions, snackbars}) => {
    const {searchValue, order, orderBy, page, rowsPerPage,  parameters, 
        handleSearch, handleRequestSort, handleChangePage, handleChangeRowsPerPage, closeSnackbar,
    parameterProps,openDialog, handleOpenDialog, handleCloseDialog,
    isLoading,isSuccess,isError,editProps, openEdit, handleOpenEdit,
        handleCloseEdit, handleMakeExternal, isSaving} = useParameterList(actions)
    const allParams = !parameters ? [] : parameters;
    const {openBar, type, message} = snackbars;
    const components = [{label: 'Lab Tests', path: '/labsettings'},
    {label: 'Lab Types', path: '/labtype'},
    {label: 'Sample Types', path: '/addsample'},]
    return (
        <div>
            <PageTitle title="Laboratory Settings"/>
            <SubHeader title="Laboratory" subTitle="Laboratory settings">
                <FontAwesomeIcon icon={faVials}/>
            </SubHeader>
            <CustomDialog open={openDialog} handleClose={handleCloseDialog}  isContentOverflow={false}
            title='Add Parameters' maxWidth="lg">
                <AddLabParameters {...parameterProps}/>
            </CustomDialog>
            <CustomDialog open={openEdit} handleClose={handleCloseEdit}  isContentOverflow={false}
            title='Edit Parameters' maxWidth="lg">
                <AddLabParameters {...editProps}/>
            </CustomDialog>
            <Container>
                <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/>
                <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>
                <CustomTable  handleChangePage={handleChangePage}
                                handleChangeRowsPerPage={handleChangeRowsPerPage}
                                headData={headData} handler={handleSearch} page={page} rowsPerPage={rowsPerPage}
                                handleRequestSort={handleRequestSort} data={allParams} colSpan={headData.length} order={order}
                                orderBy={orderBy} filter>
                        <FilterResults value={searchValue} data={allParams}
                            renderResults={results => (
                                <TableBody>
                                    {isLoading ? <TableRow>
                                        <TableCell colSpan={headData.length} align="center"><img src="/images/smsloader.gif"
                                                    alt="loader"/></TableCell>
                                    </TableRow>:null}
                                    {isSuccess ? allParams.length > 0 ?  stableSort(results, getComparator(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((item, index) => {
                                            const count = (page * rowsPerPage) + index + 1;
                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    key={index}
                                                    className='mui-table-row'>
                                                    <TableCell>{count}</TableCell>
                                                    <TableCell>{item.hospital_test_name}</TableCell>
                                                    <TableCell>{item.is_external === true ? 'External':'Internal'}</TableCell>
                                                    <TableCell>
                                                        {item.result_type === null || item.result_type === 2 ?
                                                            <button onClick={()=>handleOpenDialog(item.hospital_test_id)}
                                                                    className="btn btn-sm sms-btn small-btn mr-1">Add  parameters</button>: null}

                                                        {item.result_type !== null ? <button onClick={()=>handleOpenEdit(item.hospital_test_id)}
                                                            className="btn btn-sm sms-info-btn small-btn mr-1">Edit  parameters</button>: null}
                                                    </TableCell>
                                                    <TableCell>
                                                        <button onClick={()=>handleMakeExternal(item.hospital_test_id, item.is_external)}
                                                                className="btn btn-sm sms-gray-btn small-btn mr-1">{isSaving ? 'Saving...':`${item.is_external === false ? 'Make external':'Make Internal'}`}</button>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        }):<TableRow>
                                    <TableCell colSpan={headData.length} align="center">No records found</TableCell>
                                </TableRow>:null}
                                {isError ? <TableRow>
                                    <TableCell colSpan={headData.length} align="center">The server did not return a valid response</TableCell>
                                </TableRow>:null}
                                </TableBody>
                            )}/>
                </CustomTable>
            </Container>
         
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(ParameterList);
