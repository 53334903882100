import React from 'react';
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import PrintIcon from "@material-ui/icons/Print";
import {useAccountingStyles} from "../Expenses/hooks/useAccountingStyles";
import { RightAlignedContainer } from '../../Utils/styledComponents';


const PrintPdfButtons = ({handlePrint, children}) =>{

    const classes = useAccountingStyles();
    return (
        <RightAlignedContainer>
            {/* <div className="btn-group mt-2"> */}
              
                <button onClick={handlePrint }
                        className='btn btn-sm btn-default'>
                    <PrintIcon classes={{root: classes.icon}}/></button>
                {children}
            {/* </div> */}
        </RightAlignedContainer>
    )
}
export {PrintPdfButtons}