import {useEffect, useRef, useState} from "react";
import axios from "axios";
import {config} from "../../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import {exportPDF} from "../../../Utils/downloadPDF";
import {titleCase} from "../../../Users/addUser";
import { useAddSupplier } from "./useAddSupplier";

export const useSupplierList = (actions) =>{
    const [searchValue, setSearchValue] = useState('');
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('sl');
    const [item, setItem] = useState({supplier_id:'', status:''});
    const [isDelete, setIsDelete] = useState('idle')
    const [loading, setLoading] = useState(false);
    const [suppliers, setList] = useState([]);
    const [openDelete, setDelete] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const [total_count, setTotalCount] = useState(0);
    const [allRecords, setAllRecords] = useState([]);
    const csvRef = useRef();

    const {supplierProps, isResolved, 
    openDialog, handleOpenDialog, handleCloseDialog} = useAddSupplier(actions)


    const isDeleted = isDelete === 'deleted'
    useEffect(() => {
        setLoading(true);
        const formData = new FormData()
        formData.append('page', activePage)
        formData.append('search', searchValue ? titleCase(searchValue):'')
        axios.post(`${config.smsUrl}/csupplier/manage_supplier`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const cats = !dt.suppliers_list ? [] : dt.suppliers_list;
            const count = !dt.total_count ? 0 : dt.total_count;
            setList(cats);
            setTotalCount(count);
            setLoading(false);
        }).catch((err) => {
            logoutErrorMessage(err, null, actions);
            setLoading(false);
        })
    }, [activePage, isResolved,isDeleted, searchValue]);

    const retrieveAllRecords = () => {
        return axios.get(`${config.smsUrl}/csupplier/retrieve_all_suppliers`)
    }

    const handleOpenDelete = (obj) => {
        const {supplier_id, status} = obj;
        setDelete(true);
        setItem({supplier_id, status})
    };

    const handleChangeActivePage = (pg) =>{
        setActivePage(pg)
    };

    const handleCloseDelete = () => {
        setDelete(false)
    };

    const handleDelete = () => {
        const {supplier_id, status} = item;
        const formData = new FormData();
        const isActive = status === 1 ? 0 : status === 0 ? 1 : ''
        formData.append('supplier_id', supplier_id);
        formData.append('status', isActive);
        setIsDelete('pending')
        axios.post(`${config.smsUrl}/csupplier/delete_supplier`, formData).then(() => {
            const message = status === 1 ? 'deactivated' : status === 0 ? 'activated' : ''
            actions.snackbarActions.snackSuccess(`Supplier ${message} successfully`);
            setIsDelete('deleted')
            setDelete(false)
        }).catch(err => {
            setIsDelete('rejected')
            errorMessages(err, null, actions)
        })
    };

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const handleSearch = (event) => {
        setSearchValue(event.target.value)
    };


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const downloadPdf = () => {
        const formData = new FormData();
        formData.append('search', searchValue)
        retrieveAllRecords().then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const items = !dt.total_manufacturers ? [] : dt.total_manufacturers;
            exportPDF("Suppliers", [["SL", "Supplier Name", "Contact", "Email", "Address"]],
                items.map((item, index) => [index += 1, titleCase(item.supplier_name),  item.supplier_contact,
                    item.supplier_email,item.supplier_adress,
                ]),
                "Suppliers .pdf");
            setAllRecords(items);
        }).catch((err) => {
            logoutErrorMessage(err, null, actions);
        })

    }

    const downloadCsv = () => {
        retrieveAllRecords().then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const items = !dt.total_manufacturers ? [] : dt.total_manufacturers;
            setAllRecords(items);
            csvRef.current.link.click()
        }).catch((err) => {
            logoutErrorMessage(err, null, actions);
        })
    }

    return {searchValue, order, orderBy, loading, suppliers, openDelete, activePage,item,
        total_count, handleOpenDelete, handleChangeActivePage, handleCloseDelete, handleDelete,
        closeSnackbar, handleSearch, handleRequestSort, csvRef,allRecords,downloadCsv, downloadPdf,
    supplierProps, handleOpenDialog, openDialog, handleCloseDialog}
}