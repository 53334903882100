import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../actions/snackbarActions";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import {useGeneralLabReport} from "./CustomHooks/useGeneralLabReport";
import PageTitle from "../Utils/smsTitle";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faVials} from "@fortawesome/free-solid-svg-icons/faVials";
import Card  from "@material-ui/core/Card";
import { usePrint } from "../Utils/Templates/usePrint";
import { history } from "../../Helpers/history";
import PatientLabReport from "./PatientLabReport";
import {BackButton} from "../Utils/Buttons/DataExportationButton";
import {RightAlignedContainer} from "../Utils/styledComponents";


const GeneralLabReport = (props) => {

    const  {report, labTests, closeSnackbar, handleOpenItem} = useGeneralLabReport(props)
    const {openBar,message,type} = props;
    const handleOnAfterPrint = () => {
        history.push('/labreports');
    }
    const {componentRef, handlePrint, a4Styles, fontSize}  = usePrint(`Lab_Report`, handleOnAfterPrint);
    const a4Paper = a4Styles();


    return (
        <div>
            <PageTitle title='General Lab Report'/>
            <SubHeader title="Laboratory" subTitle='General Lab Report'>
                <FontAwesomeIcon icon={faVials}/>
            </SubHeader>
            <div className='mui-tables'>
            
                <div className="general-lab-report">
                    <div className='row'>
                       <div className='col-lg-6'>
                           <BackButton to='/labreports' text='Lab Reports' data-testid="lab-reports-back-button"/>
                       </div>
                        <div className='col-lg-6'>
                            <RightAlignedContainer>
                                <button onClick={handlePrint} className="btn sms-amber-btn  btn-sm">Print</button>
                            </RightAlignedContainer>
                        </div>
                    </div>

                    <MainSnackbar message={message} open={openBar} handleCloseBar={closeSnackbar} variant={type}/>
                    <Card  id="prescription-print" className={a4Paper.centreItems}>
                        <div className="p-general-report" ref={componentRef} id="general-lab-report" >
                            {fontSize}
                          <PatientLabReport labTests={labTests} data={report} handleOpenItem={handleOpenItem}/>
                        </div>
                    </Card>
                </div>
            </div>
            
        </div>
    )
};

function mapStateToProps(state) {
    return {
        snackbars:state.snackbar
    }
}

function mapDispatchToProps(dispatch) {
    return {actions:{snackbarActions:bindActionCreators(snackbarActions, dispatch)}}
}
export default connect(mapStateToProps, mapDispatchToProps)(GeneralLabReport);
