import {useEffect, useState} from "react";
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import {history} from "../../../../Helpers/history";


const submitForm = (url, formData) => {
    return axios.post(`${config.smsUrl}/cpatient/${url}`, formData);
};

export const useClearOutPatientDebt = (actions, match, receipt) => {
    const [payment_method, setPaymentMethod] = useState('');
    const [p_number, setPatientNumber] = useState({patient_name: '', patient_number: '', address:'', phone_no:''});
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const {provider_id, pay_status} = match.params;
    const [ids, setIds] = useState({provider_id: '', receipt_number: ''});
    const [open_receipt, setOpenReceipt] = useState(false);
    const [bills, setBills] = useState([]);
    const [billDetails, setBillDetails] = useState([]);
    const [totals, setTotals] = useState({grand_total: 0, due_amount_total: 0, payment_total: 0,
        total_bill_amount:0})
    const [amount_paid,setAmountPaid] = useState('')
    const [memo, setMemo] = useState('');
    const [payTypes, setPayTypes] = useState([])
    const [invoices, setInvoices] = useState([])
    const user = JSON.parse(sessionStorage.getItem('user'));


    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;

    useEffect(() => {
        const formData = new FormData();
        formData.append('provider_id', provider_id);
        formData.append('pay_status', pay_status);

        const url = receipt ? 'cbilling/view_receipt' : 'cpatient/previous_balance'
        axios.post(`${config.smsUrl}/${url}`, formData)
            .then(res => {
                const details = res.data;
                const data = !details ? {} : details;
                const inv = !data.previous ? [] : data.previous;
                const rt = data.bill ?? [];
                const dt = receipt ? rt : inv;
        
                const p_invoice = data.patient_info ?? [];
                const p_receipt = data.patient_info ?? {};
                const item = receipt ? p_receipt : p_invoice[0] ?? {};
                const {first_name, last_name, patient_number, address, phone_no,patient_firstname,patient_lastname} = item
                const f_name = receipt ? patient_firstname : first_name;
                const l_name = receipt ? patient_lastname : last_name
                const patient_name = `${!f_name ? "" : f_name} ${!l_name ? "" : l_name}`;
                setPatientNumber({patient_name, patient_number, address, phone_no})
                setBillDetails(dt)
             
            }).catch(error => {
            logoutErrorMessage(error, null, actions)
        });
    }, []);

    useEffect(() => {
        axios.get(`${config.smsUrl}/cbilling/payment_methods`).then(res => {
            const data = res.data;
            const all_data = !data ? {}: data;
            const arr = all_data.data ?? [];
            const list = arr.map(item=>({value:item.id, label:item.payment_type_name}))
            setPayTypes(list)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, []);

    useEffect(() => {
        setBills(billDetails)
        
        const arr = billDetails.map(item=>{
            const {invoice,receipts} = item;
            return ({
                total_mount:invoice.quantity * invoice.rate,
                total_paid:calculateTotal(receipts)
            })
        })
        const arr1 = billDetails.map(item=>{
            const {invoice,receipts} = item;
            return ({ ...invoice,
                total_amount:invoice.quantity * invoice.rate,
                total_paid:calculateTotal(receipts)
            })
        })
        setInvoices(arr1)
        calculateTotalAmount(arr)
    }, [billDetails])

    const calculateTotal = (arr) => {
        return arr.reduce((sum, item) => {
            return +item.amount_paid + +sum
        }, 0)
    }

    const calculateTotalAmount = (arr) => {
        const t_amount = arr.reduce((sum, item) => {
            return +item.total_mount + +sum
        }, 0)

        const p_total = arr.reduce((sum, item) => {
            return +item.total_paid + +sum
        }, 0)

        const due_amt = t_amount - p_total
        setTotals({grand_total: p_total, due_amount_total:due_amt, payment_total: 0, total_bill_amount: t_amount})
    }

    const handleChangeBillPayment = (e) => {
        setAmountPaid(e.target.value)
    }

    const handleBlurPayment = () => {
        calculateTotalAmount(bills)
    }

    const handleFullAmount = (e, bill_id) => {
        const {checked} = e.target;
        const arr = bills.map(item => {
            if (item.bill_id === bill_id) {
                return {...item, isFull: checked, payment: checked ? item.amount_due : ''}
            }
            return item
        })
        setBills(arr)
        calculateTotalAmount(arr)
    }


    const handleChangePayment = (event) => {
        setPaymentMethod(event.target.value)
    };

    const handleChangeMemo = (event) =>{
        setMemo(event.target.value)
    }


    const handleSubmit = (event) => {
        event.preventDefault();
        const user = JSON.parse(sessionStorage.getItem('user'));
        const userObj = !user ? {} : user;
        const token = !userObj.token ? [] : userObj.token;
        const _token = !token[1] ? {} : token[1];
        const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;
        const { due_amount_total} = totals;
        const bill_mode = bills.map(item => {
            const {invoice} = item;
            return invoice.bill_mode
            
        }) 

        const name= bills.map(item => {
            const{invoice} = item
            return({
                account_name: invoice.account_name
            })
        })

        const card= bills.map(item => {
            const{invoice} = item
            return({
                card_number: invoice.card_number
            })
        })

        const billed_to = bills.map(item => {
            const{invoice} = item
            return({
                billed_to: invoice.billed_to
            })
        })
        const arr  = bills.map(item => {
            const {invoice, receipts} = item;
            const obj = receipts[0] ?? {};
            const t_amount = invoice.quantity * invoice.rate
            const p_amount = invoice.pay_status === 3 ? 0 :  obj.amount_paid ?? 0
            return ({
                bill_id: invoice.bill_id,
                service_name: invoice.service_name,
                date_created: invoice.date_created,
                transaction_id: invoice.invoice_no,
                total_amount: p_amount,
                amount : invoice.quantity * invoice.rate,
                amount_due: invoice.pay_status === 3 ? t_amount - p_amount  :obj.balance,
                bill_type: +invoice.bill_type,
                invoice_no:invoice.invoice_no,
                pay_status:invoice.pay_status,
                product_id:invoice.product_id,
                quantity:invoice.quantity,
                rate:invoice.rate,
                service_id:invoice.service_id,
                provider_id:invoice.provider_id,
                payment: '',
                isFull: false,
            })
        })
        setSubmitted(true);
        if (payment_method && +amount_paid && +amount_paid <= due_amount_total) {
            setIsSubmitted('pending');
            submitForm('pay_debt', {provider_id: +provider_id, pay_type: +payment_method, bill_mode:bill_mode[0], 
                received_by:user_roles_id,memo,
                amount_paid:+amount_paid, invoices_paid:arr,account_name: name, card_number: card, billed_to: billed_to}).then((res) => {
                const data = res.data;
                const dt = data ? data : {};
                const {provider_id, receipt_numbers} = dt;
                let receipt_number;
            
                if (typeof data.receipt_numbers === "string"){
                    receipt_number = receipt_numbers ?? '';
                }else if (typeof data.receipt_numbers === "number"){
                    receipt_number = receipt_numbers ?? 0;
                }else {
                    const numbers = receipt_numbers ?? [];
                    receipt_number = numbers.join('-');
                }
                setIds({provider_id, receipt_number});
                setOpenReceipt(true);
                actions.snackbarActions.snackSuccess('Deposit made successfully')
                setPaymentMethod('');
                setSubmitted(false);
                setIsSubmitted('resolved');
            }).catch(err => {
                setIsSubmitted('rejected');
                errorMessages(err, null, actions)
            })
        }
    };

    const handleOpenReceipt = () => {
        const {receipt_number} = ids
        
        setOpenReceipt(false);
        history.push(pay_status ? `/debtorsdetails/${provider_id}/${pay_status}/${receipt_number}` :
            `/debtorsdetails/${provider_id}/${receipt_number}`)
    }

    const handleCloseReceipt = () => {
        setOpenReceipt(false);
        history.push('/debtors')
    }


    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };
    return {payTypes, p_number, closeSnackbar, open_receipt, payment_method, handleChangePayment,
        handleOpenReceipt, handleCloseReceipt, bills, handleChangeBillPayment,memo,handleChangeMemo,
        handleFullAmount, handleBlurPayment, totals, handleSubmit, isSubmitted, submitted, amount_paid,
        invoices
    }
}
