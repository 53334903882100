import {useEffect, useState} from "react";
import {dateConvert} from "../../../Utils/ConvertDate";
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {logoutErrorMessage} from "../../../../Helpers/ErrorMessages";



export const useLabScanResults = (actions,patient_type) =>{
    const [searchValue, setSearchValue] = useState('');
    const [pendingRequests, setPendingRequests] = useState([]);
    const [loading, setLoading] = useState('idle');
    const [total_count, setCount] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [refresh, setRefresh] = useState(false);

    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;

    const retrieveResults = () =>{
        setLoading('pending');
        const formData = new FormData();
        const url = patient_type === 1 ? 'finished_lab_scanrequest_list':'finished_inlab_scanrequest_list';
        if (patient_type === 1){
            formData.append('user_roles_id', user_roles_id);
            formData.append('ls_date', dateConvert());
        }
        formData.append('search', searchValue);
        axios.post(`${config.smsUrl}/cdoctor/${url}/${activePage}`, formData).then(res => {
            const data = res.data;
            const dt = !data.scanreq_list ? [] : data.scanreq_list;
            const count = !data.total_count ? 0 : data.total_count;
            setPendingRequests(dt);
            setCount(count);
            setLoading('success')
        }).catch(err => {
            setLoading('error');
            logoutErrorMessage(err, null, actions)
        })
    }

    useEffect(() => {
        retrieveResults()
        const interval = setInterval(()=>{
            retrieveResults()
        }, 10000)
        return () =>clearInterval(interval)

    }, [searchValue, activePage]);

    const handleSearch = (event) => {
        setSearchValue(event.target.value);
    };

    const handleRefresh = () =>{
        setRefresh(!refresh)
    }

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const handleChangeActivePage = (page) => {
        setActivePage(page)
    };

    const isLoading =  loading === 'pending';
    const isSuccess = loading === 'success';
    const isRejected = loading === 'error';




    return {searchValue, pendingRequests, loading, total_count, activePage,
        handleSearch, closeSnackbar, handleChangeActivePage, handleRefresh, isLoading, isSuccess, isRejected}
}