/** @jsxImportSource @emotion/react */
import {css } from '@emotion/react'
import React, {useEffect, useState} from "react";
import {formatDate, formatDateTime} from "../Utils/ConvertDate";
import {titleCase} from "../Users/addUser";
import {detailsTable, table, tableBordered, tableMargin, textWeight} from "../../styles/tableStyles";
import {DateText, ReportList} from "../Utils/Lists/ReportList";
import {ItemsTable, PrintTemplate} from "../Utils/Templates/PrintTemplate";
import {splitNotes} from "../Radiology/RadiologyReport";
import * as colors from "../../styles/colors";
import axios from "axios";
import {config} from "../../Helpers/env";
import {logoutErrorMessage} from "../../Helpers/ErrorMessages";

const tdComments = css`
    border-bottom: 1px solid ${colors.text01};
`

const approvalTable = css`
     margin-top: 3rem;
 `

const zeroBorder = css`
     border-bottom: 0;
 `

const zeroMargin = css`
  margin-bottom: 0;
`
const approvalSpan = css`
  border-bottom: 1px solid ${colors.text01};
  display: inline-block;
`

const headData = [{key:'parameter',value:'Test'}, {key:'result',value:'Result'}, //{key:'flag',value:'flag'},
    {key:'unit',value:'Unit'},{key:'ref_range',value:'Reference Range'}]

const noneRefData =  [{key:'parameter',value:'Parameter'}, {key:'result',value:'Result'}]

const QualitativeTable = ({test_name, params,comments}) =>(
    <table css={[table,detailsTable, tableMargin, tableBordered, zeroMargin]}>
        <tbody>
            <tr>
                <td css={[textWeight]}><span><strong>{test_name}</strong></span></td>
                {params.map(test=>{
                    const isQualitative = (
                        test.sub_test_name === '+ve/-ve' ?
                                <span>{test.test_value === '1' ? 'Positive' :
                                    'Negative'}</span> : test.sub_test_name === 'reactive/non-reactive' ?
                                    <span>{test.test_value === '3' ? 'Reactive' : 'Non-reactive'}</span> :
                                    test.sub_test_name === 'blood_group' ?
                                        <span>{test.test_value}</span> : null
                    )
                    return(
                        <td>
                            <span>{test.result_type === 3 ? splitNotes(test.test_value) : isQualitative}</span>
                        </td>
                    )
                })}
            </tr>
            {comments ? <tr>
                <td css={[zeroBorder]} colSpan={2}  style={{paddingBottom: 0}}>
                    <span css={[textWeight]}>Comments</span><br/>
                    <span>{comments ? splitNotes(titleCase(comments)) : comments}</span>
                </td>
            </tr>: null}
        </tbody>
    </table>
)


export const LabResultsTable = ({item}) => {
    const para = item.params ? item.params : [];
    const pr = para[0] ? para[0] : {};
    const comments = pr.notes ? pr.notes : '';
    const isReferenceRange =item?.params.some(item=>item.reference_range)

    const parentHeadData = (
        <tr>
            <td colSpan={headData.length} css={[textWeight]} ><span><strong>{item?.hospital_test_name}</strong></span></td>
        </tr>
    )


    return (
        isReferenceRange  ? <ItemsTable headData={isReferenceRange ? headData : noneRefData} zeroMargin={zeroMargin} parentHeadData={parentHeadData}>
            <tbody>
            {item.params.map((test) => {
                return (
                    <tr key={test.hospital_test_id}>
                        <td>
                                    <span>{test.result_type === 2 ? titleCase(test.sub_test_name) : (test.result_type === 1 && test.sub_test_name === 'blood_group') ?
                                        'Blood Group' : test.result_type === 1 ? 'Result' : 'Observations'}</span>
                        </td>
                        {test.result_type === 1 ?
                            <td>
                                {test.sub_test_name === '+ve/-ve' ?
                                    <span>{test.test_value === '1' ? 'Positive' :
                                        'Negative'}</span> : test.sub_test_name === 'reactive/non-reactive' ?
                                        <span>{test.test_value === '3' ? 'Reactive' : 'Non-reactive'}</span> :
                                        test.sub_test_name === 'blood_group' ?
                                            <span>{test.test_value}</span> : <p/>}
                            </td> :
                            <td colSpan={test.result_type === 3 ? 4 : 0}>
                                <span>{test.result_type === 3 ? splitNotes(test.test_value) : test.test_value}</span>
                            </td>}
                        {isReferenceRange ? <td>
                            <span>{test.unit ? test.unit : 'NA'}</span>
                        </td>: null}
                        {isReferenceRange ? <td>
                            <span>{test.reference_range ? test.reference_range : 'NA'}</span>
                        </td> : null}

                        {/*{test.result_type !== 3 && <td>
                                    <span>{flag}</span>
                                </td>} */}

                    </tr>
                )
            })}
            {comments ? <tr>
                <td css={[zeroBorder]} colSpan={isReferenceRange ? headData.length : 2}  style={{paddingBottom: 0}}>
                    <span css={[textWeight]}>Comments</span><br/>
                    <span>{comments ? splitNotes(titleCase(comments)) : comments}</span>
                </td>
            </tr>: null}
            {/*<tr>*/}
            {/*    <td colSpan={headData.length} css={[tdComments]}>*/}
            {/*        */}
            {/*    </td>*/}
            {/*</tr>*/}
            </tbody>

        </ItemsTable>:
        <QualitativeTable test_name={item?.hospital_test_name} params={item.params} comments={comments}/>


    )
};



const PatientLabReport = ({data, labTests, handleOpenItem}) => {
    const {patient_number, patient_firstname, patient_lastname, age, gender,
        time_requested, address,labtech_firstname,labtech_lastname,doctor_firstname,
        doctor_lastname,approved_first_name,approved_last_name, visit_id} = data;
    const patient_name = `${!patient_firstname ? "" : patient_firstname} ${!patient_lastname ? "" : patient_lastname}`;
    const lab_tech_name = `${!labtech_firstname ? "" : labtech_firstname} ${!labtech_lastname ? "" : labtech_lastname}`;
    const approved_name = `${!approved_first_name ? "" : approved_first_name} ${!approved_last_name ? "" : approved_last_name}`;
    const doctor_name =  `${!doctor_firstname ? "" : doctor_firstname} ${!doctor_lastname ? "" : doctor_lastname}`
    const date = time_requested ? formatDateTime(time_requested) : '';
    const [visitDetails, setVisitDetails] = useState({})

    useEffect(()=>{
        if(!visit_id){
            return
        }
        axios.get(`${config.smsUrl}/cvisit/${visit_id}`).then(res=>{
            const data = res.data;
            const dt = !data ? {} : data;
            setVisitDetails(dt)
        }).catch(err=>{
        })
    },[visit_id]);

    const patientDetails = (
        <tbody>
        <tr>
            <td><span>Patient Name: </span> <span css={[textWeight]}>{patient_name}</span></td>
            <td><span>Patient No.: </span> <span css={[textWeight]}>{patient_number}
                </span></td>
        </tr>

        <tr>

            <td><span>Gender: </span> <span css={[textWeight]}>{gender}</span></td>
            <td><span>Date & Time:</span> <span css={[textWeight]}>{date}</span></td>
        </tr>
        <tr>
            <td><span>Address: </span> <span css={[textWeight]}>{address}</span></td>
            <td><span>Lab Technician:</span> <span css={[textWeight]}>{lab_tech_name}</span></td>
        </tr>
        <tr>
            <td><span>Requested by: </span> <span css={[textWeight]}>{doctor_name}</span></td>
            <td><span>Payment Mode: </span> <span css={[textWeight]}>{visitDetails.is_on_insurance === 1 ? 'CREDIT' : visitDetails.is_on_insurance === 0 ?  'CASH':''}</span></td>
        </tr>
        {visitDetails.is_on_insurance === 1 ? <tr>
            <td><span>Provider Name: </span> <span css={[textWeight]}>{visitDetails?.insurance_provider_name}</span></td>
            <td><span>Account Name: </span> <span css={[textWeight]}>{visitDetails?.account_name}</span></td>
        </tr>: null}
        </tbody>
    )
    const details = (
        labTests.map((item, index) => {
            return (
                <LabResultsTable key={index} item={item} handleOpenItem={() => handleOpenItem(index)} time_requested={
                    <DateText  primary={formatDate(time_requested)}/>}/>
            )})
    )

    const approval = (
        <table css={[table, detailsTable, approvalTable]}>
            <tbody>
            <tr>
                <td><span>Carried Out By </span></td>
                <td/>
                <td align='right'><span>Approved By</span></td>
            </tr>
            <tr>
                <td><span css={[approvalSpan]}>{lab_tech_name}</span></td>
                <td/>
                <td align='right'><span css={[approvalSpan]}>{approved_name}</span></td>
            </tr>
            </tbody>
        </table>
    )

    const itemDetails =(
        <>
            {details}
            {approval}
        </>

    )
    
    return (
        <PrintTemplate title='Lab Report' {...{patientDetails,itemDetails}}/>

    )
};
export default PatientLabReport;
