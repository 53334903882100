import {useState} from "react";
import axios from "axios";
import { config } from "../../../Helpers/env";
import { logoutErrorMessage } from "../../../Helpers/ErrorMessages";

export const useDDOFilterComponent = (actions, activeTablePage, setSurgeryList, surgeryStatus)=>{

    const [currentstate, setCurrentState] = useState({start_date: '', end_date: '',filter_by:'1', department:'', filter_operation_class:''})
    const [submittedDDOFilter, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');

    const {start_date, end_date,filter_by, department, filter_operation_class} = currentstate;
    const [loading, setLoading] = useState('idle');

    const handleSubmitFilter = (event) =>{
        event.preventDefault();
        setSubmitted(true);

        setLoading('pending');
        setIsSubmitted('pending')
        const formData = new FormData();

        formData.append("status", surgeryStatus);
        formData.append("page", activeTablePage);
        formData.append('option', Number(filter_by));

        if (filter_by === '1') {
            // filter by operation date
            if (start_date && end_date) {
                formData.append('start_date', start_date);
                formData.append('end_date', end_date);
                formData.append('search', "");
            } else {
                return;
            }
            
        } else if (filter_by === '2') {
            // filter by department/surgery_name
            if (department) {
                formData.append('department_id', department);
                formData.append('search', "");
            } else {
                return;
            }
            
        } else if (filter_by === '3') {
            //filter by operation class
            if (filter_operation_class) {
                formData.append('operation_class', filter_operation_class);
                formData.append('search', "");
            } else {
                return;
            }
            
        }

        axios.post(`${config.smsUrl}/surgery/list/${activeTablePage}`, formData).then(res => {

            const response = res['data'];
            const dt = response ? response : [];

            const surgery_arr = dt.map((item, index) => ({
                count: (activeTablePage - 1) * 10 + (index + 1),
                date: item.begin_datetime,
                surgery_id: item.id,
                visit_id: item.visit_id,
                patient_number: item.patient_number,
                patient_name: `${item.first_name} ${item.last_name}`,
                status: item.status,
                operation_date: item.operation_date,
                operation_class: item.operation_class,
                surgery_name : item.service_name,
                lead_surgeon: item.lead_surgeon,
                service_id : item.service_id,
                surgery_department : item.department_name,
            }));

            setLoading('success')
            setIsSubmitted('resolved')
            setSurgeryList({surgeries: surgery_arr, total_count: surgery_arr.length});
            //setOriginalSurgeryList({surgeries: surgery_arr, total_count: surgery_arr.length});
            setSubmitted(false);
        }).catch((err) => {
            logoutErrorMessage(err, null, actions);
            setLoading('error')
            setIsSubmitted('rejected');
            setSubmitted(false);
        })

    }

    const handleFilterChange = (event) =>{
        setSubmitted(false);
        const {name, value} = event.target;
        setCurrentState({...currentstate,[name]:value})
    }
    
    return {
        currentstate, handleSubmitFilter, handleFilterChange, submittedDDOFilter
    }
}