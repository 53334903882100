import React from "react";
import PageTitle from "../Utils/smsTitle";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCog} from "@fortawesome/free-solid-svg-icons/faCog";
import Form from "react-bootstrap/Form";
import Card from "@material-ui/core/Card"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../actions/snackbarActions";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import Row from "react-bootstrap/Row";
import Label from "../Utils/FormInputs/Label";
import Col from "react-bootstrap/Col";
import {useTheme} from "@material-ui/core/styles";
import FormGroup, {FormTextArea, ReusablePhoneInput} from "../Utils/FormInputs/FormGroup";
import {useAddCompany} from "./CustomHooks/useAddCompany";
import 'react-phone-number-input/style.css'


function AddCompanyInfo({actions, snackbars}) {
    const {state, logo, submitted, isSubmitted, ref, handleChange,
        handleChangeLogo, handleSubmit, handleCloseSnackbar, contact,setContact,error} = useAddCompany(actions)
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    const {openBar, type, message} = snackbars;
    const {name,address,website, email} = state;
    return (
        <div data-testid="assign-role">
            <PageTitle title="Add Hospital Info"/>
            <SubHeader title="Add Hospital Info" subTitle="Add hospital info">
                <FontAwesomeIcon icon={faCog}/>
            </SubHeader>
            <div className="assign_role_card general-card">
                <form onSubmit={handleSubmit} autoComplete="off" ref={ref}>
                    <Card className="p-3 mt-5 general-card">
                        <MainSnackbar variant={type} open={openBar} message={message}
                                      handleCloseBar={handleCloseSnackbar}/>
                        <h6 className={`visit-heading ${palette.type === "dark" && "dark-visit-heading"}`}>Add Hospital Info</h6>
                            <FormGroup  submitted={submitted} labelType={true} onChange={handleChange}
                                        value={name} colSm={7} labelSm={2}    id='hospital_name'
                                        name="name" type="text" labelName="Hospital Name" />
                        <Form.Group as={Row}>
                            <Label column sm={2} name="Contact" htmlFor='contact' type/>
                            <Col sm={7}>
                              <ReusablePhoneInput submitted={submitted} id='contact' contact={contact} setContact={setContact} required/>
                            </Col>
                        </Form.Group>
                        <FormGroup  submitted={submitted} labelType={true} onChange={handleChange}
                                   value={email} colSm={7} labelSm={2}    id='email'
                                    name="email" type="email" labelName="Email" />
                        <FormGroup  onChange={handleChange}
                                    value={website} colSm={7} labelSm={2}    id='website'
                                    name="website" type="url" labelName="Website"/>
                        <FormTextArea value={address} onChange={handleChange} labelSm={2} name='address'
                                      labelName="Address" colSm={7} id='company_address' submitted={submitted} labelType={true}/>
                        <Form.Group as={Row}>
                            <Label column sm={2} name="Logo" htmlFor='company_logo' type/>
                            <Col sm={7}>
                                <Form.Control type="file" id='company_logo' onChange={handleChangeLogo}/>
                                { (submitted && !logo) ? <div className="help-block">Logo is required</div>:
                                error && <div className="help-block">{error}</div>}
                            </Col>
                        </Form.Group>

                        <div className="text-right mr-5">
                            <button type="submit" disabled={isSubmitted === 'pending' || isSubmitted==='resolved'} id='submit-role' className="btn btn-sm sms-btn px-4">
                                {isSubmitted === 'pending' ? "Saving..." : "Save"}</button>
                        </div>
                    </Card>
                </form>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {snackbars: state.snackbar}

}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(AddCompanyInfo);
