import React from "react";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CustomTable from "../../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import {getComparator, stableSort} from "../../Utils/Tables/sortingFunctions";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import *  as snackbarActions from "../../../actions/snackbarActions";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {MainSearchComponent} from "../MainSearch";
import FilterResults from "react-filter-search";
import {useCashCollections} from "./CustomHooks/useCashCollections";
import {faClipboardList} from "@fortawesome/free-solid-svg-icons/faClipboardList";
import {DataExportButton} from "../../Utils/Buttons/DataExportationButton";
import { formatAmount } from "../../Utils/formatNumbers";




const CashCollections = ({actions, snackbars, urls, component, title, headData, children}) => {
    const {
        closeSnackbar, search_type, handleSubmit,
        text, textType, year, date, handleChangeSearch, inputType,
        handleChangeDate, handleChangeYear, handleIncrementYear, isSubmitted, submitted,
        searchValue, order, orderBy,  collections, page, rowsPerPage,csvRef,downloadPdf,downloadCsv,
        handleSearch, handleRequestSort, handleChangePage, handleChangeRowsPerPage,
        isLoading, isSuccess, isError,totals
    } = useCashCollections(actions, urls, component)
    const lbl = component === 'cashier' ? 'Cashier' : 'Payment Type'
    const headers = [{label: lbl, key: 'name'},{label: 'Out Patients', key: 'out'},
        {label: 'In Patients', key: 'in'},{label: 'Amount', key: 'value'}]

    const {start_date, end_date} = date;
    const {openBar, type, message} = snackbars;
    const {total_in, total_out, grand_total} = totals;
    return (
        <div className='journals' data-testid="cash-collections-report">
            <PageTitle title={title}/>
            <SubHeader title="Reports" subTitle={title}>
                <FontAwesomeIcon icon={faClipboardList}/>
            </SubHeader>
            {children}
            <div>
                <div>
                    <MainSearchComponent text={text} type={textType} changeDate={handleChangeDate}
                                        handleSubmit={handleSubmit} isSubmitted={isSubmitted}
                                        changePeriod={handleChangeSearch} end_date={end_date}
                                        handleChangeValue={handleChangeYear} inputType={inputType}
                                        search_type={search_type} handleChangeYear={handleIncrementYear}
                                        start_date={start_date} year={year} submitted={submitted}>
                        <DataExportButton data={collections} exportCsv={downloadCsv} csvRef={csvRef} downLoadPdf={downloadPdf}
                        headers={headers} title={component === 'cashier' ? 'Cashier' : 'Payment Type'}/>
                    </MainSearchComponent>
                </div>
            </div>
            <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>
            <div>
                        <CustomTable title="" term={searchValue} handler={handleSearch}
                                     handleChangePage={handleChangePage}
                                     handleChangeRowsPerPage={handleChangeRowsPerPage}
                                     page={page} rowsPerPage={rowsPerPage} headData={headData}
                                     handleRequestSort={handleRequestSort} data={collections} colSpan={headData.length} order={order}
                                     orderBy={orderBy} filter>
                            <FilterResults value={searchValue} data={collections}
                                renderResults={results => (
                                <TableBody>
                                    {isLoading ? <TableRow>
                                        <TableCell colSpan={headData.length} align="center"><img src="/images/smsloader.gif"
                                                                                alt="loader"/></TableCell>
                                    </TableRow>:null}
                                    {isSuccess ? collections.length > 0 ?
                                    <>
                                        {stableSort(results, getComparator(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((item, index) => {
                                            const count = (page * rowsPerPage) + index + 1;
                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    key={index}
                                                    className='mui-table-row'>
                                                    <TableCell>{count}</TableCell>
                                                    <TableCell>{item.name}</TableCell>
                                                    <TableCell>{formatAmount(item.out)}</TableCell>
                                                    <TableCell>{formatAmount(item.in)}</TableCell>
                                                    <TableCell>{formatAmount(item.value)}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                        <TableRow>
                                            <TableCell></TableCell>
                                            <TableCell align="right">Total: </TableCell>
                                            <TableCell><strong>{formatAmount(total_out)}</strong></TableCell>
                                            <TableCell><strong>{formatAmount(total_in)}</strong></TableCell>
                                            <TableCell><strong>{formatAmount(grand_total)}</strong></TableCell>
                                        </TableRow>
                                        </>:<TableRow>
                                        <TableCell colSpan={headData.length} align="center">No records found</TableCell>
                                    </TableRow>:null}
                                    {isError ? <TableRow>
                                        <TableCell colSpan={headData.length} align="center">The server did not return a valid response</TableCell>
                                    </TableRow>:null}
                            </TableBody>)}/>
                        </CustomTable>
            </div>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(CashCollections);
