import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Label from "../Utils/FormInputs/Label";
import Col from "react-bootstrap/Col";
import TextField from "../Utils/FormInputs/TextField";
import {CustomSelect} from "../Utils/FormInputs/SelectInput";
import {titleCase} from "../Users/addUser";
import {ReusablePhoneInput} from "../Utils/FormInputs/FormGroup";
import moment from "moment";
import Textarea from "../Utils/FormInputs/Textarea";
import { ErrorMessage } from "../Utils/styledComponents";

const ScheduleFollowUp = ({
                             handleChange,
                             handleSubmit,
                             state,
                             submitted,
                             isSubmitted,
                             addSchedule,
                             handleChangeStaff,
                             staff_name,
                             employees,
                             phone,
                             setPhone
                         }) => {
    const {patient_time, slot_name, start_period, end_period, doctor_name, description, patient_phone_no} = state;
    const date = new Date();
    const current_date = moment(date).format().split('T')[0];
    return (

            <form onSubmit={handleSubmit} autoComplete="off">
                        <Form.Group as={Row}>
                            <Label column sm={2} name="Patient Name" type/>
                            <Col sm={7}>
                                <TextField  {...{submitted}} value={titleCase(slot_name)} type="text" disabled={true}
                                           name="slot_name" data-testid="slot-name"/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Label column sm={2} name="Patient Phone Number" type/>
                            <Col sm={7}>
                                <TextField  {...{submitted}} value={patient_phone_no} type="text" disabled={true}
                                           name="patient_phone_no" data-testid="patient-phone-number"/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Label column sm={2} name="Description"/>
                            <Col sm={7}>
                                <Textarea value={titleCase(description)} onChange={handleChange}
                                           name="description" data-testid="description"/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Label column sm={2} name="Doctor Name" type/>
                            <Col sm={7}>
                                {staff_name.value === 'other' ?
                                    <TextField  {...{submitted}} value={doctor_name ? titleCase(doctor_name) : doctor_name}
                                               onChange={handleChange} type="text"
                                               name="doctor_name"/>
                                    : <CustomSelect   onChange={handleChangeStaff} value={staff_name} options={employees}
                                                    dataTest="staff_name" />}
                                {(submitted && !staff_name) &&
                                <ErrorMessage>Doctor name is required</ErrorMessage>}
                            </Col>
                        </Form.Group>

                        {staff_name ? <Form.Group as={Row}>
                            <Label column sm={2} name="Contact"/>
                            <Col sm={7}>
                                <ReusablePhoneInput submitted={submitted} required contact={phone} setContact={setPhone}
                                                    disabled={addSchedule && staff_name.value !== 'other'}/>
                            </Col>
                        </Form.Group> : null}

                        <Form.Group as={Row}>
                            <Label column sm={2} name="Date Scheduled" type/>
                            <Col sm={7}>
                                <TextField  {...{submitted}} value={patient_time} onChange={handleChange} type="date"
                                           name="patient_time"/>
                                {(submitted && !patient_time) ?
                                <ErrorMessage>Date scheduled is required</ErrorMessage> :
                                    (patient_time && patient_time < current_date) && <ErrorMessage>Please select current or future date</ErrorMessage>}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Label column sm={2} name="Available Time" type/>
                            <Col sm={7}>
                                <Row>
                                    <Col sm={6}>
                                        <TextField  {...{submitted}} value={start_period} onChange={handleChange} type="time"
                                                   name="start_period" placeholder="Start Time"/>
                                        {(submitted && !start_period) &&
                                        <ErrorMessage>Start Time is required</ErrorMessage>}

                                    </Col>
                                    <Col sm={6}>
                                        <TextField  {...{submitted}} value={end_period} onChange={handleChange} type="time"
                                                   name="end_period"
                                                   placeholder="End Time"/>
                                        {(submitted && !end_period) ?
                                        <ErrorMessage>End Time is required</ErrorMessage> :
                                            (end_period && end_period < start_period) &&  <ErrorMessage>End Time should be greater than start time</ErrorMessage> }
                                    </Col>
                                </Row>
                            </Col>
                        </Form.Group>
                        <div className="text-right mr-5">
                         
                            
                            <button type="submit" className="btn btn-sm sms-btn px-4"
                                    disabled={isSubmitted === 'pending'}
                                    data-testid="submit-schedule">
                                {isSubmitted === 'pending' ? "Saving..." : "Save"}</button>
                        </div>
                    </form>

    )
};


export default ScheduleFollowUp;
