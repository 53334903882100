import React from 'react';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import {formatDigits} from "../../Utils/formatNumbers";
import { history } from '../../../Helpers/history';
import TableRow from "@material-ui/core/TableRow";
import Table  from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import {useStyles,MinusSquare, PlusSquare, StyledTreeItem,  COATableCustomCell} from "../ChartofAccounts/TreeViewComponent"
import * as mq from "../../../styles/media-queries"
import styled from "@emotion/styled/macro";

const widths = {
    "170":{
        [mq.medium]:{
            minWidth:'170px'
        }
    },
    "200":{
        [mq.medium]:{
            minWidth:'200px'
        }
    },
    "120":{
        [mq.medium]:{
            minWidth:'120px'
        }
    },
    "150":{
        [mq.small]:{
            minWidth:'150px'
        }
    },

}
export const COACell = styled(COATableCustomCell)({
    borderBottom:0
},({variant})=>widths[variant])



export function TrialBalanceTree({header,tree_label, children,data=[] }) {
    const classes = useStyles();

    const openIds = data.map(item=>item.account_name)

    // const otherIds = data.map(item=> {
    //     const items = item.children ? item.children : []
    //     return {
    //         ids: items.map(item => item.account_name)
    //     }
    // }).reduce((a, item)=>{
    //     return a.concat(item.ids)
    // },[])

    // const defaultIds = [...openIds, ...otherIds]


    function nestedMenu(items) {
        return items.map((nav) => {
            const head_level = nav.head_level ? nav.head_level : 0;
            const isParent = head_level === -1;
            const nav_children = nav.account ? nav.account : []
            const idxs = nav.account_name || nav.name
            if (nav.account && nav_children.length === 0) {
                return (
                    <>
                        {!nav.account ?
                            <StyledTreeItem key={nav.id} nodeId={idxs} label={tree_label('final_level', nav,  isParent)}/>
                            :
                            <StyledTreeItem key={nav.id} nodeId={idxs} label={tree_label('current_parent', nav,  isParent)}/>
                        }
                    </>
                )
            }
            return (
                <>
                    <StyledTreeItem key={nav.id} nodeId={idxs} label={tree_label('parent', nav,  isParent)}>
                        {nestedMenu(nav_children)}
                    </StyledTreeItem>

                </>
            )
        })


    }

    return (
        <Table>
            <TableHead>
                <TreeItem label={header} nodeId={-2}/>
            </TableHead>
            <TableBody>
                <TreeView
                    className={classes.root}
                    defaultCollapseIcon={<MinusSquare />}
                    defaultExpandIcon={<PlusSquare />}
                    defaultExpanded={openIds}
                >
                    {nestedMenu(data)}
                </TreeView>
                {children}
            </TableBody>
        </Table>
    );
}

export function TrialBalancePrint({header,tree_label, data=[] }) {
    function nestedMenu(items) {
        return items.map((nav) => {
            const head_level = nav.head_level ? nav.head_level : 0;
            const isParent = head_level === -1;
            const nav_children = nav.sub_accounts ? nav.sub_accounts : []
            if (nav.sub_accounts && nav_children.length === 0) {
                return (
                    <>
                        {!nav.sub_accounts ?
                            tree_label('final_level', nav,  isParent)
                            :
                            tree_label('current_parent', nav,  isParent)
                        }
                    </>
                )
            }
            return (
                <>
                    {tree_label('parent', nav,  isParent)}
                    {nestedMenu(nav_children)}

                </>
            )
        })
    }



    return (
        <Table>
            {header}
            <TableBody>
                {nestedMenu(data)}
            </TableBody>
        </Table>
    );
}

