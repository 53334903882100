import React from "react";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CustomTable from "../../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import {getComparator, stableSort} from "../../Utils/Tables/sortingFunctions";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import *  as snackbarActions from "../../../actions/snackbarActions";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {MainSearchComponent} from "../MainSearch";
import {useNumberOfPositives} from "./CustomHooks/useNumberOfPositivesAndNegatives";
import FilterResults from "react-filter-search";
import {faClipboardList} from "@fortawesome/free-solid-svg-icons/faClipboardList";
import {Link} from "react-router-dom";
import {BackButton, DataExportButton} from "../../Utils/Buttons/DataExportationButton";
import {ReusableRouteTabs} from "../../Utils/Dialogs/ReusableTabs";
import {history} from "../../../Helpers/history";


const headData = [
    {id: 'testName', numeric: false, disablePadding: false, label: 'Test'},
    {id: 'total', numeric: false, disablePadding: false, label: 'Total'},
    {id: 'positives', numeric: false, disablePadding: false, label: 'Positives'},
    {id: 'negatives', numeric: false, disablePadding: false, label: 'Negatives'}

];


const TotalPositivesAndNegatives = ({actions,snackbars}) => {
    const {
        closeSnackbar, search_type, handleSubmit,
        text, textType, year, date, handleChangeSearch,csvRef,downloadCsv,downloadPdf,
        handleChangeDate, handleChangeYear, handleIncrementYear,  isSubmitted, submitted,
        searchValue, order, orderBy, loading, labTests,  page, rowsPerPage,inputType,
        handleSearch, handleRequestSort, handleChangePage, handleChangeRowsPerPage
    } = useNumberOfPositives(actions)
    const {start_date, end_date} = date;
    const {openBar, type, message} = snackbars;
    const data = labTests.map(item => ({
        hospital_test_name: item.hospital_test_name,
        negatives: item.negatives,
        positives: item.positives,
        total: +item.positives + +item.negatives
    }))
    const headers = [{label: 'Test', key: 'hospital_test_name'}, {label: 'Negatives', key: 'negatives'},
        {label: 'Positives', key: 'positives'}, {label: 'Total', key: 'total'}]
    const components = [{label: 'Lab Tests by Gender', path: '/reports/tests/lab'},
        {label: 'Total Positives & Negatives', path: '/reports/totalpositives'},
        {label: 'Number of Lab Tests', path: '/reports/numberoftestsreports'},
    ]
    return (
        <div className='journals' data-testid="number-of-negative-positives">
            <PageTitle title="Total Positives & Negatives"/>
            <SubHeader title="Reports" subTitle="Total Positives & Negatives">
                <FontAwesomeIcon icon={faClipboardList}/>
            </SubHeader>
            <div style={{margin: '10px'}}>
                <BackButton text={'General Reports'} to={'/reports/generalreports'}/>
            </div>
            <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/> 
            <div>
                <MainSearchComponent text={text} type={textType} changeDate={handleChangeDate}
                                     handleSubmit={handleSubmit} isSubmitted={isSubmitted}
                                     changePeriod={handleChangeSearch} end_date={end_date}
                                     handleChangeValue={handleChangeYear} submitted={submitted}
                                     search_type={search_type} handleChangeYear={handleIncrementYear}
                                     start_date={start_date} year={year} inputType={inputType}>
                    <DataExportButton headers={headers} title='Total Positives & Negatives'
                                      csvRef={csvRef} data={data} downLoadPdf={downloadPdf}
                                      exportCsv={downloadCsv}/>
                </MainSearchComponent>
            </div>
            <div>
                <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>
                {/* <div className="row mr-0">
                    <div className="col-md-5"> */}
                        <CustomTable title="" term={searchValue} handler={handleSearch}
                                     handleChangePage={handleChangePage}
                                     handleChangeRowsPerPage={handleChangeRowsPerPage}
                                     page={page} rowsPerPage={rowsPerPage} headData={headData}
                                     handleRequestSort={handleRequestSort} data={labTests} colSpan={4} order={order}
                                     orderBy={orderBy} filter fewRows>
                            {loading ? <TableBody><TableRow>
                                <TableCell colSpan="8" align="center"><img src="/images/smsloader.gif"
                                                                           alt="loader"/></TableCell>
                            </TableRow></TableBody> : labTests.length > 0 ?
                                <FilterResults value={searchValue} data={labTests}
                                               renderResults={results => (
                                                   <TableBody>
                                                       {stableSort(results, getComparator(order, orderBy))
                                                           .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                           .map((item, index) => {
                                                               const count = (page * rowsPerPage) + index + 1;
                                                               return (
                                                                   <TableRow
                                                                       hover
                                                                       role="checkbox"
                                                                       tabIndex={-1}
                                                                       key={index}
                                                                       className='mui-table-row'>
                                                                        <TableCell>{count}</TableCell>
                                                                       <TableCell><Link to={{pathname:`/reports/labtestdetails/${item.hospital_test_id}/name=${item.hospital_test_name}/0`}}>
                                                                           {item.hospital_test_name}</Link></TableCell>
                                                                       <TableCell>{+(item.positives) + +(item.negatives)}</TableCell>
                                                                       <TableCell>{item.positives}</TableCell>
                                                                       <TableCell>{item.negatives}</TableCell>
                                                                   </TableRow>
                                                               );
                                                           })}
                                                   </TableBody>)}/> : <TableBody><TableRow>
                                    <TableCell colSpan="4" align="center">No records found</TableCell>
                                </TableRow></TableBody>}
                        </CustomTable>
                    {/* </div> */}
                    {/* <div className="col-md-7">
                        <Card className="p-3 general-card">
                            <div className="row mx-0">
                                <div className="col-md-8">
                                    <Form.Group as={Row}>
                                        <Form.Label style={{marginTop: '.5rem'}} sm={3}>Select Test:</Form.Label>
                                        <Col sm={8}>
                                            <CustomSelect value={hospital_test_id} onChange={handleChangeTest}
                                                          options={allTests.map(item => ({
                                                              value: item.hospital_test_id,
                                                              label: item.hospital_test_name
                                                          }))}/>
                                        </Col>
                                    </Form.Group>
                                </div>
                                <div className="col-md-4"/>
                            </div>
                            <SmsComposedChart stroke="#568203" label={{
                                value: 'Total',
                                angle: -90,
                                position: 'insideLeft'
                            }}
                                              xlabel={{value: label, position: 'bottom', offset: 0}}
                                              data={statistics}
                                              industry_stroke="#cd9575" fill="#d0f0c0"
                                              customizedLabel={<RenderCustomizedPercentLabel/>}>
                                <Legend verticalAlign="top" height={36} align="right" iconType="plainline"/>
                                <defs>
                                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#2bdd5e" stopOpacity={0.8}/>
                                        <stop offset="95%" stopColor="#2bdd5e" stopOpacity={0}/>
                                    </linearGradient>
                                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#ea6075" stopOpacity={0.8}/>
                                        <stop offset="95%" stopColor="#ea6075" stopOpacity={0}/>
                                    </linearGradient>

                                </defs>
                                <Area type="monotoneX" dataKey="positives" name='Positives' fill="url(#colorUv)"
                                      stroke="#2bdd5e" activeDot={{r: 5}}/>

                                <Area type="monotoneX" dataKey="negatives" name='Negatives' fill="url(#colorPv)"
                                      stroke="#ea6075" activeDot={{r: 5}}/>

                            </SmsComposedChart>
                        </Card>

                    </div> */}
                {/* </div> */}
            </div>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(TotalPositivesAndNegatives);
