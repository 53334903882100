import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Label, { FormLabel } from "../Utils/FormInputs/Label";
import Col from "react-bootstrap/Col";
import {titleCase} from "../Users/addUser";
import ClearIcon from '@material-ui/icons/Clear';
import {CustomSelect} from "../Utils/FormInputs/SelectInput";
import TextField from "../Utils/FormInputs/TextField";
import {makeStyles} from '@material-ui/core/styles';
import {ErrorMessage} from "../Utils/styledComponents";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '.8em',
    }
}));

const MainVitals = ({handleSubmitTriage, triageList, handleChange, submitted, removeMetric,
                        handleResetForm, isSubmitted, handleChangeWeight, handleChangeHeight,
                        bmi, weight, height, children, handleChangeVitals, triage,inpatient,
                        optionalMetrics, handleChangeOptional, otherMetric, others,
                        handleChangeOthersMetrics,chronic_illness
                    }) => {
    const classes = useStyles();
    return (
        <div>
            {children}
            <form onSubmit={handleSubmitTriage} id="submit_triage" autoComplete="off">
                <div className="row">
                    <div className="col-md-6">
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                                <FormLabel name="Weight(kgs)" toolTip="Weight"/>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                                <TextField type='number' value={weight} onChange={handleChangeWeight}/>
                            </Col>
                        </Form.Group>
                    </div>
                    <div className="col-md-6">
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                                <FormLabel name="Height(cms)" toolTip="Enter height"/>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                                <TextField type='number' value={height} onChange={handleChangeHeight} />
                            </Col>
                        </Form.Group>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                                <Label name="BMI"/>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                                <TextField value={bmi} disabled/>
                            </Col>
                        </Form.Group>
                    </div>
                    {triage.map((triage, index) => (<div className="col-md-6" key={index}>
                        <Form.Group as={Row} key={triage.metric_id}>
                            <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                                <FormLabel name={titleCase(triage.metric_name)}/>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                                <TextField type='number' name={triage.name}
                                           onChange={(e) => handleChange(e, index)}
                                           value={triage.metric_value} />
                            </Col>
                        </Form.Group>
                    </div>))}
                </div>
                {triageList.length > 0 ? <div className="row">
                    {triageList.map((triage, index) => (<div className="col-md-6" key={index}>
                        <Form.Group as={Row} key={triage.metric_id}>
                            <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                                <FormLabel name={titleCase(triage.metric_name)}/>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={7} xl={7}>

                                <TextField type='text' name={triage.name}
                                           onChange={(e) => handleChangeVitals(e, index)}
                                           value={triage.metric_value} />
                            </Col>
                        </Form.Group>

                    </div>))}
                </div>: null}
                {optionalMetrics.length > 0 ? <div className="row">
                    {optionalMetrics.map(triage => (<div className="col-md-6" key={triage.metric_id}>
                        <Form.Group as={Row} key={triage.metric_id}>
                            <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                                <FormLabel name={titleCase(triage.metric_name)}
                                           toolTip={`Enter ${triage.metric_name}`}/>
                            </Col>
                            <Col xs={10} sm={10} md={10} lg={7} xl={7}>
                                <TextField type='text' name={triage.name}
                                           onChange={(e) => handleChangeOptional(e, triage.metric_id)}
                                           value={triage.metric_value}/>
                            </Col>
                            <Col  xs={2} sm={2} md={2} lg={1} xl={1}>
                                <button type="button" onClick={() => removeMetric(triage.metric_id)}
                                        className='btn btn-sm sms-btn-dismiss'
                                        style={{padding: '0 1px', borderRadius: '50px'}}><ClearIcon
                                    classes={{root: classes.root}}/></button>
                            </Col>
                        </Form.Group>
                    </div>))}
                </div>: null}
                <div className="row">
                    <div className="col-md-6">
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                                <Label name="Add Metric"/>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                                <CustomSelect value={otherMetric}
                                              onChange={handleChangeOthersMetrics} options={others}/>
                            </Col>
                        </Form.Group>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        {chronic_illness}
                    </div>
                    <div className="col-lg-6"/>

                </div>

                <div className="text-right">
                    <button type="button" className="btn btn-sm sms-grey-btn px-4 mr-2"
                            onClick={handleResetForm} id="reset_triage">Reset
                    </button>
                    <button type="submit" disabled={isSubmitted === 'pending' || isSubmitted === 'resolved'}
                            className="btn btn-sm sms-btn px-4">{isSubmitted === 'pending' ? "Saving..." : "Save"}</button>
                </div>
            </form>
        </div>
    )
};
export default MainVitals;
