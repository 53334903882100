import React from "react";
import Dialog from "@material-ui/core/Dialog";
import {DialogContent, DialogTitle} from "../../Utils/SmsDialog";
import Form from "react-bootstrap/Form";


const PurchaseDialog = ({openDialog, handleClose, handleUploadCsv, handleChangeUpload,submitted,csv,isSubmitted, children, element=null}) => {
    return (
     
            <Dialog open={openDialog} maxWidth="lg">
                <DialogTitle onClose={handleClose}>
                    <div style={{fontSize: 15}} className="text-center">
                        Upload CSV
                    </div>
                </DialogTitle>
                <DialogContent className="pt-0">
                    <div className="text-right p-1 my-2">
                        {children}
                    </div>
                    <form onSubmit={handleUploadCsv}>
                        {element}
                        <div className="card p-2 mb-2">
                            <Form.Control type="file" onChange={handleChangeUpload}/>
                            {(submitted && !csv) && <div className="help-block">Please select csv file</div>}
                        </div>
                        <div className="text-right">
                            <button className="btn sms-btn btn-sm mr-2" type='submit'
                                    disabled={!!isSubmitted}>{isSubmitted ? 'Submitting...' : 'Submit'}</button>
                            <button type="button" className="btn sms-grey-btn btn-sm" onClick={handleClose}>Close
                            </button>
                        </div>
                    </form>
                </DialogContent>
            </Dialog>
      
      
    )

};
export default PurchaseDialog;
