import {useEffect, useRef, useState} from "react";
import axios from "axios";
import {config} from "../../../../Helpers/env";
import {logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import {exportPDF} from "../../../Utils/downloadPDF";
import {dateStringConvert} from "../../../Utils/ConvertDate";
import { useStockAdjustment } from "./useStockAdjustment";


export const useStockAdjustmentList = (actions) => {
    const [searchValue, setSearchValue] = useState('');
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('sl');
    const [loading, setLoading] = useState(false);
    const [stockList, setList] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const [total_count, setCount] = useState(0);
    const [allRecords, setAllRecords] = useState([]);
    const csvRef = useRef();
    const {adjustmentProps, openStockDialog, handleOpenStockDialog, handleCloseStockDialog,
        isResolvedStock} = useStockAdjustment(actions)

    useEffect(() => {
        const formData = new FormData();
        formData.append('search', searchValue)
        setLoading(true);
        axios.post(`${config.smsUrl}/cinventory/stock_adj_list/${activePage}`).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const stock = !dt.stock ? [] : dt.stock;
            const count = !dt.total_count ? 0 : dt.total_count;
            const arr = stock.map((item, index) => ({
                count: ((activePage - 1) * 10) + (index + 1),
                date: item.date_created,
                created_by: item.created_by,
                reason: item.reason,
                adjusted_by: `${item.first_name ? item.first_name : ''} ${item.last_name ? item.last_name : ''}`,
                stock_adjustment_id: item.stock_adjustment_id,
                stock_type: item.stock_type === 0 ? 'Available Stock' : 'Old Stock'
            }))
            setList(arr);
            setCount(count)
            setLoading(false);
        }).catch((err) => {
            logoutErrorMessage(err, null, actions);
            setLoading(false);
        })
    }, [searchValue, activePage, isResolvedStock]);

    const retrieveAllRecords = () => {
        const formData = new FormData();
        formData.append('search', searchValue)
        return axios.post(`${config.smsUrl}/cinventory/stock_adj_list/${activePage}`, formData)
    }

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const handleSearch = (event) => {
        setSearchValue(event.target.value)
    };

    const handleChangePage = (page) => {
        setActivePage(page)
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const downloadPdf = () => {
        const formData = new FormData();
        formData.append('search', searchValue)
        retrieveAllRecords().then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const items = !dt.stock ? [] : dt.stock;
            exportPDF("Stock Adjustment List", [["SL", "Date", "Adjusted By", "Reason", "Category"]],
                items.map((item, index) => [index += 1, dateStringConvert(item.date_created),
                    `${item.first_name ? item.first_name : ''} ${item.last_name ? item.last_name : ''}`,
                    item.reason, item.stock_type === 0 ? 'Available Stock' : 'Old Stock'
                ]),
                "Stock Adjustment List.pdf");
            setAllRecords(items);
        }).catch((err) => {
            logoutErrorMessage(err, null, actions);
        })

    }

    const downloadCsv = () => {
        retrieveAllRecords().then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const items = !dt.stock ? [] : dt.stock;
            setAllRecords(items);
            csvRef.current.link.click()
        }).catch((err) => {
            logoutErrorMessage(err, null, actions);
        })
    }

    return {searchValue, order, orderBy, loading, stockList, activePage, total_count,
        closeSnackbar, handleSearch, handleChangePage, handleRequestSort, allRecords,
        csvRef, downloadCsv, downloadPdf,adjustmentProps, openStockDialog, handleOpenStockDialog, handleCloseStockDialog
    }
}