/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React from 'react'
import * as colors from "../../../styles/colors"
import {ErrorStar} from "../styledComponents"

const table = css`
    width: 100%;
    color: #212529;
    font-size:14px;
` 
const tableBordered = css`
    th{
        border-bottom: 1px solid ${colors.text00};
    }
    td, th{
        border: 1px solid ${colors.gray100};
    }
`

const tableSm = css`
    td{
        padding: 0.5rem;
    }
    th{
        padding: 0.5rem 0.5rem 0 0.5rem;
    }
`

function BootStrapTable({headData, children, heading, ...props}) {
  return (
    <table css={[table, tableBordered, tableSm]} {...props}>
        <thead>
            {heading}
            <tr>
            {headData.map((item, index)=>(
                <th key={index}>{item.name}{item.isRequired ? <ErrorStar>*</ErrorStar>: null}</th>
            ))}
            </tr>
        </thead>
        {children}
    </table>
  )
}

const TableData = (props) =>{
    return(
        <td css={{border:'0 !important', fontWeight:600}} {...props}/>
    )
}

export {BootStrapTable,TableData}