import React, {useState} from "react";
import axios from "axios";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import {config} from "../../../../Helpers/env";
import { history } from "../../../../Helpers/history";
import {ConfirmationDialog} from "../../../Utils/ReusableComponents/ConfirmationDialog";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Label from "../../../Utils/FormInputs/Label";
import Col from "react-bootstrap/Col";
import {AutoCompleteControlled} from "../../../Utils/FormInputs/AutoCompleteInput";
import InputGroup from "react-bootstrap/InputGroup";
import TextField from "../../../Utils/FormInputs/TextField";
import {CustomSelect} from "../../../Utils/FormInputs/SelectInput";
import {ErrorMessage, RightAlignedContainer} from "../../../Utils/styledComponents";
import {BlockNavigationComponent} from "../../../Utils/ReusableComponents/BlockNavigationComponent";


export function useNewSingleProcedure(props) {
    const {patient_number, visit_id, visit_type, service_type, department_name, actions, departs, services,setOpenDialog,setIsBlocking, isBlocking,
        setServiceType} = props
    const [submitted, setSubmitted] = useState(false);
    const [service, setService] = useState("");
    const [quantity, setQuantity] = useState(1)
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [serviceCost, setServiceCost] = useState({currency_id:'', service_cost:0, currency_symbol:'', is_provider:0, is_provided:0})
    const [openService, setOpenService] = useState(false)
    const [service_item_id, setServiceItemId] = useState('')
    const [department, setDepartment] = useState(department_name)
    const [doctor, setDoctor] = useState('')
    const [doctors, setDoctors] = useState([])
    const [isBlockDialog, setIsBlockDialog] = useState(false)



    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = _token.user_roles_id ? _token.user_roles_id : ''
    const requested_by = `${!_token.first_name ? "" : _token.first_name} ${!_token.last_name ? "" : _token.last_name}`;


    const handleRetrieveDoctors = (id) =>{
        const formData = new FormData();
        formData.append('department_id', id);
        axios.post(`${config.smsUrl}/cvisit/getdoctorsbydepartment_id`, formData)
            .then(response => {
                const res = response.data;
                const data = !res ? {} : res;
                const docs = !data.doctors ? [] : data.doctors;
                setDoctors(docs)
            })
            .catch(error => {
                logoutErrorMessage(error, null, actions)
            });
    }


    const handleChangeService = (value) => {
        if (!value) {
            setDepartment('');
            setService('');
            return;
        }
        setService(value);
        const formData = new FormData();
        const s_id = value.value ?? '';
        const id = s_id.split('-');
        formData.append('service_id', id[0]);
        const endPoints = [{
            url:`${config.smsUrl}/cbilling/retrieve_department_by_service_id`,
            data: formData
        },
            {
                url:`${config.smsUrl}/cbilling/item_cost`,
                data: {visit_id: +visit_id, item_id: +id[0], item_type:'service'}
            }
        ]
        Promise.all(endPoints.map((endpoint) => axios.post(endpoint.url, endpoint.data)))
            .then(([{data: departmentData}, {data: currencyData}] )=> {
                const data = !departmentData ? {} : departmentData;
                const {department_id, department_name} = data;
                setDepartment({
                    value: department_id ? department_id : '',
                    label: department_name ? department_name : ''
                });
                if (department_id) {
                    handleRetrieveDoctors(department_id)
                }

                const visitObj = currencyData.data ? currencyData.data : {}

                setServiceCost({currency_id: visitObj.currency_id,service_cost: visitObj.cost, currency_symbol: visitObj.currency, ...visitObj})
                if(visitObj.is_provider === 1 && visitObj.is_provided === 0){
                    setServiceItemId(data.procedure_id)
                    setOpenService(true)
                }
            })
        setIsBlocking(true)
    };


    const handleChangeDoctor=(value)=>{
        setDoctor(value)
        setIsBlocking(true)
    }

    const handleChangeDepartment = (value) => {
        setDepartment(value);
        handleRetrieveDoctors(value.value)
        setIsBlocking(true)
    };



    const addServiceToReceipt = () =>{
        setOpenService(false)
    }



    const handleCloseService = () =>{
        const s_id = service.value ?? '';
        const id = s_id.split('-')[0];
        if(service_item_id === id){
            setDepartment({value:null, label:null});
            setService({value:null, label:null});
            return
        }
        setOpenService(false)
    }


    const handleCancelService = () =>{
        setOpenService(false)
    }


    const handleChangeQuantity = (event) =>{
        setQuantity(event.target.value)
    }

    const handleSubmitService = (event) => {
        event.preventDefault();
        const s_id = service.value ?? '';
        const id = s_id.split('-');
        const isANC = service_type['value'] === 'Ante Natal Care';
        const {currency_id, is_provided, is_provider, service_cost} = serviceCost

        const procedure = { service_id: +id[0],quantity: +quantity, rate: service_cost, currency_id ,
            is_provider, is_provided}

        const antenatal_data = {
            service_id: +id[0],
            visit_id,
        };

        const bill_details = isANC ? [procedure] : procedure

        const  params = {
            patient_number:patient_number, visit_type: +visit_type?.value ?? '',assigned_to: doctor?.value ?? '',
            department_id: department.value ?? '', receptionist:user_roles_id, user_roles_id, patient_type: 1,
            result_type: isANC ? 3 :  1,bill_type:2, visit_id: +visit_id,
            bill_details: bill_details , antenatal_data
        }

        setSubmitted(true);
        setIsBlocking(false)

        if (service_type && service && quantity) {
            if (isANC) {
                axios.post(`${config.smsUrl}/antenatal/create_anc_service`, {...params}).then(() => {
                    actions.snackbarActions.snackSuccess('Antenatal visit created  successful');
                }).catch((err) => {
                    errorMessages(err, null, actions)
                    setSubmitted(false);
                    setIsSubmitted('rejected');
                });
            } else {
                setIsSubmitted('pending');
                axios.post(`${config.smsUrl}/cvisit/create_procedure`, {...params}).then(() => {
                    setIsSubmitted('resolved');
                    actions.snackbarActions.snackSuccess(`Service submitted successfully`);
                    handleResetForm()
                }).catch((err) => {
                    errorMessages(err, null, actions)
                    setSubmitted(false);
                    setIsSubmitted('rejected');
                });
            }
        }

    };

    const handleResetForm = () =>{
        if(setOpenDialog){
            setOpenDialog(false)
        }
        setIsBlockDialog(false)
        setIsBlocking(false)
        setSubmitted(false);
        setService(null)
        setDepartment(null)
        setDoctor(null)
        setServiceType('')
        setQuantity('')
        setServiceCost({currency_id:'', service_cost:0, currency_symbol:'', is_provider:0, is_provided:0})
    }

    const handleCloseSingleService = () => {
        if(isBlocking){
            setIsBlockDialog(true)
        }else{
            handleResetForm()
        }
    };

    const isPending = isSubmitted === 'pending'
    const isResolved = isSubmitted === 'resolved'
    const isRejected = isSubmitted === 'rejected'


    const docs = doctors ? doctors : [];

    const {service_cost, currency_symbol} = serviceCost

    const isAntenatal = service_type.value === "Ante Natal Care"

    return {
        isResolved,
        isRejected,
        handleSubmitService,
        submitted,
        handleCloseSingleService,
        renderSingleProcedure: (
            <React.Fragment>
                <BlockNavigationComponent
                    when={isBlocking}
                    shouldBlockNavigation={()=>{
                        return isBlocking;
                    }}
                    navigate={path=>history.push(path)}
                    dialogClose={isBlockDialog}
                    setIsBlockDialog={setIsBlockDialog}
                    clearForm={handleResetForm}
                />
                <ConfirmationDialog open={openService} handleClose={handleCancelService}
                                    title='Make cash payment'
                                    confirmItems={addServiceToReceipt}
                                    removeItems={handleCloseService} service='procedure'/>
                <div className="row">
                    <div className="col-lg-6">
                        <Form.Group as={Row}>
                            <Label name="Service" column sm="3" type/>
                            <Col sm="8">
                                <AutoCompleteControlled reset={isResolved} options={services} handleChange={handleChangeService}   submitted={submitted}
                                                        medicine_value={service}  placeholder="Select service..."  dataTest="service" testId='service_name'
                                                        errorMsg='Service is required' id='service' value_text='value' label_text='label' />
                            </Col>
                        </Form.Group>

                        {service ? <Form.Group as={Row}>
                            <Label name="Quantity" column sm="3" type/>
                            <Col sm="8">
                                    <TextField  type="number" value={quantity} onChange={handleChangeQuantity}/>
                                {submitted && !quantity ? <ErrorMessage>Quantity is required</ErrorMessage>: null}
                            </Col>
                        </Form.Group>: null}
                        <Form.Group as={Row}>
                            <Label name="Cost" column sm="3" type/>
                            <Col sm="8">
                                <InputGroup>
                                    <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">{currency_symbol}</InputGroup.Text>
                                    <TextField  type="number" value={service_cost} style={{pointerEvents:'none'}}/>
                                </InputGroup>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Label name="Department" column sm="3" type/>
                            <Col sm="8">
                                <CustomSelect submitted={submitted}  onChange={handleChangeDepartment} id='department-name'
                                              value={department} options={departs}
                                              />
                                {(submitted && !department) &&
                                    <ErrorMessage>Department is required</ErrorMessage>}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Label name={isAntenatal ? 'Requested By': 'Doctor Name'} column sm="3"/>
                            <Col sm="8">
                                {isAntenatal ?  <TextField type="text" name="requested_by" value={requested_by}
                                                           id="requested_by"
                                                           disabled={true}/>:
                                    <AutoCompleteControlled reset={isResolved} options={docs.map(doctor => ({
                                        value: doctor.user_roles_id,
                                        label: `${doctor.first_name} ${doctor.last_name}`
                                    }))} handleChange={handleChangeDoctor}    submitted={submitted} id='visit_doctor'
                                                            medicine_value={doctor}  placeholder="Select doctor..." testId='doctor_name'
                                                            errorMsg='Doctor is required'  value_text='value' label_text='label' />
                                }
                            </Col>
                        </Form.Group>
                        <Col sm='11'>
                            <RightAlignedContainer>
                                <button type="submit" id='submit_form'
                                        className="sms-btn btn btn-sm px-3"  disabled={isPending}>{isPending ? 'Saving...' : 'Save Service'}</button>
                                {/*<button type="button"  id="reset_form"  className="sms-gray-btn btn btn-sm ml-2" >Cancel</button>*/}
                            </RightAlignedContainer>
                        </Col>

                    </div>
                    <div className="col-lg-6"/>
                </div>
            </React.Fragment>
        )
    }
}


