import {useEffect, useState} from "react";
import axios from "axios";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import {config} from "../../../../Helpers/env";

export function usePatientInfo(actions, patient_number) {
    const [patient, setPatient] = useState({})

    useEffect(() => {
        const formData = new FormData();
        formData.append('patient_number', patient_number);
        axios.post(`${config.smsUrl}/cdoctor/get_patient_details`, formData).then(res => {
            const data = res.data;
            const details = !data ? {} : data;
            setPatient(details)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, [patient_number]);

    return {patient}

}


