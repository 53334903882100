import React, {useEffect, useState} from 'react';
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import CustomTable, {CustomTablePagination, useHiddenColumns} from "../../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {Link} from "react-router-dom";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../../actions/snackbarActions";
import * as billingActions from "../../../actions/BillingActions/BillingActions";
import {connect} from "react-redux";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {useTheme} from '@material-ui/core';
import PageTitle from '../../Utils/smsTitle';
import {SubHeader} from '../../../Containers/SubHeader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCoins} from '@fortawesome/free-solid-svg-icons/faCoins';
import {history} from "../../../Helpers/history";
import {ReusableRouteTabs} from "../../Utils/Dialogs/ReusableTabs";
import {dateConvert, formatDateTime} from "../../Utils/ConvertDate";
import {titleCase} from "../../Users/addUser";
import { ExpiredStatus, Processing, FinishedStatus } from '../../DoctorsModule/doctorStyles';
import {DateFilter} from "../utils/DateFilter";
import {getCurrentYear} from "../../Utils/ConvertDate";
import {useDebounce} from "../../Lab/useDebounce";


const billingHeadData = [
    {id: 'count', numeric: false, disablePadding: false, label: 'Sl', hidden:false},
    {id: 'date', numeric: false, disablePadding: false, label: 'Date', hidden:false},
    {id: 'invoice_no', numeric: false, disablePadding: false, label: 'Invoice#', hidden:false},
    {id: 'patient_number', numeric: false, disablePadding: false, label: 'Patient Number', hidden:false},
    {id: 'patient_name', numeric: false, disablePadding: false, label: 'Patient Name', hidden:false},
    {id: 'phone_no', numeric: false, disablePadding: false, label: 'Phone', hidden:false},
    {id: 'kin_phone_no', numeric: false, disablePadding: false, label: 'NOK Phone', hidden:false},
    {id: 'patient_type', numeric: false, disablePadding: false, label: 'Patient Type', hidden:false},
    {id: 'provider_name', numeric: false, disablePadding: false, label: 'Provider Name', hidden:false},
    {id: 'provider_type', numeric: false, disablePadding: false, label: 'Provider Type', hidden:false},
    {id: 'status', numeric: false, disablePadding: false, label: 'Status', hidden:false},
    {id: 'action', numeric: false, disablePadding: false, label: 'Action', hidden:false}
];

const PaidInvoices = (props) => {
    const {actions} = props;
    const theme = useTheme();
    const {palette} = theme;
    const {openBar, type, message} = props.snackbars;
    const [term, setTerm] = useState('')
    const [activePage, setActivePage] = useState(1)
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [data, setData] = useState({billingList:[], total_count:0, status:'idle'})
    const {billingList, total_count, status} = data
    const [date, setDate] = useState({start_date:dateConvert(), end_date:dateConvert()})
    const {start_date, end_date} = date
    const [debouncedInputValue] = useDebounce(term, 500)


    useEffect(() => {
        const formData = new FormData();
        const search = term ? titleCase(term) : ''
        formData.append('search', debouncedInputValue);
        formData.append('start_date', start_date);
        formData.append('end_date', end_date);
        formData.append('per_page', rowsPerPage);
        setData({...data, status: 'loading'})
        axios.post(`${config.smsUrl}/cbilling/list_of_invoiced_visit/${activePage}`, formData).then(res => {
            const data = res.data;
            const bills = !data ? {} : data;
            const arr = !bills.invoiced_visits ? [] : bills.invoiced_visits;

            const all_bills = arr.map((bill, index)=>{
                const count = ((activePage - 1) * rowsPerPage) + index + 1;
                const item_status = {
                    0:<Processing>Pending</Processing>,
                    2:<FinishedStatus>Complete</FinishedStatus>,
                    1:<ExpiredStatus>Incomplete</ExpiredStatus>,
                    null:<Processing>Pending</Processing>,
                }
                const isInsurance = bill.provider_type === 'Insurance Company'
                return {
                    ...bill,
                    count,
                    date:formatDateTime(bill.begin_datetime),
                    patient_name: titleCase(`${!bill.patient_firstname ? "" : bill.patient_firstname} 
             ${!bill.patient_lastname ? "" : bill.patient_lastname}`),
                    patient_type:bill.patient_type===1 ? 'Out patient':'In patient',
                    status:isInsurance ? item_status[bill.claim_status] : '',
                    invoice_no:`${bill?.visit_id}/${getCurrentYear()}`,
                    action: (
                        <>
                            <Link to={{pathname: `/billedinvoice/${bill.visit_id}/${bill.patient_type}/${bill.insurance_id}/${bill.claim_status}/${bill.provider_type}`}}
                                  style={{
                                      textDecoration: "none",
                                      color: palette.type === "dark" ? "#fff" : "#3d3d3d"
                                  }}>
                                <button className="btn btn-sm sms-info-btn mr-3">View
                                </button>
                            </Link>

                            <Link to={{pathname: `/reverseinvoice/${bill.visit_id}/${bill.patient_type}/${bill.insurance_id}`}}
                                  style={{
                                      textDecoration: "none",
                                      color: palette.type === "dark" ? "#fff" : "#3d3d3d"
                                  }}>
                                <button className="btn btn-sm sms-btn mr-1">Reverse
                                </button>
                            </Link>
                        </>
                    )
                }
            })
            const t_count = !bills.total_count ? 0 : bills.total_count;
            setData({...data, status: 'success', billingList: all_bills, total_count: t_count})
        }).catch(err => {
            logoutErrorMessage(err, null, actions);
            setData({...data, status: 'error'})
        })
    }, [activePage, debouncedInputValue, start_date, end_date, rowsPerPage])


    const handler = (event) => {
        setTerm(event.target.value)
        setActivePage(1)
        setPage(0)
    }

    const handleChangeActivePage = (val) => {
        setActivePage(val)
    };

    const handleChangeDate = (event) =>{
        const {name, value} = event.target;
        setDate({...date,[name]:value})
        setActivePage(1)
        setPage(0)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setActivePage(newPage+1)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        setActivePage(1);
    };

    const closeSnackBar = () => {
        actions.snackbarActions.hideSnackbar();
    };

    const components = [{label: 'Out-patient', path: '/paymentList'},
        {label: 'In-patient', path: '/patientbillinglist'},
        {label: 'Invoiced Bills', path: '/invoicedbills'},
      
    ]

    const isLoading = status === 'loading'
    const isSuccess = status === 'success'
    const isError = status === 'error'



    const {
        headCells, all_hidden, handleAllHeadCells, handleHeadCells
    } = useHiddenColumns(billingHeadData);

    const pagination = (
        <CustomTablePagination
            colSpan={3}
            activePage={activePage}
            page={page}
            rowsPerPage={rowsPerPage}
            count={total_count}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            isTotalCount
        />
    );

    return (
        <div className='journals'>
            <PageTitle title="Debtors"/>
            <SubHeader title="Billing" subTitle="Debtors">
                <FontAwesomeIcon icon={faCoins}/>
            </SubHeader>
            <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/>
            <div className="mui-tables">
                <DateFilter {...{start_date, end_date,  handleChangeDate, isSubmit:true}}/>
                <MainSnackbar handleCloseBar={closeSnackBar} message={message} open={openBar} variant={type}/>
                <CustomTable title="" colSpan={headCells.length}
                             data={billingList} {...{all_hidden, handleAllHeadCells, handleHeadCells, pagination}}
                             headData={headCells}
                             handler={handler} activePage={activePage}
                             handleChangeNextPage={handleChangeActivePage} total_count={total_count}
                             records={10} customPage term={term}>
                        <TableBody>
                            {isLoading ? <TableRow>
                                <TableCell colSpan={headCells.length} align="center">
                                    <img src="/images/smsloader.gif"
                                         alt="loader"/></TableCell>
                            </TableRow>:null}
                            {isSuccess ? billingList.length > 0 ?  billingList.slice(0, rowsPerPage)
                                .map((bill, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={bill.visit_id}
                                            className='mui-table-row'>
                                            {headCells.map((head)=>(
                                                <TableCell id={head.id}  key={head.id}
                                                           hidden={head.hidden}>{bill[head.id]}</TableCell>
                                            ))}
                                        </TableRow>
                                    );
                                }) :<TableRow>

                                <TableCell colSpan={headCells.length} align="center">No records found</TableCell>
                            </TableRow>:null}
                            {isError ? <TableRow>

                                <TableCell colSpan={headCells.length} align="center">The server did not return a valid response</TableCell>
                            </TableRow> :null}

                        </TableBody>
                </CustomTable>

            </div>
        </div>
    )
}

function mapStateToProps(state) {
    const {snackbar, billing} = state
    return {
        snackbars: snackbar, billing
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch),
            billingActions: bindActionCreators(billingActions, dispatch),
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PaidInvoices)