import React from 'react';
import PageTitle from "../components/Utils/smsTitle";
import {SubHeader} from "./SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/free-solid-svg-icons/faHome";

export const ErrorFallBack = () =>{
    return (
        <div>
            <PageTitle title="Error Page"/>
            <SubHeader title="Error" subTitle="Error">
                <FontAwesomeIcon icon={faHome}/>
            </SubHeader>
        </div>
    )
}