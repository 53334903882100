import React from "react";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CustomTable from "../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {Link} from "react-router-dom";
import * as snackbarActions from "../../actions/snackbarActions"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import PageTitle from "../Utils/smsTitle";
import {faNotesMedical} from "@fortawesome/free-solid-svg-icons/faNotesMedical";
import { dateStringConvert} from "../Utils/ConvertDate";
import {useLabScanResults} from "../DoctorsModule/Lab/hooks/useDoctorLabResults";
import {history} from "../../Helpers/history";
import {ReusableRouteTabs} from "../Utils/Dialogs/ReusableTabs";
import { RightAlignedContainer } from "../Utils/styledComponents";
import { Container } from "../DoctorsModule/doctorStyles";


const headData = [{id: 'sl', numeric: false, disablePadding: false, label: 'SL'},
    {id: 'visitDate', numeric: false, disablePadding: false, label: 'Date'},
    {id: 'patientNo', numeric: false, disablePadding: false, label: 'Patient NO.'},
    {id: 'patient', numeric: false, disablePadding: false, label: 'Patient Name'},
    {id: 'phone_no', numeric: false, disablePadding: false, label: 'Phone'},
    {id: 'kin_phone_no', numeric: false, disablePadding: false, label: 'NOK Phone'},
    {id: 'actions', numeric: false, disablePadding: true, label: 'Action'},
];

export const ScanAndLabResults = ({actions,snackbars}) => {
    const {searchValue, pendingRequests, total_count, activePage,
         handleSearch, closeSnackbar, handleChangeActivePage, handleRefresh,
         isLoading, isSuccess, isRejected} = useLabScanResults(actions,2);
    const {openBar, type, message} = snackbars;
    const patients = !pendingRequests ? [] : pendingRequests;
    const components = [{label: 'Admitted Patients', path: `/patientroundlist`}, {
        label: 'Lab/Radiology Reports',
        path: `/medicationresults`
    },{label: 'Discharged Patients', path: `/dischargelist`}];
    return (
        <div data-testid="scan-lab-results" className='journals'>
            <PageTitle title="Lab/Radiology Reports"/>
            <SubHeader title="Medication and Rounds" subTitle="Lab/Radiology Reports">
                <FontAwesomeIcon icon={faNotesMedical}/>
            </SubHeader>
            <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/>
            <Container>
                <div className="mui-tables">
                    <MainSnackbar variant={type} message={message} open={openBar} handleCloseBar={closeSnackbar}/>
                    <RightAlignedContainer>
                        <div className="text-right">
                            <button onClick={handleRefresh} className="btn btn-sm sms-info-btn mb-2">Refresh</button>
                        </div>
                    </RightAlignedContainer>
                    <CustomTable title="Reports" headData={headData} handler={handleSearch} term={searchValue}
                                data={patients} colSpan={headData.length}
                                customPage records={10} activePage={activePage}
                                handleChangeNextPage={handleChangeActivePage} total_count={total_count}>
        
                            <TableBody>
                                {isLoading ? <TableRow>
                            <TableCell colSpan={headData.length} align="center"><img src="/images/smsloader.gif"
                                                                    alt="loader"/></TableCell>
                        </TableRow>:null}
                                {isSuccess ? patients.length > 0 ? patients.slice(0, 10)
                                    .map((item, index) => {
                                        const count = ((activePage - 1) * 10) + index + 1;
                                        const url = item.labrequest_id ? 'doctorlab' : item.scan_request_id ? 'doctorradiology':
                                            (item.labrequest_id && item.scan_request_id) ? 'doctorlab':'previousvisits'
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={item.visit_id}
                                                className='mui-table-row'>
                                                <TableCell>{count}</TableCell>
                                                <TableCell>{item.time_requested ? dateStringConvert(item.time_requested) : ''}</TableCell>
                                                <TableCell>{item.patient_number}</TableCell>
                                                <TableCell>{`${item.first_name} ${item.last_name}`}</TableCell>
                                                <TableCell>{item.phone_no}</TableCell>
                                                <TableCell>{item.kin_phone_no}</TableCell>
                                                <TableCell>
                                                    <Link
                                                        to={{pathname:`/${url}/${item.patient_number}/${item.visit_id}/${item.labrequest_id}/${item.scan_request_id}/2/${item.patient_admission_id}`}}>
                                                        <button className="btn btn-sm sms-info-btn mr-1">View</button>
                                                    </Link>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    }):<TableRow>
                                    <TableCell colSpan={headData.length} align="center">No records found</TableCell>
                                </TableRow>:null}
                                {isRejected ? <TableRow>
                                    <TableCell colSpan={headData.length} align="center">The server did not return a valid response</TableCell>
                                </TableRow>:null}
                            </TableBody>
                            
                    </CustomTable>
                </div>
            </Container>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScanAndLabResults);
