import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Collapse from "@material-ui/core/Collapse";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import {makeStyles} from "@material-ui/core/styles";
import * as colors from "../../../styles/colors"

export const useStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    icon:{
        width:'0.75em',
        height:'0.75em'
    },
    tableCell:{
        padding:'7px 8px'
    },
    firstCell:{
        padding:'7px 8px'
    },
    headCell:{
        padding:'7px 8px',
        borderTop:`1px solid ${colors.gray200}`,
        borderBottom:`1px solid ${colors.gray200}`,
        fontWeight:700
    },
    openCell:{
        fontWeight:700
    }
});

function StockProductsTable({children, colSpan, headData=[]}) {
    const classes = useStyles();
    return (
        <TableContainer>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        {headData.map((item, index)=>(
                            <TableCell colSpan={colSpan} key={index} className={classes.headCell}>{item.name}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {children}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

function StockProductRow({product, open, handleOpen, children, colSpan,cellSpan,preview=false, action=null}) {
    const classes = useStyles();

    return (
        <React.Fragment>
            <TableRow className={classes.root}>
                <TableCell colSpan={colSpan} component="th" scope="row" className={classes.firstCell}>
                    {product} <IconButton aria-label="expand row" size="small" onClick={handleOpen}>
                        {open ? <KeyboardArrowDownIcon className={classes.icon}/> : <ChevronRightIcon className={classes.icon}/>}
                    </IconButton>
                </TableCell>
                {action}
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: (open && !preview) || (!open && !preview) ?  '30px' : '0 ', paddingRight:0, paddingLeft:'7px', paddingTop:0, borderBottom:0 }} colSpan={cellSpan}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        {children}
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export {StockProductsTable, StockProductRow}