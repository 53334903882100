import {useEffect, useState} from "react";
import { history } from "../../../../Helpers/history";
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import { dateConvert } from "../../../Utils/ConvertDate";

export const useAdmissionList = (actions, url) => {
    const [searchValue, setSearchValue] = useState('');
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('sl');
    const [activePage, setActivePage] = useState(1);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [admissionList, setAdmissionList] = useState({});
    const [loading, setLoading] = useState('idle');
    const [admission, setAdmissionId] = useState({});
    const [refresh, setRefresh] = useState(false);
    const [state, setState] = useState({from_date: dateConvert(), to_date: dateConvert()});
    const [submitted, setSubmitted] = useState(false);
    const [filter, setFilter] = useState('Follow up date');
    const [payment, setPayment] = useState({value: '', label: 'All'},)
    const {from_date, to_date} = state;

    useEffect(() => {
        const formData = new FormData();
        formData.append('search', searchValue);
        formData.append('start_date', from_date)
        formData.append('end_date', to_date)
        formData.append('per_page', rowsPerPage)
        formData.append('mode_of_payment', payment?.value)
        setLoading('pending');
        axios.post(`${config.smsUrl}/${url}/${activePage}`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            setAdmissionList(dt);
            setLoading('success')
        }).catch(err => {
            setLoading('error');
            logoutErrorMessage(err, null, actions)
        })
    }, [activePage, searchValue, payment, rowsPerPage]);

    const handleRefresh = () =>{
        setRefresh(!refresh)
    }

    const handleSearch = (event) => {
        setSearchValue(event.target.value);
        setActivePage(1)
    };

    const handleChangeOption = (e) => {
       setFilter(e.target.value)
    }

    const handleSubmit = (e) => {
       e.preventDefault();
       setSubmitted(true);

       let date_option = '';
       if( filter === 'Follow up date'){
        date_option = 'follow_up'  
       } else if(filter=== 'Discharge date') {
        date_option = 'discharge'
       }
        
        const formData = new FormData();

        formData.append('date_option', date_option)
        formData.append('start_date', from_date)
        formData.append('end_date', to_date)
        formData.append('per_page', rowsPerPage)
        formData.append('mode_of_payment', payment?.value)
       if(date_option) {
       
        setLoading('pending')
        axios.post(`${config.smsUrl}/${url}/${activePage}`, formData).then(res => {
            const data = res.data;
            const dta = !data ? {} : data;
            setAdmissionList(dta);
            setLoading('success')
            
        }).catch(err => {
            setLoading('error');
            logoutErrorMessage(err, null, actions)
        })
       }
   
    }

    const handleChangeDate = (e) => {
        const {value, name} = e.target;
        setState({...state,[name]:value})
        setActivePage(1)
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleClick = ( visit_id) =>{
        history.push(`/patientadmissiondetails/${visit_id}`)


    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setActivePage(newPage+1)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        setActivePage(1);
    };
     

    const handleChangeNextPage = (value) => {
        setActivePage(value)
    };
  
    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar();
    };

    const isLoading = loading === 'pending';
    const isSuccess = loading === 'success';
    const isError = loading === 'error';

    return {searchValue,  order, orderBy, activePage, admissionList, loading,handleClick,
        admission, handleSearch, handleRequestSort, handleChangeNextPage, closeSnackbar,submitted,
        setAdmissionList,setAdmissionId, handleRefresh, isError, isLoading, isSuccess, state,
        handleChangeOption, handleSubmit, handleChangeDate,payment, setPayment,
        page, rowsPerPage, handleChangeRowsPerPage, handleChangePage
    }
}