import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MoreIcon from '@material-ui/icons/MoreVert';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {history} from "../Helpers/history";
import {titleCase} from "../components/Users/addUser";
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import Brightness2Icon from '@material-ui/icons/Brightness2';
import Tooltip from '@material-ui/core/Tooltip';
import HomeIcon from '@material-ui/icons/Home';
import {Link} from "react-router-dom";
import * as snackbarActions from "../actions/snackbarActions"
import NotificationsIcon from '@material-ui/icons/Notifications';
import { Badge } from '@material-ui/core';
import AddShoppingCartOutlinedIcon from "@material-ui/icons/AddShoppingCartOutlined";
import ScheduleOutlinedIcon from "@material-ui/icons/ScheduleOutlined";
import { config } from "../Helpers/env";
import axios from "axios";
import List from "@material-ui/core/List";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ListItem from "@material-ui/core/ListItem";
import Collapse from '@material-ui/core/Collapse';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { useVersionsContext } from '../Context/versions-context';

// styles for the elements in the AppHeader
const useAppHeaderStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  iconButtonColor: {
    color: '#3B509E !important'
  }, 
  innerIconButtonColor: {
    color: '#5c76d9 !important'
  }
}));

function AppHeader(props) {
  const classes = useAppHeaderStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const [expired_items, setExpiredItems] = useState(0);
  const [out_of_stock, setOutOfStock] = useState(0);
  const [expired, setExpired] = useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const isExpiredOpen = Boolean(expired);
  const [search, setSearch] = useState('');

  // hooks for the small menu
  const [isSmallProfileOpen, setSmallProfileOpen] = useState(false);
  const [isSmallNotificationsOpen, setSmallNotificationsOpen] = useState(false);

  const {subscription} = useVersionsContext(props)

  useEffect(() => {
    const formData = new FormData();
    formData.append('search', search);
    (async ()=>{
      try {
          const getExpiredDrugs = axios.post(`${config.smsUrl}/creport/expired_drugs/1`, formData);
          const getOutOfStock = axios.post(`${config.smsUrl}/creport/out_of_stock/1`, formData);
          const [expiredResponse, outOfStockResponse] = await Promise.all([getExpiredDrugs, getOutOfStock]);
          const expired = expiredResponse.data;
          const exp = expired.total_count ? expired.total_count : 0;
          const outOfStock = outOfStockResponse.data;
          const stock = outOfStock.total_count ? outOfStock.total_count : 0;
          setExpiredItems(exp);
          setOutOfStock(stock);
      } catch (error) {
          // errorMessages(error, null, actions)
      }
  })()
   
  }, [])
  
  const notifications = expired_items + out_of_stock;

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleClickOpen = () => {
    setOpen(true)
  };
  
  const handleExpiredMenuOpen = (event) => {
    setExpired(event.currentTarget);
  };

  const gotoDashboard = () => {
    history.push('/');
    handleMobileMenuClose();
  }

  const logOut = () => {
    history.push("/login");
    sessionStorage.clear()
    setSmallProfileOpen(false);
    if (isMobileMenuOpen) {handleMobileMenuClose();}
  };

  const changePassword = () =>{
    history.push('/changepassword');
    setSmallProfileOpen(false);
    if (isMobileMenuOpen) {handleMobileMenuClose();}
  };

  const handleExpiredItems  = () =>{
    history.push('/reports/expiredproducts');
    handleExpiredMenuClose();
    setSmallNotificationsOpen(false);
  };

  const handleOutOfStockItems  = () =>{
      history.push('/reports/outofstock');
      handleExpiredMenuClose();
      setSmallNotificationsOpen(false);
  };

  const handleExpiredMenuClose =() => {
    setExpired(null);
    if (isMobileMenuOpen) {handleMobileMenuClose();}
  }

  // style for the gray vertical line
  const vertical_line_style = {
    borderLeft: '2px solid #D7D7D7',
    borderRadius: '8px',
    height: 'auto',
    marginLeft: '10px',
    marginRight: '15px',
    marginTop: '6px',
    marginBottom: '6px'
  }

  const user = JSON.parse(sessionStorage.getItem("user"));
  const userObj = !user ? {} : user;
  const token = !userObj.token ? [] : userObj.token;
  const _token = !token[1] ? {} : token[1];
  const first_name = !_token.first_name ? '' : _token.first_name;
  const last_name = !_token.last_name ? '' : _token.last_name;
  const {onClick, appBar, menuBtn, position, toggleSwitch, palette} = props;

  const expiredId = "expired-menu";
    const renderExpiredMenu = (
        <Menu
            anchorEl={expired}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={expiredId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isExpiredOpen}
            onClose={handleExpiredMenuClose}
        >
            <MenuItem style={{color: "#616771"}} onClick={handleExpiredItems} className="mui-menu-item">
                <ScheduleOutlinedIcon classes={{root: classes.innerIconButtonColor}}/>
                <span className="ml-3">Expired Items</span>
                <p style={{margin:'5px', marginLeft: '20px'}}><Badge badgeContent={expired_items} color="secondary"/></p>
            </MenuItem>
            <MenuItem style={{color: "#616771"}} onClick={handleOutOfStockItems} className="mui-menu-item">
                <AddShoppingCartOutlinedIcon classes={{root: classes.innerIconButtonColor}}/>
                <span className="ml-3">Out of Stock Items</span>
                <p style={{margin:'5px', marginLeft: '20px'}}><Badge badgeContent={out_of_stock} color="secondary"/></p>
            </MenuItem>
        </Menu>
    );


  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{vertical: 'top', horizontal: 'right'}}
      id={menuId}
      keepMounted
      transformOrigin={{vertical: 'top', horizontal: 'right'}}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem className="mui-menu-item" onClick={changePassword}><span
        className='responsive-menu-text '>Change Password</span></MenuItem>
      <MenuItem className="mui-menu-item" id='logout' onClick={logOut}><span
        className='responsive-menu-text'>Logout</span></MenuItem>
    </Menu>
  );
  
  // menu for mobile

  const handleSmallFilterOpen = () => {
    setSmallNotificationsOpen(!isSmallNotificationsOpen);
    if (isSmallProfileOpen) { setSmallProfileOpen(false); }
  }

  const handleSmallSettingsOpen = () =>{
    setSmallProfileOpen(!isSmallProfileOpen);
    if (isSmallNotificationsOpen) { setSmallNotificationsOpen(false); }
  }

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{vertical: 'top', horizontal: 'right'}}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{vertical: 'top', horizontal: 'right'}}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={gotoDashboard}>
          <IconButton aria-label="home" color="inherit">
            <HomeIcon colorInterpolationFilters="inherit" classes={{root: classes.iconButtonColor}}/>
          </IconButton>
          <p style={{margin:'5px'}}>Home</p>
      </MenuItem>
      {/* The Docs should direct you to the user guide of Mpeke HMIS
      <MenuItem>
        <IconButton aria-label="docs" color="inherit">
          <LibraryBooksIcon colorInterpolationFilters="inherit" classes={{root: classes.iconButtonColor}}/>
        </IconButton>
        <p>Docs</p>
      </MenuItem> */}
      <MenuItem  onClick={handleSmallFilterOpen}>
        <IconButton aria-label="docs" color="inherit">
          <NotificationsIcon colorInterpolationFilters="inherit" classes={{root: classes.iconButtonColor}}/>
        </IconButton>
        <p style={{margin:'5px'}}>Notifications</p>
        <p style={{margin:'5px', marginLeft: '20px'}}><Badge badgeContent={notifications} color="secondary"/></p>
        <p style={{margin:'5px', marginLeft: '45px'}}>
        {isSmallNotificationsOpen ? <ExpandMore classes={{root: classes.iconButtonColor}}/> :
            <ChevronLeftIcon classes={{root: classes.iconButtonColor}}/>}
        </p>
      </MenuItem>

      <Collapse in={isSmallNotificationsOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding style={{paddingLeft:'35px', paddingRight: '20px'}}>
              <ListItem button onClick={handleExpiredItems}>
                    <ScheduleOutlinedIcon  classes={{root: classes.innerIconButtonColor}}/>
                    <span className="ml-3">Expired Items</span>
                    <p style={{margin:'5px', marginLeft: '20px'}}><Badge badgeContent={expired_items} color="secondary"/></p>
              </ListItem>
              <ListItem button onClick={handleOutOfStockItems}>
                  <AddShoppingCartOutlinedIcon  classes={{root: classes.innerIconButtonColor}}/>
                  <span className="ml-3">Out of Stock Items</span>
                  <p style={{margin:'5px', marginLeft: '20px'}}><Badge badgeContent={out_of_stock} color="secondary"/></p>
              </ListItem>                 
          </List>
      </Collapse>

      <MenuItem>
        <IconButton aria-label="docs" color="inherit" onClick={toggleSwitch}>
          {palette.type === "dark" ?
            <WbSunnyIcon colorInterpolationFilters="inherit" color="action"/> :
            <Brightness2Icon colorInterpolationFilters="inherit" classes={{root: classes.iconButtonColor}}/>}
        </IconButton>
        <p  style={{margin:'5px'}}>Switch to light/dark theme</p>
      </MenuItem>


      <MenuItem onClick={handleSmallSettingsOpen}>
          <IconButton
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <AccountCircle colorInterpolationFilters="inherit" classes={{root: classes.iconButtonColor}}/>
          </IconButton>
          <p style={{margin:'5px'}}>Profile</p>
          <p style={{margin:'5px', paddingLeft:'120px'}}>
            {isSmallProfileOpen ? <ExpandMore classes={{root: classes.iconButtonColor}}/> :
              <ChevronLeftIcon classes={{root: classes.iconButtonColor}}/>}
          </p>
      </MenuItem>

      <Collapse in={isSmallProfileOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding style={{paddingLeft:'35px', paddingRight: '20px'}}>
              <ListItem button onClick={changePassword}>
                <span className='responsive-menu-text '>Change Password</span>
              </ListItem>
              <ListItem button onClick={logOut}>
                <span className='responsive-menu-text'>Logout</span>
              </ListItem>       
          </List>
      </Collapse>

    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar position={position} className={appBar} color="inherit" elevation={0}>
        <Toolbar>
          <IconButton
            edge="start"
            className={menuBtn}
            color="inherit"
            aria-label="open drawer"
            onClick={onClick}
          >
            <MenuIcon classes={{root: classes.iconButtonColor}}/>
          </IconButton>
          <div className={classes.grow}/>
          <div className={classes.sectionDesktop}>
            <Tooltip title='Home'>
              <Link to="/">
                <IconButton aria-label="home" color="inherit">
                  <HomeIcon colorInterpolationFilters="inherit" classes={{root: classes.iconButtonColor}}/>
                </IconButton>
              </Link>
            </Tooltip>
            {/*<Tooltip title='Docs'>
              <a
                href="https://mpekehmis.marascientific.com/"
                target='_blank'>
                <IconButton aria-label="docs" >
                  <LibraryBooksIcon colorInterpolationFilters="inherit" classes={{root: classes.iconButtonColor}}/>
                </IconButton>
              </a>
              </Tooltip>*/}
            <Tooltip title='Notifications'onClick={handleExpiredMenuOpen} aria-controls={expiredId}>
                <IconButton aria-label="docs" color="inherit">
                <Badge badgeContent={notifications} color="secondary">
                <NotificationsIcon colorInterpolationFilters="inherit" color="action"  classes={{root: classes.iconButtonColor}}/>
                </Badge>  
                </IconButton>
            </Tooltip>
            <Tooltip title='Switch to light/dark theme'>
              <IconButton aria-label="docs" onClick={toggleSwitch}>
                {palette.type === "dark" ?
                  <WbSunnyIcon colorInterpolationFilters="inherit" color="action"/> :
                  <Brightness2Icon colorInterpolationFilters="inherit" classes={{root: classes.iconButtonColor}}/>}
              </IconButton>
            </Tooltip>
            <div style={vertical_line_style}/>
              <div style={{marginRight: '10px', paddingTop:'5px', paddingBottom: '5px'}}>
              <p style={{margin: 4, fontWeight: 600, fontSize: '12px', textAlign: 'right'}}>
                {titleCase(`${first_name} ${last_name}`)}
              </p>
              <p style={{margin: 0, fontWeight: 400, fontSize: '10px', textAlign: 'right'}}>
                {_token.isAdmin ? `Admin.` : ``}
              </p>
              
            </div>
            <Tooltip title='User'>
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
                id='user-account'
              >
                <AccountCircle colorInterpolationFilters="inherit" classes={{root: classes.iconButtonColor}}/>
              </IconButton>
            </Tooltip>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon classes={{root: classes.iconButtonColor}}/>
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderExpiredMenu}
      {renderMenu}
    </div>
  );
}

function mapStateToProps(state) {
  const {snackbar} = state;
  return {
    snackbars: snackbar
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader);
