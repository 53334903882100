import { useState, useEffect } from "react";
import axios from "axios";
import { config } from "../../../../../Helpers/env";
import { history } from "../../../../../Helpers/history";
import {errorMessages, logoutErrorMessage} from "../../../../../Helpers/ErrorMessages";

export const useANCLists  = (actions, anc_status) => {
    const [searchValue, setSearchValue] = useState('');
    const [ancList, setANCList] = useState({ancs: [], total_count:0});
    const [activeTablePage, setActiveTablePage] = useState(1);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('sl');
    const [ancStatus, setANCStatus] = useState(anc_status);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [loading, setLoading] = useState('idle');
    const [openAssign, setOpenAssign] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [doctor_name, setDoctor] = useState('');
    const [doctors, setDoctors] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [department, setDepartment] = useState('');
    const [ids, setIds] = useState({patient_number: '', visit_id: '',patient_name:'',anc_id:0});
    const [openDialog, setOpenDialog] = useState(false);

    const isResolved = isSubmitted === 'resolved';
    const isPending = isSubmitted === 'pending';
    const isRejected = isSubmitted === 'reject';
    const isLoading = isSubmitted === 'pending';
    const isSuccess = isSubmitted === 'success';
    const isError = isSubmitted === 'error';

    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;

    useEffect(() => {
        axios.get(`${config.smsUrl}/cvisit/retrieve_department`).then(res=>{
            const data = res.data ?? {}
            let departments = data.departments ?? [];
            const arr = departments.map(item=>({
                value:item.department_id, label:item.department_name
            }))
            setDepartments(arr)
        }).catch(err=>{
            logoutErrorMessage(err, null, actions)
        })
    }, []);

    useEffect(() => {
        const formD = new FormData();
        formD.append("status", ancStatus);
        formD.append("page", activeTablePage);

        axios.post(`${config.smsUrl}/antenatal/antenatal_list/${activeTablePage}`, formD).then(res => {

            const response = res['data'];
            const dt = response ? response['antenatal_list'] : [];

            const anc_arr = dt.map((item, index) => ({
                count: (activeTablePage - 1) * 10 + (index + 1),
                date: item.begin_datetime,
                'visit_date': new Date(),
                'patient_number': item.patient_number,
                'service_id':item.service_id,
                'patient_name': `${item.patient_firstname} ${item.patient_lastname}`,
                'anc_id':item.id,
                'visit_id':item.visit_id,
                'anc_status':item.status
            }));

            setANCList({ancs: anc_arr, total_count: anc_arr.length});
            setIsSubmitted('success')
            //setLoading('success');
        }).catch(err => {
            logoutErrorMessage(err, null, actions);
           setIsSubmitted('error');
            // setLoading('error');
        }) 

    }, [ancStatus, activeTablePage, isResolved]); 

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangeNewPage = (page) => {
        setActiveTablePage(page);
    }

    const handleSearch = (value) => {
        setSearchValue(value);
        const formD = new FormData();
        formD.append("status", ancStatus);
        formD.append("page", activeTablePage);
        formD.append('search', value);

        axios.post(`${config.smsUrl}/antenatal/antenatal_list/${activeTablePage}`, formD).then(res => {

            const response = res['data'];
            const dt = response ? response['antenatal_list'] : [];

            const anc_arr = dt.map((item, index) => ({
                count: (activeTablePage - 1) * 10 + (index + 1),
                date: item.begin_datetime,
                'visit_date': new Date(),
                'patient_number': item.patient_number,
                'service_id':item.service_id,
                'patient_name': `${item.patient_firstname} ${item.patient_lastname}`,
                'anc_id':item.id,
                'visit_id':item.visit_id,
                'anc_status':item.status
            }));

            setANCList({ancs: anc_arr, total_count: anc_arr.length});
            setIsSubmitted('success')
        }).catch(err => {
            logoutErrorMessage(err, null, actions);
           setIsSubmitted('error');
        }) 
        
    }

    const handleOpenAssign = (item) => {
        setOpenAssign(true);
        setIds(item);
    };

    const retrieveDoctors = (d_id) =>{
        const formData = new FormData();
        formData.append('department_id', d_id);
        axios.post(`${config.smsUrl}/cvisit/getdoctorsbydepartment_id`, formData).then(res => {
            const data = res.data;
            const dt = !data ? [] : data;
            const doctors = !dt.doctors ? [] : dt.doctors;
            const arr = doctors.map(doctor => ({value: doctor.user_roles_id,
                label: `${doctor.first_name} ${doctor.last_name}`
            }))
            setDoctors(arr)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }

    const handleCloseAssign = () => {
        setOpenAssign(false)
    };

    const assignDoctor = (event) => {
        event.preventDefault();
        const formData = new FormData();
        const {visit_id} = ids;

        formData.append('visit_id', visit_id);
        formData.append('department_id', department?.value);
        formData.append('user_roles_id', doctor_name.value);
        setSubmitted(true)
        if (doctor_name && department) {
            setIsSubmitted('pending')
            axios.post(`${config.smsUrl}/cvisit/reassign_patient`, formData).then(() => {
                actions.snackbarActions.snackSuccess('Patient reassigned successfully');
                setIsSubmitted('resolved')
                setOpenAssign(false)
                setDepartment('');
                setDoctor('')
                setSubmitted(false)
            }).catch(e => {
                errorMessages(e, null, actions);
                setIsSubmitted('rejected')
                setOpenAssign(false)
            });
           
        }
    };

    const handleChangeDoctor = (value) => {
        setDoctor(value)
    }

    const handleChangeDepartment = (value) => {
        setDepartment(value)
        retrieveDoctors(value.value)
    }

    const handleTakeUpPatient = (item) => {
        sessionStorage.setItem(`current_anc_status_${item['visit_id']}`, JSON.stringify({'anc_stat' : 'start'}));
        history.push(`/antenatal_patient_form/${item.patient_number}/${item.visit_id}/${null}/${null}/1/${null}/${item.anc_id}/${item['anc_status']}`);
    }

    const handleContinuePatient = (item) => {
        history.push(`/antenatal_patient_form/${item.patient_number}/${item.visit_id}/${null}/${null}/1/${null}/${item.anc_id}/${item['anc_status']}`);
    }

    const handleCompleteVisit = (item) => {
        const formData = new FormData();
        formData.append('id', item['anc_id']);
        axios.post(`${config.smsUrl}/antenatal/complete_antenatal`, formData).then(() => {
            setANCStatus(2);
        }).catch((err) => {
          logoutErrorMessage(err, null, actions)
        });
    }

    const handleCloseDialog = () => {
        setOpenDialog(false);
    }

    const handleViewPastANC = (item) => {
    
        const formData1 = new FormData();
        formData1.append('visit_id', item['visit_id']);

        const formData2 = new FormData();
        formData2.append('patient_number', item['patient_number']);
        
        const promise1 = axios.post(`${config.smsUrl}/antenatal/view_antenatal_details`, formData1);
        const promise2 = axios.post(`${config.smsUrl}/cdoctor/get_all_patient_treatment_details`, formData1);
        const promise3 = axios.post(`${config.smsUrl}/cdoctor/get_patient_details`, formData2);

        Promise.all([promise1, promise2, promise3]).then(([res1, res2, res3]) => {

            const resp1 = res1['data'];
            const resp2 = res2['data'];
            const resp3 = res3['data'];

            const antenatal_data = resp1 ? resp1 : {};
            const other_details = resp2 ? resp2 : {};
            const patient_details = resp3 ? resp3 : {};

            const triage = other_details['triage'] ? other_details['triage'] : [];
            const lab = other_details['lab'] ? other_details['lab'] : [];
            const visit_detail = other_details['visit_detail'] ? other_details['visit_detail'] : [];
            const diagnosis = other_details['diagnosis'] ? other_details['diagnosis'] : [];
            const exam_notes = other_details['exam_notes'] ? other_details['exam_notes'] : [];
            const lab_result = other_details['lab_result'] ? other_details['lab_result'] : [];
            const other_medicine = other_details['other_medicine'] ? other_details['other_medicine'] : [];
            const scan = other_details['scan'] ? other_details['scan'] : [];
            const referral = other_details['referral'] ? other_details['referral'] : [];
            const prescription  = other_details['prescription'] ? other_details['prescription'] : [];

            const dict_items = {patient_details, antenatal_data, triage, prescription, referral, scan, visit_detail,
                lab, diagnosis, exam_notes, other_medicine, lab_result};

            setIds(dict_items);
            setOpenDialog(true);
            setIsSubmitted('resolved')
        }).catch((err) => {
          logoutErrorMessage(err, null, actions);
          setIsSubmitted('rejected')
        });
    }

    return {
        handleSearch, searchValue, activeTablePage, ancList, handleChangeNewPage, order, orderBy, handleRequestSort, handleContinuePatient,
        ancStatus, setANCStatus, isPending, isLoading, isError, isSuccess, isRejected, handleOpenAssign, handleCloseDialog,
        handleCloseAssign, openAssign, assignDoctor, submitted, isSubmitted,  ids, department, departments, handleChangeDoctor,
        doctor_name, doctors, handleChangeDepartment, handleTakeUpPatient, handleCompleteVisit, handleViewPastANC, openDialog
    }
}