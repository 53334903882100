import {useEffect, useState} from "react";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {logoutErrorMessage,errorMessages} from "../../../Helpers/ErrorMessages";

export const useAddBed = (actions) =>{
    const [state, setState] = useState({bed_number: '', description: '', status: '1'});
    const [ward, setWard] = useState('');
    const [room, setRoom] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [wards, setWards] = useState([]);
    const [rooms, setRooms] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState('idle')
    const [open, setOpen] = useState(false)
    const [bed, setBed] = useState({});
    const [openEditDialog, setOpenEditDialog] = useState(false)
    const [bed_id, setBedId] = useState('');


    useEffect(() => {
        if(!bed_id){
            return
        }
        const formData = new FormData();
        formData.append('bed_id', bed_id);
        axios.post(`${config.smsUrl}/cbedmanager/bed_update_form`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            setBed(dt)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, [bed_id]);


    useEffect(() => {
        if(!bed_id && bed){
            return
        }
        const {bed_number, description, word_name, ward_id, room_id, room_name} = bed;
        setState({bed_number, description, status:'1'});
        setWard({value: ward_id, label: word_name});
        setRoom({value: room_id, label: room_name})
    }, [bed]);



    useEffect(() => {
        axios.get(`${config.smsUrl}/cbedmanager/getAllWards`).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const list = !dt.wards ? [] : dt.wards;
            const arr = list.map(item=>({value:item.ward_id, label:item.name}))
            setWards(arr)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, []);


    const handleChange = (event) => {
        const {name, value} = event.target;
        setState({...state, [name]: value})
    };

    const handleChangeWard = (value) => {
        setWard(value);
        const formData = new FormData();
        formData.append('ward_id', value.value);
        axios.post(`${config.smsUrl}/cbedmanager/retrieve_room_by_ward_id`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const list = !dt.room ? [] : dt.room;
            const arr = list.map(item=>({value:item.room_id, label:item.name}))
            setRooms(arr)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    };

    const handleChangeRoom = (value) => {
        setRoom(value)
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setSubmitted(true);
        const formData = new FormData(event.currentTarget);
        const fieldValue = Object.fromEntries(formData.entries())
        if(bed_id){
            formData.append('bed_id', bed_id);
        }
        formData.append('room_id', room.value);
        if (ward && room && fieldValue['bed_number']) {
            setIsSubmitted('pending')
            const url = bed_id ? 'bed_update' : 'add_bed'
            axios.post(`${config.smsUrl}/cbedmanager/${url}`, formData).then(()=>{
                actions.snackbarActions.snackSuccess('Bed added successfully');
                setIsSubmitted('resolved')
                setState({bed_number: '', description: '', status: '1'})
                setRoom('')
                setWard('')
                setSubmitted(false)
                setOpen(false)
                setBedId('')
                setOpenEditDialog(false)
            }).catch(err=>{
                errorMessages(err, null, actions)
                setIsSubmitted('rejected')
            })
        }
          };

    const handleResetForm = () => {
        setState({bed_number: '', description: '', status: ''});
        setWard(null);
        setRoom(null)
        setBedId('')
    };

    const handleOpenDialogForm =()=>{
        setOpen(true)
    }

    const handleCloseDialogForm =()=> {
        setOpen(false)
    }

    const handleOpenEditDialog =(bed_id)=>{
        setOpenEditDialog(true)
        setBedId(bed_id)
    }

    const handleCloseEditDialog =()=>{
        setOpenEditDialog(false)
        setState({bed_number: '', description: '', status: ''});
        setWard(null);
        setRoom(null)
    }

    const isPending = isSubmitted === 'pending';
    const isResolved = isSubmitted === 'resolved';
    const isRejected = isSubmitted === 'rejected';

    const closeSnackbar = () =>{
        actions.snackbarActions.hideSnackbar()
    };

    const bedProps = {
        state, ward, room, submitted, wards, rooms,handleChange, handleChangeWard,
        handleChangeRoom, handleSubmit, handleResetForm, closeSnackbar, isPending,
        isRejected, isResolved, handleOpenDialogForm, handleCloseDialogForm, open,openEditDialog,
        handleCloseEditDialog, handleOpenEditDialog
    }

    return {isResolved, bedProps}
}