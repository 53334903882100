import React, {useEffect, useState} from 'react';
import Card from "@material-ui/core/Card";
import PageTitle from "../Utils/smsTitle";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMortarPestle} from "@fortawesome/free-solid-svg-icons/faMortarPestle";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../actions/snackbarActions"
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import axios from "axios";
import { config } from "../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../Helpers/ErrorMessages";
import {history} from "../../Helpers/history";
import CustomDialog from "../Utils/Dialogs/CustomDialog";
import Form from "react-bootstrap/Form"
import Col from "react-bootstrap/Col";
import Label from "../Utils/FormInputs/Label";
import {ResizableTextarea} from "../Utils/FormInputs/Textarea";
import {ErrorMessage, RightAlignedContainer} from "../Utils/styledComponents";
import { faWheelchair } from "@fortawesome/free-solid-svg-icons/faWheelchair";

const DispatchedItems = ({snackbar, actions,match}) => {
    const [products, setProducts] = useState([])
    const [submitted, setSubmitted] = useState(false);
    const [openDialog, setOpenDialog] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState('idle')
    const [reason, setReason] = useState('')
    const {module_name, dispatch_id} = match.params;

    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = _token.user_roles_id ? _token.user_roles_id : ''


    useEffect(() => {
        const formData = new FormData();
        formData.append('dispatch_id', dispatch_id);
        axios.post(`${config.smsUrl}/cinventory/pharmacy_dispatch_items`, {
            dispatch_id
        }).then(res => {
            const data = res.data;
            const all_data = !data.data ? [] : data.data;
            setProducts(all_data);
        }).catch(e => {
            logoutErrorMessage(e, null, actions)
        })
    }, [dispatch_id]);

    const handleChangeReason = (event) =>{
        setReason(event.target.value)
    }

    const handleOpenDialog = () =>{
        setOpenDialog(true)
    }

    const handleCloseDialog = () =>{
        setOpenDialog(false);
        setReason('')
    }


    const handleCancel = (event) =>{
        event.preventDefault();
        setSubmitted(true);
        if(reason){
            setIsSubmitted('pendingCancel')
            axios.post(`${config.smsUrl}/cinventory/cancel_dispatch_note`, {
                created_by : user_roles_id,
                reason,
                'dispatch_id': parseInt(dispatch_id),
                dispatched_products : products               
            }).then(()=>{
                setSubmitted(false);
                actions.snackbarActions.snackSuccess('Dispatch dispatch successfully')
                setOpenDialog(false);
                setReason('');
                history.push(module_name === 'pharmacy' ? '/pharmacydispatchlist' : module_name === 'emergency' ? '/emergencydispatchlist' : '')
                setIsSubmitted('resolvedCancel')
            }).catch(err=>{
                setSubmitted(false)
                errorMessages(err, null, actions)
                setIsSubmitted('rejectedCancel')
            })
        }

    }




    const handleSubmit = (event) =>{
        event.preventDefault();
        setSubmitted(true);
        setIsSubmitted('pending')
        axios.post(`${config.smsUrl}/cinventory/receive_pharmacy_dispatch`, {received_by:user_roles_id, dispatch_id }).then(()=>{
            setSubmitted(false);
            actions.snackbarActions.snackSuccess('Products received successfully')
            history.push(module_name === 'pharmacy' ? '/pharmacydispatchlist' : module_name === 'emergency' ? '/emergencydispatchlist' : '')
            setIsSubmitted('resolved')
        }).catch(err=>{
            setSubmitted(false)
            errorMessages(err, null, actions)
            setIsSubmitted('rejected')
        })
    }

    const closeSnackbar = () =>{
        actions.snackbarActions.hideSnackbar()
    }

    const isPending = isSubmitted === 'pending'
    const isPendingCancel = isSubmitted === 'isPendingCancel'

    const {openBar, message, type} = snackbar;
    return (
        <div data-testid="dispatched_items">
            <PageTitle title="Dispatched Products"/>
            <SubHeader title={module_name === 'pharmacy' ? "Pharmacy" : 'Patient Emergency'} 
                subTitle="Receive products">
                <FontAwesomeIcon icon={faMortarPestle}/>
            </SubHeader>
            <CustomDialog open={openDialog} handleClose={handleCloseDialog} title='Cancel Dispatch'>
                <form onSubmit={handleCancel}>
                    <Form.Group as={Col}>
                        <Label name='Please provide a reason for canceling' type/>
                        <ResizableTextarea value={reason} submitted={submitted} onChange={handleChangeReason} rows={3} placeholder="Write reason...."/>
                        {submitted && !reason ? <ErrorMessage>This field is required</ErrorMessage>: null}
                    </Form.Group>
                    <RightAlignedContainer>
                        <button type="submit" disabled={isPendingCancel} className="btn btn-sm sms-btn  mr-3"  id="continue_cancel">
                            {isPending ? "Canceling...":"Continue"}</button>
                        <button type="button"  onClick={handleCloseDialog} className="btn btn-sm sms-gray-btn  mr-3"  id="cancel_dialog">
                            No</button>
                    </RightAlignedContainer>
                </form>
            </CustomDialog>
            <div className="billing-details">
                <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>
                <Card className="p-3">
                    <form autoComplete="off" onSubmit={handleSubmit}>
                        <div className="table-responsive">
                            <table className="table table-bordered table-sm profile-table strike-table">
                                <thead>
                                <tr>
                                    <td width="300">Product</td>
                                    <td width="200">Batch#</td>
                                    <td width="200">Quantity</td>
                                </tr>
                                </thead>
                                <tbody>
                                {products.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{item.product_name}</td>
                                            <td>{item.batch_id}</td>
                                            <td>{item.quantity}</td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                        <div className="text-right mr-5">
                            <button type="submit" disabled={isPending} className="btn btn-sm sms-btn mr-3"  id="receive_dispatch">
                                {isPending ? "Receiving...":"Receive Dispatch"}</button>
                            <button type="button"  onClick={handleOpenDialog} className="btn btn-sm sms-gray-btn  mr-3"  id="cancel_dispatch">
                                Cancel</button>
                        </div>
                    </form>
                </Card>
            </div>
        </div>
    )
}

function mapStateToProps(state){
    const {snackbar} = state;
    return {snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions:{snackbarActions:bindActionCreators(snackbarActions,dispatch)}}
}
export default connect(mapStateToProps, mapDispatchToProps)(DispatchedItems);