import  {useState} from 'react';
import axios from "axios";
import { config } from "../../../Helpers/env";
import {logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {titleCase} from "../../Users/addUser";


export const useEmergencyTreatment = (actions) => {
    const initialState = Array(5).fill({
        drug_name: '', dosage: '', quantity: '', route: '', comments: '',
         showDrug: false,  isError: false,rate:0, intake_time:''
    })
    const [submitted, setSubmitted] = useState(false);
    const [prescription, setPrescription] = useState(initialState);
    const [drugs, setDrugs] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState('idle');


    const retrieveDrugs = (event, idx) => {
        const arr = prescription.map((item, index) => {
            if (index === idx) {
                const formData = new FormData();
                formData.append('product_name', titleCase(event.target.value));
                if (event.target.value !== '') {
                    axios.post(`${config.smsUrl}/cdoctor/autocomplete_search_medicine`, formData).then(res => {
                        const resp = res.data;
                        const data = !resp ? [] : resp;
                        if (data[0] === "No Product Found") {
                            setDrugs([{value: '', label: 'No medicine found'}])
                        } else {
                            setDrugs(data);
                        }
                    }).catch(error => {
                        logoutErrorMessage(error, null, actions)
                    });
                    return {...item, drug_name: event.target.value, showDrug: true, isError: false}
                }
                return {...item, drug_name: event.target.value, showDrug: false, isError: false}
            }
            return item
        })
        setPrescription(arr)
        setIsSubmitted('idle')
    };

    const clickDrug = (event, idx) => {
        const arr = prescription.map((item, index) => {
            if (index === idx) {
                const formData = new FormData();
                formData.append('product_id', event.value);
                formData.append('location', 1); // 1 for store
                item.drug_name = event.innerText;
                item.product_id = event.value
                if (event.value) {
                    axios.post(`${config.smsUrl}/cdoctor/get_medicinedetails_byproduct_id`, formData).then(res => {
                        const resp = res.data;
                        const data = !resp ? {} : resp;
                        const {price, quantity} = data
                        item.rate = price;
                        item.status = quantity
                    }).catch(error => {
                        logoutErrorMessage(error, null, actions)
                    });
                    item.isError = false
                    item.showDrug = false
                    return item
                } else {
                    item.showDrug = false
                    item.isError = true
                    return item
                }
            }
            return item
        })
        setPrescription(arr)
    }


    const handleChangeItems = (event, index) => {
        const {name, value} = event.target;
        const arr = prescription.map((item, idx) => {
            if (idx === index) {
                return {...item, [name]: value}
            }
            return item
        })
        setPrescription(arr)
        setIsSubmitted('idle')
    };


    const handleAddRow = () => {
        setPrescription([...prescription, {
            drug_name: '', dosage: '', quantity: 0, route: '', comments: 0,
            showDrug: false,  isError: false,rate:0, intake_time:''
        }])
    };

    const removeRow = (index) => {
        const arr = prescription.filter((_, idx) => idx !== index)
        setPrescription(arr);
    }


    const prescriptionProps = {prescription, retrieveDrugs, clickDrug, handleChangeItems, handleAddRow,
        removeRow, submitted, drugs,}


    return {prescriptionProps,isSubmitted,  setIsSubmitted, setSubmitted, 
         setPrescription,  initialState}
}