import React from "react";
import CustomTable, {useHiddenColumns} from "../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import {getComparator, stableSort} from "../Utils/Tables/sortingFunctions";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {titleCase} from "../Users/addUser";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../actions/snackbarActions"
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import {formatDateTime, dateTimeConvert} from "../Utils/ConvertDate";
import {history} from "../../Helpers/history";
import PageTitle from "../Utils/smsTitle";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faVials} from "@fortawesome/free-solid-svg-icons/faVials";
import {ReusableRouteTabs} from "../Utils/Dialogs/ReusableTabs";
import { DateTimeFilter } from "../Utils/ReusableComponents/DateTimeFilter";
import { usePendingLabRequests } from "./CustomHooks/usePendingLabRequests";
import CustomDialog from "../Utils/Dialogs/CustomDialog";
import CollectSample from "./CollectSample";


const headData = [{id: 'count', numeric: false, disablePadding: false, label: 'SL', hidden:false},
    {id: 'date', numeric: false, disablePadding: false, label: 'Date', hidden:false},
    {id: 'patient_number', numeric: false, disablePadding: false, label: 'Patient No.', hidden:false},
    {id: 'patient_name', numeric: false, disablePadding: false, label: 'Patient Name', hidden:false},
    {id: 'phone_no', numeric: false, disablePadding: false, label: 'Phone', hidden:false},
    {id: 'kin_phone_no', numeric: false, disablePadding: false, label: 'NOK Phone', hidden:false},
    {id: 'doctor_name', numeric: false, disablePadding: false, label: 'Doctor', hidden:false},
    {id: 'action', numeric: false, disablePadding: false, label: 'Action', hidden:false},
];
export const LabRequestList = ({actions, snackbars}) => {
    const url = {tableUrl:'get_all_pending_lab_requests', exportUrl:'get_all_pending_lab_requests'};
    const {openBar, type, message} = snackbars;
    const {sampleProps,searchValue, order, orderBy,  activePage, total_count, reports:requests, 
        handleSearch, handleRequestSort, handleChangeNextPage, closeSnackbar,
         isLoading, isSuccess, isError, state,submitted, lab_queue,
        handleChange, handleSubmit, isPending,  openDialog, handleOpenDialog, handleCloseDialog
    } = usePendingLabRequests(actions,url)
    const components = [{label: 'Pending Lab Requests', path: "/labrequests", badge:true, badge_count:total_count},
        {label: 'Pending Lab Results', path:  "/pendingsamples"}, {label: ' Lab Results', path: "/labresultlist"},
        {label: 'Lab Reports', path: "/labreports"}];
    const current_date = new Date();       
    const requestList = !requests.data ? [] : requests.data;
    const {start_date, end_date} = state

// console.log(lab_queue)

    const reports = requestList.map((item, index)=>{
        const count = ((activePage - 1) * 10) + index + 1;
        return {
            ...item,
            count,
            date:item.time_requested ? formatDateTime(item.time_requested) : "",
            patient_name:titleCase(`${item.patient_firstname} ${item.patient_lastname}`),
            doctor_name:titleCase(`${item.doctor_firstname} ${item.doctor_lastname}`),
            action: (
                <button  onClick={() => handleOpenDialog({labrequest_id: item.labrequest_id,
                    current_date:dateTimeConvert(current_date),
                    visit_id:item.visit_id, activity_id:item.activity_id})}
                     className="btn btn-sm sms-info-btn">Collect Samples</button>
            )
        }
    })

    const {
        headCells, all_hidden, handleAllHeadCells, handleHeadCells
    } = useHiddenColumns(headData);
    return (
        <div className='journals'>
            <PageTitle title='Pending Lab Requests'/>
            <SubHeader title="Laboratory" subTitle='Pending Lab Requests'>
                <FontAwesomeIcon icon={faVials}/>
            </SubHeader>
            <CustomDialog title='Collect Sample' open={openDialog} handleClose={handleCloseDialog} maxWidth="md"  isContentOverflow={false} isPaperStyle={true} >
                <CollectSample {...{...sampleProps,count:total_count}}/>
            </CustomDialog>
            <MainSnackbar variant={type} handleCloseBar={closeSnackbar} open={openBar} message={message}/>
            <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/>
            <div className='mui-tables'>
                <DateTimeFilter {...{start_date,end_date, handleChange, submitted,handleSubmit,isPending, isFilter:false}}/>
                <CustomTable title="Pending Lab Requests"
                             headData={headCells} handler={handleSearch} term={searchValue}
                             handleRequestSort={handleRequestSort} data={reports} colSpan={5} order={order}
                             orderBy={orderBy} 
                             activePage={activePage} total_count={total_count} records={10} customPage
                             handleChangeNextPage={handleChangeNextPage}
                             {...{all_hidden, handleAllHeadCells, handleHeadCells}}>
                        <TableBody>
                            {isLoading ? <TableRow>
                                <TableCell colSpan={headCells.length} align="center"><img src="/images/smsloader.gif"
                                                                   alt="loader"/></TableCell>
                            </TableRow>:null}
                            {isSuccess ? reports.length > 0 ? stableSort(reports, getComparator(order, orderBy))
                                .slice(0, 10)
                                .map((item, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={index}
                                            className='mui-table-row'
                                           >
                                            {headCells.map((head)=>(
                                                <TableCell id={head.id}  key={head.id}
                                                           hidden={head.hidden}>{item[head.id]}</TableCell>
                                            ))}
                                        </TableRow>
                                    );
                                }):<TableRow>
                                <TableCell colSpan={headCells.length} align="center">No records found</TableCell>
                            </TableRow>:null}
                            {isError ? <TableRow>
                                <TableCell colSpan={headCells.length} align="center">The server did not return a valid response</TableCell>
                            </TableRow>:null}
                        </TableBody>
                </CustomTable>
            </div>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(LabRequestList);
