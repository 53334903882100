import React, {useEffect, useState} from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Label from "../Utils/FormInputs/Label";
import {CustomSelect} from "../Utils/FormInputs/SelectInput";
import TextField from "../Utils/FormInputs/TextField";
import { ErrorMessage } from "../Utils/styledComponents";
import {ResizableTextarea} from "../Utils/FormInputs/Textarea";
import InputGroup from "react-bootstrap/InputGroup";
import {convDate, dateConvert, dateStrokeConvert, gmtDateConvert} from "../Utils/ConvertDate";
import axios from "axios";
import {config} from "../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../Helpers/ErrorMessages";
import {ConfirmationDialog} from "../Utils/ReusableComponents/ConfirmationDialog";

const calculateDays = (start_date, end_date) =>{
    const n_date = new Date(end_date) - new Date(start_date)
    return  Math.ceil(n_date / (1000 * 60 * 60 * 24))
}

function EditBedAssignment({actions,openDialog,setOpenDialog,isSubmitted,setIsSubmitted,admissionDetails}) {
    const [state, setState] = useState({assign_date: '', discharge_date: '', description: '', status: '1',
        patient_name:'',patient_admission_id:'', quantity:0});
    const [submitted, setSubmitted] = useState(false);
    const [rooms, setRooms] = useState([]);
    const [wards, setWards] = useState([]);
    const [bedList, setBeds] = useState([]);
    const [ward_name, setWard] = useState('');
    const [room_name, setRoom] = useState('');
    const [bed_name, setBed] = useState('');
    const [currencyDetails, setCurrencyDetails] = useState({currency_id:'',currency_symbol:'', cost:0, is_provider:0, is_provided:0})
    const [openConfirmation, setOpenConfirmation] = useState(false)
    const [patient_room_id, setPatientRoomId] = useState('')
    const [isBlocking, setIsBlocking] = useState(false)
    const [isBlockDialog, setIsBlockDialog] = useState(false)
    const [quantity, setQuantity] = useState(0)


    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;

    useEffect(() => {
        if(!admissionDetails.bed_assignment_id){
            return
        }

        const bd = !admissionDetails ? {} : admissionDetails;
        const {bed_id, bed_number, ward_id, ward_name, room_id, room_name:name, assign_date, end_date} = bd;
        const days = calculateDays(convDate(assign_date), convDate(end_date))
        setState({ ...bd,assign_date:assign_date ? convDate(assign_date):'',status:'1',
            discharge_date:end_date ? convDate(end_date):'', patient_name: `${bd.first_name} ${bd.last_name}`});
        setQuantity(days)
        setWard({value: ward_id, label: ward_name});
        setRoom({value: room_id, label: name});
        setBed({value: bed_id, label: bed_number})
       setCurrencyDetails({...bd,cost:bd.rate})
        retrieveWards(ward_id)
        retrieveRooms(room_id, bd.visit_id, false)
    }, [admissionDetails]);

    const retrieveWards = (value) =>{
        const formData = new FormData();
        formData.append('ward_id', value);
        axios.post(`${config.smsUrl}/cbedmanager/retrieve_room_by_ward_id`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const list = !dt.room ? [] : dt.room;
            const arr = list.map(rm => ({label: rm.name, value: rm.room_id}))
            setRooms(arr)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }

    const retrieveRooms = (value, visit_id=state.visit_id, isPresent=true) =>{
        const formData = new FormData();
        formData.append('room_id', value);

        axios.post(`${config.smsUrl}/cbedmanager/retrieve_bed_by_room_id`, formData).then(res => {
            const resp = res.data;
            const dt = !resp ? {} : resp;
            axios.post(`${config.smsUrl}/cbilling/item_cost`, {visit_id: +visit_id, item_id: +value, item_type:'room'}).then(data => {
                const resObj = data.data;
                const dataObj = !resObj.data ? {} : resObj.data;
                setCurrencyDetails({...dataObj, currency_symbol: dataObj.currency})
                setPatientRoomId(value)
                if(dataObj.is_provider === 1 && dataObj.is_provided === 0 && isPresent){
                    setOpenConfirmation(true)
                }
                const list = !dt.all_beds ? [] : dt.all_beds;
                const arr = list.map(bd => ({label: bd.bed_number, value: bd.bed_id}))
                setBeds(arr);
            }).catch(error => {
                errorMessages(error, null, actions)
            });

        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
        setIsBlocking(true)
    }



    useEffect(() => {
        axios.get(`${config.smsUrl}/cbedmanager/getAllWards`).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const list = !dt.wards ? [] : dt.wards;
            const arr = list.map(wd => ({label: wd.name, value: wd.ward_id}))
            setWards(arr)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, []);

    const handleChangeWard = (value) => {
        setWard(value);
        setRoom('')
        setBed('')
        setCurrencyDetails({...currencyDetails, cost: 0})
        retrieveWards(value.value)
        setIsBlocking(true)

    };

    const handleChangeRoom = (value) => {
        setRoom(value);
        setBed('')
        retrieveRooms(value.value)
    };

    const handleCloseConfirmation = () =>{
        if(patient_room_id === room_name?.value){
            setRoom({value:null, label: null})
            setBeds([])
            setCurrencyDetails({...currencyDetails, cost: 0})
        }
        setOpenConfirmation(false)
    }

    const handleBillRoom = () =>{
        setOpenConfirmation(false)
    }


    const handleChangeBed = (value) => {
        setBed(value)
        setIsBlocking(true)
    };

    const handleChange = (event) => {
        const {name, value} = event.target;
        setState({...state, [name]: value})
        if (name === 'assign_date' || name==="discharge_date"){
            const start = name === 'assign_date' ? value : state.assign_date
            const end = name === 'discharge_date' ? value : state.discharge_date
            const days = calculateDays(convDate(start),convDate(end))

            setQuantity(days)
        }
        setIsBlocking(true)
    };


    // const handleClosePrintDialog = () => {
    //     setOpenPrintDialog(false)
    // };


    const handleSubmit = (event) => {
        event.preventDefault();
        setSubmitted(true);
        const {discharge_date,  assign_date,bed_assignment_id} = state;
        const {currency_id, is_provided, is_provider, cost } = currencyDetails

        setIsBlocking(false)
        if (ward_name && room_name && bed_name){
            setIsSubmitted('pending')
           const formData = new  FormData()
            formData.append('assign_date', assign_date)
            formData.append('end_date', discharge_date)
            formData.append('rate', cost)
            formData.append('quantity', quantity)
            formData.append('currency', currency_id)
            formData.append('is_provided', is_provided)
            formData.append('is_provider', is_provider)
            formData.append('bed_id', bed_name?.value)
            formData.append('bill_id', state?.bill_id)
            axios.put(`${config.smsUrl}/cbedmanager/bed_assignment/${bed_assignment_id}`, formData).then(() => {
                actions.snackbarActions.snackSuccess('Bed assigned successfully');
                setIsSubmitted('resolved')
                setOpenDialog(false)
            }).catch(err => {
                errorMessages(err, null, actions)
                setIsSubmitted('rejected')
            })
        }
    };


    const isPending = isSubmitted === 'pending';
    const {description,status, patient_name, patient_admission_id,
        assign_date, discharge_date} = state;
    const { cost, currency_symbol,} = currencyDetails

    return (
        <form autoComplete='off' onSubmit={handleSubmit} >
            <ConfirmationDialog open={openConfirmation} handleClose={handleCloseConfirmation}
                                title='Make cash payment'
                                confirmItems={handleBillRoom}
                                removeItems={handleCloseConfirmation} service='room'/>
            <Form.Group as={Row}>
                <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                    <Label name="Admission No."  type/>
                </Col>
                <Col xs={12} sm={12} md={12} lg={7} xl={7}>

                    <TextField type="text" value={patient_admission_id} disabled/>
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                    <Label name="Patient Name"/>
                </Col>
                <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                    <TextField disabled={true}   value={patient_name}
                               name="patient_name" type="text"  />
                </Col>
            </Form.Group>

            <Form.Group as={Row}>
                <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                    <Label name="Date Assigned"/>
                </Col>
                <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                    <TextField  value={assign_date} onChange={handleChange}
                               name="assign_date" type="date"  />
                </Col>
            </Form.Group>

            <Form.Group as={Row}>
                <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                    <Label name="Date Discharged"/>
                </Col>
                <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                    <TextField   value={discharge_date} onChange={handleChange}
                               name="discharge_date" type="date"  />
                </Col>
            </Form.Group>

            <Form.Group as={Row}>
                <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                    <Label  name="Ward" type/>
                </Col>
                <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                    <CustomSelect id="ward-name" submitted={submitted} options={wards} onChange={handleChangeWard} value={ward_name} dataTest="ward_name"/>
                    {(submitted && !ward_name) && <ErrorMessage>Ward is required</ErrorMessage>}
                </Col>
            </Form.Group>

            <Form.Group as={Row}>
                <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                    <Label  name="Room Name" type/>
                </Col>
                <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                    <CustomSelect id="room-name" submitted={submitted} options={rooms} onChange={handleChangeRoom} value={room_name} dataTest="room_name"/>
                    {(submitted && !room_name) && <ErrorMessage>Room name is required</ErrorMessage>}
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                    <Label  name="Room Cost"/>
                </Col>
                <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                    <InputGroup>
                        <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">{currency_symbol}</InputGroup.Text>
                        <TextField  type="number" value={cost} style={{pointerEvents:'none'}}/>
                    </InputGroup>
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                    <Label name="No. of days"/>
                </Col>
                <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                    <TextField  value={quantity} onChange={handleChange}
                                name="quantity" type="number" disabled />
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                    <Label  name="Bed Number" type/>
                </Col>
                <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                    <CustomSelect id="bed-number" {...{submitted}} options={bedList} onChange={handleChangeBed} value={bed_name} dataTest="room"/>
                    {(submitted && !bed_name) && <ErrorMessage>Bed number is required</ErrorMessage>}
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                    <Label  name="Description"/>
                </Col>
                <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                    <ResizableTextarea value={description} rows={2}
                                       onChange={handleChange} name="description"/>
                </Col>
            </Form.Group>

            <Form.Group as={Row}>
                <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                    <Label  name="Status" type/>
                </Col>
                <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                    <Form.Check type="radio" inline label="Active" name="status" value="1"  checked={status === "1"}
                                onChange={handleChange} id="active"/>
                    <Form.Check type="radio" inline label="Inactive" name="status" value="0" checked={status === "0"}
                                onChange={handleChange} id="inactive"/>
                    {(submitted && !status) && <ErrorMessage>Status is required</ErrorMessage>}
                </Col>
            </Form.Group>
            <button type="submit" data-testid="assign-bed" className="btn btn-sm sms-btn px-4" disabled={isPending}>
                {isPending ? "Saving...":"Save"}</button>
        </form>
    )
}

export {EditBedAssignment};
