import React, {useEffect, useRef, useState} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import * as snackbarActions from '../../../actions/snackbarActions';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { LoadingGif } from "../../Utils/Loader";
import { formatDigits } from "../../Utils/formatNumbers";
import { ProductsPurchased } from "./ProductsPurchased";
import { HospitalAddress } from "../../Utils/HospitalAddress";
import { BackButton } from "../../Utils/Buttons/DataExportationButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import { DataCell } from "../../Utils/Lists/CollapsibleList";
import styled from "@emotion/styled/macro";
import PageTitle from "../../Utils/smsTitle";
import { SubHeader } from "../../../Containers/SubHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardList } from "@fortawesome/free-solid-svg-icons/faClipboardList";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import { Container } from "../../Utils/styledComponents";
import axios from "axios";
import {config} from "../../../Helpers/env";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {RecordSupplierPayment} from "./RecordSupplierPayment";



const useStyles = makeStyles((theme) => ({
    drawerWidth: {
        width: 600,
        padding: '16px'
    },
    table: {
        // width:'40%',
        marginTop: '20px'
    },
    paragraph: {
        fontSize: '15px',
        display: 'inline-block',
        marginBottom: '0.8rem',
        marginRight: '16px'
    },
    icon: {
        width: '.9em',
        height: '.9em'
    },
    iconHolder: {
        minWidth: '38px '
    },
    item: {
        borderRight: '1px solid #c7c6c7',
        borderLeft: '1px solid #c7c6c7',
        height: '100vh',
        overflow: "auto",
        '&::-webkit-scrollbar': {
            width: "5px",
            height: "5px"
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#c7c6c7',
            "-webkit-border-radius": "10px",
            borderRadius: "10px",
        }
    },
    pItem: {
        borderLeft: '1px solid #c7c6c7',
    },
    rounded: {
        borderRadius: 0
    },
    listText: {
        textAlign: 'right'
    },
    approved: {
        color: "green"
    },
    purchased: {
        color: "#1d5dd3"
    },
    canceled: {
        color: '#D9512C'
    },
    pending: {
        color: "#F29339"
    }
}));

const StyledCell = styled(DataCell)({
    border: 0
})
const AccountsPayablesDetails = ({ actions, snackbars, match }) => {
    const classes = useStyles();

    const { invoice_no } = match.params;


    const [products, setProducts] = useState({ productData: [], loading: 'idle' });
    const [invoice, setInvoice] = useState('');
    const [pay_details, setPayDetails] = useState({});
    const [purchaseDetails, setPurchaseDetails] = useState({});
    const [openDialog, setOpenDialog] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState('idle')

    const { loading: loadingProducts, productData } = products;

    const isResolved = isSubmitted === 'resolved'

    useEffect(() => {
        if (!invoice_no) {
            return
        }
        setProducts({ ...products, loading: 'loading' })
        axios.post(`${config.smsUrl}/csupplier/supplier_payables/single`, { payable_id: invoice_no })
            .then(res => {
                const data = res?.data?.data;

                const payDetails = data?.pay_details ?? {};

                const purchase = data?.purchase ?? {};

                const products = data?.purchase_details ?? [];

                const exchange_rate = purchase.currency_rate ? purchase.currency_rate : 0;

                const currency_symbol = payDetails.is_base_currency ?  payDetails.is_base_currency : false

                const isNotBase = currency_symbol === false

                const productsArray = products.map(item => ({
                    ...item,
                    id: item?.id ?? item?.batch_id,
                    non_base_rate: isNotBase ?  +item.rate / +exchange_rate : 0,
                    non_base_total: (isNotBase ?  +item.rate / +exchange_rate : 0) * item.quantity
                }));

                const paid_amount = isNotBase ? payDetails.paid_amount / purchase.currency_rate : payDetails.paid_amount

                setPayDetails({...payDetails, paid_amount});

                setPurchaseDetails(purchase);

                setInvoice(purchase?.chalan_no);

                setProducts({ ...data, productData: productsArray, loading: 'success' });
            }).catch(err => {
            errorMessages(err, null, actions)
            setProducts({ ...products, loading: 'error' })
        })

    }, [invoice_no, isResolved]);


    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    }

    const isLoadingProduct = loadingProducts === 'loading'
    const isSuccessProducts = loadingProducts === 'success'
    const isErrorProducts = loadingProducts === 'error'



    const { openBar, type, message } = snackbars;


    const currency = pay_details?.currency
    const isBase = pay_details.is_base_currency ?  pay_details.is_base_currency : false



    const isNotBase = isBase === false

    return (
        <div className='journals'>
            <PageTitle title="Accounts Payable Details" />

            <SubHeader title="Reports" subTitle="Accounts Payable Details">
                <FontAwesomeIcon icon={faClipboardList} />
            </SubHeader>

            <MainSnackbar
                open={openBar}
                message={message}
                variant={type}
                handleCloseBar={closeSnackbar}
            />
            <RecordSupplierPayment {...{actions, openDialog, setOpenDialog, isSubmitted,setIsSubmitted,
                supplierDetails:{...pay_details, ...purchaseDetails, invoice_no}}}/>

            <Container classes={{ root: classes.rounded }}>

                {isLoadingProduct ? <div className='text-center mt-5'><LoadingGif /></div> : null}
                {isSuccessProducts ? productData.length > 0 ? <>
                    <div className="top-bar">
                        <div className="row">
                            <div className='col-lg-6'>
                                <div className="mb-3">
                                    <BackButton text='Accounts Payable' to='/creditors/report' />
                                </div>
                                <p style={{ fontSize: '18px', marginTop: '4px', marginBottom: 0, marginLeft: 0 }}>Invoice#: <strong>{invoice}</strong></p>
                            </div>
                            <div className='col-lg-6'>
                                <div className="text-right">
                                    <button type='button' style={{ marginTop: '0.6rem' }} onClick={()=>{
                                        setOpenDialog(true)
                                    }}
                                        className="btn btn-sm sms-info-btn mr-2">Record Payment
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="m-2">
                        <HospitalAddress />
                        <ProductsPurchased {...{products:productData, currency_symbol:currency, isNotBase}}/>
                        <Table className={classes.table}>
                            <TableBody>
                                <TableRow>
                                    <StyledCell />
                                    <StyledCell />
                                    <StyledCell />
                                    <StyledCell />
                                    <StyledCell />
                                    <StyledCell />
                                    <StyledCell align='right'>Total Amount</StyledCell>
                                    <StyledCell ><strong>{currency} {isNotBase ? formatDigits(purchaseDetails?.grand_currency_total) :  formatDigits(purchaseDetails?.grand_total)}</strong></StyledCell>
                                </TableRow>

                                <TableRow>
                                    <StyledCell />
                                    <StyledCell />
                                    <StyledCell />
                                    <StyledCell />
                                    <StyledCell />
                                    <StyledCell />
                                    <StyledCell align='right'>Payment Made</StyledCell>
                                    <StyledCell><strong>{currency} {formatDigits(pay_details?.paid_amount)}</strong></StyledCell>
                                </TableRow>
                                
                                <TableRow>
                                    <StyledCell />
                                    <StyledCell />
                                    <StyledCell />
                                    <StyledCell />
                                    <StyledCell />
                                    <StyledCell />
                                    <StyledCell align='right'>Amount Due</StyledCell>
                                    <StyledCell><strong>{currency} {formatDigits(pay_details?.balance)}</strong></StyledCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                </> : <div className='text-center mt-5'>No records found</div> : null}
                {isErrorProducts ? <div className='text-center mt-5'>The server did not return a valid response</div> : null}
            </Container>

        </div>
    )
}

function mapStateToProps(state) {

    return {
        snackbars: state.snackbar
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountsPayablesDetails);
