import ReusableAnteNatalTabs, {anteNatalRoutes} from "../MotherChild/AnteNatal/ANCScreen/ReusableAnteNatalTabs";
import {useANCMedicalHistory} from "../MotherChild/AnteNatal/ANCScreen/hooks/useANCMedicalHistoryArchive";
import PageTitle from "../Utils/smsTitle";
import {SubHeader} from "../../Containers/SubHeader";
import {ReusableTreatmentHistory,Spacer} from "./ReusableTreatmentHistory";
import {Container, RightAlignedContainer} from "../Utils/styledComponents";
import React from "react";
import {usePrint} from "../Utils/Templates/usePrint";
import CustomDialog from "../Utils/Dialogs/CustomDialog";
import { PatientInformation } from "./PatientInfo/PatientInformation";
import { dateConvert } from "../Utils/ConvertDate";

import { ResultsHeading, PreviousTriage, PreviousClinicalNotes,
     PreviousDiagnosis, Heading, Border,SelectForm, VisitsList } from "./PatientPreviousVisits";


const AnteNatalPreviousVisits = ({actions, match}) => {
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const doctor_name = `${!_token.first_name ? "" : _token.first_name} ${!_token.last_name ? "" : _token.last_name}`;   

    const {patient_number, labrequest_id, scan_request_id, visit_id, patient_type,patient_admission_id, anc_id, anc_status} = match.params;
    const arr = anteNatalRoutes(patient_number, visit_id, labrequest_id, scan_request_id, patient_type,patient_admission_id, anc_id, anc_status);

    const {visits, patientInfo, handleOpenVisit, handleClickComponent,option, options, handleChangeOption,
        handleOpenResults,visitDetails,openPrint, handleOpenPrint, handleClosePrint, handleOpenNote} = useANCMedicalHistory(actions, patient_number)

    const {componentRef, handlePrint,  fontSize}  = usePrint('Medical History Report');

    const {patientDiagnosis, patientTriage, clinicalNotes} = visitDetails

    const triageComponent = (
        patientTriage?.length > 0 ?  <>
                <ResultsHeading>
                    <Heading>Triage</Heading>
                    <Border/>
                </ResultsHeading>
                <PreviousTriage {...{patientTriage}}/>
            </>: null
    )

    const clinicalNotesComponent = (
        clinicalNotes?.length > 0 ?
                <Spacer>
                    <ResultsHeading>
                        <Heading>Clinical Notes</Heading>
                        <Border/>
                    </ResultsHeading>
                    <PreviousClinicalNotes {...{clinicalNotes, visit_id, handleOpen:handleOpenNote}}/>
                </Spacer>:  null
    )

    const diagnosisComponent = (
        patientDiagnosis?.length > 0 ?
                <Spacer>
                    <PreviousDiagnosis {...{patientDiagnosis}} />
                </Spacer>
                : null
    )
   
    return (
        <div className="journals">
            <PageTitle title={`${patient_type === '1' ? 'Medical History':'Previous Rounds'}`}/>
            <SubHeader title="Ante Natal" subTitle={`${patient_type === '1' ? 'Medical History':'Previous Rounds'}`} doctor_name={doctor_name}>
            <div className="header-icon"><img src="/images/antenatal-blue.png" className="icon-resize surgery-icon-tabs ante-natal-green"/></div>
            </SubHeader>
            <CustomDialog open={openPrint} handleClose={handleClosePrint} title='Print Preview' maxWidth='lg'>
                <SelectForm {...{options, option, handleChangeOption,handlePrint}}/>
                <div ref={componentRef}>
                    {fontSize}
                    <ReusableTreatmentHistory {...{patient_type, patient_number, patientInfo, treatmentDetails:visitDetails,option,
                          handleOpenResults, diagnosisComponent, clinicalNotesComponent, triageComponent}}/>
                </div>
            </CustomDialog>
            <ReusableAnteNatalTabs components={arr}>
                <Container>
                    <PatientInformation style={{paddingRight:0, paddingLeft:0, marginBottom:'16px'}} {...patientInfo}
                    patient_number={patient_number}
                                        date={dateConvert()}/>
                    <Heading>Previous Visits</Heading>
                    <Border/>
                    <VisitsList {...{visits,handleOpenVisit, handleClickComponent,handleOpenPrint,handleOpenResults}}/>

                </Container>
            </ReusableAnteNatalTabs>
        </div>
    )
}
export default AnteNatalPreviousVisits