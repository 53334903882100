import React from "react";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col"
import FormGroup from "../../Utils/FormInputs/FormGroup";
import Label from "../../Utils/FormInputs/Label";
import Card from "@material-ui/core/Card";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../../actions/snackbarActions";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {useTheme} from "@material-ui/core/styles";
import {faNotesMedical} from "@fortawesome/free-solid-svg-icons/faNotesMedical";
import {useEditAdmission} from "./hooks/useEditAdmission";


export const EditPatientAdmission = (props) => {
    const {state, p_number, showNumber, patientNumbers, submitted, isSubmitted, handleChange, //form,
        handleChangePatientNumbers, handleClickPatientNumber, handleSubmit,
        closeSnackbar} = useEditAdmission(props)
    const theme = useTheme();
    // const them = !theme ? {} : theme;
    // const palette = !them.palette ? {} : them.palette;

    const {openBar, type, message} = props.snackbars;
    const { first_name,  relationship, contact, address, nin} = state;
    return (
        <div data-testid="edit-admission">
            <PageTitle title="Admission Form"/>
            <SubHeader title="Visit" subTitle="Admission form">
                <FontAwesomeIcon icon={faNotesMedical}/>
            </SubHeader>
            <div className="add-service-card">
                <Card className="p-3">
                    <h6 className={`visit-heading ${theme.palette.type === "dark" && "dark-visit-heading"}`}>Edit Admission</h6>
                    <MainSnackbar message={message} open={openBar} handleCloseBar={closeSnackbar} variant={type}/>
                    <form onSubmit={handleSubmit} autoComplete="off">
                        <div className="row">
                            <div className="col-md-6">
                                <Form.Group as={Row}>
                                    <Label name="Patient NO" htmlFor='p_number' column sm="5" type/>
                                    <Col sm="7">
                                        <Form.Control type="text"
                                                      className={`form__control ${theme.palette.type === "dark" && 'form-control-dark'}`}
                                                      name="p_number"
                                                      value={p_number}
                                                      onChange={handleChangePatientNumbers} id="patient_id"/>
                                        {(submitted && !p_number) &&
                                        <div className="help-block">Patient ID is required</div>}
                                        {showNumber && <Form.Control as="select" multiple id="patient_number_select"
                                                                     className="drug-ul visit-ul">
                                            {patientNumbers.map((number, index) => (
                                                <option className="drug-li" key={index}
                                                        onClick={handleClickPatientNumber}
                                                        value={number.label} id="numbers">{number.label}</option>
                                            ))}
                                        </Form.Control>}
                                    </Col>
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <FormGroup onChange={handleChange} name="first_name" value={first_name} id='first_name' type="text"
                                           labelName=" Guardian  Name" colSm="7" labelSm="5"
                                           dataTest="first_name"/>
                                {/*<FormGroup labelType={true} onChange={handleChange} name="admission_date"*/}
                                {/*           value={dateTimeConvert(admission_date)} type="datetime-local"*/}
                                {/*           labelName="Admission Date" colSm="7" labelSm="5" submitted={submitted}*/}
                                {/*           dataTest="admission_date"/>*/}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <FormGroup onChange={handleChange} name="relationship" id='relationship' value={relationship} type="text"
                                           labelName="Relationship" colSm="7" labelSm="5" dataTest="relationship"/>
                                <FormGroup onChange={handleChange} name="address" id='address' value={address} type="text"
                                           labelName="Guardian Address" colSm="7" labelSm="5" dataTest="address"/>
                            </div>
                            <div className="col-md-6">
                                {/*<FormGroup onChange={handleChange} name="last_name" value={last_name} type="text"*/}
                                {/*           labelName="Guardian Last Name" colSm="7" labelSm="5" dataTest="last_name"/>*/}
                                <FormGroup onChange={handleChange} name="contact" id='contact' value={contact} type="text"
                                           labelName="Guardian Contact" colSm="7" labelSm="5" submitted={submitted}
                                           dataTest="contact"/>
                                <FormGroup onChange={handleChange} name="nin" id='nin' value={nin} type="text"
                                           labelName=" Guardian NIN" colSm="7" labelSm="5"
                                           dataTest="nin"/>
                            </div>
                        </div>

                        <div className="text-right mr-5">
                            <button type="submit" className="btn btn-sm sms-btn px-4" disabled={!!isSubmitted}
                                    data-testid="editAdmissionForm">
                                {isSubmitted ? "Saving..." : "Save"}</button>
                        </div>
                    </form>
                </Card>
            </div>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(EditPatientAdmission);
