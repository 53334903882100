import React from 'react';
import {useTheme} from "@material-ui/core/styles";


export const ReusableResults = ({image_path,hospital_scan_name,notes,indication, conclusion, ...others}) =>{
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    const splitNotes = (txt) =>{
        const nt = txt ? txt : '';
        return nt.split('\n').map(text=><p>{text}</p>)
    }
    const splitConclusion = (txt) =>{
        const nt = txt ? txt : '';
        return nt.split('\n').map(text=><ul style={{marginBottom:0}}><li style={{listStyle:'none', marginLeft:'30px'}}>
            <p >{text}</p></li></ul>)
    }
    return (
        <table className="table table-borderless table-sm" {...others}>
            <tr>
                <td align='center'><h6 style={{borderBottom:'1px solid #000'}}>{`${hospital_scan_name ? hospital_scan_name.toUpperCase() : ''} REPORT`}</h6></td>
            </tr>
            <tr>
                <td><p><strong>EXAMINATION: </strong> {hospital_scan_name}</p></td>
            </tr>
            <tr>
                <td style={{paddingBottom:10}}><p><strong>INDICATION: </strong>{indication ? indication : ''}</p></td>
            </tr>
            <tr/>
            <tr>
                <td style={{paddingBottom:0}}><p><strong>Findings</strong></p></td>
            </tr>
            <tr>
                <td style={{paddingBottom:20}}>{splitNotes(notes)}</td>
            </tr>

            {conclusion ? <tr>
                <td style={{paddingBottom:0}}><p><strong>Conclusion</strong></p></td>
            </tr> : null}
            {conclusion ? <tr>
                <td>{splitConclusion(conclusion)}</td>
            </tr>: null}
            <tr>
                <td colSpan="2">
                    <div className="text-right radiology-findings">
                    </div>
                </td>
                <td/>
            </tr>
        </table>
    )
}