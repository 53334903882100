import  {useEffect, useState} from 'react';
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import {useDoctorRadiologyRequest} from "./useDoctorRadiologyRequest";
import {useEditRadiologyRequest} from "./useEditRadiologyRequest";



const useViewPreviousRequests = (actions, match, _token) => {
    const { visit_id} = match
    const [scanRequests, setScanRequests] = useState([])
    const [status, setStatus] = useState('idle')
    const [id, setId] = useState('')
    const [openReport, setOpenReport] = useState(false)
    const {addProps} = useDoctorRadiologyRequest(actions, match, _token)
    const {editProps} = useEditRadiologyRequest(actions, match, _token)
    const [radiologyResults, setResults] = useState([]);
    const [scanResults, setScanResults] = useState({});
    const [activeChip, setActiveChip] = useState(0)
    const {isResolved} = addProps
    const {isResolved: isEditResolved} = editProps


    useEffect(()=>{
        if(!id){
            return
        }
        const formData = new FormData();
        formData.append('visit_id', visit_id);
        axios.post(`${config.smsUrl}/cdoctor/get_scan_results_details_by_visit_id`, formData).then(res=>{
            const data = res.data;
            const dt = !data ? {} : data;
            const list = !dt.scan_result ? [] : dt.scan_result;
            const arr = list.filter(item=>item.scan_request_id === id)
            const result = arr[0] ?? {};
            const r_id = result.scan_result_id ?? '';
            retrieveResults(r_id, 0)
            setResults(arr)
        }).catch(err=>{
            logoutErrorMessage(err,null, actions)
        })
    },[id]);

    const retrieveResults = (scan_result_id, active) => {
        const formData = new FormData();
        formData.append('scan_result_id', scan_result_id);
        axios.post(`${config.smsUrl}/cradiology/get_scan_results`, formData).then((res) => {
            const data = res.data;
            const dt = !data ? {} : data;
            setScanResults(dt)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
        setActiveChip(active)
    };



    useEffect(() => {
        const formData = new FormData();
        formData.append('visit_id', visit_id);
        setStatus('pending')
        axios.post(`${config.smsUrl}/cdoctor/get_previous_scan_request_by_visit_id`, formData).then(res => {
            const resp = res.data;
            const data = !resp ? [] : resp;
            const arr = groupByScanRequestId(data)
            const requests = arr.map(item=>({...item, open:true}))
            setScanRequests(requests);
            setStatus('success')
        }).catch(error => {
            logoutErrorMessage(error, null, actions)
            setStatus('error')
        });
    }, [isResolved,isEditResolved])


    const groupByScanRequestId = (arr=[]) =>{
        let result = []
        let knowledge = {}
        arr.forEach(item =>{
            let id = item.scan_request_id;
            if(id in knowledge){
                result[knowledge[id]].scans.push(item)
            }else {
                knowledge[id] = result.length
                result.push({
                    id: id,
                    date: item.date_created,
                    doctor_name:`${item.doctor_firstname ? item.doctor_firstname:''} ${item.doctor_lastname ? item.doctor_lastname : ''}`,
                    scans: [item]
                })

            }
        })
        return result
    }

    const handleOpen = (id) =>{

        const itemsArr = scanRequests.map((item)=>{
            if(item.id === id){
                return {...item,open:!item.open}
            }else{
                return item
            }
        })
        setScanRequests(itemsArr)
    }

    const handleOpenReport  = (_id) =>{
        setId(_id)
        setOpenReport(true)
    }

    const handleCloseReport  = () =>{
        setOpenReport(false)
    }

    const isLoading = status === 'pending'
    const isSuccess = status === 'success'
    const isError = status === 'error'




    return {scanRequests, isLoading, isSuccess, isError,addProps, editProps,
        openReport, handleOpenReport, handleCloseReport, handleOpen, id,
    radiologyResults, scanResults,retrieveResults, activeChip}
}
export {useViewPreviousRequests}