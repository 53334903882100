import {useRef, useState} from "react";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {history} from "../../../Helpers/history";
import {errorMessages} from "../../../Helpers/ErrorMessages";

export const useChangePassword = (actions) =>{
    const user = JSON.parse(sessionStorage.getItem("user"));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_name = !_token.user_name ? '' : _token.user_name;
    const [state, setState] = useState({
        user_name: user_name,
        old_password: '',
        new_password: '',
        repassword: ''
    });
    const [submitted, setSubmitted] = useState(false);
    const [submitting, setSubmitting] = useState(false);


    const passwordForm = useRef();

    const handleChange = (event) => {
        const {name, value} = event.target;
        setState({...state, [name]: value})
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        const {old_password,new_password,repassword,user_name} = state
        if (user_name && old_password && new_password && repassword) {
            setSubmitting(true);
            const formData = new FormData(passwordForm.current);
            axios.post(`${config.smsUrl}/admin/change_password`, formData).then(response => {
                setSubmitting(false);
                actions.snackbarActions.snackSuccess('Password updated successfully');
                setTimeout(() => {
                    history.push('/login')
                }, 2000)
            }).catch(error => {
                errorMessages(error, null, actions);
                setSubmitting(false)
            });
        } else {
            setSubmitted(true);

        }

    };
    const handleCloseSnackbar = () => {
        actions.snackbarActions.hideSnackbar();
    };

    return {state, submitted, submitting, handleChange, handleSubmit, handleCloseSnackbar,passwordForm,
        userObj, token, _token, user_name, setState}
}