import { useState, useEffect } from "react";
import moment from 'moment';
import axios from "axios";
import { config } from "../../../Helpers/env";
import { logoutErrorMessage } from "../../../Helpers/ErrorMessages";
import {dateConvert, convDate } from "../../Utils/ConvertDate";


// gets percentages for the age groups
const calculatePercentageNo = (dict_grps) => {
    let total = 0;
    let new_dict = {};

    Object.values(dict_grps).forEach((val) => total = total + val);
    for (const [key, value] of Object.entries(dict_grps)) {
        const per = Math.ceil((value/total) * 100);
        new_dict[key] = per;
    }
    return new_dict;
}


export const useMainPage = ({props}) => {
    const {actions} = props;

    // getting permissions
    const perm = JSON.parse(sessionStorage.getItem("permission"));
    const permission = !perm ? {} : perm;

    // earnings
    const earnings_perm = !permission.earnings ? {} : permission.earnings;
    const isEarningsPermission = earnings_perm.create || earnings_perm.read || earnings_perm.update || earnings_perm.delete;

    // inventory
    const inventory_perm = !permission.inventory_dashboard ? {} : permission.inventory_dashboard;
    const isInventoryPermission = inventory_perm.create || inventory_perm.read || inventory_perm.update || inventory_perm.delete;

    //settings
    const add_role = !permission.add_role ? {} : permission.add_role;
    const assign_role = !permission.assign_role ? {} : permission.assign_role;
    const assigned_user_role = !permission.assigned_user_role ? {} : permission.assigned_user_role;
    const roles = !permission.roles ? {} : permission.roles;

    const isRoles = (roles.create || roles.read || roles.update ||
        roles.delete || add_role.create || add_role.read || add_role.update ||
        add_role.delete);

    const isAssignRole = (assigned_user_role.create || assigned_user_role.read || assigned_user_role.update ||
        assigned_user_role.delete || assign_role.create || assign_role.read || assign_role.update ||
        assign_role.delete);

    // earnings
    const [earnings, setEarnings] = useState({total_earnings:'', cash_earnings:'', percentage_cash_earnings:'',
            percentage_credit_earnings:'', credit_earnings:''});
    
    // dates filters
    const [start_date, setStartDate] = useState(dateConvert());
    const [end_date, setEndDate] = useState(dateConvert());
    const [filter_type, setFilterType] = useState('Today');
    
    // patient visits
    const [patient_visits, setPatientVisits] = useState({in_patients:'', out_patients:'', total_patients:''});

    // Patient Stats Card
    const [gender_data, setGenderData] = useState([]);
    const [age_groups, setAgeGroups] = useState([]);

    // In Patient Stats
    const [in_patient_statistics, setInPatientStatistics] = useState({bed_occupancy:'', total_beds:'', avg_length_of_stay:'', 
    patient_readmisison_rate:'', admitted_patients:''});

    // set sales analytics
    const [sales_analytics, setSalesAnalytics]  = useState([]);

    const [inventory, setInventory] = useState({out_of_stock:'', expired_items:'', medical_equipment:'', sundries:'', medicine:'', total_products:''})

    // mortality rate
    const [mortality_rate, setMortalityRate] = useState({inpatient:'', outpatient:'', total_deaths: ''});

    // Time to Service Card
    const [time_to_service, setTimeToService] = useState([]);

    // Top Selling Products
    const [sell_state, setSellState] = useState({products: [], page: 0});

    const [state, setState] = useState({
        openMenu: null,
    });

    // handles the change of the earnings
    const handleEarningsChange = (date_params) => {
    
        axios.get(`${config.smsUrl}/admin/dashboard_earnings`, {params : date_params}).then((dash_earns_res) => {
            // Earnings
            const earns_data = dash_earns_res.data ? dash_earns_res.data : {};
            setEarnings({...earnings, ...earns_data});
        }).catch((err) => {
            setState({...state, loading: false});
            logoutErrorMessage(err, null, actions);
        });

    }

    // handle Patient Visits Changes
    const handlePatientVisitsChange = (date_params) => {
        axios.get(`${config.smsUrl}/admin/visits_counted`, {params : date_params}).then((dash_visits_res) => {
            // Patient Visits
            const visits_data = dash_visits_res.data ? dash_visits_res.data : {};
            const out_patients = visits_data['outpatient'] ? visits_data['outpatient'] : 0;
            const total_patients = visits_data['total_visits']? visits_data['total_visits'] : 0;
            const in_patients =  visits_data['inpatient']? visits_data['inpatient'] : 0;;
            const patient_vs = {in_patients, out_patients, total_patients};
            setPatientVisits(patient_vs);
        }).catch((err) => {
            setState({...state, loading: false});
            logoutErrorMessage(err, null, actions);
        });
    }

    // handle the top selling products
    const handleTopSellingProductsChange = (date_params) => {
        axios.get(`${config.smsUrl}/admin/top_selling_products`, {params : date_params}).then((top_prods) => {
            // Top Selling Products
            const dt =  top_prods.data ?  top_prods.data : [];
            setSellState({...sell_state, 'products': dt});
        }).catch((err) => {
            setState({...state, loading: false});
            logoutErrorMessage(err, null, actions);
        });
    }

    // handle the mortality rate
    const handleMortalityRateChange = (date_params) => {
        axios.get(`${config.smsUrl}/admin/mortality_rate`, {params : date_params}).then((top_prods) => {
            // Top Selling Products
            const dt =  top_prods.data ?  top_prods.data : [];
            setMortalityRate(dt);
        }).catch((err) => {
            setState({...state, loading: false});
            logoutErrorMessage(err, null, actions);
        });
    }

    // changes the filter type
    const handleOverallFilterChange = (filter_change) => {    
        // set dates
        let overall_start_date = '';
        let overall_end_date = '';
        if (filter_change === 'Today') {
            overall_start_date = overall_end_date = dateConvert();
            setFilterType('Today');
        } 
        if (filter_change === 'This Week') {
    
            var startOfWeek = moment().startOf('isoWeek').toDate();
            var endOfWeek   = moment().endOf('isoWeek').toDate();
    
            overall_start_date = convDate(startOfWeek);
            overall_end_date = convDate(endOfWeek);
            setFilterType('This Week');
        }
        if (filter_change === 'This Month') {
    
            var date = new Date();
            var monthStartDay = convDate(new Date(date.getFullYear(), date.getMonth(), 1));
            var monthEndDay = convDate(new Date(date.getFullYear(), date.getMonth() + 1, 0));
    
            overall_start_date = convDate(monthStartDay);
            overall_end_date = convDate(monthEndDay);
            setFilterType('This Month')
        }
    
        setStartDate(overall_start_date);
        setEndDate(overall_end_date);
    
        const date_params = {'start_date': overall_start_date, 'end_date': overall_end_date};

        // set patient visits and mortality : normal dashboard
        handlePatientVisitsChange(date_params);
        handleMortalityRateChange(date_params);

        if (isEarningsPermission) {
            // set earnings
            handleEarningsChange(date_params);
        }

        if (isInventoryPermission) {
            // set top selling products    
            handleTopSellingProductsChange(date_params);
        }

    }

    // call for patient stats
    const handlePatientStatisticsChange = () => {

        axios.get(`${config.smsUrl}/admin/patient_statistics`).then((patient_stat_res) => {
            //patient Stats -> GENDER : DONE
            const data_ps = patient_stat_res.data;
            const ps_dt = data_ps ? data_ps.data['patient_statistics'] : {};

            //get the gender
            const gender_cats = ps_dt['gender_categories'] ? ps_dt['gender_categories'] : {female: 0, male: 0};
            const gender = [
                { name: 'Female', value: gender_cats['female']},
                { name: 'Male', value: gender_cats['male'] },
                ];
            setGenderData(gender);

            // get the patient_count_age_group
            const age_grps = ps_dt['patient_count_age_group'] ? ps_dt['patient_count_age_group'] : {};
            const percentages = calculatePercentageNo(age_grps);
            const ages = Object.keys(age_grps).length > 0 ?
                [
                    { bgcolor: "#04E762", completed: age_grps['zero_twenty_eight_days'],
                        percentage_no : percentages['zero_twenty_eight_days'], age_group: '0 - 28 days' },
                    { bgcolor: "#F17105", completed: age_grps['twenty_nine_days_to_four_years'],
                        percentage_no : percentages['twenty_nine_days_to_four_years'], age_group: '29 days - 4 years' },
                    { bgcolor: "#6665DD", completed: age_grps['five_to_nine_years'],
                        percentage_no : percentages['five_to_nine_years'], age_group: '5 - 9 years' },
                    { bgcolor: "#34D1BF", completed: age_grps['ten_to_nineteen_years'],
                        percentage_no : percentages['ten_to_nineteen_years'], age_group: '10 - 19 years' },
                    { bgcolor: "#FF715B", completed: age_grps['twenty_years_and_above'],
                        percentage_no : percentages['twenty_years_and_above'], age_group: '20+ years' },
                ] : [
                    { bgcolor: "#04E762", completed: 0, percentage_no : 0, age_group: '0 - 28 days' },
                    { bgcolor: "#F17105", completed: 0, percentage_no : 0, age_group: '29 days - 4 years' },
                    { bgcolor: "#6665DD", completed: 0, percentage_no : 0, age_group: '5 - 9 years' },
                    { bgcolor: "#34D1BF", completed: 0, percentage_no : 0, age_group: '10 - 19 years' },
                    { bgcolor: "#FF715B", completed: 0, percentage_no : 0, age_group: '20+ years' },
            ]
            setAgeGroups(ages);
        }).catch((err) => {
            setState({...state, loading: false});
            logoutErrorMessage(err, null, actions);
        });

    }

    const handleInPatientStatsChange = () => {
        var date = new Date();
        var monthStartDay = convDate(new Date(date.getFullYear(), date.getMonth(), 1));
        var monthEndDay = convDate(new Date(date.getFullYear(), date.getMonth() + 1, 0));
        const date_params = {'start_date': monthStartDay, 'end_date': monthEndDay};

        axios.get(`${config.smsUrl}/admin/dashboard_inpatient_stats`, {params : date_params}).then((dash_visits_res) => {
            // In Patient Stats
            const visits_data = dash_visits_res.data ? dash_visits_res.data : {};

            const in_patient = {
                bed_occupancy: visits_data['bed_occupancy_calcualtion'] ? visits_data['bed_occupancy_calcualtion'] : 0, 
                total_beds: visits_data['total_bed_count'] ? visits_data['total_bed_count'] : 0, 
                avg_length_of_stay: visits_data['average_length_of_stay'] ? visits_data['average_length_of_stay'] : 0, 
                admitted_patients: visits_data['total_admissions'] ? visits_data['total_admissions'] : 0,
                total_wards: visits_data['total_wards'] ? visits_data['total_wards'] : 0,
            }
            setInPatientStatistics(in_patient);
        }).catch((err) => {
            setState({...state, loading: false});
            logoutErrorMessage(err, null, actions);
        });
    }

    // main hook to get all the components values
    const retreieveMainPageDetails = () => {
   
        setState({...state, loading: true});

        const sales_req = axios.get(`${config.smsUrl}/admin/sales_analytics`);    
        const time_req = axios.get(`${config.smsUrl}/admin/time_of_service`);
        const inventory_req = axios.get(`${config.smsUrl}/admin/dashboard_inventory`);

        // handle the patient statistics call
        handlePatientStatisticsChange();

        // calls that use the date filters
        handleOverallFilterChange(filter_type, true);
        handleInPatientStatsChange();

        if (isEarningsPermission && !isInventoryPermission) {
            // if earnings is only present
            Promise.all([sales_req, time_req]) 
            .then(([sales_res, time_res, inventory_res]) => {
                setTimeToService(time_res.data);
                setSalesAnalytics(sales_res.data);
            }).catch((err) => {
                setState({...state, loading: false});
                logoutErrorMessage(err, null, actions);
            });  
        } else if (isInventoryPermission && !isEarningsPermission) {
            // if inventory is only present
            inventory_req .then((inventory_res) => {
                setInventory(inventory_res.data);
            }).catch((err) => {
                setState({...state, loading: false});
                logoutErrorMessage(err, null, actions);
            });  

        } else {
            Promise.all([sales_req, time_req, inventory_req]) 
            .then(([sales_res, time_res, inventory_res]) => {
                setTimeToService(time_res.data);
                setSalesAnalytics(sales_res.data);
                setInventory(inventory_res.data);
            }).catch((err) => {
                setState({...state, loading: false});
                logoutErrorMessage(err, null, actions);
            });  
        }

    }


    // api calls for generic dashboard : normal
    const retreieveGenericPageDetails = () => {

        setState({...state, loading: true});

        // calls that use the date filters
        handleOverallFilterChange(filter_type);

        // handle the patient statistics call
        handlePatientStatisticsChange();
    }

    useEffect(()=> {

        if (isEarningsPermission || isInventoryPermission) {
            // true
            retreieveMainPageDetails();
        } else {
            // generic dashboard -> no finances
            retreieveGenericPageDetails();
        }

        const interval = setInterval(()=>{
            if (isEarningsPermission && isInventoryPermission) {
                // true
                retreieveMainPageDetails();
            } else {
                // generic dashboard -> no finances
                retreieveGenericPageDetails();
            }
        }, 600000);

        return ()=>clearInterval(interval);

    },[filter_type]); 
  

    // handles for the popup menu buttons
    const handleClick = (event) => {
        setState({...state, openMenu: event.currentTarget});
    };

    const handleClose = () => {
        setState({...state, openMenu: null});
    };


    // top selling products hooks
  const handleChangePage = (event, newPage) => {
    setSellState({...sell_state,page: newPage});
  }

  const handleChangeRowsPerPage = (event) => {
    setSellState({...sell_state, page: 0, rowsPerPage: parseInt(event.target.value, 10)})
  }

    const topSellsProps = {sell_state, handleChangePage, handleChangeRowsPerPage}

    return {
        gender_data, age_groups, topSellsProps, time_to_service, earnings, filter_type, isInventoryPermission,
        handleClick, handleClose, inventory, in_patient_statistics, mortality_rate, sales_analytics,
        patient_visits, handleOverallFilterChange, isEarningsPermission, isRoles, isAssignRole
    }
}
