import { useState} from 'react';
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import {titleCase} from "../../../Users/addUser";


export const useDoctorProcedure = (actions, match) => {
    const {visit_id} = match
    const initialState = [{procedure_id: '', service_name: '', showProcedure: false,
        service_id: '', isError: false, rate:0, currency:'', currency_symbol:'', quantity:1}]
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [all_procedures, setAllProcedures] = useState([]);
    const [procedures, setProcedures] = useState(initialState);
    const [submitted, setSubmitted] = useState(false)
    const [openDialog, setOpenDialog] = useState(false)
    const [openConfirmation, setOpenConfirmation] = useState(false)
    const [cashItems, setCashItems] = useState([])
    const [procedure_id, setProcedureId] = useState('')
    const [isBlocking, setIsBlocking] = useState(false)
    const [isBlockDialog, setIsBlockDialog] = useState(false)


    const handleOpenDialog = () =>{
        setOpenDialog(true)
    }

    const handleCloseDialog = () =>{
        if(isBlocking){
            setIsBlockDialog(true)
        }else{
            setIsBlockDialog(false)
            setIsBlocking(false)
            setOpenDialog(false);
            setSubmitted(false);
        }
    }



    const retrieveAllProcedures = (event, idx) => {
        const arr = procedures.map((prod, index) => {
            if (index === idx) {
                const formData = new FormData();
                formData.append('procedure_name', titleCase(event));
                prod.service_name = event;
                if (event !== '') {
                    axios.post(`${config.smsUrl}/cdoctor/autocomplete_search_procedure`, formData).then(res => {
                        const data = res.data;
                        const dt = !data ? [] : data;
                        if (dt[0] === "No Procedure Found") {
                            setAllProcedures([{value: '', label: 'No procedure found'}])
                        } else {
                            setAllProcedures(dt);
                        }
                    }).catch(error => {
                        logoutErrorMessage(error, null, actions)
                    });
                }
                prod.showProcedure = true;
                prod.isError = false;
                return prod
            }
            return prod
        })
        setProcedures(arr)
        setIsSubmitted('idle')
        setIsBlocking(true)
    };

    const getProcedureDetails = (event, idx) => {
        let arr = procedures.map((item, index)=>{
            if(index === idx){
                return {...item,service_name:event.innerText, procedure_id:event.value, isError:false, showProcedure:false}
            }
            return item
        })
        const formData = new FormData();
        formData.append('procedure_id', event.value);
        if (event.value) {
            axios.post(`${config.smsUrl}/cdoctor/get_service_id_by_procedure_id`, formData).then(res => {
                const resp = res.data;
                const data = !resp ? {} : resp;
                const {service_id} = data;
                axios.post(`${config.smsUrl}/cbilling/item_cost`, {visit_id:+visit_id, item_id:service_id, item_type:'service'}).then(res => {
                    const resObj = res.data;
                    const dataObj = !resObj.data ? {} : resObj.data;
                    arr = arr.map((item, index)=>{
                        if(index === idx){
                            return {...item,currency: dataObj.currency_id, service_id, currency_symbol: dataObj.currency,rate:dataObj.cost ?? 0, ...dataObj}
                        }
                        return item
                    })
                    setProcedures(arr)
                    if(dataObj.is_provider === 1 && dataObj.is_provided === 0){
                        setProcedureId(data.procedure_id)
                        setOpenConfirmation(true)
                    }
                }).catch(error => {
                    errorMessages(error, null, actions)
                });
            }).catch(error => {
                errorMessages(error, null, actions)
            });
        }
        setIsBlocking(true)
    };

    const handleChangeProcedureQuantity = (e, idx)=> {
        let arr = procedures.map((item, index) => {
            if (index === idx) {
                return {...item, quantity: e.target.value}
            }
            return item
        })
        setProcedures(arr)
    }

    const addItemsToReceipt = () =>{
        const arr = procedures.filter(item=>item.procedure_id === procedure_id)
        setCashItems(arr)
        setOpenConfirmation(false)
    }

    const handleCloseConfirmation = () =>{
        const arr = procedures.filter(item=>item.procedure_id !== procedure_id)
        setProcedures(arr)
        setOpenConfirmation(false)
    }

    const handleCancelConfirmation = () =>{
        setOpenConfirmation(false)
    }

    const handleAddRow = () => {
        setProcedures([...procedures, ...initialState])
        setIsSubmitted('idle')
    };

    const removeRow = (index) => {
        const arr = procedures.filter((_, idx) => idx !== index)
        setProcedures(arr);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const arr = procedures.every(prod => prod.service_id && prod.procedure_id && prod.quantity)
        setSubmitted(true)
        if (arr) {
            setIsSubmitted('pending');
            const params = {
                visit_id, result_type: 1,bill_type:2,
                bill_details: procedures.filter(prod => prod.service_id && prod.procedure_id).map(({procedure_id, service_id, quantity,rate,...item}) => ({
                    procedure_id,
                    service_id,
                    quantity:+quantity,
                    rate,
                    is_provided: item.is_provided,
                    is_provider: item.is_provider,
                    currency_id:item.currency_id
                }))
            }
            setIsBlocking(false)
            // const url = patient_type === '1' ? 'add_procedure' : 'save_inpatient_procedure'
            axios.post(`${config.smsUrl}/cdoctor/add_procedure`, {...params}).then(() => {
                actions.snackbarActions.snackSuccess('Procedure added successfully');
                setIsSubmitted('resolved');
                setSubmitted(false)
                setOpenDialog(false)
                setProcedures(initialState)
            }).catch(err => {
                errorMessages(err, null, actions)
                setIsSubmitted('rejected');
            })
        }
    };

    const handleResetForm = () =>{
        setIsBlockDialog(false)
        setIsBlocking(false)
        setOpenDialog(false);
        setSubmitted(false);
        const arr =procedures.map(item=>({
            ...item,procedure_id: '', service_name: '', showProcedure: false,quantity:1,
            service_id: '', isError: false, rate:0,qty:1, currency:'', currency_symbol:''
        }))
        setProcedures(arr)
    }

    const isPending = isSubmitted === 'pending'
    const isResolved = isSubmitted === 'resolved'
    const isRejected = isSubmitted === 'rejected'

    const addProps = { all_procedures, procedures,  submitted,openDialog,
        retrieveAllProcedures, getProcedureDetails, handleAddRow, removeRow, handleSubmit,
        handleOpenDialog, handleCloseDialog, isPending, isRejected, isResolved,
        openConfirmation, handleCloseConfirmation, addItemsToReceipt,handleCancelConfirmation,
        isBlockDialog, isBlocking, setIsBlockDialog,  handleResetForm, handleChangeProcedureQuantity
    }


    return {addProps}
}