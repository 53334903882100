import React, {useState} from "react";
import axios from "axios";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import { config } from "../../../../Helpers/env";
import {randomColor} from "../../../Utils/Charts/utilityFunctions";
import {useCommonState} from "../../CustomHooks/useReusableFunctions";
import {titleCase} from "../../../Users/addUser";


export const useProductTrends = (props) => {
    const {actions} = props;
    const [product, setProduct] = useState({product_id: '', product_name: ''});
    const [showProduct, setShowProduct] = useState(false)
    const [products, setProducts] = useState([])
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [chips, setChips] = useState([]);
    const [statistics, setStatistics] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [sortBy, setSortBy] = useState({value: 1, label: 'Amount'});
    const {closeSnackbar, search_type, text, textType, year, date, handleChangeSearch,
        handleChangeDate, handleChangeYear, handleIncrementYear,setSearchType} = useCommonState(actions)

    const parameters = (_id) => {
        const {start_date, end_date} = date;
        const s_date = search_type === '2' ? year : start_date;
        const current = s_date ? s_date : year
        const s_type = current === year ? '2' : search_type
        const {value} = sortBy;

        if (search_type === '3') {
            return {
                start_date: s_date,
                end_date: end_date,
                option: search_type,
                product_id: _id,
                sortBy: value
            }
        } else {
            return {
                start_date: current,
                option: s_type,
                product_id: _id,
                sortBy: value
            }
        }

    }

    const handleChangeSortBy = (value) => {
        setSortBy(value)
        setStatistics([])
    }

    const autoSearchProduct = (event) => {
        const {value} = event.target;
        setProduct({product_id: '', product_name: value})
        const formData = new FormData();
        formData.append('product_name', titleCase(value));
        axios.post(`${config.smsUrl}/creport/product_auto_search`, formData).then(res => {
            const resp = res.data;
            const data = !resp ? [] : resp;
            if (resp === "No Medicine Found") {
                const val = [{value: "", label: "No Medicine Found"}]
                setProducts(val)
            } else {
                setProducts(data);
            }
            setShowProduct(true)
        }).catch(error => {
            setShowProduct(false)
            logoutErrorMessage(error, null, actions)
        });
    };


    const selectProduct = (obj) => {
        const {value, label} = obj;
        const stat = [...statistics]
        const val = {...obj, color: randomColor()}
        const existingIds = chips.map((id) => id.value)
        if (!existingIds.includes(val.value)) {
            axios.get(`${config.smsUrl}/creport/product_trends`, {params: parameters(false, value)}).then(res => {
                const data = res.data;
                const dt = data ? data : [];
                for (let i = 0; i < stat.length; i++) {
                    stat[i][`value_${value}`] =  dt[i].value
                }
                setStatistics(stat)
                setChips([...chips, val])
            }).catch(err => {
                errorMessages(err, null, actions);
            })
        }
        setProduct({product_id: value, product_name: label})

    }

    const handleDeleteChip = (chipToDelete) => {
        setChips((chips) => chips.filter((chip) => `value_${chip.value}` !== chipToDelete.dataKey));

    };

    const handleRetrieveData = () => {
        const {start_date, end_date} = date;
        setSubmitted(true)
        if (start_date || (start_date && end_date) || year) {
            setIsSubmitted('pending')
            setChips([])
            axios.get(`${config.smsUrl}/creport/product_trends`, {
                params: parameters( '')
            }).then(res => {
                const data = res.data;
                const dt = data ? data : [];
                setStatistics(dt)
                setIsSubmitted('resolved')
            }).catch(err => {
                setIsSubmitted('rejected')
                errorMessages(err, null, actions);
            })
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        handleRetrieveData();
    }


    return {
       closeSnackbar, search_type, handleSubmit, chips,product, showProduct, products,
        sortBy, handleChangeSortBy, autoSearchProduct, selectProduct,setSearchType, parameters,
        handleDeleteChip, text, textType, year, date, handleChangeSearch, handleRetrieveData,
        handleChangeDate, handleChangeYear, handleIncrementYear, statistics, isSubmitted, submitted,
    }
}