import ReusableTabs, { TabPanel } from "../Utils/Dialogs/ReusableTabs";
import React, {useState} from "react";
import Triage from "../Triage/Triage";
import SurgeryEquipment from './SurgeryEquipment';
import {makeStyles, useTheme} from "@material-ui/core/styles";
import { DoctorsDetails, InputICD } from "./DoctorsDetails";
import { RightAlignedContainer } from "../Utils/styledComponents";
import {PatientPreviousVisitsSurgery} from "../DoctorsModule/PatientPreviousVisits";
import useCompleteSurgery from "./hooks/useCompleteSurgery";
import { PostSurgeryDetails } from './PostSurgeryDetails';
import PageTitle from "../Utils/smsTitle";
import { SubHeader } from "../../Containers/SubHeader";
import ScheduleSurgery, {PatientDetailsSurgery} from "./ScheduleSurgery";
import { DiagnosisComponent } from "./../DoctorsModule/Diagnosis/NewDiagnosis";
import CustomDialog from "../Utils/Dialogs/CustomDialog";
import { DoctorAdmissionForm } from "../DoctorsModule/Admission/DoctorAdmission";
import * as snackbarActions from "../../actions/snackbarActions"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PreviousDiagnosis from './../DoctorsModule/Diagnosis/PreviousDiagnosis';
import {ReloadObservationTriageChart} from '../MedicationandVisits/ObservationChart';
import { useDoctorDiagnosis } from "../DoctorsModule/Diagnosis/hooks/useDoctorDiagnosis";

const useStyles = makeStyles((theme) => ({
    paper: {
        margin: '22px',
        overflow:'initial',
        padding:'20px',
        height: 'inherit'
    }
}));

const CompleteSurgeryDetails = ({actions, snackbar, match}) => {

    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    const {patient_number, visit_id, patient_surgery_id, patient_type}= match.params;

    const { patient, openTriageDialog, openDiagnosisDialog, handleCloseDialog, handleOpenDialog,
        surgery_time,  onChangeSurgeryTime,
        general_notes, handleNotesState, admissionProps,
        operation_class, operationClasses, handleOpClassChange,
        anaesthesia_type, anaesthesiaTypes, handleAnesTypeChange,
        p_number, handleUpdateSurgery, patient_type:admit_patient,
        surgery_name, handleSurgeryTypeChange, surgeryTypes, 
        surgeon,surgeons, isOpen, handleChangeSurgeon, handleSelectSurgeon,
        other_anaestheists, other_surgeons, isVisitingDoctor, inputDoc,
        handleOtherSurgeons, handleOtherAnaestheists, handleChangeDoctors, handleChangeInputSurgeons,
        submitted, handleCompleteSurgery,  handleSubmitTriage, triageProps, tabular, handleChangeTabular, tableData, graphData, closeSnackbar,
        expanded,handleChange, handleEditTriage
    } = useCompleteSurgery(actions, patient_number, visit_id, patient_surgery_id, patient_type);

    const record_tabs = ['View Patient History', 'Surgery Details','Surgery Equipment', 'Complete Surgery'];

    const surgery_details_tabs = ['Triage and Diagnosis', 'Surgery'];

    const [tabValue, setTabValue] = useState(0);
    const [surgeryDetsTabValue, setSurgeryDetsTabValue] = useState(0);

    const handleChangeTabValue = (event,val) => {
        setTabValue(val);
    }

    const handleSurgeryDetsChangeTabValue = (event,val) => {
        setSurgeryDetsTabValue(val);
    }

    const {first_name, last_name, Age, gender} = patient;
    const patient_name = `${first_name} ${last_name}`;

    const isPending = false;
    
    const { 
        operation_date, estimated_start_time, duration_hr, duration_mins,
    } = surgery_time;

    const [title, setDialogTitle] = useState("");
    const [takeVitals, setTakeVitals] = useState(false);
    const [takeDiagnosis, setTakeDiagnosis] = useState(false);

    const {triageList, triage, setSubmitted:setSubmitTriage,
        isEditTriage, setEditTriage,
        setAllMetrics, allMetrics, clearTriageDetails, setSaveTriage,
         triage_element, setTriageElement
    } = triageProps;

    //const arr1 = triageList.filter(metric=>metric.metric_id !== 6).some(metric=>metric.metric_value);
    //const arr2 = triage.some(metric => metric.metric_value);
    //const IskipTriage = (arr1 && arr2);

    const handleTakeVitals = (e) => {
        setSubmitTriage(false);
        setTakeVitals(true);
        setDialogTitle("Save Triage");
        handleOpenDialog('Triage');
    }

    const handleTakeDiagnosis = (e) => {
        e.preventDefault();
        setTakeDiagnosis(true);
        setDialogTitle("Save Diagnosis");
        handleOpenDialog('Diagnosis');
    }

    // for icd codes
    const {state, isSubmitted,  handleChangeState, handleSubmitNotes, status, isResolved,
        handleChangeStatus,  allCodes, codes, retrieveAllCodes, removeRow, getDetails, doctor_name,
        submitted:diagnosisSubmitted, showDiagnosis, disease, error, handleContinue, handleReset
    } = useDoctorDiagnosis(patient_number, actions, visit_id)

    const diagProps = {
        isSubmitted, doctor_name, handleChangeState, handleSubmitNotes, closeSnackbar, state,
        codes, allCodes, removeRow, retrieveAllCodes, showDiagnosis, getDetails, isResolved,
        status, handleChangeStatus, diagnosisSubmitted, disease, error, handleContinue,handleReset
    }

    const handleSubmitTriageSurgery = (event) => {
        handleSubmitTriage(event);
        //if (IskipTriage) {
            setTakeVitals(false);
            setDialogTitle("");
            handleChangeTabular(1);
            handleCloseDialog('Triage');
        //}
    }

    const handleSubmitDiagnosisSurgery = (event) => {
    //    handleSubmitNotes(event);
        setTakeDiagnosis(false);
        setDialogTitle("");
        handleCloseDialog('Diagnosis');
    }

    const match_visit_id = {visit_id};
    const classes = useStyles();

    const {handleCloseAdmission, openAdmission, setAdmissionDialog, checkSubmissionAdmit} = admissionProps;

    const handleAdmitPatient = () => {
        setAdmissionDialog(true);
    }

    const handleOpenEditTriage = (item) => {
        setEditTriage(true);
        const dt = item.data ? item.data : [];
        setAllMetrics(dt);
        setDialogTitle("Edit Triage");
        handleOpenDialog('Triage');
    }

    const handleCloseEditTriage = (event) => {
        setEditTriage(false);
        clearTriageDetails();
        handleEditTriage(event);
        setDialogTitle("");
        handleCloseDialog('Triage');
    }

    return (
        <>
            <PageTitle title="Complete Surgery"/>
            <SubHeader title="Surgery" subTitle="Complete Surgery" >
                <img src="/images/surgery-21.png" alt="surgery" className="icon-resize surgery-icon-tabs"/>
            </SubHeader>

            <CustomDialog title={title} open={openTriageDialog} handleClose={(e)=>handleCloseDialog('Triage')} maxWidth="md" isPaperStyle={true} isContentOverflow={false}>
                {takeVitals && (isEditTriage === false) ?
                    <div>
                        <Triage {...{...triageProps, patient_number,patient:{patient_name,Age, gender}}}/>
                        <button type="submit" disabled={isPending} onClick={handleSubmitTriageSurgery} className="btn btn-sm sms-btn">{isPending ? 'Saving....' : 'Save Triage'}</button>
                    </div>             
                : isEditTriage ?
                    <div className='okay-try'>
                        <Triage {...{...triageProps, patient_number,patient:{patient_name, Age, gender}}}/>
                        <button type="submit" disabled={false} onClick={handleCloseEditTriage} className="btn btn-sm sms-btn">{'Edit Triage'}</button>
                    </div> 
                : null
                }
            </CustomDialog>                 

            {takeDiagnosis ? 
            <CustomDialog title={title} open={openDiagnosisDialog} handleClose={(e)=>handleCloseDialog('Diagnosis')} maxWidth="md" isPaperStyle={true}  isContentOverflow={false}>
                <div>
                    <DiagnosisComponent diagProps={diagProps}/>
                   {/*} <button type="submit" disabled={isPending} onClick={handleSubmitDiagnosisSurgery} className="btn btn-sm sms-btn">{isPending ? 'Saving....' : 'Save Diagnosis'}</button> */}
                </div>     
            </CustomDialog>             
            : null}

            {openAdmission ?  
                <CustomDialog title='Patient Admission Form' open={openAdmission} handleClose={handleCloseAdmission} maxWidth="md"  isContentOverflow={false}>
                    <DoctorAdmissionForm admissionProps={admissionProps}/>
                </CustomDialog>
                : null
            }
                            
            <ReusableTabs value={tabValue} tabHeading={record_tabs} handleChange={handleChangeTabValue}>
                <TabPanel value={tabValue} index={0}>
                    <PatientDetailsSurgery p_number={p_number} patientDetails={patient}/>
                    <PatientPreviousVisitsSurgery actions={actions} patient_number={patient_number}/>
                </TabPanel>

                <TabPanel value={tabValue} index={1}>
                    <ReusableTabs value={surgeryDetsTabValue} tabHeading={surgery_details_tabs} handleChange={handleSurgeryDetsChangeTabValue}>

                        <TabPanel value={surgeryDetsTabValue} index={0}>
                            <PatientDetailsSurgery p_number={p_number} patientDetails={patient}/>
                            <div className="triage-surgery ">
                                <h6 className='patient-surgery-details' style={{marginBottom:"10px"}}>Previous Triage</h6>
                                <ReloadObservationTriageChart {...{ tabular, handleChangeTabular, tableData, graphData, closeSnackbar, expanded,handleChange,  handleOpenEditTriage, isDoctorScreen:true}}/>
                                <button type="submit" className="btn btn-sm sms-btn" onClick={handleTakeVitals} style={{marginTop:"10px", marginBottom:"10px"}}>Add Triage</button>
                                <h6 className='patient-surgery-details' style={{marginTop:"20px", marginBottom:"10px"}}>ICD Diagnosis</h6>
                                <PreviousDiagnosis actions={actions} match={match_visit_id} isResolved={isResolved}/>
                                <button type="submit" className="btn btn-sm sms-btn" onClick={handleTakeDiagnosis} style={{marginTop:"10px", marginBottom:"10px"}}>Add Diagnosis</button>
                            </div>
                        </TabPanel>

                        <TabPanel value={surgeryDetsTabValue} index={1}>
                            <ScheduleSurgery
                                p_number={patient_number}
                                completeSurgery={true}
                                patientDetails={patient} 
                                submitted={submitted}
                                operation_date={operation_date}  onChange={onChangeSurgeryTime}
                                operation_class={operation_class} operationClasses={operationClasses} handleOpClassChange={handleOpClassChange}    
                                surgery_name={surgery_name} surgeryTypes={surgeryTypes} handleSurgeryTypeChange={handleSurgeryTypeChange} 
                                estimated_start_time = {estimated_start_time} duration_hr={duration_hr} duration_mins={duration_mins}
                            />
                            
                            <DoctorsDetails 
                                completeSurgery={true}
                                other_anaestheists={other_anaestheists} other_surgeons ={other_surgeons}
                                anaesthesia_type={anaesthesia_type} anaesthesiaTypes={anaesthesiaTypes} handleAnesTypeChange={handleAnesTypeChange}
                                general_notes={general_notes} handleNotesState={handleNotesState}
                                handleChange={handleChangeInputSurgeons}
                                {...{surgeon, surgeons, isOpen, handleChangeSurgeon, handleSelectSurgeon, handleOtherAnaestheists, handleOtherSurgeons, handleChangeDoctors, isVisitingDoctor, inputDoc}}
                            />
                            
                            <RightAlignedContainer>
                                <button type="submit" className="btn btn-sm sms-hl-btn" style={{marginRight:'30px'}} onClick={handleUpdateSurgery}>Save</button>
                            </RightAlignedContainer>
                        </TabPanel>
                    
                    </ReusableTabs> 

                </TabPanel>

                <TabPanel value={tabValue} index={2}>
                    <PatientDetailsSurgery p_number={p_number} patientDetails={patient}/>
                    <SurgeryEquipment actions={actions} snackbar={snackbar} match={match}/>
                </TabPanel>

                <TabPanel value={tabValue} index={3}>
                    <PatientDetailsSurgery p_number={p_number} patientDetails={patient}/>
                    <PostSurgeryDetails submitted={submitted} surgery_time={surgery_time}  onChange={onChangeSurgeryTime} general_notes={general_notes} handleNotesState={handleNotesState} handleCompleteSurgery={handleCompleteSurgery} 
                    handleAdmitPatient={handleAdmitPatient} checkSubmissionAdmit={checkSubmissionAdmit} patient_type={admit_patient}/>
                </TabPanel>
            </ReusableTabs>             
        </>
    )
}

function mapStateToProps(state) {
    const {snackbar} = state;
    return {snackbar}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(CompleteSurgeryDetails);