import {useEffect, useRef, useState} from "react";
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import {exportPDF} from "../../../Utils/downloadPDF";
import { useReceiveStock } from "../../../Pharmacy/CustomHooks/useReceiveStock";
import { useStockAdjustment } from "../../stock/hooks/useStockAdjustment";


export const useStockList = (props) =>{
    const [searchValue, setSearchValue] = useState('');
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('sl');
    const [loading, setLoading] = useState(false);
    const [stockList, setList] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const [total_count, setCount] = useState(0);
    const [allRecords, setAllRecords] = useState([]);
    const csvRef = useRef();
    const {actions} = props;
    const {openDialog,setOpenDialog, isResolved,stockProps} = useReceiveStock(actions)
    const {adjustmentProps, isResolved:isResolvedAdjustment,isCsvResolved} = useStockAdjustment(actions)
    const [isSetup, setIsSetup] = useState('')
    const [activeStatus,  setActiveStatus] = useState(1)
    const [item, setItem] = useState({product_id:'', status:''}); 
    const [openDelete, setDelete] = useState(false);
    const [isDelete, setIsDelete] = useState('idle')
    const { isBlocking, setIsBlockDialog,  handleResetForm} = stockProps

    useEffect(() => {
        setLoading('pending');
        const formData = new FormData();
        formData.append('category_id', activeStatus)
        formData.append('search', searchValue)
        formData.append('page', activePage)
        formData.append('to_destination', 1)
        axios.post(`${config.smsUrl}/cpharmacy/store_stock`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const st = !dt.stock ? [] : dt.stock;
            const count = !dt.total_count ? 0 : dt.total_count;
            setCount(count)
            setList(st);
            setLoading('success');
        }).catch((err) => {
            logoutErrorMessage(err, null, actions);
            setLoading('error');
        })
    }, [activePage,searchValue, isResolved,isResolvedAdjustment, activeStatus,isCsvResolved]);

    useEffect(()=>{
        setActivePage(1)
        setSearchValue('')
    },[activeStatus])

    useEffect(() => {
        axios.get(`${config.smsUrl}/cpharmacy/check_initial_setup`).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const message = dt.message ? dt.message : ''
            setIsSetup(message)
        }).catch((err) => {
            logoutErrorMessage(err, null, actions);
        })
    }, []);

    const retrieveAllRecords = () => {
        return axios.get(`${config.smsUrl}/cinventory/getStock`)
    }

    const changeActiveStatus= (status) => {
        setActiveStatus(status)
    }

    const closeSnackbar = () => {
        const {actions} = props;
        actions.snackbarActions.hideSnackbar()
    };

    const handleSearch = (event) => {
        setSearchValue(event.target.value)
    };

    const handleChangePage = (page) =>{
        setActivePage(page)
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const downloadPdf = () => {
        const formData = new FormData();
        formData.append('search', searchValue)
        retrieveAllRecords().then(res => {
            const data = res.data;
            const items = !data ? [] : data;
            exportPDF("Stock  List", [["SL",  "Product", "Sell Price", "In Quantity",
                    "Quantity Dispatched","Stock"]],
                items.map((item, index) => [index += 1, item.product_name, item.price,
                    item.totalpurchaseqnty, item.totaldispatched, item.stock
                ]),
                "Stock List .pdf");
            setAllRecords(items);
        }).catch((err) => {
            logoutErrorMessage(err, null, actions);
        })
    
    }

    const downloadCsv = () => {
        retrieveAllRecords().then(res => {
            const data = res.data;
            const items = !data ? [] : data;
            setAllRecords(items);
            csvRef.current.link.click()
        }).catch((err) => {
            logoutErrorMessage(err, null, actions);
        })
    }

  
    const handleOpenDialog = () =>{
        setOpenDialog(true)
    }

    const handleCloseDialog = () =>{
        if(isBlocking){
            setIsBlockDialog(true)
        }else{
            handleResetForm()
        }
    }


    const handleDelete = () => {
        const {product_id, status} = item;
        const formData = new FormData();
        const isActive = status === 1 ? 0 : status === 0 ? 1 : '';
        formData.append('product_id', product_id);
        formData.append('status', isActive);
        setIsDelete('pending')
        axios.post(`${config.smsUrl}/cpharmacy/medicine_delete`, formData).then(() => {
            setIsDelete('deleted')
            const message = status === 1 ? 'deactivated' : status === 0 ? 'activated':''
            actions.snackbarActions.snackSuccess(`Product ${message} successfully`);
            setDelete(false)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
            setIsDelete('error')
        })
    };


    const handleOpenDelete = (obj) => {
        const {product_id, status} = obj;
        setDelete(true);
        setItem({product_id, status})
    };

    const handleCloseDelete = () => {
        setDelete(false)
    };

    const isLoading = loading === 'pending';
    const isSuccess = loading === 'success';
    const isError = loading === 'error';
    const isDeleted = isDelete === 'deleted'
    const isPending = isDelete === 'pending';
    const setupRequired = isSetup === "Initial Setup Required"

    return {searchValue, order, orderBy, loading, stockList, activePage, total_count,downloadPdf, isLoading, isSuccess,isError,
        handleOpenDelete, handleCloseDelete, openDelete, handleDelete, item, isPending,
        closeSnackbar, handleSearch, handleChangePage, handleRequestSort, csvRef, downloadCsv,  allRecords, openDialog, handleCloseDialog, isResolved,stockProps, handleOpenDialog, adjustmentProps, isResolvedAdjustment,
        setupRequired, changeActiveStatus, activeStatus}
}