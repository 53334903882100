import React, {useEffect, useState} from "react";
import PageTitle from "../../../Utils/smsTitle";
import {SubHeader} from "../../../../Containers/SubHeader";
import ReusableTabs, {TabPanel} from "../../../Utils/Dialogs/ReusableTabs";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../../../actions/snackbarActions";
import {connect} from "react-redux";
import MainSnackbar from "../../../Utils/Snackbar/SmsSnackbar";
import InternalReferralNote  from "../../../DoctorsModule/ReferralNote/InternalReferral";
import OutGoingReferralNote  from "../../../DoctorsModule/ReferralNote/OutGoingReferral";
import { usePatientDetails } from '../../../DoctorsModule/ReferralNote/usePatientDetails';
import ReusableAnteNatalTabs, { anteNatalRoutes } from './ReusableAnteNatalTabs';

/**
 * Component that returns the referral note for a patient in the Ante natal screen
 * @param {*} param0 
 * @returns 
 */
const AnteNatalReferralNote = ({actions, snackbars ,match}) => {
    const {patient_number, labrequest_id, scan_request_id, visit_id, patient_type,patient_admission_id, anc_id, anc_status} = match.params;
    const arr = anteNatalRoutes(patient_number, visit_id, labrequest_id, scan_request_id, patient_type,patient_admission_id, anc_id, anc_status);
    const [tabValue, setTabValue] = useState(0);
    const [patient, setPatient] = useState({patient_name: "", age: "", gender: "", address: "", phone: ""});
    const {patient: patientDetails} = usePatientDetails(actions, patient_number, '')
    useEffect(() => {
        setPatient({
            patient_name: `${!patientDetails.first_name ? "" : patientDetails.first_name} ${!patientDetails.last_name ? "" : patientDetails.last_name}`,
            age: patientDetails.Age,
            gender: patientDetails.gender,
            address: patientDetails.address,
            phone: patientDetails.phone_no
        });
    }, [patientDetails]);


    const handleChangeTabValue = (event, val) => {
        setTabValue(val)
    }


    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const tabs = ['Outgoing Referral', 'Internal Referral']

    const {patient_name, phone} = patient;
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const doctor_name = `${!_token.first_name ? "" : _token.first_name} ${!_token.last_name ? "" : _token.last_name}`;
    const {openBar, type, message} = snackbars;
    return (
        <div className='journals' data-testid="ante-natal-referral-note">
            <PageTitle title="Referral Note"/>
            <SubHeader title="Ante Natal" subTitle="Referral Note"  doctor_name={doctor_name}>
            <div className="header-icon-margin-right-zero"><img src="/images/antenatal-blue.png" className="icon-resize surgery-icon-tabs ante-natal-green"/></div>
            </SubHeader>
            <MainSnackbar open={openBar} handleCloseBar={closeSnackbar} variant={type} message={message}/>
            <ReusableAnteNatalTabs components={arr}>
                <ReusableTabs value={tabValue} tabHeading={tabs} handleChange={handleChangeTabValue}>
                    <TabPanel value={tabValue} index={0}>
                        <OutGoingReferralNote patient_name={patient_name} phone_no={phone} snackbars={snackbars}
                                              patient_number={patient_number} visit_id={visit_id} actions={actions}/>
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        <InternalReferralNote patient_name={patient_name}  phone_no={phone} actions={actions}
                                              patient_number={patient_number} visit_id={visit_id} snackbars={snackbars}/>
                    </TabPanel>
                </ReusableTabs>
            </ReusableAnteNatalTabs>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbar
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(AnteNatalReferralNote);