import {useEffect, useRef, useState} from "react";
import axios from "axios";
import {config} from "../../../../Helpers/env";
import {logoutErrorMessage} from "../../../../Helpers/ErrorMessages";

export const useViewRequisitionNote = (match,actions) =>{
    const [requisition, setRequisition] = useState({});
    const componentRef = useRef();

    const {requisition_id} = match.params;
    useEffect(() => {
        const formData = new FormData();
        formData.append('requisition_id', requisition_id);
        axios.post(`${config.smsUrl}/cinventory/view_requisition_note`, formData).then(res => {
            const data = res.data;
            const all_data = !data ? {} : data;
            setRequisition(all_data)
        }).catch(e => {
            logoutErrorMessage(e, null, actions)
        })
    }, []);

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    return {requisition, componentRef, closeSnackbar}
}