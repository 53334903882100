import React from "react";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CustomTable from "../../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import {getComparator, stableSort} from "../../Utils/Tables/sortingFunctions";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {Link} from "react-router-dom";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import *  as snackbarActions from "../../../actions/snackbarActions";
import DeleteDialog from "../../Utils/Dialogs/DeleteDialog";
import {faShippingFast} from "@fortawesome/free-solid-svg-icons/faShippingFast";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {usePurchaseList} from "./hooks/usePurchaseList";
import {dateStringConvert} from "../../Utils/ConvertDate";
import {DataExportButton} from "../../Utils/Buttons/DataExportationButton";
import {makeStyles} from "@material-ui/core/styles";
import CustomDialog from "../../Utils/Dialogs/CustomDialog";
import {ViewPurchaseTemplate} from "./ViewPurchase";
import {RightAlignedContainer} from "../../Utils/styledComponents";
import {ReusableRouteTabs} from "../../Utils/Dialogs/ReusableTabs";
import {history} from "../../../Helpers/history";

const useStyles = makeStyles((theme) => ({

    received: {
        color: "green"
    },
    purchased: {
        color: "#1d5dd3"
    },
    canceled: {
        color: '#D9512C'
    },
    pending: {
        color: "#F29339"
    },

}));

const headData = [{id: 'sl', numeric: false, disablePadding: false, label: 'SL'},
    {id: 'date', numeric: false, disablePadding: false, label: 'Purchase Date'},
    {id: 'invoice_no', numeric: false, disablePadding: false, label: 'Invoice No.'},
    {id: 'purchase_id', numeric: false, disablePadding: false, label: 'Purchase#'},
    {id: 'supplier_name', numeric: false, disablePadding: false, label: 'Supplier Name'},
    {id: 'total_amount', numeric: false, disablePadding: false, label: 'Total Amount'},
    {id: 'currency', numeric: false, disablePadding: false, label: 'Currency'},
    {id: 'status', numeric: false, disablePadding: false, label: 'Status'},
    {id: 'actions', numeric: false, disablePadding: true, label: 'Action'},
];


export const CanceledPurchases = (props) => {
    const propsObj = {...props, status:[9]}
    const {searchValue, order, orderBy, loading, purchases,  activePage, total_count,
        handleSearch, handleChangePage, downloadPdf,
        closeSnackbar, handleRequestSort, csvRef,allRecords, downloadCsv, openReverse,
        handleCloseReverse, handleReverse,
        draftDialog,  handleCancelDraft, handleCloseDraftDialog,
        handleCancelPurchase} = usePurchaseList(propsObj)

    const headers = [{label: 'Purchase Date', key: ' purchase_date'}, {
        label: 'Purchase ID',
        key: ' product_purchase_id'
    },
        {label: 'Supplier', key: 'supplier_name'}, {label: 'Total Amount', key: '  total_amount'}]
    const {openBar, type, message} = props.snackbars;
    const classes = useStyles()


    const components = [{label: 'All Purchases', path: '/purchaselist'},
        {label: 'Canceled Purchases', path: '/canceled-purchases'},
        {label: 'Drafted Purchases', path: '/drafted-purchases'}
    ]
    return (
        <div data-testid="purchase-list" className='journals'>
            <PageTitle title="Canceled purchases"/>
            <SubHeader title="Inventory" subTitle="Canceled purchases">
                <FontAwesomeIcon icon={faShippingFast}/>
            </SubHeader>
            <div className='general-ledger-header'>
                <RightAlignedContainer>
                    <Link to="/addpurchase">
                        <button className="btn sms-btn btn-sm small-btn mr-4"><FontAwesomeIcon icon={faPlus}/> Add
                            Purchase
                        </button>
                    </Link>
                    <DataExportButton data={allRecords} title='Purchase List' csvRef={csvRef}
                                      exportCsv={downloadCsv} downLoadPdf={downloadPdf} headers={headers}/>
                </RightAlignedContainer>
            </div>

            <CustomDialog handleClose={handleCloseDraftDialog} open={draftDialog}
                          title='Use purchase as draft' isContentOverflow={true}>
                <p style={{marginLeft:0, marginRight:0}} className='text-center'>Would you like to use this purchase as a draft?</p>
                <RightAlignedContainer>
                    <button className='btn sms-info-btn btn-sm mr-3' onClick={handleCancelPurchase}>Yes</button>
                    <button className="btn sms-grey-btn btn-sm" onClick={handleCancelDraft}>No</button>
                </RightAlignedContainer>

            </CustomDialog>
            <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/>
            <div className="mui-tables">
                {/*<DeleteDialog message='cancel' openDialog={openDelete} handleClose={handleCloseDelete}*/}
                {/*                    text="purchase" title='Cancel Purchase'>*/}
                {/*         */}
                {/*</DeleteDialog>*/}

                <DeleteDialog message='reverse' openDialog={openReverse} handleClose={handleCloseReverse}
                              text="purchase" title='Reverse Purchase'>
                    <button className='btn sms-info-btn btn-sm mr-3' onClick={handleReverse}>Continue</button>
                    <button className="btn sms-grey-btn btn-sm" onClick={handleCloseReverse}>No</button>
                </DeleteDialog>
                <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>
                <CustomTable title="Purchases" handleChangeNextPage={handleChangePage} total_count={total_count}
                             headData={headData} handler={handleSearch} activePage={activePage} records={10}
                             handleRequestSort={handleRequestSort} data={purchases} colSpan={5} order={order}
                             orderBy={orderBy} term={searchValue} customPage>
                    {loading ? <TableBody><TableRow>
                        <TableCell colSpan={headData.length} align="center"><img src="/images/smsloader.gif"
                                                                   alt="loader"/></TableCell>
                    </TableRow></TableBody> : purchases.length > 0 ?
                        <TableBody>
                            {stableSort(purchases, getComparator(order, orderBy))
                                .slice(0, 10)
                                .map((item, index) => {
                                    const styles = {
                                        1: classes.pending,
                                        2: classes.received,
                                        9: classes.canceled,

                                    }
                                    const text = {
                                        1: 'PURCHASED',
                                        2: 'RECEIVED',
                                        9: 'CANCELED',

                                    }
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={index}
                                            className='mui-table-row'>
                                            <TableCell>{item.sl}</TableCell>
                                            <TableCell>{item.purchase_date ? dateStringConvert(item.purchase_date) : ''}</TableCell>
                                            <TableCell>{item.chalan_no}</TableCell>
                                            <TableCell>{item.product_purchase_id}</TableCell>
                                            <TableCell>{item.supplier_name}</TableCell>
                                            <TableCell>{item.total_amount}</TableCell>
                                            <TableCell>{item.currency_symbol}</TableCell>
                                            <TableCell>
                                                <span className={styles[item.status]}>{text[item.status]}</span>
                                            </TableCell>
                                            <TableCell>
                                                <Link
                                                    to={{pathname: `/view-purchase/${item.product_purchase_id}/canceled`}}>
                                                    <button className="btn btn-sm small-btn sms-btn mr-2 mb-2" id="view_invoice">
                                                        View</button>
                                                </Link>

                                                <Link
                                                    to={{pathname: `/addpurchase/${item.product_purchase_id}/canceled_draft`}}>
                                                    <button className="btn btn-sm small-btn sms-info-btn  mb-2" id="draft_invoice">
                                                        Use as draft</button>
                                                </Link>

                                            </TableCell>

                                        </TableRow>
                                    );
                                })}
                        </TableBody> : <TableBody><TableRow>
                            <TableCell colSpan={headData.length} align="center">No records found</TableCell>
                        </TableRow></TableBody>}
                </CustomTable>
            </div>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(CanceledPurchases);
